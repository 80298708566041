import React, { useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import NormalForm from "./NormalInvoice/NormalForm";
import SatNormalForm from "./SatNormalInvoice/SatNormalForm";
import { useLanguage } from "../../../Providers/LanguageContext";

function InvoiceForm2({ getTime, value }) {
  const { language, currentLanguage } = useLanguage();
  const [invType, setInvType] = useState("");
  // console.log("invTypedd", invType);

  return (
    <>
      <section className="invoiceFormTwoSec">
        <div className="container myOwnFisiContainer">
          <p className="creNewHead">Create new invoice</p>
          <div className="tabFisiContainer">
            <Tabs>
              <div className="myDivFisiTab">
                <TabList className="myFisiTab">
                  <Tab>Normal</Tab>
                  <Tab>SAT-Normal</Tab>
                  <Tab>SAT-Addenda</Tab>
                  <Tab>SAT-Substitute</Tab>
                </TabList>
              </div>

              {/* normal form */}
              <TabPanel>
                <NormalForm
                  getTime={getTime}
                  value={value}
                />
              </TabPanel>

              {/* sat normal form */}
              <TabPanel>
                <SatNormalForm
                  getTime={getTime}
                  value={value}
                  setInvType={setInvType}
                />
              </TabPanel>

              <TabPanel>
                <h2>Any content 3</h2>
              </TabPanel>
              <TabPanel>
                <h2>Any content 4</h2>
              </TabPanel>
            </Tabs>
          </div>
        </div>
      </section>
    </>
  );
}

export default InvoiceForm2;
