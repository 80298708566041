import React from 'react'

function Track2() {
  return (

   <>

<div id='budget'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-xl-7 col-lg-7 col-md-12 col-12'>
                            <div className='budget-left'>
                                <div className='heading'>
                                    <h2>Seguimiento del Presupuesto</h2>
                                </div>
                                <div className='budget-cnt'>
                                    <div className='cnt-top'>
                                        <form>
                                            <div className="form-group">
                                                <label for="exampleInputEmail1">Importe Presupuestario</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id=""
                                                    aria-describedby=""
                                                    placeholder="Cantidad"
                                                />
                                            </div>
                                        </form>
                                    </div>
                                    <div className='cnt-mid'>

                                        <form>
                                            <div class="form-group">
                                                <label>Partida Presupuestaria</label>
                                                <select className="form-control drop" id="exampleFormControlSelect1">
                                                    <option>Partida presupuestaria</option>
                                                    <option>2</option>
                                                    <option>3</option>
                                                    <option>4</option>
                                                    <option>5</option>
                                                </select>
                                            </div>
                                        </form>

                                    </div>
                                    <div className='btm-cnt'>
                                        <form>
                                            <div class="form-group">
                                                <label>Periodo Presupuestario</label>
                                                <select className="form-control drop" id="exampleFormControlSelect1">
                                                    <option>Periodo Presupuestario</option>
                                                    <option>2</option>
                                                    <option>3</option>
                                                    <option>4</option>
                                                    <option>5</option>
                                                </select>
                                            </div>
                                        </form>
                                    </div>
                                    {/* <div className='btm-dropdown'>
                                    <form>
                                            <div class="form-group">
                                                <label>Periodo Presupuestario</label>
                                                <select className="form-control drop" id="exampleFormControlSelect1">
                                                    <option>Fecha de Inicio</option>
                                                    <option>2</option>
                                                    <option>3</option>
                                                    <option>4</option>
                                                    <option>5</option>
                                                </select>
                                            </div>
                                        </form>
                                        <form>
                                            <div class="form-group">
                                                <label>Periodo Presupuestario</label>
                                                <select className="form-control drop" id="exampleFormControlSelect1">
                                                    <option>Fecha de Finalización</option>
                                                    <option>2</option>
                                                    <option>3</option>
                                                    <option>4</option>
                                                    <option>5</option>
                                                </select>
                                            </div>
                                        </form>
                                    </div> */}
                                    <div className='cnt-btn'>
                                        <button type="send" className='post-btn'>Publicar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-xl-5 col-lg-5 col-md-12 col-12 d-flex justify-content-center align-items-center' style={{paddingTop:"80px"}}>
                            <div className='bill-right'>
                                <label className="form_label">
                                    <input type="file" className="form-control" id="uploadPhoto" />
                                    imagen por enviar
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

   </>

  )
}

export default Track2