import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import Gifcomponent from '../Component/Gifcomponent';

import { SpeechContext } from '../Providers/SpeechWrapper';
import HttpClient from '../utils/HttpClient';
import goldgif from "../images/NEWGIFS/1.ClassLevelGifs-Gold/-gold.gif";
import orogif from "../images/NEWGIFS/1.ClassLevelGifs-Gold/-oro.gif"
import outstandinggif from "../images/NEWGIFS/1.ClassLevelGifs-Gold/NIVEL1Outstanding.gif"
import outstandingesgif from "../images/NEWGIFS/1.ClassLevelGifs-Gold/NIVEL1sobresaliente.gif"
import superiorgif from "../images/NEWGIFS/1.ClassLevelGifs-Gold/NIVEL2SUPERIORES-EN.gif"
import phenomenal_es from "../images/NEWGIFS/1.ClassLevelGifs-Gold/NIVEL3FENOMENAL.gif"
import phenomenal_en from "../images/NEWGIFS/1.ClassLevelGifs-Gold/NIVEL3Phenomenal.gif"


import diamondgif from "../images/NEWGIFS/ClassLevelGifsDiamond/DIAMOND.gif";
import diamondesgif from "../images/NEWGIFS/ClassLevelGifsDiamond/DIAMANTE.gif";
import worldclassengif from "../images/NEWGIFS/ClassLevelGifsDiamond/NIVEL6World-Class.gif";
import worldclassesgif from "../images/NEWGIFS/ClassLevelGifsDiamond/NIVEL6CLASE-MUNDIAL.gif";
import top1gif from "../images/NEWGIFS/ClassLevelGifsDiamond/NIVEL7TOP1ESEN.gif";
// import diamondesgif from "../images/NEWGIFS/CClassLevelGifsDiamond/DIAMANTE.gif"
// import worldclassengif from "../images/NEWGIFS/CClassLevelGifsDiamond/NIVEL6World-Class.gif";
// import worldclassesgif from "../images/NEWGIFS/CClassLevelGifsDiamond/NIVEL6CLASE-MUNDIAL.gif";
// import top1gif from "../images/NEWGIFS/CClassLevelGifsDiamond/NIVEL7TOP1ESEN.gif"

import platinum_en from "../images/NEWGIFS/ClassLevelGifs-Platinum/PLATINO.gif";
import platinum_es from "../images/NEWGIFS/ClassLevelGifs-Platinum/PLATINUM.gif";
import brilliance_en from "../images/NEWGIFS/ClassLevelGifs-Platinum/NIVEL4Brilliance.gif";
import brilliance_es from "../images/NEWGIFS/ClassLevelGifs-Platinum/NIVEL4brillante.gif";
import genius_en from "../images/NEWGIFS/ClassLevelGifs-Platinum/NIVEL5Genius.gif";
import genius_es from "../images/NEWGIFS/ClassLevelGifs-Platinum/NIVEL5GENIO.gif";



import plus_one from "../images/DigitalIcon/+1.gif";
import plus_two from "../images/DigitalIcon/+2.gif";
import plus_five from "../images/DigitalIcon/+5.gif";
import plus_ten from "../images/DigitalIcon/+10.gif";
import plus_twenty from "../images/DigitalIcon/+20.gif";
import plus_twenty_five from "../images/DigitalIcon/+25.gif";
import plus_thirty from "../images/DigitalIcon/+50.gif";




import { useLanguage } from '../Providers/LanguageContext';
import { UserContext } from '../Providers/UserWrapper';

import settings_audio_en from "../extras/xi_view_settings_audio_a.mp3";
import settings_audio_es from "../extras/xi_view_settings_audio_a_es.mp3";


function AudioManager() {
    const { show, setShow } = useContext(UserContext);
    const location = useLocation();
    const [rewards, setRewards] = useState("")
    const [currentGif, setCurrentGif] = useState({
        name: "",
        source: ""
    })
    const { playing, setCurrAudio, currAudio, showGif, gifToShow, gifPoints, rewardsOriginal,
    } = useContext(SpeechContext)
    // console.log('gifPoints',gifPoints)

    const { currentLanguage } = useLanguage()

    useEffect(() => {
        if (playing === false) {
            if (currAudio) {
                if (!currAudio.paused) {
                    currAudio.pause()
                }
                setCurrAudio(null)
            }
        }
    }, [playing])

    useEffect(() => {
        fetchRewards()
    }, [])

    const fetchRewards = async () => {
        const res = await HttpClient.requestData("reward-manager", "GET");
        // console.log('rewardRes', res)
        if (res && res.status) {
            setRewards(res.data)
        }
    }


    useEffect(() => {
        setShow(false)
    }, [location.pathname])


    useEffect(() => {
        if (gifPoints) {
            if (gifPoints === 1) {
                setCurrentGif({
                    name: "plus_one",
                    source: plus_one
                })

                setTimeout(() => {
                    setCurrentGif({
                        name: "",
                        source: ""
                    })
                }, 3000)
                const newReward = rewardsOriginal + 1;

                console.log(newReward, rewardsOriginal, "helloworld")
                console.log(rewardsOriginal, "rewardsOriginalbaby")

            } else if (gifPoints === 2) {
                setCurrentGif({
                    name: "plus_two",
                    source: plus_two
                })
                setTimeout(() => {
                    setCurrentGif({
                        name: "",
                        source: ""
                    })
                }, 3000)
                const newReward = rewardsOriginal + 2;
                console.log(newReward, rewardsOriginal, "helloworld")

            } else if (gifPoints === 5) {
                setCurrentGif({
                    name: "plus_five",
                    source: plus_five
                })
                setTimeout(() => {
                    setCurrentGif({
                        name: "",
                        source: ""
                    })
                }, 3000)
            } else if (gifPoints === 10) {
                setCurrentGif({
                    name: "plus_ten",
                    source: plus_ten
                })

                setTimeout(() => {
                    setCurrentGif({
                        name: "",
                        source: ""
                    })
                }, 3000)
                const newReward = rewardsOriginal + 10;
                console.log(newReward, rewardsOriginal, "helloworld")

            } else if (gifPoints === 20) {
                setCurrentGif({
                    name: "plus_twenty",
                    source: plus_twenty
                })
                setTimeout(() => {
                    setCurrentGif({
                        name: "",
                        source: ""
                    })
                }, 3000)
                const newReward = rewardsOriginal + 20;
                console.log(newReward, rewardsOriginal, "helloworld")

            } else if (gifPoints === 25) {
                setCurrentGif({
                    name: "plus_twenty_five",
                    source: plus_twenty_five
                })
                setTimeout(() => {
                    setCurrentGif({
                        name: "",
                        source: ""
                    })
                }, 3000)
                const newReward = rewardsOriginal + 25;
                console.log(newReward, rewardsOriginal, "helloworld")

            } else if (gifPoints === 30) {
                setCurrentGif({
                    name: "plus_thirty",
                    source: plus_thirty
                })
                setTimeout(() => {
                    setCurrentGif({
                        name: "",
                        source: ""
                    })
                }, 3000)
                const newReward = rewardsOriginal + 30;
                console.log(newReward, rewardsOriginal, "helloworld")

            } else if (gifPoints === 50) {
                setCurrentGif({
                    name: "plus_thirty",
                    source: plus_thirty
                })
                setTimeout(() => {
                    setCurrentGif({
                        name: "",
                        source: ""
                    })
                }, 3000)
                const newReward = rewardsOriginal + 50;
                console.log(newReward, rewardsOriginal, "helloworld")

            }
        }
    }, [gifPoints])



    return (
        <>
            {
                currentGif.name && currentGif.source && (
                    <Gifcomponent src={currentGif.source} />
                )
            }
            <Outlet />
        </>
    )
}

export default AudioManager