import React, { useCallback, useContext, useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";
import { Link, useNavigate } from 'react-router-dom';
import { useLanguage } from '../Providers/LanguageContext';
import { UserContext } from '../Providers/UserWrapper';
import { useUserData } from '../Providers/UserWrapper';
import { PlaidLink, usePlaidLink } from "react-plaid-link";
import HttpClient from "../utils/HttpClient";


function CustomFormButtons({ currModalClicked, closeModal, setIntgModalOpen }) {
  const { language } = useLanguage();
  const { setShow } = useContext(UserContext)
  const navigate = useNavigate();
  const { profileData, isMainUserMemo, setIsLoading, isBookKeeper, isInvestor } = useUserData();

  // for integration manager
  // ******************plaid get token***********************************
  const [token, setToken] = useState(null);
  const [isIntegration, setIsintregation] = useState(false)
  const onSuccess = useCallback(async (publicToken) => {
    // console.log("publicToken", publicToken)

    // return
    const sendData = {
      publicToken: publicToken
    }
    setIsLoading(true);
    const res = await HttpClient.requestData("update-bank-access-token", "POST", sendData)
    if (res && res?.status) {
      navigate('/dashboard-manager', { state: { slideNo: 5 } });
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  }, [token]);


  // console.log("profileDatabb", profileData)

  const onExit = useCallback(() => {
    navigate('/manager');
    closeModal()
  }, [])

  const config = {
    token,
    onSuccess,
    // onEvent
    onExit
  };

  const { open, ready } = usePlaidLink(config);

  const createLinkToken = React.useCallback(async () => {
    setIsLoading(true);
    const res = await HttpClient.requestData("create-link-token", "GET");
    if (res && res?.status) {
      setToken(res?.data?.link_token);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  }, [setToken]);

  useEffect(() => {
    if (isIntegration) {
      if (token == null) {
        console.log("connectMy", ready)
        createLinkToken();
      }
      // if (ready) {
      open();
      // }
    }
  }, [token, ready, isIntegration]);
  // ******************plaid get token end***********************************



  const linksrender = useCallback(() => {
    let jsxtoreturn;
    switch (currModalClicked) {

      case language.task_manager:
        jsxtoreturn = (
          <>
            <h6
              style={{
                color: "#98989",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {language.select_option}
            </h6>
            <Link
              to="/connectCalender"
              style={{
                textDecoration: "none",
                color: "black",
                display: "flex",
                justifyContent: "center",
                marginBottom: "3px",
                marginTop: "30px",
              }}
            >
              <button className="customBtn">
                {language.connect_my_calendar}
              </button>
            </Link>
            <Link
              to="/eventsCalender"
              style={{
                textDecoration: "none",
                color: "black",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <button className="customBtn">{language.task_event_manage}</button>
            </Link>
            <Link
              // to="/editexistingrevenue"
              style={{
                textDecoration: "none",
                color: "black",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <button className="customBtn">{language.productivity_intelligence}</button>
            </Link>

          </>
        );
        break;


      case language.revenew_manager:
        jsxtoreturn = (
          <>
            <h6
              style={{
                color: "#98989",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {language.select_option}
            </h6>
            <Link
              to="/postnewincome"
              style={{
                textDecoration: "none",
                color: "black",
                display: "flex",
                justifyContent: "center",
                marginBottom: "3px",
                marginTop: "30px",
              }}
            >
              <button className="customBtn">
                {profileData && profileData.userType === "Business"
                  ? language.post_new_revenue
                  : language.post_new_income}
              </button>
            </Link>
            <Link
              to="/editexistingrevenue"
              style={{
                textDecoration: "none",
                color: "black",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <button className="customBtn">{language.view_edit}</button>
            </Link>
          </>
        );
        break;

      case language.income_manager:
        jsxtoreturn = (
          <>
            <h6
              style={{
                color: "#98989",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {language.select_option}
            </h6>
            <Link
              to="/postnewincome"
              style={{
                textDecoration: "none",
                color: "black",
                display: "flex",
                justifyContent: "center",
                marginBottom: "3px",
                marginTop: "30px",
              }}
            >
              <button className="customBtn">{language.post_new_income}</button>
            </Link>
            <Link
              to="/editexistingrevenue"
              style={{
                textDecoration: "none",
                color: "black",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <button className="customBtn">{language.view_edit}</button>
            </Link>
          </>
        );
        break;

      case language.expense_manager:
        jsxtoreturn = (
          <>
            <h6
              style={{
                color: "#98989",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {language.select_option}
            </h6>
            <Link
              to="/newexpense"
              style={{
                textDecoration: "none",
                color: "black",
                display: "flex",
                justifyContent: "center",
                marginBottom: "3px",
                marginTop: "30px",
              }}
            >
              <button className="customBtn">{language.post_new_expense}</button>
            </Link>
            <Link
              to="/viewexpensedetails"
              style={{
                textDecoration: "none",
                color: "black",
                display: "flex",
                justifyContent: "center",
                marginBottom: "3px",
              }}
            >
              <button className="customBtn">
                {language.view_expense_details}
              </button>
            </Link>
            <Link
              to="/edit-expense-details"
              style={{
                textDecoration: "none",
                color: "black",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <button className="customBtn">{language.edit_exp_detail}</button>
            </Link>
          </>
        );
        break;

      case language.budget_manager:
        jsxtoreturn = (
          <>
            <Link
              to="/budget-tracker"
              style={{
                textDecoration: "none",
                color: "black",
                display: "flex",
                justifyContent: "center",
                marginBottom: "40px",
                marginTop: "0px",
              }}
            >
              <button className="customBtn">{language.setup_budget}</button>
            </Link>
          </>
        );
        break;

      case language.invoice_manager:
        jsxtoreturn = (
          <>
            <h6
              style={{
                color: "#98989",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {language.select_option}
            </h6>
            <Link
              to="/create-invoice"
              style={{
                textDecoration: "none",
                color: "black",
                display: "flex",
                justifyContent: "center",
                marginBottom: "3px",
                marginTop: "30px",
              }}
            >
              <button className="customBtn">
                {language.create_send_invoice}
              </button>
            </Link>
            <Link
              to="/view-invoice"
              style={{
                textDecoration: "none",
                color: "black",
                display: "flex",
                justifyContent: "center",
                marginBottom: "3px",
              }}
            >
              <button className="customBtn">{language.view_invoice}</button>
            </Link>
          </>
        );
        break;

      case language.project_manager:
        jsxtoreturn = (
          <>
            <h6
              style={{
                color: "#98989",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {language.select_option}
            </h6>
            <Link
              to="/project-budget-setup"
              style={{
                textDecoration: "none",
                color: "black",
                display: "flex",
                justifyContent: "center",
                marginBottom: "3px",
                marginTop: "30px",
              }}
            >
              <button className="customBtn">{language.setup_pro_budget}</button>
            </Link>
            <Link
              to="/project-performance"
              style={{
                textDecoration: "none",
                color: "black",
                display: "flex",
                justifyContent: "center",
                marginBottom: "3px",
              }}
            >
              <button className="customBtn">
                {language.view_pro_performance}
              </button>
            </Link>
          </>
        );
        break;

      case language.dashboard_manager:
        jsxtoreturn = (
          <>
            <Link
              to="/dashboard-manager"
              style={{
                textDecoration: "none",
                color: "black",
                display: "flex",
                justifyContent: "center",
                marginBottom: "40px",
                marginTop: "0px",
              }}
            >
              <button className="customBtn">{language.view_dashboard}</button>
            </Link>
          </>
        );
        break;

      case language.book_keeper_manager:
        jsxtoreturn = (
          <>
            <h6
              style={{
                color: "#98989",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {language.select_option}
            </h6>
            {
              isBookKeeper ?
                <>
                  <Link
                    // to="/bookkeper"
                    to="/link-manage-account"
                    style={{
                      textDecoration: "none",
                      color: "black",
                      display: "flex",
                      justifyContent: "center",
                      marginBottom: "3px",
                      marginTop: "30px",
                    }}
                  >
                    <button className="customBtn">
                      {language.link_account}
                    </button>
                  </Link>

                  <Link
                    to="/link-manage-account"
                    state={{ slideNo: 1 }}
                    style={{
                      textDecoration: "none",
                      color: "black",
                      display: "flex",
                      justifyContent: "center",
                      marginBottom: "3px",
                    }}
                  >
                    <button className="customBtn">
                      {language.view_manage_docs}
                    </button>
                  </Link>

                  <span
                    onClick={() => navigate("/link-manage-account", { state: { slideNo: 1 } })}
                    // to="/dashboard-manager"
                    style={{
                      textDecoration: "none",
                      color: "black",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <button className="customBtn">
                      {language.view_tax_deductible}
                    </button>
                  </span>
                </>
                :
                <>
                  {
                    profileData?.accountType === "Bookkeeper"
                      ?
                      <Link
                        to="/bookkeper-subuser"
                        style={{
                          textDecoration: "none",
                          color: "black",
                          display: "flex",
                          justifyContent: "center",
                          marginBottom: "3px",
                          marginTop: "30px",
                        }}
                      >
                        <button className="customBtn">
                          Manage Accounts
                        </button>
                      </Link>
                      :
                      <Link
                        to="/bookkeper"
                        style={{
                          textDecoration: "none",
                          color: "black",
                          display: "flex",
                          justifyContent: "center",
                          marginBottom: "3px",
                          marginTop: "30px",
                        }}
                      >
                        <button className="customBtn">
                          Add Virtual Bookkeeper
                        </button>
                      </Link>
                  }

                  <Link
                    to="/bookKeperDocs"
                    style={{
                      textDecoration: "none",
                      color: "black",
                      display: "flex",
                      justifyContent: "center",
                      marginBottom: "3px",
                    }}
                  >
                    <button className="customBtn">
                      View & Upload Docs
                    </button>
                  </Link>
                  <Link
                    to="/dashboard-manager"
                    state={{ slideNo: 7 }}
                    style={{
                      textDecoration: "none",
                      color: "black",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <button className="customBtn">
                      View Tax Deductibles
                    </button>
                  </Link>
                </>
            }
          </>
        );
        break;

      case language.Investment_Manager:
        jsxtoreturn = (
          <>
            <h6
              style={{
                color: "#98989",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {language.select_option}
            </h6>
            {
              isInvestor ?
                <>
                  <Link
                    // to="/bookkeper"
                    to="/link-manage-investment"
                    style={{
                      textDecoration: "none",
                      color: "black",
                      display: "flex",
                      justifyContent: "center",
                      marginBottom: "3px",
                      marginTop: "30px",
                    }}
                  >
                    <button className="customBtn">
                    {language.link_investment}
                    </button>
                  </Link>

                  <Link
                    to="/link-manage-investment"
                    state={{ slideNo: 1 }}
                    style={{
                      textDecoration: "none",
                      color: "black",
                      display: "flex",
                      justifyContent: "center",
                      marginBottom: "3px",
                    }}
                  >
                    <button className="customBtn">
                    {language.view_manage_docs}
                    </button>
                  </Link>

                  {/* <Link
                    to="/dashboard-manager"
                    state={{ slideNo: 7 }}
                    style={{
                      textDecoration: "none",
                      color: "black",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <button className="customBtn">
                      View Investment Dashboards
                    </button>
                  </Link> */}

                  <Link
                    to="/investment-options"
                    style={{
                      textDecoration: "none",
                      color: "black",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <button className="customBtn">
                      {language.investment_option}
                    </button>
                  </Link>
                </>
                :
                <>
                  {
                    profileData?.accountType === "Bookkeeper"
                      ?
                      <Link
                        to="/bookkeper-subuser"
                        style={{
                          textDecoration: "none",
                          color: "black",
                          display: "flex",
                          justifyContent: "center",
                          marginBottom: "3px",
                          marginTop: "30px",
                        }}
                      >
                        <button className="customBtn">
                          Manage Accounts
                        </button>
                      </Link>
                      :
                      <Link
                        to="/raise-capital"
                        style={{
                          textDecoration: "none",
                          color: "black",
                          display: "flex",
                          justifyContent: "center",
                          marginBottom: "3px",
                          marginTop: "30px",
                        }}
                      >
                        <button className="customBtn">
                          Raise Capital
                        </button>
                      </Link>
                  }

                  <Link
                    to="/view-investor-document"
                    style={{
                      textDecoration: "none",
                      color: "black",
                      display: "flex",
                      justifyContent: "center",
                      marginBottom: "3px",
                    }}
                  >
                    <button className="customBtn">
                      View & Upload Docs
                    </button>
                  </Link>
                  <Link
                    to="/view-capital-option"
                    // state={{ slideNo: 7 }}
                    style={{
                      textDecoration: "none",
                      color: "black",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <button className="customBtn">
                      View Capital Options
                    </button>
                  </Link>
                </>
            }
          </>
        );
        break;

      case language.Document_Manager:
        jsxtoreturn = (
          <>
            <h6
              style={{
                color: "#98989",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {language.select_option}
            </h6>

            <>
              <Link
                to="/document-manager"
                state={{ slideNo: 0 }}
                style={{
                  textDecoration: "none",
                  color: "black",
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "3px",
                  marginTop: "30px",
                }}
              >
                <button className="customBtn">
                  Import Documents
                </button>
              </Link>

              <Link
                to="/document-manager"
                state={{ slideNo: 1 }}
                style={{
                  textDecoration: "none",
                  color: "black",
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "3px",
                }}
              >
                <button className="customBtn">
                  View Document Vault
                </button>
              </Link>

              <Link
                to="/create-send-docs"
                state={{ slideNo: 7 }}
                style={{
                  textDecoration: "none",
                  color: "black",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <button className="customBtn">
                  Create & Send Docs
                </button>
              </Link>

            </>
          </>
        );
        break;


      case language.integration_manager:
        jsxtoreturn = (
          <>
            <h6
              style={{
                color: "#98989",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {language.select_option}
            </h6>
            <a
              // to="/integration"
              onClick={() => {
                setIsintregation(true);
                // closeModal();
              }}
              style={{
                textDecoration: "none",
                color: "black",
                display: "flex",
                justifyContent: "center",
                marginBottom: "3px",
                marginTop: "30px",
              }}
            >
              <button className="customBtn">
                {language.connect_my_account}
              </button>
            </a>
            <a
              // to="/dashboard-manager"
              onClick={() => navigate('/dashboard-manager', { state: { slideNo: 5 } })}
              // onClick={() => {
              //   setIntgModalOpen(true);
              //   closeModal();
              // }}
              style={{
                textDecoration: "none",
                color: "black",
                display: "flex",
                justifyContent: "center",
                marginBottom: "3px",
              }}
            >
              <button className="customBtn">{language.view_all_balance}</button>
            </a>
            <a
              // to="/dashboard-manager"
              onClick={() => navigate('/dashboard-manager', { state: { slideNo: 6 } })}
              style={{
                textDecoration: "none",
                color: "black",
                display: "flex",
                justifyContent: "center",
                marginBottom: "3px",
              }}
            >
              <button className="customBtn">{language.view_all_transaction}</button>
            </a>
          </>
        );
        break;

      case language.reward_manager:
        jsxtoreturn = (
          <>
            <Link
              to="/achievements"
              style={{
                textDecoration: "none",
                color: "black",
                display: "flex",
                justifyContent: "center",
                marginBottom: "40px",
                marginTop: "0px",
              }}
            >
              <button className="customBtn">{language.view_achieve}</button>
            </Link>
          </>
        );
        break;

      case language.cipbits_manager:
        jsxtoreturn = (
          <>
            <Link
              to="/news"
              style={{
                textDecoration: "none",
                color: "black",
                display: "flex",
                justifyContent: "center",
                marginBottom: "40px",
                marginTop: "0px",
              }}
            >
              <button className="customBtn">{language.view_news}</button>
            </Link>
          </>
        );
        break;

      case language.user_manager:
        jsxtoreturn = (
          <>
            {isMainUserMemo && (
              <h6
                style={{
                  color: "#98989",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {language.select_option}
              </h6>
            )}
            <Link
              to="/configuration"
              style={
                isMainUserMemo
                  ? {
                    textDecoration: "none",
                    color: "black",
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: "3px",
                    marginTop: "30px",
                  }
                  : {
                    textDecoration: "none",
                    color: "black",
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: "40px",
                    marginTop: "0px",
                  }
              }
            >
              <button className="customBtn">
                {language.share_account_code}
              </button>
            </Link>
            {isMainUserMemo && (
              <Link
                to="/users"
                style={{
                  textDecoration: "none",
                  color: "black",
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "3px",
                }}
              >
                <button className="customBtn">{language.manage_users}</button>
              </Link>
            )}
          </>
        );
        break;

      case language.reports_manager:
        jsxtoreturn = (
          <>
            <h6
              style={{
                color: "#98989",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {language.select_option}
            </h6>
            <Link
              to="/buildReport"
              style={{
                textDecoration: "none",
                color: "black",
                display: "flex",
                justifyContent: "center",
                marginBottom: "3px",
                marginTop: "30px",
              }}
            >
              <button className="customBtn">{language.build_report}</button>
            </Link>
            <Link
              to="/view_reports"
              style={{
                textDecoration: "none",
                color: "black",
                display: "flex",
                justifyContent: "center",
                marginBottom: "3px",
              }}
            >
              <button className="customBtn">{language.view_reports}</button>
            </Link>

            {/* <Link to="/view_reports"
              style={{ textDecoration: "none", color: "black", display: "flex", justifyContent: "center", marginBottom: '40px', marginTop: "0px" }}>
              <button className='customBtn'>
                {language.view_reports}
              </button>
            </Link> */}
          </>
        );
        break;

      case language.setting_manager:
        jsxtoreturn = (
          <>
            <div
              // to="/postnewincome"
              onClick={() => setShow(true)}
              style={{
                textDecoration: "none",
                color: "black",
                display: "flex",
                justifyContent: "center",
                marginBottom: "40px",
                marginTop: "0px",
              }}
            >
              <button className="customBtn">{language.view_setting}</button>
            </div>
          </>
        );
        break;

      case language.resource_manager:
        jsxtoreturn = (
          <>
            <Link
              to="/resource"
              style={{
                textDecoration: "none",
                color: "black",
                display: "flex",
                justifyContent: "center",
                marginBottom: "40px",
                marginTop: "0px",
              }}
            >
              <button className="customBtn">
                {language.view_additional_resource}
              </button>
            </Link>
          </>
        );
        break;

      default:
        jsxtoreturn = "";
    }
    return jsxtoreturn;
  }, [currModalClicked]);

  return (
    <div>
      <span className="d-flex justify-content-end">
        <AiOutlineClose
          className="icon"
          onClick={closeModal}
          size={20}
          color="gray"
        />
      </span>
      {/* <h6 style={{ color: "#98989" }}>{language.select_option}</h6> */}
      <div>{linksrender()}</div>
    </div>
  );
}

export default CustomFormButtons;
