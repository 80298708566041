import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useLanguage } from '../Providers/LanguageContext'

function Userlist({ users }) {
    const navigate = useNavigate()
    const { language } = useLanguage()
    console.log('ListUser', users)
    return (

        <>
            <div id='userlist'>
                <div>
                    {
                        users && users.map((u, i) => (
                            <div className='list-box'>
                                <div className='list-left'>
                                    <span className={u.isDisable ? "red-btn" : "green-btn"}></span>
                                    <h4>{u.name && u.surName ? u.name + ' ' + u.surName : u.email}</h4>
                                </div>
                                <div className='list-right' onClick={() => navigate("/userform", { state: u })}>
                                    <a className='edit-btn'>{language.edit}</a>
                                </div>
                            </div>
                        ))
                    }
                    {/*           
            <div className='list-box'>
                <div className='list-left'>
                    <span className='red-btn'></span>
                    <h4>Nombre</h4>
                </div>
                <div className='list-right'>
                    <a href='#' className='edit-btn'>Editar</a>
                </div>
            </div> */}
                </div>
            </div>

        </>

    )
}

export default Userlist