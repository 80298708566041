import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import HttpClient from '../../utils/HttpClient';

function GoogleCalendarConnect() {

    const { search } = useLocation();
    const convertQueryParamsStringToQueryParamsObject = (string) => {
        let queryParams = {};
        // remove first letter from query string
        string = string.substring(1);
        // console.log("string", string);
        // convert string to array
        const queryParamsArray = string.split("&");
        // console.log("queryParamsArray", queryParamsArray);

        // covert each index query to object
        queryParamsArray.forEach((queryParam) => {
            // console.log("queryParam", queryParam);
            // split param by =
            const queryParamArray = queryParam.split("=");
            // console.log("queryParamArray", queryParamArray);
            queryParams[queryParamArray[0]] = queryParamArray[1];
            // console.log("queryParams", queryParams);
        });
        return queryParams;
    };

    const getGoogleEvent = async () => {
        const queryParamData = convertQueryParamsStringToQueryParamsObject(search)
        console.log("queryParamData",);
        const decodedValue = JSON.parse(decodeURIComponent(decodeURIComponent(queryParamData.state)))
        console.log("decodedValue", decodedValue)


        if (decodedValue.type == 'google') {
            const res = await HttpClient.requestData(`google-auth/callback?code=${queryParamData?.code}&state=${queryParamData?.state}`, "GET")
        } else if (decodedValue.type == 'outlook') {
            const res = await HttpClient.requestData(`outlook-auth/callback?code=${queryParamData?.code}&state=${queryParamData?.state}`, "GET")
        }


        if (/Android/i.test(navigator.userAgent)) {
            window.location.href = "fisibility://GoogleCalendarConnect"
        } else if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
            // alert("ios")
        } else {
            // alert("other")
            window.location.href = "https://fisibility.com/eventsCalender"
        }
        var timeout = setTimeout(function () {
            window.location.href = "https://fisibility.com/eventsCalender";
        }, 1000); // Adjust timeout as needed

        window.addEventListener("blur", function () {
            clearTimeout(timeout);
        });

    }

    useEffect(() => {
        if (search) {
            getGoogleEvent()
        }
    }, [search])
    return (
        <div></div>
    )
}

export default GoogleCalendarConnect