import React, { useEffect, useState } from 'react'
import ReactApexChart from 'react-apexcharts'
import { useLanguage } from '../../../Providers/LanguageContext'

function DashboardFivePieChart({ data }) {
    const { language } = useLanguage()

    const [series, setSeries] = useState([Math.round(data[0]), Math.round(data[1])])

    const [options, setOptions] = useState({
        chart: {
            type: 'donut',
        },
        plotOptions: {
            pie: {
                customScale: 1,
            }
        },
        labels: [language.currentAr, language.pastdueAr],
        colors: [
            "#454747",
            "#7e8485"
        ],
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    height: 150,
                    width: '100%',
                },
                legend: {
                    position: 'bottom'
                }
            }
        }],
        legend: {
            show: true,
            showForSingleSeries: false,
            showForNullSeries: true,
            showForZeroSeries: true,
        }
    }
    )

    useEffect(() => {
        if (data.length > 0) {
            setSeries([Math.round(data[0]), Math.round(data[1])])
        }
    }, [data])

    return (
        <>
            {
                data && data[0] == 0 && data[1] == 0 && (
                    <h5 className='text-center mt-3'>{language.no_data}</h5>
                )
            }
            <ReactApexChart options={options} width={"100%"} height={300} series={series} type="donut" />

        </>
    )
}

export default DashboardFivePieChart