import React from "react";
import a from "../Images/9-NoMoreUncertainty.png";
export default function Uncertain({ goToRegistration }) {
  return (


    <div className="inner">
      <div className="Uncertain">
        <div className="top">
          <h3>No More Uncertainty!</h3>
          <span>We Understand Your Concerns</span>
        </div>
        <div className="SpreadSheet Uncertainimg mt-4">
          <div className="lftImg ">
            <img src={a} alt="" />
          </div>
          <div className="rgtCnt">
            <div className="bottom_cnt">
              <div className="wrapCnt">
                <h5 style={{ color: "#4FACFF" }}>
                  ...and this is why Fisibility is providing Administration
                  Professionals with:
                </h5>
                <ul>
                  <li>Freemium License Access – instant value</li>
                  <li>No Contract Required – risk free</li>
                  <li>No Hidden Fees – total transparency</li>
                  <li>Free Mobile App Access – staff collaboration</li>
                  <li>AES 256-bit Encryption – safe & secure</li>
                  <li>Access To Product Specialist – just in case</li>
                  <li>Lowest Per User Pricing – you’re worth it</li>
                  <li>Easy Setup – personalize, ignite, engage</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <a onClick={() => goToRegistration()} className="signupBtn mb-3">
          LET’S GO!
        </a>
      </div>
    </div>

  );
}
