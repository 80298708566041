import React, { useCallback } from 'react';
import './IntegrationBankDeailModal.css'
import { useLanguage } from '../Providers/LanguageContext';

const IntegrationBankDeailModal = ({ closeModal, filteredData }) => {
    const { language, currentLanguage } = useLanguage();

    console.log("filteredDataModal", filteredData)

    // retuns credit amt(if amt is + returns amt or returns "-")
    const creditAmmount = useCallback((amt) => {
        if (amt > 0) {
            return "$" + amt
        } else {
            return "-"
        }
    }, [])

    // retuns Debit amt(if amt is - returns amt or returns "-")
    const debitAmmount = useCallback((amt) => {
        if (amt < 0) {
            return "$" + -amt
        } else {
            return "-"
        }
    }, [])

    // returns total debei
    const totalDabit = () => {
        const sum = filteredData?.reduce((acc, cur) => {
            if (cur?.amount < 0) {
                return acc + cur?.amount
            } else {
                return acc
            }
        }, 0)
        return -sum?.toFixed(2);
    }

    //returns total credit 
    const totalCredit = () => {
        const sum = filteredData?.reduce((acc, cur) => {
            if (cur?.amount >= 0) {
                return acc + cur?.amount
            } else {
                return acc
            }
        }, 0)
        return sum?.toFixed(2)
    }


    return (
        <div className='int-modal-heat' onClick={(e) => e.stopPropagation()}>
            <div className='int-modal-body'>
                <a href='#' className='intclose' onClick={() => closeModal()}>
                    <i class="fa-solid fa-rectangle-xmark"></i>
                </a>
                <div className='mt-3'>
                    <div className="dashSixTableDiv mb-5">
                        <table className="dashSixTable">
                            <thead className="mb-4">
                                <th className="dashSixTableHead">{language.bank_name}</th>
                                {/* <th className="dashSixTableHead">{language.current_avail_balance}</th> */}
                                {/* <th className="dashSixTableHead">Marchent Name</th> */}
                                <th className="dashSixTableHead">{language.account_type}</th>
                                <th className="dashSixTableHead">{language.current_debits}</th>
                                <th className="dashSixTableHead">{language.crrent_credits}</th>
                                {/* <th className="dashSixTableHead">Details</th> */}
                            </thead>

                            <tbody>
                                {
                                    filteredData && filteredData?.map((item, i) =>
                                        <tr className="dashSixTableRow" >
                                            <td className="dashSixTableData">{item?.institutionName}</td>
                                            {/* <td className="dashSixTableData">{item?.name}</td> */}
                                            <td className="dashSixTableData">{item?.accountType}</td>
                                            <td className="dashSixTableData">{debitAmmount(item?.amount)}</td>
                                            <td className="dashSixTableData">{creditAmmount(item?.amount)}</td>


                                        </tr>
                                    )

                                }
                            </tbody><br />

                            {/* total */}
                            <tfoot className="dashSixTableFoot">
                                <tr className="">
                                    <td className="dashSixTableConcludeData">{language.total?.toUpperCase()}:</td>
                                    <td className="dashSixTableConcludeData">{filteredData?.length}</td>
                                    <td className="dashSixTableConcludeData">
                                        {totalDabit()}
                                    </td>
                                    {/* <td className="dashSixTableConcludeData">-</td> */}
                                    <td className="dashSixTableConcludeData">
                                        {totalCredit()}
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default IntegrationBankDeailModal
