import React from "react";
import big from "../Images/1-MVP.png";
export default function WorkPlace({ goToRegistration }) {
  return (
    <div className="inner">
      <div className="fst_slide">
        <div className="big_img" style={{ backgroundImage: `url('${big}')` }}>
          <img src={big} alt=""></img>
        </div>
        <div className="bottom_cnt mb-2">
          <h4 className="mt-2">
            Unlock MVP Status In Your Workplace, Stress Free!
          </h4>
          <p>
            Finally there’s a way to seamlessly manage tasks and finances in one
            place, eliminate stress, and save time.
          </p>
          <h5>
            Administration Professionals & Office Managers Join Fisibility Risk
            Free!
          </h5>
          <a className="signupBtn" onClick={() => goToRegistration()} >
            Sign me up!
          </a>
        </div>
      </div>
    </div>
  );
}
