import React, { useCallback, useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import TimeFrameTabs from '../../../Component/TimeFrameTabs'
import toast from 'react-hot-toast';
import MyVerticallyCenteredModal from '../../../Component/ModalComp';
import { ListGroup } from 'react-bootstrap';
import moment from 'moment';
import CustomAccordion from '../../../Component/CustomAccordion';
import HttpClient from '../../../utils/HttpClient';
import TimeFrameComp from '../../../Component/TimeFrameComp';

function NewSalesRevenue() {
    const [modalShow, setModalShow] = useState(false);
    const [values, setValues] = useState({
        dayType: "day",
        price: "",
        customerName: "",
        type: "",
        transactionDate: moment(new Date(Date.now())).format("YYYY-MM-DD"),
        userType: "Bussiness",
        salesCategory: "",
        customerCredit: "",
        projectName: ""
    })
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })
    const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
    const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })

    const onChangeNav = (activeKey) => {
        setValues(prev => ({ ...prev, dayType: activeKey }))
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const { price, type, customerName } = values;
        if (!price) {
            toast.error("Enter sale price");
            return;
        }
        if (!type) {
            toast.error("Select sale type (cash or digital)");
            return;
        }
        if (!customerName) {
            toast.error("Please enter customer name");
            return;
        }
        setModalShow(true)
        console.log(values)
    }

    const postSales = async () => {
        const res = await HttpClient.requestData("add-sales", "POST", values);
        if (res && res.status) {
            toast.success("The sale has been posted successfully")
            setValues({
                dayType: "",
                price: "",
                customerName: "",
                type: "",
                transactionDate: moment(new Date(Date.now())).format("YYYY-MM-DD"),
                userType: "Bussiness",
                salesCategory: "internet",
                customerCredit: "0",
                projectName: ""
            })
            setModalShow(false)
        } else {
            toast.error("Could not post sales")
        }
    }

    return (
        <div>
            {/* <TimeFrameComp getTime={onChangeNav} value={values.dayType} /> */}
            <TimeFrameTabs onChangeNav={onChangeNav} />
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <form style={{ width: (isDesktopOrLaptop) ? "450px" : "300px", margin: "25px" }} onSubmit={handleSubmit}>
                    <h3>Sales</h3>
                    <div className="form-group">
                        <label for="exampleInputEmail1">Enter sales amount</label>
                        <input type="number"
                            value={values.price}
                            onChange={(e) => setValues(prev => ({ ...prev, price: e.target.value }))}
                            class="form-control" id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            placeholder="Enter sales amount" />
                    </div>
                    <div class="form-group">
                        <button type='button' className='btn bg-dark text-light mr-2'
                            disabled={values.type === "cash"}
                            onClick={() => {
                                setValues(prev => ({ ...prev, type: "cash" }))
                                toast.success("Selected cash type")
                            }}>Cash</button>
                        <button type='button'
                            disabled={values.type === "digital"}
                            className='btn bg-dark text-light'
                            onClick={() => {
                                setValues(prev => ({ ...prev, type: "digital" }))
                                toast.success("Selected digital type")
                            }}>Digital</button>
                    </div>
                    <div class="form-group">
                        <label for="exampleInputPassword1">Customer name</label>
                        <input
                            type="text"
                            class="form-control"
                            id="exampleInputPassword1"
                            placeholder="Customer name"
                            value={values.customerName}
                            onChange={(e) => setValues(prev => ({ ...prev, customerName: e.target.value }))}
                        />
                    </div>

                    <button type="submit" class="btn btn-primary">Submit</button>
                </form>
            </div>
            <MyVerticallyCenteredModal
                show={modalShow}
                title="Confirmation"
                postSales={postSales}
                onHide={() => setModalShow(false)}
            >
                <ConfirmPageData values={values} setValues={setValues} />
            </MyVerticallyCenteredModal>
        </div>
    )
}


function ConfirmPageData({ values, setValues }) {

    const [projects, setProjects] = useState([]);

    const getProjects = useCallback(async () => {
        const res = await HttpClient.requestData("projectname", "GET");
        if (res && res.status) {
            setProjects(res.data)
        } else {
            console.log(res, "error")
        }
    }, [])

    useEffect(() => {
        getProjects()
    }, [])
    return (
        <ListGroup style={{ fontWeight: "500" }}>
            <ListGroup.Item style={{ backgroundColor: "lightgrey" }}>Sales : {values.price || ""}</ListGroup.Item>
            <ListGroup.Item style={{ backgroundColor: "lightgrey" }}>Customer Name : {values.customerName || ""}</ListGroup.Item>
            <ListGroup.Item style={{ backgroundColor: "lightgrey" }}>Current Date : {moment(new Date(Date.now())).format("MMMM Do YYYY")}</ListGroup.Item>
            <ListGroup.Item style={{ backgroundColor: "lightgrey" }}>
                <label htmlFor="datebox" className='mr-2'>Choose Date</label>
                <input type="date" name="datebox" id="datebox" value={values.transactionDate} onChange={(e) => {
                    setValues(prev => ({ ...prev, transactionDate: e.target.value }))
                }} />
            </ListGroup.Item>
            <ListGroup.Item style={{ backgroundColor: "lightgrey" }}>Type : {values.type}</ListGroup.Item>
            <ListGroup.Item style={{ backgroundColor: "lightgrey" }}>
                <select className="form-select" aria-label="Default select example" onChange={(e) => {
                    if (e.target.value) {
                        setValues(prev => ({ ...prev, projectName: e.target.value }))
                    }
                }}>
                    <option>Select project</option>
                    {

                    }
                </select>
            </ListGroup.Item>
            <ListGroup.Item style={{ backgroundColor: "lightgrey" }}>
                <CustomAccordion values={values} setValues={setValues} data={[{ title: "Sales category", value: "Sales category", field: "salesCategory" }]} />
            </ListGroup.Item>
            <ListGroup.Item style={{ backgroundColor: "lightgrey" }}>
                <CustomAccordion values={values} setValues={setValues} data={[{ title: "Customer credit", value: "Customer credit", field: "customerCredit" }]} />
            </ListGroup.Item>
        </ListGroup>
    )
}

export default NewSalesRevenue