import React, { useEffect, useMemo, useState } from 'react'
import { useLanguage } from '../../../../Providers/LanguageContext';
import toast from 'react-hot-toast';
import HttpClient from '../../../../utils/HttpClient';
import { useUserData } from '../../../../Providers/UserWrapper';
import CustomModal from '../../../../Component/CustomModal';
import { AiOutlineClose } from 'react-icons/ai';
import { currencies } from "../../currencies";
import CustomLoaderLine from '../../../../Component/Loader/CustomLoaderLine';
import { IoCheckmarkCircleSharp } from "react-icons/io5";
import { MdDelete } from 'react-icons/md';
import { CgCalendarDates } from "react-icons/cg";


import "react-datetime/css/react-datetime.css";
import Datetime from "react-datetime";
import moment from 'moment';


const today = new Date()

const SatNormalForm = ({ getTime, value, setInvType }) => {
    const { language, currentLanguage } = useLanguage();
    const { setIsLoading, profileData } = useUserData();

    const [isInvoiceName, setIsInvoiceName] = useState(false);
    const [isSave, setIsSave] = useState(false);
    const [searchData, setSearchData] = useState([]);
    const [debouncing, setDebouncing] = useState("");

    const [prodServicekey, setProdServiceKey] = useState("");
    const [prodServData, setProdServData] = useState([]);
    const [isProdServKey, setIsProServKey] = useState("");
    const [prodServiceLoading, setProdServiceLoad] = useState(false)

    const [unitKey, setUnitKey] = useState("");
    const [unitKeyData, setUnitKeyData] = useState([]);
    const [isUnitKey, setIsUnitKey] = useState("");
    const [unitKeyLoading, setUnitKeyLoading] = useState(false);

    const [documentData, setDocumentData] = useState({ cerFile: "", keyFile: "", keyPass: "" });
    const [satDocData, setSatDocData] = useState({});
    const [docLoading, setDocLoading] = useState(false);
    const [isSaveDoc, setISSavedoc] = useState(false);
    const [isFetchDoc, setIsFetchDoc] = useState(false);

    console.log("isFetchDoc", isFetchDoc);

    // other inputs
    const initValue = {
        RFCIssuer: "",
        IssuerName: "",
        Follo: "",
        RFCReceiver: "",
        RecipientName: "",
        CFDIuse: "",
        TaxFolio: "",
        CSDSerialNo: "",
        Series: "A",
        PostcardDateAndTimeOfIssue: today.toISOString(),
        VoucherEffect: "",
        TaxRegime: "",
        email: "",

        Tax: "",
        Type: "",
        Base: "",
        TypeFactor: "",
        RateorQuota: "",
        RateAmount: "",

        Currency: "MXN",
        WayToPay: "",
        PaymentMethod: "",
        Subtotal: "",
        TransferTaxes: "",
        Total: "",
        fiscalReceptor: "",
        recievingProcecutor: "",
        export: "",
        conditionOfPayment: "",
        taxObject: "",
        fiscalRegimeReciever: ""
    }
    const [formValue, setFormValue] = useState(initValue);
    // console.log("formValuesss", formValue.PostcardDateAndTimeOfIssue)

    const handleFormChange = (e) => {
        const { name, value } = e.target;
        setFormValue(prev => ({ ...prev, [name]: value }))
    }
    // end other inputs


    //***add more start***
    const initAddMore = {
        ProductAndOrServiceKey: "",
        NoIdentification: "",
        quantity: "",
        UnitKey: "",
        Unit: "",
        UnitValue: "",
        importe: "",
        // Discount: "",
        // PendingNo: "",
        // PropertyAccountNo: "",
        ProductAndOrServiceName: "",
        unitKeyName: "",
        taxObject: "",

        // table2
        Description: "",
        Tax: "",
        Type: "",
        Base: "",
        TypeFactor: "",
        RateorQuota: "",
        RateAmount: "",


    }
    const [addMoreData, setAddMoreData] = useState([initAddMore]);
    console.log("addMoreDatasat", addMoreData)

    const validateAddMore = () => {
        let error = false;
        for (let item of addMoreData) {
            error = Object.values(item).every((ele) => ele !== "");
            console.log("error", error)
            if (!error) {
                return false;
            }
        }
        return error
    }

    const handleClickAddMore = (e) => {
        e.preventDefault();
        // console.log("validateAddMore", validateAddMore);
        // if (validateAddMore()) {
        setAddMoreData(prev => ([...prev, initAddMore]));
        // } else {
        //     toast.error(language.please_enter)
        // }
    }

    const handleDeleteAddMore = (e, ind) => {
        e.preventDefault();
        setAddMoreData(prev => prev.filter((item, i) => i !== ind))
    }

    // onChange for add more
    const handleChangeAddMore = (e, ind) => {
        const { name, value } = e.target;
        // console.log("namee", name, value)
        setAddMoreData(prev => prev.map((item, i) => {
            if (i === ind) {
                return { ...item, [name]: value }
            } else {
                return item
            }
        }));

    }
    //***end add more***

    // calculate impuestos
    useEffect(() => {
        const sumImporte = sumFromArrayOfObject(addMoreData, "importe");
        const sumTansferAmm = sumFromArrayOfObject(addMoreData, "RateAmount");

        setFormValue(prev => ({ ...prev, Subtotal: sumImporte, TransferTaxes: sumTansferAmm }));
    }, [addMoreData])

    // calculate impuestos
    useEffect(() => {
        const totalSum = +formValue.Subtotal + +formValue.TransferTaxes
        setFormValue(prev => ({ ...prev, Total: Number(totalSum).toFixed(2) }));
    }, [formValue.Subtotal, formValue.TransferTaxes])

    // sum and percentage calculation function for expense
    const calculateAllDataExp = (changedArr) => {
        const sumImporte = sumFromArrayOfObject(changedArr, "importe");
        console.log("sumImporte", changedArr, sumImporte)
        setFormValue(prev => ({ ...prev, Subtotal: sumImporte }));
    }

    // returns the sum of a key from array of object
    const sumFromArrayOfObject = (arr, key) => {
        const sum = arr.reduce((acc, cur) => acc + Number(cur[key]), 0);
        return sum ? Number(sum)?.toFixed(2) : "";
    }

    // returns the multiplication of to number
    const multiplicationTwoNum = (num1, num2) => {
        const mult = Number(num1) * Number(num2)
        console.log("tofffg", mult.toFixed(2), num1, num2)
        return mult.toFixed(2);
    }

    // document upload
    const handleDocumentSave = async () => {
        let data = new FormData();
        data.append("filecer", documentData.cerFile);
        data.append("filekey", documentData.keyFile);
        data.append("password", documentData.keyPass);

        setDocLoading(true);
        let res = await HttpClient.fileUplode("upload-credentials", "POST", data);
        // console.log("resDoc", res);
        if (res && res?.status) {
            setSatDocData(res?.data);
            setDocLoading(false);
            // setDocumentData({ cerFile: "", keyFile: "", keyPass: "" });
            toast.success(language.document_updated_success);
            setISSavedoc(true);
        } else {
            setDocLoading(false);
            if (res?.message === "Please upload both filekey and filecer") {
                toast.error(language.pls_upload_key_ser_file);
            } else if (res?.message === "Incorrect key password!") {
                toast.error(language.incorrect_key_pass);
            } else if (res?.message === "Failed to get certificate validity date") {
                toast.error(language.fail_get_cer_val_date);
            } else {
                toast.error(res?.message || "error");
            }
        }
    }

    // send invoice
    const handleSend = async (e) => {
        e.preventDefault();
        if (Object.keys(satDocData).length === 0) return toast.error(language.sat_document_required)
        if (!formValue.RFCIssuer) return toast.error(language.rfc_issuer_req)
        if (!formValue.IssuerName) return toast.error(language.isser_name_req)
        // if (!formValue.Follo) return toast.error(language.please_enter)
        if (!formValue.RFCReceiver) return toast.error(language.rfc_reciever_req)
        if (!formValue.RecipientName) return toast.error(language.recipient_name_req)
        if (!formValue.CFDIuse) return toast.error(language.use_CFDI_req)
        if (!formValue.recievingProcecutor) return toast.error(language.receiving_procecutor_req)
        if (!formValue.fiscalRegimeReciever) return toast.error(language.fiscal_regime_reciever_req)
        if (!formValue.export) return toast.error(language.export_is_req)
        // if (!formValue.TaxFolio) return toast.error(language.tax_folio_req)
        // if (!formValue.CSDSerialNo) return toast.error(language.please_enter)
        // if (!formValue.Series) return toast.error(language.series_req)
        // if (!formValue.PostcardDateAndTimeOfIssue) return toast.error(language.please_enter)
        // if (!formValue.VoucherEffect) return toast.error(language.please_enter)
        if (!formValue.TaxRegime) return toast.error(language.tax_regime_req)
        if (!formValue.email) return toast.error(language.email_recipient_req)

        // if (!validateAddMore()) return toast.error(language.please_enter)
        // if (!formValue.Tax) return toast.error(language.please_enter)
        // if (!formValue.Type) return toast.error(language.please_enter)
        // if (!formValue.Base) return toast.error(language.please_enter)
        // if (!formValue.TypeFactor) return toast.error(language.please_enter)
        // if (!formValue.RateorQuota) return toast.error(language.please_enter)
        // if (!formValue.RateAmount) return toast.error(language.please_enter)

        if (!formValue.Currency) return toast.error(language.currency_req)
        if (!formValue.WayToPay) return toast.error(language.way_pay_req)
        if (!formValue.PaymentMethod) return toast.error(language.payment_method_req)
        if (!formValue.conditionOfPayment) return toast.error(language.condition_of_payment_req)

        if (!formValue.Subtotal) return toast.error(language.subtotal_req)
        if (!formValue.TransferTaxes) return toast.error(language.transfer_taxes_req)
        if (!formValue.Total) return toast.error(language.total_req);

        // console.log("satDocData", satDocData)
        const data = {
            "Series": "A",
            "Folio": formValue?.Follo,
            "Date": moment(formValue?.PostcardDateAndTimeOfIssue).format('YYYY-MM-DD[T]hh:mm:ss'),
            "WayToPay": formValue?.WayToPay,
            "NoCertificate": satDocData?.noCertificado,
            "Certificate": satDocData?.certificado,
            "PaymentConditions": formValue?.conditionOfPayment,
            "SubTotal": formValue?.Subtotal,
            "Currency": formValue?.Currency,
            "Total": formValue?.Total,
            "Type": addMoreData?.[0]?.Type,
            "PaymentMethod": formValue?.PaymentMethod,
            "PostalCode": profileData?.cp,
            "Export": formValue?.export,
            "IssuerRFC": formValue?.RFCIssuer,
            "IssuerName": formValue?.IssuerName,
            "TaxRegima": formValue?.TaxRegime,
            "ReceiverRFC": formValue?.RFCReceiver,
            "ReceiverName": formValue?.RecipientName,
            "useCFDI": formValue?.CFDIuse,
            "RecipientTaxDomicile": formValue?.recievingProcecutor,
            "RegimenFiscalReceptor": formValue?.fiscalRegimeReciever,
            "Concept": addMoreData?.map((item) => ({
                "ProductOrServiceKeys": item?.ProductAndOrServiceKey,
                "NoIdentification": item?.NoIdentification,
                "Amount": item?.quantity,
                "UnitKey": item?.UnitKey,
                "Unit": item?.Unit,
                "Description": item?.Description,
                "Importe": item?.importe,
                "UnitValue": Number(item?.UnitValue).toFixed(2),
                "TaxObject": item?.taxObject,
                "TransferBase": item?.Base,
                "TransferTax": item?.Tax,
                "TransferTypeFactor": item?.TypeFactor,
                "TransferRateOfQuota": item?.RateorQuota,
                "TransferRateAmount": item?.RateAmount
            })),
            "TotalTransferedTax": formValue?.TransferTaxes,
            "privatePath": satDocData?.privatePath,
            "password": satDocData?.password
        }

        // console.log("sendDataa", data)
        // return
        setIsLoading(true);
        const res = await HttpClient.requestData("add-invoice-web-satnormal", "POST", data);
        console.log("resSend", res)
        if (res && res.status) {
            setIsLoading(false);
            setFormValue(initValue);
            setAddMoreData([initAddMore]);
            toast.success(language.mail_sent_success);
            window.open(res?.data, "_blank");
        } else {
            setIsLoading(false)
        }
    }


    const removeDuplicates = (arr) => {
        return arr.filter((item, index) => arr.indexOf(item) === index);
    }

    // search customer Name
    const fetchSerchName = async (name) => {
        const res = await HttpClient.requestData("sat-invoice-web-search/" + name, "GET");
        // console.log('searchNamewebinv', res, name)
        let arr = [];
        if (res && res.status) {
            res.data.forEach(element => {
                arr.push(element.RecipientName)
            });
            // console.log('element',arr)
            setSearchData(removeDuplicates(arr))
        } else {
            setSearchData([])
        }
    }

    // debouncing in search of recipent name
    useEffect(() => {
        // if (debouncing) {
        let timer = setTimeout(() => {
            fetchSerchName(debouncing)
        }, 300);
        return () => clearTimeout(timer);
        // }
    }, [debouncing]);


    // search product service key
    const fetchProdServiceKey = async () => {
        const data = {
            searchText: prodServicekey
        }
        setProdServiceLoad(true);
        const res = await HttpClient.requestData("search-product-serial", "POST", data);
        // console.log("resDfff", res);
        if (res && res?.status) {
            setProdServData(res?.data);
            setProdServiceLoad(false);
        } else {
            setProdServiceLoad(false);
        }
    }
    useEffect(() => {
        if (prodServicekey) {
            let timer = setTimeout(() => {
                fetchProdServiceKey();
            }, 300)
            return () => clearTimeout(timer);
        } else {
            setIsProServKey(false);
        }
    }, [prodServicekey])


    // search unit key
    const fetchUnitKey = async () => {
        const data = {
            searchText: unitKey
        }
        setUnitKeyLoading(true);
        const res = await HttpClient.requestData("search-unit-key", "POST", data);
        if (res && res?.status) {
            setUnitKeyData(res?.data);
            setUnitKeyLoading(false);
        } else {
            setUnitKeyLoading(false);
        }
    }
    useEffect(() => {
        if (unitKey) {
            let timer = setTimeout(() => {
                fetchUnitKey();
            }, 300)
            return () => clearTimeout(timer);
        } else {
            setIsUnitKey(false);
        }
    }, [unitKey])



    // get folio number
    const getFolioNumber = async () => {
        const res = await HttpClient.requestData("folio-number", "GET");
        // console.log("resff", res)
        if (res && res?.status) {
            setFormValue(prev => ({ ...prev, Follo: res?.data?.folioNumber }))
        }
    }

    //ftching document data
    const fetchCerKeyFiles = async () => {
        const res = await HttpClient.requestData("chk-credentials-already-uploaded", "GET", {});
        console.log("rescerr", res)
        if (res && res?.status) {
            setIsFetchDoc(true);
            setSatDocData(res?.data);
        } else {
            setIsFetchDoc(false);
        }
    }

    // get Recipient Tax Regime in respect to recipient Name
    const getFiscalReceptorNombre = async (name) => {
        const data = {
            "nombre": name
        }
        const res = await HttpClient.requestData("get-fiscal-receptor-of-nombre", "POST", data)
        console.log("resNombre", res)
        if (res && res?.status) {
            setFormValue(prev => ({ ...prev, fiscalRegimeReciever: res?.data?.fiscalReceptor }))
        } else {
            setFormValue(prev => ({ ...prev, fiscalRegimeReciever: "" }))
        }
    }
    useEffect(() => {
        if (formValue.RecipientName && isSave) {
            getFiscalReceptorNombre(formValue.RecipientName)
        }
    }, [isSave])

    useEffect(() => {
        setInvType("SatNormal");
        getFolioNumber();
        fetchCerKeyFiles();
    }, [])

    useEffect(() => {
        if (profileData) {
            setFormValue(prev => ({
                ...prev,
                RFCIssuer: profileData?.rfcID,
                IssuerName: profileData?.companyName
            }));
        }
    }, [profileData])


    return (
        <div
        // onClick={() => setIsProServKey("")}
        >

            {/* *******start** Required SAT Documents form */}
            <div className="normalTabPanelDiv">
                <p className="requHead">{language.required_sat_documents}</p>
                <div className="requTextMainDiv">
                    <div className="requTextDiv">
                        <p className="requFileText">.cer</p>

                        {/* .cer file */}
                        <div className="requInnerDiv">
                            {(documentData?.cerFile || isFetchDoc)
                                ?
                                <div className='d-flex justify-content-center'>
                                    <div className='sat-right-icon'> <IoCheckmarkCircleSharp /></div>
                                    <div
                                        style={{ color: '#a30303' }}
                                        onClick={() => {
                                            setDocumentData(prev => ({ ...prev, cerFile: "" }))
                                            setIsFetchDoc(false);
                                        }}
                                    >
                                        <MdDelete />
                                    </div>
                                </div>
                                :
                                <div>
                                    <input
                                        type="file"
                                        className="requInnerInp"
                                        onChange={(e) => {
                                            setDocumentData(prev => ({ ...prev, cerFile: e.target.files[0] }))
                                        }}
                                    />
                                    <button className="reqUplBtn">{language.upload}</button>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="requTextDiv">
                        <p className="requFileText">.key</p>

                        {/* .key file */}
                        <div className="requInnerDiv">
                            {
                                (documentData?.keyFile || isFetchDoc)
                                    ?
                                    <div className='d-flex justify-content-center'>
                                        <div className='sat-right-icon'> <IoCheckmarkCircleSharp /></div>
                                        <div
                                            style={{ color: '#a30303' }}
                                            onClick={() => {
                                                setDocumentData(prev => ({ ...prev, keyFile: "" }));
                                                setIsFetchDoc(false);
                                            }}
                                        >
                                            <MdDelete />
                                        </div>
                                    </div>
                                    :
                                    <div>
                                        <input
                                            type="file"
                                            className="requInnerInp"
                                            onChange={(e) => {
                                                setDocumentData(prev => ({ ...prev, keyFile: e.target.files[0] }))
                                            }}
                                        />
                                        <button className="reqUplBtn">{language.upload}</button>
                                    </div>

                            }
                        </div>

                    </div>
                    <div className="requTextDiv">
                        <div className="keyInpDiv">
                            <input
                                type="password"
                                value={documentData.keyPass}
                                placeholder=".key password"
                                onChange={(e) => {
                                    setDocumentData(prev => ({ ...prev, keyPass: e.target.value }))
                                }}
                            />
                        </div>
                        <div className="requSaveBtnDiv">
                            {!isSaveDoc
                                ?
                                <button
                                    className="requSaveBtn"
                                    onClick={() => handleDocumentSave()}
                                >
                                    {docLoading ? <CustomLoaderLine height={20} color="#fff" /> : language.save}
                                </button>
                                :
                                <button
                                    className="requSaveBtn"
                                    onClick={() => setISSavedoc(false)}
                                >
                                    {docLoading ? <CustomLoaderLine height={20} color="#fff" /> : language.update}
                                </button>
                            }
                        </div>
                    </div>
                </div>
            </div>
            {/* *******end** Required SAT Documents form */}


            <form className="sendInfoForm">
                <div className="sendInfoFirstFormDiv">

                    {/* upper left part */}
                    <div className="sendInfoSubDiv">
                        <p className="sendInfoHead">{language.invoice_information}</p>
                        <div className="sendInnerSubDiv">
                            <label className="sendFisiLabel">{language.rfc_issuer}:</label>
                            <input
                                type="text"
                                placeholder={language.rfc_issuer}
                                className="sendFisiInp"
                                name="RFCIssuer"
                                value={formValue.RFCIssuer}
                                onChange={(e) => handleFormChange(e)}
                            />
                        </div>
                        <div className="sendInnerSubDiv">
                            <label className="sendFisiLabel">{language.issuer_name}:</label>
                            <input
                                type="text"
                                placeholder={language.issuer_name}
                                className="sendFisiInp"
                                name="IssuerName"
                                value={formValue.IssuerName}
                                onChange={(e) => handleFormChange(e)}
                            />
                        </div>
                        <div className="sendInnerSubDiv">
                            <label className="sendFisiLabel">Folio:</label>
                            <input
                                type="text"
                                placeholder="Folio"
                                className="sendFisiInp"
                                name="Follo"
                                value={formValue.Follo}
                                onChange={(e) => handleFormChange(e)}
                                disabled
                            />
                        </div>
                        <div className="sendInnerSubDiv">
                            <label className="sendFisiLabel">{language.rfc_reciever}:</label>
                            <input
                                type="text"
                                placeholder={language.rfc_reciever}
                                className="sendFisiInp"
                                name="RFCReceiver"
                                value={formValue.RFCReceiver}
                                onChange={(e) => handleFormChange(e)}
                            />
                        </div>

                        <div className="sendInnerSubDiv">
                            <label className="sendFisiLabel">
                                {language.recipient_name}:
                            </label>
                            <input
                                type="text"
                                placeholder={language.recipient_name}
                                className="sendFisiInp"
                                name="RecipientName"
                                value={isSave ? formValue.RecipientName : language.recipient_name}
                                // onChange={(e) => handleFormChange(e)}
                                onClick={() => {
                                    setIsInvoiceName(true);
                                    setIsSave(false);
                                }}
                                readOnly

                            />
                        </div>

                        {/* recipient Name search modal */}
                        <CustomModal
                            modalIsOpen={isInvoiceName}
                            setIsOpen={setIsInvoiceName}
                            openModal={() => setIsInvoiceName(true)}
                            closeModal={() => setIsInvoiceName(false)}
                        >
                            <div>
                                <div className='d-flex justify-content-between text-secondary'>
                                    <span>
                                        {/* {language.confirmation} */}
                                    </span>
                                    <span>
                                        <AiOutlineClose className='icon' onClick={() => setIsInvoiceName(false)} size={20} color="gray" />
                                    </span>
                                </div>
                            </div>
                            <div className="input-group mb-3">
                                <div style={{ width: "80%" }}>
                                    <input
                                        type="text"
                                        className="form-control"
                                        // placeholder={isBusiness ? language.customer_name : language.income_type}
                                        aria-label="Recipient's username"
                                        aria-describedby="basic-addon2"
                                        value={formValue?.RecipientName}
                                        onChange={(e) => {
                                            if (/^[A-Za-z\s]*$/.test(e.target.value)) {
                                                setFormValue((prev) => ({ ...prev, RecipientName: e.target.value }));
                                                setDebouncing(e.target.value);
                                            }
                                        }}
                                    />
                                </div>
                                <button
                                    style={{ paddingLeft: "2px" }}
                                    className='customer_name_button_revenew btn btn-primary'
                                    onClick={() => {
                                        setIsSave(true);
                                        setIsInvoiceName(false);
                                    }}
                                >
                                    {language.save}
                                </button>
                                {
                                    searchData.map((item, i) =>
                                        <div className='search_name' key={i}>
                                            <p
                                                style={{ margin: '0', cursor: "pointer" }}
                                                onClick={() => {
                                                    // setValues((prev) => {
                                                    //     return { ...prev, customerName: item }
                                                    // })
                                                    setFormValue((prev) => ({ ...prev, RecipientName: item }))
                                                    setSearchData([]);
                                                }}
                                            >
                                                {item}
                                            </p>
                                        </div>
                                    )
                                }
                            </div>
                        </CustomModal >

                        {/* use cfdi */}
                        <div className="sendInnerSubDiv">
                            <label className="sendFisiLabel">{language.use_cfdi}:</label>
                            <select
                                className="sendFisiInp"
                                name="CFDIuse"
                                value={formValue.CFDIuse}
                                onChange={(e) => handleFormChange(e)}
                            >
                                <option value="" disabled>
                                    Select
                                </option>

                                <option value="G01">{language.aqui_of_good}</option>
                                <option value="G02">{language.refund_rebate_dic}</option>
                                <option value="G03">{language.gen_exp}</option>
                                <option value="I01">{language.construction2}</option>
                                <option value="I02">{language.office_furn_equip}</option>
                                <option value="I03">{language.transp_equip}</option>
                                <option value="I04">{language.comp_equip_access}</option>
                                <option value="I05">{language.dies_tooling}</option>
                                <option value="I06">{language.tele_commun}</option>
                                <option value="I07">{language.satelite}</option>
                                <option value="I08">{language.other_machinary}</option>
                                <option value="D01">{language.medical_dental_hospital_fee}</option>
                                <option value="D02">{language.mdical_disability}</option>
                                <option value="D03">{language.funeral_exp}</option>
                                <option value="D04">{language.donation}</option>
                                <option value="D05">{language.mortage_interest}</option>
                                <option value="D06">{language.voluntery_contri_sar}</option>
                                <option value="D07">{language.medical_ins_prem}</option>
                                <option value="D08">{language.school_transp_exp}</option>
                                <option value="D09">{language.deposit_into_saving_acc}</option>
                                <option value="D10">{language.edu_tution}</option>
                                <option value="S01">{language.no_tax_effect}</option>
                                <option value="CP01">{language.payments}</option>
                                <option value="CN01">{language.payroll}</option>

                            </select>
                        </div>

                        {/* recieving procecutor */}
                        <div className="sendInnerSubDiv">
                            <label className="sendFisiLabel">{language.recieving_procecutor}: </label>
                            <input
                                type="text"
                                placeholder={language.recieving_procecutor}
                                className="sendFisiInp"
                                name="recievingProcecutor"
                                value={formValue.recievingProcecutor}
                                onChange={(e) => handleFormChange(e)}
                            />
                        </div>


                        {/* Receptor Regime Fiscal */}
                        <div className="sendInnerSubDiv">
                            <label className="sendFisiLabel">{language.receptor_regim_fiscal}: </label>
                            <select
                                className="sendFisiInp"
                                name="fiscalRegimeReciever"
                                value={formValue.fiscalRegimeReciever}
                                onChange={(e) => handleFormChange(e)}
                            >
                                <option value="" disabled>
                                    Select
                                </option>

                                <option value="601">{language.general_legal_entity}</option>
                                <option value="603">{language.non_profit_entity}</option>
                                <option value="605">{language.wages_saleries}</option>
                                <option value="606">{language.leasing}</option>
                                <option value="607">{language.disposal_aquisition_asset}</option>
                                <option value="608">{language.other_income_rev}</option>
                                <option value="610">{language.non_performence}</option>
                                <option value="611">{language.divident_income}</option>
                                <option value="612">{language.individual_with_business}</option>
                                <option value="614">{language.interest_income}</option>
                                <option value="615">{language.income_from_prizes}</option>
                                <option value="616">{language.no_tax_liability}</option>
                                <option value="620">{language.prod_siciety_defers_revenue}</option>
                                <option value="621">{language.tax_incorporation}</option>
                                <option value="622">{language.farming_agriculture}</option>
                                <option value="623">{language.optipnal_company_group}</option>
                                <option value="624">{language.coordinated}</option>
                                <option value="625">{language.activity_through_tech_platform}</option>
                                <option value="626">{language.simplified_trust}</option>


                            </select>
                        </div>

                        {/* Export */}
                        <div className="sendInnerSubDiv">
                            <label className="sendFisiLabel">{language.export}:</label>
                            <select
                                className="sendFisiInp"
                                name="export"
                                value={formValue.export}
                                onChange={(e) => handleFormChange(e)}
                            >
                                <option value="" disabled>
                                    Select
                                </option>

                                <option value="01">{language.not_applicable}</option>
                                <option value="02">{language.definitive}</option>
                                <option value="03">{language.temporal}</option>

                            </select>
                        </div>


                    </div>

                    {/* upper right part */}
                    <div className="sendInfoSubDiv">
                        <p className="sendInfoHead demoTextClr">gthj</p>
                        {/* <div className="sendInnerSubDiv">
                            <label className="sendFisiLabel">{language.tax_folio}:</label>
                            <select
                                className="sendFisiInp"
                                name="TaxFolio"
                                value={formValue.TaxFolio}
                                onChange={(e) => handleFormChange(e)}
                            >
                                <option value="" disabled>
                                    Auto-assign or select
                                </option>
                                <option value="tax folio 1">tax folio 1</option>
                                <option value="tax folio 2">tax folio 2</option>
                                <option value="tax folio 3">tax folio 3</option>
                            </select>
                        </div> */}
                        {/* <div className="sendInnerSubDiv">
                            <label className="sendFisiLabel">
                                {language.csd_serial_no}:
                            </label>
                            <input
                                type="text"
                                className="sendFisiInp"
                                placeholder={language.csd_serial_no}
                                name="CSDSerialNo"
                                value={formValue.CSDSerialNo}
                                onChange={(e) => handleFormChange(e)}
                            />
                        </div> */}
                        <div className="sendInnerSubDiv">
                            <label className="sendFisiLabel">{language.series}:</label>
                            {/* <select
                                className="sendFisiInp"
                                name="Series"
                                value={formValue.Series}
                                onChange={(e) => handleFormChange(e)}
                            >
                                <option value="" disabled>
                                    Drop-down-select
                                </option>
                                <option value="series 1">series 1</option>
                                <option value="series 2">series 2</option>
                                <option value="series 3">series 3</option>
                            </select> */}
                            <input
                                type="text"
                                className="sendFisiInp"
                                placeholder={language.csd_serial_no}
                                name="Series"
                                value={formValue.Series}
                                onChange={(e) => handleFormChange(e)}
                                disabled
                            />
                        </div>
                        <div className="sendInnerSubDiv">
                            <label className="sendFisiLabel">
                                {language.postal_date_time}:
                            </label>

                            <div className='sendFisiInp'>{formValue?.PostcardDateAndTimeOfIssue}</div>
                            <input
                                type="date"
                                // className="sendFisiInp"
                                className='sendFisiInp-hidden'
                                placeholder={language.postal_date_time}
                                name="PostcardDateAndTimeOfIssue"
                                value={moment(formValue.PostcardDateAndTimeOfIssue).format("YYYY-MM-DD")}
                                // onChange={(e) => handleFormChange(e)}
                                onChange={(e) => {
                                    const today2 = new Date()
                                    const day = moment(today2).format(e.target.value + " " + "hh:mm:ss");
                                    const isoDate = moment(day).toISOString();
                                    setFormValue(prev => ({ ...prev, PostcardDateAndTimeOfIssue: isoDate }))

                                    // console.log("todaymmjs", moment(day).toISOString(), day)
                                    // console.log("timemm", time)
                                }}
                            />
                            <CgCalendarDates className='sendFisiInp-dateicon' />
                            {/* <Datetime
                                // renderInput={renderInput}
                                // utc={true}
                                closeOnSelect
                                // onChange={(e) =>
                                //     setDateData((prev) => {
                                //         // console.log("dateTime", e);
                                //         return {
                                //             ...prev,
                                //             startDate: moment.isMoment(e) ? e.format("yyyy-MM-DD") : "",
                                //         };
                                //     })
                                // }
                                timeFormat={false}
                                initialValue={language.start_date}
                            // className="sendFisiInp"
                            /> */}
                        </div>
                        {/* <div className="sendInnerSubDiv">
                            <label className="sendFisiLabel">
                                {language.voucher_effect}:
                            </label>
                            <select
                                className="sendFisiInp"
                                name="VoucherEffect"
                                value={formValue.VoucherEffect}
                                onChange={(e) => handleFormChange(e)}
                            >
                                <option value="" disabled>
                                    Drop-down-select
                                </option>
                                <option value="voucher 1">voucher 1</option>
                                <option value="voucher 1">voucher 2</option>
                                <option value="voucher 1">voucher 3</option>
                            </select>
                        </div> */}
                        <div className="sendInnerSubDiv">
                            <label className="sendFisiLabel">{language.tax_regime}:</label>
                            <select
                                className="sendFisiInp"
                                name="TaxRegime"
                                value={formValue.TaxRegime}
                                onChange={(e) => handleFormChange(e)}
                            >
                                <option value="" disabled>
                                    Select
                                </option>

                                <option value="601">{language.general_legal_entity}</option>
                                <option value="603">{language.non_profit_entity}</option>
                                <option value="605">{language.wages_saleries}</option>
                                <option value="606">{language.leasing}</option>
                                <option value="607">{language.disposal_aquisition_asset}</option>
                                <option value="608">{language.other_income_rev}</option>
                                <option value="610">{language.non_performence}</option>
                                <option value="611">{language.divident_income}</option>
                                <option value="612">{language.individual_with_business}</option>
                                <option value="614">{language.interest_income}</option>
                                <option value="615">{language.income_from_prizes}</option>
                                <option value="616">{language.no_tax_liability}</option>
                                <option value="620">{language.prod_siciety_defers_revenue}</option>
                                <option value="621">{language.tax_incorporation}</option>
                                <option value="622">{language.farming_agriculture}</option>
                                <option value="623">{language.optipnal_company_group}</option>
                                <option value="624">{language.coordinated}</option>
                                <option value="625">{language.activity_through_tech_platform}</option>
                                <option value="626">{language.simplified_trust}</option>


                                {/* <option value="tax regime 2">tax regime 2</option>
                                <option value="tax regime 3">tax regime 3</option> */}
                            </select>
                        </div>
                    </div>

                </div>

                {/*email concepts */}
                <div className="sendInfoSecondFormDiv">
                    <div className="secInfoSubDiv">
                        <div className="plusConDiv">
                            <label className="concepLabel">{language.email_recipients}:</label>
                        </div>
                        <div className="entInpDiv">
                            <input
                                type='email'
                                placeholder={language.email_recipients}
                                name="email"
                                value={formValue.email}
                                onChange={(e) => handleFormChange(e)}
                            />
                        </div>
                    </div>
                </div>

                <div className="sendInfoThirdFormDiv">

                    {/* add more part */}

                    {/* + button */}
                    <div className='addConcepBtnDiv'>
                        <button
                            type='button'
                            className="concepBtn"
                            onClick={(e) => handleClickAddMore(e)}
                        >
                            <i className="fa-solid fa-plus"></i>
                        </button>
                        <p className='addConcepBtnTxt'>Add Conceptors</p>
                    </div>

                    {addMoreData.map((item, i, arr) => {
                        return (
                            <div className='mb-4' key={i}>
                                <div className="cnrFirstDiv">

                                    {/* table 1 */}
                                    <table>
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th className="invoTableTH">
                                                    <span className="descSpan">
                                                        {language.product_service_key}
                                                    </span>
                                                </th>
                                                <th className="invoTableTH">
                                                    <span>No. Identification</span>
                                                </th>
                                                <th className="invoTableTH">
                                                    <span>{language.quantity2}</span>
                                                </th>
                                                <th className="invoTableTH">
                                                    <span>{language.unit_key}</span>
                                                </th>
                                                <th className="invoTableTH">
                                                    <span>{language.unit}</span>
                                                </th>
                                                <th className="invoTableTH">
                                                    <span>{language.unit_price}</span>
                                                </th>
                                                <th className="invoTableTH">
                                                    <span>Importe</span>
                                                </th>
                                                <th className="invoTableTH">
                                                    <span>{language.tax_object}</span>
                                                </th>
                                                {/* <th className="invoTableTH">
                                        <span>{language.discount}</span>
                                    </th> */}
                                                {/* <th className="invoTableTH">
                                        <span>{language.pending_no}</span>
                                    </th> */}
                                                {/* <th className="invoTableTH">
                                        <span>{language.prop_acc_no}</span>
                                    </th> */}

                                            </tr>
                                        </thead>

                                        <tbody>
                                            <tr
                                            // key={i}
                                            >
                                                <td>
                                                    {arr.length > 1 &&
                                                        <button
                                                            type='button'
                                                            className='invoiceTrashBtn'
                                                            onClick={(e) => handleDeleteAddMore(e, i)}
                                                        >
                                                            <i className="fa-regular fa-trash-can"></i>
                                                        </button>
                                                    }
                                                </td>
                                                <td className="invoTableTD">
                                                    <input
                                                        type="text"
                                                        className="invoTableTDSecInp"
                                                        placeholder={language.search}
                                                        name='ProductAndOrServiceKey'
                                                        value={item?.ProductAndOrServiceKey}
                                                        // onChange={(e) => handleChangeAddMore(e, i)}
                                                        onChange={(e) => {
                                                            setProdServiceKey(e.target.value);
                                                            // setIsProServKey(i)
                                                            handleChangeAddMore(e, i);
                                                        }}
                                                    />
                                                    {/* product searvice key search dropdon */}
                                                    {
                                                        isProdServKey === i &&
                                                        <div className='searvice-key-dropdown'>
                                                            {
                                                                !prodServiceLoading
                                                                    ?
                                                                    prodServData?.map((ele, ind) =>
                                                                        <p
                                                                            key={ind}
                                                                            onClick={(e) => {
                                                                                e.stopPropagation();
                                                                                setAddMoreData(prev => prev.map((item, i) => {
                                                                                    if (i === isProdServKey) {
                                                                                        return {
                                                                                            ...item,
                                                                                            ProductAndOrServiceKey: ele.value,
                                                                                            ProductAndOrServiceName: ele.title
                                                                                        }
                                                                                    } else {
                                                                                        return item
                                                                                    }
                                                                                }));
                                                                                setIsProServKey(false);
                                                                                setProdServiceKey("");
                                                                            }}
                                                                        >
                                                                            {ele?.title}
                                                                        </p>
                                                                    )
                                                                    :
                                                                    <p><CustomLoaderLine height="20px" /></p>
                                                            }

                                                            {
                                                                (prodServData?.length === 0 && !prodServiceLoading) ? <p>{language.no_data}</p> : null
                                                            }

                                                            {/* {prodServiceLoading && <CustomLoaderLine />} */}

                                                        </div>
                                                    }


                                                </td>
                                                <td className="invoTableTD">
                                                    <input
                                                        type="text"
                                                        className="invoTableTDSecInp"
                                                        name='NoIdentification'
                                                        value={item.NoIdentification}
                                                        onChange={(e) => {
                                                            handleChangeAddMore(e, i);

                                                        }}
                                                    />
                                                </td>
                                                <td className="invoTableTD">
                                                    <input
                                                        type="number"
                                                        className="invoTableTDSecInp"
                                                        // placeholder={}
                                                        name='quantity'
                                                        value={item.quantity}
                                                        onChange={(e) => {
                                                            handleChangeAddMore(e, i)
                                                            const qty = e.target.value
                                                            if (item.UnitValue) {
                                                                setAddMoreData(prev => prev.map((item, ind) => {
                                                                    if (i === ind) {
                                                                        const mltiResult = multiplicationTwoNum(item.UnitValue, qty);
                                                                        return { ...item, importe: mltiResult, Base: mltiResult }
                                                                    } else {
                                                                        return item
                                                                    }
                                                                }));
                                                            }
                                                        }}
                                                    />
                                                </td>
                                                <td className="invoTableTD">
                                                    <input
                                                        type="text"
                                                        className="invoTableTDSecInp"
                                                        placeholder={language.search}
                                                        name='unitKeyName'
                                                        value={item.unitKeyName}
                                                        // onChange={(e) => handleChangeAddMore(e, i)}
                                                        onChange={(e) => {
                                                            setUnitKey(e.target.value);
                                                            setIsUnitKey(i)
                                                            handleChangeAddMore(e, i);
                                                        }}
                                                    />

                                                    {
                                                        isUnitKey === i &&
                                                        <div className='searvice-key-dropdown'>
                                                            {
                                                                !unitKeyLoading
                                                                    ?
                                                                    unitKeyData?.map((ele, ind) =>
                                                                        <p
                                                                            key={ind}
                                                                            onClick={(e) => {
                                                                                e.stopPropagation();
                                                                                setAddMoreData(prev => prev.map((item, i) => {
                                                                                    if (i === isUnitKey) {
                                                                                        return {
                                                                                            ...item,
                                                                                            UnitKey: ele.value,
                                                                                            unitKeyName: ele.title
                                                                                        }
                                                                                    } else {
                                                                                        return item
                                                                                    }
                                                                                }));
                                                                                setIsUnitKey(false);
                                                                                setUnitKey("");
                                                                            }}
                                                                        >
                                                                            {ele?.title}
                                                                        </p>
                                                                    )
                                                                    :
                                                                    <p><CustomLoaderLine height="20px" /></p>
                                                            }

                                                            {
                                                                (unitKeyData?.length === 0 && !unitKeyLoading) ? <p>{language.no_data}</p> : null
                                                            }

                                                            {/* {prodServiceLoading && <CustomLoaderLine />} */}

                                                        </div>
                                                    }
                                                </td>
                                                <td className="invoTableTD">
                                                    <input
                                                        type="text"
                                                        className="invoTableTDSecInp"
                                                        name='Unit'
                                                        value={item.Unit}
                                                        onChange={(e) => handleChangeAddMore(e, i)}
                                                    />
                                                </td>

                                                {/* unit price */}
                                                <td className="invoTableTD">
                                                    <input
                                                        type="number"
                                                        className="invoTableTDSecInp"
                                                        name='UnitValue'
                                                        value={item.UnitValue}
                                                        onChange={(e) => {
                                                            handleChangeAddMore(e, i)
                                                            const price = e.target.value
                                                            if (item.quantity) {
                                                                setAddMoreData(prev => prev.map((item, ind) => {
                                                                    if (i === ind) {
                                                                        const mltiResult = multiplicationTwoNum(item.quantity, price);
                                                                        // console.log("mltiResult", mltiResult)
                                                                        return { ...item, importe: mltiResult, Base: mltiResult }
                                                                    } else {
                                                                        return item
                                                                    }
                                                                }));
                                                            }
                                                        }}
                                                    />
                                                </td>
                                                <td className="invoTableTD">
                                                    <input
                                                        type="number"
                                                        className="invoTableTDSecInp"
                                                        name='importe'
                                                        value={item.importe}
                                                        onChange={(e) => {
                                                            handleChangeAddMore(e, i)

                                                        }}
                                                        readOnly
                                                    />
                                                </td>

                                                {/* <td className="invoTableTD">
                                                <input
                                                    type="number"
                                                    className="invoTableTDSecInp"
                                                    name='Discount'
                                                    value={item.Discount}
                                                    onChange={(e) => handleChangeAddMore(e, i)}
                                                />
                                            </td> */}
                                                {/* <td className="invoTableTD">
                                                <input
                                                    type="number"
                                                    className="invoTableTDSecInp"
                                                    name='PendingNo'
                                                    value={item.PendingNo}
                                                    onChange={(e) => handleChangeAddMore(e, i)}
                                                />
                                            </td> */}
                                                {/* <td className="invoTableTD">
                                                <input
                                                    type="number"
                                                    className="invoTableTDSecInp"
                                                    name='PropertyAccountNo'
                                                    value={item.PropertyAccountNo}
                                                    onChange={(e) => handleChangeAddMore(e, i)}
                                                />
                                            </td> */}

                                                <td className="invoTableTD">
                                                    <select
                                                        className="invoTableTDinp"
                                                        name="taxObject"
                                                        value={item.taxObject}
                                                        onChange={(e) => handleChangeAddMore(e, i)}
                                                    >
                                                        <option value="" disabled>
                                                            Select
                                                        </option>
                                                        <option value="01">{language.tax_object_01}</option>
                                                        <option value="02">{language.tax_object_02}</option>
                                                        <option value="03">{language.tax_object_03}</option>
                                                    </select>
                                                </td>
                                            </tr>

                                        </tbody>
                                    </table>

                                    {/* table 2 */}
                                    <table mt-2>
                                        <thead>
                                            <tr>
                                                <th></th>

                                                <th className="invoTableTH">
                                                    <span>{language?.description}</span>
                                                </th>
                                                <th className="invoTableTH">
                                                    <span>{language.base_transfer}</span>
                                                </th>
                                                <th className="invoTableTH">
                                                    <span>{language.tax}</span>
                                                </th>

                                                <th className="invoTableTH">
                                                    <span>{language.type}</span>
                                                </th>

                                                <th className="invoTableTH">
                                                    <span>{language.type_factor}</span>
                                                </th>
                                                <th className="invoTableTH">
                                                    <span>{language.rate_of_quota}</span>
                                                </th>
                                                <th className="invoTableTH">
                                                    <span>{language.rate_amount}</span>
                                                </th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    {arr.length > 1 &&
                                                        <div
                                                            style={{ opacity: 0 }}
                                                        // className='invoiceTrashBtn'
                                                        // onClick={(e) => handleDeleteAddMore(e, i)}
                                                        >
                                                            <i className="fa-regular fa-trash-can"></i>
                                                        </div>
                                                    }
                                                </td>
                                                <td className="invoTableTD">
                                                    <input
                                                        type="text"
                                                        className="invoTableTDSecInp"
                                                        name='Description'
                                                        value={item.Description}
                                                        onChange={(e) => handleChangeAddMore(e, i)}
                                                    />
                                                </td>

                                                <td className="invoTableTD" style={{ width: "11%" }}>
                                                    <input
                                                        type="number"
                                                        className="invoTableTDinp"
                                                        placeholder="Subtotal"
                                                        name="Base"
                                                        value={item.Base}
                                                        onChange={(e) => {
                                                            // handleFormChange(e);
                                                            handleChangeAddMore(e, i)
                                                        }}
                                                        readOnly
                                                    />
                                                </td>

                                                {/* Tax */}
                                                <td className="invoTableTD" style={{ width: "11%" }}>
                                                    <select
                                                        className="invoTableTDinp"
                                                        name="Tax"
                                                        value={item.Tax}
                                                        // onChange={(e) => handleFormChange(e)}
                                                        onChange={(e) => {
                                                            handleChangeAddMore(e, i);

                                                            const val = e.target.value
                                                            if (item.TypeFactor) {
                                                                setAddMoreData(prev => prev.map((ele, ind) => {
                                                                    if (i === ind) {
                                                                        if (val === "003" && (item.TypeFactor === "Tasa" || item.TypeFactor === "Cuota")) {
                                                                            console.log("elebase", ele.Base)
                                                                            return { ...ele, RateorQuota: "0.080000", RateAmount: multiplicationTwoNum(0.08, ele.Base) }
                                                                        } else if (val === "002" && (item.TypeFactor === "Tasa" || item.TypeFactor === "Cuota")) {
                                                                            return { ...ele, RateorQuota: "0.160000", RateAmount: multiplicationTwoNum(0.16, ele.Base) }
                                                                        } else if (val === "001" || item.TypeFactor === "Exento") {
                                                                            return { ...ele, RateorQuota: "0.000000", RateAmount: multiplicationTwoNum(0.00, ele.Base) }
                                                                        }
                                                                        else {
                                                                            console.log("elsess")
                                                                            return { ...ele, RateorQuota: "" }
                                                                        }
                                                                    } else {
                                                                        return ele
                                                                    }
                                                                }))
                                                            }
                                                        }}
                                                    >
                                                        <option value="" disabled>
                                                            Select
                                                        </option>
                                                        <option value="001">{language.tax_001}</option>
                                                        <option value="002">{language.tax_002}</option>
                                                        <option value="003">{language.tax_003}</option>
                                                    </select>
                                                </td>

                                                <td className="invoTableTD" style={{ width: "11%" }}>
                                                    <select
                                                        className="invoTableTDinp"
                                                        name="Type"
                                                        value={item.Type}
                                                        // onChange={(e) => handleFormChange(e)}
                                                        onChange={(e) => handleChangeAddMore(e, i)}

                                                    >
                                                        <option value="" disabled>
                                                            Select
                                                        </option>
                                                        <option value="I">{language.income}</option>
                                                        <option value="E">{language.expense}</option>
                                                        <option value="T">{language.transfer}</option>
                                                        <option value="N">{language.payroll}</option>
                                                        <option value="P">{language.payment}</option>
                                                    </select>
                                                </td>
                                                <td className="invoTableTD" style={{ width: "11%" }}>
                                                    <select
                                                        className="invoTableTDinp"
                                                        name="TypeFactor"
                                                        value={item.TypeFactor}
                                                        // onChange={(e) => handleFormChange(e)}
                                                        onChange={(e) => {
                                                            handleChangeAddMore(e, i)

                                                            const val = e.target.value
                                                            if (item.Tax) {
                                                                setAddMoreData(prev => prev.map((ele, ind) => {
                                                                    if (i === ind) {
                                                                        if (item.Tax === "003" && (val === "Tasa" || val === "Cuota")) {
                                                                            return { ...ele, RateorQuota: "0.080000", RateAmount: multiplicationTwoNum(0.08, ele.Base) }
                                                                        } else if (item.Tax === "002" && (val === "Tasa" || val === "Cuota")) {
                                                                            return { ...ele, RateorQuota: "0.160000", RateAmount: multiplicationTwoNum(0.16, ele.Base) }
                                                                        } else if (item.Tax === "001" || val === "Exento") {
                                                                            return { ...ele, RateorQuota: "0.000000", RateAmount: multiplicationTwoNum(0.00, ele.Base) }
                                                                        }
                                                                        else {
                                                                            return { ...ele, RateorQuota: "" }
                                                                        }
                                                                    } else {
                                                                        return ele
                                                                    }
                                                                }))
                                                            }
                                                        }}
                                                    >
                                                        <option value="" disabled>
                                                            Select
                                                        </option>
                                                        <option value="Tasa">{language.Rate}</option>
                                                        <option value="Cuota">{language.Fee}</option>
                                                        <option value="Exento">{language.Exempt}</option>
                                                    </select>
                                                </td>
                                                <td className="invoTableTD" style={{ width: "11%" }}>
                                                    <input
                                                        type="number"
                                                        className="invoTableTDinp"
                                                        // placeholder="Iva%"
                                                        name="RateorQuota"
                                                        value={item.RateorQuota}
                                                        onChange={(e) => {
                                                            // handleFormChange(e)
                                                            handleChangeAddMore(e, i)
                                                        }}
                                                        readOnly
                                                    />
                                                </td>

                                                <td className="invoTableTD">
                                                    <input
                                                        type="number"
                                                        className="invoTableTDinp"
                                                        name="RateAmount"
                                                        value={item.RateAmount}
                                                        // onChange={(e) => handleFormChange(e)}
                                                        onChange={(e) => handleChangeAddMore(e, i)}
                                                        readOnly
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                {/* tax /  type  /  base */}
                                {/* <div className="mt-4 cnrSecondDiv">

                                </div> */}
                            </div>
                        )
                    })
                    }
                </div>

                {/* bottom part */}
                <div className="tableAfterDiv">
                    <div className="tableInnerAfterDiv">

                        {/* currency */}
                        <div className="subTabDiv">
                            <label className="subLabelTab">{language.currency2}:</label>
                            <div className="subInSeDiv">
                                <select
                                    className="subInputTab"
                                    name="Currency"
                                    value={formValue.Currency}
                                    onChange={(e) => handleFormChange(e)}
                                >
                                    <option value="" readOnly>
                                        Select
                                    </option>
                                    {
                                        currencies && currencies?.map((item, i) =>
                                            <option value={item?.cc} key={i}>{item?.name}</option>
                                        )
                                    }
                                </select>
                            </div>
                        </div>

                        <div className="subTabDiv">
                            <label className="subLabelTab">{language.way_to_pay}:</label>
                            <div className="subInSeDiv">
                                <select
                                    className="subInputTab"
                                    name="WayToPay"
                                    value={formValue.WayToPay}
                                    onChange={(e) => handleFormChange(e)}
                                >
                                    <option value="" readOnly disabled>
                                        Select
                                    </option>
                                    <option value="01">{language.Way_to_pay_01}</option>
                                    <option value="02">{language.Way_to_pay_02}</option>
                                    <option value="03">{language.Way_to_pay_03}</option>
                                    <option value="04">{language.Way_to_pay_04}</option>
                                    <option value="05">{language.Way_to_pay_05}</option>
                                    <option value="06">{language.Way_to_pay_06}</option>
                                    <option value="08">{language.Way_to_pay_08}</option>
                                    <option value="12">{language.Way_to_pay_12}</option>
                                    <option value="13">{language.Way_to_pay_13}</option>
                                    <option value="14">{language.Way_to_pay_14}</option>
                                    <option value="15">{language.Way_to_pay_15}</option>
                                    <option value="17">{language.Way_to_pay_17}</option>
                                    <option value="23">{language.Way_to_pay_23}</option>
                                    <option value="24">{language.Way_to_pay_24}</option>
                                    <option value="25">{language.Way_to_pay_25}</option>
                                    <option value="26">{language.Way_to_pay_26}</option>
                                    <option value="27">{language.Way_to_pay_27}</option>
                                    <option value="28">{language.Way_to_pay_28}</option>
                                    <option value="29">{language.Way_to_pay_29}</option>
                                    <option value="30">{language.Way_to_pay_30}</option>
                                    <option value="31">{language.Way_to_pay_31}</option>
                                    <option value="99">{language.Way_to_pay_99}</option>

                                </select>
                            </div>
                        </div>

                        {/* payment method */}
                        <div className="subTabDiv">
                            <label className="subLabelTab">{language.payment_method}:</label>
                            <div className="subInSeDiv">
                                <select
                                    className="subInputTab"
                                    name="PaymentMethod"
                                    value={formValue.PaymentMethod}
                                    onChange={(e) => handleFormChange(e)}
                                >
                                    <option value="" readOnly disabled>
                                        Select
                                    </option>
                                    <option value="PUE">{language.one_time_payment}</option>
                                    <option value="PPD">{language.differed_payment}</option>
                                </select>
                            </div>
                        </div>

                        {/* condition of payment*/}
                        <div className="subTabDiv">
                            <label className="subLabelTab">{language.condition_of_payment}:</label>
                            <div className="subInSeDiv">
                                <select
                                    className="subInputTab"
                                    name="conditionOfPayment"
                                    value={formValue.conditionOfPayment}
                                    onChange={(e) => handleFormChange(e)}
                                >
                                    <option value="" readOnly disabled>
                                        Select
                                    </option>
                                    <option value="Crédito">{language.credit_terms}</option>
                                    <option value="CONTADO">CONTADO</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    {/* bottom right part */}
                    <div className="tableInnerAfterDiv">
                        <div className="subTabDiv">
                            <label className="subLabelTab">Subtotal:</label>
                            <div className="subInSeDiv">
                                <span className="ivSpan d-none d-md-inline-block"></span>
                                <input
                                    type="number"
                                    placeholder="+ all rows"
                                    className="subInputTab"
                                    name="Subtotal"
                                    value={formValue.Subtotal}
                                    onChange={(e) => handleFormChange(e)}
                                    readOnly
                                />
                            </div>
                        </div>
                        <div className="subTabDiv">
                            <label className="subLabelTab">
                                {language.transfer_taxes}:
                            </label>
                            <div className="subInSeDiv">
                                {/* <span className="ivSpan">IVA 16%</span> */}
                                <span className="ivSpan d-none d-md-inline-block"></span>
                                <div className="ibAfterSpanDiv">
                                    <input
                                        type="number"
                                        placeholder="=tax total"
                                        className="subInputTab"
                                        name="TransferTaxes"
                                        value={formValue.TransferTaxes}
                                        onChange={(e) => handleFormChange(e)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="subTabDiv">
                            <label className="subLabelTab">Total:</label>
                            <div className="subInSeDiv">
                                <span className="ivSpan d-none d-md-inline-block"></span>
                                <input
                                    type="number"
                                    placeholder="=sub + tax"
                                    className="subInputTab"
                                    name="Total"
                                    value={formValue.Total}
                                    onChange={(e) => handleFormChange(e)}
                                    readOnly
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="sendBtnDiv">
                    <button
                        className="sendFisiBtn"
                        type='submit'
                        onClick={(e) => handleSend(e)}
                    >
                        {language.send}
                    </button>
                </div>
            </form >

        </div >
    )
}

export default SatNormalForm
