import React from 'react'
import { Navigate, Outlet } from 'react-router-dom';
import { useUserData } from '../Providers/UserWrapper';

const BusinessPriceRoute = () => {
    const { isBusiness, isLogin } = useUserData();
    // console.log('isBusinessRoute',isBusiness)
    if (isLogin) {
        if (isBusiness) {
            return <Outlet />
        } else {
            return <Navigate to="/personal-price" />
        }
    }else{
        return <Navigate to="/price" />
    }
}

export default BusinessPriceRoute