import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import HttpClient from "../../../utils/HttpClient";

import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import moment from "moment";
import { useUserData } from "../../../Providers/UserWrapper";
import { useNavigate } from "react-router-dom";


function DashboardChartSeven({ time, location }) {
  const navigate = useNavigate();
  const { isInvestor, isBookKeeper } = useUserData()
  const [options, setOptions] = useState({
    chart: {
      type: "bar",
      height: "100%",
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "30%",
        endingShape: "rounded",
        distributed: true,
        dataLabels: {
          position: "top", // top, center, bottom
        },
      },
    },
    title: {
      text: "day",
      align: "left",
      margin: 10,
      offsetX: 0,
      offsetY: 0,
      floating: false,
      style: {
        fontSize: "14px",
        fontWeight: "bold",
        fontFamily: undefined,
        color: "#263238",
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return "$ " + Number(val).toLocaleString();
      },
      offsetY: -20,
      style: {
        fontSize: "12px",
        colors: ["#304758"],
      },
    },
    stroke: {
      show: true,
      width: -2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: ["Tax-Deductible", "Non-Deductible"],
      position: "bottom",
      labels: {
        offsetX: 0,
      },
    },

    fill: {
      opacity: 1,
      // gradient: {
      //     shade: "light",
      //     type: "horizontal",
      //     shadeIntensity: 0.25,
      //     gradientToColors: undefined,
      //     inverseColors: true,
      //     opacityFrom: 1,
      //     opacityTo: 1,
      //     stops: [50, 0, 100, 100]
      // }
    },
    tooltip: {
      y: {
        formatter: function (val, opt) {
          console.log(opt, "optionsbaby");
          return "$ " + val;
        },
      },
      enabled: true,
      offsetY: -35,
    },
    colors: ["#3b3425", "#735a24", "#918b7e"],
    legend: {
      show: true,
      showForSingleSeries: false,
      showForNullSeries: true,
      showForZeroSeries: true,
      position: "bottom",
      horizontalAlign: "center",
      floating: false,
      fontSize: "14px",
      fontFamily: "Helvetica, Arial",
      fontWeight: 400,
      formatter: undefined,
      inverseOrder: false,
      width: undefined,
      height: undefined,
      tooltipHoverFormatter: undefined,
      customLegendItems: [],
      offsetX: 0,
      offsetY: 0,
      labels: {
        colors: undefined,
        useSeriesColors: false,
      },
      markers: {
        width: 12,
        height: 12,
        strokeWidth: 0,
        strokeColor: "#fff",
        fillColors: undefined,
        radius: 12,
        customHTML: undefined,
        onClick: undefined,
        offsetX: 0,
        offsetY: 0,
      },
      itemMargin: {
        horizontal: 5,
        vertical: 0,
      },
      onItemClick: {
        toggleDataSeries: true,
      },
      onItemHover: {
        highlightDataSeries: true,
      },
    },
  });

  const [series, setSeries] = useState([
    {
      name: "text",
      data: [0, 0],
    },
  ]);



  // for circle
  const [series2, setSeries2] = useState([0, 0]);

  // console.log("seriessss", series, series2)

  const [options2, setOptions2] = useState({
    chart: {
      type: "donut",
    },
    plotOptions: {
      pie: {
        customScale: 1,
      },
    },
    labels: ["Tax-Deductible", "Non-Deductible"],
    colors: [
      // function({ value, seriesIndex, w }) {
      //     if (value >=40) {
      //       return '#052d6e'
      //     } else if(value<40 && value>30) {
      //       return '#6c7078'
      //     }else{
      //       return "#8b96ad"
      //     }
      //   },
      //   function({ value, seriesIndex, w }) {
      //     if (value >=40) {
      //       return '#052d6e'
      //     } else if(value<40 && value>30) {
      //       return '#46474a'
      //     }else{
      //       return "#46474a"
      //     }
      //   },
      "#7e8485",
      "#454747",
    ],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            height: 150,
            width: "100%",
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    legend: {
      show: true,
      showForSingleSeries: false,
      showForNullSeries: true,
      showForZeroSeries: true,
    },
  });

  const initFilter = { vendorName: "", startDate: "", endDate: "" }
  const [filterData, setFilterData] = useState(initFilter)
  const [taxData, setTaxData] = useState({});

  const [clientNameSearch, setClientNameSearch] = useState([]);
  const [allClientNameData, setAllClientNameData] = useState([]);
  const [singleClient, setSingleClient] = useState({})
  const [clientInp, setClientInp] = useState("")
  const [clientInpShow, setClientInpShow] = useState("")


  // console.log("locationcc", location)

  // get tax
  const getTaxData = async () => {
    let data = {
      "dayType": time,
    }

    if (
      (filterData.startDate !== "" && filterData.endDate !== "")
    ) {
      data = {
        "dayType": "dayRange",
        "startDate": filterData?.startDate,
        "endDate": filterData?.endDate
      }
    } else {
      data = {
        "dayType": time,
      }
    }

    // console.log("dataaa", data)

    let res = {}
    if (isInvestor || isBookKeeper) {
      res = await HttpClient.requestData(
        "investor/investor-get-tax-non-tax-data/"
        + singleClient?.accountRegCode,
        "POST", data
      );
    } else {
      res = await HttpClient.requestData("get-tax-non-tax-data", "POST", data);
    }
    console.log("restt", res?.data?.[0])
    if (res && res?.status) {
      if (res?.data?.[0]) {
        setTaxData(res?.data?.[0])
        setSeries([
          {
            name: "text",
            data: [res?.data?.[0]?.totalPriceWithTax, res?.data?.[0]?.totalPriceWithoutTax],
          }
        ])
        setSeries2([res?.data?.[0]?.totalPriceWithTax, res?.data?.[0]?.totalPriceWithoutTax])
      } else {
        setSeries([
          {
            name: "text",
            data: [0, 0],
          }
        ])
        setSeries2([0, 0])
      }
    }
  }

  // get client name
  const getClintName = async () => {
    const res = await HttpClient.requestData("investor/get-all-investor-linked-account-list", "GET");
    // console.log("ressddcx", res)
    if (res && res?.status) {
      setAllClientNameData(res?.data)
      if (location?.state?.userRegCodr) {
        const fndData = res?.data?.find(ele => ele?.accountRegCode === location?.state?.userRegCodr)
        setSingleClient(fndData)
        setClientInp(fndData?.client_name)
      }
    }
  }


  // handleClientClick
  const handleClientClick = (item) => {
    console.log("item", item)
    setSingleClient(item)
    setClientInp(item?.client_name)
    setClientInpShow("")
    navigate("/dashboard-manager", {
      state: {
        userRegCodr: item?.accountRegCode,
        slideNo: 7,
      },
    })
  }

  useEffect(() => {
    if (
      (filterData.startDate !== "" && filterData.endDate !== "") ||
      (filterData.startDate === "" && filterData.endDate === "")
    ) {
      getTaxData();
    }
  }, [time, filterData, singleClient, singleClient?.accountRegCode])

  useEffect(() => {
    getClintName()
  }, [])

  return (
    <>
      <div className="dashSevDiv d-flex">
        {
          isInvestor || isBookKeeper &&
          <>
            <input
              style={{ position: "relative" }}
              type="search"
              placeholder="Client-Search"
              className="dshSevSeInp"
              value={clientInp}
              onChange={(e) => {
                setClientInp(e.target.value)
                setClientInpShow(e.target.value)
                const fltData = allClientNameData?.filter((ele) => {
                  return ele?.client_name?.toLowerCase().includes(e.target.value?.toLowerCase())
                })
                // console.log("fltDatadd", ele?.client_name?.toLowerCase(), e.target.value?.toLowerCase())
                // console.log("fltDatadd", fltData)
                setClientNameSearch(fltData)
              }}
            />
            {
              clientInpShow !== "" &&
              <div className="invDashboardDrop">
                {
                  clientNameSearch?.map((item, i) =>
                    <p
                      onClick={() => handleClientClick(item)}
                    >
                      {item?.client_name}
                    </p>
                  )
                }
              </div>
            }
          </>
        }
        {/* <input type="date" className="dshSecDateInp" /> */}

        {/* Start Date */}
        <div style={{ position: 'relative' }}>
          <button className='bookSecTopDivBtn' style={{ position: "relative" }}>
            {filterData.startDate ? filterData?.startDate : "Start Date"}
          </button>
          <Datetime
            className='bookTaxDetailManageStart'
            value={filterData?.startDate ? moment(filterData?.endDate) : ""}
            closeOnSelect
            onChange={(e) => setFilterData((prev) => {
              return {
                ...prev,
                startDate: moment.isMoment(e) ? e.format("yyyy-MM-DD") : ""
              }
            }
            )}
            timeFormat={false}
            // initialValue={language.end_date}
            input={true}
          />
        </div>

        {/* End Date */}
        <div style={{ position: 'relative' }}>
          <button className='bookSecTopDivBtn' style={{ position: "relative" }}>
            {filterData.endDate ? filterData.endDate : "End Date"}
          </button>
          <Datetime
            className='bookTaxDetailManageEnd'
            value={filterData?.startDate ? moment(filterData?.endDate) : ""}
            onChange={(e) => setFilterData((prev) => {
              return {
                ...prev,
                endDate: moment.isMoment(e) ? e.format("yyyy-MM-DD") : ""
              }
            }
            )}
            closeOnSelect
            // onChange={(e) => setFilterData((prev) => {
            //   return {
            //     ...prev,
            //     endData: moment.isMoment(e) ? e.format("yyyy-MM-DD") : ""
            //   }
            // }
            // )}
            timeFormat={false}
            // initialValue={language.end_date}
            input={true}
          />
        </div>

        {/* clear */}
        <div
          className='bookTaxDetClearButton'
          onClick={() => setFilterData(initFilter)}
        >
          Clear
        </div>

      </div>
      <div className="row">
        <div className="col-md-6">
          <div>
            <ReactApexChart
              options={options}
              series={series}
              type="bar"
              height={350}
            />
          </div>
        </div>

        <div className="col-md-6">
          <ReactApexChart
            options={options2}
            width={"100%"}
            height={300}
            series={series2}
            type="donut"
          />
        </div>
      </div>
      <div className="dshSevToExpDiv">
        <p className="dshTotHead">TOTAL EXPENSES:</p>
        <input
          type="text"
          value={`$${taxData?.totalSum ? taxData?.totalSumL : 0}`} className="dshTotInp" />
      </div>
    </>
  );
}

export default DashboardChartSeven;
