import React from 'react'
import iMg1 from "../../images/ilustraciones soluciones industriales_LEGAL - JURIDICO 1 2.png";
import iMg2 from "../../images/ilustraciones soluciones industriales_Mesa de trabajo 1 copia 79.png";
import iMg3 from "../../images/39982.png";
import { useLanguage } from '../../Providers/LanguageContext';
import SignUpButton from './SignUpButton';
const Legal = () => {
  const { language } = useLanguage();

  return (
    <>

      <section id='agriculture_sec'>
        <div className='container'>
          <div className='row mb-5'>
            <div className='col-md-6 col-12 m-auto'>
              <div className='agriculture_head'>
                <h3 style={{ color: "#000000" }}>{language.legal}</h3>
              </div>

              <div className='agriculture_headtext'>
                <h4 style={{ color: "#000000" }}>{language.automated_billing_and_invoicing}</h4>
                <p>{language.fisi_help_billing}
                </p>
              </div>
            </div>
            <div className='col-md-6 col-12 m-auto'>
              <div className='agriculture_img'>
                <img src={iMg2} className='img-fluid' alt='' />
              </div>
            </div>
          </div>

          <div className='row row_reverse mb-5'>
            <div className='col-md-6 col-12 m-auto'>
              <div className='agriculture_img'>
                <img src={iMg1} className='img-fluid' alt='' />
             
              </div>
            </div>
            <div className='col-md-6 col-12 m-auto'>
              <div className='agriculture_headtext'>
                <h4 style={{ color: "#000000", textAlign: "right" }}>{language.budget_track}</h4>
                <p style={{ textAlign: "justify" }}>{language.fisi_legal_track}</p>

              </div>
            </div>

          </div>



          <div className='row wrapBg_Img mt-5'>


            <div className='col-md-12 col-12 m-auto'>
              <div className='agriculture_headtext'>
                <h4 style={{ color: "#000000", textAlign: "right" }}>{language.client_trust_acc}</h4>
                <p style={{ textAlign: "justify" }}>{language.fisi_help_manage}
                </p>

              </div>
            </div>

            <div className='col-md-12 col-12 m-auto mt-5'>
              <div className='agriculture_headtext'>
                <h4 style={{ color: "#000000", textAlign: "left" }}>{language.customisable_reporting}</h4>
                <p style={{ textAlign: "justify" }}>{language.fisi_offers_customizabvle}</p>

              </div>
            </div>
            <div className='col-md-6 col-12 m-auto pt-5'>
              <div className='agriculture_headtext'>
                <h4 style={{ color: "#000000", textAlign: "left" }}>{language.integration_exist_soft}</h4>
                <p>{language.fisi_intigrate_exist}
                </p>
              </div>
            </div>

            <div className='col-md-6 col-12 m-auto mb-5'>
              <div className='agriculture_img' style={{ maxWidth: "60%", }}>
                <img src={iMg3} className='img-fluid' alt='' style={{ marginBottom: "30px" }} />

                {/* <div className='registrarAnddescargar' style={{ transform: "translate(-50% , -50%)", top: "100%", }}>
                  <button className='registrar_Btn' style={{ background:"#000" }}>Registrarse</button>
                  <button className='descargar_Btn'>Descargar</button>
                </div> */}
                  <SignUpButton/>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Legal