import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Bannerimage from "../../images/englishImage/gif home 1.gif";
import Bannerimage2 from "../../images/banner-bg-2.png";
import Bannerimage3 from "../../images/banner-bg-3.jpg";
import { useLanguage } from "../../Providers/LanguageContext";
import { UserContext } from "../../Providers/UserWrapper";
import Slider from "react-slick";

function Banner() {
  const { language } = useLanguage();
  const { profileData, logout } = useContext(UserContext);

  const [btnText, setBtnText] = useState();
  const navigate = useNavigate();
  function createMarkup(text) {
    return { __html: text };
  }

  useEffect(() => {
    if (profileData) {
      setBtnText(language.logout)
    } else {
      setBtnText(language.homeBannerBtn1)
    }
  }, [profileData])

  const handleBtnClick = () => {
    if (btnText === language.logout) {
      logout()
    } else {
      navigate('/login')
    }
  }

  var settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    // speed: 1000,
    autoplaySpeed: 3500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  return (
    <>
      <Slider {...settings}>
        <div>
          <section
            id="banner_area"
            style={{ backgroundImage: `url('${Bannerimage}')`, backgroundSize: "contain" }}
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-md-12 col-12">
                  <div className="left_cnt">
                    <div className="head">
                      <h2
                        dangerouslySetInnerHTML={createMarkup(
                          language.homeBannerHeading
                        )}
                      ></h2>
                    </div>

                    <div className="text">
                      <p>{language.homeBannerPara}</p>
                    </div>

                    <div className="bottom">
                      <div className="registrarse_btn">
                        <button onClick={handleBtnClick} className="btn">{profileData ? language.logout : language.homeBannerBtn1}</button>
                      </div>
                      <div className="descargar_btn">
                        <a href='https://fisibilitylite.com/app_api/share_url.html' target="_blank">
                          <button className="btn">{language.homeBannerBtn2}</button>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-12">
                  <div className="right_cnt"></div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div>
          <section
            id="banner_area"
            style={{ backgroundImage: `url('${Bannerimage2}')` }}
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-md-12 col-12">
                  <div className="left_cnt">
                    <div className="head">
                      <h2
                        dangerouslySetInnerHTML={createMarkup(
                          language.homeBannerHeading2
                        )}
                      ></h2>
                    </div>

                    {/* <div className="text">
                      <p>{language.homeBannerPara}</p>
                    </div> */}

                    <div className="bottom">
                      <div className="registrarse_btn">
                        <button onClick={handleBtnClick} className="btn">{profileData ? language.logout : language.homeBannerBtn1}</button>
                      </div>
                      <div className="descargar_btn">
                        <a href='https://fisibilitylite.com/app_api/share_url.html' target="_blank">
                          <button className="btn">{language.homeBannerBtn2}</button>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-12">
                  <div className="right_cnt"></div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div>
          <section
            id="banner_area"
            style={{ backgroundImage: `url('${Bannerimage3}')`, height: "693px" }}
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-md-12 col-12">
                  <div className="left_cnt">
                    <div className="head">
                      <h2
                        dangerouslySetInnerHTML={createMarkup(
                          language.homeBannerHeading3
                        )}
                      ></h2>
                    </div>

                    {/* <div className="text">
                      <p>{language.homeBannerPara}</p>
                    </div> */}

                    <div className="bottom">
                      <div className="registrarse_btn">
                        <button onClick={handleBtnClick} className="btn">{profileData ? language.logout : language.homeBannerBtn1}</button>
                      </div>
                      <div className="descargar_btn">
                        <a href='https://fisibilitylite.com/app_api/share_url.html' target="_blank">
                          <button className="btn">{language.homeBannerBtn2}</button>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-12">
                  <div className="right_cnt"></div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Slider>

    </>
  );
}

export default Banner;
