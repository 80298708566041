import React, { useState, useCallback } from 'react';
import { Calendar, DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import Modal from 'react-modal';

const closeStyle = {
    color: "#aaaaaa",
    float: "right",
    fontSize: "28px",
    fontWeight: "bold",
    cursor: "pointer"
}

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: "50%",

    },
};


const ReactDateRange = ({ modalIsOpen, closeModal, setState, state }) => {

    const [selectedDate, setSelectedDate] = useState(new Date());

    const handleSelect = useCallback((date) => {
        console.log(date); // native Date object
        setSelectedDate = (!selectedDate);
    }, []);

    return (
        <div>
            <Modal
                isOpen={modalIsOpen}
                // onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <span onClick={() => closeModal()} style={closeStyle}>&times;</span>

                <DateRange
                    editableDateInputs={true}
                    onChange={item => setState([item.selection])}
                    moveRangeOnFirstSelection={false}
                    ranges={state}
                />

            </Modal>


            {/* <Calendar
                date={selectedDate}
                onChange={handleSelect}
            /> */}

        </div>
    )
}

export default ReactDateRange