import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import BookCustDocMissingModal from '../../../Modal/BookCustDocMissingModal';
import MissingDocModal from '../../../Modal/MissingDocModal';
import ManageDocModal from '../../../Modal/ManageDocModal';
import HttpClient from '../../../utils/HttpClient';
import toast from 'react-hot-toast';
import { useUserData } from '../../../Providers/UserWrapper';
import { useLanguage } from '../../../Providers/LanguageContext';


const ManageAccount = ({ linkedAccData, setCallGetAcc }) => {
    const { setIsLoading } = useUserData()
    const navigate = useNavigate();
    const { language, currentLanguage } = useLanguage()
    const [modal, setModal] = useState(false);
    const [ismodal, setIsModal] = useState(false);
    const [singleUserData, setSingleUserData] = useState({});
    const [calCulatedData, setCalculatedData] = useState({ activeClient: "", price: "" });

    const initFilter = {
        name: "",
        startDate: "",
        endDate: "",
        month: "",
        year: ""
    }
    const [filterData, setFilterData] = useState(initFilter);
    const [AccData, setAccData] = useState([]);
    const [years, setYears] = useState([]);
    const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
    ];


    // console.log("calCulatedDatadd", calCulatedData)

    useEffect(() => {
        const startDate = new Date('2000-01-01'); // Change this to your desired starting date
        const currentDate = new Date();
        const startYear = startDate.getFullYear();
        const currentYear = currentDate.getFullYear();

        const yearRange = Array.from({ length: currentYear - startYear + 1 }, (_, index) => startYear + index);

        setYears(yearRange?.reverse());
    }, []);

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilterData(prev => ({ ...prev, [name]: value }))
    }

    const handleClick = (item) => {
        setModal(!modal)
        setSingleUserData(item)
    }

    const handClick = (item) => {
        setIsModal(!ismodal);
        setSingleUserData(item)
    }

    // send all reminder
    const handleSendAllReminder = async (item) => {
        setIsLoading(true);
        const data = {
            "language": currentLanguage === "eng" ? "EN" : "ES"
        }
        const res = await HttpClient.requestData("bookkeeper/send-missing-all-mail-user/" + item?.accountRegCode, "POST", data)
        console.log("itemmvv", item)
        setIsLoading(false);
        if (res && res?.status) {
            toast.success("A Reminder Mail has been Sent Successfully")
        } else {
            toast.error(res?.message)
        }
    }

    const getLinkedAccData = async () => {
        const data = {
            "clientName": filterData.name ? filterData.name : null,
            "month": filterData.month ? filterData.month : null,
            "year": filterData.year ? filterData.year : null,
            "startDate": filterData?.startDate ? filterData?.startDate : null,
            "endDate": filterData.endDate ? filterData.endDate : null
        }
        setIsLoading(true)
        const res = await HttpClient.requestData("bookkeeper/view-linkedaccount-filter", "POST", data);
        console.log("resssdd", res)
        setIsLoading(false)
        if (res && res?.status) {
            setAccData(res?.data)
            const newArr = res?.data?.filter(item => item?.acceptStatus === "accepted")
            const count = newArr?.length
            setCalculatedData({
                activeClient: count,
                price: currentLanguage === "eng" ? count * 599.00 : count * 1999.00
            })

            getInternalCode()
        }
        else {

        }
    }

    useEffect(() => {
        if (
            (filterData.startDate !== "" && filterData.endDate !== "") ||
            (filterData.startDate === "" && filterData.endDate === "")
        ) {
            getLinkedAccData()
        } else if (
            (filterData.startDate !== "" && filterData.endDate !== "") ||
            (filterData.startDate === "" && filterData.endDate === "")
        ) {
            getLinkedAccData()
        }

    }, [filterData.startDate, filterData.endDate, filterData.month, filterData.year, currentLanguage])

    useEffect(() => {
        // if (filterData.vendorName) {
        const timer = setTimeout(() => {
            getLinkedAccData()
        }, 600);

        return () => clearTimeout(timer)
        // }
    }, [filterData.name])

    const addInternalCode = async (accountRegCode, index) => {
        let code = AccData[index].internalCode

        if (AccData[index].internalCodeError) {
            toast.error("Internal code already exists !!!")
            return
        }

        if (!code || code == '') {
            return
        }

        let data = {
            internalCode: code,
            accountRegCode: accountRegCode
        };
        setIsLoading(true)
        const res = await HttpClient.requestData(
            "bookkeeper/createinternalcode_book",
            "POST",
            data
        );
        if (res && res?.status) {
            setIsLoading(false)
            return toast.success(res?.message || "Saved successfully...!")
        } else {
            return toast.error(res?.message || "Something went wrong...!")
        }

        console.log("res", res)
    };

    const checkInternalCode = async (index, value) => {
        let data = {
            internalCode: value
        }

        const res = await HttpClient.requestData(
            "bookkeeper/checkinternalcode_book",
            "POST",
            data
        );

        if (res && !res.status) {
            if (value != '') {
                setAccData(state => {
                    state[index].internalCodeError = true;

                    return JSON.parse(JSON.stringify(state))
                })
            } else {
                setAccData(state => {
                    state[index].internalCodeError = false;

                    return JSON.parse(JSON.stringify(state))
                })
            }
        } else {
            setAccData(state => {
                state[index].internalCodeError = false;

                return JSON.parse(JSON.stringify(state))
            })
        }
    }

    const getInternalCode = async () => {
        const res = await HttpClient.requestData(
            "bookkeeper/userinternalcode_book",
            "GET"
        );

        if (res && res.status) {
            res.data.forEach(element => {
                setAccData(state => {
                    let index = state.findIndex(it => it.accountRegCode == element.assignuserregcode)

                    if (index >= 0) {
                        state[index].internalCode = element.internalCode
                    }

                    return JSON.parse(JSON.stringify(state))
                })
            });
        }
    };


    return (
        <div className='container'>
            <section>

                {/* Headings */}
                <div>
                    {/* <h1 className='BookLinkHading'>Bookkeeper Manager - Link & Manage Accounts</h1> */}
                    <h6 className='BookLinkSubHeading'>{language.manage_acc_dash}</h6>
                </div>

                {/* middle part */}
                <div>
                    <div className='row'>

                        {/* left part form */}
                        <div className='col-md-12 col-lg-6'>
                            <form className='bookManageAcctForm'>
                                <div className='bookLinkFormDiv form-group' >
                                    <label>{language.client_name}:</label>
                                    <div className='bookformInp'>
                                        <input
                                            type='text'
                                            placeholder={language.type_to_search}
                                            style={{ width: "100%" }}
                                            class="form-control"
                                            id="exampleFormControlInput1"
                                            name='name'
                                            value={filterData.name}
                                            onChange={(e) => handleFilterChange(e)}
                                        />
                                    </div>
                                </div>

                                <div className='bookLinkFormDiv form-group' >
                                    <label>{language.date_range}:</label>
                                    <div className='bookformInp'>
                                        <input
                                            type='date'
                                            class="form-control"
                                            id="exampleFormControlInput1"
                                            name='startDate'
                                            value={filterData.startDate}
                                            onChange={(e) => {
                                                setFilterData(prev => ({ ...prev, month: "", year: "" }))
                                                handleFilterChange(e)
                                            }
                                            }
                                        />
                                        <input
                                            type='date'
                                            class="form-control"
                                            id="exampleFormControlInput1"
                                            name='endDate'
                                            value={filterData.endDate}
                                            onChange={(e) => {
                                                setFilterData(prev => ({ ...prev, month: "", year: "" }))
                                                handleFilterChange(e)
                                            }
                                            }
                                        />
                                    </div>
                                </div>

                                <div className='bookLinkFormDiv form-group' >
                                    <label>{language.month_year}:</label>
                                    <div className='bookformInp'>
                                        {/* <input placeholder='Month' class="form-control" id="exampleFormControlInput1" />
                                        <input placeholder='Year' class="form-control" id="exampleFormControlInput1" /> */}
                                        <select
                                            name='month'
                                            value={filterData.month}
                                            onChange={(e) => {
                                                setFilterData(prev => ({ ...prev, startDate: "", endDate: "" }))
                                                handleFilterChange(e)
                                            }
                                            }
                                        >
                                            <option value="" disabled>{language.month}</option>
                                            {
                                                months.map((item, i) =>
                                                    <option key={i} value={i + 1}>{item}</option>
                                                )
                                            }
                                        </select>

                                        <select
                                            name='year'
                                            value={filterData.year}
                                            onChange={(e) => {
                                                setFilterData(prev => ({ ...prev, startDate: "", endDate: "" }))
                                                handleFilterChange(e)
                                            }
                                            }
                                        >
                                            <option value="" disabled>{language.year}</option>
                                            {
                                                years.map((item, i) =>
                                                    <option key={i} value={item}>{item}</option>
                                                )
                                            }
                                        </select>
                                    </div>
                                </div>

                                <div className='clearAll'>
                                    <Link className='clearAllLink'>{language.clear_all}</Link>
                                </div>

                            </form>
                        </div>

                        {/* right part */}
                        <div className='col-md-12 col-lg-6'>
                            <ul className='acctDashUl'>
                                <li>
                                    <span className='LinkBookHead'>{language.total_activities_client}: </span>
                                    <span className='LinkBookNumber'>{calCulatedData?.activeClient}</span>
                                </li>

                                <li>
                                    <span className='LinkBookHead'>{language.total_monthly_fee}: </span>
                                    <span className='LinkBookNumber'>
                                        $ {calCulatedData?.price} {currentLanguage === "eng" ? "USD" : "MXN"}
                                    </span>
                                </li>

                                <li>
                                    <span className='LinkBookHead'>{language.avg_monthly_fee}: </span>
                                    <span className='LinkBookNumber'>
                                        $ {calCulatedData?.price} {currentLanguage === "eng" ? "USD" : "MXN"}
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                {/* add more part */}
                <div className='table-responsive outerTblDiv'>
                    <table className="buildCapitalTable mt-3 mb-5">
                        <thead>
                            <tr>
                                <th className="buildCapMiddleTh">
                                    <span className="buildCapLeftThSpan">{language.client_name}</span>
                                </th>
                                <th className="buildCapMiddleTh">
                                    <span className="buildCapLeftThSpan">{language.missing_docs}</span>
                                </th>
                                <th className="buildCapMiddleTh">
                                    <span className="buildCapLeftThSpan">{language.reminder}</span>
                                </th>
                                <th className="buildCapMiddleTh">
                                    <span className="buildCapLeftThSpan">{language.account_code}</span>
                                </th>
                                <th className="buildCapMiddleTh">
                                    <span className="buildCapLeftThSpan">{language.internal_code}</span>
                                </th>
                                <th className="buildCapMiddleTh">
                                    <span className="buildCapLeftThSpan">{language.monthly_fee} $</span>
                                </th>
                                <th className="buildCapMiddleTh">
                                    <span className="buildCapLeftThSpan">{language.performance}</span>
                                </th>
                            </tr>
                        </thead>


                        <tbody>
                            {AccData?.map((item, i) => {
                                // console.log("item", item)
                                return (
                                    <tr key={i}>
                                        <td className="buildCapMiddleTd" onClick={() => handClick(item)}>
                                            {
                                                item?.acceptStatus === "accepted"
                                                    ?
                                                    <Link to='' className='buildCapMidlink'>{item?.client_name}</Link>
                                                    :
                                                    "-"
                                            }
                                        </td>
                                        <td className="buildCapMiddleTd" onClick={() => handleClick(item)}>
                                            <Link to='' className='buildCapMidlink'>{item?.missingDocument}</Link>
                                        </td>
                                        <td className="buildCapMiddleTd" >
                                            <button
                                                className='buildCapTblBtn'
                                                onClick={() => handleSendAllReminder(item)}
                                            >
                                                {language.send}
                                            </button>
                                        </td>

                                        <td className="buildCapMiddleTd">
                                            <input
                                                className='bookLinkTableInput'
                                                type="text"
                                                name="typeOfUnits"
                                                placeholder='710552470'
                                                value={item.accountRegCode}
                                                // onChange={(e) => handleChangeOwner(e, i)}
                                                readOnly
                                            />
                                        </td>

                                        <td className="buildCapMiddleTd">
                                            <input
                                                className='bookLinkTableInput'
                                                type="text"
                                                name="percent"
                                                placeholder='E&Y ID: 1234'
                                                value={item.internalCode}
                                                onChange={(e) => {
                                                    setAccData((state) => {
                                                        state[i].internalCode = e.target.value;

                                                        return JSON.parse(JSON.stringify(state));
                                                    });
                                                    checkInternalCode(i, e.target.value)
                                                }}
                                                style={{
                                                    borderColor: item.internalCodeError ? 'red' : null
                                                }}
                                            />

                                            <button
                                                className="invLinkSaveBtn"
                                                onClick={() => addInternalCode(item.accountRegCode, i)}
                                            >{language.save}</button>
                                        </td>
                                        <td className="buildCapMiddleTd">
                                            {
                                                item?.acceptStatus === "accepted"
                                                    ?
                                                    currentLanguage === "eng" ? "$599.00 USD" : "$1999.00 MXN"
                                                    :
                                                    "-"
                                            }
                                        </td>

                                        <td className="buildCapMiddleTd">
                                            <button
                                                className="btn btn-success"
                                                onClick={() =>
                                                    navigate("/dashboard-manager", {
                                                        state: {
                                                            userRegCodr: item?.accountRegCode,
                                                            slideNo: 0,
                                                        },
                                                    })
                                                }
                                            >
                                                {language.view}
                                            </button>
                                        </td>
                                    </tr>
                                )
                            })}

                        </tbody>
                    </table>
                </div>

                {/* dd */}

            </section>

            {/* missing doc modal */}
            {modal && <MissingDocModal
                closeModal={setModal}
                singleUserData={singleUserData}
            />}

            {ismodal && <ManageDocModal
                closeModal={setIsModal}
                singleUserData={singleUserData}
                setCallGetAcc={setCallGetAcc}
            />}
        </div>
    )
}

export default ManageAccount
