import React, { useEffect, useState } from 'react'
import { useLanguage } from '../../../../Providers/LanguageContext'
import HttpClient from '../../../../utils/HttpClient';
import { useNavigate } from 'react-router-dom';
import { useUserData } from '../../../../Providers/UserWrapper';

const ViewInvoiceForm2 = ({ filters, normalInvData }) => {
    const { language } = useLanguage();
    const navigete = useNavigate();


    // useEffect(() => {
    //     getAllNormalInvData();
    // }, [filters])

    return (
        <div>
            {/* <div id='clafication'>
                <div className='container'>
                    <div className='table-top'>
                        <div className='top-left'>
                            ppppppppppppppp
                            <form>
                                <div className='top-select'>
                                    <div class="form-group">
                                        <select class="form-control" id="exampleFormControlSelect1"
                                        >
                                            <option>{language.classification}</option>
                                            <option value="Recent">{language.most_recent}</option>
                                            <option value="oldest">{language.oldest}</option>
                                            <option value="A-Z">Name A-Z</option>
                                            <option value="Z-A">Name Z-A</option>
                                        </select>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className='top-right'>
                            <span className='todo-btn' id="filterbtn">{language.all_results}</span>
                        </div>
                    </div>
                </div>
            </div> */}
            {normalInvData.length !== 0 &&
                <div className='mt-3'>
                    <h3>Normal Invoice</h3>
                </div>
            }

            <div id='todo-table'>
                <div className='container'>
                    <div className='table-responsive'>
                        <table className="table">
                            <tbody>
                                {normalInvData && normalInvData.map((item, i) => {
                                    return (
                                        <tr style={{ backgroundColor: "#ebeff3" }} key={i}>
                                            <td style={{
                                                color: "#0e2537",
                                                fontWeight: "600",
                                                fontSize: "18px",
                                                paddingLeft: "20px",
                                                borderTop: "0"
                                            }}>
                                                {item?.customerName}
                                            </td>
                                            <td style={{
                                                display: "flex",
                                                justifyContent: "flex-end",
                                                alignItems: "flex-end",
                                                paddingRight: "30px"
                                            }}>
                                                <button
                                                    style={{
                                                        maxWidth: "110px",
                                                        width: "100%",
                                                        height: "30px",
                                                        borderRadius: "10px",
                                                        background: "linear-gradient(to right, #18465e 1%, #14384d 51%, #0f273a 100%)",
                                                        border: "none", color: "#fff", fontSize: "16px"
                                                    }}
                                                    onClick={() => navigete(`/view-normal-invoice/${item?._id}`)}
                                                >
                                                    {language.view}
                                                </button>
                                            </td>
                                        </tr>
                                    )
                                })

                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default ViewInvoiceForm2
