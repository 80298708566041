import React from 'react'
import { useNavigate } from 'react-router-dom';
import iMg1 from "../../images/ilustraciones soluciones industriales_Mesa de trabajo 1 copia 69.png";
import iMg2 from "../../images/ilustraciones soluciones industriales_Mesa de trabajo 1 copia 70.png";
import iMg3 from "../../images/ilustraciones soluciones industriales_PETROLEO Y GAS 3.png";
import { useLanguage } from '../../Providers/LanguageContext';
import SignUpButton2 from './SignUpButton2';
export default function Technology() {
  const { language } = useLanguage();
  const navigate=useNavigate();

  return (
    <>

      <section id='agriculture_sec'>
        <div className='container'>
          <div className='row mb-5'>
            <div className='col-md-6 col-12 m-auto'>
              <div className='agriculture_head'>
                <h3 style={{ color: "#000000" }}>{language.technology}</h3>
              </div>

              <div className='agriculture_headtext'>
                <h4 style={{ color: "#000000" }}>{language.expanse_tracking}</h4>
                <p>{language.fisi_allow_business_easily}
                </p>
              </div>
            </div>
            <div className='col-md-6 col-12 m-auto'>
              <div className='agriculture_img'>
                <img src={iMg2} className='img-fluid' alt='' />
              </div>
            </div>
          </div>


          <div className='row row_reverse mb-5'>
            <div className='col-md-6 col-12 m-auto'>
              <div className='agriculture_img'>
                <img src={iMg1} className='img-fluid' alt='' />
                {/* <div className='registrarAnddescargar' style={{ transform: "translate(-50% , -50%)", top: "45%", left: "20%", flexDirection: "column" }}>
                  <button onClick={()=>navigate('/login')} className='registrar_Btn' style={{ background: "#000000", border: "0", marginBottom: "20px" }}>{language.homeBannerBtn1}</button>
                  <button className='descargar_Btn'>{language.homeBannerBtn2}</button>
                </div> */}
                <SignUpButton2/>
              </div>
            </div>
            <div className='col-md-6 col-12 m-auto'>
              <div className='agriculture_headtext'>
                <h4 style={{ color: "#000000", textAlign: "right" }}>{language.project_management}</h4>
                <p style={{ textAlign: "justify" }}>{language.fisi_includes_project}</p>

              </div>
            </div>

          </div>

          <div className='col-md-12 col-12 m-auto'>
            <div className='agriculture_headtext'>
              <h4 style={{ color: "#000000", textAlign: "left" }}>{language.financial_analysis}</h4>
              <p style={{ textAlign: "justify" }}>{language.fisi_provide_business_powerful}
              </p>

            </div>
          </div>


        </div>
      </section>

    </>
  )
}
