import React, { useState } from "react";
import { toast } from "react-hot-toast";
import { useLanguage } from "../../Providers/LanguageContext";
import HttpClient from "../../utils/HttpClient";

export default function SearchInvestment({
  setIsSearchInv,
  setIsSearchResult,
  setSearchResultData,
  setAllSearchData
}) {
  const { language, currentLanguage } = useLanguage();
  const initData = {
    businessName: "",
    keyPersonName: "",
    investmentEmail: "",
    taxID: "",
    rfcID: "",
    crudID: "",
  };
  const [searchData, setSearchData] = useState(initData);
  console.log("searchData", searchData);

  const handleSearch = async (e) => {
    e.preventDefault();
    if (
      !searchData.businessName &&
      !searchData.crudID &&
      !searchData.investmentEmail &&
      !searchData.keyPersonName &&
      !searchData.rfcID &&
      !searchData.taxID
    ) {
      toast.error(language.please_enter_Data)
    }
    else {
      const res = await HttpClient.requestData("search-investment", "POST", searchData);
      console.log('searchRes', res);
      if (res && res.status) {
        setAllSearchData(res.data)
        setSearchResultData(res.data[0])
        setIsSearchResult(true)
        setIsSearchInv(false)
      } else {
        toast.error(language.no_data)
      }
    }
  }

  return (
    <>
      <div className="RegisterInvest">
        <section className="Regiter SearchInvest">
          <div className="heading">
            <h1 style={{ fontWeight: "400" }}>{language.search_investment}</h1>
          </div>
          <div onClick={() => setIsSearchInv(false)}>
            <a href="#" className="closeIcon">
              <i className="fa fa-times" aria-hidden="true"></i>
            </a>
          </div>
          <p className="datos">{language.data}</p>
          <form>
            <div className="form-group row m-0" style={{ alignItems: "center" }}>
              <label
                htmlFor="inputPassword"
                className="col-sm-4 col-form-label"
              >
                {language.business_name}
              </label>
              <div className="col-sm-6">
                <input
                  type="text"
                  className="form-control"
                  id="inputPassword"
                  placeholder=""
                  value={searchData.businessName}
                  onChange={(e) =>
                    setSearchData((prev) => {
                      return { ...prev, businessName: e.target.value };
                    })
                  }
                />
              </div>
            </div>
            <div className="form-group row m-0" style={{ alignItems: "center" }}>
              <label
                htmlFor="inputPassword"
                className="col-sm-4 col-form-label"
              >
                {language.key_person_name}
              </label>
              <div className="col-sm-6">
                <input
                  type="text"
                  className="form-control"
                  id="inputPassword"
                  placeholder=""
                  value={searchData.keyPersonName}
                  onChange={(e) =>
                    setSearchData((prev) => {
                      return { ...prev, keyPersonName: e.target.value };
                    })
                  }
                />
              </div>
            </div>
            <div className="form-group row m-0" style={{ alignItems: "center" }}>
              <label
                htmlFor="inputPassword"
                className="col-sm-4 col-form-label"
              >
                {language.investment_email}
              </label>
              <div className="col-sm-6">
                <input
                  type="text"
                  className="form-control"
                  id="inputPassword"
                  placeholder=""
                  value={searchData.investmentEmail}
                  onChange={(e) =>
                    setSearchData((prev) => {
                      return { ...prev, investmentEmail: e.target.value };
                    })
                  }
                />
              </div>
            </div>
            <div className="form-group row m-0" style={{ alignItems: "center" }}>
              <label
                htmlFor="inputPassword"
                className="col-sm-4 col-form-label"
              >
                Tax ID#
              </label>
              <div className="col-sm-6">
                <input
                  type="text"
                  className="form-control"
                  id="inputPassword"
                  placeholder=""
                  value={searchData.taxID}
                  onChange={(e) =>
                    setSearchData((prev) => {
                      return { ...prev, taxID: e.target.value };
                    })
                  }
                />
              </div>
            </div>
            <div className="form-group row m-0" style={{ alignItems: "center" }}>
              <label
                htmlFor="inputPassword"
                className="col-sm-4 col-form-label"
              >
                RFC ID
              </label>
              <div className="col-sm-6">
                <input
                  type="text"
                  className="form-control"
                  id="inputPassword"
                  placeholder=""
                  value={searchData.rfcID}
                  onChange={(e) =>
                    setSearchData((prev) => {
                      return { ...prev, rfcID: e.target.value };
                    })
                  }
                />
              </div>
            </div>
            <div className="form-group row" style={{ alignItems: "center" }}>
              <label
                htmlFor="inputPassword"
                className="col-sm-4 col-form-label"
              >
                SS# / CURP ID
              </label>
              <div className="col-sm-6">
                <input
                  type="text"
                  className="form-control"
                  id="inputPassword"
                  placeholder=""
                  value={searchData.crudID}
                  onChange={(e) =>
                    setSearchData((prev) => {
                      return { ...prev, crudID: e.target.value };
                    })
                  }
                />
              </div>
            </div>
            <div className="register d-flex justify-content-between align-items-center mt-5">
              <div className="">
                <button
                  className="return"
                  onClick={() => setIsSearchInv(false)}
                >
                  {language.return}
                </button>
              </div>
              <div className="">
                <div className="confirm">
                  <button onClick={handleSearch}>{language.search}</button>
                </div>
              </div>
            </div>
          </form>
        </section>
      </div>
    </>
  );
}
