import React, { useState } from 'react'
import { toast } from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import { useLanguage } from '../Providers/LanguageContext';
import HttpClient from '../utils/HttpClient';

export default function ConfirmPassword() {
    const { language } = useLanguage();
    const [forgotEmail, setForgotEmail] = useState('');
    const [passData, setPassData] = useState("");
    const [conPass, setConPass] = useState("");
    const { id } = useParams();
    const navigate = useNavigate();

    const handleSave = async (e) => {
        e.preventDefault();
        if (!passData) {
            toast.error(language.password_validate_blank);
            return;
        }
        else if (passData.length < 6) {
            toast.error(language.password_validate_min);
            return;
        }

        if (!conPass) {
            toast.error(language.confirm_password_validate_blank);
            return
        }
        else if (conPass.length < 6) {
            toast.error(language.password_validate_min);
            return
        }
        else if (conPass !== passData) {
            toast.error(language.confirm_password_validate_match);
            return;
        }
        const sendData = {
            password: passData
        }
        const res = await HttpClient.requestData(`update-password/${id}`, "PUT", sendData);
        if (res && res.status) {
            toast.success(language.pass_update_success);
            navigate("/login");
        }
    }

    return (

        <>

            <section id='restore'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-xl-12 col-lg-12 col-md-12 col-12'>
                            <div className='heading'>
                                {/* <h3>{language.restore_password}</h3> */}
                                <h3>Confirm Password</h3>
                            </div>
                            {/* <div className='heading-description'>
                                <p>{language.enter_email_forgot}</p>
                                <p>{language.send_you_link}</p>
                            </div> */}
                        </div>
                    </div>
                </div>

                <div className='inner-container'>
                    <form className='form'>
                        <div className="form-group">
                            <input
                                type="password"
                                className="form-control"
                                id=""
                                aria-describedby=""
                                placeholder={language.password_placeholder}
                                onChange={(e) => {
                                    setPassData(e.target.value);
                                }}
                            />
                        </div>
                        <div className="form-group">
                            <input
                                type="password"
                                className="form-control"
                                id=""
                                aria-describedby=""
                                placeholder={language.confirm_password_placeholder}
                                onChange={(e) => setConPass(e.target.value)}
                            />
                        </div>
                        {/* <p>{language.not_recieve_link} <a href="#">{language.resend}</a></p> */}
                        <div className='reset-btn'>
                            <button onClick={handleSave} type='submit' className='password-submit'>{language.save}</button>
                        </div>
                    </form>
                </div>

            </section>

        </>

    )
}
