import React from 'react'
import iMg1 from "../../images/ilustraciones soluciones industriales_PETROLEO Y GAS 2.png";
import iMg2 from "../../images/ilustraciones soluciones industriales_PETROLEO Y GAS 1.png";
import iMg3 from "../../images/ilustraciones soluciones industriales_PETROLEO Y GAS 3.png";
import iMg3en from "../../images/ilustraciones soluciones industriales_PETROLEO Y GAS 3 EN.png";

import { useLanguage } from '../../Providers/LanguageContext';
import SignUpButton from './SignUpButton';
const OilAndGas = () => {
  const { language, currentLanguage } = useLanguage();

  return (
    <>

      <section id='agriculture_sec'>
        <div className='container'>
          <div className='row mb-5'>
            <div className='col-md-6 col-12 m-auto'>
              <div className='agriculture_head'>
                <h3 style={{ color: "#000000" }}>{language.oil_and_gas}</h3>
              </div>

              <div className='agriculture_headtext'>
                <h4 style={{ color: "#000000" }}>{language.fuel_mamagement}</h4>
                <p>{language.fisi_provide_visibility}
                </p>
              </div>
            </div>
            <div className='col-md-6 col-12 m-auto'>
              <div className='agriculture_img'>
                <img src={iMg2} className='img-fluid' alt='' />
              </div>
            </div>
          </div>

          <div className='col-md-12 col-12 m-auto'>
            <div className='agriculture_headtext'>
              <h4 style={{ color: "#000000", textAlign: "left" }}>{language.retail_management}</h4>
              <p style={{ textAlign: "justify" }}>{language.fisi_offers_retail}
              </p>

            </div>
          </div>
          <div className='row row_reverse mb-5'>
            <div className='col-md-6 col-12 m-auto'>
              <div className='agriculture_img'>
                <img src={iMg1} className='img-fluid' alt='' />

              </div>
            </div>
            <div className='col-md-6 col-12 m-auto'>
              <div className='agriculture_headtext'>
                <h4 style={{ color: "#000000", textAlign: "right" }}>{language.consolidated_analysis}</h4>
                <p style={{ textAlign: "justify" }}>{language.mention_earlier_fisi}</p>

              </div>
            </div>

          </div>



          <div className='row wrapBg_Img mt-5'>



            <div className='col-md-12 col-12 m-auto mt-5'>
              <div className='agriculture_headtext'>
                <h4 style={{ color: "#000000", textAlign: "left" }}>{language.cost_control}</h4>
                <p style={{ textAlign: "justify" }}>{language.fisi_help_gas_station}</p>

              </div>
            </div>
            <div className='col-md-7 col-12 m-auto pt-5'>
              <div className='agriculture_headtext'>
                <h4 style={{ color: "#000000", textAlign: "left" }}>{language.regulatory_compliance}</h4>
                <p>{language.oil_and_gas_heavuily}
                </p>
              </div>
            </div>

            <div className='col-md-5 col-12 m-auto mb-5'>
              <div className='agriculture_img' style={{ maxWidth: "90%", }}>
                {
                  currentLanguage === 'spanish'
                    ?
                    <img src={iMg3} className='img-fluid' alt='' />
                    :
                    <img src={iMg3en} className='img-fluid' alt='' />
                }
                {/* <div className='registrarAnddescargar' style={{ transform: "translate(-50% , -50%)", top: "77%", }}>
                  <button className='registrar_Btn' style={{ background: "#ed7502", border: "0" }}>Registrarse</button>
                  <button className='descargar_Btn'>Descargar</button>
                </div> */}
                <SignUpButton />
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  )
}

export default OilAndGas