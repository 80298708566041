import React, { useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

const PublicRouter = () => {
    console.log("private router");
    // const [isLogin]=useState(()=>{
    //     const userData=localStorage.getItem("userData");
    //     if(userData){
    //         return true;
    //     }
    //     else{
    //         return false;
    //     }
    // })
    // console.log(isLogin);

    if(!localStorage.getItem("userData")){
        return <Outlet />
    }else{
        return <Navigate to="/" />
    }

//     return (
//       isLogin ?<Outlet/> :<Navigate to='/login'/>
//   )
}

export default PublicRouter;