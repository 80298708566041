import React, { useEffect, useState } from 'react'
import { useLanguage } from '../../Providers/LanguageContext';
import HttpClient from '../../utils/HttpClient';

const ViewBussinessFS = ({ setBuildRepData }) => {

    //   >>>>Current Assets Default Value<<<< 
    const defaultCurrentAssets = [
        {
            title: "Cash – checking accounts",
            price: ""
        },
        {
            title: "Cash – savings accounts",
            price: ""
        },
        {
            title: "Certificates of deposit",
            price: ""
        },
        {
            title: "Securities – stocks / bonds / mutual funds",
            price: ""
        },
        {
            title: "Notes & contracts receivable",
            price: ""
        },
        {
            title: "Advances to Employees",
            price: ""
        },
        {
            title: "Prepaid Expenses",
            price: ""
        },
        {
            title: "Other Current Assets",
            price: ""
        }
    ]

    // >>>>Fixed Assets Default Value<<<<
    const defaultFixedAsset = [
        {
            title: "Real-estate and buildings",
            price: ""
        },
        {
            title: "Less Accumulated Depreciation",
            price: ""
        },
        {
            title: "Furniture, Equipment, & Vehicles",
            price: ""
        },
        {
            title: "Less Accumulated Depreciation",
            price: ""
        },
        {
            title: "Other Fixed Assets",
            price: ""
        },
        {
            title: "Other Long Term Assets",
            price: ""
        }

    ]

    // >>>>Current Liability Default Value<<<<
    const defaultCurrentLiability = [
        {
            title: "Accounts Payable",
            price: ""
        },
        {
            title: "Accrued Interest on Borrowings",
            price: ""
        },
        {
            title: "Notes Payable – Current Portion",
            price: ""
        },
        {
            title: "Accrued Taxes on Real Estate",
            price: ""
        },
        {
            title: "Accrued Taxes Other",
            price: ""
        },
        {
            title: "Other Current Payables",
            price: ""
        }
    ]

    // >>>>Long-Term Liability Default Value<<<<
    const defaultLongTermLiability = [
        {
            title: "Notes Payable",
            price: ""
        },
        {
            title: "Notes Payable on Real Estate",
            price: ""
        },
        {
            title: "Other Long-Term Liabilities",
            price: ""
        }
    ]

    // >>>>Financial Institutions Default Value<<<<
    const defaultFinancialInstitutions = [
        {
            nameOfFinancialInstitution: "",
            accountInNameOf: "",
            typeOfAccount: "",
            accountNumber: "",
            balance: "",
        },
        {
            nameOfFinancialInstitution: "",
            accountInNameOf: "",
            typeOfAccount: "",
            accountNumber: "",
            balance: "",
        },
        {
            nameOfFinancialInstitution: "",
            accountInNameOf: "",
            typeOfAccount: "",
            accountNumber: "",
            balance: "",
        },
        {
            nameOfFinancialInstitution: "",
            accountInNameOf: "",
            typeOfAccount: "",
            accountNumber: "",
            balance: "",
        },
        {
            nameOfFinancialInstitution: "",
            accountInNameOf: "",
            typeOfAccount: "",
            accountNumber: "",
            balance: "",
        },
        {
            nameOfFinancialInstitution: "",
            accountInNameOf: "",
            typeOfAccount: "",
            accountNumber: "",
            balance: "",
        },
        {
            nameOfFinancialInstitution: "",
            accountInNameOf: "",
            typeOfAccount: "",
            accountNumber: "",
            balance: "",
        }
    ]

    // >>>>Certificate Deposit Default Value<<<<
    const defaultCertificateDeposit = [
        {
            nameOfFinancialInstitution: "",
            accountInNameOf: "",
            ifPledgedStateToWhom: "",
            accountNumber: "",
            balance: "",
        },
        {
            nameOfFinancialInstitution: "",
            accountInNameOf: "",
            ifPledgedStateToWhom: "",
            accountNumber: "",
            balance: "",
        },
        {
            nameOfFinancialInstitution: "",
            accountInNameOf: "",
            ifPledgedStateToWhom: "",
            accountNumber: "",
            balance: "",
        },
        {
            nameOfFinancialInstitution: "",
            accountInNameOf: "",
            ifPledgedStateToWhom: "",
            accountNumber: "",
            balance: "",
        },
        {
            nameOfFinancialInstitution: "",
            accountInNameOf: "",
            ifPledgedStateToWhom: "",
            accountNumber: "",
            balance: "",
        },
        {
            nameOfFinancialInstitution: "",
            accountInNameOf: "",
            ifPledgedStateToWhom: "",
            accountNumber: "",
            balance: "",
        },
        {
            nameOfFinancialInstitution: "",
            accountInNameOf: "",
            ifPledgedStateToWhom: "",
            accountNumber: "",
            balance: "",
        }
    ]

    // >>>>Marketable Assets Default Value<<<<
    const defaultMarketableAssets = [
        {
            faceValueBondsShares: "",
            descriptionOfSecurity: "",
            registeredInNameOf: "",
            cost: "",
            incomeReceivedLastYear: "",
            ifPledgedStateToWhom: "",
            presentMarketValue: ""
        },
        {
            faceValueBondsShares: "",
            descriptionOfSecurity: "",
            registeredInNameOf: "",
            cost: "",
            incomeReceivedLastYear: "",
            ifPledgedStateToWhom: "",
            presentMarketValue: ""
        },
        {
            faceValueBondsShares: "",
            descriptionOfSecurity: "",
            registeredInNameOf: "",
            cost: "",
            incomeReceivedLastYear: "",
            ifPledgedStateToWhom: "",
            presentMarketValue: ""
        },
        {
            faceValueBondsShares: "",
            descriptionOfSecurity: "",
            registeredInNameOf: "",
            cost: "",
            incomeReceivedLastYear: "",
            ifPledgedStateToWhom: "",
            presentMarketValue: ""
        },
        {
            faceValueBondsShares: "",
            descriptionOfSecurity: "",
            registeredInNameOf: "",
            cost: "",
            incomeReceivedLastYear: "",
            ifPledgedStateToWhom: "",
            presentMarketValue: ""
        },
        {
            faceValueBondsShares: "",
            descriptionOfSecurity: "",
            registeredInNameOf: "",
            cost: "",
            incomeReceivedLastYear: "",
            ifPledgedStateToWhom: "",
            presentMarketValue: ""
        },
        {
            faceValueBondsShares: "",
            descriptionOfSecurity: "",
            registeredInNameOf: "",
            cost: "",
            incomeReceivedLastYear: "",
            ifPledgedStateToWhom: "",
            presentMarketValue: ""
        }
    ]

    // >>>>Notes Payable Default Value<<<<
    const defaultNotesPayable = [
        {
            nameAddressDebtor: "",
            ageOfDebt: "",
            descriptionOfDebt: "",
            descriptionOfSecurityHeld: "",
            dateOfPayment: "",
            amountOwing: "",
        },
        {
            nameAddressDebtor: "",
            ageOfDebt: "",
            descriptionOfDebt: "",
            descriptionOfSecurityHeld: "",
            dateOfPayment: "",
            amountOwing: "",
        },
        {
            nameAddressDebtor: "",
            ageOfDebt: "",
            descriptionOfDebt: "",
            descriptionOfSecurityHeld: "",
            dateOfPayment: "",
            amountOwing: "",
        },
        {
            nameAddressDebtor: "",
            ageOfDebt: "",
            descriptionOfDebt: "",
            descriptionOfSecurityHeld: "",
            dateOfPayment: "",
            amountOwing: "",
        },
        {
            nameAddressDebtor: "",
            ageOfDebt: "",
            descriptionOfDebt: "",
            descriptionOfSecurityHeld: "",
            dateOfPayment: "",
            amountOwing: "",
        },
        {
            nameAddressDebtor: "",
            ageOfDebt: "",
            descriptionOfDebt: "",
            descriptionOfSecurityHeld: "",
            dateOfPayment: "",
            amountOwing: "",
        },
        {
            nameAddressDebtor: "",
            ageOfDebt: "",
            descriptionOfDebt: "",
            descriptionOfSecurityHeld: "",
            dateOfPayment: "",
            amountOwing: "",
        }
    ]

    // >>>>Prepaid Expenses Default Value<<<<
    const defaultPrepaidExpenses = [
        {
            typeOfPrepaid: "",
            toWhomPaid: "",
            expirationDate: "",
            originalAmount: "",
            currentBalance: "",
        },
        {
            typeOfPrepaid: "",
            toWhomPaid: "",
            expirationDate: "",
            originalAmount: "",
            currentBalance: "",
        },
        {
            typeOfPrepaid: "",
            toWhomPaid: "",
            expirationDate: "",
            originalAmount: "",
            currentBalance: "",
        },
        {
            typeOfPrepaid: "",
            toWhomPaid: "",
            expirationDate: "",
            originalAmount: "",
            currentBalance: "",
        },
        {
            typeOfPrepaid: "",
            toWhomPaid: "",
            expirationDate: "",
            originalAmount: "",
            currentBalance: "",
        }
    ]

    // >>>>Real Estate & Buildings Default Value<<<<
    const defaultRealEstateBuildings = [
        {
            locationDescription: "",
            mortgagesLiens: "",
            dueDatesPaymentAmount: "",
            unpaidTaxes: "",
            cost: "",
            presentMarketValue: ""
        },
        {
            locationDescription: "",
            mortgagesLiens: "",
            dueDatesPaymentAmount: "",
            unpaidTaxes: "",
            cost: "",
            presentMarketValue: ""
        },
        {
            locationDescription: "",
            mortgagesLiens: "",
            dueDatesPaymentAmount: "",
            unpaidTaxes: "",
            cost: "",
            presentMarketValue: ""
        },
        {
            locationDescription: "",
            mortgagesLiens: "",
            dueDatesPaymentAmount: "",
            unpaidTaxes: "",
            cost: "",
            presentMarketValue: ""
        },
        {
            locationDescription: "",
            mortgagesLiens: "",
            dueDatesPaymentAmount: "",
            unpaidTaxes: "",
            cost: "",
            presentMarketValue: ""
        },
        {
            locationDescription: "",
            mortgagesLiens: "",
            dueDatesPaymentAmount: "",
            unpaidTaxes: "",
            cost: "",
            presentMarketValue: ""
        },
        {
            locationDescription: "",
            mortgagesLiens: "",
            dueDatesPaymentAmount: "",
            unpaidTaxes: "",
            cost: "",
            presentMarketValue: ""
        }
    ]

    // >>>>Accounts Payable Default Value<<<<
    const defaultAccountsPayable = [
        {
            accountName: "",
            payableTo: "",
            whenDue: "",
            amountDue: ""
        },
        {
            accountName: "",
            payableTo: "",
            whenDue: "",
            amountDue: ""
        },
        {
            accountName: "",
            payableTo: "",
            whenDue: "",
            amountDue: ""
        },
        {
            accountName: "",
            payableTo: "",
            whenDue: "",
            amountDue: ""
        },
        {
            accountName: "",
            payableTo: "",
            whenDue: "",
            amountDue: ""
        },
        {
            accountName: "",
            payableTo: "",
            whenDue: "",
            amountDue: ""
        },
        {
            accountName: "",
            payableTo: "",
            whenDue: "",
            amountDue: ""
        }
    ]

    // >>>>Accrued Taxes Default Value<<<<
    const defaultAccruedTaxes = [
        {
            typeOfTax: "",
            payableTo: "",
            whenDue: "",
            amountDue: ""
        },
        {
            typeOfTax: "",
            payableTo: "",
            whenDue: "",
            amountDue: ""
        },
        {
            typeOfTax: "",
            payableTo: "",
            whenDue: "",
            amountDue: ""
        },
        {
            typeOfTax: "",
            payableTo: "",
            whenDue: "",
            amountDue: ""
        },
        {
            typeOfTax: "",
            payableTo: "",
            whenDue: "",
            amountDue: ""
        },
        {
            typeOfTax: "",
            payableTo: "",
            whenDue: "",
            amountDue: ""
        },
        {
            typeOfTax: "",
            payableTo: "",
            whenDue: "",
            amountDue: ""
        }
    ]

    // >>>>Equity Stock Holder Default Value<<<<
    const defaultEquityStockHolder = [
        {
            title: "Common Stock",
            amount: ""
        },
        {
            title: "Preferred Stock",
            amount: ""
        },
        {
            title: "Additional Paid-In Capital",
            amount: ""
        },
        {
            title: "Retained Earnings",
            amount: ""
        }
    ]

    const { language, currentLanguage } = useLanguage();

    const [currentAssetData, setCurrentAssetData] = useState(defaultCurrentAssets);
    const [fixedAssetData, setFixedAssetData] = useState(defaultFixedAsset);
    const [currentLiabilityData, setCurrentLiabilityData] = useState(defaultCurrentLiability);
    const [longTermLiabilityData, setLongTermLiabilityData] = useState(defaultLongTermLiability)
    const [financialInstitutions, setFinancialInstitutions] = useState(defaultFinancialInstitutions);
    const [certificateDeposit, setCertificateDeposit] = useState(defaultCertificateDeposit);
    const [marketableAssets, setMarketableAssets] = useState(defaultMarketableAssets);
    const [notesPayable, setNotesPayable] = useState(defaultNotesPayable);
    const [prepaidExpenses, setPrepaidExpenses] = useState(defaultPrepaidExpenses);
    const [realEstateBuildings, setRealEstateBuildings] = useState(defaultRealEstateBuildings);
    const [accountsPayable, setAccountsPayable] = useState(defaultAccountsPayable);
    const [accruedTaxes, setAccruedTaxes] = useState(defaultAccruedTaxes);
    const [equityStockHolder, setEquityStockHolder] = useState(defaultEquityStockHolder);


    const [totalCurrentAssets, setTotalCurrentAssets] = useState(0);
    const [totalFixedAsset, setTotalFixedAsset] = useState(0);
    const [totalAssets, setTotalAssets] = useState(0)
    const [totalCurrentLiabiliy, setTotalCurrentLiabiliy] = useState(0);
    const [totalLongTermLiability, setTotalLongTermLiability] = useState(0)
    const [totalLiability, setTotalLiability] = useState(0)
    const [totalFinanceBalance, setTotalFinanceBalance] = useState(0);
    const [totalCertificateBalance, setTotalCertificateBalance] = useState(0);
    const [totalPresentMarketValue, setTotalPresentMarketValue] = useState(0);
    const [totalNotesPayable, setTotalNotesPayable] = useState(0);
    const [totalPreaidExp, setTotalPreaidExp] = useState(0);
    const [totalRealEstate, setTotalRealEstate] = useState(0);
    const [totalAccountPayable, setTotalAccountPayable] = useState(0);
    const [totalAccruedTax, setTotalAccruedTax] = useState(0);
    const [totalEquityAmount, setTotalEquityAmount] = useState(0);


    // >>>>GET Bussiness Financial Statement<<<<
    const getBussinessFinancialStatement = async () => {
        try {
            const res = await HttpClient.requestData("reportbuilder-view-budgetfianance-statement", "GET");
            if (res && res.status) {
                const data = res.data[0] || {};

                setCurrentAssetData(data.currentAsset.length > 0 ? data.currentAsset : defaultCurrentAssets);
                setFixedAssetData(data.fixedAsset.length > 0 ? data.fixedAsset : defaultFixedAsset);
                setCurrentLiabilityData(data.currentLiabilities.length > 0 ? data.currentLiabilities : defaultCurrentLiability);
                setLongTermLiabilityData(data.longLiabilities.length > 0 ? data.longLiabilities : defaultLongTermLiability);
                setFinancialInstitutions(data.schedule1a.length > 0 ? data.schedule1a : defaultFinancialInstitutions);
                setCertificateDeposit(data.schedule1b.length > 0 ? data.schedule1b : defaultCertificateDeposit);
                setMarketableAssets(data.schedule2.length > 0 ? data.schedule2 : defaultMarketableAssets);
                setNotesPayable(data.schedule3.length > 0 ? data.schedule3 : defaultNotesPayable);
                setPrepaidExpenses(data.schedule4.length > 0 ? data.schedule4 : defaultPrepaidExpenses);
                setRealEstateBuildings(data.schedule5.length > 0 ? data.schedule5 : defaultRealEstateBuildings);
                setAccountsPayable(data.schedule6.length > 0 ? data.schedule6 : defaultAccountsPayable);
                setAccruedTaxes(data.schedule7.length > 0 ? data.schedule7 : defaultAccruedTaxes);
                setEquityStockHolder(data.schedule8?.length > 0 ? data.schedule8 : defaultEquityStockHolder);

                console.log("API Data:", data);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    // >>>>Calculate the total amount of current assets<<<
    useEffect(() => {
        const total = currentAssetData.reduce((acc, item) => {
            const price = parseFloat(item.price) || 0;
            return acc + price;
        }, 0);
        setTotalCurrentAssets(total);
    }, [currentAssetData]);

    // >>>>Calculate the total amount of fixed assets<<<
    useEffect(() => {
        const total = fixedAssetData.reduce((acc, item) => {
            const price = parseFloat(item.price) || 0;
            return acc + price;
        }, 0);
        setTotalFixedAsset(total);
    }, [fixedAssetData]);

    // >>>>Calculate the total amount of total assets<<<
    useEffect(() => {
        if (totalCurrentAssets !== 0 && totalFixedAsset !== 0) {
            console.log("totalFixedAssetds4", totalCurrentAssets, totalFixedAsset)
            const total = totalCurrentAssets + totalFixedAsset
            setTotalAssets(total);
        } else {
            setTotalAssets(0);
        }
    }, [totalCurrentAssets, totalFixedAsset]);


    // >>>>Calculate the total amount of current liability<<<
    useEffect(() => {
        const total = currentLiabilityData.reduce((acc, item) => {
            const price = parseFloat(item.price) || 0;
            return acc + price;
        }, 0);
        setTotalCurrentLiabiliy(total);
    }, [currentLiabilityData]);

    // >>>>Calculate the total amount of long term liability<<<
    useEffect(() => {
        const total = longTermLiabilityData.reduce((acc, item) => {
            const price = parseFloat(item.price) || 0;
            return acc + price;
        }, 0);
        setTotalLongTermLiability(total);
    }, [longTermLiabilityData]);


    // >>>>Calculate the total amount of total liability<<<
    useEffect(() => {
        if (totalCurrentLiabiliy !== 0 && totalLongTermLiability !== 0) {
            const total = totalCurrentLiabiliy + totalLongTermLiability
            setTotalLiability(total);
        } else {
            setTotalLiability(0);
        }
    }, [totalCurrentLiabiliy, totalLongTermLiability]);

    // >>>>Calculate the total balance financialInstitutions<<<<
    useEffect(() => {
        const total = financialInstitutions.reduce((acc, item) => {
            const balance = parseFloat(item.balance) || 0;
            return acc + balance;
        }, 0);
        setTotalFinanceBalance(total);
    }, [financialInstitutions]);

    // >>>>Calculate the total balance Certificate Deposit<<<<
    useEffect(() => {
        const total = certificateDeposit.reduce((acc, item) => {
            const balance = parseFloat(item.balance) || 0;
            return acc + balance;
        }, 0);
        setTotalCertificateBalance(total);
    }, [certificateDeposit]);

    // >>>>Calculate the total balance Marketable Assets<<<<
    useEffect(() => {
        const total = marketableAssets.reduce((acc, item) => {
            const balance = parseFloat(item.presentMarketValue) || 0;
            return acc + balance;
        }, 0);
        setTotalPresentMarketValue(total);
    }, [marketableAssets]);

    // >>>>Calculate the total balance Notes Payable<<<<
    useEffect(() => {
        const total = notesPayable.reduce((acc, item) => {
            const balance = parseFloat(item.amountOwing) || 0;
            return acc + balance;
        }, 0);
        setTotalNotesPayable(total);
    }, [notesPayable]);

    // >>>>Calculate the total balance Prepaid Expenses<<<<
    useEffect(() => {
        const total = prepaidExpenses.reduce((acc, item) => {
            const balance = parseFloat(item.currentBalance) || 0;
            return acc + balance;
        }, 0);
        setTotalPreaidExp(total);
    }, [prepaidExpenses]);

    // >>>>Calculate the total balance Real Estate<<<<
    useEffect(() => {
        const total = realEstateBuildings.reduce((acc, item) => {
            const balance = parseFloat(item.presentMarketValue) || 0;
            return acc + balance;
        }, 0);
        setTotalRealEstate(total);
    }, [realEstateBuildings]);

    // >>>>Calculate the total balance Account Payable <<<<
    useEffect(() => {
        const total = accountsPayable.reduce((acc, item) => {
            const balance = parseFloat(item.amountDue) || 0;
            return acc + balance;
        }, 0);
        setTotalAccountPayable(total);
    }, [accountsPayable]);

    // >>>>Calculate the total balance Accrued Taxes<<<<
    useEffect(() => {
        const total = accruedTaxes.reduce((acc, item) => {
            const balance = parseFloat(item.amountDue) || 0;
            return acc + balance;
        }, 0);
        setTotalAccruedTax(total);
    }, [accruedTaxes]);

    // >>>>Calculate the total balance Equity Amount<<<<
    useEffect(() => {
        const total = equityStockHolder.reduce((acc, item) => {
            const balance = parseFloat(item.amount) || 0;
            return acc + balance;
        }, 0);
        setTotalEquityAmount(total);
    }, [equityStockHolder]);


    useEffect(() => {
        getBussinessFinancialStatement()
    }, [])

    return (
        <div className='container'>
            <div className='buildIncmStTableDiv'>

                {/***CURRENT ASSETS table**/}
                <table className="buildIncmStTable">
                    <tbody>
                        <tr>
                            <th className="buildIncmStTh12 buildIncmStTh12First">
                                <span>{language.current_asssets}</span>
                            </th>
                            <th className="buildIncmStTh12">
                                <span>{language.amount_dollars}</span>
                            </th>
                        </tr>


                        {
                            currentAssetData.map((item, i, arr) => {
                                return (
                                    <tr key={i}>
                                        <td className="buildBalLeftTdInp">
                                            <input
                                                type="text"
                                                name="title"
                                                value={item.title}
                                            />

                                        </td>
                                        <td className="buildBalRightTdInp">
                                            <input
                                                type="number"
                                                name="price"
                                                value={item.price}
                                                placeholder="$00"
                                            />
                                        </td>
                                    </tr>
                                )
                            })
                        }

                        <tr>
                            <td />
                            <td colSpan={2}>
                                <div className="buildTotDivider mt-2" />
                            </td>
                        </tr>

                        <tr>
                            <td className="buildIncmStTd">
                                <span className="buildTotSpan">{language.total_current_asset}</span>
                            </td>
                            <td className="buildBalRightTdInp">
                                <input
                                    type="number"
                                    readOnly
                                    value={totalCurrentAssets}
                                    placeholder="$0.00"
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>

                {/***CURRENT LIABILITIES table**/}
                <table className="buildIncmStTable mt-4">
                    <tbody>
                        <tr>
                            <th className="buildIncmStTh12 buildIncmStTh12First">
                                <span>{language.current_liability}</span>
                            </th>
                            <th className="buildIncmStTh12">
                                <span>{language.amount_dollars}</span>
                            </th>
                        </tr>
                        {
                            currentLiabilityData.map((item, i, arr) => {
                                return (
                                    <tr key={i}>
                                        <td className="buildBalLeftTdInp">
                                            <input
                                                type="text"
                                                name="title"
                                                value={item.title}
                                            />

                                        </td>
                                        <td className="buildBalRightTdInp">
                                            <input
                                                type="number"
                                                name="price"
                                                value={item.price}
                                                placeholder='$00'
                                            />
                                        </td>
                                    </tr>
                                )
                            })
                        }

                        <tr>
                            <td />
                            <td colSpan={2}>
                                <div className="buildTotDivider mt-2" />
                            </td>
                        </tr>

                        <tr>
                            <td className="buildIncmStTd">
                                <span className="buildTotSpan">{language.total_current_liability}</span>
                            </td>
                            <td className="buildBalRightTdInp">
                                <input
                                    type="number"
                                    readOnly
                                    value={totalCurrentLiabiliy}
                                    placeholder="$0.00"
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>

                {/***FIXED ASSETS table**/}
                <table className="buildIncmStTable mt-4">
                    <tbody>
                        <tr>
                            <th className="buildIncmStTh12 buildIncmStTh12First">
                                <span>{language.fixed_assets}</span>
                            </th>
                            <th className="buildIncmStTh12">
                                <span>{language.amount_dollars}</span>
                            </th>
                        </tr>

                        {
                            fixedAssetData.map((item, i, arr) => {
                                return (
                                    <tr key={i}>
                                        <td className="buildBalLeftTdInp">
                                            <input
                                                type="text"
                                                name="title"
                                                value={item.title}
                                            />

                                        </td>
                                        <td className="buildBalRightTdInp">
                                            <input
                                                type="number"
                                                name="price"
                                                value={item.price}
                                                placeholder='$00'
                                            />
                                        </td>
                                    </tr>
                                )
                            })
                        }


                        <tr>
                            <td />
                            <td colSpan={2}>
                                <div className="buildTotDivider mt-2" />
                            </td>
                        </tr>
                        <tr>
                            <td className="buildIncmStTd">
                                <span className="buildTotSpan" style={{ opacity: "0.60" }}>{language.total_fixet_asset}</span>
                            </td>
                            <td className="buildBalRightTdInp">
                                <input
                                    type="number"
                                    readOnly
                                    value={totalFixedAsset}
                                    placeholder="$0.00"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="buildIncmStTd">
                                <span className="buildTotSpan">{language.total_assets}</span>
                            </td>
                            <td className="buildBalRightTdInp">
                                <input
                                    type="number"
                                    readOnly
                                    value={totalAssets}
                                    placeholder="$0.00"
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>

                {/***LONG-TERM LIABILITIES table**/}
                <table className="buildIncmStTable mt-4">
                    <tbody>
                        <tr>
                            <th className="buildIncmStTh12 buildIncmStTh12First">
                                <span>{language.long_term_liability}</span>
                            </th>
                            <th className="buildIncmStTh12">
                                <span>{language.amount_dollars}</span>
                            </th>
                        </tr>

                        {
                            longTermLiabilityData.map((item, i, arr) => {
                                return (
                                    <tr key={i}>
                                        <td className="buildBalLeftTdInp">
                                            <input
                                                type="text"
                                                name="title"
                                                value={item.title}
                                            />

                                        </td>
                                        <td className="buildBalRightTdInp">
                                            <input
                                                type="number"
                                                name="price"
                                                value={item.price}
                                                placeholder='$00'
                                            />
                                        </td>
                                    </tr>
                                )
                            })
                        }

                        <tr>
                            <td />
                            <td colSpan={2}>
                                <div className="buildTotDivider mt-2" />
                            </td>
                        </tr>
                        <tr>
                            <td className="buildIncmStTd">
                                <span className="buildTotSpan" style={{ opacity: "0.60" }}>{language.total_long_term_lia}</span>
                            </td>
                            <td className="buildBalRightTdInp">
                                <input
                                    type="number"
                                    readOnly
                                    value={totalLongTermLiability}
                                    placeholder="$0.00"
                                />
                            </td>
                        </tr>
                        {/* <tr>
                            <td className="buildIncmStTd">
                                <span className="buildTotSpan" style={{ opacity: "0.60" }}>NET WORTH OR STOCKHOLDERS EQUITY</span>
                            </td>
                            <td className="buildIncmStTd">
                                <input
                                    type="number"
                                    readOnly=""
                                    placeholder="$0.00"
                                    defaultValue={0}
                                />
                            </td>
                        </tr> */}
                        <tr>
                            <td className="buildIncmStTd">
                                <span className="buildTotSpan">{language.total_liability_net_worth}</span>
                            </td>
                            <td className="buildBalRightTdInp">
                                <input
                                    type="number"
                                    readOnly
                                    value={totalLiability}
                                    placeholder="$0.00"
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>


                <h5 className="mb-1 mt-3 font-weight-bold">{language.SCHEDULES}</h5>
                {/***Schedule 1a. table**/}
                <p className='mb-1' style={{ fontSize: "15px" }}>{language.schedule_1a}</p>
                <table className='buildIncmStTable'>
                    <tr>
                        <th className="buildIncmStTh12 buildIncmStTh12First">
                            <span>{language.name_of_financial}<br /> {language.institution_address}</span>
                        </th>
                        <th className='text-center'>{language.acc_name_of}</th>
                        <th className='text-center'>{language.type_of_acc}</th>
                        <th className='text-center'>{`${language.account} #`}</th>
                        <th className='text-center'>{language.balance}</th>
                    </tr>

                    {
                        financialInstitutions?.map((item, i, arr) => {
                            return (
                                <tr>
                                    <td className="buildIncmStTd">
                                        <input
                                            type="text"
                                            className="userBuildInp"
                                            name="nameOfFinancialInstitution"
                                            value={item.nameOfFinancialInstitution}
                                        />
                                    </td>
                                    <td className="buildIncmStTd ">
                                        <input
                                            type="text"
                                            className="userBuildInp mx-2"
                                            name="accountInNameOf"
                                            value={item.accountInNameOf}

                                        />
                                    </td>
                                    <td className="buildIncmStTd">
                                        <input
                                            type="text"
                                            className="userBuildInp"
                                            name="typeOfAccount"
                                            value={item.typeOfAccount}
                                        />
                                    </td>
                                    <td className="buildIncmStTd">
                                        <input
                                            type="number"
                                            className="userBuildInp"
                                            name="accountNumber"
                                            value={item.accountNumber}
                                            placeholder="$00"
                                        />
                                    </td>
                                    <td className="buildIncmStTd">
                                        <input
                                            type="number"
                                            className="userBuildInp"
                                            name="balance"
                                            value={item.balance}
                                            placeholder="$00"
                                        />
                                    </td>
                                </tr>
                            )
                        })

                    }

                    <tr>
                        <td colSpan={5}>
                            <div className="buildTotDivider" />
                        </td>
                    </tr>

                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td className="buildIncmStTd">
                            <span className="buildTotSpan">{language.total}</span>
                        </td>
                        <td className="buildIncmStTd">
                            <input
                                type="number"
                                readOnly
                                value={totalFinanceBalance}
                                placeholder="$0.00"
                            />
                        </td>
                    </tr>
                </table>

                {/***Schedule 1b. table**/}
                <p className='mb-1' style={{ fontSize: "15px" }}>{language.schedule_1b}</p>
                <table className='buildIncmStTable'>
                    <tr>
                        <th className="buildIncmStTh12 buildIncmStTh12First">
                            <span>{language.name_of_financial} <br /> {language.institution_address}</span>
                        </th>
                        <th className='text-center'>{language.acc_name_of}</th>
                        <th className='text-center'>{language.if_pledged}</th>
                        <th className='text-center'>{`${language.account} #`}</th>
                        <th className='text-center'>{language.balance}</th>
                    </tr>

                    {
                        certificateDeposit?.map((item, i, arr) => {
                            return (
                                <tr>
                                    <td className="buildIncmStTd">
                                        <input
                                            type="text"
                                            className="userBuildInp"
                                            name="nameOfFinancialInstitution"
                                            value={item.nameOfFinancialInstitution}
                                        />
                                    </td>
                                    <td className="buildIncmStTd">
                                        <input
                                            type="text"
                                            className="userBuildInp"
                                            name="accountInNameOf"
                                            value={item.accountInNameOf}
                                        />
                                    </td>
                                    <td className="buildIncmStTd">
                                        <input
                                            type="text"
                                            className="userBuildInp"
                                            name="ifPledgedStateToWhom"
                                            value={item.ifPledgedStateToWhom}
                                        />
                                    </td>
                                    <td className="buildIncmStTd">
                                        <input
                                            type="number"
                                            className="userBuildInp"
                                            name="accountNumber"
                                            value={item.accountNumber}
                                            placeholder="$00"
                                        />
                                    </td>
                                    <td className="buildIncmStTd">
                                        <input
                                            type="number"
                                            className="userBuildInp"
                                            name="balance"
                                            value={item.balance}
                                            placeholder="$00"
                                        />
                                    </td>
                                </tr>
                            )
                        })
                    }

                    <tr>
                        <td colSpan={5}>
                            <div className="buildTotDivider" />
                        </td>
                    </tr>

                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td className="buildIncmStTd">
                            <span className="buildTotSpan">{language.total}</span>
                        </td>
                        <td className="buildIncmStTd">
                            <input
                                type="number"
                                readOnly
                                placeholder="$0.00"
                                value={totalCertificateBalance}
                            />
                        </td>
                    </tr>
                </table>

                {/***Schedule 2. table**/}
                <p className='mb-1' style={{ fontSize: "15px" }}>{language.schedule2}</p>
                <table className='buildIncmStTable'>
                    <tr>
                        <th className="buildIncmStTh12 buildIncmStTh12First">
                            <span>{`${language.face_value},`}<br /> {`# ${language.of_shares}`}</span>
                        </th>
                        <th className='text-center'>{language.des_security}</th>
                        <th className='text-center'>{language.regs_name_of}</th>
                        <th className='text-center'>{language.cost}</th>
                        <th className='text-center'>{language.income_received_lastyear}</th>
                        <th className='text-center'>{language.if_pledged}</th>
                        <th className='text-center'>{language.present_market_value}</th>
                    </tr>

                    {
                        marketableAssets?.map((item, i, arr) => {
                            return (
                                <tr>
                                    <td className="buildIncmStTd">
                                        <input
                                            type="text"
                                            className="userBuildInp"
                                            name="faceValueBondsShares"
                                            value={item.faceValueBondsShares}
                                        />
                                    </td>
                                    <td className="buildIncmStTd">
                                        <input
                                            type="text"
                                            className="userBuildInp"
                                            name="descriptionOfSecurity"
                                            value={item.descriptionOfSecurity}
                                        />
                                    </td>
                                    <td className="buildIncmStTd">
                                        <input
                                            type="text"
                                            className="userBuildInp"
                                            name="registeredInNameOf"
                                            value={item.registeredInNameOf}
                                        />
                                    </td>
                                    <td className="buildIncmStTd">
                                        <input
                                            type="number"
                                            className="userBuildInp"
                                            name="cost"
                                            value={item.cost}
                                            placeholder='$00'
                                        />
                                    </td>
                                    <td className="buildIncmStTd">
                                        <input
                                            type="number"
                                            className="userBuildInp"
                                            name="incomeReceivedLastYear"
                                            value={item.incomeReceivedLastYear}
                                            placeholder='$00'
                                        />
                                    </td>
                                    <td className="buildIncmStTd">
                                        <input
                                            type="text"
                                            className="userBuildInp"
                                            name="ifPledgedStateToWhom"
                                            value={item.ifPledgedStateToWhom}
                                        />
                                    </td>
                                    <td className="buildIncmStTd">
                                        <input
                                            type="number"
                                            className="userBuildInp"
                                            name="presentMarketValue"
                                            value={item.presentMarketValue}
                                            placeholder='$00'
                                        />
                                    </td>
                                </tr>
                            )
                        })
                    }

                    <tr>
                        <td colSpan={7}>
                            <div className="buildTotDivider" />
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td className="buildIncmStTd">
                            <span className="buildTotSpan">{language.total}</span>
                        </td>
                        <td className="buildIncmStTd">
                            <input
                                type="number"
                                readOnly
                                value={totalPresentMarketValue}
                                placeholder="$0.00"
                            />
                        </td>
                    </tr>
                </table>

                {/***Schedule 3. table**/}
                <p className='mb-1' style={{ fontSize: "15px" }}>{language.schedule3}</p>
                <table className='buildIncmStTable'>
                    <tr>
                        <th className="buildIncmStTh12 buildIncmStTh12First">
                            <span>{language.name_address}<br /> {language.of_debtor}</span>
                        </th>
                        <th className='text-center'>{language.age_debt}</th>
                        <th className='text-center'>{language.des_debt}</th>
                        <th className='text-center'>{language.des_sec_held}</th>
                        <th className='text-center'>{language.date_payment}</th>
                        <th className='text-center'>{language.amt_owning}</th>
                    </tr>

                    {
                        notesPayable?.map((item, i, arr) => {
                            return (
                                <tr>
                                    <td className="buildIncmStTd">
                                        <input
                                            type="text"
                                            className="userBuildInp"
                                            name="nameAddressDebtor"
                                            value={item.nameAddressDebtor}
                                        />
                                    </td>
                                    <td className="buildIncmStTd">
                                        <input
                                            type="number"
                                            className="userBuildInp"
                                            name="ageOfDebt"
                                            value={item.ageOfDebt}
                                            placeholder='$00'
                                        />
                                    </td>
                                    <td className="buildIncmStTd">
                                        <input
                                            type="text"
                                            className="userBuildInp"
                                            name="descriptionOfDebt"
                                            value={item.descriptionOfDebt}
                                        />
                                    </td>
                                    <td className="buildIncmStTd">
                                        <input
                                            type="text"
                                            className="userBuildInp"
                                            name="descriptionOfSecurityHeld"
                                            value={item.descriptionOfSecurityHeld}
                                        />
                                    </td>
                                    <td className="buildIncmStTd">
                                        <input
                                            type="date"
                                            className="userBuildInp"
                                            name="dateOfPayment"
                                            value={item.dateOfPayment}
                                        />
                                    </td>
                                    <td className="buildIncmStTd">
                                        <input
                                            type="number"
                                            className="userBuildInp"
                                            name="amountOwing"
                                            value={item.amountOwing}
                                            placeholder='$00'
                                        />
                                    </td>
                                </tr>
                            )
                        })
                    }


                    <tr>
                        <td colSpan={6}>
                            <div className="buildTotDivider" />
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td className="buildIncmStTd">
                            <span className="buildTotSpan">{language.total}</span>
                        </td>
                        <td className="buildIncmStTd">
                            <input
                                type="number"
                                readOnly
                                value={totalNotesPayable}
                                placeholder="$0.00"
                            />
                        </td>
                    </tr>
                </table>

                {/***Schedule 4. table**/}
                <p className='mb-1' style={{ fontSize: "15px" }}>{language.schedule4}</p>
                <table className='buildIncmStTable'>
                    <tr>
                        <th className="buildIncmStTh12 buildIncmStTh12First">
                            <span>{language.type_of_prepaid} </span>
                        </th>
                        <th className='text-center'>{language.to_whom_paid}</th>
                        <th className='text-center'>{language.exp_date}</th>
                        <th className='text-center'>{language.original_amt}</th>
                        <th className='text-center'>{language.curr_balance}</th>
                    </tr>

                    {
                        prepaidExpenses?.map((item, i, arr) => {
                            return (
                                <tr>
                                    <td className="buildIncmStTd">
                                        <input
                                            type="text"
                                            className="userBuildInp"
                                            name="typeOfPrepaid"
                                            value={item.typeOfPrepaid}
                                        />
                                    </td>
                                    <td className="buildIncmStTd">
                                        <input
                                            type="text"
                                            className="userBuildInp"
                                            name="toWhomPaid"
                                            value={item.toWhomPaid}
                                        />
                                    </td>
                                    <td className="buildIncmStTd">
                                        <input
                                            type="date"
                                            className="userBuildInp"
                                            name="expirationDate"
                                            value={item.expirationDate}
                                        />
                                    </td>
                                    <td className="buildIncmStTd">
                                        <input
                                            type="number"
                                            className="userBuildInp"
                                            name="originalAmount"
                                            value={item.originalAmount}
                                            placeholder='$00'
                                        />
                                    </td>
                                    <td className="buildIncmStTd">
                                        <input
                                            type="number"
                                            className="userBuildInp"
                                            name="currentBalance"
                                            value={item.currentBalance}
                                            placeholder='$00'
                                        />
                                    </td>
                                </tr>
                            )
                        })
                    }

                    <tr>
                        <td colSpan={5}>
                            <div className="buildTotDivider" />
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td className="buildIncmStTd">
                            <span className="buildTotSpan">{language.total}</span>
                        </td>
                        <td className="buildIncmStTd">
                            <input
                                type="number"
                                readOnly
                                value={totalPreaidExp}
                                placeholder="$0.00"
                            />
                        </td>
                    </tr>
                </table>

                {/***Schedule 5. table**/}
                <p className='mb-1' style={{ fontSize: "15px" }}>{language.schedule5}</p>
                <table className='buildIncmStTable'>
                    <tr>
                        <th className="buildIncmStTh12 buildIncmStTh12First">
                            <span>{`${language.location_street} #`}<br /> {`& ${language.description}`}</span>
                        </th>
                        <th className='text-center'>{language.mortgage}</th>
                        <th className='text-center'>{language.due_dates}</th>
                        <th className='text-center'>{language.unpaid_tax}</th>
                        <th className='text-center'>{language.cost}</th>
                        <th className='text-center'>{language.present_market_value}</th>
                    </tr>

                    {
                        realEstateBuildings?.map((item, i, arr) => {
                            return (
                                <tr>
                                    <td className="buildIncmStTd">
                                        <input
                                            type="text"
                                            className="userBuildInp"
                                            name="locationDescription"
                                            value={item.locationDescription}
                                        />
                                    </td>
                                    <td className="buildIncmStTd">
                                        <input
                                            type="text"
                                            className="userBuildInp"
                                            name="mortgagesLiens"
                                            value={item.mortgagesLiens}
                                        />
                                    </td>
                                    <td className="buildIncmStTd">
                                        <input
                                            type="text"
                                            className="userBuildInp"
                                            name="dueDatesPaymentAmount"
                                            value={item.dueDatesPaymentAmount}
                                        />
                                    </td>
                                    <td className="buildIncmStTd">
                                        <input
                                            type="number"
                                            className="userBuildInp"
                                            name="unpaidTaxes"
                                            value={item.unpaidTaxes}
                                            placeholder='$00'
                                        />
                                    </td>
                                    <td className="buildIncmStTd">
                                        <input
                                            type="number"
                                            className="userBuildInp"
                                            name="cost"
                                            value={item.cost}
                                            placeholder='$00'
                                        />
                                    </td>
                                    <td className="buildIncmStTd">
                                        <input
                                            type="number"
                                            className="userBuildInp"
                                            name="presentMarketValue"
                                            value={item.presentMarketValue}
                                            placeholder='$00'
                                        />
                                    </td>
                                </tr>
                            )
                        })
                    }

                    <tr>
                        <td colSpan={6}>
                            <div className="buildTotDivider" />
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td className="buildIncmStTd">
                            <span className="buildTotSpan">{language.total}</span>
                        </td>
                        <td className="buildIncmStTd">
                            <input
                                type="number"
                                readOnly
                                value={totalRealEstate}
                                placeholder="$0.00"
                            />
                        </td>
                    </tr>
                </table>

                {/***Schedule 6. table**/}
                <p className='mb-1' style={{ fontSize: "15px" }}>{language.schedule6}</p>
                <table className='buildIncmStTable'>
                    <tr>
                        <th className="buildIncmStTh12 buildIncmStTh12First">
                            <span>{language.name_of_acc}</span>
                        </th>
                        <th className='text-center'>{language.payable_to}</th>
                        <th className='text-center'>{language.when_due}</th>
                        <th className='text-center'>{language.amount_due}</th>
                    </tr>

                    {
                        accountsPayable?.map((item, i, arr) => {
                            return (
                                <tr>
                                    <td className="buildIncmStTd">
                                        <input
                                            type="text"
                                            className="userBuildInp"
                                            name="accountName"
                                            value={item.accountName}
                                        />
                                    </td>
                                    <td className="buildIncmStTd">
                                        <input
                                            type="text"
                                            className="userBuildInp"
                                            name="payableTo"
                                            value={item.payableTo}
                                        />
                                    </td>
                                    <td className="buildIncmStTd">
                                        <input
                                            type="date"
                                            className="userBuildInp"
                                            name="whenDue"
                                            value={item.whenDue}
                                        />
                                    </td>

                                    <td className="buildIncmStTd">
                                        <input
                                            type="number"
                                            className="userBuildInp"
                                            name="amountDue"
                                            value={item.amountDue}
                                            placeholder='$00'
                                        />
                                    </td>
                                </tr>
                            )
                        })
                    }

                    <tr>
                        <td colSpan={4}>
                            <div className="buildTotDivider" />
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td className="buildIncmStTd">
                            <span className="buildTotSpan">{language.total}</span>
                        </td>
                        <td className="buildIncmStTd">
                            <input
                                type="number"
                                readOnly
                                placeholder="$0.00"
                                value={totalAccountPayable}
                            />
                        </td>
                    </tr>
                </table>

                {/***Schedule 7. table**/}
                <p className='mb-1' style={{ fontSize: "15px" }}>{language.schedule7}</p>
                <table className='buildIncmStTable'>
                    <tr>
                        <th className="buildIncmStTh12 buildIncmStTh12First">
                            <span>{language.type_of_tax}</span>
                        </th>
                        <th className='text-center'>{language.payable_to}</th>
                        <th className='text-center'>{language.when_due}</th>
                        <th className='text-center'>{language.amount_due}</th>
                    </tr>

                    {
                        accruedTaxes?.map((item, i, arr) => {
                            return (
                                <tr>
                                    <td className="buildIncmStTd">
                                        <input
                                            type="text"
                                            className="userBuildInp"
                                            name="typeOfTax"
                                            value={item.typeOfTax}
                                        />
                                    </td>
                                    <td className="buildIncmStTd">
                                        <input
                                            type="text"
                                            className="userBuildInp"
                                            name="payableTo"
                                            value={item.payableTo}
                                        />
                                    </td>
                                    <td className="buildIncmStTd">
                                        <input
                                            type="date"
                                            className="userBuildInp"
                                            name="whenDue"
                                            value={item.whenDue}
                                        />
                                    </td>

                                    <td className="buildIncmStTd">
                                        <input
                                            type="number"
                                            className="userBuildInp"
                                            name="amountDue"
                                            value={item.amountDue}
                                            placeholder='$00'
                                        />
                                    </td>
                                </tr>
                            )
                        })
                    }


                    <tr>
                        <td colSpan={4}>
                            <div className="buildTotDivider" />
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td className="buildIncmStTd">
                            <span className="buildTotSpan">{language.total}</span>
                        </td>
                        <td className="buildIncmStTd">
                            <input
                                type="number"
                                readOnly
                                value={totalAccruedTax}
                                placeholder="$0.00"
                            />
                        </td>
                    </tr>
                </table>

                {/***Schedule 8. table**/}
                <p className='mb-1' style={{ fontSize: "15px" }}>{language.schedule8}</p>
                <table className='buildIncmStTable mb-2'>
                    <tr>
                        <th className="buildIncmStTh12 buildIncmStTh12First">
                            <span>{language.Type}</span>

                        </th>
                        <th className='text-center'>{language.Amount} </th>
                    </tr>

                    {
                        equityStockHolder.map((item, i, arr) => {
                            return (
                                <tr key={i}>
                                    <td className="buildBalLeftTdInp">

                                        <input
                                            type="text"
                                            name="title"
                                            value={item.title}
                                        />

                                    </td>
                                    <td className="buildBalRightTdInp">
                                        <input
                                            type="number"
                                            name="amount"
                                            value={item.amount}
                                            placeholder='$00'
                                        // readOnly={isSave ? false : true}
                                        />
                                    </td>
                                </tr>
                            )
                        })
                    }

                    <tr>
                        <td colSpan={2}>
                            <div className="buildTotDivider mt-2" />
                        </td>
                    </tr>

                    <tr>
                        <td className="buildIncmStTd">
                            <span className="buildTotSpan">{language.total}</span>
                        </td>
                        <td className="buildBalRightTdInp">
                            <input
                                type="number"
                                readOnly
                                value={totalEquityAmount}
                                placeholder="$0.00"
                            />
                        </td>
                    </tr>
                </table>

            </div>
        </div>
    )
}

export default ViewBussinessFS