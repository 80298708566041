import React, { useEffect, useState } from 'react'
import HttpClient from '../../utils/HttpClient';
import { useLanguage } from '../../Providers/LanguageContext';

const ViewTrialBalance = ({ setBuildRepData }) => {
    const { language, currentLanguage } = useLanguage();

    const initAcc = {
        accName: "",
        debit: "",
        credit: ""
    }
    const [accNameData, setAccNameData] = useState([initAcc]);

    const [totalData, setTotalData] = useState({ debit: "", credit: "", difference: "" });


    const getInvoiceStatement = async () => {
        const res = await HttpClient.requestData("reportbuilder-view-trialbalance", "GET");
        // console.log("ressPttrr", res);
        if (res && res?.status) {
            setBuildRepData(res?.data)
            const incdata = res?.data?.Account?.map((item) => ({
                accName: item?.accountName,
                debit: item?.credit,
                credit: item?.debit
            }))
            setAccNameData(incdata);

            setTotalData({
                debit: res?.data?.totalCredit,
                credit: res?.data?.totalDebit,
                difference: res?.data?.diffrence
            })
        }
    }

    useEffect(() => {
        getInvoiceStatement();
    }, [])

    return (
        <div>
            <div className="buildTrialTableDiv mt-4 mb-4">
                <table className="buildTrialTable">

                    <thead>
                        <th className="bulidTriLeftTh">



                            <span className="buildBalanceHead">{language.account_name}</span>



                        </th>
                        <th className="bulidTriRightTh">
                            <span className="buildBalanceHead">Debit</span>
                        </th>
                        <th className="bulidTriRightTh">
                            <span className="buildBalanceHead">Credit</span>
                        </th>
                    </thead>

                    <tbody>
                        {accNameData && accNameData?.map((item, i) => {
                            return (
                                <tr key={i}>
                                    <td className="buildTrialLeftTd">

                                        <span className="buildBalLeftTdSpan">{item.accName?.toUpperCase()}</span>

                                    </td>
                                    <td className="buildTrialRightTd">
                                        <input
                                            type="number"
                                            // placeholder="$0.00"
                                            name="debit"
                                            value={item.debit}
                                        />
                                    </td>
                                    <td className="buildTrialRightTd">
                                        <input
                                            type="number"
                                            // placeholder="$0.00"
                                            name="credit"
                                            value={item.credit}
                                        />
                                    </td>
                                </tr>
                            )
                        })}

                    </tbody>


                    {/* total */}
                    <tfoot>
                        <tr>
                            <td className="buildTrialLeftFootTd">
                                {/* <span>{language.totals}:</span> */}
                                <span>Totals:</span>
                            </td>
                            <td className="buildTrialRightTd">
                                <input
                                    type="number"
                                    // placeholder="$0.00"
                                    value={totalData.debit}
                                    readOnly
                                />
                            </td>
                            <td className="buildTrialRightTd">
                                <input
                                    type="number"
                                    // placeholder="$0.00"
                                    value={totalData.credit}
                                    readOnly
                                />

                            </td>
                        </tr>
                        <tr>
                            <td className="buildTrialLeftFootTd">
                                <span>{language.difference}:</span>
                            </td>
                            <td className="buildTrialRightTd">

                            </td>
                            <td className="buildTrialRightTd">
                                <input
                                    type="number"
                                    placeholder="$0.00"
                                    value={totalData.difference}
                                    readOnly
                                />
                            </td>
                        </tr>
                    </tfoot>
                </table>

                {/* button group */}
                {/* <div className="d-flex justify-content-center mt-3">
                    <button
                        className="btn-report-save"
                        onClick={() => handleSave()}
                    >
                        {isSave ?
                            saveLoading ? <CustomLoaderLine height="10" width="40" /> : language.save?.toUpperCase()
                            :
                            language.edit?.toUpperCase()
                        }
                    </button>
                    <button
                        className="btn-report-build"
                        onClick={() => handleBuildRepport()}
                    >
                        {loading ? <CustomLoaderLine height="10" width="100" /> : language.build_report?.toUpperCase()}
                    </button>
                </div> */}

            </div>

        </div>
    )
}

export default ViewTrialBalance
