import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import CustomModal from '../../Component/CustomModal';
import girlimg from "../../images/1turquesa.png"
import CustomFormButtons from '../../Component/CustomFormButtons';
import { useLanguage } from '../../Providers/LanguageContext';
import { UserContext } from '../../Providers/UserWrapper';
import { Navigate, useNavigate } from 'react-router-dom';
import { SpeechContext } from '../../Providers/SpeechWrapper';
import welcome_audio_en from "../../Audio_files/welcome_audio/welcome_en.mp3";
import welcome_audio_es from "../../spanish_audio_files/welcome_audio/welcome_audio_es.mp3";
import sales_page_home_en from "../../Audio_files/II. Revenue Manager Audio/ii_revenue_manager_user_selected_intro_audio_instructions.mp3";
import sales_page_home_es from "../../spanish_audio_files/II. Revenue Manager Audio/ii_revenue_manager_user_selected_intro_audio_instructions_es.mp3";
import expense_page_home_en from "../../Audio_files/III. Expense Manager Audio/iii_expense_manager_user_selected_intro_audio_instructions.mp3";
import expense_page_home_es from "../../spanish_audio_files/III. Expense Manager Audio/iii_expense_manager_user_selected_intro_audio_instructions_es.mp3";
import budget_page_home_en from "../../Audio_files/IV. Budget Manager Audio/iv_budget_manager_user_selected_intro_audio_instructions.mp3";
import budget_page_home_es from "../../spanish_audio_files/IV. Budget Manager Audio/iv_budget_manager_user_selected_intro_audio_instructions_es.mp3";
import invoice_page_home_en from "../../Audio_files/V. Invoice Manager Audio/v_invoice_manager_user_selected_intro_audio_instructions.mp3";
import invoice_page_home_es from "../../spanish_audio_files/V. Invoice Manager Audio/v_invoice_manager_user_selected_intro_audio_instructions_es.mp3";
import project_manager_home_en from "../../Audio_files/VI. Project Manager Audio/vi_project_manager_user_selected_intro_audio_instructions.mp3";
import project_manager_home_es from "../../spanish_audio_files/VI. Project Manager Audio/vi_project_manager_user_selected_intro_audio_instructions_es.mp3";
import dashboard_manager_home_en from "../../Audio_files/VII. Dashboard Manager Audio/vii_view_dashboards_user_selected_intro_audio_instructions.mp3";
import dashboard_manager_home_es from "../../spanish_audio_files/VII. Dashboard Manager Audio/vii_view_dashboards_user_selected_intro_audio_instructions_es.mp3";
import reward_manager_home_en from "../../Audio_files/VIII. Rewards Manager Audio/viii_rewards_manager_user_selected_intro_audio_instructions.mp3";
import reward_manager_home_es from "../../spanish_audio_files/VIII. Rewards Manager Audio/viii_rewards_manager_user_selected_intro_audio_instructions_es.mp3";
import cipbits_manager_home_en from "../../Audio_files/IX. CIPBITS Manager Audio/ix_cipbits_manger_user_selected_intro_audio_instructions.mp3";
import cipbits_manager_home_es from "../../spanish_audio_files/IX. CIPBITS Manager Audio/ix_cipbits_manger_user_selected_intro_audio_instructions_es.mp3";
import user_home_en from "../../extras/x_subusers_and_profile_manager_user_selected_intro_audio_instructions.mp3";
import user_home_es from "../../extras/x_subusers_and_profile_manager_user_selected_intro_audio_instructions_es.mp3";
import setting_home_en from "../../extras/xi_settings_manager_user_selected_intro_audio_instructions.mp3";
import setting_home_es from "../../extras/xi_settings_manager_user_selected_intro_audio_instructions_es.mp3";
import reports_manager_en from "../../extras/reports_manager_user_selected_intro_audio_instructions_en.mp3";
import reports_manager_es from "../../extras/reports_manager_user_selected_intro_audio_instructions_es.mp3";
import Gifcomponent from '../../Component/Gifcomponent';
import resource_ins_en from '../../Audio_files/Resource Manager Audio Files/EN Audio Files/resource_manager_user_selected_intro_audio_instructions.mp3'
import resource_ins_es from '../../Audio_files/Resource Manager Audio Files/ES Audio Files/resource_manager_user_selected_intro_audio_instructions_es.mp3'
import girlimgBu from '../../images/ILUSTRACIONES/BUSINESS.png';
import girlimgPu from '../../images/ILUSTRACIONES/PERSONAL.png'
import HttpClient from '../../utils/HttpClient';
import { AiOutlineClose } from 'react-icons/ai';


function Welcome2() {
    const { language, currentLanguage } = useLanguage();
    const { currAudio, setCurrAudio, playing, setIsPlaying } = useContext(SpeechContext);

    const { profileData, isPaid, isBusiness, isBookKeeper, isInvestor, isMainUserMemo } = useContext(UserContext);
    console.log('profileDatamanager', isBookKeeper, isInvestor)
    const navigate = useNavigate();
    const [isSubmit, setIsSubmit] = useState(false);
    const [intModalOpen, setIntgModalOpen] = useState(false)

    // bank data
    const [bankData, setBankData] = useState();
    const [bankLoading, setBankLoading] = useState();

    // console.log('bankDatadff', bankData)

    const [currModalClicked, setCurrModalClicked] = useState("");

    const buMainUser = [
        {
            title: language.task_manager,
        },
        {
            title: language.revenew_manager,
        },
        {
            title: language.expense_manager
        },
        {
            title: language.budget_manager
        },
        {
            title: language.invoice_manager
        },
        {
            title: language.project_manager
        },
        {
            title: language.dashboard_manager
        },
        {
            title: language.book_keeper_manager
        },
        {
            title: language.Investment_Manager
        },
        {
            title: language.Document_Manager
        },
        {
            title: language.integration_manager
        },
        {
            title: language.reward_manager
        },
        {
            title: language.cipbits_manager
        },
        {
            title: language.user_manager
        },
        {
            title: language.reports_manager
        },
        {
            title: language.setting_manager
        },
        {
            title: language.resource_manager
        }
    ]
    const buSubUser = [
        {
            title: language.task_manager,
        },
        {
            title: language.revenew_manager,
        },
        {
            title: language.expense_manager
        },
        // {
        //     title: language.budget_manager
        // },
        {
            title: language.invoice_manager
        },
        {
            title: language.project_manager
        },
        {
            title: language.dashboard_manager
        },
        {
            title: language.book_keeper_manager
        },
        {
            title: language.Investment_Manager
        },
        {
            title: language.Document_Manager
        },
        {
            title: language.integration_manager
        },
        {
            title: language.reward_manager
        },
        {
            title: language.cipbits_manager
        },
        {
            title: language.user_manager
        },
        {
            title: language.reports_manager
        },
        {
            title: language.setting_manager
        },
        {
            title: language.resource_manager
        }
    ]
    const puMainUser = [
        {
            title: language.task_manager,
        },
        {
            title: language.income_manager,
        },
        {
            title: language.expense_manager
        },
        {
            title: language.budget_manager
        },
        // {
        //     title: language.invoice_manager
        // },
        {
            title: language.project_manager
        },
        {
            title: language.dashboard_manager
        },
        {
            title: language.book_keeper_manager
        },
        {
            title: language.Investment_Manager
        },
        {
            title: language.Document_Manager
        },
        {
            title: language.integration_manager
        },
        {
            title: language.reward_manager
        },
        {
            title: language.cipbits_manager
        },
        {
            title: language.user_manager
        },
        {
            title: language.reports_manager
        },
        {
            title: language.setting_manager
        },
        {
            title: language.resource_manager
        }
    ]
    const puSubuser = [
        {
            title: language.task_manager,
        },
        {
            title: language.income_manager,
        },
        {
            title: language.expense_manager
        },
        // {
        //     title: language.budget_manager
        // },
        // {
        //     title: language.invoice_manager
        // },
        {
            title: language.project_manager
        },
        {
            title: language.dashboard_manager
        },
        {
            title: language.integration_manager
        },
        {
            title: language.book_keeper_manager
        },
        {
            title: language.Investment_Manager
        },
        {
            title: language.Document_Manager
        },
        {
            title: language.reward_manager
        },
        {
            title: language.cipbits_manager
        },
        {
            title: language.user_manager
        },
        {
            title: language.reports_manager
        },
        {
            title: language.setting_manager
        },
        {
            title: language.resource_manager
        }
    ]

    const obj =
        profileData && profileData.userType === 'Business'
            ?
            isMainUserMemo ?
                [
                    ...buMainUser
                ]
                :
                [
                    ...buSubUser
                ]
            :
            isMainUserMemo ?
                [
                    ...puMainUser
                ]
                :
                [
                    ...puSubuser
                ]

    const [modalIsOpen, setIsOpen] = useState(false);

    function openModal(title) {
        setIsOpen(true);
        setCurrModalClicked(title)
    }

    function closeModal() {
        setIsOpen(false);
        setCurrModalClicked("")
    }


    // boookkeeper disabled memo
    // const bookDisabledMemo = useCallback((title) => {
    //     console.log("df41651", title)
    //     if (isBookKeeper) {
    //         if (title === language.book_keeper_manager) {
    //             return false
    //         } else {
    //             return true
    //         }
    //     }
    //     else if (isInvestor) {
    //         if (title === language.Investment_Manager) {
    //             return false
    //         } else {
    //             return true
    //         }
    //     }
    //     else {
    //         return false
    //     }
    // }, [isBookKeeper, isInvestor, language])
    const bookDisabledMemo = useCallback((title) => {
        // console.log("df41651", title);

        if (isBookKeeper) {
            return title !== language.book_keeper_manager && title !== language.Document_Manager;
        }

        if (isInvestor) {
            return title !== language.Investment_Manager && title !== language.Document_Manager;
        }
        return false;
    }, [isBookKeeper, isInvestor, language]);


    const getFirstProfile = async () => {
        const res = await HttpClient.requestData("get-profile-count", "GET", {});
        // console.log("resProSub", res);
        if (res && res?.status) {
            setIsSubmit(res.data);
        }
    }

    // getting bank details
    const getBankDetails = async () => {
        setBankLoading(true);
        const res = await HttpClient.requestData("plaid-account-get", "GET");
        if (res && res?.status) {
            setBankLoading(false)
            setBankData(res?.data);
        } else {
            setBankLoading(false);
        }
    }

    useEffect(() => {
        getFirstProfile();
        // getBankDetails();
    }, [])

    useEffect(() => {
        if (profileData) {
            if (profileData?.userFlag == 0) {
                if (!profileData.name) {
                    navigate("/createprofile")
                }
            } else {
                if (isSubmit) {
                    if (!isSubmit?.length) {
                        navigate("/createprofile");
                    }
                }
            }
        }
    }, [profileData, isSubmit])

    useEffect(() => {
        console.log(currAudio, setCurrAudio, "funcsssss")
        if (playing) {
            if (currAudio) {
                if (!currAudio.paused) {
                    currAudio.pause()
                    // setCurrAudio()
                }
            }
            if (currentLanguage === "eng") {
                setCurrAudio(new Audio(welcome_audio_en))
            } else {
                setCurrAudio(new Audio(welcome_audio_es))
            }
        } else {
            if (currAudio) {
                if (!currAudio.paused) {
                    currAudio.pause()
                    setCurrAudio(null)
                }
            }
        }

        return () => {
            if (currAudio && !currAudio.paused) {
                currAudio.pause()
            }
        }
    }, [playing, currentLanguage])

    // useEffect(() => {
    //     console.log("currAudioWelcome", currAudio)
    //     if (currAudio) {
    //         if (!currAudio.paused) {
    //             currAudio.pause()
    //         }
    //         currAudio.play().catch((err) => {
    //             console.log('errAudio', err)
    //         })
    //     }
    //     return () => {
    //         if (currAudio && !currAudio.paused) {
    //             currAudio.pause()
    //         }
    //     }
    // }, [currAudio])

    useEffect(() => {
        if (playing) {
            if (currModalClicked && modalIsOpen) {
                if (currAudio) {
                    if (!currAudio.paused) {
                        currAudio.pause()
                    }
                    if (currModalClicked === language.revenew_manager) {
                        if (currentLanguage === "eng") {
                            setCurrAudio(new Audio(sales_page_home_en))
                        } else {
                            setCurrAudio(new Audio(sales_page_home_es))
                        }
                    } else if (currModalClicked === language.expense_manager) {
                        if (currentLanguage === "eng") {
                            setCurrAudio(new Audio(expense_page_home_en))
                        } else {
                            setCurrAudio(new Audio(expense_page_home_es))
                        }
                    } else if (currModalClicked === language.budget_manager) {
                        if (currentLanguage === "eng") {
                            setCurrAudio(new Audio(budget_page_home_en))
                        } else {
                            setCurrAudio(new Audio(budget_page_home_es))
                        }
                    } else if (currModalClicked === language.invoice_manager) {
                        if (currentLanguage === "eng") {
                            setCurrAudio(new Audio(invoice_page_home_en))
                        } else {
                            setCurrAudio(new Audio(invoice_page_home_es))
                        }
                    } else if (currModalClicked === language.project_manager) {
                        if (currentLanguage === "eng") {
                            setCurrAudio(new Audio(project_manager_home_en))
                        } else {
                            setCurrAudio(new Audio(project_manager_home_es))
                        }
                    } else if (currModalClicked === language.dashboard_manager) {
                        if (currentLanguage === "eng") {
                            setCurrAudio(new Audio(dashboard_manager_home_en))
                        } else {
                            setCurrAudio(new Audio(dashboard_manager_home_es))
                        }
                    } else if (currModalClicked === language.reward_manager) {
                        if (currentLanguage === "eng") {
                            setCurrAudio(new Audio(reward_manager_home_en))
                        } else {
                            setCurrAudio(new Audio(reward_manager_home_es))
                        }
                    } else if (currModalClicked === language.cipbits_manager) {
                        if (currentLanguage === "eng") {
                            setCurrAudio(new Audio(cipbits_manager_home_en))
                        } else {
                            setCurrAudio(new Audio(cipbits_manager_home_es))
                        }
                    } else if (currModalClicked === language.user_manager) {
                        if (currentLanguage === "eng") {
                            setCurrAudio(new Audio(user_home_en))
                        } else {
                            setCurrAudio(new Audio(user_home_es))
                        }
                    } else if (currModalClicked === language.reports_manager) {
                        if (currentLanguage === "eng") {
                            setCurrAudio(new Audio(reports_manager_en))
                        } else {
                            setCurrAudio(new Audio(reports_manager_es))
                        }
                    } else if (currModalClicked === language.setting_manager) {
                        if (currentLanguage === "eng") {
                            setCurrAudio(new Audio(setting_home_en))
                        } else {
                            setCurrAudio(new Audio(setting_home_es))
                        }
                    } else if (currModalClicked === language.resource_manager) {
                        if (currentLanguage === "eng") {
                            setCurrAudio(new Audio(resource_ins_en))
                        } else {
                            setCurrAudio(new Audio(resource_ins_es))
                        }
                    }
                }
            } else {
                // if (currAudio) {
                //     if (!currAudio.paused) {
                //         currAudio.pause()
                //         setCurrAudio(null)
                //     }
                // }
                // if (currAudio) {
                //     if (!currAudio.paused) {
                //         currAudio.pause()
                //     }
                // }
                // if (currentLanguage === "eng") {
                //     setCurrAudio(new Audio(welcome_audio_en))
                // } else {
                //     setCurrAudio(new Audio(welcome_audio_es))
                // }
            }
        }
        return () => {
            if (currAudio && !currAudio.paused) {
                currAudio.pause()
                setCurrAudio(null)
            }
        }
    }, [currModalClicked, playing, modalIsOpen, currentLanguage])


    // console.log(isPaid, "isPaid")

    // if(!isPaid){
    //     return <Navigate to="/payment" />
    // }
    return (
        <>
            {/* <Gifcomponent/> */}
            <div className='container' style={{}}>
                <div className='row' style={{ paddingBottom: "60px" }}>
                    <div className="col-xl-6 col-lg-6 col-md-12 col-12">
                        <div style={{ width: "100%", height: "auto", marginTop: "80px", cursor: 'default' }}>
                            <h2 className='fw-bold' style={{ fontSize: "5rem", marginTop: "30px" }}>{language.hello}</h2>
                            <h3 style={{ marginBottom: "20px" }}>{language.welcome_fisibility}</h3>
                            <h5 style={{ color: "#808080", marginBottom: "50px" }}>{language.select_manager}</h5>
                            {
                                obj.slice(0, 8).map((val, i) =>
                                    <div>

                                        <button
                                            className='managerpagebtn'
                                            onClick={() => openModal(val.title)}
                                            style={{
                                                width: "100%",
                                                height: "45px",
                                                padding: "10px 20px",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                border: "none",
                                                fontSize: "24px",
                                                fontWeight: "500",
                                                marginBottom: "10px",
                                                color: "#fff",
                                                opacity: bookDisabledMemo(val?.title) ? "0.7" : "1"
                                            }}
                                            disabled={bookDisabledMemo(val?.title)}
                                        >
                                            {val.title}
                                        </button>

                                    </div>
                                )
                            }
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-12 col-12">
                        <div style={{ width: "100%", height: "auto" }}>
                            <div style={{ width: "100%", height: "auto" }}>
                                <div style={{
                                    width: "90%",
                                    height: "420px",
                                    overflow: "hidden",
                                    margin: "0 auto",
                                    objectFit: "contain"
                                }}>
                                    {
                                        isBusiness ?
                                            <img src={girlimgBu} className="img-fluid" style={{ width: "100%", height: "100%" }} />
                                            :
                                            <img src={girlimgPu} className="img-fluid" style={{ width: "100%", height: "90%" }} />
                                    }
                                </div>

                                <div className='' style={{ width: "80%", height: "auto", margin: "0 auto" }}>
                                    {
                                        obj.slice(8).map((val, i) => (
                                            <button className='managerpagebtn'
                                                onClick={() => openModal(val.title)}
                                                style={{
                                                    width: "100%",
                                                    height: "45px",
                                                    padding: "10px 20px",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    border: "none",
                                                    color: "#fff",
                                                    fontSize: "24px",
                                                    fontWeight: "500",
                                                    marginBottom: "10px",
                                                    opacity: bookDisabledMemo(val?.title) ? "0.7" : "1"
                                                }}
                                                disabled={bookDisabledMemo(val?.title)}
                                            >
                                                {val.title}
                                            </button>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >

            <CustomModal
                modalIsOpen={modalIsOpen}
                setIsOpen={setIsOpen}
                openModal={openModal}
                closeModal={closeModal}
            >
                <CustomFormButtons
                    currModalClicked={currModalClicked}
                    closeModal={closeModal}
                    setIntgModalOpen={setIntgModalOpen}
                />
            </CustomModal>

            {/* modal for integration manager view all */}
            <CustomModal
                modalIsOpen={intModalOpen}
                setIsOpen={setIntgModalOpen}
                openModal={() => setIntgModalOpen(true)}
                closeModal={() => setIntgModalOpen(false)}
            >
                <div>
                    <span className="d-flex justify-content-end">
                        <AiOutlineClose
                            className="icon"
                            onClick={() => setIntgModalOpen(false)}
                            size={20}
                            color="gray"
                        />
                    </span>
                    <div className='ml-5'>
                        {bankLoading && <div>Loading...</div>}
                        <h1 className='mb-5'>Bank Names</h1>
                        {
                            bankData?.map((item, i) => {
                                // console.log("itemff", item)
                                return (
                                    <p
                                        key={i}
                                        style={{ margin: "5px 0px", cursor: "pointer", fontSize: "20px", fontWeight: 600 }}
                                        onClick={() => navigate('/dashboard-manager', { state: { slideNo: 5 } })}
                                    >
                                        {item?.institutionData?.name}
                                    </p>
                                )
                            })
                        }
                    </div>
                </div>
            </CustomModal>
        </>
    )
}

export default Welcome2