import React, { useEffect, useMemo, useState } from 'react'
import { useLanguage } from '../../Providers/LanguageContext';
import { useUserData } from '../../Providers/UserWrapper';
import moment from "moment";
import "react-datetime/css/react-datetime.css";
import Datetime from "react-datetime";
import { CSVLink } from "react-csv";
import * as XLSX from 'xlsx';
import { ThreeDots } from 'react-loader-spinner'
import { useNavigate } from 'react-router-dom';
import CustomLoaderLine from '../../Component/Loader/CustomLoaderLine';

const ReportInputSection = ({
    pdfData,
    pdfLoading,
    setReportDate,
    reportDate,
    reportSelect,
    setSendName,
    setShowModal,
    setShowSchedModal,
    setSchedName,
    schedNameMemo,
    setWeekName,
    setMonthName,
    selectNameMemo,
    hoverLoading,
    time
}) => {
    const { language, currentLanguage } = useLanguage();
    const { profileData, repCsv, repExcel } = useUserData();
    const [showWeekModal, setShowWeekModal] = useState(false);
    const [showMonthModal, setShowMonthModal] = useState(false);
    const navigate = useNavigate();

    // console.log("reportSelect", reportSelect)
    // console.log("timettyy", time)

    let curr_date = new Date();
    function get_all_dates(year, month) {
        let date = new Date(year, month, 1);
        let dates = [];
        let arr = []
        let i = 0;
        while (date.getMonth() === month) {
            dates.push(new Date(date));
            date.setDate(date.getDate() + 1);
            // console.log(dates[i]);
            arr.push(moment(dates[i]).format("D"));
            i++;
        }
        // console.log("monthAerr", arr)
        return (arr)
    }
    // console.log("monthAerr", get_all_dates(curr_date.getFullYear(), curr_date.getMonth()))

    const weekName = [
        {
            name: "Sunday",
            name_es: "Domingo",
            day: 0
        },
        {
            name: "Monday",
            name_es: "Lunes",
            day: 1
        },
        {
            name: "Tuesday",
            name_es: "Martes",
            day: 2
        },
        {
            name: "Wednesday",
            name_es: "Miércoles",
            day: 3
        },
        {
            name: "ThursDay",
            name_es: "Jueves",
            day: 4
        },
        {
            name: "Friday",
            name_es: "Viernes",
            day: 5
        },
        {
            name: "Saturday",
            name_es: "Sábado",
            day: 6
        }
    ];


    // for download excel
    const arrayToExcel = (myarray, sheetName, fileName) => {
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.aoa_to_sheet(myarray);
        XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);

        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = URL.createObjectURL(data);

        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        link.click();
        URL.revokeObjectURL(url);
    }

    const myArray = [
        ['User Defined Date Range Total', 'Current Month Total', 'Current Year Total', 'Current Year Total'],
        ['John', 25, 'USA'],
        ['Alice', 30, 'Canada'],
        ['Bob', 35, 'UK']
    ];

    const handleExportClick = () => {
        // arrayToExcel(myArray, 'Sheet1', 'output.xlsx');
        arrayToExcel(repExcel, 'Sheet1', 'output.xlsx');
    };

    // view and edit button click
    const hamdleViewEditDetail = () => {
        // console.log("reportSelectbbb", reportSelect)
        switch (reportSelect) {
            case "ExpenseDetail":
                // navigate("/expense-datewise", {
                navigate("/viewexpensedetails", {
                    state: {
                        startDate: reportDate.startData,
                        endDate: reportDate.endDate,
                        time: time
                    }
                })
                break;
            case "Transaction":
                navigate("/editexistingrevenue", {
                    state: {
                        startDate: reportDate.startData,
                        endDate: reportDate.endDate,
                        time: time
                    }
                })
                break;
            case "Profitability":
                // navigate("/customer-vendor-datewise", {
                navigate("/edit-expense-details", {
                    state: {
                        startDate: reportDate.startData,
                        endDate: reportDate.endDate,
                        time: time
                    }
                })
                break;
            case "Revenue":
                navigate("/editexistingrevenue", {
                    state: {
                        startDate: reportDate.startData,
                        endDate: reportDate.endDate,
                        time: time
                    }
                })
                break;
            case "Expense":
                navigate("/viewexpensedetails", {
                    state: {
                        startDate: reportDate.startData,
                        endDate: reportDate.endDate,
                        time: time
                    }
                })
                break;
            // default: null
        }
    }

    const isEditRxpDetMemo = useMemo(() => {
        switch (reportSelect) {
            case "ExpenseDetail": return true;
            case "Transaction": return true;
            case "Profitability": return true;
            case "Revenue": return true;
            case "Expense": return true;
            default: return false
        }
    }, [reportSelect])

    return (
        <div>
            <div className="report-selector">

                <form>
                    <div className="">
                        <div className="col-md-12">
                            <div className="row">
                                <div className="form-group col-md-2">
                                    <Datetime
                                        // renderInput={renderInput}
                                        // utc={true}
                                        value={reportDate?.startData ? moment(reportDate?.startData) : ""}
                                        closeOnSelect
                                        onChange={(e) => setReportDate((prev) => {
                                            console.log('dateTime', e)
                                            return {
                                                ...prev,
                                                startData: moment.isMoment(e) ? e.format("yyyy-MM-DD") : ""
                                            }
                                        }
                                        )}
                                        timeFormat={false}
                                        initialValue={language.start_date}
                                        className="report-date-input"
                                    />
                                </div>

                                <div className="form-group col-md-2">
                                    <Datetime
                                        // renderInput={renderInput}
                                        // utc={true}
                                        value={reportDate?.startData ? moment(reportDate?.endDate) : ""}
                                        closeOnSelect
                                        onChange={(e) => setReportDate((prev) => {
                                            return {
                                                ...prev,
                                                endDate: moment.isMoment(e) ? e.format("yyyy-MM-DD") : ""
                                            }
                                        }
                                        )}
                                        timeFormat={false}
                                        initialValue={language.end_date}
                                        className="report-date-input "
                                        input={true}
                                    />
                                </div>

                                <div className="form-group col-md-3">
                                    <button
                                        className="reprtBtnGrp w-100"
                                        style={{ borderRadius: "5px" }}
                                        onClick={(e) => {
                                            e.preventDefault()
                                        }}
                                    >
                                        {selectNameMemo}
                                    </button>
                                </div>

                                {/* download */}
                                <div className="form-group col-md-2">
                                    <div className="dropdown">
                                        <button
                                            className="btn btn-secondary dropdown-toggle reprtBtnGrp w-100"
                                            type="button"
                                            data-toggle="dropdown"
                                            aria-expanded="false"
                                        >
                                            {language.homeBannerBtn2}
                                        </button>

                                        <div className="dropdown-menu">
                                            <a className="dropdown-item" style={{ cursor: "pointer" }}>
                                                {/* <CSVLink data={repCsv.data} headers={repCsv.headers}> */}
                                                <CSVLink data={repExcel}>
                                                    CSV
                                                </CSVLink>
                                            </a>
                                            {
                                                pdfLoading
                                                    ?
                                                    <ThreeDots
                                                        height="20"
                                                        width="50"
                                                        radius="5"
                                                        color="#0E2537"
                                                        ariaLabel="three-dots-loading"
                                                        wrapperStyle={{}}
                                                        wrapperClassName=""
                                                        visible={true}
                                                    />
                                                    :
                                                    <a className="dropdown-item" href={pdfData} target="_blank">
                                                        PDF
                                                    </a>
                                            }

                                            <a className="dropdown-item" onClick={handleExportClick}>
                                                Excel
                                            </a>
                                        </div>
                                    </div>
                                </div>

                                {/* send */}
                                <div className="form-group col-md-1">
                                    <div
                                        className="btn btn-secondary dropdown-toggle reprtBtnGrp w-100"
                                        onClick={() => {
                                            setShowModal(true)
                                        }}
                                    >
                                        {language.send}
                                    </div>
                                </div>

                                {/* Schedule */}
                                <div className="form-group col-md-2">
                                    <div className="dropdown">
                                        <button
                                            className="btn btn-secondary dropdown-toggle reprtBtnGrp w-100"
                                            type="button"
                                            data-toggle="dropdown"
                                            aria-expanded="false"
                                        // disabled={true}
                                        >
                                            {schedNameMemo ? schedNameMemo : language.schedule}
                                        </button>
                                        <div
                                            className="dropdown-menu"
                                            style={{ top: "8px" }}
                                        >
                                            <a className="dropdown-item" onClick={() => {
                                                setSchedName("daily");
                                                setShowSchedModal(true)
                                                // setShowWeekModal(false);
                                                // setShowMonthModal(false);
                                            }}>
                                                {language.daily}
                                            </a>
                                            <a className="dropdown-item" onClick={() => {
                                                setSchedName("weekly");
                                                setShowSchedModal(true)
                                                // setShowWeekModal(true);
                                                // setShowMonthModal(false);
                                            }}>
                                                {language.weekly}
                                            </a>
                                            <a className="dropdown-item" onClick={() => {
                                                setSchedName("monthly");
                                                setShowSchedModal(true)
                                                // setShowWeekModal(false);
                                                // setShowMonthModal(true);
                                            }}>
                                                {language.monthly}
                                            </a>
                                        </div>
                                    </div>

                                    {
                                        showWeekModal &&
                                        <div className='weekly-sched-box' style={{ top: "8px" }}>
                                            {
                                                weekName.map((item, i) => {
                                                    return (
                                                        <p key={i} onClick={() => {
                                                            setWeekName(item);
                                                            // setShowModal(true);
                                                            setShowSchedModal(true)
                                                            setShowWeekModal(false);
                                                        }}>
                                                            {currentLanguage === "eng" ? item.name : item.name_es}
                                                        </p>
                                                    )
                                                })
                                            }
                                        </div>
                                    }

                                    {
                                        showMonthModal &&
                                        <div className='monthly-sched-box' style={{ top: "8px" }}>
                                            {
                                                get_all_dates(curr_date.getFullYear(), curr_date.getMonth()).map((item, i) =>
                                                    <p onClick={() => {
                                                        setMonthName(item)
                                                        // setShowModal(true);
                                                        setShowSchedModal(true);
                                                        setShowMonthModal(false);
                                                    }}>
                                                        {item}
                                                    </p>
                                                )
                                            }
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </form>

            </div >


            {/* View & Edit Details button */}
            {/* {time && */}
            {isEditRxpDetMemo &&
                <div className='d-flex justify-content-end mb-3'>
                    <button
                        className="btn reprtBtnGrp"
                        type="button"
                        onClick={hamdleViewEditDetail}
                    >
                        {hoverLoading ? <CustomLoaderLine width="50px" height="30px" /> : language.view_edit_details}
                    </button>
                </div>
            }
            <div className="rprtQury mb-5">
                <h4>
                    {language.report_generated_by}:<span> {profileData && profileData?.name + " " + profileData?.surName}</span>
                </h4>
                <h4>
                    {language.report_generated_on}:<span> {moment().format("YYYY-MM-DD")}</span>
                </h4>
            </div>
        </div >
    )
}

export default ReportInputSection;