import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import moment from 'moment';
import toast from 'react-hot-toast';
import { FaArrowCircleDown } from 'react-icons/fa';
// import SetUpManageDocs from './SetUpManageDocs';
import { FaFileUpload } from "react-icons/fa";
import HttpClient from '../../../utils/HttpClient';
import { useLanguage } from '../../../Providers/LanguageContext';
import { useUserData } from '../../../Providers/UserWrapper';
import NoDataFound from '../../../Component/NoDataFound';
import ManageOffers from '../ManageOffers';
import SetupInvDocs from '../SetupInvDocs';


function MakeAnOfferModal(props) {
    const {
        closeModal,
        singleUserData,
        setCallGetAcc,
        getLinkedAccData
    } = props;
    const { language, currentLanguage } = useLanguage()
    const { setIsLoading } = useUserData();
    const loop = [{ id: 1, status: "Attached", }, { id: 2, status: "Attached", }, { id: 3, status: "Past Due", },];

    const settings = {
        dots: false,
        infinite: false,
        swipe: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    const [transactionData, setTransacctionData] = useState({});
    const [singleInvData, setSingleInvData] = useState({});

    const initDetailDescription = {
        description: "",
        subTotal: "",
        taxType: "",
        tax: "",
        taxAmount: "",
        total: "",
        pdf: "",
        checked: false
    }
    const initTaxDet = {
        vendorName: "",
        transactionDate: "",
        description: "",
        subTotal: "",
        taxType: "",
        tax: "",
        taxAmount: "",
        total: "",
        updatedPrice: "",
        _id: "",
        detailDescription: [],
        pdf: "",
        checked: false
    }
    const [taxDetData, setTaxDetData] = useState([initTaxDet])

    const initFilter = {
        startDate: "",
        endData: "",
        vendorName: ""
    }
    const [filterData, setFilterData] = useState(initFilter);

    const [taxDetIndex, setTaxDetIndex] = useState({ indTax: null, valPrice: null });

    console.log("taxDetData", taxDetData, singleUserData)


    // handle Change
    const handleChangeTaxDet = (e, i) => {
        const { name, value } = e.target;
        // console.log("namee", name, e.target.checked)
        if (name === "checked") {
            const newData = taxDetData.map((item, ind) => {
                // console.log("indii", i, ind)
                if (i === ind) {
                    return { ...item, [name]: e.target.checked }
                } else {
                    return item
                }
            })
            setTaxDetData(newData)
        } else {
            const newData = taxDetData.map((item, ind) => {
                if (i === ind) {
                    return { ...item, [name]: value }
                } else {
                    return item
                }
            })
            setTaxDetData(newData)
        }
    }

    // calculate total
    const calculateTotal = (num1, num2) => {
        return Number(num1) + Number(num2)
    }

    // calculate tax amount
    const calculateTaxAmount = (price, tax) => {
        return (price * tax) / 100
    }

    // calculated updated sum
    const calculateUpdatedSum = (arr) => {
        const updatedSum = arr?.reduce((acc, cur) => Number(acc) + Number(cur?.total), 0)
        return updatedSum
    }


    // get all transaction
    // const getAllTransacion = async () => {
    //     const data = {
    //         "accountRegCode": singleUserData?.accountRegCode,
    //         "startDate": filterData?.startDate,
    //         "endDate": filterData?.endData,
    //         "vendorName": filterData?.vendorName
    //     }
    //     setIsLoading(true)
    //     const res = await HttpClient.requestData("bookkeeper/get-user-expense-details", "POST", data)
    //     setIsLoading(false)
    //     // console.log("resAlltt", res)
    //     if (res && res?.status) {
    //         // const fltData = removeDuplicate(res?.data)
    //         setTransacctionData(res?.data)
    //         const newData = res?.data?.map((item) => ({
    //             ...initTaxDet,
    //             vendorName: item?.vendorName,
    //             transactionDate: moment(item?.transactionDate).format("DD-MM-YYYY"),
    //             description: item?.expenseTypeData?.name,
    //             total: item?.price,
    //             _id: item?._id,
    //             updatedPrice: item?.updatedPrice ? item?.updatedPrice : "",
    //             pdf: item?.finalPdf ? item?.finalPdf : "",
    //             checked: item?.checkTaxDeduct,
    //             detailDescription: item?.descriptionDetails
    //                 ?
    //                 item?.descriptionDetails?.map(ele => ({
    //                     description: ele?.item_description,
    //                     subTotal: ele?.subTotal,
    //                     taxType: ele?.taxType,
    //                     tax: ele?.tax,
    //                     taxAmount: ele?.taxAmount,
    //                     total: ele?.totalPrice,
    //                     pdf: ele?.pdf
    //                 }))
    //                 :
    //                 []
    //         }))
    //         // console.log("fltDatares", fltData)
    //         setTaxDetData(newData)
    //     }
    // }


    // useEffect(() => {
    //     if (
    //         (filterData.startDate !== "" && filterData.endData !== "") ||
    //         (filterData.startDate === "" && filterData.endData === "")
    //     ) {
    //         getAllTransacion()
    //     }
    // }, [singleUserData, filterData.startDate, filterData.endData])

    // useEffect(() => {
    //     // if (filterData.vendorName) {
    //     const timer = setTimeout(() => {
    //         getAllTransacion()
    //     }, 600);

    //     return () => clearTimeout(timer)
    //     // }
    // }, [filterData.vendorName])


    // calculate the updated sum price
    useEffect(() => {
        const sumPrice = calculateUpdatedSum(taxDetData[taxDetIndex?.indTax]?.detailDescription);
        handleChangeTaxDet({ target: { name: "updatedPrice", value: sumPrice } }, taxDetIndex?.indTax)
    }, [taxDetIndex])


    return (
        <>
            <section className='missingDocModal'>
                <div className='missingDocModInninvstmnt'>
                    <div className='missingDocClose' onClick={() => closeModal()}>
                        <i className="fa-solid fa-xmark"></i>
                    </div>

                    {/* <Slider {...settings}> */}

                    {/* slide 1 */}
                    {/* <div>
                            <SetupInvDocs
                                singleUserData={singleUserData}
                                setCallGetAcc={setCallGetAcc}
                            />
                        </div> */}

                    {/* slide 2 */}
                    <ManageOffers
                        closeModal={closeModal}
                        singleUserData={singleUserData}
                        getLinkedAccData={getLinkedAccData}
                    />
                    {/* </Slider> */}
                </div>
            </section>
        </>
    )
}

export default MakeAnOfferModal