import React from 'react'

function DeleteAccountPage() {
    return (
        <>
            <section className='deleteaccount_page'>
                <div className='container'>
                    <h2 className='heding'>Delete My User Account</h2>
                    <p className='desc'>Please Enter your Account Code that you would like to delete and then click the delete button below:</p>
                    <form>
                        <div className="form-group">
                            <label htmlFor="">
                                Account Code:
                            </label>
                            <input
                                type="password"
                                className="form-control"
                                id=""
                                placeholder="enter code here"
                            />
                        </div>
                    </form>

                    <div className='Deletebutton_btn'>
                        <button className='btn'>Delete</button>
                    </div>
                </div>
            </section>
        </>
    )
}

export default DeleteAccountPage