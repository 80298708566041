import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLanguage } from "../Providers/LanguageContext";
// import PrivacyEn from "./PrivacyPolicy/PrivacyEn";
// import privacy_en from "../images/blog_pdfs/Fisibility - Privacy Policy_En.pdf";
import privacy_en from '../images/blog_pdfs/Privacy_Policy_En.pdf';
import privacy_es from "../images/blog_pdfs/privacy_police_Es.pdf";
// import { Document, Page } from "react-pdf";

// import PDFViewer from 'pdf-viewer-reactjs'

const PrivacyPolicy = () => {
  const { language, currentLanguage } = useLanguage();
  console.log('currentLanguage', currentLanguage);
  const navigate = useNavigate();
  const [pageNumber, setPageNumber] = useState(1);
  const [numPages, setNumPages] = useState(null);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <div>
      <iframe
        style={{ border: "1px solid #666CCC", width: "100%", height: "100vh" }}
        id="fred"
        title="PDF in an i-Frame"
        src={currentLanguage === "eng" ? privacy_en : privacy_es}
      ></iframe>
    </div>
  );
};

export default PrivacyPolicy;
