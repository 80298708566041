import React, { useState } from "react";
import { useEffect } from "react";
import { toast } from "react-hot-toast";
import { useLanguage } from "../../Providers/LanguageContext";
import HttpClient from "../../utils/HttpClient";

export default function RegisterInvestment({ setIsRegisterInv }) {
  const { language, currentLanguage } = useLanguage();

  const initData = {
    InvestorOrgName: "",
    IndividualName: "",
    Email: "",
    InvestmentType: "",
    InvestmentAmt: "",
    InvestmentDate: "",
    InvestmentURL: "",
    InvestmentOrgName: "",
    KeypersonName: "",
    InvestmentEmail: "",
    TaxID: "",
    RfcID: "",
    CurpID: "",
    ln: "EN",
  };

  const [RegData, setRegData] = useState(initData);
  console.log("RegData", RegData);

  useEffect(() => {
    if (currentLanguage === "eng") {
      setRegData((prev) => {
        return { ...prev, ln: "EN" };
      });
    } else {
      setRegData((prev) => {
        return { ...prev, ln: "ES" };
      });
    }
  }, [currentLanguage]);

  const handleInvRegister = async (e) => {
    e.preventDefault();
    const emailReg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const urlPattern = /^(https?:\/\/)[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?:\/[^\s]*)?$/;
    if (!RegData.InvestorOrgName) {
      toast.error(language.fill_all_the_field);
      return;
    }
    if (!RegData.IndividualName) {
      toast.error(language.fill_all_the_field);
      return;
    }
    if (!RegData.Email) {
      toast.error(language.fill_all_the_field);
      return;
    } else if (!emailReg.test(RegData.Email)) {
      toast.error(language.email_valid);
      return;
    }
    if (!RegData.InvestmentType) {
      toast.error(language.fill_all_the_field);
      return;
    }
    if (!RegData.InvestmentAmt) {
      toast.error(language.fill_all_the_field);
      return;
    }
    if (!RegData.InvestmentDate) {
      toast.error(language.fill_all_the_field);
      return;
    }

    if (!RegData.InvestmentURL) {
      toast.error(language.fill_all_the_field);
      return;
    } else if (!urlPattern.test(RegData.InvestmentURL)) {
      toast.error(language.enter_valid_url);
      return;
    }
    if (!RegData.InvestorOrgName) {
      toast.error(language.fill_all_the_field);
      return;
    }
    if (!RegData.KeypersonName) {
      toast.error(language.fill_all_the_field);
      return;
    }

    if (!RegData.InvestmentEmail) {
      toast.error(language.fill_all_the_field);
      return;
    } else if (!emailReg.test(RegData.InvestmentEmail)) {
      toast.error(language.email_valid);
      return;
    }

    if (!RegData.TaxID) {
      toast.error(language.fill_all_the_field);
      return;
    }
    if (!RegData.RfcID) {
      toast.error(language.fill_all_the_field);
      return;
    }
    if (!RegData.CurpID) {
      toast.error(language.fill_all_the_field);
      return;
    }
    const res = await HttpClient.requestData("add-investment", "POST", RegData);
    console.log("RegDataRes", res);
    if (res.status) {
      toast.success(language.registered_successfully_confirm);
      setRegData(initData);
      setIsRegisterInv(false);
    } else {
      toast.error(res.message);
    }
  };

  return (
    <>
      <div className="RegisterInvest">
        <section className="Regiter InvestREgi">
          <div className="heading">
            <h1 style={{ fontWeight: "400" }}>{language.reg_inv}</h1>
          </div>
          <p className="datos">{language.data}</p>
          <div onClick={() => setIsRegisterInv(false)}>
            <a href="#" className="closeIcon">
              <i class="fa fa-times" aria-hidden="true"></i>
            </a>
          </div>
          <form>
            <div className="form-group row">
              <div className="col-xl-12 col-lg-12 col-md-12 col-12 mb-4">
                <div className="company">
                  <div className="row">
                    <label htmlFor="" className="col-sm-3 col-form-label">
                      {language.register_investment}
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        placeholder={language.company_name}
                        value={RegData.InvestorOrgName}
                        onChange={(e) =>
                          setRegData((prev) => {
                            return { ...prev, InvestorOrgName: e.target.value };
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="company">
                  <div className="row">
                    <label className="col-sm-3 col-form-label">
                      {language.individual_name}
                    </label>
                    <div className="col-sm-4">
                      <input
                        type="text"
                        className="form-control"
                        placeholder={language.first_last_name}
                        value={RegData.IndividualName}
                        onChange={(e) =>
                          setRegData((prev) => {
                            return { ...prev, IndividualName: e.target.value };
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="company">
                  <div className="row">
                    <label className="col-sm-3 col-form-label">
                      {language.email}
                    </label>
                    <div className="col-sm-4">
                      <input
                        type="email"
                        className="form-control"
                        id="inputPassword"
                        placeholder={language.email}
                        value={RegData.Email}
                        onChange={(e) =>
                          setRegData((prev) => {
                            return { ...prev, Email: e.target.value };
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="company">
                  <div className="row">
                    <label className="col-sm-3 col-form-label">
                      {language.investment_type}
                    </label>
                    <div className="col-sm-4">
                      <input
                        type="text"
                        className="form-control"
                        placeholder={language.debt_equity_mezz}
                        value={RegData.InvestmentType}
                        onChange={(e) =>
                          setRegData((prev) => {
                            return { ...prev, InvestmentType: e.target.value };
                          })
                        }
                      />
                    </div>
                    <label className="col-sm-2 col-form-label">
                      {language.investment_amt}
                    </label>
                    <div className="col-sm-3">
                      <input
                        type="number"
                        className="form-control"
                        placeholder="$1:00"
                        value={RegData.InvestmentAmt}
                        onChange={(e) =>
                          setRegData((prev) => {
                            return { ...prev, InvestmentAmt: e.target.value };
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
                {/* <div className="company">
                  <div className="row">
                    <label className="col-sm-4 col-form-label">
                      {language.investment_amt}
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="$1:00"
                        value={RegData.InvestmentAmt}
                        onChange={(e) =>
                          setRegData((prev) => {
                            return { ...prev, InvestmentAmt: e.target.value };
                          })
                        }
                      />
                    </div>
                  </div>
                </div> */}
                <div className="company">
                  <div className="row">
                    <label className="col-sm-3 col-form-label">
                      {language.investment_date}
                    </label>
                    <div className="col-sm-4">
                      <input
                        type="date"
                        className="form-control"
                        id="inputPassword"
                        placeholder=""
                        value={RegData.InvestmentDate}
                        onChange={(e) =>
                          setRegData((prev) => {
                            return { ...prev, InvestmentDate: e.target.value };
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="company">
                  <div className="row">
                    <label className="col-sm-3 col-form-label">
                      {language.investment_url}
                    </label>
                    <div className="col-sm-4">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="HTTPS://"
                        value={RegData.InvestmentURL}
                        onClick={() => {
                          if (!RegData.InvestmentURL) {
                            setRegData((prev) => {
                              return { ...prev, InvestmentURL: "https://" };
                            })
                          }
                        }}
                        onChange={(e) =>
                          setRegData((prev) => {
                            return { ...prev, InvestmentURL: e.target.value };
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>


              <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                <div className="company">
                  <div className="row">
                    <label className="col-sm-3 col-form-label" style={{ color: "gray" }}>
                      {language.investment_name}
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control mail"
                        placeholder={language.company_name}
                        value={RegData.InvestmentOrgName}
                        onChange={(e) =>
                          setRegData((prev) => {
                            return {
                              ...prev,
                              InvestmentOrgName: e.target.value,
                            };
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="company">
                  <div className="row">
                    <label className="col-sm-3 col-form-label" style={{ color: "gray" }}>
                      {language.key_person_name}
                    </label>
                    <div className="col-sm-4">
                      <input
                        type="text"
                        className="form-control mail"
                        placeholder={language.first_last_name}
                        value={RegData.KeypersonName}
                        onChange={(e) =>
                          setRegData((prev) => {
                            return { ...prev, KeypersonName: e.target.value };
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="company">
                  <div className="row">
                    <label className="col-sm-3 col-form-label" style={{ color: "gray" }}>
                      {language.investment_email}
                    </label>
                    <div className="col-sm-4">
                      <input
                        type="email"
                        className="form-control mail"
                        placeholder=""
                        value={RegData.InvestmentEmail}
                        onChange={(e) =>
                          setRegData((prev) => {
                            return { ...prev, InvestmentEmail: e.target.value };
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="company">
                  <div className="row">
                    <label className="col-sm-3 col-form-label" style={{ color: "gray" }}>Tax-ID#</label>
                    <div className="col-sm-4">
                      <input
                        type="text"
                        className="form-control mail"
                        placeholder={123456789}
                        value={RegData.TaxID}
                        onChange={(e) =>
                          setRegData((prev) => {
                            return { ...prev, TaxID: e.target.value };
                          })
                        }
                      />
                    </div>
                    <label className="col-sm-1 col-form-label">RFC ID</label>
                    <div className="col-sm-4">
                      <input
                        type="text"
                        className="form-control mail"
                        placeholder="RFC12345678"
                        value={RegData.RfcID}
                        onChange={(e) =>
                          setRegData((prev) => {
                            return { ...prev, RfcID: e.target.value };
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
                {/* <div className="company">
                  <div className="row">
                    <label className="col-sm-4 col-form-label">RFC ID</label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control mail"
                        placeholder="RFC12345678"
                        value={RegData.RfcID}
                        onChange={(e) =>
                          setRegData((prev) => {
                            return { ...prev, RfcID: e.target.value };
                          })
                        }
                      />
                    </div>
                  </div>
                </div> */}
                <div className="company">
                  <div className="row">
                    <label
                      htmlFor="inputPassword"
                      className="col-sm-3 col-form-label"
                      style={{ color: "gray" }}
                    >
                      SS# / CURP ID
                    </label>
                    <div className="col-sm-4">
                      <input
                        type="text"
                        className="form-control mail"
                        id="inputPassword"
                        placeholder=""
                        value={RegData.CurpID}
                        onChange={(e) =>
                          setRegData((prev) => {
                            return { ...prev, CurpID: e.target.value };
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="register d-flex align-items-center justify-content-between">
              <div className="">
                <button
                  className="return rounded-pill"
                  onClick={() => setIsRegisterInv(false)}
                >
                  {language.return}
                </button>
              </div>
              <div className="">
                <div className="confirm ">
                  <button onClick={handleInvRegister}>
                    {language.register_confirm}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </section>
      </div>
    </>
  );
}
