import React, { useContext } from 'react'
import { Link } from "react-router-dom";
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import Eliminategirl from "../../images/eliminate-girl.png";
import { useLanguage } from '../../Providers/LanguageContext';
import { UserContext } from '../../Providers/UserWrapper';
import HttpClient from '../../utils/HttpClient';

function Eliminate({closeModal}) {
    const navigate = useNavigate()
    const {language} = useLanguage()
    const {logout} = useContext(UserContext)
    const handleDelete = async()=>{
        const res = await HttpClient.requestData("delete","DELETE",{});
        if(res && res.status){
            toast.success(language.acc_deleted);
            logout()
            navigate("/login")
        }else {
            toast.error(language.acc_delete_failed);
        }
    }
    return (

        <>

            <div id="eliminate" className='eliminate-modal'>
                <div className='eliminate-img' style={{ backgroundImage: `url('${Eliminategirl}')`, position: "relative" }}></div>
                <div className='eliminate-body'>
                    <div className='eliminate-cnt'>
                        <p>{language.delete_account}</p>
                            <div className='choice-btn'>
                                <button className='eliminate-btn' onClick={handleDelete}>{language.eliminate}</button>
                                <button className='cancle-btn' onClick={()=>{closeModal(false)}}>{language.cancel}</button>
                            </div>
                    </div>
                </div>
            </div>

        </>

    )
}

export default Eliminate