import React, { useEffect, useState } from 'react'
import ReactDateRange from './ReactDateRange';
import HttpClient from '../../utils/HttpClient';
import { useLanguage } from '../../Providers/LanguageContext';
import TotalDOcumentModal from './TotalDOcumentModal';
import DocumentItemCard from './DocumentItemCard';
import NewDocsVault from './NewDocsVault';

const DocumentVault = ({ docData }) => {
    const { language, currentLanguage } = useLanguage()

    const [openTotalModal, setOpenTotalModal] = useState(false);
    const [singleTotalDoc, setSingleTotalDoc] = useState([]);
    const [isShowCalender, setIsShowCalender] = useState("")
    const [date, setDate] = useState([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection'
        }
    ]);
    console.log("statedfggas", docData)


    console.log("docDataddf", singleTotalDoc)


    return (
        <>
            <section className='documentManagerslider_sec' onClick={() => setIsShowCalender("")}>
                <div className='container'>
                    <div className='headbar'>
                        <h4>Document Manager – Document Vault</h4>
                    </div>

                    <div className='subheadbar'>
                        <h6>Document Vault</h6>
                    </div>

                    <div className='allDocument_Vault'>

                        {/* {
                            docData.map((item, index) => {
                                // console.log("itemerff", item?.typeNameInSpanish)
                                return (
                                    <DocumentItemCard
                                        key={index}
                                        index={index}
                                        item={item}
                                        setSingleTotalDoc={setSingleTotalDoc}
                                        setOpenTotalModal={setOpenTotalModal}
                                        setIsShowCalender={setIsShowCalender}
                                        isShowCalender={isShowCalender}
                                    />
                                )
                            })
                        } */}
                        <NewDocsVault docData={docData}  />
                    </div>
                </div>
            </section>

            {/* total document modal */}
            <TotalDOcumentModal
                modalIsOpen={openTotalModal}
                closeModal={() => setOpenTotalModal(false)}
                singleTotalDoc={singleTotalDoc}
            />

        </>
    )
}

export default DocumentVault;




