import React from 'react'

function Passwordconformation() {
    return (

        <section id='new-pass'>
            <div className='container'>
                <div className='pass-heading'>
                    <h2>Nueva Contraseña</h2>
                </div>
                <div className='pass-form'>
                    <div className="form-group">
                        <input
                            type="text"
                            className="form-control"
                            id=""
                            aria-describedby=""
                            placeholder="Contraseña"
                        />
                    </div>
                    <div className="form-group">
                        <input
                            type="text"
                            className="form-control"
                            id=""
                            aria-describedby=""
                            placeholder="Conrmar Contraseña"
                        />
                    </div>

                    <div className='new-pass-btn'>
                        <button type="submit" className='pass-btn'>Guardar</button>
                    </div>
                </div>
            </div>
        </section>

    )
}

export default Passwordconformation
