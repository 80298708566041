import React, { useEffect } from "react";
import "./TaskManager.css";
import { Link } from "react-router-dom";
import moment from "moment";
import HttpClient from "../../utils/HttpClient";
import toast from "react-hot-toast";
import { useLanguage } from "../../Providers/LanguageContext";


function TaskPopUp({ closemodal, detailData, getTaskData }) {
  const { language } = useLanguage();


  // console.log("detailDatadff", detailData)

  // const handleSaveDone = async () => {
  //   closemodal();
  //   const data = {
  //     "taskName": detailData.taskName,
  //     "taskNotes": detailData.taskNote,
  //     "taskStatus": detailData.taskStatus ? detailData.taskStatus : "open",
  //     "assignToUser": detailData.assignedUser,
  //     "selectDate": moment(detailData.date).format("YYYY-MM-DD"),
  //     "Time": moment(detailData.date).format("h:mm a")
  //   }
  //   const res = await HttpClient.requestData("add-task-eventDetails", "POST", data)
  //   // console.log("ressff", res)
  //   if (res && res?.status) {
  //     toast.success("Task Added Successfully");
  //     clearState();
  //     getTaskData();
  //   } else {

  //   }
  // }


  const handleSelectStatus = async (e) => {
    if (!e.target.value) return

    const data = {
      taskStatus: e.target.value
    }
    const res = await HttpClient.requestData("update-task-eventDetails/" + detailData?._id, "PUT", data)
    if (res && res?.status) {
      getTaskData();
      closemodal();
      toast.success("Status Updated Sucessfully");
    }
  }

  return (
    <>
      <section className="taskPopSection">
        <div className="taskInPopDiv">
          <p className="taskModHead" style={{ textAlign: 'center' }}>
            Task
          </p>
          <div className="taskInScrollDiv">
            <div className="mb-2">
              {detailData?.taskName && <p className="dryClnDropText">{detailData?.taskName}</p>}
              {detailData?.selectDate && (
                <p className="taskDtDetail">{moment(detailData?.selectDate).format('DD-MM-YYYY')}</p>
              )}
              {detailData?.startTime && detailData?.endTime && (
                <p className="taskTimeDetail">
                  {moment(detailData?.startTime, ['HH:mm']).format('hh:mm a')} -{' '}
                  {moment(detailData?.endTime, ['HH:mm']).format('hh:mm a')}
                </p>
              )}
              {detailData?.taskStatus && <p className="taskNoteSmallHead">Status: {detailData?.taskStatus}</p>}
              <div className="taskNoteTextDiv">
                {detailData?.taskNotes && (
                  <>
                    <p className="taskNoteSmallHead">Task Notes :{detailData?.taskNotes}</p>
                    {/* <p className="taskNoteText">{detailData?.taskNotes}</p> */}
                  </>
                )}

                {
                  detailData?.assignToUserName
                    ?
                    <p className="taskNoteSmallHead">Assign User: {detailData?.assignToUserName}</p>
                    :
                    <p className="taskNoteSmallHead">Assign User: {language.not_assigned}</p>
                }
              </div>
              {detailData?.assignToProjectName && (
                <p className="taskNoteSmallHead">Assign Project: {detailData?.assignToProjectName}</p>
              )}
            </div>
          </div>

          <div className="tskSaveAsBtnDiv">
            <button className="tskSaveAsBtn" onClick={() => closemodal()}>
              Save as Done
            </button>
          </div>
          <button className="closeTaskModBtn" onClick={() => closemodal()}>
            <i class="fa-solid fa-x"></i>
          </button>
        </div>
      </section>
    </>
  );
}

export default TaskPopUp;


