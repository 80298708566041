import React, { useEffect, useState } from "react";
import "./BuildReports.css";
import { useLanguage } from "../../../Providers/LanguageContext";
import toast from "react-hot-toast";
import HttpClient from "../../../utils/HttpClient";
import CustomLoaderLine from "../../../Component/Loader/CustomLoaderLine";
import moment from "moment";
import { useUserData } from "../../../Providers/UserWrapper";

function BuildCapital({ setCapitalCsv, setPdfCreate, setPdfLoader, setEmailSendFunc }) {
  const { language, currentLanguage } = useLanguage();
  const { profileData, setIsLoading } = useUserData();

  const initData = {
    fname: "",
    lname: "",
    role: "",
    email: "",
    noOfUnits: "",
    typeOfUnits: "",
    percent: ""
  }
  const [ownerData, setOwnerData] = useState([initData]);
  const [totalData, setTotalData] = useState({ role: 1, unit: "", percent: "" });

  const [formData, setFormData] = useState({ countryIncorporate: "", stateIncorporate: "", governmentId: "" })

  const [isSave, setIsSave] = useState(true);
  const [loading, setLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [isDelOwner, setIsDelOwner] = useState(false);

  // console.log("formData", formData);

  // onChange ownwr / admore
  const handleChangeOwner = (e, ind) => {
    const { name, value } = e.target;
    const data = ownerData?.map((item, i) => {

      if (i === ind) {
        return { ...item, [name]: value }
      } else {
        return item
      }
    })
    clculateAllDataIncome(data);
  }

  // onchange form
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  }

  // sum calculation function for asset
  const clculateAllDataIncome = (changedArr) => {
    const sumUnit = sumFromArrayOfObject(changedArr, "noOfUnits");
    // const sumPer = sumFromArrayOfObject(changedArr, "percent");

    //  stores the percentage calculate value in percentArr
    const percentArr = changedArr?.map((item) => {
      return ({
        ...item,
        percent: perCentageCalculate(item?.noOfUnits, sumUnit),
      })
    })

    setOwnerData(percentArr);

    setTotalData(prev => ({ ...prev, role: changedArr?.length, unit: sumUnit, percent: 100 }));
  }

  // returns the sum of a key from array of object
  const sumFromArrayOfObject = (arr, key) => {
    const sum = arr.reduce((acc, cur) => acc + Number(cur[key]), 0);
    return sum ? sum : "";
  }

  // returns the parcentage of a number
  const perCentageCalculate = (num, total) => {
    const percent = Number(num) / Number(total) * 100;
    return Number.isInteger(percent) ? percent : percent?.toFixed(2);
  }

  const handleSave = async () => {

    const ownerArr = ownerData?.map(item => ({
      "firstName": item.fname,
      "lastName": item.lname
    }));
    // console.log("ownerArr", ownerArr)

    // return

    if (isSave) {
      const sendData = {
        Data: ownerData?.map((item) => ({
          "firstName": item?.fname,
          "lastName": item?.lname,
          "role": item?.role,
          "email": item?.email,
          "units": item?.noOfUnits,
          "typeofUnits": item?.typeOfUnits,
          "percentage": item?.percent
        })),
        "totals": totalData?.role,
        "unitTotal": totalData?.unit,
        "percentageTotal": totalData?.percent,
        "countryofIncorporation": formData?.countryIncorporate,
        "stateofIncorporation": formData?.stateIncorporate,
        "governmentID": formData?.governmentId
      }


      setSaveLoading(true);
      const res = await HttpClient.requestData("reportbuilder-add-capitalization", "POST", sendData);
      console.log("resbbb", res)
      if (res && res?.status) {
        // window.open(res?.path, "_blank");
        setSaveLoading(false);
        toast.success(language.report_saved_successfully);
        setIsSave(!isSave);
      }
      else {
        setSaveLoading(false);
      }
    } else {
      setIsSave(!isSave);
    }
  }

  // click on build report
  const handleBuildRepport = async () => {
    // if (!validateAsset() || !validateLiability()) {
    //   toast.error(language.please_enter_all);
    //   return
    // }

    if (isSave) {
      toast.error("Please Save First");
      return
    }

    // console.log("accNameData", accNameData)
    const sendData = {
      Data: ownerData?.map((item) => ({
        "firstName": item?.fname,
        "lastName": item?.lname,
        "role": item?.role,
        "email": item?.email,
        "units": item?.noOfUnits,
        "typeofUnits": item?.typeOfUnits,
        "percentage": item?.percent
      })),
      "totals": totalData?.role,
      "unitTotal": totalData?.unit,
      "percentageTotal": totalData?.percent,
      "countryofIncorporation": formData?.countryIncorporate,
      "stateofIncorporation": formData?.stateIncorporate,
      "governmentID": formData?.governmentId
    }

    // return

    setLoading(true);
    setPdfLoader(true);
    // const res = await HttpClient.requestData("report-builder/capitalization-table", "POST", sendData);
    const res = await HttpClient.requestData("reportbuilder-add-capitalization", "POST", sendData);
    // console.log("resbbb", res)
    if (res && res?.status) {
      // window.open(res?.path, "_blank");
      setLoading(false);
      setPdfLoader(false);
      toast.success(language.report_build_succ);
    }
    else {
      setLoading(false);
      setPdfLoader(false);
    }
  }

  // getting saved invoice data
  // const getInvoiceData = async () => {
  //   const sendData = {
  //     "type": "capitalization_table"
  //   }
  //   const res = await HttpClient.requestData("view-record-reportbuilder", "POST", sendData);
  //   console.log("resInvvowner", res);
  //   if (res && res?.status) {
  //     const ownerArr = res?.data?.Owner_Name?.map(item => ({ ...initData, fname: item?.firstName, lname: item?.lastName }));
  //     setOwnerData([...ownerArr]);
  //   }
  // }

  // send mail
  const sendCapatialMail = async (emailData, oncloseSendModal) => {
    const data = {
      "email": emailData?.email,
      "language": currentLanguage === "eng" ? "EN" : "ES",
      "Data": ownerData?.map((item) => ({
        "firstName": item?.fname,
        "lastName": item?.lname,
        "role": item?.role,
        "email": item?.email,
        "units": item?.noOfUnits,
        "typeofUnits": item?.typeOfUnits,
        "percentage": item?.percent
      })),
      "totals": totalData?.role,
      "unitTotal": totalData?.unit,
      "percentageTotal": totalData?.percent,
      "countryofIncorporation": formData?.countryIncorporate,
      "stateofIncorporation": formData?.stateIncorporate,
      "governmentID": formData?.governmentId
    }

    oncloseSendModal();
    setIsLoading(true);
    const res = await HttpClient.requestData("reportbuilder/pdf/capitalizationtable", "POST", data)
    if (res && res?.status) {
      toast?.success(language.mail_sent_success);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  }

  // get invoice statement
  const getInvoiceStatement = async () => {
    const res = await HttpClient.requestData("reportbuilder-view-capitalization", "GET");
    // console.log("resscall", res);
    if (res && res?.status) {
      const incdata = res?.data?.Data?.map((item) => ({
        fname: item?.firstName,
        lname: item?.lastName,
        role: item?.role,
        email: item?.email,
        noOfUnits: item?.units,
        typeOfUnits: item?.typeofUnits,
        percent: item?.percentage
      }))

      if (incdata) {
        setOwnerData(incdata);
      }

      setTotalData({
        role: res?.data?.totals,
        unit: res?.data?.unitTotal,
        percent: res?.data?.percentageTotal
      })

      setFormData({
        countryIncorporate: res?.data?.countryofIncorporation,
        stateIncorporate: res?.data?.stateofIncorporation,
        governmentId: res?.data?.governmentID
      })
    }
  }

  // dowmload pdf
  const handlePdfDownload = async () => {
    // if (isSave) {
    //     toast.error("Please Save First");
    //     return
    // }

    setPdfLoader(true);
    const res = await HttpClient.requestData("reportbuilder/download/pdf/capitalizationtable", "GET", {})
    setPdfLoader(false);
    if (res && res?.status) {
      window.open(res?.data, "_blank")
    } else {
      toast.error(res?.message || "Something Wrong!")
    }
  }


  useEffect(() => {
    // getInvoiceData();
    getInvoiceStatement();
    setPdfCreate(() => handlePdfDownload);

  }, []);


  //  csv , pdf , excel 
  useEffect(() => {

    if (!isSave) {
      const arrOwner = ownerData?.map((item) => {
        return Object.values(item)
      })

      const finalData = [
        [],
        [language.cap_table_owner, language.period_ending, language.report_date],
        [profileData?.companyName, "", moment().format("DD-MM-YYYY")],
        [language.report_generated_by + ":", profileData?.name + " " + profileData?.surName],
        [],
        [],
        ["Owner Name", "", "Role", "Email", "# of Units", "Type of Units", "%"],
        [],
        ...arrOwner,
        [],
        ["", "Totals", totalData?.role, "", totalData.unit, "", totalData.percent],
        ["", "Country of Incorporation", formData.countryIncorporate],
        ["", "State of Incorporation", formData.stateIncorporate],
        ["", "Government ID", formData.governmentId]
      ];

      // console.log("finalData", finalData);
      setCapitalCsv(finalData);

      // this saves the pdf download function in a state
      // setPdfCreate(() => handleBuildRepport);
    }

    // send
    setEmailSendFunc(() => sendCapatialMail)

  }, [isSave])


  return (
    <div className="mt-4 buildCapitalTableDiv">
      <table className="buildCapitalTable">
        <thead>
          <tr>
            <th className="buildCapLeftTh">

              {/* add button */}
              {
                isSave &&
                <button
                  className="buildAddBtn"
                  onClick={() => {
                    setOwnerData(prev => [...prev, initData]);
                    setTotalData(prev => ({ ...prev, role: ownerData?.length + 1 }))
                  }}
                >
                  <i class="fa-solid fa-plus"></i>
                </button>
              }
              <span className="buildCapLeftThSpan">{language.owner_name}</span>


              {/* delete Row Button */}
              {isSave &&
                <button
                  style={{ fontSize: "12px" }}
                  className="delBuildRowBtn"
                  onClick={() => setIsDelOwner(prev => !prev)}
                >
                  {isDelOwner ? language.remove_delete : language.delete_row}
                </button>
              }
            </th>
            <th className="buildCapMiddleTh">
              <span className="buildCapLeftThSpan">Role</span>
            </th>
            <th className="buildCapMiddleTh">
              <span className="buildCapLeftThSpan">Email</span>
            </th>
            <th className="buildCapMiddleTh">
              <span className="buildCapLeftThSpan">{language.no_of_units}</span>
            </th>
            <th className="buildCapMiddleTh">
              <span className="buildCapLeftThSpan">{language.type_of_unit}</span>
            </th>
            <th className="buildCapRightTh">
              <span className="buildCapLeftThSpan">%</span>
            </th>
          </tr>
        </thead>

        <tbody>
          {
            ownerData?.map((item, i) => {
              return (
                <tr key={i}>
                  <td className="buildCapLeftTd">

                    {/* delete minus icon */}
                    {(isDelOwner && isSave) &&
                      <button
                        className="buildCapiRemoveBtn"
                        onClick={() => {
                          const filteredData = ownerData?.filter((ele, ind) => ind !== i)
                          clculateAllDataIncome(filteredData);
                          // setOwnerData(prev => prev.filter((ele, ind) => ind !== i))
                        }}
                      >
                        <i class="fa-solid fa-minus"></i>
                      </button>
                    }

                    <div>
                      <input
                        type="text"
                        placeholder="First Name"
                        name="fname"
                        value={item.fname}
                        onChange={(e) => handleChangeOwner(e, i)}
                        readOnly={!isSave}
                      />
                      <input
                        type="text"
                        placeholder="Last Name"
                        name="lname"
                        value={item.lname}
                        onChange={(e) => handleChangeOwner(e, i)}
                        readOnly={!isSave}
                      />
                    </div>
                  </td>
                  <td className="buildCapMiddleTd">
                    <input
                      type="text"
                      name="role"
                      value={item.role}
                      onChange={(e) => handleChangeOwner(e, i)}
                      readOnly={!isSave}
                    />
                  </td>
                  <td className="buildCapMiddleTd">
                    <input
                      type="email"
                      name="email"
                      value={item.email}
                      onChange={(e) => handleChangeOwner(e, i)}
                      readOnly={!isSave}
                    />
                  </td>
                  <td className="buildCapMiddleTd">
                    <input
                      type="number"
                      name="noOfUnits"
                      value={item.noOfUnits}
                      onChange={(e) => handleChangeOwner(e, i)}
                      readOnly={!isSave}
                    />
                  </td>
                  <td className="buildCapMiddleTd">
                    <input
                      type="text"
                      name="typeOfUnits"
                      value={item.typeOfUnits}
                      onChange={(e) => handleChangeOwner(e, i)}
                      readOnly={!isSave}
                    />
                  </td>
                  <td className="buildCapRightTd">
                    <input
                      type="number"
                      name="percent"
                      value={item.percent}
                      onChange={(e) => handleChangeOwner(e, i)}
                      readOnly={!isSave}

                    />
                  </td>
                </tr>

              )
            })
          }
        </tbody><br />

        <tfoot>
          <tr>
            <td className="buildCapLeftFootTd">
              <span>Totals:</span>
            </td>
            <td className="buildCapMiddleFootTd">
              <input
                type="number"
                readOnly
                value={totalData.role}
              />
            </td>
            <td className="buildCapMiddleFootTd">
            </td>
            <td className="buildCapMiddleFootTd">
              <input
                type="number"
                readOnly
                value={totalData.unit}
              />
            </td>
            <td className="buildCapMiddleFootTd">
            </td>
            <td className="buildCapRightTd">
              <input
                type="number"
                readOnly
                value={totalData.percent}
              />
            </td>
          </tr>
          <tr>
            <td className="buildCapLeftFootTd">
              <span>{language.country_of_incorporation}:</span>
            </td>
            <td className="buildCapMiddleFootTd">
              <input
                type="text"
                name="countryIncorporate"
                value={formData.countryIncorporate}
                onChange={(e) => handleChange(e)}
              />
            </td>
            <td className="buildCapMiddleFootTd">
            </td>
            <td className="buildCapMiddleFootTd">
            </td>
            <td className="buildCapMiddleFootTd">
            </td>
            <td className="buildCapRightTd">
            </td>
          </tr>
          <tr>
            <td className="buildCapLeftFootTd">
              <span>{language.state_of_incorporation}:</span>
            </td>
            <td className="buildCapMiddleFootTd">
              <input
                type="text"
                name="stateIncorporate"
                value={formData.stateIncorporate}
                onChange={(e) => handleChange(e)}
              />
            </td>
            <td className="buildCapMiddleFootTd">
            </td>
            <td className="buildCapMiddleFootTd">
            </td>
            <td className="buildCapMiddleFootTd">
            </td>
            <td className="buildCapRightTd">
            </td>
          </tr>
          <tr>
            <td className="buildCapLeftFootTd">
              <span>{language.government_id}:</span>
            </td>
            <td className="buildCapMiddleFootTd">
              <input
                type="text"
                name="governmentId"
                value={formData.governmentId}
                onChange={(e) => handleChange(e)}
              />
            </td>
            <td className="buildCapMiddleFootTd">
            </td>
            <td className="buildCapMiddleFootTd">
            </td>
            <td className="buildCapMiddleFootTd">
            </td>
            <td className="buildCapRightTd">
            </td>
          </tr>
        </tfoot>
      </table>

      {/* button group */}
      <div className="d-flex justify-content-center mt-3">
        <button
          className="btn-report-save"
          onClick={() => handleSave()}
        >
          {isSave ?
            saveLoading ? <CustomLoaderLine height="10" width="40" /> : language.save?.toUpperCase()
            :
            language.edit?.toUpperCase()
          }
        </button>
        <button
          className="btn-report-build"
          onClick={() => handleBuildRepport()}
        >
          {loading ? <CustomLoaderLine height="10" width="100" /> : language.build_report?.toUpperCase()}
        </button>
          {/* {language.build_report?.toUpperCase()} */}
      </div>
    </div >
  );
}

export default BuildCapital;
