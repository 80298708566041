import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    name: "",
    data: 0
}

const bookSlice = createSlice({
    name: "Bookkeeper_slice",
    initialState,
    reducers: {
        increment(state, action) {
            console.log("inccc")
            state.data = state.data + 1
        }
    }
    // extraReducers: {

    // }
})

export const { increment } = bookSlice.actions;
export const bookRed = bookSlice.reducer;
