import { async } from "@firebase/util";
import React, { useContext, useEffect, useState } from "react";
import { useLanguage } from "../../Providers/LanguageContext";
import { SpeechContext } from "../../Providers/SpeechWrapper";
import HttpClient from "../../utils/HttpClient";
import { useUserData } from "../../Providers/UserWrapper";
import { reactLocalStorage } from "reactjs-localstorage";

const Sidebar1 = () => {
  const { currentLanguage, language, setCurrentLanguage } = useLanguage();
  const { playing, setIsPlaying, isProject, setIsproject } = useContext(SpeechContext);
  const { profileData, getProfile, isCipAi, setIsCipAi } = useUserData()
  const [isNotification, setIsnotification] = useState(true);
  const [checkAcc, setCheckAcc] = useState(false);

  const handleAudioPlay = async (e) => {
    // console.log(e.target.checked, "audioplay222223");
    const res = await HttpClient.requestData("change-audio", "PUT");
    if (res && res.status) {
      getProfile();
    }
    // const checked = e.target.checked;
    // if (checked) {
    //   setIsPlaying(true);
    // } else {
    //   setIsPlaying(false);
    // }
  };

  const handleAccount = async () => {
    const result = await HttpClient.requestData("switch", "PUT")

    if (result && result.status) {
      reactLocalStorage.setObject("userData", result.data[0])
      setTimeout(() => {
        getProfile()
        window.location.reload(false)
      }, 1000)

    }
  }

  const checkAccount = async () => {
    const result = await HttpClient.requestData("check", "GET")
    if (result) {
      setCheckAcc(result.status)
    }
  }


  useEffect(() => {
    checkAccount()
  })


  return (
    <div className="container">
      {/* {data.map((item, i) => (
        <div className="row" key={i}>
          <div className="col-md-8">
            <span>{item.text}</span>
          </div>
          <div className="col-md-4">{item.switch}</div>
          <hr />
        </div>
      ))} */}

      {/* notification */}
      <div className="row">
        <div className="col-md-8">
          <span>{language.notifications}</span>
        </div>
        <div className="col-md-4">
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              role="switch"
              id="flexSwitchCheckChecked"
              onChange={() => setIsnotification(!isNotification)}
              checked={isNotification}
            />
          </div>
        </div>
        <hr />
      </div>

      {/* cip AI */}
      <div className="row">
        <div className="col-md-8">
          <span>{language.cip_ia}</span>
        </div>
        <div className="col-md-4">
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              checked={isCipAi}
              onChange={() => setIsCipAi(!isCipAi)}
              type="checkbox"
              role="switch"
              id="flexSwitchCheckChecked"
            />
          </div>
        </div>
        <hr />
      </div>

      {/* cip Audio */}
      <div className="row">
        <div className="col-md-8">
          <span>{language.cip_audio}</span>
        </div>
        <div className="col-md-4">
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              checked={playing}
              onChange={handleAudioPlay}
              type="checkbox"
              role="switch"
              id="flexSwitchCheckChecked"
            />
          </div>
        </div>
        <hr />
      </div>

      {/* check user */}
      {
        checkAcc
        &&
        <div className="row">
          <div className="col-md-8">
            <span>
              {
                profileData.userType === "Business" ? language.change_to_personal : language.change_to_business
              }
            </span>
          </div>
          <div className="col-md-4">
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                // checked={''}
                onChange={handleAccount}
                type="checkbox"
                role="switch"
                id="flexSwitchCheckChecked"
              />
            </div>
          </div>
          <hr />
        </div>
      }

      {/* project management */}
      <div className="row">
        <div className="col-md-8">
          <span>{language.project_manager}</span>
        </div>
        <div className="col-md-4">
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              role="switch"
              id="flexSwitchCheckChecked"
              checked={isProject}
              onChange={() => setIsproject(!isProject)}
            />
          </div>
        </div>
        <hr />
      </div>

      {/* language */}
      <div className="row">
        <div className="col-md-8">
          <span>{language.language}</span>
        </div>
        <div className="col-md-4" style={{ cursor: "pointer" }}>

          <span
            className="langchange"
            style={{ fontWeight: currentLanguage === "spanish" ? "bold" : 300, cursor: "pointer" }}
            onClick={() => setCurrentLanguage("spanish")}
          >
            Es{" "}
          </span>
          /
          <span
            className="langchange"
            style={{ fontWeight: currentLanguage === "eng" ? "bold" : 300, cursor: "pointer" }}
            onClick={() => setCurrentLanguage("eng")}
          >
            {" "}
            En{" "}
          </span>

        </div>
        <hr />
      </div>
    </div>
  );
};

export default Sidebar1;
