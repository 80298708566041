import React, { useEffect, useState, ChangeEventHandler, useCallback } from "react";
import HttpClient from "../../../utils/HttpClient";
import { useUserData } from "../../../Providers/UserWrapper";
import { useLanguage } from "../../../Providers/LanguageContext";
import { data } from "jquery";
import { Prev } from "react-bootstrap/esm/PageItem";
import { useNavigate } from "react-router-dom";
import './bankNameDropdown.css';
import CustomLoaderLine from "../../../Component/Loader/CustomLoaderLine";
import moment from "moment";
import toast from "react-hot-toast";
import { PlaidLink, usePlaidLink } from "react-plaid-link";
import IntegrationBankDeailModal from "../../../Modal/IntegrationBankDeailModal";

function DashboardChartSixB({ goToSelectedSlice, time, setDetailModal, setTransData, userCode }) {
    const { profileData, setIsLoading, isInvestor, isBookKeeper } = useUserData();
    const { language, currentLanguage } = useLanguage();
    const navigate = useNavigate();

    const [bankName, setBankName] = useState("");
    const [isShowList, setIsShowList] = useState(false);
    const [searchDate, setSearchDate] = useState({ startDate: "", endDate: "" });

    // bank data
    const [isBankNameDrop, setIsBankNameDrop] = useState(false);
    const [bankData, setBankData] = useState();
    const [singleBankName, setSingleBankName] = useState();
    const [bankDetailData, setBankDetailData] = useState([]);
    const [bankLoading, setBankLoading] = useState(false);
    const [bankDetailLoading, setBankDetailLoading] = useState();
    const [bankNameList, setBankNameList] = useState("");
    const [accType, setAccType] = useState("All");
    const [filteredData, setFilteredData] = useState([]);
    const [totalData, setTotalData] = useState({ accType: "", credit: "", debit: "" });


    // console.log("bankDataas", bankData)

    // getting bank names
    const getBankDetails = async () => {
        setBankLoading(true);
        const res = await HttpClient.requestData("plaid-account-get", "GET");
        // console.log("resBBank", res)
        if (res && res?.status) {
            const filteredData = removeDuplicate(res?.data);
            setBankData(filteredData);
            setBankLoading(false)
        } else {
            setBankLoading(false);
        }
    }

    // getting bank transaction
    const getBankTramsaction = async () => {
        const data = {
            dayType: (searchDate.endDate && searchDate.endDate) ? "date" : time,
            start_date: searchDate.startDate,
            end_date: searchDate.endDate
        }
        setBankDetailLoading(true)
        const res = await HttpClient.requestData(isInvestor || isBookKeeper ? `investor/investor-plaid-trasaction-get/${userCode}` : "plaid-trasaction-get", "POST", data)
        console.log("resdsd", res)
        if (res && res?.status) {
            setBankDetailLoading(false);
            const uniq = removeDuplicate2(res?.data);
            setBankNameList(uniq);
            setBankDetailData(res);
        } else {
            setBankDetailLoading(false);
        }
    }

    // it removes duplicate from array of object
    const removeDuplicate = useCallback((arr) => {
        const unique = arr.filter((obj, index) => {
            return index === arr.findIndex(o => obj?.institutionData?.name === o?.institutionData?.name);
        });
        return unique
    }, [])

    const removeDuplicate2 = useCallback((arr) => {
        const unique = arr.filter((obj, index) => {
            return index === arr.findIndex(o => obj?.institutionName === o?.institutionName);
        });
        console.log("uniqueddduu", unique)
        return unique
    }, [])

    // to calculate total debit per Bank
    const totalDebitPerBank = (name) => {
        const arr = bankDetailData?.data?.filter(item => item?.institutionName === name?.institutionName);
        const sum = arr?.reduce((acc, cur) => {
            if (cur?.amount < 0) {
                return acc + cur?.amount
            } else {
                return acc
            }
        }, 0)
        return -sum?.toFixed(2);
    }

    // to calculate total debit per Bank
    const totalCreditPerBank = (name) => {
        const arr = bankDetailData?.data?.filter(item => item?.institutionName === name?.institutionName);
        const sum = arr?.reduce((acc, cur) => {
            if (cur?.amount >= 0) {
                return acc + cur?.amount
            } else {
                return acc
            }
        }, 0)
        return sum?.toFixed(2)
    }


    // retuns credit amt(if amt is + returns amt or returns "-")
    const creditAmmount = useCallback((amt) => {
        if (amt > 0) {
            return "$" + amt
        } else {
            return "-"
        }
    }, [])

    // retuns Debit amt(if amt is - returns amt or returns "-")
    const debitAmmount = useCallback((amt) => {
        if (amt < 0) {
            return "$" + -amt
        } else {
            return "-"
        }
    }, [])

    // returns the sum of a key from array of object
    const sumFromArrayOfObject = (arr, key) => {
        const sum = arr.reduce((acc, cur) => acc + Number(cur[key]), 0);
        return sum ? sum : "";
    }

    // auto Sync
    const fetchAutoSync = async () => {
        const res = await HttpClient.requestData("plaid-auto-post", "GET");
        console.log("resSync", res)
        if (res && res?.status) {
            toast.success(language.auto_sync_success)
        } else {
            toast.error(res?.message || "Something Wrong")
        }
    }

    const handleClick = (item) => {
        setDetailModal(true);
        console.log("itemm", item?.institutionName, bankDetailData)
        const arr = bankDetailData?.data?.filter(ele => ele?.institutionName === item?.institutionName);
        setTransData(arr)
    }


    // ******************plaid get token start***********************************
    const [token, setToken] = useState(null);
    const [isIntegration, setIsintregation] = useState(false);

    const onSuccess = useCallback(async (publicToken) => {
        const sendData = {
            publicToken: publicToken
        }
        setIsLoading(true);
        const res = await HttpClient.requestData("update-bank-access-token", "POST", sendData)
        if (res && res?.status) {
            setIsLoading(false);
        } else {
            setIsLoading(false);
        }
    }, [token]);

    const onExit = useCallback(() => {
        // navigate('/manager');
        // closeModal()
    }, [])

    const config = {
        token,
        onSuccess,
        // onEvent
        onExit
    };

    const { open, ready } = usePlaidLink(config);

    const createLinkToken = React.useCallback(async () => {
        setIsLoading(true);
        const res = await HttpClient.requestData("create-link-token", "GET");
        if (res && res?.status) {
            setToken(res?.data?.link_token);
            setIsLoading(false);
        } else {
            setIsLoading(false);
        }
    }, [setToken]);

    useEffect(() => {
        if (isIntegration) {
            if (token == null) {
                // console.log("connectMy", ready)
                createLinkToken();
            }
            // if (ready) {
            open();
            // }
        }
    }, [token, ready, isIntegration]);

    // ******************plaid get token End***********************************

    // filter by account Name
    useEffect(() => {
        // filter
        if (accType === "All") {
            setFilteredData(bankNameList)
            setTotalData((prev) => ({ ...prev, accType: bankNameList?.length }));
            setTransData((prev) => ({ ...prev, accType: bankNameList?.length }))
        }
        else {
            const arr = bankNameList?.filter((item) => item?.institutionName === accType)
            setFilteredData(arr)
            setTransData(arr);

            const creditData = arr.filter((item, i) => item?.amount >= 0);
            setTotalData(prev => ({ ...prev, credit: sumFromArrayOfObject(creditData, "amount") }));

            const debitData = arr.filter((item, i) => item?.amount < 0)
            setTotalData(prev => ({ ...prev, debit: -sumFromArrayOfObject(debitData, "amount") }))

            setTotalData((prev) => ({ ...prev, accType: arr?.length }))
        }
    }, [accType, bankDetailData])


    useEffect(() => {
        getBankDetails()
    }, []);

    // useEffect(() => {
    //     getBankTramsaction();
    // }, []);

    useEffect(() => {
        if (searchDate.startDate && searchDate.endDate) {
            getBankTramsaction();
        } else if (!searchDate.startDate && !searchDate.endDate) {
            getBankTramsaction();
        }
    }, [time, searchDate, isInvestor, isBookKeeper, userCode]);


    // useEffect(() => {
    //     if (bankData && bankData?.[0]) {
    //         setSingleBankName(bankData?.[0]);
    //     }
    // }, [bankData]);


    return (
        <>
            <div
                onClick={() => {
                    setIsBankNameDrop(false);
                    setIsShowList(false);
                }}
            >

                {/* ***************** bank name dropdown******************** */}
                {
                    // <div>
                    //     <div id="wrap">
                    //         {/* <h1>Stylish User Settings Menu</h1> */}

                    //         <div
                    //             id="dropdown"
                    //             onClick={(e) => {
                    //                 e.stopPropagation();
                    //                 setIsBankNameDrop(prev => !prev)
                    //             }}
                    //         >
                    //             {/* {singleBankName ? singleBankName?.institutionData?.name : language.select_bank_name} */}
                    //             {language.select_bank_name}
                    //             {
                    //                 isBankNameDrop &&
                    //                 <ul>
                    //                     {bankData?.map((item, i) =>
                    //                         <li onClick={() => setSingleBankName(item)} key={i}>
                    //                             <a href="#">
                    //                                 {item?.institutionData?.name}
                    //                             </a>
                    //                         </li>
                    //                     )
                    //                     }

                    //                     {bankLoading && <CustomLoaderLine />}
                    //                 </ul>
                    //             }
                    //         </div>
                    //     </div>
                    // </div>
                }
                {/* ***************** bank name dropdown end******************** */}



                <div>
                    <div className="clearFillDiv">
                        {/* clear filter */}
                        {/* <button
                            className="clearFilterBtn"
                            onClick={() => {
                                setSearchDate({ startDate: "", endDate: "" });
                                setAccType("All");
                            }}
                        >
                            {language.clear_filters}
                        </button> */}

                        {/* three lines */}
                        {/* <button
                            onClick={(e) => {
                                e.stopPropagation();
                                setIsShowList(prev => !prev);
                            }}
                            className="barScreenShotBtn"
                        >
                            <i className="fa-solid fa-bars"></i>
                        </button> */}

                        {/* dropdown part */}
                        {/* {isShowList &&
                            <div className="plaid-manager-dropdown">
                                <p onClick={() => {
                                    // navigate('/integration');
                                    setIsintregation(true);
                                }}
                                >
                                    {language.connect_more_acc}
                                </p>
                                <p onClick={() => fetchAutoSync()}>{language.auto_sync_post_all}</p>
                                <p onClick={() => goToSelectedSlice(1)}>View Transactions Dashboard</p>
                                <p onClick={() => navigate('/editexistingrevenue')} > View Transactions Detail</p>
                            </div>
                        } */}
                    </div>

                    {/* button group */}
                    <div className="transacBtnGroup">
                        <button onClick={() => setIsintregation(true)}>{language.connect_more_acc}</button>
                        <button onClick={() => fetchAutoSync()}>{language.auto_sync_post_all}</button>
                        <button onClick={() => goToSelectedSlice(isInvestor || isBookKeeper ? 4 : 5)}>View Balance Dashboard</button>
                        <button onClick={() => navigate(`/editexistingrevenue${isInvestor || isBookKeeper ? '?id='+ userCode : ''}`)}>View Transactions Detail</button>
                    </div>

                    <div className="dateSearDiv" >
                        {/* Date search  */}
                        <input
                            type="date"
                            className="dashSixDateInp"
                            value={moment(searchDate.startDate).format("YYYY-MM-DD")}
                            onChange={(e) => setSearchDate(prev => ({ ...prev, startDate: moment(e.target.value).format() }))}
                        />
                        <input
                            type="date"
                            className="dashSixDateInp"
                            value={moment(searchDate.endDate).format("YYYY-MM-DD")}

                            onChange={(e) => setSearchDate(prev => ({ ...prev, endDate: moment(e.target.value).format() }))}
                        />

                        {/* clear filter */}
                        <button
                            className="clearFilterBtn"
                            onClick={() => {
                                setSearchDate({ startDate: "", endDate: "" });
                                // setAccType("All");
                            }}
                        >
                            {language.clear_filters}
                        </button>



                        {/* account filter */}
                        {/* <select
                            className="dashSixDateInp"
                            style={{ width: "183px", height: "36px" }}
                            value={accType}
                            onChange={(e) => setAccType(e.target.value)}
                        >
                            <option value={"All"}>{language.all}</option>
                            {
                                bankData?.map((item, i) =>
                                    <option key={i} value={item?.institutionData?.name}>{item?.institutionData?.name}</option>
                                )
                            }
                        </select> */}
                    </div>

                    {/* <div className="totalCalInp">
                        <p className="totalAvaText">{language.total_avail_cash}</p>
                        <input type="text" value={!bankDetailLoading ? "$" + bankDetailData?.totalAmount : "..."} className="totalDashSixInp" />
                    </div> */}


                    {/* tablle */}
                    {
                        !bankDetailLoading
                            ?
                            <div className="dashSixTableDiv mb-5">
                                <table className="dashSixTable">
                                    <thead className="mb-4">
                                        <th className="dashSixTableHead">{language.bank_name}</th>
                                        {/* <th className="dashSixTableHead">{language.current_avail_balance}</th> */}
                                        {/* <th className="dashSixTableHead">Marchent Name</th> */}
                                        {/* <th className="dashSixTableHead">{language.account_type}</th> */}
                                        <th className="dashSixTableHead">{language.totl_dabit}</th>
                                        <th className="dashSixTableHead">{language.total_credits}</th>
                                        <th className="dashSixTableHead">Details</th>
                                    </thead>

                                    <tbody>
                                        {
                                            filteredData && filteredData?.map((item, i) =>
                                                <tr className="dashSixTableRow" key={i}>
                                                    <td className="dashSixTableData">{item?.institutionName}</td>
                                                    {/* <td className="dashSixTableData">{item?.name}</td> */}
                                                    {/* <td className="dashSixTableData">{item?.accountType}</td> */}
                                                    {/* <td className="dashSixTableData">{debitAmmount(item?.amount)}</td> */}
                                                    {/* <td className="dashSixTableData">{creditAmmount(item?.amount)}</td> */}
                                                    <td className="dashSixTableData">{totalDebitPerBank(item)}</td>
                                                    <td className="dashSixTableData">{totalCreditPerBank(item)}</td>
                                                    <td className="dashSixTableData">
                                                        <button
                                                            className="btn"
                                                            style={{ backgroundColor: "#0E2537", color: "#fff" }}
                                                            onClick={(e) => {
                                                                e.stopPropagation()
                                                                handleClick(item)
                                                            }}
                                                        >
                                                            Details
                                                        </button>
                                                    </td>

                                                </tr>
                                            )

                                        }
                                    </tbody><br />

                                    {/* total */}
                                    <tfoot className="dashSixTableFoot">
                                        <tr className="">
                                            <td className="dashSixTableConcludeData">{language.total?.toUpperCase()}:</td>
                                            {/* <td className="dashSixTableConcludeData">-</td> */}
                                            <td className="dashSixTableConcludeData">
                                                ${accType === "All" ? bankDetailData?.total?.debits?.toFixed(2) : Number(totalData?.debit)?.toFixed(2)}
                                            </td>
                                            {/* <td className="dashSixTableConcludeData">-</td> */}
                                            <td className="dashSixTableConcludeData">
                                                ${accType === "All" ? bankDetailData?.total?.credits?.toFixed(2) : Number(totalData?.credit)?.toFixed(2)}
                                            </td>
                                            <td className="dashSixTableConcludeData">
                                                {accType === "All" ? totalData?.accType : totalData?.accType}
                                            </td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                            :
                            <>
                                <CustomLoaderLine />
                                <p className="d-flex justify-content-center">{language.we_refresh_info}</p>
                            </>
                    }

                </div>


            </div>

        </>

    );
}

export default DashboardChartSixB;
