import React, { useState } from 'react'
import Managebtn from '../../Component/Managebtn'
import InvoiceForm from './InvoiceForm'
import InvoiceForm2 from "./invoiceForm2/InvoiceForm2"
import BackArrow from '../../Component/BackArrow/BackArrow'
import { useNavigate } from 'react-router-dom'
import { useLanguage } from '../../Providers/LanguageContext'

function CreateInvoice() {
    const [time, setTime] = useState("day")
    const { language } = useLanguage();
    const navigate = useNavigate()
    const getTime = (val) => {
        setTime(val)
    }
    return (
        <>
            <div className='d-flex justify-content-start ' style={{marginLeft:"5rem"}}>
                <div>
                    <BackArrow />
                </div>
                <div className='mt-3'>
                    <button className='w-100 mb-2 btn btn-outline-success' style={{ fontWeight: "bold" }} onClick={() => navigate('/view-invoice')}>
                        {language.view_invoice}
                    </button>
                </div>
            </div>
            <Managebtn getTime={getTime} value={time} />

            {/* <InvoiceForm time={time} /> */}
            <InvoiceForm2 getTime={getTime} value={time} />

        </>
    )
}

export default CreateInvoice