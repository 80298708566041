import React from 'react'
import { useLanguage } from '../../../Providers/LanguageContext'
import ExpenseChart from './ExpenseChart'

function DashboardChartFourColumn({ data }) {
    const {language} = useLanguage()
    console.log(data, "DATACOLUMN")
    return (
        <div className='row justify-content-md-center mt-5'>
            <div className="col-sm-12 col-md-6 col-lg-4">
                <ExpenseChart data={data} text={language.expense} />
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4">
                <ExpenseChart data={data} text={language.sales} />
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4">
                <ExpenseChart data={data} text={language.profit} />
            </div>
        </div>
    )
}

export default DashboardChartFourColumn