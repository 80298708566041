import React from 'react'
import { Link } from 'react-router-dom'
import a from "../../images/APIs.jpg"
import b from "../../images/b.jpg"
import c from "../../images/c.jpg"
import d from "../../images/d.jpg"
import e from "../../images/e.jpg"
import f from "../../images/f.jpg"
import g from "../../images/g.jpg"
import h from "../../images/h.png"

import ambassador from "../../images/blog images/Ambassador Overview.png"
import client_que from "../../images/blog images/Client Questionnaire.png"
import digital_only from "../../images/blog images/Digital Only-Version Did You Know.png"
import go_green from "../../images/blog images/GO GREEN.png"
import mid_acc from "../../images/blog images/Mid-Size Accounting Firm.png"
import mid_construct from "../../images/blog images/Mid-Size Construction.png"
import mid_factory from "../../images/blog images/Mid-Size Factory.png"
import mid_factory_2 from "../../images/blog images/Mid-Size Factory2.png"
import mid_gas from "../../images/blog images/Mid-Size Multiple Gas Station.png"
import mid_gas_2 from "../../images/blog images/Mid-Size Multiple Gas Stations2.png"
import small_tech from "../../images/blog images/Small Tech Company.png"
import small_tech_2 from "../../images/blog images/Small Tech Company2.png"
import tut_guide from "../../images/blog images/Tutorial Guide.png"
import saas_overview from "../../images/blog images/1..png"
import Value_statemen from "../../images/blog images/17..png"
import user_value_faq from "../../images/blog images/User Value FAQ.jpg"


import { useLanguage } from '../../Providers/LanguageContext'

import apis_En from '../../images/blog_pdfs/APIs_En.pdf'
import apis_Es from '../../images/blog_pdfs/APIs_Es.pdf'
import BI_En from '../../images/blog_pdfs/Business Intelligence_En.pdf'
import BI_Es from '../../images/blog_pdfs/Business Intelligence_Es.pdf'
import CIPBITS_En from '../../images/blog_pdfs/CIPBITS-business-En.pdf'
import CIPBITS_Es from '../../images/blog_pdfs/CIPBITS-business-Es.pdf'
import CA_En from '../../images/blog_pdfs/Consolidated Analytics_En.pdf'
import CA_Es from '../../images/blog_pdfs/Consolidated Analytics_Es.pdf'
import DigitalMarket_En from '../../images/blog_pdfs/Digital Marketplace_En.pdf'
import DigitalMarket_Es from '../../images/blog_pdfs/Digital Marketplace_Es.pdf'
import ten_reson_En from '../../images/blog_pdfs/10 Reasons Why_En.pdf'
import ten_reson_Es from '../../images/blog_pdfs/10 Reasons Why_Es.pdf'
import visibility_En from '../../images/blog_pdfs/Visibility_En.pdf'
import visibility_Es from '../../images/blog_pdfs/Visibility_Es.pdf'
import Fisi_Comp_En from '../../images/blog_pdfs/Fisibility Company_En.pdf'
import Fisi_Comp_Es from '../../images/blog_pdfs/Fisibility Company_Es.pdf'
import amb_pog_En from '../../images/blog_pdfs/Ambassador Program_ En.pdf'
import amb_pog_Es from '../../images/blog_pdfs/Ambassador Program_ Es.pdf'
import client_que_En from '../../images/blog_pdfs/client_questionaries_En.pdf'
import client_que_Es from '../../images/blog_pdfs/client_questionaries_Es.pdf'
import digital_only_En from '../../images/blog_pdfs/Digital only_En.pdf'
import digital_only_Es from '../../images/blog_pdfs/Digital only_En.pdf'
import go_green_En from '../../images/blog_pdfs/GO GREEN_En.pdf'
import go_green_Es from '../../images/blog_pdfs/GO GREEN_Es.pdf'
import mid_acc_En from '../../images/blog_pdfs/Mid-Size Accounting Firm_En.pdf'
import mid_acc_Es from '../../images/blog_pdfs/Mid-Size Accounting Firm_Es.pdf'
import mid_const_En from '../../images/blog_pdfs/Mid-Size Construction_En.pdf'
import mid_const_Es from '../../images/blog_pdfs/Mid-Size Construction_Es.pdf'
import mid_size_factory_En from '../../images/blog_pdfs/Mid-Size Factory_En.pdf'
import mid_size_factory_Es from '../../images/blog_pdfs/Mid-Size Factory_Es.pdf'
import mid_gas_En from '../../images/blog_pdfs/Mid-Size Multiple Gas Stations_En.pdf'
import mid_gas_Es from '../../images/blog_pdfs/Mid-Size Multiple Gas Stations_Es.pdf'
import small_tech_En from '../../images/blog_pdfs/Small Tech Company_En.pdf'
import small_tech_Es from '../../images/blog_pdfs/Small Tech Company_Es.pdf'
import mid_fact_2_En from '../../images/blog_pdfs/Mid-Size  Factory2_En.pdf'
import mid_fact_2_Es from '../../images/blog_pdfs/Mid-Size  Factory2_Es.pdf'
import mid_gas_2_En from '../../images/blog_pdfs/Mid-Size  Multiple Gas Stations2_En.pdf'
import mid_gas_2_Es from '../../images/blog_pdfs/Mid-Size  Multiple Gas Stations2_Es.pdf'
import small_tech_2_En from '../../images/blog_pdfs/Small Tech Company2_En.pdf'
import small_tech_2_Es from '../../images/blog_pdfs/Small Tech Company2_ES.pdf'
import tut_En from '../../images/blog_pdfs/Tutorial Guide_En.pdf'
import tut_Es from '../../images/blog_pdfs/Tutorial Guide_En.pdf'
import saas_overview_En from '../../images/blog_pdfs/Fisibility SaaS Overview.pdf'
import Value_statement_En from '../../images/blog_pdfs/Fisibility User Value Statement Overview.pdf'
import user_value_faq_En from '../../images/blog_pdfs/FISIBILITY VALUE - USER FAQ - EN.pdf'
import user_value_faq_Es from '../../images/blog_pdfs/FISIBILITY VALUE - USER FAQ - ES.pdf'


export default function BlogBox() {

    const { language, currentLanguage } = useLanguage()

    const blogbox = [
        {
            "img": a,
            "heading": "APIs",
            "para": language.api_inteligence_connect,
            url: currentLanguage === 'eng' ? apis_En : apis_Es
        },
        {
            "img": b,
            "heading": language.business_inteligence,
            "para": "The Strategic Value of a Capital Intelligente Plataform - Bussiness Intelligence (BI)",
            url: currentLanguage === 'eng' ? BI_En : BI_Es,

        },
        {
            "img": c,
            "heading": "CIPBITS",
            "para": "The Strategic Value of a Capital Intelligente Plataform - API Integration & Connectivity",
            url: currentLanguage === 'eng' ? CIPBITS_En : CIPBITS_Es
        },
        {
            "img": d,
            "heading": language.consolidate_ana,
            "para": "The Strategic Value of a Capital Intelligente Plataform - API Integration & Connectivity",
            url: currentLanguage === 'eng' ? CA_En : CA_Es
        },
        {
            "img": e,
            "heading": language.digital_marketplace,
            "para": "The Strategic Value of a Capital Intelligente Plataform - API Integration & Connectivity",
            url: currentLanguage === 'eng' ? DigitalMarket_En : DigitalMarket_Es
        },
        {
            "img": g,
            "heading": language.Visibility,
            "para": "The Strategic Value of a Capital Intelligente Plataform - API Integration & Connectivity",
            url: currentLanguage === 'eng' ? visibility_En : visibility_Es
        }
    ]

    const extras = [
        {
            img: f,
            heading: "10 Reasons Why Document Digitization Matters",
            url: currentLanguage === 'eng' ? ten_reson_En : ten_reson_Es
        },
        {
            img: h,
            heading: language.fisi_comp_overview,
            url: currentLanguage === 'eng' ? Fisi_Comp_En : Fisi_Comp_Es
        },
        {
            img: ambassador,
            heading: language.ambassador_overview,
            url: currentLanguage === 'eng' ? amb_pog_En : amb_pog_Es
        },
        {
            img: client_que,
            heading: language.client_questionire,
            url: currentLanguage === 'eng' ? client_que_En : client_que_Es
        },
        {
            img: digital_only,
            heading: currentLanguage === 'eng' ? 'Digital OnlyVersion "Did You Know"' : 'Versión solo digital "Sabías"',
            url: currentLanguage === 'eng' ? digital_only_En : digital_only_En
        },
        {
            img: go_green,
            heading: language.go_green,
            url: currentLanguage === 'eng' ? go_green_En : go_green_Es
        },
        {
            img: mid_acc,
            heading: language.mid_size_acc_firm,
            url: currentLanguage === 'eng' ? mid_acc_En : mid_acc_Es
        },
        {
            img: mid_construct,
            heading: language.mid_size_const,
            url: currentLanguage === 'eng' ? mid_const_En : mid_const_Es
        },
        {
            img: mid_factory,
            heading: language.mid_size_factory,
            url: currentLanguage === 'eng' ? mid_size_factory_En : mid_size_factory_Es
        },
        {
            img: mid_gas,
            heading: language.mid_size_multiple_gas,
            url: currentLanguage === 'eng' ? mid_gas_En : mid_gas_Es
        },
        {
            img: small_tech,
            heading: language.small_tech_comp,
            url: currentLanguage === 'eng' ? small_tech_En : small_tech_Es
        },
        {
            img: mid_factory_2,
            heading: language.mid_size_factory,
            url: currentLanguage === 'eng' ? mid_fact_2_En : mid_fact_2_Es
        },
        {
            img: mid_gas_2,
            heading: language.mid_size_multiple_gas,
            url: currentLanguage === 'eng' ? mid_gas_2_En : mid_gas_2_Es
        },
        {
            img: small_tech_2,
            heading: language.small_tech_comp,
            url: currentLanguage === 'eng' ? small_tech_2_En : small_tech_2_Es
        },
        {
            img: tut_guide,
            heading: language.tutorial_guide,
            url: currentLanguage === 'eng' ? tut_En : tut_En
        },
        {
            img: saas_overview,
            heading: language.sas_overview,
            url: currentLanguage === 'eng' ? saas_overview_En : saas_overview_En
        },
        {
            img: Value_statemen,
            heading: language.user_value_statement,
            url: currentLanguage === 'eng' ? Value_statement_En : Value_statement_En
        },
        {
            img: user_value_faq,
            heading: "Fisibility User Value – FAQ",
            url: currentLanguage === 'eng' ? user_value_faq_En : user_value_faq_Es
        }
    ]
    return (
        <>
            <section id="newBogBoxWrap">
                <div className='container-fluid'>
                    <div className='row'>
                        {blogbox.map((item, index) => {
                            return (
                                <div className='col-lg-4 col-md-6 col-12' key={index}>
                                    <a href={item.url} target="_blank" rel='noreferrer'>
                                        <div className='BlogBox'>
                                            <div className='image'>
                                                <img src={item.img} className='img-fluid' />
                                            </div>
                                            <div className='cntBox'>
                                                <h3>{item.heading}</h3>
                                                <p>{language.strategic_value} - {item.heading}</p>
                                                <h6>{language.more_information}</h6>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            )
                        }
                        )}

                        {
                            extras.map((item, index) =>
                                <div className='col-lg-4 col-md-6 col-12' key={index}>
                                    <a href={item.url} target="_blank" rel='noreferrer'>
                                        <div className='BlogBox'>
                                            <div className='image'>
                                                <img src={item.img} className='img-fluid' />
                                            </div>
                                            <div className='cntBox'>
                                                <h3>{item.heading}</h3>
                                                {/* <p>{language.strategic_value} - {item.heading}</p> */}
                                                <h6>{language.more_information}</h6>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            )
                        }
                    </div>
                </div>
            </section>
        </>
    )
}
