import React, { useEffect, useState } from "react";
import { useLanguage } from "../../Providers/LanguageContext";

export default function SendReportModal({ setShowModal, setSendName, sendName, selectNameMemo, handleSend, setSendForm, sendForm }) {
  const { language, currentLanguage } = useLanguage();

  const [selName, setSelName] = useState(selectNameMemo);
  useEffect(() => {
    setSelName(selectNameMemo);
  }, [currentLanguage])

  return (
    <>
      <div className="ftrbtn">
        <div className="modal d-block">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header border-0">
                <h4 className="text-secondary dts">{language.data}</h4>
                <button type="button" className="close" data-dismiss="modal" onClick={() => {
                  setShowModal(false)
                  setSendName("")
                }}>
                  {" "}
                  ×
                </button>
              </div>
              <div className="modal-body">
                <form>
                  {/* {
                    sendName &&
                    <div className="form-group">
                      <div className="btn btn-block btnp ">
                        <h2 className="tda">{language.type_of_file} :  {sendName}</h2>
                      </div>
                    </div>
                  } */}
                  <div className="form-group">
                    <label htmlFor="usr">{language.send_to}:</label>
                    <input
                      type="email"
                      className="form-control"
                      id="usr"
                      placeholder={language.email}
                      value={sendForm.email}
                      onChange={(e) => setSendForm((prev) => { return { ...prev, email: e.target.value } })}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="pwd">{language.subject}</label>
                    <input
                      type="text"
                      className="form-control"
                      id="pwd"
                      value={selectNameMemo}
                      // value={selName}
                      readOnly
                    />
                  </div>
                  <div className="form-group">

                    <textarea
                      className="form-control"
                      rows={5}
                      id="comment"
                      // defaultValue={""}
                      placeholder={language.describe_message}
                      value={sendForm.message}
                      onChange={(e) => setSendForm((prev) => { return { ...prev, message: e.target.value } })}
                    />
                  </div>
                  <div className="footerBtn">
                    <a
                      style={{ color: "#fff", cursor: "pointer" }}
                      onClick={handleSend}
                    >
                      {language.send}
                    </a>
                    <a onClick={() => setShowModal(false)} style={{ cursor: 'pointer', background: "rgb(135 124 124 / 58%)" }}>{language.cancel}</a>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
