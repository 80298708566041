import React from 'react';
import { useLanguage } from '../../Providers/LanguageContext'
import SignUpButton from './SignUpButton';
import iMg1 from "../../images/ilustraciones soluciones industriales_EDUCACION 1.png";
import iMg2 from "../../images/ilustraciones soluciones industriales_EDUCACION 2.png";
import iMg3 from "../../images/ilustraciones soluciones industriales_EDUCACION 3.png";
import iMg3en from "../../images/ilustraciones soluciones industriales_EDUCACION 3 EN.png";

const Education = () => {
  const { language, currentLanguage } = useLanguage();

  return (
    <div>
      {/* <section id='agriculture_sec'>
        <div className='container'>
          <div className='agriculture_head'>
            <div>
              <h3>{language.fisibility_key_feture}:</h3>
              <h2>{language.education}</h2>
            </div>
          </div>
          <div className='agriculture_list'>
            <ul>
              <li><strong>{language.simplify_budgeting}: </strong>
                {language.fisi_edu_institution}
              </li>
              <li><strong>{language.streamline_exp_track}: </strong>
                {language.fisi_school_university}
              </li>
               <li><strong>{language.time_saving_automation}: </strong>
                {language.fisi_edu_automate}
              </li>
              <li><strong>{language.improve_finan_tranparancy}: </strong>
                {language.fisi_prov_tran_finanve}
              </li>
              <li><strong>{language.increased_finance_security}: </strong>
                {language.prov_secure_access_finance}
              </li>
            </ul>
          </div>

          <SignUpButton />
        </div>
      </section> */}

      <section id='agriculture_sec'>
        <div className='container'>
          <div className='row mb-5'>
            <div className='col-md-6 col-12 m-auto'>
              <div className='agriculture_head'>
                <h3 style={{ color: "#3fa9f5" }}>{language.education}</h3>
              </div>

              <div className='agriculture_headtext'>
                <h4 style={{ color: "#b21f6e" }}>{language.simplify_budgeting}</h4>
                <p>{language.fisi_edu_institution}</p>
                <p>{language.app_catogrise_expanse}</p>
              </div>
            </div>
            <div className='col-md-6 col-12 m-auto'>
              <div className='agriculture_img'>
                <img src={iMg1} className='img-fluid' alt='' />
              </div>
            </div>
          </div>

          <div className='row row_reverse mb-5'>
            <div className='col-md-6 col-12 m-auto'>
              <div className='agriculture_img'>
                <img src={iMg2} className='img-fluid' alt='' />
              </div>
            </div>
            <div className='col-md-6 col-12 m-auto'>
              <div className='agriculture_headtext'>
                <h4 style={{ color: "#218cb1", textAlign: "right" }}>{language.streamline_exp_track}</h4>
                <p>{language.fisi_school_university}</p>
              </div>
            </div>
          </div>

          <div className='row mb-5'>
            <div className='col-md-12 col-12'>
              <div className='agriculture_headtext'>
                <h4 style={{ color: "#b6cc2b", textAlign: "left" }}>{language.time_saving_automation}</h4>
                <p> {language.fisi_edu_automate}</p>
              </div>
            </div>
          </div>

          <div className='row mb-5'>
            <div className='col-md-12 col-12'>
              <div className='agriculture_headtext'>
                <h4 style={{ color: "#f7931e", textAlign: "left" }}>{language.improve_finan_tranparancy}</h4>
                <p>{language.fisi_prov_tran_finanve}</p>
              </div>
            </div>
          </div>

          <div className='row'>
            <div className='col-md-6 col-12 m-auto'>
              <div className='agriculture_headtext'>
                <h4 style={{ color: "#b21f6e", textAlign: "left" }}>{language.increased_finance_security}</h4>
                <p>{language.prov_secure_access_finance}</p>
              </div>
            </div>

            <div className='col-md-6 col-12 m-auto'>
              <div className='agriculture_img' style={{ maxWidth: "60%", }}>
                {
                  currentLanguage === 'spanish'
                    ?
                    <img src={iMg3} className='img-fluid' alt='' style={{ marginBottom: "30px" }} />
                    :
                    <img src={iMg3en} className='img-fluid' alt='' style={{ marginBottom: "30px" }} />

                }

                {/* <div className='registrarAnddescargar' style={{ transform: "translate(-50% , -50%)", top: "100%", }}>
                  <button className='registrar_Btn'>Registrarse</button>
                  <button className='descargar_Btn'>Descargar</button>
                </div> */}
                <SignUpButton />
              </div>
            </div>
          </div>
        </div>
      </section>

    </div>
  )
}

export default Education