import React from 'react'
import { Outlet } from 'react-router-dom'
import Cipbitheader from './Cipbitheader'
import Footer from "../Layout/Footer"
export default function SingleLayout() {
  return (
 <>
 <Cipbitheader />
 <Outlet/>
 <Footer/>
 </>
  )
}
