import React, { useEffect, useMemo, useState } from 'react'
import { useLanguage } from '../../Providers/LanguageContext';
import HttpClient from '../../utils/HttpClient';
import { TiArrowSortedDown } from 'react-icons/ti';
import { TiArrowSortedUp } from 'react-icons/ti';
import { UserContext, useUserData } from '../../Providers/UserWrapper';
import moment from 'moment';



const FinancialOverviewTable = ({ reportDate }) => {
    const { language, currentLanguage } = useLanguage();
    const { profileData, setFinanceCsv, setFinanceExcel } = useUserData();
    const { isBusiness } = useUserData();
    const [monthData, setMonthData] = useState({});
    const [yearData, setYearData] = useState({});
    const [trailData, setTrailData] = useState({});
    const [prevData, setPrevData] = useState({});
    const [dateData, setDateData] = useState({})

    console.log('reportDate', reportDate)

    const getMonthData = async () => {
        const res = await HttpClient.requestData("out-of-box-month", "GET", {});
        console.log('monthRes', res);
        if (res && res.status) {
            setMonthData(res?.data)
        }
    }

    const getYearData = async () => {
        const res = await HttpClient.requestData("out-of-box-year", "GET", {});
        // console.log('yearRes', res);
        if (res && res.status) {
            setYearData(res?.data)
        }
    }

    const getTrailData = async () => {
        const res = await HttpClient.requestData("trailing-twelve-months", "GET", {});
        // console.log('trailData', res);
        if (res && res.status) {
            setTrailData(res?.data)
        }
    }

    const getPreviousData = async () => {
        const res = await HttpClient.requestData("out-of-box-previousyear", "GET", {});
        console.log('prevData', res);
        if (res && res.status) {
            setPrevData(res?.data)
        }
    }

    const getDateData = async () => {
        const sendData = {
            startData: reportDate.startData,
            endDate: reportDate.endDate
        }
        console.log('sendDatarepdaa', sendData)
        const res = await HttpClient.requestData("get-date-data", "POST", sendData);
        console.log('dateData', res);
        if (res && res.status) {
            setDateData(res?.data)
        }
    }


    const finanHeader = [
        { label: "", key: "titles" },
        { label: "User Defined Date Range Total", key: "dateData" },
        { label: "Current Month Total", key: "monthData" },
        { label: "Current Year Total", key: "currData" },
        { label: "Trailing Twelve Months Total", key: "trailData" },
        { label: "Previous Year Total", key: "prevData" }
    ]

    const getCsvData = () => {
        const finanData = [
            { titles: isBusiness ? language.revenue : language.income, dateData: "", monthData: "", currData: "", trailData: '', prevData: '' },
            { titles: isBusiness ? language.revenue_actual : language.income_actual, dateData: dateData.revenueActual, monthData: monthData.revenueActual, currData: yearData.revenueActual, trailData: trailData.revenueActual, prevData: prevData.revenueActual },
            { titles: isBusiness ? language.revenue_budget2 : language.income_budget2, dateData: dateData.revenueBudget, monthData: monthData.revenueBudget, currData: yearData.revenueBudget, trailData: trailData.revenueBudget, prevData: prevData.revenueBudget },
            { titles: language.over_under_budget, dateData: dateData.salesBudgetAmount, monthData: monthData.salesBudgetAmount, currData: yearData.salesBudgetAmount, trailData: trailData.salesBudgetAmount, prevData: prevData.salesBudgetAmount },
            // { titles: "Revenue Health Indicator", monthData: "Tomi", currData: "", trailData: '', prevData: '' },
            { titles: "", dateData: "", monthData: "", currData: "", trailData: '', prevData: '' },
            { titles: language.expense, dateData: "", monthData: "", currData: "", trailData: '', prevData: '' },
            { titles: language.expense_actual, dateData: dateData.expenseActual, monthData: monthData.expenseActual, currData: yearData.expenseActual, trailData: trailData.expenseActual, prevData: prevData.expenseActual },
            { titles: language.expense_budget3, dateData: dateData.expenseBudget, monthData: monthData.expenseBudget, currData: yearData.expenseBudget, trailData: trailData.expenseBudget, prevData: prevData.expenseBudget },
            { titles: language.over_under_budget, dateData: dateData.expenseBudgetAmount, monthData: monthData.expenseBudgetAmount, currData: yearData.expenseBudgetAmount, trailData: trailData.expenseBudgetAmount, prevData: prevData.expenseBudgetAmount },
            // { titles: "Expense Health Indicator", monthData: "Tomi", currData: "", trailData: '', prevData: '' },
            { titles: "", dateData: "", monthData: "", currData: "", trailData: '', prevData: '' },
            { titles: isBusiness ? language.profitability : language.margin, dateData: "", monthData: "", currData: "", trailData: '', prevData: '' },
            { titles: isBusiness ? language.profit_actual : language.margin_actual, dateData: dateData.profitActual, monthData: monthData.profitActual, currData: yearData.profitActual, trailData: trailData.profitActual, prevData: prevData.profitActual },
            { titles: isBusiness ? language.profit_budget2 : language.margin_budget, dateData: dateData.profitBudget, monthData: monthData.profitBudget, currData: yearData.profitBudget, trailData: trailData.profitBudget, prevData: prevData.profitBudget },
            { titles: language.over_under_budget, dateData: dateData.profitBudegetAmount, monthData: monthData.profitBudegetAmount, currData: yearData.profitBudegetAmount, trailData: trailData.profitBudegetAmount, prevData: prevData.profitBudegetAmount },
            // { titles: "Profit Health Indicator", monthData: "Tomi", currData: "", trailData: '', prevData: '' },
        ]
        // console.log('finanData', finanData)
        setFinanceCsv({
            headers: finanHeader,
            data: finanData
        })
    }

    const getExcelData = () => {
        const myArray = [
            [language.report_generated_by, profileData?.name + " " + profileData?.surName],
            [language.report_generated_on, moment().format("YYYY-MM-DD")],
            [],
            [language.general_fian_overview],
            ["", language.user_date_range, language.current_month_total2, language.current_year_total2, language.trailing_twelve_month, language.prev_year_total],
            [isBusiness ? language.revenue : language.income],
            [isBusiness ? language.revenue_actual : language.income_actual, dateData.revenueActual, monthData.revenueActual, yearData.revenueActual, trailData.revenueActual, prevData.revenueActual],
            [isBusiness ? language.revenue_budget2 : language.income_budget2, dateData.revenueBudget, monthData.revenueBudget, yearData.revenueBudget, trailData.revenueBudget, prevData.revenueBudget],
            [language.over_under_budget, dateData.salesBudgetAmount, monthData.salesBudgetAmount, yearData.salesBudgetAmount, trailData.salesBudgetAmount, prevData.salesBudgetAmount],
            [],
            [language.expense],
            [language.expense_actual, dateData.expenseActual, monthData.expenseActual, yearData.expenseActual, trailData.expenseActual, prevData.expenseActual],
            [language.expense_budget3, dateData.expenseBudget, monthData.expenseBudget, yearData.expenseBudget, trailData.expenseBudget, prevData.expenseBudget],
            [language.over_under_budget, dateData.expenseBudgetAmount, monthData.expenseBudgetAmount, yearData.expenseBudgetAmount, trailData.expenseBudgetAmount, prevData.expenseBudgetAmount],
            [],
            [isBusiness ? language.profitability : language.margin],
            [isBusiness ? language.profit_actual : language.margin_actual, dateData.profitActual, monthData.profitActual, yearData.profitActual, trailData.profitActual, prevData.profitActual],
            [isBusiness ? language.profit_budget2 : language.margin_budget, dateData.profitBudget, monthData.profitBudget, yearData.profitBudget, trailData.profitBudget, prevData.profitBudget],
            [language.over_under_budget, dateData.profitBudegetAmount, monthData.profitBudegetAmount, yearData.profitBudegetAmount, trailData.profitBudegetAmount, prevData.profitBudegetAmount],
        ];


        setFinanceExcel(myArray);
    }

    useEffect(() => {
        getCsvData();
        getExcelData();

    }, [monthData, yearData, trailData, prevData, dateData, currentLanguage])

    useEffect(() => {
        getMonthData();
        getYearData();
        getTrailData();
        getPreviousData();
    }, [])

    useEffect(() => {
        // console.log('rdcheck', Object.values(reportDate).find((item) => item === ""))
        if (reportDate.startData && reportDate.endDate) {
            getDateData();
        }
    }, [reportDate]);


    return (
        <div>
            <div className='table-responsive'>
                <table className="table table-striped gen-fin-table mb-5">
                    <thead>
                        <tr>
                            <th scope="col"></th>
                            <th scope="col">{language.user_date_range}</th>
                            <th scope="col">{language.current_month_total2}</th>
                            <th scope="col">{language.current_year_total2}</th>
                            <th scope="col">{language.trailing_twelve_month}</th>
                            <th scope="col">{language.prev_year_total}</th>
                        </tr>
                    </thead>
                    <tbody className="">
                        <h4 className="mt-4">{isBusiness ? language.revenue : language.income}</h4>
                        <tr>
                            <td className='report-first-column'>
                                {isBusiness ? language.revenue_actual : language.income_actual}
                            </td>
                            <td>{dateData.revenueActual}</td>
                            <td>{monthData.revenueActual}</td>
                            <td>{yearData.revenueActual}</td>
                            <td>{trailData.revenueActual}</td>
                            <td>{prevData.revenueActual}</td>
                        </tr>
                        <tr>
                            <td className='report-first-column'>
                                {isBusiness ? language.revenue_budget2 : language.income_budget2}
                            </td>
                            <td>{dateData.revenueBudget}</td>
                            <td>{monthData.revenueBudget}</td>
                            <td>{yearData.revenueBudget}</td>
                            <td>{trailData.revenueBudget}</td>
                            <td>{prevData.revenueBudget}</td>
                        </tr>
                        <tr>
                            <td className='report-first-column'>
                                {language.over_under_budget}
                            </td>
                            <td>{dateData.salesBudgetAmount}</td>
                            <td>{monthData.salesBudgetAmount}</td>
                            <td>{yearData.salesBudgetAmount}</td>
                            <td>{trailData.salesBudgetAmount}</td>
                            <td>{prevData.salesBudgetAmount}</td>
                        </tr>
                        <tr>
                            <td className='report-first-column'>
                                {isBusiness ? language.rev_health_ind : language.income_health_indecator}
                            </td>
                            <td>
                                <RedGreenArrow indicator={dateData.revenueHealthIndicator} />
                            </td>
                            <td>
                                <RedGreenArrow indicator={monthData.revenueHealthIndicator} />
                            </td>
                            <td>
                                <RedGreenArrow indicator={yearData.revenueHealthIndicator} />
                            </td>
                            <td>
                                <RedGreenArrow indicator={trailData.revenueHealthIndicator} />
                            </td>
                            <td>
                                <RedGreenArrow indicator={prevData.revenueHealthIndicator} />
                            </td>
                        </tr>
                    </tbody>
                    <tbody>
                        <h4>{language.expense}</h4>
                        <tr>
                            <td className='report-first-column'>
                                {language.expense_actual}
                            </td>
                            <td>{dateData.expenseActual}</td>
                            <td>{monthData.expenseActual}</td>
                            <td>{yearData.expenseActual}</td>
                            <td>{trailData.expenseActual}</td>
                            <td>{prevData.expenseActual}</td>
                        </tr>
                        <tr>
                            <td className='report-first-column'>
                                {language.expense_budget3}
                            </td>
                            <td>{dateData.expenseBudget}</td>
                            <td>{monthData.expenseBudget}</td>
                            <td>{yearData.expenseBudget}</td>
                            <td>{trailData.expenseBudget}</td>
                            <td>{prevData.expenseBudget}</td>
                        </tr>
                        <tr>
                            <td className='report-first-column'>
                                {language.over_under_budget}
                            </td>
                            <td>{dateData.expenseBudgetAmount}</td>
                            <td>{monthData.expenseBudgetAmount}</td>
                            <td>{yearData.expenseBudgetAmount}</td>
                            <td>{trailData.expenseBudgetAmount}</td>
                            <td>{prevData.expenseBudgetAmount}</td>
                        </tr>
                        <tr>
                            <td className='report-first-column'>
                                {language.exp_health_ind}
                            </td>
                            <td>
                                <RedGreenArrow indicator={dateData.expenseHealthIndicator} />
                            </td>
                            <td>
                                <RedGreenArrow indicator={monthData.expenseHealthIndicator} />
                            </td>
                            <td>
                                <RedGreenArrow indicator={yearData.expenseHealthIndicator} />
                            </td>
                            <td>
                                <RedGreenArrow indicator={trailData.expenseHealthIndicator} />
                            </td>
                            <td>
                                <RedGreenArrow indicator={prevData.expenseHealthIndicator} />
                            </td>
                        </tr>
                    </tbody>
                    <tbody>
                        <h4>{isBusiness ? language.profitability : language.margin}</h4>
                        <tr>
                            <td className='report-first-column'>
                                {isBusiness ? language.profit_actual : language.margin_actual}
                            </td>
                            <td>{dateData.profitActual}</td>
                            <td>{monthData.profitActual}</td>
                            <td>{yearData.profitActual}</td>
                            <td>{trailData.profitActual}</td>
                            <td>{prevData.profitActual}</td>
                        </tr>
                        <tr>
                            <td className='report-first-column'>
                                {isBusiness ? language.profit_budget2 : language.margin_budget}
                            </td>
                            <td>{dateData.profitBudget}</td>
                            <td>{monthData.profitBudget}</td>
                            <td>{yearData.profitBudget}</td>
                            <td>{trailData.profitBudget}</td>
                            <td>{prevData.profitBudget}</td>
                        </tr>
                        <tr>
                            <td className='report-first-column'>
                                {language.over_under_budget}
                            </td>
                            <td>{dateData.profitBudegetAmount}</td>
                            <td>{monthData.profitBudegetAmount}</td>
                            <td>{yearData.profitBudegetAmount}</td>
                            <td>{trailData.profitBudegetAmount}</td>
                            <td>{prevData.profitBudegetAmount}</td>
                        </tr>
                        <tr>
                            <td className='report-first-column'>
                                {isBusiness ? language.profit_health_ind : language.margin_indecator}
                            </td>
                            <td>
                                <RedGreenArrow indicator={dateData.profitHealthIndicator} />
                            </td>
                            <td>
                                <RedGreenArrow indicator={monthData.profitHealthIndicator} />
                            </td>
                            <td>
                                <RedGreenArrow indicator={yearData.profitHealthIndicator} />
                            </td>
                            <td>
                                <RedGreenArrow indicator={trailData.profitHealthIndicator} />
                            </td>
                            <td>
                                <RedGreenArrow indicator={prevData.profitHealthIndicator} />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default FinancialOverviewTable;


const RedGreenArrow = ({ indicator }) =>
    <>
        {
            indicator === "red" &&
            <div div style={{ fontSize: "18px", color: "red" }}>
                <TiArrowSortedDown />
            </div>
        }
        {
            indicator === "green" &&
            <div div style={{ fontSize: "18px", color: "green" }}>
                <TiArrowSortedUp />
            </div>
        }
    </>
