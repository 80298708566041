import React from 'react'
import iMg1 from "../../images/ilustraciones soluciones industriales_ORGANIZACION SIN FINES LUCRATIVOS 2.png";
import iMg1en from "../../images/ilustraciones soluciones industriales_ORGANIZACION SIN FINES LUCRATIVOS 2 EN.png";

import iMg2 from "../../images/ilustraciones soluciones industriales_ORGANIZACION SIN FINES LUCRATIVOS.png";
import iMg3 from "../../images/ilustraciones soluciones industriales_MINORISTAS 3 .png";
import { useLanguage } from '../../Providers/LanguageContext';
import { useNavigate } from 'react-router-dom';
import { useUserData } from '../../Providers/UserWrapper';
const NoProfitOrg = () => {
  const { language, currentLanguage } = useLanguage();
  const navigate = useNavigate();
  const { logout, isLogin } = useUserData();

  return (
    <>
      <section id='agriculture_sec'>
        <div className='container'>
          <div className='agriculture_head' style={{ marginBottom: "20px" }}>
            <h3 style={{ color: "#0e2537" }}>{language.non_profit_organization}</h3>
          </div>
          <div className='row mb-5'>
            <div className='col-md-6 col-12 m-auto'>
              <div className='agriculture_headtext'>
                <h4 style={{ color: "#3c3c3b" }}>{language.compre_finance_manage}</h4>
                <p>{language.fisi_non_manage}
                </p>
              </div>
            </div>
            <div className='col-md-6 col-12 m-auto'>
              <div className='agriculture_img'>
                <img src={iMg2} className='img-fluid' alt='' />
              </div>
            </div>
          </div>

          <div className='row row_reverse mb-5'>
            <div className='col-md-6 col-12 m-auto'>
              <div className='agriculture_img'>
                {
                  currentLanguage === 'spanish'
                    ?
                    <img src={iMg1} className='img-fluid' alt='' />
                    :
                    <img src={iMg1en} className='img-fluid' alt='' />
                }
                <div className='registrarAnddescargar' style={{ transform: "translate(-50% , -50%)", top: "43%", justifyContent: "space-between" }}>

                  <button
                    onClick={() => navigate('/price')}
                    className='registrar_Btn'
                    style={{ background: "background: linear-gradient(to right, #18465d 1%,#0f283b 100%,#2989d8 100%);", border: "0" }}
                  >
                    {language.homeBannerBtn1}
                  </button>

                  <a href='https://fisibilitylite.com/app_api/share_url.html' target="_blank">
                    <button className='descargar_Btn' style={{ borderColor: "#3c3c3b", color: "#3c3c3b" }}>{language.homeBannerBtn2}</button>
                  </a>
                </div>
              </div>
            </div>
            <div className='col-md-6 col-12 m-auto'>
              <div className='agriculture_headtext'>
                <h4 style={{ color: "#3c3c3b", textAlign: "right" }}>{language.donation_tracking_analysis}</h4>
                <p style={{ textAlign: "justify" }}>{language.fisi_allow_non_profit}
                </p>

              </div>

            </div>

          </div>



          <div className='row wrapBg_Img mt-5'>

            <div className='col-md-12 col-12 m-auto pt-5'>
              <div className='agriculture_headtext'>
                <h4 style={{ color: "#3c3c3b", textAlign: "left" }}>{language.collab_account}</h4>
                <p>{language.non_profit_often_multiple}
                </p>
              </div>
            </div>


          </div>
        </div>
      </section>

    </>
  )
}

export default NoProfitOrg