import React from "react";

function BookCustModal() {
  function bookkCustModalClose() {
    document
      .querySelector(".bookCustModalSection")
      .classList.add("bookCustDisplayModalDiv");
  }

  function changeModalValueNext() {
    document.querySelector(".bookkCustModHead").innerHTML =
      "ABC Enterprises: Tax Detail Management";
    document.querySelector(".bookCustModBtn").style.cssText += "display: none;";
    document
      .querySelector(".bookkCustModAddDiv")
      .classList.remove("bookkCustModAddDisplayDiv");
    document.querySelector(".bookkModalNextBtn").style.cssText +=
      "display: none;";
    document.querySelector(".bookkModalPrevBtn").style.cssText +=
      "display: inline-block;";
    document.querySelector(".bookkCustModTableDiv").style.cssText +=
      "display: none;";
    document.querySelector(".taxDetailBookkTableDiv").style.cssText +=
      "display: block;";
    document.querySelector(".texDetMainDiv").style.cssText += "display: block;";
  }

  function changeModalValuePrev() {
    document.querySelector(".bookkCustModHead").innerHTML =
      "ABC Enterprises: Setup & Manage Documents";
    document.querySelector(".bookCustModBtn").style.cssText +=
      "display: inline-block;";
    document
      .querySelector(".bookkCustModAddDiv")
      .classList.add("bookkCustModAddDisplayDiv");
    document.querySelector(".bookkModalNextBtn").style.cssText +=
      "display: inline-block;";
    document.querySelector(".bookkModalPrevBtn").style.cssText +=
      "display: none;";
    document.querySelector(".bookkCustModTableDiv").style.cssText +=
      "display: block;";
    document.querySelector(".taxDetailBookkTableDiv").style.cssText +=
      "display: none;";
    document.querySelector(".texDetMainDiv").style.cssText += "display: none;";
  }
  return (
    <>
      <section className="bookCustModalSection bookCustDisplayModalDiv">
        <div className="bookCustModalDiv">
          <button className="bookkCustModClsBtn" onClick={bookkCustModalClose}>
            <i class="fa-solid fa-xmark"></i>
          </button>
          <p className="bookkCustModHead">
            ABC Enterprises: Setup & Manage Documents
          </p>
          <div>
            <button className="bookCustModBtn">+ADD</button>
            <div className="bookkCustModAddDiv bookkCustModAddDisplayDiv">
              <input
                type="search"
                placeholder="Vendor-Search"
                className="bookkVenInp"
              />
              <input type="date" className="bookkVenDtInp" />
            </div>
          </div>
          <div className="bookkCustModTableGrandDiv">
            <div className="bookkCustModTableDiv">
              <table className="bookkCustModTable">
                <thead>
                  <tr>
                    <th className="bookkCustModTableHead">Document Type</th>
                    <th className="bookkCustModTableHead">Due Date</th>
                    <th className="bookkCustModTableHead">Status</th>
                    <th className="bookkCustModTableHead">Reminder</th>
                    <th className="bookkCustModTableHead">Upload</th>
                    <th className="bookkCustModTableHead">View File</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="bookkCustModTableData">
                      <input type="text" className="bookkDocTypeInp" />
                    </td>
                    <td className="bookkCustModTableData">
                      <input type="date" className="bookkDocDateInp" />
                    </td>
                    <td className="bookkCustModTableData">
                      <span className="bookkDocStatusSpan">Attached</span>
                    </td>
                    <td className="bookkCustModTableData">
                      <button className="bookkDocSendBtn">Send</button>
                    </td>
                    <td className="bookkCustModTableData">
                      <button className="bookkDocSendBtn">Browse</button>
                    </td>
                    <td className="bookkCustModTableData">
                      <button className="bookkDocViewBtn">AR.xlsx</button>
                    </td>
                  </tr>
                  <tr>
                    <td className="bookkCustModTableData">
                      <input type="text" className="bookkDocTypeInp" />
                    </td>
                    <td className="bookkCustModTableData">
                      <input type="date" className="bookkDocDateInp" />
                    </td>
                    <td className="bookkCustModTableData">
                      <span className="bookkDocStatusSpan">Attached</span>
                    </td>
                    <td className="bookkCustModTableData">
                      <button className="bookkDocSendBtn">Send</button>
                    </td>
                    <td className="bookkCustModTableData">
                      <button className="bookkDocSendBtn">Browse</button>
                    </td>
                    <td className="bookkCustModTableData">
                      <button className="bookkDocViewBtn">AR.xlsx</button>
                    </td>
                  </tr>
                  <tr>
                    <td className="bookkCustModTableData">
                      <input type="text" className="bookkDocTypeInp" />
                    </td>
                    <td className="bookkCustModTableData">
                      <input type="date" className="bookkDocDateInp" />
                    </td>
                    <td className="bookkCustModTableData">
                      <span className="bookkDocStatusSpan">Attached</span>
                    </td>
                    <td className="bookkCustModTableData">
                      <button className="bookkDocSendBtn">Send</button>
                    </td>
                    <td className="bookkCustModTableData">
                      <button className="bookkDocSendBtn">Browse</button>
                    </td>
                    <td className="bookkCustModTableData">
                      <button className="bookkDocViewBtn">AR.xlsx</button>
                    </td>
                  </tr>
                  <tr>
                    <td className="bookkCustModTableData">
                      <input type="text" className="bookkDocTypeInp" />
                    </td>
                    <td className="bookkCustModTableData">
                      <input type="date" className="bookkDocDateInp" />
                    </td>
                    <td className="bookkCustModTableData">
                      <span className="bookkDocStatusSpan">Attached</span>
                    </td>
                    <td className="bookkCustModTableData">
                      <button className="bookkDocSendBtn">Send</button>
                    </td>
                    <td className="bookkCustModTableData">
                      <button className="bookkDocSendBtn">Browse</button>
                    </td>
                    <td className="bookkCustModTableData">
                      <button className="bookkDocViewBtn">AR.xlsx</button>
                    </td>
                  </tr>
                  <tr>
                    <td className="bookkCustModTableData">
                      <input type="text" className="bookkDocTypeInp" />
                    </td>
                    <td className="bookkCustModTableData">
                      <input type="date" className="bookkDocDateInp" />
                    </td>
                    <td className="bookkCustModTableData">
                      <span className="bookkDocStatusSpan">Attached</span>
                    </td>
                    <td className="bookkCustModTableData">
                      <button className="bookkDocSendBtn">Send</button>
                    </td>
                    <td className="bookkCustModTableData">
                      <button className="bookkDocSendBtn">Browse</button>
                    </td>
                    <td className="bookkCustModTableData">
                      <button className="bookkDocViewBtn">AR.xlsx</button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <button
              className="bookkModalNextBtn"
              onClick={changeModalValueNext}
            >
              <i class="fa-solid fa-angle-right"></i>
            </button>
            {/* Tax Detail Management Part */}
            <div className="taxDetailBookkTableDiv">
              <table className="taxDetailBookkTable">
                <thead>
                  <tr>
                    <th className="taxDetBkTabHead">Vendor Name</th>
                    <th className="taxDetBkTabHead">Date</th>
                    <th className="taxDetBkTabHead">Item Description</th>
                    <th className="taxDetBkTabHead">Sub-Total</th>
                    <th className="taxDetBkTabHead">Tax Type</th>
                    <th className="taxDetBkTabHead">Tax %</th>
                    <th className="taxDetBkTabHead">Tax Amount</th>
                    <th className="taxDetBkTabHead">Total</th>
                    <th className="taxDetBkTabHead">Tax Deductible</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="taxDetBkTabData">
                      <span className="texDeFirstSpan">Vendor A</span>
                    </td>
                    <td className="taxDetBkTabData">
                      <input type="date" className="taxDebkDtInp" />
                    </td>
                    <td className="taxDetBkTabData">
                      <input type="text" className="taxDebkDtInp" />
                    </td>
                    <td className="taxDetBkTabData">
                      <input type="number" className="taxDebkDtInp" />
                    </td>
                    <td className="taxDetBkTabData">
                      <input type="text" className="taxDebkDtInp" />
                    </td>
                    <td className="taxDetBkTabData">
                      <input type="number" className="taxDebkDtInp" />
                    </td>
                    <td className="taxDetBkTabData">
                      <input type="number" className="taxDebkDtInp" />
                    </td>
                    <td className="taxDetBkTabData">
                      <input type="number" className="taxDebkDtInp" />
                    </td>
                    <td className="taxDetBkTabData">
                      <div className="texChkBtnDebDiv">
                        <input type="checkbox" />
                        <button className="texDebkBtn">.png</button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="texDetMainDiv">
              <div className="taxDetTotDucDiv">
                <label className="taxdeTotLabel">Total Deductibles:</label>
                <input type="text" value="$464.00" className="taxdeTotInp" />
              </div>
              <div className="texDetBtnsDiv">
                <button className="taxDetEditBtn">Edit</button>
                <button className="taxDetUpdateBtn">Update</button>
              </div>
            </div>
            <button
              className="bookkModalPrevBtn"
              onClick={changeModalValuePrev}
            >
              <i class="fa-solid fa-angle-left"></i>
            </button>
          </div>
        </div>
      </section>
    </>
  );
}

export default BookCustModal;
