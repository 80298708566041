import React from 'react';
import { useLanguage } from '../../Providers/LanguageContext'
import SignUpButton from './SignUpButton';
import iMg1 from "../../images/ilustraciones soluciones industriales_construcción 1.png";
import iMg2 from "../../images/ilustraciones soluciones industriales_construcción 2.png";
import iMg3 from "../../images/ilustraciones soluciones industriales_construcción 3.png";
import iMg3en from "../../images/ilustraciones soluciones industriales_construcción 3 EN.png";


const Construction = () => {
  const { language, currentLanguage } = useLanguage();

  return (
    <div>
      {/* <section id='agriculture_sec'>
        <div className='container'>
          <div className='agriculture_head'>
            <div>
              <h3>{language.fisibility_key_feture}:</h3>
              <h2>{language.construction}</h2>
            </div>
          </div>
          <div className='agriculture_list'>
            <ul>
              <li><strong>{language.budget_track}: </strong>
                {language.fisi_const_comp}
              </li>
               <li><strong>{language.invoicing_pay_track}: </strong>
                {language.allow_const_comp}
              </li>
             <li><strong>{language.time_and_albour_man}: </strong>
                {language.fisi_const_comp_track}
              </li>
               <li><strong>{language.project_manage}: </strong>
                {language.fisi_const_comp_manage}
              </li>
              <li><strong>{language.collaboration}: </strong>
                {language.enable_const_team}
              </li>
            </ul>
          </div>

          <SignUpButton />
        </div>
      </section> */}

      <section id='agriculture_sec'>
        <div className='container'>
          <div className='row mb-5'>
            <div className='col-md-6 col-12 m-auto'>
              <div className='agriculture_head'>
                <h3 style={{ color: "#000000" }}>{language.construction}</h3>
              </div>

              <div className='agriculture_headtext'>
                <h4 style={{ color: "#eabf07" }}>{language.budget_track}</h4>
                <p>{language.fisi_const_comp}</p>
              </div>
            </div>
            <div className='col-md-6 col-12 m-auto'>
              <div className='agriculture_img'>
                <img src={iMg1} className='img-fluid' alt='' />
              </div>
            </div>
          </div>

          <div className='row mb-5 row_reverse'>
            <div className='col-md-6 col-12 m-auto'>
              <div className='agriculture_img'>
                <img src={iMg2} className='img-fluid' alt='' />
              </div>
            </div>

            <div className='col-md-6 col-12 m-auto'>
              <div className='agriculture_headtext'>
                <h4 style={{ color: "#000", textAlign: "right" }}>{language.invoicing_pay_track}</h4>
                <p>{language.allow_const_comp}</p>
              </div>

              <div className='agriculture_headtext'>
                <h4 style={{ color: "#eabf07", textAlign: "right" }}>{language.time_and_albour_man}</h4>
              </div>
            </div>
          </div>

          <div className='row mb-5'>
            <div className='col-md-12 col-12'>
              <div className='agriculture_headtext'>
                <p>{language.fisi_const_comp_track}</p>
              </div>
            </div>
          </div>

          <div className='row mb-5'>
            <div className='col-md-12 col-12'>
              <div className='agriculture_headtext'>
                <h4 style={{ color: "#000", textAlign: "left" }}>{language.project_manage}</h4>
                <p>{language.fisi_const_comp_manage}</p>
              </div>
            </div>
          </div>


          <div className='row mb-5'>
            <div className='col-md-6 col-12 m-auto'>
              <div className='agriculture_headtext'>
                <h4 style={{ color: "#eabf07", textAlign: "left" }}>{language.collaboration}</h4>
                <p> {language.enable_const_team}</p>
              </div>
            </div>

            <div className='col-md-6 col-12 m-auto'>
              <div className='agriculture_img'>
                {
                  currentLanguage === 'spanish'
                    ?
                    <img src={iMg3} className='img-fluid' alt='' style={{ width: "100%", maxWidth: "40%", height: "auto", marginBottom: "40px" }} />
                    :
                    <img src={iMg3en} className='img-fluid' alt='' style={{ width: "100%", maxWidth: "40%", height: "auto", marginBottom: "40px" }} />

                }
                {/* 
                <div className='registrarAnddescargar' style={{ transform: "translate(-50% , -50%)", top: "100%" }}>
                  <button className='registrar_Btn'>Registrarse</button>
                  <button className='descargar_Btn'>Descargar</button>
                </div> */}
                <SignUpButton />
              </div>
            </div>
          </div>



        </div>
      </section>

    </div>
  )
}

export default Construction