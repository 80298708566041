import React, { useEffect, useState } from 'react'
import { useLanguage } from '../../../Providers/LanguageContext'
import { useUserData } from '../../../Providers/UserWrapper'
import CustomLoaderLine from '../../../Component/Loader/CustomLoaderLine'
import toast from 'react-hot-toast'
import HttpClient from '../../../utils/HttpClient'

const Build12MonthCashFlowStatement = ({ setCapitalCsv, setPdfCreate, setPdfLoader, setEmailSendFunc }) => {
    //    >>>>For Cash Receipts<<<< 
    const initReceipts = {
        title: "",
        week1price: "",
        week2price: "",
        week3price: "",
        week4price: "",
        week5price: "",
        week6price: "",
        week7price: "",
        week8price: "",
        week9price: "",
        week10price: "",
        week11price: "",
        week12price: ""
    }
    // >>>>For Cash Paid Out<<<<
    const initCashPaidOut = {
        title: "",
        week1price: "",
        week2price: "",
        week3price: "",
        week4price: "",
        week5price: "",
        week6price: "",
        week7price: "",
        week8price: "",
        week9price: "",
        week10price: "",
        week11price: "",
        week12price: ""
    }

    //    >>>>For OPERATING DATA <<<< 
    const initOperatingData = {
        title: "",
        week1price: "",
        week2price: "",
        week3price: "",
        week4price: "",
        week5price: "",
        week6price: "",
        week7price: "",
        week8price: "",
        week9price: "",
        week10price: "",
        week11price: "",
        week12price: ""
    }

    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>><<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

    const initTotals = {
        week1price: 0,
        week2price: 0,
        week3price: 0,
        week4price: 0,
        week5price: 0,
        week6price: 0,
        week7price: 0,
        week8price: 0,
        week9price: 0,
        week10price: 0,
        week11price: 0,
        week12price: 0
    };

    // >>>>Cash On Hand Data<<<<
    const initCashOnHand = [
        {
            Beginning: "",
            week1price: "",
            week2price: "",
            week3price: "",
            week4price: "",
            week5price: "",
            week6price: "",
            week7price: "",
            week8price: "",
            week9price: "",
            week10price: "",
            week11price: "",
            week12price: ""
        }
    ]

    //   >>>>Cash Receipts Default Value<<<< 
    const defaultCashReceipts = [
        {
            title: "Cash Sales",
            week1price: "",
            week2price: "",
            week3price: "",
            week4price: "",
            week5price: "",
            week6price: "",
            week7price: "",
            week8price: "",
            week9price: "",
            week10price: "",
            week11price: "",
            week12price: ""
        },
        {
            title: "Customer Account Collections",
            week1price: "",
            week2price: "",
            week3price: "",
            week4price: "",
            week5price: "",
            week6price: "",
            week7price: "",
            week8price: "",
            week9price: "",
            week10price: "",
            week11price: "",
            week12price: ""
        },
        {
            title: "Loan / Other Cash Injection",
            week1price: "",
            week2price: "",
            week3price: "",
            week4price: "",
            week5price: "",
            week6price: "",
            week7price: "",
            week8price: "",
            week9price: "",
            week10price: "",
            week11price: "",
            week12price: ""
        },
        {
            title: "Interest Income",
            week1price: "",
            week2price: "",
            week3price: "",
            week4price: "",
            week5price: "",
            week6price: "",
            week7price: "",
            week8price: "",
            week9price: "",
            week10price: "",
            week11price: "",
            week12price: ""
        },
        {
            title: "Tax Refund",
            week1price: "",
            week2price: "",
            week3price: "",
            week4price: "",
            week5price: "",
            week6price: "",
            week7price: "",
            week8price: "",
            week9price: "",
            week10price: "",
            week11price: "",
            week12price: ""
        },
        {
            title: "Other Cash Receipts",
            week1price: "",
            week2price: "",
            week3price: "",
            week4price: "",
            week5price: "",
            week6price: "",
            week7price: "",
            week8price: "",
            week9price: "",
            week10price: "",
            week11price: "",
            week12price: ""
        },
        {
            title: "Other",
            week1price: "",
            week2price: "",
            week3price: "",
            week4price: "",
            week5price: "",
            week6price: "",
            week7price: "",
            week8price: "",
            week9price: "",
            week10price: "",
            week11price: "",
            week12price: ""
        }
    ];

    //   >>>>Cash Paid Out Default Value<<<< 
    const defaultCashPaidOut = [
        {
            title: "Purchases (merchandise)",
            week1price: "",
            week2price: "",
            week3price: "",
            week4price: "",
            week5price: "",
            week6price: "",
            week7price: "",
            week8price: "",
            week9price: "",
            week10price: "",
            week11price: "",
            week12price: ""
        },
        {
            title: "Purchases (specify)",
            week1price: "",
            week2price: "",
            week3price: "",
            week4price: "",
            week5price: "",
            week6price: "",
            week7price: "",
            week8price: "",
            week9price: "",
            week10price: "",
            week11price: "",
            week12price: ""
        },
        {
            title: "Gross Wages (exact withdrawal)",
            week1price: "",
            week2price: "",
            week3price: "",
            week4price: "",
            week5price: "",
            week6price: "",
            week7price: "",
            week8price: "",
            week9price: "",
            week10price: "",
            week11price: "",
            week12price: ""
        },
        {
            title: "Payroll Expenses (taxes, etc)",
            week1price: "",
            week2price: "",
            week3price: "",
            week4price: "",
            week5price: "",
            week6price: "",
            week7price: "",
            week8price: "",
            week9price: "",
            week10price: "",
            week11price: "",
            week12price: ""
        },
        {
            title: "Outside Services",
            week1price: "",
            week2price: "",
            week3price: "",
            week4price: "",
            week5price: "",
            week6price: "",
            week7price: "",
            week8price: "",
            week9price: "",
            week10price: "",
            week11price: "",
            week12price: ""
        },
        {
            title: "Supplies (office & operating)",
            week1price: "",
            week2price: "",
            week3price: "",
            week4price: "",
            week5price: "",
            week6price: "",
            week7price: "",
            week8price: "",
            week9price: "",
            week10price: "",
            week11price: "",
            week12price: ""
        },
        {
            title: "Repairs & Maintenance",
            week1price: "",
            week2price: "",
            week3price: "",
            week4price: "",
            week5price: "",
            week6price: "",
            week7price: "",
            week8price: "",
            week9price: "",
            week10price: "",
            week11price: "",
            week12price: ""
        },
        {
            title: "Advertising",
            week1price: "",
            week2price: "",
            week3price: "",
            week4price: "",
            week5price: "",
            week6price: "",
            week7price: "",
            week8price: "",
            week9price: "",
            week10price: "",
            week11price: "",
            week12price: ""
        },
        {
            title: "Car, Delivery & Travel",
            week1price: "",
            week2price: "",
            week3price: "",
            week4price: "",
            week5price: "",
            week6price: "",
            week7price: "",
            week8price: "",
            week9price: "",
            week10price: "",
            week11price: "",
            week12price: ""
        },
        {
            title: "Accounting & Lega",
            week1price: "",
            week2price: "",
            week3price: "",
            week4price: "",
            week5price: "",
            week6price: "",
            week7price: "",
            week8price: "",
            week9price: "",
            week10price: "",
            week11price: "",
            week12price: ""
        },
        {
            title: "Rent",
            week1price: "",
            week2price: "",
            week3price: "",
            week4price: "",
            week5price: "",
            week6price: "",
            week7price: "",
            week8price: "",
            week9price: "",
            week10price: "",
            week11price: "",
            week12price: ""
        },
        {
            title: "Telephone",
            week1price: "",
            week2price: "",
            week3price: "",
            week4price: "",
            week5price: "",
            week6price: "",
            week7price: "",
            week8price: "",
            week9price: "",
            week10price: "",
            week11price: "",
            week12price: ""
        },
        {
            title: "Utilities",
            week1price: "",
            week2price: "",
            week3price: "",
            week4price: "",
            week5price: "",
            week6price: "",
            week7price: "",
            week8price: "",
            week9price: "",
            week10price: "",
            week11price: "",
            week12price: ""
        },
        {
            title: "Insurance",
            week1price: "",
            week2price: "",
            week3price: "",
            week4price: "",
            week5price: "",
            week6price: "",
            week7price: "",
            week8price: "",
            week9price: "",
            week10price: "",
            week11price: "",
            week12price: ""
        },
        {
            title: "Taxes (real estate, etc.)",
            week1price: "",
            week2price: "",
            week3price: "",
            week4price: "",
            week5price: "",
            week6price: "",
            week7price: "",
            week8price: "",
            week9price: "",
            week10price: "",
            week11price: "",
            week12price: ""
        },
        {
            title: "Interest",
            week1price: "",
            week2price: "",
            week3price: "",
            week4price: "",
            week5price: "",
            week6price: "",
            week7price: "",
            week8price: "",
            week9price: "",
            week10price: "",
            week11price: "",
            week12price: ""
        },
        {
            title: "Other Expenses (specify)",
            week1price: "",
            week2price: "",
            week3price: "",
            week4price: "",
            week5price: "",
            week6price: "",
            week7price: "",
            week8price: "",
            week9price: "",
            week10price: "",
            week11price: "",
            week12price: ""
        },
        {
            title: "Other (specify)",
            week1price: "",
            week2price: "",
            week3price: "",
            week4price: "",
            week5price: "",
            week6price: "",
            week7price: "",
            week8price: "",
            week9price: "",
            week10price: "",
            week11price: "",
            week12price: ""
        },
        {
            title: "Miscellaneous",
            week1price: "",
            week2price: "",
            week3price: "",
            week4price: "",
            week5price: "",
            week6price: "",
            week7price: "",
            week8price: "",
            week9price: "",
            week10price: "",
            week11price: "",
            week12price: ""
        },
        {
            title: "Loan Principal",
            week1price: "",
            week2price: "",
            week3price: "",
            week4price: "",
            week5price: "",
            week6price: "",
            week7price: "",
            week8price: "",
            week9price: "",
            week10price: "",
            week11price: "",
            week12price: ""
        },
        {
            title: "Capital Purchase (specify)",
            week1price: "",
            week2price: "",
            week3price: "",
            week4price: "",
            week5price: "",
            week6price: "",
            week7price: "",
            week8price: "",
            week9price: "",
            week10price: "",
            week11price: "",
            week12price: ""
        },
        {
            title: "Other Startup Costs",
            week1price: "",
            week2price: "",
            week3price: "",
            week4price: "",
            week5price: "",
            week6price: "",
            week7price: "",
            week8price: "",
            week9price: "",
            week10price: "",
            week11price: "",
            week12price: ""
        },
        {
            title: "Reserve and/or Escrow",
            week1price: "",
            week2price: "",
            week3price: "",
            week4price: "",
            week5price: "",
            week6price: "",
            week7price: "",
            week8price: "",
            week9price: "",
            week10price: "",
            week11price: "",
            week12price: ""
        },
        {
            title: "Owner’s Withdrawal",
            week1price: "",
            week2price: "",
            week3price: "",
            week4price: "",
            week5price: "",
            week6price: "",
            week7price: "",
            week8price: "",
            week9price: "",
            week10price: "",
            week11price: "",
            week12price: ""
        }
    ]

    //   >>>>Operating Data Default Value<<<< 
    const defaultOperatingData = [
        {
            // title: language.cash_on_hand,
            title: "Sales Volume",
            week1price: "",
            week2price: "",
            week3price: "",
            week4price: "",
            week5price: "",
            week6price: "",
            week7price: "",
            week8price: "",
            week9price: "",
            week10price: "",
            week11price: "",
            week12price: "",
        },
        {
            title: "Accounts Receivable",
            week1price: "",
            week2price: "",
            week3price: "",
            week4price: "",
            week5price: "",
            week6price: "",
            week7price: "",
            week8price: "",
            week9price: "",
            week10price: "",
            week11price: "",
            week12price: "",
        },
        {
            title: "Bad Debt (end of month)",
            week1price: "",
            week2price: "",
            week3price: "",
            week4price: "",
            week5price: "",
            week6price: "",
            week7price: "",
            week8price: "",
            week9price: "",
            week10price: "",
            week11price: "",
            week12price: "",
        },
        {
            title: "Inventory on Hand",
            week1price: "",
            week2price: "",
            week3price: "",
            week4price: "",
            week5price: "",
            week6price: "",
            week7price: "",
            week8price: "",
            week9price: "",
            week10price: "",
            week11price: "",
            week12price: ""
        },
        {
            title: "Accounts Payable",
            week1price: "",
            week2price: "",
            week3price: "",
            week4price: "",
            week5price: "",
            week6price: "",
            week7price: "",
            week8price: "",
            week9price: "",
            week10price: "",
            week11price: "",
            week12price: ""
        },
        {
            title: "Depreciation",
            week1price: "",
            week2price: "",
            week3price: "",
            week4price: "",
            week5price: "",
            week6price: "",
            week7price: "",
            week8price: "",
            week9price: "",
            week10price: "",
            week11price: "",
            week12price: ""
        }
    ]

    const { language, currentLanguage } = useLanguage();
    const { profileData, setIsLoading } = useUserData();
    const [isSave, setIsSave] = useState(true);
    const [loading, setLoading] = useState(false);
    const [saveLoading, setSaveLoading] = useState(false);

    const [cashOnHand, setCashOnHand] = useState(initCashOnHand)
    const [cashReceipts, setCashReceipts] = useState(defaultCashReceipts);
    const [cashPaidOut, setCashPaidOut] = useState(defaultCashPaidOut);
    const [operatingData, setOperatingData] = useState(defaultOperatingData);

    const [totals, setTotals] = useState({
        week1price: 0,
        week2price: 0,
        week3price: 0,
        week4price: 0,
        week5price: 0,
        week6price: 0,
        week7price: 0,
        week8price: 0,
        week9price: 0,
        week10price: 0,
        week11price: 0,
        week12price: 0

    });

    const [deleteCashReceiptsRow, setDeleteCashReceiptsRow] = useState(false);
    const [deleteCashPaidOutRow, setDeleteCashPaidOutRow] = useState(false);
    const [deleteOperatingDataRow, setDeleteOperatingDataRow] = useState(false);
    const [summedValues, setSummedValues] = useState(initTotals);
    const [totalCashPaidOut, setTotalCashPaidOut] = useState(new Array(12).fill(0));
    const [subtractedValues, setSubtractedValues] = useState(new Array(12).fill(0));

    // >>>>Cash Receipts handlechange<<<<
    const handleChangeCashReceipts = (e, ind) => {
        const { name, value } = e.target;
        const data = cashReceipts?.map((item, i) => {
            if (i === ind) {
                return { ...item, [name]: value }
            } else {
                return item
            }
        })
        setCashReceipts(data);
        // clculateAllDataIncome(data)
    }

    // >>>>Cash Paid Out handlechange<<<<
    const handleChangeCashPaidOut = (e, ind) => {
        const { name, value } = e.target;
        const data = cashPaidOut?.map((item, i) => {
            if (i === ind) {
                return { ...item, [name]: value }
            } else {
                return item
            }
        })
        setCashPaidOut(data);
        // clculateAllDataIncome(data)
    }

    // >>>>Operating Data handlechange<<<<
    const handleChangeOperatingData = (e, ind) => {
        const { name, value } = e.target;
        const data = operatingData.map((item, i) => {
            if (i === ind) {
                return { ...item, [name]: value }
            } else {
                return item
            }
        })
        setOperatingData(data);
        // clculateAllDataIncome(data)
    }

    // >>>>Cash On Hand Change<<<<
    const handleCashOnHandCahnge = (e) => {
        const { name, value } = e.target;
        setCashOnHand({ ...cashOnHand, [name]: value })
    }

    // >>>>GET 12 Month Cash Flow Statemwnt Data<<<<
    const get12MonthCashFlowStatementData = async () => {
        try {
            const res = await HttpClient.requestData("reportbuilder-view-12monthsattements", "GET");
            if (res && res.status) {
                const data = res.data[0] || {};

                setCashOnHand(data.cashonhands.length > 0 ? data.cashonhands[0] : initCashOnHand);
                setCashReceipts(data.cashRecipts.length > 0 ? data.cashRecipts : defaultCashReceipts)
                setCashPaidOut(data.CashPaidOut.length > 0 ? data.CashPaidOut : defaultCashPaidOut)
                setOperatingData(data.operatingData.length > 0 ? data.operatingData : defaultOperatingData)
                // console.log("APIData", data, data.cashonhands);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    // >>>>Handle Save<<<<
    const handleSave = async () => {
        if (isSave) {
            const parseFloatSafely = (value) => value !== undefined ? parseFloat(value) : 0;

            const data = {
                cashonhands: [{
                    Beginning: parseFloatSafely(cashOnHand.Beginning),
                    week1price: parseFloatSafely(cashOnHand.week1price),
                    week2price: parseFloatSafely(cashOnHand.week2price),
                    week3price: parseFloatSafely(cashOnHand.week3price),
                    week4price: parseFloatSafely(cashOnHand.week4price),
                    week5price: parseFloatSafely(cashOnHand.week5price),
                    week6price: parseFloatSafely(cashOnHand.week6price),
                    week7price: parseFloatSafely(cashOnHand.week7price),
                    week8price: parseFloatSafely(cashOnHand.week8price),
                    week9price: parseFloatSafely(cashOnHand.week9price),
                    week10price: parseFloatSafely(cashOnHand.week10price),
                    week11price: parseFloatSafely(cashOnHand.week11price),
                    week12price: parseFloatSafely(cashOnHand.week12price)
                }],
                cashRecipts: cashReceipts?.map(({ _id, ...item }) => ({
                    ...item,
                    week1price: parseFloatSafely(item.week1price),
                    week2price: parseFloatSafely(item.week2price),
                    week3price: parseFloatSafely(item.week3price),
                    week4price: parseFloatSafely(item.week4price),
                    week5price: parseFloatSafely(item.week5price),
                    week6price: parseFloatSafely(item.week6price),
                    week7price: parseFloatSafely(item.week7price),
                    week8price: parseFloatSafely(item.week8price),
                    week9price: parseFloatSafely(item.week9price),
                    week10price: parseFloatSafely(item.week10price),
                    week11price: parseFloatSafely(item.week11price),
                    week12price: parseFloatSafely(item.week12price)
                })),
                CashPaidOut: cashPaidOut?.map(({ _id, ...item }) => ({
                    ...item,
                    week1price: parseFloatSafely(item.week1price),
                    week2price: parseFloatSafely(item.week2price),
                    week3price: parseFloatSafely(item.week3price),
                    week4price: parseFloatSafely(item.week4price),
                    week5price: parseFloatSafely(item.week5price),
                    week6price: parseFloatSafely(item.week6price),
                    week7price: parseFloatSafely(item.week7price),
                    week8price: parseFloatSafely(item.week8price),
                    week9price: parseFloatSafely(item.week9price),
                    week10price: parseFloatSafely(item.week10price),
                    week11price: parseFloatSafely(item.week11price),
                    week12price: parseFloatSafely(item.week12price)
                })),
                operatingData: operatingData?.map(({ _id, ...item }) => ({
                    ...item,
                    week1price: parseFloatSafely(item.week1price),
                    week2price: parseFloatSafely(item.week2price),
                    week3price: parseFloatSafely(item.week3price),
                    week4price: parseFloatSafely(item.week4price),
                    week5price: parseFloatSafely(item.week5price),
                    week6price: parseFloatSafely(item.week6price),
                    week7price: parseFloatSafely(item.week7price),
                    week8price: parseFloatSafely(item.week8price),
                    week9price: parseFloatSafely(item.week9price),
                    week10price: parseFloatSafely(item.week10price),
                    week11price: parseFloatSafely(item.week11price),
                    week12price: parseFloatSafely(item.week12price)
                })),
                cashAtendofmonth: subtractedValues
            }
            console.log("45fdg53464", data)
            // return
            setSaveLoading(true);
            const res = await HttpClient.requestData('reportbuilder-add-12month-statement', 'POST', data)
            if (res && res.status) {
                setSaveLoading(false);
                toast.success(language.twel_report_mssg);
                setIsSave(!isSave);
                get12MonthCashFlowStatementData()
            }
            else {
                setSaveLoading(false);
                toast.error(res?.message || "Something went wrong.");
            }
        } else {
            setIsSave(!isSave);
        }
    }

    // >>>>Build Report<<<<
    const handleBuildRepport = async () => {
        if (isSave) {
            toast.error("Please Save First");
            return
        }
        const parseFloatSafely = (value) => value !== undefined ? parseFloat(value) : 0;

        const data = {
            cashonhands: [
                {
                    Beginning: parseFloatSafely(cashOnHand.Beginning),
                    week1price: parseFloatSafely(cashOnHand.week1price),
                    week2price: parseFloatSafely(cashOnHand.week2price),
                    week3price: parseFloatSafely(cashOnHand.week3price),
                    week4price: parseFloatSafely(cashOnHand.week4price),
                    week5price: parseFloatSafely(cashOnHand.week5price),
                    week6price: parseFloatSafely(cashOnHand.week6price),
                    week7price: parseFloatSafely(cashOnHand.week7price),
                    week8price: parseFloatSafely(cashOnHand.week8price),
                    week9price: parseFloatSafely(cashOnHand.week9price),
                    week10price: parseFloatSafely(cashOnHand.week10price),
                    week11price: parseFloatSafely(cashOnHand.week11price),
                    week12price: parseFloatSafely(cashOnHand.week12price)
                }
            ],
            cashRecipts: cashReceipts?.map(({ _id, ...item }) => ({
                ...item,
                week1price: parseFloatSafely(item.week1price),
                week2price: parseFloatSafely(item.week2price),
                week3price: parseFloatSafely(item.week3price),
                week4price: parseFloatSafely(item.week4price),
                week5price: parseFloatSafely(item.week5price),
                week6price: parseFloatSafely(item.week6price),
                week7price: parseFloatSafely(item.week7price),
                week8price: parseFloatSafely(item.week8price),
                week9price: parseFloatSafely(item.week9price),
                week10price: parseFloatSafely(item.week10price),
                week11price: parseFloatSafely(item.week11price),
                week12price: parseFloatSafely(item.week12price)
            })),
            CashPaidOut: cashPaidOut?.map(({ _id, ...item }) => ({
                ...item,
                week1price: parseFloatSafely(item.week1price),
                week2price: parseFloatSafely(item.week2price),
                week3price: parseFloatSafely(item.week3price),
                week4price: parseFloatSafely(item.week4price),
                week5price: parseFloatSafely(item.week5price),
                week6price: parseFloatSafely(item.week6price),
                week7price: parseFloatSafely(item.week7price),
                week8price: parseFloatSafely(item.week8price),
                week9price: parseFloatSafely(item.week9price),
                week10price: parseFloatSafely(item.week10price),
                week11price: parseFloatSafely(item.week11price),
                week12price: parseFloatSafely(item.week12price)
            })),
            operatingData: operatingData?.map(({ _id, ...item }) => ({
                ...item,
                week1price: parseFloatSafely(item.week1price),
                week2price: parseFloatSafely(item.week2price),
                week3price: parseFloatSafely(item.week3price),
                week4price: parseFloatSafely(item.week4price),
                week5price: parseFloatSafely(item.week5price),
                week6price: parseFloatSafely(item.week6price),
                week7price: parseFloatSafely(item.week7price),
                week8price: parseFloatSafely(item.week8price),
                week9price: parseFloatSafely(item.week9price),
                week10price: parseFloatSafely(item.week10price),
                week11price: parseFloatSafely(item.week11price),
                week12price: parseFloatSafely(item.week12price)
            })),
            cashAtendofmonth: subtractedValues
        }
        console.log("45fdg53464", data)
        // return
        setLoading(true);
        const res = await HttpClient.requestData('reportbuilder-add-12month-statement', 'POST', data)
        if (res && res.status) {
            setLoading(false);
            toast.success(language.report_build_succ);
            get12MonthCashFlowStatementData()
        }
        else {
            setLoading(false);
            toast.error(res?.message || "Something went wrong.");
        }

    }

    // >>>>Download PDF<<<<
    const handlePdfDownload = async () => {
        // if (isSave) {
        //     toast.error("Please Save First");
        //     return
        // }

        setPdfLoader(true);
        setIsLoading(true);
        const res = await HttpClient.requestData("reportbuilder/download/pdf/twelveMonthCash-Statement", "GET", {})
        setPdfLoader(false);
        setIsLoading(false);
        if (res && res?.status) {
            window.open(res?.data, "_blank")
        } else {
            toast.error(res?.message || "Something Wrong!")
        }
    }

    // >>>>Sending mail<<<<
    const sendCapatialMail = async (emailData, oncloseSendModal) => {
        if (isSave) {
            return toast.error("Please Save First");
        } else {
            const parseFloatSafely = (value) => value !== undefined ? parseFloat(value) : 0;

            const data = {
                email: emailData?.email,
                language: currentLanguage === "eng" ? "EN" : "ES",
                cashonhands: [
                    {
                        Beginning: parseFloatSafely(cashOnHand.Beginning),
                        week1price: parseFloatSafely(cashOnHand.week1price),
                        week2price: parseFloatSafely(cashOnHand.week2price),
                        week3price: parseFloatSafely(cashOnHand.week3price),
                        week4price: parseFloatSafely(cashOnHand.week4price),
                        week5price: parseFloatSafely(cashOnHand.week5price),
                        week6price: parseFloatSafely(cashOnHand.week6price),
                        week7price: parseFloatSafely(cashOnHand.week7price),
                        week8price: parseFloatSafely(cashOnHand.week8price),
                        week9price: parseFloatSafely(cashOnHand.week9price),
                        week10price: parseFloatSafely(cashOnHand.week10price),
                        week11price: parseFloatSafely(cashOnHand.week11price),
                        week12price: parseFloatSafely(cashOnHand.week12price)
                    }
                ],
                cashRecipts: cashReceipts?.map(({ _id, ...item }) => ({
                    ...item,
                    week1price: parseFloatSafely(item.week1price),
                    week2price: parseFloatSafely(item.week2price),
                    week3price: parseFloatSafely(item.week3price),
                    week4price: parseFloatSafely(item.week4price),
                    week5price: parseFloatSafely(item.week5price),
                    week6price: parseFloatSafely(item.week6price),
                    week7price: parseFloatSafely(item.week7price),
                    week8price: parseFloatSafely(item.week8price),
                    week9price: parseFloatSafely(item.week9price),
                    week10price: parseFloatSafely(item.week10price),
                    week11price: parseFloatSafely(item.week11price),
                    week12price: parseFloatSafely(item.week12price)
                })),
                CashPaidOut: cashPaidOut?.map(({ _id, ...item }) => ({
                    ...item,
                    week1price: parseFloatSafely(item.week1price),
                    week2price: parseFloatSafely(item.week2price),
                    week3price: parseFloatSafely(item.week3price),
                    week4price: parseFloatSafely(item.week4price),
                    week5price: parseFloatSafely(item.week5price),
                    week6price: parseFloatSafely(item.week6price),
                    week7price: parseFloatSafely(item.week7price),
                    week8price: parseFloatSafely(item.week8price),
                    week9price: parseFloatSafely(item.week9price),
                    week10price: parseFloatSafely(item.week10price),
                    week11price: parseFloatSafely(item.week11price),
                    week12price: parseFloatSafely(item.week12price)
                })),
                operatingData: operatingData?.map(({ _id, ...item }) => ({
                    ...item,
                    week1price: parseFloatSafely(item.week1price),
                    week2price: parseFloatSafely(item.week2price),
                    week3price: parseFloatSafely(item.week3price),
                    week4price: parseFloatSafely(item.week4price),
                    week5price: parseFloatSafely(item.week5price),
                    week6price: parseFloatSafely(item.week6price),
                    week7price: parseFloatSafely(item.week7price),
                    week8price: parseFloatSafely(item.week8price),
                    week9price: parseFloatSafely(item.week9price),
                    week10price: parseFloatSafely(item.week10price),
                    week11price: parseFloatSafely(item.week11price),
                    week12price: parseFloatSafely(item.week12price)
                })),
                cashAtendofmonth: subtractedValues
            }
            console.log("sdfssd", data)
            // return
            oncloseSendModal();
            setIsLoading(true);
            const res = await HttpClient.requestData("reportbuilder/pdf/twelvemonthsatementpdf", "POST", data)
            if (res && res?.status) {
                toast?.success(language.mail_sent_success);
                setIsLoading(false);
            } else {
                setIsLoading(false);
            }
        }
    }

    // >>>>Setting TOTAL CASH RECEIPTS Value<<<<
    useEffect(() => {
        const calculateTotals = () => {
            const totals = {
                week1price: 0,
                week2price: 0,
                week3price: 0,
                week4price: 0,
                week5price: 0,
                week6price: 0,
                week7price: 0,
                week8price: 0,
                week9price: 0,
                week10price: 0,
                week11price: 0,
                week12price: 0
            };

            // Calculate totals for cashReceipts
            cashReceipts.forEach(item => {
                Object.keys(totals).forEach(week => {
                    totals[week] += parseFloat(item[week] || 0);
                });
            });

            return totals;
        };

        setTotals(calculateTotals());
    }, [cashReceipts]);

    // >>>>Setting Total Cash Available (before cash out)<<<<
    useEffect(() => {
        const calculateSums = () => {
            let summed = {};
            for (let week = 1; week <= 12; week++) {
                const cashValue = parseFloat(cashOnHand[`week${week}price`] || 0);
                const totalValue = totals[`week${week}price`];
                summed[`week${week}sum`] = cashValue + totalValue;
            }
            setSummedValues(summed);
        };

        calculateSums();
    }, [cashOnHand, totals]);

    // >>>>Setting TOTAL CASH PAID OUT<<<<
    useEffect(() => {
        const calculateTotalCashPaidOut = () => {
            const weeks = 12; // Number of weeks
            const totals = new Array(weeks).fill(0);

            cashPaidOut.forEach(item => {
                for (let i = 1; i <= weeks; i++) {
                    const weekPrice = parseFloat(item[`week${i}price`]) || 0;
                    totals[i - 1] += weekPrice;
                }
            });

            return totals;
        };
        setTotalCashPaidOut(calculateTotalCashPaidOut());
    }, [cashPaidOut]);


    // >>>>Setting Cash Position (end of the month)<<<<
    useEffect(() => {
        const calculateSubtraction = () => {
            let subtracted = {};
            for (let week = 1; week <= 12; week++) {
                const summedValue = summedValues[`week${week}sum`] || 0;
                const subtractedValue = summedValue - totalCashPaidOut[week - 1];
                subtracted[`week${week}pricetotal`] = subtractedValue;
            }
            setSubtractedValues(subtracted);
        };

        calculateSubtraction();
    }, [summedValues, totalCashPaidOut]);

    // >>>>Sending Male<<<< 
    useEffect(() => {
        setEmailSendFunc(() => sendCapatialMail)

    }, [isSave])

    useEffect(() => {
        get12MonthCashFlowStatementData();
        setPdfCreate(() => handlePdfDownload);
    }, [])

    return (
        <>
            <div className="container">
                <div className="buildIncmStTableDiv">
                    <div className="CurrentPeriod_drowp">
                        <div className="linewrap">
                            <span>Fiscal Year Begins:</span>
                            <select className="typesBuildSelect">
                                <option selected>-Select year-</option>
                                <option>2024</option>
                                <option>2023</option>
                                <option>2022</option>
                                <option>2021</option>
                                <option>2020</option>
                            </select>
                        </div>

                        <div className="linewrap">
                            <span style={{ color: "red", fontSize: "15px", fontWeight: "400" }}>{language.changing_year_auto_month}</span>
                            {/* <input type="text" placeholder="-store user entry-" /> */}
                        </div>
                    </div>

                    <table className="buildIncmStTable">
                        <tbody>
                            <tr>
                                <th className="buildIncmStTh12 buildIncmStTh12First">
                                </th>
                                <th className="buildIncmStTh12 pr-2">
                                    <span>{language.beginning}</span>
                                </th>
                                <th className="buildIncmStTh12 pr-2">
                                    <span>Jan 24</span>
                                </th>
                                <th className="buildIncmStTh12 pr-2">
                                    <span>Feb 24</span>
                                </th>
                                <th className="buildIncmStTh12 pr-2">
                                    <span>Mar 24</span>
                                </th>
                                <th className="buildIncmStTh12 pr-2">
                                    <span>Apr 24</span>
                                </th>
                                <th className="buildIncmStTh12 pr-2">
                                    <span>May 24</span>
                                </th>
                                <th className="buildIncmStTh12 pr-2">
                                    <span>Jun 24</span>
                                </th>
                                <th className="buildIncmStTh12 pr-2">
                                    <span>Jul 24</span>
                                </th>
                                <th className="buildIncmStTh12 pr-2">
                                    <span>Aug 24</span>
                                </th>
                                <th className="buildIncmStTh12 pr-2">
                                    <span>Sep 24</span>
                                </th>
                                <th className="buildIncmStTh12 pr-2">
                                    <span>Oct 24</span>
                                </th>
                                <th className="buildIncmStTh12 pr-2">
                                    <span>Nov 24</span>
                                </th>
                                <th className="buildIncmStTh12">
                                    <span>Dec 24</span>
                                </th>
                            </tr>
                            <tr>
                                <td className="buildIncmStTd" style={{ fontWeight: "bold" }}>

                                    {language.coh}
                                </td>
                                <td className="buildIncmStTd pr-2">
                                    <input
                                        type="number"
                                        name="Beginning"
                                        value={cashOnHand?.Beginning}
                                        onChange={handleCashOnHandCahnge}
                                        placeholder="$0.00"
                                    />
                                </td>
                                <td className="buildIncmStTd pr-2">
                                    <input
                                        type="number"
                                        name="week1price"
                                        value={cashOnHand?.week1price}
                                        onChange={handleCashOnHandCahnge}
                                        placeholder="$0.00"
                                    />
                                </td>
                                <td className="buildIncmStTd pr-2">
                                    <input
                                        type="number"
                                        name="week2price"
                                        value={cashOnHand?.week2price}
                                        onChange={handleCashOnHandCahnge}
                                        placeholder="$0.00"
                                    />
                                </td>
                                <td className="buildIncmStTd pr-2">
                                    <input
                                        type="number"
                                        name="week3price"
                                        value={cashOnHand?.week3price}
                                        onChange={handleCashOnHandCahnge}
                                        placeholder="$0.00"
                                    />
                                </td>
                                <td className="buildIncmStTd pr-2">
                                    <input
                                        type="number"
                                        name="week4price"
                                        value={cashOnHand?.week4price}
                                        onChange={handleCashOnHandCahnge}
                                        placeholder="$0.00"
                                    />
                                </td>
                                <td className="buildIncmStTd pr-2">
                                    <input
                                        type="number"
                                        name="week5price"
                                        value={cashOnHand?.week5price}
                                        onChange={handleCashOnHandCahnge}
                                        placeholder="$0.00"
                                    />
                                </td>
                                <td className="buildIncmStTd pr-2">
                                    <input
                                        type="number"
                                        name="week6price"
                                        value={cashOnHand?.week6price}
                                        onChange={handleCashOnHandCahnge}
                                        placeholder="$0.00"
                                    />
                                </td>
                                <td className="buildIncmStTd pr-2">
                                    <input
                                        type="number"
                                        name="week7price"
                                        value={cashOnHand?.week7price}
                                        onChange={handleCashOnHandCahnge}
                                        placeholder="$0.00"
                                    />
                                </td>
                                <td className="buildIncmStTd pr-2">
                                    <input
                                        type="number"
                                        name="week8price"
                                        value={cashOnHand?.week8price}
                                        onChange={handleCashOnHandCahnge}
                                        placeholder="$0.00"
                                    />
                                </td>
                                <td className="buildIncmStTd pr-2">
                                    <input
                                        type="number"
                                        name="week9price"
                                        value={cashOnHand?.week9price}
                                        onChange={handleCashOnHandCahnge}
                                        placeholder="$0.00"
                                    />
                                </td>
                                <td className="buildIncmStTd pr-2">
                                    <input
                                        type="number"
                                        name="week10price"
                                        value={cashOnHand?.week10price}
                                        onChange={handleCashOnHandCahnge}
                                        placeholder="$0.00"
                                    />
                                </td>
                                <td className="buildIncmStTd pr-2">
                                    <input
                                        type="number"
                                        name="week11price"
                                        value={cashOnHand?.week11price}
                                        onChange={handleCashOnHandCahnge}
                                        placeholder="$0.00"
                                    />
                                </td>
                                <td className="buildIncmStTd pr-2">
                                    <input
                                        type="number"
                                        name="week12price"
                                        value={cashOnHand?.week12price}
                                        onChange={handleCashOnHandCahnge}
                                        placeholder="$0.00"
                                    />
                                </td>
                            </tr>


                            <tr>
                                <td className="buildIncmStTh12 buildIncmStTh12First">
                                    {isSave &&
                                        <button
                                            className="buildAddBtn mb-2"
                                            onClick={() => {
                                                // if (validateAsset()) {
                                                setCashReceipts(prev => ([...prev, initReceipts]));
                                                // } else {
                                                //   toast.error(language.please_enter_all);
                                                // }
                                            }}
                                        >
                                            <i class="fa-solid fa-plus"></i>
                                        </button>
                                    }
                                    <span>{language.cash_receipt}</span>
                                    {isSave &&
                                        <button
                                            className="delBuildRowBtn"
                                            onClick={() => setDeleteCashReceiptsRow(prev => !prev)}
                                        >
                                            {deleteCashReceiptsRow ? language.remove_delete : language.delete_row}
                                        </button>
                                    }
                                </td>
                            </tr>

                            {
                                cashReceipts?.map((item, i, arr) => {
                                    return (

                                        <tr>
                                            <td className="buildIncmStTd">
                                                {
                                                    // deleteCurrentAssetRow && isSave &&
                                                    deleteCashReceiptsRow &&
                                                    arr.length !== 1 &&
                                                    <button
                                                        className="buildRemoveBtn"
                                                        onClick={() => {
                                                            // const flltData = assetData?.filter((ele, ind) => i !== ind);
                                                            // clculateAllDataIncome(flltData);
                                                            setCashReceipts(prev => prev.filter((ele, ind) => i !== ind));
                                                        }}
                                                    >
                                                        <i class="fa-solid fa-minus"></i>
                                                    </button>
                                                }
                                                <input
                                                    type="text"
                                                    className="userBuildInp"
                                                    name="title"
                                                    value={item.title}
                                                    onChange={(e) => handleChangeCashReceipts(e, i)}
                                                />
                                            </td>
                                            <td className="buildIncmStTd pr-2">

                                            </td>
                                            <td className="buildIncmStTd pr-2">
                                                <input
                                                    type="number"
                                                    name="week1price"
                                                    value={item.week1price}
                                                    onChange={(e) => handleChangeCashReceipts(e, i)}
                                                    placeholder="$0.00"
                                                />
                                            </td>
                                            <td className="buildIncmStTd pr-2">
                                                <input
                                                    type="number"
                                                    name="week2price"
                                                    value={item.week2price}
                                                    onChange={(e) => handleChangeCashReceipts(e, i)}
                                                    placeholder="$0.00"
                                                />
                                            </td>
                                            <td className="buildIncmStTd pr-2">
                                                <input
                                                    type="number"
                                                    name="week3price"
                                                    value={item.week3price}
                                                    onChange={(e) => handleChangeCashReceipts(e, i)}
                                                    placeholder="$0.00"
                                                />
                                            </td>
                                            <td className="buildIncmStTd pr-2">
                                                <input
                                                    type="number"
                                                    name="week4price"
                                                    value={item.week4price}
                                                    onChange={(e) => handleChangeCashReceipts(e, i)}
                                                    placeholder="$0.00"
                                                />
                                            </td>
                                            <td className="buildIncmStTd pr-2">
                                                <input
                                                    type="number"
                                                    name="week5price"
                                                    value={item.week5price}
                                                    onChange={(e) => handleChangeCashReceipts(e, i)}
                                                    placeholder="$0.00"
                                                />
                                            </td>
                                            <td className="buildIncmStTd pr-2">
                                                <input
                                                    type="number"
                                                    name="week6price"
                                                    value={item.week6price}
                                                    onChange={(e) => handleChangeCashReceipts(e, i)}
                                                    placeholder="$0.00"
                                                />
                                            </td>
                                            <td className="buildIncmStTd pr-2">
                                                <input
                                                    type="number"
                                                    name="week7price"
                                                    value={item.week7price}
                                                    onChange={(e) => handleChangeCashReceipts(e, i)}
                                                    placeholder="$0.00"
                                                />
                                            </td>
                                            <td className="buildIncmStTd pr-2">
                                                <input
                                                    type="number"
                                                    name="week8price"
                                                    value={item.week8price}
                                                    onChange={(e) => handleChangeCashReceipts(e, i)}
                                                    placeholder="$0.00"
                                                />
                                            </td>
                                            <td className="buildIncmStTd pr-2">
                                                <input
                                                    type="number"
                                                    name="week9price"
                                                    value={item.week9price}
                                                    onChange={(e) => handleChangeCashReceipts(e, i)}
                                                    placeholder="$0.00"
                                                />
                                            </td>
                                            <td className="buildIncmStTd pr-2">
                                                <input
                                                    type="number"
                                                    name="week10price"
                                                    value={item.week10price}
                                                    onChange={(e) => handleChangeCashReceipts(e, i)}
                                                    placeholder="$0.00"
                                                />
                                            </td>
                                            <td className="buildIncmStTd pr-2">
                                                <input
                                                    type="number"
                                                    name="week11price"
                                                    value={item.week11price}
                                                    onChange={(e) => handleChangeCashReceipts(e, i)}
                                                    placeholder="$0.00"
                                                />
                                            </td>
                                            <td className="buildIncmStTd pr-2">
                                                <input
                                                    type="number"
                                                    name="week12price"
                                                    value={item.week12price}
                                                    onChange={(e) => handleChangeCashReceipts(e, i)}
                                                    placeholder="$0.00"
                                                />
                                            </td>
                                        </tr>
                                    )
                                })
                            }

                            <tr>
                                <td colSpan={14}>
                                    <div className="buildTotDivider" />
                                </td>
                            </tr>

                            <tr>
                                <td className="buildIncmStTd">
                                    <span className="buildTotSpan">{language.total_cash_receipt}</span>
                                </td>
                                <td className="buildIncmStTd pr-2">

                                </td>
                                {Array.from({ length: 12 }).map((_, index) => (
                                    <td key={index} className="buildIncmStTd pr-2">
                                        <input
                                            type="number"
                                            placeholder="$0.00"
                                            value={totals[`week${index + 1}price`]}
                                            readOnly
                                        />
                                    </td>
                                ))}

                            </tr>
                        </tbody>
                    </table>

                    <table className="buildIncmStTable mt-3">
                        <tbody>
                            <tr>
                                <th className="buildIncmStTh12 buildIncmStTh12First">
                                </th>
                                <th className="buildIncmStTh12 pr-2">
                                    <span>{language.beginning}</span>
                                </th>
                                <th className="buildIncmStTh12 pr-2">
                                    <span>Jan 24</span>
                                </th>
                                <th className="buildIncmStTh12 pr-2">
                                    <span>Feb 24</span>
                                </th>
                                <th className="buildIncmStTh12 pr-2">
                                    <span>Mar 24</span>
                                </th>
                                <th className="buildIncmStTh12 pr-2">
                                    <span>Apr 24</span>
                                </th>
                                <th className="buildIncmStTh12 pr-2">
                                    <span>May 24</span>
                                </th>
                                <th className="buildIncmStTh12 pr-2">
                                    <span>Jun 24</span>
                                </th>
                                <th className="buildIncmStTh12 pr-2">
                                    <span>Jul 24</span>
                                </th>
                                <th className="buildIncmStTh12 pr-2">
                                    <span>Aug 24</span>
                                </th>
                                <th className="buildIncmStTh12 pr-2">
                                    <span>Sep 24</span>
                                </th>
                                <th className="buildIncmStTh12 pr-2">
                                    <span>Oct 24</span>
                                </th>
                                <th className="buildIncmStTh12 pr-2">
                                    <span>Nov 24</span>
                                </th>
                                <th className="buildIncmStTh12">
                                    <span>Dec 24</span>
                                </th>
                            </tr>
                            <tr>
                                <td className="buildIncmStTd" style={{ fontWeight: "bold" }}>
                                    {language.cash_available}
                                </td>
                                <td className="buildIncmStTd pr-2">
                                    <input
                                        disabled
                                        type="number"
                                        name="pastDue"
                                        placeholder="$0.00"
                                        defaultValue=""
                                    />
                                </td>

                                {Array.from({ length: 12 }).map((_, index) => (
                                    <td key={index} className="buildIncmStTd pr-2">
                                        <input
                                            type="number"
                                            placeholder="$0.00"
                                            value={summedValues[`week${index + 1}sum`] || 0}
                                            readOnly
                                        />
                                    </td>
                                ))}
                            </tr>

                            <tr>
                                <td className="buildIncmStTh12 buildIncmStTh12First">
                                    {isSave &&
                                        <button
                                            className="buildAddBtn"
                                            onClick={() => {
                                                // if (validateAsset()) {
                                                setCashPaidOut(prev => ([...prev, initCashPaidOut]));
                                                // } else {
                                                //   toast.error(language.please_enter_all);
                                                // }
                                            }}
                                        >
                                            <i class="fa-solid fa-plus"></i>
                                        </button>
                                    }
                                    <span>{language.cash_paid_out}</span>
                                    {isSave &&
                                        <button
                                            className="delBuildRowBtn"
                                            onClick={() => setDeleteCashPaidOutRow(prev => !prev)}
                                        >
                                            {deleteCashPaidOutRow ? language.remove_delete : language.delete_row}
                                        </button>
                                    }
                                </td>
                            </tr>

                            {
                                cashPaidOut?.map((item, i, arr) => {
                                    return (
                                        <tr>
                                            <td className="buildIncmStTd">
                                                {
                                                    // deleteCurrentAssetRow && isSave &&
                                                    deleteCashPaidOutRow &&
                                                    arr.length !== 1 &&
                                                    <button
                                                        className="buildRemoveBtn"
                                                        onClick={() => {
                                                            // const flltData = assetData?.filter((ele, ind) => i !== ind);
                                                            // clculateAllDataIncome(flltData);
                                                            setCashPaidOut(prev => prev.filter((ele, ind) => i !== ind));
                                                        }}
                                                    >
                                                        <i class="fa-solid fa-minus"></i>
                                                    </button>
                                                }
                                                <input
                                                    type="text"
                                                    className="userBuildInp"
                                                    name="title"
                                                    value={item.title}
                                                    onChange={(e) => handleChangeCashPaidOut(e, i)}
                                                />
                                            </td>
                                            <td className="buildIncmStTd pr-2">

                                            </td>
                                            <td className="buildIncmStTd pr-2">
                                                <input
                                                    type="number"
                                                    name="week1price"
                                                    value={item.week1price}
                                                    onChange={(e) => handleChangeCashPaidOut(e, i)}
                                                    placeholder="$0.00"
                                                />
                                            </td>
                                            <td className="buildIncmStTd pr-2">
                                                <input
                                                    type="number"
                                                    name="week2price"
                                                    value={item.week2price}
                                                    onChange={(e) => handleChangeCashPaidOut(e, i)}
                                                    placeholder="$0.00"
                                                />
                                            </td>
                                            <td className="buildIncmStTd pr-2">
                                                <input
                                                    type="number"
                                                    name="week3price"
                                                    value={item.week3price}
                                                    onChange={(e) => handleChangeCashPaidOut(e, i)}
                                                    placeholder="$0.00"
                                                />
                                            </td>
                                            <td className="buildIncmStTd pr-2">
                                                <input
                                                    type="number"
                                                    name="week4price"
                                                    value={item.week4price}
                                                    onChange={(e) => handleChangeCashPaidOut(e, i)}
                                                    placeholder="$0.00"
                                                />
                                            </td>
                                            <td className="buildIncmStTd pr-2">
                                                <input
                                                    type="number"
                                                    name="week5price"
                                                    value={item.week5price}
                                                    onChange={(e) => handleChangeCashPaidOut(e, i)}
                                                    placeholder="$0.00"
                                                />
                                            </td>
                                            <td className="buildIncmStTd pr-2">
                                                <input
                                                    type="number"
                                                    name="week6price"
                                                    value={item.week6price}
                                                    onChange={(e) => handleChangeCashPaidOut(e, i)}
                                                    placeholder="$0.00"
                                                />
                                            </td>
                                            <td className="buildIncmStTd pr-2">
                                                <input
                                                    type="number"
                                                    name="week7price"
                                                    value={item.week7price}
                                                    onChange={(e) => handleChangeCashPaidOut(e, i)}
                                                    placeholder="$0.00"
                                                />
                                            </td>
                                            <td className="buildIncmStTd pr-2">
                                                <input
                                                    type="number"
                                                    name="week8price"
                                                    value={item.week8price}
                                                    onChange={(e) => handleChangeCashPaidOut(e, i)}
                                                    placeholder="$0.00"
                                                />
                                            </td>
                                            <td className="buildIncmStTd pr-2">
                                                <input
                                                    type="number"
                                                    name="week9price"
                                                    value={item.week9price}
                                                    onChange={(e) => handleChangeCashPaidOut(e, i)}
                                                    placeholder="$0.00"
                                                />
                                            </td>
                                            <td className="buildIncmStTd pr-2">
                                                <input
                                                    type="number"
                                                    name="week10price"
                                                    value={item.week10price}
                                                    onChange={(e) => handleChangeCashPaidOut(e, i)}
                                                    placeholder="$0.00"
                                                />
                                            </td>
                                            <td className="buildIncmStTd pr-2">
                                                <input
                                                    type="number"
                                                    name="week11price"
                                                    value={item.week11price}
                                                    onChange={(e) => handleChangeCashPaidOut(e, i)}
                                                    placeholder="$0.00"
                                                />
                                            </td>
                                            <td className="buildIncmStTd pr-2">
                                                <input
                                                    type="number"
                                                    name="week12price"
                                                    value={item.week12price}
                                                    onChange={(e) => handleChangeCashPaidOut(e, i)}
                                                    placeholder="$0.00"
                                                />
                                            </td>
                                        </tr>
                                    )
                                })

                            }

                            <tr>
                                <td />
                                <td colSpan={14}>
                                    <div className="buildTotDivider" />
                                </td>
                            </tr>

                            <tr>
                                <td className="buildIncmStTd">
                                    <span className="buildTotSpan">{language.total_cash_paid_out}</span>
                                </td>
                                <td className="buildIncmStTd pr-2">

                                </td>

                                {totalCashPaidOut.map((total, index) => (
                                    <td key={index} className="buildIncmStTd pr-2">
                                        <input type="number" value={total} readOnly />
                                    </td>
                                ))}
                            </tr>
                        </tbody>
                    </table>

                    <table className="buildIncmStTable mt-3">
                        <tbody>
                            <tr>
                                <th className="buildIncmStTh12 buildIncmStTh12First">
                                </th>
                                <th className="buildIncmStTh12 pr-2">
                                    <span>{language.beginning}</span>
                                </th>
                                <th className="buildIncmStTh12 pr-2">
                                    <span>Jan 24</span>
                                </th>
                                <th className="buildIncmStTh12 pr-2">
                                    <span>Feb 24</span>
                                </th>
                                <th className="buildIncmStTh12 pr-2">
                                    <span>Mar 24</span>
                                </th>
                                <th className="buildIncmStTh12 pr-2">
                                    <span>Apr 24</span>
                                </th>
                                <th className="buildIncmStTh12 pr-2">
                                    <span>May 24</span>
                                </th>
                                <th className="buildIncmStTh12 pr-2">
                                    <span>Jun 24</span>
                                </th>
                                <th className="buildIncmStTh12 pr-2">
                                    <span>Jul 24</span>
                                </th>
                                <th className="buildIncmStTh12 pr-2">
                                    <span>Aug 24</span>
                                </th>
                                <th className="buildIncmStTh12 pr-2">
                                    <span>Sep 24</span>
                                </th>
                                <th className="buildIncmStTh12 pr-2">
                                    <span>Oct 24</span>
                                </th>
                                <th className="buildIncmStTh12 pr-2">
                                    <span>Nov 24</span>
                                </th>
                                <th className="buildIncmStTh12">
                                    <span>Dec 24</span>
                                </th>
                            </tr>
                            <tr>
                                <td className="buildIncmStTd" style={{ fontWeight: "bold" }}>
                                    {language.cash_position}
                                </td>
                                <td className="buildIncmStTd pr-2">
                                    <input
                                        disabled
                                        type="number"
                                        name="pastDue"
                                        placeholder="$0.00"
                                        defaultValue=""
                                    />
                                </td>
                                {Array.from({ length: 12 }).map((_, index) => (
                                    <td key={index} className="buildIncmStTd pr-2">
                                        <input
                                            type="number"
                                            placeholder="$0.00"
                                            value={subtractedValues[`week${index + 1}pricetotal`] || 0}
                                            readOnly
                                        />
                                    </td>
                                ))}
                            </tr>
                            <tr>
                                <td className="buildIncmStTh12 buildIncmStTh12First" colSpan={5}>
                                    {isSave &&
                                        <button
                                            className="buildAddBtn"
                                            onClick={() => {
                                                // if (validateAsset()) {
                                                setOperatingData(prev => ([...prev, initOperatingData]));
                                                // } else {
                                                //   toast.error(language.please_enter_all);
                                                // }
                                            }}
                                        >
                                            <i class="fa-solid fa-plus"></i>
                                        </button>
                                    }
                                    <span>{language.essential_operating_data}</span>
                                    {isSave &&
                                        <button
                                            className="delBuildRowBtn"
                                            onClick={() => setDeleteOperatingDataRow(prev => !prev)}
                                        >
                                            {deleteOperatingDataRow ? language.remove_delete : language.delete_row}
                                        </button>
                                    }
                                </td>
                            </tr>
                            {
                                operatingData?.map((item, i, arr) => {
                                    return (
                                        <tr>
                                            <td className="buildIncmStTd">
                                                {
                                                    // deleteCurrentAssetRow && isSave &&
                                                    deleteOperatingDataRow &&
                                                    arr.length !== 1 &&
                                                    <button
                                                        className="buildRemoveBtn"
                                                        onClick={() => {
                                                            // const flltData = assetData?.filter((ele, ind) => i !== ind);
                                                            // clculateAllDataIncome(flltData);
                                                            setOperatingData(prev => prev.filter((ele, ind) => i !== ind));
                                                        }}
                                                    >
                                                        <i class="fa-solid fa-minus"></i>
                                                    </button>
                                                }
                                                <input
                                                    type="text"
                                                    className="userBuildInp"
                                                    name="title"
                                                    value={item.title}
                                                    onChange={(e) => handleChangeOperatingData(e, i)}
                                                />
                                            </td>
                                            <td className="buildIncmStTd pr-2">

                                            </td>
                                            <td className="buildIncmStTd pr-2">
                                                <input
                                                    type="number"
                                                    name="week1price"
                                                    value={item.week1price}
                                                    onChange={(e) => handleChangeOperatingData(e, i)}
                                                    placeholder="$0.00"
                                                />
                                            </td>
                                            <td className="buildIncmStTd pr-2">
                                                <input
                                                    type="number"
                                                    name="week2price"
                                                    value={item.week2price}
                                                    onChange={(e) => handleChangeOperatingData(e, i)}
                                                    placeholder="$0.00"
                                                />
                                            </td>
                                            <td className="buildIncmStTd pr-2">
                                                <input
                                                    type="number"
                                                    name="week3price"
                                                    value={item.week3price}
                                                    onChange={(e) => handleChangeOperatingData(e, i)}
                                                    placeholder="$0.00"
                                                />
                                            </td>
                                            <td className="buildIncmStTd pr-2">
                                                <input
                                                    type="number"
                                                    name="week4price"
                                                    value={item.week4price}
                                                    onChange={(e) => handleChangeOperatingData(e, i)}
                                                    placeholder="$0.00"
                                                />
                                            </td>
                                            <td className="buildIncmStTd pr-2">
                                                <input
                                                    type="number"
                                                    name="week5price"
                                                    value={item.week5price}
                                                    onChange={(e) => handleChangeOperatingData(e, i)}
                                                    placeholder="$0.00"
                                                />
                                            </td>
                                            <td className="buildIncmStTd pr-2">
                                                <input
                                                    type="number"
                                                    name="week6price"
                                                    value={item.week6price}
                                                    onChange={(e) => handleChangeOperatingData(e, i)}
                                                    placeholder="$0.00"
                                                />
                                            </td>
                                            <td className="buildIncmStTd pr-2">
                                                <input
                                                    type="number"
                                                    name="week7price"
                                                    value={item.week7price}
                                                    onChange={(e) => handleChangeOperatingData(e, i)}
                                                    placeholder="$0.00"
                                                />
                                            </td>
                                            <td className="buildIncmStTd pr-2">
                                                <input
                                                    type="number"
                                                    name="week8price"
                                                    value={item.week8price}
                                                    onChange={(e) => handleChangeOperatingData(e, i)}
                                                    placeholder="$0.00"
                                                />
                                            </td>
                                            <td className="buildIncmStTd pr-2">
                                                <input
                                                    type="number"
                                                    name="week9price"
                                                    value={item.week9price}
                                                    onChange={(e) => handleChangeOperatingData(e, i)}
                                                    placeholder="$0.00"
                                                />
                                            </td>
                                            <td className="buildIncmStTd pr-2">
                                                <input
                                                    type="number"
                                                    name="week10price"
                                                    value={item.week10price}
                                                    onChange={(e) => handleChangeOperatingData(e, i)}
                                                    placeholder="$0.00"
                                                />
                                            </td>
                                            <td className="buildIncmStTd pr-2">
                                                <input
                                                    type="number"
                                                    name="week11price"
                                                    value={item.week11price}
                                                    onChange={(e) => handleChangeOperatingData(e, i)}
                                                    placeholder="$0.00"
                                                />
                                            </td>
                                            <td className="buildIncmStTd pr-2">
                                                <input
                                                    type="number"
                                                    name="week12price"
                                                    value={item.week12price}
                                                    onChange={(e) => handleChangeOperatingData(e, i)}
                                                    placeholder="$0.00"
                                                />
                                            </td>
                                        </tr>
                                    )
                                })
                            }

                        </tbody>
                    </table>

                    <div className="d-flex justify-content-center mt-3">
                        <button className="btn-report-save"
                            onClick={handleSave}
                        >{
                                isSave ?
                                    saveLoading ? <CustomLoaderLine height="10" width="40" /> : language.save?.toUpperCase()
                                    :
                                    language.edit?.toUpperCase()
                            }</button>
                        <button className="btn-report-build"
                            onClick={() => handleBuildRepport()}

                        >
                            {loading ? <CustomLoaderLine height="10" width="100" /> : language.build_report?.toUpperCase()}
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Build12MonthCashFlowStatement