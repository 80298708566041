import React, { useContext } from 'react'
import { Navigate } from 'react-router-dom';
import { UserContext } from '../../Providers/UserWrapper'

function PaymentPublic({ children }) {
    const { profileData } = useContext(UserContext);

    if (profileData && profileData.isSubscribed) {
        return <Navigate to="/manager" />
    }
    return (
        <>
            {children}
        </>
    )
}

export default PaymentPublic