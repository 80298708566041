import React, { useEffect, useState, ChangeEventHandler, useCallback } from "react";
import HttpClient from "../../../utils/HttpClient";
import { useUserData } from "../../../Providers/UserWrapper";
import { useLanguage } from "../../../Providers/LanguageContext";
import { data } from "jquery";
import { Prev } from "react-bootstrap/esm/PageItem";
import { useNavigate } from "react-router-dom";
import './bankNameDropdown.css';
import CustomLoaderLine from "../../../Component/Loader/CustomLoaderLine";
import moment from "moment";
import toast from "react-hot-toast";
import { PlaidLink, usePlaidLink } from "react-plaid-link";
import NoDataFound from "../../../Component/NoDataFound";

function DashboardChartSix({ goToSelectedSlice, time, userCode }) {
  const { profileData, setIsLoading, isInvestor, isBookKeeper } = useUserData();
  const { language, currentLanguage } = useLanguage();
  const navigate = useNavigate();

  const [bankName, setBankName] = useState("");
  const [isShowList, setIsShowList] = useState(false);
  const [searchDate, setSearchDate] = useState("");

  // bank data
  const [isBankNameDrop, setIsBankNameDrop] = useState(false);
  const [bankData, setBankData] = useState();
  const [singleBankName, setSingleBankName] = useState();
  const [bankDetailData, setBankDetailData] = useState([]);
  const [bankLoading, setBankLoading] = useState(false);
  const [bankDetailLoading, setBankDetailLoading] = useState();
  const [accType, setAccType] = useState("All");
  const [filteredData, setFilteredData] = useState([]);
  const [totalData, setTotalData] = useState({ accType: "", credit: "", debit: "" });
  const [totalAvailData, setTotalAvailData] = useState("");

  const [searchName, setSearchName] = useState("")
  const [searchData, setSearchData] = useState([]);

  // getting bank names
  const getBankDetails = async () => {
    setBankLoading(true);
    const res = await HttpClient.requestData(isInvestor || isBookKeeper ? `investor/investor-plaid-account-get/${userCode}` : "plaid-account-get", "GET");
    if (res && res?.status) {
      // const filteredData = removeDuplicate(res?.data);
      // console.log("filteredDatadfgg", filteredData, res?.data)
      setBankData(res?.data);
      setSearchData(res?.data);
      const sum = res?.data?.reduce((cur, acc) => {
        return cur + acc?.balances?.available
      }, 0)
      setTotalAvailData(sum);
      setBankLoading(false);
    } else {
      setBankLoading(false);
    }
  }

  // getting bank transaction
  const getBankTramsaction = async () => {
    const data = {
      accessToken: singleBankName?.accessTokens,
      dayType: searchDate ? "date" : time,
      date: searchDate ? moment(searchDate).format() : ""
    }
    setBankDetailLoading(true)
    const res = await HttpClient.requestData("plaid-trasaction-get", "POST", data)
    console.log("resdsd", res)
    if (res && res?.status) {
      setBankDetailData(res);
      setBankDetailLoading(false);
    } else {
      setBankDetailLoading(false);
    }
  }

  // it removes duplicate from 
  const removeDuplicate = useCallback((arr) => {
    const unique = arr.filter((obj, index) => {
      return index === arr.findIndex(o => obj?.institutionData?.name === o?.institutionData?.name);
    });

    return unique
  }, [])

  // retuns credit amt(if amt is + returns amt or returns "-")
  const creditAmmount = useCallback((amt) => {
    if (amt > 0) {
      return "$" + amt
    } else {
      return "-"
    }
  }, [])

  // retuns Debit amt(if amt is - returns amt or returns "-")
  const debitAmmount = useCallback((amt) => {
    if (amt < 0) {
      return "$" + -amt
    } else {
      return "-"
    }
  }, [])

  // returns the sum of a key from array of object
  const sumFromArrayOfObject = (arr, key) => {
    const sum = arr.reduce((acc, cur) => acc + Number(cur[key]), 0);
    return sum ? sum : "";
  }

  // auto Sync
  const fetchAutoSync = async () => {
    const res = await HttpClient.requestData("plaid-auto-post", "GET");
    console.log("resSync", res)
    if (res && res?.status) {
      toast.success(language.auto_sync_success)
    } else {
      toast.error(res?.message || "Something Wrong")
    }
  }


  // ******************plaid get token start***********************************
  const [token, setToken] = useState(null);
  const [isIntegration, setIsintregation] = useState(false);

  const onSuccess = useCallback(async (publicToken) => {
    const sendData = {
      publicToken: publicToken
    }
    setIsLoading(true);
    const res = await HttpClient.requestData("update-bank-access-token", "POST", sendData)
    if (res && res?.status) {
      setIsLoading(false);
      setIsintregation(false);
      getBankDetails();
    } else {
      setIsLoading(false);
      setIsintregation(false);
    }
  }, [token]);

  const onExit = useCallback(() => {
    // navigate('/manager');
    // closeModal()
    setIsintregation(false);
  }, [])

  const config = {
    token,
    onSuccess,
    // onEvent
    onExit
  };

  const { open, ready } = usePlaidLink(config);

  const createLinkToken = React.useCallback(async () => {
    setIsLoading(true);
    const res = await HttpClient.requestData("create-link-token", "GET");
    if (res && res?.status) {
      setToken(res?.data?.link_token);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  }, [setToken]);


  useEffect(() => {
    if (isIntegration) {
      if (token == null) {
        // console.log("connectMy", ready)
        createLinkToken();
      }
      // if (ready) {
      open();
      // }
    }
  }, [token, ready, isIntegration]);

  // ******************plaid get token End***********************************


  // filter by account Type
  // useEffect(() => {
  //   // filter
  //   // if (accType === "All") {
  //   //   setFilteredData(bankDetailData?.data)
  //   // }
  //   // else {
  //   //   const arr = bankDetailData?.data?.filter((item) => item?.accountType === accType)
  //   //   setFilteredData(arr)

  //   //   const creditData = arr.filter((item, i) => item?.amount >= 0);
  //   //   setTotalData(prev => ({ ...prev, credit: sumFromArrayOfObject(creditData, "amount") }));

  //   //   const debitData = arr.filter((item, i) => item?.amount < 0)
  //   //   setTotalData(prev => ({ ...prev, debit: -sumFromArrayOfObject(debitData, "amount") }))

  //   //   setTotalData((prev) => ({ ...prev, accType: arr?.length }))
  //   // }


  //   // setTotalAvailData(sumFromArrayOfObject(bankData, "amount"))

  // }, [accType, bankDetailData])

  const handleSearch = (e) => {
    const name = e.target.value;
    setSearchName(name)
    if (name) {
      const filteredData = bankData?.filter(item => item?.institutionData?.name?.toLowerCase()?.includes(name?.toLowerCase()));
      // console.log("filteredDataDD", filteredData)
      setSearchData(filteredData);
      const sum = filteredData?.reduce((cur, acc) => {
        return cur + acc?.balances?.available
      }, 0)
      setTotalAvailData(sum);
    } else {
      setSearchData(bankData);
      const sum = bankData?.reduce((cur, acc) => {
        return cur + acc?.balances?.available
      }, 0)
      setTotalAvailData(sum);
    }
  }

  useEffect(() => {
    getBankDetails()
  }, [profileData, userCode]);

  // useEffect(() => {
  //   if (singleBankName) {
  //     getBankTramsaction();
  //   }
  // }, [singleBankName, time, searchDate])

  useEffect(() => {
    if (bankData && bankData?.[0]) {
      setSingleBankName(bankData?.[0]);
    }
  }, [bankData])


  return (
    <div
      onClick={() => {
        setIsBankNameDrop(false);
        setIsShowList(false);
      }}
    >


      {/* ***************** bank name dropdown******************** */}
      {/* <div>
        <div id="wrap">
          <div
            id="dropdown"
            onClick={(e) => {
              e.stopPropagation();
              setIsBankNameDrop(prev => !prev)
            }}
          >
            {language.select_bank_name}
            {
              isBankNameDrop &&
              <ul>
                {bankData?.map((item, i) =>
                  <li onClick={() => setSingleBankName(item)} key={i}>
                    <a href="#">
                      {item?.institutionData?.name}
                    </a>
                  </li>
                )
                }

                {bankLoading && <CustomLoaderLine />}
              </ul>
            }
          </div>
        </div>
      </div> */}
      {/* ***************** bank name dropdown end******************** */}

      {/* button group */}
      <div className="transacBtnGroup">
        {/* {
          !isInvestor ?
            <> */}
              <button onClick={() => setIsintregation(true)}>{language.connect_more_acc}</button>
              <button onClick={() => fetchAutoSync()}>{language.auto_sync_post_all}</button>
            {/* </>
            :
            null
        } */}

        <button onClick={() => goToSelectedSlice(isInvestor || isBookKeeper ? 5 : 6)}>View Transaction Dashboard</button>
        <button onClick={() => navigate(`/editexistingrevenue${isInvestor || isBookKeeper ? '?id='+ userCode : ''}`)}>View Transactions Detail</button>

      </div>


      {bankLoading &&
        <>
          <CustomLoaderLine />
          <p className="d-flex justify-content-center">{language.we_refresh_info}</p>
        </>
      }


      {singleBankName &&
        <div>
          {
            // <div className="clearFillDiv">

            //   {/* clear filter */}
            //   <button
            //     className="clearFilterBtn"
            //     onClick={() => {
            //       setSearchDate("");
            //       setAccType("All");
            //     }}
            //   >
            //     {language.clear_filters}
            //   </button>

            //   {/* three lines */}
            //   <button
            //     onClick={(e) => {
            //       e.stopPropagation();
            //       setIsShowList(prev => !prev);
            //     }}
            //     className="barScreenShotBtn"
            //   >
            //     <i class="fa-solid fa-bars"></i>
            //   </button>

            //   {/* dropdown part */}
            //   {isShowList &&
            //     <div className="plaid-manager-dropdown">
            //       <p onClick={() => {
            //         setIsintregation(true);
            //       }}
            //       >
            //         {language.connect_more_acc}
            //       </p>
            //       <p onClick={() => fetchAutoSync()}>{language.auto_sync_post_all}</p>
            //       <p onClick={() => goToSelectedSlice(1)}>View Transactions Dashboard</p>
            //       <p onClick={() => navigate('/editexistingrevenue')} > View Transactions Detail</p>
            //     </div>
            //   }
            // </div>
          }

          {
            // <div div className="dateSearDiv" >

            //   {/* Date search  */}
            //   <input
            //     type="date"
            //     className="dashSixDateInp"
            //     value={searchDate}
            //     onChange={(e) => setSearchDate(e.target.value)}
            //   />
            //   {/* <input
            //   type="search"
            //   placeholder={language.bank_name}
            //   className="dashSixSearchInp"
            //   value={bankName}
            //   onChange={(e) => handleSarch(e)}
            // /> */}


            //   {/* account filter */}
            //   <select
            //     className="dashSixDateInp"
            //     style={{ width: "183px", height: "36px" }}
            //     value={accType}
            //     onChange={(e) => setAccType(e.target.value)}
            //   >
            //     <option value={"All"}>{language.all}</option>
            //     {
            //       bankDetailData?.accounts?.map((item, i) =>
            //         <option key={i} value={item?.subtype}>{item?.subtype}</option>
            //       )
            //     }
            //   </select>
            // </div>
          }

          {/* <div className="totalCalInp">
            <p className="totalAvaText">{language.total_avail_cash}</p>
            <input type="text" value={!bankDetailLoading ? "$" + bankDetailData?.totalAmount : "..."} className="totalDashSixInp" />
          </div> */}


          {/* tablle */}
          {
            !bankDetailLoading
              ?
              <div className="dashSixTableDiv mb-5">

                {/*  Connect Account button */}
                <div className="d-flex justify-content-center mb-3" >

                  {/* search */}
                  <div>
                    <input
                      className="ml-1"
                      placeholder="Search"
                      onChange={(e) => handleSearch(e)}
                      value={searchName}
                    />
                  </div>

                  <div
                    className="ml-1"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setSearchData(bankData);
                      setSearchName("")
                    }}
                  >
                    Clear
                  </div>

                </div>

                <table className="dashSixTable">
                  <thead className="mb-4">
                    <th className="dashSixTableHead">{language.bank_name}</th>
                    {/* <th className="dashSixTableHead">{language.current_avail_balance}</th> */}
                    {/* <th className="dashSixTableHead">Marchent Name</th> */}
                    <th className="dashSixTableHead">{language.account_type}</th>
                    <th className="dashSixTableHead">{"Current Available Balance"}</th>
                    {/* <th className="dashSixTableHead">{language.current_debits}</th> */}
                    {/* <th className="dashSixTableHead">{language.crrent_credits}</th> */}
                  </thead>
                  <tbody>
                    {
                      searchData && searchData?.map((item, i) =>
                        <tr className="dashSixTableRow" key={i}>
                          <td className="dashSixTableData">{item?.institutionData?.name}</td>
                          {/* <td className="dashSixTableData">{item?.name}</td> */}
                          <td className="dashSixTableData">{item?.subtype}</td>
                          <td className="dashSixTableData">{item?.balances?.available}</td>
                          {/* <td className="dashSixTableData">{creditAmmount(item?.amount)}</td> */}
                        </tr>
                      )
                    }

                    {searchData?.length == 0 && <NoDataFound />}
                  </tbody><br />

                  {/* total */}
                  <tfoot className="dashSixTableFoot">
                    <tr className="">
                      <td className="dashSixTableConcludeData">{language.total?.toUpperCase()}:</td>
                      {/* <td className="dashSixTableConcludeData">-</td> */}
                      <td className="dashSixTableConcludeData">
                        {searchData?.length}
                      </td>
                      <td className="dashSixTableConcludeData">
                        {Number(totalAvailData)?.toFixed(2)}
                      </td>
                      {/* <td className="dashSixTableConcludeData">-</td> */}
                      {/* <td className="dashSixTableConcludeData">
                        ${accType === "All" ? bankDetailData?.total?.credits?.toFixed(2) : Number(totalData?.credit)?.toFixed(2)}
                      </td> */}
                    </tr>
                  </tfoot>
                </table>
              </div>
              :
              <>
                <CustomLoaderLine />
                <p className="d-flex justify-content-center">{language.we_refresh_info}</p>
              </>
          }

        </div>

      }
      {bankData?.length === 0 && <NoDataFound />}
    </div>

  );
}

export default DashboardChartSix;

