import React from 'react'
import Welcome2 from '../welcome2/Welcome2'

const BookkeeperWelcome = () => {
    return (
        <div>
            <Welcome2 />
        </div>
    )
}

export default BookkeeperWelcome
