import React, { useEffect, useState } from "react";
import HttpClient from "../../utils/HttpClient";
import moment from "moment";
import NoDataFound from "../../Component/NoDataFound";
import { useUserData } from "../../Providers/UserWrapper";
import toast from "react-hot-toast";
import { useLanguage } from "../../Providers/LanguageContext";
import { useNavigate } from "react-router-dom";

function BookKeperDocs() {
  const { setIsLoading } = useUserData()
  const { language } = useLanguage();
  const navigate = useNavigate()
  const [documentData, setDocumentData] = useState([]);
  const [allDocData, setAllDocData] = useState([]);

  console.log("allDocData", allDocData)


  // return attach, missing, pastDue
  const statusOfDoc = (date, file) => {
    const checkDate = moment(date).isAfter(moment().format("YYYY-MM-DD")) || moment(date).isSame(moment().format("YYYY-MM-DD"))
    if (date) {
      if (checkDate) {
        if (file) {
          return "Attached"
        } else {
          return "Missing"
        }
      } else {
        return "Past Due"
      }
    } else {
      return "-"
    }
  }

  // get doc data
  const getDocData = async () => {
    setIsLoading(true)
    const res = await HttpClient.requestData("get-user-expense-manage-document", "GET")
    setIsLoading(false)
    // console.log("resff", res?.data?.[0]?.Data)
    if (res && res?.status) {
      setDocumentData(res?.data?.[0]?.Data)
      setAllDocData(res?.data?.[0])
    }
  }


  // upload document
  const handleDocChange = async (e, item) => {
    // console.log("itemff", item)
    const file = e.target.files[0]
    // console.log("filesdd", file?.name)
    if (file) {
      const data = new FormData();
      data.append("pdfFile", file)
      setIsLoading(true)
      const res = await HttpClient.fileUplode("bookkeeper/upload-pdf", "POST", data);
      // console.log('imgres', res)

      if (res && res.status) {
        // handleChange({ target: { name: "fileUrl", value: res?.url } }, i)
        // handleChange({ target: { name: "fileName", value: file?.name } }, i)
        // setTaxDetData(prev => ({ ...prev, pdf: res?.url }))
        editDoc(item, res?.url, file?.name)
      } else {
        setIsLoading(false)
        toast.error(res?.message)
      }
    }
  }

  // edit documnt
  const editDoc = async (item, url, name) => {
    const data = {
      "docId": item?._id,
      "pdf": url,
      "pdfName": name
    }
    const res = await HttpClient.requestData("edit-user-expense-manage-document/" + allDocData?._id, "PUT", data)
    console.log("resdff", res)
    setIsLoading(false)
    if (res && res?.status) {
      getDocData()
      toast.success("Document Uploaded Successfully")
    } else {
      toast.error(res?.message || "wrong")
    }
  }

  useEffect(() => {
    getDocData();
  }, [])

  return (
    <section className="bookkDocUpSec">
      <div className="container">
        <div className='d-flex gap-2 mb-2' >
          <div className=''>
            <button className='w-100 btn btn-outline-success' style={{ fontWeight: "bold" }} onClick={() => navigate('/bookkeper')}>{language.add_virtual_bookkeper}</button>
          </div>

          <div className=''>
            <button className='w-100 btn btn-outline-success' style={{ fontWeight: "bold" }} onClick={() => navigate('/dashboard-manager')}>
              {language.view_tax_deduct}
            </button>
          </div>
        </div>
        <div className="bookkeMainHeadDiv">
          <span className="bookkeMainHead">
            Bookkeeper Manager - Upload & Manage Documents
          </span>
        </div>
        <div className="bookDocHeadDiv">
          <span className="bokkHead">Bookkeeper Document Assistant</span>
        </div>
        <div className="bookkTableDiv">
          <table className="bookkDocsTable">
            <thead>
              <tr>
                <th className="bookTabData">
                  <span className="bookHeadSpan">Document Type</span>
                </th>
                <th className="bookTabData">
                  <span className="bookHeadSpan">Upload Document</span>
                </th>
                <th className="bookTabData">
                  <span className="bookHeadSpan">Document Status</span>
                </th>
                <th className="bookTabData">
                  <span className="bookHeadSpan">Document Due Date</span>
                </th>
                <th className="bookTabData">
                  <span className="bookHeadSpan">View File</span>
                </th>
                <th className="bookTabData">
                  <span className="bookHeadSpan">View Example File</span>
                </th>
              </tr>
            </thead>
            <tbody>

              {
                documentData?.map((item, i) => {
                  const checkDate = moment(item?.dueDate).isAfter(moment().format())
                  // console.log("dattte", checkDate)
                  return (
                    <tr key={i}>
                      <td className="bookTabData">
                        <span className="bokkAcc">{item?.documentType}</span>
                      </td>
                      <td className="bookTabData" style={{ position: "relative" }}>
                        <button className="bookkBrowBtn">BROWSE</button>
                        <input
                          className="docInpBookUser"
                          type="file"
                          onChange={(e) => handleDocChange(e, item)}
                        />
                      </td>
                      <td className="bookTabData">
                        {
                          statusOfDoc(item?.dueDate, item?.pdf) === "Attached"
                          && <span className='text-success'>Attached</span>
                        }
                        {
                          statusOfDoc(item?.dueDate, item?.pdf) === "Missing"
                          && <span className='text-primary'>Missing</span>
                        }
                        {
                          statusOfDoc(item?.dueDate, item?.pdf) === "Past Due"
                          && <span className='text-danger'>Past Due</span>
                        }

                        {
                          statusOfDoc(item?.dueDate, item?.pdf) === "-"
                          && <div className='text-dark'>-</div>
                        }

                        {
                          item?.pdf &&
                          <span
                            className='ml-1'
                            style={{ cursor: "pointer", color: "#0056b3" }}
                            onClick={() => {
                              editDoc(item, "", "")
                            }}
                          >
                            (remove)
                          </span>
                        }

                        {/* <span className="bookAttSpan">
                          {
                            item?.pdf === ""
                              ?
                              <span>Missing</span>
                              :
                              <div>
                                <span className="mr-1">Attached</span>
                                (<button className="bookAttBtn"> remove </button>)
                              </div>
                          }

                          {checkDate ? "" : <span className="text-danger ml-1 accordion">(Past Due)</span>}
                          { }

                        </span> */}
                      </td>
                      <td className="bookTabData">
                        <span className="bookkDate">{moment(item?.dueDate).format("LL")}</span>
                      </td>
                      <td className="bookTabData">
                        {/* <button
                          className="bookFileBtn"
                          onClick={() => {
                            window.open(item?.pdf, "_blank")
                          }}
                        >
                          {item?.pdfName}
                        </button> */}
                        <div>
                          <a href={item?.pdf} target='_blank' className='buildCapMidlink'>{item?.pdfName}</a>

                        </div>
                      </td>

                      <td>
                        <div>
                          <a href={item?.pdf1} target='_blank' className='buildCapMidlink'>{item?.pdfName1}</a>

                        </div>
                      </td>
                    </tr>
                  )
                }
                )
              }

            </tbody>
          </table>

          {documentData?.length === 0 && <NoDataFound />}

        </div>
      </div>
    </section>
  );
}

export default BookKeperDocs;
