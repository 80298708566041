import React, { useEffect, useState } from 'react'
import { useLanguage } from '../../../Providers/LanguageContext';
import moment from 'moment';
import HttpClient from '../../../utils/HttpClient';
import { data } from 'jquery';
import { useUserData } from '../../../Providers/UserWrapper';
import toast from 'react-hot-toast';

const InvOptionProfileModal = ({ closeModal, singleCapitalData, getLinkedAccData }) => {
    const { language, currentLanguage } = useLanguage();
    const { setIsLoading } = useUserData()

    console.log("singleCapitalDatadd", singleCapitalData)

    const initValue = {
        indiCorp: "",
        date: moment().format("YYYY-MM-DD"),
        corpType: "",
        industryType: "",
        monthlyRevenue: "",
        monthlyPayable: "",
        monthSuppInvoice: "",
        bankAcc: "",
        customerContract: "",
        supplierContract: "",
        loanAgreement: "",
        employeeNumber: "",
        contractorNumber: "",
        allServices: [],
        requestedAmt: "",
        existingAmt: "",
        totalAmt: ""
    }
    const [formValue, setFormValue] = useState(initValue);

    // handle Accept
    const handleAccept = async (status) => {
        const data = {
            "offerStatus": status //"accepted" //"cancelled"
        }
        setIsLoading(true)
        const res = await HttpClient.requestData("investor/update-risecapital-offerstatus/" + singleCapitalData?._id, "PUT", data)
        setIsLoading(false)
        if (res && res?.status) {
            if ("accepted") {
                toast.success("Raise Capital Accepted Successfully!")
            } else {
                toast.success("Raise Capital Rejected Successfully!")
            }
            getLinkedAccData();
            closeModal(false);
        } else {
            toast.error(res?.message || "Error")
        }
    }

    useEffect(() => {
        setFormValue(prev => ({
            ...prev,
            indiCorp: singleCapitalData.companyType,
            date: moment(singleCapitalData.dateOfIncorporation).format("YYYY-MM-DD"),
            corpType: singleCapitalData.corporationType,
            industryType: singleCapitalData.selectIndustryType,
            monthlyRevenue: singleCapitalData.monthlyRevenue,
            monthlyPayable: singleCapitalData.monthlyPayables,
            monthSuppInvoice: singleCapitalData.monthlySupplierInvoices,
            bankAcc: singleCapitalData.selectofBankAccounts,
            customerContract: singleCapitalData.customerContracts,
            supplierContract: singleCapitalData.supplierContracts,
            loanAgreement: singleCapitalData.loanAgreements,
            employeeNumber: singleCapitalData.employees,
            contractorNumber: singleCapitalData.contractors,
            allServices: singleCapitalData.considerEquityType,
            requestedAmt: singleCapitalData.reqAndDebtAmmount,
            existingAmt: singleCapitalData.existingDebtAndEquity,
            totalAmt: singleCapitalData.totalAmmount
        }))
    }, [])


    return (
        <div>
            <div id="myModal" class="modal-ivnProfile">

                <div class="modal-content-ivnProfile">
                    <span class="close-ivnProfile" onClick={() => closeModal(false)}>&times;</span>
                    <div className="customBookkeDiv">
                        <p className="custBookkHead">
                            {singleCapitalData.capitalType}:
                        </p>

                        {/* radio and date part */}
                        <div className="custTypeDiv">
                            <div className="custinnerTypeDiv">

                                <div className="custIndDiv">
                                    <input
                                        type="radio"
                                        id="custTyInd"
                                        className="custTyInp"
                                        checked={formValue.indiCorp === "Individual"}
                                        name="indiCorp"
                                        value="Individual"
                                        //onChange={(e) => handleCustomChange(e)}
                                        readOnly
                                    />
                                    <label htmlFor="custTyInd" className="custInpLabel">
                                        Individual
                                    </label>
                                </div>
                                <div className="custCorDiv">
                                    <input
                                        type="radio"
                                        id="custTyCor"
                                        className="custTyInp"
                                        checked={formValue.indiCorp === "Corporation"}
                                        name="indiCorp"
                                        value="Corporation"
                                        //onChange={(e) => handleCustomChange(e)}
                                        readOnly
                                    />
                                    <label htmlFor="custTyCor" className="custInpLabel">
                                        Corporation
                                    </label>
                                </div>
                            </div>

                            <div className="custBkkDateLabDiv">
                                <label className="custInpDateLabel">
                                    Date of Incorporation
                                </label>
                                <input
                                    type="date"
                                    className="custDateInp"
                                    name="date"
                                    value={formValue.date}
                                    //onChange={(e) => handleCustomChange(e)}
                                    readOnly
                                />
                            </div>

                        </div>

                        {/* select  */}
                        <div
                            className="row row-cols-3 justify-content-between"
                            style={{ width: "100%", margin: "0 auto" }}
                        >
                            <div className='custSelecInp border'>{language.Corporation_Type}: {formValue.corpType}</div>
                            <div className='custSelecInp border'>{language.Select_Industry_Type}: {formValue.industryType}</div>
                            <div className='custSelecInp border'>{language.Monthly_Revenue}: {formValue.monthlyRevenue}</div>
                            <div className='custSelecInp border'>{language.Monthly_Payables}: {formValue.monthlyPayable}</div>
                            <div className='custSelecInp border'>{language.Monthly_Supplier_Invoices}: {formValue.monthSuppInvoice}</div>
                            <div className='custSelecInp border'>{language.Select_Bank_Accounts}: {formValue.bankAcc}</div>
                            <div className='custSelecInp border'>{language.Customer_Contracts}: {formValue.customerContract}</div>
                            <div className='custSelecInp border'>{language.Supplier_Contracts}: {formValue.supplierContract}</div>
                            <div className='custSelecInp border'>{language.Loan_Agreements}: {formValue.loanAgreement}</div>
                            <div className='custSelecInp border'>{language.No_of_Employees}: {formValue.employeeNumber}</div>
                            <div className='custSelecInp border'>{language.No_of_Contractors}: {formValue.contractorNumber}</div>

                        </div>

                        {/* All services checkbox */}
                        <div className="selecAllServDiv mt-3">
                            {/* <p className="custSelectHead">
                                Select All Services You Would Like to Have
                            </p> */}
                            {
                                singleCapitalData?.capitalType === "Debt Capital Raise"
                                    ?
                                    <div className="selectAllOpDiv">
                                        {/* <div className="seleAllBtnDiv">
                                    {
                                        isAllSelect
                                            ?
                                            <button
                                                className="selecAllBtnBkk"
                                                onClick={() => {
                                                    setFormValue(prev => ({ ...prev, allServices: [] }))
                                                    setIsAllSelect(false)
                                                }
                                                }
                                            >
                                                Deselect All
                                            </button>
                                            :
                                            <button
                                                className="selecAllBtnBkk"
                                            // onClick={() => selectAllClick()}
                                            >
                                                Select All
                                            </button>
                                    }
                                </div> */}
                                        <div className="row row-cols-2">

                                            <div className="custBkkLabDiv">
                                                <input
                                                    type="checkbox"
                                                    checked={formValue?.allServices?.indexOf("Long-Term Loan") !== -1}
                                                    name="allServices"
                                                    value="Long-Term Loan"
                                                    //onChange={(e) => handleServiceChange(e)}
                                                    readOnly
                                                />
                                                <label className="custBkkLabel">Long-Term Loan</label>
                                            </div>

                                            <div className="custBkkLabDiv">
                                                <input
                                                    type="checkbox"
                                                    checked={formValue?.allServices?.indexOf("Short-Term Loan") !== -1}
                                                    name="allServices"
                                                    value="Short-Term Loan"
                                                    //onChange={(e) => handleServiceChange(e)}
                                                    readOnly
                                                />
                                                <label className="custBkkLabel">
                                                    Short-Term Loan
                                                </label>
                                            </div>

                                            <div className="custBkkLabDiv">
                                                <input
                                                    type="checkbox"
                                                    checked={formValue?.allServices?.indexOf("AR Factoring") !== -1}
                                                    name="allServices"
                                                    value="AR Factoring"
                                                    //onChange={(e) => handleServiceChange(e)}
                                                    readOnly
                                                />
                                                <label className="custBkkLabel">
                                                    AR Factoring
                                                </label>
                                            </div>

                                            <div className="custBkkLabDiv">
                                                <input
                                                    type="checkbox"
                                                    checked={formValue?.allServices?.indexOf("Mezzanine") !== -1}
                                                    name="allServices"
                                                    value="Mezzanine"
                                                    //onChange={(e) => handleServiceChange(e)}
                                                    readOnly
                                                />
                                                <label className="custBkkLabel">
                                                    Mezzanine
                                                </label>
                                            </div>

                                            <div className="custBkkLabDiv">
                                                <input
                                                    type="checkbox"
                                                    checked={formValue?.allServices?.indexOf("Merchant Services") !== -1}
                                                    name="allServices"
                                                    value="Merchant Services"
                                                    //onChange={(e) => handleServiceChange(e)}
                                                    readOnly
                                                />
                                                <label className="custBkkLabel">
                                                    Merchant Services
                                                </label>
                                            </div>

                                            <div className="custBkkLabDiv">
                                                <input
                                                    type="checkbox"
                                                    checked={formValue?.allServices?.indexOf("Accounts Payable Services") !== -1}
                                                    name="allServices"
                                                    value="Accounts Payable Services"
                                                    //onChange={(e) => handleServiceChange(e)}
                                                    readOnly
                                                />
                                                <label className="custBkkLabel">
                                                    Accounts Payable Services
                                                </label>
                                            </div>

                                        </div>
                                    </div>
                                    :
                                    <div className="selectAllOpDiv">
                                        <div className="row row-cols-2">

                                            <div className="custBkkLabDiv">
                                                <input
                                                    type="checkbox"
                                                    checked={formValue?.allServices?.indexOf("Minority Raise") !== -1}
                                                    name="allServices"
                                                    value="Minority Raise"
                                                    // onChange={(e) => handleServiceChange(e)}
                                                    readOnly
                                                />
                                                <label className="custBkkLabel">Minority Raise</label>
                                            </div>

                                            <div className="custBkkLabDiv">
                                                <input
                                                    type="checkbox"
                                                    checked={formValue?.allServices?.indexOf("Majority Raise") !== -1}
                                                    name="allServices"
                                                    value="Majority Raise"
                                                    // onChange={(e) => handleServiceChange(e)}
                                                    readOnly
                                                />
                                                <label className="custBkkLabel">
                                                    Majority Raise
                                                </label>
                                            </div>

                                            <div className="custBkkLabDiv">
                                                <input
                                                    type="checkbox"
                                                    checked={formValue?.allServices?.indexOf("Buyout") !== -1}
                                                    name="allServices"
                                                    value="Buyout"
                                                    // onChange={(e) => handleServiceChange(e)}
                                                    readOnly
                                                />
                                                <label className="custBkkLabel">
                                                    Buyout
                                                </label>
                                            </div>

                                            <div className="custBkkLabDiv">
                                                <input
                                                    type="checkbox"
                                                    checked={formValue?.allServices?.indexOf("Common Stock") !== -1}
                                                    name="allServices"
                                                    value="Common Stock"
                                                    // onChange={(e) => handleServiceChange(e)}
                                                    readOnly
                                                />
                                                <label className="custBkkLabel">
                                                    Common Stock
                                                </label>
                                            </div>

                                            <div className="custBkkLabDiv">
                                                <input
                                                    type="checkbox"
                                                    checked={formValue?.allServices?.indexOf("Preferred Stock") !== -1}
                                                    name="allServices"
                                                    value="Preferred Stock"
                                                    // onChange={(e) => handleServiceChange(e)}
                                                    readOnly
                                                />
                                                <label className="custBkkLabel">
                                                    Preferred Stock
                                                </label>
                                            </div>

                                            <div className="custBkkLabDiv">
                                                <input
                                                    type="checkbox"
                                                    checked={formValue?.allServices?.indexOf("Revenue Share") !== -1}
                                                    name="allServices"
                                                    value="Revenue Share"
                                                    // onChange={(e) => handleServiceChange(e)}
                                                    readOnly
                                                />
                                                <label className="custBkkLabel">
                                                    Revenue Share
                                                </label>
                                            </div>

                                        </div>
                                    </div>
                            }
                        </div>

                        {/* prices  */}
                        <div className="allPlnsDiv">
                            <div className="allPlnsInnerDiv">
                                <label className="allPlnLable">Requested Debt Amount:</label>
                                <div className="allInpSpanMainDiv">
                                    <input
                                        className="allPlnInp"
                                        type="text"
                                        name="requestedAmt"
                                        value={formValue.requestedAmt}
                                    //onChange={(e) => handleCustomChange(e)}
                                    />
                                    <span className="allPlnsSpan">USD</span>
                                </div>
                            </div>
                            <div className="allPlnsInnerDiv">
                                <label className="allPlnLable">Existing Debt:</label>
                                <div className="allInpSpanMainDiv">
                                    <input
                                        type="text"
                                        className="allPlnInp"
                                        name="existingAmt"
                                        value={formValue.existingAmt}
                                        //onChange={(e) => handleCustomChange(e)}
                                        readOnly
                                    />
                                    <span className="allPlnsSpan">USD</span>
                                </div>
                            </div>
                            <div className="allPlnsInnerDiv">
                                <label className="allPlnLable">Current Monthly Debt Payment:</label>
                                <div className="allInpSpanMainDiv">
                                    <input
                                        type="text"
                                        className="allPlnInp"
                                        name="totalAmt"
                                        value={formValue.totalAmt}
                                        //onChange={(e) => handleCustomChange(e)}
                                        readOnly
                                    />
                                    <span className="allPlnsSpan">USD</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* button group */}
                    {/* <div>
                        {
                            singleCapitalData?.offerStatus === "noAction"
                            &&
                            <div className="linkBookGDiv">
                                <button
                                    className="bookkPayNowBtn mr-1"
                                    onClick={() => handleAccept("accepted")}
                                >
                                    Accept
                                </button>

                                <button
                                    className="bookkPayNowBtn bg-danger "
                                    onClick={() => handleAccept("cancelled")}
                                >
                                    Reject
                                </button>
                            </div>
                        }
                        {
                            singleCapitalData?.offerStatus === "accepted"
                            &&
                            <div className='text-success d-flex justify-content-center'>Accepted</div>
                        }
                        {
                            singleCapitalData?.offerStatus === "cancelled"
                            &&
                            <div className='text-danger d-flex justify-content-center'>Rejected</div>
                        }
                    </div> */}

                </div>

            </div>
        </div>
    )
}

export default InvOptionProfileModal
