import React, { useEffect, useState } from 'react'
import HttpClient from '../../utils/HttpClient';
import { useLanguage } from '../../Providers/LanguageContext';

const ViewFinancialReport = ({ setBuildRepData }) => {
    const { language, currentLanguage } = useLanguage();

    const assetData = {
        title: "",
        price: ""
    }

    const liabilityData = {
        title: "",
        price: "",
    }

    const [ownerData, setOwnerData] = useState([assetData]);
    const [childData, setChildData] = useState([liabilityData]);
    const [totalAssets, setTotalAssets] = useState("")
    const [totalLiabilities, setTotalLiabilities] = useState("")
    const [netWorth, setNetWorth] = useState("")

    // >>>>Get Financial Report<<<<
    const getFinancialReport = async () => {
        // setSaveLoading(true)
        const res = await HttpClient.requestData('reportbuilder-view-financialreport', 'GET');
        if (res && res?.status) {
            // setSaveLoading(false);
            setOwnerData(res?.data?.assets)
            setChildData(res?.data?.liabilities)
            setTotalAssets(res?.data?.Total_assets)
            setTotalLiabilities(res?.data?.Total_liabilities)
            setNetWorth(res?.data?.Net_worth)
        } else {
            setTotalAssets()
            setTotalLiabilities()
            setNetWorth()
        }
    }

    useEffect(() => {
        getFinancialReport()
    }, [])
    return (
        <div className='container'>
            <div className='buildIncmStTableDiv'>
                <table className="buildIncmStTable">
                    <tbody>

                        {/****table Head***/}
                        <tr>
                            <th className="buildIncmStTh12 buildIncmStTh12First">
                                <span>{language.assets}</span>
                                </th>
                            <th className="buildIncmStTh12">
                                <span>{language.amount_dollars}</span>
                            </th>
                        </tr>

                        {/****Data**/}
                        {ownerData?.map((item, i) => {
                            return (
                                <tr key={i}>
                                    <td className="buildIncmStTd">
                                        <input type="text"
                                            className="userBuildInp"
                                            name="title"
                                            value={item?.title}
                                            defaultValue="Cash – checking accounts" />
                                    </td>
                                    <td className="buildIncmStTd">
                                        <input type="number"
                                            name="price"
                                            value={item?.price}
                                            defaultValue=""
                                            placeholder='$0.00' />
                                    </td>
                                </tr>
                            )
                        })
                        }

                        {/*****border**/}
                        <tr>
                            <td />
                            <td colSpan={1}>
                                <div className="buildTotDivider" />
                            </td>
                        </tr>

                        {/***Sub Total**/}
                        <tr>
                            <td className="buildIncmStTd">
                                <span className="buildTotSpan">{language.total_assets}</span>
                            </td>
                            <td className="buildIncmStTd">
                                <input type="number" readOnly="" placeholder='$0.00'
                                    value={totalAssets}
                                />
                            </td>
                        </tr>



                        {/****table Head***/}
                        <tr>
                            <th className="buildIncmStTh12 buildIncmStTh12First">
                            </th>
                            <th className="buildIncmStTh12">
                                <span>{language.amount_dollars}</span>
                            </th>
                        </tr>

                        {/****Data**/}
                        {childData?.map((item, i) => {
                            return (
                                <tr key={i}>
                                    <td className="buildIncmStTd">
                                        <input type="text"
                                            name="title"
                                            value={item?.title}
                                            className="userBuildInp"
                                            defaultValue="Current Debt (Credit cards, Accounts)" />
                                    </td>
                                    <td className="buildIncmStTd">
                                        <input type="number"
                                            name="price"
                                            value={item?.price}
                                            defaultValue=""
                                            placeholder='$0.00' />
                                    </td>
                                </tr>
                            )
                        })
                        }


                        {/*****border**/}
                        <tr>
                            <td />
                            <td colSpan={1}>
                                <div className="buildTotDivider" />
                            </td>
                        </tr>

                        {/***Sub Total**/}
                        <tr>
                            <td className="buildIncmStTd">
                                <span className="buildTotSpan">{language.total_liabilities}</span>
                            </td>
                            <td className="buildIncmStTd">
                                <input type="number" readOnly=""
                                    value={totalLiabilities}
                                    placeholder='$0.00' />
                            </td>
                        </tr>

                        {/***NET WORTH**/}
                        <tr>
                            <td className="buildIncmStTd">
                                <span className="buildTotSpan">{language.net_worth}</span>
                            </td>
                            <td className="buildIncmStTd">
                                <input type="number" readOnly=""
                                    value={netWorth}
                                    placeholder='$0.00' />
                            </td>
                        </tr>

                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default ViewFinancialReport