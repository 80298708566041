import React, { useEffect, useState } from 'react'
import "react-datetime/css/react-datetime.css";
import Datetime from "react-datetime";
import moment from 'moment';
import { toast } from 'react-hot-toast';
import Swal from 'sweetalert2';
import { useLanguage } from '../../Providers/LanguageContext';
import HttpClient from '../../utils/HttpClient';

function CustomDateCompIncome({ defaultval, setDate, id, setData, data, fetchTranData, singleData, getTransactionData,setCurrPage, currPage }) {

    console.log('singleDatacustinc', singleData)
    const { language } = useLanguage();

    const [editEnabled, setEditEnabled] = useState(false)

    const [dateVal, setDateVal] = useState(moment(new Date()).format("YYYY-MM-DD"))
    const [isSaved, setIsSaved] = useState(false)
    const handleEdit = () => {
        setEditEnabled(prev => !prev);
    }

    const handleDate = (value) => {
        const formatted = moment(value.target.value).format("YYYY-MM-DD");
        setDate(formatted)
    }

    useEffect(() => {
        console.log(defaultval, "default value")
        if (defaultval) {
            // console.log("defaultvall",moment(defaultval).utc().format("YYYY-MM-DD"))
            // setDateVal(moment(defaultval).utc().format("YYYY-MM-DD"))
            setDateVal(moment(defaultval).format("YYYY-MM-DD"))
        }
    }, [defaultval])

    const handleDelete = async () => {
        Swal.fire({
            title: singleData.customerName ? language.sales_expense : language.delete_expense,
            showCancelButton: true,
            confirmButtonText: language.delete,
            cancelButtonText: language.cancel
        }).then(async (result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                if (singleData.customerName) {
                    const res = await HttpClient.requestData("delete-sales/" + singleData._id, "DELETE");
                    if (res && res.status) {
                        toast.success(language.sales_delete)
                        // getTransactionData(data.transactionFilter, data.email)
                        fetchTranData();
                        if (currPage !== 0) {
                            setCurrPage(prev => --prev)
                        } else {
                            setCurrPage(0)
                        }
                    } else {
                        toast.error(language.sales_delete_error)
                    }
                } else {
                    const res = await HttpClient.requestData("expense-delete/" + singleData._id, "DELETE");
                    if (res && res.status) {
                        toast.success(language.expense_delete)
                        // getTransactionData(data.transactionFilter, data.email)
                        fetchTranData();
                        if (currPage !== 0) {
                            setCurrPage(prev => --prev)
                        } else {
                            setCurrPage(0)
                        }
                    } else {
                        toast.error(language.expense_delete_error)
                    }
                }
            } else if (result.isDenied) {
                Swal.fire(language.expense_delete_cancelled)
            }
        })

    }


    const handleSave = () => {
        setIsSaved(true)
    }

    const handleEditDate = async () => {
        if (singleData.customerName) {
            const res = await HttpClient.requestData("update-sales/" +singleData._id, "PUT", { transactionDate: dateVal });
            if (res && res.status) {
                toast.success(language.sales_update);
                // getTransactionData(data.transactionFilter, data.email)
                fetchTranData()
                console.log(res)
            } else {
                toast.error("Error updating expense")
            }
        } else {
            const res = await HttpClient.requestData("update-expense/" +singleData._id, "PUT", { transactionDate: dateVal });
            if (res && res.status) {
                toast.success(language.expense_updated);
                // getTransactionData(data.transactionFilter, data.email)
                fetchTranData();
                console.log(res)
            } else {
                toast.error("Error updating expense")
            }
        }
    }



    return (
        <div className='mt-3' style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "8px" }}>
            {/* <Datetime inputProps={{ disabled: !editEnabled }}
                    onChange={handleDate}
                    value={dateVal}
                     /> */}
            <div className="td-details mb-2" style={{
                display: "flex",
                justifyContent: "center", alignItems: "center",
                gap: "8px", width: "50%", background: "lightgray", padding: "10px"
            }}>
                <div className='td-top'>
                    <label htmlFor="inputdate" style={{ marginRight: "0", fontSize: "25px", width: "185px", textAlign: "left" }}>
                        {language.date}
                    </label>
                    <input
                        type="date"
                        value={dateVal}
                        id="inputdate"
                        disabled={!editEnabled}
                        onChange={handleDate} />
                </div>
                <div className='td-buttons'>
                    <button className='customBtn10' style={{ marginRight: "10px" }} onClick={handleEdit}>{editEnabled ? language.disable_edit : language.edit}</button>
                    <button className='btn btn-danger' style={{ marginRight: "10px" }} onClick={handleDelete}>{language.delete}</button>
                    <button className='customBtn10' style={{ marginRight: "10px" }} onClick={handleSave}>{language.save}</button>
                    <button className='customBtn10' onClick={handleEditDate} disabled={!isSaved}>{language.post}</button>

                </div>

            </div>
        </div>
    )
}

export default CustomDateCompIncome