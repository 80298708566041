import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
// import { useLanguage } from "../../Providers/LanguageContext";
// import { useUserData } from "../../Providers/UserWrapper";
// import HttpClient from "../../utils/HttpClient";
import toast from "react-hot-toast";
import { useLanguage } from "../../../Providers/LanguageContext";
import { useUserData } from "../../../Providers/UserWrapper";
import HttpClient from "../../../utils/HttpClient";
import moment from "moment";

function RaiseCapital() {
    const { language, currentLanguage } = useLanguage();
    const navigate = useNavigate()
    const { isMainUserMemo, profileData, setIsLoading } = useUserData()

    const initValue = {
        indiCorp: "",
        date: moment().format("YYYY-MM-DD"),
        corpType: "",
        industryType: "",
        monthlyRevenue: "",
        monthlyPayable: "",
        monthSuppInvoice: "",
        bankAcc: "",
        customerContract: "",
        supplierContract: "",
        loanAgreement: "",
        employeeNumber: "",
        contractorNumber: "",
        allServices: [],
        requestedAmt: "",
        existingAmt: "",
        totalAmt: ""
    }
    const [formValue, setFormValue] = useState(initValue);
    const [planName, setPlanName] = useState("Debt");
    const [isAllSelect, setIsAllSelect] = useState(false)


    console.log("planNamedd", formValue)

    // handle custom change
    const handleCustomChange = (e) => {
        const { value, name } = e.target;
        setFormValue(prev => ({ ...prev, [name]: value }))
    }

    // handle service change
    const handleServiceChange = (e) => {
        const { name, value } = e.target;
        let arr = formValue.allServices;
        if (arr.indexOf(value) !== -1) {
            arr.splice(arr.indexOf(value), 1)
        } else {
            arr.push(value)
        }
        setFormValue(prev => ({ ...prev, allServices: arr }))
        // console.log("fggcc", arr)
    }

    const selectAllClick = () => {
        const arr = [
            "Long-Term Loan",
            "AR Factoring",
            "Merchant Services",
            "Short-Term Loan",
            "Mezzanine",
            "Accounts Payable Services"
        ]
        setFormValue(prev => ({ ...prev, allServices: arr }));
        setIsAllSelect(true)
    }

    const selectAllClick2 = () => {
        const arr = [
            "Minority Raise",
            "Buyout",
            "Preferred Stock",
            "Majority Raise",
            "Common Stock",
            "Revenue Share"
        ]
        setFormValue(prev => ({ ...prev, allServices: arr }));
        setIsAllSelect(true)
    }

    // handle pay
    const handleBasicPay = async () => {
        // if (isMainUserMemo) {
        const data = {
            "capitalType": planName === "Debt" ? "Debt Capital Raise" : "Equity Capital Raise",
            "companyType": formValue.indiCorp,
            "dateOfIncorporation": formValue.date,
            "considerEquityType": formValue.allServices,
            "reqAndDebtAmmount": formValue.requestedAmt,
            "existingDebtAndEquity": formValue.existingAmt,
            "totalAmmount": formValue.totalAmt,
            "corporationType": formValue.corpType,
            "selectIndustryType": formValue.industryType,
            "monthlyRevenue": formValue.monthlyRevenue,
            "monthlyPayables": formValue.monthlyPayable,
            "monthlySupplierInvoices": formValue.monthSuppInvoice,
            "selectofBankAccounts": formValue.bankAcc,
            "customerContracts": formValue.customerContract,
            "supplierContracts": formValue.supplierContract,
            "loanAgreements": formValue.loanAgreement,
            "employees": formValue.employeeNumber,
            "contractors": formValue.contractorNumber,
        }
        setIsLoading(true)
        const res = await HttpClient.requestData("add-raise-capital-by-user", "POST", data)
        // console.log("resbb", res?.data?.url)
        setIsLoading(false)
        if (res && res?.status) {
            // window.open(res?.data?.url)
            toast.success("Raise Capital Published Successfully!")
        } else {
            toast.error(res?.message || "Error")
        }
        //     } else {
        //         toast.error("Only Owner Can Pay")
        //     }
    }

    const handleReqQuote = async () => {
        const data = {
            "email": "pallabmondal185@gmail.com",
            "date": formValue.date,
            "corporetionType": formValue.corpType,
            "select_industry_type": formValue.industryType,
            "monthly_type": formValue.monthlyRevenue,
            "monthly_paylable": formValue.monthlyPayable,
            "back_account": formValue.bankAcc,
            "customer_contracts": formValue.customerContract,
            "loan_aggrements": formValue.loanAgreement,
            "supplier_contracts": formValue.supplierContract,
            "monthly_supplier_invoice": formValue.monthSuppInvoice,
            "numberOfEmployee": formValue.employeeNumber,
            "numberOfContractor": formValue.contractorNumber,
            "likeService": formValue.allServices

        }
        setIsLoading(true)
        const res = await HttpClient.requestData("add-customPlan-bookkeeper", "POST", data);
        setIsLoading(false)
        console.log("essmm", res)
        if (res) {
            toast.success("Request Quote Send Successfully");
            setFormValue(initValue)
        } else {
            // toast.error(res.message || "Error")
        }
    }

    // ddd

    return (
        <>
            <section className="bookkeperSection">
                <div className="container">
                    <div className='d-flex gap-2 mb-2'>
                        <div className=''>
                            <button className='w-100 btn btn-outline-success' style={{ fontWeight: "bold" }} onClick={() => navigate('/view-investor-document')}>{language.view_upload_docs}</button>
                        </div>

                        <div className=''>
                            <button className='w-100 btn btn-outline-success' style={{ fontWeight: "bold" }} onClick={() => navigate('/view-capital-option')}>
                                {language.view_capital_opt}
                            </button>
                        </div>
                    </div>
                    <div className="bookkeMainHeadDiv">
                        <span className="bookkeMainHead">Investment Manager – Raise Capital</span>
                    </div>
                    <Tabs className="bookeperPlanTab">
                        <TabList>
                            <Tab onClick={() => setPlanName("Debt")}>
                                <div className="bookkeTabDiv">
                                    <label className="bookkPlan">Debt Capital Raise</label>
                                </div>
                            </Tab>
                            <Tab onClick={() => setPlanName("Equity")}>
                                <div className="bookkeTabDiv">
                                    <label className="bookkPlan">Equity Capital Raise</label>
                                </div>
                            </Tab>
                        </TabList>


                        {/* Debt Capital Raise */}
                        <TabPanel>
                            <div className="customBookkeDiv">
                                <p className="custBookkHead">
                                    Debt Capital Raise:
                                </p>

                                {/* radio and date part */}
                                <div className="custTypeDiv">
                                    <div className="custinnerTypeDiv">

                                        <div className="custIndDiv">
                                            <input
                                                type="radio"
                                                id="custTyInd"
                                                className="custTyInp"
                                                checked={formValue.indiCorp === "Individual"}
                                                name="indiCorp"
                                                value="Individual"
                                                onChange={(e) => handleCustomChange(e)}
                                            />
                                            <label htmlFor="custTyInd" className="custInpLabel">
                                                Individual
                                            </label>
                                        </div>
                                        <div className="custCorDiv">
                                            <input
                                                type="radio"
                                                id="custTyCor"
                                                className="custTyInp"
                                                checked={formValue.indiCorp === "Corporation"}
                                                name="indiCorp"
                                                value="Corporation"
                                                onChange={(e) => handleCustomChange(e)}
                                            />
                                            <label htmlFor="custTyCor" className="custInpLabel">
                                                Corporation
                                            </label>
                                        </div>
                                    </div>

                                    <div className="custBkkDateLabDiv">
                                        <label className="custInpDateLabel">
                                            Date of Incorporation
                                        </label>
                                        <input
                                            type="date"
                                            className="custDateInp"
                                            name="date"
                                            value={formValue.date}
                                            onChange={(e) => handleCustomChange(e)}
                                        />
                                    </div>

                                </div>

                                {/* select  */}
                                <div
                                    className="row row-cols-3 justify-content-between"
                                    style={{ width: "100%", margin: "0 auto" }}
                                >
                                    {/* Corporation Type */}
                                    <select
                                        className="custSelecInp"
                                        name="corpType"
                                        value={formValue.corpType}
                                        onChange={(e) => handleCustomChange(e)}
                                    >
                                        <option value="" disabled>{language.Corporation_Type}</option>
                                        <option value="Sole Proprietorship">Sole Proprietorship</option>
                                        <option value="Partnership">Partnership</option>
                                        <option value="LLC.">LLC.</option>
                                        <option value="S Corp.">S Corp.</option>
                                        <option value="C Corp.">C Corp.</option>
                                        <option value="Nonprofit">Nonprofit</option>
                                    </select>

                                    {/* Select Industry Type */}
                                    <select
                                        className="custSelecInp"
                                        name="industryType"
                                        value={formValue.industryType}
                                        onChange={(e) => handleCustomChange(e)}
                                    >
                                        <option value="" disabled>{language.Select_Industry_Type}</option>
                                        <option value={language.Advertising_Marketing}>{language.Advertising_Marketing}</option>
                                        <option value={language.Aerospace}>{language.Aerospace}</option>
                                        <option value={language.agriculture}>{language.agriculture}</option>
                                        <option value={language.Computer_Technology}>{language.Computer_Technology}</option>
                                        <option value={language.construction}>{language.construction}</option>
                                        <option value={language.education}>{language.education}</option>
                                        <option value={language.energy}>{language.energy}</option>
                                        <option value={language.Entertainment}>{language.Entertainment}</option>
                                        <option value={language.Fashion}>{language.Fashion}</option>
                                        <option value={language.Finance_Economic}>{language.Finance_Economic}</option>
                                        <option value={language.Food_Beverage}>{language.Food_Beverage}</option>
                                        <option value={language.Healthcare}>{language.Healthcare}</option>
                                        <option value={language.Hospitality}>{language.Hospitality}</option>
                                        <option value={language.manufacturing}>{language.manufacturing}</option>
                                        <option value={language.Media_News}>{language.Media_News}</option>
                                        <option value={language.Mining}>{language.Mining}</option>
                                        <option value={language.Pharmaceutical}>{language.Pharmaceutical}</option>
                                        <option value={language.Telecommunications}>{language.Telecommunications}</option>
                                        <option value={language.Transportation}>{language.Transportation}</option>
                                    </select>

                                    {/* Monthly Revenue */}
                                    <select
                                        className="custSelecInp"
                                        name="monthlyRevenue"
                                        value={formValue.monthlyRevenue}
                                        onChange={(e) => handleCustomChange(e)}
                                    >
                                        <option value="" disabled>{language.Monthly_Revenue}</option>
                                        <option value="Less than $100k">Less than $100k</option>
                                        <option value="$100K-$500K">$100K-$500K</option>
                                        <option value="$501K-$1M">$501K-$1M</option>
                                        <option value="$1M-$2M">$1M-$2M</option>
                                        <option value="$2M-$3M">$2M-$3M</option>
                                        <option value="$3M-$5M">$3M-$5M</option>
                                        <option value="$5M-$10M">$5M-$10M</option>
                                        <option value="$10M-$20M">$10M-$20M</option>
                                        <option value="Over $20M">Over $20M</option>
                                    </select>


                                    {/* Monthly Payables */}
                                    <select
                                        className="custSelecInp"
                                        name="monthlyPayable"
                                        value={formValue.monthlyPayable}
                                        onChange={(e) => handleCustomChange(e)}
                                    >
                                        <option value="" disabled>{language.Monthly_Payables}</option>
                                        <option value="Less than $100k">Less than $100k</option>
                                        <option value="$100K-$500K">$100K-$500K</option>
                                        <option value="$501K-$1M">$501K-$1M</option>
                                        <option value="$1M-$2M">$1M-$2M</option>
                                        <option value="$2M-$3M">$2M-$3M</option>
                                        <option value="$3M-$5M">$3M-$5M</option>
                                        <option value="$5M-$10M">$5M-$10M</option>
                                        <option value="$10M-$20M">$10M-$20M</option>
                                        <option value="Over $20M">Over $20M</option>
                                    </select>


                                    {/* Monthly Supplier Invoices */}
                                    <select
                                        className="custSelecInp"
                                        name="monthSuppInvoice"
                                        value={formValue.monthSuppInvoice}
                                        onChange={(e) => handleCustomChange(e)}
                                    >
                                        <option value="" disabled>{language.Monthly_Supplier_Invoices}</option>
                                        <option value="1-50">1-50</option>
                                        <option value="51-100">51-100</option>
                                        <option value="101-200">101-200</option>
                                        <option value="201-500">201-500</option>
                                        <option value="501-1000">501-1000</option>
                                        <option value="Over 1000">Over 1000</option>
                                    </select>

                                    {/* Bank Accounts */}
                                    <select
                                        className="custSelecInp"
                                        name="bankAcc"
                                        value={formValue.bankAcc}
                                        onChange={(e) => handleCustomChange(e)}
                                    >
                                        <option value="" disabled>{language.Select_Bank_Accounts}</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                        <option value="7">7</option>
                                        <option value="8">8</option>
                                        <option value="9">9</option>
                                        <option value="10">10</option>
                                        <option value="Over 10">Over 10</option>
                                    </select>

                                    {/* # of Customer Contracts */}
                                    <select
                                        className="custSelecInp"
                                        name="customerContract"
                                        value={formValue.customerContract}
                                        onChange={(e) => handleCustomChange(e)}
                                    >
                                        <option value="" disabled>{language.Customer_Contracts}</option>
                                        <option value="1-10">1-10</option>
                                        <option value="11-25">11-25</option>
                                        <option value="26-50">26-50</option>
                                        <option value="51-100">51-100</option>
                                        <option value="Over 100">Over 100</option>
                                    </select>

                                    {/* Supplier Contracts */}
                                    <select
                                        className="custSelecInp"
                                        name="supplierContract"
                                        value={formValue.supplierContract}
                                        onChange={(e) => handleCustomChange(e)}
                                    >
                                        <option value="" disabled>{language.Supplier_Contracts}</option>
                                        <option value="1-10">1-10</option>
                                        <option value="11-25">11-25</option>
                                        <option value="26-50">26-50</option>
                                        <option value="51-100">51-100</option>
                                        <option value="Over 100">Over 100</option>
                                    </select>

                                    {/* Loan Agreements */}
                                    <select
                                        className="custSelecInp"
                                        name="loanAgreement"
                                        value={formValue.loanAgreement}
                                        onChange={(e) => handleCustomChange(e)}
                                    >
                                        <option value="" disabled>{language.Loan_Agreements}</option>
                                        <option value="None">None</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="Over 5">Over 5</option>
                                    </select>

                                    {/* Loan Agreements */}
                                    <select
                                        className="custSelecInp"
                                        name="employeeNumber"
                                        value={formValue.employeeNumber}
                                        onChange={(e) => handleCustomChange(e)}
                                    >
                                        <option value="" disabled>{language.No_of_Employees}</option>
                                        <option value="Less than 5">Less than 5</option>
                                        <option value="6-10">6-10</option>
                                        <option value="11-20">11-20</option>
                                        <option value="21-30">21-30</option>
                                        <option value="31-40">31-40</option>
                                        <option value="41-50">41-50</option>
                                        <option value="51-75">51-75</option>
                                        <option value="76-100">76-100</option>
                                        <option value="Over 100">Over 100</option>
                                    </select>


                                    {/* Loan Agreements */}
                                    <select
                                        className="custSelecInp"
                                        name="contractorNumber"
                                        value={formValue.contractorNumber}
                                        onChange={(e) => handleCustomChange(e)}
                                    >
                                        <option value="" disabled>{language.No_of_Contractors}</option>
                                        <option value="Less than 5">Less than 5</option>
                                        <option value="6-10">6-10</option>
                                        <option value="11-20">11-20</option>
                                        <option value="21-30">21-30</option>
                                        <option value="31-40">31-40</option>
                                        <option value="41-50">41-50</option>
                                        <option value="51-75">51-75</option>
                                        <option value="76-100">76-100</option>
                                        <option value="Over 100">Over 100</option>
                                    </select>

                                </div>

                                {/* All services checkbox */}
                                <div className="selecAllServDiv mt-3">
                                    <p className="custSelectHead">
                                        Select All Services You Would Like to Have
                                    </p>
                                    <div className="selectAllOpDiv">
                                        <div className="seleAllBtnDiv">
                                            {
                                                isAllSelect
                                                    ?
                                                    <button
                                                        className="selecAllBtnBkk"
                                                        onClick={() => {
                                                            setFormValue(prev => ({ ...prev, allServices: [] }))
                                                            setIsAllSelect(false)
                                                        }
                                                        }
                                                    >
                                                        Deselect All
                                                    </button>
                                                    :
                                                    <button
                                                        className="selecAllBtnBkk"
                                                        onClick={() => selectAllClick()}
                                                    >
                                                        Select All
                                                    </button>
                                            }
                                        </div>
                                        <div className="row row-cols-2">

                                            <div className="custBkkLabDiv">
                                                <input
                                                    type="checkbox"
                                                    checked={formValue?.allServices?.indexOf("Long-Term Loan") !== -1}
                                                    name="allServices"
                                                    value="Long-Term Loan"
                                                    onChange={(e) => handleServiceChange(e)}
                                                />
                                                <label className="custBkkLabel">Long-Term Loan</label>
                                            </div>

                                            <div className="custBkkLabDiv">
                                                <input
                                                    type="checkbox"
                                                    checked={formValue?.allServices?.indexOf("Short-Term Loan") !== -1}
                                                    name="allServices"
                                                    value="Short-Term Loan"
                                                    onChange={(e) => handleServiceChange(e)}
                                                />
                                                <label className="custBkkLabel">
                                                    Short-Term Loan
                                                </label>
                                            </div>

                                            <div className="custBkkLabDiv">
                                                <input
                                                    type="checkbox"
                                                    checked={formValue?.allServices?.indexOf("AR Factoring") !== -1}
                                                    name="allServices"
                                                    value="AR Factoring"
                                                    onChange={(e) => handleServiceChange(e)}
                                                />
                                                <label className="custBkkLabel">
                                                    AR Factoring
                                                </label>
                                            </div>

                                            <div className="custBkkLabDiv">
                                                <input
                                                    type="checkbox"
                                                    checked={formValue?.allServices?.indexOf("Mezzanine") !== -1}
                                                    name="allServices"
                                                    value="Mezzanine"
                                                    onChange={(e) => handleServiceChange(e)}
                                                />
                                                <label className="custBkkLabel">
                                                    Mezzanine
                                                </label>
                                            </div>

                                            <div className="custBkkLabDiv">
                                                <input
                                                    type="checkbox"
                                                    checked={formValue?.allServices?.indexOf("Merchant Services") !== -1}
                                                    name="allServices"
                                                    value="Merchant Services"
                                                    onChange={(e) => handleServiceChange(e)}
                                                />
                                                <label className="custBkkLabel">
                                                    Merchant Services
                                                </label>
                                            </div>

                                            <div className="custBkkLabDiv">
                                                <input
                                                    type="checkbox"
                                                    checked={formValue?.allServices?.indexOf("Accounts Payable Services") !== -1}
                                                    name="allServices"
                                                    value="Accounts Payable Services"
                                                    onChange={(e) => handleServiceChange(e)}
                                                />
                                                <label className="custBkkLabel">
                                                    Accounts Payable Services
                                                </label>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                {/* prices  */}
                                <div className="allPlnsDiv">
                                    <div className="allPlnsInnerDiv">
                                        <label className="allPlnLable">Requested Debt Amount:</label>
                                        <div className="allInpSpanMainDiv">
                                            <input
                                                className="allPlnInp"
                                                type="text"
                                                name="requestedAmt"
                                                value={formValue.requestedAmt}
                                                onChange={(e) => handleCustomChange(e)}
                                            />
                                            <span className="allPlnsSpan">USD</span>
                                        </div>
                                    </div>
                                    <div className="allPlnsInnerDiv">
                                        <label className="allPlnLable">Existing Debt:</label>
                                        <div className="allInpSpanMainDiv">
                                            <input
                                                type="text"
                                                className="allPlnInp"
                                                name="existingAmt"
                                                value={formValue.existingAmt}
                                                onChange={(e) => handleCustomChange(e)}
                                            />
                                            <span className="allPlnsSpan">USD</span>
                                        </div>
                                    </div>
                                    <div className="allPlnsInnerDiv">
                                        <label className="allPlnLable">Current Monthly Debt Payment:</label>
                                        <div className="allInpSpanMainDiv">
                                            <input
                                                type="text"
                                                className="allPlnInp"
                                                name="totalAmt"
                                                value={formValue.totalAmt}
                                                onChange={(e) => handleCustomChange(e)}
                                            />
                                            <span className="allPlnsSpan">USD</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </TabPanel>


                        {/* Equity Capital Raise */}
                        <TabPanel>

                            <div className="customBookkeDiv">
                                <p className="custBookkHead">
                                    Equity Capital Raise:
                                </p>

                                {/* radio and date part */}
                                <div className="custTypeDiv">
                                    <div className="custinnerTypeDiv">

                                        <div className="custIndDiv">
                                            <input
                                                type="radio"
                                                id="custTyInd"
                                                className="custTyInp"
                                                checked={formValue.indiCorp === "Individual"}
                                                name="indiCorp"
                                                value="Individual"
                                                onChange={(e) => handleCustomChange(e)}
                                            />
                                            <label htmlFor="custTyInd" className="custInpLabel">
                                                Individual
                                            </label>
                                        </div>
                                        <div className="custCorDiv">
                                            <input
                                                type="radio"
                                                id="custTyCor"
                                                className="custTyInp"
                                                checked={formValue.indiCorp === "Corporation"}
                                                name="indiCorp"
                                                value="Corporation"
                                                onChange={(e) => handleCustomChange(e)}
                                            />
                                            <label htmlFor="custTyCor" className="custInpLabel">
                                                Corporation
                                            </label>
                                        </div>
                                    </div>

                                    <div className="custBkkDateLabDiv">
                                        <label className="custInpDateLabel">
                                            Date of Incorporation
                                        </label>
                                        <input
                                            type="date"
                                            className="custDateInp"
                                            name="date"
                                            value={formValue.date}
                                            onChange={(e) => handleCustomChange(e)}
                                        />
                                    </div>

                                </div>

                                {/* select  */}
                                <div
                                    className="row row-cols-3 justify-content-between"
                                    style={{ width: "100%", margin: "0 auto" }}
                                >
                                    {/* Corporation Type */}
                                    <select
                                        className="custSelecInp"
                                        name="corpType"
                                        value={formValue.corpType}
                                        onChange={(e) => handleCustomChange(e)}
                                    >
                                        <option value="" disabled>{language.Corporation_Type}</option>
                                        <option value="Sole Proprietorship">Sole Proprietorship</option>
                                        <option value="Partnership">Partnership</option>
                                        <option value="LLC.">LLC.</option>
                                        <option value="S Corp.">S Corp.</option>
                                        <option value="C Corp.">C Corp.</option>
                                        <option value="Nonprofit">Nonprofit</option>
                                    </select>

                                    {/* Select Industry Type */}
                                    <select
                                        className="custSelecInp"
                                        name="industryType"
                                        value={formValue.industryType}
                                        onChange={(e) => handleCustomChange(e)}
                                    >
                                        <option value="" disabled>{language.Select_Industry_Type}</option>
                                        <option value="Advertising & Marketing">{language.Advertising_Marketing}</option>
                                        <option value="Aerospace">{language.Aerospace}</option>
                                        <option value="Agriculture">{language.agriculture}</option>
                                        <option value="Computer & Technology">{language.Computer_Technology}</option>
                                        <option value="Construction">{language.construction}</option>
                                        <option value="Education">{language.education}</option>
                                        <option value="Energy">{language.energy}</option>
                                        <option value="Energy">{language.Entertainment}</option>
                                        <option value="Entertainment">{language.Fashion}</option>
                                        <option value="Finance & Economic">{language.Finance_Economic}</option>
                                        <option value="Food & Beverage">{language.Food_Beverage}</option>
                                        <option value="Healthcare">{language.Healthcare}</option>
                                        <option value="Hospitality">{language.Hospitality}</option>
                                        <option value="Manufacturing">{language.manufacturing}</option>
                                        <option value="Media & News">{language.Media_News}</option>
                                        <option value="Mining">{language.Mining}</option>
                                        <option value="Pharmaceutical">{language.Pharmaceutical}</option>
                                        <option value="Telecommunications">{language.Telecommunications}</option>
                                        <option value="Transportation">{language.Transportation}</option>
                                    </select>

                                    {/* Monthly Revenue */}
                                    <select
                                        className="custSelecInp"
                                        name="monthlyRevenue"
                                        value={formValue.monthlyRevenue}
                                        onChange={(e) => handleCustomChange(e)}
                                    >
                                        <option value="" disabled>{language.Monthly_Revenue}</option>
                                        <option value="Less than $100k">Less than $100k</option>
                                        <option value="$100K-$500K">$100K-$500K</option>
                                        <option value="$501K-$1M">$501K-$1M</option>
                                        <option value="$1M-$2M">$1M-$2M</option>
                                        <option value="$2M-$3M">$2M-$3M</option>
                                        <option value="$3M-$5M">$3M-$5M</option>
                                        <option value="$5M-$10M">$5M-$10M</option>
                                        <option value="$10M-$20M">$10M-$20M</option>
                                        <option value="Over $20M">Over $20M</option>
                                    </select>


                                    {/* Monthly Payables */}
                                    <select
                                        className="custSelecInp"
                                        name="monthlyPayable"
                                        value={formValue.monthlyPayable}
                                        onChange={(e) => handleCustomChange(e)}
                                    >
                                        <option value="" disabled>{language.Monthly_Payables}</option>
                                        <option value="Less than $100k">Less than $100k</option>
                                        <option value="$100K-$500K">$100K-$500K</option>
                                        <option value="$501K-$1M">$501K-$1M</option>
                                        <option value="$1M-$2M">$1M-$2M</option>
                                        <option value="$2M-$3M">$2M-$3M</option>
                                        <option value="$3M-$5M">$3M-$5M</option>
                                        <option value="$5M-$10M">$5M-$10M</option>
                                        <option value="$10M-$20M">$10M-$20M</option>
                                        <option value="Over $20M">Over $20M</option>
                                    </select>


                                    {/* Monthly Supplier Invoices */}
                                    <select
                                        className="custSelecInp"
                                        name="monthSuppInvoice"
                                        value={formValue.monthSuppInvoice}
                                        onChange={(e) => handleCustomChange(e)}
                                    >
                                        <option value="" disabled>{language.Monthly_Supplier_Invoices}</option>
                                        <option value="1-50">1-50</option>
                                        <option value="51-100">51-100</option>
                                        <option value="101-200">101-200</option>
                                        <option value="201-500">201-500</option>
                                        <option value="501-1000">501-1000</option>
                                        <option value="Over 1000">Over 1000</option>
                                    </select>

                                    {/* Bank Accounts */}
                                    <select
                                        className="custSelecInp"
                                        name="bankAcc"
                                        value={formValue.bankAcc}
                                        onChange={(e) => handleCustomChange(e)}
                                    >
                                        <option value="" disabled>{language.Select_Bank_Accounts}</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                        <option value="7">7</option>
                                        <option value="8">8</option>
                                        <option value="9">9</option>
                                        <option value="10">10</option>
                                        <option value="Over 10">Over 10</option>
                                    </select>

                                    {/* # of Customer Contracts */}
                                    <select
                                        className="custSelecInp"
                                        name="customerContract"
                                        value={formValue.customerContract}
                                        onChange={(e) => handleCustomChange(e)}
                                    >
                                        <option value="" disabled>{language.Customer_Contracts}</option>
                                        <option value="1-10">1-10</option>
                                        <option value="11-25">11-25</option>
                                        <option value="26-50">26-50</option>
                                        <option value="51-100">51-100</option>
                                        <option value="Over 100">Over 100</option>
                                    </select>

                                    {/* Supplier Contracts */}
                                    <select
                                        className="custSelecInp"
                                        name="supplierContract"
                                        value={formValue.supplierContract}
                                        onChange={(e) => handleCustomChange(e)}
                                    >
                                        <option value="" disabled>{language.Supplier_Contracts}</option>
                                        <option value="1-10">1-10</option>
                                        <option value="11-25">11-25</option>
                                        <option value="26-50">26-50</option>
                                        <option value="51-100">51-100</option>
                                        <option value="Over 100">Over 100</option>
                                    </select>

                                    {/* Loan Agreements */}
                                    <select
                                        className="custSelecInp"
                                        name="loanAgreement"
                                        value={formValue.loanAgreement}
                                        onChange={(e) => handleCustomChange(e)}
                                    >
                                        <option value="" disabled>{language.Loan_Agreements}</option>
                                        <option value="None">None</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="Over 5">Over 5</option>
                                    </select>

                                    {/* Loan Agreements */}
                                    <select
                                        className="custSelecInp"
                                        name="employeeNumber"
                                        value={formValue.employeeNumber}
                                        onChange={(e) => handleCustomChange(e)}
                                    >
                                        <option value="" disabled>{language.No_of_Employees}</option>
                                        <option value="Less than 5">Less than 5</option>
                                        <option value="6-10">6-10</option>
                                        <option value="11-20">11-20</option>
                                        <option value="21-30">21-30</option>
                                        <option value="31-40">31-40</option>
                                        <option value="41-50">41-50</option>
                                        <option value="51-75">51-75</option>
                                        <option value="76-100">76-100</option>
                                        <option value="Over 100">Over 100</option>
                                    </select>


                                    {/* Loan Agreements */}
                                    <select
                                        className="custSelecInp"
                                        name="contractorNumber"
                                        value={formValue.contractorNumber}
                                        onChange={(e) => handleCustomChange(e)}
                                    >
                                        <option value="" disabled>{language.No_of_Contractors}</option>
                                        <option value="Less than 5">Less than 5</option>
                                        <option value="6-10">6-10</option>
                                        <option value="11-20">11-20</option>
                                        <option value="21-30">21-30</option>
                                        <option value="31-40">31-40</option>
                                        <option value="41-50">41-50</option>
                                        <option value="51-75">51-75</option>
                                        <option value="76-100">76-100</option>
                                        <option value="Over 100">Over 100</option>
                                    </select>

                                </div>

                                {/* All services */}
                                <div className="selecAllServDiv mt-3">
                                    <p className="custSelectHead">
                                        Select All Services You Would Like to Have
                                    </p>
                                    <div className="selectAllOpDiv">
                                        <div className="seleAllBtnDiv">
                                            {
                                                isAllSelect
                                                    ?
                                                    <button
                                                        className="selecAllBtnBkk"
                                                        onClick={() => {
                                                            setFormValue(prev => ({ ...prev, allServices: [] }))
                                                            setIsAllSelect(false)
                                                        }
                                                        }
                                                    >
                                                        Deselect All
                                                    </button>
                                                    :
                                                    <button
                                                        className="selecAllBtnBkk"
                                                        onClick={() => selectAllClick2()}
                                                    >
                                                        Select All
                                                    </button>
                                            }
                                        </div>

                                        {/* checkbox part */}
                                        <div className="row row-cols-2">

                                            <div className="custBkkLabDiv">
                                                <input
                                                    type="checkbox"
                                                    checked={formValue?.allServices?.indexOf("Minority Raise") !== -1}
                                                    name="allServices"
                                                    value="Minority Raise"
                                                    onChange={(e) => handleServiceChange(e)}
                                                />
                                                <label className="custBkkLabel">Minority Raise</label>
                                            </div>

                                            <div className="custBkkLabDiv">
                                                <input
                                                    type="checkbox"
                                                    checked={formValue?.allServices?.indexOf("Majority Raise") !== -1}
                                                    name="allServices"
                                                    value="Majority Raise"
                                                    onChange={(e) => handleServiceChange(e)}
                                                />
                                                <label className="custBkkLabel">
                                                    Majority Raise
                                                </label>
                                            </div>

                                            <div className="custBkkLabDiv">
                                                <input
                                                    type="checkbox"
                                                    checked={formValue?.allServices?.indexOf("Buyout") !== -1}
                                                    name="allServices"
                                                    value="Buyout"
                                                    onChange={(e) => handleServiceChange(e)}
                                                />
                                                <label className="custBkkLabel">
                                                    Buyout
                                                </label>
                                            </div>

                                            <div className="custBkkLabDiv">
                                                <input
                                                    type="checkbox"
                                                    checked={formValue?.allServices?.indexOf("Common Stock") !== -1}
                                                    name="allServices"
                                                    value="Common Stock"
                                                    onChange={(e) => handleServiceChange(e)}
                                                />
                                                <label className="custBkkLabel">
                                                    Common Stock
                                                </label>
                                            </div>

                                            <div className="custBkkLabDiv">
                                                <input
                                                    type="checkbox"
                                                    checked={formValue?.allServices?.indexOf("Preferred Stock") !== -1}
                                                    name="allServices"
                                                    value="Preferred Stock"
                                                    onChange={(e) => handleServiceChange(e)}
                                                />
                                                <label className="custBkkLabel">
                                                    Preferred Stock
                                                </label>
                                            </div>

                                            <div className="custBkkLabDiv">
                                                <input
                                                    type="checkbox"
                                                    checked={formValue?.allServices?.indexOf("Revenue Share") !== -1}
                                                    name="allServices"
                                                    value="Revenue Share"
                                                    onChange={(e) => handleServiceChange(e)}
                                                />
                                                <label className="custBkkLabel">
                                                    Revenue Share
                                                </label>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                {/* prices */}
                                <div className="allPlnsDiv">
                                    <div className="allPlnsInnerDiv">
                                        <label className="allPlnLable">Requested Equity Amount:</label>
                                        <div className="allInpSpanMainDiv">
                                            <input
                                                className="allPlnInp"
                                                type="text"
                                                name="requestedAmt"
                                                value={formValue.requestedAmt}
                                                onChange={(e) => handleCustomChange(e)}
                                            />
                                            <span className="allPlnsSpan">USD</span>
                                        </div>
                                    </div>
                                    <div className="allPlnsInnerDiv">
                                        <label className="allPlnLable">Existing Equity:</label>
                                        <div className="allInpSpanMainDiv">
                                            <input
                                                type="text"
                                                className="allPlnInp"
                                                name="existingAmt"
                                                value={formValue.existingAmt}
                                                onChange={(e) => handleCustomChange(e)}
                                            />
                                            <span className="allPlnsSpan">USD</span>
                                        </div>
                                    </div>
                                    <div className="allPlnsInnerDiv">
                                        <label className="allPlnLable">Total # of Stockholders:</label>
                                        <div className="allInpSpanMainDiv">
                                            <input
                                                type="text"
                                                className="allPlnInp"
                                                name="totalAmt"
                                                value={formValue.totalAmt}
                                                onChange={(e) => handleCustomChange(e)}
                                            />
                                            <span className="allPlnsSpan">USD</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </TabPanel>

                    </Tabs>


                    <div className="linkBookGDiv">
                        {/* {
                            planName === "Basic"
                            &&
                            <div className="linkBookkDiv">
                                <Link
                                    // to="/"
                                    className="needMoreLinkBkk"
                                >
                                    I need more services
                                </Link>
                            </div>
                        } */}

                        <button
                            className="bookkPayNowBtn mr-1"
                            onClick={() => handleBasicPay()}
                        >
                            PUBLISH
                        </button>

                        <button
                            className="bookkPayNowBtn bg-secondary "
                        // onClick={() => handleReqQuote()}
                        >
                            PAUSE
                        </button>
                    </div>
                </div>
            </section>
        </>
    );
}

export default RaiseCapital;
