import React, { useCallback, useEffect, useMemo, useState } from "react";
import "./TaskManager.css";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
// import fisiIconDrop from "../../images/FisTaskIcon.png";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import HttpClient from "../../utils/HttpClient";
import TaskSidebar from "./sidebar/TaskSidebar";
import EventSidebar from "./sidebar/EventSidebar";
import ProjectSidebar from "./sidebar/ProjectSidebar";
import TaskList from "./todolists/TaskList";
import EventList from "./todolists/EventList";
import ProjectList from "./todolists/ProjectList";
import ProjectListTeam from "./todolists/ProjectListTeam";
import TaskPopUp from "./TaskPopUp";
import EventPopUp from "./EventPopUp";
import ProjectPopUp from "./ProjectPopUp";
import { useLanguage } from "../../Providers/LanguageContext";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import TaskListUser from "./todolists/TaskListUser";
import EventListUser from "./todolists/EventListUser";
import { useUserData } from "../../Providers/UserWrapper";

function EventsCalender() {

  // designers code
  const localizer = momentLocalizer(moment);
  const navigate = useNavigate();
  const { language } = useLanguage();
  const { search } = useLocation();
  const { setIsLoading } = useUserData();

  const [modala, setModala] = useState(false);
  const [modalb, setModalb] = useState(false);
  const [modalc, setModalc] = useState(false);
  const [taskDetailData, setTaskDetailData] = useState({});
  const [assignUserData, setAssignUserData] = useState([]);

  const [tabName, setTabName] = useState("Tasks");
  const [detailName, setDetailName] = useState("");
  const [dayType, setDayType] = useState("month");
  const [buttonName, setButtonName] = useState("");
  const [isSelectUser, setIsSelectUser] = useState(false);

  const [dateData, setDatedata] = useState(new Date());
  const [calenderEvent, setCalenderEvent] = useState([]);
  const [custNames, setCustNames] = useState([]);
  const [vendorName, setVendorName] = useState([]);


  // returns tab details
  const tabDetailsMemo = useMemo(() => {
    switch (tabName) {
      case "Tasks":
        return "Task Details";
      case "Events":
        return "Events Details";
      case "Projects":
        return "Projects Details";
      default:
        return "";
    }
  }, [tabName]);


  // returns tab details color
  const tabDetailsColorMemo = useMemo(() => {
    switch (detailName) {
      case "Task Details":
        return "clrTaskBtn ";
        break;
      case "Events Details":
        return "clrEventsBtn ";
        break;
      case "Projects Details":
        return "clrProjectBtn ";
        break;
      default:
        return "clrDefaultBtn";
    }
  }, [detailName]);


  // console.log("tabDetailsColorMemo", tabDetailsColorMemo)


  // getting assigned users
  const getAssignedUSer = async () => {
    const res = await HttpClient.requestData("user-management", "GET")
    // console.log("resUser", res);
    if (res && res?.status) {
      // setAssignUserData(res?.data?.filter(item => item?._id !== profileData?._id));
      setAssignUserData(res?.data);
    }
  }

  // format assignUser data for searchable dropdown
  const selectAssignUserMemo = useMemo(() => {
    return assignUserData?.map(item => ({
      value: item?._id,
      label: item?.name + " " + item?.surName,
      // color: '#FF8B00'
    }
    ))
  }, [assignUserData])


  // *****big calender ***********
  const handleSelectSlot = (slotinfo) => {
    setDatedata(slotinfo);
  };
  const handleSelectDayType = (weekinfo) => {
    setDayType(weekinfo);
  };
  // *****end big calender ***********



  // get the week number of a year
  const getWeekNumber = (year, month, day) => {
    const currentDate = new Date(year, month, day);
    let startDate = new Date(currentDate.getFullYear(), 0, 1);
    let days = Math.floor((currentDate - startDate) /
      (24 * 60 * 60 * 1000));

    let weekNumber = Math.ceil(days / 7);
    return weekNumber;
  }



  // **********task List*********
  const [taskData, setTaskData] = useState({
    open: [],
    doing: [],
    done: [],
    ideas: [],
    onhold: [],
  });
  const [taskAllData, setTaskAllData] = useState([]);

  const getTaskData = async () => {
    const data = {
      dayType: dayType,
      day: dateData.getDate(),
      month: dateData.getMonth() + 1,
      year: dateData.getFullYear(),
      week: getWeekNumber(dateData.getFullYear(), dateData.getMonth(), dateData.getDate())
    };
    // console.log("dataaasdff", data)
    // return;
    const res = await HttpClient.requestData("view-task-eventDetails", "POST", data);
    // console.log("ress", res)
    if (res && res?.status) {
      setTaskAllData(res?.data);

      setDragDropData(res?.data, "taskStatus", setTaskData);

    }
  };
  // **********end task todo List*********




  // **********Event List*********
  const [eventData, setEventData] = useState({
    open: [],
    doing: [],
    done: [],
    ideas: [],
    onhold: [],
  });
  const [eventAllData, setEventAllData] = useState([]);

  const getEventData = async () => {
    const data = {
      dayType: dayType,
      day: dateData.getDate(),
      month: dateData.getMonth() + 1,
      year: dateData.getFullYear(),
      week: getWeekNumber(dateData.getFullYear(), dateData.getMonth(), dateData.getDate())
    };
    // console.log("dataaasdff", data)
    const res = await HttpClient.requestData(
      "view-event-eventDetails",
      "POST",
      data
    );
    // console.log("ressecvenffs", res)
    if (res && res?.status) {
      setEventAllData(res?.data);

      setDragDropData(res?.data, "eventStatus", setEventData);

    }
  };
  // **********end Event  List*********

  // **********project List*********
  const [projectData, setProjectData] = useState({
    open: [],
    doing: [],
    done: [],
    ideas: [],
    onhold: [],
  });
  const [preojectAllData, setProjectAllData] = useState([]);

  const getProjecData = async () => {
    const data = {
      dayType: dayType,
      day: dateData.getDate(),
      month: dateData.getMonth() + 1,
      year: dateData.getFullYear(),
      week: getWeekNumber(dateData.getFullYear(), dateData.getMonth(), dateData.getDate())
    };

    const res = await HttpClient.requestData(
      "view-project-eventdetails",
      "POST",
      data
    );
    // console.log("ressecvenffsPP", res)
    if (res && res?.status) {
      setProjectAllData(res?.data);

      setDragDropData(res?.data, "projectStatus", setProjectData);

    }
  };
  // **********end project List*********


  // convert task, event, project data in drag and deop format
  const setDragDropData = (data, statusName, setData) => {
    const open = data?.filter((item) => item?.[statusName] === "open");
    const doing = data?.filter((item) => item?.[statusName] === "doing");
    const done = data?.filter((item) => item?.[statusName] === "done");
    const idea = data?.filter((item) => item?.[statusName] === "ideas");
    const onhold = data?.filter((item) => item?.[statusName] === "onhold");

    setData([
      {
        name: "Open",
        color: "rgb(240, 91, 140)",
        status: open,
        apiStatus: "open",
      },
      {
        name: "Doing",
        color: "rgb(46, 195, 247)",
        status: doing,
        apiStatus: "doing",
      },
      {
        name: "Done",
        color: "rgb(61, 241, 66)",
        status: done,
        apiStatus: "done",
      },
      {
        name: "Ideas",
        color: "rgb(113, 129, 200)",
        status: idea,
        apiStatus: "ideas",
      },
      {
        name: "On-hold",
        color: "rgb(247, 200, 31)",
        status: onhold,
        apiStatus: "onhold",
      },
    ]);
  }


  useEffect(() => {
    getTaskData();
    getEventData();
    getProjecData();
  }, [dayType, dateData]);


  const getDateTimeFormat = useCallback((date, time) => {
    const year = moment(date).year();
    const month = moment(date).month();
    const day = moment(date).date();
    const hour = time?.split(":")?.[0];
    const minute = time?.split(":")?.[1];

    // console.log("dateTimeDate", new Date(year, month, day, hour, minute), date, year, month, day, hour, minute)
    return new Date(year, month, day, hour, minute);

    // console.log("dateTime", moment(date).year(), moment(date).day(), moment(date).month(), time?.split(":")?.[0], time?.split(":")?.[1])
  }, [])


  // set events of calender
  const SetEventsOfCalender = () => {
    setCalenderEvent([]);

    const arr = taskAllData?.map((item) => ({
      title: item?.taskName,
      // start: new Date(item?.selectDate),
      start: getDateTimeFormat(item?.selectDate, item?.startTime),
      end: getDateTimeFormat(item?.selectDate, item?.endTime),
      details: item,
      tabName: "Tasks"
    }));
    const arr2 = eventAllData?.map((item) => ({
      title: item?.eventName,
      start: getDateTimeFormat(item?.selectDate, item?.startTime),
      end: getDateTimeFormat(item?.selectDate, item?.endTime),
      details: item,
      tabName: "Events"
    }));
    const arr3 = preojectAllData?.map((item) => ({
      title: item?.projectName,
      start: new Date(item?.startDate),
      end: new Date(item?.endDate),
      details: item,
      tabName: "Projects"
    }));

    setCalenderEvent([...arr, ...arr2, ...arr3]);
  }
  useEffect(() => {
    SetEventsOfCalender(taskAllData, eventAllData, preojectAllData)
  }, [taskAllData, eventAllData, preojectAllData]);


  // console.log("calenderEventsdf", calenderEvent, taskAllData);


  // **************** google calender apis *******************
  const getGoogleEvent = async () => {
    const queryParamData = convertQueryParamsStringToQueryParamsObject(search)
    console.log("queryParamData",);
    const decodedValue = JSON.parse(decodeURIComponent(decodeURIComponent(queryParamData.state)))
    console.log("decodedValue", decodedValue)
    if (decodedValue.type == 'google') {
      const res = await HttpClient.requestData(`google-auth/callback?code=${queryParamData?.code}&state=${queryParamData?.state}`, "GET")
    } else if (decodedValue.type == 'outlook') {
      const res = await HttpClient.requestData(`outlook-auth/callback?code=${queryParamData?.code}&state=${queryParamData?.state}`, "GET")
    }
    // 
    // console.log("resdfrgrefd", res)
  }

  const convertQueryParamsStringToQueryParamsObject = (string) => {
    let queryParams = {};
    // remove first letter from query string
    string = string.substring(1);
    // console.log("string", string);
    // convert string to array
    const queryParamsArray = string.split("&");
    // console.log("queryParamsArray", queryParamsArray);

    // covert each index query to object
    queryParamsArray.forEach((queryParam) => {
      // console.log("queryParam", queryParam);
      // split param by =
      const queryParamArray = queryParam.split("=");
      // console.log("queryParamArray", queryParamArray);
      queryParams[queryParamArray[0]] = queryParamArray[1];
      // console.log("queryParams", queryParams);
    });
    return queryParams;
  };

  const GoogleCalenderSynced = async () => {
    const res = await HttpClient.requestData("google-calender-data", "GET")
    // console.log("resgvvb", res)
  }

  useEffect(() => {
    if (search) {
      getGoogleEvent()
    }
  }, [search])
  // **************** End google calender apis *******************



  // color of event in calender
  const eventStyleGetter = (event) => {

    const bgColor = () => {
      switch (event?.tabName) {
        case "Tasks": return "#729FCF"; break;
        case "Events": return "#F6B4CA"; break;
        case "Projects": return "#A1467E"; break;
        default: return "#729FCF"
      }
    }

    return {
      style: {
        backgroundColor: bgColor(), // Default background color for events
        borderRadius: "5px",
        color: "white",
        border: "none",
        display: "block",
        fontSize: "13px",
        // height: "50px"
      },
    };

  };


  // click on event in calender
  const handleClickEvent = (event, e) => {
    // console.log("AAAAAAAAAAAAAAAAAAAAAAAAAAAAaaaa");
    setTaskDetailData({ ...event?.details, tabName: event?.tabName });

    switch (event?.tabName) {
      case "Tasks":
        setModala(true);
        break;
      case "Events":
        setModalb(true);
        break;
      case "Projects":
        setModalc(true);
        break;
      default:
        setModala(false);
        setModalc(false);
        setModalb(true);
        break;
    }
  };

  // clicks on select user dropdown
  const handleClickSubUser = async (item) => {
    // console.log("objeitemsubuct", item, tabName)
    const data = {
      selectedUser: item?._id,
      dayType: dayType,
      day: dateData.getDate(),
      month: dateData.getMonth() + 1,
      year: dateData.getFullYear(),
      week: getWeekNumber(dateData.getFullYear(), dateData.getMonth(), dateData.getDate())
    }
    setIsSelectUser(false)
    setIsLoading(true)
    const res = await HttpClient.requestData("view-user-task-eventDetails", "POST", data);
    if (res && res?.status) {
      // console.log("ressTask", res)
      setTaskAllData(res?.data);
      setDragDropData(res?.data, "taskStatus", setTaskData);
      setIsLoading(false)
    } else {
      setIsLoading(false)
    }

    const res2 = await HttpClient.requestData("view-user-event-eventDetails", "POST", data);
    if (res2 && res2?.status) {
      // console.log("ressTask", res)
      setEventAllData(res2?.data);
      setDragDropData(res2?.data, "eventStatus", setEventData);
      setIsLoading(false)
    } else {
      setIsLoading(false)
    }

    const res3 = await HttpClient.requestData("view-user-project-eventdetails", "POST", data);
    if (res3 && res3?.status) {
      // console.log("ressTask", res3)
      setProjectAllData(res3?.data);
      setDragDropData(res3?.data, "projectStatus", setProjectData);
      setIsLoading(false)
    } else {
      setIsLoading(false)
    }

  }

  const viewAllCalData = () => {
    setIsSelectUser(false);
    getTaskData();
    getEventData();
    getProjecData();
  }


  // remove duplicate from array of object
  const removeDuplicateFunc = (arr, key) => {
    const unique = arr.filter((obj, index) => {
      return index === arr.findIndex(o => obj[key] === o[key]);
    });
    return unique;
  }

  // get customer, vendor data
  const getCustomerVendor = async () => {
    const data1 = {
      input_type: "Customer"
    }
    const res1 = await HttpClient.requestData("get-dashboard-zero-bu-input-data", "POST", data1);
    if (res1 && res1?.success) {
      const filtData = removeDuplicateFunc(res1?.data, "customerName")
      setCustNames(filtData)
      // case "Vendor":
      // const filtData2 = removeDuplicateFunc(res?.data, "vendorName")
      // setCustData(filtData2)
    }

    const data2 = {
      input_type: "Vendor"
    }
    const res2 = await HttpClient.requestData("get-dashboard-zero-bu-input-data", "POST", data2);
    if (res2 && res2?.success) {
      const filtData2 = removeDuplicateFunc(res2?.data, "vendorName")
      setVendorName(filtData2)
    }

  }

  // format customer Vendor data for searchable dropdown
  const selectCustVendorMemo = useMemo(() => {
    const cust = custNames?.map(item => ({
      value: item?.customerName,
      label: item?.customerName,
    }))
    const vendor = vendorName?.map(item => ({
      value: item?.vendorName,
      label: item?.vendorName,
    }))
    return [...cust, ...vendor]
  }, [custNames, vendorName])


  useEffect(() => {
    GoogleCalenderSynced();

    getAssignedUSer();
    getCustomerVendor();
  }, [])




  return (
    <>
      <section className="evntsCalSection">
        <div className="container">
          <div className=''>
            <button className='w-25 mb-2 btn btn-outline-success' style={{ fontWeight: "bold" }} onClick={() => navigate('/connectCalender')}>
              {language.connect_my_calendar}
            </button>
          </div>
          <div className="row mb-3">
            <div className="col">
              <div className="takEvntDiv">
                <span className="takEvntText">{language.task_event_manager}</span>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-4 col-12">
              <div className="myTaskEvPrTabDiv">
                <Tabs>
                  <TabList>
                    <Tab
                      onClick={() => {
                        setTabName('Tasks');
                        setDetailName('');
                        setButtonName('');
                      }}
                    >
                      {language.task}
                    </Tab>
                    <Tab
                      onClick={() => {
                        setTabName('Events');
                        setDetailName('');
                        setButtonName('');
                      }}
                    >
                      {language.events}
                    </Tab>
                    <Tab
                      onClick={() => {
                        setTabName('Projects');
                        setDetailName('');
                        setButtonName('');
                      }}
                    >
                      {language.Projects}
                    </Tab>
                  </TabList>

                  {/* Task */}
                  <TabPanel>
                    <TaskSidebar
                      setModala={setModala}
                      getTaskData={getTaskData}
                      taskAllData={taskAllData}
                      setTaskDetailData={setTaskDetailData}
                      selectAssignUserMemo={selectAssignUserMemo}
                    />
                  </TabPanel>

                  {/* Events */}
                  <TabPanel>
                    <EventSidebar
                      setModalb={setModalb}
                      getEventData={getEventData}
                      eventAllData={eventAllData}
                      setTaskDetailData={setTaskDetailData}
                      selectAssignUserMemo={selectAssignUserMemo}
                      selectCustVendorMemo={selectCustVendorMemo}
                    />
                  </TabPanel>

                  {/* Projects */}
                  <TabPanel>
                    <ProjectSidebar
                      setModalc={setModalc}
                      getProjecData={getProjecData}
                      preojectAllData={preojectAllData}
                      setTaskDetailData={setTaskDetailData}
                      selectAssignUserMemo={selectAssignUserMemo}
                      selectCustVendorMemo={selectCustVendorMemo}
                    />
                  </TabPanel>
                </Tabs>
              </div>
            </div>

            <div className="col-lg-8 col-12 mt-4 mt-lg-0">
              <div className="evntTaskCalenDiv">
                {/* details buttons  */}
                <div className="selectProBtnDiv">
                  {/* {detailName &&
                    <div className="mr-2">
                      <button
                        className={`dwmBtns ${dayType === "day" && "dwmBtnsActive"}`}
                        onClick={() => setDayType("day")}
                      >
                        day
                      </button>

                      <button
                        className={`dwmBtns ${dayType === "week" && "dwmBtnsActive"}`}
                        onClick={() => setDayType("week")}
                      >
                        week
                      </button>

                      <button
                        className={`dwmBtns ${dayType === "month" && "dwmBtnsActive"}`}
                        onClick={() => setDayType("month")}
                      >
                        month
                      </button>

                    </div>
                  } */}

                  {/* team / status for project */}
                  {buttonName !== '' ? (
                    <div>
                      <span className="dwmBtns dwmBtnsActive">
                        {buttonName === 'User' ? language.team : language.status}
                      </span>
                      <span
                        className="dwmBtns"
                        style={{ cursor: 'pointer' }}
                        onClick={() => setButtonName(prev => (prev === 'Detail' ? 'User' : 'Detail'))}
                      >
                        {'<'}
                      </span>
                      <span
                        className="dwmBtns"
                        style={{ cursor: 'pointer' }}
                        onClick={() => setButtonName(prev => (prev === 'Detail' ? 'User' : 'Detail'))}
                      >
                        {'>'}
                      </span>
                    </div>
                  ) : (
                    <div> </div>
                  )}

                  {/* right side buttons */}
                  <div className="detailBtnGroups">
                    <button
                      style={{ position: 'relative' }}
                      className={`slctUser mr-2 ${buttonName === 'User' && tabDetailsColorMemo}`}
                      onClick={() => {
                        // setButtonName("User")
                        // setDetailName(tabDetailsMemo)
                        setIsSelectUser(prev => !prev);
                      }}
                    >
                      {language.Select_User}
                    </button>
                    {isSelectUser && (
                      <div className="selectUserCelDrop">
                        <p onClick={() => viewAllCalData()}>View All</p>
                        {assignUserData?.map((item, i) => (
                          <p key={i} className="" onClick={() => handleClickSubUser(item)}>
                            {item?.name + ' ' + item?.surName}
                          </p>
                        ))}
                      </div>
                    )}

                    <button
                      className={`slctUser ${buttonName === 'Detail' && tabDetailsColorMemo}`}
                      onClick={() => {
                        setButtonName('Detail');
                        setDetailName(tabDetailsMemo);
                      }}
                    >
                      {tabDetailsMemo}
                    </button>
                  </div>
                </div>

                <div>
                  {/* Big Calender */}
                  {detailName === '' && (
                    <div className="taskBigCalDiv">
                      <Calendar
                        localizer={localizer}
                        events={calenderEvent}
                        selectable={true}
                        onNavigate={handleSelectSlot} //for date
                        onView={handleSelectDayType} //for day type
                        startAccessor="start"
                        endAccessor="end"
                        style={{ height: '768px' }}
                        eventPropGetter={eventStyleGetter}
                        onSelectEvent={handleClickEvent}
                        showAllEvents={true}
                      // messages={{ agenda: "my Agenda Name" }}
                      // components={components}
                      // views={['month', 'day', 'agenda']}
                      />
                    </div>
                  )}

                  {/* Color Full Tab Part --> Don't delete the below part */}
                  {/* drag and drop part */}

                  {/* Task */}
                  {buttonName === 'Detail' && detailName === 'Task Details' && (
                    <TaskList
                      setModala={setModala}
                      taskData={taskData}
                      getTaskData={getTaskData}
                      setTaskData={setTaskData}
                    />
                  )}

                  {buttonName === 'User' && detailName === 'Task Details' && (
                    <TaskListUser allData={taskAllData} setAllData={setTaskAllData} />
                  )}

                  {/* Event */}
                  {buttonName === 'Detail' && detailName === 'Events Details' && (
                    <EventList eventData={eventData} getEventData={getEventData} setEventData={setEventData} />
                  )}

                  {buttonName === 'User' && detailName === 'Events Details' && (
                    <EventListUser allData={eventAllData} setAllData={setEventAllData} />
                  )}

                  {/* project */}
                  {buttonName === 'Detail' && detailName === 'Projects Details' && (
                    <ProjectList
                      projectData={projectData}
                      getProjecData={getProjecData}
                      setProjectData={setProjectData}
                    />
                  )}

                  {buttonName === 'User' && detailName === 'Projects Details' && (
                    <ProjectListTeam allData={preojectAllData} setProjectAllData={setProjectAllData} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {modala && <TaskPopUp closemodal={setModala} detailData={taskDetailData} getTaskData={getTaskData} />}

      {modalb && <EventPopUp closemodal={setModalb} detailData={taskDetailData} getEventData={getEventData} />}

      {modalc && <ProjectPopUp closemodal={setModalc} detailData={taskDetailData} getProjecData={getProjecData} />}
    </>
  );
}

export default EventsCalender;
