import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useLanguage } from '../Providers/LanguageContext'
import girlimg from "../images/1turquesa.png"
import { toast } from 'react-hot-toast';
import HttpClient from '../utils/HttpClient';
import { Watch } from 'react-loader-spinner';
import CustomModal from './CustomModal';
import moment from 'moment';
import { useRequestData } from '../hooks/RequestDataHook';
import { AiOutlineClose, git } from "react-icons/ai";
import { SpeechContext } from '../Providers/SpeechWrapper';
import expense_home_en_1 from "../Audio_files/III. Expense Manager Audio/III a. Post New Expense User Selected Option/iii_post_new_expense_audio_a.mp3";
import expense_home_en_2 from "../Audio_files/III. Expense Manager Audio/III a. Post New Expense User Selected Option/iii_post_new_expense_audio_b.mp3";
import expense_home_es_1 from "../spanish_audio_files/III. Expense Manager Audio/III a. Post New Expense User Selected Option/iii_post_new_expense_audio_a_es.mp3";
import expense_home_es_2 from "../spanish_audio_files/III. Expense Manager Audio/III a. Post New Expense User Selected Option/iii_post_new_expense_audio_b_es.mp3";
import expense_home_en_3 from "../Audio_files/III. Expense Manager Audio/III a. Post New Expense User Selected Option/iii_post_new_expense_audio_c.mp3";
import expense_home_es_3 from "../spanish_audio_files/III. Expense Manager Audio/III a. Post New Expense User Selected Option/iii_post_new_expense_audio_c_es.mp3";
import expense_home_en_4 from "../Audio_files/III. Expense Manager Audio/III a. Post New Expense User Selected Option/iii_post_new_expense_audio_d.mp3";
import expense_home_es_4 from "../spanish_audio_files/III. Expense Manager Audio/III a. Post New Expense User Selected Option/iii_post_new_expense_audio_d_es.mp3";
import expense_home_en_5 from "../Audio_files/III. Expense Manager Audio/III a. Post New Expense User Selected Option/iii_post_new_expense_audio_e.mp3";
import expense_home_en_6 from "../Audio_files/III. Expense Manager Audio/III a. Post New Expense User Selected Option/iii_post_new_expense_audio_f.mp3";
import expense_home_es_5 from "../spanish_audio_files/III. Expense Manager Audio/III a. Post New Expense User Selected Option/iii_post_new_expense_audio_e_es.mp3";
import expense_home_es_6 from "../spanish_audio_files/III. Expense Manager Audio/III a. Post New Expense User Selected Option/iii_post_new_expense_audio_f_es.mp3";
import axios from 'axios';
import { useUserData } from '../Providers/UserWrapper';
import CustomButton from './CustomButton';
import CustomLoaderLine from './Loader/CustomLoaderLine';
function Billcnt({ time, setTime }) {
    const { language, currentLanguage } = useLanguage()
    const { currAudio, setCurrAudio, playing, setShowGif, setGifToShow, setGifPoints } = useContext(SpeechContext);
    const { isBusiness, profileData } = useUserData();

    const timerRef = useRef()
    const inputRef = useRef()
    const [isPlayingFirstAudio, setIsPlayingFirstAudio] = useState(false);
    const [isPlayingPostAudio, setIsPlayingPostAudio] = useState(false);
    const [cashCreditClicked, setCashCreditClicked] = useState(false);
    const [amount, setAmount] = useState("");
    const [expenseType, setExpenseType] = useState("");
    const [vendorName, setVendorName] = useState("");
    const [picture, setPicture] = useState("");
    const [loading, setLoading] = useState(false);
    const [isCustonerName, setIsCustomerName] = useState(false);
    const [searchData, setSearchData] = useState([]);
    const [isSave, setIsSave] = useState(false)
    const { isCipAi } = useUserData();
    const [isSaveVendor, setIsSaveVendor] = useState(false);
    const [recurringDate, setRecurringDate] = useState(moment().format("YYYY-MM-DD"))

    const [values, setValues] = useState({
        transactionDate: "",
        projectName: "",
        expenseTypeID: "",
        vendorCredit: 0
    })
    console.log('expenseTypeYY', expenseType)

    const { loading: expense_type_loading, data, error } = useRequestData(isBusiness ? "transaction-type" : "transaction-type-personal", "GET", {})
    console.log('dataexp', data)

    const { loading: project_type_loading, data: project, project_error } = useRequestData("projectname", "GET", {});
    console.log('dataPro', project)
    const [modalIsOpen, setIsOpen] = useState(false);

    const today = new Date();
    const [isPost, setIsPost] = useState(false);
    const [autoSyncLoad, setAutoSyncLoad] = useState(false);

    const [weekList, setWeekList] = useState([]);
    const getCurrWeekMemo = useMemo(() => {
        let currentDate = new Date();
        let startDate = new Date(currentDate.getFullYear(), 0, 1);
        let days = Math.floor((currentDate - startDate) /
            (24 * 60 * 60 * 1000));
        let weekNumber = Math.ceil(days / 7);
        return weekNumber?.toString()
    }, [time, isPost]);

    const [weekDate, setWeekDate] = useState({
        weekNumber: "",
        startDate: "",
        endDate: ""
    });
    const [yearList, setYearList] = useState([]);
    console.log("yearList", yearList)

    const [months, setMonths] = useState([
        { label: 'January', value: 'January', number: 0, month: "01" },
        { label: 'February', value: 'February', number: 1, month: "02" },
        { label: 'March', value: 'March', number: 2, month: "03" },
        { label: 'April', value: 'April', number: 3, month: "04" },
        { label: 'May', value: 'May', number: 4, month: "05" },
        { label: 'June', value: 'June', number: 5, month: "06" },
        { label: 'July', value: 'July', number: 6, month: "07" },
        { label: 'August', value: 'August', number: 7, month: "08" },
        { label: 'September', value: 'September', number: 8, month: "09" },
        { label: 'October', value: 'October', number: 9, month: "10" },
        { label: 'November', value: 'November', number: 10, month: "11" },
        { label: 'December', value: 'December', number: 11, month: "12" },
    ]);

    // console.log('weekList', weekList.filter((item) => item.weekNumber == getCurrWeekMemo)[0].startDate)

    useEffect(() => {
        setWeekDate({
            weekNumber: getCurrWeekMemo,
            startDate: weekList.length && weekList.filter((item) => item.weekNumber == getCurrWeekMemo)[0].startDate,
            endDate: weekList.length && weekList.filter((item) => item.weekNumber == getCurrWeekMemo)[0].endDate,
        })
    }, [weekList, isPost])


    function openModal() {
        setIsOpen(true);
    }


    function closeModal() {
        setIsOpen(false);
    }

    const handleExpensePost = () => {
        // console.log(time, "time");
        // console.log(amount, "amount");
        // console.log(expenseType, "expenseType");
        // console.log(vendorName, "vendorName");

        if (!time) {
            toast.error(language.timeframe_error);
            return;
        }
        if (!amount) {
            toast.error(language.amount_error);
            return;
        }
        if (!expenseType) {
            toast.error(language.type_error);
            return;
        }
        if (!vendorName) {
            toast.error(language.provider_name_error);
            return;
        }
        openModal()
        // if (!picture) {
        //     toast.error(language.picture_error);
        //     return
        // }
    }

    const handleImageUpload = async (e) => {
        const file = e.target.files[0]
        if (file) {
            const data = new FormData();
            data.append("image", file)
            setLoading(true)
            // console.log('imgData',data)
            const res = await HttpClient.fileUplode("upload", "POST", data);
            setLoading(false)
            // inputRef.current.value = ""
            console.log('imgres', res)
            if (res && res.status) {
                setPicture(res.url);
                toast.success(language.img_upload_msg)
                if (isCipAi) {
                    OCRdataFecthing(file)
                }
            }
        }
    }

    const OCRdataFecthing = (file) => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('api_key', '5x6kLs_Nw6bce_YwUG3TRdoz');

        axios.post('https://ocr.asprise.com/api/v1/receipt', formData)
            .then(response => {
                if (response.data.success) {
                    console.log("ocr data", response.data)

                    setAmount(response.data.receipts[0].total)
                    setVendorName(response.data.receipts[0].merchant_name)
                    setExpenseType("trx");

                    setValues((prev) => {
                        return { ...prev, transactionDate: response.data.receipts[0].date, }
                    })

                }

                // setReceiptInfo(response.data);
            })
            .catch(error => {
                console.log(error);
            });
    }

    const handleExpensePostData = async () => {

        // const sendData = {
        //     price: parseInt(amount),
        //     vendorName,
        //     type: expenseType,
        //     vendorImage: picture,
        //     dayType: time,
        //     ...values,
        //     vendorCredit: parseInt(values.vendorCredit)
        // }

        const sendData = {
            dayType: time,
            price: parseInt(amount),
            type: expenseType,
            transactionDate: expenseType === "recurring" ? recurringDate : values.transactionDate,
            projectName: values.projectName,
            vendorName: vendorName,
            vendorImage: picture,
            vendorCredit: parseInt(values.vendorCredit),
            expenseTypeID: values.expenseTypeID
        }
        // console.log("dataaa", data)


        if (!sendData.transactionDate) {
            toast.error(language.transaction_error);
            return;
        }
        if (!sendData.expenseTypeID) {
            toast.error(language.select_expense_id);
            return;
        }


        const res = await HttpClient.requestData("add-expense", "POST", sendData);
        console.log(res, "resexpensedata")
        if (res && res.status) {
            setIsPost(!isPost);
            if (res.message && res.message === "Recurring Expense Setup 50 points") {
                setShowGif(true);
                setGifToShow("Recurring Expense Setup 50 points")
                setGifPoints(50)
            }
            toast.success(language.expense_success);
            setValues({
                transactionDate: "",
                projectName: "",
                expenseTypeID: "",
                vendorCredit: 0
            })

            if (time === "day") {
                setValues(prev => ({
                    ...prev,
                    // dayType: time,
                    transactionDate: moment().format("YYYY-MM-DD")
                }));
            } else if (time === "week") {
                setValues(prev => ({
                    ...prev,
                    // dayType: time,
                    transactionDate: weekDate.startDate
                }));
            } else if (time === "month") {
                let month = months.filter((item) => item.number == today.getMonth())[0].month;
                // console.log("monthOBj", month)
                setValues(prev => ({
                    ...prev,
                    // dayType: time,
                    transactionDate: today.getFullYear() + "-" + month + "-01"
                }));
            } else if (time === "year") {
                setValues(prev => ({
                    ...prev,
                    // dayType: time,
                    transactionDate: today.getFullYear() + "-01-01"
                }));
            } else {
                setValues(prev => ({
                    ...prev,
                    // dayType: time,
                    transactionDate: moment().format("YYYY-MM-DD")
                }));
            }

            setRecurringDate(moment().format("YYYY-MM-DD"))
            setAmount("");
            setExpenseType("");
            setVendorName("");
            setPicture("")
            // setTime("")
            closeModal();

        } else {
            toast.error(language.expense_error)
        }
        console.log(sendData)
    }


    const removeDuplicates = (arr) => {
        return arr.filter((item, index) => arr.indexOf(item) === index);
    }


    const fetchSerchName = async (name) => {
        const res = await HttpClient.requestData("expense-search/" + name, "GET");
        console.log('searchNameexp', res)
        let arr = [];
        if (res && res.status) {
            res.data.forEach(element => {
                arr.push(element.vendorName)
            });
            // console.log('element',arr)
            setSearchData(removeDuplicates(arr))
        } else {
            setSearchData([])
        }
    }

    const fetchOcr = () => {
        fetch('http://ocr.asprise.com/api/v1/receipt')
            .then(() => {

            })
    }

    const removeDuplicate = (arr) => {
        const unique = arr.filter((obj, index) => {
            return index === arr.findIndex(o => obj.projectName === o.projectName);
        });
        return unique;
    }
    // console.log('removeDuplicate',removeDuplicate(project))

    useEffect(() => {
        if (expenseType !== "trx") {
            if (time === "day") {
                setExpenseType("monthly")
            } else {
                setExpenseType("recurring")
            }
        }

        if (time === "day") {
            setValues(prev => ({
                ...prev,
                // dayType: time,
                transactionDate: moment().format("YYYY-MM-DD")
            }));
        } else if (time === "week") {
            setValues(prev => ({
                ...prev,
                // dayType: time,
                transactionDate: weekDate.startDate
            }));
        } else if (time === "month") {
            let month = months.filter((item) => item.number == today.getMonth())[0].month;
            console.log("monthOBj", month)
            setValues(prev => ({
                ...prev,
                // dayType: time,
                transactionDate: today.getFullYear() + "-" + month + "-01"
            }));
        } else if (time === "year") {
            setValues(prev => ({
                ...prev,
                // dayType: time,
                transactionDate: today.getFullYear() + "-01-01"
            }));
        } else {
            setValues(prev => ({
                ...prev,
                // dayType: time,
                transactionDate: moment().format("YYYY-MM-DD")
            }));
        }

        if (expenseType === "recurring") {
            setValues(prev => ({
                ...prev,
                // dayType: time,
                transactionDate: recurringDate
            }));
        }

        setWeekList(getWeeksInYear(today.getFullYear()));
        setYearList(NewgetAllYear());
    }, [time, recurringDate, expenseType]);

    const getWeeksInYear = (year) => {
        const weeks = [];
        const date = new Date(year, 0, 1); // January 1st of the year
        while (date.getFullYear() === year) {
            const weekNumber = getWeekNumber(date);
            const startDate = new Date(date);
            const endDate = new Date(date)
            endDate.setDate(date.getDate() + 6);
            weeks.push({
                weekNumber: weekNumber?.toString(),
                startDate: moment(startDate).format('YYYY-MM-DD'),
                endDate: moment(endDate).format('YYYY-MM-DD')
            });
            date.setDate(date.getDate() + 7);
        }
        return weeks;
    }

    function getWeekNumber(date) {
        const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
        const daysSinceFirstDay = (date - firstDayOfYear) / 86400000; // 86400000 = milliseconds in a day
        return Math.ceil((daysSinceFirstDay + firstDayOfYear.getDay() + 1) / 7);
    }


    function NewgetAllYear() {
        const currentYear = new Date().getFullYear();
        const range = (start, stop, step) =>
            Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);
        return range(currentYear, currentYear - 50, -1).map(item => {
            return {
                label: item.toString(),
                value: item.toString(),
            };
        });
    }

    // plaid api
    const handlePlaid = async () => {
        const data = {
            userId: profileData?._id
        }
        setAutoSyncLoad(true);
        const res = await HttpClient.requestData("plaid-auto-post-expense", "POST", data);
        if (res && res?.status) {
            toast.success(language.auto_sync_success);
            setAutoSyncLoad(false);
        } else {
            setAutoSyncLoad(false);
        }
    }


    useEffect(() => {
        if (playing) {
            if (currAudio) {
                if (!currAudio.paused) {
                    currAudio.pause()
                }
            }
            if (currentLanguage === "eng") {
                setCurrAudio(new Audio(expense_home_en_1))
                setIsPlayingFirstAudio(true)
            } else {
                setCurrAudio(new Audio(expense_home_es_1))
                setIsPlayingFirstAudio(true)
            }
        } else {
            if (currAudio) {
                if (!currAudio.paused) {
                    currAudio.pause()
                    setCurrAudio(null)
                }
            }
        }

        return () => {
            if (currAudio && !currAudio.paused) {
                currAudio.pause()
            }
        }
    }, [playing, currentLanguage])


    useEffect(() => {
        if (playing && isPlayingFirstAudio && currAudio) {
            currAudio.onended = function () {
                if (currentLanguage === "eng") {
                    setCurrAudio(new Audio(expense_home_en_2));
                    setIsPlayingFirstAudio(false)
                } else {
                    setCurrAudio(new Audio(expense_home_es_2));
                    setIsPlayingFirstAudio(false)
                }
            }

        } else {
            if (currAudio) {
                if (!currAudio.paused) {
                    currAudio.pause()
                    setCurrAudio(null)
                }
            }
        }

        return () => {
            if (currAudio && !currAudio.paused) {
                currAudio.pause()
            }
        }
    }, [isPlayingFirstAudio, playing])

    // useEffect(() => {
    //     console.log("currAudio", currAudio)
    //     if (playing) {
    //         if (currAudio) {
    //             if (!currAudio.paused) {
    //                 currAudio.pause()
    //             }
    //             currAudio.play()
    //         }
    //     }
    //     return () => {
    //         if (currAudio && !currAudio.paused) {
    //             currAudio.pause()
    //         }
    //     }
    // }, [currAudio])


    useEffect(() => {
        if (playing) {
            if (cashCreditClicked) {
                // alert("clicked")
                if (currentLanguage === "eng") {
                    setCurrAudio(new Audio(expense_home_en_3));
                } else {
                    setCurrAudio(new Audio(expense_home_es_3));
                }
            }
        }
        return () => {
            if (currAudio && !currAudio.paused) {
                currAudio.pause()
            }
        }
    }, [cashCreditClicked])


    useEffect(() => {
        if (playing) {
            if (vendorName) {
                if (currentLanguage === "eng") {
                    if (timerRef.current) {
                        clearTimeout(timerRef.current)
                    }
                    timerRef.current = setTimeout(() => {
                        setCurrAudio(new Audio(expense_home_en_4))
                    }, 300);
                } else {
                    if (timerRef.current) {
                        clearTimeout(timerRef.current)
                    }
                    timerRef.current = setTimeout(() => {
                        setCurrAudio(new Audio(expense_home_es_4))
                    }, 300)
                }
            }
        }
        return () => {
            if (currAudio && !currAudio.paused) {
                currAudio.pause()
            }
        }
    }, [isSaveVendor]);

    useEffect(() => {
        if (playing) {
            if (modalIsOpen) {
                if (currentLanguage === "eng") {
                    setCurrAudio(new Audio(expense_home_en_5))
                    setIsPlayingPostAudio(true)
                } else {
                    setCurrAudio(new Audio(expense_home_es_5))
                    setIsPlayingPostAudio(true)
                }
            } else {
                if (currAudio && !currAudio.paused) {
                    currAudio.pause()
                }
            }
        }
        return () => {
            if (currAudio && !currAudio.paused) {
                currAudio.pause()
            }
        }
    }, [modalIsOpen])


    useEffect(() => {
        if (playing) {
            if (currAudio) {
                currAudio.onended = function () {
                    if (currentLanguage === "eng") {
                        setCurrAudio(new Audio(expense_home_en_6))
                    } else {
                        setCurrAudio(new Audio(expense_home_es_6))
                    }
                }
            }
        }
        return () => {
            if (currAudio && !currAudio.paused) {
                currAudio.pause()
            }
        }
    }, [isPlayingPostAudio])


    return (

        <>

            <div id='bill-cnt'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-xl-7 col-lg-7 col-md-7 col-12'>
                            <div className='bill-left'>
                                <div className='heading'>
                                    <h2>{language.bills}</h2>
                                </div>
                                <div className='bill-cnt'>
                                    <div className='top-cnt'>
                                        <form>
                                            <div className="form-group">
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    id=""
                                                    aria-describedby=""
                                                    placeholder={language.amount}
                                                    value={amount}
                                                    onChange={(e) => {
                                                        if(e.target.value < 0) {
                                                            return
                                                        }
                                                        setAmount(e.target.value)
                                                    }}
                                                />
                                            </div>
                                        </form>
                                    </div>
                                    <div className='top-btn'>
                                        <button className='txr-btn'
                                            disabled={expenseType === "trx"}
                                            onClick={() => {
                                                setExpenseType("trx");
                                                toast.success(language.select_trx)
                                                setCashCreditClicked("trx")
                                            }}>TRX</button>
                                        <button
                                            className='monthly-btn'
                                            disabled={expenseType === "recurring" || expenseType === "monthly"}
                                            onClick={() => {
                                                if (time === "day") {
                                                    setExpenseType("monthly")
                                                } else {
                                                    setExpenseType("recurring")
                                                }
                                                toast.success(language.select_monthly)
                                                setCashCreditClicked("recurring")
                                            }}
                                        >
                                            {
                                                time === "day"
                                                    ?
                                                    language.monthly
                                                    :
                                                    language.recurring
                                            }
                                        </button>
                                    </div>
                                    <div className='btm-cnt'>
                                        <form>
                                            <div className="form-group">
                                                <label htmlFor="">{language.vendor_name}</label>
                                                {/* <input
                                                    type="text"
                                                    className="form-control"
                                                    id=""
                                                    aria-describedby=""
                                                    placeholder={language.provider_name}
                                                    value={vendorName}
                                                    onChange={(e) => {
                                                        // let val = e.target.value;
                                                        if (/^[A-Za-z\s]*$/.test(e.target.value)) {
                                                            setVendorName(e.target.value)
                                                        }
                                                    }}
                                                /> */}
                                                <input
                                                    type='text'
                                                    placeholder={language.vendor_name}
                                                    readOnly

                                                    value={vendorName}
                                                    onClick={() => {
                                                        setIsCustomerName(true)
                                                        setIsSave(false)
                                                    }
                                                    }
                                                />
                                                <CustomModal
                                                    modalIsOpen={isCustonerName}
                                                    setIsOpen={setIsCustomerName}
                                                    openModal={() => setIsCustomerName(true)}
                                                    closeModal={() => setIsCustomerName(false)}
                                                >
                                                    <div>
                                                        <div className='d-flex justify-content-between text-secondary'>
                                                            <span>
                                                                {/* {language.confirmation} */}
                                                            </span>
                                                            <span>
                                                                <AiOutlineClose className='icon' onClick={() => setIsCustomerName(false)} size={20} color="gray" />
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="input-group mb-3">
                                                        <div style={{ width: "80%" }}>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder={language.vendor_name}
                                                                aria-label="Recipient's username"
                                                                aria-describedby="basic-addon2"
                                                                value={vendorName}
                                                                onChange={(e) => {
                                                                    // console.log(e.target.value)
                                                                    if (/^[A-Za-z\s]*$/.test(e.target.value)) {
                                                                        setVendorName(e.target.value)
                                                                        fetchSerchName(e.target.value)
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                        <button
                                                            className='customer_name_button_revenew btn btn-primary'
                                                            onClick={() => {
                                                                setIsSave(true);
                                                                setIsCustomerName(false);
                                                                setIsSaveVendor(!isSaveVendor);
                                                            }}
                                                        >
                                                            {language.save}
                                                        </button>
                                                        {
                                                            searchData.map((item, i) =>
                                                                <div className='search_name' key={i}>
                                                                    <p
                                                                        style={{ margin: '0' }}
                                                                        onClick={() => {
                                                                            setVendorName(item)
                                                                            setSearchData([]);
                                                                        }}
                                                                    >
                                                                        {item}
                                                                    </p>
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                </CustomModal>
                                            </div>
                                        </form>
                                    </div>
                                    <div className='btm-btn' onClick={handleExpensePost}>
                                        <button type="submit" className='post-btn'>
                                            {language.post}
                                        </button>
                                    </div>

                                    <CustomButton
                                        text={!autoSyncLoad ? language.auto_sync : <CustomLoaderLine />}
                                        className="customBtn2"
                                        onClick={handlePlaid}
                                    />

                                </div>
                            </div>
                        </div>

                        <div className='col-xl-5 col-lg-5 col-md-5 col-12 d-flex justify-content-center align-items-center' style={{ paddingTop: "80px" }}>
                            <div className='bill-right'>
                                <label className="form_label" style={{ width: "100%", height: "100%" }}>
                                    <input type="file" r
                                        ef={inputRef}
                                        className="form-control"
                                        id="uploadFile"
                                        onChange={handleImageUpload}
                                        accept="image/*"
                                    />
                                    {!loading && !picture && (
                                        <span style={{ marginTop: "100px" }}>
                                            {!loading && !picture && language.image_send}
                                        </span>
                                    )}
                                    {
                                        loading && (
                                            <Watch
                                                height="80"
                                                width="80"
                                                radius="48"
                                                color="#4fa94d"
                                                ariaLabel="watch-loading"
                                                wrapperStyle={{}}
                                                wrapperClassName=""
                                                visible={true}
                                            />
                                        )
                                    }
                                    <div>
                                        {
                                            !loading && picture && (
                                                <img src={picture} width={300} height={350} style={{ objectFit: "cover" }} />
                                            )
                                        }
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* modal section */}



            <CustomModal
                modalIsOpen={modalIsOpen}
                setIsOpen={setIsOpen}
                openModal={openModal}
                closeModal={closeModal}
            >
                <div>
                    <div className='d-flex justify-content-between text-secondary'>
                        <span>
                            {language.confirmation}
                        </span>
                        <span>
                            <AiOutlineClose className='icon' onClick={closeModal} size={20} color="gray" />
                        </span>
                    </div>
                    <div className='d-flex flex-column income-confirm-data'>
                        <span>{language.expense}: ${amount}</span>
                        <span>{language.provider_name}: {vendorName}</span>
                        {
                            expenseType === "recurring" &&
                            <span>
                                <label htmlFor="dateinput">
                                    {language.recurring_date}
                                </label>
                                <input type="date" className='mx-2' name="dateinput" id="dateinput"
                                    style={{ padding: "3px" }}
                                    value={recurringDate}
                                    onChange={(e) => {
                                        setRecurringDate(e.target.value)
                                        setValues(prev => ({ ...prev, transactionDate: moment(e.target.value).format("YYYY-MM-DD") }))
                                    }
                                    }
                                />
                            </span>
                        }
                        <span>{language.current_date}: {moment(new Date()).format("LL")}</span>
                        {
                            time === "day" &&
                            <span>
                                <label htmlFor="dateinput">
                                    {language.transaction_date}
                                </label>
                                <input type="date" className='mx-2' name="dateinput" id="dateinput"
                                    style={{ padding: "3px" }}
                                    value={values.transactionDate}
                                    onChange={(e) => setValues(prev => ({ ...prev, transactionDate: moment(e.target.value).format("YYYY-MM-DD") }))}
                                />
                            </span>
                        }

                        {
                            time === "week" &&
                            <>
                                <span>
                                    <label htmlFor="dateinput">{language.choose_week}</label>
                                    {/* <input type="date" className='mx-2' name="dateinput" id="dateinput"
                                        onChange={(e) => setValues(prev => ({ ...prev, transactionDate: moment(e.target.value).format("YYYY-MM-DD") }))}
                                    /> */}
                                    <select onChange={(e) => {
                                        setWeekDate(JSON.parse(e.target.value));
                                        setValues((prev) => { return { ...prev, transactionDate: JSON.parse(e.target.value).startDate } })
                                    }}
                                    >
                                        {weekList.map((item, i) =>
                                            <option
                                                value={JSON.stringify(item)}
                                                selected={item.weekNumber == getCurrWeekMemo ? true : false}
                                            >
                                                {item.weekNumber}
                                            </option>
                                        )
                                        }
                                    </select>
                                </span>
                                <span>{language.start_date}: {weekDate.startDate}</span>
                                <span>{language.end_date}: {weekDate.endDate}</span>
                            </>
                        }

                        {
                            time === "month" &&
                            <span>
                                <label htmlFor="dateinput">{language.choose_month}</label>
                                <select
                                    onChange={(e) => {
                                        setValues((prev) => { return { ...prev, transactionDate: today.getFullYear() + "-" + e.target.value + "-01" } })
                                    }}
                                >
                                    {
                                        months.map((item, i) =>
                                            <option
                                                value={item.month}
                                                selected={item.number == today.getMonth() ? true : false}
                                            >
                                                {item.label}
                                            </option>
                                        )
                                    }
                                </select>
                            </span>
                        }

                        {
                            time === "year" &&
                            <span>
                                <label htmlFor="dateinput">{language.choose_year}</label>
                                <select
                                    onChange={(e) => {
                                        setValues((prev) => { return { ...prev, transactionDate: e.target.value + "-01-01" } })
                                    }}
                                >
                                    {
                                        yearList.map((item, i) =>
                                            <option
                                                value={item.value}
                                                selected={item.number == today.getFullYear() ? true : false}
                                            >
                                                {item.label}
                                            </option>
                                        )
                                    }
                                </select>
                            </span>
                        }

                        <h6 className='mt-2'>
                            {language.expense_type}:
                        </h6>
                        <span>
                            <select onChange={(e) => {
                                console.log(e.target.value)
                                if (e.target.value !== language.select_expense_type) {
                                    setValues(prev => ({ ...prev, expenseTypeID: e.target.value }))
                                }
                            }}>
                                <option>{language.select_expense_type}</option>
                                {
                                    data && data.map((p, i) => (
                                        <option key={p._id} value={p._id}>
                                            {currentLanguage === "eng" ? p.name : p.spanish}
                                        </option>
                                    ))
                                }
                            </select>
                        </span>


                        <h6 className='mt-2'>
                            {language.vendor_credit}:
                        </h6>
                        <span>
                            <input type="text" name="vendorcredit" id="vendorcredit"
                                onChange={(e) => setValues(prev => ({ ...prev, vendorCredit: e.target.value }))}
                                placeholder={language.vendor_credit}
                            />
                        </span>
                        <span>
                            {language.type}: {expenseType === "trx" && "TRX"} {expenseType === "monthly" && language.monthly} {expenseType === "recurring" && language.recurring}
                        </span>
                        <span>
                            <select onChange={(e) => {
                                console.log(e.target.value)
                                if (e.target.value !== "Select project") {
                                    setValues(prev => ({ ...prev, projectName: e.target.value }))
                                }
                            }}>
                                <option>{language.select_project}</option>
                                {
                                    project && removeDuplicate(project).map((p, i) => (
                                        <option key={p._id} value={p.projectName}>{p.projectName}</option>
                                    ))
                                }
                            </select>
                        </span>
                        <div className='d-flex justify-content-center' style={{ gap: "10px", marginTop: "10px" }}>
                            <button className='customBtn4' onClick={handleExpensePostData}>{language.post}</button>
                            <button className='customBtn5' onClick={closeModal}>{language.cancel}</button>
                        </div>
                    </div>
                </div>

            </CustomModal>

        </>

    )
}

export default Billcnt;