import React, { useContext, useEffect, useState } from 'react'
import { Triangle } from 'react-loader-spinner'
import CustomDateBtn from '../../Component/CustomDateBtn'
import CustomLoader from '../../Component/CustomLoader'
import Dropbox from '../../Component/Dropbox'
import ListComp from '../../Component/ListComp'
import TopbarComp from '../../Component/TopbarComp'
import { useRequestData } from '../../hooks/RequestDataHook'
import { useLanguage } from '../../Providers/LanguageContext'
import HttpClient from '../../utils/HttpClient'
import revenue_edit1_en from "../../Audio_files/II. Revenue Manager Audio/II b. View & Edit Sales Revenue User Selected Option/ii_view_and_edit_sales_revenue_audio_a.mp3";
import revenue_edit1_es from "../../spanish_audio_files/II. Revenue Manager Audio/II b. View & Edit Sales Revenue User Selected Option/ii_view_and_edit_sales_revenue_audio_a_es.mp3";

import { SpeechContext } from '../../Providers/SpeechWrapper'
import { useLocation, useNavigate } from 'react-router-dom'
import { AiOutlineArrowLeft } from 'react-icons/ai'
import { useUserData } from '../../Providers/UserWrapper'

function TransactionDetails() {
  const { isInvestor, isBookKeeper } = useUserData();
  const { language, currentLanguage } = useLanguage();
  const location = useLocation();
  const navigate = useNavigate();
  const [projects, setProjects] = useState([]);
  const [isCleared, setIsCleared] = useState(false)
  const [transactionFilter, setTransactionsFilter] = useState({
    startDate: "",
    endDate: "",
    projectName: ""
  })
  const {
    currAudio, setCurrAudio, playing, setIsPlaying
  } = useContext(SpeechContext);

  // for dashboard
  const [hoverData, setHoverData] = useState({
    revenue: {},
    expense: {},
    revenueBudget: {},
    expenseBudget: {}
  })

  // Parse query parameters
  const searchParams = new URLSearchParams(location.search);
  const userCode = searchParams.get('id');

  // const { data,loading,error } = useRequestData("get-transaction", "POST", {
  //   startDate: "",
  //   endDate: "",
  //   projectName: ""
  // })

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false)

  console.log('locationGhhtrr', location.state)

  useEffect(() => {
    fetchProjects()
  }, [])

  useEffect(() => {
    setTransactionsFilter(prev => ({
      ...prev,
      startDate: location?.state?.startDate,
      endDate: location?.state?.endDate
    }))
  }, [location])

  const fetchProjects = async () => {
    const res = await HttpClient.requestData("projectname", "GET");
    console.log(res, "Resssss")
    if (res && res.status) {
      setProjects(res.data);
    } else {
      console.log("error fetching projects")
    }
  }



  const getStartDate = (value) => {
    setTransactionsFilter(prev => ({ ...prev, startDate: value }))
  }

  const getEndDate = (value) => {
    setTransactionsFilter(prev => ({ ...prev, endDate: value }))
  }

  const getProjectValue = (e) => {
    const val = e.target.value;
    if (val !== "Select project") {
      setTransactionsFilter(prev => ({ ...prev, projectName: val }))
    }
  }

  useEffect(() => {
    const { startDate, endDate, projectName } = transactionFilter;
    if (startDate && endDate) {
      fetchTransactions(transactionFilter)
    } else if (!startDate && !endDate && projectName) {
      fetchTransactions(transactionFilter)
    } else if (!startDate && !endDate && !projectName) {
      fetchTransactions(transactionFilter)
    }
  }, [transactionFilter])


  const fetchTransactions = async (filters) => {
    setLoading(true)
    const res = await HttpClient.requestData(`sales-transaction/${isInvestor || isBookKeeper ? userCode : ''}`, "GET");
    console.log('resGetTranbbh', res)
    setLoading(false)
    if (res && res.status) {
      setData(res.data);
    } else {
      console.log(res, "error")
    }
  }

  const clearFilters = () => {
    setTransactionsFilter({
      startDate: "",
      endDate: "",
      projectName: ""
    })
  }

  // gettinh hover data of dash board 2
  const getHoverData = async (time) => {
    const data = {
      "dayType": time
    }

    // revenue hover
    if (location?.state?.type === "revenue") {
      const res = await HttpClient.requestData("dashboard-two-hover-revenue", "POST", data);
      console.log("resddf", res)
      if (res && res?.status) {
        // setHoverData(prev => ({ ...prev, revenue: res?.data }));
        setTransactionsFilter((prev) => ({
          ...prev,
          startDate: res?.data?.startDate,
          endDate: res?.data?.endDate,
        }))
      } else {

      }
    }

    // // expense hover
    // const res1 = await HttpClient.requestData("dashboard-two-hover-expense", "POST", data);
    // console.log("tes1Exp", res1)
    // if (res1 && res1?.status) {
    //   setHoverData(prev => ({ ...prev, expense: res1?.data }))
    // } else {

    // }

    // revenue budget
    if (location?.state?.type === "revenue-budget") {
      const res2 = await HttpClient.requestData("dashboard-two-hover-revenue-budget", "POST", data);
      if (res2 && res2?.status) {
        // setHoverData(prev => ({ ...prev, revenueBudget: res2?.data }))
        setTransactionsFilter((prev) => ({
          ...prev,
          startDate: res2?.data?.startDate,
          endDate: res2?.data?.endDate,
        }))
      } else {

      }
    }

    // // expense budget
    // const res3 = await HttpClient.requestData("dashboard-two-hover-expense-budget", "POST", data);
    // if (res3 && res3?.status) {
    //   setHoverData(prev => ({ ...prev, expenseBudget: res3?.data }))
    // } else {

    // }
  }

  //for dashboard 2
  useEffect(() => {
    if (location?.state?.time) {
      getHoverData(location?.state?.time);
    }
  }, [location?.state?.time])

  useEffect(() => {
    console.log(currAudio, setCurrAudio, "funcsssss")
    if (playing) {
      if (currAudio) {
        if (!currAudio.paused) {
          currAudio.pause()
        }
      }
      if (currentLanguage === "eng") {
        setCurrAudio(new Audio(revenue_edit1_en))
      } else {
        setCurrAudio(new Audio(revenue_edit1_es))
      }
    } else {
      if (currAudio) {
        if (!currAudio.paused) {
          currAudio.pause()
          setCurrAudio(null)
        }
      }
    }

    return () => {
      if (currAudio && !currAudio.paused) {
        currAudio.pause()
      }
    }
  }, [playing, currentLanguage])

  // useEffect(() => {
  //   console.log("currAudio", currAudio)
  //   if (currAudio) {
  //     if (!currAudio.paused) {
  //       currAudio.pause()
  //     }
  //     currAudio.play()
  //   }
  //   return () => {
  //     if (currAudio && !currAudio.paused) {
  //       currAudio.pause()
  //     }
  //   }
  // }, [currAudio])


  return (
    <div className='container'>
      <div className='d-flex justify-content-start gap-3'>
        <div
          className="back-arrow-left"
          onClick={() => {
            navigate(-1)
          }
          }
        >
          <AiOutlineArrowLeft />
        </div>
        <div className='mt-3'>
          <button className='w-100 mb-2 btn btn-outline-success' style={{ fontWeight: "bold" }} onClick={() => navigate('/postnewincome')}>
            {language.post_new_income}
          </button>
        </div>
        {/* back arrow icon */}
      </div>
      <TopbarComp textprop={language.transaction_details} time={location?.state?.time} />
      <CustomDateBtn text={language.start_date} getValue={getStartDate} setIsCleared={setIsCleared} isCleared={isCleared} filters={{ date: transactionFilter?.startDate }} />
      <CustomDateBtn text={language.final_date} getValue={getEndDate} setIsCleared={setIsCleared} isCleared={isCleared} filters={{ date: transactionFilter?.endDate }} />
      <Dropbox transactionFilter={transactionFilter} text={language.select_project} data={projects} getValue={getProjectValue} />
      {
        ((transactionFilter.startDate && transactionFilter.endDate) || (transactionFilter.projectName)) && (
          <div className='container'>
            <button className='customBtn3' onClick={clearFilters}>{language.clear_filters}</button>
          </div>
        )
      }

      {
        loading && (
          <CustomLoader />
        )
      }
      <ListComp transactions={data} transactionFilter={transactionFilter} />
    </div>
  )
}


export default TransactionDetails