import React, { useEffect, useRef, useState } from 'react'
import { useContext } from 'react'
import { Watch } from 'react-loader-spinner'
import Clasification from '../../../Component/Clasification'
import Dropbox from '../../../Component/Dropbox'
import Managementtop from '../../../Component/Managementtop'
import { useRequestData } from '../../../hooks/RequestDataHook'
import { useLanguage } from '../../../Providers/LanguageContext'
import { SpeechContext } from '../../../Providers/SpeechWrapper'
import HttpClient from '../../../utils/HttpClient'
import expense_details_en1 from "../../../Audio_files/III. Expense Manager Audio/III b. View Expense Detail User Selected Option/iii_view_expense_detail_audio_a.mp3";
import expense_details_es1 from "../../../spanish_audio_files/III. Expense Manager Audio/III b. View Expense Detail User Selected Option/iii_view_expense_detail_audio_a_es.mp3";
import expense_details_en2 from "../../../Audio_files/III. Expense Manager Audio/III b. View Expense Detail User Selected Option/iii_view_expense_detail_audio_b.mp3";
import expense_details_es2 from "../../../spanish_audio_files/III. Expense Manager Audio/III b. View Expense Detail User Selected Option/iii_view_expense_detail_audio_b_es.mp3";
import moment from 'moment'
import { useLocation, useNavigate } from 'react-router-dom'


function NewExpenseDetails() {

  const searchBoxRef = useRef();
  const timerRef = useRef();
  const navigate = useNavigate()
  const { language, currentLanguage } = useLanguage();
  const { currAudio, setCurrAudio, playing } = useContext(SpeechContext);
  const [expenses, setExpenses] = useState([]);
  const [isCleared, setIsCleared] = useState(false)
  const [filters, setFilters] = useState({
    vendor: "",
    transDate: "",
    project: ""
  })

  const location = useLocation();
  console.log("locationdd", location.state);
  useEffect(() => {
    if (location?.state?.startDate) {
      setFilters(prev => ({ ...prev, transDate: location?.state?.startDate }))
    }
  }, [location])

  const [modalOpenSt, setModalOpenst] = useState(false);

  const getProjectVal = (val) => {
    const value = val.target.value;
    if (value !== "Select project") {
      setFilters(prev => ({ ...prev, project: value }))
    } else {
      setIsCleared(false)
      setFilters(prev => ({ ...prev, project: "" }))
    }
    console.log(val.target.value, "project value")
  }

  const { loading: projectsLoading, data: projectsData, error: projectsErr } = useRequestData("projectname", "GET", {});

  const fetchExpenses = async () => {
    const sendData = {
      // vendor: "",
      // transDate: "",
      // project: "",
      vendor: '',
      transDate: ''
    }
    const res = await HttpClient.requestData("expense-edit-view", "POST", sendData);
    console.log('expensedd', res)
    if (res && res.status) {
      const arr1 = res.data.filter((item) => item.vendorName?.toLowerCase().includes(filters.vendor?.toLowerCase()))
      const arr2 = filters.project ? arr1.filter((item) => item.projectName === filters.project) : arr1
      const arr3 = filters.transDate ? arr2.filter((item) => moment(item.transactionDate).format('yyyy-MM-DD') === filters.transDate) : arr2
      // console.log('compp', moment(arr2[0].transactionDate).format('yyyy-MM-DD')=== filters.transDate)
      // console.log('arr1exp', arr3)

      setExpenses(arr3);
    } else {
      console.log("Error", res)
    }

  }

  useEffect(() => {
    fetchExpenses()
    fetchExpensesByFilter();
  }, [filters])


  const getVendorName = (e) => {
    const value = e.target.value;
    if (timerRef.current) {
      clearTimeout(timerRef.current)
    }
    timerRef.current = setTimeout(() => {
      setFilters(prev => ({ ...prev, vendor: value }))
    }, 500)
  }

  const handleFilterClassChange = (e) => {
    const value = e.target.value;
    if (value === language.classification) {
      fetchExpenses()
    } else {
      fetchExpensesByFilter(value)
    }
    console.log(e.target.value)
  }

  const fetchExpensesByFilter = async (val) => {
    // const res = await HttpClient.requestData("expense-filter", "POST", { type: val });
    // if (res && res.status) {
    //   setExpenses(res.data)
    // } else {
    //   console.log("Error", res)
    // }
    console.log('valuee', val);
    if (val === 'Recent') {
      let arr = expenses;
      sortByDate(arr)
      let arr2 = arr.reverse()
      setExpenses([...arr2]);
    }
    else if (val === 'Oldest') {
      let arr = expenses;
      sortByDate(arr);
      setExpenses([...arr])
    }
    else if (val === 'Z-A') {
      let arr = expenses;
      sortByNamesVendor(arr);
      let arr2 = arr.reverse();
      setExpenses([...arr2]);
    }
    else if (val === 'A-Z') {
      let arr = expenses;
      sortByNamesVendor(arr);
      setExpenses([...arr]);
    }
  }

  const sortByDate = (val) => {
    console.log('sortval')
    val.sort((a, b) => {
      let da = new Date(a.transactionDate),
        db = new Date(b.transactionDate);
      return da - db;
    });
    // console.log('sortval', val)
    return val
  }

  const sortByNamesVendor = (val) => {
    val.sort((a, b) => {
      let fa = a.vendorName?.toLowerCase(),
        fb = b.vendorName?.toLowerCase();
      if (fa < fb) {
        return -1;
      }
      if (fa > fb) {
        return 1;
      }
      return 0;
    });
  }

  const getCreationDate = (value) => {
    console.log(value, "Creation date")
    setFilters(prev => ({ ...prev, transDate: value }))
  }

  useEffect(() => {
    if (playing) {
      if (currAudio) {
        if (!currAudio.paused) {
          currAudio.pause()
        }
      }
      if (currentLanguage === "eng") {
        setCurrAudio(new Audio(expense_details_en1))
      } else {
        setCurrAudio(new Audio(expense_details_es1))
      }
    } else {
      if (currAudio) {
        if (!currAudio.paused) {
          currAudio.pause()
          setCurrAudio(null)
        }
      }
    }

    return () => {
      if (currAudio && !currAudio.paused) {
        currAudio.pause()
      }
    }
  }, [playing, currentLanguage])


  // useEffect(() => {
  //   console.log("currAudio", currAudio)
  //   if (currAudio) {
  //     if (!currAudio.paused) {
  //       currAudio.pause()
  //     }
  //     currAudio.play()
  //   }
  //   return () => {
  //     if (currAudio && !currAudio.paused) {
  //       currAudio.pause()
  //     }
  //   }
  // }, [currAudio])


  useEffect(() => {
    console.log(modalOpenSt, "")
    if (playing) {
      if (modalOpenSt) {
        if (currentLanguage === "eng") {
          setCurrAudio(new Audio(expense_details_en2))
        } else {
          setCurrAudio(new Audio(expense_details_es2))
        }
      } else {
        if (currAudio && !currAudio.paused) {
          currAudio.pause()
        }
      }
    }
    return () => {
      if (currAudio && !currAudio.paused) {
        currAudio.pause()
      }
    }
  }, [modalOpenSt])

  return (
    <div className='container'>
      <div className='d-flex gap-2 mb-2' >
        <div className=''>
          <button className='w-100 btn btn-outline-success' style={{ fontWeight: "bold" }} onClick={() => navigate('/newexpense')}>{language.post_new_expense}</button>
        </div>

        <div className=''>
          <button className='w-100 btn btn-outline-success' style={{ fontWeight: "bold" }} onClick={() => navigate('/edit-expense-details')}>
            {language.edit_exp_detail}
          </button>
        </div>
      </div>
      <Managementtop
        title={language.search_expenses}
        isCleared={isCleared}
        getVendorName={getVendorName}
        setIsCleared={setIsCleared}
        getCreationDate={getCreationDate}
        searchBoxRef={searchBoxRef}
        filters={filters}
        time={location?.state?.time}
      />
      <Dropbox transactionFilter={filters} text={language.select_project} getValue={getProjectVal} data={projectsData} />

      <Clasification
        expenses={expenses}
        handleFilterClassChange={handleFilterClassChange}
        setFilters={setFilters}
        fetchExpenses={fetchExpenses}
        searchBoxRef={searchBoxRef}
        setIsCleared={setIsCleared}
        setModalOpenst={setModalOpenst}
      />
    </div>
  )
}

export default NewExpenseDetails