import React from 'react'

function Allnews({text,onClick}) {
    return (

        <>

            <div id='all-news'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-xl-12 col-lg-12 col-md-12 col-12'>
                            <div className='news-box'>
                                <ul onClick={()=>onClick(text)}>
                                    <li>
                                        <a href='#'>
                                            <div className='cnt-wrap'>
                                                <h4>{text || ""}</h4>
                                                <i class="fa-solid fa-chevron-right"></i>
                                            </div>
                                        </a>
                                    </li>
                                </ul>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Allnews