import React, { useCallback, useState } from 'react'
import CustomButton from '../../Component/CustomButton'
import CustomInputField from '../../Component/CustomInputField'
import Managebtn from '../../Component/Managebtn'
import TimeFrameComp from '../../Component/TimeFrameComp'
import girlimg from "../../images/ILUSTRACIONES/2 gris.png"
import IncomeForm from './IncomeForm'
import { useNavigate } from 'react-router-dom'
import { useLanguage } from '../../Providers/LanguageContext'

function CustomPageForm() {
    const [time, setTime] = useState("day")
    const navigate = useNavigate();
    const { language } = useLanguage();

    const getTime = (val) => {
        setTime(val)
    }

    return (
        <>
            <div className=''>
                <button className='w-25 mb-2 btn btn-outline-success' style={{ fontWeight: "bold", margin:"0 0 0 154px" }} onClick={() => navigate('/editexistingrevenue')}>
                    {language.view_edit}
                </button>
            </div>
            <Managebtn getTime={getTime} value={time} />

            <div className='container' style={{}}>
                <div className='row' style={{ paddingBottom: "60px" }}>
                    {/* <TimeFrameComp getTime={getTime} value={time} /> */}

                    {/* form */}
                    <div className="col-xl-8 col-lg-8 col-md-12 col-12">
                        <IncomeForm setTime={setTime} time={time} />
                    </div>

                    {/* image of right side */}
                    <div className="col-xl-4 col-lg-4 col-md-12 col-12">
                        <div style={{ width: "100%", height: "auto" }}>
                            <div style={{ width: "100%", height: "auto" }}>
                                <div style={{
                                    width: "100%",
                                    height: "auto",
                                    overflow: "hidden",
                                    margin: "40px 10px 10px 10px",
                                    objectFit: "contain",
                                    padding: "50px",
                                    background: "whitesmoke"
                                }}>
                                    <img src={girlimg} className="img-fluid" style={{ width: "100%", height: "100%" }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CustomPageForm;