import React from 'react';
import { useLanguage } from '../../Providers/LanguageContext'
import SignUpButton from './SignUpButton';
import iMg1 from "../../images/ilustraciones soluciones industriales_CUIDADO DE LA SALUD 1.png";
import iMg2 from "../../images/ilustraciones soluciones industriales_CUIDADO DE LA SALUD 2.png";
import iMg3 from "../../images/love-heartbeat.png";


const HealthCare = () => {
  const { language } = useLanguage();

  return (
    <div>
      {/* <section id='agriculture_sec'>
        <div className='container'>
          <div className='agriculture_head'>
            <div>
              <h3>{language.fisibility_key_feture}:</h3>
              
                <h2>{language.health_care} </h2>
          
            </div>
          </div>
          <div className='agriculture_list'>
            <ul>
              <li><strong>{language.automated_expence_track}: </strong>
                {language.healthcare_prof_often}
              </li>
               <li><strong>{language.busi_and_personal}: </strong>
                {language.healthcare_Prof_web}
              </li>
              <li><strong>{language.tax_prep_reporting}: </strong>
                {language.fisi_simplify_tax_pprep}
              </li>
              <li><strong>{language.invoicing_and_payment}: </strong>
                {language.health_proff_bill}
              </li>
            </ul>
          </div>

          <SignUpButton />
        </div>
      </section> */}

      <section id='agriculture_sec'>
        <div className='container'>
          <div className='row mb-5'>
            <div className='col-md-6 col-12 m-auto'>
              <div className='agriculture_head'>
                <h3 style={{ color: "#3fa9f5" }}>{language.health_care}</h3>
                <p style={{ color: "#3fa9f5" }}>{language.fisi_help_improve_health}</p>
              </div>

              <div className='agriculture_headtext'>
                <h4 style={{ color: "#ff1d25" }}>{language.automated_expence_track}</h4>
                <p>{language.healthcare_prof_often}</p>
                <p>{language.fisi_auto_expance_track}</p>
              </div>
            </div>
            <div className='col-md-6 col-12 m-auto'>
              <div className='agriculture_img'>
                <img src={iMg1} className='img-fluid' alt='' />
              </div>
            </div>
          </div>

          <div className='row row_reverse'>
            <div className='col-md-6 col-12 m-auto'>
              <div className='agriculture_img'>
                <img src={iMg2} className='img-fluid' alt='' />
              </div>
            </div>
            <div className='col-md-6 col-12 m-auto'>
              <div className='agriculture_headtext'>
                <h4 style={{ color: "#3fa9f5", textAlign: "right" }}>{language.busi_and_personal}</h4>
                <p>{language.healthcare_Prof_web}</p>
              </div>
            </div>
          </div>

          <div className='row mb-5'>
            <div className='col-md-12 col-12'>
              <div className='agriculture_headtext'>
                <h4 style={{ color: "#ff1d25", textAlign: "left" }}>{language.tax_prep_reporting}</h4>
                <p>{language.fisi_simplify_tax_pprep}</p>
              </div>
            </div>
          </div>

          <div className='row'>
            <div className='col-md-6 col-12 m-auto'>
              <div className='agriculture_headtext'>
                <h4 style={{ color: "#3fa9f5", textAlign: "left" }}>{language.invoicing_and_payment}</h4>
                <p>{language.health_proff_bill}</p>
                <p>{language.app_create_and_send}</p>
              </div>
            </div>

            <div className='col-md-6 col-12 m-auto'>
              <div className='agriculture_img'>
                <img src={iMg3} className='img-fluid' alt='' style={{opacity: "0.5"}} />

                {/* <div className='registrarAnddescargar' style={{ transform: "translate(-50% , -50%)", top: "85%", }}>
                  <button className='registrar_Btn'>Registrarse</button>
                  <button className='descargar_Btn'>Descargar</button>
                </div> */}
                <SignUpButton/>
              </div>
            </div>
          </div>
        </div>
      </section>

    </div>
  )
}

export default HealthCare