import React, { useContext, useState } from "react";
import Cipbitlogo from "../images/fisibility-logo (1).png";
import Calenderlogo from "../images/calender.png";
import Ftrlogo from "../../src/images/footer-logo.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useLanguage } from "../Providers/LanguageContext";
import OffcanvasComp from "../Component/OffcanvasComp";
import { UserContext } from "../Providers/UserWrapper";
import { toast } from "react-hot-toast";
import { reactLocalStorage } from "reactjs-localstorage";
import HttpClient from "../utils/HttpClient";
import { useEffect } from "react";
import moment from "moment";
import { BsDot } from "react-icons/bs";

function Cipbitheader() {
  const { language, currentLanguage } = useLanguage();
  const { profileData, logout, show, setShow, isBusiness, isBookKeeper, isMainUserMemo, isLogin } =
    useContext(UserContext);
  const userData = reactLocalStorage.getObject("userData");
  // console.log('userDataclip', userData)
  const [notiData, setNotiData] = useState([]);
  const [showManager, setShowManager] = useState(false);
  const [showMiniNoti, setShowMiniNoti] = useState(false);
  const [showNoti, setShowNoti] = useState(false);
  const [notiText, setNotiText] = useState("");

  const [dailyData, setDailyData] = useState();
  const [budgetData, setBudgetData] = useState();
  const [paymentData, setPaymentData] = useState();


  const [dailyLength, setDailyLength] = useState(0);
  const [budgetLength, setBudgetLength] = useState(0);
  const [payLength, setPayLength] = useState(0);

  const [oldDailyLength, setOldDailyLength] = useState(0);
  const [oldBudgetLength, setOldBudgetLength] = useState(0);
  const [oldPaymentLength, setOldPaymentLength] = useState(0);

  // console.log("oldDailyLengthDD", oldDailyLength, dailyLength)
  // console.log("oldBudgetLengthBudget", oldBudgetLength, budgetLength)
  // console.log("oldDailyLengthPay", oldPaymentLength, payLength)


  // const [dailyRed, setDailyRed] = useState();

  const handleshowNoti = async () => {
    // setShowNoti(!showNoti)
    setShowMiniNoti(!showMiniNoti);
    if (showNoti) {
      setShowNoti(false);
      setShowMiniNoti(false);
    }

    // if (isBusiness) {
    //   const res1 = await HttpClient.requestData("bad-news-bu-day", "GET", {});
    //   const res2 = await HttpClient.requestData("bad-news-bu-week", "GET", {});
    //   const res3 = await HttpClient.requestData("bad-news-bu-month", "GET", {});
    //   const res4 = await HttpClient.requestData("bad-news-bu-year", "GET", {});
    //   const res5 = await HttpClient.requestData("add-expense-notification", "POST", {});
    //   console.log('res5', res5)
    // } else {
    //   const res1 = await HttpClient.requestData("bad-news-pu-day", "GET", {});
    //   const res2 = await HttpClient.requestData("bad-news-pu-week", "GET", {});
    //   const res3 = await HttpClient.requestData("bad-news-pu-month", "GET", {});
    //   const res4 = await HttpClient.requestData("bad-news-pu-year", "GET", {});
    //   const res5 = await HttpClient.requestData("add-expense-notification", "POST", {});
    // }
  };

  const addNotification = async () => {
    if (isBusiness) {
      const res1 = await HttpClient.requestData("bad-news-bu-day", "GET", {});
      const res2 = await HttpClient.requestData("bad-news-bu-week", "GET", {});
      const res3 = await HttpClient.requestData("bad-news-bu-month", "GET", {});
      const res4 = await HttpClient.requestData("bad-news-bu-year", "GET", {});
      const res5 = await HttpClient.requestData("add-expense-notification", "POST", {});
      // console.log('res5', res5)
    } else {
      const res1 = await HttpClient.requestData("bad-news-pu-day", "GET", {});
      const res2 = await HttpClient.requestData("bad-news-pu-week", "GET", {});
      const res3 = await HttpClient.requestData("bad-news-pu-month", "GET", {});
      const res4 = await HttpClient.requestData("bad-news-pu-year", "GET", {});
      const res5 = await HttpClient.requestData("add-expense-notification", "POST", {});
    }
  }

  useEffect(() => {
    addNotification();
  }, [isBusiness, showNoti])

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const navigate = useNavigate();
  const mobileNav = () => {
    let display = document.querySelector("#home-sidebar").style.display;
    // console.log(display)
    document.getElementById("home-sidebar").style.display =
      display == "block" ? "none" : "block";
  };

  // const fetchNoti = async () => {
  //   const res = await HttpClient.requestData("notification", "GET", {});
  //   // console.log("notires", res);
  //   if (res && res.status) {
  //     setNotiData(res.data);
  //   }
  // };
  // console.log('notiData', notiData)

  const addDailyNotiNum = async () => {
    const res = await HttpClient.requestData("add-notification-count", "POST", { count: dailyLength });
    // console.log("resAddnnDaily", res)
    dailyNumber();
  }

  const addBudgetNotiNum = async () => {
    const res = await HttpClient.requestData("add-budget-notification-count", "POST", { count: budgetLength });
    // console.log("resAddnnBudget", res)
    budgetNumber();
  }

  const addPaymentNotiNum = async () => {
    const res = await HttpClient.requestData("add-payment-notification-count", "POST", { count: payLength });
    // console.log("resAddnnPay")
    paymentNumber();
  }


  const handleDailyInsp = async () => {
    // const res = await HttpClient.requestData("motivational-notification", "GET", {});
    // console.log("dailyres", res);
    // if (res && res?.status) {
    //   setNotiData(res?.data);
    //   setShowMiniNoti(false);
    //   setShowNoti(true);
    //   setNotiText("dailyInsp");
    // } else {
    //   // toast.error(res.message)
    // }
    setNotiData(dailyData);
    setShowMiniNoti(false);
    setShowNoti(true);
    setNotiText("dailyInsp");
    addDailyNotiNum();
  };

  const handleBudgetAlert = async () => {
    // const res = await HttpClient.requestData("budget-notification", "GET", {});
    // console.log("budgetNoti", res);
    // if (res && res?.status) {
    //   setNotiData(res?.data);
    //   setShowMiniNoti(false);
    //   setShowNoti(true);
    //   setNotiText("budgetAlert");
    // } else {
    //   // toast.error(res.message)
    // }
    setNotiData(budgetData);
    setShowMiniNoti(false);
    setShowNoti(true);
    setNotiText("budgetAlert");
    addBudgetNotiNum();
  };

  const handlePaymentReminder = async () => {
    // const res = await HttpClient.requestData("payment-notification", "GET", {});
    // console.log("paymentNoti", res);
    // if (res && res?.status) {
    //   setNotiData(res?.data);
    //   setShowMiniNoti(false);
    //   setShowNoti(true);
    //   setNotiText("PaymentReminder");
    // } else {
    //   // toast.error(res.message)
    // }
    setNotiData(paymentData);
    setShowMiniNoti(false);
    setShowNoti(true);
    setNotiText("PaymentReminder");
    addPaymentNotiNum();
  };

  const handleDeleteNoti = async (id) => {
    const res = await HttpClient.requestData(
      "delete-notification/" + id,
      "PUT",
      {}
    );
    // console.log("deleteNoti", res);
    if (res && res?.status) {
      toast.success(language.noti_del_success);
      switch (notiText) {
        case "dailyInsp":
          handleDailyInsp();
          break;
        case "budgetAlert":
          handleBudgetAlert();
          break;
        case "PaymentReminder":
          handlePaymentReminder();
          break;
        default:
          handleDailyInsp();
          break;
      }
    }
  };

  const getPrevNotiLength = async () => {
    const res = await HttpClient.requestData("motivational-notification", "GET", {});
    if (res && res?.status) {
      setDailyLength(res?.data?.length);
      setDailyData(res?.data);
    }

    const res1 = await HttpClient.requestData(isBusiness ? "budget-notification" : "budget-notification-pu", "GET", {});
    // const res1 = await HttpClient.requestData("budget-notification", "GET", {});
    // console.log("res1budget", res1)
    if (res1 && res1?.status) {
      setBudgetLength(res1?.data?.length)
      setBudgetData(res1?.data);
    }

    const res2 = await HttpClient.requestData("payment-notification", "GET", {});
    if (res2 && res2?.status) {
      setPayLength(res2?.data?.length);
      setPaymentData(res2?.data);
    }
  }


  const dailyNumber = async () => {
    const res = await HttpClient.requestData("get-notification-count", "GET", {});
    // console.log("resNoNumber", res)
    if (res && res?.status) {
      setOldDailyLength(res?.data.length ? res?.data[0]?.notificationNumber : 0);
    }
  }

  const budgetNumber = async () => {
    const res = await HttpClient.requestData("get-budget-notification-count", "GET", {});
    // console.log("budgetRescc", res);
    if (res && res?.status) {
      setOldBudgetLength(res?.data.length ? res?.data[0]?.notificationNumber : 0);
    }
  }

  const paymentNumber = async () => {
    const res = await HttpClient.requestData("get-payment-notification-count", "GET", {});
    // console.log("paymentRescc", res?.data?.length === 0);
    if (res && res?.status) {
      setOldPaymentLength(res?.data.length ? res?.data[0]?.notificationNumber : 0);
    }
  }

  useEffect(() => {
    // fetchNoti();
    getPrevNotiLength();
    dailyNumber();
    budgetNumber();
    paymentNumber();
  }, [isBusiness]);
  // console.log('showNoti', showNoti)

  return (
    <>
      <section id="cipbit-header">
        <div className="container">
          <header className="header2">
            <div className="header-wrap">
              <div className="header-left">
                <div
                  style={{ cursor: "pointer" }}
                  className="header-logo"
                  onClick={() =>
                    // navigate(userData.token ? isBookKeeper ? "/bookkeeper-manager" : '/manager' : '/')
                    navigate(isLogin ? '/manager' : '/')
                  }
                >
                  <img className="img-fluid" src={Cipbitlogo} alt="logo" />
                </div>
              </div>


              <div className="header-right">

                {/* manager dropdown start */}
                <div className="dropdown mr-3">
                  <button
                    className="dropbtn"
                    onClick={() => setShowManager(!showManager)}
                    onBlur={() => {
                      setTimeout(() => {
                        setShowManager(false)
                      }, 200)
                    }}
                  >
                    {language.manager}
                  </button>
                  <i className="fa-solid fa-angle-down" />
                  {showManager && (
                    <div id="myDropdown" className="dropdown-content">
                      <Link to="/postnewincome">{isBusiness ? language.revenew_manager : language.income_manager}</Link>
                      <Link to="/newexpense">{language.expense_manager}</Link>
                      {isMainUserMemo &&
                        <Link to="/budget-tracker">{language.budget_manager}</Link>
                      }
                      {
                        isBusiness &&
                        <Link to="/create-invoice">{language.invoice_manager}</Link>
                      }
                      <Link to="/project-budget-setup">{language.project_manager}</Link>
                      <Link to="/dashboard-manager">{language.dashboard_manager}</Link>
                      <Link to="/integration">{language.integration_manager}</Link>
                      <Link to="/achievements">{language.reward_manager}</Link>
                      <Link to="/news">{language.cipbits_manager}</Link>
                      {isMainUserMemo &&
                        <Link to="/users">{language.user_manager}</Link>
                      }
                      {/* <Link to="#contact">{language.setting_manager}</Link> */}
                      <Link to="/resource">{language.resource_manager}</Link>
                      <Link to="/view_reports">{language.reports_manager}</Link>
                    </div>
                  )}
                </div>
                {/* manager dropdown start */}

                <div
                  className="calender-logo"
                  onClick={() => navigate("/news")}
                >
                  <img className="img-fluid" src={Calenderlogo} alt="logo" />
                </div>
                <button className="bell-icon" onClick={handleshowNoti}>
                  <i
                    className="fa-solid fa-bell"
                    style={{ position: "relative" }}
                  ></i>
                  {dailyLength > oldDailyLength || budgetLength > oldBudgetLength || payLength > oldPaymentLength
                    ?
                    <span className="main-noti-dot"><BsDot /></span>
                    :
                    null
                  }
                  {
                    showMiniNoti && (
                      // <div className='dropdown'>
                      <div className="miniDrop ">
                        <p onClick={handleDailyInsp}>
                          {language.daily_inspiration}{" "}
                          <i
                            className="fa-solid fa-bell"
                            style={{ position: "relative" }}
                          ></i>
                          {dailyLength > oldDailyLength &&
                            <span className="daily-red-dot"><BsDot /></span>
                          }
                        </p>
                        <p onClick={handleBudgetAlert}>
                          {language.budget_alert}{" "}
                          <i
                            className="fa-solid fa-bell"
                            style={{ position: "relative" }}
                          ></i>
                          {budgetLength > oldBudgetLength &&
                            <span className="budget-red-dot"><BsDot /></span>
                          }
                        </p>
                        <p onClick={handlePaymentReminder}>
                          {language.payment_reminder}{" "}
                          <i
                            className="fa-solid fa-bell"
                            style={{ position: "relative" }}
                          ></i>
                          {payLength > oldPaymentLength &&
                            <span className="payment-red-dot"><BsDot /></span>
                          }
                        </p>
                      </div>
                    )
                    // </div>
                  }
                </button>

                <button className="setting-icon" onClick={handleShow}>
                  <i class="fa-solid fa-gear"></i>
                </button>
                {/* <button className='hambar' onClick={mobileNav}><i className="fa-solid fa-bars"></i></button> */}

                {showNoti && (
                  <div className="notiDrop">
                    <a
                      href="#"
                      className="close"
                      onClick={() => {
                        setShowNoti(false);
                      }}
                    >
                      <i class="fa-solid fa-xmark"></i>
                    </a>
                    <div className="box">
                      <ul>
                        {notiData?.map((item, i) => (
                          <li>
                            {/* <h5>Discount unlocked</h5> */}
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "end",
                                textAlign: "justify",
                              }}
                            >
                              <h6>
                                {currentLanguage === "eng"
                                  ? item.NotificationEng
                                  : item.NotificationSpn}{" "}
                                <strong>{item.value && Number(item.value).toFixed(2)}</strong>
                              </h6>
                              <span
                                className="delnoti"
                                onClick={() => handleDeleteNoti(item._id)}
                              >
                                x
                              </span>
                            </div>
                            <span>{moment(item.createdOn).fromNow()}</span>
                            <hr />
                          </li>
                        ))}

                        {
                          !notiData?.length &&
                          <div className="d-flex justify-content-center">
                            {language.no_notification}
                          </div>
                        }
                      </ul>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </header>
        </div>
      </section>

      {/*   Responsive Menu      */}

      <div id="home-sidebar">
        <button className="cancel" onClick={mobileNav}>
          <i className="fa-solid fa-xmark"></i>
        </button>

        <div className="home-side_list">
          <ul>
            {/* <li><a href="#">Inicio</a></li> */}
            <Link
              to="/blog"
              onClick={mobileNav}
              style={{ textDecoration: "none", color: "black" }}
            >
              <li>
                <a>Blog</a>
              </li>
            </Link>
            <Link
              to="/manager"
              onClick={mobileNav}
              style={{ textDecoration: "none", color: "black" }}
            >
              <li>
                <a>{language.manager}</a>
              </li>
            </Link>
            <li>
              <Link to="/nosotros">{language.headerMenu3}</Link>
            </li>
            <li
              onClick={() => {
                if (profileData) {
                  logout();
                  navigate("/");
                } else {
                  navigate("/login");
                }
              }}
            >
              {" "}
              <button className="side-list_btn">
                {profileData ? language.logoutButton : language.loginButton}
              </button>{" "}
            </li>
          </ul>

          <div className="sidebar-logo">
            <img src={Ftrlogo} alt="logo" />
          </div>
        </div>
      </div>

      {/* Cipbit Header */}

      {/* <div id="cipbit-sidebar">
      
      <button className="cancel" onClick={mobileNav}><i className="fa-solid fa-xmark"></i></button>

      <div className="home-side_list">

        <ul>
          <li><a href="#">Inicio</a></li>
          <li><a href="#">Blog</a></li>
          <li><a href="#">Nosotros</a></li>
          <li> <button className="side-list_btn">Iniciar Sesión</button> </li>
        </ul>

        <div className="sidebar-logo">
          <img src={Ftrlogo} alt="logo" />
        </div>
      </div>

    </div> */}
      <OffcanvasComp show={show} handleClose={handleClose} />
    </>
  );
}

export default Cipbitheader;
