import React, { useEffect, useState } from 'react'
import "react-datetime/css/react-datetime.css";
import Datetime from "react-datetime";
import moment from 'moment';
import { useLanguage } from '../Providers/LanguageContext';

function CustomDateBtn({ text, getValue = () => { }, isCleared = false, setIsCleared, filters }) {
    // console.log('filt336', filters)
    const { language } = useLanguage()
    const [date, setDate] = useState("")

    useEffect(() => {
        if (filters) {
            setDate(filters.date)
        }
    }, [filters])

    const renderInput = (props, openCalendar, closeCalendar) => {
        function clear() {
            props.onChange({ target: { value: "" } });
        }
        if (date) {
            let splitted = date.split("-");
            return (
                <div {...props} onClick={openCalendar} className="circum-section">
                    <button className='month1-btn'
                    // className='month-btn'
                    >{moment(splitted[1], 'MM').format('MMMM')}
                    </button>
                    <button className='month2-btn'
                    // className='day-btn'
                    >{splitted[2]}</button>
                    <button className='month3-btn'
                    // className='year-btn'
                    >{splitted[0]}</button>
                </div>
            )
        } else {
            return (
                <div {...props} onClick={openCalendar} className="circum-section">
                    <button className='month1-btn'
                    // className='month-btn'
                    >
                        {language.month}
                    </button>
                    <button className='month2-btn'
                    // className='day-btn'
                    >{language.day}</button>
                    <button className='month3-btn'
                    // className='year-btn'
                    >{language.year}</button>
                </div>
            )
        }

    }

    const onChange = (value) => {
        const formatted = moment(new Date(value._d).toISOString()).utc().format("YYYY-MM-DD");
        setIsCleared(false)
        setDate(formatted)
    }

    useEffect(() => {
        getValue(date)
    }, [date])

    useEffect(() => {
        if (isCleared) {
            setDate("")
        }
        console.log(isCleared, "isCleared")
    }, [isCleared])

    return (
        <div id='creation'>
            <div className='container'>
                <div className='inner-container'>
                    <div className='cnt-wrap2'>
                        <div className='left-heading2'>
                            <h2>{text}</h2>
                        </div>
                        <Datetime
                            renderInput={renderInput}
                            utc={true}
                            closeOnSelect
                            onChange={onChange}
                            timeFormat={false}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CustomDateBtn