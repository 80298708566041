import React, { useState } from 'react'
import Managebtn from '../../Component/Managebtn'
import Billcnt from '../../Component/Billcnt'
import TimeFrameComp from '../../Component/TimeFrameComp'
import { useNavigate } from 'react-router-dom'
import { useLanguage } from '../../Providers/LanguageContext'

function Index() {
    const [time, setTime] = useState("day")
    const { language } = useLanguage();
    const navigate = useNavigate()
    const getTime = (val) => {
        setTime(val)
    }

    return (

        <>
            <div className='d-flex gap-2 mb-2' style={{margin:"0 0 0 154px"}}>
                <div className=''>
                    <button className='w-100 btn btn-outline-success' style={{ fontWeight: "bold" }} onClick={() => navigate('/viewexpensedetails')}>{language.view_expense_details}</button>
                </div>

                <div className=''>
                    <button className='w-100 btn btn-outline-success' style={{ fontWeight: "bold" }} onClick={() => navigate('/edit-expense-details')}>
                        {language.edit_exp_detail}
                    </button>
                </div>
            </div>
            <Managebtn getTime={getTime} value={time} />

            {/* <div style={{margin:"0 6% 0 7%"}}>
                <div className='row'>
                    <TimeFrameComp getTime={getTime} value={time} />
                </div>
            </div> */}

            <Billcnt time={time} setTime={setTime} />


        </>
    )
}

export default Index