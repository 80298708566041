import { async } from '@firebase/util';
import React, { useEffect, useState } from 'react';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { useLanguage } from '../../Providers/LanguageContext';
import HttpClient from '../../utils/HttpClient';

const Information = () => {
    const { language } = useLanguage();
    const [invData, setInvData] = useState([]);
    const navigate = useNavigate();

    const fetchInvite = async () => {
        const res = await HttpClient.requestData('get-invitation', 'GET', {})
        console.log('resInvite', res)
        if (res && res.status) {
            setInvData(res.data)
        }
    }
    console.log('invData', invData)

    const mapData = [
        {
            text: language.invitation_sent,
            value: invData.invitationSend
        },
        {
            text: language.registered_invitations,
            value: invData.registertedInvitation
        },
        {
            text: language.paid_invitation,
            value: invData.paidInvitation
        },
        {
            text: language.earned_comm,
            value: invData.monthlyAmount
        },
        {
            text: language.total_earned_comm,
            value: invData.yearlyAmount
        },

    ]

    useEffect(() => {
        fetchInvite();
    }, [])
    return (
        <>

            <div id='todo-table'>
                <div className='container'>
                    <div
                        style={{ fontSize: '53px' }}
                        onClick={() => {
                            navigate('/invite');
                            // setShow(true);
                        }}
                    >
                        <AiOutlineArrowLeft />
                    </div>
                    <div className='table-responsive mt-5'>
                        <div className='d-flex justify-content-center'>
                            <h2>{language.information}</h2>
                        </div>
                        {
                            mapData.map((item, i) =>
                                <table className="table">
                                    <tbody>
                                        <tr style={{ backgroundColor: "#ebeff3" }}>
                                            <td style={{
                                                color: "#0e2537",
                                                fontWeight: "600",
                                                fontSize: "18px",
                                                paddingLeft: "20px",
                                                borderTop: "0"
                                            }}>
                                                {/* {t.name} {t.surName} */}
                                                {item.text}
                                            </td>
                                            <td style={{
                                                display: "flex",
                                                justifyContent: "flex-end",
                                                alignItems: "flex-end",
                                                paddingRight: "30px",
                                                color: "#0e2537",
                                                fontWeight: "600",
                                                fontSize: "18px",
                                                paddingLeft: "20px",
                                                borderTop: "0"
                                            }}>
                                                {item.value}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            )
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default Information