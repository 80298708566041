import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import Slider from "react-slick";
import Managebtn from "../../../Component/Managebtn";
import HttpClient from "../../../utils/HttpClient";
import DashboardChartOne from "./DashboardChartOne";
import DashboardChartThree from "./DashboardChartThree";
import DashboardChartTwo from "./DashboardChartTwo";
import { FcPrevious, FcNext } from "react-icons/fc";
import DashboardChartFive from "./DashboardChartFive";
import DashboardChartFour from "./DashboardChartFour";
import DashboardChartSix from "./DashboardChartSix";
import DashboardChartSeven from "./DashboardChartSeven";
import { useLocation, useNavigate } from "react-router-dom";
import dashboard_en_1 from "../../../Audio_files/VI. Project Manager Audio/VI. b. View Project Performance/vi_view_project_performance_audio_a.mp3";
import dashboard_es_1 from "../../../spanish_audio_files/VI. Project Manager Audio/VI. b. View Project Performance/vi_view_project_performance_audio_a_es.mp3";
import dashboard_en_2 from "../../../Audio_files/VII. Dashboard Manager Audio/VII. a. View Dashboards/vii_view_dashboards_audio_a.mp3";
import dashboard_es_2 from "../../../spanish_audio_files/VII. Dashboard Manager Audio/VII. a. View Dashboards/vii_view_dashboards_audio_a_es.mp3";
import { useLanguage } from "../../../Providers/LanguageContext";
import { SpeechContext } from "../../../Providers/SpeechWrapper";
import { UserContext } from "../../../Providers/UserWrapper";
// import DashBoardZero from "./DashBoardZero";
import './toolip.css';
import DashboardChartSixB from "./DashboardChartSixB";
import IntegrationBankDeailModal from "../../../Modal/IntegrationBankDeailModal";
import Dashboardzerotwo from "./Dashboardzerotwo";

function DashboardManager() {

  const location = useLocation();
  const { language, currentLanguage } = useLanguage();
  const navigate = useNavigate()
  console.log("4f5dg4561", location)
  const slideRef = useRef();
  const { setShowGif, setGifToShow, setGifPoints } = useContext(SpeechContext);
  const { currAudio, setCurrAudio, playing } = useContext(SpeechContext);
  const { profileData, isBusiness, isInvestor, isBookKeeper } = useContext(UserContext);
  const [isToggle, setIsToggle] = useState(false)
  const [detailModal, setDetailModal] = useState(false);
  const [transData, setTransData] = useState([])

  // console.log("locationd", location);

  // console.log("pathname", location.pathname)
  const [time, setTime] = useState("day");
  const [zeroDate, setZeroDate] = useState({
    startDate: "",
    endDate: "",
  });


  const [dashboardOneData, setDashboardOneData] = useState([]);
  const [dashboardTwoData, setDashboardTwoData] = useState([]);
  const [dashboardThreeData, setDashboardThreeData] = useState([]);
  const [dashboardZeroData, setDashbardZeroData] = useState({});
  const [dashBoardZeroTaskData, setDashBoardZeroTaskData] = useState({});


  // console.log("locationdasff", location)


  const getTime = (val) => {
    setTime(val);
    setZeroDate({
      startDate: "",
      endDate: "",
    });
  };

  // dashboard one data fetch
  const fetchDashboardOne = async () => {
    if (profileData) {
      // if (profileData.userType === "Business") {
      let res = {}
      if (isInvestor || isBookKeeper) {
        res = await HttpClient.requestData("investor/investor-dashboard-one-view/" + location?.state?.userRegCodr, "POST", { dayType: time });
      }
      else if (profileData.userType === "Business") {
        res = await HttpClient.requestData("dasbord-one-view", "POST", { dayType: time });
      }
      else if (profileData.userType === "Personal") {
        res = await HttpClient.requestData(
          "dasbord-one-pu-view",
          "POST",
          { dayType: time }
        );
      }
      if (res && res.status) {
        if (
          res.message &&
          res.message.trim() === "Dashboard rewards 1 points"
        ) {
          setShowGif(true);
          setGifToShow("Dashboard rewards 1 points");
          setGifPoints(1);
        }
        const fixedData = FixedTwoDecimal(res.data);
        setDashboardOneData(fixedData);
      }



      // else {
      //   const res = await HttpClient.requestData(
      //     "dasbord-one-pu-view",
      //     "POST",
      //     { dayType: time }
      //   );
      //   // console.log("dasbordonepu", res);
      //   if (res && res.status) {
      //     if (
      //       res.message &&
      //       res.message.trim() === "Dashboard rewards 1 points"
      //     ) {
      //       setShowGif(true);
      //       setGifToShow("Dashboard rewards 1 points");
      //       setGifPoints(1);
      //     }
      //     const fixedData = FixedTwoDecimal(res.data);
      //     setDashboardOneData(fixedData);
      //   }
      // }
    }
  };

  const fetchDashboardThreeData = async () => {
    if (profileData) {
      let res = {}
      if (profileData.userType === "Business") {
        res = await HttpClient.requestData(
          "dashboard-three-view",
          "POST",
          { dayType: time }
        );
      } else if (profileData.userType === "Personal") {
        res = await HttpClient.requestData(
          "dashboard-three-pu-view",
          "POST",
          { dayType: time }
        )
      } else if (isInvestor || isBookKeeper) {
        res = await HttpClient.requestData(
          "investor/investor-dashboard-three-view/" + location?.state?.userRegCodr,
          "POST",
          { dayType: time }
        )
      }
      if (res && res.status) {
        if (
          res.message &&
          res.message.trim() === "Dashboard rewards 1 points"
        ) {
          setShowGif(true);
          setGifToShow("Dashbord rewards 1 points");
          setGifPoints(1);
        }
        setDashboardThreeData(res.data);
      }
      // } 
      // else {
      //   const res = await HttpClient.requestData(
      //     "dashboard-three-pu-view",
      //     "POST",
      //     { dayType: time }
      //   );
      //   // console.log("dasbordthreepu", res);
      //   if (res && res.status) {
      //     if (
      //       res.message &&
      //       res.message.trim() === "Dashboard rewards 1 points"
      //     ) {
      //       setShowGif(true);
      //       setGifToShow("Dashbord rewards 1 points");
      //       setGifPoints(1);
      //     }
      //     setDashboardThreeData(res.data);
      //   }
      // }
    }
  };

  // dashboard data fetch
  const fetchDashboardTwoData = async () => {
    if (profileData) {
      let res = {}
      if (profileData.userType === "Business") {
        // console.log('timetwo',time)
        res = await HttpClient.requestData("dashboard-two-view", "POST", {
          dayType: time,
        });
      } else if (profileData.userType === "personal") {
        res = await HttpClient.requestData(
          "dashboard-two-pu-view",
          "POST",
          { dayType: time }
        );
      } else if (isInvestor || isBookKeeper) {
        res = await HttpClient.requestData("investor/investor-dashboard-two-view/" + location?.state?.userRegCodr, "POST", {
          dayType: time,
        });
      }
      // console.log("dasbordtwobu", res);
      if (res && res.status) {
        if (
          res.message &&
          res.message.trim() === "Dashboard rewards 1 points"
        ) {
          setShowGif(true);
          setGifToShow("Dashboard rewards 1 points");
          setGifPoints(1);
        }
        const fixedData = FixedTwoDecimal(res.data);
        setDashboardTwoData(fixedData);
      }
      // }

      // else {
      //   const res = await HttpClient.requestData(
      //     "dashboard-two-pu-view",
      //     "POST",
      //     { dayType: time }
      //   );
      //   console.log("dasbordtwopu", res);
      //   if (res && res.status) {
      //     if (
      //       res.message &&
      //       res.message.trim() === "Dashboard rewards 1 points"
      //     ) {
      //       setShowGif(true);
      //       setGifToShow("Dashboard rewards 1 points");
      //       setGifPoints(1);
      //     }
      //     let obj = {
      //       Expense: Number(res.data.Expense).toFixed(2),
      //       ExpenseBudget: Number(res.data.ExpenseBudget).toFixed(2),
      //     };
      //     // console.log("obj", obj)
      //     setDashboardTwoData(obj);
      //   }
      // }
    }
  };

  // fixed value in to decimal from an object
  const FixedTwoDecimal = (obj) => {
    Object.keys(obj).forEach((key) => {
      obj[key] = Number.isInteger(Number(obj[key]))
        ? Number(obj[key])
        : Number(obj[key])?.toFixed(2);
    });
    return obj;
  };

  const isDtaeMemo = useMemo(() => {
    if (time && !zeroDate.startDate && !zeroDate.endDate) {
      return true;
    } else if (!time && zeroDate.startDate && zeroDate.endDate) {
      return true;
    } else {
      return false;
    }
  }, [zeroDate, time]);


  // **********************dashboard zero *************************

  const fetchDashBoardZeroData = async () => {
    const sendData = {
      // dayType: time,
      // startDate: zeroDate.startDate,
      // endDate: zeroDate.endDate,

      "input_type": "",
      "input_name": "",
      "dayType": time,
      "startAR": "",
      "endAR": ""
    };

    // console.log("sendData", sendData, isInvestor)

    // if (isDtaeMemo) {
    // if (isBusiness) {
    let res = null
    if (isInvestor || isBookKeeper) {
      res = await HttpClient.requestData("investor/investor-dashboard-zero-view/" + location?.state?.userRegCodr, "POST", sendData);
    } else {
      res = await HttpClient.requestData(
        "dashboard-zero-bu",
        "POST",
        sendData
      );
    }

    console.log("resszero", res)
    if (res && res.status) {
      let obj2 = FixedTwoDecimal(res.data);
      setDashbardZeroData(obj2);
    }
    // }
    // else {
    //   const res = await HttpClient.requestData(
    //     "dashboard-zero-pu",
    //     "POST",
    //     sendData
    //   );
    //   if (res && res.status) {
    //     let obj2 = FixedTwoDecimal(res.data);
    //     setDashbardZeroData(obj2);
    //   }
    // }
    // }
  };

  const fetchDashboardZeroTask = async () => {
    const data = {
      "dayType": "month"
    }
    const res = await HttpClient.requestData("dashboard-zero-view-countaksevent", "POST", data);
    // console.log("ressDDd", res);
    if (res && res?.status) {
      setDashBoardZeroTaskData(res?.data);
    } else {

    }
  }

  useEffect(() => {
    fetchDashBoardZeroData();
    fetchDashboardZeroTask();
  }, [profileData, time, location?.state?.userRegCodr]);
  // ********************** end dashboard zero **************************


  useEffect(() => {
    fetchDashboardOne();
    fetchDashboardThreeData();
    fetchDashboardTwoData();
  }, [time, profileData, isInvestor, isBookKeeper, location?.state?.userRegCodr]);

  // returns the slide no for initial slide
  const getInitialSlideMemo = useMemo(() => {
    if (location.pathname === "/project-performance") return 2;
    else if (location?.state?.slideNo === 5) {
      getTime("month");
      if (isBusiness) return 5;
      else return 4;
    }
    else if (location?.state?.slideNo === 6) {
      getTime("month");
      if (isBusiness) return 6;
      else return 5;
    }
    else if (location?.state?.slideNo === 7) {
      // getTime("month");
      if (isBusiness) return 7;
      else return 6;
    }

    // else if (location.pathname === "/dashboard-manager") return 5;
    else return 0;
  }, [location])


  // for sliders
  const settings = {
    dots: false,
    infinite: false,
    draggable: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // initialSlide: location.pathname === "/project-performance" ? 2 : 0,
    initialSlide: getInitialSlideMemo
    // nextArrow:<FcNext />,
    // prevArrow:<FcPrevious />
  };

  const goToSelectedSlice = (n) => {
    slideRef.current.slickGoTo(n)
  }

  useEffect(() => {
    if (playing) {
      if (currAudio) {
        if (!currAudio.paused) {
          currAudio.pause();
        }
      }
      if (currentLanguage === "eng") {
        if (location.pathname === "/project-performance") {
          setCurrAudio(new Audio(dashboard_en_1));
        } else {
          setCurrAudio(new Audio(dashboard_en_2));
        }
      } else {
        if (location.pathname === "/project-performance") {
          setCurrAudio(new Audio(dashboard_es_1));
        } else {
          setCurrAudio(new Audio(dashboard_es_2));
        }
      }
    } else {
      if (currAudio) {
        if (!currAudio.paused) {
          currAudio.pause();
          setCurrAudio(null);
        }
      }
    }

    return () => {
      if (currAudio && !currAudio.paused) {
        currAudio.pause();
      }
    };
  }, [playing, currentLanguage]);


  // useEffect(() => {
  //   console.log("currAudio", currAudio)
  //   if (currAudio) {
  //     if (!currAudio.paused) {
  //       currAudio.pause()
  //     }
  //     currAudio.play()
  //   }
  //   return () => {
  //     if (currAudio && !currAudio.paused) {
  //       currAudio.pause()
  //     }
  //   }
  // }, [currAudio])


  const handleViewAllBalance = () => {
    if (slideRef.current) {
      slideRef.current.slickGoTo(5);
      setTimeout(() => setIsToggle(true), 300); // Delay to ensure slide transition
    }
  };

  const handleViewAllTransaction = () => {
    if (slideRef.current) {
      slideRef.current.slickGoTo(6);
      setTimeout(() => setIsToggle(false), 300); // Delay to ensure slide transition
    }
  };

  useEffect(() => {
    if (location?.state?.slideNo === 5) {
      setIsToggle(true)
    } else {
      setIsToggle(false)
    }
  }, [location?.state?.slideNo])
  return (
    <div onClick={() => setDetailModal(false)}>
      {location?.pathname === "/project-performance" && <div className=''>
        <button className='w-25 mb-2 btn btn-outline-success' style={{ fontWeight: "bold", margin: "0 0 0 120px" }} onClick={() => navigate('/project-budget-setup')}>
          {language.setup_pro_budget}
        </button>
      </div>}
      {location?.pathname === "/dashboard-manager" && location?.state?.slideNo === 7 &&
        <div className='d-flex gap-2 mb-2' style={{ margin: "0 0 0 154px" }}>
          <div className=''>
            <button className='w-100 btn btn-outline-success' style={{ fontWeight: "bold" }} onClick={() => navigate('/bookkeper')}>{language.add_virtual_bookkeper}</button>
          </div>

          <div className=''>
            <button className='w-100 btn btn-outline-success' style={{ fontWeight: "bold" }}
              onClick={() => navigate('/bookKeperDocs')}
            >
              {language.view_upload_docs}
            </button>
          </div>
        </div>
      }


      {location?.pathname === "/dashboard-manager" && isToggle && (
        <div className=''>
          <button
            className='w-25 btn btn-outline-success mb-2'
            style={{ fontWeight: "bold", margin: "0 0 0 120px" }}
            onClick={handleViewAllTransaction}
          >
            {language.view_all_transaction}
          </button>
        </div>
      )}

      {location?.pathname === "/dashboard-manager" && !isToggle && (
        <div className=''>
          <button
            className='w-25 btn btn-outline-success mb-2'
            style={{ fontWeight: "bold", margin: "0 0 0 120px" }}
            onClick={handleViewAllBalance}
          >
            {language.view_all_balance}
          </button>
        </div>
      )}

      {/* {location?.pathname === "/dashboard-manager" && isToggle && location?.state?.slideNo === 5 &&
        <div className=''>
          <button className='w-25 btn btn-outline-success mb-2' style={{ fontWeight: "bold", margin: "0 0 0 120px" }}
            // onClick={() => navigate('/dashboard-manager')}
            onClick={handleViewAllTransaction}
          >
            {language.view_all_transaction}
          </button>
        </div>
      }

      {location?.pathname === "/dashboard-manager" && !isToggle && location?.state?.slideNo === 6 &&
        <div className=''>
          <button className='w-25 btn btn-outline-success mb-2' style={{ fontWeight: "bold", margin: "0 0 0 120px" }}
            // onClick={() => navigate('/dashboard-manager')}
            onClick={handleViewAllBalance}
          >
            {language.view_all_balance}
          </button>
        </div>
      } */}
      <Managebtn getTime={getTime} value={time} />
      <div className="chart-slide">
        <div className="container">
          <Slider {...settings} ref={slideRef}>

            {/* zero */}
            <div>

              {/* <DashBoardZero
                setZeroDate={setZeroDate}
                zeroDate={zeroDate}
                dashboardZeroData={dashboardZeroData}
                setTime={setTime}
              /> */}

              <Dashboardzerotwo
                setZeroDate={setZeroDate}
                zeroDate={zeroDate}
                dashboardZeroData={dashboardZeroData}
                setDashbardZeroData={setDashbardZeroData}
                dashBoardZeroTaskData={dashBoardZeroTaskData}
                setTime={setTime}
                time={time}
                userCode={location?.state?.userRegCodr}
              />

            </div>

            <div>
              <div className="row justify-content-md-center">

                {/* one */}
                <div className="col-sm-12 col-md-6 mt-4">
                  {dashboardOneData &&
                    Object.keys(dashboardOneData).length > 0 && (
                      <DashboardChartOne
                        data={Object.values(dashboardOneData)}
                        time={time}
                        userType={profileData.userType}
                        userCode={location?.state?.userRegCodr}
                      />
                    )}
                </div>

                {/* two */}
                <div className="col-sm-12 col-md-6 mt-4">
                  {dashboardTwoData &&
                    Object.keys(dashboardTwoData).length > 0 && (
                      <DashboardChartTwo
                        data={Object.values(dashboardTwoData)}
                        time={time}
                        userType={profileData.userType}
                        userCode={location?.state?.userRegCodr}
                      />
                    )}
                </div>

              </div>
            </div>

            {/* three */}
            <div className="container mt-4">
              {dashboardThreeData &&
                Object.keys(dashboardThreeData).length > 0 && (
                  <DashboardChartThree
                    data={Object.values(dashboardThreeData)}
                    time={time}
                    userType={profileData.userType}
                  />
                )}
            </div>

            {/* four */}
            <div className="container">
              {<DashboardChartFour time={time} userCode={location?.state?.userRegCodr} />}
            </div>

            {/* five */}
            {profileData && profileData.userType === "Business" && (
              <div className="container">
                {<DashboardChartFive time={time} />}
              </div>
            )}

            {/* integration manager / six*/}
            <div className="container mt-4">
              <DashboardChartSix
                goToSelectedSlice={goToSelectedSlice}
                time={time}
                userCode={location?.state?.userRegCodr}
              />
            </div>

            {/* integration manager / six B*/}
            <div className="container mt-4">
              <DashboardChartSixB
                goToSelectedSlice={goToSelectedSlice}
                time={time}
                setDetailModal={setDetailModal}
                setTransData={setTransData}
                userCode={location?.state?.userRegCodr}
              />
            </div>

            {/* Book keepers / seven */}
            <div>
              <DashboardChartSeven
                time={time}
                location={location}
              />
            </div>

          </Slider>
        </div>
      </div>

      {
        detailModal &&
        <IntegrationBankDeailModal
          closeModal={(e) => {
            // e.stopPropagation()
            setDetailModal(false)
          }}
          filteredData={transData}
        />
      }
    </div>
  );
}

export default DashboardManager;
