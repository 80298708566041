import { async } from '@firebase/util';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import success from '../../images/success.gif'
import HttpClient from '../../utils/HttpClient';
import { reactLocalStorage } from 'reactjs-localstorage';
import toast from 'react-hot-toast';
import { useLanguage } from '../../Providers/LanguageContext';
import { useUserData } from '../../Providers/UserWrapper';

const PaymentSuccess = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [token, setToken] = useState(location.state)
  const { language, currentLanguage } = useLanguage();
  const { profileData, setProfileData, setIsLoading, setRegCodeData } = useUserData();

  useEffect(() => {
    // const currentUrl = window.location.href;
    // const spliturl = currentUrl.split('?')
    // const urlParams = new URLSearchParams(`${spliturl[0]}+?hii=hh&${spliturl[1]}`);

    // const paymentIntentParam = urlParams.get('payment_intent')
    // const paymentSecret = urlParams.get('payment_intent_client_secret')
    // const paymentstatus = urlParams.get('redirect_status')

    updateSubsCription()
    // updateSubsCription()
    // subscriptionUpdateinUser();
  }, []);

  const updateSubsCription = async () => {
    let result = await HttpClient.requestData('subcription-status-update', 'PUT', {})
    if (result && result?.status) {
      // console.log('successfull payment')
    } else {
      console.log('error', result)
    }
  }

  const subscriptionUpdateinUser = async () => {
    // toast.success('Payment Successfull')
    const stripeData = reactLocalStorage.getObject("stripeData")
    let data = {
      subscriptionID: stripeData.id,
      amount: stripeData.ammount,
      subscriptionType: stripeData.duration,
      reffCode: stripeData.refffcode,
    }
    // console.log('subData', data);
    // return false
    let result = await HttpClient.requestData(
      "addUserSubscription",
      "POST",
      data
    )
    if (result && result.status) {
      toast.success(language.subs_added_success)
      // StripeRecurring()
      // setTimeout(() => {
      //     navigate("/manager");
      // }, 2000)
      getProfile()
    }
  }

  const getProfile = async () => {
    setIsLoading(true);
    const result = await HttpClient.requestData("get-profile", "GET");
    if (result && result.success) {
      setProfileData(result.data[0]);
      // navigate("/manager");
      getUserNumber();
    } else {
      setIsLoading(false);
    }
  }

  const getUserNumber = async () => {
    const sendData = {
      accountRegCode: Number(profileData && profileData.accountRegCode)
    }
    setIsLoading(true);
    const res = await HttpClient.requestData("total-user", "POST", sendData);
    if (res && res.status) {
      setRegCodeData(res.data[0]);
      localStorage.removeItem("stripeData");
      // setTimeout(() => {
      //   navigate("/manager");
      // }, 1000);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  }




  return (
    <div className="payment-success">
      <h1>Payment Successful!</h1>
      <p>Thank you for your payment.</p>
      <div className="gif-container">
        <img src={success} alt="Success GIF" />
      </div>
      <button
        style={{ height: '30px', width: '100px', backgroundColor: '#33aaa4', margin: '10px' }}
        onClick={() => navigate('/manager')}
      >
        Go to home
      </button>
    </div>
  );
};

export default PaymentSuccess;