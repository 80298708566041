import React, { useEffect, useState } from "react";
import { useLanguage } from "../../Providers/LanguageContext";

export default function ScheduleModal(props) {
    const { setShowSchedModal,
        setSendName,
        sendName,
        selectNameMemo,
        handleSchedule,
        setSendForm,
        sendForm,
        schedName
    } = props;

    const { language, currentLanguage } = useLanguage();
    const [selName, setSelName] = useState(selectNameMemo);
    useEffect(() => {
        setSelName(selectNameMemo);
    }, [currentLanguage])

    return (
        <>
            <div className="ftrbtn">
                <div className="modal d-block">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header border-0">
                                <h4 className="text-secondary dts">
                                    {/* {language.data} */}
                                    {language.schedule}:
                                    {schedName === "daily" && language.daily}
                                    {schedName === "weekly" && language.weekly}
                                    {schedName === "monthly" && language.monthly}
                                </h4>
                                <button type="button" className="close" data-dismiss="modal" onClick={() => {
                                    setShowSchedModal(false)
                                    setSendName("")
                                }}>
                                    {" "}
                                    ×
                                </button>
                            </div>
                            <div className="modal-body">
                                <form>
                                    {/* {
                    sendName &&
                    <div className="form-group">
                      <div className="btn btn-block btnp ">
                        <h2 className="tda">{language.type_of_file} :  {sendName}</h2>
                      </div>
                    </div>
                  } */}
                                    <div className="form-group">
                                        <label htmlFor="usr">{language.send_to}:</label>
                                        <input
                                            type="email"
                                            className="form-control"
                                            id="usr"
                                            placeholder={language.email}
                                            value={sendForm.email}
                                            onChange={(e) => setSendForm((prev) => { return { ...prev, email: e.target.value } })}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="pwd">{language.subject}</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="pwd"
                                            value={selectNameMemo}
                                            // value={selName}
                                            readOnly
                                        />
                                    </div>
                                    {/* <div className="form-group">

                                        <textarea
                                            className="form-control"
                                            rows={5}
                                            id="comment"
                                            // defaultValue={""}
                                            placeholder={language.describe_message}
                                            value={sendForm.message}
                                            onChange={(e) => setSendForm((prev) => { return { ...prev, message: e.target.value } })}
                                        />
                                    </div> */}
                                    <div className="footerBtn">
                                        <a
                                            style={{ color: "#fff", cursor: "pointer" }}
                                            onClick={handleSchedule}
                                        >
                                            {language.schedule}

                                        </a>
                                        <a onClick={() => setShowSchedModal(false)} style={{ cursor: 'pointer', background: "rgb(135 124 124 / 58%)" }}>{language.cancel}</a>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
