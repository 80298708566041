import React, { useEffect, useState } from 'react';
import fisiIconDrop from "../../../images/FisTaskIcon.png";
import HttpClient from '../../../utils/HttpClient';
import ProjectPopUp from '../ProjectPopUp';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useLanguage } from '../../../Providers/LanguageContext';

const ProjectList = (props) => {
    const { projectData, getProjecData, setProjectData } = props;
    const { language } = useLanguage();
    const [modalc, setModalc] = useState(false);
    const [ProjectDetailData, setProjectDetailData] = useState({})

    // console.log("projectData", projectData)

    const handleClicIem = (item) => {
        setModalc(true);
        setProjectDetailData(item);
    }



    // ********************drag and drop*******************
    const grid = 4;

    // when moves in a single list or column
    const reorder = (list, startIndex, endIndex) => {
        // console.log('list_startIndex_endIndex', list, startIndex, endIndex)
        const result = Array.from(list?.status);
        // console.log('resultreorder', result, list)
        const [removed] = result.splice(startIndex, 1);
        // console.log('removedreorder', removed)
        result.splice(endIndex, 0, removed);

        return result;
    };


    // Moves an item from one list to another list.
    const move = (source, destination, droppableSource, droppableDestination) => {
        // console.log('source_destination', source, destination, droppableSource, droppableDestination)
        const sourceClone = Array.from(source?.status);
        const destClone = Array.from(destination?.status);
        const [removed] = sourceClone.splice(droppableSource.index, 1);

        destClone.splice(droppableDestination.index, 0, removed);

        const result = {};
        // console.log('droppableSource', droppableSource.droppableId, droppableDestination.droppableId)
        result[droppableSource.droppableId] = sourceClone;
        result[droppableDestination.droppableId] = destClone;
        // console.log('resultMove', result)
        return result;
    };


    //drag end: this function calles aftert drag end
    const onDragEnd = (result) => {
        const { source, destination } = result;
        console.log("onDragEndresult", result)
        // dropped outside the list
        if (!destination) {
            return;
        }
        const sInd = +source.droppableId;
        const dInd = +destination.droppableId;
        // console.log('sInd_dInd', sInd, dInd);

        if (sInd === dInd) {
            const items = reorder(projectData[sInd], source.index, destination.index);
            // console.log('itemsReorder', items)
            const newState = [...projectData];
            newState[sInd]["status"] = items;

            // setProjectData(newState);
        } else {
            const resultArr = move(projectData[sInd], projectData[dInd], source, destination);
            const newState = [...projectData];
            newState[sInd]["status"] = resultArr[sInd];
            newState[dInd]["status"] = resultArr[dInd];

            console.log("resultmove", projectData[sInd], projectData[dInd], resultArr[dInd])
            // setState(newState.filter(group => group.length));
            setProjectData(newState);

            updateStatus(result?.draggableId, projectData[dInd]);
        }
    }

    const updateStatus = async (id, destData) => {
        const data = {
            projectStatus: destData?.apiStatus
        }
        const res = await HttpClient.requestData("update-project-eventDetails/" + id, "PUT", data)
        if (res && res?.status) {
            // getTaskData();
            // closemodal();
        }
    }

    // add style to the each list or column
    const getListStyle = isDraggingOver => {
        // console.log("isDraggingOver", isDraggingOver)
        return {
            background: isDraggingOver ? "#e5dfdf" : "lightgrey",
            padding: grid,
            width: 144,
        }
    };

    // adds style to the each row
    const getItemStyle = (isDragging, draggableStyle) => ({
        // some basic styles to make the items look a bit nicer
        userSelect: "none",
        padding: grid,
        margin: `0 0 ${grid}px 0`,

        // change background colour if dragging
        background: isDragging ? "lightgreen" : "#fff",
        borderTop: "2px solid #c9c9c9",
        // styles we need to apply on draggables
        ...draggableStyle
    });
    // ********************End drag and drop*******************


    return (
        <div>
            <div className="addTaskTestDiv">


                <DragDropContext onDragEnd={onDragEnd}>
                    {
                        projectData?.map((ele, ind) => {
                            return (
                                <div key={ind}>
                                    <div className="addinnerTskDivOne addinnerTskDiv" >
                                        <div
                                            className="addInHeadDiv"
                                            style={{ backgroundColor: ele?.color }}
                                        >
                                            <span className="addInHeadSpan">{ele?.name}</span>
                                            <span className="addInNumSpan">{ele?.open?.length}</span>
                                        </div>

                                        <Droppable key={ind} droppableId={`${ind}`}>
                                            {(provided, snapshot) => {
                                                return (
                                                    <div
                                                        ref={provided.innerRef}
                                                        style={getListStyle(snapshot.isDraggingOver)}
                                                        {...provided.droppableProps}
                                                    >
                                                        <div className="addMinTaskDiv">
                                                            {
                                                                ele?.status?.map((item, index) => {
                                                                    return (
                                                                        <div key={index}>
                                                                            <Draggable
                                                                                key={item?._id}
                                                                                draggableId={item?._id}
                                                                                index={index}
                                                                            >
                                                                                {(provided, snapshot) => (
                                                                                    <div
                                                                                        ref={provided.innerRef}
                                                                                        {...provided.draggableProps}
                                                                                        {...provided.dragHandleProps}
                                                                                        style={getItemStyle(
                                                                                            snapshot.isDragging,
                                                                                            provided.draggableProps.style
                                                                                        )}
                                                                                    >
                                                                                        <div
                                                                                            className="addInTextsDiv"
                                                                                            key={index}
                                                                                            onClick={() => {
                                                                                                setProjectDetailData(item)
                                                                                                setModalc(true)
                                                                                            }}
                                                                                        >
                                                                                            <p className="addMinPrepText">
                                                                                                {item?.projectName}
                                                                                            </p>
                                                                                            <div className="addAssiTextDiv">
                                                                                                <p className="addAssignText">
                                                                                                    {
                                                                                                        item?.assignToUserName
                                                                                                            ?
                                                                                                            <span>
                                                                                                                {language.assign_to}: <span>{item?.assignToUserName}</span>
                                                                                                            </span>
                                                                                                            :
                                                                                                            <span>{language.not_assigned}</span>
                                                                                                    }
                                                                                                </p>
                                                                                                <figure className="smallFisiIconFig">
                                                                                                    <img src={fisiIconDrop} alt="..." />
                                                                                                </figure>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                )}
                                                                            </Draggable>
                                                                        </div>
                                                                    )
                                                                }
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                            }}
                                        </Droppable>

                                        {/* <div>
                                            <button className="addInTaskBtn">+ Add New Task</button>
                                        </div> */}
                                    </div>
                                </div>
                            )
                        })

                    }
                </DragDropContext>


            </div>

            {modalc && <ProjectPopUp closemodal={setModalc} detailData={ProjectDetailData} getProjecData={getProjecData} />}


        </div>
    )
}

export default ProjectList;
