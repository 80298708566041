import React from "react";
import "./TaskManager.css";
import { Link } from "react-router-dom";
import HttpClient from "../../utils/HttpClient";
import toast from "react-hot-toast";
import moment from "moment";
import { useLanguage } from "../../Providers/LanguageContext";
function ProjectPopUp({ closemodal, detailData, getProjecData }) {
  const { language } = useLanguage();

  // console.log("detailDataprojj", detailData)

  const handleSelectStatus = async (e) => {
    if (!e.target.value) return

    const data = {
      projectStatus: e.target.value
    }
    const res = await HttpClient.requestData("update-project-eventDetails/" + detailData?._id, "PUT", data)
    if (res && res?.status) {
      closemodal();
      getProjecData();
      toast.success("Status Updated Sucessfully");
    }
  }

  return (
    <section className="taskPopSection">
      <div className="taskInPopDiv">
        <p className="taskModHead" style={{ textAlign: 'center' }}>
          Project
        </p>
        <div className="taskInScrollDiv">
          <div className="taskInScrollDiv">
            <div className="taskInScrollDiv">
              <div className="mb-2">
                {detailData?.projectName && <p className="dryClnDropText">{detailData?.projectName}</p>}
                {detailData?.startDate && detailData?.endDate && (
                  <p className="taskDtDetail">
                    {moment(detailData?.startDate).format('DD-MM-YYYY')} {` to `}
                    {moment(detailData?.endDate).format('DD-MM-YYYY')}
                  </p>
                )}
                {detailData?.startTime && detailData?.endTime && (
                  <p className="taskTimeDetail">
                    {moment(detailData?.startTime, ['HH:mm']).format('hh:mm a')} -{' '}
                    {moment(detailData?.endTime, ['HH:mm']).format('hh:mm a')}
                  </p>
                )}
                {detailData?.projectStatus && <p className="taskNoteSmallHead">Status: {detailData?.projectStatus}</p>}
                <div className="taskNoteTextDiv">
                  {detailData?.projectNotes && (
                    <>
                      <p className="taskNoteSmallHead">Project Notes :{detailData?.projectNotes}</p>
                      {/* <p className="taskNoteText">{detailData?.taskNotes}</p> */}
                    </>
                  )}

                  {detailData?.assignToUserName
                    ?
                    <p className="taskNoteSmallHead">Assign User: {detailData?.assignToUserName}</p>
                    :
                    <p className="taskNoteSmallHead">Assign User: {language.not_assigned}</p>
                  }
                </div>
                {detailData?.linkedUser && <p className="taskNoteSmallHead">Linked User: {detailData?.linkedUser}</p>}
                {detailData?.linkedTask && <p className="taskNoteSmallHead">Linked Task: {detailData?.linkedTask}</p>}
              </div>
            </div>

          </div>
        </div>
        <div className="tskSaveAsBtnDiv">
          <button className="tskSaveAsBtn" onClick={() => closemodal()}>
            Save as Done
          </button>
        </div>
        <button className="closeTaskModBtn" onClick={() => closemodal()}>
          <i class="fa-solid fa-x"></i>
        </button>
      </div>
    </section>
  );
}

export default ProjectPopUp;
