import React from 'react'
import PhonePNG from '../../images/fisibility-logo (2).png';
import PhonePNGEn from '../../images/englishImage/gold - EN.png'
import oroIcon from '../../images/oro-icon.png';
import platinoIcon from '../../images/platino-icon.png';
import diamanteIcon from '../../images/diamante-icon.png';
import { useLanguage } from '../../Providers/LanguageContext';
import gold from '../../images/AllBatch/gold.png';
import diamond from '../../images/AllBatch/diamond.png';
import platinum from '../../images/AllBatch/platinum.png';
// import enVideo from '../../images/video/En_video.mp4';
// import esVideo from '../../images/video/Es_video.mp4'


function Recommend() {
  const { language, currentLanguage } = useLanguage()
  console.log('currentLanguage', currentLanguage)
  return (
    <>
      <section id='recommend_area'>
        <div className='top'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-4 col-md-4 col-4'></div>
              <div className='col-lg-8 col-md-8 col-8'>
                <h2>{language.homeLastSectionHeading.toUpperCase()}</h2>
              </div>
            </div>
          </div>
        </div>
        <div className='bottom_area'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-4 col-md-4 col-4'>
                <div className='phone_img'>
                  {
                    currentLanguage === 'spanish'
                      ?
                      <img src={PhonePNG} className="img-fluid" alt='phone' style={{ width: "250px", height: '470px' }} />
                      :
                      <img src={PhonePNGEn} className="img-fluid" alt='phone' style={{ width: "250px", height: '470px' }} />
                  }
                  {/* <img src={PhonePNG} className="img-fluid" alt='phone' /> */}
                </div>
              </div>
              <div className='col-lg-8 col-md-8 col-8'>
                <div className='potencial'>
                  <div className='all_list'>
                    <ul>
                      <li>{language.homeLastSectionPoint1}</li>
                      <li>{language.homeLastSectionPoint2}</li>
                      <li>{language.homeLastSectionPoint3}</li>
                    </ul>
                  </div>

                  <div className='text'>
                    <p>{language.homeLastSectionPoint4}</p>
                  </div>


                  {
                    currentLanguage === 'spanish'
                      ?
                      <div className='all_icon'>
                        <div className='icon_area'>
                          <img src={oroIcon} className="img-fluid" alt='image' />
                        </div>
                        <div className='icon_area'>
                          <img src={platinoIcon} className="img-fluid" alt='image' />
                        </div>
                        <div className='icon_area'>
                          <img src={diamanteIcon} className="img-fluid" alt='image' />
                        </div>
                      </div>
                      :
                      <div className='all_icon'>
                        <div className='icon_area1'>
                          <img src={gold} className="img-fluid" alt='image' />
                        </div>
                        <div className='icon_area1'>
                          <img src={platinum} className="img-fluid" alt='image' />
                        </div>
                        <div className='icon_area1'>
                          <img src={diamond} className="img-fluid" alt='image' />
                        </div>
                      </div>
                  }
                </div>
              </div>
            </div>

            <div className='row'>
              <div className='col-lg-12 col-md-12 col-12'>
                <div className='text_responsive'>
                  <p>{language.homeLastSectionPoint4}</p>
                </div>
                {
                  currentLanguage === 'spanish'
                    ?
                    <div className='all_icon_responsive'>
                      <div className='icon_area'>
                        <img src={oroIcon} className="img-fluid" alt='image' />
                      </div>
                      <div className='icon_area'>
                        <img src={platinoIcon} className="img-fluid" alt='image' />
                      </div>
                      <div className='icon_area'>
                        <img src={diamanteIcon} className="img-fluid" alt='image' />
                      </div>
                    </div>
                    :
                    <div className='all_icon_responsive'>
                      <div className='icon_area'>
                        <img src={gold} className="img-fluid" alt='image' />
                      </div>
                      <div className='icon_area'>
                        <img src={platinum} className="img-fluid" alt='image' />
                      </div>
                      <div className='icon_area'>
                        <img src={diamond} className="img-fluid" alt='image' />
                      </div>
                    </div>
                }

              </div>
            </div>


            <div className='row'>
              <div className='col-lg-12 col-md-12 col-12'>
                <div className="dinero_area">
                  <canvas id="canvas"></canvas>
                  {/* <video id="video" src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4" controls autoplay loop muted playsinline></video> */}
                  {
                    currentLanguage === 'eng'
                      ?
                      <video
                        id="video"
                        src="https://fisibilitylite.s3.us-west-1.amazonaws.com/user/profile/63f5ccea2a1431e4f5f14663/c2ec25a0-004a-11ee-a38f-d9ceec8795a7.mp4"
                        controls
                        autoplay
                        loop
                        muted
                        playsinline
                      />
                      :
                      <video
                        id="video"
                        src="https://fisibilitylite.s3.us-west-1.amazonaws.com/user/profile/63f5ccea2a1431e4f5f14663/34196210-004b-11ee-a38f-d9ceec8795a7.mp4"
                        controls
                        autoplay
                        loop
                        muted
                        playsinline
                      />
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Recommend