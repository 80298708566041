import React, { useState } from 'react'
import Budget from '../../Component/Budget'
import Managebtn from '../../Component/Managebtn'

function Index() {
    const [time, setTime] = useState("")

    const getTime = (val) => {
        setTime(val)
    }
    return (
        <>
            <Managebtn getTime={getTime} value={time} />
            <Budget time={time} setTime={setTime} />
        </>
    )
}

export default Index