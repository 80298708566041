import React, { useCallback, useEffect, useState } from 'react'
import HttpClient from '../../../../utils/HttpClient';
import toast from 'react-hot-toast';
import { useLanguage } from '../../../../Providers/LanguageContext';

import Datetime from 'react-datetime';
import { useUserData } from '../../../../Providers/UserWrapper';
import CustomModal from '../../../../Component/CustomModal';
import { AiOutlineClose } from 'react-icons/ai';

const NormalForm = ({ getTime, value }) => {
    const { language, currentLanguage } = useLanguage();
    const { setIsLoading } = useUserData();

    const initData = {
        customerName: "",
        customerRFCID: "",
        state: "",
        city: "",
        zip: "",
        emailRecipents: "",
        invoiceDate: "",
        invoice: "",
        Oredr: "",
        reference: "",
        purchaseOrder: "",
        invoiceDetailsType: "",
        salesTaxPercentage: "",
        salesTaxAmount: "",
        other: "",
        total: ""
    }
    const [formValue, setFormValue] = useState(initData);

    const initAddMore = {
        description: "",
        quantity: "",
        unit: "",
        itemPrice: "",
        subTotal: ""
    }
    const [addMoreData, setAddMoreData] = useState([initAddMore]);
    const [subTotalAll, setSubTotalAll] = useState("")

    const [isAllAddMore, setIsAllAddMore] = useState(false);
    const [isInvoiceName, setIsInvoiceName] = useState(false);
    const [isSave, setIsSave] = useState(false);
    const [searchData, setSearchData] = useState([]);
    const [debouncing, setDebouncing] = useState("");

    // console.log("addMoreData", formValue);

    // store other input field data
    const handleFormChange = (e) => {
        const { name, value } = e.target;
        setFormValue((prev) => { return { ...prev, [name]: value } })
    }

    // store add more input field data
    const handleAddMoreChange = (e, ind) => {
        const { name, value } = e.target;
        if (addMoreData[ind].quantity && name === "itemPrice") {
            const data = value * addMoreData[ind].quantity;
            const updatedData = addMoreData.map((item, i) => {
                if (i === ind) {
                    return { ...item, [name]: value, subTotal: data };
                }
                else {
                    return item;
                }
            })
            setAddMoreData(updatedData);
        } else if (addMoreData[ind].itemPrice && name === "quantity") {
            const data = value * addMoreData[ind].itemPrice;
            const updatedData = addMoreData.map((item, i) => {
                if (i === ind) {
                    return { ...item, [name]: value, subTotal: data };
                }
                else {
                    return item;
                }
            })
            setAddMoreData(updatedData);
        }
        else {
            const updatedData = addMoreData.map((item, i) => {
                if (i === ind) {
                    return { ...item, [name]: value };
                }
                else {
                    return item;
                }
            })
            setAddMoreData(updatedData);
        }
    }

    // validation
    const validate = () => {
        if (!formValue.customerName) {
            toast.error(language.please_enter);
            return true
        }
        if (!formValue.customerRFCID) {
            toast.error(language.please_enter);
            return true
        }
        if (!formValue.city) {
            toast.error(language.please_enter);
            return true
        }
        if (!formValue.state) {
            toast.error(language.please_enter);
            return true
        }
        if (!formValue.zip) {
            toast.error(language.please_enter);
            return true
        }
        if (!formValue.emailRecipents) {
            toast.error(language.please_enter);
            return true
        }
        if (!formValue.invoiceDate) {
            toast.error(language.please_enter);
            return true
        }
        if (!formValue.invoice) {
            toast.error(language.please_enter);
            return true
        }
        if (!formValue.Oredr) {
            toast.error(language.please_enter);
            return true
        }
        if (!formValue.reference) {
            toast.error(language.please_enter);
            return true
        }
        if (!formValue.purchaseOrder) {
            toast.error(language.please_enter);
            return true
        }
        if (!formValue.invoiceDetailsType) {
            toast.error(language.please_enter);
            return true
        }

        // if (!formValue.salesTaxPercentage) {
        //     toast.error(language.please_enter);
        //     return true
        // }
        // if (!formValue.salesTaxAmount) {
        //     toast.error(language.please_enter);
        //     return true
        // }
        // if (!formValue.other) {
        //     toast.error(language.please_enter);
        //     return true
        // }
        // if (!formValue.total) {
        //     toast.error(language.please_enter);
        //     return true
        // }

        if (isAllAddMore) {
            toast.error(language.please_enter);
            return true
        }

        return false;
    }

    // vallidation of add more
    const addMoreValidate = () => {
        addMoreData.forEach((item) => {
            if (Object.values(item).some((ele) => ele === "")) {
                setIsAllAddMore(true)
            } else {
                setIsAllAddMore(false)
            }
        })
    }
    useEffect(() => {
        addMoreValidate()
    }, [addMoreData])

    // add new field / click on + button
    const addNewDesc = (e) => {
        e.preventDefault();
        setAddMoreData((prev) => { return [...prev, initAddMore] })

        setFormValue((prev) => {
            return {
                ...prev,
                salesTaxAmount: "",
                salesTaxPercentage: "",
                total: "",
                other: ""
            }
        })
    }

    // delete fieadl of add more
    const handleDeleteAddMore = (e, ind) => {
        e.preventDefault();
        setAddMoreData((prev) => { return prev.filter((item, i) => i !== ind) });

        setFormValue((prev) => {
            return {
                ...prev,
                salesTaxAmount: "",
                salesTaxPercentage: "",
                total: "",
                other: ""
            }
        })
    }

    // calculate sales-tax and total for sles-percent
    useEffect(() => {
        if (formValue.salesTaxPercentage && !isAllAddMore) {
            let data = 0;
            addMoreData.forEach((item) => {
                data = data + item.subTotal
            })
            let taxData = data + (data * formValue.salesTaxPercentage / 100);
            setFormValue((prev) => { return { ...prev, salesTaxAmount: taxData, total: taxData } })
        } else {
            setFormValue((prev) => { return { ...prev, salesTaxAmount: "", total: subTotalAll } })
        }
    }, [formValue.salesTaxPercentage, isAllAddMore, addMoreData])

    //calculate total for other
    useEffect(() => {
        if (formValue.other && !isAllAddMore) {
            if (formValue.salesTaxAmount) {
                let totalData = Number(formValue.salesTaxAmount) + Number(formValue.other);
                setFormValue((prev) => { return { ...prev, total: totalData } });
            } else {
                let totalData = Number(subTotalAll) + Number(formValue.other);
                setFormValue((prev) => { return { ...prev, total: totalData } });
            }
        } else {
            setFormValue((prev) => { return { ...prev, total: subTotalAll } })
        }
    }, [formValue.other, isAllAddMore])

    // calculate total for add-more
    useEffect(() => {
        if (!isAllAddMore) {
            let data = 0;
            addMoreData.forEach((item) => {
                data = data + item.subTotal;
                // setCalTotalData((prev) => { return (Number(prev) + Number(item.subTotal)) });
            })
            setFormValue((prev) => { return { ...prev, total: data } })
            setSubTotalAll(data);
        } else {
            setFormValue((prev) => { return { ...prev, total: "" } })
            setSubTotalAll("");
        }
    }, [addMoreData, isAllAddMore])


    const removeDuplicates = (arr) => {
        return arr.filter((item, index) => arr.indexOf(item) === index);
    }

    // search customer Name
    const fetchSerchName = async (name) => {
        const res = await HttpClient.requestData("invoice-web-search/" + name, "GET");
        console.log('searchNamewebinv', res, name)
        let arr = [];
        if (res && res.status) {
            res.data.forEach(element => {
                arr.push(element.customerName)
            });
            // console.log('element',arr)
            setSearchData(removeDuplicates(arr))
        } else {
            setSearchData([])
        }
    }

    // send email
    const handleSend = async (e) => {
        e.preventDefault();

        if (validate()) {
            return
        }

        const sendData = {
            customerName: formValue.customerName,
            customerRFCID: formValue.customerRFCID,
            state: formValue.state,
            city: formValue.city,
            zip: formValue.zip,
            emailRecipents: formValue.emailRecipents,
            invoiceDate: formValue.invoiceDate,
            invoice: formValue.invoice,
            Oredr: formValue.Oredr,
            reference: formValue.reference,
            purchaseOrder: formValue.purchaseOrder,
            invoiceDetailsType: formValue.invoiceDetailsType,
            invoiceDetails: addMoreData,
            salesTaxPercentage: formValue.salesTaxPercentage ? formValue.salesTaxPercentage : "-",
            salesTaxAmount: formValue.salesTaxAmount ? formValue.salesTaxAmount : "-",
            other: formValue.other ? formValue.other : "-",
            total: formValue.total
        }
        setIsLoading(true);
        const res = await HttpClient.requestData(
            currentLanguage === "eng" ? "add-invoice-web" : "add-invoice-web-es", "POST", sendData
        );
        console.log("resNormInv", res);
        if (res && res?.status) {
            toast.success(res?.message || "mail Sent Successfully");
            setAddMoreData([initAddMore]);
            setFormValue(initData);
            setIsLoading(false)
        } else {
            toast.error(res.message || "something went wrong");
            setIsLoading(false)
        }
    }

    // debouncing in search
    useEffect(() => {
        let timer = setTimeout(() => {
            fetchSerchName(debouncing)
        }, 300);
        return () => clearTimeout(timer);
    }, [debouncing]);

    return (
        <div>
            <form className="sendInfoForm">
                <div className="sendInfoFirstFormDiv">

                    {/* Send Invoice To / left part*/}
                    <div className="sendInfoSubDiv">
                        <p className="sendInfoHead">{language.send_invoice_to}</p>

                        {/* name */}
                        <div className="sendInnerSubDiv">
                            <label className="sendFisiLabel">{language.customer_name}:</label>
                            <input
                                type="text"
                                placeholder={language.customer_name}
                                className="sendFisiInp"
                                name="customerName"
                                value={isSave ? formValue.customerName : language.customer_name}
                                // onChange={(e) => handleFormChange(e)}
                                onClick={() => {
                                    setIsInvoiceName(true);
                                    setIsSave(false);
                                }}
                                readOnly
                            />
                        </div>

                        {/* customer search modal */}
                        <CustomModal
                            modalIsOpen={isInvoiceName}
                            setIsOpen={setIsInvoiceName}
                            openModal={() => setIsInvoiceName(true)}
                            closeModal={() => setIsInvoiceName(false)}
                        >
                            <div>
                                <div className='d-flex justify-content-between text-secondary'>
                                    <span>
                                        {/* {language.confirmation} */}
                                    </span>
                                    <span>
                                        <AiOutlineClose className='icon' onClick={() => setIsInvoiceName(false)} size={20} color="gray" />
                                    </span>
                                </div>
                            </div>
                            <div className="input-group mb-3">
                                <div style={{ width: "80%" }}>
                                    <input
                                        type="text"
                                        className="form-control"
                                        // placeholder={isBusiness ? language.customer_name : language.income_type}
                                        aria-label="Recipient's username"
                                        aria-describedby="basic-addon2"
                                        value={formValue.customerName}
                                        onChange={(e) => {
                                            if (/^[A-Za-z\s]*$/.test(e.target.value)) {
                                                setFormValue((prev) => { return { ...prev, customerName: e.target.value } })
                                                setDebouncing(e.target.value);
                                            }
                                        }}
                                    />
                                </div>
                                <button
                                    className='customer_name_button_revenew btn btn-primary'
                                    onClick={() => {
                                        setIsSave(true);
                                        setIsInvoiceName(false);
                                    }}
                                >
                                    {language.save}
                                </button>
                                {
                                    searchData.map((item, i) =>
                                        <div className='search_name' key={i}>
                                            <p
                                                style={{ margin: '0' }}
                                                onClick={() => {
                                                    // setValues((prev) => {
                                                    //     return { ...prev, customerName: item }
                                                    // })
                                                    setFormValue((prev) => { return { ...prev, customerName: item } })
                                                    setSearchData([]);
                                                }}
                                            >
                                                {item}
                                            </p>
                                        </div>
                                    )
                                }
                            </div>
                        </CustomModal >


                        {/* tax id */}
                        <div className="sendInnerSubDiv">
                            <label className="sendFisiLabel">
                                Tax ID / RFC ID:
                            </label>
                            <input
                                type="text"
                                placeholder=" Tax ID / RFC ID"
                                className="sendFisiInp"
                                name="customerRFCID"
                                value={formValue.customerRFCID}
                                onChange={(e) => handleFormChange(e)}
                            />
                        </div>

                        {/* city */}
                        <div className="sendInnerSubDiv">
                            <label className="sendFisiLabel">{language.city}:</label>
                            <input
                                type="text"
                                placeholder={language.city}
                                className="sendFisiInp"
                                name="city"
                                value={formValue.city}
                                onChange={(e) => handleFormChange(e)}
                            />
                        </div>

                        {/* state */}
                        <div className="sendInnerSubDiv">
                            <label className="sendFisiLabel">{language.state}:</label>
                            <input
                                type="text"
                                placeholder={language.state}
                                className="sendFisiInp"
                                name="state"
                                value={formValue.state}
                                onChange={(e) => handleFormChange(e)}
                            />
                        </div>

                        {/* zip / postal */}
                        <div className="sendInnerSubDiv">
                            <label className="sendFisiLabel">Zip / Postal:</label>
                            <input
                                type="text"
                                placeholder="Zip / Postal"
                                className="sendFisiInp"
                                name="zip"
                                value={formValue.zip}
                                onChange={(e) => handleFormChange(e)}
                            />
                        </div>

                        {/* email recipient */}
                        <div className="sendInnerSubDiv">
                            <label className="sendFisiLabel">
                                {language.email_recipients}:
                            </label>
                            <input
                                type="email"
                                placeholder={language.email_recipients}
                                className="sendFisiInp"
                                name="emailRecipents"
                                value={formValue.emailRecipents}
                                onChange={(e) => handleFormChange(e)}
                            />
                        </div>
                    </div>

                    {/* Invoice Information / right part */}
                    <div className="sendInfoSubDiv">
                        <p className="sendInfoHead">{language.invoice_information}</p>

                        {/* Invoice Date */}
                        <div className="sendInnerSubDiv">
                            <label className="sendFisiLabel">{language.invoice_date}:</label>
                            <input
                                type="date"
                                placeholder={language.invoice_date}
                                className="sendFisiInp"
                                name="invoiceDate"
                                value={formValue.invoiceDate}
                                onChange={(e) => handleFormChange(e)}
                            />
                            {/* <Datetime className="sendFisiInp" /> */}
                        </div>

                        {/* Invoice # */}
                        <div className="sendInnerSubDiv">
                            <label className="sendFisiLabel">{language.invoice} #:</label>
                            <input
                                type="text"
                                className="sendFisiInp"
                                name="invoice"
                                value={formValue.invoice}
                                onChange={(e) => handleFormChange(e)}
                            />
                        </div>

                        {/* Order # */}
                        <div className="sendInnerSubDiv">
                            <label className="sendFisiLabel">{language.order} #:</label>
                            <input
                                type="text"
                                className="sendFisiInp"
                                name="Oredr"
                                value={formValue.Oredr}
                                onChange={(e) => handleFormChange(e)}
                            />
                        </div>

                        {/* Reference # */}
                        <div className="sendInnerSubDiv">
                            <label className="sendFisiLabel">{language.referance} #:</label>
                            <input
                                type="text"
                                className="sendFisiInp"
                                name="reference"
                                value={formValue.reference}
                                onChange={(e) => handleFormChange(e)}
                            />
                        </div>

                        {/* Purchase Order # */}
                        <div className="sendInnerSubDiv">
                            <label className="sendFisiLabel">
                                {language.purchase_order} #:
                            </label>
                            <input
                                type="text"
                                className="sendFisiInp"
                                name="purchaseOrder"
                                value={formValue.purchaseOrder}
                                onChange={(e) => handleFormChange(e)}
                            />
                        </div>

                    </div>
                </div>

                {/* Invoice Details / radio part */}
                <div className="sendInfoSecondFormDiv">
                    <div className="secInfoSubDiv">
                        <p className="inoDetPara">{language.invoice_details}</p>
                        <div className="mr-2">
                            <input
                                type="radio"
                                className="infoDetSubRadio"
                                name="invoiceDetailsType"
                                value="Product"
                                onChange={(e) => handleFormChange(e)}
                                checked={formValue.invoiceDetailsType === "Product"}
                            />
                            <label className="infoDetSubLabel">{language.product}</label>
                        </div>
                        <div>
                            <input
                                type="radio"
                                className="infoDetSubRadio"
                                name="invoiceDetailsType"
                                value="Service"
                                onChange={(e) => handleFormChange(e)}
                                checked={formValue.invoiceDetailsType === "Service"}
                            />
                            <label className="infoDetSubLabel">{language.service}</label>
                        </div>
                    </div>
                </div>

                <div className="sendInfoThirdFirstFormDiv">

                    {/* description / Add more part */}
                    <div className="thrFirstDiv">
                        <table>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th className="invoTableTH">
                                        <button
                                            id="desc-button"
                                            type='button'
                                            className="invoTHbtn"
                                            onClick={(e) => { addNewDesc(e) }}
                                        >
                                            <i className="fa-solid fa-plus"></i>
                                        </button>
                                        <span className="descSpan">{language.description}</span>
                                    </th>
                                    <th className="invoTableTH">
                                        <span>{language.quantity}</span>
                                    </th>
                                    <th className="invoTableTH">
                                        <span>{language.unit}</span>
                                    </th>
                                    <th className="invoTableTH">
                                        <span>{language.item_price} ($)</span>
                                    </th>
                                    <th className="invoTableTH">
                                        <span>Sub-Total ($)</span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    addMoreData.map((item, i, arr) => {
                                        return (
                                            <tr key={i}>
                                                <td className='invoiceTrashBtnTD'>
                                                    {arr.length !== 1 &&
                                                        <button
                                                            className='invoiceTrashBtn'
                                                            onClick={(e) => handleDeleteAddMore(e, i)}
                                                        >
                                                            <i className="fa-regular fa-trash-can"></i>
                                                        </button>
                                                    }
                                                </td>
                                                {/* description */}
                                                <td className="invoTableTD">
                                                    <input
                                                        type="text"
                                                        className="invoTableTDinp"
                                                        name='description'
                                                        value={addMoreData[i].description}
                                                        onChange={(e) => handleAddMoreChange(e, i)}
                                                    />
                                                </td>

                                                {/* Quantity */}
                                                <td className="invoTableTD">
                                                    <input
                                                        type="number"
                                                        className="invoTableTDinp"
                                                        name='quantity'
                                                        value={addMoreData[i].quantity}
                                                        onChange={(e) => {
                                                            handleAddMoreChange(e, i);
                                                        }}
                                                    />
                                                </td>

                                                {/* Unit */}
                                                <td className="invoTableTD">
                                                    <input
                                                        type="number"
                                                        className="invoTableTDinp"
                                                        name='unit'
                                                        value={addMoreData[i].unit.toUpperCase()}
                                                        onChange={(e) => handleAddMoreChange(e, i)}
                                                    />
                                                </td>

                                                {/* Item Price */}
                                                <td className="invoTableTD">
                                                    <input
                                                        type="number"
                                                        className="invoTableTDinp"
                                                        name='itemPrice'
                                                        value={addMoreData[i].itemPrice}
                                                        onChange={(e) => {
                                                            handleAddMoreChange(e, i);
                                                        }}
                                                    />
                                                </td>

                                                {/* sub-total */}
                                                <td>
                                                    <input
                                                        type="number"
                                                        // placeholder={`${language.price}X Quantity`}
                                                        className="invoTableTDinp"
                                                        name='subTotal'
                                                        value={addMoreData[i].subTotal}
                                                        onChange={(e) => handleAddMoreChange(e, i)}
                                                        readOnly
                                                    />
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>

                    {/* bottom right part */}
                    <div className="thrSecondDiv">
                        <div className="thrSecSubDiv">
                            <label className="thrSecSubLabel">{language.sale_tax} (%)</label>
                            <input
                                type="number"
                                placeholder=""
                                className="thrSecSubInp"
                                name="salesTaxPercentage"
                                value={formValue.salesTaxPercentage}
                                onChange={(e) => handleFormChange(e)}
                            />
                        </div>
                        <div className="thrSecSubDiv">
                            <label className="thrSecSubLabel">{language.sale_tax} ($)</label>
                            <input
                                type="number"
                                // placeholder="=subtotals X %"
                                className="thrSecSubInp"
                                name="salesTaxAmount"
                                value={formValue.salesTaxAmount}
                                onChange={(e) => handleFormChange(e)}
                                readOnly
                            />
                        </div>
                        <div className="thrSecSubDiv">
                            <label className="thrSecSubLabel">{language.other} ($)</label>
                            <input
                                type="number"
                                // placeholder={language.other}
                                className="thrSecSubInp"
                                name="other"
                                value={formValue.other}
                                onChange={(e) => handleFormChange(e)}
                            />
                        </div>
                        <div className="thrSecSubDiv">
                            <label className="thrSecSubLabel">Total ($)</label>
                            <input
                                type="number"
                                // placeholder={`=Sub-total + ${language.tax}$`}
                                className="thrSecSubInp"
                                name="total"
                                value={formValue.total}
                                onChange={(e) => handleFormChange(e)}
                                readOnly
                            />
                        </div>
                    </div>

                </div>
                <div className="sendBtnDiv">
                    <button
                        id="send-inv"
                        className="sendFisiBtn"
                        onClick={(e) => handleSend(e)}
                    >
                        SEND
                    </button>
                </div>
            </form>

        </div >
    )
}

export default NormalForm
