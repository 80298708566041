import React, { useCallback } from 'react'
import { AiOutlineClose } from "react-icons/ai";
import { useLanguage } from '../../Providers/LanguageContext';

function GeneralNewsData({ data, closeModal, time, userType, currency }) {
    console.log("datanews", data)

    const { language, currentLanguage } = useLanguage();
    const spanishEngTime = (time) => {
        switch (time) {
            case 'day': return language.daily
            case 'month': return language.monthly
            case 'week': return language.weekly
            case 'year': return language.yearly
            default: return time;
        }
    }

    const renderJsx = useCallback(() => {
        if (userType === "Business") {
            if (time === "year") {
                return (
                    <div>
                        {/* expense yearly */}
                        {
                            (data.TotalExpenseCogs || data.TotalExpenseRecurring)
                            &&
                            <div>
                                <h3>{language.expense_information}</h3>
                                <ul>
                                    {
                                        data.TotalExpenseCogs
                                            ?
                                            currentLanguage === 'eng'
                                                ?
                                                <p>
                                                    Total Transactional Expenses for Current Year are $ {data.TotalExpenseCogs}.
                                                </p>
                                                :
                                                <p>
                                                    El total de los gastos transaccionales del año en curso es de $ {data.TotalExpenseCogs}.
                                                </p>
                                            :
                                            null
                                    }

                                    {
                                        data.TotalExpenseRecurring
                                            ?
                                            currentLanguage === 'eng'
                                                ?
                                                <p>
                                                    Total Recurring Expenses for Current Year are $ {data.TotalExpenseRecurring}.
                                                </p>
                                                :
                                                <p>
                                                    El total de gastos recurrentes para el año en curso es de $ {data.TotalExpenseRecurring}.
                                                </p>
                                            :
                                            null
                                    }
                                </ul>

                                {/* sales yearly */}
                                {
                                    (data.TotalSalesCash || data.TotalSalesCredit)
                                    &&
                                    <div>
                                        <h3>{language.sales_information}</h3>
                                        <ul>
                                            {
                                                data.TotalSalesCash
                                                    ?
                                                    currentLanguage === 'eng'
                                                        ?
                                                        <p>
                                                            Total Cash Sales Receipts for Current Year are $ {data.TotalSalesCash}.
                                                        </p>
                                                        :
                                                        <p>
                                                            El total de ingresos por ventas en efectivo para el año en curso es de $ {data.TotalSalesCash}.
                                                        </p>
                                                    :
                                                    null
                                            }

                                            {
                                                data.TotalSalesCredit
                                                    ?
                                                    currentLanguage === 'eng'
                                                        ?
                                                        <p>
                                                            Total Credit Sales Receipts for Current Year are $ {data.TotalSalesCredit}.

                                                        </p>
                                                        :
                                                        <p>
                                                            El total de ingresos por ventas a crédito para el año en curso es de $ {data.TotalSalesCredit}.
                                                        </p>
                                                    :
                                                    null
                                            }
                                        </ul>
                                    </div>
                                }
                            </div>
                        }
                    </div>
                )
            } else {
                return (
                    <div>
                        {/* expense */}
                        {
                            (data.AvgExpenseCogs || data.AvgExpenseRecurring)
                            &&
                            <div>
                                <h3>{language.expense_information}</h3>
                                <ul>
                                    {
                                        data.AvgExpenseCogs
                                            ?
                                            currentLanguage === 'eng'
                                                ?
                                                <p>
                                                    Average {spanishEngTime(time)} Transactional Expenses are $ {data.AvgExpenseCogs}.

                                                </p>
                                                :
                                                <p>
                                                    Los gastos transaccionales {spanishEngTime(time)} medios son de $ {data.AvgExpenseCogs}.
                                                </p>
                                            :
                                            null
                                    }

                                    {
                                        data.AvgExpenseRecurring
                                            ?
                                            currentLanguage === 'eng'
                                                ?
                                                <p>
                                                    Average {spanishEngTime(time)} Recurring Expenses are $ {data.AvgExpenseRecurring}.
                                                </p>
                                                :
                                                <p>
                                                    El promedio de gastos {spanishEngTime(time)} recurrentes es de $ {data.AvgExpenseRecurring}.

                                                </p>
                                            :
                                            null
                                    }

                                </ul>
                            </div>
                        }

                        {/* sales */}
                        {
                            (data.AvgSalesCash || data.AvgSalesCredit)
                            &&
                            <div>
                                <h3>{language.sales_information}</h3>
                                <ul>
                                    {
                                        data.AvgSalesCash
                                            ?
                                            currentLanguage === 'eng'
                                                ?
                                                <p>
                                                    Average {spanishEngTime(time)} Cash Sales Receipts are $ {data.AvgSalesCash}.
                                                </p>
                                                :
                                                <p>
                                                    El Promedio {spanishEngTime(time)} de Ventas en efectivo es de $ {data.AvgSalesCash}.
                                                </p>
                                            :
                                            null
                                    }

                                    {
                                        data.AvgSalesCredit
                                            ?
                                            currentLanguage === 'eng'
                                                ?
                                                <p>
                                                    Average {spanishEngTime(time)} Credit Sales are $ {data.AvgSalesCredit}.
                                                </p>
                                                :
                                                <p>
                                                    El Promedio {spanishEngTime(time)} de Ventas a crédito es de $ {data.AvgSalesCredit}.
                                                </p>
                                            :
                                            null
                                    }
                                </ul>
                            </div>
                        }
                    </div>
                )
            }

        } else if (userType === "Personal") {
            if (time === "year") {
                return (
                    <div>
                        {/* expense yearly pu */}
                        {
                            (data.TotalExpenseCogs || data.TotalExpenseRecurring)
                            &&
                            <div>
                                <h3>{language.expense_information}</h3>
                                <ul>
                                    {
                                        data.TotalExpenseCogs
                                            ?
                                            currentLanguage === 'eng'
                                                ?
                                                <p>
                                                    Total Transactional Expenses for Current Year are $ {data.TotalExpenseCogs}.
                                                </p>
                                                :
                                                <p>
                                                    El total de los gastos transaccionales del año en curso es de $ {data.TotalExpenseCogs}.
                                                </p>
                                            :
                                            null
                                    }

                                    {
                                        data.TotalExpenseRecurring
                                            ?
                                            currentLanguage === 'eng'
                                                ?
                                                <p>
                                                    Total Recurring Expenses for Current Year are $ {data.TotalExpenseRecurring}.
                                                </p>
                                                :
                                                <p>
                                                    El total de gastos recurrentes para el año en curso es de $ {data.TotalExpenseRecurring}.
                                                </p>
                                            :
                                            null
                                    }
                                </ul>

                                {/* sales yearly pu */}
                                {
                                    (data.TotalSalesCash || data.TotalSalesDigital)
                                    &&
                                    <div>
                                        <h3>{language.income_information}</h3>
                                        <ul>
                                            {
                                                data.TotalSalesCash
                                                    ?
                                                    currentLanguage === 'eng'
                                                        ?
                                                        <p>
                                                            Total Cash Income Receipts for Current Year are $ {data.TotalSalesCash}.
                                                        </p>
                                                        :
                                                        <p>
                                                            El total de ingresos en efectivo para el año en curso es de $ {data.TotalSalesCash}.
                                                        </p>
                                                    :
                                                    null
                                            }

                                            {
                                                data.TotalSalesDigital
                                                    ?
                                                    currentLanguage === 'eng'
                                                        ?
                                                        <p>
                                                            Total Digital Income Receipts for Current Year are $ {data.TotalSalesDigital}.
                                                        </p>
                                                        :
                                                        <p>
                                                            El total de ingresos digitales para el año en curso es de $ {data.TotalSalesDigital}.
                                                        </p>
                                                    :
                                                    null
                                            }
                                        </ul>
                                    </div>
                                }
                            </div>
                        }
                    </div>
                )
            } else {
                return (
                    <div>
                        {/* expense pu */}
                        {
                            (data.AvgExpenseCogs || data.AvgExpenseRecurring)
                            &&
                            <div>
                                <h3>{language.expense_information}</h3>
                                <ul>
                                    {
                                        data.AvgExpenseCogs
                                            ?
                                            currentLanguage === 'eng'
                                                ?
                                                <p>
                                                    Average {spanishEngTime(time)} Transactional Expenses are $ {data.AvgExpenseCogs}.

                                                </p>
                                                :
                                                <p>
                                                    Los gastos transaccionales {spanishEngTime(time)} medios son de $ {data.AvgExpenseCogs}.
                                                </p>
                                            :
                                            null
                                    }

                                    {
                                        data.AvgExpenseRecurring
                                            ?
                                            currentLanguage === 'eng'
                                                ?
                                                <p>
                                                    Average {spanishEngTime(time)} Recurring Expenses are $ {data.AvgExpenseRecurring}.
                                                </p>
                                                :
                                                <p>
                                                    El promedio de gastos {spanishEngTime(time)} recurrentes es de $ {data.AvgExpenseRecurring}.

                                                </p>
                                            :
                                            null
                                    }

                                </ul>
                            </div>
                        }

                        {/* sales pu */}
                        {
                            (data.AvgSalesCash || data.AvgSalesDigital)
                            &&
                            <div>
                                <h3>{language.income_information}</h3>
                                <ul>
                                    {
                                        data.AvgSalesCash
                                            ?
                                            currentLanguage === 'eng'
                                                ?
                                                <p>
                                                    Average {spanishEngTime(time)} Cash Income Receipts are $ {data.AvgSalesCash}.
                                                </p>
                                                :
                                                <p>
                                                    El promedio de ingresos {spanishEngTime(time)} en efectivo es de $ {data.AvgSalesCash}.
                                                </p>
                                            :
                                            null
                                    }

                                    {
                                        data.AvgSalesDigital
                                            ?
                                            currentLanguage === 'eng'
                                                ?
                                                <p>
                                                    Average {spanishEngTime(time)} Digital Income is $ {data.AvgSalesDigital}.
                                                </p>
                                                :
                                                <p>
                                                    El promedio de ingresos digitales {spanishEngTime(time)} es de $ {data.AvgSalesDigital}.
                                                </p>
                                            :
                                            null
                                    }
                                </ul>
                            </div>
                        }
                    </div>
                )
            }
        }
    }, [userType, time, data])


    return (
        <div>
            <div className='d-flex justify-content-end'>
                <AiOutlineClose className='icon' onClick={closeModal} size={20} color="gray" />
            </div>
            {
                data && (
                    <div>
                        {
                            renderJsx()
                        }
                    </div>
                )
            }
            {
                (!data || (typeof data === "object" && Object.values(data).length === 0)) && (
                    <span>{language.no_data}</span>
                )
            }
        </div>
    )
}

export default GeneralNewsData