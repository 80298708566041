import React from 'react'

export default function MVPstory({ goToRegistration }) {
  return (
    <div className='inner'>
      <div className=" thrd_slide">
        <div className="bottom_cnt mt-3">
          <h4>
            Trusted by Administration Professionals & Office Managers
            Everywhere
          </h4>
          <p>
            Join Thousands of Admin Professionals Who Have Unlocked Their
            Potential With Fisibility
          </p>
          <p>
            Check Out a Few Of Their Success Stories Below to See How
            Fisibility Has Transformed Their Lives:
          </p>
          <p>
            "Fisibility has been a game-changer for me. It's like having a
            personal assistant that never sleeps. I can finally manage my
            tasks and finances effortlessly. Highly recommend!" - Sarah,
            Executive Assistant
          </p>
          <p>
            "I used to drown in spreadsheets and calendars. Fisibility
            made my life so much easier. It's intuitive and saved me
            countless hours of work. Thank you, Fisibility!" - John,
            Office Manager
          </p>
          <p>
            "As an Admin Professional, Fisibility has been a lifesaver.
            It's made me more organized, reduced stress, and improved
            productivity. I can't imagine my workday without it." - Emily,
            Administrative Assistant
          </p>
          <p>
            "Fisibility's freemium license is a game-changer. I started
            with the free version and couldn't believe the value. Now I've
            upgraded, and it's transformed the way I manage tasks and
            finances." - Mike, Administration Professional
          </p>
          <p>
            "The security and support from Fisibility are top-notch. The
            AES 256-bit encryption and access to a product specialist give
            me peace of mind. Fisibility truly cares about its users." -
            Lisa, Office Manager
          </p>
          <a onClick={() => goToRegistration()} className="signupBtn">
            My MVP Story Begins Now!
          </a>
        </div>
      </div>
    </div>

  )
}
