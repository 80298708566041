
import React, { useMemo, useState, useRef, useEffect } from 'react';
import Slider from 'react-slick';
import { useLocation, useNavigate } from 'react-router-dom';
import LinkInvestMent from './LinkInvestMent';
import ManageInvestment from './ManageInvestment';
import { useLanguage } from '../../Providers/LanguageContext';

const LinkManageInvestment = () => {
    const { language } = useLanguage();
    const navigate = useNavigate();
    const location = useLocation();
    const sliderRef = useRef(null);
    const [linkedAccData, setLinkedAccData] = useState([]);
    const [callGetAcc, setCallGetAcc] = useState(false);
    const [buttonText, setButtonText] = useState(language.view_manage_docs);
    const [slideIndex, setSlideIndex] = useState(0);
    const [initialSlide, setInitialSlide] = useState(0);

    // const getInitialSlideMemo = useMemo(() => {
    //     if (location?.state?.slideNo === 1) {
    //         return 1;
    //     }
    //     else return 0;
    // }, [location]);

    useEffect(() => {
        if (location?.state?.slideNo === 1) {
            setInitialSlide(1);
            setButtonText(language.link_investment);
            handleButtonClick()
        } else {
            setInitialSlide(0);
            setButtonText(language.view_manage_docs);
        }
    }, [location, language]);

    // Slider settings
    const settings = {
        dots: false,
        infinite: false,
        swipe: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        initialSlide: initialSlide,
        // initialSlide: getInitialSlideMemo,
        afterChange: (index) => setSlideIndex(index),
    };

    // Handle button click 
    const handleButtonClick = () => {
        if (buttonText === language.view_manage_docs) {
            setButtonText(language.link_investment);
            sliderRef.current.slickGoTo(1);
        } else {
            setButtonText(language.view_manage_docs);
            sliderRef.current.slickGoTo(0);
        }
    };

    return (
        <div className='container'>
            <div className='d-flex gap-2'>
                <div className=''>
                    <button className='w-100 btn btn-outline-success' style={{ fontWeight: "bold" }} onClick={() => navigate('/investment-options')}>{language.investment_option}</button>
                </div>

                <div className=''>
                    <button className='w-100 btn btn-outline-success' style={{ fontWeight: "bold" }} onClick={handleButtonClick}>
                        {buttonText}
                    </button>
                </div>
            </div>
            <div>
                <h3 className='BookLinkHading'>{language.inv_manager} - {language.link_manage_inv}</h3>
            </div>

            <div className="chart-slide">
                <div className="container">
                    <Slider {...settings} ref={sliderRef}>
                        <div>
                            <LinkInvestMent
                                setLinkedAccData={setLinkedAccData}
                                callGetAcc={callGetAcc}
                            />
                        </div>

                        <div>
                            <ManageInvestment
                                linkedAccData={linkedAccData}
                                setCallGetAcc={setCallGetAcc}
                            />
                        </div>
                    </Slider>
                </div>
            </div>
        </div>
    );
}

export default LinkManageInvestment;

