import React from "react";

function BookCustDocMissingModal() {
  function bookkMissModalClose() {
    document
      .querySelector(".bookkMissModalSection")
      .classList.add("bookCustDisplayModalDiv");
  }
  return (
    <>
      <section className="bookkMissModalSection bookCustDisplayModalDiv">
        <div className="bookCustModalDiv">
          <button className="bookkCustModClsBtn" onClick={bookkMissModalClose}>
            <i class="fa-solid fa-xmark"></i>
          </button>
          <p className="bookkCustModHead">ABC Enterprises: Missing Documents</p>
          <div className="bookkMissingDocTableDiv">
            <table className="bookkMissingDocTable">
              <thead>
                <tr>
                  <th className="bookkMissTableHead">Document Type</th>
                  <th className="bookkMissTableHead">Due Date</th>
                  <th className="bookkMissTableHead">Status</th>
                  <th className="bookkMissTableHead">Days Past Due</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="bookkMissTableData">
                    <input type="text" className="bookkMissDocType" />
                  </td>
                  <td className="bookkMissTableData">
                    <input type="date" className="bookkMissDocType" />
                  </td>
                  <td className="bookkMissTableData">
                    <span className="bookkMissDocSpan">Past Due</span>
                  </td>
                  <td className="bookkMissTableData">
                    <span className="bookkMissDocSpan">6</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </>
  );
}

export default BookCustDocMissingModal;
