import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react'
import { toast } from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router-dom'
import CustomModal from '../../Component/CustomModal';
import ExpenseEditInput from '../../Component/expense/ExpenseEditInput';
import { useLanguage } from '../../Providers/LanguageContext';
import HttpClient from '../../utils/HttpClient';
import InvoiceInput from './InvoiceInput';
import { AiOutlineArrowLeft, AiOutlineClose } from "react-icons/ai"
import invoice_summary_en1 from "../../Audio_files/V. Invoice Manager Audio/V. b. View Invoices User Selected Option/v_view_invoices_audio_b.mp3";
import invoice_summary_es1 from "../../spanish_audio_files/V. Invoice Manager Audio/V. b. View Invoices User Selected Option/v_view_invoices_audio_b_es.mp3";
import { SpeechContext } from '../../Providers/SpeechWrapper';

function InvoiceSummaryDetails() {
    const location = useLocation();
    const navigate = useNavigate();
    const { language, currentLanguage } = useLanguage()
    const { currAudio, setCurrAudio, playing } = useContext(SpeechContext);
    const [invoiceDetailsData, setInvoiceData] = useState([])
    console.log('invoiceDetailsData', invoiceDetailsData)

    const [modalIsOpen, setIsOpen] = useState(false);

    function openModal() {
        setIsOpen(true);
    }


    function closeModal() {
        setIsOpen(false);
    }
    console.log(location.state, "locationstate")
    useEffect(() => {
        if (!location.state) {
            navigate(-1)
        } else {
            fetchInvoiceDetails(location.state)
        }
    }, [location.state])

    const fetchInvoiceDetails = async (id) => {
        console.log('invid', id)
        const res = await HttpClient.requestData("view-invoice-details-data/" + id, "GET", {});
        console.log('invRes', res)
        if (res && res.data) {
            console.log(res);
            setInvoiceData(res.data[0])
        } else {
            console.log("error", res)
        }
    }

    const changePaymentStatus = async (id) => {
        const res = await HttpClient.requestData("update-invoice-payment/" + id, "PUT", {});
        if (res && res.status) {
            toast.success(language.invoice_payment_success);
            fetchInvoiceDetails(id)
        } else {
            toast.error(language.invoice_payment_error);
        }
    }

    useEffect(() => {
        if (playing) {
            if (currAudio) {
                if (!currAudio.paused) {
                    currAudio.pause()
                }
            }
            if (currentLanguage === "eng") {
                setCurrAudio(new Audio(invoice_summary_en1))
            } else {
                setCurrAudio(new Audio(invoice_summary_es1))
            }
        } else {
            if (currAudio) {
                if (!currAudio.paused) {
                    currAudio.pause()
                    setCurrAudio(null)
                }
            }
        }

        return () => {
            if (currAudio && !currAudio.paused) {
                currAudio.pause()
            }
        }
    }, [playing, currentLanguage])


    // useEffect(() => {
    //     console.log("currAudio", currAudio)
    //     if (currAudio) {
    //         if (!currAudio.paused) {
    //             currAudio.pause()
    //         }
    //         currAudio.play()
    //     }
    //     return () => {
    //         if (currAudio && !currAudio.paused) {
    //             currAudio.pause()
    //         }
    //     }
    // }, [currAudio])

    return (
        <>

            <div className='container'>
                <div
                    className="back-arrow-left"
                    onClick={() => {
                        navigate(-1)
                        console.log("object")
                    }
                    }
                >
                    <AiOutlineArrowLeft />
                </div>
                <section id='mng-top' className='mb-4'>

                    <div className='container'>
                        <div className='row'>
                            <div className='col-xl-12 col-lg-12 col-md-12 col-12'>
                                <div className='left-heading text-center'>
                                    <h2>{invoiceDetailsData && invoiceDetailsData.customerName}</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <div id='todo-table'>
                    <div className='container'>
                        <div className='table-responsive'>
                            <table className="table">
                                <tbody>
                                    {
                                        invoiceDetailsData && (
                                            <tr style={{ backgroundColor: "#ebeff3" }}>
                                                {/* <th scope="row">1</th> */}
                                                <td style={{ color: "#0e2537", fontWeight: "600", fontSize: "18px", paddingLeft: "20px", borderTop: "0" }}>
                                                    {moment(invoiceDetailsData.date).utc().format("LL")}
                                                </td>
                                                <td style={{ display: "flex", justifyContent: "flex-end", alignItems: "flex-end", paddingRight: "30px" }}>

                                                    <span className='mx-3 mb-2'>
                                                        #{invoiceDetailsData.invoiceNumber}
                                                    </span>
                                                    <span className='mx-3 mb-2'>
                                                        ${invoiceDetailsData.subTotal}
                                                    </span>
                                                    {
                                                        invoiceDetailsData && invoiceDetailsData.paymentStatus === "Unpaid" && (
                                                            <button style={{
                                                                width: "100px", height: "40px", borderRadius: "10px",
                                                                background: "linear-gradient(to right, #ed213a, #93291e)",
                                                                border: "none", color: "#fff", fontSize: "16px",
                                                                marginRight: "10px"
                                                            }}
                                                                onClick={() => changePaymentStatus(invoiceDetailsData._id)}
                                                            >
                                                                {language.unpaid}
                                                            </button>
                                                        )
                                                    }
                                                    {
                                                        invoiceDetailsData && invoiceDetailsData.paymentStatus !== "Unpaid" && (
                                                            <button className='btn-success' style={{
                                                                width: "100px", height: "40px", borderRadius: "10px",
                                                                border: "none", color: "#fff", fontSize: "16px",
                                                                marginRight: "10px"
                                                            }}
                                                                disabled
                                                            >
                                                                {language.paid}
                                                            </button>
                                                        )
                                                    }

                                                    <button style={{
                                                        width: "100px", height: "40px", borderRadius: "10px",
                                                        background: "linear-gradient(to right, #18465e 1%, #14384d 51%, #0f273a 100%)",
                                                        border: "none", color: "#fff", fontSize: "16px"
                                                    }}
                                                        onClick={() => {
                                                            openModal()
                                                        }}
                                                    >
                                                        {language.see}
                                                    </button>
                                                </td>
                                                {/* <td>@mdo</td> */}
                                            </tr>
                                        )
                                    }

                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>

            </div>
            <CustomModal
                modalIsOpen={modalIsOpen}
                setIsOpen={setIsOpen}
                openModal={openModal}
                closeModal={closeModal}
            >
                <div>
                    <div className='d-flex justify-content-between align-items-center text-dark'>
                        <div className='d-flex justify-content-start align-items-center'>
                            {/* <span style={{fontSize:"1.3rem"}}>
                         {invoiceDetailsData && invoiceDetailsData.customerName}
                     </span> */}
                            <button className='customBtn3' onClick={() => navigate("/invoice-print", { state: invoiceDetailsData })}>
                                {language.view}
                            </button>
                        </div>

                        <span>
                            <AiOutlineClose className='icon' onClick={() => {
                                closeModal();
                            }} size={20} color="gray" />
                        </span>
                    </div>
                    <div style={{ width: "100%" }}>
                        <InvoiceInput type='text' label={language.customer_name} value={invoiceDetailsData.customerName} />
                        <InvoiceInput type='text' label={language.customer_email} value={invoiceDetailsData.customerEmail} />
                        <InvoiceInput type='text' label={language.created_at} value={moment(invoiceDetailsData.createdOn).utc().format("YYYY/MM/DD")} />
                        <InvoiceInput type='text' label={language.certification_date} value={moment(invoiceDetailsData.date).utc().format("YYYY/MM/DD")} />
                        <InvoiceInput type='text' label={language.method_of_payment} value={invoiceDetailsData.methodOfPay === "cash" ? language.cash : language.credit} />
                        <InvoiceInput type='number' label={language.invoice_amount} value={Number(invoiceDetailsData.invoiceAmount)} />
                        <InvoiceInput type='number' label={language.subtotal} value={Number(invoiceDetailsData.subTotal)} />

                        <InvoiceInput type='text' label={language.identification_number} value={invoiceDetailsData.identificationNumber} />
                        <InvoiceInput type='text' label={language.invoice_number} value={invoiceDetailsData.invoiceNumber} />

                    </div>
                </div>
            </CustomModal>
        </>
    )
}

export default InvoiceSummaryDetails