import React, { useState } from 'react'
import { useLanguage } from '../../Providers/LanguageContext';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';

const AddUserModal = (props) => {
    const { setShowModal, formValue, setFormValue, handleAddUser } = props;
    const { language, currentLanguage } = useLanguage();
    const [visible, setVisible] = useState(false);
    const [confirmvisible, setConfirmVisible] = useState(false);

    const passsVisibility = () => {
        if (visible) {
            setVisible(false)
        }
        else {
            setVisible(true)
        }
    }
    const passsVisibility2 = () => {
        if (confirmvisible) {
            setConfirmVisible(false)
        }
        else {
            setConfirmVisible(true)
        }
    }

    return (
        <div>
            <div id="add-user-modal" class="modal">
                <div class="modal-content">
                    <span class="close" onClick={() => setShowModal(false)}>&times;</span>
                    <section id='create-account' style={{ padding: "0" }}>
                        <div className='container'>
                            <div className='heading'>
                                <h4>{language.add_user}</h4>
                            </div>
                            <div className='inner-container'>
                                <form
                                // onSubmit={handleRegister}
                                >
                                    <div className="form-group text-field">
                                        <input
                                            type="email"
                                            className="form-control"
                                            id=""
                                            aria-describedby=""
                                            placeholder={language.email_placeholder}
                                            name="email"
                                            value={formValue.email}
                                            onChange={(e) => setFormValue((prev) => { return { ...prev, email: e.target.value } })}
                                        />
                                        {/* <span className='text-danger'>{formError.email}</span> */}
                                    </div>

                                    <div className='lock-field'>

                                        <div className="form-group">
                                            <input
                                                type={visible ? 'text' : 'password'}
                                                className="form-control"
                                                aria-describedby=""
                                                placeholder={language.password_placeholder}
                                                name="pass"
                                                value={formValue.pass}
                                                onChange={(e) => setFormValue((prev) => { return { ...prev, pass: e.target.value } })}

                                            />
                                            <div
                                                onClick={passsVisibility}
                                                className='lock-icon'
                                            >
                                                {
                                                    visible ? (<AiFillEyeInvisible size={25} />) :
                                                        (<AiFillEye size={25} />)
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    {/* <span className='text-danger'>{formError.pass}</span> */}

                                    <div className='lock-field'>
                                        <div className="form-group">
                                            <input
                                                type={confirmvisible ? 'text' : 'password'}
                                                className="form-control"
                                                aria-describedby=""
                                                placeholder={language.confirm_password_placeholder}
                                                name="conPass"
                                                value={formValue.conPass}
                                                onChange={(e) => setFormValue((prev) => { return { ...prev, conPass: e.target.value } })}
                                            />
                                            <div
                                                onClick={passsVisibility2}
                                                className='lock-icon'>
                                                {
                                                    confirmvisible ? (<AiFillEyeInvisible size={25} />) :
                                                        (<AiFillEye size={25} />)
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    {/* <span className='text-danger'>{formError.conPass}</span> */}



                                    {/* ...........Account Type.......... */}

                                    <div className='form-checkbox'>
                                        <label className="check-field">
                                            {language.manager}
                                            <input type="radio"
                                                checked={formValue.acType === "Manager" ? true : false}
                                                id="ac2"
                                                name="acType"
                                                value="Manager"
                                                onChange={(e) => setFormValue((prev) => { return { ...prev, acType: e.target.value } })}
                                            />
                                            <span className="checkmark" />
                                        </label>

                                        <label className="check-field">
                                            {language.staff}
                                            <input type="radio"
                                                checked={formValue.acType === "Staff" ? true : false}
                                                id="ac3"
                                                name="acType"
                                                value="Staff"
                                                onChange={(e) => setFormValue((prev) => { return { ...prev, acType: e.target.value } })}
                                            />
                                            <span className="checkmark" />
                                        </label>

                                        {/* bookkeper */}
                                        <label className="check-field">
                                            {language.bookkeeper}
                                            <input type="radio"
                                                id="ac3"
                                                name="acType"
                                                value="Bookkeeper"
                                                onChange={(e) => setFormValue((prev) => { return { ...prev, acType: e.target.value } })}
                                            />
                                            <span className="checkmark" />
                                        </label>
                                    </div>
                                    {/* <span className='text-danger'>{formError.acType}</span> */}

                                    <div className='account-btn'>
                                        <button onClick={handleAddUser} className='register-btn'>
                                            {language.add.toUpperCase()}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </section >

                </div>

            </div>
        </div>
    )
}

export default AddUserModal
