import React, { useCallback } from 'react'
import { AiOutlineClose } from "react-icons/ai";
import { useLanguage } from '../../Providers/LanguageContext';

function BadNewsData({ data, closeModal, time, userType, currency }) {
    console.log("datanews", data)
    const { language, currentLanguage } = useLanguage()

    const spanishEngTime = (time) => {
        switch (time) {
            case 'day': return language.daily
            case 'month': return language.monthly
            case 'week': return language.weekly
            case 'year': return language.yearly
            default: return time;
        }
    }

    const personalJsx = (data) => {
        return (
            <div>
                <ul>
                    {
                        currentLanguage === 'eng'
                            ?
                            <p>
                                Household is spending $ {data} more in {spanishEngTime(time)} Expenses than Budget calls for.
                            </p>
                            :
                            <p>
                                La familiar está gastando $ {data} {spanishEngTime(time)} en Gastos mensuales de lo que exige el presupuesto.
                            </p>
                    }
                </ul>
            </div>
        )
    }

    const renderJsx = useCallback(() => {
        if (userType === "Business") {

            return (
                <div className='d-flex justify-content-start flex-column'>
                    {/* expense info */}
                    {
                        data.expenseBudget &&
                        <div>
                            <h3>{language.expense_information}</h3>
                            <ul>

                                {
                                    currentLanguage === 'eng'
                                        ?
                                        <p>
                                            Company is spending $ {data.expenseBudget} more in {spanishEngTime(time)} Expenses than Budget calls for.
                                        </p>
                                        :
                                        <p>
                                            La compañía está gastando $ {data.expenseBudget} {spanishEngTime(time)} en Gastos mensuales de lo que exige el presupuesto.
                                        </p>
                                }

                            </ul>
                        </div>
                    }

                    {/* salse Info */}
                    {
                        (data.salesBudget || data.avgSalesDecreased) &&
                        <div>
                            <h3>{language.sales_information}</h3>
                            <ul>
                                {
                                    data.salesBudget
                                        ?
                                        currentLanguage === 'eng'
                                            ?
                                            <p>
                                                Company is missing {spanishEngTime(time)} Sales Budget by $ {data.salesBudget}.
                                            </p>
                                            :
                                            <p>
                                                A la compañía le falta el presupuesto de ventas {spanishEngTime(time)} por $ {data.salesBudget}.
                                            </p>
                                        :
                                        null
                                }

                                {
                                    data.avgSalesDecreased
                                        ?
                                        currentLanguage === 'eng'
                                            ?
                                            <p>
                                                Average {spanishEngTime(time)} Sales have decreased by $ {data.avgSalesDecreased}.
                                            </p>
                                            :
                                            <p>
                                                Las Ventas {spanishEngTime(time)} Promedio han disminuido en $ {data.avgSalesDecreased}.
                                            </p>
                                        :
                                        null
                                }
                            </ul>
                        </div>
                    }

                    {/* profit info */}
                    {
                        (data.profitBudget || data.avgProfitDecreased) &&
                        <div>
                            <h3>{language.profit_information}</h3>
                            <ul>
                                {
                                    data.profitBudget
                                        ?
                                        currentLanguage === 'eng'
                                            ?
                                            <p>
                                                Company is missing {spanishEngTime(time)} Profit Budget by $ {data.profitBudget}
                                            </p>
                                            :
                                            <p>
                                                A la compañía le falta el presupuesto de ganancias {spanishEngTime(time)} por $ {data.profitBudget}
                                            </p>
                                        :
                                        null
                                }

                                {
                                    data.avgProfitDecreased
                                        ?
                                        currentLanguage === 'eng'
                                            ?
                                            <p>
                                                Average {spanishEngTime(time)} Profit has decreased by $ {data.avgProfitDecreased}
                                            </p>
                                            :
                                            <p>
                                                Las Ventas {spanishEngTime(time)} Promedio han disminuido en $ {data.avgSalesDecreased}
                                            </p>
                                        :
                                        null
                                }
                            </ul>
                        </div>
                    }

                    {/* {
                            (Boolean(data.salesBudget) || data.salesBudget===0) && (<span>
                                {language.sales_budget}: {Number(data?.salesBudget).2)} {currency}
                            </span>
                            )
                        }
                        {
                            (Boolean(data.avgSalesDecreased) || data.avgSalesDecreased===0) && (
                                <span>
                                    {language.avg_sales_decrease}: {Number(data?.avgSalesDecreased).toFixed(2)} {currency}
                                </span>
                            )
                        }
                        {
                            (Boolean(data.avgProfitDecreased) || data.avgProfitDecreased===0) && (
                                <span>
                                    {language.avg_profit_decrease}: {Number(data?.avgProfitDecreased).toFixed(2)} {currency}
                                </span>
                            )
                        }
                        {
                            (Boolean(data.expenseBudget) || data.expenseBudget===0) && (
                                <span>
                                    {language.expense_budget}: {Number(data?.expenseBudget).toFixed(2)} {currency}
                                </span>
                            )
                        }
                        {
                            (Boolean(data.profitBudget) || data.profitBudget===0) && (
                                <span>
                                    {language.profit_budget}: {Number(data?.profitBudget).toFixed(2)} {currency}
                                </span>
                            )
                        } */}
                </div>
            )
        } else if (userType === "Personal") {
            return (
                <div>
                    <h3>{language.expense_information}</h3>

                    {
                        data.dailyExpenseIncreased &&
                        personalJsx(data.dailyExpenseIncreased)
                    }

                    {
                        data.weeklyExpenseIncreased &&
                        personalJsx(data.weeklyExpenseIncreased)
                    }

                    {
                        data.monthlyExpenseIncreased &&
                        personalJsx(data.monthlyExpenseIncreased)
                    }

                    {
                        data.yearlyExpenseIncreased &&
                        personalJsx(data.yearlyExpenseIncreased)
                    }

                </div>
            )

            // if (time === "day") {

            //     return (
            //         <div className='d-flex justify-content-start flex-column'>
            //             {(Boolean(data.dailyExpenseIncreased) || data.dailyExpenseIncreased === 0) && (
            //                 <span>
            //                     {language.daily_expense} : {Number(data?.dailyExpenseIncreased).toFixed(2)} {currency}
            //                 </span>
            //             )}

            //         </div>
            //     )
            // } else if (time === "week") {
            //     return (
            //         <div className='d-flex justify-content-start flex-column'>
            //             {
            //                 (Boolean(data.weeklyExpenseIncreased) || data.weeklyExpenseIncreased === 0) && (
            //                     <span>
            //                         {language.weekly_expense} : {Number(data?.weeklyExpenseIncreased).toFixed(2)} {currency}
            //                     </span>
            //                 )
            //             }

            //         </div>
            //     )
            // } else if (time === "month") {
            //     return (
            //         <div>
            //             {
            //                 (Boolean(data.monthlyExpenseIncreased) || data.monthlyExpenseIncreased === 0) && (
            //                     <span>
            //                         {language.monthly_expense} : {Number(data?.monthlyExpenseIncreased).toFixed(2)} {currency}
            //                     </span>
            //                 )
            //             }

            //         </div>
            //     )
            // } else if (time === "year") {
            //     return (
            //         <div>
            //             {
            //                 (Boolean(data.yearlyExpenseIncreased) || data.yearlyExpenseIncreased === 0) && (
            //                     <span>
            //                         {language.yearly_expense} : {Number(data?.yearlyExpenseIncreased).toFixed(2)} {currency}
            //                     </span>
            //                 )
            //             }

            //         </div>
            //     )
            // }
        }
    }, [userType, time, data])
    console.log('userTypeBad', userType)

    return (
        <div>
            <div className='d-flex justify-content-end'>
                <AiOutlineClose className='icon' onClick={closeModal} size={20} color="gray" />
            </div>
            {
                data && (
                    <div>
                        {
                            renderJsx()
                        }
                    </div>
                )
            }
            {
                (!data || (typeof data === "object" && Object.values(data).length === 0)) && (
                    <span>{language.no_data}</span>
                )
            }
        </div>
    )
}

export default BadNewsData