import React, { useEffect, useState } from "react";
import "./TaskManager.css";
import { Link } from "react-router-dom";
import moment from "moment";
import HttpClient from "../../utils/HttpClient";
import toast from "react-hot-toast";
import { useLanguage } from "../../Providers/LanguageContext";
function EventPopUp({ closemodal, detailData, getEventData }) {

  const { language } = useLanguage();
  const [financeData, setFinanceData] = useState({});

  // console.log("financeData", financeData)

  const handleSelectStatus = async (e) => {
    if (!e.target.value) return

    const data = {
      eventStatus: e.target.value
    }
    const res = await HttpClient.requestData("update-event-eventDetails/" + detailData?._id, "PUT", data)
    if (res && res?.status) {
      closemodal();
      getEventData();
      toast.success("Status Updated Sucessfully");
    }
  }

  const getCustFinanceData = async () => {
    const data = {
      "customerName": detailData?.linkedUser
    }
    const res = await HttpClient.requestData("getCustomerReports", "POST", data);
    if (res && res?.status) {
      setFinanceData(res?.data);
    }
  }

  useEffect(() => {
    getCustFinanceData();
  }, [])

  return (
    <section className="taskPopSection">
      <div className="taskInPopDiv">
        <p className="taskModHead" style={{ textAlign: 'center' }}>
          Event
        </p>

        <div className="taskInScrollDiv">
          <div className="taskInScrollDiv">
            <div className="mb-2">
              {detailData?.eventName && <p className="dryClnDropText">{detailData?.eventName}</p>}
              {detailData?.selectDate && (
                <p className="taskDtDetail">{moment(detailData?.selectDate).format('DD-MM-YYYY')}</p>
              )}
              {detailData?.startTime && detailData?.endTime && (
                <p className="taskTimeDetail">
                  {moment(detailData?.startTime, ['HH:mm']).format('hh:mm a')} -{' '}
                  {moment(detailData?.endTime, ['HH:mm']).format('hh:mm a')}
                </p>
              )}
              {detailData?.eventStatus && <p className="taskNoteSmallHead">Status: {detailData?.eventStatus}</p>}
              <div className="taskNoteTextDiv">
                {detailData?.eventNotes && (
                  <>
                    <p className="taskNoteSmallHead">Event Notes : {detailData?.eventNotes}</p>
                  </>
                )}

                {detailData?.assignToUserName
                  ?
                  <p className="taskNoteSmallHead">Assign User: {detailData?.assignToUserName}</p>
                  :
                  <p className="taskNoteSmallHead">Assign User: {language.not_assigned}</p>
                }
              </div>
              {detailData?.firstName && <p className="taskNoteSmallHead">First Name: {detailData?.firstName}</p>}
              {detailData?.lastName && <p className="taskNoteSmallHead">Last Name: {detailData?.lastName}</p>}
              {detailData?.mtgLinkAddress && <p className="taskNoteSmallHead">Address: {detailData?.mtgLinkAddress}</p>}
            </div>


            {/* finance  */}
            {detailData?.linkedUser &&
              <div>
                <div className="pyramidTextDiv">
                  <p className="custEvntName">
                    <span>{language.customer_name}: </span>{detailData?.linkedUser}
                  </p>
                </div>
                <div className="pyramidTextDiv">
                  <p className="custEvntName">
                    <span>YTD Revenue :</span> ${financeData?.yTDRevenue}
                  </p>
                  <RedGreenIndicator color={financeData?.revenueIndicator} />
                </div>
                <div className="pyramidTextDiv">
                  <p className="custEvntName">
                    <span>YTD Profit :</span> ${financeData?.yTDProfit}
                  </p>
                  <RedGreenIndicator color={financeData?.profitIndicator} />
                </div>
                <div className="pyramidTextDiv">
                  <p className="custEvntName">
                    <span>Past Due AR :</span> ${financeData?.pastDueAR}
                  </p>
                  <RedGreenIndicator color={financeData?.arIndicator} />
                </div>
              </div>
            }

          </div>
        </div>
        <div className="tskSaveAsBtnDiv">
          <button className="tskSaveAsBtn" onClick={() => closemodal()}>
            Save as Done
          </button>
        </div>
        <button className="closeTaskModBtn" onClick={() => closemodal()}>
          <i class="fa-solid fa-x"></i>
        </button>
      </div>
    </section >
  );
}

export default EventPopUp;


const RedGreenIndicator = ({ color }) => {
  return (
    color === "green"
      ?
      <div className="pyramidDiv" style={{ backgroundColor: "green" }} />
      :
      <div className="pyramidDiv" style={{ transform: 'rotate(180deg)' }} />
  )
}

