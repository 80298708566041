import React from 'react';
import { FaDollarSign, FaFileInvoice, FaUserCog } from 'react-icons/fa';
import { FaYoutubeSquare } from 'react-icons/fa';
import { HiUsers } from 'react-icons/hi';
import revenewIncomeManager from '../../images/fisibility_icons/revenue_income_manager.png';
import expenseManager from '../../images/fisibility_icons/expense_manager.png';
// import Customer from '../../images/fisibility_icons/invoice_managerbu_vendor_managerpu.png';

import { useLanguage } from '../../Providers/LanguageContext';
import { Link, useNavigate } from 'react-router-dom';
// import expenseManager from '../../images/fisibility_icons/expense_manager.png';
import { useUserData } from '../../Providers/UserWrapper'
import customer from '../../images/Customer_vendor.png';
import resourse from '../../images/fisibility_icons/resource manager.png'


const Sidebar4 = () => {
    const { language } = useLanguage()
    const navigate = useNavigate()
    const { profileData, isMainUserMemo, isBusiness } = useUserData()
    console.log('isMainUserMemo', isMainUserMemo)

    const data = profileData && profileData.userType === "Business"
        ?
        [
            // {
            //     text: language.user_management,
            //     icon: <FaUserCog />,
            //     link: "/users"
            // },
            // {
            //     text: language.user_tutorial,
            //     icon: <FaYoutubeSquare />,
            //     link: "/tutorial"
            // },
            // {
            //     text: language.invite_friend,
            //     icon: <HiUsers />,
            //     link: "/invite"
            // },
            {
                text: language.transaction_details,
                icon: <img src={revenewIncomeManager} alt="" width={21} height={21} />,
                link: "/editexistingrevenue"
            },
            {
                text: language.expense_management,
                // icon: <img src={expenseManager} alt="" width={23} height={23} />,
                icon: <FaDollarSign />,
                link: "/viewexpensedetails"
            },

            {
                text: isBusiness ? language.client_vendor : language.income_vendor,
                icon: <img src={customer} alt="" style={{ height: '18px', width: "18px" }} />,
                link: "/edit-expense-details"
            },
            {
                text: language.Invoice_Summary,
                // icon: <img src={Customer} alt="" width={21} height={21} />,
                icon: <FaFileInvoice />,
                link: "/view-invoice"
            },
            // {
            //     text: language.resourse_management,
            //     icon: <img src={resourse} alt="" width={21} height={21} />,
            //     link: "/resource"
            // },
        ]
        :
        [
            // {
            //     text: language.user_management,
            //     icon: <FaUserCog />,
            //     link: "/users"
            // },
            // {
            //     text: language.user_tutorial,
            //     icon: <FaYoutubeSquare />,
            //     link: "/tutorial"
            // },
            // {
            //     text: language.invite_friend,
            //     icon: <HiUsers />,
            //     link: "/invite"
            // },
            {
                text: language.transaction_details,
                icon: <img src={revenewIncomeManager} alt="" width={21} height={21} />,
                link: "/editexistingrevenue"
            },
            {
                text: language.expense_management,
                // icon: <img src={expenseManager} alt="" width={23} height={23} />,
                icon: <FaDollarSign />,
                link: "/viewexpensedetails"
            },
            {
                text: isBusiness ? language.client_vendor : language.income_vendor,
                icon: <img src={customer} alt="" style={{ height: '18px', width: "18px" }} />,
                link: "/edit-expense-details"
            },
            // {
            //     text: language.resourse_management,
            //     icon: <img src={resourse} alt="" width={21} height={21} />,
            //     link: "/resource"
            // },
        ]


    // console.log(networkImg,'networkImg')
    return (
        <div className='container mt-4'>
            {
                isMainUserMemo &&
                <div className="row" onClick={() => {
                    // setShow(false)
                    navigate("/users")
                }}
                >
                    <div className="col-md-1 sidebar-icon">
                        {/* <img src="" alt="" /> */}
                        <span><FaUserCog /></span>
                    </div>
                    <div className="col-md-11" style={{ cursor: 'pointer' }}>
                        <span>{language.user_management}</span>
                    </div>
                    <hr />
                </div>
            }

            {
                data.map((item, i) =>

                    <div className="row" key={i} onClick={() => {
                        // setShow(false)
                        navigate(item.link)
                    }}
                    >
                        <div className="col-md-1 sidebar-icon">
                            {/* <img src="" alt="" /> */}
                            <span>{item.icon}</span>
                        </div>
                        <div className="col-md-11" style={{ cursor: 'pointer' }}>
                            <span> {item.text}</span>
                        </div>
                        <hr />
                    </div>
                )
            }



        </div>
    )
}

export default Sidebar4