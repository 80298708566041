import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import HttpClient from '../../../utils/HttpClient';
import { useLanguage } from '../../../Providers/LanguageContext';
import CustomLoaderLine from '../../../Component/Loader/CustomLoaderLine';
import { useUserData } from '../../../Providers/UserWrapper';

const BuildAccountsPayableAging = ({ setCapitalCsv, setPdfCreate, setPdfLoader, setEmailSendFunc }) => {
    const { language, currentLanguage } = useLanguage();
    const { profileData, setIsLoading } = useUserData();
    const [isSave, setIsSave] = useState(true);
    const [loading, setLoading] = useState(false);
    const [saveLoading, setSaveLoading] = useState(false);

    const initData = {
        title: "",
        totalPastDue: "",
        current: "",
        days1to15: "",
        days16to30: "",
        days31to45: "",
        days46to60: "",
        days61to90: "",
        over90: ""
    }

    const [ownerData, setOwnerData] = useState([initData]);
    console.log("dr4fg6531", ownerData)
    const [isDelOwner, setIsDelOwner] = useState(false);
    const [totals, setTotals] = useState({
        totalPastDue: 0,
        current: 0,
        days1to15: 0,
        days16to30: 0,
        days31to45: 0,
        days46to60: 0,
        days61to90: 0,
        over90: 0
    });
    console.log("dr541f35", totals)


    // >>>>onChange ownwr / admore<<<<
    const handleChangeOwner = (e, ind) => {
        const { name, value } = e.target;
        const data = ownerData?.map((item, i) => {

            if (i === ind) {
                return { ...item, [name]: value }
            } else {
                return item
            }
        })
        setOwnerData(data);
    }

    // >>>>GET Aging Report data<<<<
    const getAgingReportData = async () => {
        const res = await HttpClient.requestData("reportbuilder-view-agingreport", "GET");
        if (res && res?.status) {
            console.log("s65r4tg65s", res, res?.data?.[0])
            if (res.data && res.data.length > 0 && res.data[0].vendorName && res.data[0].vendorName.length > 0) {
                setOwnerData(res.data[0].vendorName.map(item => ({
                    title: item.title,
                    totalPastDue: item.totalPastDue,
                    current: item.current,
                    days1to15: item.days1to15,
                    days16to30: item.days16to30,
                    days31to45: item.days31to45,
                    days46to60: item.days46to60,
                    days61to90: item.days61to90,
                    over90: item.over90
                })));
            } else {
                setOwnerData([initData]);
            }
        }

    }

    // >>>>Handle Save<<<<
    const handleSave = async () => {
        for (let i = 0; i < ownerData.length; i++) {
            if (!ownerData[i].title) {
                toast.error("Vendor name cannot be empty...!");
                return;
            }
        }

        const transformData = ownerData?.map(item => ({
            title: item?.title,
            totalPastDue: parseFloat(item.totalPastDue) || 0,
            current: parseFloat(item.current) || 0,
            days1to15: parseFloat(item.days1to15) || 0,
            days16to30: parseFloat(item.days16to30) || 0,
            days31to45: parseFloat(item.days31to45) || 0,
            days46to60: parseFloat(item.days46to60) || 0,
            days61to90: parseFloat(item.days61to90) || 0,
            over90: parseFloat(item.over90) || 0,
        }))

        if (isSave) {
            const data = {
                reports: transformData
            }
            console.log("df623454gh65d", data)
            // return
            setSaveLoading(true);
            const res = await HttpClient.requestData('reportbuilder-add-aging-statement', 'POST', data);
            if (res && res?.status) {
                toast.success(language.aging_report)
                setSaveLoading(false);
                setIsSave(!isSave);
                getAgingReportData()
            } else {
                toast.error(res?.message || "Something went wrong...!")
                setSaveLoading(false);
            }
        } else {
            setIsSave(!isSave);
        }
    }

    // >>>>Handle Build Report<<<<
    const handleBuildRepport = async () => {
        for (let i = 0; i < ownerData.length; i++) {
            if (!ownerData[i].title) {
                toast.error("Vendor name cannot be empty...!");
                return;
            }
        }

        if (isSave) {
            toast.error("Please Save First");
            return
        }

        const transformData = ownerData?.map(item => ({
            title: item?.title,
            totalPastDue: parseFloat(item.totalPastDue) || 0,
            current: parseFloat(item.current) || 0,
            days1to15: parseFloat(item.days1to15) || 0,
            days16to30: parseFloat(item.days16to30) || 0,
            days31to45: parseFloat(item.days31to45) || 0,
            days46to60: parseFloat(item.days46to60) || 0,
            days61to90: parseFloat(item.days61to90) || 0,
            over90: parseFloat(item.over90) || 0,
        }))

        // if (isSave) {
        const data = {
            reports: transformData
        }
        console.log("df623454gh65d", data)
        // return
        setLoading(true);
        const res = await HttpClient.requestData('reportbuilder-add-aging-statement', 'POST', data);
        if (res && res?.status) {
            toast.success(language.report_build_succ);
            setLoading(false);
            getAgingReportData()
        } else {
            toast.error(res?.message || "Something went wrong...!")
            setSaveLoading(false);
        }
        // } else {
        //     setIsSave(!isSave);
        // }
    }

    // >>>>Download PDF<<<<
    const handlePdfDownload = async () => {
        // if (isSave) {
        //     toast.error("Please Save First");
        //     return
        // }

        setPdfLoader(true);
        setIsLoading(true);
        const res = await HttpClient.requestData("reportbuilder/download/pdf/aging-statement", "GET", {})
        setPdfLoader(false);
        setIsLoading(false);
        if (res && res?.status) {
            window.open(res?.data, "_blank")
        } else {
            toast.error(res?.message || "Something Wrong!")
        }
    }

    // >>>>Sending mail<<<<
    const sendCapatialMail = async (emailData, oncloseSendModal) => {
        const transformData = ownerData?.map(item => ({
            title: item?.title,
            totalPastDue: parseFloat(item.totalPastDue) || 0,
            current: parseFloat(item.current) || 0,
            days1to15: parseFloat(item.days1to15) || 0,
            days16to30: parseFloat(item.days16to30) || 0,
            days31to45: parseFloat(item.days31to45) || 0,
            days46to60: parseFloat(item.days46to60) || 0,
            days61to90: parseFloat(item.days61to90) || 0,
            over90: parseFloat(item.over90) || 0,
        }))
        if (isSave) {
            return toast.error("Please Save First");
        } else {
            const data = {
                email: emailData?.email,
                language: currentLanguage === "eng" ? "EN" : "ES",
                reports: transformData
            }
            // console.log("4d5fgh51", data)
            // return
            oncloseSendModal();
            setIsLoading(true);
            const res = await HttpClient.requestData("reportbuilder/pdf/agingStatementPDF", "POST", data)
            if (res && res?.status) {
                toast?.success(language.mail_sent_success);
                setIsLoading(false);
            } else {
                setIsLoading(false);
            }
        }
    }

    // >>>>Calculate totals<<<<
    useEffect(() => {
        const calculateTotals = () => {
            const newTotals = ownerData.reduce((acc, item) => {
                acc.totalPastDue += parseFloat(item.totalPastDue) || 0;
                acc.current += parseFloat(item.current) || 0;
                acc.days1to15 += parseFloat(item.days1to15) || 0;
                acc.days16to30 += parseFloat(item.days16to30) || 0;
                acc.days31to45 += parseFloat(item.days31to45) || 0;
                acc.days46to60 += parseFloat(item.days46to60) || 0;
                acc.days61to90 += parseFloat(item.days61to90) || 0;
                acc.over90 += parseFloat(item.over90) || 0;
                return acc;
            }, {
                totalPastDue: 0,
                current: 0,
                days1to15: 0,
                days16to30: 0,
                days31to45: 0,
                days46to60: 0,
                days61to90: 0,
                over90: 0
            });

            setTotals(newTotals);
        };

        calculateTotals();
    }, [ownerData]);

    // >>>>Sending Male<<<< 
    useEffect(() => {
        setEmailSendFunc(() => sendCapatialMail)

    }, [isSave])

    useEffect(() => {
        getAgingReportData()
        setPdfCreate(() => handlePdfDownload);
    }, [])

    return (
        <div className='container'>
            <div className="buildIncmStTableDiv">

                <div className='CurrentPeriod_drowp'>
                    <div className='linewrap'>
                        <span>{language.current_period} :</span>
                        <select className="typesBuildSelect">
                            <option selected>-select- week-</option>
                            <option>1 week</option>
                            <option>2 week</option>
                            <option>3 week</option>
                        </select>
                    </div>
                    <div className='linewrap'>
                        <span>{language.total_open_amount} :</span>
                        <input type="number" placeholder='$00' />
                    </div>

                </div>

                <table className="buildIncmStTable">
                    <tbody>

                        {/****table Head***/}
                        <tr>
                            <th className="buildIncmStTh12 buildIncmStTh12First">
                                {isSave && <button
                                    className="buildAddBtn"
                                    onClick={() => {
                                        setOwnerData(prev => [...prev, initData]);
                                    }}
                                >
                                    <i className="fa-solid fa-plus" />
                                </button>}
                                <span>{language.vendor_name}</span>
                                {isSave && <button className="delBuildRowBtn"
                                    onClick={() => setIsDelOwner(prev => !prev)}
                                >{isDelOwner ? language.remove_delete : language.delete_row}</button>}
                            </th>
                            <th className="buildIncmStTh12 pr-2">
                                <span>{language.total_past_due}</span>
                            </th>
                            <th className="buildIncmStTh12 pr-2">
                                <span>{language.current}</span>
                            </th>
                            <th className="buildIncmStTh12 pr-2">
                                <span>1-15</span>
                            </th>
                            <th className="buildIncmStTh12 pr-2">
                                <span>16-30</span>
                            </th>
                            <th className="buildIncmStTh12 pr-2">
                                <span>31-45</span>
                            </th>
                            <th className="buildIncmStTh12 pr-2">
                                <span>46-60</span>
                            </th>
                            <th className="buildIncmStTh12 pr-2">
                                <span>61-90</span>
                            </th>
                            <th className="buildIncmStTh12">
                                <span>{language.over} 90</span>
                            </th>
                        </tr>

                        {/****Data**/}
                        {ownerData?.map((item, i) => {
                            return (
                                <tr>
                                    <td className="buildIncmStTd">
                                        {(isDelOwner && isSave) && <button
                                            className="buildCapiRemoveBtn"
                                            onClick={() => {
                                                setOwnerData(prev => prev.filter((ele, ind) => ind !== i))
                                            }}
                                        >
                                            <i class="fa-solid fa-minus"></i>
                                        </button>}
                                        <input type="text" className="userBuildInp"
                                            name="title"
                                            value={item?.title}
                                            onChange={(e) => handleChangeOwner(e, i)}
                                        />
                                    </td>
                                    <td className="buildIncmStTd pr-2">
                                        <input type="number"
                                            name="totalPastDue"
                                            value={item?.totalPastDue}
                                            onChange={(e) => handleChangeOwner(e, i)}
                                            defaultValue=""
                                            placeholder='$00' />
                                    </td>
                                    <td className="buildIncmStTd pr-2">
                                        <input type="number"
                                            name="current"
                                            value={item?.current}
                                            onChange={(e) => handleChangeOwner(e, i)}
                                            defaultValue=""
                                            placeholder='$00'
                                        />
                                    </td>
                                    <td className="buildIncmStTd pr-2">
                                        <input type="number"
                                            name="days1to15"
                                            value={item?.days1to15}
                                            onChange={(e) => handleChangeOwner(e, i)}
                                            defaultValue=""
                                            placeholder='$00'
                                        />
                                    </td>
                                    <td className="buildIncmStTd pr-2">
                                        <input type="number"
                                            name="days16to30"
                                            value={item?.days16to30}
                                            onChange={(e) => handleChangeOwner(e, i)}
                                            defaultValue=""
                                            placeholder='$00'
                                        />
                                    </td>
                                    <td className="buildIncmStTd pr-2">
                                        <input type="number"
                                            name="days31to45"
                                            value={item?.days31to45}
                                            onChange={(e) => handleChangeOwner(e, i)}
                                            defaultValue=""
                                            placeholder='$00'
                                        />
                                    </td>
                                    <td className="buildIncmStTd pr-2">
                                        <input type="number"
                                            name="days46to60"
                                            value={item?.days46to60}
                                            onChange={(e) => handleChangeOwner(e, i)}
                                            defaultValue=""
                                            placeholder='$00'
                                        />
                                    </td>
                                    <td className="buildIncmStTd pr-2">
                                        <input type="number"
                                            name="days61to90"
                                            value={item?.days61to90}
                                            onChange={(e) => handleChangeOwner(e, i)}
                                            defaultValue=""
                                            placeholder='$00'
                                        />
                                    </td>
                                    <td className="buildIncmStTd">
                                        <input type="number"
                                            name="over90"
                                            value={item?.over90}
                                            onChange={(e) => handleChangeOwner(e, i)}
                                            defaultValue=""
                                            placeholder='$00'
                                        />
                                    </td>
                                </tr>
                            )
                        })
                        }

                        {/*****border**/}
                        <tr>
                            <td />
                            <td colSpan={8}>
                                <div className="buildTotDivider" />
                            </td>
                        </tr>

                        {/***Sub Total**/}
                        <tr>
                            <td className="buildIncmStTd">
                                <span className="buildTotSpan">{language.total}</span>
                            </td>

                            {/* Total past Due */}
                            <td className="buildIncmStTd pr-2">
                                <input type="number" readOnly value={totals?.totalPastDue} placeholder='$0.00' />
                            </td>

                            {/* Current */}
                            <td className="buildIncmStTd pr-2">
                                <input type="number" readOnly value={totals?.current} placeholder='$0.00' />
                            </td>

                            {/* 1-15 */}
                            <td className="buildIncmStTd pr-2">
                                <input type="number" readOnly value={totals?.days1to15} placeholder='$0.00' />
                            </td>

                            {/* 16-30 */}
                            <td className="buildIncmStTd pr-2">
                                <input type="number" readOnly value={totals?.days16to30} placeholder='$0.00' />
                            </td>

                            {/* 31-45 */}
                            <td className="buildIncmStTd pr-2">
                                <input type="number" readOnly value={totals?.days31to45} placeholder='$0.00' />
                            </td>

                            {/* 46-60 */}
                            <td className="buildIncmStTd pr-2">
                                <input type="number" readOnly value={totals?.days46to60} placeholder='$0.00' />
                            </td>

                            {/* 61-90 */}
                            <td className="buildIncmStTd pr-2">
                                <input type="number" readOnly value={totals?.days61to90} placeholder='$0.00' />
                            </td>

                            {/* Over-90 */}
                            <td className="buildIncmStTd">
                                <input type="number" readOnly value={totals?.over90} placeholder='$0.00' />
                            </td>

                        </tr>

                    </tbody>
                </table>
                <div className="d-flex justify-content-center mt-3">
                    <button className="btn-report-save"
                        onClick={handleSave}
                    >{
                            isSave ?
                                saveLoading ? <CustomLoaderLine height="10" width="40" /> : language.save?.toUpperCase()
                                :
                                language.edit?.toUpperCase()
                        }</button>

                    <button className="btn-report-build"
                        onClick={() => handleBuildRepport()}

                    >
                        {loading ? <CustomLoaderLine height="10" width="100" /> : language.build_report?.toUpperCase()}
                    </button>
                </div>
            </div>
        </div>
    )
}

export default BuildAccountsPayableAging