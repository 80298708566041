import React from 'react'

function CustomInputField({ type, placeholder = "", name = "", getValue, value }) {
  return (
    <input
      type={type}
      placeholder={placeholder}
      name={name}
      getValue={getValue}
      onChange={(e) => {
        if (type === 'number') {
          getValue(e.target.value, name);
        }
        else if (type === 'text') {
          if (/^[A-Za-z\s]*$/.test(e.target.value)) {
            getValue(e.target.value, name);
          }
        }
      }}
      value={value}
    />
  )
}

export default CustomInputField