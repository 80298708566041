import React from "react";
import "./TaskManager.css";
import GoogleCalendar from "../../images/GoogleCalendar.png";
import OutlookCalendar from "../../images/OutlookCalendar.png";
import AppleCalendar from "../../images/AppleCalendar.png";
import googleIcon from "../../images/googleIcon.png";
import appleIcon from "../../images/appleIcon.png";
import HttpClient from "../../utils/HttpClient";
import { useUserData } from "../../Providers/UserWrapper";
import { useNavigate } from "react-router-dom";
import { useLanguage } from "../../Providers/LanguageContext";

function ConnectCalender() {
  const { setIsLoading } = useUserData()
  const { language } = useLanguage();
  const navigate = useNavigate()
  const handleConnectGoogle = async () => {
    setIsLoading(true);
    const res = await HttpClient.requestData("google-auth", "GET");
    if (res && res?.status) {
      window.open(res?.authUrl, "_blank");
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  }

  const handleConnectOutlook = async () => {
    setIsLoading(true);
    const res = await HttpClient.requestData("outlook-auth", "GET");
    if (res && res?.status) {
      window.open(res?.authUrl, "_blank");
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  }

  return (
    <section className="conntCalenderSec">
      <div className="container">
        <div className=''>
          <button className='w-25 mb-2 btn btn-outline-success' style={{ fontWeight: "bold" }} onClick={() => navigate('/eventsCalender')}>
            {language.task_event_manage}
          </button>
        </div>
        <div className="row mb-3">
          <div className="col">
            <div className="takEvntDiv">
              <span className="takEvntText">
                Task & Events Calendar Connect
              </span>
            </div>
          </div>
        </div>

        <div className="row">

          <div className="col-md-4">
            <div className="allTaskMainDiv">
              <figure className="taskFigure">
                <img src={GoogleCalendar} alt="..." />
              </figure>
              <div>
                <div className="taskInpsDiv">
                  <input
                    type="text"
                    placeholder="username"
                    className="taskInps"
                  />
                </div>
                <div>
                  <input
                    type="password"
                    placeholder="password"
                    className="taskInps"
                  />
                </div>
              </div>
              <div className="taskCnctBtnDiv">
                <button className="taskCnctBtn">CONNECT</button>
              </div>
              <div className="allTaskIconsTextDiv">
                <figure className="allTaskIconsFig">
                  <img src={GoogleCalendar} alt="..." />
                </figure>
                <p className="taskContText" onClick={() => handleConnectGoogle()}>Continue with Google</p>
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div className="allTaskMainDiv">
              <figure className="taskFigure">
                <img src={AppleCalendar} alt="..." />
              </figure>
              <div>
                <div className="taskInpsDiv">
                  <input
                    type="text"
                    placeholder="username"
                    className="taskInps"
                  />
                </div>
                <div>
                  <input
                    type="password"
                    placeholder="password"
                    className="taskInps"
                  />
                </div>
              </div>
              <div className="taskCnctBtnDiv">
                <button className="taskCnctBtn">CONNECT</button>
              </div>
              <div className="allTaskIconsTextDiv">
                <figure className="allTaskIconsFig">
                  <img src={appleIcon} alt="..." />
                </figure>
                <p className="taskContText">Continue with Apple</p>
              </div>
            </div>
          </div>

          {/* outlook */}
          <div className="col-md-4">
            <div className="allTaskMainDiv">
              <figure className="taskFigure">
                <img src={OutlookCalendar} alt="..." />
              </figure>
              <div>
                <div className="taskInpsDiv">
                  <input
                    type="text"
                    placeholder="username"
                    className="taskInps"
                  />
                </div>
                <div>
                  <input
                    type="password"
                    placeholder="password"
                    className="taskInps"
                  />
                </div>
              </div>
              <div className="taskCnctBtnDiv">
                <button className="taskCnctBtn" onClick={handleConnectOutlook}>CONNECT</button>
              </div>
              {/* <div className="allTaskIconsTextDiv">
                <figure className="allTaskIconsFig">
                  <img src={appleIcon} alt="..." />
                </figure>
                <p className="taskContText">Continue with Apple</p>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ConnectCalender;
