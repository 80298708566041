import React from 'react'
import iMg1 from "../../images/ilustraciones soluciones industriales_GOBIERNO 1.png";
import iMg2 from "../../images/ilustraciones soluciones industriales_GOBIERNO 2.png";
import iMg3 from "../../images/gobierno_GOBIERNO 3.png";
import { useLanguage } from '../../Providers/LanguageContext';
import SignUpButton from './SignUpButton';
const Government = () => {
  const { language } = useLanguage();

  return (
    <>
      <section id='agriculture_sec'>
        <div className='container'>
          <div className='row mb-5'>
            <div className='col-md-6 col-12 m-auto'>
              <div className='agriculture_head'>
                <h3 style={{ color: "#000000" }}>{language.government}</h3>
              </div>

              <div className='agriculture_headtext'>
                <h4 style={{ color: "#4d4d4d" }}>{language.budget_and_planning}</h4>
                <p>{language.fisi_help_govt_agency}
                </p>
              </div>
            </div>
            <div className='col-md-6 col-12 m-auto'>
              <div className='agriculture_img'>
                <img src={iMg1} className='img-fluid' alt='' />
              </div>
            </div>
          </div>

          <div className='row row_reverse mb-5'>

            <div className='col-md-12 col-12 m-auto'>
              <div className='agriculture_headtext'>
                <h4 style={{ color: "#000000", textAlign: "left" }}>{language.expense_tracking_reporting}</h4>
                <p>{language.fisi_provide_detail_analytics}</p>
                <p>{language.can_help_Identify}
                </p>
              </div>
            </div>
          </div>

          <div className='row mb-5'>
            <div className='col-md-6 col-12 m-auto'>
              <div className='agriculture_img'>
                <img src={iMg2} className='img-fluid' alt='' />
              </div>
            </div>
            <div className='col-md-6 col-12'>
              <div className='agriculture_headtext'>
                <h4 style={{ color: "#333333", textAlign: "right" }}>{language.compliance_and_regulation}</h4>
                <p>{language.govt_agencies_dept}
                </p>
              </div>
            </div>
          </div>

          <div className='row wrapBg_Img'>


            <div className='col-md-12 col-12 m-auto'>
              <div className='agriculture_headtext'>
                <h4 style={{ color: "#000000", textAlign: "left" }}>{language.transe_and_account}</h4>
                <p>{language.fisi_help_increase_transparancy}
                </p>
                <p>{language.build_trust_citigen}
                </p>
              </div>
            </div>


            <div className='col-md-7 col-12 m-auto pt-5'>
              <div className='agriculture_headtext'>
                <h4 style={{ color: "#333333", textAlign: "left" }}>{language.grant_management}</h4>
                <p>{language.many_govt_agencies_dept}
                </p>
              </div>
            </div>

            <div className='col-md-5 col-12 m-auto mb-5'>
              <div className='agriculture_img' style={{ maxWidth: "60%", }}>
                <img src={iMg3} className='img-fluid' alt='' style={{ marginBottom: "30px" }} />

                {/* <div className='registrarAnddescargar' style={{ transform: "translate(-50% , -50%)", top: "100%", }}>
                  <button className='registrar_Btn' style={{ background: "#000" }}>Registrarse</button>
                  <button className='descargar_Btn'>Descargar</button>
                </div> */}
                <SignUpButton/>
              </div>
            </div>
            <div className='col-md-12 col-12 m-auto'>
              <div className='agriculture_headtext'>
                <h4 style={{ color: "#000000", textAlign: "right" }}>{language.auditing_supplier_invoices}</h4>
                <p>{language.fisi_help_save_money}
                </p>

              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  )
}

export default Government