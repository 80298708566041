import React from 'react'
import Modal from 'react-modal';

function CustomModal(props) {
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: "400px",
      maxHeight: "500px",
      backgroundColor: "white",
      padding: "2%",
    },
  };
  return (
    <div className='modal-div'>
      <Modal
        onAfterClose={() => {
          if (props.setModalOpen) {
            props.setModalOpen(false)
            props.setIsOpen(false);
            // props.setModalId("")
          }
        }}
        isOpen={props.modalIsOpen}
        onAfterOpen={props.afterOpenModal}
        onRequestClose={props.closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        {props.children}
      </Modal>
    </div>

  )
}

export default CustomModal