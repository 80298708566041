import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { toast } from 'react-hot-toast'
import { AiOutlineClose } from "react-icons/ai";
import { useLanguage } from '../../Providers/LanguageContext';
import CustomModal from '../CustomModal';
import ExpenseEditInput from './ExpenseEditInput';

function ClassificationExpenseEdit({ expenses, sales, fetchSales, setModalOpenSt, handleFilterClassChange, setFilters, fetchExpenses, searchBoxRef, setIsCleared }) {
    const { language } = useLanguage()
    // const { } = useContext(SpeechContext);
    // console.log('salesme', sales)
    // console.log('expenses', expenses.length)

    const [modalIsOpen, setIsOpen] = useState(false);

    const [modalOpen, setModalOpen] = useState(false);

    function openModal() {
        setIsOpen(true);
        // setExpenseEditModalClicked(true)
    }


    function closeModal() {
        setIsOpen(false);
        // setExpenseEditModalClicked(false)
    }

    const [modalId, setModalId] = useState({});


    const selectRef = useRef()

    const clearFilters = () => {
        setFilters({
            vendor: "",
            transDate: "",
            project: ""
        })
        searchBoxRef.current.value = ""
        selectRef.current.selectedIndex = "0"
        setIsCleared(true)
        fetchExpenses()
        toast.success(language.clear_filter)
    }

    useEffect(() => {
        // const fetchSingleExpenseData = async(id)=>{
        //    const res = await HttpClient.requestData("view-expense-details_single/"+id,"GET",{});
        //    if(res && res.status){
        //      setModalData(res.data[0])
        //      openModal()
        //    }else{
        //     toast.error(language.expense_single_error)
        //    }
        // }

        if (modalOpen) {
            setModalOpenSt(true)
        } else {
            setModalOpenSt(false)
        }
        if (modalId && modalOpen === true) {
            // fetchSingleExpenseData(modalId)
            openModal()
        }

    }, [modalId, modalOpen])






    return (

        <>

            <div id='clafication'>
                <div className='container'>
                    <div className='table-top'>
                        <div className='top-left'>
                            <form>
                                <div className='top-select'>
                                    <div class="form-group">
                                        <select
                                            class="form-control"
                                            id="exampleFormControlSelect1"
                                            ref={selectRef}
                                            onChange={handleFilterClassChange}
                                        >
                                            <option disabled>{language.classification}</option>
                                            <option value="Recent">{language.most_recent}</option>
                                            <option value="Oldest">{language.oldest}</option>
                                            <option value="A-Z">Name A-Z</option>
                                            <option value="Z-A">Name Z-A</option>
                                        </select>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div className='top-right'>
                            {/* <button className='customBtn3' onClick={clearFilters}>{language.clear_filters}</button> */}
                            <span onClick={clearFilters} className='todo-btn' id="filterbtn">{language.all_results}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div id='todo-table'>
                <div className='container'>
                    {
                        expenses.length === 0 && sales.length === 0 &&
                        <div className='d-flex justify-content-center m-3'>
                            {language.no_data}
                        </div>
                    }
                    <div className='table-responsive'>
                        <div>
                            <h3>{expenses.length !== 0 && language.vendor}</h3>
                        </div>

                        {/* Vendor */}
                        <table className="table">
                            {/* <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">First</th>
                                <th scope="col">Last</th>
                                <th scope="col">Handle</th>
                            </tr>
                        </thead> */}
                            <tbody>
                                {
                                    expenses && expenses.map((ex, i) => (
                                        <tr style={{ backgroundColor: "#ebeff3" }}>
                                            {/* <th scope="row">1</th> */}
                                            <td style={{ color: "#0e2537", fontWeight: "600", fontSize: "18px", paddingLeft: "20px", borderTop: "0" }}>
                                                {ex.vendorName}
                                            </td>
                                            <td style={{ display: "flex", justifyContent: "flex-end", alignItems: "flex-end", paddingRight: "30px" }}>
                                                <span className='mx-3'>
                                                    {
                                                        moment(ex.transactionDate).utc().format("LL")
                                                    }
                                                </span>
                                                <button style={{
                                                    width: "150px", height: "30px", borderRadius: "10px",
                                                    background: "linear-gradient(to right, #18465e 1%, #14384d 51%, #0f273a 100%)",
                                                    border: "none", color: "#fff", fontSize: "16px"
                                                }}
                                                    onClick={() => {
                                                        setModalId(ex);
                                                        setModalOpen(true);
                                                    }}
                                                >
                                                    {language.see}
                                                </button>
                                            </td>
                                            {/* <td>@mdo</td> */}
                                        </tr>
                                    ))
                                }

                            </tbody>
                        </table>

                        <div>
                            <h3>{sales.length !== 0 && language.customer}</h3>
                        </div>


                        {/* customer */}
                        <table className="table">
                            {/* <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">First</th>
                                <th scope="col">Last</th>
                                <th scope="col">Handle</th>
                            </tr>
                        </thead> */}
                            <tbody>
                                {
                                    sales && sales.map((ex, i) => (
                                        <tr style={{ backgroundColor: "#ebeff3" }}>
                                            {/* <th scope="row">1</th> */}
                                            <td style={{ color: "#0e2537", fontWeight: "600", fontSize: "18px", paddingLeft: "20px", borderTop: "0" }}>
                                                {ex.customerName}
                                            </td>
                                            <td style={{ display: "flex", justifyContent: "flex-end", alignItems: "flex-end", paddingRight: "30px" }}>
                                                <span className='mx-3'>
                                                    {
                                                        moment(ex.transactionDate).format("LL")
                                                    }
                                                </span>
                                                <button style={{
                                                    width: "150px", height: "30px", borderRadius: "10px",
                                                    background: "linear-gradient(to right, #18465e 1%, #14384d 51%, #0f273a 100%)",
                                                    border: "none", color: "#fff", fontSize: "16px"
                                                }}
                                                    onClick={() => {
                                                        setModalId(ex);
                                                        setModalOpen(true);
                                                    }}
                                                >
                                                    {language.see}
                                                </button>
                                            </td>
                                            {/* <td>@mdo</td> */}
                                        </tr>
                                    ))
                                }

                            </tbody>
                        </table>
                    </div>

                </div>
            </div>

            {/* custom modal */}
            <CustomModal
                setModalOpen={setModalOpen}
                setIsOpen={setIsOpen}
                openModal={openModal}
                closeModal={closeModal}
                width="auto"
                modalOpen={modalOpen}
                modalIsOpen={modalIsOpen}
            >
                <div>
                    <div className='d-flex justify-content-between text-secondary'>
                        <span>
                            {language.confirmation}
                        </span>
                        <span>
                            <AiOutlineClose className='icon' onClick={() => {
                                closeModal();
                                setModalOpen(false)
                            }} size={20} color="gray" />
                        </span>
                    </div>
                    <div style={{ width: "100%" }}>
                        <ExpenseEditInput
                            id={modalId._id}
                            data={modalId}
                            // expenseData={transactions.expenseData}
                            type="number"
                            field="expense"
                            successmsg={language.expense_updated}
                            errormsg={language.expense_updated_error}
                        />
                    </div>
                </div>
            </CustomModal>
        </>

    )
}

export default ClassificationExpenseEdit