import React from 'react'
import iMg1 from "../../images/ilustraciones soluciones industriales_FABRICACION 1.png";
import iMg2 from "../../images/ilustraciones soluciones industriales_FABRICACION  2.png";
import iMg3 from "../../images/ilustraciones soluciones industriales_FABRICACION  3 EN.png";
import iMg3es from "../../images/ilustraciones soluciones industriales_FABRICACION  3.png";

import { useLanguage } from '../../Providers/LanguageContext';
import SignUpButton from './SignUpButton';
const Manufacturing = () => {
  const { language, currentLanguage } = useLanguage();

  return (
    <>


      <section id='agriculture_sec'>
        <div className='container'>
          <div className='row mb-5'>
            <div className='col-md-6 col-12 m-auto'>
              <div className='agriculture_head'>
                <h3 style={{ color: "#d3671e" }}>{language.manufacturing}</h3>
              </div>

              <div className='agriculture_headtext'>
                <h4 style={{ color: "#0d2536" }}>{language.inventory_management}</h4>
                <p>{language.app_allow_inventory}</p>
              </div>
            </div>
            <div className='col-md-6 col-12 m-auto'>
              <div className='agriculture_img'>
                <img src={iMg1} className='img-fluid' alt='' />
              </div>
            </div>
          </div>

          <div className='row row_reverse mb-5'>
            <div className='col-md-6 col-12 m-auto'>
              <div className='agriculture_img'>
                <img src={iMg2} className='img-fluid' alt='' />
              </div>
            </div>
            <div className='col-md-6 col-12 m-auto'>
              <div className='agriculture_headtext'>
                <h4 style={{ color: "#d3671e", textAlign: "right" }}>{language.cost_tracking}</h4>
                <p>{language.fisi_enables_cost}</p>
                <p>{language.help_menu_identify}
                </p>
              </div>
            </div>
          </div>

          <div className='row mb-5'>
            <div className='col-md-12 col-12'>
              <div className='agriculture_headtext'>
                <h4 style={{ color: "#0d2536", textAlign: "right" }}>{language.order_management}</h4>
                <p>{language.provide_manu_streamline}</p>
              </div>
            </div>
          </div>

          <div className='row wrapBg_Img'>


            <div className='col-md-12 col-12 m-auto'>
              <div className='agriculture_headtext'>
                <h4 style={{ color: "#d3671e", textAlign: "left" }}>{language.finance_reporting}</h4>
                <p>{language.fisi_manu_comprehensive}
                </p>
              </div>
            </div>


            <div className='col-md-6 col-12 m-auto'>
              <div className='agriculture_headtext'>
                <h4 style={{ color: "#0d2536", textAlign: "left" }}>{language.production_planning}</h4>
                <p>{language.allow_create_detail_production}
                </p>
              </div>
            </div>

            <div className='col-md-6 col-12 m-auto'>
              <div className='agriculture_img' style={{ maxWidth: "60%", }}>
                {
                  currentLanguage === 'spanish'
                    ?
                    <img src={iMg3es} className='img-fluid' alt='' style={{ marginBottom: "30px" }} />
                    :
                    <img src={iMg3} className='img-fluid' alt='' style={{ marginBottom: "30px" }} />

                }
                {/* <div className='registrarAnddescargar' style={{ transform: "translate(-50% , -50%)", top: "100%", }}>
                  <button className='registrar_Btn'>Registrarse</button>
                  <button className='descargar_Btn'>Descargar</button>
                </div> */}
                <SignUpButton />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Manufacturing