import React from 'react'
import { AiOutlineArrowLeft } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom'

const BackArrow = () => {
    const navigate = useNavigate();
    return (
        <div className='container'>
            <div
                className="back-arrow-left"
                onClick={() => {
                    navigate(-1)
                }
                }
            >
                <AiOutlineArrowLeft />
            </div>
        </div>
    )
}

export default BackArrow
