import React from "react";
import a from "../Images/4-Problem-With-Other-Solutions.png";
export default function Solution({ goToRegistration }) {
  return (
    <div className="inner">
      <div className="SpreadSheet">
        <div className="lftImg">
          <img src={a} alt="" />
        </div>
        <div className="rgtCnt">
          <div className="bottom_cnt">
            <div className="wrapCnt">
              <h4>
                Problem With Other Solutions On The Market Is They Are Expensive
                & Often Difficult to Use!
              </h4>
              <ul>
                <li>
                  You can’t easily obtain financial insights about staff,
                  customers, vendors, or projects.
                </li>
                <li>
                  You can’t effortlessly connect all calendar activities with
                  financial performance & receive profitability insights.
                </li>
              </ul>
              <a onClick={() => goToRegistration()} className="read-more">
                Try FISIBILITY Instead
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
