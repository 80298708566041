import React, { useEffect, useState } from 'react'
import { useLanguage } from '../../../../Providers/LanguageContext'
import HttpClient from '../../../../utils/HttpClient';
import { useNavigate } from 'react-router-dom';
import { useUserData } from '../../../../Providers/UserWrapper';

const SatNormalInvList = ({ filters, satNormalInvoiceData }) => {
    const { language } = useLanguage();
    const navigete = useNavigate();

    console.log("satNormalInvoiceDataghty", satNormalInvoiceData)

    // useEffect(() => {
    //     getAllNormalInvData();
    // }, [filters])

    return (
        <div>
            {satNormalInvoiceData.length !== 0 &&
                <div className='mt-3'>
                    <h3>Sat Normal Invoice</h3>
                </div>
            }

            <div id='todo-table'>
                <div className='container'>
                    <div className='table-responsive'>
                        <table className="table">
                            <tbody>
                                {satNormalInvoiceData && satNormalInvoiceData?.map((item, i) => {
                                    return (
                                        <tr style={{ backgroundColor: "#ebeff3" }} key={i}>
                                            <td style={{
                                                color: "#0e2537",
                                                fontWeight: "600",
                                                fontSize: "18px",
                                                paddingLeft: "20px",
                                                borderTop: "0"
                                            }}>
                                                {item?.RecipientName}
                                            </td>
                                            <td style={{
                                                display: "flex",
                                                justifyContent: "flex-end",
                                                alignItems: "flex-end",
                                                paddingRight: "30px"
                                            }}>
                                                <button
                                                    style={{
                                                        maxWidth: "110px",
                                                        width: "100%",
                                                        height: "30px",
                                                        borderRadius: "10px",
                                                        background: "linear-gradient(to right, #18465e 1%, #14384d 51%, #0f273a 100%)",
                                                        border: "none", color: "#fff", fontSize: "16px"
                                                    }}
                                                    onClick={() => navigete(`/view-SatNormal-invoice/${item?._id}`)}
                                                >
                                                    {language.view}
                                                </button>
                                            </td>
                                        </tr>
                                    )
                                })

                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default SatNormalInvList
