import React, { useContext, useEffect, useRef, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useRequestData } from '../../../hooks/RequestDataHook';
import { useLanguage } from '../../../Providers/LanguageContext';
import { SpeechContext } from '../../../Providers/SpeechWrapper';
import HttpClient from '../../../utils/HttpClient';
import budget_en_1 from "../../../Audio_files/VI. Project Manager Audio/VI. a. Setup Project & Project Budget/vi_setup_project_and_project_budget_audio_a.mp3";
import budget_en_2 from "../../../Audio_files/VI. Project Manager Audio/VI. a. Setup Project & Project Budget/vi_setup_project_and_project_budget_audio_c.mp3";
import budget_es_1 from "../../../spanish_audio_files/VI. Project Manager Audio/VI. a. Setup Project & Project Budget/vi_setup_project_and_project_budget_audio_a_es.mp3";
import budget_es_2 from "../../../spanish_audio_files/VI. Project Manager Audio/VI. a. Setup Project & Project Budget/vi_setup_project_and_project_budget_audio_c_es.mp3";
import budget_en_3 from "../../../extras/vi_setup_project_and_project_budget_audio_b.mp3";
import budget_es_3 from "../../../extras/vi_setup_project_and_project_budget_audio_b_es.mp3";
import CustomModal from '../../../Component/CustomModal';
import { AiOutlineClose } from 'react-icons/ai';

function Tracker({ time, setTime }) {
    const { language, currentLanguage } = useLanguage()
    const { currAudio, setCurrAudio, playing, setGifPoints } = useContext(SpeechContext);
    const { data, loading, error } = useRequestData("projectname", "GET", {});
    const [isCustonerName, setIsCustomerName] = useState(false);
    const [searchData, setSearchData] = useState([]);
    const [isSave, setIsSave] = useState(false);

    const timerRef = useRef()
    console.log("projectData", data)
    const selectRef = useRef();
    // const projectselectRef = useRef();
    const [values, setValues] = useState({
        budgetAmount: "",
        budgetItem: "",
        budgetPeriod: "",
        projectName: "",
        startProjectDate: "",
        endProjectDate: ""
    })

    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isSaveVendor, setIsSaveVendor] = useState(false);


    const handleBudgetItem = (e) => {
        if (e.target.value !== language.budget_itemm) {
            setValues(prev => ({ ...prev, budgetItem: e.target.value }))
        }
    }

    const removeDuplicates = (arr) => {
        return arr.filter((item, index) => arr.indexOf(item) === index);
    }

    const fetchSerchName = async (name) => {
        console.log('name', name)
        const res = await HttpClient.requestData("projectname-search/" + name, "GET");
        console.log('searchName', res)
        let arr = [];
        if (res && res.status) {
            res.data.forEach(element => {
                arr.push(element.projectName)
            });
            // console.log('element',arr)
            setSearchData(removeDuplicates(arr))
        } else {
            setSearchData([])
        }
    }


    useEffect(() => {
        setValues(prev => ({ ...prev, budgetPeriod: time }))
    }, [time])

    const handleBudgetPost = async () => {
        if (!values.budgetAmount || !values.budgetItem || !values.budgetPeriod
            || !values.projectName || !values.startProjectDate || !values.endProjectDate
        ) {
            toast.error(language.please_enter);
            return
        }
        console.log('vallu', values)
        const sendData = {
            budgetAmount: values.budgetAmount,
            budgetItem: values.budgetItem.toLowerCase(),
            budgetPeriod: values.budgetPeriod,
            projectName: values.projectName,
            startProjectDate: values.startProjectDate,
            endProjectDate: values.endProjectDate
        }
        // {
        //     "budgetAmount": "30",
        //     "budgetItem": "sales",
        //     "budgetPeriod": "",
        //     "projectName": "lkuy",
        //     "startProjectDate": "2023-01-12",
        //     "endProjectDate": "2023-04-25"
        // }
        const res = await HttpClient.requestData("add-budget-project", "POST", sendData);
        console.log('projectRes', res)
        if (res && res.status) {
            toast.success(language.budget_added)
            setTime("")
            setValues({
                budgetAmount: "",
                budgetItem: "",
                budgetPeriod: "",
                projectName: "",
                startProjectDate: "",
                endProjectDate: ""
            })
            selectRef.current.selectedIndex = "0"
            setGifPoints(50)
            setIsSubmitted(true)
            // projectselectRef.current.selectedIndex = "0"
        } else {
            toast.error(language.budget_error)
        }
    }

    useEffect(() => {
        console.log(currAudio, setCurrAudio, "funcsssss")
        if (playing) {
            if (currAudio) {
                if (!currAudio.paused) {
                    currAudio.pause()
                }
            }
            if (currentLanguage === "eng") {
                setCurrAudio(new Audio(budget_en_1))
            } else {
                setCurrAudio(new Audio(budget_es_1))
            }
        } else {
            if (currAudio) {
                if (!currAudio.paused) {
                    currAudio.pause()
                    setCurrAudio(null)
                }
            }
        }

        return () => {
            if (currAudio && !currAudio.paused) {
                currAudio.pause()
            }
        }
    }, [playing, currentLanguage])

    // useEffect(() => {
    //     console.log("currAudio", currAudio)
    //     if (currAudio) {
    //         if (!currAudio.paused) {
    //             currAudio.pause()
    //         }
    //         currAudio.play()
    //     }
    //     return () => {
    //         if (currAudio && !currAudio.paused) {
    //             currAudio.pause()
    //         }
    //     }
    // }, [currAudio])

    useEffect(() => {
        // if (values.projectName !== "") {
        if (currentLanguage === "eng") {
            if (timerRef.current) {
                clearTimeout(timerRef.current)
            }
            timerRef.current = setTimeout(() => {
                setCurrAudio(new Audio(budget_en_3))
            }, 500);
        } else {
            if (timerRef.current) {
                clearTimeout(timerRef.current)
            }
            timerRef.current = setTimeout(() => {
                setCurrAudio(new Audio(budget_es_3))
            }, 500)
        }
        // } else {
        //     if (currAudio && !currAudio.paused) {
        //         currAudio.pause()
        //     }
        // }
        // return () => {
        //     if (currAudio && !currAudio.paused) {
        //         currAudio.pause()
        //     }
        // }
    }, [isSaveVendor]);


    useEffect(() => {
        if (isSubmitted) {
            // alert("clicked")
            if (currentLanguage === "eng") {
                setCurrAudio(new Audio(budget_en_2));
            } else {
                setCurrAudio(new Audio(budget_es_2));
            }
        }
        return () => {
            if (currAudio && !currAudio.paused) {
                currAudio.pause()
            }
        }
    }, [isSubmitted])


    return (

        <>

            <div id='budget'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-xl-12 col-lg-12 col-md-12 col-12'>
                            <div className='budget-left'>
                                <div className='heading'>
                                    <h2>{language.budget_tracking}</h2>
                                </div>
                                <div className='budget-cnt'>
                                    <div className='cnt-top'>
                                        <form>
                                            <div className="form-group">
                                                <label for="exampleInputEmail1">{language.budget_amount}</label>
                                                <input
                                                    value={values.budgetAmount}
                                                    type="Number"
                                                    className="form-control"
                                                    id=""
                                                    aria-describedby=""
                                                    onChange={(e) => setValues(prev => ({ ...prev, budgetAmount: e.target.value }))}
                                                    placeholder={language.amount}
                                                />
                                            </div>
                                        </form>
                                    </div>
                                    <div className='cnt-mid'>

                                        <form>
                                            <div class="form-group">
                                                <label>{language.budget_itemm}</label>
                                                <select className="form-control drop" id="exampleFormControlSelect1" ref={selectRef} onChange={handleBudgetItem}>
                                                    <option>{language.budget_itemm}</option>
                                                    <option>{language.sales}</option>
                                                    <option>{language.expense}</option>
                                                    <option>{language.profit}</option>
                                                </select>
                                            </div>
                                        </form>

                                    </div>

                                    <div className='btm-cnt'>
                                        <form>
                                            <div class="form-group">
                                                <label>{language.project_name}</label>
                                                {/* <select className="form-control drop" id="exampleFormControlSelect1" ref={projectselectRef} onChange={(e) => {
                                                    if (e.target.value !== language.project_name) {
                                                        setValues(prev => ({ ...prev, projectName: e.target.value }))
                                                    }
                                                }}>
                                                    <option>{language.project_name}</option>
                                                    {
                                                        data.map((proj, i) => (
                                                            <option key={proj._id}>{proj.projectName}</option>
                                                        ))
                                                    }
                                                </select> */}
                                                {/* <input
                                                    value={values.projectName}
                                                    type="text"
                                                    className="form-control"
                                                    id=""
                                                    aria-describedby=""
                                                    onChange={(e) => {
                                                        if( /^[A-Za-z\s]*$/.test(e.target.value)){
                                                        setValues(prev => ({ ...prev, projectName: e.target.value }))
                                                        }
                                                    }}
                                                    placeholder={language.project_name}
                                                /> */}

                                                <input
                                                    type='text'
                                                    placeholder={language.customer_name}
                                                    readOnly
                                                    value={isSave ? values.projectName : language.project_name}
                                                    onClick={() => {
                                                        setIsCustomerName(true)
                                                        setIsSave(false)
                                                    }
                                                    }
                                                />
                                                <CustomModal
                                                    modalIsOpen={isCustonerName}
                                                    setIsOpen={setIsCustomerName}
                                                    openModal={() => setIsCustomerName(true)}
                                                    closeModal={() => setIsCustomerName(false)}
                                                >
                                                    <div>
                                                        <div className='d-flex justify-content-between text-secondary'>
                                                            <span>
                                                                {/* {language.confirmation} */}
                                                            </span>
                                                            <span>
                                                                <AiOutlineClose className='icon' onClick={() => setIsCustomerName(false)} size={20} color="gray" />
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="input-group mb-3">
                                                        <div style={{ width: "80%" }}>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder={language.project_name}
                                                                aria-label="Recipient's username"
                                                                aria-describedby="basic-addon2"
                                                                value={values.projectName}
                                                                onChange={(e) => {
                                                                    // console.log(e.target.value)
                                                                    // if (/^[A-Za-z\s]*$/.test(e.target.value)) {
                                                                    setValues((prev) => {
                                                                        return { ...prev, projectName: e.target.value }
                                                                    })
                                                                    fetchSerchName(e.target.value)
                                                                    // }
                                                                }}
                                                            />
                                                        </div>
                                                        <button
                                                            className='customer_name_button_revenew btn btn-primary'
                                                            onClick={() => {
                                                                setIsSave(true);
                                                                setIsCustomerName(false);
                                                                setIsSaveVendor(!isSaveVendor)
                                                            }}
                                                        >
                                                            {language.save}
                                                        </button>
                                                        {
                                                            searchData.map((item, i) =>
                                                                <div className='search_name' key={i}>
                                                                    <p
                                                                        style={{ margin: '0' }}
                                                                        onClick={() => {
                                                                            setValues((prev) => {
                                                                                return { ...prev, projectName: item }
                                                                            })
                                                                            setSearchData([]);
                                                                        }}
                                                                    >
                                                                        {item}
                                                                    </p>
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                </CustomModal>

                                            </div>
                                        </form>
                                    </div>
                                    <div className='btm-cnt'>
                                        <form>
                                            <div className="row justify-content-center">
                                                <div className="form-group col-sm-12 col-md-12 col-lg-6">
                                                    <label for="exampleInputEmail1">{language.start_date}</label>
                                                    <input
                                                        value={values.startProjectDate}
                                                        type="date"
                                                        className="form-control"
                                                        id=""
                                                        aria-describedby=""
                                                        onChange={(e) => setValues(prev => ({ ...prev, startProjectDate: e.target.value }))}
                                                    />
                                                </div>
                                                <div className="form-group col-sm-12 col-md-12 col-lg-6">
                                                    <label for="exampleInputEmail1">{language.end_date}</label>
                                                    <input
                                                        value={values.endProjectDate}
                                                        type="date"
                                                        className="form-control"
                                                        id=""
                                                        aria-describedby=""
                                                        onChange={(e) => setValues(prev => ({ ...prev, endProjectDate: e.target.value }))}
                                                    />
                                                </div>
                                            </div>

                                        </form>

                                    </div>
                                    <div className='cnt-btn'>
                                        <button type="send" className='post-btn' onClick={handleBudgetPost}>{language.post}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className='col-xl-5 col-lg-5 col-md-12 col-12 d-flex justify-content-center align-items-center' style={{paddingTop:"80px"}}>
                            <div className='bill-right'>
                                <label className="form_label">
                                    <input type="file" className="form-control" id="uploadPhoto" />
                                    imagen por enviar
                                </label>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>

        </>

    )
}

export default Tracker