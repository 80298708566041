import React from "react";
import { useLanguage } from "../../Providers/LanguageContext";
import SignUpButton from "./SignUpButton";
import iMg1 from "../../images/Uso Personal y Doméstico_3-76.png";
import iMg2 from "../../images/Uso Personal y Doméstico_1-74.png";
import iMg3 from "../../images/Uso Personal y Doméstico_2-75.png";
import iMg4 from "../../images/Uso Personal y Doméstico_4 .png";
import iMg5 from "../../images/Uso Personal y Doméstico_5 .png";
import iMg6 from "../../images/Uso Personal y Doméstico_6-81.png";
import iMg7 from "../../images/Uso Personal y Doméstico_7 INGLES.png";
import iMg7es from "../../images/Uso Personal y Doméstico_1 copia 3.png";
import bodyimage from "../../images/fondo.png";
import tick from "../../images/tick.png";
import SignUpButton2 from "./SignUpButton2";
const PersonalUse = () => {
  const { language, currentLanguage } = useLanguage();

  return (
    <>
      {/* <section id='agriculture_sec'>
        <div className='container'>
          <div className='agriculture_head'>
            <div>
              <h3>{language.fisibility_key_feture}:</h3>
              <h2>{language.personal_and_domestic_use}</h2>
            </div>
          </div>
          <div className='agriculture_list'>
            <ul>
              <li><strong>{language.budgeting}: </strong>
                {language.fisi_create_person_budget}
              </li>
               <li><strong>{language.bill_tracking}: </strong>
                {language.fisi_user_track_bill}
              </li>
              <li><strong>{language.financial_goal_setting}: </strong>
                {language.fisi_financial_goal}
              </li>
              <li><strong>{language.automated_expence_track}: </strong>
                {language.fisi_automatically_cata}
              </li>
              <li><strong>{language.investment_tracking}: </strong>
                {language.fisi_invest_trackibg}
              </li>
              <li><strong>{language.net_worth_tracking}: </strong>
                {language.fisi_track_worth}
              </li>
              <li><strong>{language.mobile_access}: </strong>
                {language.fisi_available_desktop}
              </li>
              <li><strong>{language.secure_data_storage}: </strong>
                {language.fisi_bank_encryption}
              </li>
              <li><strong>{language.personalized_insights}: </strong>
                {language.fisi_person_insight}
              </li>
              <li><strong>{language.user_friendly_interface}: </strong>
                {language.fisi_user_interface}
              </li>
              
            </ul>
          </div>

          <SignUpButton />
        </div>
      </section> */}
{/* 
      <Helmet>
        <link rel="canonical" href="https://fisibility.com/personalUse" />
      </Helmet> */}

      <section
        id="agriculture_sec"
        className="banking"
        style={{ backgroundImage: `url('${bodyimage}')`, position: "relative", paddingBottom: "0" }}
      >
        <div className="container">
          <div className="agriculture_head" style={{ marginBottom: "0" }}>
            <h3 style={{ color: "#0e2537" }}>
              {language.fisi_key_benifits}
            </h3>
          </div>
          <div className="row mb-5" >
            <div className="col-md-6 col-12">
              <div className="agriculture_headtext">
                <h5>
                  {language.personal_and_household}
                </h5>
                <div className="tickpara">
                  <div className="tick">
                    <img src={tick}></img>
                  </div>
                  <div>
                    <h4 style={{ color: "#333333" }}>
                      {language.budgeting}
                    </h4>
                    <p>
                      {language.fisi_create_person_budget}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-12 m-auto">
              <div className="agriculture_img">
                <img src={iMg2} className="img-fluid" alt="" />
              </div>
            </div>
          </div>

          <div className="col-md-12 col-12 rightsideGap">
            <div className="agriculture_headtext">
              <div className="tickpara">
                <div className="tick">
                  <img src={tick}></img>
                </div>
                <div>
                  <h4 style={{ color: "#333333" }}>{language.bill_tracking}</h4>
                  <p>
                    {language.fisi_user_track_bill}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="row mb-5" style={{ alignItems: "center" }}>
            <div className="col-md-7 col-12">
              <div className="agriculture_headtext">
                <div className="tickpara">
                  <div className="tick">
                    <img src={tick}></img>
                  </div>
                  <div>
                    <h4 style={{ color: "#333333" }}>{language.financial_goal_setting}</h4>
                    <p>
                      {language.fisi_financial_goal}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-5 col-12 m-auto">
              <div className="agriculture_img">
                <img src={iMg3} className="img-fluid" alt="" />
              </div>
            </div>
          </div>

          <div className="row mb-5" style={{ alignItems: "center" }}>
            <div className="col-md-4 col-12 m-auto">
              <div className="agriculture_img">
                <img src={iMg1} className="img-fluid" alt="" />
              </div>
            </div>
            <div className="col-md-8 col-12">
              <div className="agriculture_headtext">
                <div className="tickpara">
                  <div className="tick">
                    <img src={tick}></img>
                  </div>
                  <div>
                    <h4 style={{ color: "#333333" }}>
                      {language.automated_expence_track}
                    </h4>
                    <p>
                      {language.fisi_automatically_cata}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-8 col-12">
            <div className="agriculture_headtext">
              <div className="tickpara">
                <div className="tick">
                  <img src={tick}></img>
                </div>
                <div>
                  <h4 style={{ color: "#333333" }}>
                    {language.investment_tracking}
                  </h4>
                  <p>
                    {language.fisi_invest_trackibg}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="row mb-5" style={{ alignItems: "center" }}>
            <div className="col-md-4 col-12 m-auto">
              <div className="agriculture_img">
                <img src={iMg4} className="img-fluid" alt="" />
              </div>
            </div>
            <div className="col-md-8 col-12">
              <div className="agriculture_headtext">
                <div className="tickpara">
                  <div className="tick">
                    <img src={tick}></img>
                  </div>
                  <div>
                    <h4 style={{ color: "#333333" }}>
                      {language.net_worth_tracking}
                    </h4>
                    <p>
                      {language.fisi_track_worth}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-5" style={{ alignItems: "center" }}>
            <div className="col-md-8 col-12">
              <div className="agriculture_headtext">
                <div className="tickpara">
                  <div className="tick">
                    <img src={tick}></img>
                  </div>
                  <div>
                    <h4 style={{ color: "#333333" }}>
                      {language.mobile_access}
                    </h4>
                    <p>
                      {language.fisi_available_desktop}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-12 m-auto">
              <div className="agriculture_img">
                <img src={iMg5} className="img-fluid" alt="" />
              </div>
            </div>
          </div>

          <div className="col-md-12 col-12 rightsideGap">
            <div className="agriculture_headtext">
              <div className="tickpara">
                <div className="tick">
                  <img src={tick}></img>
                </div>
                <div>
                  <h4 style={{ color: "#333333" }}>
                    {language.secure_data_storage}
                  </h4>
                  <p>
                    {language.fisi_bank_encryption}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="row mb-5" style={{ alignItems: "center" }}>
            <div className="col-md-8 col-12">
              <div className="agriculture_headtext">
                <div className="tickpara">
                  <div className="tick">
                    <img src={tick}></img>
                  </div>
                  <div>
                    <h4 style={{ color: "#333333" }}>
                      {language.personalized_insights}
                    </h4>
                    <p>
                      {language.fisi_person_insight}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-12 m-auto">
              <div className="agriculture_img">
                <img src={iMg6} className="img-fluid" alt="" />
              </div>
            </div>
          </div>

          <div className="row" style={{ alignItems: "center" }}>
            <div className="col-md-5 col-12 m-auto">
              <div className="agriculture_img">
                {
                  currentLanguage === 'spanish'
                    ?
                    <img src={iMg7es} className="img-fluid" alt="" />
                    :
                    <img src={iMg7} className="img-fluid" alt="" />
                }
                {/* <div
                  className="registrarAnddescargar"
                  style={{
                    transform: "translate(-50% , -50%)",
                    top: "44%",
                    left: "21%",
                    flexDirection: "column",
                  }}
                >
                  <button
                    className="registrar_Btn"
                    style={{
                      background:
                        " linear-gradient(to right, #18465d 1%,#0f283b 100%,#2989d8 100%)",
                      border: "0",
                      marginBottom: "20px",
                    }}
                  >
                    Registrarse
                  </button>
                  <button className="descargar_Btn">Descargar</button>
                </div> */}
                <SignUpButton2 />
              </div>
            </div>
            <div className="col-md-7 col-12">
              <div className="agriculture_headtext">
                <div className="tickpara">
                  <div className="tick">
                    <img src={tick}></img>
                  </div>
                  <div>
                    <h4 style={{ color: "#333333" }}>
                      {language.user_friendly_interface}
                    </h4>
                    <p>
                      {language.fisi_user_interface}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PersonalUse;
