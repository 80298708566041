import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import HttpClient from '../../../utils/HttpClient';
import moment from 'moment';
import toast from 'react-hot-toast';
import { FaArrowCircleDown } from 'react-icons/fa';
import { useLanguage } from '../../..//Providers/LanguageContext';
import { useUserData } from '../../../Providers/UserWrapper';
// import SetUpManageDocs from '../../../SetUpManageDocs';
import NoDataFound from '../../../Component/NoDataFound';
import SetUpManageDocs from '../../../Modal/SetUpManageDocs';
import SetUpManageDocsSubUser from './SetUpManageDocsSubUser';


function SubUserBook(props) {
    const {
        closeModal,
        singleUserData,
        setCallGetAcc
    } = props;
    const { language, currentLanguage } = useLanguage()
    const { setIsLoading } = useUserData();
    const loop = [{ id: 1, status: "Attached", }, { id: 2, status: "Attached", }, { id: 3, status: "Past Due", },];

    const settings = {
        dots: false,
        infinite: false,
        swipe: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    const [transactionData, setTransacctionData] = useState({});
    const initDetailDescription = {
        description: "",
        subTotal: "",
        taxType: "",
        tax: "",
        taxAmount: "",
        total: "",
        pdf: "",
        checked: false
    }
    const initTaxDet = {
        vendorName: "",
        transactionDate: "",
        description: "",
        subTotal: "",
        taxType: "",
        tax: "",
        taxAmount: "",
        total: "",
        updatedPrice: "",
        _id: "",
        detailDescription: [],
        pdf: ""
    }
    const [taxDetData, setTaxDetData] = useState([initTaxDet])

    const initFilter = {
        startDate: "",
        endData: "",
        vendorName: ""
    }
    const [filterData, setFilterData] = useState(initFilter);

    const [taxDetIndex, setTaxDetIndex] = useState({ indTax: null, valPrice: null });

    console.log("taxDetData", taxDetData)


    // handle Change
    const handleChangeTaxDet = (e, i) => {
        const { name, value } = e.target;
        const newData = taxDetData.map((item, ind) => {
            if (i === ind) {
                return { ...item, [name]: value }
            } else {
                return item
            }
        })
        setTaxDetData(newData)
    }

    // click on plus button
    const handleClickPlusBtn = (item, i) => {
        // const newDesc = [...taxDetData.detailDescription, initDetailDescription]
        setTaxDetData(prev => {
            const fltArr = prev.map((ele, ind) => {
                if (i === ind) {
                    return {
                        ...ele,
                        detailDescription: [initDetailDescription, ...ele.detailDescription]
                    }
                } else {
                    return ele
                }
            })
            return fltArr
        })
    }

    // click on minus button
    const handleClickMinusBtnDel = (item, i, indMinus) => {
        setTaxDetIndex(prev => ({ ...prev, indTax: i, valPrice: indMinus }))
        setTaxDetData(prev => {
            const fltArr = prev.map((ele, ind) => {
                if (i === ind) {
                    return {
                        ...ele,
                        detailDescription: ele?.detailDescription?.filter((des, index) => index !== indMinus)
                    }
                } else {
                    return ele
                }
            })
            return fltArr
        })
    }

    //onchange detail description
    const handleChangeDetDesc = (e, i, indDesc) => {
        const { name, value } = e.target;
        const newData = taxDetData.map((item, ind) => {
            if (i === ind) {
                const detData = item.detailDescription.map((val, idx, arrDesc) => {
                    if (indDesc === idx) {
                        if (name === "subTotal") {
                            setTaxDetIndex(prev => ({ ...prev, indTax: i, valPrice: value }))
                            const calTaxTotal = calculateTaxAmount(value, val?.tax);
                            return {
                                ...val,
                                [name]: value,
                                taxAmount: calTaxTotal,
                                total: calculateTotal(calTaxTotal, value)
                            }
                        } else if (name === "tax") {
                            setTaxDetIndex(prev => ({ ...prev, indTax: i, valPrice: value }))
                            const calTaxTotal = calculateTaxAmount(val?.subTotal, value);
                            return {
                                ...val,
                                [name]: value,
                                taxAmount: (val?.subTotal * value) / 100,
                                total: calculateTotal(calTaxTotal, val?.subTotal)
                            }
                        } else if (name === "checked") {
                            return { ...val, [name]: e.target.checked }
                        } else {
                            return { ...val, [name]: value }
                        }
                    }
                    else {
                        return val
                    }
                })
                return { ...item, detailDescription: detData }
            } else {
                return item
            }
        })
        setTaxDetData(newData)
    }

    // handle change pdf
    const handlePdfUpload = async (e, i) => {
        const file = e.target.files[0]
        if (file) {
            const data = new FormData();
            data.append("pdfFile", file)
            // console.log('imgData',data)
            setIsLoading(true)
            const res = await HttpClient.fileUplode("bookkeeper/upload-pdf", "POST", data);
            // console.log('imgres', res)
            setIsLoading(false)
            if (res && res.status) {
                handleChangeTaxDet({ target: { name: "pdf", value: res?.url } }, i)
                // setTaxDetData(prev => ({ ...prev, pdf: res?.url }))
                toast.success(language.img_upload_msg)
            } else {

            }
        }
    }

    // handle change setail pdf
    const handlePdfUploadDetail = async (e, i, ind) => {
        const file = e.target.files[0]
        if (file) {
            const data = new FormData();
            data.append("pdfFile", file)
            // console.log('imgData',data)
            setIsLoading(true)
            const res = await HttpClient.fileUplode("bookkeeper/upload-pdf", "POST", data);
            // console.log('imgres', res)
            setIsLoading(false)
            if (res && res.status) {
                handleChangeDetDesc({ target: { name: "pdf", value: res?.url } }, i, ind)
                // setTaxDetData(prev => ({ ...prev, pdf: res?.url }))
                toast.success(language.img_upload_msg)
            } else {

            }
        }
    }


    // calculate total
    const calculateTotal = (num1, num2) => {
        return Number(num1) + Number(num2)
    }

    // calculate tax amount
    const calculateTaxAmount = (price, tax) => {
        return (price * tax) / 100
    }

    // calculated updated sum
    const calculateUpdatedSum = (arr) => {
        const updatedSum = arr?.reduce((acc, cur) => Number(acc) + Number(cur?.total), 0)
        return updatedSum
    }


    // get all transaction
    const getAllTransacion = async () => {
        const data = {
            "accountRegCode": singleUserData?.accountRegCode,
            "startDate": filterData?.startDate,
            "endDate": filterData?.endData,
            "vendorName": filterData?.vendorName
        }
        setIsLoading(true)
        const res = await HttpClient.requestData("get-expense-details-user", "POST", data)
        setIsLoading(false)
        console.log("resAlltt", res)
        if (res && res?.status) {
            // const fltData = removeDuplicate(res?.data)
            setTransacctionData(res?.data)
            const newData = res?.data?.map((item) => ({
                ...initTaxDet,
                vendorName: item?.vendorName,
                transactionDate: moment(item?.transactionDate).format("DD-MM-YYYY"),
                description: item?.expenseTypeData?.name,
                total: item?.price,
                _id: item?._id,
                updatedPrice: item?.updatedPrice ? item?.updatedPrice : "",
                pdf: item?.finalPdf ? item?.finalPdf : "",
                detailDescription: item?.descriptionDetails
                    ?
                    item?.descriptionDetails?.map(ele => ({
                        description: ele?.item_description,
                        subTotal: ele?.subTotal,
                        taxType: ele?.taxType,
                        tax: ele?.tax,
                        taxAmount: ele?.taxAmount,
                        total: ele?.totalPrice,
                        pdf: ele?.pdf
                    }))
                    :
                    []
            }))
            // console.log("fltDatares", fltData)
            setTaxDetData(newData)
        }
    }

    // remove duplicates
    const removeDuplicate = (data) => {
        const unique = data.filter((obj, index) => {
            return index === data.findIndex(o => obj.vendorName === o.vendorName);
        });
        const filteredData = unique.map((item, i) => {
            const tranDetail = data?.filter(ele => ele?.vendorName === item?.vendorName)
            return {
                vendorName: item?.vendorName,
                details: tranDetail
            }
        })
        return filteredData
    }

    // handle update
    const handleUpdateTaxDet = async (item) => {
        const data = {
            "vendorName": item?.vendorName,
            "descriptionDetails": item?.detailDescription?.map(ele => ({
                "item_description": ele?.description,
                "subTotal": ele?.subTotal,
                "taxType": ele?.taxType,
                "tax": ele?.tax,
                "taxAmount": ele?.taxAmount,
                "pdf": ele?.pdf,
                "totalPrice": ele?.total
            })),
            "updatedPrice": item?.updatedPrice,
            "finalPdf": item?.pdf
        }
        setIsLoading(true)
        const res = await HttpClient.requestData("edit-expense-details-by-user/" + item?._id, "PUT", data)
        setIsLoading(false)
        if (res && res?.status) {
            toast.success(res?.message)
        }

        console.log("taxDetDataupdate", item, data, res)

    }



    useEffect(() => {
        if (
            (filterData.startDate !== "" && filterData.endData !== "") ||
            (filterData.startDate === "" && filterData.endData === "")
        ) {
            getAllTransacion()
        }
    }, [singleUserData, filterData.startDate, filterData.endData])

    useEffect(() => {
        // if (filterData.vendorName) {
        const timer = setTimeout(() => {
            getAllTransacion()
        }, 600);

        return () => clearTimeout(timer)
        // }
    }, [filterData.vendorName])


    // calculate the updated sum price
    useEffect(() => {
        const sumPrice = calculateUpdatedSum(taxDetData[taxDetIndex?.indTax]?.detailDescription);
        handleChangeTaxDet({ target: { name: "updatedPrice", value: sumPrice } }, taxDetIndex?.indTax)
    }, [taxDetIndex])







    return (
        <>
            {
                <section className='missingDocsuserModal pagedesign'>
                    <div className='missingDocuserModInn pagedesign'>
                        {/* <div className='missingDocClose' onClick={() => closeModal()}>
                            <i className="fa-solid fa-xmark"></i>
                        </div> */}
                        <div className='subuserslide'>

                            <Slider {...settings}>

                                {/* slide 1 */}
                                <div>
                                    <SetUpManageDocsSubUser
                                        singleUserData={singleUserData}
                                        setCallGetAcc={setCallGetAcc}
                                    />
                                </div>

                                {/* slide 2 */}
                                <div>
                                    <h4 className='bookSecModalHead'>{singleUserData?.client_name}: Tax Detail Management</h4>

                                    {/* filter */}
                                    <div className='d-flex gjhjhj'>

                                        {/* Vendor-Search */}
                                        <div>
                                            <input
                                                className='bookSecTopDivBtn'
                                                placeholder='Vendor-Search'
                                                value={filterData.vendorName}
                                                onChange={(e) => {
                                                    // clearInterval(timer)
                                                    // const timer = setInterval(() => {
                                                    setFilterData(prev => ({ ...prev, vendorName: e.target.value }))
                                                    // }, 300)
                                                }}
                                            />
                                        </div>

                                        {/* Start Date */}
                                        <div style={{ position: 'relative' }}>
                                            <button className='bookSecTopDivBtn' style={{ position: "relative" }}>
                                                {filterData.startDate ? filterData.startDate : "Start Date"}
                                            </button>
                                            <Datetime
                                                className='bookTaxDetailManageStart'
                                                // value={reportDate?.startData ? moment(reportDate?.endDate) : ""}
                                                closeOnSelect
                                                onChange={(e) => setFilterData((prev) => {
                                                    return {
                                                        ...prev,
                                                        startDate: moment.isMoment(e) ? e.format("yyyy-MM-DD") : ""
                                                    }
                                                }
                                                )}
                                                timeFormat={false}
                                                // initialValue={language.end_date}
                                                input={true}
                                            />
                                        </div>

                                        {/* End Date */}
                                        <div style={{ position: 'relative' }}>
                                            <button className='bookSecTopDivBtn' style={{ position: "relative" }}>
                                                {filterData.endData ? filterData.endData : "End Date"}
                                            </button>
                                            <Datetime
                                                className='bookTaxDetailManageEnd'
                                                // value={reportDate?.startData ? moment(reportDate?.endDate) : ""}
                                                closeOnSelect
                                                onChange={(e) => setFilterData((prev) => {
                                                    return {
                                                        ...prev,
                                                        endData: moment.isMoment(e) ? e.format("yyyy-MM-DD") : ""
                                                    }
                                                }
                                                )}
                                                timeFormat={false}
                                                // initialValue={language.end_date}
                                                input={true}
                                            />
                                        </div>

                                        {/* clear */}
                                        <div
                                            className='bookTaxDetClearButton'
                                            onClick={() => setFilterData(initFilter)}
                                        >
                                            Clear
                                        </div>
                                    </div>

                                    {/* table */}
                                    <div className='manageSliderInn'>
                                        <div className='missingDocTbl'>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Vendor Name</th>
                                                        <th>Date</th>
                                                        <th>Item Description</th>
                                                        <th>Sub-Total</th>
                                                        <th>Tax Type</th>
                                                        <th>Tax %</th>
                                                        <th>Tax Amount</th>
                                                        <th>Total Price</th>
                                                        <th>Updated Price</th>
                                                        <th>Tax Deductible</th>
                                                        <th>Update</th>
                                                    </tr>
                                                </thead>

                                                {taxDetData.map((item, i) => {
                                                    return (
                                                        <tbody key={i}>

                                                            {/* main description */}
                                                            <tr>
                                                                <td>
                                                                    {item?.vendorName}
                                                                </td>
                                                                <td>
                                                                    <button className='bookModalBtn'>
                                                                        {item?.transactionDate}
                                                                        {/* <input type='date' /> */}
                                                                    </button>
                                                                </td>

                                                                <td>
                                                                    <button className='bookModalBtn'>
                                                                        {/* {item.description} */}
                                                                        <input
                                                                            className="form-control form-control-sm taxDetailTableInput"
                                                                            type="text"
                                                                            placeholder="Sub-Total"
                                                                            name='description'
                                                                            value={item?.description}
                                                                            onChange={(e) => handleChangeTaxDet(e, i)}
                                                                        />
                                                                    </button>
                                                                </td>

                                                                <td>
                                                                    {/* <button className='bookModalBtn'>100.00</button> */}
                                                                    <button
                                                                        className='plusBtnBookTaxDet'
                                                                        onClick={() => handleClickPlusBtn(item, i)}
                                                                    >
                                                                        +
                                                                    </button>
                                                                    <input
                                                                        className="form-control form-control-sm taxDetailTableInput"
                                                                        type="number"
                                                                        placeholder="Sub Total"
                                                                        name='subTotal'
                                                                        value={item?.subTotal}
                                                                        onChange={(e) => handleChangeTaxDet(e, i)}
                                                                        disabled
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <input
                                                                        className="form-control form-control-sm taxDetailTableInput"
                                                                        type="text"
                                                                        placeholder="Type"
                                                                        name='taxType'
                                                                        value={item?.taxType}
                                                                        onChange={(e) => handleChangeTaxDet(e, i)}
                                                                        disabled
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <input
                                                                        className="form-control form-control-sm taxDetailTableInput"
                                                                        type="number"
                                                                        placeholder="Tax"
                                                                        name='tax'
                                                                        value={item?.tax}
                                                                        onChange={(e) => handleChangeTaxDet(e, i)}
                                                                        disabled
                                                                    />
                                                                </td>
                                                                <td>
                                                                    {/* <button className='bookModalBtn'>16.00</button> */}
                                                                    <input
                                                                        className="form-control form-control-sm taxDetailTableInput"
                                                                        type="number"
                                                                        placeholder="Tax Amount"
                                                                        name='taxAmount'
                                                                        value={item?.taxAmount}
                                                                        onChange={(e) => handleChangeTaxDet(e, i)}
                                                                        disabled
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <input
                                                                        className="form-control form-control-sm taxDetailTableInput"
                                                                        type="number"
                                                                        placeholder="Total"
                                                                        name='total'
                                                                        value={item?.total}
                                                                        onChange={(e) => handleChangeTaxDet(e, i)}
                                                                        readOnly
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <input
                                                                        className="form-control form-control-sm taxDetailTableInput"
                                                                        type="number"
                                                                        placeholder="Price"
                                                                        name='updatedPrice'
                                                                        value={item?.updatedPrice}
                                                                        onChange={(e) => handleChangeTaxDet(e, i)}
                                                                        readOnly
                                                                    />
                                                                </td>
                                                                <td style={{ position: "relative" }}>
                                                                    <button className='bookSmallBtn'>pdf</button>
                                                                    <input
                                                                        style={{ right: item?.pdf ? "62px" : "51px" }}
                                                                        className='bookDetailPdfInpt'
                                                                        type='file'
                                                                        onChange={(e) => handlePdfUpload(e, i)}
                                                                    />
                                                                    {
                                                                        item?.pdf &&
                                                                        <span
                                                                            className='ml-1'
                                                                            style={{ fontSize: "20px" }}
                                                                            onClick={() => {
                                                                                window.open(item?.pdf)
                                                                            }}
                                                                        >
                                                                            <FaArrowCircleDown />
                                                                        </span>
                                                                    }

                                                                </td>

                                                                <td>
                                                                    <button
                                                                        className='taxManageUpBtn'
                                                                        onClick={() => handleUpdateTaxDet(item)}
                                                                    >
                                                                        Update
                                                                    </button>
                                                                    {/* <button className='taxManageEditBtn'>Edit</button> */}

                                                                </td>
                                                            </tr>

                                                            {/* detail description */}
                                                            {item?.detailDescription?.length !== 0 &&
                                                                item?.detailDescription?.map((ele, ind) =>
                                                                    <tr key={ind}>
                                                                        <td>
                                                                            {/* {item?.vendorName} */}
                                                                        </td>
                                                                        <td>
                                                                            {/* <button className='bookModalBtn'>
                                                                    {item?.transactionDate}
                                                                </button> */}
                                                                        </td>

                                                                        <td>
                                                                            <button className='bookModalBtn'>
                                                                                <input
                                                                                    className="form-control form-control-sm taxDetailTableInput"
                                                                                    type="text"
                                                                                    placeholder="Description"
                                                                                    name='description'
                                                                                    value={ele?.description}
                                                                                    onChange={(e) => handleChangeDetDesc(e, i, ind)}
                                                                                />
                                                                            </button>
                                                                        </td>

                                                                        <td>
                                                                            <button
                                                                                className='plusBtnBookTaxDet text-danger '
                                                                                onClick={() => handleClickMinusBtnDel(item, i, ind)}
                                                                            >
                                                                                -
                                                                            </button>
                                                                            <input
                                                                                className="form-control form-control-sm taxDetailTableInput"
                                                                                type="number"
                                                                                placeholder="Sub Total"
                                                                                name='subTotal'
                                                                                value={ele?.subTotal}
                                                                                onChange={(e) => handleChangeDetDesc(e, i, ind)}
                                                                            />
                                                                        </td>

                                                                        <td>
                                                                            <input
                                                                                className="form-control form-control-sm taxDetailTableInput"
                                                                                type="text"
                                                                                placeholder="Type"
                                                                                name='taxType'
                                                                                value={ele?.taxType}
                                                                                onChange={(e) => handleChangeDetDesc(e, i, ind)}
                                                                            />
                                                                        </td>

                                                                        <td>
                                                                            <input
                                                                                className="form-control form-control-sm taxDetailTableInput"
                                                                                type="number"
                                                                                placeholder="Tax"
                                                                                name='tax'
                                                                                value={ele?.tax}
                                                                                onChange={(e) => handleChangeDetDesc(e, i, ind)}
                                                                            />
                                                                        </td>
                                                                        <td>
                                                                            <input
                                                                                className="form-control form-control-sm taxDetailTableInput"
                                                                                type="number"
                                                                                placeholder="Tax Amount"
                                                                                name='taxAmount'
                                                                                value={ele?.taxAmount}
                                                                                onChange={(e) => handleChangeDetDesc(e, i, ind)}
                                                                                readOnly
                                                                            />
                                                                        </td>
                                                                        <td>
                                                                            <input
                                                                                className="form-control form-control-sm taxDetailTableInput"
                                                                                type="number"
                                                                                placeholder="Total"
                                                                                name='total'
                                                                                value={ele?.total}
                                                                                onChange={(e) => handleChangeDetDesc(e, i, ind)}
                                                                                readOnly
                                                                            />
                                                                        </td>
                                                                        <td></td>
                                                                        <td style={{ position: "relative" }}>
                                                                            <input
                                                                                type='checkbox'
                                                                                name='checked'
                                                                                value={ele?.checked}
                                                                                checked={ele.checked}
                                                                                onChange={(e) => handleChangeDetDesc(e, i, ind)}
                                                                            />
                                                                            <button className='bookSmallBtn'>pdf</button>
                                                                            <input
                                                                                className={`bookDetailPdfInptCheck ${ele?.pdf ? "pdfExist" : "pdfNoexist"}`}
                                                                                // style={{ right: ele?.pdf ? "54px" : "41px" }}
                                                                                type='file'
                                                                                name='pdf'
                                                                                onChange={(e) => handlePdfUploadDetail(e, i, ind)}
                                                                            />
                                                                            {
                                                                                ele?.pdf &&
                                                                                <span
                                                                                    className='ml-1'
                                                                                    style={{ fontSize: "20px" }}
                                                                                    onClick={() => {
                                                                                        window.open(item?.pdf)
                                                                                    }}
                                                                                >
                                                                                    <FaArrowCircleDown />
                                                                                </span>
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }
                                                        </tbody>
                                                    )
                                                })}



                                            </table>
                                            {taxDetData?.length === 0 && <NoDataFound />}
                                        </div>
                                    </div>

                                    {/* <div className='totalAmtDiv'>
                                <p>Total Deductibles: <button className='totalAmtBtn'>$464.00</button></p>
                            </div> */}
                                    {/* <div className='taxManageBtnDiv'>
                                <button className='taxManageEditBtn'>Edit</button>
                                <button className='taxManageUpBtn'>Update</button>
                            </div> */}
                                </div>

                            </Slider>
                        </div>
                    </div>
                </section>
            }


            {/* dddddddd */}
        </>
    )
}

export default SubUserBook