import React, { useState } from 'react'
import Modal from 'react-modal';
import HttpClient from '../../utils/HttpClient';
import toast from 'react-hot-toast';
import { ThreeDots } from 'react-loader-spinner';
import CustomLoaderLine from '../../Component/Loader/CustomLoaderLine';
import { useLanguage } from '../../Providers/LanguageContext';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: "50%",

    },
};




function DownloadTemplateModal({ handleClose, modalIsOpen }) {
    const [pdfLoader, setPdfLoader] = useState(false);
    const { language, currentLanguage } = useLanguage();

    const tamplateList = [
        {
            name: language.income_statement_profit_los,
            endPoint: "profitLoss"
        },
        {
            name: language.trial_balance,
            endPoint: "trialBalance"
        },
        {
            name: language.balance_sheet,
            endPoint: "balanceSheet"
        },
        {
            name: language.cash_flow,
            endPoint: "cashFlow"
        },
        {
            name: language.cap_table_owner,
            endPoint: "capitalizationTable"
        },
        {
            name: language.budget_forcast,
            endPoint: "budgetForecast"
        },
        {
            name: language.account_payable,
            endPoint: "agingReport"
        },
        {
            name: language.bussiness_fs,
            endPoint: "budgetFinance"
        },
        {
            name: language.personel_financial_statement,
            endPoint: "personalFinancial"
        },
        {
            name: language.thirteenweek_cfs,
            endPoint: "thirteenWeekCash"
        },
        {
            name: language.twelveMonth_cfs,
            endPoint: "twelveMonthCash"
        },

    ]
    // dowmload pdf
    const handlePdfDownload = async (endpoint) => {
        const data = {
            reportType: endpoint
        }
        setPdfLoader(true);
        const res = await HttpClient.requestData("selservice/downloadtemplate", "POST", data)
        setPdfLoader(false);
        if (res && res?.status) {
            window.open(res?.data, "_blank")
        } else {
            toast.error(res?.message || "Something Wrong!")
        }
    }

    return (
        <>
            {/* <section className='DownloadTemplate_Modal'> */}
            <Modal
                isOpen={modalIsOpen}
                // onAfterOpen={afterOpenModal}
                onRequestClose={handleClose}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <div className='DownloadTemplateModal_body'>
                    <button className='closebtn_btn' onClick={handleClose}>x</button>
                    <div className='d-flex justify-content-center mb-3'>
                        <h3>Download Template</h3>
                    </div>

                    <div className='d-flex justify-content-center mb-3'>
                        {
                            pdfLoader &&
                            <CustomLoaderLine />
                            // <ThreeDots
                            //     height="20"
                            //     width="50"
                            //     radius="5"
                            //     color="#0E2537"
                            //     ariaLabel="three-dots-loading"
                            //     wrapperStyle={{}}
                            //     wrapperClassName=""
                            //     visible={true}
                            // />
                        }
                    </div>

                    <div className='content d-flex justify-content-center'>
                        <ul className='list'>
                            {
                                tamplateList?.map((item, i) =>
                                    <li
                                        key={i}
                                        onClick={() => {
                                            if (item?.endPoint) {
                                                handlePdfDownload(item?.endPoint)
                                            }
                                        }}
                                    >
                                        {item?.name}
                                    </li>
                                )
                            }

                            {/* <li>13 Week Cash Flow Statement</li>
                            <li>Accounts Receivable Report</li>
                            <li>Accounts Payable Report</li>
                            <li onClick={() => handlePdfDownload()}>Balance Sheet</li>
                            <li>Budget Forecast (planned & actual)</li>
                            <li>Business Financial Statement</li>
                            <li>Customer Invoice</li>
                            <li>Income Statement</li>
                            <li>Personal Financial Statement</li>
                            <li>Profit & Loss Statement</li>
                            <li>Purchase Order</li> */}
                        </ul>
                    </div>

                </div>
            </Modal>
            {/* </section> */}
        </>
    )
}

export default DownloadTemplateModal