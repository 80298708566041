import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
// import BookCustDocMissingModal from '../../../Modal/BookCustDocMissingModal';
// import MissingDocModal from '../../../Modal/MissingDocModal';
// import ManageDocModal from '../../../Modal/ManageDocModal';
// import HttpClient from '../../../utils/HttpClient';
import toast from 'react-hot-toast';
// import { useUserData } from '../../../Providers/UserWrapper';
// import { useLanguage } from '../../../Providers/LanguageContext';
import HttpClient from '../../utils/HttpClient';
import { useLanguage } from '../../Providers/LanguageContext';
import { useUserData } from '../../Providers/UserWrapper';
import ManageInvDocModal from './modals/ManageInvDocModal';
import MissingInvDocModal from './modals/MissingInvDocModal';
import moment from 'moment';
import InvOptionProfileModal from './modals/InvOptionProfileModal';
import MakeAnOfferModal from './modals/MakeAnOfferModal';


const InvestmentOptions = ({ linkedAccData, setCallGetAcc }) => {
    const navigate = useNavigate()
    const { setIsLoading } = useUserData()
    const { language, currentLanguage } = useLanguage()
    const [modal, setModal] = useState(false);
    const [ismodal, setIsModal] = useState(false);
    const [singleUserData, setSingleUserData] = useState({});
    const [calCulatedData, setCalculatedData] = useState({ activeClient: "", sentOfferPrice: "", acceptOfferPrice: "" });

    const initFilter = {
        name: "",
        startDate: "",
        endDate: "",
        month: "",
        year: ""
    }
    const [filterData, setFilterData] = useState(initFilter);
    const [AccData, setAccData] = useState([]);
    const [years, setYears] = useState([]);
    const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
    ];
    const [singleCapitalData, setSingleCapitalData] = useState({});

    console.log("AccDatass", AccData)

    useEffect(() => {
        const startDate = new Date('2000-01-01'); // Change this to your desired starting date
        const currentDate = new Date();
        const startYear = startDate.getFullYear();
        const currentYear = currentDate.getFullYear();

        const yearRange = Array.from({ length: currentYear - startYear + 1 }, (_, index) => startYear + index);

        setYears(yearRange?.reverse());
    }, []);

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilterData(prev => ({ ...prev, [name]: value }))
    }

    const handleClick = (item) => {
        setModal(!modal)
        setSingleUserData(item)
    }

    const handClick = (item) => {
        setIsModal(!ismodal);
        setSingleUserData(item)
    }

    // send all reminder
    const handleSendAllReminder = async (item) => {
        setIsLoading(true);
        const data = {
            "language": currentLanguage === "eng" ? "EN" : "ES"
        }
        const res = await HttpClient.requestData("bookkeeper/send-missing-all-mail-user/" + item?.accountRegCode, "POST", data)
        // console.log("itemmvv", item)
        setIsLoading(false);
        if (res && res?.status) {
            toast.success("A Reminder Mail has been Sent Successfully")
        } else {
            toast.error(res?.message)
        }
    }

    // get all account
    const getLinkedAccData = async () => {
        const data = {
            "clientName": filterData.name ? filterData.name : null,
            "month": filterData.month ? filterData.month : null,
            "year": filterData.year ? filterData.year : null,
            "startDate": filterData?.startDate ? filterData?.startDate : null,
            "endDate": filterData.endDate ? filterData.endDate : null
        }
        setIsLoading(true)
        const res = await HttpClient.requestData("investor/filter-investor-raise-capital", "POST", data);
        // console.log("resssdd", res)
        setIsLoading(false)
        if (res && res?.status) {
            setAccData(res?.data);
            const newArr = res?.data?.filter(item => item?.offerStatus === "accepted")
            const amountAccept = newArr.reduce((acc, cur) => acc + Number(cur?.reqAndDebtAmmount), 0);

            const count = res?.data?.length;

            const uniqueRegCodeDAta = removeDuplicate(res?.data)
            const amount = uniqueRegCodeDAta?.reduce((acc, cur) => acc + Number(cur?.totalinvestorSendOfferDetails), 0);

            setCalculatedData({
                activeClient: count,
                sentOfferPrice: amount,
                acceptOfferPrice: amountAccept
            })
            // console.log("newArrdd", newArr)
        }
        else {

        }
    }

    useEffect(() => {
        if (
            (filterData.startDate !== "" && filterData.endDate !== "") ||
            (filterData.startDate === "" && filterData.endDate === "")
        ) {
            getLinkedAccData()
        } else if (
            (filterData.startDate !== "" && filterData.endDate !== "") ||
            (filterData.startDate === "" && filterData.endDate === "")
        ) {
            getLinkedAccData()
        }

    }, [filterData.startDate, filterData.endDate, filterData.month, filterData.year, currentLanguage])

    useEffect(() => {
        // if (filterData.vendorName) {
        const timer = setTimeout(() => {
            getLinkedAccData()
        }, 600);

        return () => clearTimeout(timer)
        // }
    }, [filterData.name])


    // remove duplicate
    const removeDuplicate = (arr) => {
        const unique = arr.filter((obj, index) => {
            return index === arr.findIndex(o => obj.userBusniessRegcode === o.userBusniessRegcode);
        });
        return unique;
    }

    return (
        <div className='container'>
            <section>
                <div className='d-flex gap-2'>
                    <div className=''>
                        <button className='w-100 btn btn-outline-success' style={{ fontWeight: "bold" }} onClick={() => navigate('/link-manage-investment')}>{language.link_investment}</button>
                    </div>

                    <div className=''>
                        <button className='w-100 btn btn-outline-success' style={{ fontWeight: "bold" }} onClick={() => navigate('/link-manage-investment', { state: { slideNo: 1 } })}>{language.view_manage_docs}</button>
                    </div>
                </div>
                <div>
                    <h3 className='BookLinkHading'>{language.Investment_Manager} - {language.investment_option}</h3>
                </div>

                {/* Headings */}
                <div>
                    {/* <h1 className='BookLinkHading'>Bookkeeper Manager - Link & Manage Accounts</h1> */}
                    <h6 className='BookLinkSubHeading'>{language.manage_inv_option}</h6>
                </div>

                {/* middle part */}
                <div>
                    <div className='row'>

                        {/* left part form */}
                        <div className='col-md-12 col-lg-6'>
                            <form className='bookManageAcctForm'>
                                <div className='bookLinkFormDiv form-group' >
                                    <label>{language.client_name}:</label>
                                    <div className='bookformInp'>
                                        <input
                                            type='text'
                                            placeholder={language.type_to_search}
                                            style={{ width: "100%" }}
                                            class="form-control"
                                            id="exampleFormControlInput1"
                                            name='name'
                                            value={filterData.name}
                                            onChange={(e) => handleFilterChange(e)}
                                        />
                                    </div>
                                </div>

                                <div className='bookLinkFormDiv form-group' >
                                    <label>{language.date_range}:</label>
                                    <div className='bookformInp'>
                                        <input
                                            type='date'
                                            class="form-control"
                                            id="exampleFormControlInput1"
                                            name='startDate'
                                            value={filterData.startDate}
                                            onChange={(e) => {
                                                setFilterData(prev => ({ ...prev, month: "", year: "" }))
                                                handleFilterChange(e)
                                            }
                                            }
                                        />
                                        <input
                                            type='date'
                                            class="form-control"
                                            id="exampleFormControlInput1"
                                            name='endDate'
                                            value={filterData.endDate}
                                            onChange={(e) => {
                                                setFilterData(prev => ({ ...prev, month: "", year: "" }))
                                                handleFilterChange(e)
                                            }
                                            }
                                        />
                                    </div>
                                </div>

                                <div className='bookLinkFormDiv form-group' >
                                    <label>{language.month_year}:</label>
                                    <div className='bookformInp'>
                                        {/* <input placeholder='Month' class="form-control" id="exampleFormControlInput1" />
                                        <input placeholder='Year' class="form-control" id="exampleFormControlInput1" /> */}
                                        <select
                                            name='month'
                                            value={filterData.month}
                                            onChange={(e) => {
                                                setFilterData(prev => ({ ...prev, startDate: "", endDate: "" }))
                                                handleFilterChange(e)
                                            }
                                            }
                                        >
                                            <option value="" disabled>{language.month}</option>
                                            {
                                                months.map((item, i) =>
                                                    <option key={i} value={i + 1}>{item}</option>
                                                )
                                            }
                                        </select>

                                        <select
                                            name='year'
                                            value={filterData.year}
                                            onChange={(e) => {
                                                setFilterData(prev => ({ ...prev, startDate: "", endDate: "" }))
                                                handleFilterChange(e)
                                            }
                                            }
                                        >
                                            <option value="" disabled>{language.year}</option>
                                            {
                                                years.map((item, i) =>
                                                    <option key={i} value={item}>{item}</option>
                                                )
                                            }
                                        </select>
                                    </div>
                                </div>

                                <div className='clearAll' onClick={() => setFilterData(initFilter)}>
                                    <Link className='clearAllLink'>{language.clear_all}</Link>
                                </div>

                            </form>
                        </div>

                        {/* right part */}
                        <div className='col-md-12 col-lg-6'>
                            <ul className='acctDashUl'>
                                <li>
                                    <span className='LinkBookHead'>{language.total_inv_opp}: </span>
                                    <span className='LinkBookNumber'>{AccData?.length}</span>
                                </li>

                                <li>
                                    <span className='LinkBookHead'>{language.total_offer_sent}: </span>
                                    <span className='LinkBookNumber'>
                                        $ {calCulatedData?.sentOfferPrice} USD
                                        {/* {currentLanguage === "eng" ? "USD" : "MXN"} */}
                                    </span>
                                </li>

                                <li>
                                    <span className='LinkBookHead'>{language.total_offer_accepted}: </span>
                                    <span className='LinkBookNumber'>
                                        $ {calCulatedData?.acceptOfferPrice} USD
                                        {/* {currentLanguage === "eng" ? "USD" : "MXN"} */}
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                {/* add more part */}
                <div className='table-responsive outerTblDiv'>
                    <table className="buildCapitalTable mt-3 mb-5">
                        <thead>
                            <tr>
                                <th className="buildCapMiddleTh">
                                    <span className="buildCapLeftThSpan">{language.make_an_offer}</span>
                                </th>
                                <th className="buildCapMiddleTh">
                                    <span className="buildCapLeftThSpan">{language.type}</span>
                                </th>
                                <th className="buildCapMiddleTh">
                                    <span className="buildCapLeftThSpan">{language.requested}</span>
                                </th>
                                <th className="buildCapMiddleTh">
                                    <span className="buildCapLeftThSpan">{language.offer_sent}</span>
                                </th>
                                <th className="buildCapMiddleTh">
                                    <span className="buildCapLeftThSpan">{language.offer_accepted}</span>
                                </th>
                                <th className="buildCapMiddleTh">
                                    <span className="buildCapLeftThSpan">{language.profile}</span>
                                </th>
                                <th className="buildCapMiddleTh">
                                    <span className="buildCapLeftThSpan">{language.lead_date}</span>
                                </th>
                                <th className="buildCapMiddleTh">
                                    <span className="buildCapLeftThSpan">{language.performance}</span>
                                </th>
                                {/* <th className="buildCapMiddleTh">
                                    <span className="buildCapLeftThSpan">Performance</span>
                                </th> */}
                            </tr>
                        </thead>


                        <tbody>
                            {AccData?.reverse()?.map((item, i) => {
                                return (
                                    <tr key={i}>
                                        <td
                                            className="buildCapMiddleTd"
                                            onClick={() => handClick(item)}
                                        >
                                            {/* {
                                                item?.acceptStatus === "accepted"
                                                    ? */}
                                            <Link to='' className='buildCapMidlink'>{item?.investorFullName}</Link>
                                            {/* :
                                                    "-"
                                            } */}
                                        </td>
                                        <td className="buildCapMiddleTd">
                                            {/* <Link to='' className='buildCapMidlink'>{item?.missingDocument}</Link> */}
                                            <input
                                                className='bookLinkTableInput'
                                                type="text"
                                                name="typeOfUnits"
                                                placeholder=''
                                                value={item.capitalType === "Debt Capital Raise" ? "Debt" : "Equity"}
                                                // onChange={(e) => handleChangeOwner(e, i)}
                                                readOnly
                                            />
                                        </td>
                                        <td className="buildCapMiddleTd" >
                                            {/* <button
                                                className='buildCapTblBtn'
                                                onClick={() => handleSendAllReminder(item)}
                                            >
                                                Send
                                            </button> */}
                                            <input
                                                className='bookLinkTableInput'
                                                type="text"
                                                name="typeOfUnits"
                                                placeholder=''
                                                value={"$" + item.reqAndDebtAmmount}
                                                // onChange={(e) => handleChangeOwner(e, i)}
                                                readOnly
                                            />
                                        </td>

                                        <td className="buildCapMiddleTd">
                                            {/* <input
                                                className='bookLinkTableInput'
                                                type="text"
                                                name="typeOfUnits"
                                                placeholder='710552470'
                                                value={item.accountRegCode}
                                                // onChange={(e) => handleChangeOwner(e, i)}
                                                readOnly
                                            /> */}
                                            <p className='buildCapMidlink'>
                                                {item?.investorOfferStatus === true ? "Yes" : "No"}
                                            </p>

                                        </td>

                                        <td className="buildCapMiddleTd">
                                            <p className='buildCapMidlink'>
                                                {item?.offerStatus === "accepted" ? "Yes" : "No"}
                                            </p>
                                        </td>

                                        <td
                                            className="buildCapMiddleTd"
                                            onClick={() => {
                                                setSingleCapitalData(item)
                                                setModal(true)
                                            }}
                                        >
                                            <Link to='' className='buildCapMidlink'>Profile</Link>
                                        </td>
                                        <td className="buildCapMiddleTd" style={{ width: "141px" }}>
                                            {/* <input
                                                className='bookLinkTableInput'
                                                type="date"
                                                name="percent"
                                                placeholder=''
                                                value={moment(item.dateOfIncorporation).format("YYYY-MM-DD")}
                                                // onChange={(e) => handleChangeOwner(e, i)}
                                                readOnly
                                            /> */}
                                            {/* <div className='custSelecInp border'> */}
                                            {moment(item.dateOfIncorporation).format("YYYY-MM-DD")}
                                            {/* </div> */}
                                        </td>
                                        <td className='d-flex justify-content-center '>
                                            <button
                                                className='btn btn-success'
                                                style={{ padding: "1px 10px" }}
                                                onClick={() => navigate("/dashboard-manager", {
                                                    state: {
                                                        slideNo: 0,
                                                        userRegCodr: item?.userBusniessRegcode
                                                    }
                                                })}
                                            >
                                                {language.view}
                                            </button>
                                        </td>


                                        {/* <td className="buildCapMiddleTd">
                                            {
                                                item?.acceptStatus === "accepted"
                                                    ?
                                                    currentLanguage === "eng" ? "$599.00 USD" : "$1999.00 MXN"
                                                    :
                                                    "-"
                                            }
                                        </td> */}
                                    </tr>
                                )
                            })}

                        </tbody>
                    </table>
                </div>

                {/* dd */}
            </section>

            {/* missing doc modal */}
            {/* {true && <MissingInvDocModal */}
            {modal &&
                <InvOptionProfileModal
                    closeModal={setModal}
                    singleCapitalData={singleCapitalData}
                    getLinkedAccData={getLinkedAccData}
                />}

            {/* {true && <ManageInvDocModal */}
            {ismodal && <MakeAnOfferModal
                closeModal={setIsModal}
                singleUserData={singleUserData}
                setCallGetAcc={setCallGetAcc}
                getLinkedAccData={getLinkedAccData}
            />}
        </div>
    )
}

export default InvestmentOptions
