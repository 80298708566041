import React from 'react'
import Bnnerbg from "../../images/_Trazado_.png";
import Bnnercnt from "../../images/Group 6.png";
import { useLanguage } from '../../Providers/LanguageContext';


function Blogbanner() {
    const {language}=useLanguage()
    return (

        <div style={{cursor:'default'}}>

            <div id='banner-heading'>
                <div className='container'>
                    <div className='heading-name'>
                        <h2>{language.blog_get_view}</h2>
                    </div>
                </div>
            </div>


            <div id='blog-banner' style={{ backgroundImage: `url('${Bnnerbg}')` }}>
                <div className='container'>
                    <div className='col-xl-12 col-lg-12 col-md-12 col-12'>
                        <div className='banner-cnt'>
                        <img className="img-fluid" src={Bnnercnt} />
                        </div>
                    </div>
                </div>
            </div>

        </div>


    )
}

export default Blogbanner