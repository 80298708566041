import React, { useContext, useEffect, useState } from 'react'
import Userlist from '../../Component/Userlist'
import { useLanguage } from '../../Providers/LanguageContext'
import HttpClient from '../../utils/HttpClient';
import user_home_en1 from "../../extras/x_manage_users_audio_a.mp3";
import user_home_es1 from "../../extras/x_manage_users_audio_a_es.mp3";
import { SpeechContext } from '../../Providers/SpeechWrapper';
import AddUserModal from './AddUserModal';
import { toast } from 'react-hot-toast';
import { useUserData } from '../../Providers/UserWrapper';
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai"
import { useNavigate } from 'react-router-dom';


function Index() {
    const navigate = useNavigate();
    const { language, currentLanguage } = useLanguage()
    const { profileData, isBusiness, setIsLoading, regCodeData } = useUserData()
    const { currAudio, setCurrAudio, playing } = useContext(SpeechContext);
    const [users, setUsers] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [loadingCheck, setLoadingCheck] = useState(false);
    const [checkTotalUser, setCheckTotalUser] = useState(false);
    const [userSize, setUserSize] = useState("");
    const [payUser, setPayUser] = useState({
        total: "",
        payType: ""
    });
    console.log("userSizeff", userSize);

    const initialValue = {
        email: '',
        pass: '',
        conPass: '',
        acType: 'Manager',
        // discCode: '',
        // accCode: '',
        // userType: "",
        // term: false
    }

    const [formValue, setFormValue] = useState(initialValue);
    const [formError, setFormError] = useState({});
    console.log("formValueAdd", formValue)


    const fetchUsers = async () => {
        const res = await HttpClient.requestData("user-management", "GET", {});
        console.log('usermanagement', res)
        if (res && res.status) {
            const arr1 = res.data.filter((item) => {
                if (item?.name) {
                    let d = item.name?.toLowerCase() + item.surName?.toLowerCase()
                    console.log('derd', d)
                    return d?.includes(searchValue?.toLowerCase())
                }
                else {
                    return "-"
                }
            })
            console.log('arr1User', arr1)
            setUsers(arr1)
            // setUsers(res.data)
        }
    }

    const handleUserSearch = async (e) => {
        const { value } = e.target;
        setSearchValue(value);
    }

    const getUserNumber = async () => {
        // console.log('formValue', formValue)
        if (profileData && profileData?.userType === "Business") {
            const sendData = {
                accountRegCode: Number(profileData?.accountRegCode)
            }
            console.log('sendData', sendData)
            setLoadingCheck(true);
            const res = await HttpClient.requestData("total-user", "POST", sendData);
            console.log('resPaytyyy', res)
            if (res && res.status) {
                // console.log('numuserTes', res?.data[0]?.SubscriptionData[0]?.priceData[0]?.payType);
                const total = res?.data[0]?.total;
                const payType = res?.data[0]?.SubscriptionData[0]?.priceData[0]?.payType;
                setPayUser({
                    total: total,
                    payType: payType
                })
                console.log('totalPaytype', total, payType)
                if (total && payType) {
                    if (payType === "starter" && total < 5) {
                        setCheckTotalUser(false);
                    } else if (payType === "small business plan" && total < 10) {
                        setCheckTotalUser(false);
                    } else if (payType === "mid size plan" && total < 20) {
                        setCheckTotalUser(false);
                    } else {
                        setCheckTotalUser(true);
                    }
                } else {
                    setCheckTotalUser(false)
                }

                if (total && payType) {
                    if (payType === "starter") {
                        setUserSize(5)
                    } else if (payType === "small business plan") {
                        setUserSize(10)
                    } else if (payType === "mid size plan") {
                        setUserSize(20)
                    } else {
                        setUserSize("");
                    }
                } else {
                    setUserSize("")
                }


                setLoadingCheck(false);
            }
        }
    }

    useEffect(() => {
        getUserNumber();
    }, [profileData, users]);


    const handleAddUser = async (e) => {
        e.preventDefault();
        if (!formValue.email) {
            toast.error(language.email_validate_blank);
            return;
        }
        if (!formValue.pass) {
            toast.error(language.password_validate_blank);
            return;
        } else if (formValue.pass.length < 3) {
            toast.error(language.password_validate_min);
            return;
        }

        if (!formValue.conPass) {
            toast.error(language.confirm_password_validate_blank);
            return;
        } else if (formValue.conPass.length < 3) {
            toast.error(language.password_validate_min);
            return;
        }

        if (formValue.pass != formValue.conPass) {
            toast.error(language.confirm_password_validate_match);
            return;
        }


        let registerData = {
            email: formValue.email,
            password: formValue.pass,
            accountType: formValue.acType,
            userType: profileData && profileData?.userType,
            accountRegCode: profileData && profileData?.accountRegCode,
            fromWhichReffCode: "",
            language: currentLanguage == "eng" ? "EN" : "ES",
            userFlag: 0
        }
        console.log('registerData', registerData);
        setIsLoading(true);
        setShowModal(false);
        const result = await HttpClient.requestData('register', 'POST', registerData);
        console.log("registered", result);
        if (result && result.status) {
            setIsLoading(false);
            setFormError(initialValue);
            toast.success("New User Created SuccessFully");
            fetchUsers();
        } else {
            setIsLoading(false);
            if (result.message === "Email already exsists") {
                toast.error(language.email_exist)
            } else if (result.message === "This code does not exsists") {
                toast.error(language.discount_code_not_exist)
            }
            else {
                toast.error(result?.message)
            }
        }
        console.log("addd")
    }

    const handleModal = () => {
        if (!loadingCheck) {
            if (checkTotalUser) {
                toast.error(language.no_more_user);
            } else {
                setShowModal(true);
            }
        }
    }

    useEffect(() => {
        fetchUsers()
    }, [searchValue])

    useEffect(() => {
        if (playing) {
            if (currAudio) {
                if (!currAudio.paused) {
                    currAudio.pause()
                }
            }
            if (currentLanguage === "eng") {
                setCurrAudio(new Audio(user_home_en1))
            } else {
                setCurrAudio(new Audio(user_home_es1))
            }
        } else {
            if (currAudio) {
                if (!currAudio.paused) {
                    currAudio.pause()
                    setCurrAudio(null)
                }
            }
        }

        return () => {
            if (currAudio && !currAudio.paused) {
                currAudio.pause()
            }
        }
    }, [playing, currentLanguage])


    // useEffect(() => {
    //     console.log("currAudio", currAudio)
    //     if (currAudio) {
    //         if (!currAudio.paused) {
    //             currAudio.pause()
    //         }
    //         currAudio.play()
    //     }
    //     return () => {
    //         if (currAudio && !currAudio.paused) {
    //             currAudio.pause()
    //         }
    //     }
    // }, [currAudio])
    return (

        <div className='container'>
            <div className='' style={{marginLeft:"-7.5rem"}}>
                <button className='w-25 btn btn-outline-success mb-2' style={{ fontWeight: "bold", margin: "0 0 0 120px" }} onClick={() => navigate('/configuration')}>
                    {language.share_account_code}
                </button>
            </div>
            <div id="user-header">
                <div>
                    <div class="row" style={{ padding: "0 10px" }}>
                        <div class="col-xl-6 col-lg-6 col-md-12 col-12" style={{ display: "flex", alignItems: "center" }}>
                            <div class="left-heading">
                                <h2>{language.users}</h2>
                            </div>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-12 col-12" style={{ display: "flex", alignItems: "center" }}>
                            <div class="right-box">
                                <form>
                                    <div class="top-search">
                                        <div class="form-group">
                                            <input type="text" class="form-control" id="" aria-describedby=""
                                                placeholder=""
                                                onChange={handleUserSearch}
                                            />
                                            <i class="fa-solid fa-magnifying-glass"></i>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isBusiness &&
                <div className='d-flex justify-content-between mb-2'>
                    <div className='add-user-btn'>
                        <button className='btn' onClick={handleModal}>{language.add_user}</button>
                    </div>

                    <div style={{ backgroundColor: "#d8e4ec", width: "78px", padding: "6px", height: "31px" }}>
                        <span className='font-weight-bold'>{users.length}</span>
                        {" of "}
                        <span className='font-weight-bold'>{userSize}</span>
                    </div>
                </div>
            }

            <Userlist users={users} />

            {
                users.length === 0 &&
                <div className='d-flex justify-content-center m-3'>
                    {language.no_data}
                </div>
            }

            {showModal &&
                <AddUserModal
                    setShowModal={setShowModal}
                    setFormValue={setFormValue}
                    formValue={formValue}
                    handleAddUser={handleAddUser}
                />
            }
        </div>
    )
}

export default Index