import React, { useEffect, useState } from 'react'
import { useLanguage } from '../Providers/LanguageContext'

function Managebtn({ getTime, value }) {


  const { language } = useLanguage()
  const [activeTime, setActiveTime] = useState("")

  useEffect(() => {
    if (activeTime) {
      getTime(activeTime)
    }
  }, [activeTime])

  return (

    <>

      <div id='managebtn'>
        <div className='container'>
          <div className='btn-wrap'>
            <button
              onClick={() => setActiveTime("day")}
              className={`bill-btn ${value === "day" ? "timeframe-btn-active" : ""}`}
            >{language.day}
            </button>
            <button
              onClick={() => setActiveTime("week")}
              className={`bill-btn ${value === "week" ? "timeframe-btn-active" : ""}`}
            >
              {language.week}
            </button>
            <button
              onClick={() => setActiveTime("month")}
              className={`bill-btn ${value === "month" ? "timeframe-btn-active" : ""}`}
            >{language.month}</button>
            <button className={`bill-btn ${value === "year" ? "timeframe-btn-active" : ""}`}
              onClick={() => setActiveTime("year")}
            >
              {language.year}
            </button>
          </div>
        </div>
      </div>

    </>
  )
}

export default Managebtn