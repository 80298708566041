import React from 'react'
import { useNavigate } from 'react-router-dom';
import { useLanguage } from '../Providers/LanguageContext';
import term_es from "../images/blog_pdfs/term_condition_Es.pdf"
import term_en from "../images/blog_pdfs/Fisibility - Terms of Service_En.pdf"


const TermCondition = () => {
  const { language, currentLanguage } = useLanguage();
  const navigate = useNavigate();
  return (
    <div>
      <iframe
        style={{ border: "1px solid #666CCC", width: "100%", height: "100vh" }}
        id="fred"
        title="PDF in an i-Frame"
        src={currentLanguage === "eng" ? term_en : term_es}
      ></iframe>

    </div>
  )
}

export default TermCondition