import React, { useContext, useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import Logo from "../../src/images/fisibility-logo (1).png";
import "../Layout/Responsive.css";
import Ftrlogo from "../../src/images/footer-logo 11.png";
import Cipbitheader from "./Cipbitheader";
import $ from "jquery";
import { useLanguage } from "../Providers/LanguageContext";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../Providers/UserWrapper";
import OffcanvasComp from "../Component/OffcanvasComp";
import { reactLocalStorage } from "reactjs-localstorage";
import CustomModal from "../Component/CustomModal";
import { AiOutlineClose } from "react-icons/ai";
import RegisterInvestment from "../View/InvestmentRegistry/RegisterInvestment";
import SearchInvestment from "../View/InvestmentRegistry/SearchInvestment";
import InvestmentRegistry from "../Modal/InvestmentRegistry";
import SearchResultModal from "../View/InvestmentRegistry/SearchResultModal";
import CustomCssModal from "../Modal/CustomCssModal";

function Header() {
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { profileData, logout, show, setShow, pricePathMemo, isBookKeeper, isLogin } = useContext(UserContext)
  const userData = reactLocalStorage.getObject('userData')
  // console.log('userDatapp', profileData)
  const [isInvestment, setIsInvestment] = useState(false);
  const [isRegisterInv, setIsRegisterInv] = useState(false);
  const [isSearchInv, setIsSearchInv] = useState(false);
  const [isSearchResult, setIsSearchResult] = useState(false);

  const [showIndustry, setShowIndustry] = useState(false);
  // console.log('isSearchResult', isSearchResult)

  const [searchResultData, setSearchResultData] = useState({});
  const [allSearchData, setAllSearchData] = useState([]);
  const [searchCount, setSearchCount] = useState(1);
  // console.log('allSearchData', allSearchData)


  const { language, currentLanguage, setCurrentLanguage } = useLanguage();
  const navigate = useNavigate()

  const mobileNav = () => {
    let display = document.querySelector("#home-sidebar").style.display;
    // console.log(display)
    document.getElementById("home-sidebar").style.display =
      display == "block" ? "none" : "block";
  };

  const handleRegultNext = () => {
    if (searchCount < allSearchData.length) {
      setSearchCount(searchCount + 1);
    }
  }

  const handleRegultPrev = () => {
    if (searchCount > 1) {
      setSearchCount(searchCount - 1);
    }
  }

  useEffect(() => {
    // console.log('searchCount',searchCount)
    if (allSearchData.length) {
      setSearchResultData(allSearchData[searchCount - 1]);
      // console.log('allSearchData',allSearchData[searchCount-1])
    }
  }, [searchCount, allSearchData])

  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  }, []);

  const isSticky = (e) => {
    const header = document.querySelector(".mainheader");
    const scrollTop = window.scrollY;
    scrollTop >= 10
      ? header?.classList.add("is-sticky")
      : header?.classList.remove("is-sticky");
  };

  useEffect(() => {
    // $('.menu-link').on('click', function(){
    //   $(".mobile-menu").toggleClass('removemenu');

    //   });

    $(".home-side_list ul li a").on("click", function () {
      $("#home-sidebar").addClass("removemenu");
    });
    $(".tglebtn").on("click", function () {
      $("#home-sidebar").removeClass("removemenu");
    });
  }, []);

  const handleBtn = () => {
    if (!profileData) {
      mobileNav()
      navigate("/login");
    } else {
      mobileNav()
      logout()
    }
  }

  return (
    <>

      <header id="header-section" className="mainheader">
        <div className="container-fluid">
          <nav className="navbar navbar-expand-lg">
            {/* <NavLink to={userData.token ? isBookKeeper ? "/bookkeeper-manager" : '/manager' : '/'} */}
            <NavLink to={isLogin ? '/manager' : '/'}
              className="navbar-brand"
            >
              <img className="img-fluid" src={Logo} />
            </NavLink>

            <div className="tglebtn" onClick={mobileNav}>
              <span></span>
              <span></span>
              <span className="lastspan"></span>
            </div>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul
                className="navbar-nav ml-auto"
                style={{ alignItems: "center" }}
              >
                <li className="nav-item">
                  <NavLink to="/"
                    className={({ isActive, isPending }) =>
                      isActive ? "nav-link hover-effect" : "nav-link"
                    }
                    href="#">
                    {language.headerMenu1}
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/product-tour"
                    className={({ isActive, isPending }) =>
                      isActive ? "nav-link hover-effect" : "nav-link"
                    }
                    href="#">
                    {language.headerMenu4}
                  </NavLink>
                </li>
                {/* <li className="nav-item">
                  <NavLink to="/contact"
                    className={({ isActive, isPending }) =>
                      isActive ? "nav-link hover-effect" : "nav-link"
                    }
                    href="#">
                    {language.contact}
                  </NavLink>
                </li> */}

                <li className="nav-item dropdown" style={{ position: "relative" }}>
                  <Link className="nav-link dropdown-toggle" id="SolucionesDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    {language.industrial_solutions}
                  </Link>
                  <span className="arrow_down"><i className="fa-solid fa-angle-down"></i></span>
                  <div className="dropdown-menu" aria-labelledby="SolucionesDropdown" style={{ top: "148%" }}>
                    <Link className="dropdown-item" to="/generalUse">{language.general_use}</Link>
                    <Link className="dropdown-item" to="/personalUse">{language.personal_and_domestic_use}</Link>
                    <Link className="dropdown-item" to="/capitalProviders">{language.capital_providers_and_investors}</Link>
                    <Link className="dropdown-item" to="/banckIndustry">{language.banks_and_banking_industry}</Link>
                    <div className="dropdown-divider"></div>
                    <Link className="dropdown-item" to="/agriculture">{language.agriculture}</Link>
                    <Link className="dropdown-item" to="/construction">{language.construction}</Link>
                    <Link className="dropdown-item" to="/electronicsCommerce">{language.electronic_commerce}</Link>
                    <Link className="dropdown-item" to="/healthCare">{language.health_care}</Link>
                    <Link className="dropdown-item" to="/education">{language.education}</Link>
                    <Link className="dropdown-item" to="/accountComp">{language.accounting_companies}</Link>
                    <Link className="dropdown-item" to="/energy">{language.energy}</Link>
                    <Link className="dropdown-item" to="/manufacturing">{language.manufacturing}</Link>
                    <Link className="dropdown-item" to="/government">{language.government}</Link>
                    <Link className="dropdown-item" to="/hospitality">{language.hospitality}</Link>
                    <Link className="dropdown-item" to="/realState">{language.real_estate}</Link>
                    <Link className="dropdown-item" to="/legal">{language.legal}</Link>
                    <Link className="dropdown-item" to="/retailer">{language.retailers}</Link>
                    <Link className="dropdown-item" to="/noProfitOrg">{language.non_profit_organization}</Link>
                    <Link className="dropdown-item" to="/oilAndGas">{language.oil_and_gas}</Link>
                    <Link className="dropdown-item" to="/technology">{language.technology}</Link>
                    <Link className="dropdown-item" to="/transportation">{language.transportation}</Link>
                  </div>
                </li>


                <li className="nav-item">
                  <NavLink to="/Blog"
                    className={({ isActive, isPending }) =>
                      isActive ? "nav-link hover-effect" : "nav-link"
                    }
                    href="#">
                    {language.headerMenu2}
                  </NavLink>
                </li>

                

                <li className="nav-item">
                  <NavLink to="/Nosotros"
                    className={({ isActive, isPending }) =>
                      isActive ? "nav-link hover-effect" : "nav-link"
                    }
                    href="#">
                    {language.headerMenu3}
                  </NavLink>
                </li>
                <li className="nav-item">
                  {/* <NavLink to={`/${pricePathMemo}`} */}
                  <NavLink to={`/price`}
                    className={({ isActive, isPending }) =>
                      isActive ? "nav-link hover-effect" : "nav-link"
                    }
                  >
                    {language.prices}
                  </NavLink>
                </li>
                <li className="nav-item mr-3" style={{ position: "relative" }}>
                  <select
                    className="translate"
                    value={currentLanguage}
                    onChange={(val) => {
                      localStorage.setItem("language", val.target.value)
                      setCurrentLanguage(val.target.value)
                    }}
                  >
                    <option value="spanish">ES</option>
                    <option value="eng">EN</option>
                  </select>
                  <span className="borderright"></span>
                </li>
              </ul>
              <ul
                className="navbar-nav mr-auto ml-1"
                style={{ alignItems: "center" }}
              >
                {userData.token && profileData && profileData.name &&

                  <li className="nav-item" style={{ fontSize: "22px", cursor: 'pointer' }}>
                    <Link className="nav-link">
                      <i className="fa-solid fa-gear" onClick={handleShow}></i>
                    </Link>
                  </li>

                }


                {
                  profileData
                    ?
                    profileData.name
                      ?
                      <li className="nav-item dropdown" style={{ position: "relative" }}>
                        <Link style={{ paddingLeft: '0' }} className="nav-link dropdown-toggle" id="SolucionesDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          {language.my_accoutn}
                        </Link>
                        <span className="arrow_down"><i className="fa-solid fa-angle-down"></i></span>
                        <div
                          className="dropdown-menu mtAccountPop"
                          aria-labelledby="SolucionesDropdown"
                          style={{ width: '300px', height: "210px", padding: "15px", top: "148%" }}
                        >

                          <div className="">
                            {profileData.name.toUpperCase() + ' ' + profileData?.surName.toUpperCase()}<br />
                            {language.email}: {profileData.email}<br />
                            {language.city}: {profileData.city}<br />
                            {language.country}: {profileData.country}<br />
                            {language.user_type}: {profileData.userType}
                            <hr />
                            <div
                              className="login-btn pt-0"
                              style={{ height: "20px", textAlign: "right", maxWidth: "100%", color: "#212529", justifyContent: "center" }}
                              onClick={() => {
                                logout()
                                navigate("/")
                              }}
                            >
                              {/* {language.my_accoutn} */}
                              {language.logoutButton}
                            </div>
                          </div>
                        </div>
                      </li>
                      :
                      (
                        <button
                          className="login-btn"
                          onClick={() => {
                            logout()
                            navigate("/")
                          }}
                        >
                          {language.logoutButton}
                        </button>
                      )
                    : (
                      <button
                        style={{ marginLeft: '10px' }}
                        onClick={() => navigate("/login")}
                        className="login-btn"
                      >
                        {language.loginButton}
                      </button>
                    )
                }
              </ul>
              <div
                style={{ cursor: "pointer", marginLeft: "10px", border: "1px solid", padding: "5px", borderRadius: "3px" }}
                onClick={() => setIsInvestment(true)}
              >
                {language.investment_registry}
              </div>
              {/* <form className="form-inline my-2 my-lg-0">
              <input
                className="form-control mr-sm-2"
                type="search"
                placeholder="Search"
                aria-label="Search"
              />
              <button className="btn btn-outline-success my-2 my-sm-0" type="submit">
                Search
              </button>
            </form> */}
            </div>
          </nav>
        </div>
      </header >

      {/* 
      <CustomModal
        modalIsOpen={isInvestment}
        setIsOpen={setIsInvestment}
        openModal={() => setIsInvestment(true)}
        closeModal={() => setIsInvestment(false)}
      >
        <div>
          <div className='d-flex justify-content-between text-secondary'>
            <span>
            </span>
            <span>
              <AiOutlineClose className='icon' onClick={() => setIsInvestment(false)} size={20} color="gray" />
            </span>
          </div>
        </div>
        <div className="input-group mb-3">
          <div style={{ width: "80%" }}>
            <div className="row">
              <div className="col-sm-6">
                <button
                  className="btn btn-secondary"
                  onClick={() => setIsRegisterInv(true)}
                >
                  Register Investment
                </button>
              </div>
              <div className="col-sm-6">
                <button
                  className="btn btn-secondary"
                  onClick={() => setIsSearchInv(true)}
                >
                  Search Investment
                </button>
              </div>
            </div>
          </div>
        </div>
      </CustomModal> */}



      {/*   Responsive Menu      */}

      <div div id="home-sidebar" >
        <button className="cancel" onClick={mobileNav}>
          <i className="fa-solid fa-xmark"></i>
        </button>

        <div className="home-side_list">
          <ul>
            {/* <li>
              <Link to="/" href="#">
                Inicio
              </Link>
            </li> */}
            <li>
              <NavLink to="/"
                className={({ isActive, isPending }) =>
                  isActive ? "nav-link " : "nav-link"
                }
              >
                {language.headerMenu1}
              </NavLink>
            </li>

            {/* Industry Solution */}
            <li style={{ position: "relative" }}>
              <a onClick={(e) => {
                e.preventDefault();
                setShowIndustry(!showIndustry)
              }}
              >
                {language.industrial_solutions}
              </a>
              {/* <span className="arrow_down"><i className="fa-solid fa-angle-down"></i></span> */}
            </li>

            <li>
              <NavLink to="/Blog"
                className={({ isActive, isPending }) =>
                  isActive ? "nav-link " : "nav-link"
                }
              >
                {language.headerMenu2}
              </NavLink>
            </li>
            <li>
              <NavLink to="/Nosotros"
                className={({ isActive, isPending }) =>
                  isActive ? "nav-link" : "nav-link"
                }
              >
                {language.headerMenu3}
              </NavLink>
            </li>

            <li>
              {/* <NavLink to={`/${pricePathMemo}`} */}
              <NavLink to="/price"
                className={({ isActive, isPending }) =>
                  isActive ? "nav-link " : "nav-link"
                }
                href="#">
                {language.prices}
              </NavLink>
            </li>
            <li
              style={{ cursor: "pointer", marginLeft: "10px", border: 'none', padding: "5px", borderRadius: "3px" }}
              onClick={() => setIsInvestment(true)}
            >
              <NavLink>
                {language.investment_registry}
              </NavLink>
            </li>
            <li>
              <select
                className="translate"
                value={currentLanguage}
                onChange={(val) => {
                  localStorage.setItem("language", val.target.value)
                  setCurrentLanguage(val.target.value)
                }}
              >
                <option value="spanish">ES</option>
                <option value="eng">EN</option>
              </select>
              <span className="borderright"></span>
            </li>
            {/* <li>
              <NavLink to="/Nosotros"
                className={({ isActive, isPending }) =>
                  isActive ? "nav-link hover-effect" : "nav-link"
                }
              >
                {language.contact}
              </NavLink>
            </li> */}

            {/* <li>
              <NavLink to="/manager"
                className={({ isActive, isPending }) =>
                  isActive ? "nav-link " : "nav-link"
                }
                href="#">
                {language.manager}
              </NavLink>
            </li>
            <li>
              <NavLink to="/Nosotros"
                className={({ isActive, isPending }) =>
                  isActive ? "nav-link " : "nav-link"
                }
              >
                {language.white_papers}
              </NavLink>
            </li> */}
            <li>
              {" "}
              <button
                className="side-list_btn"
                onClick={handleBtn}
              >
                {profileData ? language.logoutButton : language.loginButton}
              </button>
              {" "}
            </li>
          </ul>

          <div className="sidebar-logo">
            <img src={Ftrlogo} alt="logo" />
          </div>
        </div>
      </div >
      <OffcanvasComp show={show} handleClose={handleClose} />

      {
        isRegisterInv &&
        <RegisterInvestment
          setIsRegisterInv={setIsRegisterInv}
        />
      }

      {
        isSearchInv &&
        <SearchInvestment
          setIsSearchInv={setIsSearchInv}
          setIsSearchResult={setIsSearchResult}
          setSearchResultData={setSearchResultData}
          setAllSearchData={setAllSearchData}
        />
      }

      {
        isInvestment &&
        <InvestmentRegistry
          closeModal={() => setIsInvestment(false)}
          registerClick={() => setIsRegisterInv(true)}
          searchClick={() => setIsSearchInv(true)}
        />
      }

      {
        isSearchResult &&
        <SearchResultModal
          setIsSearchResult={setIsSearchResult}
          // setIsSearchInv={setIsSearchInv}
          searchResultData={searchResultData}
          handleRegultNext={handleRegultNext}
          handleRegultPrev={handleRegultPrev}
          allSearchData={allSearchData}
          searchCount={searchCount}
        />
      }

      {
        showIndustry &&
        <CustomCssModal
          setShowIndustry={setShowIndustry}
        />
      }
    </>
  );
}

export default Header;
