import { lang } from 'moment';
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react'
import { toast } from 'react-hot-toast';
import HttpClient from '../utils/HttpClient';
import { useLanguage } from './LanguageContext';
import { useLocation } from 'react-router-dom';
import { useUserData } from './UserWrapper';

export const SpeechContext = createContext();

export const useSpeach = () => useContext(SpeechContext);

function SpeechWrapper({ children }) {

    const [currAudio, setCurrAudio] = useState("");
    // console.log('currAudioSpeach', currAudio)
    const [playing, setIsPlaying] = useState(true);
    const [showGif, setShowGif] = useState(false);
    const [gifToShow, setGifToShow] = useState("");
    const [gifPoints, setGifPoints] = useState()
    const [rewardsOriginal, setRewardsOriginal] = useState("")
    const [isProject, setIsproject] = useState(false);
    const { profileData } = useUserData();
    // console.log("profileDataPro", profileData && profileData.audio)


    useEffect(() => {
        fetchRewards()
    }, [])

    useEffect(() => {
        if (profileData) {
            setIsPlaying(profileData.audio);
        } else {
            setIsPlaying(false);
        }
    }, [profileData])

    useEffect(() => {
        console.log("currAudioWelcome", currAudio)
        if (playing) {
            if (currAudio) {
                if (!currAudio.paused) {
                    currAudio.pause()
                }
                currAudio.play().catch((err) => {
                    console.log('errAudio', err)
                })
            }
            return () => {
                if (currAudio && !currAudio.paused) {
                    currAudio.pause()
                }
            }
        }
    }, [currAudio])


    const fetchRewards = async () => {
        const res = await HttpClient.requestData("reward-manager", "GET");
        if (res && res.status) {
            setRewardsOriginal(res.data)
        } else {
            setRewardsOriginal(0)
        }
    }

    const values = {
        currAudio, setCurrAudio, playing, setIsPlaying, showGif, setShowGif, setGifToShow, gifToShow,
        gifPoints, setGifPoints, rewardsOriginal, isProject, setIsproject
    }

    return (
        <SpeechContext.Provider value={values}>
            {children}
        </SpeechContext.Provider>
    )
}

export default SpeechWrapper