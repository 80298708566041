import React, { useEffect, useState } from 'react'
import { useLanguage } from '../../Providers/LanguageContext';
import HttpClient from '../../utils/HttpClient';

const ViewAgingReport = ({ setBuildRepData }) => {
    const { language, currentLanguage } = useLanguage();

    const initData = {
        title: "",
        totalPastDue: "",
        current: "",
        days1to15: "",
        days16to30: "",
        days31to45: "",
        days46to60: "",
        days61to90: "",
        over90: ""
    }

    const [ownerData, setOwnerData] = useState([initData]);
    const [totals, setTotals] = useState({
        totalPastDue: 0,
        current: 0,
        days1to15: 0,
        days16to30: 0,
        days31to45: 0,
        days46to60: 0,
        days61to90: 0,
        over90: 0
    });
    // >>>>GET Aging Report data<<<<
    const getAgingReportData = async () => {
        const res = await HttpClient.requestData("reportbuilder-view-agingreport", "GET");
        if (res && res?.status) {
            console.log("s65r4tg65s", res, res?.data?.[0])
            if (res.data && res.data.length > 0 && res.data[0].vendorName && res.data[0].vendorName.length > 0) {
                setOwnerData(res.data[0].vendorName.map(item => ({
                    title: item.title,
                    totalPastDue: item.totalPastDue,
                    current: item.current,
                    days1to15: item.days1to15,
                    days16to30: item.days16to30,
                    days31to45: item.days31to45,
                    days46to60: item.days46to60,
                    days61to90: item.days61to90,
                    over90: item.over90
                })));
            } else {
                setOwnerData([initData]);
            }
        }

    }

    // >>>>Calculate totals<<<<
    useEffect(() => {
        const calculateTotals = () => {
            const newTotals = ownerData.reduce((acc, item) => {
                acc.totalPastDue += parseFloat(item.totalPastDue) || 0;
                acc.current += parseFloat(item.current) || 0;
                acc.days1to15 += parseFloat(item.days1to15) || 0;
                acc.days16to30 += parseFloat(item.days16to30) || 0;
                acc.days31to45 += parseFloat(item.days31to45) || 0;
                acc.days46to60 += parseFloat(item.days46to60) || 0;
                acc.days61to90 += parseFloat(item.days61to90) || 0;
                acc.over90 += parseFloat(item.over90) || 0;
                return acc;
            }, {
                totalPastDue: 0,
                current: 0,
                days1to15: 0,
                days16to30: 0,
                days31to45: 0,
                days46to60: 0,
                days61to90: 0,
                over90: 0
            });

            setTotals(newTotals);
        };

        calculateTotals();
    }, [ownerData]);

    useEffect(() => {
        getAgingReportData()
    }, [])

    return (
        <div className='container'>
            <div className='buildIncmStTableDiv'>

                <table className="buildIncmStTable">
                    <tbody>

                        {/****table Head***/}
                        <tr>
                            <th className="buildIncmStTh12 buildIncmStTh12First">
                                <span>{language.vendor_name}</span>
                            </th>
                            <th className="buildIncmStTh12 pr-2">
                                <span>{language.total_past_due}</span>
                            </th>
                            <th className="buildIncmStTh12 pr-2">
                                <span>{language.current}</span>
                            </th>
                            <th className="buildIncmStTh12 pr-2">
                                <span>1-15</span>
                            </th>
                            <th className="buildIncmStTh12 pr-2">
                                <span>16-30</span>
                            </th>
                            <th className="buildIncmStTh12 pr-2">
                                <span>31-45</span>
                            </th>
                            <th className="buildIncmStTh12 pr-2">
                                <span>46-60</span>
                            </th>
                            <th className="buildIncmStTh12 pr-2">
                                <span>61-90</span>
                            </th>
                            <th className="buildIncmStTh12">
                                <span>{language.over} 90</span>
                            </th>
                        </tr>

                        {/****Data**/}
                        {ownerData?.map((item, i) => {
                            return (
                                <tr>
                                    <td className="buildIncmStTd">
                                        <input type="text" className="userBuildInp"
                                            name="title"
                                            value={item?.title}
                                        />
                                    </td>
                                    <td className="buildIncmStTd pr-2">
                                        <input type="number"
                                            name="totalPastDue"
                                            value={item?.totalPastDue}
                                            placeholder='$00' />
                                    </td>
                                    <td className="buildIncmStTd pr-2">
                                        <input type="number"
                                            name="current"
                                            value={item?.current}
                                            placeholder='$00'
                                        />
                                    </td>
                                    <td className="buildIncmStTd pr-2">
                                        <input type="number"
                                            name="days1to15"
                                            value={item?.days1to15}
                                            placeholder='$00'
                                        />
                                    </td>
                                    <td className="buildIncmStTd pr-2">
                                        <input type="number"
                                            name="days16to30"
                                            value={item?.days16to30}
                                            placeholder='$00'
                                        />
                                    </td>
                                    <td className="buildIncmStTd pr-2">
                                        <input type="number"
                                            name="days31to45"
                                            value={item?.days31to45}
                                            placeholder='$00'
                                        />
                                    </td>
                                    <td className="buildIncmStTd pr-2">
                                        <input type="number"
                                            name="days46to60"
                                            value={item?.days46to60}
                                            placeholder='$00'
                                        />
                                    </td>
                                    <td className="buildIncmStTd pr-2">
                                        <input type="number"
                                            name="days61to90"
                                            value={item?.days61to90}
                                            placeholder='$00'
                                        />
                                    </td>
                                    <td className="buildIncmStTd">
                                        <input type="number"
                                            name="over90"
                                            value={item?.over90}
                                            placeholder='$00'
                                        />
                                    </td>
                                </tr>
                            )
                        })
                        }

                        {/*****border**/}
                        <tr>
                            <td />
                            <td colSpan={8}>
                                <div className="buildTotDivider" />
                            </td>
                        </tr>

                        {/***Sub Total**/}
                        <tr>
                            <td className="buildIncmStTd">
                                <span className="buildTotSpan">{language.total}</span>
                            </td>

                            {/* Total past Due */}
                            <td className="buildIncmStTd pr-2">
                                <input type="number" readOnly value={totals?.totalPastDue} placeholder='$0.00' />
                            </td>

                            {/* Current */}
                            <td className="buildIncmStTd pr-2">
                                <input type="number" readOnly value={totals?.current} placeholder='$0.00' />
                            </td>

                            {/* 1-15 */}
                            <td className="buildIncmStTd pr-2">
                                <input type="number" readOnly value={totals?.days1to15} placeholder='$0.00' />
                            </td>

                            {/* 16-30 */}
                            <td className="buildIncmStTd pr-2">
                                <input type="number" readOnly value={totals?.days16to30} placeholder='$0.00' />
                            </td>

                            {/* 31-45 */}
                            <td className="buildIncmStTd pr-2">
                                <input type="number" readOnly value={totals?.days31to45} placeholder='$0.00' />
                            </td>

                            {/* 46-60 */}
                            <td className="buildIncmStTd pr-2">
                                <input type="number" readOnly value={totals?.days46to60} placeholder='$0.00' />
                            </td>

                            {/* 61-90 */}
                            <td className="buildIncmStTd pr-2">
                                <input type="number" readOnly value={totals?.days61to90} placeholder='$0.00' />
                            </td>

                            {/* Over-90 */}
                            <td className="buildIncmStTd">
                                <input type="number" readOnly value={totals?.over90} placeholder='$0.00' />
                            </td>

                        </tr>

                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default ViewAgingReport