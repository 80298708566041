import React from 'react'
import { Outlet } from "react-router";
import Header from './Header';
import Footer from './Footer'
import { Sidebar } from './SideBar/Sidebar.jsx';
function Mainlayout() {
  return (
    <>
      <Header />
      <Sidebar name={'button'} placement='end'/>
      <Outlet />
      <Footer />
    </>
  )
}

export default Mainlayout