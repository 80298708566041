import React from 'react'
import Aboutbg from "../../images/about-bg.png";
import { useLanguage } from '../../Providers/LanguageContext';

export default function Nosotros3() {
  const {language}=useLanguage();
  return (

    <>

      <section id='conversation'>
        <div className='container'>
          <div className='mission'>
            <div>
              <div className='convo-left'>
                <div className='left-heading'>
                  {/* <h2>	</h2> */}
                  <h2>{language.mission}</h2>
                </div>
                <div className='left-content'>
                  <p>{language.assist_people}</p>
                </div>
              </div>
            </div>
          </div>
          <div className='vission'>
            <div className='convo-right'>
              <div className='right-heading'>
                {/* <h2>	</h2> */}
                <h2>{language.vision}</h2>
              </div>
              <div className='right-content'>
                <p>{language.become_world}</p>
              </div>
            </div>
          </div>
        </div>

        <div className='convo-bg' style={{ backgroundImage: `url('${Aboutbg}')` }}></div>
      </section>

    </>

  )
}
