import React from 'react'

const ViewCapitalOptComp = ({ arrData, handleAccept, handleReject }) => {
    console.log("d6fhg3210", arrData)
    return (
        <div>
            <div className="bookkTableDiv">
                <table className="bookkDocsTable">
                    <thead>
                        <tr>
                            <th className="bookTabData-cap">
                                <span className="bookHeadSpan">Investor Name</span>
                            </th>
                            <th className="bookTabData-cap">
                                <span className="bookHeadSpan">Investment Type</span>
                            </th>
                            <th className="bookTabData-cap">
                                <span className="bookHeadSpan">Investment Amount</span>
                            </th>
                            <th className="bookTabData-cap">
                                <span className="bookHeadSpan">Interest %</span>
                            </th>
                            <th className="bookTabData-cap">
                                <span className="bookHeadSpan">Requested Amount</span>
                            </th>
                            <th className="bookTabData-cap">
                                <span className="bookHeadSpan">Status</span>
                            </th>
                            <th className="bookTabData-cap">
                                <span className="bookHeadSpan">Action</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>

                        {
                            arrData?.map((item, i) => {
                                // const checkDate = moment(item?.dueDate).isAfter(moment().format())
                                // console.log("dattte", checkDate)
                                return (
                                    <tr
                                        key={i}
                                    >
                                        <td className="bookTabData-cap" >
                                            <span className="bokkAcc">{item?.userName}</span>
                                        </td>

                                        <td className="bookTabData-cap" style={{ position: "relative" }}>
                                            <span className="bokkAcc">{item?.investmentType}</span>
                                        </td>

                                        <td className="bookTabData-cap">
                                            <span className="bokkAcc">{item?.investmentAmmount}</span>
                                        </td>

                                        <td className="bookTabData-cap">
                                            <span className="bokkAcc">{item?.interestPercentage}</span>
                                        </td>

                                        <td className="bookTabData-cap">
                                            <span className="bokkAcc">{item?.requstedAmmount}</span>
                                        </td>
                                        <td className="bookTabData-cap">
                                            <span className="bokkAcc">{item?.acceptStatus}</span>
                                        </td>
                                        <td className="bookTabData-cap">
                                            {
                                                item?.acceptStatus === "pending" &&
                                                <div>
                                                    <button
                                                        className='btn btn-success mr-1'
                                                        onClick={() => handleAccept(item)}
                                                    >
                                                        Accept
                                                    </button>
                                                    <button
                                                        className='btn btn-danger'
                                                        onClick={() => handleReject(item)}
                                                    >
                                                        Cancel
                                                    </button>
                                                </div>
                                            }

                                            {
                                                item?.acceptStatus === "accepted" &&
                                                <span className='text-success'>Accepted</span>
                                            }

                                            {
                                                item?.acceptStatus === "cancelled" &&
                                                <span className='text-danger'>Cancelled</span>
                                            }

                                            {/* {
                                                item?.acceptStatus === "Rejected" &&
                                                <span className='text-danger'>Rejected</span>
                                            } */}

                                        </td>
                                    </tr>
                                )
                            }
                            )
                        }

                    </tbody>
                </table>

                {/* {documentData?.length === 0 && <NoDataFound />} */}

            </div>

        </div>
    )
}

export default ViewCapitalOptComp
