import React from 'react'
import { Navigate, Outlet } from 'react-router-dom';
import { useUserData } from '../Providers/UserWrapper';

const BookkeeperPriceRoute = () => {
    const { isBusiness, isLogin, profileData, regCodeData } = useUserData();
    console.log('profileDatappbboook', regCodeData)
    if (profileData) {
        if (Object.keys(regCodeData)?.length !== 0) {
            // return <Outlet />
            if (regCodeData?.isSubscribed) {
                return <Outlet />
            } else {
                return <Navigate to="/price" />
            }
        }
    }
}

export default BookkeeperPriceRoute