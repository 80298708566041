import React, { useState } from 'react';
import { useLanguage } from '../Providers/LanguageContext';
// import Button from 'react-bootstrap/Button';
export default function Otpinput () {
    const {language}=useLanguage()

    const [otp,setOtp]=useState({ value: '', otp1: "", otp2: "", otp3: "", otp4: "", otp5: "", disable: true })

        // this.state = { value: '', otp1: "", otp2: "", otp3: "", otp4: "", otp5: "", disable: true };

    const  handleChange=(value1, event)=> {

        setOtp({ [value1]: event.target.value });
    }

    const handleSubmit=(event) =>{

        const data = new FormData(event.target);
        console.log(this.state);
        event.preventDefault();
    }

    const inputfocus = (elmnt) => {
        if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
            const next = elmnt.target.tabIndex - 2;
            if (next > -1) {

                elmnt.target.form.elements[next].focus()
            }
        }
        else {
            console.log("next");

            const next = elmnt.target.tabIndex;
            if (next < 5) {
                elmnt.target.form.elements[next].focus()
            }
        }

    }
    
        return (
            <>
                <section id='otp-box'>
                    <div className='container'>
                        <div className='otp-top'>
                            <div className='otp-heading'>
                                <h4>{language.otp_verification}</h4>
                            </div>
                            <div className='otp-description'>
                                <p>{language.enter_otp}</p>
                            </div>
                        </div>
                        
                        <div className='inner-container'>
                        <form className="form" onSubmit={handleSubmit}>
                            <div className="otpContainer">

                                <input
                                    name="otp1"
                                    type="text"
                                    autoComplete="off"
                                    className="otpInput"
                                    value={otp.otp1}
                                    // onKeyPress={keyPressed}
                                    onChange={e => handleChange("otp1", e)}
                                    tabIndex="1" maxLength="1" onKeyUp={e => inputfocus(e)}

                                />
                                <input
                                    name="otp2"
                                    type="text"
                                    autoComplete="off"
                                    className="otpInput"
                                    value={otp.otp2}
                                    onChange={e => handleChange("otp2", e)}
                                    tabIndex="2" maxLength="1" onKeyUp={e => inputfocus(e)}

                                />
                                <input
                                    name="otp3"
                                    type="text"
                                    autoComplete="off"
                                    className="otpInput"
                                    value={otp.otp3}
                                    onChange={e => handleChange("otp3", e)}
                                    tabIndex="3" maxLength="1" onKeyUp={e => inputfocus(e)}

                                />
                                <input
                                    name="otp4"
                                    type="text"
                                    autoComplete="off"
                                    className="otpInput"
                                    value={otp.otp4}
                                    onChange={e => handleChange("otp4", e)}
                                    tabIndex="4" maxLength="1" onKeyUp={e => inputfocus(e)}
                                />

                                <input
                                    name="otp5"
                                    type="text"
                                    autoComplete="off"
                                    className="otpInput"
                                    value={otp.otp5}
                                    onChange={e => handleChange("otp5", e)}
                                    tabIndex="5" maxLength="1" onKeyUp={e => inputfocus(e)}
                                />
                            </div>
                            <div className='link-text'>
                            <p>{language.not_recieve_code}  <a href='#'>{language.resend}</a></p>
                            </div>
                            <div className='otp-btn'><button className="hide" id="submit" >{language.continue}</button></div>
                        </form>
                        </div>
                    </div>
                </section>
            </>
        );
    }


// export default Otpinput;