import React from 'react';
import './DeleteModal.css'

const DeleteModal = ({ closeDeleteModal, handleDelete }) => {
    return (
        <div>
            <div id="myModal-delete" class="modal-delete">

                <div class="modal-content-delete">
                    <span class="close-delete" onClick={closeDeleteModal}>&times;</span>

                    <p>Do You Want to Remove This Account</p>

                    <div className='delButtonGroup'>
                        <button className='btn delButton' onClick={handleDelete}>Remove</button>
                        <button className='btn btn-danger' onClick={closeDeleteModal}>Cancel</button>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default DeleteModal
