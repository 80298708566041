import React from "react";
import bannBT from "../../images/_Trazado_.png";
import Computer from "../../images/phone-computer.png";
import lockImg from "../../images/lock.png";
import networkImg from "../../images/phone-network.png";
import { useLanguage } from "../../Providers/LanguageContext";

function Bannerbottom() {
  const { language } = useLanguage();
  const points = [
    {
      image: Computer,
      description: language.bottomBanner1,
    },
    {
      image: lockImg,
      description: language.bottomBanner2,
    },
    {
      image: networkImg,
      description: language.bottomBanner3,
    },
  ];
  return (
    <>
      <section
        id="banner_bottom"
        style={{ backgroundImage: `url('${bannBT}')` }}
      >
        <div className="container">
          <div className="main_cnt">
            {points.map((item, i) => {
              return (
                <div className="box_card" key={i}>
                  <div className="icon_area">
                    <img src={item.image} className="img-fluid" alt="icon" />
                  </div>
                  <div className="description">
                    <p>{item.description}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
}

export default Bannerbottom;
