import React from 'react';
import LoaderRotate from "../Component/LoaderRotate"
import { Link, useNavigate } from 'react-router-dom';
import { useLanguage } from '../Providers/LanguageContext';

const CustomCssModal = ({ setShowIndustry }) => {
    const { language, currentLanguage, setCurrentLanguage } = useLanguage();
    const navigate = useNavigate();

    const industryData = [
        {
            name: language.general_use,
            Link: "/generalUse"
        },
        {
            name: language.personal_and_domestic_use,
            Link: "/personalUse"
        },
        {
            name: language.capital_providers_and_investors,
            Link: "/capitalProviders"
        },
        {
            name: language.banks_and_banking_industry,
            Link: "/banckIndustry"
        },
        {
            name: language.agriculture,
            Link: "/agriculture"
        },
        {
            name: language.construction,
            Link: "/construction"
        },
        {
            name: language.electronic_commerce,
            Link: "/electronicsCommerce"
        },
        {
            name: language.health_care,
            Link: "/healthCare"
        },
        {
            name: language.education,
            Link: "/education"
        },
        {
            name: language.accounting_companies,
            Link: "/energy"
        },
        {
            name: language.energy,
            Link: "/generalUse"
        },
        {
            name: language.manufacturing,
            Link: "/manufacturing"
        },
        {
            name: language.government,
            Link: "/government"
        },
        {
            name: language.hospitality,
            Link: "/hospitality"
        },
        {
            name: language.real_estate,
            Link: "/realState"
        },
        {
            name: language.legal,
            Link: "/legal"
        },
        {
            name: language.retailers,
            Link: "/retailer"
        },
        {
            name: language.non_profit_organization,
            Link: "/noProfitOrg"
        },
        {
            name: language.oil_and_gas,
            Link: "/oilAndGas"
        },
        {
            name: language.technology,
            Link: "/technology"
        },
        {
            name: language.transportation,
            Link: "/transportation"
        }

    ]

    return (
        <div className='container'>
            {/* <h2>Modal Example</h2> */}

            {/* <button id="myBtn">Open Modal</button> */}

            <div id="myModal_fis" class="modal_fis">

                <div class="modal-content_fis">
                    <span class="close" onClick={() => setShowIndustry(false)}>&times;</span>
                    {/* <p>Some text in the Modal..</p> */}
                    {/* <LoaderRotate /> */}
                    {
                        industryData.map((item, i) =>
                            <div key={i}
                                onClick={() => {
                                    navigate(item.Link);
                                    setShowIndustry(false);
                                }}
                            >
                                {item.name}
                            </div>
                        )

                    }

                    {/* <Link to="/personalUse">{language.personal_and_domestic_use}</Link>
                    <Link to="/capitalProviders">{language.capital_providers_and_investors}</Link>
                    <Link to="/banckIndustry">{language.banks_and_banking_industry}</Link>
                    <div className="dropdown-divider"></div>
                    <Link to="/agriculture">{language.agriculture}</Link>
                    <Link to="/construction">{language.construction}</Link>
                    <Link to="/electronicsCommerce">{language.electronic_commerce}</Link>
                    <Link to="/healthCare">{language.health_care}</Link>
                    <Link to="/education">{language.education}</Link>
                    <Link to="/accountComp">{language.accounting_companies}</Link>
                    <Link to="/energy">{language.energy}</Link>
                    <Link to="/manufacturing">{language.manufacturing}</Link>
                    <Link to="/government">{language.government}</Link>
                    <Link to="/hospitality">{language.hospitality}</Link>
                    <Link to="/realState">{language.real_estate}</Link>
                    <Link to="/legal">{language.legal}</Link>
                    <Link to="/retailer">{language.retailers}</Link>
                    <Link to="/noProfitOrg">{language.non_profit_organization}</Link>
                    <Link to="/oilAndGas">{language.oil_and_gas}</Link>
                    <Link to="/technology">{language.technology}</Link>
                    <Link to="/transportation">{language.transportation}</Link> */}

                </div>

            </div>
        </div >
    )
}

export default CustomCssModal