import React, { useState } from 'react'
import TimeFrameTabs from '../../../Component/TimeFrameTabs'
import moment from 'moment'
function AddExpense() {
    const [values, setValues] = useState({
        dayType: "",
        price: "",
        vendorName: "",
        type: "",
        transactionDate: moment(new Date(Date.now())).format("YYYY-MM-DD"),
        vendorImage: "",
        projectName: "",
        vendorCredit: "",
        expenseTypeID: ""
    })
    const onChangeNav = (activeKey) => {
        setValues(prev => ({ ...prev, dayType: activeKey }))
    }

    const handleSubmit = (e)=>{
        e.preventDefault()
    }

    return (
        <div>
            <TimeFrameTabs onChangeNav={onChangeNav} />
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                {/* <form style={{ width: (isDesktopOrLaptop) ? "450px" : "300px", margin: "25px" }} onSubmit={handleSubmit}>
                    <h3>Sales</h3>
                    <div className="form-group">
                        <label for="exampleInputEmail1">Enter sales amount</label>
                        <input type="number"
                            value={values.price}
                            onChange={(e) => setValues(prev => ({ ...prev, price: e.target.value }))}
                            class="form-control" id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            placeholder="Enter sales amount" />
                    </div>
                    <div class="form-group">
                        <button type='button' className='btn bg-dark text-light mr-2'
                            disabled={values.type === "cash"}
                            onClick={() => {
                                setValues(prev => ({ ...prev, type: "cash" }))
                                toast.success("Selected cash type")
                            }}>Cash</button>
                        <button type='button'
                            disabled={values.type === "digital"}
                            className='btn bg-dark text-light'
                            onClick={() => {
                                setValues(prev => ({ ...prev, type: "digital" }))
                                toast.success("Selected digital type")
                            }}>Digital</button>
                    </div>
                    <div class="form-group">
                        <label for="exampleInputPassword1">Customer name</label>
                        <input
                            type="text"
                            class="form-control"
                            id="exampleInputPassword1"
                            placeholder="Customer name"
                            value={values.customerName}
                            onChange={(e) => setValues(prev => ({ ...prev, customerName: e.target.value }))}
                        />
                    </div>

                    <button type="submit" class="btn btn-primary">Submit</button>
                </form> */}
            </div>
        </div>
    )
}

export default AddExpense