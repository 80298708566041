import React, { useEffect, useState } from 'react'
import { useLanguage } from '../../../Providers/LanguageContext';
import toast from 'react-hot-toast';
import HttpClient from '../../../utils/HttpClient';
import DeleteModal from '../../../Modal/deleteMoal/DeleteModal';
import { useUserData } from '../../../Providers/UserWrapper';

const LinkAccount = ({ setLinkedAccData, callGetAcc }) => {
    const { language, currentLanguage } = useLanguage();
    const { setIsLoading } = useUserData()
    console.log("4f51g2x", language, currentLanguage)
    const initAddMore = {
        accCode: "",
        clientName: "",
        clientType: "",
        subsType: "",
        acceptStatus: "",
        id: ""
    }
    const [addMoreData, setAddMoreData] = useState([initAddMore]);
    const [isDeleteModal, setIsDeleteModal] = useState(false);
    const [delIndex, setDelIndex] = useState("");
    const [singleAcc, setSingleAcc] = useState({});
    const [allUserData, setAllUserData] = useState([]);
    const [isUserDrop, setIsUserDrop] = useState("");


    // console.log("allUserData", allUserData)


    // add more change
    const handleChangeAddMore = (e, ind) => {
        const { name, value } = e.target;
        setAddMoreData(prev => prev.map((item, i) => {
            if (i === ind) {
                return { ...item, [name]: value }
            } else {
                return item
            }
        }))
    }

    // close delete modal
    const closeDeleteModal = () => {
        setIsDeleteModal(false);
    }

    // link Account
    const LinkAccount = async (item) => {
        if (!item.accCode) {
            toast.error("Please Enter Valid Account Code");
            return
        }

        const data = {
            "language": currentLanguage === "eng" ? "EN" : "ES",
            "accountRegCode": item.accCode
        }
        console.log("4sdg5s1", data)
        // return
        setIsLoading(true)
        const res = await HttpClient.requestData("bookkeeper/inviteOwner", "POST", data)
        console.log("ressAcc", res)
        if (res && res?.status) {
            setIsLoading(false)
            getLinkedAccount();
            toast.success("Mail Sent to Owner Successfully to Link Account");
        } else {
            setIsLoading(false)
            toast.error(res.message || "Something Wrong")
        }
    }

    // get linked account
    const getLinkedAccount = async () => {
        const res = await HttpClient.requestData("bookkeeper/getAllAccounts", "GET")
        // console.log("resAdd", res)
        if (res && res?.status) {
            if (res?.data?.length) {
                setLinkedAccData(res?.data);
                setAddMoreData(prev => {
                    return res?.data?.map(item => (
                        {
                            // ...prev,
                            accCode: item?.accountRegCode,
                            clientName: item?.client_name,
                            clientType: item?.client_type,
                            subsType: "Basic Plan",
                            acceptStatus: item?.acceptStatus,
                            id: item?._id,
                            assignToName: item?.assignToName
                        }
                    ))
                })
            } else {
                setAddMoreData([initAddMore])
            }
        }
    }

    // click on remove button
    const handleRemove = (i) => {
        setDelIndex(i)
        setIsDeleteModal(true)
    }

    // remove account
    const removeAccount = async (i, item) => {
        // setAddMoreData(prev => prev.filter((ele, ind) => i !== ind));
        const res = await HttpClient.requestData("bookkeeper/deleteInvite/" + item?.id, "DELETE")
        // console.log("ressAcc", res)
        if (res && res?.status) {
            toast.success("Account Removed Successfully")
            getLinkedAccount()
            closeDeleteModal();
        } else {
            toast?.error(res?.message || "Error")
        }
    }

    // getting all user data
    const getAllSubUser = async () => {
        const res = await HttpClient.requestData("user-management", "GET")
        if (res && res?.status) {
            setAllUserData(res?.data);
        }
    }

    // handle assign
    const handleAssign = async (ele, id) => {
        const data = {
            "assignToId": ele?._id
        }
        setIsLoading(true)
        const res = await HttpClient.requestData("bookkeeper/assign-bookkeeper/" + id, "PUT", data)
        console.log("ress", res)
        if (res && res?.status) {
            toast.success("User Assigned Successfully!");
            setIsLoading(false)
            getLinkedAccount();
            setIsUserDrop("")
        } else {
            setIsLoading(false)
            toast.error(res?.message || "Error")
        }
    }

    useEffect(() => {
        getLinkedAccount();
    }, [callGetAcc])

    useEffect(() => {
        getAllSubUser();
    }, [])

    return (
        <div className='container'>
            <section>

                {/* Headings */}
                <div>
                    <h6 className='BookLinkSubHeading'>{language.link_account_heading}</h6>
                </div>

                {/* add more part */}
                <div className='outerTblDiv'>
                    <div className='table-responsive'>
                        <table className="buildCapitalTable mt-1 mb-2">
                            <thead>
                                <tr>
                                    <th className="buildCapMiddleTh" id='buildCapFirsthead'>
                                        <button
                                            className="buildAddBtn"
                                            onClick={() => setAddMoreData(prev => [...prev, initAddMore])}
                                        >
                                            <i class="fa-solid fa-plus"></i>
                                        </button>
                                        <span className="buildCapLeftThSpan">{language.enter_account_code}</span>
                                    </th>
                                    <th className="buildCapMiddleTh">
                                        <span className="buildCapLeftThSpan">{language.link_account_heading}</span>
                                    </th>
                                    <th className="buildCapMiddleTh">
                                        <span className="buildCapLeftThSpan">{language.client_name}</span>
                                    </th>
                                    <th className="buildCapMiddleTh">
                                        <span className="buildCapLeftThSpan">{language.client_type}</span>
                                    </th>
                                    <th className="buildCapMiddleTh">
                                        <span className="buildCapLeftThSpan">{language.subscription_type}</span>
                                    </th>

                                    <th className="buildCapMiddleTh">
                                        <span className="buildCapLeftThSpan">{language.assign_to}</span>
                                    </th>
                                    {/* <th className="buildCapMiddleTh">
                                    <span className="buildCapLeftThSpan">Assign</span>
                                </th> */}

                                </tr>
                            </thead>


                            <tbody>
                                {
                                    addMoreData?.map((item, i) =>
                                        <tr>
                                            <td className="buildCapMiddleTd">
                                                <input
                                                    className='bookLinkTableInput'
                                                    type="number"
                                                    name="accCode"
                                                    placeholder='710552470'
                                                    value={item.accCode}
                                                    onChange={(e) => handleChangeAddMore(e, i)}
                                                // readOnly={!isSave}
                                                />
                                            </td>
                                            <td className="buildCapMiddleTd">
                                                {
                                                    item?.acceptStatus === "noAction"
                                                    && <span className='text-warning'>Pending</span>
                                                }

                                                {
                                                    item?.acceptStatus === "cancelled" &&
                                                    <span className='text-danger'>Rejected</span>
                                                }

                                                {
                                                    item?.acceptStatus === "accepted" &&
                                                    <span className='text-success'>Accepted</span>
                                                }

                                                {
                                                    item?.acceptStatus === "" &&
                                                    <button
                                                        className='buildCapTblBtn'
                                                        onClick={() => LinkAccount(item)}
                                                    >
                                                        Link
                                                    </button>
                                                }

                                                {
                                                    item?.acceptStatus !== ""
                                                        ?
                                                        <button
                                                            className='tblRmvBtn'
                                                            onClick={() => {
                                                                handleRemove(i)
                                                                setSingleAcc(item);
                                                            }}
                                                        >
                                                            Remove
                                                        </button>
                                                        :
                                                        <button
                                                            className='tblRmvBtn'
                                                            onClick={() => {
                                                                setAddMoreData(prev => prev.filter((ele, ind) => i !== ind));
                                                            }}
                                                        >
                                                            Delete
                                                        </button>
                                                }
                                            </td>
                                            <td className="buildCapMiddleTd">
                                                {item?.clientName ? item?.clientName : "-"}
                                            </td>
                                            <td className="buildCapMiddleTd">
                                                {item?.clientType ? item?.clientType : "-"}
                                            </td>
                                            <td className="buildCapMiddleTd">
                                                Basic Plan
                                            </td>
                                            <td className="buildCapMiddleTd">
                                                <a
                                                    className='text-primary'
                                                    style={{ cursor: "pointer", position: "relative" }}
                                                    onClick={() => setIsUserDrop(prev => prev ? "" : i + 1)}
                                                >
                                                    {/* Assign */}
                                                    {item?.assignToName ? item?.assignToName : "Assign"}
                                                </a>

                                                {
                                                    isUserDrop === i + 1 &&
                                                    <div className='LinkBookUserList'>
                                                        {
                                                            allUserData?.map((ele, ind) =>
                                                                <p
                                                                    key={ind}
                                                                    onClick={() => handleAssign(ele, item?.id)}
                                                                >
                                                                    {ele?.name ? ele?.name + " " + ele?.surName : ele?.email}
                                                                </p>
                                                            )
                                                        }
                                                    </div>
                                                }
                                            </td>
                                        </tr>
                                    )
                                }

                            </tbody>
                        </table>
                    </div>
                </div>

            </section >

            {
                isDeleteModal &&
                <DeleteModal
                    closeDeleteModal={closeDeleteModal}
                    handleDelete={() => removeAccount(delIndex, singleAcc)}
                />
            }
        </div >
    )
}

export default LinkAccount
