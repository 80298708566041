import { async } from '@firebase/util';
import React, { useState } from 'react'
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { useLanguage } from '../Providers/LanguageContext';
import { useUserData } from '../Providers/UserWrapper';
import HttpClient from '../utils/HttpClient';

export default function Restorepassword() {
    const { language, currentLanguage } = useLanguage();
    const [forgotEmail, setForgotEmail] = useState('');
    const { setIsLoading } = useUserData();
    const navigate = useNavigate();
    // console.log(forgotEmail)

    const handleResend = async (e) => {
        e.preventDefault();
        console.log(forgotEmail)
        const email_regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (!forgotEmail) {
            toast.error(language.email_validate_blank);
            return;
        }
        else if (!email_regex.test(forgotEmail)) {
            toast.error(language.enter_valid_email);
            return;
        }

        const sendData = {
            email: forgotEmail,
            lang: currentLanguage === "eng" ? "EN" : "ES"
        }
        // console.log('sendData', sendData)
        setIsLoading(true);
        const res = await HttpClient.requestData("reset-password", "POST", sendData);
        // console.log('resPass', res)
        if (res && res.status) {
            setIsLoading(false);
            toast.success(language.mail_sent_success);
            navigate("/");
        }
        else {
            toast.error("Error")
        }
    }

    return (

        <>
            <section id='restore'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-xl-12 col-lg-12 col-md-12 col-12'>
                            <div className='heading'>
                                <h3>{language.restore_password}</h3>
                            </div>
                            <div className='heading-description'>
                                <p>{language.enter_email_forgot}</p>
                                <p>{language.send_you_link}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='inner-container'>
                    <form className='form'>
                        <div className="form-group">
                            <input
                                type="email"
                                className="form-control"
                                id=""
                                aria-describedby=""
                                placeholder={language.email_placeholder}
                                onChange={(e) => setForgotEmail(e.target.value)}
                            />
                        </div>
                        <p>{language.not_recieve_link} <a href="#">{language.resend}</a></p>
                        <div className='reset-btn'>
                            <button onClick={handleResend} type='submit' className='password-submit'>{language.send}</button>
                        </div>
                    </form>
                </div>

            </section>

        </>

    )
}
