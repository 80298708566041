import React from 'react';
import Modal from 'react-modal';

const closeStyle = {
    color: "#aaaaaa",
    float: "right",
    fontSize: "28px",
    fontWeight: "bold",
    cursor: "pointer"
};

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: "50%",
    },
};

const NewBankSubCardModal = ({ modalIsOpen, closeModal, singleTotalDoc, pdfDocData, clickvalue }) => {
    return (
        <div>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Document Modal"
            >
                <span onClick={closeModal} style={closeStyle}>&times;</span>

                <h3 className='d-flex justify-content-center'>
                    {singleTotalDoc?.typeNameEnglish || 'Documents'}
                </h3>

                <div className='d-flex justify-content-center mt-4'>
                    <div className='invoicedocModalTotal'>
                        {
                            (clickvalue === "Missing" || clickvalue === "Past Due") && singleTotalDoc && singleTotalDoc?.length !== 0 &&
                            singleTotalDoc?.map((item, i) => {
                                return (
                                    <div key={i}>
                                        <ul>
                                            <li>
                                                <a
                                                    href={item}
                                                    target='_blank'
                                                    rel='noopener noreferrer'
                                                >
                                                    {item}
                                                </a>

                                            </li>
                                        </ul>
                                    </div>
                                )
                            })

                        }

                        {
                            clickvalue === "Total" && pdfDocData && pdfDocData?.pdfUrls?.length !== 0 &&
                            pdfDocData?.pdfUrls?.map((item, i) => {
                                return (
                                    <div key={i}>
                                        <ul>
                                            <li>
                                                <label>Your Upload : </label>
                                                <a
                                                    href={item}
                                                    target='_blank'
                                                    rel='noopener noreferrer'
                                                >
                                                    {item}
                                                </a>

                                            </li>
                                        </ul>
                                    </div>
                                )
                            })
                        }

                        {
                            clickvalue === "Total" && pdfDocData && pdfDocData?.pdf2Urls?.length !== 0 &&
                            pdfDocData?.pdf2Urls?.map((item, i) => {
                                return (
                                    <div key={i}>
                                        <ul>
                                            <li>
                                                <label>Investor Upload : </label>
                                                <a
                                                    href={item}
                                                    target='_blank'
                                                    rel='noopener noreferrer'
                                                    className=''
                                                >
                                                    {item}
                                                </a>

                                            </li>
                                        </ul>
                                    </div>
                                )
                            })
                        }

                        {
                            clickvalue === "Total" && pdfDocData && pdfDocData?.pdf3Urls?.length !== 0 &&
                            pdfDocData?.pdf3Urls?.map((item, i) => {
                                return (
                                    <div key={i}>
                                        <ul>
                                            <li>
                                                <label>Your Upload : </label>
                                                <a
                                                    href={item}
                                                    target='_blank'
                                                    rel='noopener noreferrer'
                                                >
                                                    {item}
                                                </a>

                                            </li>
                                        </ul>
                                    </div>
                                )
                            })
                        }

                        {
                            clickvalue === "Total" && pdfDocData && pdfDocData?.pdf4Urls?.length !== 0 &&
                            pdfDocData?.pdf4Urls?.map((item, i) => {
                                return (
                                    <div key={i}>
                                        <ul>
                                            <li>
                                                <label>BookKeeper Upload : </label>
                                                <a
                                                    href={item}
                                                    target='_blank'
                                                    rel='noopener noreferrer'
                                                    className=''
                                                >
                                                    {item}
                                                </a>

                                            </li>
                                        </ul>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default NewBankSubCardModal