import React from 'react'
import { useLanguage } from '../Providers/LanguageContext'
import { useNavigate } from 'react-router-dom'

function TopbarComp(textprop) {
    // console.log(textprop, "textprop")
    const { language, currentLanguage } = useLanguage();
    const navigate = useNavigate();
    return (
        <>
            <section id='mng-top'>
                <div className='container'>
                    <div className='d-flex justify-content-between'>
                        <div className='col-xl-6 col-lg-6 col-md-12 col-12'>
                            <div className='left-heading'>
                                <h5>{textprop.textprop}</h5>
                            </div>
                        </div>


                        {/* view insight button */}
                        <div className='viewInsignInpBtnDiv'>
                            <button
                                className='viewInsighBtn'
                                onClick={() => navigate("/news", { state: { time: textprop?.time } })}
                            >
                                {language.view_insight}
                            </button>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default TopbarComp