import React, { useEffect, useState, useRef, useCallback } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import WorkPlace from "./WorkPlace";
import MVPstory from "./MVPstory";
import Instead from "./Instead";
import MVPrank from "./MVPrank";
import SpreadSheet from "./SpreadSheet";
import Solution from "./Solution";
import Insights from "./Insights";
import Integration from "./Integration";
import RiskFree from "./RiskFree";
import Uncertain from "./Uncertain";
import Stress from "./Stress";
import { useNavigate } from "react-router-dom";
import { useUserData } from "../../Providers/UserWrapper";
export default function SignUp({ closeModal }) {
  const stopPopup = (e) => {
    e.stopPropagation();
  };
  const progressCircle = useRef(null);
  const progressContent = useRef(null);
  const onAutoplayTimeLeft = (s, time, progress) => {
    progressCircle.current.style.setProperty("--progress", 1 - progress);
    progressContent.current.textContent = `${Math.ceil(time / 1000)}s`;
  };

  const { isLogin, isBusiness } = useUserData()
  const navigate = useNavigate()

  const goToRegistration = useCallback(() => {
    if (isLogin) {
      // if (isBusiness) navigate('/busines-price');
      // else navigate('/personal-price');
      navigate("/price")
    }
    else {
      navigate('/creataccount', { state: { userType: "Business", discCode: "ADMIN" } })
    }
  }, [])
  //  l
  // kk

  return (
    <>
      <div className="SignUpWrap" id="bbb" onClick={closeModal}>
        <div className="SignUp" onClick={stopPopup}>
          <a href="#" className="my_close" onClick={closeModal}>
            <i class="fa-solid fa-xmark"></i>
          </a>

          <Swiper
            spaceBetween={30}
            centeredSlides={true}
            // autoplay={{
            //   delay: 6500,
            //   disableOnInteraction: false,
            // }}
            pagination={{
              clickable: true,
            }}
            navigation={true}
            modules={[Autoplay, Pagination, Navigation]}
            onAutoplayTimeLeft={onAutoplayTimeLeft}
            className="mySwiper"
          >
            <SwiperSlide>
              <WorkPlace goToRegistration={goToRegistration} />
            </SwiperSlide>

            <SwiperSlide>
              <Instead goToRegistration={goToRegistration} />
            </SwiperSlide>

            <SwiperSlide>
              <SpreadSheet goToRegistration={goToRegistration} />
            </SwiperSlide>

            <SwiperSlide>
              <Solution goToRegistration={goToRegistration} />
            </SwiperSlide>

            <SwiperSlide>
              <Stress goToRegistration={goToRegistration} />
            </SwiperSlide>

            <SwiperSlide>
              <Insights goToRegistration={goToRegistration} />
            </SwiperSlide>

            <SwiperSlide>
              <Integration goToRegistration={goToRegistration} />
            </SwiperSlide>

            <SwiperSlide>
              <RiskFree goToRegistration={goToRegistration} />
            </SwiperSlide>

            <SwiperSlide>
              <Uncertain goToRegistration={goToRegistration} />
            </SwiperSlide>

            <SwiperSlide>
              <MVPstory goToRegistration={goToRegistration} />
            </SwiperSlide>

            <SwiperSlide>
              <MVPrank goToRegistration={goToRegistration} />
            </SwiperSlide>
            {/* 
              <div className="autoplay-progress" slot="container-end">
                <svg viewBox="0 0 48 48" ref={progressCircle}>
                  <circle cx="24" cy="24" r="20"></circle>
                </svg>
                <span ref={progressContent}></span>
              </div> */}
          </Swiper>
          {/* <WorkPlace /> */}

          {/* <Instead /> */}

          {/* <MVPstory /> */}

          {/* <MVPrank/> */}

          {/* <SpreadSheet/> */}

          {/* <Solution/> */}

          {/* <Insights/> */}

          {/* <Integration/> */}

          {/* <RiskFree/> */}

          {/* <Uncertain/> */}
          {/* 
            <Stress/> */}
        </div>

      </div>
    </>
  );
}
