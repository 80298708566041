import React, { useEffect, useState } from 'react'
import HttpClient from '../../../utils/HttpClient';
import toast from 'react-hot-toast';
import { useLanguage } from '../../../Providers/LanguageContext';
import CustomLoaderLine from '../../../Component/Loader/CustomLoaderLine';
import { useUserData } from '../../../Providers/UserWrapper';

const BuildPersonalFinancialStatement = ({ setCapitalCsv, setPdfCreate, setPdfLoader, setEmailSendFunc }) => {
    const { language, currentLanguage } = useLanguage();
    const { profileData, setIsLoading } = useUserData();
    console.log("65fdg103", language, currentLanguage)
    const assetData = {
        title: "",
        price: ""
    }

    const liabilityData = {
        title: "",
        price: "",
    }


    // >>>>Set Default value for Assets<<<<
    const defaultOwnerData = [
        { title: "Cash – checking accounts", price: "" },
        { title: "Cash – savings accounts", price: "" },
        { title: "Certificates of deposit", price: "" },
        { title: "Securities – stocks / bonds / mutual funds", price: "" },
        { title: "Notes & contracts receivable", price: "" },
        { title: "Life insurance (cash surrender value)", price: "" },
        { title: "Personal Property (autos, jewelry, etc.)", price: "" },
        { title: "Retirement funds (e.g., IRAs, 401k)", price: "" },
        { title: "Real Estate (market value)", price: "" },
        { title: "Other assets (specify)", price: "" }
    ];


    // // >>>>Set Default value for Liability<<<<
    const defaultChildData = [
        { title: "Current Debt (Credit cards, Accounts)", price: "" },
        { title: "Notes payable", price: "" },
        { title: "Taxes payable", price: "" },
        { title: "Real estate mortgage", price: "" },
        { title: "Other liabilities (specify)", price: "" }
    ];

    const [ownerData, setOwnerData] = useState(defaultOwnerData);
    const [childData, setChildData] = useState(defaultChildData);
    const [isDelOwner, setIsDelOwner] = useState(false);
    const [isDelChild, setIsDelChild] = useState(false);
    const [totalAssets, setTotalAssets] = useState(0);
    const [totalLiabilities, setTotalLiabilities] = useState(0);
    const [netWorth, setNetWorth] = useState(0);
    const [currentDate, setCurrentDate] = useState('');
    const [isSave, setIsSave] = useState(true);
    const [loading, setLoading] = useState(false);
    const [saveLoading, setSaveLoading] = useState(false);

    // >>>>onChange ownwr / admore<<<<
    const handleChangeOwner = (e, ind) => {
        const { name, value } = e.target;
        const data = ownerData?.map((item, i) => {

            if (i === ind) {
                return { ...item, [name]: value }
            } else {
                return item
            }
        })
        setOwnerData(data);
    }

    // >>>>onChange Child / admore<<<<
    const handleChangeChild = (e, ind) => {
        const { name, value } = e.target;
        const data = childData?.map((item, i) => {

            if (i === ind) {
                return { ...item, [name]: value }
            } else {
                return item
            }
        })
        setChildData(data);
    }

    // >>>>Get Financial Report<<<<
    const getFinancialReport = async () => {
        setSaveLoading(true)
        const res = await HttpClient.requestData('reportbuilder-view-financialreport', 'GET');
        if (res && res?.status) {
            setSaveLoading(false);
            const assets = res?.data?.assets || [];
            const liabilities = res?.data?.liabilities || [];

            setOwnerData(assets.length > 0 ? assets : defaultOwnerData);
            setChildData(liabilities.length > 0 ? liabilities : defaultChildData);
        } else {
            setSaveLoading(false);
            setOwnerData(defaultOwnerData);
            setChildData(defaultChildData);
        }
    }

    // >>>>Input Validation<<<<
    const validateInputs = () => {
        const assetErrors = ownerData.some(item => !item.title || !item.price);
        const liabilityErrors = childData.some(item => !item.title || !item.price);

        if (assetErrors || liabilityErrors) {
            toast.error("Please fill the asset/liability fields.");
        }

        return !assetErrors && !liabilityErrors;
    };

    // >>>>Handle Save<<<<
    const handleSubmit = async () => {
        if (validateInputs()) {
            if (isSave) {
                const data = {
                    assets: ownerData?.map(item => ({
                        ...item,
                        price: parseFloat(item.price)
                    })),
                    liabilities: childData?.map(item => ({
                        ...item,
                        price: parseFloat(item.price)
                    })),
                }
                console.log("df4g65", data)
                // return
                setSaveLoading(true);
                const res = await HttpClient.requestData('reportbuilder-add-financial-statement', 'POST', data);
                // console.log("Submittingdata", res, data);
                if (res && res.status) {
                    setSaveLoading(false);
                    toast.success(language.pfs_msg);
                    setIsSave(!isSave);
                    getFinancialReport()
                }
                else {
                    setSaveLoading(false);
                    toast.error(res?.message || "Something went wrong.");
                }
            } else {
                setIsSave(!isSave);
            }
        }
    };

    // >>>>Handle Build Report<<<<
    const handleBuildRepport = async () => {
        // if (!validateAsset() || !validateLiability()) {
        //   toast.error(language.please_enter_all);
        //   return
        // }

        if (isSave) {
            toast.error("Please Save First");
            return
        }

        // console.log("accNameData", accNameData)
        const data = {
            assets: ownerData?.map(item => ({
                ...item,
                price: parseFloat(item.price)
            })),
            liabilities: childData?.map(item => ({
                ...item,
                price: parseFloat(item.price)
            })),
        }
        console.log("df4g65", data)

        // return
        setLoading(true);
        // setPdfLoader(true);
        const res = await HttpClient.requestData('reportbuilder-add-financial-statement', 'POST', data);
        // console.log("resbbb", res)
        if (res && res?.status) {
            // window.open(res?.path, "_blank");
            setLoading(false);
            toast.success(language.report_build_succ);
            // setPdfLoader(false);
            getFinancialReport()
        }
        else {
            setLoading(false);
            // setPdfLoader(false);
        }
    }

    // >>>>Download PDF<<<<
    const handlePdfDownload = async () => {
        // if (isSave) {
        //     toast.error("Please Save First");
        //     return
        // }
        const data = {
            language: currentLanguage === "eng" ? "EN" : "ES",
        }

        setPdfLoader(true);
        setIsLoading(true);
        const res = await HttpClient.requestData("reportbuilder/download/pdf/personal-financestatement", "POST", data)
        setPdfLoader(false);
        setIsLoading(false);
        if (res && res?.status) {
            window.open(res?.data, "_blank")
        } else {
            toast.error(res?.message || "Something Wrong!")
        }
    }

    // >>>>Sending mail<<<<
    const sendCapatialMail = async (emailData, oncloseSendModal) => {
        if (isSave) {
            return toast.error("Please Save First");
        } else {
            const data = {
                email: emailData?.email,
                language: currentLanguage === "eng" ? "EN" : "ES",
                assets: ownerData,
                liabilities: childData
            }
            // console.log("4d5fgh51", data)
            // return
            oncloseSendModal();
            setIsLoading(true);
            const res = await HttpClient.requestData("reportbuilder/pdf/personalfinance", "POST", data)
            if (res && res?.status) {
                toast?.success(language.mail_sent_success);
                setIsLoading(false);
            } else {
                setIsLoading(false);
            }
        }
    }

    // >>>>Sending Male<<<< 
    useEffect(() => {
        setEmailSendFunc(() => sendCapatialMail)

    }, [isSave])

    // >>>>Calculating Total Assets<<<<
    useEffect(() => {
        const total = ownerData?.reduce((sum, item) => sum + Number(item?.price || 0), 0);
        setTotalAssets(total);
    }, [ownerData]);

    // >>>>Calculating Total Liabilities<<<<
    useEffect(() => {
        const total = childData?.reduce((sum, item) => sum + Number(item?.price || 0), 0);
        setTotalLiabilities(total);
    }, [childData]);

    // >>>>Calculating Net Worth<<<<
    useEffect(() => {
        setNetWorth(totalAssets - totalLiabilities);
    }, [totalAssets, totalLiabilities]);

    // >>>>Setting Current Date<<<<
    useEffect(() => {
        const today = new Date();
        const formattedDate = today.toLocaleDateString('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });
        setCurrentDate(formattedDate);
    }, []);

    useEffect(() => {
        getFinancialReport();
        setPdfCreate(() => handlePdfDownload);
    }, [])

    return (
        <div className='container'>
            <div className="buildIncmStTableDiv">

                <div className='CurrentPeriod_drowp'>
                    <h5 className='text-bold'>Pallb Mondal</h5>
                    <h6 className='text-bold'>{currentDate}</h6>
                </div>

                <table className="buildIncmStTable">
                    <tbody>

                        {/****table Head***/}
                        <tr>
                            <th className="buildIncmStTh12 buildIncmStTh12First">
                                {isSave && <button
                                    className="buildAddBtn"
                                    onClick={() => {
                                        setOwnerData(prev => [...prev, assetData]);
                                    }}
                                >
                                    <i className="fa-solid fa-plus" />
                                </button>
                                }
                                <span>{language.assets}</span>
                                {isSave && <button className="delBuildRowBtn"
                                    onClick={() => setIsDelOwner(prev => !prev)}
                                >{isDelOwner ? language.remove_delete : language.delete_row}</button>}
                            </th>
                            <th className="buildIncmStTh12">
                                <span>{language.amount_dollars}</span>
                            </th>
                        </tr>

                        {/****Data**/}
                        {ownerData?.map((item, i) => {
                            return (
                                <tr key={i}>
                                    <td className="buildIncmStTd">
                                        {(isDelOwner && isSave) && <button
                                            className="buildCapiRemoveBtn"
                                            onClick={() => {
                                                setOwnerData(prev => prev.filter((ele, ind) => ind !== i))
                                            }}
                                        >
                                            <i class="fa-solid fa-minus"></i>
                                        </button>}
                                        <input type="text"
                                            className="userBuildInp"
                                            name="title"
                                            value={item?.title}
                                            onChange={(e) => handleChangeOwner(e, i)}
                                            defaultValue="Cash – checking accounts" />
                                    </td>
                                    <td className="buildIncmStTd">
                                        <input type="number"
                                            name="price"
                                            value={item?.price}
                                            onChange={(e) => handleChangeOwner(e, i)}
                                            defaultValue=""
                                            placeholder='$0.00' />
                                    </td>
                                </tr>
                            )
                        })
                        }

                        {/*****border**/}
                        <tr>
                            <td />
                            <td colSpan={1}>
                                <div className="buildTotDivider" />
                            </td>
                        </tr>

                        {/***Sub Total**/}
                        <tr>
                            <td className="buildIncmStTd">
                                <span className="buildTotSpan">{language.total_assets}</span>
                            </td>
                            <td className="buildIncmStTd">
                                <input type="number" readOnly="" placeholder='$0.00' value={totalAssets} />
                            </td>
                        </tr>



                        {/****table Head***/}
                        <tr>
                            <th className="buildIncmStTh12 buildIncmStTh12First">
                                {isSave && <button
                                    className="buildAddBtn"
                                    onClick={() => {
                                        setChildData(prev => [...prev, liabilityData]);
                                    }}
                                >
                                    <i className="fa-solid fa-plus" />
                                </button>}
                                <span>{language.liabilities}</span>
                                {isSave && <button className="delBuildRowBtn"
                                    onClick={() => setIsDelChild(prev => !prev)}
                                >{isDelChild ? language.remove_delete : language.delete_row}</button>}
                            </th>
                            <th className="buildIncmStTh12">
                                <span>{language.amount_dollars}</span>
                            </th>
                        </tr>

                        {/****Data**/}
                        {childData?.map((item, i) => {
                            return (
                                <tr key={i}>
                                    <td className="buildIncmStTd">
                                        {isDelChild && <button
                                            className="buildCapiRemoveBtn"
                                            onClick={() => {
                                                setChildData(prev => prev.filter((ele, ind) => ind !== i))
                                            }}
                                        >
                                            <i class="fa-solid fa-minus"></i>
                                        </button>}
                                        <input type="text"
                                            name="title"
                                            value={item?.title}
                                            onChange={(e) => handleChangeChild(e, i)}
                                            className="userBuildInp"
                                            defaultValue="Current Debt (Credit cards, Accounts)" />
                                    </td>
                                    <td className="buildIncmStTd">
                                        <input type="number"
                                            name="price"
                                            value={item?.price}
                                            onChange={(e) => handleChangeChild(e, i)}
                                            defaultValue=""
                                            placeholder='$0.00' />
                                    </td>
                                </tr>
                            )
                        })
                        }


                        {/*****border**/}
                        <tr>
                            <td />
                            <td colSpan={1}>
                                <div className="buildTotDivider" />
                            </td>
                        </tr>

                        {/***Sub Total**/}
                        <tr>
                            <td className="buildIncmStTd">
                                <span className="buildTotSpan">{language.total_liabilities}</span>
                            </td>
                            <td className="buildIncmStTd">
                                <input type="number" readOnly="" value={totalLiabilities} placeholder='$0.00' />
                            </td>
                        </tr>

                        {/***NET WORTH**/}
                        <tr>
                            <td className="buildIncmStTd">
                                <span className="buildTotSpan">{language.net_worth}</span>
                            </td>
                            <td className="buildIncmStTd">
                                <input type="number" readOnly="" value={netWorth} placeholder='$0.00' />
                            </td>
                        </tr>

                    </tbody>
                </table>

                {/* Buttons */}
                <div className="d-flex justify-content-center mt-3">

                    <button type='button' className="btn-report-save" onClick={() => handleSubmit()}>
                        {
                            isSave ?
                                saveLoading ? <CustomLoaderLine height="10" width="40" /> : language.save?.toUpperCase()
                                :
                                language.edit?.toUpperCase()
                        }
                    </button>
                    <button className="btn-report-build"
                        onClick={() => handleBuildRepport()}
                    >
                        {loading ? <CustomLoaderLine height="10" width="100" /> : language.build_report?.toUpperCase()}
                    </button>
                </div>

            </div>
        </div>
    )
}

export default BuildPersonalFinancialStatement