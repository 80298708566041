import React from "react";
import a from "../Images/5-IceburgImage.png";
export default function Stress({ goToRegistration }) {
  return (
    <div className="inner">
      <div className="Stress">
        <div className="bottom_cnt">
          <h5 style={{ color: "#4FACFF" }}>
            Fisibility: Where Activity Meets Profitability
          </h5>
          <h4>Simplify Tasking, Calendaring, Accounting & Bookkeeping</h4>
          <p>Transform It All Into Stress-Free Efficiency & Profitability</p>
          <h5 style={{ color: "#4FACFF" }}>
            In Minutes Not Months <br />
            Check how it works in under 4 min
          </h5>
          <a onClick={() => goToRegistration()} className="signupBtn my-3">
            Yes, I Want Less Stress!
          </a>
          <span className="d-block">
            Calendaring, Accounting & Bookkeeping Capabilities are just the tip
            of the iceberg
          </span>
          <div className="fisibility-card__image">
            <img src={a} />
          </div>
        </div>
      </div>
    </div>
  );
}
