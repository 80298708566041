import moment from 'moment';
import React, { useEffect, useState } from 'react'
import NoDataFound from '../../Component/NoDataFound';
import { useLanguage } from '../../Providers/LanguageContext';
import { useUserData } from '../../Providers/UserWrapper';
import HttpClient from '../../utils/HttpClient';

const ExpenseDetailTable = ({ reportDate }) => {
  const { language, currentLanguage } = useLanguage();
  const { isBusiness, SetExpenseDetExcel } = useUserData();

  const [expData, setExpData] = useState([]);
  const [expStatusData, setExpenseStatusData] = useState({});

  const getExcelData = () => {

    let myArray = [
      [language.report_generated_by, expStatusData.reportGeneratedBy],
      [language.report_generated_on, expStatusData.reportGeneratedBy],
      [language.report_date_range, expStatusData.startDate, expStatusData.endDate],
      [language.report_expense_total, expStatusData.expenseTotal],
      // [isBusiness ? language.report_expense_total : language.report_income_total, expStatusData.salesTotal],
      [language.rep_tran_count_total, expData.length],
      [],
      // [isBusiness ? language.sales : language.income],
      [language.transaction_date, language.expense_ammount, language.supplier, language.expense_desc_detail, language.expense_freq, language.project_name, language.sub_user_name]

    ]
    expData.forEach((item) => {
      myArray.push([
        moment(item.transactionDate).format("YYYY-MM-DD"),
        item.expenseAmount,
        item.supplierName,
        item.expenseName,
        item.expenseFrequency,
        item.projectName ? item.projectName : "-",
        item.firstName + " " + item.lastName
      ])
    })
    // myArray.push([])
    // myArray.push([language.expense])

    // expDetail.forEach((item) => {
    //   myArray.push([
    //     moment(item.transactionDate).format("YYYY-MM-DD"),
    //     item.amount,
    //     language.expense,
    //     item.transactionFrequency,
    //     item.projectName ? item.projectName : "-",
    //     item.firstName + " " + item.lastName
    //   ])
    // })

    console.log('myArrayexp', myArray)
    SetExpenseDetExcel(myArray);


    // SetExpenseDetExcel([["Expense"]])
  }

  useEffect(() => {
    getExcelData()
  }, [currentLanguage]);

  const getExpenseDetail = async () => {
    const sendData = {
      startDate: reportDate.startData,
      endDate: reportDate.endDate
    }
    const res = await HttpClient.requestData("expense-report", "POST", sendData);
    console.log('expttRes', res);
    if (res && res.status) {
      setExpData(res.data);
    }
  }

  const getExpenseStatus = async () => {
    const sendData = {
      startDate: reportDate.startData,
      endDate: reportDate.endDate
    }
    const res = await HttpClient.requestData("expense-stat", "POST", sendData);
    console.log('expttResSat', res);
    if (res && res.status) {
      setExpenseStatusData(res.data);
    }

  }

  useEffect(() => {
    getExpenseDetail();
    getExpenseStatus();
  }, [reportDate])


  return (
    <>
      <div className="rprtQury mb-5">
        <h4>
          {language.report_date_range}:
          {
            expStatusData.startDate && expStatusData.endDate &&
            <span> {expStatusData.startDate} to {expStatusData.endDate}</span>
          }
        </h4>
        <h4>
          {language.report_expense_total}:<span> {expStatusData.expenseTotal}</span>
        </h4>
        <h4>
          {language.rep_tran_count_total}:<span> {expData.length}</span>
        </h4>
      </div>
      <div>

        <div className='table-responsive'>
          <table className="table table-striped gen-fin-table_tran">
            <thead>
              <tr>
                <th scope="col">{language.transaction_date}</th>
                <th scope="col">{language.expense_ammount}</th>
                <th scope="col">{language.supplier}</th>
                <th scope="col">{language.expense_desc_detail}</th>
                <th scope="col">{language.expense_freq}</th>
                {/* <th scope="col">{language.expense_currency}</th> */}
                <th scope="col">{language.project_name}</th>
                <th scope="col">{language.sub_user_name}</th>
              </tr>
            </thead>
            <tbody className="">
              {
                expData.map((item, i) =>
                  <tr key={i}>
                    <td>{moment(item.transactionDate).format("YYYY-MM-DD")}</td>
                    <td>{item.expenseAmount}</td>
                    <td>{item.supplierName}</td>
                    <td>{item.expenseName}</td>
                    <td>{item.expenseFrequency}</td>
                    {/* <td>data</td> */}
                    <td>{item.projectName ? item.projectName : "-"}</td>
                    <td>{item.firstName + " " + item.lastName}</td>
                  </tr>
                )
              }

            </tbody>
          </table>
        </div>
        {expData.length == 0 && <NoDataFound />}

      </div>

    </>
  )
}

export default ExpenseDetailTable;