import React from 'react'
import { useLanguage } from '../../Providers/LanguageContext'
import CustomDateBtn from '../CustomDateBtn'

function CreateDateComp({ title, getCreationDate, isCleared, setIsCleared ,filters}) {
    const { language } = useLanguage()
    console.log(title, "ttileee")
    return (


        <>

            <section id='mng-top'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-xl-12 col-lg-12 col-md-12 col-12'>
                            <div className='left-heading text-center'>
                                <h2>{title ? title : language.expense_management}</h2>
                            </div>
                        </div>
                        {/* <div className='col-xl-6 col-lg-6 col-md-12 col-12'>
                            <div className='right-box'>
                                <form>
                                    <div className='top-search'>
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id=""
                                                aria-describedby=""
                                                placeholder=""
                                                onChange={getVendorName}
                                                ref={searchBoxRef}
                                            />
                                            <i class="fa-solid fa-magnifying-glass"></i>
                                        </div>
                                    </div>

                                </form>
                            </div>
                        </div> */}
                    </div>
                </div>
            </section>

            <div id='creation'>
                <div className='container'>
                    <CustomDateBtn
                        text={language.creation_date}
                        getValue={getCreationDate}
                        setIsCleared={setIsCleared}
                        isCleared={isCleared}
                        filters={filters}
                    />
                </div>
            </div>

        </>


    )
}

export default CreateDateComp