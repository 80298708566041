import React, { useEffect, useState } from 'react';
import fisiIconDrop from '../../../images/FisTaskIcon.png';
import HttpClient from '../../../utils/HttpClient';
import TaskPopUp from '../TaskPopUp';
import { useLanguage } from '../../../Providers/LanguageContext';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

const TodoList = props => {
  const { language } = useLanguage();
  const { taskData, getTaskData, setTaskData } = props;

  const [modala, setModala] = useState(false);
  const [taskDetailData, setTaskDetailData] = useState({});

  // console.log("taskData", taskData)

  // ********************drag and drop*******************
  const grid = 4;

  // when moves in a single list or column
  const reorder = (list, startIndex, endIndex) => {
    // console.log('list_startIndex_endIndex', list, startIndex, endIndex)
    const result = Array.from(list?.status);
    // console.log('resultreorder', result, list)
    const [removed] = result.splice(startIndex, 1);
    // console.log('removedreorder', removed)
    result.splice(endIndex, 0, removed);

    return result;
  };

  // Moves an item from one list to another list.
  const move = (source, destination, droppableSource, droppableDestination) => {
    // console.log('source_destination', source, destination, droppableSource, droppableDestination)
    const sourceClone = Array.from(source?.status);
    const destClone = Array.from(destination?.status);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    // console.log('droppableSource', droppableSource.droppableId, droppableDestination.droppableId)
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;
    // console.log('resultMove', result)
    return result;
  };

  //drag end: this function calles aftert drag end
  const onDragEnd = result => {
    const { source, destination } = result;
    console.log('onDragEndresult', result);
    // dropped outside the list
    if (!destination) {
      return;
    }
    const sInd = +source.droppableId;
    const dInd = +destination.droppableId;
    // console.log('sInd_dInd', sInd, dInd);

    if (sInd === dInd) {
      const items = reorder(taskData[sInd], source.index, destination.index);
      // console.log('itemsReorder', items)
      const newState = [...taskData];
      newState[sInd]['status'] = items;

      // setTaskData(newState);
    } else {
      const resultArr = move(taskData[sInd], taskData[dInd], source, destination);
      const newState = [...taskData];
      newState[sInd]['status'] = resultArr[sInd];
      newState[dInd]['status'] = resultArr[dInd];

      console.log('resultmove', taskData[sInd], taskData[dInd], resultArr[dInd]);
      // setState(newState.filter(group => group.length));
      setTaskData(newState);

      updateStatus(result?.draggableId, taskData[dInd]);
    }
  };

  const updateStatus = async (id, destData) => {
    const data = {
      taskStatus: destData?.apiStatus,
    };
    const res = await HttpClient.requestData('update-task-eventDetails/' + id, 'PUT', data);
    if (res && res?.status) {
      // getTaskData();
      // closemodal();
    }
  };

  // add style to the each list or column
  const getListStyle = isDraggingOver => {
    // console.log("isDraggingOver", isDraggingOver)
    return {
      background: isDraggingOver ? '#e5dfdf' : 'lightgrey',
      padding: grid,
      width: 144,
    };
  };

  // adds style to the each row
  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: grid,
    margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    background: isDragging ? 'lightgreen' : '#fff',
    borderTop: '2px solid #c9c9c9',
    // styles we need to apply on draggables
    ...draggableStyle,
  });
  // ********************End drag and drop*******************

  return (
    <div>
      <div className="addTaskTestDiv">
        <DragDropContext onDragEnd={onDragEnd}>
          {taskData?.map((ele, ind) => {
            return (
              <div key={ind}>
                <div className="addinnerTskDivOne addinnerTskDiv">
                  <div className="addInHeadDiv" style={{ backgroundColor: ele?.color }}>
                    <span className="addInHeadSpan">{ele?.name}</span>
                    <span className="addInNumSpan">{ele?.open?.length}</span>
                  </div>

                  <Droppable key={ind} droppableId={`${ind}`}>
                    {(provided, snapshot) => {
                      return (
                        <div
                          ref={provided.innerRef}
                          style={getListStyle(snapshot.isDraggingOver)}
                          {...provided.droppableProps}
                        >
                          <div className="addMinTaskDiv">
                            {ele?.status?.map((item, index) => {
                              return (
                                <div key={index}>
                                  <Draggable key={item?._id} draggableId={item?._id} index={index}>
                                    {(provided, snapshot) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                      >
                                        <div
                                          className="addInTextsDiv"
                                          key={index}
                                          onClick={() => {
                                            setTaskDetailData(item);
                                            setModala(true);
                                          }}
                                        >
                                          <p className="addMinPrepText">{item?.taskName}</p>
                                          <div className="addAssiTextDiv">
                                            <p className="addAssignText">
                                              {
                                                item?.assignToUserName
                                                  ?
                                                  <span>
                                                    {language.assign_to}: <span>{item?.assignToUserName}</span>
                                                  </span>
                                                  :
                                                  <span>{language.not_assigned}</span>
                                              }
                                            </p>
                                            <figure className="smallFisiIconFig">
                                              <img src={fisiIconDrop} alt="..." />
                                            </figure>
                                          </div>
                                        </div>
                                      </div>
                                    )
                                    }
                                  </Draggable>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      );
                    }}
                  </Droppable>

                  {/* <div>
                                            <button className="addInTaskBtn">+ Add New Task</button>
                                        </div> */}
                </div>
              </div>
            );
          })}
        </DragDropContext >

        {/* Doing */}
        {/* <div className="addinnerTskDivTwo addinnerTskDiv">
                    <div
                        className="addInHeadDiv"
                        style={{ backgroundColor: "rgb(46, 195, 247)" }}
                    >
                        <span className="addInHeadSpan">Doing</span>
                        <span className="addInNumSpan">{taskData?.doing?.length}</span>
                    </div>
                    <div className="addMinTaskDiv">
                        {taskData?.doing?.map((item, i) =>
                            <div
                                key={i}
                                className="addInTextsDiv"
                                onClick={() => {
                                    setTaskDetailData(item)
                                    setModala(true)
                                }}
                            >
                                <p className="addMinPrepText">
                                    {item?.taskName}
                                </p>
                                <div className="addAssiTextDiv">
                                    <p className="addAssignText">
                                        Assigned to: <span>{item?.assignToUserName}</span>
                                    </p>
                                    <figure className="smallFisiIconFig">
                                        <img src={fisiIconDrop} alt="..." />
                                    </figure>
                                </div>
                            </div>
                        )
                        }
                    </div>
                    <div>
                        <button className="addInTaskBtn">+ Add New Task</button>
                    </div>
                </div> */}

        {/* Done */}
        {/* <div className="addinnerTskDivThree addinnerTskDiv">
                    <div
                        className="addInHeadDiv"
                        style={{ backgroundColor: "rgb(61, 241, 66)" }}
                    >
                        <span className="addInHeadSpan">Done</span>
                        <span className="addInNumSpan">{taskData?.done?.length}</span>
                    </div>
                    <div className="addMinTaskDiv">
                        {
                            taskData?.done?.map((item, i) =>
                                <div
                                    key={i}
                                    className="addInTextsDiv"
                                    onClick={() => {
                                        setTaskDetailData(item)
                                        setModala(true)
                                    }}
                                >
                                    <p className="addMinPrepText">
                                        {item?.taskName}
                                    </p>
                                    <div className="addAssiTextDiv">
                                        <p className="addAssignText">
                                            Assigned to: <span>{item?.assignToUserName}</span>
                                        </p>
                                        <figure className="smallFisiIconFig">
                                            <img src={fisiIconDrop} alt="..." />
                                        </figure>
                                    </div>
                                </div>
                            )

                        }
                    </div>
                    <div>
                        <button className="addInTaskBtn">+ Add New Task</button>
                    </div>
                </div> */}

        {/* Ideas */}
        {/* <div className="addinnerTskDivFour addinnerTskDiv">
                    <div
                        className="addInHeadDiv"
                        style={{ backgroundColor: "rgb(113, 129, 200)" }}
                    >
                        <span className="addInHeadSpan">Ideas</span>
                        <span className="addInNumSpan">{taskData?.ideas?.length}</span>
                    </div>
                    <div className="addMinTaskDiv">
                        {
                            taskData?.ideas?.map((item, i) =>
                                <div
                                    className="addInTextsDiv"
                                    key={i}
                                    onClick={() => {
                                        setTaskDetailData(item)
                                        setModala(true)
                                    }}
                                >
                                    <p className="addMinPrepText">
                                        {item?.taskName}
                                    </p>
                                    <div className="addAssiTextDiv">
                                        <p className="addAssignText">
                                            Assigned to: <span>{item?.assignToUserName}</span>
                                        </p>
                                        <figure className="smallFisiIconFig">
                                            <img src={fisiIconDrop} alt="..." />
                                        </figure>
                                    </div>
                                </div>
                            )

                        }
                    </div>
                    <div>
                        <button className="addInTaskBtn">+ Add New Task</button>
                    </div>
                </div> */}

        {/* On-Hold */}
        {/* <div className="addinnerTskDivFive addinnerTskDiv">
                    <div
                        className="addInHeadDiv"
                        style={{ backgroundColor: "rgb(247, 200, 31)" }}
                    >
                        <span className="addInHeadSpan">On-Hold</span>
                        <span className="addInNumSpan">{taskData?.onhold?.length}</span>

                    </div>
                    <div className="addMinTaskDiv">
                        {
                            taskData?.onhold?.map((item, i) =>
                                <div
                                    key={i}
                                    className="addInTextsDiv"
                                    onClick={() => {
                                        setTaskDetailData(item)
                                        setModala(true)
                                    }}
                                >
                                    <p className="addMinPrepText">
                                        {item?.taskName}
                                    </p>
                                    <div className="addAssiTextDiv">
                                        <p className="addAssignText">
                                            Assigned to: <span>{item?.assignToUserName}</span>
                                        </p>
                                        <figure className="smallFisiIconFig">
                                            <img src={fisiIconDrop} alt="..." />
                                        </figure>
                                    </div>
                                </div>
                            )

                        }
                    </div>
                    <div>
                        <button className="addInTaskBtn">+ Add New Task</button>
                    </div>
                </div> */}
      </div >

      {modala && <TaskPopUp closemodal={setModala} detailData={taskDetailData} getTaskData={getTaskData} />}
    </div >
  );
};

export default TodoList;
