
import React, { useState, useEffect } from 'react';
import { DateRange } from 'react-date-range';
import moment from 'moment';
import CustomLoaderLine from '../../Component/Loader/CustomLoaderLine';
import TotalDOcumentModal from './TotalDOcumentModal';
import { useLanguage } from '../../Providers/LanguageContext';
import HttpClient from '../../utils/HttpClient';
import CustomerInvoiceModal from './CustomerInvoiceModal';
import BankStatementsModal from './BankStatementsModal';
import Fin_CorDocsModal from './Fin_CorDocsModal';

const NewDocsVault = ({ docData }) => {
    const { language, currentLanguage } = useLanguage();
    const [openTotalModal, setOpenTotalModal] = useState(false);
    const [singleTotalDoc, setSingleTotalDoc] = useState([]);
    const [isShowCalender, setIsShowCalender] = useState(false);
    const [date, setDate] = useState({ startDate: "", endDate: "" });
    const [custInvoiceTotal, setCustInvoiceTotal] = useState({})
    const [bankStatement, setBankStaement] = useState({})
    const [openCustomerInvoiceModal, setOpenCustomerInvoiceModal] = useState(false);
    const [openBankStatementsModal, setOpenBankStatementsModal] = useState(false);
    const [finCorModal, setFinCorModal] = useState(false)
    const [bankCardValue, setBankCardValue] = useState("")
    const [bankSubCatModal, setBankSubCatModal] = useState(false);

    const initState = {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection'
    };
    const [state, setState] = useState(initState);

    // >>>>Showing all card value<<<<
    const [cards, setCards] = useState([
        { typeName: "Customer Invoice", total: "0", pastDue: "0" },
        { typeName: "Customer Agreement(s)", total: "0", signed: "0" },
        { typeName: "Customer Quotes", total: "0", open: "0" },
        { typeName: "Accounts Receivable Report", total: "0" },
        { typeName: "Supplier Invoices", total: "0", pastDue: "0" },
        { typeName: "Supplier Agreement(s)", total: "0", signed: "0" },
        { typeName: "Supplier Purchase Orders", total: "0", open: "0" },
        { typeName: "Accounts Payable Report", total: "0" },
        { typeName: "Bank Statements", total: "0", missing: "0" },
        { typeName: "Financial Statements", total: "0" },
        { typeName: "Corporate Docs", total: "0" },
        { typeName: "Other", total: "0" },
    ]);

    // >>>>Handling all card details<<<<
    useEffect(() => {
        const documentNames = [
            "Income Statement",
            "Balance Sheet",
            "Cash Flow Statement",
            "Profit & Loss Statement",
            "Monthly-End Financial Report",
            "13 Week Cash Flow Statement",
            "Personal Financial Statement",
            "Business Financial Statement"
        ];

        const docTwoNames = [
            "Non-Disclosure Agreement(s)",
            "Loan Agreements",
            "Operating Agreement",
            "Capitalization Table (owners)",
            "Tax Return(s)"
        ];

        // Update cards array after fetching data
        const updatedCards = [
            { typeName: "Customer Invoice", total: `${custInvoiceTotal?.totalDocuments}`, pastDue: `${custInvoiceTotal?.pastDueDocuments}` },
            { typeName: "Customer Agreement(s)", total: "0", signed: "0" },
            { typeName: "Customer Quotes", total: "0", open: "0" },
            { typeName: "Accounts Receivable Report", total: "0" },
            { typeName: "Supplier Invoices", total: "0", pastDue: "0" },
            { typeName: "Supplier Agreement(s)", total: "0", signed: "0" },
            { typeName: "Supplier Purchase Orders", total: "0", open: "0" },
            { typeName: "Accounts Payable Report", total: "0" },
            { typeName: "Bank Statements", total: `${bankStatement?.totalDataCount ?? 0}`, missing: `${bankStatement?.totalMissingBankStatements ?? 0}` },
            { typeName: "Financial Statements", total: "0" },
            { typeName: "Corporate Docs", total: "0" },
            { typeName: "Other", total: "0" },
        ].map(card => {
            if (card.typeName === "Financial Statements") {
                // Calculate total for Financial Statements card
                const total = docData
                    .filter(doc => documentNames.includes(doc.typeNameInEnglish))
                    .reduce((acc, doc) => acc + (doc.total || 0), 0);
                return { ...card, total };
            } else if (card.typeName === "Corporate Docs") {
                const total = docData
                    .filter(doc => docTwoNames.includes(doc.typeNameInEnglish))
                    .reduce((acc, doc) => acc + (doc.total || 0), 0);
                return { ...card, total };
            } else if (card.typeName !== "Customer Invoice" && card.typeName !== "Bank Statements") {
                const matchingDoc = docData.find(doc => doc.typeNameInEnglish === card.typeName);
                if (matchingDoc) {
                    return { ...card, total: matchingDoc.total };
                }
            }
            return card;
        });
        setCards(updatedCards);
    }, [custInvoiceTotal, docData, bankStatement]);

    // >>>>Handling Different Modal<<<< 
    const handleTotalClick = (typeName, clickValue) => {
        console.log("6df54651df", typeName, clickValue)
        if (typeName === "Customer Invoice") {
            setOpenCustomerInvoiceModal(true);
            if (clickValue === "Total") {
                setSingleTotalDoc(custInvoiceTotal?.pdf2Urls)
            } else if (clickValue === "Past Due") {
                setSingleTotalDoc(custInvoiceTotal?.pastDuePdf2Urls)
            }
        } else if (typeName === "Bank Statements") {
            setOpenBankStatementsModal(true);
            if (clickValue === "Investment") {
                setBankCardValue("Investment")
            } else if (clickValue === "BookKeeper") {
                setBankCardValue("BookKeeper")
            }
        } else if (typeName === "Financial Statements") {
            const documentNames = [
                "Income Statement",
                "Balance Sheet",
                "Cash Flow Statement",
                "Profit & Loss Statement",
                "Monthly-End Financial Report",
                "13 Week Cash Flow Statement",
                "Personal Financial Statement",
                "Business Financial Statement"
            ];
            const total = docData.filter(doc => documentNames.includes(doc.typeNameInEnglish))
            const updatedData = total.flatMap(doc =>
                doc?.data?.map(ele => ({
                    file: ele?.file,
                    documentName: ele?.documentName
                }))
            );
            console.log("6df54651", total, updatedData)
            setFinCorModal(true)
            setSingleTotalDoc(updatedData)
        } else if (typeName === "Corporate Docs") {
            const docTwoNames = [
                "Non-Disclosure Agreement(s)",
                "Loan Agreements",
                "Operating Agreement",
                "Capitalization Table (owners)",
                "Tax Return(s)"
            ];
            const total = docData.filter(doc => docTwoNames.includes(doc.typeNameInEnglish))
            const updatedData = total.flatMap(doc =>
                doc?.data?.map(ele => ({
                    file: ele?.file,
                    documentName: ele?.documentName
                }))
            );
            setFinCorModal(true)
            setSingleTotalDoc(updatedData)
        } else {
            const matchingDoc = docData.find(doc => doc.typeNameInEnglish === typeName);
            if (matchingDoc) {
                const docDetails = matchingDoc.data.map(docItem => ({
                    file: docItem.file,
                    documentName: docItem.documentName,
                }));
                setSingleTotalDoc(docDetails);
                setOpenTotalModal(true);
            }
        }
    };

    // >>>>Toggel Show Calender<<<<
    const toggleShowCalender = () => {
        setIsShowCalender(!isShowCalender);
    };

    // >>>>Fetching Customer Invoice<<<<
    const getCustomerInvoiceData = async () => {
        const res = await HttpClient.requestData("customerinvoicevault", "GET");
        if (res && res?.status) {
            setCustInvoiceTotal(res?.data)
        }
    }

    // >>>>Fetching Bank Statements<<<<
    const getBankStatementsData = async () => {
        const res = await HttpClient.requestData("bankstatements", "GET");
        if (res && res?.status) {
            setBankStaement(res?.data)
        }
    }

    useEffect(() => {
        getCustomerInvoiceData();
        getBankStatementsData();
    }, [])
    return (
        <>
            <section className='documentManagerslider_sec' onClick={() => setIsShowCalender(false)}>
                <div className='container'>
                    <div className='allDocument_Vault'>
                        {cards.map((card, index) => (
                            <div key={index} className='documentVault_box'>
                                <div className='toparea'>
                                    <div className='box'>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Name"
                                        />
                                    </div>

                                    <div
                                        className='box'
                                        style={{ cursor: "pointer", position: "relative" }}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            toggleShowCalender();
                                        }}
                                    >
                                        <span className="form-control">
                                            {(date?.startDate && date?.endDate)
                                                ? <span style={{ whiteSpace: "nowrap" }}>{date?.startDate + " - " + date?.endDate}</span>
                                                : "Date Range"}
                                        </span>
                                    </div>
                                </div>

                                <div className='bottomarea'>
                                    <p className='name'>{card.typeName}</p>
                                    <div className='d-flex align-items-center justify-content-around'>
                                        <div className='pointarea'>
                                            <p>{card.typeName === "Bank Statements" ? "" : "Total"}</p>
                                            <span
                                                onClick={() => handleTotalClick(card.typeName, card.typeName === "Bank Statements" ? "Investment" : "Total")}
                                                style={{ cursor: "pointer" }}
                                            >
                                                {false ? <CustomLoaderLine height="25" width="25" /> : card.typeName === "Bank Statements" ? "Investor" : card.total}
                                            </span>
                                        </div>

                                        {card.pastDue && <div className='pointarea'>
                                            <p>Past Due</p>
                                            <span
                                                onClick={() => handleTotalClick(card.typeName, "Past Due")}
                                                style={{ cursor: "pointer" }}
                                            >{card.pastDue}</span>
                                        </div>}

                                        {card.signed && <div className='pointarea'>
                                            <p>Signed</p>
                                            <span>{card.signed}</span>
                                        </div>}

                                        {card.open && <div className='pointarea'>
                                            <p>Open</p>
                                            <span>{card.open}</span>
                                        </div>}

                                        {card.missing && <div className='pointarea'>
                                            <p>{card.typeName === "Bank Statements" ? "" : "Missing"}</p>
                                            <span
                                                onClick={() => handleTotalClick(card.typeName, card.typeName === "Bank Statements" ? "BookKeeper" : "Missing")}
                                                style={{ cursor: "pointer" }}
                                            >{card.typeName === "Bank Statements" ? "BookKeeper" : card.missing}</span>
                                        </div>}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            {isShowCalender &&
                <div className='DocVaultCalender' onClick={(e) => e.stopPropagation()}>
                    <div className='DocVaultCalender_body'>
                        <button className='closebtn' onClick={toggleShowCalender}><i className="fa-solid fa-xmark"></i></button>
                        <DateRange
                            editableDateInputs={true}
                            onChange={item => {
                                setState(item.selection)
                                setDate({
                                    startDate: moment(item.selection?.startDate).format("YYYY-MM-DD"),
                                    endDate: moment(item?.selection?.endDate).format("YYYY-MM-DD")
                                });
                            }}
                            moveRangeOnFirstSelection={false}
                            ranges={[state]}
                        />

                        <div className='d-flex justify-content-between p-2'>
                            <button
                                className='btn btn-sm btn-primary'
                                onClick={() => {
                                    setState(initState);
                                    setDate({
                                        startDate: moment().format("YYYY-MM-DD"),
                                        endDate: moment().format("YYYY-MM-DD"),
                                    });
                                }}
                            >
                                Today
                            </button>

                            <button
                                className='btn btn-sm btn-primary'
                                onClick={() => {
                                    setDate({ startDate: "", endDate: "" });
                                    setState(initState);
                                    setIsShowCalender(false);
                                }}
                            >
                                Clear
                            </button>
                        </div>
                    </div>
                </div>
            }


            {openTotalModal && (
                <TotalDOcumentModal
                    modalIsOpen={openTotalModal}
                    closeModal={() => setOpenTotalModal(false)}
                    singleTotalDoc={singleTotalDoc}
                />)}

            {openCustomerInvoiceModal && (
                <CustomerInvoiceModal
                    modalIsOpen={openCustomerInvoiceModal}
                    closeModal={() => setOpenCustomerInvoiceModal(false)}
                    singleTotalDoc={singleTotalDoc}
                />)}

            {openBankStatementsModal && (
                <BankStatementsModal
                    modalIsOpen={openBankStatementsModal}
                    closeModal={() => setOpenBankStatementsModal(false)}
                    bankCardValue={bankCardValue}
                />
            )}

            {finCorModal && (
                <Fin_CorDocsModal
                    modalIsOpen={finCorModal}
                    closeModal={() => setFinCorModal(false)}
                    singleTotalDoc={singleTotalDoc}
                />
            )}
        </>
    );
};

export default NewDocsVault;