import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import HttpClient from "../../utils/HttpClient";
import toast from "react-hot-toast";
import { useUserData } from "../../Providers/UserWrapper";

const AcceptCOnnectReq = () => {
  const { isLogin } = useUserData();
  const params = useParams();
  const navigate = useNavigate();
  const [statusData, setStatusData] = useState({});

  // accept
  const handleAccept = async () => {
    const data = {
      acceptStatus: "accepted",
    };
    const res = await HttpClient.requestData(
      "acceptInvite/" + params?.id,
      "PUT",
      data
    );
    console.log("ressdd", res);
    if (res && res?.status) {
      toast.success("Request Accepted Successfully");
      navigate("/");
    } else {
      toast.error(res.message || "Error");
    }
  };

  // reject
  const handleReject = async () => {
    const data = {
      acceptStatus: "cancelled",
    };
    const res = await HttpClient.requestData(
      "acceptInvite/" + params?.id,
      "PUT",
      data
    );
    console.log("ressdd", res);
    if (res && res?.status) {
      toast.success("Request Rejected Successfully");
      navigate("/");
    } else {
      toast.error(res.message || "Error");
    }
  };

  // gte Accept Status
  const getAcceptStatus = async () => {
    const res = await HttpClient.requestData(
      "view-invitation-status/" + params?.id
    );
    // console.log("resStt", res)
    if (res && res?.status) {
      setStatusData(res?.data);
    }
  };

  useEffect(() => {
    if (isLogin) {
      getAcceptStatus();
    } else {
        navigate(`/login?redirectFrom=accept_bookkeeper&param_id=${params?.id}`)
    }
  }, []);

  return (
    <div className="container">
      <section className="acceptCard mt-5">
        <div className="acceptCardBody">
          <p>Do You Want to Accept?</p>
          <button
            className="btn btn-success mr-2"
            onClick={() => handleAccept()}
          >
            Accept
          </button>

          <button className="btn btn-danger" onClick={() => handleReject()}>
            Reject
          </button>
        </div>

        {/* {
                    statusData?.acceptStatus === "accepted" &&
                    <div className='acceptCardBody'>
                        <p className='text-success'>Request Has Been Accepted</p>
                    </div>
                } */}
        {/* {
                    statusData?.acceptStatus === "cancelled" &&
                    <div className='acceptCardBody'>
                        <p className='text-danger'>Request Has Been Rejected</p>
                    </div>
                } */}
        {/* {
                    statusData?.acceptStatus === "noAction" &&
                    <div className='acceptCardBody'>
                        <p>Do You Want to Accept?</p>
                        <button
                            className='btn btn-success mr-2'
                            onClick={() => handleAccept()}
                        >
                            Accept
                        </button>

                        <button
                            className='btn btn-danger'
                            onClick={() => handleReject()}
                        >
                            Reject
                        </button>
                    </div>
                } */}
      </section>
    </div>
  );
};

export default AcceptCOnnectReq;
