import React, { useEffect, useMemo, useState } from 'react';
import FisEventIcon from "../../../images/FisEventIcon.png";
import HttpClient from '../../../utils/HttpClient';
import { useUserData } from '../../../Providers/UserWrapper';
import moment from 'moment';
import toast from 'react-hot-toast';
import { useLanguage } from '../../../Providers/LanguageContext';
import Select from 'react-select';


const EventSidebar = ({
  getEventData,
  setModalb,
  setTaskDetailData,
  eventAllData,
  selectAssignUserMemo,
  selectCustVendorMemo
}) => {
  const { isMainUserMemo, profileData } = useUserData();
  const { language } = useLanguage();

  const initSelectData = { value: "", label: "" }
  const initEvent = {
    date: '',
    startTime: '',
    endTime: '',
    eventName: '',
    eventNotes: '',
    assignedUser: initSelectData,
    linkedUser: initSelectData,
    mtgLinkAddress: '',
    firstName: '',
    lastName: '',
    eventStatus: '',
  };
  const [formData, setFormData] = useState(initEvent);
  const [searchedData, setSearchedData] = useState(initSelectData);


  // console.log("eventAllData", eventAllData)


  const searchSelectOptionMemo = useMemo(() => {
    return eventAllData?.map((item) => ({
      value: item,
      label: item?.eventName
    }))
  }, [eventAllData])


  //on change 
  const handleEventChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  }


  // validate
  const validate = () => {
    if (!formData.date) {
      toast.error("Date is Required");
      return true
    }
    if (!formData.startTime) {
      toast.error("Start Time is Required");
      return true
    }
    if (!formData.endTime) {
      toast.error("End Time is Required");
      return true
    }
    if (!formData.eventName) {
      toast.error("Event Name is Required");
      return true
    }
    // if (!formData.eventStatus) {
    //     toast.error("Event Status is Required");
    //     return true
    // }
    // if (!formData.assignUser) {
    //     toast.error("Assign User is Required");
    //     return true
    // }

    return false;
  }

  //submit click on schedule
  const handleSchrdule = async () => {
    if (validate()) {
      return
    }


    const data = {
      eventName: formData.eventName,
      eventNotes: formData.eventNotes,
      eventStatus: formData.eventStatus ? formData.eventStatus : 'open',
      assignToUser: isMainUserMemo ? formData.assignedUser?.value : profileData?._id,
      firstName: formData.firstName,
      lastName: formData.lastName,
      linkedUser: formData.linkedUser?.value,
      mtgLinkAddress: formData.mtgLinkAddress,
      selectDate: moment(formData.StartDate).format('YYYY-MM-DD'),
      startTime: formData.startTime,
      endTime: formData.endTime,
    };

    const res = await HttpClient.requestData("add-event-eventDetails", "POST", data);
    // console.log("ressEvv", res);
    if (res && res?.status) {
      toast.success("Event Added Successfully");
      getEventData();
      setFormData(initEvent);
    } else {
      toast.error(res?.message || "Something Went Wrong");
    }
  }


  return (
    <div>
      {/* form */}
      <form action="" className="taskEvForm">
        <div>
          <div className="taskMainInpDiv">
            {/* search */}
            <div className="taskTabInps">
              <Select
                className="taskUpperInpSearch"
                placeholder={language.search}
                isClearable={true}
                isSearchable={true}
                // name="linkedUser"
                value={searchedData?.value === "" ? null : searchedData}
                options={searchSelectOptionMemo}
                onChange={e => {
                  // console.log('euser', e);
                  if (e) {
                    // setSearchedData(e);
                    setSearchedData(initSelectData);
                    setModalb(true);
                    setTaskDetailData(e.value)
                  } else {
                    setSearchedData(initSelectData);
                  }
                }}
              />

              {/* <input type="text" placeholder={language.search} className="taskSearInp" /> */}
              <i class="fa-solid fa-magnifying-glass taskSearGlass" style={{ left: "108px" }}></i>
            </div>

            {/* date */}
            <div className="taskTabInps">
              <input
                className="taskSearInp"
                type="date"
                // placeholder={language.date}
                name="date"
                value={formData.date}
                onChange={e => handleEventChange(e)}
              />
            </div>
          </div>

          <div className="taskMainInpDiv">
            {/* start time */}
            <div className="taskTabInps">
              <input
                // type="datetime-local"
                type="time"
                className="taskDateTimeInp"
                name="startTime"
                value={formData.startTime}
                onChange={e => handleEventChange(e)}
              />
            </div>

            {/* end time */}
            <div className="taskTabInps">
              <input
                // type="datetime-local"
                type="time"
                className="taskDateTimeInp"
                name="endTime"
                value={formData.endTime}
                onChange={e => handleEventChange(e)}
              />
            </div>
          </div>

          {/* Event Name */}
          <div className="taskMainInpDiv">
            <input
              type="text"
              placeholder={language.event_name}
              className="taskUpperInp"
              name="eventName"
              value={formData.eventName}
              onChange={e => handleEventChange(e)}
            />
          </div>

          {/* Event Notes */}
          <div className="taskMainInpDiv">
            <input
              type="text"
              placeholder={language.event_note}
              className="taskUpperInp"
              name="eventNotes"
              value={formData.eventNotes}
              onChange={e => handleEventChange(e)}
            />
          </div>

          {/* status */}
          <div className="taskMainInpDiv">
            <select
              id=""
              className="taskUpperInp"
              name="eventStatus"
              value={formData.eventStatus}
              onChange={e => handleEventChange(e)}
            >
              <option value="" disabled selected>
                {language.event_Status_optional}
              </option>

              <option value="open">{language.open}</option>
              <option value="doing">{language.doing}</option>
              <option value="done">{language.done}</option>
              <option value="ideas">{language.ideas}</option>
              <option value="onhold">{language.on_hold}</option>
            </select>
          </div>

          {/* type */}
          {/* <div className="taskTabInps">
                            <select
                                className="taskUpperInp"
                                name='type'
                                value={formData.type}
                                onChange={(e) => handleEventChange(e)}
                            >
                                <option value="" disabled selected>
                                    {language.Type_select}
                                </option>
                                <option value="">{language.option}1</option>
                                <option value="">{language.option}2</option>
                            </select>
                        </div>
 */}

          {/* Assign (user) */}
          {isMainUserMemo && (
            <div className="taskMainInpDiv">
              <Select
                className="taskUpperInpSearch"
                placeholder="Assign to User(Select)"
                isClearable={true}
                isSearchable={true}
                name="assignedUser"
                value={formData?.assignedUser?.value === "" ? null : formData?.assignedUser}
                options={selectAssignUserMemo}
                onChange={e => {
                  if (e) {
                    setFormData(prev => ({ ...prev, assignedUser: e }));
                  } else {
                    setFormData(prev => ({ ...prev, assignedUser: initSelectData }));
                  }
                }}
              />

              {/* <select
                                className="taskUpperInp"
                                name='assignUser'
                                value={formData.assignUser}
                                onChange={(e) => handleEventChange(e)}
                            >
                                <option value="" disabled selected>

                                    {language.Assign_user}
                                </option>
                                <option value={profileData?._id}>Me</option>
                                {
                                    assignUserData?.map((item, i) =>
                                        <option value={item?._id}>{item?.name + " " + item?.surName}</option>
                                    )
                                }
                            </select> */}
            </div>
          )}

          {/* Link Users, Customers. Vendors */}
          <div className="taskMainInpDiv">
            <Select
              className="taskUpperInpSearch"
              placeholder={language.Link_Users_Customers_Vendors}
              isClearable={true}
              isSearchable={true}
              name="linkedUser"
              value={formData?.linkedUser?.value === "" ? null : formData?.linkedUser}
              options={selectCustVendorMemo}
              onChange={e => {
                // console.log('euser', e);
                if (e) {
                  setFormData(prev => ({ ...prev, linkedUser: e }));
                } else {
                  setFormData(prev => ({ ...prev, linkedUser: initSelectData }));
                }
              }}
            />
          </div>

          {/* <div className="taskMainInpDiv">
            <input
              type="text"
              className="taskUpperInp"
              placeholder={language.Link_Users_Customers_Vendors}
              name="linkedUser"
              value={formData.linkedUser}
              onChange={e => handleEventChange(e)}
            />
          </div> */}

          <div className="taskMainInpDiv">
            {/* first */}
            <div className="taskTabInps">
              <input
                type="text"
                className="taskUpperInp"
                placeholder={language.first}
                name="firstName"
                value={formData.firstName}
                onChange={e => handleEventChange(e)}
              />
            </div>

            {/* last */}
            <div className="taskTabInps">
              <input
                type="text"
                className="taskUpperInp"
                placeholder={language.last}
                name="lastName"
                value={formData.lastName}
                onChange={e => handleEventChange(e)}
              />
            </div>
          </div>
          <div className="taskMainInpDiv">
            {/* Mtg Link / Address / Dial Info */}
            <input
              type="text"
              className="taskUpperInp"
              placeholder={language.Mtg_Link_Info}
              name="mtgLinkAddress"
              value={formData.mtgLinkAddress}
              onChange={e => handleEventChange(e)}
            />
          </div>
        </div>
        <div className="postTaskBtnDiv">
          <button
            type="button"
            onClick={e => {
              handleSchrdule();
            }}
          >
            {language.Schedule}
          </button>
        </div>
      </form>

      <div className="orDrFigDiv">
        <p className="orDrText">{language.Or_drop_to_calendar}</p>
        <figure className="fisiDrIconFig">
          <img src={FisEventIcon} alt="..." />
        </figure>
      </div>



      {/* Quick View */}
      {eventAllData?.length !== 0 && (
        <div className="quickMainViewDiv">
          <p className="ouickViewHead">{language.Quick_View}</p>
          <div className="quickViewUlDiv">
            <ul style={{ maxHeight: "255px" }} className="quickViewUl">
              {eventAllData?.map((item, i) => (
                <li
                  key={i}
                  className="quickViewLi"
                  onClick={() => {
                    setModalb(true);
                    setTaskDetailData(item);
                  }}
                >
                  <div>
                    <p className="quickAnsText">{item?.eventName}</p>
                    <div className="quickDoneTextDiv">
                      <p className="quickDoneText quickGreenDoneDot">{item?.eventStatus}</p>
                    </div>
                  </div>
                  <figure className="quickFirstIconFig">
                    <img src={FisEventIcon} alt="..." />
                  </figure>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
}

export default EventSidebar
