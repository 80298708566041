import React, { useEffect, useState } from 'react'
import "react-datetime/css/react-datetime.css";
import Datetime from "react-datetime";
import moment from 'moment';
import { toast } from 'react-hot-toast';
import Swal from 'sweetalert2';
import HttpClient from '../../utils/HttpClient';
import { useLanguage } from '../../Providers/LanguageContext';

function InvoiceInput({ type = "text", field,label,value }) {

    const { language } = useLanguage();

    return (
        <div className='mt-3' style={{  }}>
            <div className="td-details mb-2" style={{
                // display: "flex",
                // justifyContent: "center", alignItems: "center",
                // gap: "8px", width: "100%", background: "lightgray", padding: "10px"
            }}
            >
              <div style={{background:"whitesmoke",padding:"10px"}}>
              <label htmlFor="inputdate" style={{ marginRight: "0", fontSize: "20px", width:"100%", textAlign:"left" }}>
                   {label}
                </label>
                <div className='custom-wrap' style={{display: "flex",justifyContent:"start"}}>
                {type !== "select" && (
                    <input type={type} style={{ width: "100%" }} value={value} id="inputdate" disabled={true} />
                )}
              </div>
                </div>
               </div>
               
        </div>
    )
}

export default InvoiceInput