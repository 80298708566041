import React, { useState } from 'react'
import producttour_bg from "../../images/producttour_bg.png";
import "./Producttour.css"
import { Link, useNavigate } from 'react-router-dom';
// import { Helmet } from 'react-helmet';
const Producttour = () => {
  // const [bgproducttrbtn,setBgproducttrbtn] =useState(false);
  // const navigate=useNavigate()
  // const handleproduct = () => {
  //   setBgproducttrbtn(true);
  //   navigate("/product_tourbusiness")
  // }
  return (
    <>
      {/* <section className='producttourmain' id='login_banner_area' style={{ backgroundImage: `url(${producttour_bg})` }}>
        <div className='container'>
          <div className="row">
            <div className='col-xl-12 col-lg-12 col-md-12 col-12'>
              <div className='banner-top-heading'>
                <h4>FINANCIAL VISIBILITY</h4>
              </div>
              <div class="banner-second-heading">
                <h2>WELCOME TO FISIBILITY</h2>
              </div>
              <div className="banner-btn">
                <Link 
                  className="producttourbtn" to="https://fisibility.navattic.com/da102wd">
                  Business
                </Link>
                <Link 
                  className="producttourbtn" to="https://fisibility.navattic.com/x2a0u9a"
                  
                >
                  Personal
                </Link>
                <Link 
                  className="producttourbtn" to="https://fisibility.navattic.com/qq40ckx"
                  
                >
                  Bookkeeper
                </Link>
                <Link
                  className="producttourbtn" to="https://fisibility.navattic.com/k890mcu"
                 
                >
                  Investor
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section> */}
         {/* <Helmet>
        <link rel="canonical" href="https://fisibility.com/product-tour" />
      </Helmet> */}

      <section className='productourmain'>
        <div className='container'>
          <div className='row'>
            <div className='col-xl-6 col-lg-6 col-md-12 col-12 mb-resp'>
              <div className='userproducttour'>
                <p className='userproducttourtxtpp'>
                  <i class="fa-solid fa-user"></i>
                  Business
                </p>
              </div>
              <div className='iframedivimg' style={{ backgroundImage: `url(${producttour_bg})` }}>
                <div className='container'>
                  <div className='row'>

                    <div className='col-xl-12 col-lg-12 col-md-12 col-12'>

                      <div className='banner-top-heading headbtnstartdivflx'>
                        <h4>FINANCIAL VISIBILITY</h4>
                        <div className='startbtnproductmrlt'>
                          <Link className='startbtnproduct' to="https://fisibility.navattic.com/da102wd">
                            Start
                            <i class="fa-solid fa-circle-check"></i>
                          </Link>
                        </div>
                      </div>
                      <div class="banner-second-heading">
                        <h2>WELCOME TO FISIBILITY</h2>
                      </div>
                      <div className="banner-btn">
                        <Link
                          className="producttourbtn" style={{backgroundColor:"#102a3d", color:"#fff"}}>
                          Business
                        </Link>
                        <Link
                          className="producttourbtn">
                          Personal
                        </Link>
                        <Link
                          className="producttourbtn">
                          Bookkeeper
                        </Link>
                        <Link
                          className="producttourbtn">
                          Investor
                        </Link>
                      </div>
                    </div>

                  </div>
                </div>
              </div>


            </div>
            <div className='col-xl-6 col-lg-6 col-md-12 col-12 mb-resp'>

              <div className='userproducttour'>
                <p className='userproducttourtxtpp'>
                <i class="fa-solid fa-user"></i>
                  Investor
                </p>
              </div>
              <div className='iframedivimg' style={{ backgroundImage: `url(${producttour_bg})` }}>
                <div className='container'>
                  <div className='row'>
                    <div className='col-xl-12 col-lg-12 col-md-12 col-12'>

                      <div className='banner-top-heading headbtnstartdivflx'>
                        <h4>FINANCIAL VISIBILITY</h4>
                        <div className='startbtnproductmrlt'>
                          <Link className='startbtnproduct' to="https://fisibility.navattic.com/k890mcu" >
                            Start
                            <i class="fa-solid fa-circle-check"></i>
                          </Link>
                        </div>
                      </div>
                      <div class="banner-second-heading">
                        <h2>WELCOME TO FISIBILITY</h2>
                      </div>
                      <div className="banner-btn">
                        <Link
                          className="producttourbtn">
                          Business
                        </Link>
                        <Link
                          className="producttourbtn">
                          Personal
                        </Link>
                        <Link
                          className="producttourbtn">
                          Bookkeeper
                        </Link>
                        <Link
                          className="producttourbtn" style={{backgroundColor:"#102a3d", color:"#fff"}}>
                          Investor
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


            </div>
            <div className='col-xl-6 col-lg-6 col-md-12 col-12   mb-resp'>
              <div className='userproducttour'>
                <p className='userproducttourtxtpp'>
                <i class="fa-solid fa-user"></i>
                  Personal
                </p>
              </div>
              <div className='iframedivimg' style={{ backgroundImage: `url(${producttour_bg})` }}>
                <div className='container'>
                  <div className='row'>
                    <div className='col-xl-12 col-lg-12 col-md-12 col-12'>

                      <div className='banner-top-heading headbtnstartdivflx'>
                        <h4>FINANCIAL VISIBILITY</h4>
                        <div className='startbtnproductmrlt'>
                          <Link className='startbtnproduct' to="https://fisibility.navattic.com/x2a0u9a">
                            Start
                            <i class="fa-solid fa-circle-check"></i>
                          </Link>
                        </div>
                      </div>
                      <div class="banner-second-heading">
                        <h2>WELCOME TO FISIBILITY</h2>
                      </div>
                      <div className="banner-btn">
                        <Link
                          className="producttourbtn">
                          Business
                        </Link>
                        <Link
                          className="producttourbtn" style={{backgroundColor:"#102a3d", color:"#fff"}}>
                          Personal
                        </Link>
                        <Link
                          className="producttourbtn">
                          Bookkeeper
                        </Link>
                        <Link
                          className="producttourbtn">
                          Investor
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


            </div>
            <div className='col-xl-6 col-lg-6 col-md-12 col-12  mb-resp'>
              <div className='userproducttour'>
              <p className='userproducttourtxtpp'>
              <i class="fa-solid fa-user"></i>
                  Bookkeeper
                </p>
              </div>
              <div className='iframedivimg' style={{ backgroundImage: `url(${producttour_bg})` }}>
                <div className='container'>
                  <div className='row'>
                    <div className='col-xl-12 col-lg-12 col-md-12 col-12'>

                      <div className='banner-top-heading headbtnstartdivflx'>
                        <h4>FINANCIAL VISIBILITY</h4>
                        <div className='startbtnproductmrlt'>
                          <Link className='startbtnproduct' to="https://fisibility.navattic.com/qq40ckx">
                            Start
                            <i class="fa-solid fa-circle-check"></i>
                          </Link>
                        </div>
                      </div>
                      <div class="banner-second-heading">
                        <h2>WELCOME TO FISIBILITY</h2>
                      </div>
                      <div className="banner-btn">
                        <Link
                          className="producttourbtn">
                          Business
                        </Link>
                        <Link
                          className="producttourbtn">
                          Personal
                        </Link>
                        <Link
                          className="producttourbtn" style={{backgroundColor:"#102a3d", color:"#fff"}}>
                          Bookkeeper
                        </Link>
                        <Link
                          className="producttourbtn">
                          Investor
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Producttour
