import React from 'react'
import iMg1 from "../../images/ilustraciones soluciones industriales_ENERGIA 1 .png";
import iMg2 from "../../images/ilustraciones soluciones industriales_ENERGIA 2.png";
import iMg3 from "../../images/energia_ENERGIA 3.png";
import iMg3en from "../../images/energia_ENERGIA 3 EN.png";
import bannerimage from "../../images/ENERGIA 4_Mesa de trabajo 1 copia 72.png";
import { useLanguage } from '../../Providers/LanguageContext';
import SignUpButton from './SignUpButton';

const Energy = () => {
  const { language, currentLanguage } = useLanguage();
  return (
    <>
      <section id='agriculture_sec'>
        <div className='container'>
          <div className='row mb-5'>
            <div className='col-md-6 col-12 m-auto'>
              <div className='agriculture_head'>
                <h3 style={{ color: "#3c3c3b" }}>{language.energy}</h3>
              </div>

              <div className='agriculture_headtext'>
                <h4 style={{ color: "#0d2536" }}>{language.real_time_tracking_expance}</h4>
                <p>{language.fisi_energy_cpmpany}</p>
              </div>
            </div>
            <div className='col-md-6 col-12 m-auto'>
              <div className='agriculture_img'>
                <img src={iMg1} className='img-fluid' alt='' />
              </div>
            </div>
          </div>

          <div className='row row_reverse mb-5'>
            <div className='col-md-6 col-12 m-auto'>
              <div className='agriculture_img'>
                <img src={iMg2} className='img-fluid' alt='' />
              </div>
            </div>
            <div className='col-md-6 col-12 m-auto'>
              <div className='agriculture_headtext'>
                <h4 style={{ color: "#3c3c3b", textAlign: "right" }}>{language.improved_risk_management}</h4>
                <p>{language.energy_sector_subjected}</p>
              </div>
            </div>
          </div>

          <div className='row mb-5'>
            <div className='col-md-12 col-12'>
              <div className='agriculture_headtext'>
                <h4 style={{ color: "#0d2536", textAlign: "left" }}>{language.enhance_finance_report}</h4>
                <p>{language.energy_comp_subject}</p>
              </div>
            </div>
          </div>

          <div className='row wrapBg_Img' style={{ backgroundImage: `url('${bannerimage}')` }}>

            <div className='col-md-6 col-12 m-auto'></div>
            <div className='col-md-6 col-12 m-auto'>
              <div className='agriculture_headtext'>
                <h4 style={{ color: "#3c3c3b", textAlign: "right" }}>{language.supplier_invoice_audit}</h4>
                <p>{language.fisi_audit_supplier}</p>
              </div>
            </div>


            <div className='col-md-6 col-12 m-auto'>
              <div className='agriculture_headtext'>
                <h4 style={{ color: "#0d2536", textAlign: "left" }}>{language.resource_cllocation_opt}</h4>
                <p>{language.data_analysis_capa}</p>
              </div>
            </div>

            <div className='col-md-6 col-12 m-auto'>
              <div className='agriculture_img' style={{ maxWidth: "60%", }}>
                {
                  currentLanguage === 'spanish'
                    ?
                    <img src={iMg3} className='img-fluid' alt='' style={{ marginBottom: "30px" }} />
                    :
                    <img src={iMg3en} className='img-fluid' alt='' style={{ marginBottom: "30px" }} />

                }
                {/* <div className='registrarAnddescargar' style={{ transform: "translate(-50% , -50%)", top: "100%", }}>
                  <button className='registrar_Btn'>Registrarse</button>
                  <button className='descargar_Btn'>Descargar</button>
                </div> */}
                <SignUpButton />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Energy