import React, { useEffect, useState } from 'react'
import { useLanguage } from '../../Providers/LanguageContext';
import HttpClient from '../../utils/HttpClient';

const ViewCapitalBalance = ({ setBuildRepData }) => {
    const { language, currentLanguage } = useLanguage();

    const initData = {
        fname: "",
        lname: "",
        role: "",
        email: "",
        noOfUnits: "",
        typeOfUnits: "",
        percent: ""
    }
    const [ownerData, setOwnerData] = useState([initData]);
    const [totalData, setTotalData] = useState({ role: 1, unit: "", percent: "" });

    const [formData, setFormData] = useState({ countryIncorporate: "", stateIncorporate: "", governmentId: "" });


    const getInvoiceStatement = async () => {
        const res = await HttpClient.requestData("reportbuilder-view-capitalization", "GET");
        console.log("resscall", res);
        if (res && res?.status) {
            setBuildRepData(res?.data)
            const incdata = res?.data?.Data?.map((item) => ({
                fname: item?.firstName,
                lname: item?.lastName,
                role: item?.role,
                email: item?.email,
                noOfUnits: item?.units,
                typeOfUnits: item?.typeofUnits,
                percent: item?.percentage
            }))
            setOwnerData(incdata);

            setTotalData({
                role: res?.data?.totals,
                unit: res?.data?.unitTotal,
                percent: res?.data?.percentageTotal
            })

            setFormData({
                countryIncorporate: res?.data?.countryofIncorporation,
                stateIncorporate: res?.data?.stateofIncorporation,
                governmentId: res?.data?.governmentID
            })
        }
    }


    useEffect(() => {
        getInvoiceStatement();
    }, [])


    return (
        <div>
            <div className="mt-4 mb-4 buildCapitalTableDiv" >
                <table className="buildCapitalTable">
                    <thead>
                        <tr>
                            <th className="buildCapLeftTh">

                                <span className="buildCapLeftThSpan">{language.owner_name}</span>


                            </th>
                            <th className="buildCapMiddleTh">
                                <span className="buildCapLeftThSpan">Role</span>
                            </th>
                            <th className="buildCapMiddleTh">
                                <span className="buildCapLeftThSpan">Email</span>
                            </th>
                            <th className="buildCapMiddleTh">
                                <span className="buildCapLeftThSpan">{language.no_of_units}</span>
                            </th>
                            <th className="buildCapMiddleTh">
                                <span className="buildCapLeftThSpan">{language.type_of_unit}</span>
                            </th>
                            <th className="buildCapRightTh">
                                <span className="buildCapLeftThSpan">%</span>
                            </th>
                        </tr>
                    </thead>

                    <tbody>
                        {
                            ownerData?.map((item, i) => {
                                return (
                                    <tr key={i}>
                                        <td className="buildCapLeftTd">

                                            <div>
                                                <input
                                                    type="text"
                                                    placeholder="First Name"
                                                    name="fname"
                                                    value={item.fname}
                                                />
                                                <input
                                                    type="text"
                                                    placeholder="Last Name"
                                                    name="lname"
                                                    value={item.lname}
                                                />
                                            </div>
                                        </td>
                                        <td className="buildCapMiddleTd">
                                            <input
                                                type="text"
                                                name="role"
                                                value={item.role}
                                            />
                                        </td>
                                        <td className="buildCapMiddleTd">
                                            <input
                                                type="email"
                                                name="email"
                                                value={item.email}
                                            />
                                        </td>
                                        <td className="buildCapMiddleTd">
                                            <input
                                                type="number"
                                                name="noOfUnits"
                                                value={item.noOfUnits}
                                            />
                                        </td>
                                        <td className="buildCapMiddleTd">
                                            <input
                                                type="text"
                                                name="typeOfUnits"
                                                value={item.typeOfUnits}
                                            />
                                        </td>
                                        <td className="buildCapRightTd">
                                            <input
                                                type="number"
                                                name="percent"
                                                value={item.percent}

                                            />
                                        </td>
                                    </tr>

                                )
                            })
                        }
                    </tbody><br />

                    <tfoot>
                        <tr>
                            <td className="buildCapLeftFootTd">
                                <span>Totals:</span>
                            </td>
                            <td className="buildCapMiddleFootTd">
                                <input
                                    type="number"
                                    readOnly
                                    value={totalData.role}
                                />
                            </td>
                            <td className="buildCapMiddleFootTd">
                            </td>
                            <td className="buildCapMiddleFootTd">
                                <input
                                    type="number"
                                    readOnly
                                    value={totalData.unit}
                                />
                            </td>
                            <td className="buildCapMiddleFootTd">
                            </td>
                            <td className="buildCapRightTd">
                                <input
                                    type="number"
                                    readOnly
                                    value={totalData.percent}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="buildCapLeftFootTd">
                                <span>{language.country_of_incorporation}:</span>
                            </td>
                            <td className="buildCapMiddleFootTd">
                                <input
                                    type="text"
                                    name="countryIncorporate"
                                    value={formData.countryIncorporate}
                                />
                            </td>
                            <td className="buildCapMiddleFootTd">
                            </td>
                            <td className="buildCapMiddleFootTd">
                            </td>
                            <td className="buildCapMiddleFootTd">
                            </td>
                            <td className="buildCapRightTd">
                            </td>
                        </tr>
                        <tr>
                            <td className="buildCapLeftFootTd">
                                <span>{language.state_of_incorporation}:</span>
                            </td>
                            <td className="buildCapMiddleFootTd">
                                <input
                                    type="text"
                                    name="stateIncorporate"
                                    value={formData.stateIncorporate}
                                />
                            </td>
                            <td className="buildCapMiddleFootTd">
                            </td>
                            <td className="buildCapMiddleFootTd">
                            </td>
                            <td className="buildCapMiddleFootTd">
                            </td>
                            <td className="buildCapRightTd">
                            </td>
                        </tr>
                        <tr>
                            <td className="buildCapLeftFootTd">
                                <span>{language.government_id}:</span>
                            </td>
                            <td className="buildCapMiddleFootTd">
                                <input
                                    type="text"
                                    name="governmentId"
                                    value={formData.governmentId}
                                />
                            </td>
                            <td className="buildCapMiddleFootTd">
                            </td>
                            <td className="buildCapMiddleFootTd">
                            </td>
                            <td className="buildCapMiddleFootTd">
                            </td>
                            <td className="buildCapRightTd">
                            </td>
                        </tr>
                    </tfoot>
                </table>

                {/* button group */}
                {/* <div className="d-flex justify-content-center mt-3">
                    <button
                        className="btn-report-save"
                        onClick={() => handleSave()}
                    >
                        {isSave ?
                            saveLoading ? <CustomLoaderLine height="10" width="40" /> : language.save?.toUpperCase()
                            :
                            language.edit?.toUpperCase()
                        }
                    </button>
                    <button
                        className="btn-report-build"
                        onClick={() => handleBuildRepport()}
                    >
                        {loading ? <CustomLoaderLine height="10" width="100" /> : language.build_report?.toUpperCase()}
                    </button>
                </div> */}
            </div >

        </div>
    )
}

export default ViewCapitalBalance
