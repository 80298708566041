import React, { useContext, useEffect, useMemo, useState } from "react";
import { useLanguage } from "../../../Providers/LanguageContext";
import { UserContext } from "../../../Providers/UserWrapper";
import HttpClient from "../../../utils/HttpClient";
import { data } from "jquery";
import { useNavigate } from "react-router-dom";

const Dashboardzerotwo = ({
  zeroDate,
  setZeroDate,
  dashboardZeroData,
  setDashbardZeroData,
  dashBoardZeroTaskData,
  setTime,
  time,
  userCode
}) => {
  const { language } = useLanguage();
  const navigate = useNavigate();
  // console.log('dashboardZeroData22', dashboardZeroData);
  const { isBusiness, setIsLoading, isInvestor } = useContext(UserContext);

  // console.log("dashBoardZeroTaskData", dashBoardZeroTaskData)
  const [customerType, setCustomerType] = useState("")
  const [custData, setCustData] = useState([]);
  const [searchName, setSearchName] = useState("");
  const [isSearchList, setISSearchList] = useState(false);

  const [allClientNameData, setAllClientNameData] = useState([]);
  const [singleClient, setSingleClient] = useState({})
  const [clientInp, setClientInp] = useState("")
  const [clientInpShow, setClientInpShow] = useState("")
  const [clientNameSearch, setClientNameSearch] = useState([]);


  // console.log("dashboardZeroData", dashboardZeroData)


  // change the type customer
  const handleChangeCustType = async (e) => {
    // setCustomerType(e.target.value)
    const cust = e.target.value;
    setCustomerType(cust)
    const data = {
      input_type: cust
    }
    setIsLoading(true)
    const res = await HttpClient.requestData("get-dashboard-zero-bu-input-data", "POST", data);
    // console.log("custres", res)
    if (res && res?.success) {
      switch (cust) {
        case "Customer":
          const filtData = removeDuplicateFunc(res?.data, "customerName")
          setCustData(filtData)
          break;
        case "Vendor":
          const filtData2 = removeDuplicateFunc(res?.data, "vendorName")
          setCustData(filtData2)
          break;
        default: setCustData([])
      }
      setIsLoading(false)
    } else {
      setIsLoading(false)
    }
  }

  // remove duplicate from array of object
  const removeDuplicateFunc = (arr, key) => {
    const unique = arr.filter((obj, index) => {
      return index === arr.findIndex(o => obj[key] === o[key]);
    });
    console.log("unique", unique)
    return unique;
  }

  // filter search data
  const filteredData = useMemo(() => {
    switch (customerType) {
      case "Customer":
        return custData?.filter((item) => item?.customerName?.includes(searchName))

      case "Vendor":
        return custData?.filter((item) => item?.vendorName?.includes(searchName))

      default: return []
    }
  }, [customerType, custData, searchName]);


  // click on search list
  const handleSearchListClick = async (item) => {
    setSearchName(customerType === "Customer" ? item?.customerName : item?.vendorName);
    setISSearchList(true);
    const data = {
      "input_type": customerType,
      "input_name": customerType === "Customer" ? item?.customerName : item?.vendorName,
      "dayType": time,
      "startAR": zeroDate?.startDate,
      "endAR": zeroDate?.endDate
    }

    // return
    getDashZeroData(data)

  }


  // dash zero data get
  const getDashZeroData = async (data) => {
    setIsLoading(true)
    const res = await HttpClient.requestData("dashboard-zero-bu", "POST", data)
    console.log("ress", res);
    if (res && (res?.success || res?.status)) {
      setIsLoading(false)
      setDashbardZeroData(res?.data)
    } else {
      setIsLoading(false)
    }
  }


  useEffect(() => {
    if (zeroDate?.startDate && zeroDate?.endDate) {
      // console.log("zerotrue", zeroDate?.startDate !== "" && zeroDate?.endDate !== "")

      const data = {
        "input_type": customerType,
        "input_name": searchName,
        "dayType": time,
        "startAR": zeroDate?.startDate,
        "endAR": zeroDate?.endDate
      }
      getDashZeroData(data)
    }
  }, [zeroDate])

  // clear filter
  const handleClearFilter = () => {
    setCustomerType("");
    setCustData([]);
    setSearchName("");
    setISSearchList(false);
    setZeroDate({
      endDate: "",
      startDate: ""
    })
    setTime("day")

    const data = {
      "input_type": "",
      "input_name": "",
      "dayType": time,
      "startAR": "",
      "endAR": ""
    }
    getDashZeroData(data)
  }

  useEffect(() => {
    getClintName()
  }, [userCode])

  const getClintName = async () => {
    const res = await HttpClient.requestData("investor/get-all-investor-linked-account-list", "GET");
    // console.log("ressddcx", res)
    if (res && res?.status) {
      setAllClientNameData(res?.data)
      if (userCode) {
        const fndData = res?.data?.find(ele => ele?.accountRegCode === userCode)
        setSingleClient(fndData)
        setClientInp(fndData?.client_name)
      }
    }
  }

  const handleClientInvestorClick = (item) => {
    setSingleClient(item)
    setClientInp(item?.client_name)
    setClientInpShow("")
    navigate("/dashboard-manager", {
      state: {
        userRegCodr: item?.accountRegCode,
        slideNo: 0,
      },
    })
  }


  return (
    <div>
      <div id="config-form">
        <div className="container">

          {/* filter */}
          <div className="">
            <form>
              <div className="form-group">
                <div className="row" style={{ alignItems: "center" }}>

                  {
                    isInvestor ?
                      <div className="col-xl-2 col-lg-2 col-md-6 col-sm-12 col-sx-12 pt-4">
                        <div className="drpbox">
                          <input
                            type="search"
                            placeholder="Type-To-Search"
                            value={clientInp}
                            onChange={(e) => {
                              setClientInp(e.target.value)
                              setClientInpShow(e.target.value)
                              const fltData = allClientNameData?.filter((ele) => {
                                return ele?.client_name?.toLowerCase().includes(e.target.value?.toLowerCase())
                              })
                              setClientNameSearch(fltData)
                            }}
                          />
                        </div>

                        {
                          clientInpShow !== "" &&
                          <div className="dashboard-zero-search">
                            {
                              clientNameSearch?.length !== 0
                                ?
                                clientNameSearch?.map((item, i) =>
                                  <p key={i} onClick={() => handleClientInvestorClick(item)}>
                                    {item?.client_name}
                                  </p>
                                )
                                :
                                <div>No Data Found...</div>
                            }
                          </div>
                        }

                      </div>
                      :
                      <>
                        <div className="col-xl-2 col-lg-2 col-md-6 col-sm-12 col-sx-12 pt-4">
                          <div className="" >
                            <select className="drpdwnlst" value={customerType} onChange={(e) => handleChangeCustType(e)}>
                              <option value="">Select</option>
                              <option value="Customer">{language.customers}</option>
                              <option value="Vendor">{language.Vendedores}</option>
                            </select>

                          </div>
                        </div>
                        <div className="col-xl-2 col-lg-2 col-md-6 col-sm-12 col-sx-12 pt-4">
                          <div className="drpbox">
                            <input
                              type="search"
                              placeholder="Type-To-Search"
                              value={searchName}
                              onChange={(e) => {
                                setISSearchList(false);
                                setSearchName(e.target.value);
                              }}
                            />
                          </div>

                          {
                            (searchName !== "" && customerType !== "" && !isSearchList) &&
                            <div className="dashboard-zero-search">
                              {
                                filteredData?.length !== 0
                                  ?
                                  filteredData?.map((item, i) =>
                                    <p key={i} onClick={() => handleSearchListClick(item)}>
                                      {customerType === "Customer" ? item?.customerName : item?.vendorName}
                                    </p>
                                  )
                                  :
                                  <div>No Data Found...</div>
                              }
                            </div>
                          }

                        </div>
                      </>
                  }


                  <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-sx-12">
                    <label className="mb-2" style={{ fontSize: "1.2rem" }}>
                      {language.start_date}
                    </label>
                    <input
                      type="date"
                      className="form-control mb-3"
                      id=""
                      aria-describedby=""
                      value={zeroDate.startDate}
                      onChange={(e) => {
                        setZeroDate(prev => ({ ...prev, startDate: e.target.value }));
                        setTime("")
                      }}
                    />
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-sx-12">
                    <label className="mb-2" style={{ fontSize: "1.2rem" }}>
                      {language.final_date}
                    </label>
                    <input
                      type="date"
                      className="form-control mb-3"
                      id=""
                      aria-describedby=""
                      value={zeroDate.endDate}
                      onChange={(e) => {
                        setZeroDate(prev => ({ ...prev, endDate: e.target.value }));
                        setTime("")
                      }}
                    />
                  </div>
                  <div className="col-xl-2 col-lg-2 col-md-6 col-sm-12 col-sx-12">
                    <button type="button" className="fltrsall" onClick={handleClearFilter}>Clear All Filters</button>
                  </div>
                </div>
              </div>
            </form>
          </div>

          <section className="fisibility">
            <div className="container" style={{ width: "70%" }}>
              <div className="total">
                <div className="row">

                  {/* revenue total */}
                  <div className="col-md-6 col-lg-4 col-sm-6">
                    <div className="text">
                      <h4>
                        {isBusiness
                          ? language.revenue_total
                          : language.income_total}
                      </h4>
                      <span>
                        ${" "}
                        {
                          // isBusiness? 
                          dashboardZeroData.RevenueTotal
                          // : dashboardZeroData.IncomeTotal
                        }
                      </span>
                    </div>
                  </div>


                  {/* expese total */}
                  <div className="col-md-6 col-lg-4 col-sm-6">
                    <div className="text">
                      <h4>{language.expense_total}</h4>
                      <span>$ {dashboardZeroData.ExpenseTotal}</span>
                    </div>
                  </div>

                  {/* profit total */}
                  <div className="col-md-6 col-lg-4 col-sm-6">
                    <div className="text">
                      <h4>{isBusiness ? language.profit_total : language.margin_total}</h4>
                      <span>$ {
                        // isBusiness ? 
                        dashboardZeroData.ProfitTotal
                        // : dashboardZeroData.MarginTotal
                      }
                      </span>
                    </div>
                  </div>


                  {/* AR TOTAL */}
                  <div className="col-md-6 col-lg-4 col-sm-6">
                    <div className="text">
                      <h4>
                        {isBusiness ? "AR TOTAL" : language.income_budget}
                      </h4>
                      <span>
                        ${" "}
                        {
                          // isBusiness
                          //   ? 
                          dashboardZeroData.ARTotal
                          // : dashboardZeroData.IncomeBudget
                        }
                      </span>
                    </div>
                  </div>

                  {/* CURRENT AR */}
                  <div className="col-md-6 col-lg-4 col-sm-6">
                    <div className="text">
                      <h4>
                        {isBusiness ? "CURRENT AR" : language.expense_budget2}
                      </h4>
                      <span>
                        ${" "}
                        {
                          // isBusiness
                          //   ?
                          dashboardZeroData.CurrentAR
                          // : dashboardZeroData.ExpenseBudget
                        }
                      </span>
                    </div>
                  </div>

                  {/* PAST DUE AR   */}
                  <div className="col-md-6 col-lg-4 col-sm-6">
                    <div className="text">
                      <h4>
                        {isBusiness ? "PAST DUE AR" : language.margin_total}
                      </h4>
                      <span>
                        ${" "}
                        {
                          // isBusiness
                          //   ? 
                          dashboardZeroData.PastDueAR
                          // : dashboardZeroData.MarginBudget
                        }
                      </span>
                    </div>
                  </div>

                  {/* EVENTS */}
                  <div className="col-md-6 col-lg-4 col-sm-6">
                    <div className="text">
                      <h4>{language?.num_of_event}</h4>
                      <span>
                        {dashBoardZeroTaskData?.eventCount}
                      </span>
                    </div>
                  </div>

                  {/* TASKS */}
                  <div className="col-md-6 col-lg-4 col-sm-6">
                    <div className="text">
                      <h4>{language.num_of_task}</h4>
                      <span>
                        {dashBoardZeroTaskData?.taskCount}
                      </span>
                    </div>
                  </div>

                  {/* PROJECTS */}
                  <div className="col-md-6 col-lg-4 col-sm-6">
                    <div className="text">
                      <h4>{language.num_of_project}</h4>
                      <span>
                        {dashBoardZeroTaskData?.projectCount}
                      </span>
                    </div>
                  </div>

                  {/* TOTAL #  ACTIVITIES */}
                  <div className="col-md-6 col-lg-4 col-sm-6">
                    <div className="text">
                      <h4>{language.total_num_activity}</h4>
                      <span>
                        {dashBoardZeroTaskData?.totalActivities}
                      </span>
                    </div>
                  </div>

                  {/* TOTAL # ACTIVITY HOURS */}
                  <div className="col-md-6 col-lg-4 col-sm-6">
                    <div className="text">
                      <h4>{language.total_activity_hour}</h4>
                      <span>
                        {dashBoardZeroTaskData?.totalActivityHours && Number(dashBoardZeroTaskData?.totalActivityHours)?.toFixed(2)}
                        {/* --- */}
                      </span>
                    </div>
                  </div>

                  {/* # UNASSIGNED ACTIVITIES */}
                  <div className="col-md-6 col-lg-4 col-sm-6">
                    <div className="text">
                      <h4>{language.unassigned_activity}</h4>
                      <span>
                        {dashBoardZeroTaskData?.unAssignDataCount}
                      </span>
                    </div>
                  </div>

                  {/* TOTAL #  CUSTOMERS */}
                  <div className="col-md-6 col-lg-4 col-sm-6">
                    <div className="text">
                      <h4>{isBusiness ? language.total_customer : language.total_num_income_source}</h4>
                      <span>
                        {dashBoardZeroTaskData?.customerDataCount}
                        {/* --- */}
                      </span>
                    </div>
                  </div>

                  {/* TOTAL #  VENDORS */}
                  <div className="col-md-6 col-lg-4 col-sm-6">
                    <div className="text">
                      <h4>{language.total_vendors}</h4>
                      <span>
                        {dashBoardZeroTaskData?.vendorDataCount}
                      </span>
                    </div>
                  </div>

                  {/* TOTAL #  USERS */}
                  <div className="col-md-6 col-lg-4 col-sm-6">
                    <div className="text">
                      <h4>{language.total_user}</h4>
                      <span>
                        {dashBoardZeroTaskData?.userCount}
                      </span>
                    </div>
                  </div>

                  {/*RECOMMENDATIONS */}
                  <div className="col-md-6 col-lg-4 col-sm-6">
                    <div className="text">
                      <h4>RECOMMENDATIONS</h4>
                      <span>
                        {/* {dashBoardZeroTaskData?.userCount} */}
                        ---
                      </span>
                    </div>
                  </div>

                  {/* # PROFITABLE EVENTS */}
                  <div className="col-md-6 col-lg-4 col-sm-6">
                    <div className="text">
                      <h4># PROFITABLE EVENTS</h4>
                      <span>
                        {/* {dashBoardZeroTaskData?.userCount} */}
                        ---
                      </span>
                    </div>
                  </div>

                  {/* # PROFITABLE TASKS*/}
                  <div className="col-md-6 col-lg-4 col-sm-6">
                    <div className="text">
                      <h4># PROFITABLE TASKS</h4>
                      <span>
                        {/* {dashBoardZeroTaskData?.userCount} */}
                        ---
                      </span>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </section>

        </div>
      </div>
    </div>
  );
};

export default Dashboardzerotwo;
