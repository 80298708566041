import React, { useCallback } from 'react'
import { AiOutlineClose } from "react-icons/ai";
import { GrMoney } from "react-icons/gr";
import { GiProfit, GiExpense } from "react-icons/gi"
import { useLanguage } from '../../Providers/LanguageContext';

function GoodnewsData({ data, closeModal, time, userType, currency }) {
    console.log("datanews", data, time)
    const { language, currentLanguage } = useLanguage();

    const spanishEngTime = (time) => {
        switch (time) {
            case 'day': return language.daily
            case 'month': return language.monthly
            case 'week': return language.weekly
            case 'year': return language.yearly
            default: return time;
        }
    }

    const personalJsx = (data) => {
        return (
            <div>
                <ul>
                    {
                        currentLanguage === 'eng'
                            ?
                            <p>
                                Household is spending $ {data} less in {spanishEngTime(time)} Expenses than Budget calls for.
                            </p>
                            :
                            <p>
                                La familiar gasta $ {data} menos en gastos {spanishEngTime(time)} de lo que pide el presupuesto.
                            </p>
                    }
                </ul>
            </div>
        )
    }

    const renderJsx = useCallback(() => {
        if (userType === "Business") {

            return (
                <div>
                    {/* expense info */}
                    {
                        data.ExpenseDecreased &&
                        <div>
                            <h3>{language.expense_information}</h3>
                            <ul>
                                {
                                    currentLanguage === 'eng'
                                        ?
                                        <p>Company is spending $ {data.ExpenseDecreased} less in {spanishEngTime(time)} Expenses than Budget calls for.</p>
                                        :
                                        <p>La empresa está gastando $ {data.ExpenseDecreased} menos en gastos {spanishEngTime(time)} de lo que pide el presupuesto.</p>
                                }
                            </ul>
                        </div>
                    }

                    {/* salse Info */}
                    {
                        (data.avgSalesIncreased || data.SalesIncreased) &&
                        <div>
                            <h3>{language.sales_information}</h3>
                            <ul>
                                {
                                    data.avgSalesIncreased
                                        ?
                                        currentLanguage === 'eng'
                                            ?
                                            <p>
                                                Company is exceeding {spanishEngTime(time)} Sales Budget by $ {data.avgSalesIncreased}.
                                            </p>
                                            :
                                            <p>
                                                La empresa supera el presupuesto de ventas {spanishEngTime(time)} en $ {data.avgSalesIncreased}.
                                            </p>
                                        :
                                        null
                                }

                                {
                                    data.SalesIncreased
                                        ?
                                        currentLanguage === 'eng'
                                            ?
                                            <p>
                                                Average {spanishEngTime(time)} Sales have increased by $ {data.SalesIncreased}.
                                            </p>
                                            :
                                            <p>
                                                Las Ventas {spanishEngTime(time)} Promedio han aumentado en $ {data.SalesIncreased}.
                                            </p>
                                        :
                                        null
                                }
                            </ul>
                        </div>
                    }
                    {/* profit info */}
                    {
                        (data.ProfitBudget || data.avgProfitIncreased) &&
                        <div>
                            <h3>{language.profit_information}</h3>
                            <ul>
                                {
                                    data.ProfitBudget
                                        ?
                                        currentLanguage === 'eng'
                                            ?
                                            <p>
                                                Company is exceeding {spanishEngTime(time)} Profit Budget by $ {data.ProfitBudget}.
                                            </p>
                                            :
                                            <p>
                                                La empresa supera el presupuesto de beneficios {spanishEngTime(time)} en $ {data.ProfitBudget}.
                                            </p>
                                        :
                                        null
                                }

                                {
                                    data.avgProfitIncreased
                                        ?
                                        currentLanguage === 'eng'
                                            ?
                                            <p>
                                                Average {spanishEngTime(time)} Profit has increased by $ {data.avgProfitIncreased}.
                                            </p>
                                            :
                                            <p>
                                                La utilidad mensual {spanishEngTime(time)} ha aumentado en $ {data.avgProfitIncreased}.
                                            </p>
                                        :
                                        null
                                }
                            </ul>
                        </div>
                    }

                </div>
            )
            // if (time === "day") {
            //     return (
            //         <div className='d-flex justify-content-start flex-column'>
            //             {
            //                 (Boolean(data.dailySalesIncreased) || data.dailySalesIncreased === 0) && (<span>
            //                     <GrMoney size={20} /> {language.daily_sale_increase}: {Number(data?.dailySalesIncreased).toFixed(2)} {currency}
            //                 </span>
            //                 )
            //             }
            //             {
            //                 (Boolean(data.avgSalesIncreased) || data.avgSalesIncreased === 0) && (
            //                     <span>
            //                         <GrMoney size={20} /> {language.avg_sale_increase}: {Number(data?.avgSalesIncreased).toFixed(2)} {currency}
            //                     </span>
            //                 )
            //             }
            //             {
            //                 (Boolean(data.avgProfitIncreased) || data.avgProfitIncreased === 0) && (
            //                     <span>
            //                         <GiProfit size={20} /> {language.avg_profit_increase}: {Number(data?.avgProfitIncreased).toFixed(2)} {currency}
            //                     </span>
            //                 )
            //             }
            //             {
            //                 (Boolean(data.dailyExpenseDecreased) || data.dailyExpenseDecreased === 0) && (
            //                     <span>
            //                         <GiExpense size={20} /> {language.daily_expense_decrease}: {Number(data?.dailyExpenseDecreased).toFixed(2)} {currency}
            //                     </span>
            //                 )
            //             }
            //             {
            //                 (Boolean(data.dailyProfitBudget) || data.dailyProfitBudget === 0) && (
            //                     <span>
            //                         <GiProfit size={20} />  {language.daily_profit_budget}: {Number(data?.dailyProfitBudget).toFixed(2)} {currency}
            //                     </span>
            //                 )
            //             }
            //         </div>
            //     )
            // } else if (time === "week") {
            //     return (
            //         <div className='d-flex justify-content-start flex-column'>
            //             {
            //                 (Boolean(data.weeklySalesIncreased) || data.weeklySalesIncreased === 0) && (
            //                     <span>
            //                         <GrMoney size={20} />  {language.weekly_sales_increase}: {Number(data?.weeklySalesIncreased).toFixed(2)}  {currency}
            //                     </span>
            //                 )
            //             }

            //             {
            //                 (Boolean(data.avgSalesIncreased) || data.avgSalesIncreased === 0) && (
            //                     <span>
            //                         <GrMoney size={20} />  {language.avg_sales_increase}: {Number(data?.avgSalesIncreased).toFixed(2)}  {currency}
            //                     </span>
            //                 )
            //             }

            //             {
            //                 (Boolean(data.avgProfitIncreased) || data.avgProfitIncreased === 0) && (
            //                     <span>
            //                         <GiProfit size={20} />  {language.avg_profit_increase}: {Number(data?.avgProfitIncreased).toFixed(2)}  {currency}
            //                     </span>
            //                 )
            //             }

            //             {
            //                 (Boolean(data.weeklyExpenseDecreased) || data.weeklyExpenseDecreased === 0) && (
            //                     <span>
            //                         <GiExpense size={20} /> {language.weekly_expense_decrease}: {Number(data?.weeklyExpenseDecreased).toFixed(2)}  {currency}
            //                     </span>
            //                 )
            //             }
            //             {
            //                 (Boolean(data.weeklyProfitBudget) || data.weeklyProfitBudget === 0) && (
            //                     <span>
            //                         <GiProfit size={20} /> {language.weekly_profit_budget}: {Number(data?.weeklyProfitBudget).toFixed(2)}  {currency}
            //                     </span>
            //                 )
            //             }
            //         </div>
            //     )
            // } else if (time === "month") {
            //     return (
            //         <div className='d-flex justify-content-start flex-column'>
            //             {
            //                 (Boolean(data.monthlySalesIncreased) || data.monthlySalesIncreased === 0) && (
            //                     <span>
            //                         <GrMoney size={20} /> {language.monthly_sales_increase}: {Number(data?.monthlySalesIncreased).toFixed(2)}  {currency}
            //                     </span>
            //                 )
            //             }

            //             {
            //                 (Boolean(data.avgSalesIncreased) || data.avgSalesIncreased === 0) && (
            //                     <span>
            //                         <GrMoney size={20} /> {language.avg_sale_increase}: {Number(data?.avgSalesIncreased).toFixed(2)}  {currency}
            //                     </span>
            //                 )
            //             }


            //             {
            //                 (Boolean(data.avgProfitIncreased) || data.avgProfitIncreased === 0) && (
            //                     <span>
            //                         <GiProfit size={20} /> {language.avg_profit_increase}: {Number(data?.avgProfitIncreased).toFixed(2)}  {currency}
            //                     </span>
            //                 )
            //             }
            //             {
            //                 (Boolean(data.monthlyExpenseDecreased) || data.monthlyExpenseDecreased === 0) && (
            //                     <span>
            //                         <GiExpense size={20} /> {language.monthly_expense_decrease}: {Number(data?.monthlyExpenseDecreased).toFixed(2)}  {currency}
            //                     </span>
            //                 )
            //             }

            //             {
            //                 (Boolean(data.monthlyProfitBudget) || data.monthlyProfitBudget === 0) && (
            //                     <span>
            //                         <GiProfit size={20} /> {language.monthly_profit_budget}: {Number(data?.monthlyProfitBudget).toFixed(2)}  {currency}
            //                     </span>
            //                 )
            //             }
            //         </div>
            //     )
            // } else if (time === "year") {
            //     return (
            //         <div className='d-flex justify-content-start flex-column'>
            //             {
            //                 (Boolean(data.yearlySalesIncreased) || data.yearlySalesIncreased === 0) && (
            //                     <span>
            //                         <GrMoney size={20} />  {language.yearly_sales_increase}: {Number(data?.yearlySalesIncreased).toFixed(2)}  {currency}
            //                     </span>
            //                 )
            //             }

            //             {
            //                 (Boolean(data.avgSalesIncreased) || data.avgSalesIncreased === 0) && (
            //                     <span>
            //                         <GrMoney size={20} />  {language.avg_sale_increase}: {Number(data?.avgSalesIncreased).toFixed(2)}  {currency}
            //                     </span>
            //                 )
            //             }

            //             {
            //                 (Boolean(data.avgProfitIncreased) || data.avgProfitIncreased === 0) && (
            //                     <span>
            //                         <GiProfit size={20} />  {language.avg_profit_increase}: {Number(data?.avgProfitIncreased).toFixed(2)}  {currency}
            //                     </span>
            //                 )
            //             }
            //             {
            //                 (Boolean(data.yearlyExpenseDecreased) || data.yearlyExpenseDecreased === 0) && (
            //                     <span>
            //                         <GiExpense size={20} />  {language.yearly_expense_decrease}: {Number(data?.yearlyExpenseDecreased).toFixed(2)}  {currency}
            //                     </span>
            //                 )
            //             }
            //             {
            //                 (Boolean(data.yearlyProfitBudget) || data.yearlyProfitBudget === 0) && (
            //                     <span>
            //                         <GiProfit size={20} />  {language.yearly_profit_budget}: {Number(data?.yearlyProfitBudget).toFixed(2)}  {currency}
            //                     </span>
            //                 )
            //             }

            //         </div>
            //     )
            // }
        } else if (userType === "Personal") {
            return (
                <div>
                    <h3>{language.expense_information}</h3>

                    {
                        data.dailyExpenseDecreased &&
                        personalJsx(data.dailyExpenseDecreased)
                    }

                    {
                        data.weeklyExpenseDecreased &&
                        personalJsx(data.weeklyExpenseDecreased)
                    }

                    {
                        data.monthlyExpenseDecreased &&
                        personalJsx(data.monthlyExpenseDecreased)
                    }

                    {
                        data.yearlyExpenseDecreased &&
                        personalJsx(data.yearlyExpenseDecreased)
                    }
                </div>
            )
            // if (time === "day") {
            //     return (
            //         <div className='d-flex justify-content-start flex-column'>
            //             {(Boolean(data.dailyExpenseDecreased) || data.dailyExpenseDecreased === 0) && (
            //                 <span>
            //                     <GiExpense size={20} /> {language.daily_expense_decrease} : {data?.dailyExpenseDecreased}
            //                 </span>
            //             )}

            //         </div>
            //     )
            // } else if (time === "week") {
            //     return (
            //         <div className='d-flex justify-content-start flex-column'>
            //             {
            //                 (Boolean(data.weeklyExpenseDecreased) || data.weeklyExpenseDecreased === 0) && (
            //                     <span>
            //                         <GiExpense size={20} /> {language.weekly_expense_decrease} : {data?.weeklyExpenseDecreased}
            //                     </span>
            //                 )
            //             }

            //         </div>
            //     )
            // } else if (time === "month") {
            //     return (
            //         <div>
            //             {
            //                 (Boolean(data.monthlyExpenseDecreased) || data.monthlyExpenseDecreased === 0) && (
            //                     <span>
            //                         <GiExpense size={20} /> {language.monthly_expense_decrease} : {data?.monthlyExpenseDecreased}
            //                     </span>
            //                 )
            //             }

            //         </div>
            //     )
            // } else if (time === "year") {
            //     return (
            //         <div>
            //             {
            //                 (Boolean(data.yearlyExpenseDecreased) || data.yearlyExpenseDecreased === 0) && (
            //                     <span>
            //                         <GiExpense size={20} />  {language.yearly_expense_decrease} : {data?.yearlyExpenseDecreased}
            //                     </span>
            //                 )
            //             }

            //         </div>
            //     )
            // }
        }
    }, [userType, time, data])
    return (
        <div>
            <div className='d-flex justify-content-end'>
                <AiOutlineClose className='icon' onClick={closeModal} size={20} color="gray" />
            </div>
            {
                data && (
                    <div>
                        {
                            renderJsx()
                        }
                    </div>
                )
            }
            {
                (!data || (typeof data === "object" && Object.values(data).length === 0)) && (
                    <span>{language.no_data}</span>
                )
            }
        </div>
    )
}

export default GoodnewsData