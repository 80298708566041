import moment from 'moment';
import React, { useEffect, useState } from 'react';
import NoDataFound from '../../Component/NoDataFound';
import { useLanguage } from '../../Providers/LanguageContext';
import { useUserData } from '../../Providers/UserWrapper';
import HttpClient from '../../utils/HttpClient';

const TransactionTable = ({ reportDate }) => {
    const { language, currentLanguage } = useLanguage();
    const { isBusiness, setTranDetailExcel } = useUserData();
    const [salesData, setSelesData] = useState([]);
    const [expDetail, setExpDetail] = useState([]);
    const [transeStatusData, setTranseStatusData] = useState({});

    console.log('salesDatatr', salesData)

    const getTransactionSales = async () => {
        const sendData = {
            startDate: reportDate.startData,
            endDate: reportDate.endDate
        }
        const res = await HttpClient.requestData('transaction-report', "POST", sendData);
        // console.log('getTranRes', res);
        if (res && res.status) {
            setSelesData(res.data);
        }
    }

    const getTransactionExpense = async () => {
        const sendData = {
            startDate: reportDate.startData,
            endDate: reportDate.endDate
        }
        const res = await HttpClient.requestData('transaction-report-expense', "POST", sendData);
        // console.log('getTranResExp', res);
        if (res && res.status) {
            setExpDetail(res.data);
        }
    }

    const getTranseStatus = async () => {
        const sendData = {
            startDate: reportDate.startData,
            endDate: reportDate.endDate
        }
        const res = await HttpClient.requestData("transaction-stat", "POST", sendData);
        console.log('transettResSat', res);
        if (res && res.status) {
            setTranseStatusData(res.data);
        }
    }


    const getExcelData = () => {
        let myArray = [
            [language.report_generated_by, transeStatusData.reportGeneratedBy],
            [language.report_generated_on, transeStatusData.reportGeneratedOn],
            [],
            [language.report_date_range, transeStatusData.startDate, transeStatusData.endDate],
            [language.report_expense_total, transeStatusData.expenseTotal],
            [isBusiness ? language.report_expense_total : language.report_income_total, transeStatusData.salesTotal],
            [language.rep_tran_count_total, salesData.length + expDetail.length],
            [],
            [isBusiness ? language.sales : language.income],
            [language.transaction_date, language.transaction_amount, language.category, language.tran_freq, language.project_name, language.sub_user_name]

        ]
        salesData.forEach((item) => {
            myArray.push([
                moment(item.transactionDate).format("YYYY-MM-DD"),
                item.amount,
                isBusiness ? language.sales : language.income,
                item.transactionFrequency,
                item.projectName ? item.projectName : "-",
                item.firstName + " " + item.lastName
            ])
        })
        myArray.push([])
        myArray.push([language.expense])
        myArray.push([language.transaction_date, language.transaction_amount, language.category, language.tran_freq, language.project_name, language.sub_user_name])

        expDetail.forEach((item) => {
            myArray.push([
                moment(item.transactionDate).format("YYYY-MM-DD"),
                item.amount,
                language.expense,
                item.transactionFrequency,
                item.projectName ? item.projectName : "-",
                item.firstName + " " + item.lastName
            ])
        })

        console.log('myArrayTran', myArray)
        setTranDetailExcel(myArray);
    }

    useEffect(() => {
        getExcelData()
    }, [salesData, expDetail, currentLanguage, transeStatusData]);

    useEffect(() => {
        // if(reportDate.startData && reportDate.endDate)
        getTransactionSales();
        getTransactionExpense();
        getTranseStatus();
    }, [reportDate])

    return (
        <>
            <div className="rprtQury mb-5">
                <h4>
                    {language.report_date_range}:
                    {
                        transeStatusData.startDate && transeStatusData.endDate &&
                        <span> {transeStatusData.startDate} to {transeStatusData.endDate}</span>
                    }
                </h4>
                <h4>
                    {language.report_expense_total}:<span> {transeStatusData.expenseTotal}</span>
                </h4>
                <h4>
                    {isBusiness ? language.report_sales_total : language.report_income_total}:<span> {transeStatusData.salesTotal}</span>
                </h4>
                <h4>
                    {language.rep_tran_count_total}:<span> {salesData.length + expDetail.length}</span>
                </h4>
            </div>

            <div>

                {/* <div className='table-responsive d-flex justify-content-center'>
                    <table className="table gen-fin-table w-50">
                        <thead>
                            <tr>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody className="">
                            <tr>
                                <td style={{ width: "50%" }}>
                                    {isBusiness ? language.daily_profit_amt : language.daily_margin_amt}
                                </td>
                                <td>112</td>
                            </tr>
                            <tr>
                                <td style={{ width: "50%" }}>
                                    {isBusiness ? language.daily_profit_amt : language.daily_margin_amt}
                                </td>
                                <td>112</td>
                            </tr><tr>
                                <td style={{ width: "50%" }}>
                                    {isBusiness ? language.daily_profit_amt : language.daily_margin_amt}
                                </td>
                                <td>112</td>
                            </tr><tr>
                                <td style={{ width: "50%" }}>
                                    {isBusiness ? language.daily_profit_amt : language.daily_margin_amt}
                                </td>
                                <td>112</td>
                            </tr>
                        </tbody>
                    </table>
                </div> */}
                <div>
                    <h3>{isBusiness ? language.sales : language.income}</h3>
                </div>

                <div className='table-responsive'>
                    <table className="table table-striped gen-fin-table_tran">
                        <thead>
                            <tr>
                                <th scope="col">{language.transaction_date}</th>
                                <th scope="col">{language.transaction_amount}</th>
                                <th scope="col">{language.category}</th>
                                {/* <th scope="col">{language.category_name_detail}</th> */}
                                {/* <th scope="col">{language.cat_desc_detail}</th> */}
                                <th scope="col">{language.tran_freq}</th>
                                {/* <th scope="col">{language.tran_currency}</th> */}
                                <th scope="col">{language.project_name}</th>
                                <th scope="col">{language.sub_user_name}</th>
                            </tr>
                        </thead>
                        <tbody className="">
                            {salesData.map((item, i) =>
                                <tr>
                                    <td>{moment(item.transactionDate).format("YYYY-MM-DD")}</td>
                                    <td>{item.amount}</td>
                                    <td>{isBusiness ? language.sales : language.income}</td>
                                    {/* <td>-</td> */}
                                    {/* <td>-</td> */}
                                    <td>{item.transactionFrequency}</td>
                                    {/* <td>-</td> */}
                                    <td>{item.projectName ? item.projectName : "-"}</td>
                                    <td>{item.firstName + " " + item.lastName}</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
                {salesData.length == 0 && <NoDataFound />}


                <div className='mt-5'>
                    <h3>{language.expense}</h3>
                </div>

                <div className='table-responsive'>
                    <table className="table table-striped gen-fin-table_tran">
                        <thead>
                            <tr>
                                <th scope="col">{language.transaction_date}</th>
                                <th scope="col">{language.transaction_amount}</th>
                                <th scope="col">{language.category}</th>
                                {/* <th scope="col">{language.category_name_detail}</th> */}
                                {/* <th scope="col">{language.cat_desc_detail}</th> */}
                                <th scope="col">{language.tran_freq}</th>
                                {/* <th scope="col">{language.tran_currency}</th> */}
                                <th scope="col">{language.project_name}</th>
                                <th scope="col">{language.sub_user_name}</th>
                            </tr>
                        </thead>
                        <tbody className="">
                            {expDetail.map((item, i) =>
                                <tr key={i}>
                                    <td>{moment(item.transactionDate).format("YYYY-MM-DD")}</td>
                                    <td>{item.amount}</td>
                                    <td>
                                        {/* {item.category} */}
                                        {language.expense}
                                    </td>
                                    {/* <td>-</td> */}
                                    {/* <td>-</td> */}
                                    <td>{item.transactionFrequency}</td>
                                    {/* <td>-</td> */}
                                    <td>{item.projectName ? item.projectName : "-"}</td>
                                    <td>{item.firstName + " " + item.lastName}</td>
                                </tr>
                            )}

                        </tbody>
                    </table>
                </div>
                {expDetail.length == 0 && <NoDataFound />}


            </div>
        </>
    )
}

export default TransactionTable;