

import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { DateRange } from 'react-date-range';
import moment from 'moment';
import NewBankSubCardModal from './NewBankSubCardModal';
import HttpClient from '../../utils/HttpClient';
import CustomLoaderLine from '../../Component/Loader/CustomLoaderLine';

// Ensure Modal's accessibility by setting the app element
Modal.setAppElement('#root');

const closeStyle = {
    color: "#aaaaaa",
    float: "right",
    fontSize: "28px",
    fontWeight: "bold",
    cursor: "pointer",
    marginTop: "-1em"
};

const BankStatementsModal = ({ modalIsOpen, closeModal, bankCardValue }) => {
    const initState = {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection'
    };
    const [state, setState] = useState(initState);
    const [isShowCalender, setIsShowCalender] = useState(false);
    const [date, setDate] = useState({ startDate: "", endDate: "" });
    const [openBankStatementsModal, setOpenBankStatementsModal] = useState(false);
    const [singleTotalDoc, setSingleTotalDoc] = useState("");
    const [statementData, setStatementData] = useState([])
    const [pdfDocData, setPdfDocData] = useState({})
    const [clickvalue, setClickValue] = useState("")
    const [isLoading, setIsLoading] = useState(true)
    // >>>>Fetching Bank Statement Data<<<<
    const getBankStatementData = async () => {
        const res = await HttpClient.requestData("bankstatements", "GET");
        if (res && res?.status) {
            setIsLoading(false)
            console.log("df5g632", res?.data, bankCardValue, singleTotalDoc)
            if (bankCardValue === "Investment") {
                const data = {
                    totalinvestordoc: res?.data?.totalinvestordoc,
                    investormissing: res?.data?.investormissing,
                    investorpastdue: res?.data?.investorpastdue,
                    investormissingdocumentpdfs: res?.data?.investormissingdocumentpdfs,
                    investorpastduedocumentpdfs: res?.data?.investorpastduedocumentpdfs,
                    totalinvestorpdfs: res?.data?.totalinvestorpdfs
                }
                setSingleTotalDoc(data)
            } else if (bankCardValue === "BookKeeper") {
                const data = {
                    totalbookkeeperdoc: res?.data?.totalbookkeeperdoc,
                    bookkeepermissing: res?.data?.bookkeepermissing,
                    bookkeeperpastdue: res?.data?.bookkeeperpastdue,
                    bookkeepermissingdocumentpdfs: res?.data?.bookkeepermissingdocumentpdfs,
                    bookkeeperpastduedocumentpdfs: res?.data?.bookkeeperpastduedocumentpdfs,
                    totalbookeeperpdfs: res?.data?.totalbookeeperpdfs
                }
                setSingleTotalDoc(data)
            } else {
                setSingleTotalDoc(res?.data)
            }
        }
    }

    const toggleShowCalender = () => {
        setIsShowCalender(!isShowCalender);
    };

    const handleTotalClick = (typeName, clickValue) => {
        console.log("clickValue165sd", typeName, clickValue)
        if (typeName === "Investment") {
            setOpenBankStatementsModal(true);
            if (clickValue === "Total") {
                setClickValue("Total")
                setPdfDocData(singleTotalDoc?.totalinvestorpdfs)
            } else if (clickValue === "Missing") {
                setClickValue("Missing")
                setStatementData(singleTotalDoc?.investormissingdocumentpdfs)
            } else if (clickValue === "Past Due") {
                setClickValue("Past Due")
                setStatementData(singleTotalDoc?.investorpastduedocumentpdfs)
            } else {
                setClickValue("")
                setStatementData([])
            }
        }

        if (typeName === "BookKeeper") {
            setOpenBankStatementsModal(true);
            if (clickValue === "Total") {
                setClickValue("Total")
                setPdfDocData(singleTotalDoc?.totalbookeeperpdfs)
            } else if (clickValue === "Missing") {
                setClickValue("Missing")
                setStatementData(singleTotalDoc?.bookkeepermissingdocumentpdfs)
            } else if (clickValue === "Past Due") {
                setClickValue("Past Due")
                setStatementData(singleTotalDoc?.bookkeeperpastduedocumentpdfs)
            } else {
                setClickValue("")
                setStatementData([])
            }
        }
    }

    useEffect(() => {
        getBankStatementData()
    }, [])

    return (
        <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            contentLabel="Document Modal"
        >
            <span onClick={closeModal} style={closeStyle}>&times;</span>

            <div className='documentVault_box' style={{ margin: "10em 28em" }}>
                <div className='toparea d-flex justify-content-between'>

                    <div className='box'>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Name"
                        />
                    </div>

                    <div
                        className='box'
                        style={{ cursor: "pointer", position: "relative" }}
                        onClick={(e) => {
                            e.stopPropagation();
                            toggleShowCalender();
                        }}
                    >
                        <span className="form-control">
                            {(date?.startDate && date?.endDate)
                                ? <span style={{ whiteSpace: "nowrap" }}>{date?.startDate + " - " + date?.endDate}</span>
                                : "Date Range"}
                        </span>
                    </div>

                </div>

                <div className='bottomarea' style={{ width: "283px" }}>
                    <p className='name'>{bankCardValue === "Investment" ? "Investor" : "BookKeeper"}</p>
                    <div className='d-flex align-items-center justify-content-around'>
                        <div className='pointarea'>
                            <p>Total</p>
                            <span
                                onClick={() => handleTotalClick(bankCardValue === "Investment" ? "Investment" : "BookKeeper", "Total")}
                                style={{ cursor: "pointer" }}
                            >{bankCardValue === "Investment" ? singleTotalDoc?.totalinvestordoc : singleTotalDoc?.totalbookkeeperdoc}
                                {isLoading && <CustomLoaderLine height="25" width="25" />}
                            </span>
                        </div>

                        <div className='pointarea'>
                            <p>Missing</p>
                            <span
                                onClick={() => handleTotalClick(bankCardValue === "Investment" ? "Investment" : "BookKeeper", "Missing")}
                                style={{ cursor: "pointer" }}
                            >{bankCardValue === "Investment" ? singleTotalDoc?.investormissing : singleTotalDoc?.bookkeepermissing}
                                {isLoading && <CustomLoaderLine height="25" width="25" />}
                            </span>
                        </div>

                        <div className='pointarea'>
                            <p>Past Due</p>
                            <span
                                onClick={() => handleTotalClick(bankCardValue === "Investment" ? "Investment" : "BookKeeper", "Past Due")}
                                style={{ cursor: "pointer" }}
                            >{bankCardValue === "Investment" ? singleTotalDoc?.investorpastdue : singleTotalDoc?.bookkeeperpastdue}
                                {isLoading && <CustomLoaderLine height="25" width="25" />}
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            {isShowCalender &&
                <div className='DocVaultCalender' onClick={(e) => e.stopPropagation()}>
                    <div className='DocVaultCalender_body'>
                        <button className='closebtn' onClick={toggleShowCalender}><i className="fa-solid fa-xmark"></i></button>
                        <DateRange
                            editableDateInputs={true}
                            onChange={item => {
                                setState(item.selection)
                                setDate({
                                    startDate: moment(item.selection?.startDate).format("YYYY-MM-DD"),
                                    endDate: moment(item?.selection?.endDate).format("YYYY-MM-DD")
                                });
                            }}
                            moveRangeOnFirstSelection={false}
                            ranges={[state]}
                        />

                        <div className='d-flex justify-content-between p-2'>
                            <button
                                className='btn btn-sm btn-primary'
                                onClick={() => {
                                    setState(initState);
                                    setDate({
                                        startDate: moment().format("YYYY-MM-DD"),
                                        endDate: moment().format("YYYY-MM-DD"),
                                    });
                                }}
                            >
                                Today
                            </button>

                            <button
                                className='btn btn-sm btn-primary'
                                onClick={() => {
                                    setDate({ startDate: "", endDate: "" });
                                    setState(initState);
                                    setIsShowCalender(false);
                                }}
                            >
                                Clear
                            </button>
                        </div>
                    </div>
                </div>
            }

            {openBankStatementsModal && (
                <NewBankSubCardModal
                    modalIsOpen={openBankStatementsModal}
                    closeModal={() => setOpenBankStatementsModal(false)}
                    singleTotalDoc={statementData}
                    pdfDocData={pdfDocData}
                    clickvalue={clickvalue}
                />
            )}
        </Modal >
    )
}

export default BankStatementsModal;
