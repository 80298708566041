import React from 'react'
import ade from "../../images/_Grupo_ (1).png";
import adh from "../../images/_Grupo_ (2).png";
import aab from "../../images/fisibility-logo (1).png";
import ab from "../../images/app-store.png";
import aa from "../../images/play-store.png";
import handImg from '../../images/Change_image/ilustracion blog_4 copia.png'

function Blogimage() {
  return (
    <>

      <section id='blog-image'>
        <div className='container'>
          <div className='inner-container'>
            <img className="img-fluid" src={handImg} />
            {/* <div className='row'>
              <div className='col-xl-12 col-lg-12 col-md-12 col-12'>
                <div className='inner-content'>
                  <div className='left-image'>
                    <img className="img-fluid" src={ade} />
                  </div>
                  <div className='mid-content'>
                    <div className='mid-logo'>
                      <img className="img-fluid" src={aab} />
                    </div>
                    <div className='play-store'>
                      <a href='#'><img src={ab} /></a>
                    </div>
                    <div className='ios'>
                      <a href='#'><img src={aa} /></a>
                    </div>
                  </div>
                  <div className='right-image'>
                    <img className="img-fluid" src={adh} />
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </section>

    </>
  )
}

export default Blogimage