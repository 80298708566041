import React from "react";
import a from "../Images/6-Confidence&FinancialInsights.png";
export default function Insights({ goToRegistration }) {
  return (
    <div className="inner">
      <div className="SpreadSheet">
        <div className="lftImg">
          <img src={a} alt="" />
        </div>
        <div className="rgtCnt">
          <div className="bottom_cnt">
            <div className="wrapCnt">
              <h4>More Confidence & Financial Insights</h4>
              <span>
                Approach every meeting with more confidence by having financial
                insights automatically placed on your calendar
              </span>

              <span className="mt-3 d-block">
                Understand which activities are producing profitable outcomes
                and which ones aren’t
              </span>
              <a onClick={() => goToRegistration()} className="read-more my-3">
                Let’s Get You Started
              </a>
              <h5 style={{ color: "#4FACFF" }}>
                “Does Fisibility automatically connect all of my tasks, events,
                & projects w/ our financial data?”
              </h5>
              <span>Yes!</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
