import React, { useContext, useEffect, useRef, useState } from 'react'
import { Watch } from 'react-loader-spinner'
import Clasification from '../../../Component/Clasification'
import Dropbox from '../../../Component/Dropbox'
import Managementtop from '../../../Component/Managementtop'
import { useRequestData } from '../../../hooks/RequestDataHook'
import { useLanguage } from '../../../Providers/LanguageContext'
import HttpClient from '../../../utils/HttpClient'
import ClassificationExpenseEdit from "../../../Component/expense/ClassificationExpenseEdit";
import { UserContext } from '../../../Providers/UserWrapper'
import expense_details_en3 from "../../../Audio_files/III. Expense Manager Audio/III c. Edit Expense Detail User Selected Option/iii_edit_expense_detail_audio_a.mp3";
import expense_details_en4 from "../../../Audio_files/III. Expense Manager Audio/III c. Edit Expense Detail User Selected Option/iii_edit_expense_detail_audio_b.mp3";
import expense_details_es3 from "../../../spanish_audio_files/III. Expense Manager Audio/III c. Edit Expense Detail User Selected Option/iii_edit_expense_detail_audio_a_es.mp3";
import expense_details_es4 from "../../../spanish_audio_files/III. Expense Manager Audio/III c. Edit Expense Detail User Selected Option/iii_edit_expense_detail_audio_b_es.mp3";
import { SpeechContext } from '../../../Providers/SpeechWrapper';
import moment from 'moment';
import CustomDateBtn from '../../../Component/CustomDateBtn'
import { useLocation, useNavigate } from 'react-router-dom'


function CustomerVendorDateWise() {

    const { currAudio, setCurrAudio, playing } = useContext(SpeechContext);
    const location = useLocation();
    const navigate = useNavigate();

    const searchBoxRef = useRef();
    const timerRef = useRef();
    const { language, currentLanguage } = useLanguage();
    const [expenses, setExpenses] = useState([]);
    const [sales, setSales] = useState([]);
    const [isCleared, setIsCleared] = useState(false);
    const [modalOpenSt, setModalOpenSt] = useState(false);
    const [filters, setFilters] = useState({
        vendor: "",
        startDate: "",
        endDate: "",
        transDate: "",
        project: ""
    })

    console.log("expensesDatt", expenses)

    const getProjectVal = (val) => {
        const value = val.target.value;
        if (value !== "Select project") {
            setFilters(prev => ({ ...prev, project: value }))
        } else {
            setIsCleared(false)
            setFilters(prev => ({ ...prev, project: "" }))
        }
        console.log(val.target.value, "project value")
    }

    const { loading: projectsLoading, data: projectsData, error: projectsErr } = useRequestData("projectname", "GET", {});

    const fetchExpenses = async () => {
        const sendData = {
            startDate: filters?.startDate,
            endDate: filters?.endDate
        }
        // const res = await HttpClient.requestData("expense-edit-view", "POST", sendData);
        const res = await HttpClient.requestData("view-data-transiction-bydate", "POST", sendData);
        console.log('expenseres', res)
        if (res && res.status) {
            const arr1 = res.data.filter((item) => item.vendorName?.toLowerCase().includes(filters.vendor?.toLowerCase()))
            // const arr2 = filters.project ? arr1.filter((item) => item.projectName === filters.project) : arr1
            // const arr3 = filters.transDate ? arr2.filter((item) => moment(item.transactionDate).format('yyyy-MM-DD') === filters.transDate) : arr2
            setExpenses(arr1);
        } else {
        }

    }

    const fetchSales = async () => {
        const sendData = {
            startDate: filters?.startDate,
            endDate: filters?.endDate
        }
        const res = await HttpClient.requestData("view-all-sales-bytransicdate", "POST", sendData);
        console.log('salesRes', res)
        if (res && res.status) {
            const arr1 = res.data.filter((item) => item.customerName?.toLowerCase().includes(filters.vendor?.toLowerCase()))
            // const arr2 = filters.project ? arr1.filter((item) => item.projectName === filters.project) : arr1
            // const arr3 = filters.transDate ? arr2.filter((item) => moment(item.transactionDate).format('yyyy-MM-DD') === filters.transDate) : arr2
            console.log('arr1sales', arr1)
            setSales(arr1);
        } else {
            console.log("Error", res)
        }
    }

    console.log('saless', sales)

    useEffect(() => {
        fetchExpenses();
        fetchSales();
        fetchExpensesByFilter();
    }, [filters])

    const getVendorName = (e) => {
        const value = e.target.value;
        if (timerRef.current) {
            clearTimeout(timerRef.current)
        }
        timerRef.current = setTimeout(() => {
            setFilters(prev => ({ ...prev, vendor: value }))
        }, 500)
    }

    const handleFilterClassChange = (e) => {
        const value = e.target.value;
        if (value === language.classification) {
            fetchExpenses()
        } else {
            fetchExpensesByFilter(value)
        }
        console.log(e.target.value)
    }

    const fetchExpensesByFilter = async (val) => {
        // const res = await HttpClient.requestData("expense-filter", "POST", { type: val });
        // if (res && res.status) {
        //   setExpenses(res.data)
        // } else {
        //   console.log("Error", res)
        // }
        if (val === 'Recent') {
            let arr = expenses;
            let arrSal = sales;
            sortByDate(arr)
            sortByDate(arrSal)
            let arr2 = arr.reverse()
            let arrSal2 = arrSal.reverse()
            setExpenses([...arr2]);
            setSales([...arrSal2]);
        }
        else if (val === 'Oldest') {
            let arr = expenses;
            let arrSal = sales;
            sortByDate(arr);
            sortByDate(arrSal);
            setExpenses([...arr])
            setSales([...arrSal])
        }
        else if (val === 'Z-A') {
            let arr = expenses;
            let arrSal = sales;
            sortByNamesVendor(arr);
            sortByNamesCustomer(arrSal);
            let arr2 = arr.reverse();
            let arrSal2 = arrSal.reverse();
            setExpenses([...arr2]);
            setSales([...arrSal2]);
        }
        else if (val === 'A-Z') {
            let arr = expenses;
            let arrSal = sales;
            sortByNamesVendor(arr);
            sortByNamesCustomer(arrSal);
            setExpenses([...arr]);
            setSales([...arrSal]);
        }
        console.log('vall', val)
    }

    const sortByDate = (val) => {
        console.log('sortval')
        val.sort((a, b) => {
            let da = new Date(a.transactionDate),
                db = new Date(b.transactionDate);
            return da - db;
        });
        // console.log('sortval', val)
        return val
    }

    const sortByNamesVendor = (val) => {
        val.sort((a, b) => {
            let fa = a.vendorName?.toLowerCase(),
                fb = b.vendorName.toLowerCase();
            if (fa < fb) {
                return -1;
            }
            if (fa > fb) {
                return 1;
            }
            return 0;
        });
    }

    const sortByNamesCustomer = (val) => {
        val.sort((a, b) => {
            let fa = a.customerName?.toLowerCase(),
                fb = b.customerName?.toLowerCase();
            if (fa < fb) {
                return -1;
            }
            if (fa > fb) {
                return 1;
            }
            return 0;
        });
    }



    const getStartDate = (value) => {
        setFilters(prev => ({ ...prev, startDate: value }))
    }
    const getEndDate = (value) => {
        setFilters(prev => ({ ...prev, endDate: value }))
    }

    // form dashboard profit
    const getHoverData = async (time) => {
        const data = {
            "dayType": time
        }

        // expense hover
        const res1 = await HttpClient.requestData("dashboard-two-hover-expense", "POST", data);
        if (res1 && res1?.status) {
            // setHoverData(prev => ({ ...prev, expense: res1?.data }))
            setFilters((prev) => ({
                ...prev,
                startDate: res1?.data?.startDate,
                endDate: res1?.data?.endDate,
            }))
        } else {

        }
    }

    useEffect(() => {
        if (location?.state?.time) {
            getHoverData(location?.state?.time);
        }
    }, [location])

    useEffect(() => {
        if (playing) {
            if (currAudio) {
                if (!currAudio.paused) {
                    currAudio.pause()
                }
            }
            if (currentLanguage === "eng") {
                setCurrAudio(new Audio(expense_details_en3))
            } else {
                setCurrAudio(new Audio(expense_details_es3))
            }
        } else {
            if (currAudio) {
                if (!currAudio.paused) {
                    currAudio.pause()
                    setCurrAudio(null)
                }
            }
        }

        return () => {
            if (currAudio && !currAudio.paused) {
                currAudio.pause()
            }
        }
    }, [playing, currentLanguage])


    // useEffect(() => {
    //   console.log("currAudio", currAudio)
    //   if (currAudio) {
    //     if (!currAudio.paused) {
    //       currAudio.pause()
    //     }
    //     currAudio.play()
    //   }
    //   return () => {
    //     if (currAudio && !currAudio.paused) {
    //       currAudio.pause()
    //     }
    //   }
    // }, [currAudio])

    useEffect(() => {
        console.log(modalOpenSt, "")
        if (playing) {
            if (modalOpenSt) {
                if (currentLanguage === "eng") {
                    setCurrAudio(new Audio(expense_details_en4))
                } else {
                    setCurrAudio(new Audio(expense_details_en4))
                }
            } else {
                if (currAudio && !currAudio.paused) {
                    currAudio.pause()
                }
            }
        }
        return () => {
            if (currAudio && !currAudio.paused) {
                currAudio.pause()
            }
        }
    }, [modalOpenSt])



    return (
        <div className='container'>
            {/* <Managementtop
                title={language.search_customer}
                isCleared={isCleared}
                setIsCleared={setIsCleared}
                getVendorName={getVendorName}
                getCreationDate={getCreationDate}
                searchBoxRef={searchBoxRef}
            /> */}

            <section id='mng-top'>
                <div className='container'>
                    <div className='row'>

                        <div className='col'>
                            <div className='viewInsighDiv'>
                                <h2>{language.expense_management}</h2>
                                <div className='viewInsignInpBtnDiv'>
                                    <button
                                        className='viewInsighBtn'
                                        onClick={() => navigate("/news", { state: { time: location.state.time } })}
                                    >
                                        {language.view_insight}
                                    </button>
                                    <div className='viewInsighInpDiv'>
                                        <input
                                            type='text'
                                            onChange={getVendorName}
                                            ref={searchBoxRef}
                                        />
                                        <i class="fa-solid fa-magnifying-glass"></i>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* <div className='col-xl-6 col-lg-6 col-md-12 col-12'>
                            <div className='left-heading'>
                                <h2>{language.expense_management}</h2>
                            </div>
                        </div> */}
                        {/* <div className='col-xl-6 col-lg-6 col-md-12 col-12'>
                            <div className='right-box'>
                                <div className='viewInsignInpBtnDiv'>
                                    <button
                                        className='viewInsighBtn mr-1'
                                        onClick={() => navigate("/news", { state: { time: location.state.time } })}
                                    >
                                        View Insights
                                    </button>
                                    <form>
                                        <div className='top-search'>
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id=""
                                                    aria-describedby=""
                                                    placeholder=""
                                                    onChange={getVendorName}
                                                    ref={searchBoxRef}
                                                />
                                                <i class="fa-solid fa-magnifying-glass"></i>
                                            </div>
                                        </div>

                                    </form>
                                </div>


                            </div>
                        </div> */}
                    </div>
                </div>
            </section>

            <div id='creation'>
                <div className='container'>
                    <CustomDateBtn text={language.start_date} getValue={getStartDate} setIsCleared={setIsCleared} isCleared={isCleared} filters={{ date: filters?.startDate }} />
                </div>
                <div className='container'>
                    <CustomDateBtn text={language.end_date} getValue={getEndDate} setIsCleared={setIsCleared} isCleared={isCleared} filters={{ date: filters?.endDate }} />
                </div>
            </div>


            <ClassificationExpenseEdit
                expenses={expenses}
                sales={sales}
                fetchSales={fetchSales}
                handleFilterClassChange={handleFilterClassChange}
                setFilters={setFilters}
                fetchExpenses={fetchExpenses}
                searchBoxRef={searchBoxRef}
                setIsCleared={setIsCleared}
                setModalOpenSt={setModalOpenSt}
            />
        </div>
    )
}

export default CustomerVendorDateWise