import React from "react";
import { useLanguage } from "../../Providers/LanguageContext";
import SignUpButton from "./SignUpButton";
import iMg1 from "../../images/1_3 USO GENERAL.png";
import iMg2 from "../../images/1_1 USO GENERAL.png";
import iMg3 from "../../images/1_2.png";
import iMg4 from "../../images/1_4 USO GENERAL.png";
import iMg5 from "../../images/ilustraciones soluciones industriales_empresas contables 3.png";
import iMg5en from "../../images/ilustraciones soluciones industriales_empresas contables 3 EN.png";
import iMg6 from "../../images/Bancos y el Sector Bancario_6-87.png";
import iMg7 from "../../images/Bancos y el Sector Bancario-88.png";
import bodyimage from "../../images/fondo.png";
import tick from "../../images/tick.png";
import { useNavigate } from "react-router-dom";
import SignUpButton2 from "./SignUpButton2";
const GeneralUse = () => {
  const { language,currentLanguage } = useLanguage();
  const navigate = useNavigate();

  return (
    <>
       {/* <Helmet>
        <link rel="canonical" href="https://fisibility.com/generalUse" />
      </Helmet> */}
      {/* <section id="agriculture_sec">
        <div className="container">
          <div className="agriculture_head">
            <div>
              <h3>{language.fisibility_key_feture}:</h3>
              <h2>{language.general_use}</h2>
            </div>
          </div>
          <div className="agriculture_list">
            <ul>
              <li>
                <strong>{language.financial_management}: </strong>
                {language.fisi_provide_compre}
              </li>
              <li>
                <strong>{language.expence_tracking}: </strong>
                {language.fisi_users_track}
              </li>
              <li>
                <strong>{language.automated_report}: </strong>
                {language.fisi_gen_details}
              </li>
              <li>
                <strong>{language.bill_management}: </strong>
                {language.fisi_manage_bills}
              </li>
              <li>
                <strong>{language.security}: </strong>
                {language.fisi_secure_user}
              </li>
            </ul>
          </div>

          <SignUpButton />
        </div>
      </section> */}

      <section
        id="agriculture_sec"
        className="banking"
        style={{ backgroundImage: `url('${bodyimage}')`, position: "relative", paddingBottom: "0" }}
      >
        <div className="container">
          <div className="agriculture_head" style={{ marginBottom: "0" }}>
            <h3 style={{ color: "#0e2537" }}>
              {language.fisi_key_benifits}
            </h3>
          </div>
          <div className="row mb-5">
            <div className="col-md-6 col-12">
              <div className="agriculture_headtext">
                <h5>
                  {language.general_app_platform}
                </h5>
                <div className="tickpara">
                  <div className="tick">
                    <img src={tick}></img>
                  </div>
                  <div>
                    <h4 style={{ color: "#333333" }}>
                      {language.financial_management}
                    </h4>
                    <p>
                      {language.fisi_provide_compre}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-12 m-auto">
              <div className="agriculture_img">
                <img src={iMg2} className="img-fluid" alt="" />
              </div>
            </div>
          </div>



          <div className="row mb-5">
            <div className="col-md-5 col-12 m-auto">
              <div className="agriculture_img">
                <img src={iMg3} className="img-fluid" alt="" />
              </div>
            </div>
            <div className="col-md-7 col-12">
              <div className="agriculture_headtext">
                <div className="tickpara">
                  <div className="tick">
                    <img src={tick}></img>
                  </div>
                  <div>
                    <h4 style={{ color: "#333333" }}>{language.expence_tracking}</h4>
                    <p>
                      {language.fisi_users_track}
                    </p>
                  </div>
                </div>
              </div>
            </div>

          </div>

          <div className="row mb-5">

            <div className="col-md-8 col-12">
              <div className="agriculture_headtext">
                <div className="tickpara">
                  <div className="tick">
                    <img src={tick}></img>
                  </div>
                  <div>
                    <h4 style={{ color: "#333333" }}>
                      {language.automated_report}
                    </h4>
                    <p>
                      {language.fisi_gen_details}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-12 m-auto">
              <div className="agriculture_img">
                <img src={iMg1} className="img-fluid" alt="" />
              </div>
            </div>
          </div>



          <div className="row mb-5">
            <div className="col-md-4 col-12 m-auto">
              <div className="agriculture_img">
                <img src={iMg4} className="img-fluid" alt="" />
              </div>
            </div>
            <div className="col-md-8 col-12">
              <div className="agriculture_headtext">
                <div className="tickpara">
                  <div className="tick">
                    <img src={tick}></img>
                  </div>
                  <div>
                    <h4 style={{ color: "#333333" }}>
                      {language.bill_management}
                    </h4>
                    <p>
                      {language.fisi_manage_bills}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-7 col-12">
              <div className="agriculture_headtext">
                <div className="tickpara">
                  <div className="tick">
                    <img src={tick}></img>
                  </div>
                  <div>
                    <h4 style={{ color: "#333333" }}>
                      {language.security}
                    </h4>
                    <p>
                      {language.fisi_secure_user}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-5 col-12 m-auto">
              <div className="agriculture_img">
                {
                  currentLanguage === 'spanish'
                    ?
                    <img src={iMg5} className="img-fluid" alt="" />
                    :
                    <img src={iMg5en} className="img-fluid" alt="" />
                }
                {/* <div
                  className="registrarAnddescargar"
                  style={{
                    transform: "translate(-50% , -50%)",
                    top: "44%",
                    left: "21%",
                    flexDirection: "column",
                  }}
                >
                  <button
                    className="registrar_Btn"
                    style={{
                      background:
                        " linear-gradient(to right, #18465d 1%,#0f283b 100%,#2989d8 100%)",
                      border: "0",
                      marginBottom: "20px",
                    }}
                    onClick={() => navigate('/login')}
                  >
                    {language.homeBannerBtn1}
                  </button>
                  <button className="descargar_Btn">{language.homeBannerBtn2}</button>
                </div> */}
                <SignUpButton2 />
              </div>
            </div>
          </div>


        </div>
      </section>
    </>
  );
};

export default GeneralUse;
