import React, { useContext, useEffect, useState } from 'react'
import Allnews from '../../Component/Allnews'
import CustomModal from '../../Component/CustomModal';
import Managebtn from '../../Component/Managebtn'
import { useLanguage } from '../../Providers/LanguageContext'
import HttpClient from '../../utils/HttpClient';
import { UserContext } from "../../Providers/UserWrapper"
import GoodnewsData from './GoodnewsData';
import BadNewsData from './BadNewsData';
import GeneralNewsData from './GeneralNewsData';
import { SpeechContext } from '../../Providers/SpeechWrapper';
import news_en1 from "../../Audio_files/IX. CIPBITS Manager Audio/IX. a. View My News User Selected Option/ix_view_my_news_audio_a.mp3";
import news_es1 from "../../spanish_audio_files/IX. CIPBITS Manager Audio/IX. a. View My News User Selected Option/ix_view_my_news_audio_a_es.mp3";
import { async } from '@firebase/util';
import NewsModal from './NewsModal';
import { useLocation } from 'react-router-dom';


function Index() {
  const { profileData } = useContext(UserContext);
  const location = useLocation();
  // console.log("locationness", location)

  console.log(profileData, "userData")

  const { language, currentLanguage } = useLanguage()
  const { currAudio, setCurrAudio, playing, setShowGif, setGifToShow, setGifPoints } = useContext(SpeechContext);

  const [time, setTime] = useState("day")
  const [goodNewsData, setGoodNewsData] = useState({});
  const [badNewsData, setBadNewsData] = useState({});
  const [generalNewsData, setGeneralNewsData] = useState({});

  const [modalData, setModalData] = useState(null);
  const [modalActive, setModalActive] = useState(null);
  const getTime = (val) => {
    setTime(val)
  }

  console.log("timefddds", time)

  useEffect(() => {
    if (location?.state?.time) {
      getTime(location?.state?.time)
    }
  }, [location?.state?.time])

  const [modalIsOpen, setIsOpen] = useState(false);

  function openModal(text) {
    console.log(text, "textttt")
    if (text === language.good_news) {
      setModalActive("good_news")
      setModalData(goodNewsData)
    } else if (text === language.bad_news) {
      setModalActive("bad_news")
      setModalData(badNewsData)
    } else if (text === language.general_news) {
      setModalActive("general_news")
      setModalData(generalNewsData)
    }
    setIsOpen(true);
  }


  function closeModal() {
    setIsOpen(false);
  }

  const FixedTwoDecimal = (obj) => {
    Object.keys(obj).forEach(key => {
      obj[key] = Number(obj[key]).toFixed(2);
    });
    return obj
  }

  const fetchGoodNewsDataBu = async (timeframe) => {
    const res = await HttpClient.requestData("good-news-bu", "POST", { dayType: timeframe });
    console.log("good_news", res)
    if (res && res.status && res.data) {
      // if (res.message && res.message === "Cipbits rewards 1 points") {
      //   setShowGif(true)
      //   setGifToShow("Cipbits rewards 1 points")
      //   setGifPoints(1)
      // }
      let d = FixedTwoDecimal(res.data)
      console.log('deciData', d)
      setGoodNewsData(d);
    } else {
      console.log(res, "error")
    }
  }

  const fetchGoodNewsDataPu = async (timeframe) => {
    const res = await HttpClient.requestData("good-news-pu", "POST", { dayType: timeframe });
    console.log("good_news", res)
    if (res && res.status && res.data) {
      // if (res.message && res.message === "Cipbits rewards 1 points") {
      //   setShowGif(true)
      //   setGifToShow("Cipbits rewards 1 points")
      //   setGifPoints(1)
      // }
      let d = FixedTwoDecimal(res.data)
      setGoodNewsData(d);
    } else {
      console.log(res, "error")
    }
  }

  const fetchBadNewsDataBu = async (timeframe) => {
    const res = await HttpClient.requestData("bad-news-bu", "POST", { dayType: timeframe });
    console.log("bad news", res.data)
    if (res && res.status && res.data) {
      // if (res.message && res.message === "Cipbits rewards 1 points") {
      //   setShowGif(true)
      //   setGifToShow("Cipbits rewards 1 points")
      //   setGifPoints(1)
      // }
      let d = FixedTwoDecimal(res.data)
      setBadNewsData(d);
    } else {
      console.log(res, "error")
    }
  }


  const fetchBadNewsDataPu = async (timeframe) => {
    const res = await HttpClient.requestData("bad-news-pu", "POST", { dayType: timeframe });
    console.log("bad news", res)
    if (res && res.status && res.data) {
      // if (res.message && res.message === "Cipbits rewards 1 points") {
      //   setShowGif(true)
      //   setGifToShow("Cipbits rewards 1 points")
      //   setGifPoints(1)
      // }
      let d = FixedTwoDecimal(res.data)
      setBadNewsData(d);
    } else {
      console.log(res, "error")
    }
  }

  const fetchGeneralNewsBu = async (timeframe) => {
    const res = await HttpClient.requestData("general-news-bu", "POST", { dayType: timeframe });
    console.log("general news", res)
    if (res && res.status && res.data) {
      // if (res.message && res.message === "Cipbits rewards 1 points") {
      //   setShowGif(true)
      //   setGifToShow("Cipbits rewards 1 points")
      //   setGifPoints(1)
      // }
      let d = FixedTwoDecimal(res.data)
      setGeneralNewsData(d);
    } else {
      console.log(res, "error")
    }
  }

  const fetchGeneralNewsPu = async (timeframe) => {
    const res = await HttpClient.requestData("general-news-pu", "POST", { dayType: timeframe });
    console.log("general news", res)
    if (res && res.status && res.data) {
      // if (res.message && res.message === "Cipbits rewards 1 points") {
      //   setShowGif(true)
      //   setGifToShow("Cipbits rewards 1 points")
      //   setGifPoints(1)
      // }
      let d = FixedTwoDecimal(res.data)
      setGeneralNewsData(d);
    } else {
      console.log(res, "error")
    }
  }

  const fetchRewardCipbit = async () => {
    const res = await HttpClient.requestData('cipbits-rewards', 'POST', {});
    console.log('resrewardcc', res)
    if (res && res.status) {
      setGifPoints(2)
    }
  }

  useEffect(() => {
    fetchRewardCipbit();
  }, [])

  useEffect(() => {
    if (profileData && profileData.userType === "Business") {
      fetchGoodNewsDataBu(time);
      fetchBadNewsDataBu(time);
      fetchGeneralNewsBu(time);
    } else if (profileData && profileData.userType === "Personal") {
      fetchGoodNewsDataPu(time);
      fetchBadNewsDataPu(time);
      fetchGeneralNewsPu(time);
    }
  }, [time, profileData])

  useEffect(() => {
    if (playing) {
      if (currAudio) {
        if (!currAudio.paused) {
          currAudio.pause()
        }
      }
      if (currentLanguage === "eng") {
        setCurrAudio(new Audio(news_en1))
      } else {
        setCurrAudio(new Audio(news_es1))
      }
    } else {
      if (currAudio) {
        if (!currAudio.paused) {
          currAudio.pause()
          setCurrAudio(null)
        }
      }
    }

    return () => {
      if (currAudio && !currAudio.paused) {
        currAudio.pause()
      }
    }
  }, [playing, currentLanguage])


  // useEffect(() => {
  //   console.log("currAudio", currAudio)
  //   if (currAudio) {
  //     if (!currAudio.paused) {
  //       currAudio.pause()
  //     }
  //     currAudio.play()
  //   }
  //   return () => {
  //     if (currAudio && !currAudio.paused) {
  //       currAudio.pause()
  //     }
  //   }
  // }, [currAudio])

  return (

    <>
      <Managebtn getTime={getTime} value={time} />
      <Allnews text={language.good_news} onClick={openModal} />
      <Allnews text={language.bad_news} onClick={openModal} />
      <Allnews text={language.general_news} onClick={openModal} />
      <NewsModal
        modalIsOpen={modalIsOpen}
        setIsOpen={setIsOpen}
        openModal={openModal}
        closeModal={closeModal}
      >

        {
          modalActive === "good_news" && (
            <GoodnewsData
              data={goodNewsData}
              time={time}
              closeModal={closeModal}
              userType={profileData.userType}
              currency={profileData.currency}
            />
          )
        }

        {
          modalActive === "bad_news" && (
            <BadNewsData
              data={badNewsData}
              closeModal={closeModal}
              time={time}
              userType={profileData.userType}
              currency={profileData.currency}
            />
          )
        }

        {
          modalActive === "general_news" && (
            <GeneralNewsData
              data={generalNewsData}
              closeModal={closeModal}
              time={time}
              userType={profileData.userType}
              currency={profileData.currency}
            />
          )
        }
      </NewsModal>
    </>

  )
}

export default Index