import React, { useEffect, useState } from 'react'
// import HttpClient from '../utils/HttpClient';
import moment from 'moment';
// import NoDataFound from '../Component/NoDataFound';
// import { useUserData } from '../Providers/UserWrapper';
import toast from 'react-hot-toast';
import NoDataFound from '../../../Component/NoDataFound';
import { useUserData } from '../../../Providers/UserWrapper';
import HttpClient from '../../../utils/HttpClient';
import { useLanguage } from '../../../Providers/LanguageContext';

function MissingInvDocModal({ closeModal, singleUserData }) {
    const [missingDocData, setMissingDocData] = useState([]);
    const { setIsLoading } = useUserData()
    const { currentLanguage } = useLanguage()
    // console.log("singleUserData", singleUserData)

    // get missing docs
    const getMissingDocs = async () => {
        setIsLoading(true)
        const res = await HttpClient.requestData("investor/investor-get-missing-document/" + singleUserData?.accountRegCode, "GET")
        // console.log("ressd", res)
        setIsLoading(false)
        if (res && res?.status) {
            setMissingDocData(res?.data)
        }
    }

    // calculate past due day
    const calCulateNumDays = (day) => {
        let date1 = new Date();
        let date2 = new Date(moment(day).format("MM/DD/YYYY"));

        // Calculating the time difference
        // of two dates
        let Difference_In_Time = date2.getTime() - date1.getTime();

        // Calculating the no. of days between
        // two dates
        let Difference_In_Days = Math.round(Difference_In_Time / (1000 * 3600 * 24));

        console.log("date1", date1, date2, moment(day).format("DD/MM/YYYY"), Difference_In_Days)
        // To display the final no. of days (result)
        return Difference_In_Days
    }

    // send all reminder
    const handleSendAllReminder = async () => {
        setIsLoading(true);
        const data = {
            "language": currentLanguage === "eng" ? "EN" : "ES"
        }
        const res = await HttpClient.requestData("investor/send-missing-all-mail-user/" + singleUserData?.accountRegCode, "POST", data)
        // console.log("itemmvv", item)
        setIsLoading(false);
        if (res && res?.status) {
            toast.success("A Reminder Mail has been Sent Successfully")
        } else {
            toast.error(res?.message)
        }
    }



    useEffect(() => {
        getMissingDocs()
    }, [singleUserData])

    return (
        <>
            <section className='missingDocModal'>
                <div className='missingDocModInn'>
                    <div className='missingDocClose' onClick={() => closeModal()}>
                        <i class="fa-solid fa-xmark"></i>
                    </div>
                    <h4 className='bookSecModalHead'>{singleUserData?.client_name}: Missing Documents</h4>
                    <div className='missingDocTbl'>
                        <table style={{ width: "100%" }}>
                            <thead>
                                <tr>
                                    <th>Document Type</th>
                                    <th>Due Date</th>
                                    <th>Status</th>
                                    <th>Days Past Due</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    missingDocData?.map(item => {
                                        // const checkDate = moment(item?.dueDate).isAfter(moment().format())
                                        // console.log("checkDatevv", checkDate)
                                        return (
                                            <tr>
                                                <td>
                                                    <button className='bookModalBtn'>{item?.documentType}</button>
                                                </td>
                                                <td>
                                                    <button className='bookModalBtn'>{moment(item?.dueDate).format("LL")}</button>
                                                </td>
                                                <td>
                                                    Missing
                                                </td>
                                                <td>
                                                    {calCulateNumDays(item?.dueDate)}
                                                </td>
                                            </tr>
                                        )
                                    }
                                    )
                                }
                            </tbody>
                        </table>
                        {missingDocData?.length === 0 && <NoDataFound />}

                    </div>


                    <button
                        className='missingDocSndBtn'
                        onClick={() => handleSendAllReminder()}
                    >
                        Send Reminders
                    </button>
                </div>
            </section>
        </>
    )
}

export default MissingInvDocModal