import React, { useEffect, useState } from 'react'
import { useLanguage } from '../../../../Providers/LanguageContext'
import { useParams } from 'react-router-dom';
import HttpClient from '../../../../utils/HttpClient';
import { useUserData } from '../../../../Providers/UserWrapper';
import BackArrow from '../../../../Component/BackArrow/BackArrow';

const ViewSatNormal = () => {
    const { language, currentLanguage } = useLanguage();
    const params = useParams();
    const { setIsLoading } = useUserData();

    const [singleData, setSingleData] = useState([]);

    const getSingleData = async () => {
        setIsLoading(true)
        const res = await HttpClient.requestData("view-invoice-web-sat-single/" + params.id, "GET", {});
        console.log("resnorminvssat", res);
        if (res && res?.status) {
            setSingleData(res.data);
            setIsLoading(false);
        } else {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getSingleData();
    }, [])


    return (
        <div className='container'>
            <BackArrow />
            <form className="sendInfoForm">
                <div className="sendInfoFirstFormDiv">

                    {/* upper left part */}
                    <div className="sendInfoSubDiv">
                        <p className="sendInfoHead">{language.invoice_information}</p>
                        <div className="sendInnerSubDiv">
                            <p className="sendFisiLabel">{language.rfc_issuer}:</p>
                            <p>{singleData?.RFCIssuer}</p>
                        </div>
                        <div className="sendInnerSubDiv">
                            <p className="sendFisiLabel">{language.issuer_name}:</p>
                            <p>{singleData?.IssuerName}</p>
                        </div>
                        <div className="sendInnerSubDiv">
                            <p className="sendFisiLabel">Folio:</p>
                            <p>{singleData?.Follo}</p>
                        </div>
                        <div className="sendInnerSubDiv">
                            <p className="sendFisiLabel">{language.rfc_reciever}:</p>
                            <p>{singleData?.RFCReceiver}</p>
                        </div>
                        <div className="sendInnerSubDiv">
                            <p className="sendFisiLabel">{language.recipient_name}:</p>
                            <p>{singleData?.RecipientName}</p>
                        </div>

                        <div className="sendInnerSubDiv">
                            <p className="sendFisiLabel">{language.use_cfdi}:</p>
                            <p>{singleData?.CFDIuse}</p>
                        </div>
                    </div>

                    {/* upper right part */}
                    <div className="sendInfoSubDiv">
                        <p className="sendInfoHead demoTextClr">gthj</p>
                        <div className="sendInnerSubDiv">
                            <p className="sendFisiLabel">{language.tax_folio}:</p>
                            <p>{singleData?.TaxFolio}</p>
                        </div>
                        <div className="sendInnerSubDiv">
                            <p className="sendFisiLabel">{language.csd_serial_no}:</p>
                            <p>{singleData?.CSDSerialNo}</p>
                        </div>
                        <div className="sendInnerSubDiv">
                            <p className="sendFisiLabel">{language.series}:</p>
                            <p>{singleData?.Series}</p>
                        </div>
                        <div className="sendInnerSubDiv">
                            <p className="sendFisiLabel">{language.postal_date_time}:</p>
                            <p>{singleData?.PostcardDateAndTimeOfIssue}</p>
                        </div>
                        <div className="sendInnerSubDiv">
                            <p className="sendFisiLabel">
                                {language.voucher_effect}:
                            </p>
                            <p>{singleData?.VoucherEffect}</p>
                        </div>
                        <div className="sendInnerSubDiv">
                            <p className="sendFisiLabel">{language.tax_regime}:</p>
                            <p>{singleData?.TaxRegime}</p>
                        </div>
                    </div>

                </div>

                {/*email concepts */}
                <div className="sendInfoSecondFormDiv">
                    <div className="secInfoSubDiv">
                        <div className="plusConDiv">
                            <p className="concepLabel">{language.email_recipients}:</p>
                        </div>
                        <div className="entInpDiv">
                            <p>{singleData.email}</p>
                        </div>
                    </div>
                </div>

                <div className="sendInfoThirdFormDiv">

                    {/* add more part */}
                    <div className="cnrFirstDiv">
                        <table style={{ textAlign: "center", border: "1px solid black" }}>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th className="invoTableTH">
                                        <span className="descSpan">
                                            {language.product_service_key}
                                        </span>
                                    </th>
                                    <th className="invoTableTH">
                                        <span>No. Identification</span>
                                    </th>
                                    <th className="invoTableTH">
                                        <span>{language.amount}</span>
                                    </th>
                                    <th className="invoTableTH">
                                        <span>{language.unit_key}</span>
                                    </th>
                                    <th className="invoTableTH">
                                        <span>{language.unit}</span>
                                    </th>
                                    <th className="invoTableTH">
                                        <span>{language.unit_value}</span>
                                    </th>
                                    <th className="invoTableTH">
                                        <span>Importe</span>
                                    </th>
                                    <th className="invoTableTH">
                                        <span>{language.discount}</span>
                                    </th>
                                    <th className="invoTableTH">
                                        <span>{language.pending_no}</span>
                                    </th>
                                    <th className="invoTableTH">
                                        <span>{language.prop_acc_no}</span>
                                    </th>
                                    <th className="invoTableTH">
                                        <span>Description</span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {singleData?.Concepts?.map((item, i, arr) => {
                                    return (
                                        <tr key={i}>
                                            <td>
                                            </td>
                                            <td className="invoTableTD">
                                                <p>{item?.ProductAndOrServiceKey}</p>
                                            </td>
                                            <td className="invoTableTD">
                                                <p>{item?.NoIdentification}</p>
                                            </td>
                                            <td className="invoTableTD">
                                                <p>{item?.Amount}</p>
                                            </td>
                                            <td className="invoTableTD">
                                                <p>{item?.UnitKey}</p>
                                            </td>
                                            <td className="invoTableTD">
                                                <p>{item?.Unit}</p>
                                            </td>
                                            <td className="invoTableTD">
                                                <p>{item?.UnitValue}</p>
                                            </td>
                                            <td className="invoTableTD">
                                                <p>{item?.ImpAmount}</p>
                                            </td>
                                            <td className="invoTableTD">
                                                <p>{item?.Discount}</p>
                                            </td>
                                            <td className="invoTableTD">
                                                <p>{item?.PendingNo}</p>
                                            </td>
                                            <td className="invoTableTD">
                                                <p>{item?.PropertyAccountNo}</p>
                                            </td>
                                            <td className="invoTableTD">
                                                <p>{item?.Description}</p>
                                            </td>
                                        </tr>
                                    )
                                })
                                }
                            </tbody>
                        </table>
                    </div>

                    {/* tax /  type  /  base */}
                    <div className="mt-4 cnrSecondDiv">
                        <table style={{ textAlign: "center", border: "1px solid black" }}>
                            <thead>
                                <tr>
                                    <th className="invoTableTH">
                                        <span>{language.tax}</span>
                                    </th>
                                    <th className="invoTableTH">
                                        <span>{language.type}</span>
                                    </th>
                                    <th className="invoTableTH">
                                        <span>Base</span>
                                    </th>
                                    <th className="invoTableTH">
                                        <span>{language.type_factor}</span>
                                    </th>
                                    <th className="invoTableTH">
                                        <span>{language.rate_of_quota}</span>
                                    </th>
                                    <th className="invoTableTH">
                                        <span>{language.rate_amount}</span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="invoTableTD">
                                        <p>{singleData?.Tax}</p>

                                    </td>
                                    <td className="invoTableTD">
                                        <p>{singleData?.Guy}</p>

                                    </td>
                                    <td className="invoTableTD">
                                        <p>{singleData?.Base}</p>

                                    </td>
                                    <td className="invoTableTD">
                                        <p>{singleData?.TypeFactor}</p>

                                    </td>
                                    <td className="invoTableTD">
                                        <p>{singleData?.RateorQuota}</p>

                                    </td>
                                    <td className="invoTableTD">
                                        <p>{singleData?.RateAmount}</p>

                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                </div>

                {/* bottom part */}
                <div className="tableAfterDiv">
                    <div className="tableInnerAfterDiv">
                        <div className="subTabDiv">
                            <p className="subLabelTab">{language.currency2}:</p>
                            <div className="subInSeDiv">
                                <p>{singleData.Currency}</p>

                            </div>
                        </div>
                        <div className="subTabDiv">
                            <p className="subLabelTab">{language.way_to_pay}:</p>
                            <div className="subInSeDiv">
                                <p>{singleData.WayToPay}</p>

                            </div>
                        </div>
                        <div className="subTabDiv">
                            <p className="subLabelTab">{language.payment_method}:</p>
                            <div className="subInSeDiv">
                                <p>{singleData.PaymentMethod}</p>

                            </div>
                        </div>
                    </div>
                    <div className="tableInnerAfterDiv">
                        <div className="subTabDiv">
                            <p className="subLabelTab">Subtotal:</p>
                            <div className="subInSeDiv">
                                <span className="ivSpan d-none d-md-inline-block"></span>
                                <p>{singleData.Subtotal}</p>

                            </div>
                        </div>
                        <div className="subTabDiv">
                            <p className="subLabelTab">
                                {language.transfer_taxes}:
                            </p>
                            <div className="subInSeDiv">
                                <span className="ivSpan">IVA 16%</span>
                                <div className="ibAfterSpanDiv">
                                    <p>{singleData.TransferTaxes}</p>

                                </div>
                            </div>
                        </div>
                        <div className="subTabDiv">
                            <p className="subLabelTab">Total:</p>
                            <div className="subInSeDiv">
                                <span className="ivSpan d-none d-md-inline-block"></span>
                                <p>{singleData.Total}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </form>

        </div>
    )
}

export default ViewSatNormal
