import React, { useContext, useEffect, useRef, useState } from 'react'
import { Watch } from 'react-loader-spinner'
import Clasification from '../../../Component/Clasification'
import Dropbox from '../../../Component/Dropbox'
import Managementtop from '../../../Component/Managementtop'
import { useRequestData } from '../../../hooks/RequestDataHook'
import { useLanguage } from '../../../Providers/LanguageContext'
import HttpClient from '../../../utils/HttpClient'
import ClassificationExpenseEdit from "../../../Component/expense/ClassificationExpenseEdit";
import { UserContext, useUserData } from '../../../Providers/UserWrapper'
import expense_details_en3 from "../../../Audio_files/III. Expense Manager Audio/III c. Edit Expense Detail User Selected Option/iii_edit_expense_detail_audio_a.mp3";
import expense_details_en4 from "../../../Audio_files/III. Expense Manager Audio/III c. Edit Expense Detail User Selected Option/iii_edit_expense_detail_audio_b.mp3";
import expense_details_es3 from "../../../spanish_audio_files/III. Expense Manager Audio/III c. Edit Expense Detail User Selected Option/iii_edit_expense_detail_audio_a_es.mp3";
import expense_details_es4 from "../../../spanish_audio_files/III. Expense Manager Audio/III c. Edit Expense Detail User Selected Option/iii_edit_expense_detail_audio_b_es.mp3";
import { SpeechContext } from '../../../Providers/SpeechWrapper';
import moment from 'moment';
import { useLocation, useNavigate } from 'react-router-dom'


function EditExpenseDetails() {
  const { isBusiness } = useUserData();
  const navigate = useNavigate()
  const { currAudio, setCurrAudio, playing } = useContext(SpeechContext);
  const searchBoxRef = useRef();
  const timerRef = useRef();
  const { language, currentLanguage } = useLanguage();
  const [expenses, setExpenses] = useState([]);
  const [sales, setSales] = useState([]);
  const [isCleared, setIsCleared] = useState(false);
  const [modalOpenSt, setModalOpenSt] = useState(false);
  const [filters, setFilters] = useState({
    vendor: "",
    transDate: "",
    project: ""
  })

  const location = useLocation();
  useEffect(() => {
    setFilters(prev => ({ ...prev, transDate: location?.state?.startDate }))
  }, [])

  // console.log("locationdfg", location)

  const getProjectVal = (val) => {
    const value = val.target.value;
    if (value !== "Select project") {
      setFilters(prev => ({ ...prev, project: value }))
    } else {
      setIsCleared(false)
      setFilters(prev => ({ ...prev, project: "" }))
    }
    console.log(val.target.value, "project value")
  }

  const { loading: projectsLoading, data: projectsData, error: projectsErr } = useRequestData("projectname", "GET", {});

  // get expense data
  const fetchExpenses = async () => {
    const sendData = {
      vendor: '',
      transDate: ''
    }
    const res = await HttpClient.requestData("expense-edit-view", "POST", sendData);
    // console.log('expenseres', res)
    if (res && res.status) {
      const arr1 = res.data.filter((item) => item.vendorName?.toLowerCase().includes(filters.vendor?.toLowerCase()))
      const arr2 = filters.project ? arr1.filter((item) => item.projectName === filters.project) : arr1
      const arr3 = filters.transDate ? arr2.filter((item) => moment(item.transactionDate).format('yyyy-MM-DD') === filters.transDate) : arr2
      // console.log('compp', moment(arr2[0].transactionDate).format('yyyy-MM-DD')=== filters.transDate)
      // console.log('arr1exp', arr3)
      sortByDate(arr3)
      const arr4 = arr3.reverse()
      setExpenses(arr4);
    } else {
      // console.log("Error", res)
    }

    // console.log('filterexp', filters);
  }

  // get sales data
  const fetchSales = async () => {
    const res = await HttpClient.requestData("get-all-sales", "GET");
    // console.log('salesRes', res)
    if (res && res.status) {
      const arr1 = res.data.filter((item) => item.customerName?.toLowerCase().includes(filters.vendor?.toLowerCase()))
      const arr2 = filters.project ? arr1.filter((item) => item.projectName === filters.project) : arr1
      const arr3 = filters.transDate ? arr2.filter((item) => moment(item.transactionDate).format('yyyy-MM-DD') === filters.transDate) : arr2

      sortByDate(arr3)
      const arr4 = arr3?.reverse()
      setSales(arr4);

    } else {
      console.log("Error", res)
    }
  }


  useEffect(() => {
    fetchExpenses();
    fetchSales();
    fetchExpensesByFilter();
  }, [filters])

  const getVendorName = (e) => {
    const value = e.target.value;
    if (timerRef.current) {
      clearTimeout(timerRef.current)
    }
    timerRef.current = setTimeout(() => {
      setFilters(prev => ({ ...prev, vendor: value }))
    }, 500)
  }

  const handleFilterClassChange = (e) => {
    const value = e.target.value;
    // if (value === language.classification) {
    //   fetchExpenses()
    //   // fetchExpensesByFilter("Recent")
    // } else {
    fetchExpensesByFilter(value)
    // }
  }

  const fetchExpensesByFilter = async (val) => {
    // const res = await HttpClient.requestData("expense-filter", "POST", { type: val });
    // if (res && res.status) {
    //   setExpenses(res.data)
    // } else {
    //   console.log("Error", res)
    // }
    if (val === 'Recent') {
      let arr = expenses;
      let arrSal = sales;
      sortByDate(arr)
      sortByDate(arrSal)
      let arr2 = arr.reverse()
      let arrSal2 = arrSal.reverse()
      setExpenses([...arr2]);
      setSales([...arrSal2]);
    }
    else if (val === 'Oldest') {
      let arr = expenses;
      let arrSal = sales;
      sortByDate(arr);
      sortByDate(arrSal);
      setExpenses([...arr])
      setSales([...arrSal])
    }
    else if (val === 'Z-A') {
      let arr = expenses;
      let arrSal = sales;
      sortByNamesVendor(arr);
      sortByNamesCustomer(arrSal);
      let arr2 = arr.reverse();
      let arrSal2 = arrSal.reverse();
      setExpenses([...arr2]);
      setSales([...arrSal2]);
    }
    else if (val === 'A-Z') {
      let arr = expenses;
      let arrSal = sales;
      sortByNamesVendor(arr);
      sortByNamesCustomer(arrSal);
      setExpenses([...arr]);
      setSales([...arrSal]);
    }
    // console.log('vall', val)
  }
  // console.log('expData', expenses)

  const sortByDate = (val) => {
    console.log('sortval')
    val.sort((a, b) => {
      let da = new Date(a.transactionDate),
        db = new Date(b.transactionDate);
      return da - db;
    });
    // console.log('sortval', val)
    return val
  }

  const sortByNamesVendor = (val) => {
    val.sort((a, b) => {
      let fa = a.vendorName?.toLowerCase(),
        fb = b.vendorName.toLowerCase();
      if (fa < fb) {
        return -1;
      }
      if (fa > fb) {
        return 1;
      }
      return 0;
    });
  }

  const sortByNamesCustomer = (val) => {
    val.sort((a, b) => {
      let fa = a.customerName?.toLowerCase(),
        fb = b.customerName?.toLowerCase();
      if (fa < fb) {
        return -1;
      }
      if (fa > fb) {
        return 1;
      }
      return 0;
    });
  }


  const getCreationDate = (value) => {
    console.log(value, "Creation date")
    setFilters(prev => ({ ...prev, transDate: value }))
  }


  useEffect(() => {
    if (playing) {
      if (currAudio) {
        if (!currAudio.paused) {
          currAudio.pause()
        }
      }
      if (currentLanguage === "eng") {
        setCurrAudio(new Audio(expense_details_en3))
      } else {
        setCurrAudio(new Audio(expense_details_es3))
      }
    } else {
      if (currAudio) {
        if (!currAudio.paused) {
          currAudio.pause()
          setCurrAudio(null)
        }
      }
    }

    return () => {
      if (currAudio && !currAudio.paused) {
        currAudio.pause()
      }
    }
  }, [playing, currentLanguage])


  // useEffect(() => {
  //   console.log("currAudio", currAudio)
  //   if (currAudio) {
  //     if (!currAudio.paused) {
  //       currAudio.pause()
  //     }
  //     currAudio.play()
  //   }
  //   return () => {
  //     if (currAudio && !currAudio.paused) {
  //       currAudio.pause()
  //     }
  //   }
  // }, [currAudio])

  useEffect(() => {
    console.log(modalOpenSt, "")
    if (playing) {
      if (modalOpenSt) {
        if (currentLanguage === "eng") {
          setCurrAudio(new Audio(expense_details_en4))
        } else {
          setCurrAudio(new Audio(expense_details_en4))
        }
      } else {
        if (currAudio && !currAudio.paused) {
          currAudio.pause()
        }
      }
    }
    return () => {
      if (currAudio && !currAudio.paused) {
        currAudio.pause()
      }
    }
  }, [modalOpenSt])



  return (
    <div className='container'>
      <div className='d-flex gap-2 mb-2' >
        <div className=''>
          <button className='w-100 btn btn-outline-success' style={{ fontWeight: "bold" }} onClick={() => navigate('/newexpense')}>{language.post_new_expense}</button>
        </div>

        <div className=''>
          <button className='w-100 btn btn-outline-success' style={{ fontWeight: "bold" }} onClick={() => navigate('/viewexpensedetails')}>{language.view_expense_details}</button>
        </div>
      </div>
      <Managementtop
        title={isBusiness ? language.client_vendor : language.income_vendor}
        isCleared={isCleared}
        setIsCleared={setIsCleared}
        getVendorName={getVendorName}
        getCreationDate={getCreationDate}
        searchBoxRef={searchBoxRef}
        filters={filters}
        time={location?.state?.time}
      />

      <Dropbox
        text={language.select_project}
        transactionFilter={filters}
        getValue={getProjectVal}
        data={projectsData}
      />

      <ClassificationExpenseEdit
        expenses={expenses}
        sales={sales}
        fetchSales={fetchSales}
        handleFilterClassChange={handleFilterClassChange}
        setFilters={setFilters}
        fetchExpenses={fetchExpenses}
        searchBoxRef={searchBoxRef}
        setIsCleared={setIsCleared}
        setModalOpenSt={setModalOpenSt}
      />
    </div>
  )
}

export default EditExpenseDetails;