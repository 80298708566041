import React, { useEffect, useMemo, useState } from 'react'
import ReactApexChart from 'react-apexcharts'
import { useLanguage } from '../../../Providers/LanguageContext'
import { useUserData } from '../../../Providers/UserWrapper';
import HttpClient from '../../../utils/HttpClient';
import { useNavigate } from 'react-router-dom';


function DashboardChartTwo({ data, time, userType, userCode }) {
    const { language, currentLanguage } = useLanguage();
    const { isBusiness, isInvestor, isBookKeeper } = useUserData();
    const navigate = useNavigate();

    const [barIndex, setBarIndex] = useState("");
    const [hoverData, setHoverData] = useState({
        revenue: {},
        expense: {},
        revenueBudget: {},
        expenseBudget: {}
    })

    const [text, setText] = useState(() => {
        if (time === "day") {
            return (language.two_week)
        } else if (time === "week") {
            return (language.two_week_total)
        } else if (time === "month") {
            return (isBusiness ? language.curr_month_act_budget : language.current_month)
        } else if (time === "year") {
            return (isBusiness ? language.curr_year_act_budget : language.current_year)
        }
    })

    // console.log("hoverDatadaaa", hoverData)

    const [series, setSeries] = useState([{
        name: text,
        data: data.length === 3 ? [data[0], data[1], data[2]] : [data[0], data[1], data[2], data[3]]
    }])

    const [options, setOptions] = useState({
        chart: {
            type: 'bar',
            height: "100%",
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '30%',
                endingShape: 'rounded',
                distributed: true,
                dataLabels: {
                    position: 'top', // top, center, bottom
                },
            },

        },
        title: {
            text: text,
            align: 'left',
            margin: 10,
            offsetX: 0,
            offsetY: 0,
            floating: false,
            style: {
                fontSize: '14px',
                fontWeight: 'bold',
                fontFamily: undefined,
                color: '#263238'
            },
        },
        dataLabels: {
            enabled: false,
            formatter: function (val) {
                return "$ " + Number(val).toLocaleString();
            },
            offsetY: -20,
            style: {
                fontSize: "12px",
                colors: ["#304758"]
            }
        },
        stroke: {
            show: true,
            width: -2,
            colors: ['transparent']
        },
        xaxis: {
            categories: data.length === 3
                ?
                userType && userType === 'Business'
                    ?
                    [language.revenue, language.expense, language.profit]
                    :
                    [language.income, language.expense, language.expense_budget]
                :
                [language.revenue, language.revenue_budget, language.expense, language.expense_budget],
            position: "bottom",
            labels: {
                offsetX: 0
            },
        },

        fill: {
            opacity: 1,
            // gradient: {
            //     shade: "light",
            //     type: "horizontal",
            //     shadeIntensity: 0.25,
            //     gradientToColors: undefined,
            //     inverseColors: true,
            //     opacityFrom: 1,
            //     opacityTo: 1,
            //     stops: [50, 0, 100, 100]
            // }
        },
        tooltip: {
            y: {
                formatter: function (val) {

                    return "$ " + val
                }
            },
            enabled: true,
            offsetY: -35,

        },
        colors: ["#3b3425", "#735a24", "#918b7e"],
        legend: {
            show: true,
            showForSingleSeries: false,
            showForNullSeries: true,
            showForZeroSeries: true,
            position: 'bottom',
            horizontalAlign: 'center',
            floating: false,
            fontSize: '14px',
            fontFamily: 'Helvetica, Arial',
            fontWeight: 400,
            formatter: undefined,
            inverseOrder: false,
            width: undefined,
            height: undefined,
            tooltipHoverFormatter: undefined,
            customLegendItems: [],
            offsetX: 0,
            offsetY: 0,
            labels: {
                colors: undefined,
                useSeriesColors: false
            },
            markers: {
                width: 12,
                height: 12,
                strokeWidth: 0,
                strokeColor: '#fff',
                fillColors: undefined,
                radius: 12,
                customHTML: undefined,
                onClick: undefined,
                offsetX: 0,
                offsetY: 0
            },
            itemMargin: {
                horizontal: 5,
                vertical: 0
            },
            onItemClick: {
                toggleDataSeries: true
            },
            onItemHover: {
                highlightDataSeries: true
            },
        }
    }
    )

    // gettinh hover data
    const getHoverData = async () => {
        const data = {
            "dayType": time
        }

        // revenue hover
        const res = await HttpClient.requestData(isInvestor || isBookKeeper ? `investor/dashboard-two-hover-revenue/${userCode}` : "dashboard-two-hover-revenue", "POST", data);
        console.log("res", res)
        if (res && res?.status) {
            setHoverData(prev => ({ ...prev, revenue: res?.data }));
        } else {

        }

        // expense hover
        const res1 = await HttpClient.requestData(isInvestor || isBookKeeper ? `investor/dashboard-two-hover-expense/${userCode}` : "dashboard-two-hover-expense", "POST", data);
        if (res1 && res1?.status) {
            setHoverData(prev => ({ ...prev, expense: res1?.data }))
        } else {

        }

        // revenue budget
        const res2 = await HttpClient.requestData(isInvestor || isBookKeeper ? `investor/dashboard-two-hover-revenue-budget/${userCode}` : "dashboard-two-hover-revenue-budget", "POST", data);
        if (res2 && res2?.status) {
            setHoverData(prev => ({ ...prev, revenueBudget: res2?.data }))
        } else {

        }

        // expense budget
        const res3 = await HttpClient.requestData(isInvestor || isBookKeeper ? `investor/dashboard-two-hover-expense-budget/${userCode}` : "dashboard-two-hover-expense-budget", "POST", data);
        if (res3 && res3?.status) {
            setHoverData(prev => ({ ...prev, expenseBudget: res3?.data }))
        } else {

        }
    }

    useEffect(() => {
        if (data.length > 0) {
            if (data.length === 3) {
                setSeries([{
                    name: text,
                    data: [data[0], data[1], data[2]]
                }])
                setOptions({
                    chart: {
                        type: 'bar',
                        height: "100%",
                        events: {
                            dataPointSelection: (event, chartContext, { dataPointIndex }) => {
                                // Handle bar click event here
                                if (dataPointIndex == 0) {
                                    navigate("/view_reports", {
                                        state: {
                                            // indexData: dataPointIndex,
                                            // type: "revenue",
                                            // time: time
                                            reportType: "out",
                                            reportName: "Transaction",
                                            time: time
                                        }
                                    });
                                }
                                else if (dataPointIndex == 1 && (time === "day" || time === "week")) {
                                    navigate("/view_reports", {
                                        state: {
                                            // indexData: dataPointIndex,
                                            // type: "expense",
                                            // time: time
                                            reportType: "out",
                                            reportName: "ExpenseDetail",
                                            time: time
                                        }
                                    });
                                }
                                else if (dataPointIndex == 1 && (time === "month" || time === "year")) {
                                    navigate("/view_reports", {
                                        state: {
                                            // indexData: dataPointIndex,
                                            // type: "revenue-budget",
                                            // time: time
                                            reportType: "self",
                                            reportName: "Revenue",
                                            time: time
                                        }
                                    }
                                    );
                                }
                                else if (dataPointIndex == 2 && (time === "day" || time === "week")) {
                                    navigate("/view_reports", {
                                        state: {
                                            reportType: "self",
                                            reportName: "Profitability",
                                            time: time
                                        }
                                    });
                                }
                                else if (dataPointIndex == 2 && (time === "month" || time === "year")) {
                                    navigate("/view_reports", {
                                        state: {
                                            reportType: "out",
                                            reportName: "ExpenseDetail",
                                            time: time
                                        }
                                    });
                                }
                                else {
                                    navigate("/view_reports", {
                                        state: {
                                            // indexData: dataPointIndex,
                                            // type: "expense-budget",
                                            // time: time
                                            reportType: "self",
                                            reportName: "Expense",
                                            time: time
                                        }
                                    });
                                }
                            },
                        },
                    },

                    plotOptions: {
                        bar: {
                            horizontal: false,
                            columnWidth: '30%',
                            endingShape: 'rounded',
                            distributed: true,
                            dataLabels: {
                                position: 'top', // top, center, bottom
                            },
                        },
                    },
                    title: {
                        text: text,
                        align: 'left',
                        margin: 10,
                        offsetX: 0,
                        offsetY: 0,
                        floating: false,
                        style: {
                            fontSize: '14px',
                            fontWeight: 'bold',
                            fontFamily: undefined,
                            color: '#263238'
                        },
                    },
                    dataLabels: {
                        enabled: true,
                        formatter: function (val) {
                            return "$ " + Number(val).toLocaleString();
                        },
                        offsetY: -20,
                        style: {
                            fontSize: "12px",
                            colors: ["#304758"]
                        }
                    },
                    stroke: {
                        show: true,
                        width: -2,
                        colors: ['transparent']
                    },
                    xaxis: {
                        categories: userType && userType === 'Business'
                            ?
                            [language.revenue, language.expense, language.profit]
                            :
                            [language.income, language.expense, language.expense_budget],
                        position: "bottom",
                        labels: {
                            offsetX: 0
                        },
                    },

                    fill: {
                        opacity: 1,
                        // gradient: {
                        //     shade: "light",
                        //     type: "horizontal",
                        //     shadeIntensity: 0.25,
                        //     gradientToColors: undefined,
                        //     inverseColors: true,
                        //     opacityFrom: 1,
                        //     opacityTo: 1,
                        //     stops: [50, 0, 100, 100]
                        // }
                    },

                    // this is for hoover in bu
                    tooltip: {
                        enabled: true,
                        offsetY: -35,
                        custom: ({ series, seriesIndex, dataPointIndex, w }) => {
                            setBarIndex(dataPointIndex);
                            return null
                        }
                    },
                    colors: ["#3b3425", "#735a24", "#918b7e"],
                    legend: {
                        show: true,
                        showForSingleSeries: false,
                        showForNullSeries: true,
                        showForZeroSeries: true,
                        position: 'bottom',
                        horizontalAlign: 'center',
                        floating: false,
                        fontSize: '14px',
                        fontFamily: 'Helvetica, Arial',
                        fontWeight: 400,
                        formatter: undefined,
                        inverseOrder: false,
                        width: undefined,
                        height: undefined,
                        tooltipHoverFormatter: undefined,
                        customLegendItems: [],
                        offsetX: 0,
                        offsetY: 0,
                        labels: {
                            colors: undefined,
                            useSeriesColors: false
                        },
                        markers: {
                            width: 12,
                            height: 12,
                            strokeWidth: 0,
                            strokeColor: '#fff',
                            fillColors: undefined,
                            radius: 12,
                            customHTML: undefined,
                            onClick: undefined,
                            offsetX: 0,
                            offsetY: 0
                        },
                        itemMargin: {
                            horizontal: 5,
                            vertical: 0
                        },
                        onItemClick: {
                            toggleDataSeries: true
                        },
                        onItemHover: {
                            highlightDataSeries: true
                        },
                    }
                })
            } else if (data.length === 4) {
                setSeries([{
                    name: text,
                    data: [data[0], data[1], data[2], data[3]]
                }])
                setOptions({
                    chart: {
                        type: 'bar',
                        height: "100%",
                    },
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            columnWidth: '30%',
                            endingShape: 'rounded',
                            distributed: true,
                            dataLabels: {
                                position: 'top', // top, center, bottom
                            },
                        },

                    },
                    title: {
                        text: text,
                        align: 'left',
                        margin: 10,
                        offsetX: 0,
                        offsetY: 0,
                        floating: false,
                        style: {
                            fontSize: '14px',
                            fontWeight: 'bold',
                            fontFamily: undefined,
                            color: '#263238'
                        },
                    },
                    dataLabels: {
                        enabled: true,
                        formatter: function (val) {
                            return "$ " + Number(val).toLocaleString();
                        },
                        offsetY: -20,
                        style: {
                            fontSize: "12px",
                            colors: ["#304758"]
                        }
                    },
                    stroke: {
                        show: true,
                        width: -2,
                        colors: ['transparent']
                    },
                    xaxis: {
                        categories: [language.revenue, language.revenue_budget, language.expense, language.expense_budget],
                        position: "bottom",
                        labels: {
                            offsetX: 0
                        },
                    },

                    fill: {
                        opacity: 1,
                        // gradient: {
                        //     shade: "light",
                        //     type: "horizontal",
                        //     shadeIntensity: 0.25,
                        //     gradientToColors: undefined,
                        //     inverseColors: true,
                        //     opacityFrom: 1,
                        //     opacityTo: 1,
                        //     stops: [50, 0, 100, 100]
                        // }
                    },
                    // this is for hoover in bu
                    tooltip: {
                        enabled: true,
                        offsetY: -35,
                        custom: ({ series, seriesIndex, dataPointIndex, w }) => {
                            setBarIndex(dataPointIndex);
                            return null
                        }
                    },

                    colors: ["#3b3425", "#735a24", "#918b7e"],
                    legend: {
                        show: true,
                        showForSingleSeries: false,
                        showForNullSeries: true,
                        showForZeroSeries: true,
                        position: 'bottom',
                        horizontalAlign: 'center',
                        floating: false,
                        fontSize: '14px',
                        fontFamily: 'Helvetica, Arial',
                        fontWeight: 400,
                        formatter: undefined,
                        inverseOrder: false,
                        width: undefined,
                        height: undefined,
                        tooltipHoverFormatter: undefined,
                        customLegendItems: [],
                        offsetX: 0,
                        offsetY: 0,
                        labels: {
                            colors: undefined,
                            useSeriesColors: false
                        },
                        markers: {
                            width: 12,
                            height: 12,
                            strokeWidth: 0,
                            strokeColor: '#fff',
                            fillColors: undefined,
                            radius: 12,
                            customHTML: undefined,
                            onClick: undefined,
                            offsetX: 0,
                            offsetY: 0
                        },
                        itemMargin: {
                            horizontal: 5,
                            vertical: 0
                        },
                        onItemClick: {
                            toggleDataSeries: true
                        },
                        onItemHover: {
                            highlightDataSeries: true
                        },
                    }
                })
            } else if (data.length === 2) {
                setSeries([{
                    name: text,
                    data: [data[0], data[1]]
                }])

                setOptions({
                    chart: {
                        type: 'bar',
                        height: "100%",
                        events: {
                            dataPointSelection: (event, chartContext, { dataPointIndex }) => {
                                // Handle bar click event here
                                if (dataPointIndex == 0) {
                                    navigate("/view_reports", {
                                        state: {
                                            // indexData: dataPointIndex,
                                            // type: "expense",
                                            // time: time
                                            reportType: "out",
                                            reportName: "ExpenseDetail",
                                            time: time
                                        }
                                    });
                                }
                                else if (dataPointIndex == 1) {
                                    navigate("/view_reports", {
                                        state: {
                                            // indexData: dataPointIndex,
                                            // type: "expense",
                                            // time: time
                                            reportType: "self",
                                            reportName: "Expense",
                                            time: time
                                        }
                                    });
                                }
                            },
                        },
                    },

                    plotOptions: {
                        bar: {
                            horizontal: false,
                            columnWidth: '30%',
                            endingShape: 'rounded',
                            distributed: true,
                            dataLabels: {
                                position: 'top', // top, center, bottom
                            },
                        },

                    },
                    title: {
                        text: text,
                        align: 'left',
                        margin: 10,
                        offsetX: 0,
                        offsetY: 0,
                        floating: false,
                        style: {
                            fontSize: '14px',
                            fontWeight: 'bold',
                            fontFamily: undefined,
                            color: '#263238'
                        },
                    },
                    dataLabels: {
                        enabled: true,
                        formatter: function (val) {
                            return "$ " + Number(val).toLocaleString();
                        },
                        offsetY: -20,
                        style: {
                            fontSize: "12px",
                            colors: ["#304758"]
                        }
                    },
                    stroke: {
                        show: true,
                        width: -2,
                        colors: ['transparent']
                    },
                    xaxis: {
                        categories: [language.expense, language.expense_budget],
                        position: "bottom",
                        labels: {
                            offsetX: 0
                        },
                    },

                    fill: {
                        opacity: 1,
                        // gradient: {
                        //     shade: "light",
                        //     type: "horizontal",
                        //     shadeIntensity: 0.25,
                        //     gradientToColors: undefined,
                        //     inverseColors: true,
                        //     opacityFrom: 1,
                        //     opacityTo: 1,
                        //     stops: [50, 0, 100, 100]
                        // }
                    },
                    // this is for hoover in bu
                    tooltip: {
                        enabled: true,
                        offsetY: -35,
                        custom: ({ series, seriesIndex, dataPointIndex, w }) => {
                            setBarIndex(dataPointIndex);
                            return null
                        }
                    },

                    colors: ["#3b3425", "#735a24", "#918b7e"],
                    legend: {
                        show: true,
                        showForSingleSeries: false,
                        showForNullSeries: true,
                        showForZeroSeries: true,
                        position: 'bottom',
                        horizontalAlign: 'center',
                        floating: false,
                        fontSize: '14px',
                        fontFamily: 'Helvetica, Arial',
                        fontWeight: 400,
                        formatter: undefined,
                        inverseOrder: false,
                        width: undefined,
                        height: undefined,
                        tooltipHoverFormatter: undefined,
                        customLegendItems: [],
                        offsetX: 0,
                        offsetY: 0,
                        labels: {
                            colors: undefined,
                            useSeriesColors: false
                        },
                        markers: {
                            width: 12,
                            height: 12,
                            strokeWidth: 0,
                            strokeColor: '#fff',
                            fillColors: undefined,
                            radius: 12,
                            customHTML: undefined,
                            onClick: undefined,
                            offsetX: 0,
                            offsetY: 0
                        },
                        itemMargin: {
                            horizontal: 5,
                            vertical: 0
                        },
                        onItemClick: {
                            toggleDataSeries: true
                        },
                        onItemHover: {
                            highlightDataSeries: true
                        },
                    }
                })
            }
        }
    }, [data, text])

    useEffect(() => {
        getHoverData();
    }, [time])


    useEffect(() => {
        if (time === "day") {
            setText(language.two_week)
        } else if (time === "week") {
            setText(language.two_week_total)
        } else if (time === "month") {
            setText(isBusiness ? language.curr_month_act_budget : language.current_month)
        } else if (time === "year") {
            setText(isBusiness ? language.curr_year_act_budget : language.current_year)
        }
    }, [time, currentLanguage, isBusiness])

    //condition for expense hover modal
    const isExpensHoverMemo = useMemo(() => {
        if (barIndex === 1) {
            if (time === "day" || time === "week") return true;
            else return false;
        }
        else if (barIndex === 2) {
            if (time === "month" || time === "year") return true;
            else return false
        }
        else return false;
    }, [barIndex, time, isBusiness])

    //condition for Budget revenue hover modal
    const isBudgetRevenueMemo = useMemo(() => {
        if (barIndex === 1) {
            if (time === "month" || time === "year") return true;
            else return false;
        }
        else return false;
    }, [barIndex, time])

    // console.log("isBudgetRevenueMemo", isBudgetRevenueMemo)
    return (
        <div>
            <div onMouseLeave={() => setBarIndex("")}>
                <ReactApexChart
                    options={options}
                    series={series}
                    type="bar"
                    height={350}
                />
            </div>

            {/* hover modal */}
            <div>
                {/* revenue */}
                {console.log("hoverData", hoverData)}
                {
                    barIndex === 0 && (
                        <div className="custom-toolip bar-index-0">
                            <div className="custom-toolip-header">
                                <div className="header-text">
                                    <span>{isBusiness || isInvestor || isBookKeeper ? language.revenue_actual_budget : language.income_act_budget}</span>
                                </div>
                            </div>

                            <div className="custom-toolip-body">
                                <div className="body-text">
                                    <p className='hover-data'>
                                        <span>{language.over_under_budget2}:</span> <span>{hoverData?.revenue?.overBudget?.toFixed(2)}</span>
                                    </p>
                                    <p className='hover-data'>
                                        <span>{language.transaction_total}:</span> <span>{hoverData?.revenue?.transactionTotal}</span>
                                    </p>
                                    <p className='hover-data'>
                                        <span>{language.revenue_growth}:</span> <span>{hoverData?.revenue?.revenueGrowth?.toFixed(2)}</span>
                                    </p>
                                    <p className='hover-data'>
                                        <span>{language.customer_growth}:</span> <span>{hoverData?.revenue?.customerGrowth?.toFixed(2)}</span>
                                    </p>
                                    <p className='hover-data'>
                                        <span>{language.transaction_growth}:</span> <span>{hoverData?.revenue?.transactionGrowth?.toFixed(2)}</span>
                                    </p>
                                </div>
                                <div className='hover-more-details'>
                                    <p>{language.click_graph_more_details}</p>
                                </div>
                            </div >
                        </div >
                    )
                }


                {/* budget revenue */}
                {
                    isBudgetRevenueMemo && (
                        // true && (
                        <div className="custom-toolip bar-index-12">
                            <div className="custom-toolip-header">
                                <div className="header-text">
                                    <span>{isBusiness ? language.revenue_actual_budget : language.income_act_budget}</span>
                                    {/* <span>ddd</span> */}
                                </div>
                            </div>
                            <div className="custom-toolip-body">
                                <div className="body-text">
                                    <p className='hover-data'>
                                        <span>{language.over_under_budget2}:</span> <span>{hoverData?.revenueBudget?.overUnderBudget?.toFixed(2)}</span>
                                    </p>
                                    <p className='hover-data'>
                                        <span>{language.over_budget}:</span> <span><CustomYesNoComp data={hoverData?.revenueBudget?.overBudget} /></span>
                                    </p>
                                    <p className='hover-data'>
                                        <span>{language?.under_budget}:</span> <span><CustomYesNoComp data={hoverData?.revenueBudget?.underBudget} /></span>
                                    </p>
                                </div >
                                <div className='hover-more-details'>
                                    <p>{language.click_graph_more_details}</p>
                                </div>
                            </div >
                        </div >
                    )
                }


                {/* expense */}
                {
                    isExpensHoverMemo && (
                        // true && (
                        <div className="custom-toolip bar-index-22">
                            <div className="custom-toolip-header">
                                <div className="header-text">
                                    <span>{language.expense_actual_vs_budget}</span>
                                </div>
                            </div>
                            <div className="custom-toolip-body">
                                <div className="body-text">
                                    <p className='hover-data'>
                                        <span>{language.over_under_budget2}:</span> <span>{Number(hoverData?.expense?.overUnderBudget)?.toFixed(2)}</span>
                                    </p>
                                    <p className='hover-data'>
                                        <span>{language.transaction_total}:</span> <span>{hoverData?.expense?.transactionTotal}</span>
                                    </p>
                                    <p className='hover-data'>
                                        <span>{language?.expense_growth}:</span> <span>{hoverData?.expense?.expenseGrowth?.toFixed(2)}</span>
                                    </p>
                                    <p className='hover-data'>
                                        <span>{language.vendor_growth}:</span> <span>{hoverData?.expense?.vendorGrowth?.toFixed(2)}</span>
                                    </p>
                                    <p className='hover-data'>
                                        <span>{language.transaction_growth}:</span> <span>{hoverData?.expense?.tranTotal?.toFixed(2)}</span>
                                    </p>
                                </div >
                                <div className='hover-more-details'>
                                    <p>{language.click_graph_more_details}</p>
                                </div>
                            </div >
                        </div >
                    )
                }


                {/* budget expemse */}
                {
                    barIndex === 3 && (
                        <div className="custom-toolip bar-index-2">
                            <div className="custom-toolip-header">
                                <div className="header-text">
                                    <span>{language.expense_actual_vs_budget}</span>
                                    {/* <span>ddd</span> */}
                                </div>
                            </div>
                            <div className="custom-toolip-body">
                                <div className="body-text">
                                    <p className='hover-data'>
                                        <span>{language.over_under_budget2}:</span> <span>{hoverData?.expenseBudget?.overUnderBudget?.toFixed(2)}</span>
                                    </p>
                                    <p className='hover-data'>
                                        <span>{language.over_budget}:</span> <span><CustomYesNoComp data={hoverData?.expenseBudget?.overBudget} /></span>
                                    </p>
                                    <p className='hover-data'>
                                        <span>{language?.under_budget}:</span> <span><CustomYesNoComp data={hoverData?.expenseBudget?.underBudget} /></span>
                                    </p>
                                </div >
                                <div className='hover-more-details'>
                                    <p>{language.click_graph_more_details}</p>
                                </div>
                            </div >
                        </div >
                    )
                }
            </div>
        </div>
    )
}


//it  prints green or red colord
const CustomGreenRedComp = ({ color, data }) => {
    return (
        <span>
            {color === "green"
                ?
                <span style={{ color: "green" }}>{data}</span>
                :
                <span style={{ color: "red" }}>{data}</span>
            }
        </span >
    )
}

// it prints only yes or no
const CustomYesNoComp = ({ data }) => {
    const { language, currentLanguage } = useLanguage();
    console.log("dataXdf", data)
    return (
        <div>
            {data === "yes"
                ?
                <span>{language.yes}</span>
                :
                <span>No</span>
            }
        </div>
    )
}

export default DashboardChartTwo;






