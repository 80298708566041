import React, { useEffect, useState } from 'react'
import HttpClient from '../../../../utils/HttpClient';
import { useParams } from 'react-router-dom';
import { useUserData } from '../../../../Providers/UserWrapper';
import moment from 'moment';
import { useLanguage } from '../../../../Providers/LanguageContext';
import BackArrow from '../../../../Component/BackArrow/BackArrow';

const ViewNormalInvoice = () => {
    const params = useParams();
    const [singleData, setSingleData] = useState([]);
    const { language, currentLanguage } = useLanguage();

    const { setIsLoading } = useUserData();


    const getSingleData = async () => {
        setIsLoading(true)
        const res = await HttpClient.requestData("view-invoice-web-single/" + params.id, "GET", {});
        // console.log("resnorminv", res);
        if (res && res?.status) {
            setSingleData(res.data);
            setIsLoading(false);
        } else {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getSingleData();
    }, [])

    return (
        <div className='container'>

            <BackArrow />

            <form className="sendInfoForm mb-5">
                <div className="sendInfoFirstFormDiv">

                    {/* Send Invoice To / left part*/}
                    <div className="sendInfoSubDiv">
                        <p className="sendInfoHead">{language.send_invoice_to}</p>

                        {/* name */}
                        <div className="sendInnerSubDiv">
                            <p className="sendFisiLabel">{language.customer_name}:</p>
                            <p>{singleData?.customerName}</p>
                        </div>

                        {/* tax id */}
                        <div className="sendInnerSubDiv">
                            <p className="sendFisiLabel">Tax ID / RFC ID:</p>
                            <p>{singleData?.customerRFCID}</p>
                        </div>

                        {/* city */}
                        <div className="sendInnerSubDiv">
                            <p className="sendFisiLabel">{language.city}:</p>
                            <p>{singleData?.city}</p>
                        </div>

                        {/* state */}
                        <div className="sendInnerSubDiv">
                            <p className="sendFisiLabel">{language.state}:</p>
                            <p>{singleData?.state}</p>
                        </div>

                        {/* zip / postal */}
                        <div className="sendInnerSubDiv">
                            <p className="sendFisiLabel">Zip / Postal:</p>
                            <p>{singleData?.zip}</p>
                        </div>

                        {/* email recipient */}
                        <div className="sendInnerSubDiv">
                            <p className="sendFisiLabel">{language.email_recipients}:</p>
                            <p>{singleData?.emailRecipents}</p>
                        </div>

                        {/* invoice details */}
                        <div className="sendInnerSubDiv">
                            <p className="sendFisiLabel">{language.invoice_details}:</p>
                            <p>{singleData?.invoiceDetailsType}</p>
                        </div>
                    </div>

                    {/* Invoice Information / right part */}
                    <div className="sendInfoSubDiv">
                        <p className="sendInfoHead">{language.invoice_information}</p>

                        {/* Invoice Date */}
                        <div className="sendInnerSubDiv">
                            <p className="sendFisiLabel">{language.invoice_date}:</p>
                            <p>{moment(singleData?.invoiceDate).format("LL")}</p>
                        </div>

                        {/* Invoice # */}
                        <div className="sendInnerSubDiv">
                            <p className="sendFisiLabel">{language.invoice} #:</p>
                            <p>{singleData?.invoice}</p>
                        </div>

                        {/* Order # */}
                        <div className="sendInnerSubDiv">
                            <p className="sendFisiLabel">{language.order} #:</p>
                            <p>{singleData?.Oredr}</p>
                        </div>

                        {/* Reference # */}
                        <div className="sendInnerSubDiv">
                            <p className="sendFisiLabel">{language.referance} #:</p>
                            <p>{singleData?.reference}</p>
                        </div>

                        {/* Purchase Order # */}
                        <div className="sendInnerSubDiv">
                            <p className="sendFisiLabel">{language.purchase_order} #:</p>
                            <p>{singleData?.reference}</p>
                        </div>
                    </div>
                </div>

                <div className="sendInfoThirdFirstFormDiv">

                    {/* description / Add more part */}
                    <div className="thrFirstDiv">
                        <table className='view-normal-table'>
                            <thead>
                                <tr>
                                    <th className="invoTableTH">
                                        <span className="descSpan">{language.description}</span>
                                    </th>
                                    <th className="invoTableTH">
                                        <span>{language.quantity}</span>
                                    </th>
                                    <th className="invoTableTH">
                                        <span>{language.unit}</span>
                                    </th>
                                    <th className="invoTableTH">
                                        <span>{language.item_price} ($)</span>
                                    </th>
                                    <th className="invoTableTH">
                                        <span>Sub-Total ($)</span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    singleData?.invoiceDetails?.map((item, i) => {
                                        return (
                                            <tr >

                                                {/* description */}
                                                <td>
                                                    <p>{item?.description}</p>
                                                </td>

                                                {/* Quantity */}
                                                <td>
                                                    <p>{item?.quantity}</p>
                                                </td>

                                                {/* Unit */}
                                                <td>
                                                    <p>{item?.unit}</p>
                                                </td>

                                                {/* Item Price */}
                                                <td>
                                                    <p>{item?.itemPrice}</p>
                                                </td>

                                                {/* sub-total */}
                                                <td>
                                                    <p>{item?.subTotal}</p>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>

                    {/* bottom right part */}
                    <div className="thrSecondDiv">
                        <div className="thrSecSubDiv">
                            <p className="thrSecSubLabel">{language.sale_tax} (%)</p>
                            <p>{singleData?.salesTaxPercentage}</p>
                        </div>
                        <div className="thrSecSubDiv">
                            <p className="thrSecSubLabel">{language.sale_tax} ($)</p>
                            <p>{singleData?.salesTaxAmount}</p>
                        </div>
                        <div className="thrSecSubDiv">
                            <p className="thrSecSubLabel">{language.other} ($)</p>
                            <p>{singleData?.other}</p>
                        </div>

                        {/* <hr className='mt-0' /> */}
                        <div className="thrSecSubDiv">
                            <p className="thrSecSubLabel">Total ($)</p>
                            <p>{singleData?.total}</p>
                        </div>
                    </div>

                </div>
            </form>

        </div>
    )
}

export default ViewNormalInvoice
