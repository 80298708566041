import React from 'react'
import Righticon from "../../images/right-icon.png";
import { useLanguage } from '../../Providers/LanguageContext';

export default function Nosotros4() {
  const {language}=useLanguage()
  return (
    <>

      <section id='financial'>
        <div className='container'>
          <div className='financial-heading'>
            <h3>{language.about_multi_language}</h3>
          </div>
          <div className='row pt-5'>
            <div className='col-xl-4 col-lg-4 col-md-12 col-12 mb-5'>
              <div className='main-box'>
                <ul>
                  <li>{language.about_app_business}</li>
                </ul>
              </div>
            </div>
            <div className='col-xl-4 col-lg-4 col-md-12 col-12 mb-4'>
              <div className='main-box'>
                <ul>
                  <li>{language.capital_intelligence}</li>
                </ul>
              </div>
            </div>
            <div className='col-xl-4 col-lg-4 col-md-12 col-12 mb-5'>
              <div className='main-box'>
                <ul>
                  <li>{language.global_investment}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  )
}
