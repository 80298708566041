import React, { useMemo, useState } from 'react'
import Slider from 'react-slick';
import LinkAccount from './LinkAccount';
import ManageAccount from './ManageAccount';
import { useLocation } from 'react-router-dom';
import { useLanguage } from '../../../Providers/LanguageContext';

const LinkManageAccount = () => {
    const { language } = useLanguage();

    const location = useLocation()

    const getInitialSlideMemo = useMemo(() => {
        if (location?.state?.slideNo === 1) {
            return 1;
        }
        else return 0;
    }, [location])


    // for sliders
    const settings = {
        dots: false,
        infinite: false,
        swipe: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        initialSlide: getInitialSlideMemo
    };

    const [linkedAccData, setLinkedAccData] = useState([]);
    const [callGetAcc, setCallGetAcc] = useState(false);

    return (
        <div className='container'>
            <div>
                <h3 className='BookLinkHading'>{language.book_keeper_manager} - {language.link_account}</h3>
            </div>

            <div className="chart-slide">
                <div className="container">
                    <Slider {...settings}>
                        <div>
                            <LinkAccount
                                setLinkedAccData={setLinkedAccData}
                                callGetAcc={callGetAcc}
                            />
                        </div>
                        <div>
                            <ManageAccount
                                linkedAccData={linkedAccData}
                                setCallGetAcc={setCallGetAcc}
                            />
                        </div>
                    </Slider>
                </div>
            </div>
        </div >

    )
}

export default LinkManageAccount
