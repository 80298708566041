import React from 'react'
import Aboutimg from "../../images/about-image.png";
import MobileImage2 from '../../images/Change_image/imagen_nosotros.png'
import { useLanguage } from '../../Providers/LanguageContext';

export default function Nosotros1() {

  const { language, currentLanguage } = useLanguage()
  return (

    <>

      <section id='about-us'>
        <div className='top-heading'>
          <div className='container'>
            <div className='row'>
              <div className="col-xl-4 col-lg-4 col-md-4 col-12"></div>
              <div className="col-xl-8 col-lg-8 col-md-12 col-12">
                <div className='heading'>
                  {/* <h2>   </h2> */}
                  <h2>{language.about_us}</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='about_content'>
          <div className='container'>
            <div className='row'>
              <div className='col-xl-4 col-lg-4 col-md-12 col-12'>
                <div className='about-image'>
                  <img className="img-fluid" src={MobileImage2} />
                </div>
              </div>
              <div className='col-xl-8 col-lg-8 col-md-12 col-12'>
                <div className='right-content'>
                  <div className='first-content'>
                    <h4>{language.why}</h4>
                    <p>{language.about_patent}</p>
                  </div>

                  <div className='first-content'>
                    <h4>{language.how}</h4>
                    <p>{language.about_all_this}</p>
                  </div>

                  <div className='first-content'>
                    <h4>{language.what}</h4>
                    <p>{language.about_give_user}</p>
                  </div>
                </div>
                <div className='power-by'>
                  <p>Powered by CIP</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  )
}
