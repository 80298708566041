import React from 'react'
import Managementtop from "../../Component/Managementtop"
import Dropbox from '../../Component/Dropbox'
import Clasification from '../../Component/Clasification'

function Index() {
  return (

    <>

    <Managementtop />
    <Dropbox />
    <Clasification />

    </>

  )
}

export default Index