import React, { useEffect, useState } from 'react'
import { useLanguage } from '../../Providers/LanguageContext';
import HttpClient from '../../utils/HttpClient';

const ViewBudgetForcast = ({ setBuildRepData }) => {
    const { language, currentLanguage } = useLanguage();

    const employeeCost = {
        empCost: "",
        jan: "",
        feb: "",
        mar: "",
        apr: "",
        may: "",
        jun: "",
        jul: "",
        aug: "",
        sep: "",
        oct: "",
        nov: "",
        dec: "",
        year: ""
    }

    const officeCost = {
        offCost: "",
        jan: "",
        feb: "",
        mar: "",
        apr: "",
        may: "",
        jun: "",
        jul: "",
        aug: "",
        sep: "",
        oct: "",
        nov: "",
        dec: "",
        year: ""
    }

    const marketingCost = {
        markCost: "",
        jan: "",
        feb: "",
        mar: "",
        apr: "",
        may: "",
        jun: "",
        jul: "",
        aug: "",
        sep: "",
        oct: "",
        nov: "",
        dec: "",
        year: ""
    }

    const trainingCost = {
        trainCost: "",
        jan: "",
        feb: "",
        mar: "",
        apr: "",
        may: "",
        jun: "",
        jul: "",
        aug: "",
        sep: "",
        oct: "",
        nov: "",
        dec: "",
        year: ""
    }

    const [ownerData, setOwnerData] = useState([employeeCost]);
    const [offCostData, setOffCostData] = useState([officeCost]);
    const [markCostData, setMarketingCostData] = useState([marketingCost])
    const [trainingCostData, setTrainingCostData] = useState([trainingCost])

    const [subTotals, setSubTotals] = useState({});
    const [officeSubTotal, setOfficeSubTotal] = useState({})
    const [marketingSubTotal, setMarketingSubTotal] = useState({})
    const [trainingSubTotal, setTrainingSubTotal] = useState({})

    const [totalSubTotals, setTotalSubTotals] = useState({});
    const [cumulativeSubTotals, setCumulativeSubTotals] = useState({});

    console.log("df4654", subTotals)
    // >>>>Showing Months Value To Each Section Row-wisw<<<<
    const months = [
        "Jan-24", "Feb-24", "Mar-24", "Apr-24", "May-24", "Jun-24",
        "Jul-24", "Aug-24", "Sep-24", "Oct-24", "Nov-24", "Dec-24"
    ];
    // >>>>Get Budget Forcast Report<<<<
    const getBudgetForcastReport = async () => {
        const res = await HttpClient.requestData('reportbuilder-view-budget-statement', 'GET');
        if (res && res?.status && res?.data !== null) {
            const employeeCostData = res.data.EmployeeCost.map((cost) => ({
                empCost: cost.title,
                jan: cost.prices.find(price => price.month === 'Jan')?.amount || "",
                feb: cost.prices.find(price => price.month === 'Feb')?.amount || "",
                mar: cost.prices.find(price => price.month === 'Mar')?.amount || "",
                apr: cost.prices.find(price => price.month === 'Apr')?.amount || "",
                may: cost.prices.find(price => price.month === 'May')?.amount || "",
                jun: cost.prices.find(price => price.month === 'Jun')?.amount || "",
                jul: cost.prices.find(price => price.month === 'Jul')?.amount || "",
                aug: cost.prices.find(price => price.month === 'Aug')?.amount || "",
                sep: cost.prices.find(price => price.month === 'Sep')?.amount || "",
                oct: cost.prices.find(price => price.month === 'Oct')?.amount || "",
                nov: cost.prices.find(price => price.month === 'Nov')?.amount || "",
                dec: cost.prices.find(price => price.month === 'Dec')?.amount || "",
                year: ""
            }));

            const officeCostData = res.data.OfficeCost.map((cost) => ({
                offCost: cost.title,
                jan: cost.prices.find(price => price.month === 'Jan')?.amount || "",
                feb: cost.prices.find(price => price.month === 'Feb')?.amount || "",
                mar: cost.prices.find(price => price.month === 'Mar')?.amount || "",
                apr: cost.prices.find(price => price.month === 'Apr')?.amount || "",
                may: cost.prices.find(price => price.month === 'May')?.amount || "",
                jun: cost.prices.find(price => price.month === 'Jun')?.amount || "",
                jul: cost.prices.find(price => price.month === 'Jul')?.amount || "",
                aug: cost.prices.find(price => price.month === 'Aug')?.amount || "",
                sep: cost.prices.find(price => price.month === 'Sep')?.amount || "",
                oct: cost.prices.find(price => price.month === 'Oct')?.amount || "",
                nov: cost.prices.find(price => price.month === 'Nov')?.amount || "",
                dec: cost.prices.find(price => price.month === 'Dec')?.amount || "",
                year: ""
            }));

            const marketingCostData = res.data.MarketingCost.map((cost) => ({
                markCost: cost.title,
                jan: cost.prices.find(price => price.month === 'Jan')?.amount || "",
                feb: cost.prices.find(price => price.month === 'Feb')?.amount || "",
                mar: cost.prices.find(price => price.month === 'Mar')?.amount || "",
                apr: cost.prices.find(price => price.month === 'Apr')?.amount || "",
                may: cost.prices.find(price => price.month === 'May')?.amount || "",
                jun: cost.prices.find(price => price.month === 'Jun')?.amount || "",
                jul: cost.prices.find(price => price.month === 'Jul')?.amount || "",
                aug: cost.prices.find(price => price.month === 'Aug')?.amount || "",
                sep: cost.prices.find(price => price.month === 'Sep')?.amount || "",
                oct: cost.prices.find(price => price.month === 'Oct')?.amount || "",
                nov: cost.prices.find(price => price.month === 'Nov')?.amount || "",
                dec: cost.prices.find(price => price.month === 'Dec')?.amount || "",
                year: ""
            }));

            const trainingCostData = res.data.TrainingCost.map((cost) => ({
                trainCost: cost.title,
                jan: cost.prices.find(price => price.month === 'Jan')?.amount || "",
                feb: cost.prices.find(price => price.month === 'Feb')?.amount || "",
                mar: cost.prices.find(price => price.month === 'Mar')?.amount || "",
                apr: cost.prices.find(price => price.month === 'Apr')?.amount || "",
                may: cost.prices.find(price => price.month === 'May')?.amount || "",
                jun: cost.prices.find(price => price.month === 'Jun')?.amount || "",
                jul: cost.prices.find(price => price.month === 'Jul')?.amount || "",
                aug: cost.prices.find(price => price.month === 'Aug')?.amount || "",
                sep: cost.prices.find(price => price.month === 'Sep')?.amount || "",
                oct: cost.prices.find(price => price.month === 'Oct')?.amount || "",
                nov: cost.prices.find(price => price.month === 'Nov')?.amount || "",
                dec: cost.prices.find(price => price.month === 'Dec')?.amount || "",
                year: ""
            }));

            setOwnerData(employeeCostData);
            setOffCostData(officeCostData);
            setMarketingCostData(marketingCostData);
            setTrainingCostData(trainingCostData);
        }
    }
    // >>>>Setting Sub-Totals For Employee Cost Data<<<<
    useEffect(() => {
        const calculateSubTotals = () => {
            const subTotals = ownerData.reduce((acc, item) => {
                Object.keys(employeeCost).forEach(month => {
                    if (month !== 'empCost') {
                        acc[month] = (acc[month] || 0) + (parseFloat(item[month]) || 0);
                    }
                });
                return acc;
            }, {});
            setSubTotals(subTotals);
        };
        calculateSubTotals();
    }, [ownerData]);

    // >>>>Setting Sub-Totals For Office Cost Data<<<<
    useEffect(() => {
        const calculateSubTotals = () => {
            const subTotals = offCostData.reduce((acc, item) => {
                Object.keys(officeCost).forEach(month => {
                    if (month !== 'offCost') {
                        acc[month] = (acc[month] || 0) + (parseFloat(item[month]) || 0);
                    }
                });
                return acc;
            }, {});
            setOfficeSubTotal(subTotals);
        };
        calculateSubTotals();
    }, [offCostData]);

    // >>>>Setting Sub-Totals For Marketing Cost Data<<<<
    useEffect(() => {
        const calculateSubTotals = () => {
            const subTotals = markCostData.reduce((acc, item) => {
                Object.keys(marketingCost).forEach(month => {
                    if (month !== 'markCost') {
                        acc[month] = (acc[month] || 0) + (parseFloat(item[month]) || 0);
                    }
                });
                return acc;
            }, {});
            setMarketingSubTotal(subTotals);
        };
        calculateSubTotals();
    }, [markCostData]);

    // >>>>Setting Sub-Totals For Training Cost Data<<<<
    useEffect(() => {
        const calculateSubTotals = () => {
            const subTotals = trainingCostData.reduce((acc, item) => {
                Object.keys(trainingCost).forEach(month => {
                    if (month !== 'trainCost') {
                        acc[month] = (acc[month] || 0) + (parseFloat(item[month]) || 0);
                    }
                });
                return acc;
            }, {});
            setTrainingSubTotal(subTotals);
        };
        calculateSubTotals();
    }, [trainingCostData]);

    // >>>>Calculating Total of Sub-Totals<<<<
    useEffect(() => {
        const calculateTotalSubTotals = () => {
            const total = {};
            const months = Object.keys(employeeCost).filter(key => key !== 'empCost' && key !== 'offCost' && key !== 'markCost' && key !== 'trainCost');

            months.forEach(month => {
                total[month] = (subTotals[month] || 0) +
                    (officeSubTotal[month] || 0) +
                    (marketingSubTotal[month] || 0) +
                    (trainingSubTotal[month] || 0);
            });

            setTotalSubTotals(total);
        };

        calculateTotalSubTotals();
    }, [subTotals, officeSubTotal, marketingSubTotal, trainingSubTotal]);

    // >>>>Calculating Total Monthly Expenses<<<<
    useEffect(() => {
        const calculateCumulativeSubTotals = () => {
            const total = {};
            const cumulative = {};
            const months = Object.keys(employeeCost).filter(key => key !== 'empCost' && key !== 'offCost' && key !== 'markCost' && key !== 'trainCost');
            let runningTotal = 0;
            let hasValue = false;

            months.forEach(month => {
                total[month] = (subTotals[month] || 0) +
                    (officeSubTotal[month] || 0) +
                    (marketingSubTotal[month] || 0) +
                    (trainingSubTotal[month] || 0);

                if (total[month] !== 0) {
                    hasValue = true;
                    runningTotal += total[month];
                    cumulative[month] = runningTotal;
                } else {
                    hasValue = false
                    cumulative[month] = hasValue ? runningTotal : 0;
                }
            });

            cumulative['year'] = hasValue ? runningTotal : 0;
            setCumulativeSubTotals(cumulative);
        };

        calculateCumulativeSubTotals();
    }, [subTotals, officeSubTotal, marketingSubTotal, trainingSubTotal]);


    useEffect(() => {
        getBudgetForcastReport()
    }, [])
    return (
        <div className='container'>
            <div className="buildIncmStTableDiv">
            <h5 className='mb-2 font-weight-bold'>{language.planned_exp}</h5>
                <table className="buildIncmStTable">
                    <tbody>

                        {/****table Head Employee Cost***/}
                        <tr>
                            <th className="buildIncmStTh12 buildIncmStTh12First">
                                <span>{language.employee_cost}</span>
                            </th>

                            {months.map((month, index) => (
                                <th key={index} className="buildIncmStTh12 pr-2">
                                    <span>{month}</span>
                                </th>
                            ))}

                            <th className="buildIncmStTh12">
                                <span>YEAR</span>
                            </th>

                        </tr>

                        {/****Data**/}
                        {ownerData?.map((item, i) => {
                            return (
                                <tr key={i}>
                                    <td className=" buildIncmStTd">

                                        <input type="text"
                                            className="userBuildInp"
                                            name="empCost"
                                            value={item?.empCost}
                                        />
                                    </td>

                                    {['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec', 'year'].map(month => (
                                        <td key={month} className="buildIncmStTd pr-2">
                                            <input
                                                type="number"
                                                name={month}
                                                value={item[month]}
                                                placeholder="$0.00"
                                            />
                                        </td>
                                    ))}
                                </tr>
                            )
                        })
                        }

                        {/*****border**/}
                        <tr>
                            <td />
                            <td colSpan={13}>
                                <div className="buildTotDivider" />
                            </td>
                        </tr>

                        {/***Sub Total**/}
                        <tr>
                            <td className="buildIncmStTd">
                                <span className="buildTotSpan">{language.sub_total}</span>
                            </td>
                            {['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec', 'year'].map(month => (
                                <td key={month} className="buildIncmStTd pr-2">
                                    <input
                                        type="number"
                                        readOnly
                                        value={subTotals[month] || 0}
                                        placeholder="$0.00"
                                    />
                                </td>
                            ))}
                        </tr>

                        {/****table Head Office Cost***/}
                        <tr>
                            <th className="buildIncmStTh12 buildIncmStTh12First">
                                <span>{language.office_cost}</span>
                            </th>

                            {months.map((month, index) => (
                                <th key={index} className="buildIncmStTh12 pr-2">
                                    <span>{month}</span>
                                </th>
                            ))}

                            <th className="buildIncmStTh12">
                                <span>YEAR</span>
                            </th>

                        </tr>

                        {/****Data**/}
                        {offCostData?.map((item, i) => {
                            return (
                                <tr key={i}>
                                    <td className="buildIncmStTd">

                                        <input type="text"
                                            className="userBuildInp"
                                            name="offCost"
                                            value={item?.offCost}
                                            defaultValue="Wages"
                                        />
                                    </td>

                                    {['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec', 'year'].map(month => (
                                        <td key={month} className="buildIncmStTd pr-2">
                                            <input
                                                type="number"
                                                name={month}
                                                value={item[month]}
                                                placeholder="$0.00"
                                            />
                                        </td>
                                    ))}
                                </tr>
                            )
                        })
                        }

                        {/*****border**/}
                        <tr>
                            <td />
                            <td colSpan={13}>
                                <div className="buildTotDivider" />
                            </td>
                        </tr>

                        {/***Sub Total**/}
                        <tr>
                            <td className="buildIncmStTd">
                                <span className="buildTotSpan">{language.sub_total}</span>
                            </td>
                            {['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec', 'year'].map(month => (
                                <td key={month} className="buildIncmStTd pr-2">
                                    <input
                                        type="number"
                                        readOnly
                                        value={officeSubTotal[month] || 0}
                                        placeholder="$0.00"
                                    />
                                </td>
                            ))}
                        </tr>

                        {/****table Head Marketing Cost***/}
                        <tr>
                            <th className="buildIncmStTh12 buildIncmStTh12First">
                                <span>{language.marketing_cost}</span>
                            </th>

                            {months.map((month, index) => (
                                <th key={index} className="buildIncmStTh12 pr-2">
                                    <span>{month}</span>
                                </th>
                            ))}

                            <th className="buildIncmStTh12">
                                <span>YEAR</span>
                            </th>

                        </tr>

                        {/****Data**/}
                        {markCostData?.map((item, i) => {
                            return (
                                <tr key={i}>
                                    <td className="buildIncmStTd">
                                        <input
                                            type="text"
                                            className="userBuildInp"
                                            name="markCost"
                                            value={item?.markCost}
                                            defaultValue="Website hosting"
                                        />
                                    </td>

                                    {['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec', 'year'].map(month => (
                                        <td key={month} className="buildIncmStTd pr-2">
                                            <input
                                                type="number"
                                                name={month}
                                                value={item[month]}
                                                placeholder="$0.00"
                                            />
                                        </td>
                                    ))}
                                </tr>
                            )
                        })
                        }

                        {/*****border**/}
                        <tr>
                            <td />
                            <td colSpan={13}>
                                <div className="buildTotDivider" />
                            </td>
                        </tr>

                        {/***Sub Total**/}
                        <tr>
                            <td className="buildIncmStTd">
                                <span className="buildTotSpan">{language.sub_total}</span>
                            </td>
                            {['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec', 'year'].map(month => (
                                <td key={month} className="buildIncmStTd pr-2">
                                    <input
                                        type="number"
                                        readOnly
                                        value={marketingSubTotal[month] || 0}
                                        placeholder="$0.00"
                                    />
                                </td>
                            ))}
                        </tr>

                        {/****table Head Training Cost***/}
                        <tr>
                            <th className="buildIncmStTh12 buildIncmStTh12First">
                                <span>{language.training_cost}</span>
                            </th>

                            {months.map((month, index) => (
                                <th key={index} className="buildIncmStTh12 pr-2">
                                    <span>{month}</span>
                                </th>
                            ))}

                            <th className="buildIncmStTh12">
                                <span>YEAR</span>
                            </th>

                        </tr>

                        {/****Data**/}
                        {trainingCostData?.map((item, i) => {
                            return (
                                <tr key={i}>
                                    <td className="buildIncmStTd">
                                        <input
                                            type="text"
                                            className="userBuildInp"
                                            name="trainCost"
                                            value={item?.trainCost}
                                            defaultValue="Training Classes"
                                        />
                                    </td>

                                    {['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec', 'year'].map(month => (
                                        <td key={month} className="buildIncmStTd pr-2">
                                            <input
                                                type="number"
                                                name={month}
                                                value={item[month]}
                                                placeholder="$0.00"
                                            />
                                        </td>
                                    ))}
                                </tr>
                            )
                        })
                        }

                        {/*****border**/}
                        <tr>
                            <td />
                            <td colSpan={13}>
                                <div className="buildTotDivider" />
                            </td>
                        </tr>

                        {/***Sub Total**/}
                        <tr>
                            <td className="buildIncmStTd">
                                <span className="buildTotSpan">{language.sub_total}</span>
                            </td>
                            {['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec', 'year'].map(month => (
                                <td key={month} className="buildIncmStTd pr-2">
                                    <input
                                        type="number"
                                        readOnly
                                        value={trainingSubTotal[month] || 0}
                                        placeholder="$0.00"
                                    />
                                </td>
                            ))}
                        </tr>

                        {/****MONTHLY Planned Expenses***/}
                        <tr>
                            <td className="buildIncmStTd">
                                <span className="buildTotSpan">{language.monthly_planned_exp}</span>
                            </td>

                            <td className="buildIncmStTd pr-2">
                                <input type="number"
                                    readOnly=""
                                    value={totalSubTotals?.jan}
                                    placeholder='$0.00' />
                            </td>

                            {/* Feb-24 */}
                            <td className="buildIncmStTd pr-2">
                                <input type="number"
                                    readOnly=""
                                    value={totalSubTotals?.feb}
                                    placeholder='$0.00'
                                />
                            </td>

                            {/* Mar-24 */}
                            <td className="buildIncmStTd pr-2">
                                <input type="number"
                                    readOnly=""
                                    value={totalSubTotals?.mar}
                                    placeholder='$0.00'
                                />
                            </td>

                            {/* Apr-24 */}
                            <td className="buildIncmStTd pr-2">
                                <input type="number"
                                    readOnly=""
                                    value={totalSubTotals?.apr}
                                    placeholder='$0.00'
                                />
                            </td>

                            {/* May-24 */}
                            <td className="buildIncmStTd pr-2">
                                <input type="number"
                                    readOnly=""
                                    value={totalSubTotals?.may}
                                    placeholder='$0.00'
                                />
                            </td>

                            {/* Jun-24 */}
                            <td className="buildIncmStTd pr-2">
                                <input type="number"
                                    readOnly=""
                                    value={totalSubTotals?.jun}
                                    placeholder='$0.00'
                                />
                            </td>

                            {/* Jul-24 */}
                            <td className="buildIncmStTd pr-2">
                                <input type="number"
                                    readOnly=""
                                    value={totalSubTotals?.jul}
                                    placeholder='$0.00'
                                />
                            </td>

                            {/* Aug-24 */}
                            <td className="buildIncmStTd pr-2">
                                <input type="number"
                                    readOnly=""
                                    value={totalSubTotals?.aug}
                                    placeholder='$0.00'
                                />
                            </td>

                            {/* Sep-24 */}
                            <td className="buildIncmStTd pr-2">
                                <input type="number"
                                    readOnly=""
                                    value={totalSubTotals?.sep}
                                    placeholder='$0.00'
                                />
                            </td>

                            {/* Oct-24 */}
                            <td className="buildIncmStTd pr-2">
                                <input type="number"
                                    readOnly=""
                                    value={totalSubTotals?.oct}
                                    placeholder='$0.00'
                                />
                            </td>

                            {/* Nov-24 */}
                            <td className="buildIncmStTd pr-2">
                                <input type="number"
                                    readOnly=""
                                    value={totalSubTotals?.nov}
                                    placeholder='$0.00'
                                />
                            </td>

                            {/* Dec-24 */}
                            <td className="buildIncmStTd pr-2">
                                <input type="number"
                                    readOnly=""
                                    value={totalSubTotals?.dec}
                                    placeholder='$0.00'
                                />
                            </td>

                            {/* Year */}
                            <td className="buildIncmStTd">
                                <input type="number"
                                    readOnly=""
                                    value={totalSubTotals?.year}
                                    placeholder="$0.00"
                                />
                            </td>

                        </tr>

                        {/****TOTAL Planned Expenses****/}
                        <tr>

                            <td className="buildIncmStTd">
                                <span className="buildProSpan">{language.total_planned_exp}</span>
                            </td>

                            {/* Jan-24 */}
                            <td className="buildIncmStTd pr-2">
                                <input type="number"
                                    readOnly=""
                                    value={cumulativeSubTotals.jan}
                                    placeholder='$0.00'
                                />
                            </td>

                            {/* Feb-24 */}
                            <td className="buildIncmStTd pr-2">
                                <input type="number"
                                    readOnly=""
                                    value={cumulativeSubTotals.feb}
                                    placeholder='$0.00'
                                />
                            </td>

                            {/* Mar-24 */}
                            <td className="buildIncmStTd pr-2">
                                <input type="number"
                                    readOnly=""
                                    value={cumulativeSubTotals.mar}
                                    placeholder='$0.00'
                                />
                            </td>

                            {/* Apr-24 */}
                            <td className="buildIncmStTd pr-2">
                                <input type="number"
                                    readOnly=""
                                    value={cumulativeSubTotals.apr}
                                    placeholder='$0.00'
                                />
                            </td>

                            {/* May-24 */}
                            <td className="buildIncmStTd pr-2">
                                <input type="number"
                                    readOnly=""
                                    value={cumulativeSubTotals.may}
                                    placeholder='$0.00'
                                />
                            </td>

                            {/* Jun-24 */}
                            <td className="buildIncmStTd pr-2">
                                <input type="number"
                                    readOnly=""
                                    value={cumulativeSubTotals.jun}
                                    placeholder='$0.00'
                                />
                            </td>

                            {/* Jul-24 */}
                            <td className="buildIncmStTd pr-2">
                                <input type="number"
                                    readOnly=""
                                    value={cumulativeSubTotals.jul}
                                    placeholder='$0.00'
                                />
                            </td>

                            {/* Aug-24 */}
                            <td className="buildIncmStTd pr-2">
                                <input type="number"
                                    readOnly=""
                                    value={cumulativeSubTotals.aug}
                                    placeholder='$0.00'
                                />
                            </td>

                            {/* Sep-24 */}
                            <td className="buildIncmStTd pr-2">
                                <input type="number"
                                    readOnly=""
                                    value={cumulativeSubTotals.sep}
                                    placeholder='$0.00'
                                />
                            </td>

                            {/* Oct-24 */}
                            <td className="buildIncmStTd pr-2">
                                <input type="number"
                                    readOnly=""
                                    value={cumulativeSubTotals.oct}
                                    placeholder='$0.00'
                                />
                            </td>

                            {/* Nov-24 */}
                            <td className="buildIncmStTd pr-2">
                                <input type="number"
                                    readOnly=""
                                    value={cumulativeSubTotals.nov}
                                    placeholder='$0.00'
                                />
                            </td>

                            {/* Dec-24 */}
                            <td className="buildIncmStTd pr-2">
                                <input type="number"
                                    readOnly=""
                                    value={cumulativeSubTotals.dec}
                                    placeholder='$0.00'
                                />
                            </td>

                            {/* Year */}
                            <td className="buildIncmStTd">
                                <input type="number"
                                    readOnly=""
                                    value={cumulativeSubTotals.year}
                                    placeholder='$0.00'
                                />
                            </td>

                        </tr>

                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default ViewBudgetForcast