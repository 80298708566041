import React from 'react'
import Banner3 from '../../images/banner-bg-3.jpg';

export default function Banner2() {
    return (
        <>

            {/* <section id='banner_area' style={{ backgroundImage: `url('${Banner3}')` }}>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-6 col-md-12 col-12'>
                            <div className='left_cnt'>
                                <div className='head'>
                                    <h2>Facilita la</h2>
                                    <h2>Gestión</h2>
                                    <h2>de tu Dinero</h2>
                                    <h2>con Fisibility</h2>
                                </div> */}

                                {/* <div className='text'>
                                    <p>Disponer de una supervisión del rendimiento 24/7/365 con notificaciones de alerta en tiempo real le dá la tranquilidad de que su negocio y su capital están protegidos en todo momento.</p>
                                </div> */}

                                {/* <div className='bottom'>
                                    <div className='registrarse_btn'>
                                        <button className='btn'>REGISTRARSE</button>
                                    </div>
                                    <div className='descargar_btn'>
                                        <button className='btn'>DESCARGAR</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6 col-md-12 col-12'>
                            <div className='right_cnt'></div>
                        </div>
                    </div>
                </div>
            </section> */}

        </>
    )
}
