import React, { useEffect, useState } from 'react'
import "react-datetime/css/react-datetime.css";
import Datetime from "react-datetime";
import moment from 'moment';
import HttpClient from '../utils/HttpClient';
import { toast } from 'react-hot-toast';
import { useLanguage } from '../Providers/LanguageContext';
import Swal from 'sweetalert2';

function CustomInputComp({ type = "text", field, id, data, getTransactionData, expenseData, currPage, successmsg, errormsg }) {


    const { language } = useLanguage();

    const [editEnabled, setEditEnabled] = useState(false)

    const [expenseTypes, setExpenseTypes] = useState([]);
    const [value, setValue] = useState("")
    const [isSaved, setIsSaved] = useState(false)
    const handleEdit = () => {
        setEditEnabled(prev => !prev);
    }

    const handleVal = (value) => {
        setValue(value.target.value)
    }

    const handleSave = () => {
        setIsSaved(true)
    }

    const handleEditField = async () => {
        if (!value) {
            return
        }
        const res = await HttpClient.requestData("update-transaction/" + id, "PUT", { [field]: value });
        if (res && res.status) {
            toast.success(successmsg);
            getTransactionData(data.transactionFilter, data.email)
            console.log(res)
        } else {
            toast.error(errormsg)
        }
    }


    useEffect(() => {
        console.log(expenseData, "Expense data")
        setValue(expenseData[currPage][field])
    }, [currPage])

    useEffect(() => {
        const fetchExpenseTypes = async () => {
            const res = await HttpClient.requestData("transaction-type", "GET");
            if (res && res.status) {
                setExpenseTypes(res.data)
            }
        }

        if (type === "select") {
            fetchExpenseTypes()
        }
    }, [])
    return (
        <div className='mt-3' style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "8px" }}>
            <div className="td-details mb-2" style={{
                display: "flex",
                justifyContent: "center", alignItems: "center",
                gap: "8px", width: "50%", background: "lightgray", padding: "10px"
            }}
            >

               <label htmlFor="inputdate" style={{ marginRight: "0", fontSize: "25px", width:"170px", textAlign:"left" }}>
                    {
                        field === "price" ? language.expense : field === "vendorName" ? language.supplier : field==="expenseTypeID"?language.expensetype: ""
                    }
                </label>
                <div className='custom-wrap' style={{display: "flex", width:"500px"}}>
                {type !== "select" && (
                    <input type={type} style={{ width: "200px" }} value={value} id="inputdate" disabled={!editEnabled} onChange={handleVal} />
                )}
                {
                    type === "select" && (
                        <select onChange={handleVal} disabled={!editEnabled} style={{width:"250px"}}>
                            {
                                expenseTypes.map((val, i) => (
                                    <option value={val._id}
                                        selected={val._id === expenseData[currPage].expenseTypeID}
                                        key={val._id}>{val.name}</option>
                                ))
                            }
                        </select>
                    )
                }
                <div className='td-buttons'>
                <button className='customBtn10 me-2' onClick={handleEdit}>{editEnabled?language.disable_edit:language.edit}</button>
                <button className='customBtn10 me-2' onClick={handleSave}>{language.save}</button>
                <button className='customBtn10' onClick={handleEditField} disabled={!isSaved}>{language.post}</button>
                </div>
                </div>
               </div>
               
        </div>
    )
}

export default CustomInputComp