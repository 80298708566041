import React, { useRef, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import { useLanguage } from '../../Providers/LanguageContext'

function InvoiceList({ invoices, filters, handleFilterClassChange, setFilters, fetchInvoices, setIsCleared }) {
    const navigate = useNavigate()
    const { language } = useLanguage()

    // const selectRef = useRef()

    const handleFetchTrans = async (t) => {
        console.log(t, "Ttttt")
        // if (res && res.status) {
        //      const transactionfind = res.data.find(t=>t.email === email);
        //     navigate("/transaction-details",{state:{...transactionfind,transactionFilter}})
        // }

        navigate("/invoice-summary", { state: { name: t.customerName, filtersDate: filters.date } })

    }

    // const clearFilters = () => {
    //     setFilters({
    //         date: "",
    //         customer: "",
    //         classification: ""
    //     })
    //     selectRef.current.selectedIndex = "0"
    //     setIsCleared(true)
    //     fetchInvoices()
    //     toast.success(language.clear_filter)
    // }

    return (
        <>
            {/* <div id='clafication'>
                <div className='container'>
                    <div className='table-top'>
                        <div className='top-left'>
                            <form>
                                <div className='top-select'>
                                    <div class="form-group">
                                        <select
                                            class="form-control"
                                            id="exampleFormControlSelect1"
                                            ref={selectRef}
                                            onChange={handleFilterClassChange}
                                        >
                                            <option>{language.classification}</option>
                                            <option value="Recent">{language.most_recent}</option>
                                            <option value="oldest">{language.oldest}</option>
                                            <option value="A-Z">Name A-Z</option>
                                            <option value="Z-A">Name Z-A</option>
                                        </select>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className='top-right'>
                            <span onClick={clearFilters} className='todo-btn' id="filterbtn">{language.all_results}</span>
                        </div>
                    </div>
                </div>
            </div> */}

            <div id='todo-table'>
                <div className='container'>
                    <div className='table-responsive'>
                        <table className="table">
                            <tbody>
                                {
                                    invoices && invoices.map((t, i) => (
                                        <tr style={{ backgroundColor: "#ebeff3" }} key={i}>
                                            <td style={{
                                                color: "#0e2537",
                                                fontWeight: "600",
                                                fontSize: "18px",
                                                paddingLeft: "20px",
                                                borderTop: "0"
                                            }}>
                                                {t.customerName}
                                            </td>
                                            <td style={{
                                                display: "flex",
                                                justifyContent: "flex-end",
                                                alignItems: "flex-end",
                                                paddingRight: "30px"
                                            }}>
                                                <button
                                                    style={{
                                                        maxWidth: "110px",
                                                        width: "100%",
                                                        height: "30px",
                                                        borderRadius: "10px",
                                                        background: "linear-gradient(to right, #18465e 1%, #14384d 51%, #0f273a 100%)",
                                                        border: "none", color: "#fff", fontSize: "16px"
                                                    }}
                                                    onClick={() => handleFetchTrans(t)}
                                                >
                                                    {language.view}
                                                </button>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default InvoiceList