import React from 'react'
import a from "../Images/7-Bank-Integrations.png";
export default function Integration({ goToRegistration }) {
  return (
    <div className='inner'>
      <div className="SpreadSheet integration">

        <div className="rgtCnt">
          <div className="bottom_cnt">
            <div className="wrapCnt">
              <h4 style={{ marginBottom: '10px' }}>Over 12,000+</h4>
              <h4>Bank Integrations</h4>
              <span>
                We automate all of your financial data posts from your bank, debit, and credit card transactions
              </span>

              <span className="mt-3 d-block">
                Connect all of your bank accounts with your username & password
              </span>
              <h5 style={{ color: '#4FACFF', fontSize: '17px' }} className='mt-3'>
                “Where will I see all of my data once its converted into useful information?”
              </h5>
              <span className="mt-3 d-block">
                System dashboards will instantly consolidate & present all of this data to you
              </span>
              <a onClick={() => goToRegistration()} className="read-more mt-5">
                Let’s Get You Connected
              </a>


            </div>
          </div>
        </div>
        <div className="lftImg">
          <img src={a} alt="" />
        </div>
      </div>
    </div>

  )
}
