
import React, { Component } from "react";
import Slider from "react-slick";
import sfg from "../../images/_Imagen_.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useLanguage } from "../../Providers/LanguageContext";
import { useRequestData } from "../../hooks/RequestDataHook";
import { Triangle } from "react-loader-spinner";


function Blogslider() {
const {language}=useLanguage()

const {data,loading,error} = useRequestData("blog-get","GET",{});

  var settings = {
    dots: false,
    infinite: false,
    autoplay: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          autoplay: true,
          dots: false
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          initialSlide: 0
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          autoplay: true,
          slidesToScroll: 1
        }
      }
    ]
  };

 
  return (
    <>

      <section id='read-section'>
        <div className='container'>
          <div className='slider-heading' style={{cursor:'default'}}>
            <h2>{language.blog_like_read}</h2>
          </div>
          <div className='blog-slider'>
            <div className=''>
              {
                loading && (
                  <Triangle
                  height="80"
                  width="80"
                  color="#4fa94d"
                  ariaLabel="triangle-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={true}
                />
                )
              }
              {
                !loading && !error && (
                  <Slider {...settings}>
                 {
                  data && data.map((blog)=>(
                    <div className='item' key={blog._id}>
                    <div className='slide-item mr-2'>
                      <div className='slide-image'>
                        <img className="img-fluid" src={blog.image} />
                      </div>
                      <div className='slide-content'>
                        <div className='top-content'>
                          <h5>{blog.title}</h5>
                          <h6>{blog.text}</h6>
                        </div>
                        <div className='bottom-content'>
                          <p>{language.more_information} <a href='#'><i className="fa-solid fa-arrow-right"></i></a></p>
                        </div>
                      </div>
                    </div>
                  </div>
                  ))
                 }
                 {
                  error && (
                    <h4>{language.no_data}</h4>
                  )
                 }
                {/* <div className='item'>
                  <div className='slide-item mr-2'>
                    <div className='slide-image'>
                      <img className="img-fluid" src={sfg} />
                    </div>
                    <div className='slide-content'>
                      <div className='top-content'>
                        <h5>{language.title}</h5>
                        <h6>{language.text}</h6>
                      </div>
                      <div className='bottom-content'>
                        <p>{language.more_information} <a href='#'><i className="fa-solid fa-arrow-right"></i></a></p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='item'>
                  <div className='slide-item mr-2'>
                    <div className='slide-image'>
                      <img className="img-fluid" src={sfg} />
                    </div>
                    <div className='slide-content'>
                      <div className='top-content'>
                        <h5>{language.title}</h5>
                        <h6>{language.text}</h6>
                      </div>
                      <div className='bottom-content'>
                        <p>{language.more_information} <a href='#'><i className="fa-solid fa-arrow-right"></i></a></p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='item'>
                  <div className='slide-item mr-2'>
                    <div className='slide-image'>
                      <img className="img-fluid" src={sfg} />
                    </div>
                    <div className='slide-content'>
                      <div className='top-content'>
                        <h5>{language.title}</h5>
                        <h6>{language.text}</h6>
                      </div>
                      <div className='bottom-content'>
                        <p>{language.more_information} <a href='#'><i className="fa-solid fa-arrow-right"></i></a></p>
                      </div>
                    </div>
                  </div>
                </div> */}
              </Slider>
                )
              }
            </div>
          </div>
        </div>
      </section>


    </>
  )
}

export default Blogslider