import React, { useEffect, useState } from 'react'
import ReactApexChart from 'react-apexcharts'
import { useLanguage } from '../../../Providers/LanguageContext'

function ExpenseChart({ data, text }) {
    const { language } = useLanguage()


    // console.log(data[0], data[1], data[2], "Dataaa")




    const [series, setSeries] = useState(() => {
        if (text === language.expense) {
            return [{
                name: text,
                data: [data.actualExpense ? Math.round(Number(data.actualExpense))
                    : 0, data.budgetExpense ? data.budgetExpense : 0]
            }]
        } else if (text === language.sales) {
            return [{
                name: text,
                data: [data.actualSales ? Math.round(Number(data.actualSales))
                    : 0, data.budgetSales ? data.budgetSales : 0]
            }]
        } else if (text === language.profit) {
            return [{
                name: text,
                data: [data.actualProfit ? Math.round(Number(data.actualProfit))
                    : 0, data.budgetProfit ? data.budgetProfit : 0]
            }]
        }
    })

    const [options, setOptions] = useState({
        chart: {
            type: 'bar',
            height: "100%",
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '30%',
                endingShape: 'rounded',
                distributed: true,
                dataLabels: {
                    position: 'top', // top, center, bottom
                },
            },

        },
        title: {
            text: text,
            align: 'left',
            margin: 10,
            offsetX: 0,
            offsetY: 0,
            floating: false,
            style: {
                fontSize: '14px',
                fontWeight: 'bold',
                fontFamily: undefined,
                color: '#263238'
            },
        },
        dataLabels: {
            enabled: true,
            formatter: function (val) {
                return "$ " + Number(val).toLocaleString();
            },
            offsetY: -20,
            style: {
                fontSize: "12px",
                colors: ["#304758"]
            }
        },
        stroke: {
            show: true,
            width: -2,
            colors: ['transparent']
        },
        xaxis: {
            categories: [language.actual_expense, language.budget_expense],
            position: "bottom",
            labels: {
                offsetX: 0
            },
        },

        fill: {
            opacity: 1,
            // gradient: {
            //     shade: "light",
            //     type: "horizontal",
            //     shadeIntensity: 0.25,
            //     gradientToColors: undefined,
            //     inverseColors: true,
            //     opacityFrom: 1,
            //     opacityTo: 1,
            //     stops: [50, 0, 100, 100]
            // }
        },
        tooltip: {
            y: {
                formatter: function (val, opt) {
                    console.log(opt, "optionsbaby")
                    return "$ " + val
                }
            },
            enabled: true,
            offsetY: -35,

        },
        colors: ["#3b3425", "#735a24", "#918b7e"],
        legend: {
            show: true,
            showForSingleSeries: false,
            showForNullSeries: true,
            showForZeroSeries: true,
            position: 'bottom',
            horizontalAlign: 'center',
            floating: false,
            fontSize: '14px',
            fontFamily: 'Helvetica, Arial',
            fontWeight: 400,
            formatter: undefined,
            inverseOrder: false,
            width: undefined,
            height: undefined,
            tooltipHoverFormatter: undefined,
            customLegendItems: [],
            offsetX: 0,
            offsetY: 0,
            labels: {
                colors: undefined,
                useSeriesColors: false
            },
            markers: {
                width: 12,
                height: 12,
                strokeWidth: 0,
                strokeColor: '#fff',
                fillColors: undefined,
                radius: 12,
                customHTML: undefined,
                onClick: undefined,
                offsetX: 0,
                offsetY: 0
            },
            itemMargin: {
                horizontal: 5,
                vertical: 0
            },
            onItemClick: {
                toggleDataSeries: true
            },
            onItemHover: {
                highlightDataSeries: true
            },
        }
    }
    )


    useEffect(() => {
        if (text === language.expense) {
            setSeries([{
                name: text,
                data: [data.actualExpense ? Math.round(Number(data.actualExpense))
                    : 0, data.budgetExpense ? data.budgetExpense : 0]
            }])

            setOptions({
                chart: {
                    type: 'bar',
                    height: "100%",
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: '30%',
                        endingShape: 'rounded',
                        distributed: true,
                        dataLabels: {
                            position: 'top', // top, center, bottom
                        },
                    },

                },
                title: {
                    text: text,
                    align: 'left',
                    margin: 10,
                    offsetX: 0,
                    offsetY: 0,
                    floating: false,
                    style: {
                        fontSize: '14px',
                        fontWeight: 'bold',
                        fontFamily: undefined,
                        color: '#263238'
                    },
                },
                dataLabels: {
                    enabled: true,
                    formatter: function (val) {
                        return "$ " + Number(val).toLocaleString();
                    },
                    offsetY: -20,
                    style: {
                        fontSize: "12px",
                        colors: ["#304758"]
                    }
                },
                stroke: {
                    show: true,
                    width: -2,
                    colors: ['transparent']
                },
                xaxis: {
                    categories: [language.actual_expense, language.budget_expense],
                    position: "bottom",
                    labels: {
                        offsetX: 0
                    },
                },

                fill: {
                    opacity: 1,
                    // gradient: {
                    //     shade: "light",
                    //     type: "horizontal",
                    //     shadeIntensity: 0.25,
                    //     gradientToColors: undefined,
                    //     inverseColors: true,
                    //     opacityFrom: 1,
                    //     opacityTo: 1,
                    //     stops: [50, 0, 100, 100]
                    // }
                },
                tooltip: {
                    y: {
                        formatter: function (val, opt) {
                            console.log(opt, "optionsbaby")
                            return "$ " + val
                        }
                    },
                    enabled: true,
                    offsetY: -35,

                },
                colors: ["#3b3425", "#735a24", "#918b7e"],
                legend: {
                    show: true,
                    showForSingleSeries: false,
                    showForNullSeries: true,
                    showForZeroSeries: true,
                    position: 'bottom',
                    horizontalAlign: 'center',
                    floating: false,
                    fontSize: '14px',
                    fontFamily: 'Helvetica, Arial',
                    fontWeight: 400,
                    formatter: undefined,
                    inverseOrder: false,
                    width: undefined,
                    height: undefined,
                    tooltipHoverFormatter: undefined,
                    customLegendItems: [],
                    offsetX: 0,
                    offsetY: 0,
                    labels: {
                        colors: undefined,
                        useSeriesColors: false
                    },
                    markers: {
                        width: 12,
                        height: 12,
                        strokeWidth: 0,
                        strokeColor: '#fff',
                        fillColors: undefined,
                        radius: 12,
                        customHTML: undefined,
                        onClick: undefined,
                        offsetX: 0,
                        offsetY: 0
                    },
                    itemMargin: {
                        horizontal: 5,
                        vertical: 0
                    },
                    onItemClick: {
                        toggleDataSeries: true
                    },
                    onItemHover: {
                        highlightDataSeries: true
                    },
                }
            })
        } else if (text === language.sales) {
            setSeries([{
                name: text,
                data: [data.actualSales ? Math.round(Number(data.actualSales))
                    : 0, data.budgetSales ? data.budgetSales : 0]
            }])

            setOptions({
                chart: {
                    type: 'bar',
                    height: "100%",
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: '30%',
                        endingShape: 'rounded',
                        distributed: true,
                        dataLabels: {
                            position: 'top', // top, center, bottom
                        },
                    },

                },
                title: {
                    text: text,
                    align: 'left',
                    margin: 10,
                    offsetX: 0,
                    offsetY: 0,
                    floating: false,
                    style: {
                        fontSize: '14px',
                        fontWeight: 'bold',
                        fontFamily: undefined,
                        color: '#263238'
                    },
                },
                dataLabels: {
                    enabled: true,
                    formatter: function (val) {
                        return "$ " + Number(val).toLocaleString();
                    },
                    offsetY: -20,
                    style: {
                        fontSize: "12px",
                        colors: ["#304758"]
                    }
                },
                stroke: {
                    show: true,
                    width: -2,
                    colors: ['transparent']
                },
                xaxis: {
                    categories: [language.actual_sales, language.budget_sales],
                    position: "bottom",
                    labels: {
                        offsetX: 0
                    },
                },

                fill: {
                    opacity: 1,
                    // gradient: {
                    //     shade: "light",
                    //     type: "horizontal",
                    //     shadeIntensity: 0.25,
                    //     gradientToColors: undefined,
                    //     inverseColors: true,
                    //     opacityFrom: 1,
                    //     opacityTo: 1,
                    //     stops: [50, 0, 100, 100]
                    // }
                },
                tooltip: {
                    y: {
                        formatter: function (val, opt) {
                            console.log(opt, "optionsbaby")
                            return "$ " + val
                        }
                    },
                    enabled: true,
                    offsetY: -35,

                },
                colors: ["#3b3425", "#735a24", "#918b7e"],
                legend: {
                    show: true,
                    showForSingleSeries: false,
                    showForNullSeries: true,
                    showForZeroSeries: true,
                    position: 'bottom',
                    horizontalAlign: 'center',
                    floating: false,
                    fontSize: '14px',
                    fontFamily: 'Helvetica, Arial',
                    fontWeight: 400,
                    formatter: undefined,
                    inverseOrder: false,
                    width: undefined,
                    height: undefined,
                    tooltipHoverFormatter: undefined,
                    customLegendItems: [],
                    offsetX: 0,
                    offsetY: 0,
                    labels: {
                        colors: undefined,
                        useSeriesColors: false
                    },
                    markers: {
                        width: 12,
                        height: 12,
                        strokeWidth: 0,
                        strokeColor: '#fff',
                        fillColors: undefined,
                        radius: 12,
                        customHTML: undefined,
                        onClick: undefined,
                        offsetX: 0,
                        offsetY: 0
                    },
                    itemMargin: {
                        horizontal: 5,
                        vertical: 0
                    },
                    onItemClick: {
                        toggleDataSeries: true
                    },
                    onItemHover: {
                        highlightDataSeries: true
                    },
                }
            })
        } else if (text === language.profit) {
            setSeries([{
                name: text,
                data: [data.actualProfit ? Math.round(Number(data.actualProfit))
                    : 0, data.budgetProfit ? data.budgetProfit : 0]
            }])

            setOptions({
                chart: {
                    type: 'bar',
                    height: "100%",
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: '30%',
                        endingShape: 'rounded',
                        distributed: true,
                        dataLabels: {
                            position: 'top', // top, center, bottom
                        },
                    },

                },
                title: {
                    text: text,
                    align: 'left',
                    margin: 10,
                    offsetX: 0,
                    offsetY: 0,
                    floating: false,
                    style: {
                        fontSize: '14px',
                        fontWeight: 'bold',
                        fontFamily: undefined,
                        color: '#263238'
                    },
                },
                dataLabels: {
                    enabled: true,
                    formatter: function (val) {
                        return "$ " + Number(val).toLocaleString();
                    },
                    offsetY: -20,
                    style: {
                        fontSize: "12px",
                        colors: ["#304758"]
                    }
                },
                stroke: {
                    show: true,
                    width: -2,
                    colors: ['transparent']
                },
                xaxis: {
                    categories: [language.actual_profit, language.budget_profit],
                    position: "bottom",
                    labels: {
                        offsetX: 0
                    },
                },

                fill: {
                    opacity: 1,
                    // gradient: {
                    //     shade: "light",
                    //     type: "horizontal",
                    //     shadeIntensity: 0.25,
                    //     gradientToColors: undefined,
                    //     inverseColors: true,
                    //     opacityFrom: 1,
                    //     opacityTo: 1,
                    //     stops: [50, 0, 100, 100]
                    // }
                },
                tooltip: {
                    y: {
                        formatter: function (val, opt) {
                            console.log(opt, "optionsbaby")
                            return "$ " + val
                        }
                    },
                    enabled: true,
                    offsetY: -35,

                },
                colors: ["#3b3425", "#735a24", "#918b7e"],
                legend: {
                    show: true,
                    showForSingleSeries: false,
                    showForNullSeries: true,
                    showForZeroSeries: true,
                    position: 'bottom',
                    horizontalAlign: 'center',
                    floating: false,
                    fontSize: '14px',
                    fontFamily: 'Helvetica, Arial',
                    fontWeight: 400,
                    formatter: undefined,
                    inverseOrder: false,
                    width: undefined,
                    height: undefined,
                    tooltipHoverFormatter: undefined,
                    customLegendItems: [],
                    offsetX: 0,
                    offsetY: 0,
                    labels: {
                        colors: undefined,
                        useSeriesColors: false
                    },
                    markers: {
                        width: 12,
                        height: 12,
                        strokeWidth: 0,
                        strokeColor: '#fff',
                        fillColors: undefined,
                        radius: 12,
                        customHTML: undefined,
                        onClick: undefined,
                        offsetX: 0,
                        offsetY: 0
                    },
                    itemMargin: {
                        horizontal: 5,
                        vertical: 0
                    },
                    onItemClick: {
                        toggleDataSeries: true
                    },
                    onItemHover: {
                        highlightDataSeries: true
                    },
                }
            })

        }
    }, [data, text])



    return (
        <ReactApexChart options={options} series={series} type="bar" height={350} />
    )
}

export default ExpenseChart






