import React, { useContext, useEffect, useMemo, useState } from 'react'
import Profileimg from "../../src/images/profile-guy.png";
import { useLanguage } from '../Providers/LanguageContext';
import { countries } from "../utils/countries"
import getSymbolFromCurrency from 'currency-symbol-map'
import HttpClient from '../utils/HttpClient';
import { toast } from 'react-hot-toast';
import { Navigate, useNavigate } from 'react-router-dom';
import { UserContext } from '../Providers/UserWrapper';
import { useSpeach } from '../Providers/SpeechWrapper';

export default function Createprofile() {
    const navigate = useNavigate();
    const { language, currentLanguage } = useLanguage();
    const { getProfile, profileData, isBusiness, isMainUserMemo, regCodeData } = useContext(UserContext)
    const { setCurrAudio } = useSpeach();
    const [isSubmit, setIsSubmit] = useState(false);

    const [countryData, setCountryData] = useState([]);

    const countyList = useMemo(() => {
        return countries
    }, [])

    const [values, setValues] = useState({
        country: null,
        currency: "",
        companyName: "",
        rfcID: "",
        name: "",
        surName: "",
        cp: "",
        city: "",
        state: "",
        phoneNumber: ""
    });

    console.log("valuesfff", values)

    useEffect(() => {
        if (profileData && profileData?.userFlag == 0) {
            if (isMainUserMemo) {
                setValues({
                    country: null,
                    currency: "",
                    companyName: "",
                    rfcID: "",
                    name: "",
                    surName: "",
                    cp: "",
                    city: "",
                    state: "",
                    phoneNumber: ""
                })
            } else {
                setValues({
                    country: null,
                    currency: "",
                    companyName: regCodeData.companyName,
                    rfcID: regCodeData.rfcID,
                    name: "",
                    surName: "",
                    cp: "",
                    city: "",
                    state: "",
                    phoneNumber: ""
                })
            }
        } else {
            if (isMainUserMemo) {
                setValues({
                    country: null,
                    currency: "",
                    companyName: "",
                    rfcID: "",
                    name: "",
                    surName: "",
                    cp: "",
                    city: "",
                    state: "",
                    phoneNumber: ""
                })
            } else {
                // console.log("companyNamedd", regCodeData?.rfcID)
                setValues((prev) => ({
                    ...prev,
                    companyName: regCodeData?.companyName,
                    rfcID: regCodeData.rfcID,
                }))
            }
        }
    }, [isMainUserMemo, regCodeData, profileData]);

    const fetchCountry = async () => {
        const res = await HttpClient.requestData('get-country', 'GET');
        if (res && res.status) {
            setCountryData(res.data);
        }
    }

    useEffect(() => {
        fetchCountry();
    }, [])

    // const fetchCity = async (sendData) => {
    //     // const sendData = {
    //     //     postalCode: 20000
    //     // }
    //     const res = await HttpClient.requestData('fetch-city-postalCode', 'POST', sendData);
    // }

    const handleZipCode = async (zip) => {
        const pattern = /^\d+$/
        if (pattern.test(zip) || zip === "") {
            setValues(prev => ({ ...prev, cp: zip }));
            const sendData = {
                postalCode: Number(zip)
            }
            const res = await HttpClient.requestData('fetch-city-postalCode', 'POST', sendData);
            if (res && res.status) {
                if (res.data) {
                    setValues(prev => ({ ...prev, city: res.data.Municipality, state: res.data.State }));
                }
                else {
                    // setValues(prev => ({ ...prev, city: '', state: '' }));
                }
            }
        }
    }

    useEffect(() => {
        if (values.country) {
            const found = countyList.find(c => c.country === values.country);
            if (found) {
                setValues({
                    ...values,
                    currency: found.currency_code
                })
            }
        }
    }, [values.country])

    const updateProfile = async () => {
        const val = Object.values(values);
        const flag = val.every(v => Boolean(v));
        console.log("values", values)
        if (isBusiness) {
            if (!values.companyName) {
                toast.error(language.please_enter_all);
                return
            }
            if (!values.rfcID) {
                toast.error(language.please_enter_all);
                return
            }
        }
        if (!values.country) {
            toast.error(language.please_enter_all);
            return
        }
        if (!values.currency) {
            toast.error(language.please_enter_all);
            return
        }
        if (!values.name) {
            toast.error(language.please_enter_all);
            return
        }
        if (!values.surName) {
            toast.error(language.please_enter_all);
            return
        }
        if (!values.city) {
            toast.error(language.please_enter_all);
            return
        }
        if (!values.cp) {
            toast.error(language.please_enter_all);
            return
        }
        if (!values.state) {
            toast.error(language.please_enter_all);
            return
        }
        if (!values.phoneNumber) {
            toast.error(language.please_enter_all);
            return
        }

        const result = await HttpClient.requestData("update", "PUT", values)
        if (result && result.success) {
            getProfile();

            addFirstProfile();
            if (currentLanguage === "eng") {
                toast.success("Profile created")
            } else {
                toast.success("perfil creado")
            }

            if (profileData) {
                // if (profileData?.userType === "Bookkeeper") {
                //     console.log("routePath", true)
                //     navigate("/bookkeeper-manager");
                // } else {
                navigate("/manager");
                // }
            }
        } else {
            if (currentLanguage === "eng") {
                toast.success("failed to update")
            } else {
                toast.success("no se pudo actualizar")
            }
        }
    }

    useEffect(() => {
        if (profileData) {
            if (profileData?.userFlag == 1) {
                setValues((prev) => {
                    return {
                        ...prev,
                        country: profileData?.country ? profileData?.country : "",
                        // currency: "",
                        // companyName: profileData?.country ? profileData?.companyName : "",
                        // rfcID: profileData?.country ? profileData?.rfcID : "",
                        name: profileData?.name ? profileData?.name : "",
                        surName: profileData?.surName ? profileData?.surName : "",
                        cp: profileData?.cp ? profileData?.cp : "",
                        city: profileData?.city ? profileData?.city : "",
                        state: profileData?.state ? profileData?.state : "",
                        phoneNumber: profileData?.phoneNumber ? profileData?.phoneNumber : ""
                    }
                })
            }
        }
    }, [profileData])

    const getFirstProfile = async () => {
        const res = await HttpClient.requestData("get-profile-count", "GET", {});
        if (res && res?.status) {
            if (res?.data?.length) {
                setIsSubmit(true);
            }
        }
    }

    const addFirstProfile = async () => {
        const res = await HttpClient.requestData("update-profile-count", "POST", {});
        console.log("resUpdtpp", res)
        getFirstProfile();
    }


    useEffect(() => {
        setCurrAudio(null);
        getFirstProfile();
    }, []);

    if (profileData) {
        if (profileData?.userFlag == 0) {
            if (profileData.name) {
                // if (profileData?.userType === "Bookkeeper") {
                //     return <Navigate to="/bookkeeper-manager" />
                // } else {
                return <Navigate to="/manager" />
                // }
            }
        } else {
            if (isSubmit) {
                // return <Navigate to="/manager" />
            }
        }
    }

    return (

        <>

            <section id='create-profile'>
                <div className='container'>

                    <div className='row'>
                        <div className='col-xl-8 col-lg-8 col-md-8 col-12'>
                            <div className='profile-top'>
                                <h2>{language.hello}</h2>
                                <h4>{language.create_profile}</h4>
                                <p>{language.sign_up_continue}</p>
                            </div>
                            <div className='profile-form'>
                                <form>
                                    {
                                        isBusiness &&
                                        <>
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id=""
                                                    aria-describedby=""
                                                    placeholder={language.company_name}
                                                    value={isMainUserMemo ? values.companyName : regCodeData.companyName}
                                                    onChange={(e) => {
                                                        setValues(prev => ({ ...prev, companyName: e.target.value }))
                                                    }}
                                                    readOnly={!isMainUserMemo}
                                                />
                                            </div>

                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id=""
                                                    aria-describedby=""
                                                    placeholder={language.rfc_id}
                                                    value={isMainUserMemo ? values.rfcID : regCodeData.rfcID
                                                    }
                                                    maxLength="13"
                                                    onChange={(e) => setValues(prev => ({ ...prev, rfcID: e.target.value }))}
                                                    readOnly={!isMainUserMemo}
                                                />
                                            </div>
                                        </>
                                    }

                                    <div className="form-group">
                                        <select
                                            className="form-control"
                                            id="exampleFormControlSelect1"
                                            value={values.country}
                                            onChange={(e) => {
                                                if (e.target.value) {
                                                    setValues(prev => ({ ...prev, country: e.target.value }))
                                                }
                                            }}
                                        >
                                            <option>{language.country}</option>
                                            {
                                                countryData.map((c, i) => (
                                                    <option value={c.country} key={i}>{c.country}</option>
                                                ))
                                            }
                                            {/* {
                                                countyList.map((c, i) => (
                                                    <option value={c.country} key={i}>{c.country}</option>
                                                ))
                                            } */}
                                            {/* <option>2</option>
                                            <option>3</option>
                                            <option>4</option>
                                            <option>5</option> */}
                                        </select>
                                    </div>
                                    {/* <Select options={options} onChange={(values) => this.setValues(values)} /> */}

                                    <div className="form-group">
                                        <select className="form-control" id="exampleFormControlSelect1" >
                                            <option>{language.select_currency}</option>
                                            {
                                                values.currency && (
                                                    <option value={values.currency} selected>{values.currency} {getSymbolFromCurrency(values.currency)}</option>
                                                )
                                            }
                                            {/* <option>2</option>
                                            <option>3</option>
                                            <option>4</option>
                                            <option>5</option> */}
                                        </select>
                                    </div>


                                </form>
                            </div>
                        </div>
                        <div className='col-xl-4 col-lg-4 col-md-4 col-12 mb-3'>
                            <div className='profile-image'>
                                <img className="img-fluid" src={Profileimg} />
                            </div>
                        </div>
                    </div>

                    <div className='profile-bottom'>
                        <div className='row'>
                            <div className='col-xl-6 col-lg-6 col-md-6 col-12'>
                                <div className="form-group">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id=""
                                        aria-describedby=""
                                        placeholder={language.name}
                                        value={values.name}
                                        onChange={(e) => {
                                            let value = e.target.value;
                                            // let pattern = /^[a-zA-Z]+$/
                                            let pattern = /^[A-Za-z\s]*$/
                                            if (pattern.test(value) || value === "") {
                                                setValues(prev => ({ ...prev, name: e.target.value }))
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                            <div className='col-xl-6 col-lg-6 col-md-6 col-12'>
                                <div className="form-group">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id=""
                                        aria-describedby=""
                                        placeholder={language.surname}
                                        value={values.surName}
                                        onChange={(e) => {
                                            let value = e.target.value;
                                            let pattern = /^[a-zA-Z]+$/
                                            if (pattern.test(value) || value === "") {
                                                setValues(prev => ({ ...prev, surName: e.target.value }))
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-xl-6 col-lg-6 col-md-6 col-12'>
                                <div className="form-group">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id=""
                                        aria-describedby=""
                                        placeholder={language.cp}
                                        value={values.cp}
                                        onChange={(e) => {
                                            // const pattern = /^\d+$/
                                            // if (pattern.test(e.target.value) || e.target.value === "") {
                                            //     setValues(prev => ({ ...prev, cp: e.target.value }))
                                            // }
                                            handleZipCode(e.target.value)
                                        }}
                                    />
                                </div>
                            </div>
                            <div className='col-xl-6 col-lg-6 col-md-6 col-12'>
                                <div className="form-group">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id=""
                                        aria-describedby=""
                                        placeholder={language.city}
                                        value={values.city}
                                        onChange={(e) => {
                                            // const pattern = /^[a-zA-Z]+$/
                                            let pattern = /^[A-Za-z\s]*$/
                                            if (pattern.test(e.target.value) || e.target.value === "") {
                                                setValues(prev => ({ ...prev, city: e.target.value }))
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-xl-6 col-lg-6 col-md-6 col-12'>
                                <div className="form-group">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id=""
                                        aria-describedby=""
                                        placeholder={language.state}
                                        value={values.state}
                                        onChange={(e) => {
                                            // const pattern = /^[a-zA-Z]+$/
                                            let pattern = /^[A-Za-z\s]*$/
                                            if (pattern.test(e.target.value) || e.target.value === "") {
                                                setValues(prev => ({ ...prev, state: e.target.value }))
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                            <div className='col-xl-6 col-lg-6 col-md-6 col-12'>
                                <div className="form-group">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id=""
                                        aria-describedby=""
                                        placeholder={language.phone_number}
                                        value={values.phoneNumber}
                                        onChange={(e) => {
                                            const val = e.target.value
                                            const pattern = /^\d+$/
                                            if ((pattern.test(val) && val.length < 12) || e.target.value === "") {
                                                setValues(prev => ({ ...prev, phoneNumber: val }))
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='profile-btn'>
                        <button className='continue-btn' onClick={updateProfile}>{language.continue}</button>
                    </div>
                </div>
            </section>
        </>


    )
}
