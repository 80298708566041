import React, { useEffect, useMemo, useState } from 'react'
import HttpClient from '../../../utils/HttpClient';
import { useUserData } from '../../../Providers/UserWrapper';
import fisiIconDrop from "../../../images/FisTaskIcon.png";
import TaskPopUp from '../TaskPopUp';
import toast from 'react-hot-toast';
import moment from 'moment';
import { useLanguage } from '../../../Providers/LanguageContext';
import Select from 'react-select';


const TaskSidebar = ({ getTaskData, taskAllData, setModala, setTaskDetailData, selectAssignUserMemo }) => {
    const { language, currentLanguage } = useLanguage();
    const { isMainUserMemo, profileData } = useUserData();

    // console.log("taskAllDatasdd", taskAllData)

    const initSelectData = { value: "", label: "" }
    const initTask = {
        date: "",
        startTime: "",
        endTime: "",
        taskName: "",
        taskNote: "",
        taskStatus: "",
        assignedUser: initSelectData,
        assignedProject: null
    }
    const [formData, setFormData] = useState(initTask);

    const clearState = () => {
        setFormData(initTask)
    }

    const [assignProjectData, setAssignProjectData] = useState([]);
    const [searchedData, setSearchedData] = useState(initSelectData);

    // console.log("formData", formData)


    const searchSelectOptionMemo = useMemo(() => {
        return taskAllData?.map((item) => ({
            value: item,
            label: item?.taskName
        }))
    }, [taskAllData])

    // change 
    const handleTaskChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    }

    // validate
    const validate = () => {

        if (!formData.date) {
            toast.error("Date is Required");
            return true
        }
        if (!formData.startTime) {
            toast.error("Start Time is Required");
            return true
        }
        if (!formData.endTime) {
            toast.error("End Time is Required");
            return true
        }
        if (!formData.taskName) {
            toast.error("Task Name is Required");
            return true
        }
        if (!formData.taskNote) {
            toast.error("Task Note is Required");
            return true
        }

        // if (!formData.taskStatus) {
        //     toast.error("Task Status is Required");
        //     return true
        // }
        // if (!formData.assignedUser) {
        //     toast.error("Assigned User is Required");
        //     return true
        // }

        return false;
    }

    // click on post
    const handlePost = async (e) => {
        e.preventDefault();
        if (validate()) return;
        // setModala(true);


        const data = {
            assignToUser: isMainUserMemo ? formData?.assignedUser?.value : profileData?._id,
            taskName: formData?.taskName,
            taskNotes: formData?.taskNote,
            taskStatus: formData?.taskStatus ? formData.taskStatus : 'open',
            selectDate: moment(formData?.date).format('YYYY-MM-DD'),
            startTime: formData?.startTime,
            endTime: formData?.endTime,
            assignToProject: formData?.assignedProject,
        };

        const res = await HttpClient.requestData("add-task-eventDetails", "POST", data)
        // console.log("ressff", res)
        if (res && res?.status) {
            toast.success("Task Added Successfully");
            clearState();
            getTaskData();
        } else {
            console.log(res?.message || "something wrong");
        }
    }


    // getting assigned projects
    const getAssignedProject = async () => {
        const res = await HttpClient.requestData("view-all-project", "GET")
        // console.log("resUser", res);
        if (res && res?.status) {
            setAssignProjectData(res?.data);
        }
    }


    useEffect(() => {
        // getAssignedUSer();
        getAssignedProject()
    }, [])


    return (
        <div>

            {/* form */}
            <form action="" className="taskEvForm">
                <div>

                    <div className="taskMainInpDiv">
                        {/* search */}

                        <div className="taskTabInps">
                            <Select
                                className="taskUpperInpSearch"
                                placeholder={language.search}
                                isClearable={true}
                                isSearchable={true}
                                // name="linkedUser"
                                value={searchedData?.value === "" ? null : searchedData}
                                options={searchSelectOptionMemo}
                                onChange={e => {
                                    // console.log('euser', e);
                                    if (e) {
                                        // setSearchedData(e);
                                        setSearchedData(initSelectData);
                                        setModala(true);
                                        setTaskDetailData(e.value)
                                    } else {
                                        setSearchedData(initSelectData);
                                    }
                                }}
                            />

                            {/* <input
                                type="text"
                                placeholder={language.search}
                                className="taskSearInp"
                            /> */}
                            <i class="fa-solid fa-magnifying-glass taskSearGlass" style={{ left: "108px" }}></i>
                        </div>

                        {/* date */}
                        <div className="taskTabInps">
                            <input
                                type="date"
                                placeholder={language.date}
                                className="taskSearInp"
                                name="date"
                                value={formData.date}
                                onChange={(e) => handleTaskChange(e)}
                            />
                        </div>
                    </div>



                    <div className="taskMainInpDiv">

                        {/* start time */}
                        <div className="taskTabInps">
                            <input
                                // type="datetime-local"
                                type="time"
                                className="taskDateTimeInp"
                                name="startTime"
                                value={formData.startTime}
                                onChange={(e) => handleTaskChange(e)}
                            />
                        </div>

                        {/* end time */}
                        <div className="taskTabInps">
                            <input
                                // type="datetime-local"
                                type="time"
                                className="taskDateTimeInp"
                                name="endTime"
                                value={formData.endTime}
                                onChange={(e) => handleTaskChange(e)}
                            />
                        </div>
                    </div>

                    {/* Task Name */}
                    <div className="taskMainInpDiv">
                        <input
                            type="text"
                            placeholder={language.task_name}
                            className="taskUpperInp"
                            name="taskName"
                            value={formData.taskName}
                            onChange={(e) => handleTaskChange(e)}
                        />
                    </div>

                    {/* Task Notes */}
                    <div className="taskMainInpDiv">
                        <input
                            type="text"
                            placeholder={language.task_nate}
                            className="taskUpperInp"
                            name="taskNote"
                            value={formData.taskNote}
                            onChange={(e) => handleTaskChange(e)}
                        />
                    </div>

                    {/*  Task Status */}
                    {/* <div className="taskMainInpDiv">
                        <input
                            type="text"
                            placeholder="Task Status"
                            className="taskUpperInp"
                            name="taskStatus"
                            value={formData.taskStatus}
                            onChange={(e) => handleTaskChange(e)}
                        />
                    </div> */}



                    <div className="taskMainInpDiv">
                        <select
                            id=""
                            className="taskUpperInp"
                            name="taskStatus"
                            value={formData.taskStatus}
                            onChange={(e) => handleTaskChange(e)}
                        >
                            <option value="" disabled selected>
                                {language.task_select}
                            </option>
                            <option value="open">{language.open}</option>
                            <option value="doing">{language.doing}</option>
                            <option value="done">{language.done}</option>
                            <option value="ideas">{language.ideas}</option>
                            <option value="onhold">{language.on_hold}</option>

                        </select>
                    </div>

                    {/* Assign To User */}

                    {
                        isMainUserMemo &&
                        <div className="taskMainInpDiv">

                            <Select
                                className="taskUpperInpSearch"
                                placeholder="Assign to User(Select)"
                                isClearable={true}
                                isSearchable={true}
                                name="assignedUser"
                                options={selectAssignUserMemo}
                                onChange={(e) => {
                                    if (e) {
                                        setFormData(prev => ({ ...prev, assignedUser: e }));
                                    } else {
                                        setFormData(prev => ({ ...prev, assignedUser: initSelectData }));
                                    }
                                }}
                            />

                            {/* <select
                            id=""
                            className="taskUpperInp"
                            name="assignedUser"
                            value={formData.assignedUser}
                            onChange={(e) => handleTaskChange(e)}
                        >
                            <option value="" disabled selected>
                                {language.Asign_to_user}
                            </option>
                            <option value={profileData?._id}>Me</option>
                            {
                                assignUserData?.map((item, i) =>
                                    <option value={item?._id}>{item?.name + " " + item?.surName}</option>
                                )
                            }
                        </select> */}
                        </div>
                    }

                    {/* Assign To Project */}
                    <div className="taskMainInpDiv">
                        <select
                            id=""
                            className="taskUpperInp"
                            name="assignedProject"
                            value={formData.assignedProject}
                            onChange={(e) => handleTaskChange(e)}
                        >
                            <option value="" disabled selected>
                                {language.assign_to_project}
                            </option>
                            {
                                assignProjectData?.map((item, i) =>
                                    <option key={i} value={item?._id}>{item?.projectName}</option>
                                )
                            }
                        </select>
                    </div>

                </div>
                {/* <div className="addNewTaskBtnDiv">
                    <button type='button'>+{language.add_list}</button>
                </div> */}
                <div className="postTaskBtnDiv">
                    <button
                        onClick={(e) => handlePost(e)}
                    >
                        {language.post}
                    </button>
                </div>
            </form>

            {/* drop to calender */}
            <div className="orDrFigDiv">
                <p className="orDrText">{language.Or_drop_to_calendar}</p>
                <figure className="fisiDrIconFig">
                    <img src={fisiIconDrop} alt="..." />
                </figure>
            </div>

            {/* quick view */}
            {
                taskAllData?.length !== 0 &&
                <div className="quickMainViewDiv">
                    <p className="ouickViewHead">{language.quick_view}</p>
                    <div className="quickViewUlDiv">
                        <ul style={{ maxHeight: "345px" }} className="quickViewUl"  >
                            {
                                taskAllData?.map((item, i) =>
                                    <li
                                        key={i}
                                        className="quickViewLi"
                                        onClick={() => {
                                            setModala(true);
                                            setTaskDetailData(item)
                                        }}
                                    >
                                        <div>
                                            <p className="quickAnsText">{item?.taskName}</p>
                                            <div className="quickDoneTextDiv">
                                                <p className="quickDoneText quickGreenDoneDot">
                                                    {item?.taskStatus}
                                                </p>
                                            </div>
                                        </div>
                                        <figure className="quickFirstIconFig">
                                            <img src={fisiIconDrop} alt="..." />
                                        </figure>
                                    </li>
                                )

                            }


                        </ul>
                    </div>
                </div>
            }

            {/* {
                modala &&
                <TaskPopUp
                    closemodal={setModala}
                    clearState={clearState}
                    getTaskData={getTaskData}
                    formData={formData}
                />
            } */}


        </div>
    )
}

export default TaskSidebar
