import React, { useCallback, useContext, useEffect, useState } from 'react'
import Dropbox from '../../../Component/Dropbox';
import { useLanguage } from '../../../Providers/LanguageContext';
import { SpeechContext } from '../../../Providers/SpeechWrapper';
import HttpClient from '../../../utils/HttpClient';
import DashboardChartFourColumn from './DashboardChartFourColumn';
import { UserContext } from '../../../Providers/UserWrapper';

function DashboardChartFour({ time, userCode }) {
    const { setShowGif, setGifToShow } = useContext(SpeechContext)
    const { isInvestor, isBookKeeper } = useContext(UserContext);

    const { language } = useLanguage()
    const [projects, setProjects] = useState([]);
    const [dashboardFourData, setDashboardFourData] = useState({})
    const [filters, setFilters] = useState({
        dayType: "",
        projectName: ""
    })

    // useEffect(() => {
    //     fetchProjects()
    // }, [])

    useEffect(() => {
        fetchProjects()
        setFilters(prev => ({ ...prev, dayType: time }))
    }, [time])

    useEffect(() => {
        fetchDashboardFour(filters)
    }, [filters, userCode])

    // useEffect(() => {
    // }, [dashboardFourData])



    const fetchDashboardFour = async (filt) => {
        if (filt.dayType && filt.projectName) {
            const res = await HttpClient.requestData(isInvestor || isBookKeeper ? `investor/investor-dashboard-four-view/${userCode}` : "dashboard-four-view", "POST", filt);
            console.log('dashboardFour', res)
            if (res && res.data) {
                if (res.message && res.message.trim() === "Dashboard rewards 1 points") {
                    setShowGif(true);
                    setGifToShow("Dashboard rewards 1 points")
                }
                setDashboardFourData(res.data)
            }
        }
    }

    const uniqueProjectName = (arr) => {
        const uniqueIds = [];
        const unique = arr.filter(element => {
            const isDuplicate = uniqueIds.includes(element.projectName);
            if (!isDuplicate) {
                uniqueIds.push(element.projectName);
                return true;
            }
            return false;
        });
        console.log('unique', unique);
        return unique
    }


    const fetchProjects = async () => {
        const res = await HttpClient.requestData(isInvestor || isBookKeeper ? `investor/investor-projectname/${userCode}` : "projectname", "GET", {});
        console.log('projectnamed', res)
        if (res && res.status) {
            let arr1 = uniqueProjectName(res.data);
            setProjects(arr1);
        } else {
            console.log("error", res)
        }
    }


    const getValue = (event) => {
        const value = event.target.value;
        console.log(value, "Seelectvalue", value !== language.select_project)
        if (value !== language.select_project) {
            setFilters(prev => ({ ...prev, projectName: value }))
        }
    }
    return (
        <div>
            <Dropbox data={projects} getValue={getValue} text={language.select_project} />
            {
                Object.values(dashboardFourData).length > 0 && (
                    <DashboardChartFourColumn data={dashboardFourData} />
                )
            }
        </div>
    )
}

export default DashboardChartFour