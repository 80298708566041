import React from "react";

export default function Instead({ goToRegistration }) {
  return (
    <div className="inner">
      <div className="snd_slide">
        <div className="bottom_cnt">
          <h4>
            Overwhelmed by High Maintenance Communication Requirements,
            Spreadsheets, Disconnected Systems, or No Systems At All?
          </h4>
          <p>
            The daily grind can be exhausting, leaving you feeling undervalued.
            <br />
            We understand.
          </p>
          <h5 className="my-3">
            Fisibility is providing you with a FREE user license to help
            organize all of your Tasks & Finances into one single-system for
            both Business & Personal Use
          </h5>
          <a onClick={() => goToRegistration()} className="signupBtn">
            Get FISIBILITY Instead
          </a>
        </div>
      </div>
    </div>
  );
}
