import React, { useEffect, useState } from "react";
import "./style.css"
import Banner from './Banner'
import Bannerbottom from './Bannerbottom'
import Persons from './Persons'
import Recommend from './Recommend'
import Banner1 from './Banner1'
import Banner2 from './Banner2'
import Gototop from "../../Component/Gototop"
import LoaderRotate from '../../Component/LoaderRotate'
import SignUp from '../../LandingPage/Modal/SignUp';
function Index2() {
  const [loading, setLoading] = useState(false);
  useEffect(() => {

    document.getElementById('bbb').classList.add('activee');
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 2000); // Adjust the timeout based on your loading time

    return () => clearTimeout(timeout);
  }, [loading]);
  const closeOpenModal = () => {

    document.getElementById('bbb').classList.remove('activee');

  }
  return (
    <>
      {/* <Helmet>
        <link rel="canonical" href="https://fisibility.com/" />
      </Helmet> */}
      <Banner />
      <Banner1 />
      <Banner2 />
      <Bannerbottom />
      <Persons />
      <Recommend />
      <Gototop />
      <SignUp closeModal={closeOpenModal} />
    </>
  )
}

export default Index2