import React from 'react'
import Biglogo from "../../images/fisibility-logo (1).png";
import Aboutphone from "../../images/about-phone.png";
import { useLanguage } from '../../Providers/LanguageContext';
import newLogo from '../../images/Change_image/fisibility_icono app.png'
import Aboutphoneen from '../../images/englishImage/CELULAR MORE P-P-M.png'

export default function Nosotros2() {
  const { language, currentLanguage } = useLanguage()
  return (
    <>

      <section id='fisibility-lite'>
        <div className='container'>
          <div className='row'>
            <div className='col-xl-6 col-lg-6 col-md-12 col-12'>
              <div className='fisibility-left'>
                <div className='left-logo'>
                  <img className="img-fluid" src={newLogo} />
                </div>
                <div className='left-content'>
                  <p>{language.about_early_stage}</p>
                </div>
              </div>
            </div>
            <div className='col-xl-6 col-lg-6 col-md-12 col-12'>
              <div className='right-image'>
                {
                  currentLanguage === 'spanish'
                    ?
                    <img className="img-fluid" src={Aboutphone} />
                    :
                    <img className="img-fluid" src={Aboutphoneen} />
                }
                {/* <img className="img-fluid" src={Aboutphone}/> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
