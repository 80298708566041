import React, { useContext, useEffect, useState } from 'react'
import { toast } from 'react-hot-toast';
import { useLanguage } from '../../../Providers/LanguageContext';
import { SpeechContext } from '../../../Providers/SpeechWrapper';
import { UserContext } from "../../../Providers/UserWrapper"
import HttpClient from '../../../utils/HttpClient';
import DashboardFivePieChart from './DashboardFivePieChart';
function DashboardChartFive() {
    const { language } = useLanguage()
    const { profileData } = useContext(UserContext);
    const { setShowGif, setGifToShow } = useContext(SpeechContext)
    const [dashboardFiveData, setDashBoardFiveData] = useState({
        currentAr: 0,
        pastDueAr: 0
    })
    const [filters, setFilters] = useState({
        customerName: "",
        startDate: "",
        endDate: ""
    })
    useEffect(() => {
        if (profileData && profileData?.userType === "Business") {
            // if(!filters.customerName && filters.startDate && filters.endDate){
            //     fetchDashboardFiveBu(filters)
            // }else if(filters.customerName && filters.startDate && filters.endDate ){
            //     fetchDashboardFiveBu(filters)
            // }else if(filters.customerName && !filters.startDate && !filters.endDate){
            //     fetchDashboardFiveBu(filters)
            // }
            if ((filters.startDate !== "" && filters.endDate !== "") || (filters.startDate === "" || filters.endDate === "")) {
                fetchDashboardFiveBu(filters)
            }

        } else if (profileData && profileData?.userType !== "Business") {
            fetchDashboardFivePu(filters)
        }
    }, [filters, profileData])

    async function fetchDashboardFiveBu(filt) {
        const res = await HttpClient.requestData("dashboard-five-bu", "POST", filt);
        if (res && res.status) {
            if (res.message && res.message.trim() === "Dashboard rewards 1 points") {
                setShowGif(true);
                setGifToShow("Dashboard rewards 1 points")
            }
            setDashBoardFiveData(res.data)
        }
    }

    async function fetchDashboardFivePu(filt) {
        const res = await HttpClient.requestData("dashboard-five-pu", "POST", filt);
        if (res && res.status) {
            if (res.message && res.message.trim() === "Dashboard rewards 1 points") {
                setShowGif(true);
                setGifToShow("Dashboard rewards 1 points")
            }
            setDashBoardFiveData(res.data)
        }
    }

    useEffect(() => {
        console.log(dashboardFiveData, "dashboardFiveData")
    }, [dashboardFiveData])
    return (
        <div id='config-form'>
            <div className='container'>
                <div className='inner-container'>
                    <div className='d-flex justify-content-end'>
                        <button style={{ background: "transparent", border: "none" }}
                            onClick={() => {
                                setFilters({
                                    customerName: "",
                                    startDate: "",
                                    endDate: ""
                                })
                                toast.success(language.clear_filter)
                            }}
                        >
                            <h5 className='my-3'>{language.clear_filters}</h5>
                        </button>
                    </div>
                    <form>
                        <div className="form-group">
                            <input
                                type="text"
                                className="form-control"
                                id=""
                                aria-describedby=""
                                placeholder={language.customer_name}
                                value={filters.customerName}
                                onChange={(e) => setFilters(prev => ({ ...prev, customerName: e.target.value }))}
                            />
                        </div>
                        <div className="form-group">
                            <div className='row'>
                                <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-sx-12'>
                                    <label className='mb-2' style={{ fontSize: "1.2rem" }}>{language.start_date}</label>
                                    <input
                                        type="date"
                                        className="form-control mb-3"
                                        id=""
                                        aria-describedby=""
                                        value={filters.startDate}
                                        onChange={(e) => setFilters(prev => ({ ...prev, startDate: e.target.value }))}
                                    />
                                </div>
                                <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-sx-12'>
                                    <label className='mb-2' style={{ fontSize: "1.2rem" }}>{language.final_date}</label>
                                    <input
                                        type="date"
                                        className="form-control mb-3"
                                        id=""
                                        aria-describedby=""
                                        value={filters.endDate}
                                        onChange={(e) => setFilters(prev => ({ ...prev, endDate: e.target.value }))}
                                    />
                                </div>
                            </div>
                        </div>
                    </form>
                    <h3 className='my-3' style={{ textAlign: "center" }}>{language.total_open_accounts}</h3>
                    {
                        Boolean(dashboardFiveData.currentAr) === false && dashboardFiveData.currentAr == 0 && Boolean(dashboardFiveData.pastDueAr) && (
                            <div className="form-group">
                                <input
                                    type="number"
                                    className="form-control"
                                    id=""
                                    aria-describedby=""
                                    value={dashboardFiveData.currentAr + dashboardFiveData.pastDueAr}
                                    disabled
                                />
                            </div>
                        )
                    }
                    {
                        Boolean(dashboardFiveData.pastDueAr) === false && dashboardFiveData.pastDueAr == 0 && Boolean(dashboardFiveData.currentAr) && (
                            <div className="form-group">
                                <input
                                    type="number"
                                    className="form-control"
                                    id=""
                                    aria-describedby=""
                                    value={dashboardFiveData.currentAr + dashboardFiveData.pastDueAr}
                                    disabled
                                />
                            </div>
                        )
                    }
                    {
                        Boolean(dashboardFiveData.pastDueAr) && Boolean(dashboardFiveData.currentAr) && (
                            <div className="form-group">
                                <input
                                    type="number"
                                    className="form-control"
                                    style={{ borderRadius: "30px" }}
                                    id=""
                                    aria-describedby=""
                                    value={dashboardFiveData.currentAr + dashboardFiveData.pastDueAr}
                                    disabled
                                />
                            </div>
                        )
                    }
                    {
                        Boolean(dashboardFiveData.pastDueAr) && Boolean(dashboardFiveData.currentAr) && (
                            <div className='d-flex justify-content-between'>
                                <div className='text-center'>
                                    <span style={{ fontSize: "1.2rem", fontWeight: "500" }}>{language.currentAr}</span>
                                    <h3><span class="badge" style={{ background: "black", borderRadius: "15px" }}>{dashboardFiveData.currentAr}</span></h3>
                                </div>
                                <div className='text-center'>
                                    <span style={{ fontSize: "1.2rem", fontWeight: "500" }}>{language.pastdueAr}</span>
                                    <h3><span class="badge bg-secondary" style={{ borderRadius: "15px" }}>{dashboardFiveData.pastDueAr}</span></h3>
                                </div>
                            </div>
                        )
                    }

                    {
                        (Boolean(dashboardFiveData.pastDueAr) || dashboardFiveData.pastDueAr === 0) && (Boolean(dashboardFiveData.currentAr) || dashboardFiveData.currentAr === 0) && (
                            <DashboardFivePieChart data={[dashboardFiveData.currentAr, dashboardFiveData.pastDueAr]} />
                        )
                    }
                </div>
            </div>
        </div>

    )
}

export default DashboardChartFive