import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useLanguage } from '../Providers/LanguageContext';
import TimeFrameComp from './TimeFrameComp';

function Cipbits() {

  const getTime = (val)=>{
    console.log(val)
  }


  var settings = {
    dots: false,
    infinite: false,
    autoplay: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          autoplay: true,
          dots: false
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          initialSlide: 0
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          autoplay: true,
          slidesToScroll: 1
        }
      }
    ]
  };

  const {language}=useLanguage();
  console.log(language);

  return (


    <>

      <section id='cipbit-content'>
        <div className='container'>

          
          <TimeFrameComp getTime={getTime} />

          <Slider {...settings}>

          <div className='slide-item'>
            <div className='row'>
              <div className='col-xl-6 col-lg-6 col-md-12 col-12 mb-3'>
                <div className='slide-content'>
                  <div className='content-left'>
                    <h4>{language.slaes}</h4>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        id=""
                        aria-describedby=""
                        placeholder={language.amount}
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        id=""
                        aria-describedby=""
                        placeholder={language.income_type}
                      />
                    </div>
                    <button className='post-btn'>{language.post}</button>
                  </div>
                  <div className='content-right'>
                    <button className='cash-btn mb-4'>{language.cash}</button>
                    <button className='credit-btn'>{language.credit}</button>
                  </div>
                </div>
              </div>
              <div className='col-xl-6 col-lg-6 col-md-12 col-12 mb-3'>
                <div className='slide-content'>
                  <div className='content-left'>
                    <h4>{language.bills}</h4>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        id=""
                        aria-describedby=""
                        placeholder={language.amount}
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        id=""
                        aria-describedby=""
                        placeholder={language.providers_name}
                      />
                    </div>
                    <button className='post-btn'>{language.post}</button>
                  </div>
                  <div className='content-right'>
                    <button className='cash-btn mb-4'>{language.operatives}</button>
                    <button className='credit-btn' style={{ lineHeight: "15px" }}>{language.cost_goods}</button>
                  </div>
                </div>
              </div>
            </div>

            <div className='bottom-content'>
              <div className='inner-container'>
                <div className='bottom-heading'>
                  <h2>{language.budget_track}</h2>
                </div>
                <div className='bottom-form'>
                  <form>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        id=""
                        aria-describedby=""
                        placeholder={language.budget_ammount}
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        id=""
                        aria-describedby=""
                        placeholder={language.budget_item}
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        id=""
                        aria-describedby=""
                        placeholder={language.name_of_project}
                      />
                    </div>
                  </form>
                </div>
              </div>
              <div className='bottom-btn'>
                <div className='inner-container'>
                  <button className='date-btn'>{language.start_date}</button>
                  <button className='final-btn'>{language.final_date}</button>
                  </div>
                </div>
            </div>
          </div>

          <div className='slide-item'>
            <div className='row'>
              <div className='col-xl-6 col-lg-6 col-md-12 col-12 mb-3'>
                <div className='slide-content'>
                  <div className='content-left'>
                    <h4>Ventas</h4>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        id=""
                        aria-describedby=""
                        placeholder="Cantidad"
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        id=""
                        aria-describedby=""
                        placeholder="Tipo de Ingresos"
                      />
                    </div>
                    <button className='post-btn'>Publicar</button>
                  </div>
                  <div className='content-right'>
                    <button className='cash-btn mb-4'>Efectivo</button>
                    <button className='credit-btn'>Crédito</button>
                  </div>
                </div>
              </div>
              <div className='col-xl-6 col-lg-6 col-md-12 col-12 mb-3'>
                <div className='slide-content'>
                  <div className='content-left'>
                    <h4>Gastos</h4>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        id=""
                        aria-describedby=""
                        placeholder="Cantidad"
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        id=""
                        aria-describedby=""
                        placeholder="Nombre del Proveedor"
                      />
                    </div>
                    <button className='post-btn'>Publicar</button>
                  </div>
                  <div className='content-right'>
                    <button className='cash-btn mb-4'>Operativos</button>
                    <button className='credit-btn' style={{ lineHeight: "15px" }}>Costo de los Bienes Vendidos</button>
                  </div>
                </div>
              </div>
            </div>

            <div className='bottom-content'>
              <div className='inner-container'>
                <div className='bottom-heading'>
                  <h2>Seguimiento del Presupuesto</h2>
                </div>
                <div className='bottom-form'>
                  <form>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        id=""
                        aria-describedby=""
                        placeholder="Importe Presupuestario"
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        id=""
                        aria-describedby=""
                        placeholder="Partida Presupuestaria"
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        id=""
                        aria-describedby=""
                        placeholder="Nombre del Proyecto"
                      />
                    </div>
                  </form>
                </div>
              </div>
              <div className='bottom-btn'>
                <div className='inner-container'>
                  <button className='date-btn'>Fecha de Inicio</button>
                  <button className='final-btn'>Fecha Final</button>
                  </div>
                </div>
            </div>
          </div>

          <div className='slide-item'>
            <div className='row'>
              <div className='col-xl-6 col-lg-6 col-md-12 col-12 mb-3'>
                <div className='slide-content'>
                  <div className='content-left'>
                    <h4>Ventas</h4>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        id=""
                        aria-describedby=""
                        placeholder="Cantidad"
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        id=""
                        aria-describedby=""
                        placeholder="Tipo de Ingresos"
                      />
                    </div>
                    <button className='post-btn'>Publicar</button>
                  </div>
                  <div className='content-right'>
                    <button className='cash-btn mb-4'>Efectivo</button>
                    <button className='credit-btn'>Crédito</button>
                  </div>
                </div>
              </div>
              <div className='col-xl-6 col-lg-6 col-md-12 col-12 mb-3'>
                <div className='slide-content'>
                  <div className='content-left'>
                    <h4>Gastos</h4>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        id=""
                        aria-describedby=""
                        placeholder="Cantidad"
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        id=""
                        aria-describedby=""
                        placeholder="Nombre del Proveedor"
                      />
                    </div>
                    <button className='post-btn'>Publicar</button>
                  </div>
                  <div className='content-right'>
                    <button className='cash-btn mb-4'>Operativos</button>
                    <button className='credit-btn' style={{ lineHeight: "15px" }}>Costo de los Bienes Vendidos</button>
                  </div>
                </div>
              </div>
            </div>

            <div className='bottom-content'>
              <div className='inner-container'>
                <div className='bottom-heading'>
                  <h2>Seguimiento del Presupuesto</h2>
                </div>
                <div className='bottom-form'>
                  <form>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        id=""
                        aria-describedby=""
                        placeholder="Importe Presupuestario"
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        id=""
                        aria-describedby=""
                        placeholder="Partida Presupuestaria"
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        id=""
                        aria-describedby=""
                        placeholder="Nombre del Proyecto"
                      />
                    </div>
                  </form>
                </div>
              </div>
              <div className='bottom-btn'>
                <div className='inner-container'>
                  <button className='date-btn'>Fecha de Inicio</button>
                  <button className='final-btn'>Fecha Final</button>
                  </div>
                </div>
            </div>
          </div>

          <div className='slide-item'>
            <div className='row'>
              <div className='col-xl-6 col-lg-6 col-md-12 col-12 mb-3'>
                <div className='slide-content'>
                  <div className='content-left'>
                    <h4>Ventas</h4>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        id=""
                        aria-describedby=""
                        placeholder="Cantidad"
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        id=""
                        aria-describedby=""
                        placeholder="Tipo de Ingresos"
                      />
                    </div>
                    <button className='post-btn'>Publicar</button>
                  </div>
                  <div className='content-right'>
                    <button className='cash-btn mb-4'>Efectivo</button>
                    <button className='credit-btn'>Crédito</button>
                  </div>
                </div>
              </div>
              <div className='col-xl-6 col-lg-6 col-md-12 col-12 mb-3'>
                <div className='slide-content'>
                  <div className='content-left'>
                    <h4>Gastos</h4>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        id=""
                        aria-describedby=""
                        placeholder="Cantidad"
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        id=""
                        aria-describedby=""
                        placeholder="Nombre del Proveedor"
                      />
                    </div>
                    <button className='post-btn'>Publicar</button>
                  </div>
                  <div className='content-right'>
                    <button className='cash-btn mb-4'>Operativos</button>
                    <button className='credit-btn' style={{ lineHeight: "15px" }}>Costo de los Bienes Vendidos</button>
                  </div>
                </div>
              </div>
            </div>

            <div className='bottom-content'>
              <div className='inner-container'>
                <div className='bottom-heading'>
                  <h2>Seguimiento del Presupuesto</h2>
                </div>
                <div className='bottom-form'>
                  <form>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        id=""
                        aria-describedby=""
                        placeholder="Importe Presupuestario"
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        id=""
                        aria-describedby=""
                        placeholder="Partida Presupuestaria"
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        id=""
                        aria-describedby=""
                        placeholder="Nombre del Proyecto"
                      />
                    </div>
                  </form>
                </div>
              </div>
              <div className='bottom-btn'>
                <div className='inner-container'>
                  <button className='date-btn'>Fecha de Inicio</button>
                  <button className='final-btn'>Fecha Final</button>
                  </div>
                </div>
            </div>
          </div>

          </Slider>
        </div>
      </section>

    </>


  )
}

export default Cipbits