import React, { useEffect, useMemo, useState } from "react";
import "../View/Reports/BuildReports/BuildReports.css";
import { useNavigate } from "react-router-dom";
import { useLanguage } from "../Providers/LanguageContext";
import BuildInvoice from "../View/Reports/BuildReports/BuildInvoice";
import BuildBalance from "../View/Reports/BuildReports/BuildBalance";
import BuildTrial from "../View/Reports/BuildReports/BuildTrial";
import BuildCapital from "../View/Reports/BuildReports/BuildCapital";

import "react-datetime/css/react-datetime.css";
import Datetime from "react-datetime";
import moment from "moment";
import { CSVLink } from "react-csv";
import * as XLSX from 'xlsx';
import { ThreeDots } from "react-loader-spinner";
import SendBuildModal from "../View/Reports/BuildReports/SendBuildModal";
import toast from "react-hot-toast";
import ScheduleModal from "../View/Reports/ScheduleModal";
import { useUserData } from "../Providers/UserWrapper";
import HttpClient from "../utils/HttpClient";
import BuildCashflow from "../View/Reports/BuildReports/BuildCashflow";
import BuildBudgetForecast from "../View/Reports/BuildReports/BuildBudgetForecast";
import BuildAccountsPayableAging from "../View/Reports/BuildReports/BuildAccountsPayableAging";
import BuildPersonalFinancialStatement from "../View/Reports/BuildReports/BuildPersonalFinancialStatement";
import BuildBusinessFinancialStatement from "../View/Reports/BuildReports/BuildBusinessFinancialStatement";
import Build13WeekCashFlowStatement from "../View/Reports/BuildReports/Build13WeekCashFlowStatement";
import Build12MonthCashFlowStatement from "../View/Reports/BuildReports/Build12MonthCashFlowStatement";


function BuildRepoStart() {
  const { language, currentLanguage } = useLanguage();
  const { setIsLoading } = useUserData();
  // const navigate = useNavigate();
  const [selectedData, setSelectedData] = useState("Income Statement");
  const [reportTypeName, setReportTypeName] = useState(language.income_statement_profit_los);

  const [dateData, setDateData] = useState({ startDate: "", endDate: "" });
  const [isDownload, setIsDownload] = useState(false);

  const [incomeStCsv, setIncStCsv] = useState([]);
  const [balaanceSheetCsv, setBalanceSheetCsv] = useState([]);
  const [tialBalanceCsv, setTrialBalanceCsv] = useState([]);
  const [capitalCsv, setCapitalCsv] = useState([]);
  const [cashflowCsv, setCashflowCsv] = useState([]);

  const [repCsv, setRepCsv] = useState([]);
  const [repExcel, setRepExcel] = useState([]);

  const [pdfCreate, setPdfCreate] = useState();
  const [pdfLoader, setPdfLoader] = useState(false);
  const [isSendModal, setIsSendModal] = useState(false);

  const [emailData, setEmailData] = useState({ email: "", message: "" })
  const [emailSendFunc, setEmailSendFunc] = useState(null);

  const [showSchedModul, setShowSchedModal] = useState(false);
  const [schedName, setSchedName] = useState("");
  const [sendName, setSendName] = useState("");
  const [sendForm, setSendForm] = useState({
    email: "",
    message: ""
  })



  const oncloseSendModal = () => {
    setIsSendModal(false)
  }

  const reportTypeMemo = useMemo(() => {
    switch (selectedData) {

      case "Income Statement": return "ProfitLoss"
      case "Balance Sheet": return "BalanceSheet"
      case "Trial Balance": return "TrialBalance"
      case "Cash Flow": return "CashFlow"
      case "Capitalization Table": return "CapitalizationTable"
      default: return "Select"
    }
  }, [selectedData]);



  // console.log("repExcel", repExcel)
  const handleSchedule = async () => {
    // console.log("Schedulee", selectNameMemo)
    if (!sendForm.email) return toast.error(language.email_validate_blank);

    const sendData = {
      "email": sendForm.email,
      "subject": reportTypeName,
      "scheduleType": schedName,
      "type": reportTypeMemo,
      "language": currentLanguage === "eng" ? "EN" : "ES"
    }

    // console.log("sendDataSche", sendData)
    setShowSchedModal(false);
    setIsLoading(true)
    const res = await HttpClient.requestData("scheduleEmail", "POST", sendData);
    // console.log("dailySchedule", res);
    if (res && res?.status) {
      setIsLoading(false)
      toast.success(language.report_schedule_success);
    } else {
      setIsLoading(false)
    }
  }


  const handleSelectChange = (e) => {
    let selectedData = e.target.value;
    // console.log("selectt", e.target.value);
    switch (selectedData) {
      case "Income Statement":
        setSelectedData("Income Statement");
        setReportTypeName(language.income_statement_profit_los);
        break;
      case "Balance Sheet":
        setSelectedData("Balance Sheet");
        setReportTypeName(language.balance_sheet);
        break;
      case "Trial Balance":
        setSelectedData("Trial Balance");
        setReportTypeName(language.trial_balance);
        break;
      case "Cash Flow":
        setSelectedData("Cash Flow");
        setReportTypeName(language.cash_flow);
        break;
      case "Capitalization Table":
        setSelectedData("Capitalization Table");
        setReportTypeName(language.cap_table_owner);
        break;
      case "Budget Forecast":
        setSelectedData("Budget Forecast");
        setReportTypeName(language.budget_forcast);
        break;
      case "Accounts Payable Aging Report":
        setSelectedData("Accounts Payable Aging Report");
        setReportTypeName(language.account_payable);
        break;
      case "Financial Statement":
        setSelectedData("Financial Statement");
        setReportTypeName(language.personel_financial_statement);
        break;
      case "Business Financial Statement":
        setSelectedData("Business Financial Statement");
        setReportTypeName(language.bussiness_fs);
        break;
      case "13 Week Cash Flow Statement":
        setSelectedData("13 Week Cash Flow Statement");
        setReportTypeName(language.thirteenweek_cfs);
        break;
      case "12 Month Cash Flow Statement":
        setSelectedData("12 Month Cash Flow Statement");
        setReportTypeName(language.twelveMonth_cfs);
        break;
      default:
        setSelectedData("");
        setReportTypeName("");
    }
  };

  // click on pdf  
  const handleDownPdf = () => {
    if (pdfCreate != undefined) {
      pdfCreate();
    }
  }

  // for download excel
  const arrayToExcel = (myarray, sheetName, fileName) => {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(myarray);
    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);

    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = URL.createObjectURL(data);

    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    link.click();
    URL.revokeObjectURL(url);
  }

  // clicks on xcel
  const handleExportClick = () => {
    arrayToExcel(repExcel, 'Sheet1', 'output.xlsx');
  };

  // click on send
  const handleSend = () => {
    console.log("emailData", emailData, selectedData)
    const email_regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (!email_regex.test(emailData.email)) {
      toast.error("Please Enter Valid Email");
      return
    }

    emailSendFunc(emailData, oncloseSendModal)

    // switch (selectedData) {
    //   case "Income Statement":
    //     setSelectedData("Income Statement");
    //     setReportTypeName(language.income_statement_profit_los);
    //     break;
    //   case "Balance Sheet":
    //     setSelectedData("Balance Sheet");
    //     setReportTypeName(language.balance_sheet);
    //     break;
    //   case "Trial Balance":
    //     setSelectedData("Trial Balance");
    //     setReportTypeName(language.trial_balance);
    //     break;
    //   case "Capitalization Table":
    //     setSelectedData("Capitalization Table");
    //     setReportTypeName(language.cap_table_owner);
    //     break;
    //   default:
    //     setSelectedData("");
    //     setReportTypeName("");
    // }

  }

  // sets the array for csv and excel
  useEffect(() => {
    console.log("selectedDatacsv", selectedData)
    switch (selectedData) {
      case "Income Statement": setRepCsv(incomeStCsv); setRepExcel(incomeStCsv); break;
      case "Balance Sheet": setRepCsv(balaanceSheetCsv); setRepExcel(balaanceSheetCsv); break;
      case "Trial Balance": setRepCsv(tialBalanceCsv); setRepExcel(tialBalanceCsv); break;
      case "Cash Flow": setRepCsv(cashflowCsv); setRepExcel(cashflowCsv); break;
      case "Capitalization Table": setRepCsv(capitalCsv); setRepExcel(capitalCsv); break;

      default: setRepCsv([]);
    }
  }, [selectedData, incomeStCsv, balaanceSheetCsv, tialBalanceCsv, cashflowCsv, capitalCsv])

  return (
    <div className="row align-items-center" onClick={() => setIsDownload(false)}>

      <div className="col-lg-2 col-sm-4 col-12 mb-3 mb-lg-0">
        {/* <input type="text" placeholder="Start Date" className="strtBuildInp" /> */}
        <Datetime
          // renderInput={renderInput}
          // utc={true}
          closeOnSelect
          onChange={(e) =>
            setDateData((prev) => {
              // console.log("dateTime", e);
              return {
                ...prev,
                startDate: moment.isMoment(e) ? e.format("yyyy-MM-DD") : "",
              };
            })
          }
          timeFormat={false}
          initialValue={language.start_date}
          className="strtBuildInp"
        />
      </div>

      <div className="col-lg-2 col-sm-4 col-12 mb-3 mb-lg-0">
        {/* <input type="text" placeholder="End Date" className="endBuildInp" /> */}
        <Datetime
          // renderInput={renderInput}
          // utc={true}
          closeOnSelect
          onChange={(e) =>
            setDateData((prev) => {
              return {
                ...prev,
                endDate: moment.isMoment(e) ? e.format("yyyy-MM-DD") : "",
              };
            })
          }
          timeFormat={false}
          initialValue={language.end_date}
          className="endBuildInp"
        />
      </div>

      {/* select */}
      <div className="col-lg-3 col-sm-4 col-12 mb-3 mb-lg-0">
        <select
          className="typesBuildSelect"
          onChange={(e) => handleSelectChange(e)}
        >
          <option value="Income Statement">{language.income_statement_profit_los}</option>
          <option value="Balance Sheet">{language.balance_sheet}</option>
          <option value="Trial Balance">{language.trial_balance}</option>
          <option value="Cash Flow">Cash Flow</option>
          <option value="Capitalization Table">{language.cap_table_owner}</option>
          <option value="Budget Forecast">{language.budget_forcast}</option>
          <option value="Accounts Payable Aging Report">{language.account_payable}</option>
          <option value="Financial Statement">{language.personel_financial_statement}</option>
          <option value="Business Financial Statement">{language.bussiness_fs}</option>
          <option value="13 Week Cash Flow Statement">{language.thirteenweek_cfs}</option>
          <option value="12 Month Cash Flow Statement">{language.twelveMonth_cfs}</option>
        </select>
      </div>

      {/* download  */}
      <div className="col-lg-2 col-sm-4 col-12 mb-3 mb-lg-0">

        <button
          className="dwldBuildBtn"
          onClick={(e) => {
            e.stopPropagation();
            setIsDownload(prev => !prev)
          }}
        >
          Download
        </button>

        {/* download dropdown */}
        {isDownload &&
          <div className="rep-build-down-drop-menu" onClick={(e) => e.stopPropagation()}>
            <p className="rep-build-down-drop-item">
              <CSVLink data={repCsv}>
                CSV
              </CSVLink>
            </p>
            <p className="rep-build-down-drop-item" onClick={() => handleDownPdf()}>
              {
                pdfLoader
                  ?
                  <ThreeDots
                    height="20"
                    width="50"
                    radius="5"
                    color="#0E2537"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />
                  : "PDF"
              }
            </p>
            <p className="rep-build-down-drop-item" onClick={() => handleExportClick()}>
              Excel
            </p>
          </div>
        }
      </div>

      {/* send */}
      <div className="col-lg-1 col-sm-4 col-12 mb-3 mb-lg-0">
        <button
          className="dwldBuildBtn"
          onClick={() => {
            setIsSendModal(true)
          }}
        >
          Send
        </button>
      </div>

      {/* schedule */}
      <div
        className="col-lg-2 col-sm-4 col-12 mb-3 mb-lg-0 dropdown "
      >
        <button
          className="dwldBuildBtn dropdown-toggle"
          type="button"
          data-toggle="dropdown"
          aria-expanded="false"
          disabled
        >
          Schedule
        </button>

        <div className="dropdown-menu sched-drop mt-1" style={{ top: "8px" }}>
          <a className="dropdown-item" onClick={() => {
            setSchedName("daily");
            setShowSchedModal(true)
          }}>
            {language.daily}
          </a>
          <a className="dropdown-item" onClick={() => {
            setSchedName("weekly");
            setShowSchedModal(true)
          }}>
            {language.weekly}
          </a>
          <a className="dropdown-item" onClick={() => {
            setSchedName("monthly");
            setShowSchedModal(true)
          }}>
            {language.monthly}
          </a>
        </div>

      </div>

      {
        selectedData === "Income Statement" &&
        <BuildInvoice
          setIncStCsv={setIncStCsv}
          setPdfCreate={setPdfCreate}
          setPdfLoader={setPdfLoader}
          setEmailSendFunc={setEmailSendFunc}
        />
      }

      {
        selectedData === "Balance Sheet" &&
        <BuildBalance
          setBalanceSheetCsv={setBalanceSheetCsv}
          setPdfCreate={setPdfCreate}
          setPdfLoader={setPdfLoader}
          setEmailSendFunc={setEmailSendFunc}

        />
      }

      {
        selectedData === "Trial Balance" &&
        <BuildTrial
          setTrialBalanceCsv={setTrialBalanceCsv}
          setPdfCreate={setPdfCreate}
          setPdfLoader={setPdfLoader}
          setEmailSendFunc={setEmailSendFunc}

        />
      }

      {
        selectedData === "Cash Flow" &&
        <BuildCashflow
          setCashflowCsv={setCashflowCsv}
          setPdfCreate={setPdfCreate}
          setPdfLoader={setPdfLoader}
          setEmailSendFunc={setEmailSendFunc}

        />
      }

      {
        selectedData === "Capitalization Table" &&
        <BuildCapital
          setCapitalCsv={setCapitalCsv}
          setPdfCreate={setPdfCreate}
          setPdfLoader={setPdfLoader}
          setEmailSendFunc={setEmailSendFunc}

        />
      }

      {
        selectedData === "Budget Forecast" &&
        <BuildBudgetForecast
          setCapitalCsv={setCapitalCsv}
          setPdfCreate={setPdfCreate}
          setPdfLoader={setPdfLoader}
          setEmailSendFunc={setEmailSendFunc}

        />
      }

      {
        selectedData === "Accounts Payable Aging Report" &&
        <BuildAccountsPayableAging
          setCapitalCsv={setCapitalCsv}
          setPdfCreate={setPdfCreate}
          setPdfLoader={setPdfLoader}
          setEmailSendFunc={setEmailSendFunc}

        />
      }

      {
        selectedData === "Financial Statement" &&
        <BuildPersonalFinancialStatement
          setCapitalCsv={setCapitalCsv}
          setPdfCreate={setPdfCreate}
          setPdfLoader={setPdfLoader}
          setEmailSendFunc={setEmailSendFunc}

        />
      }

      {
        selectedData === "Business Financial Statement" &&
        <BuildBusinessFinancialStatement
          setCapitalCsv={setCapitalCsv}
          setPdfCreate={setPdfCreate}
          setPdfLoader={setPdfLoader}
          setEmailSendFunc={setEmailSendFunc}

        />
      }

      {
        selectedData === "13 Week Cash Flow Statement" &&
        <Build13WeekCashFlowStatement
          setCapitalCsv={setCapitalCsv}
          setPdfCreate={setPdfCreate}
          setPdfLoader={setPdfLoader}
          setEmailSendFunc={setEmailSendFunc}

        />
      }

{
        selectedData === "12 Month Cash Flow Statement" &&
        <Build12MonthCashFlowStatement
          setCapitalCsv={setCapitalCsv}
          setPdfCreate={setPdfCreate}
          setPdfLoader={setPdfLoader}
          setEmailSendFunc={setEmailSendFunc}

        />
      }


      {/* mail send modal */}
      {
        isSendModal &&
        <SendBuildModal
          onClose={oncloseSendModal}
          reportTypeName={reportTypeName}
          emailData={emailData}
          setEmailData={setEmailData}
          handleSend={handleSend}
        />
      }

      {
        showSchedModul &&
        <ScheduleModal
          setSendName={setSendName}
          setShowSchedModal={setShowSchedModal}
          sendName={sendName}
          selectNameMemo={reportTypeName}
          handleSchedule={handleSchedule}
          setSendForm={setSendForm}
          sendForm={sendForm}
          schedName={schedName}
        />
      }
    </div >
  );
}

export default BuildRepoStart;
