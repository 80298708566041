import React from 'react'
import Nosotros1 from './Nosotros1'
import Nosotros2 from './Nosotros2'
import Nosotros3 from './Nosotros3'
import Nosotros4 from './Nosotros4'
import Gototop from "../../Component/Gototop"
export default function index() {
    return (
        <div style={{ cursor: 'default' }}>
            {/* <Helmet>
                <link rel="canonical" href="https://fisibility.com/Nosotros" />
            </Helmet> */}
            <Nosotros1 />
            <Nosotros2 />
            <Nosotros3 />
            <Nosotros4 />
            {/* <Gototop /> */}
        </div>
    )
}
