import React from 'react'
import a from "../Images/8-HowEasyToGetStarted.png"
export default function RiskFree({ goToRegistration }) {
  return (
    <div className='inner'>
      <div className="SpreadSheet">
        <div className="lftImg">
          <img src={a} alt="" />
        </div>
        <div className="rgtCnt">
          <div className="bottom_cnt">
            <div className="wrapCnt">
              <h4>How Easy Is It To Get Started?</h4>
              <span>
                Getting Started is Quick & Easy
              </span>
              <div className='mt-3'>
                <p>Sign Up</p>
                <p>Personalize the System</p>
                <p>Connect Your Calendars</p>
                <p>Post Your Financial Data</p>
              </div>
              <h5 style={{ color: '#4FACFF' }} className='my-3'>
                “What calendars can I instantly <br />connect?”
              </h5>
              <span className='d-block'>Google, Apple, Outlook</span>
              <a onClick={() => goToRegistration()} className="read-more my-3">
                Google, Apple, Outlook
              </a>


            </div>
          </div>
        </div>
      </div>
    </div>

  )
}
