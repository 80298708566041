import React from 'react'
import { useNavigate } from 'react-router-dom';
import { useLanguage } from '../../Providers/LanguageContext';
import { useUserData } from '../../Providers/UserWrapper';

const SignUpButton2 = () => {
    const navigate = useNavigate();
    const { language } = useLanguage()
    const { logout, isLogin } = useUserData();

    return (
        <div
            className="registrarAnddescargar"
            style={{
                transform: "translate(-50% , -50%)",
                top: "44%",
                left: "21%",
                flexDirection: "column",
            }}
        >

            <button
                className="registrar_Btn"
                style={{
                    background:
                        " linear-gradient(to right, #18465d 1%,#0f283b 100%,#2989d8 100%)",
                    border: "0",
                    marginBottom: "20px",
                }}
                onClick={() => navigate('/price')}
            >
                {language.homeBannerBtn1}
            </button>

            <a href='https://fisibilitylite.com/app_api/share_url.html' target="_blank">
                <button className="descargar_Btn">{language.homeBannerBtn2}</button>
            </a>
        </div>
    )
}

export default SignUpButton2