import React from 'react'
import iMg1 from "../../images/ilustraciones soluciones industriales_INMOBILIARIA 2 EN.png";
import iMg1en from "../../images/ilustraciones soluciones industriales_INMOBILIARIA 2.png";

import iMg2 from "../../images/ilustraciones soluciones industriales_ 1.png";
import iMg3 from "../../images/gobierno_GOBIERNO 3.png";
import { useLanguage } from '../../Providers/LanguageContext';
import SignUpButton from './SignUpButton';
import { useNavigate } from 'react-router-dom';
import { useUserData } from '../../Providers/UserWrapper';
const RealState = () => {
  const { language, currentLanguage } = useLanguage();
  const navigate = useNavigate();
  const { logout, isLogin } = useUserData();

  return (
    <>

      <section id='agriculture_sec'>
        <div className='container'>
          <div className='row mb-5'>
            <div className='col-md-6 col-12 m-auto'>
              <div className='agriculture_head'>
                <h3 style={{ color: "#e87f18" }}>{language.real_estate}</h3>
              </div>

              <div className='agriculture_headtext'>
                <h4 style={{ color: "#6ea01f" }}>{language.streamlined_finan_manage}</h4>
                <p>{language.fisi_realstate_proffession}

                </p>
              </div>
            </div>
            <div className='col-md-6 col-12 m-auto'>
              <div className='agriculture_img'>
                <img src={iMg2} className='img-fluid' alt='' />
              </div>
            </div>
          </div>

          <div className='row row_reverse mb-5'>
            <div className='col-md-6 col-12 m-auto'>
              <div className='agriculture_img'>
                {
                  currentLanguage === 'spanish'
                    ?
                    <img src={iMg1} className='img-fluid' alt='' />
                    :
                    <img src={iMg1en} className='img-fluid' alt='' />
                }
                <div className='registrarAnddescargar' style={{ transform: "translate(-50% , -50%)", top: "100%", marginTop: "30px" }}>

                  <button
                    className='registrar_Btn'
                    onClick={() => navigate('/price')}
                    style={{ background: "#e57d15", border: "0" }}
                  >
                    {language.homeBannerBtn1}
                  </button>

                  <a href='https://fisibilitylite.com/app_api/share_url.html' target="_blank">
                    <button className='descargar_Btn' style={{ borderColor: "#6ea01f", color: "#6ea01f" }}>{language.homeBannerBtn2}</button>
                  </a>
                </div>
                {/* <SignUpButton /> */}
              </div>
            </div>
            <div className='col-md-6 col-12 m-auto mt-5'>
              <div className='agriculture_headtext'>
                <h4 style={{ color: "#e87f18", textAlign: "right" }}>{language.seamless_colaboration}</h4>
                <p style={{ textAlign: "justify" }}>{language.fisi_makes_easy_real_state}</p>
              </div>
            </div>

          </div>

          <div className='row wrapBg_Img mt-5'>
            <div className='col-md-12 col-12 m-auto'>
              <div className='agriculture_headtext'>
                <h4 style={{ color: "#6ea01f", textAlign: "left" }}>{language.accurate_budgeting}</h4>
                <p>{language.real_estate_proffesional_fisi}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default RealState