import React, { useEffect, useState } from 'react'
import { useLanguage } from '../../../Providers/LanguageContext';
import toast from 'react-hot-toast';
import HttpClient from '../../../utils/HttpClient';
import { useUserData } from '../../../Providers/UserWrapper';
import CustomLoaderLine from '../../../Component/Loader/CustomLoaderLine';
import moment from 'moment';

const BuildBusinessFinancialStatement = ({ setCapitalCsv, setPdfCreate, setPdfLoader, setEmailSendFunc }) => {

    //    >>>>For Assets and Liabilities<<<<
    const initAsset = {
        title: "",
        price: ""
    }

    //    >>>>For Equity<<<<
    const initEquity = {
        title: "",
        amount: ""
    }

    //    >>>>For Schedule 1a. Cash in Financial Institutions<<<<
    const initFinance = {
        nameOfFinancialInstitution: "",
        accountInNameOf: "",
        typeOfAccount: "",
        accountNumber: "",
        balance: "",
    }

    // >>>>For Schedule 1b. Certificate of Deposit<<<<
    const initCertificate = {
        nameOfFinancialInstitution: "",
        accountInNameOf: "",
        ifPledgedStateToWhom: "",
        accountNumber: "",
        balance: "",
    }

    // >>>>For Schedule 2. Stocks, Bonds, & Other Marketable Assets<<<<
    const initMarketAssets = {
        faceValueBondsShares: "",
        descriptionOfSecurity: "",
        registeredInNameOf: "",
        cost: "",
        incomeReceivedLastYear: "",
        ifPledgedStateToWhom: "",
        presentMarketValue: ""
    }

    // >>>>For Schedule 3 Accounts, Loans, & Notes Payable<<<<
    const initNotesPayable = {
        nameAddressDebtor: "",
        ageOfDebt: "",
        descriptionOfDebt: "",
        descriptionOfSecurityHeld: "",
        dateOfPayment: "",
        amountOwing: "",
    }

    // >>>>For Schedule 4. Prepaid Expenses<<<<
    const initPrepaidExpenses = {
        typeOfPrepaid: "",
        toWhomPaid: "",
        expirationDate: "",
        originalAmount: "",
        currentBalance: "",
    }

    // >>>>For Schedule 5. Real Estate & Buildings<<<<
    const initRealEstateBuildings = {
        locationDescription: "",
        mortgagesLiens: "",
        dueDatesPaymentAmount: "",
        unpaidTaxes: "",
        cost: "",
        presentMarketValue: ""
    }

    // >>>>For Schedule 6 Accounts Payable<<<<
    const initAccountsPayable = {
        accountName: "",
        payableTo: "",
        whenDue: "",
        amountDue: ""
    }

    // >>>>For Schedule 7 Accrued Taxes<<<<
    const initAccruedTaxes = {
        typeOfTax: "",
        payableTo: "",
        whenDue: "",
        amountDue: ""
    }


    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>><<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<


    //   >>>>Current Assets Default Value<<<< 
    const defaultCurrentAssets = [
        {
            title: "Cash – checking accounts",
            price: ""
        },
        {
            title: "Cash – savings accounts",
            price: ""
        },
        {
            title: "Certificates of deposit",
            price: ""
        },
        {
            title: "Securities – stocks / bonds / mutual funds",
            price: ""
        },
        {
            title: "Notes & contracts receivable",
            price: ""
        },
        {
            title: "Advances to Employees",
            price: ""
        },
        {
            title: "Prepaid Expenses",
            price: ""
        },
        {
            title: "Other Current Assets",
            price: ""
        }
    ]

    // >>>>Fixed Assets Default Value<<<<
    const defaultFixedAsset = [
        {
            title: "Real-estate and buildings",
            price: ""
        },
        {
            title: "Less Accumulated Depreciation",
            price: ""
        },
        {
            title: "Furniture, Equipment, & Vehicles",
            price: ""
        },
        {
            title: "Less Accumulated Depreciation",
            price: ""
        },
        {
            title: "Other Fixed Assets",
            price: ""
        },
        {
            title: "Other Long Term Assets",
            price: ""
        }

    ]

    // >>>>Current Liability Default Value<<<<
    const defaultCurrentLiability = [
        {
            title: "Accounts Payable",
            price: ""
        },
        {
            title: "Accrued Interest on Borrowings",
            price: ""
        },
        {
            title: "Notes Payable – Current Portion",
            price: ""
        },
        {
            title: "Accrued Taxes on Real Estate",
            price: ""
        },
        {
            title: "Accrued Taxes Other",
            price: ""
        },
        {
            title: "Other Current Payables",
            price: ""
        }
    ]

    // >>>>Long-Term Liability Default Value<<<<
    const defaultLongTermLiability = [
        {
            title: "Notes Payable",
            price: ""
        },
        {
            title: "Notes Payable on Real Estate",
            price: ""
        },
        {
            title: "Other Long-Term Liabilities",
            price: ""
        }
    ]

    // >>>>Financial Institutions Default Value<<<<
    const defaultFinancialInstitutions = [
        {
            nameOfFinancialInstitution: "",
            accountInNameOf: "",
            typeOfAccount: "",
            accountNumber: "",
            balance: "",
        },
        {
            nameOfFinancialInstitution: "",
            accountInNameOf: "",
            typeOfAccount: "",
            accountNumber: "",
            balance: "",
        },
        {
            nameOfFinancialInstitution: "",
            accountInNameOf: "",
            typeOfAccount: "",
            accountNumber: "",
            balance: "",
        },
        {
            nameOfFinancialInstitution: "",
            accountInNameOf: "",
            typeOfAccount: "",
            accountNumber: "",
            balance: "",
        },
        {
            nameOfFinancialInstitution: "",
            accountInNameOf: "",
            typeOfAccount: "",
            accountNumber: "",
            balance: "",
        },
        {
            nameOfFinancialInstitution: "",
            accountInNameOf: "",
            typeOfAccount: "",
            accountNumber: "",
            balance: "",
        },
        {
            nameOfFinancialInstitution: "",
            accountInNameOf: "",
            typeOfAccount: "",
            accountNumber: "",
            balance: "",
        }
    ]

    // >>>>Certificate Deposit Default Value<<<<
    const defaultCertificateDeposit = [
        {
            nameOfFinancialInstitution: "",
            accountInNameOf: "",
            ifPledgedStateToWhom: "",
            accountNumber: "",
            balance: "",
        },
        {
            nameOfFinancialInstitution: "",
            accountInNameOf: "",
            ifPledgedStateToWhom: "",
            accountNumber: "",
            balance: "",
        },
        {
            nameOfFinancialInstitution: "",
            accountInNameOf: "",
            ifPledgedStateToWhom: "",
            accountNumber: "",
            balance: "",
        },
        {
            nameOfFinancialInstitution: "",
            accountInNameOf: "",
            ifPledgedStateToWhom: "",
            accountNumber: "",
            balance: "",
        },
        {
            nameOfFinancialInstitution: "",
            accountInNameOf: "",
            ifPledgedStateToWhom: "",
            accountNumber: "",
            balance: "",
        },
        {
            nameOfFinancialInstitution: "",
            accountInNameOf: "",
            ifPledgedStateToWhom: "",
            accountNumber: "",
            balance: "",
        },
        {
            nameOfFinancialInstitution: "",
            accountInNameOf: "",
            ifPledgedStateToWhom: "",
            accountNumber: "",
            balance: "",
        }
    ]

    // >>>>Marketable Assets Default Value<<<<
    const defaultMarketableAssets = [
        {
            faceValueBondsShares: "",
            descriptionOfSecurity: "",
            registeredInNameOf: "",
            cost: "",
            incomeReceivedLastYear: "",
            ifPledgedStateToWhom: "",
            presentMarketValue: ""
        },
        {
            faceValueBondsShares: "",
            descriptionOfSecurity: "",
            registeredInNameOf: "",
            cost: "",
            incomeReceivedLastYear: "",
            ifPledgedStateToWhom: "",
            presentMarketValue: ""
        },
        {
            faceValueBondsShares: "",
            descriptionOfSecurity: "",
            registeredInNameOf: "",
            cost: "",
            incomeReceivedLastYear: "",
            ifPledgedStateToWhom: "",
            presentMarketValue: ""
        },
        {
            faceValueBondsShares: "",
            descriptionOfSecurity: "",
            registeredInNameOf: "",
            cost: "",
            incomeReceivedLastYear: "",
            ifPledgedStateToWhom: "",
            presentMarketValue: ""
        },
        {
            faceValueBondsShares: "",
            descriptionOfSecurity: "",
            registeredInNameOf: "",
            cost: "",
            incomeReceivedLastYear: "",
            ifPledgedStateToWhom: "",
            presentMarketValue: ""
        },
        {
            faceValueBondsShares: "",
            descriptionOfSecurity: "",
            registeredInNameOf: "",
            cost: "",
            incomeReceivedLastYear: "",
            ifPledgedStateToWhom: "",
            presentMarketValue: ""
        },
        {
            faceValueBondsShares: "",
            descriptionOfSecurity: "",
            registeredInNameOf: "",
            cost: "",
            incomeReceivedLastYear: "",
            ifPledgedStateToWhom: "",
            presentMarketValue: ""
        }
    ]

    // >>>>Notes Payable Default Value<<<<
    const defaultNotesPayable = [
        {
            nameAddressDebtor: "",
            ageOfDebt: "",
            descriptionOfDebt: "",
            descriptionOfSecurityHeld: "",
            dateOfPayment: "",
            amountOwing: "",
        },
        {
            nameAddressDebtor: "",
            ageOfDebt: "",
            descriptionOfDebt: "",
            descriptionOfSecurityHeld: "",
            dateOfPayment: "",
            amountOwing: "",
        },
        {
            nameAddressDebtor: "",
            ageOfDebt: "",
            descriptionOfDebt: "",
            descriptionOfSecurityHeld: "",
            dateOfPayment: "",
            amountOwing: "",
        },
        {
            nameAddressDebtor: "",
            ageOfDebt: "",
            descriptionOfDebt: "",
            descriptionOfSecurityHeld: "",
            dateOfPayment: "",
            amountOwing: "",
        },
        {
            nameAddressDebtor: "",
            ageOfDebt: "",
            descriptionOfDebt: "",
            descriptionOfSecurityHeld: "",
            dateOfPayment: "",
            amountOwing: "",
        },
        {
            nameAddressDebtor: "",
            ageOfDebt: "",
            descriptionOfDebt: "",
            descriptionOfSecurityHeld: "",
            dateOfPayment: "",
            amountOwing: "",
        },
        {
            nameAddressDebtor: "",
            ageOfDebt: "",
            descriptionOfDebt: "",
            descriptionOfSecurityHeld: "",
            dateOfPayment: "",
            amountOwing: "",
        }
    ]

    // >>>>Prepaid Expenses Default Value<<<<
    const defaultPrepaidExpenses = [
        {
            typeOfPrepaid: "",
            toWhomPaid: "",
            expirationDate: "",
            originalAmount: "",
            currentBalance: "",
        },
        {
            typeOfPrepaid: "",
            toWhomPaid: "",
            expirationDate: "",
            originalAmount: "",
            currentBalance: "",
        },
        {
            typeOfPrepaid: "",
            toWhomPaid: "",
            expirationDate: "",
            originalAmount: "",
            currentBalance: "",
        },
        {
            typeOfPrepaid: "",
            toWhomPaid: "",
            expirationDate: "",
            originalAmount: "",
            currentBalance: "",
        },
        {
            typeOfPrepaid: "",
            toWhomPaid: "",
            expirationDate: "",
            originalAmount: "",
            currentBalance: "",
        }
    ]

    // >>>>Real Estate & Buildings Default Value<<<<
    const defaultRealEstateBuildings = [
        {
            locationDescription: "",
            mortgagesLiens: "",
            dueDatesPaymentAmount: "",
            unpaidTaxes: "",
            cost: "",
            presentMarketValue: ""
        },
        {
            locationDescription: "",
            mortgagesLiens: "",
            dueDatesPaymentAmount: "",
            unpaidTaxes: "",
            cost: "",
            presentMarketValue: ""
        },
        {
            locationDescription: "",
            mortgagesLiens: "",
            dueDatesPaymentAmount: "",
            unpaidTaxes: "",
            cost: "",
            presentMarketValue: ""
        },
        {
            locationDescription: "",
            mortgagesLiens: "",
            dueDatesPaymentAmount: "",
            unpaidTaxes: "",
            cost: "",
            presentMarketValue: ""
        },
        {
            locationDescription: "",
            mortgagesLiens: "",
            dueDatesPaymentAmount: "",
            unpaidTaxes: "",
            cost: "",
            presentMarketValue: ""
        },
        {
            locationDescription: "",
            mortgagesLiens: "",
            dueDatesPaymentAmount: "",
            unpaidTaxes: "",
            cost: "",
            presentMarketValue: ""
        },
        {
            locationDescription: "",
            mortgagesLiens: "",
            dueDatesPaymentAmount: "",
            unpaidTaxes: "",
            cost: "",
            presentMarketValue: ""
        }
    ]

    // >>>>Accounts Payable Default Value<<<<
    const defaultAccountsPayable = [
        {
            accountName: "",
            payableTo: "",
            whenDue: "",
            amountDue: ""
        },
        {
            accountName: "",
            payableTo: "",
            whenDue: "",
            amountDue: ""
        },
        {
            accountName: "",
            payableTo: "",
            whenDue: "",
            amountDue: ""
        },
        {
            accountName: "",
            payableTo: "",
            whenDue: "",
            amountDue: ""
        },
        {
            accountName: "",
            payableTo: "",
            whenDue: "",
            amountDue: ""
        },
        {
            accountName: "",
            payableTo: "",
            whenDue: "",
            amountDue: ""
        },
        {
            accountName: "",
            payableTo: "",
            whenDue: "",
            amountDue: ""
        }
    ]

    // >>>>Accrued Taxes Default Value<<<<
    const defaultAccruedTaxes = [
        {
            typeOfTax: "",
            payableTo: "",
            whenDue: "",
            amountDue: ""
        },
        {
            typeOfTax: "",
            payableTo: "",
            whenDue: "",
            amountDue: ""
        },
        {
            typeOfTax: "",
            payableTo: "",
            whenDue: "",
            amountDue: ""
        },
        {
            typeOfTax: "",
            payableTo: "",
            whenDue: "",
            amountDue: ""
        },
        {
            typeOfTax: "",
            payableTo: "",
            whenDue: "",
            amountDue: ""
        },
        {
            typeOfTax: "",
            payableTo: "",
            whenDue: "",
            amountDue: ""
        },
        {
            typeOfTax: "",
            payableTo: "",
            whenDue: "",
            amountDue: ""
        }
    ]

    // >>>>Equity Stock Holder Default Value<<<<
    const defaultEquityStockHolder = [
        {
            title: "Common Stock",
            amount: ""
        },
        {
            title: "Preferred Stock",
            amount: ""
        },
        {
            title: "Additional Paid-In Capital",
            amount: ""
        },
        {
            title: "Retained Earnings",
            amount: ""
        }
    ]

    const { language, currentLanguage } = useLanguage();
    const { profileData, setIsLoading } = useUserData();

    const [currentAssetData, setCurrentAssetData] = useState(defaultCurrentAssets);
    const [fixedAssetData, setFixedAssetData] = useState(defaultFixedAsset);
    const [currentLiabilityData, setCurrentLiabilityData] = useState(defaultCurrentLiability);
    const [longTermLiabilityData, setLongTermLiabilityData] = useState(defaultLongTermLiability)
    const [financialInstitutions, setFinancialInstitutions] = useState(defaultFinancialInstitutions);
    const [certificateDeposit, setCertificateDeposit] = useState(defaultCertificateDeposit);
    const [marketableAssets, setMarketableAssets] = useState(defaultMarketableAssets);
    const [notesPayable, setNotesPayable] = useState(defaultNotesPayable);
    const [prepaidExpenses, setPrepaidExpenses] = useState(defaultPrepaidExpenses);
    const [realEstateBuildings, setRealEstateBuildings] = useState(defaultRealEstateBuildings);
    const [accountsPayable, setAccountsPayable] = useState(defaultAccountsPayable);
    const [accruedTaxes, setAccruedTaxes] = useState(defaultAccruedTaxes);
    const [equityStockHolder, setEquityStockHolder] = useState(defaultEquityStockHolder);

    const [deleteCurrentAssetRow, setDeleteCurrentAssetRow] = useState(false);
    const [deleteFixedAssetRow, setDeleteFixedAssetRow] = useState(false);
    const [deleteCurrentLiabilityRow, setDeleteCurrentLiabilityRow] = useState(false);
    const [deleteLongTermLiabilityRow, setDeleteLongTermLiabilityRow] = useState(false);
    const [deleteFinancialInstitutionsRow, setDeleteFinancialInstitutionsRow] = useState(false);
    const [deleteCertificateDepositRow, setDeleteCertificateDepositRow] = useState(false);
    const [deleteMarketableAssetsRow, setDeleteMarketableAssetsRow] = useState(false);
    const [deleteNotesPayableRow, setDeleteNotesPayableRow] = useState(false);
    const [deletePrepaidExpensesRow, setDeletePrepaidExpensesRow] = useState(false);
    const [deleteRealEstateBuildingsRow, setDeleteRealEstateBuildingsRow] = useState(false);
    const [deleteAccountsPayableRow, setDeleteAccountsPayableRow] = useState(false);
    const [deleteAccruedTaxesRow, setDeleteAccruedTaxesRow] = useState(false);
    const [deleteEquityStockHolderRow, setDeleteEquityStockHolderRow] = useState(false);

    const [totalCurrentAssets, setTotalCurrentAssets] = useState(0);
    const [totalFixedAsset, setTotalFixedAsset] = useState(0);
    const [totalAssets, setTotalAssets] = useState(0)
    const [totalCurrentLiabiliy, setTotalCurrentLiabiliy] = useState(0);
    const [totalLongTermLiability, setTotalLongTermLiability] = useState(0)
    const [totalLiability, setTotalLiability] = useState(0)
    const [totalFinanceBalance, setTotalFinanceBalance] = useState(0);
    const [totalCertificateBalance, setTotalCertificateBalance] = useState(0);
    const [totalPresentMarketValue, setTotalPresentMarketValue] = useState(0);
    const [totalNotesPayable, setTotalNotesPayable] = useState(0);
    const [totalPreaidExp, setTotalPreaidExp] = useState(0);
    const [totalRealEstate, setTotalRealEstate] = useState(0);
    const [totalAccountPayable, setTotalAccountPayable] = useState(0);
    const [totalAccruedTax, setTotalAccruedTax] = useState(0);
    const [totalEquityAmount, setTotalEquityAmount] = useState(0);

    const [isSave, setIsSave] = useState(true);
    const [loading, setLoading] = useState(false);
    const [saveLoading, setSaveLoading] = useState(false);


    // >>>>Current Assets handlechange<<<<
    const handleChangeCurrentAsset = (e, ind) => {
        const { name, value } = e.target;
        const data = currentAssetData.map((item, i) => {
            if (i === ind) {
                return { ...item, [name]: value }
            } else {
                return item
            }
        })
        setCurrentAssetData(data);
        // clculateAllDataIncome(data)
    }

    // >>>>Fixed Assets handlechange<<<<
    const handleChangeFixedAsset = (e, ind) => {
        const { name, value } = e.target;
        const data = fixedAssetData.map((item, i) => {
            if (i === ind) {
                return { ...item, [name]: value }
            } else {
                return item
            }
        })
        setFixedAssetData(data);
        // clculateAllDataIncome(data)
    }

    //>>>>Current Liability handlechange<<<< 
    const handleChangeCurrentLiability = (e, ind) => {
        const { name, value } = e.target;
        const data = currentLiabilityData.map((item, i) => {
            if (i === ind) {
                return { ...item, [name]: value }
            } else {
                return item
            }
        })
        setCurrentLiabilityData(data);
        // clculateAllDataLia(data);
    }

    //>>>>Long-Term Liability handlechange<<<< 
    const handleChangeLongTermLiability = (e, ind) => {
        const { name, value } = e.target;
        const data = longTermLiabilityData.map((item, i) => {
            if (i === ind) {
                return { ...item, [name]: value }
            } else {
                return item
            }
        })
        setLongTermLiabilityData(data);
        // clculateAllDataLia(data);
    }

    //>>>>Financial Institutions handlechange<<<< 
    const handleChangeFinancialInstitutions = (e, ind) => {
        const { name, value } = e.target;
        const data = financialInstitutions.map((item, i) => {
            if (i === ind) {
                return { ...item, [name]: value }
            } else {
                return item
            }
        })
        setFinancialInstitutions(data);
        // clculateAllDataLia(data);
    }

    //>>>>Certificate Deposit handlechange<<<< 
    const handleChangeCertificateDeposit = (e, ind) => {
        const { name, value } = e.target;
        const data = certificateDeposit.map((item, i) => {
            if (i === ind) {
                return { ...item, [name]: value }
            } else {
                return item
            }
        })
        setCertificateDeposit(data);
        // clculateAllDataLia(data);
    }

    //>>>>Marketable Assets handlechange<<<< 
    const handleChangeMarketableAssets = (e, ind) => {
        const { name, value } = e.target;
        const data = marketableAssets.map((item, i) => {
            if (i === ind) {
                return { ...item, [name]: value }
            } else {
                return item
            }
        })
        setMarketableAssets(data);
        // clculateAllDataLia(data);
    }

    //>>>>Notes Payable handlechange<<<< 
    const handleChangeNotesPayable = (e, ind) => {
        const { name, value } = e.target;
        const data = notesPayable.map((item, i) => {
            if (i === ind) {
                return { ...item, [name]: value }
            } else {
                return item
            }
        })
        setNotesPayable(data);
        // clculateAllDataLia(data);
    }

    //>>>>Prepaid Expenses handlechange<<<< 
    const handleChangePrepaidExpenses = (e, ind) => {
        const { name, value } = e.target;
        const data = prepaidExpenses.map((item, i) => {
            if (i === ind) {
                return { ...item, [name]: value }
            } else {
                return item
            }
        })
        setPrepaidExpenses(data);
        // clculateAllDataLia(data);
    }

    //>>>>Real Estate & Buildings handlechange<<<< 
    const handleChangeRealEstateBuildings = (e, ind) => {
        const { name, value } = e.target;
        const data = realEstateBuildings.map((item, i) => {
            if (i === ind) {
                return { ...item, [name]: value }
            } else {
                return item
            }
        })
        setRealEstateBuildings(data);
        // clculateAllDataLia(data);
    }

    //>>>>Accounts Payable handlechange<<<< 
    const handleChangeAccountsPayable = (e, ind) => {
        const { name, value } = e.target;
        const data = accountsPayable.map((item, i) => {
            if (i === ind) {
                return { ...item, [name]: value }
            } else {
                return item
            }
        })
        setAccountsPayable(data);
        // clculateAllDataLia(data);
    }

    //>>>>Accrued Taxes handlechange<<<< 
    const handleChangeAccruedTaxes = (e, ind) => {
        const { name, value } = e.target;
        const data = accruedTaxes.map((item, i) => {
            if (i === ind) {
                return { ...item, [name]: value }
            } else {
                return item
            }
        })
        setAccruedTaxes(data);
        // clculateAllDataLia(data);
    }

    //>>>>Equity Stock Holder handlechange<<<< 
    const handleChangeEquity = (e, ind) => {
        const { name, value } = e.target;
        const data = equityStockHolder.map((item, i) => {
            if (i === ind) {
                return { ...item, [name]: value }
            } else {
                return item
            }
        })
        setEquityStockHolder(data);
        // clculateAllDataLia(data);
    }

    // >>>>GET Bussiness Financial Statement<<<<
    const getBussinessFinancialStatement = async () => {
        try {
            const res = await HttpClient.requestData("reportbuilder-view-budgetfianance-statement", "GET");
            if (res && res.status) {
                const data = res.data[0] || {};

                const removeId = (items) => items.map(({ _id, ...rest }) => rest);

                setCurrentAssetData(data.currentAsset.length > 0 ? removeId(data.currentAsset) : defaultCurrentAssets);
                setFixedAssetData(data.fixedAsset.length > 0 ? removeId(data.fixedAsset) : defaultFixedAsset);
                setCurrentLiabilityData(data.currentLiabilities.length > 0 ? removeId(data.currentLiabilities) : defaultCurrentLiability);
                setLongTermLiabilityData(data.longLiabilities.length > 0 ? removeId(data.longLiabilities) : defaultLongTermLiability);
                setFinancialInstitutions(data.schedule1a.length > 0 ? removeId(data.schedule1a) : defaultFinancialInstitutions);
                setCertificateDeposit(data.schedule1b.length > 0 ? removeId(data.schedule1b) : defaultCertificateDeposit);
                setMarketableAssets(data.schedule2.length > 0 ? removeId(data.schedule2) : defaultMarketableAssets);
                setNotesPayable(data.schedule3.length > 0 ? removeId(data.schedule3) : defaultNotesPayable);
                setPrepaidExpenses(data.schedule4.length > 0 ? removeId(data.schedule4) : defaultPrepaidExpenses);
                setRealEstateBuildings(data.schedule5.length > 0 ? removeId(data.schedule5) : defaultRealEstateBuildings);
                setAccountsPayable(data.schedule6.length > 0 ? removeId(data.schedule6) : defaultAccountsPayable);
                setAccruedTaxes(data.schedule7.length > 0 ? removeId(data.schedule7) : defaultAccruedTaxes);
                setEquityStockHolder(data.schedule8?.length > 0 ? removeId(data.schedule8) : defaultEquityStockHolder);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    // >>>>Calculate the total amount of current assets<<<
    useEffect(() => {
        const total = currentAssetData.reduce((acc, item) => {
            const price = parseFloat(item.price) || 0;
            return acc + price;
        }, 0);
        setTotalCurrentAssets(total);
    }, [currentAssetData]);

    // >>>>Calculate the total amount of fixed assets<<<
    useEffect(() => {
        const total = fixedAssetData.reduce((acc, item) => {
            const price = parseFloat(item.price) || 0;
            return acc + price;
        }, 0);
        setTotalFixedAsset(total);
    }, [fixedAssetData]);

    // >>>>Calculate the total amount of total assets<<<
    useEffect(() => {
        if (totalCurrentAssets !== 0 && totalFixedAsset !== 0) {
            console.log("totalFixedAssetds4", totalCurrentAssets, totalFixedAsset)
            const total = totalCurrentAssets + totalFixedAsset
            setTotalAssets(total);
        } else {
            setTotalAssets(0);
        }
    }, [totalCurrentAssets, totalFixedAsset]);


    // >>>>Calculate the total amount of current liability<<<
    useEffect(() => {
        const total = currentLiabilityData.reduce((acc, item) => {
            const price = parseFloat(item.price) || 0;
            return acc + price;
        }, 0);
        setTotalCurrentLiabiliy(total);
    }, [currentLiabilityData]);

    // >>>>Calculate the total amount of long term liability<<<
    useEffect(() => {
        const total = longTermLiabilityData.reduce((acc, item) => {
            const price = parseFloat(item.price) || 0;
            return acc + price;
        }, 0);
        setTotalLongTermLiability(total);
    }, [longTermLiabilityData]);


    // >>>>Calculate the total amount of total liability<<<
    useEffect(() => {
        if (totalCurrentLiabiliy !== 0 && totalLongTermLiability !== 0) {
            const total = totalCurrentLiabiliy + totalLongTermLiability
            setTotalLiability(total);
        } else {
            setTotalLiability(0);
        }
    }, [totalCurrentLiabiliy, totalLongTermLiability]);

    // >>>>Calculate the total balance financialInstitutions<<<<
    useEffect(() => {
        const total = financialInstitutions.reduce((acc, item) => {
            const balance = parseFloat(item.balance) || 0;
            return acc + balance;
        }, 0);
        setTotalFinanceBalance(total);
    }, [financialInstitutions]);

    // >>>>Calculate the total balance Certificate Deposit<<<<
    useEffect(() => {
        const total = certificateDeposit.reduce((acc, item) => {
            const balance = parseFloat(item.balance) || 0;
            return acc + balance;
        }, 0);
        setTotalCertificateBalance(total);
    }, [certificateDeposit]);

    // >>>>Calculate the total balance Marketable Assets<<<<
    useEffect(() => {
        const total = marketableAssets.reduce((acc, item) => {
            const balance = parseFloat(item.presentMarketValue) || 0;
            return acc + balance;
        }, 0);
        setTotalPresentMarketValue(total);
    }, [marketableAssets]);

    // >>>>Calculate the total balance Notes Payable<<<<
    useEffect(() => {
        const total = notesPayable.reduce((acc, item) => {
            const balance = parseFloat(item.amountOwing) || 0;
            return acc + balance;
        }, 0);
        setTotalNotesPayable(total);
    }, [notesPayable]);

    // >>>>Calculate the total balance Prepaid Expenses<<<<
    useEffect(() => {
        const total = prepaidExpenses.reduce((acc, item) => {
            const balance = parseFloat(item.currentBalance) || 0;
            return acc + balance;
        }, 0);
        setTotalPreaidExp(total);
    }, [prepaidExpenses]);

    // >>>>Calculate the total balance Real Estate<<<<
    useEffect(() => {
        const total = realEstateBuildings.reduce((acc, item) => {
            const balance = parseFloat(item.presentMarketValue) || 0;
            return acc + balance;
        }, 0);
        setTotalRealEstate(total);
    }, [realEstateBuildings]);

    // >>>>Calculate the total balance Account Payable <<<<
    useEffect(() => {
        const total = accountsPayable.reduce((acc, item) => {
            const balance = parseFloat(item.amountDue) || 0;
            return acc + balance;
        }, 0);
        setTotalAccountPayable(total);
    }, [accountsPayable]);

    // >>>>Calculate the total balance Accrued Taxes<<<<
    useEffect(() => {
        const total = accruedTaxes.reduce((acc, item) => {
            const balance = parseFloat(item.amountDue) || 0;
            return acc + balance;
        }, 0);
        setTotalAccruedTax(total);
    }, [accruedTaxes]);

    // >>>>Calculate the total balance Equity Amount<<<<
    useEffect(() => {
        const total = equityStockHolder.reduce((acc, item) => {
            const balance = parseFloat(item.amount) || 0;
            return acc + balance;
        }, 0);
        setTotalEquityAmount(total);
    }, [equityStockHolder]);


    // >>>>Handle Save/Submit Data<<<<
    const handleSave = async () => {
        const data = {
            currentAsset: currentAssetData?.map(item => ({
                ...item,
                price: parseFloat(item?.price)
            })),
            fixedAsset: fixedAssetData?.map(item => ({
                ...item,
                price: parseFloat(item?.price)
            })),
            currentLiabilities: currentLiabilityData?.map(item => ({
                ...item,
                price: parseFloat(item?.price)
            })),
            longLiabilities: longTermLiabilityData?.map(item => ({
                ...item,
                price: parseFloat(item?.price)
            })),
            schedule1a: financialInstitutions?.map(item => ({
                ...item,
                accountNumber: parseFloat(item?.accountNumber),
                balance: parseFloat(item?.balance)
            })),
            schedule1b: certificateDeposit?.map(item => ({
                ...item,
                accountNumber: parseFloat(item?.accountNumber),
                balance: parseFloat(item?.balance)
            })),
            schedule2: marketableAssets?.map(item => ({
                ...item,
                cost: parseFloat(item?.cost),
                incomeReceivedLastYear: parseFloat(item?.incomeReceivedLastYear),
                presentMarketValue: parseFloat(item?.presentMarketValue)
            })),
            schedule3: notesPayable?.map(item => ({
                ...item,
                ageOfDebt: parseFloat(item?.ageOfDebt),
                amountOwing: parseFloat(item?.amountOwing),
                dateOfPayment: moment(item?.dateOfPayment)?.format("YYYY-MM-DD")
            })),
            schedule4: prepaidExpenses?.map(item => ({
                ...item,
                originalAmount: parseFloat(item?.originalAmount),
                currentBalance: parseFloat(item?.currentBalance),
                expirationDate: moment(item?.expirationDate)?.format("YYYY-MM-DD")
            })),
            schedule5: realEstateBuildings?.map(item => ({
                ...item,
                unpaidTaxes: parseFloat(item?.unpaidTaxes),
                cost: parseFloat(item?.cost),
                presentMarketValue: parseFloat(item?.presentMarketValue)
            })),
            schedule6: accountsPayable?.map(item => ({
                ...item,
                amountDue: parseFloat(item?.amountDue),
                whenDue: moment(item?.whenDue)?.format("YYYY-MM-DD")
            })),
            schedule7: accruedTaxes?.map(item => ({
                ...item,
                amountDue: parseFloat(item?.amountDue),
                whenDue: moment(item?.whenDue)?.format("YYYY-MM-DD")
            })),
            schedule8: equityStockHolder?.map(item => ({
                type: item?.title,
                amount: parseFloat(item?.amount)
            }))
        };

        console.log("s654tf65", data);
        // return
        if (isSave) {
            setSaveLoading(true);
            const res = await HttpClient.requestData('reportbuilder-add-budgetfianance-statement', 'POST', data);
            if (res && res.status) {
                setSaveLoading(false);
                toast.success(language.bFS_msg)
                setIsSave(!isSave);
                getBussinessFinancialStatement();
            } else {
                setSaveLoading(false);
                toast.error(res.message || "Something went wrong...");
            }
        } else {
            setIsSave(!isSave);
        }
    };


    // >>>>Handle Build Report<<<<  reportbuilder/pdf/budgetFinancePDF
    const handleBuildRepport = async () => {
        if (isSave) {
            toast.error("Please Save First");
            return
        }
        const data = {
            currentAsset: currentAssetData?.map(item => ({
                ...item,
                price: parseFloat(item?.price)
            })),
            fixedAsset: fixedAssetData?.map(item => ({
                ...item,
                price: parseFloat(item?.price)
            })),
            currentLiabilities: currentLiabilityData?.map(item => ({
                ...item,
                price: parseFloat(item?.price)
            })),
            longLiabilities: longTermLiabilityData?.map(item => ({
                ...item,
                price: parseFloat(item?.price)
            })),
            schedule1a: financialInstitutions?.map(item => ({
                ...item,
                accountNumber: parseFloat(item?.accountNumber),
                balance: parseFloat(item?.balance)
            })),
            schedule1b: certificateDeposit?.map(item => ({
                ...item,
                accountNumber: parseFloat(item?.accountNumber),
                balance: parseFloat(item?.balance)
            })),
            schedule2: marketableAssets?.map(item => ({
                ...item,
                cost: parseFloat(item?.cost),
                incomeReceivedLastYear: parseFloat(item?.incomeReceivedLastYear),
                presentMarketValue: parseFloat(item?.presentMarketValue)
            })),
            schedule3: notesPayable?.map(item => ({
                ...item,
                ageOfDebt: parseFloat(item?.ageOfDebt),
                amountOwing: parseFloat(item?.amountOwing),
                dateOfPayment: moment(item?.dateOfPayment)?.format("YYYY-MM-DD")
            })),
            schedule4: prepaidExpenses?.map(item => ({
                ...item,
                originalAmount: parseFloat(item?.originalAmount),
                currentBalance: parseFloat(item?.currentBalance),
                expirationDate: moment(item?.expirationDate)?.format("YYYY-MM-DD")
            })),
            schedule5: realEstateBuildings?.map(item => ({
                ...item,
                unpaidTaxes: parseFloat(item?.unpaidTaxes),
                cost: parseFloat(item?.cost),
                presentMarketValue: parseFloat(item?.presentMarketValue)
            })),
            schedule6: accountsPayable?.map(item => ({
                ...item,
                amountDue: parseFloat(item?.amountDue),
                whenDue: moment(item?.whenDue)?.format("YYYY-MM-DD")
            })),
            schedule7: accruedTaxes?.map(item => ({
                ...item,
                amountDue: parseFloat(item?.amountDue),
                whenDue: moment(item?.whenDue)?.format("YYYY-MM-DD")
            })),
            schedule8: equityStockHolder?.map(item => ({
                type: item?.title,
                amount: parseFloat(item?.amount)
            }))
        };


        console.log("s654tf65", data);
        // return
        setLoading(true);
        const res = await HttpClient.requestData('reportbuilder-add-budgetfianance-statement', 'POST', data);
        if (res && res?.status) {
            setLoading(false);
            getBussinessFinancialStatement()
            toast.success(language.report_build_succ);
        } else {
            setLoading(false);
            toast.error(res?.message || "Something went wrong...");
        }

    }

    // >>>>Download PDF<<<<
    const handlePdfDownload = async () => {
        // if (isSave) {
        //     toast.error("Please Save First");
        //     return
        // }

        setPdfLoader(true);
        setIsLoading(true);
        const res = await HttpClient.requestData("reportbuilder/download/pdf/budgetFinance-Statement", "GET", {})
        setPdfLoader(false);
        setIsLoading(false);
        if (res && res?.status) {
            window.open(res?.data, "_blank")
        } else {
            toast.error(res?.message || "Something Wrong!")
        }
    }

    // >>>>Sending mail<<<<
    const sendCapatialMail = async (emailData, oncloseSendModal) => {
        if (isSave) {
            return toast.error("Please Save First");
        } else {

            const data = {
                currentAsset: currentAssetData?.map(item => ({
                    ...item,
                    price: parseFloat(item?.price)
                })),
                fixedAsset: fixedAssetData?.map(item => ({
                    ...item,
                    price: parseFloat(item?.price)
                })),
                currentLiabilities: currentLiabilityData?.map(item => ({
                    ...item,
                    price: parseFloat(item?.price)
                })),
                longLiabilities: longTermLiabilityData?.map(item => ({
                    ...item,
                    price: parseFloat(item?.price)
                })),
                schedule1a: financialInstitutions?.map(item => ({
                    ...item,
                    accountNumber: parseFloat(item?.accountNumber),
                    balance: parseFloat(item?.balance)
                })),
                schedule1b: certificateDeposit?.map(item => ({
                    ...item,
                    accountNumber: parseFloat(item?.accountNumber),
                    balance: parseFloat(item?.balance)
                })),
                schedule2: marketableAssets?.map(item => ({
                    ...item,
                    cost: parseFloat(item?.cost),
                    incomeReceivedLastYear: parseFloat(item?.incomeReceivedLastYear),
                    presentMarketValue: parseFloat(item?.presentMarketValue)
                })),
                schedule3: notesPayable?.map(item => ({
                    ...item,
                    ageOfDebt: parseFloat(item?.ageOfDebt),
                    amountOwing: parseFloat(item?.amountOwing),
                    dateOfPayment: moment(item?.dateOfPayment)?.format("YYYY-MM-DD")
                })),
                schedule4: prepaidExpenses?.map(item => ({
                    ...item,
                    originalAmount: parseFloat(item?.originalAmount),
                    currentBalance: parseFloat(item?.currentBalance),
                    expirationDate: moment(item?.expirationDate)?.format("YYYY-MM-DD")
                })),
                schedule5: realEstateBuildings?.map(item => ({
                    ...item,
                    unpaidTaxes: parseFloat(item?.unpaidTaxes),
                    cost: parseFloat(item?.cost),
                    presentMarketValue: parseFloat(item?.presentMarketValue)
                })),
                schedule6: accountsPayable?.map(item => ({
                    ...item,
                    amountDue: parseFloat(item?.amountDue),
                    whenDue: moment(item?.whenDue)?.format("YYYY-MM-DD")
                })),
                schedule7: accruedTaxes?.map(item => ({
                    ...item,
                    amountDue: parseFloat(item?.amountDue),
                    whenDue: moment(item?.whenDue)?.format("YYYY-MM-DD")
                })),
                schedule8: equityStockHolder?.map(item => ({
                    type: item?.title,
                    amount: parseFloat(item?.amount)
                }))
            };

            console.log("s654tf65", data);
            // return
            oncloseSendModal();
            setIsLoading(true);
            const res = await HttpClient.requestData("reportbuilder/pdf/budgetFinancePDF", "POST", data)
            if (res && res?.status) {
                toast?.success(language.mail_sent_success);
                setIsLoading(false);
            } else {
                setIsLoading(false);
            }
        }
    }

    // >>>>Sending Male<<<< 
    useEffect(() => {
        setEmailSendFunc(() => sendCapatialMail)

    }, [isSave])

    useEffect(() => {
        getBussinessFinancialStatement();
        setPdfCreate(() => handlePdfDownload);
    }, [])
    return (
        <div className="container">
            <div className="buildIncmStTableDiv">

                <div className="CurrentPeriod_drowp">
                    <div className="linewrap">
                        <div>
                            <h5 className="text-bold">Pallb Mondal</h5>
                            <h6 className="text-bold">02/07/2024</h6>
                        </div>
                        <select className="typesBuildSelect">
                            <option selected>-Select Company Type-</option>
                            <option>LLC</option>
                            <option>Partnership</option>
                            <option>Corporation</option>
                            <option>Other</option>
                        </select>
                    </div>
                </div>

                {/***CURRENT ASSETS table**/}
                <table className="buildIncmStTable">
                    <tbody>
                        <tr>
                            <th className="buildIncmStTh12 buildIncmStTh12First">
                                {isSave && <button
                                    className="buildAddBtn"
                                    onClick={() => {
                                        // if (validateAsset()) {
                                        setCurrentAssetData(prev => ([...prev, initAsset]));
                                        // } else {
                                        //   toast.error(language.please_enter_all);
                                        // }
                                    }}
                                >
                                    <i class="fa-solid fa-plus"></i>
                                </button>}
                                <span>{language.current_asssets}</span>
                                {isSave && <button
                                    className="delBuildRowBtn"
                                    onClick={() => setDeleteCurrentAssetRow(prev => !prev)}
                                >
                                    {deleteCurrentAssetRow ? language.remove_delete : language.delete_row}
                                </button>}
                            </th>
                            <th className="buildIncmStTh12">
                                <span>{language.amount_dollars}</span>
                            </th>
                        </tr>


                        {
                            currentAssetData.map((item, i, arr) => {
                                return (
                                    <tr key={i}>
                                        <td className="buildBalLeftTdInp">

                                            {/* delete button */}
                                            {
                                                // deleteCurrentAssetRow && isSave &&
                                                deleteCurrentAssetRow &&
                                                arr.length !== 1 &&
                                                <button
                                                    className="buildRemoveBtn"
                                                    onClick={() => {
                                                        // const flltData = assetData?.filter((ele, ind) => i !== ind);
                                                        // clculateAllDataIncome(flltData);
                                                        setCurrentAssetData(prev => prev.filter((ele, ind) => i !== ind));
                                                    }}
                                                >
                                                    <i class="fa-solid fa-minus"></i>
                                                </button>
                                            }
                                            <input
                                                type="text"
                                                name="title"
                                                value={item.title}
                                                onChange={(e) => handleChangeCurrentAsset(e, i)}
                                            />

                                        </td>
                                        <td className="buildBalRightTdInp">
                                            <input
                                                type="number"
                                                name="price"
                                                value={item.price}
                                                onChange={(e) => handleChangeCurrentAsset(e, i)}
                                                placeholder="$00"
                                            // readOnly={isSave ? false : true}
                                            />
                                        </td>
                                    </tr>
                                )
                            })
                        }

                        <tr>
                            <td />
                            <td colSpan={2}>
                                <div className="buildTotDivider mt-2" />
                            </td>
                        </tr>

                        <tr>
                            <td className="buildIncmStTd">
                                <span className="buildTotSpan">{language.total_current_asset}</span>
                            </td>
                            <td className="buildBalRightTdInp">
                                <input
                                    type="number"
                                    readOnly
                                    value={totalCurrentAssets}
                                    placeholder="$0.00"
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>

                {/***CURRENT LIABILITIES table**/}
                <table className="buildIncmStTable mt-4">
                    <tbody>
                        <tr>
                            <th className="buildIncmStTh12 buildIncmStTh12First">
                                {isSave && <button
                                    className="buildAddBtn"
                                    onClick={() => {
                                        // if (validateLiability()) {
                                        setCurrentLiabilityData(prev => ([...prev, initAsset]));
                                        // } else {
                                        //   toast.error(language.please_enter_all);
                                        // }
                                    }}
                                >
                                    <i class="fa-solid fa-plus"></i>
                                </button>}
                                <span>{language.current_liability}</span>
                                {isSave && <button
                                    className="delBuildRowBtn"
                                    onClick={() => setDeleteCurrentLiabilityRow(prev => !prev)}
                                >
                                    {deleteCurrentLiabilityRow ? language.remove_delete : language.delete_row}

                                </button>}
                            </th>
                            <th className="buildIncmStTh12">
                                <span>{language.amount_dollars}</span>
                            </th>
                        </tr>
                        {
                            currentLiabilityData.map((item, i, arr) => {
                                return (
                                    <tr key={i}>
                                        <td className="buildBalLeftTdInp">

                                            {/* delete button */}
                                            {
                                                deleteCurrentLiabilityRow &&
                                                arr.length !== 1 &&
                                                <button
                                                    className="buildRemoveBtn"
                                                    onClick={() => {
                                                        // const fltData = liabilityData?.filter((ele, ind) => i !== ind);
                                                        // clculateAllDataLia(fltData);
                                                        setCurrentLiabilityData(prev => prev.filter((ele, ind) => i !== ind));
                                                    }}
                                                >
                                                    <i class="fa-solid fa-minus"></i>
                                                </button>
                                            }
                                            <input
                                                type="text"
                                                name="title"
                                                value={item.title}
                                                onChange={(e) => handleChangeCurrentLiability(e, i)}
                                            />

                                        </td>
                                        <td className="buildBalRightTdInp">
                                            <input
                                                type="number"
                                                name="price"
                                                value={item.price}
                                                onChange={(e) => handleChangeCurrentLiability(e, i)}
                                                placeholder='$00'
                                            // readOnly={isSave ? false : true}
                                            />
                                        </td>
                                    </tr>
                                )
                            })
                        }

                        <tr>
                            <td />
                            <td colSpan={2}>
                                <div className="buildTotDivider mt-2" />
                            </td>
                        </tr>

                        <tr>
                            <td className="buildIncmStTd">
                                <span className="buildTotSpan">{language.total_current_liability}</span>
                            </td>
                            <td className="buildBalRightTdInp">
                                <input
                                    type="number"
                                    readOnly
                                    value={totalCurrentLiabiliy}
                                    placeholder="$0.00"
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>

                {/***FIXED ASSETS table**/}
                <table className="buildIncmStTable mt-4">
                    <tbody>
                        <tr>
                            <th className="buildIncmStTh12 buildIncmStTh12First">
                                {isSave && <button
                                    className="buildAddBtn"
                                    onClick={() => {
                                        // if (validateLiability()) {
                                        setFixedAssetData(prev => ([...prev, initAsset]));
                                        // } else {
                                        //   toast.error(language.please_enter_all);
                                        // }
                                    }}
                                >
                                    <i class="fa-solid fa-plus"></i>
                                </button>}
                                <span>{language.fixed_assets}</span>
                                {isSave && <button
                                    className="delBuildRowBtn"
                                    onClick={() => setDeleteFixedAssetRow(prev => !prev)}
                                >
                                    {deleteFixedAssetRow ? language.remove_delete : language.delete_row}

                                </button>}
                            </th>
                            <th className="buildIncmStTh12">
                                <span>{language.amount_dollars}</span>
                            </th>
                        </tr>

                        {
                            fixedAssetData.map((item, i, arr) => {
                                return (
                                    <tr key={i}>
                                        <td className="buildBalLeftTdInp">

                                            {/* delete button */}
                                            {
                                                deleteFixedAssetRow &&
                                                arr.length !== 1 &&
                                                <button
                                                    className="buildRemoveBtn"
                                                    onClick={() => {
                                                        // const fltData = liabilityData?.filter((ele, ind) => i !== ind);
                                                        // clculateAllDataLia(fltData);
                                                        setFixedAssetData(prev => prev.filter((ele, ind) => i !== ind));
                                                    }}
                                                >
                                                    <i class="fa-solid fa-minus"></i>
                                                </button>
                                            }
                                            <input
                                                type="text"
                                                name="title"
                                                value={item.title}
                                                onChange={(e) => handleChangeFixedAsset(e, i)}
                                            />

                                        </td>
                                        <td className="buildBalRightTdInp">
                                            <input
                                                type="number"
                                                name="price"
                                                value={item.price}
                                                onChange={(e) => handleChangeFixedAsset(e, i)}
                                                placeholder='$00'
                                            // readOnly={isSave ? false : true}
                                            />
                                        </td>
                                    </tr>
                                )
                            })
                        }


                        <tr>
                            <td />
                            <td colSpan={2}>
                                <div className="buildTotDivider mt-2" />
                            </td>
                        </tr>
                        <tr>
                            <td className="buildIncmStTd">
                                <span className="buildTotSpan" style={{ opacity: "0.60" }}>{language.total_fixet_asset}</span>
                            </td>
                            <td className="buildBalRightTdInp">
                                <input
                                    type="number"
                                    readOnly
                                    value={totalFixedAsset}
                                    placeholder="$0.00"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="buildIncmStTd">
                                <span className="buildTotSpan">{language.total_assets}</span>
                            </td>
                            <td className="buildBalRightTdInp">
                                <input
                                    type="number"
                                    readOnly
                                    value={totalAssets}
                                    placeholder="$0.00"
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>

                {/***LONG-TERM LIABILITIES table**/}
                <table className="buildIncmStTable mt-4">
                    <tbody>
                        <tr>
                            <th className="buildIncmStTh12 buildIncmStTh12First">
                                {isSave && <button
                                    className="buildAddBtn"
                                    onClick={() => {
                                        // if (validateLiability()) {
                                        setLongTermLiabilityData(prev => ([...prev, initAsset]));
                                        // } else {
                                        //   toast.error(language.please_enter_all);
                                        // }
                                    }}
                                >
                                    <i class="fa-solid fa-plus"></i>
                                </button>}
                                <span>{language.long_term_liability}</span>
                                {isSave && <button
                                    className="delBuildRowBtn"
                                    onClick={() => setDeleteLongTermLiabilityRow(prev => !prev)}
                                >
                                    {deleteLongTermLiabilityRow ? language.remove_delete : language.delete_row}

                                </button>}
                            </th>
                            <th className="buildIncmStTh12">
                                <span>{language.amount_dollars}</span>
                            </th>
                        </tr>

                        {
                            longTermLiabilityData.map((item, i, arr) => {
                                return (
                                    <tr key={i}>
                                        <td className="buildBalLeftTdInp">

                                            {/* delete button */}
                                            {
                                                deleteLongTermLiabilityRow &&
                                                arr.length !== 1 &&
                                                <button
                                                    className="buildRemoveBtn"
                                                    onClick={() => {
                                                        // const fltData = liabilityData?.filter((ele, ind) => i !== ind);
                                                        // clculateAllDataLia(fltData);
                                                        setLongTermLiabilityData(prev => prev.filter((ele, ind) => i !== ind));
                                                    }}
                                                >
                                                    <i class="fa-solid fa-minus"></i>
                                                </button>
                                            }
                                            <input
                                                type="text"
                                                name="title"
                                                value={item.title}
                                                onChange={(e) => handleChangeLongTermLiability(e, i)}
                                            />

                                        </td>
                                        <td className="buildBalRightTdInp">
                                            <input
                                                type="number"
                                                name="price"
                                                value={item.price}
                                                onChange={(e) => handleChangeLongTermLiability(e, i)}
                                                placeholder='$00'
                                            // readOnly={isSave ? false : true}
                                            />
                                        </td>
                                    </tr>
                                )
                            })
                        }

                        <tr>
                            <td />
                            <td colSpan={2}>
                                <div className="buildTotDivider mt-2" />
                            </td>
                        </tr>
                        <tr>
                            <td className="buildIncmStTd">
                                <span className="buildTotSpan" style={{ opacity: "0.60" }}>{language.total_long_term_lia}</span>
                            </td>
                            <td className="buildBalRightTdInp">
                                <input
                                    type="number"
                                    readOnly
                                    value={totalLongTermLiability}
                                    placeholder="$0.00"
                                />
                            </td>
                        </tr>
                        {/* <tr>
                            <td className="buildIncmStTd">
                                <span className="buildTotSpan" style={{ opacity: "0.60" }}>NET WORTH OR STOCKHOLDERS EQUITY</span>
                            </td>
                            <td className="buildIncmStTd">
                                <input
                                    type="number"
                                    readOnly=""
                                    placeholder="$0.00"
                                    defaultValue={0}
                                />
                            </td>
                        </tr> */}
                        <tr>
                            <td className="buildIncmStTd">
                                <span className="buildTotSpan">{language.total_liability_net_worth}</span>
                            </td>
                            <td className="buildBalRightTdInp">
                                <input
                                    type="number"
                                    readOnly
                                    value={totalLiability}
                                    placeholder="$0.00"
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>


                <h5 className="mb-1 mt-3 font-weight-bold">{language.SCHEDULES}</h5>
                {/***Schedule 1a. table**/}
                <p className='mb-1' style={{ fontSize: "15px" }}>{language.schedule_1a}</p>
                <table className='buildIncmStTable'>
                    <tr>
                        <th className="buildIncmStTh12 buildIncmStTh12First">
                            {isSave && <button
                                className="buildAddBtn"
                                onClick={() => {
                                    // if (validateLiability()) {
                                    setFinancialInstitutions(prev => ([...prev, initFinance]));
                                    // } else {
                                    //   toast.error(language.please_enter_all);
                                    // }
                                }}
                            >
                                <i class="fa-solid fa-plus"></i>
                            </button>}
                            <span>{language.name_of_financial}<br /> {language.institution_address}</span>
                            {isSave && <button
                                className="delBuildRowBtn"
                                onClick={() => setDeleteFinancialInstitutionsRow(prev => !prev)}
                            >
                                {deleteFinancialInstitutionsRow ? language.remove_delete : language.delete_row}

                            </button>}
                        </th>
                        <th className='text-center'>{language.acc_name_of}</th>
                        <th className='text-center'>{language.type_of_acc}</th>
                        <th className='text-center'>{`${language.account} #`}</th>
                        <th className='text-center'>{language.balance}</th>
                    </tr>

                    {
                        financialInstitutions?.map((item, i, arr) => {
                            return (
                                <tr>
                                    <td className="buildIncmStTd">
                                        {
                                            deleteFinancialInstitutionsRow &&
                                            arr.length !== 1 &&
                                            <button
                                                className="buildRemoveBtn"
                                                onClick={() => {
                                                    // const fltData = liabilityData?.filter((ele, ind) => i !== ind);
                                                    // clculateAllDataLia(fltData);
                                                    setFinancialInstitutions(prev => prev.filter((ele, ind) => i !== ind));
                                                }}
                                            >
                                                <i class="fa-solid fa-minus"></i>
                                            </button>
                                        }
                                        <input
                                            type="text"
                                            className="userBuildInp"
                                            name="nameOfFinancialInstitution"
                                            value={item.nameOfFinancialInstitution}
                                            onChange={(e) => handleChangeFinancialInstitutions(e, i)}
                                        />
                                    </td>
                                    <td className="buildIncmStTd ">
                                        <input
                                            type="text"
                                            className="userBuildInp mx-2"
                                            name="accountInNameOf"
                                            value={item.accountInNameOf}
                                            onChange={(e) => handleChangeFinancialInstitutions(e, i)}

                                        />
                                    </td>
                                    <td className="buildIncmStTd">
                                        <input
                                            type="text"
                                            className="userBuildInp"
                                            name="typeOfAccount"
                                            value={item.typeOfAccount}
                                            onChange={(e) => handleChangeFinancialInstitutions(e, i)}
                                        />
                                    </td>
                                    <td className="buildIncmStTd">
                                        <input
                                            type="number"
                                            className="userBuildInp"
                                            name="accountNumber"
                                            value={item.accountNumber}
                                            onChange={(e) => handleChangeFinancialInstitutions(e, i)}
                                            placeholder="$00"
                                        />
                                    </td>
                                    <td className="buildIncmStTd">
                                        <input
                                            type="number"
                                            className="userBuildInp"
                                            name="balance"
                                            value={item.balance}
                                            onChange={(e) => handleChangeFinancialInstitutions(e, i)}
                                            placeholder="$00"
                                        />
                                    </td>
                                </tr>
                            )
                        })

                    }

                    <tr>
                        <td colSpan={5}>
                            <div className="buildTotDivider" />
                        </td>
                    </tr>

                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td className="buildIncmStTd">
                            <span className="buildTotSpan">{language.total}</span>
                        </td>
                        <td className="buildIncmStTd">
                            <input
                                type="number"
                                readOnly
                                value={totalFinanceBalance}
                                placeholder="$0.00"
                            />
                        </td>
                    </tr>
                </table>

                {/***Schedule 1b. table**/}
                <p className='mb-1' style={{ fontSize: "15px" }}>{language.schedule_1b}</p>
                <table className='buildIncmStTable'>
                    <tr>
                        <th className="buildIncmStTh12 buildIncmStTh12First">
                            {isSave && <button
                                className="buildAddBtn"
                                onClick={() => {
                                    // if (validateLiability()) {
                                    setCertificateDeposit(prev => ([...prev, initCertificate]));
                                    // } else {
                                    //   toast.error(language.please_enter_all);
                                    // }
                                }}
                            >
                                <i class="fa-solid fa-plus"></i>
                            </button>}
                            <span>{language.name_of_financial} <br /> {language.institution_address}</span>
                            {isSave && <button
                                className="delBuildRowBtn"
                                onClick={() => setDeleteCertificateDepositRow(prev => !prev)}
                            >
                                {deleteCertificateDepositRow ? language.remove_delete : language.delete_row}

                            </button>}
                        </th>
                        <th className='text-center'>{language.acc_name_of}</th>
                        <th className='text-center'>{language.if_pledged}</th>
                        <th className='text-center'>{`${language.account} #`}</th>
                        <th className='text-center'>{language.balance}</th>
                    </tr>

                    {
                        certificateDeposit?.map((item, i, arr) => {
                            return (
                                <tr>
                                    <td className="buildIncmStTd">
                                        {
                                            deleteCertificateDepositRow &&
                                            arr.length !== 1 &&
                                            <button
                                                className="buildRemoveBtn"
                                                onClick={() => {
                                                    // const fltData = liabilityData?.filter((ele, ind) => i !== ind);
                                                    // clculateAllDataLia(fltData);
                                                    setCertificateDeposit(prev => prev.filter((ele, ind) => i !== ind));
                                                }}
                                            >
                                                <i class="fa-solid fa-minus"></i>
                                            </button>
                                        }
                                        <input
                                            type="text"
                                            className="userBuildInp"
                                            name="nameOfFinancialInstitution"
                                            value={item.nameOfFinancialInstitution}
                                            onChange={(e) => handleChangeCertificateDeposit(e, i)}
                                        />
                                    </td>
                                    <td className="buildIncmStTd">
                                        <input
                                            type="text"
                                            className="userBuildInp"
                                            name="accountInNameOf"
                                            value={item.accountInNameOf}
                                            onChange={(e) => handleChangeCertificateDeposit(e, i)}
                                        />
                                    </td>
                                    <td className="buildIncmStTd">
                                        <input
                                            type="text"
                                            className="userBuildInp"
                                            name="ifPledgedStateToWhom"
                                            value={item.ifPledgedStateToWhom}
                                            onChange={(e) => handleChangeCertificateDeposit(e, i)}
                                        />
                                    </td>
                                    <td className="buildIncmStTd">
                                        <input
                                            type="number"
                                            className="userBuildInp"
                                            name="accountNumber"
                                            value={item.accountNumber}
                                            onChange={(e) => handleChangeCertificateDeposit(e, i)}
                                            placeholder="$00"
                                        />
                                    </td>
                                    <td className="buildIncmStTd">
                                        <input
                                            type="number"
                                            className="userBuildInp"
                                            name="balance"
                                            value={item.balance}
                                            onChange={(e) => handleChangeCertificateDeposit(e, i)}
                                            placeholder="$00"
                                        />
                                    </td>
                                </tr>
                            )
                        })
                    }

                    <tr>
                        <td colSpan={5}>
                            <div className="buildTotDivider" />
                        </td>
                    </tr>

                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td className="buildIncmStTd">
                            <span className="buildTotSpan">{language.total}</span>
                        </td>
                        <td className="buildIncmStTd">
                            <input
                                type="number"
                                readOnly
                                placeholder="$0.00"
                                value={totalCertificateBalance}
                            />
                        </td>
                    </tr>
                </table>

                {/***Schedule 2. table**/}
                <p className='mb-1' style={{ fontSize: "15px" }}>{language.schedule2}</p>
                <table className='buildIncmStTable'>
                    <tr>
                        <th className="buildIncmStTh12 buildIncmStTh12First">
                            {isSave && <button
                                className="buildAddBtn"
                                onClick={() => {
                                    // if (validateLiability()) {
                                    setMarketableAssets(prev => ([...prev, initMarketAssets]));
                                    // } else {
                                    //   toast.error(language.please_enter_all);
                                    // }
                                }}
                            >
                                <i class="fa-solid fa-plus"></i>
                            </button>}
                            <span>{`${language.face_value},`}<br /> {`# ${language.of_shares}`}</span>
                            {isSave && <button
                                className="delBuildRowBtn"
                                onClick={() => setDeleteMarketableAssetsRow(prev => !prev)}
                            >
                                {deleteMarketableAssetsRow ? language.remove_delete : language.delete_row}

                            </button>}
                        </th>
                        <th className='text-center'>{language.des_security}</th>
                        <th className='text-center'>{language.regs_name_of}</th>
                        <th className='text-center'>{language.cost}</th>
                        <th className='text-center'>{language.income_received_lastyear}</th>
                        <th className='text-center'>{language.if_pledged}</th>
                        <th className='text-center'>{language.present_market_value}</th>
                    </tr>

                    {
                        marketableAssets?.map((item, i, arr) => {
                            return (
                                <tr>
                                    <td className="buildIncmStTd">
                                        {
                                            deleteMarketableAssetsRow &&
                                            arr.length !== 1 &&
                                            <button
                                                className="buildRemoveBtn"
                                                onClick={() => {
                                                    // const fltData = liabilityData?.filter((ele, ind) => i !== ind);
                                                    // clculateAllDataLia(fltData);
                                                    setMarketableAssets(prev => prev.filter((ele, ind) => i !== ind));
                                                }}
                                            >
                                                <i class="fa-solid fa-minus"></i>
                                            </button>
                                        }
                                        <input
                                            type="text"
                                            className="userBuildInp"
                                            name="faceValueBondsShares"
                                            value={item.faceValueBondsShares}
                                            onChange={(e) => handleChangeMarketableAssets(e, i)}
                                        />
                                    </td>
                                    <td className="buildIncmStTd">
                                        <input
                                            type="text"
                                            className="userBuildInp"
                                            name="descriptionOfSecurity"
                                            value={item.descriptionOfSecurity}
                                            onChange={(e) => handleChangeMarketableAssets(e, i)}
                                        />
                                    </td>
                                    <td className="buildIncmStTd">
                                        <input
                                            type="text"
                                            className="userBuildInp"
                                            name="registeredInNameOf"
                                            value={item.registeredInNameOf}
                                            onChange={(e) => handleChangeMarketableAssets(e, i)}
                                        />
                                    </td>
                                    <td className="buildIncmStTd">
                                        <input
                                            type="number"
                                            className="userBuildInp"
                                            name="cost"
                                            value={item.cost}
                                            onChange={(e) => handleChangeMarketableAssets(e, i)}
                                            placeholder='$00'
                                        />
                                    </td>
                                    <td className="buildIncmStTd">
                                        <input
                                            type="number"
                                            className="userBuildInp"
                                            name="incomeReceivedLastYear"
                                            value={item.incomeReceivedLastYear}
                                            onChange={(e) => handleChangeMarketableAssets(e, i)}
                                            placeholder='$00'
                                        />
                                    </td>
                                    <td className="buildIncmStTd">
                                        <input
                                            type="text"
                                            className="userBuildInp"
                                            name="ifPledgedStateToWhom"
                                            value={item.ifPledgedStateToWhom}
                                            onChange={(e) => handleChangeMarketableAssets(e, i)}
                                        />
                                    </td>
                                    <td className="buildIncmStTd">
                                        <input
                                            type="number"
                                            className="userBuildInp"
                                            name="presentMarketValue"
                                            value={item.presentMarketValue}
                                            onChange={(e) => handleChangeMarketableAssets(e, i)}
                                            placeholder='$00'
                                        />
                                    </td>
                                </tr>
                            )
                        })
                    }

                    <tr>
                        <td colSpan={7}>
                            <div className="buildTotDivider" />
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td className="buildIncmStTd">
                            <span className="buildTotSpan">{language.total}</span>
                        </td>
                        <td className="buildIncmStTd">
                            <input
                                type="number"
                                readOnly
                                value={totalPresentMarketValue}
                                placeholder="$0.00"
                            />
                        </td>
                    </tr>
                </table>

                {/***Schedule 3. table**/}
                <p className='mb-1' style={{ fontSize: "15px" }}>{language.schedule3}</p>
                <table className='buildIncmStTable'>
                    <tr>
                        <th className="buildIncmStTh12 buildIncmStTh12First">
                            {isSave && <button
                                className="buildAddBtn"
                                onClick={() => {
                                    // if (validateLiability()) {
                                    setNotesPayable(prev => ([...prev, initNotesPayable]));
                                    // } else {
                                    //   toast.error(language.please_enter_all);
                                    // }
                                }}
                            >
                                <i class="fa-solid fa-plus"></i>
                            </button>}
                            <span>{language.name_address}<br /> {language.of_debtor}</span>
                            {isSave && <button
                                className="delBuildRowBtn"
                                onClick={() => setDeleteNotesPayableRow(prev => !prev)}
                            >
                                {deleteNotesPayableRow ? language.remove_delete : language.delete_row}

                            </button>}
                        </th>
                        <th className='text-center'>{language.age_debt}</th>
                        <th className='text-center'>{language.des_debt}</th>
                        <th className='text-center'>{language.des_sec_held}</th>
                        <th className='text-center'>{language.date_payment}</th>
                        <th className='text-center'>{language.amt_owning}</th>
                    </tr>

                    {
                        notesPayable?.map((item, i, arr) => {
                            return (
                                <tr>
                                    <td className="buildIncmStTd">
                                        {
                                            deleteNotesPayableRow &&
                                            arr.length !== 1 &&
                                            <button
                                                className="buildRemoveBtn"
                                                onClick={() => {
                                                    // const fltData = liabilityData?.filter((ele, ind) => i !== ind);
                                                    // clculateAllDataLia(fltData);
                                                    setNotesPayable(prev => prev.filter((ele, ind) => i !== ind));
                                                }}
                                            >
                                                <i class="fa-solid fa-minus"></i>
                                            </button>
                                        }
                                        <input
                                            type="text"
                                            className="userBuildInp"
                                            name="nameAddressDebtor"
                                            value={item.nameAddressDebtor}
                                            onChange={(e) => handleChangeNotesPayable(e, i)}
                                        />
                                    </td>
                                    <td className="buildIncmStTd">
                                        <input
                                            type="number"
                                            className="userBuildInp"
                                            name="ageOfDebt"
                                            value={item.ageOfDebt}
                                            onChange={(e) => handleChangeNotesPayable(e, i)}
                                            placeholder='$00'
                                        />
                                    </td>
                                    <td className="buildIncmStTd">
                                        <input
                                            type="text"
                                            className="userBuildInp"
                                            name="descriptionOfDebt"
                                            value={item.descriptionOfDebt}
                                            onChange={(e) => handleChangeNotesPayable(e, i)}
                                        />
                                    </td>
                                    <td className="buildIncmStTd">
                                        <input
                                            type="text"
                                            className="userBuildInp"
                                            name="descriptionOfSecurityHeld"
                                            value={item.descriptionOfSecurityHeld}
                                            onChange={(e) => handleChangeNotesPayable(e, i)}
                                        />
                                    </td>
                                    <td className="buildIncmStTd">
                                        <input
                                            type="date"
                                            className="userBuildInp"
                                            name="dateOfPayment"
                                            value={item.dateOfPayment}
                                            onChange={(e) => handleChangeNotesPayable(e, i)}
                                        />
                                    </td>
                                    <td className="buildIncmStTd">
                                        <input
                                            type="number"
                                            className="userBuildInp"
                                            name="amountOwing"
                                            value={item.amountOwing}
                                            onChange={(e) => handleChangeNotesPayable(e, i)}
                                            placeholder='$00'
                                        />
                                    </td>
                                </tr>
                            )
                        })
                    }


                    <tr>
                        <td colSpan={6}>
                            <div className="buildTotDivider" />
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td className="buildIncmStTd">
                            <span className="buildTotSpan">{language.total}</span>
                        </td>
                        <td className="buildIncmStTd">
                            <input
                                type="number"
                                readOnly
                                value={totalNotesPayable}
                                placeholder="$0.00"
                            />
                        </td>
                    </tr>
                </table>

                {/***Schedule 4. table**/}
                <p className='mb-1' style={{ fontSize: "15px" }}>{language.schedule4}</p>
                <table className='buildIncmStTable'>
                    <tr>
                        <th className="buildIncmStTh12 buildIncmStTh12First">
                            {isSave && <button
                                className="buildAddBtn"
                                onClick={() => {
                                    // if (validateLiability()) {
                                    setPrepaidExpenses(prev => ([...prev, initPrepaidExpenses]));
                                    // } else {
                                    //   toast.error(language.please_enter_all);
                                    // }
                                }}
                            >
                                <i class="fa-solid fa-plus"></i>
                            </button>}
                            <span>{language.type_of_prepaid} </span>
                            {isSave && <button
                                className="delBuildRowBtn"
                                onClick={() => setDeletePrepaidExpensesRow(prev => !prev)}
                            >
                                {deletePrepaidExpensesRow ? language.remove_delete : language.delete_row}

                            </button>}
                        </th>
                        <th className='text-center'>{language.to_whom_paid}</th>
                        <th className='text-center'>{language.exp_date}</th>
                        <th className='text-center'>{language.original_amt}</th>
                        <th className='text-center'>{language.curr_balance}</th>
                    </tr>

                    {
                        prepaidExpenses?.map((item, i, arr) => {
                            return (
                                <tr>
                                    <td className="buildIncmStTd">
                                        {
                                            deletePrepaidExpensesRow &&
                                            arr.length !== 1 &&
                                            <button
                                                className="buildRemoveBtn"
                                                onClick={() => {
                                                    // const fltData = liabilityData?.filter((ele, ind) => i !== ind);
                                                    // clculateAllDataLia(fltData);
                                                    setPrepaidExpenses(prev => prev.filter((ele, ind) => i !== ind));
                                                }}
                                            >
                                                <i class="fa-solid fa-minus"></i>
                                            </button>
                                        }
                                        <input
                                            type="text"
                                            className="userBuildInp"
                                            name="typeOfPrepaid"
                                            value={item.typeOfPrepaid}
                                            onChange={(e) => handleChangePrepaidExpenses(e, i)}
                                        />
                                    </td>
                                    <td className="buildIncmStTd">
                                        <input
                                            type="text"
                                            className="userBuildInp"
                                            name="toWhomPaid"
                                            value={item.toWhomPaid}
                                            onChange={(e) => handleChangePrepaidExpenses(e, i)}
                                        />
                                    </td>
                                    <td className="buildIncmStTd">
                                        <input
                                            type="date"
                                            className="userBuildInp"
                                            name="expirationDate"
                                            value={item.expirationDate}
                                            onChange={(e) => handleChangePrepaidExpenses(e, i)}
                                        />
                                    </td>
                                    <td className="buildIncmStTd">
                                        <input
                                            type="number"
                                            className="userBuildInp"
                                            name="originalAmount"
                                            value={item.originalAmount}
                                            onChange={(e) => handleChangePrepaidExpenses(e, i)}
                                            placeholder='$00'
                                        />
                                    </td>
                                    <td className="buildIncmStTd">
                                        <input
                                            type="number"
                                            className="userBuildInp"
                                            name="currentBalance"
                                            value={item.currentBalance}
                                            onChange={(e) => handleChangePrepaidExpenses(e, i)}
                                            placeholder='$00'
                                        />
                                    </td>
                                </tr>
                            )
                        })
                    }

                    <tr>
                        <td colSpan={5}>
                            <div className="buildTotDivider" />
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td className="buildIncmStTd">
                            <span className="buildTotSpan">{language.total}</span>
                        </td>
                        <td className="buildIncmStTd">
                            <input
                                type="number"
                                readOnly
                                value={totalPreaidExp}
                                placeholder="$0.00"
                            />
                        </td>
                    </tr>
                </table>

                {/***Schedule 5. table**/}
                <p className='mb-1' style={{ fontSize: "15px" }}>{language.schedule5}</p>
                <table className='buildIncmStTable'>
                    <tr>
                        <th className="buildIncmStTh12 buildIncmStTh12First">
                            {isSave && <button
                                className="buildAddBtn"
                                onClick={() => {
                                    // if (validateLiability()) {
                                    setRealEstateBuildings(prev => ([...prev, initRealEstateBuildings]));
                                    // } else {
                                    //   toast.error(language.please_enter_all);
                                    // }
                                }}
                            >
                                <i class="fa-solid fa-plus"></i>
                            </button>}
                            <span>{`${language.location_street} #`}<br /> {`& ${language.description}`}</span>
                            {isSave && <button
                                className="delBuildRowBtn"
                                onClick={() => setDeleteRealEstateBuildingsRow(prev => !prev)}
                            >
                                {deleteRealEstateBuildingsRow ? language.remove_delete : language.delete_row}

                            </button>}
                        </th>
                        <th className='text-center'>{language.mortgage}</th>
                        <th className='text-center'>{language.due_dates}</th>
                        <th className='text-center'>{language.unpaid_tax}</th>
                        <th className='text-center'>{language.cost}</th>
                        <th className='text-center'>{language.present_market_value}</th>
                    </tr>

                    {
                        realEstateBuildings?.map((item, i, arr) => {
                            return (
                                <tr>
                                    <td className="buildIncmStTd">
                                        {
                                            deleteRealEstateBuildingsRow &&
                                            arr.length !== 1 &&
                                            <button
                                                className="buildRemoveBtn"
                                                onClick={() => {
                                                    // const fltData = liabilityData?.filter((ele, ind) => i !== ind);
                                                    // clculateAllDataLia(fltData);
                                                    setRealEstateBuildings(prev => prev.filter((ele, ind) => i !== ind));
                                                }}
                                            >
                                                <i class="fa-solid fa-minus"></i>
                                            </button>
                                        }
                                        <input
                                            type="text"
                                            className="userBuildInp"
                                            name="locationDescription"
                                            value={item.locationDescription}
                                            onChange={(e) => handleChangeRealEstateBuildings(e, i)}
                                        />
                                    </td>
                                    <td className="buildIncmStTd">
                                        <input
                                            type="text"
                                            className="userBuildInp"
                                            name="mortgagesLiens"
                                            value={item.mortgagesLiens}
                                            onChange={(e) => handleChangeRealEstateBuildings(e, i)}
                                        />
                                    </td>
                                    <td className="buildIncmStTd">
                                        <input
                                            type="text"
                                            className="userBuildInp"
                                            name="dueDatesPaymentAmount"
                                            value={item.dueDatesPaymentAmount}
                                            onChange={(e) => handleChangeRealEstateBuildings(e, i)}
                                        />
                                    </td>
                                    <td className="buildIncmStTd">
                                        <input
                                            type="number"
                                            className="userBuildInp"
                                            name="unpaidTaxes"
                                            value={item.unpaidTaxes}
                                            onChange={(e) => handleChangeRealEstateBuildings(e, i)}
                                            placeholder='$00'
                                        />
                                    </td>
                                    <td className="buildIncmStTd">
                                        <input
                                            type="number"
                                            className="userBuildInp"
                                            name="cost"
                                            value={item.cost}
                                            onChange={(e) => handleChangeRealEstateBuildings(e, i)}
                                            placeholder='$00'
                                        />
                                    </td>
                                    <td className="buildIncmStTd">
                                        <input
                                            type="number"
                                            className="userBuildInp"
                                            name="presentMarketValue"
                                            value={item.presentMarketValue}
                                            onChange={(e) => handleChangeRealEstateBuildings(e, i)}
                                            placeholder='$00'
                                        />
                                    </td>
                                </tr>
                            )
                        })
                    }

                    <tr>
                        <td colSpan={6}>
                            <div className="buildTotDivider" />
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td className="buildIncmStTd">
                            <span className="buildTotSpan">{language.total}</span>
                        </td>
                        <td className="buildIncmStTd">
                            <input
                                type="number"
                                readOnly
                                value={totalRealEstate}
                                placeholder="$0.00"
                            />
                        </td>
                    </tr>
                </table>

                {/***Schedule 6. table**/}
                <p className='mb-1' style={{ fontSize: "15px" }}>{language.schedule6}</p>
                <table className='buildIncmStTable'>
                    <tr>
                        <th className="buildIncmStTh12 buildIncmStTh12First">
                            {isSave && <button
                                className="buildAddBtn"
                                onClick={() => {
                                    // if (validateLiability()) {
                                    setAccountsPayable(prev => ([...prev, initAccountsPayable]));
                                    // } else {
                                    //   toast.error(language.please_enter_all);
                                    // }
                                }}
                            >
                                <i class="fa-solid fa-plus"></i>
                            </button>}
                            <span>{language.name_of_acc}</span>
                            {isSave && <button
                                className="delBuildRowBtn"
                                onClick={() => setDeleteAccountsPayableRow(prev => !prev)}
                            >
                                {deleteAccountsPayableRow ? language.remove_delete : language.delete_row}

                            </button>}
                        </th>
                        <th className='text-center'>{language.payable_to}</th>
                        <th className='text-center'>{language.when_due}</th>
                        <th className='text-center'>{language.amount_due}</th>
                    </tr>

                    {
                        accountsPayable?.map((item, i, arr) => {
                            return (
                                <tr>
                                    <td className="buildIncmStTd">
                                        {
                                            deleteAccountsPayableRow &&
                                            arr.length !== 1 &&
                                            <button
                                                className="buildRemoveBtn"
                                                onClick={() => {
                                                    // const fltData = liabilityData?.filter((ele, ind) => i !== ind);
                                                    // clculateAllDataLia(fltData);
                                                    setAccountsPayable(prev => prev.filter((ele, ind) => i !== ind));
                                                }}
                                            >
                                                <i class="fa-solid fa-minus"></i>
                                            </button>
                                        }
                                        <input
                                            type="text"
                                            className="userBuildInp"
                                            name="accountName"
                                            value={item.accountName}
                                            onChange={(e) => handleChangeAccountsPayable(e, i)}
                                        />
                                    </td>
                                    <td className="buildIncmStTd">
                                        <input
                                            type="text"
                                            className="userBuildInp"
                                            name="payableTo"
                                            value={item.payableTo}
                                            onChange={(e) => handleChangeAccountsPayable(e, i)}
                                        />
                                    </td>
                                    <td className="buildIncmStTd">
                                        <input
                                            type="date"
                                            className="userBuildInp"
                                            name="whenDue"
                                            value={item.whenDue}
                                            onChange={(e) => handleChangeAccountsPayable(e, i)}
                                        />
                                    </td>

                                    <td className="buildIncmStTd">
                                        <input
                                            type="number"
                                            className="userBuildInp"
                                            name="amountDue"
                                            value={item.amountDue}
                                            onChange={(e) => handleChangeAccountsPayable(e, i)}
                                            placeholder='$00'
                                        />
                                    </td>
                                </tr>
                            )
                        })
                    }

                    <tr>
                        <td colSpan={4}>
                            <div className="buildTotDivider" />
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td className="buildIncmStTd">
                            <span className="buildTotSpan">{language.total}</span>
                        </td>
                        <td className="buildIncmStTd">
                            <input
                                type="number"
                                readOnly
                                placeholder="$0.00"
                                value={totalAccountPayable}
                            />
                        </td>
                    </tr>
                </table>

                {/***Schedule 7. table**/}
                <p className='mb-1' style={{ fontSize: "15px" }}>{language.schedule7}</p>
                <table className='buildIncmStTable'>
                    <tr>
                        <th className="buildIncmStTh12 buildIncmStTh12First">
                            {isSave && <button
                                className="buildAddBtn"
                                onClick={() => {
                                    // if (validateLiability()) {
                                    setAccruedTaxes(prev => ([...prev, initAccruedTaxes]));
                                    // } else {
                                    //   toast.error(language.please_enter_all);
                                    // }
                                }}
                            >
                                <i class="fa-solid fa-plus"></i>
                            </button>}
                            <span>{language.type_of_tax}</span>
                            {isSave && <button
                                className="delBuildRowBtn"
                                onClick={() => setDeleteAccruedTaxesRow(prev => !prev)}
                            >
                                {deleteAccruedTaxesRow ? language.remove_delete : language.delete_row}

                            </button>}
                        </th>
                        <th className='text-center'>{language.payable_to}</th>
                        <th className='text-center'>{language.when_due}</th>
                        <th className='text-center'>{language.amount_due}</th>
                    </tr>

                    {
                        accruedTaxes?.map((item, i, arr) => {
                            return (
                                <tr>
                                    <td className="buildIncmStTd">
                                        {
                                            deleteAccruedTaxesRow &&
                                            arr.length !== 1 &&
                                            <button
                                                className="buildRemoveBtn"
                                                onClick={() => {
                                                    // const fltData = liabilityData?.filter((ele, ind) => i !== ind);
                                                    // clculateAllDataLia(fltData);
                                                    setAccruedTaxes(prev => prev.filter((ele, ind) => i !== ind));
                                                }}
                                            >
                                                <i class="fa-solid fa-minus"></i>
                                            </button>
                                        }
                                        <input
                                            type="text"
                                            className="userBuildInp"
                                            name="typeOfTax"
                                            value={item.typeOfTax}
                                            onChange={(e) => handleChangeAccruedTaxes(e, i)}
                                        />
                                    </td>
                                    <td className="buildIncmStTd">
                                        <input
                                            type="text"
                                            className="userBuildInp"
                                            name="payableTo"
                                            value={item.payableTo}
                                            onChange={(e) => handleChangeAccruedTaxes(e, i)}
                                        />
                                    </td>
                                    <td className="buildIncmStTd">
                                        <input
                                            type="date"
                                            className="userBuildInp"
                                            name="whenDue"
                                            value={item.whenDue}
                                            onChange={(e) => handleChangeAccruedTaxes(e, i)}
                                        />
                                    </td>

                                    <td className="buildIncmStTd">
                                        <input
                                            type="number"
                                            className="userBuildInp"
                                            name="amountDue"
                                            value={item.amountDue}
                                            onChange={(e) => handleChangeAccruedTaxes(e, i)}
                                            placeholder='$00'
                                        />
                                    </td>
                                </tr>
                            )
                        })
                    }


                    <tr>
                        <td colSpan={4}>
                            <div className="buildTotDivider" />
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td className="buildIncmStTd">
                            <span className="buildTotSpan">{language.total}</span>
                        </td>
                        <td className="buildIncmStTd">
                            <input
                                type="number"
                                readOnly
                                value={totalAccruedTax}
                                placeholder="$0.00"
                            />
                        </td>
                    </tr>
                </table>

                {/***Schedule 8. table**/}
                <p className='mb-1' style={{ fontSize: "15px" }}>{language.schedule8}</p>
                <table className='buildIncmStTable'>
                    <tr>
                        <th className="buildIncmStTh12 buildIncmStTh12First">
                            {isSave && <button
                                className="buildAddBtn"
                                onClick={() => {
                                    // if (validateLiability()) {
                                    setEquityStockHolder(prev => ([...prev, initEquity]));
                                    // } else {
                                    //   toast.error(language.please_enter_all);
                                    // }
                                }}
                            >
                                <i class="fa-solid fa-plus"></i>
                            </button>}
                            <span>{language.Type}</span>
                            {isSave && <button
                                className="delBuildRowBtn"
                                onClick={() => setDeleteEquityStockHolderRow(prev => !prev)}
                            >
                                {deleteEquityStockHolderRow ? language.remove_delete : language.delete_row}

                            </button>}
                        </th>
                        <th className='text-center'>{language.Amount} </th>
                    </tr>

                    {
                        equityStockHolder.map((item, i, arr) => {
                            return (
                                <tr key={i}>
                                    <td className="buildBalLeftTdInp">

                                        {/* delete button */}
                                        {
                                            deleteEquityStockHolderRow &&
                                            arr.length !== 1 &&
                                            <button
                                                className="buildRemoveBtn"
                                                onClick={() => {
                                                    // const fltData = liabilityData?.filter((ele, ind) => i !== ind);
                                                    // clculateAllDataLia(fltData);
                                                    setEquityStockHolder(prev => prev.filter((ele, ind) => i !== ind));
                                                }}
                                            >
                                                <i class="fa-solid fa-minus"></i>
                                            </button>
                                        }
                                        <input
                                            type="text"
                                            name="title"
                                            value={item.title}
                                            onChange={(e) => handleChangeEquity(e, i)}
                                        />

                                    </td>
                                    <td className="buildBalRightTdInp">
                                        <input
                                            type="number"
                                            name="amount"
                                            value={item.amount}
                                            onChange={(e) => handleChangeEquity(e, i)}
                                            placeholder='$00'
                                        // readOnly={isSave ? false : true}
                                        />
                                    </td>
                                </tr>
                            )
                        })
                    }

                    <tr>
                        <td colSpan={2}>
                            <div className="buildTotDivider mt-2" />
                        </td>
                    </tr>

                    <tr>
                        <td className="buildIncmStTd">
                            <span className="buildTotSpan">{language.total}</span>
                        </td>
                        <td className="buildBalRightTdInp">
                            <input
                                type="number"
                                readOnly
                                value={totalEquityAmount}
                                placeholder="$0.00"
                            />
                        </td>
                    </tr>
                </table>


                <div className="d-flex justify-content-center mt-3">
                    <button className="btn-report-save"
                        onClick={handleSave}
                    >{
                            isSave ?
                                saveLoading ? <CustomLoaderLine height="10" width="40" /> : language.save?.toUpperCase()
                                :
                                language.edit?.toUpperCase()
                        }</button>
                    <button className="btn-report-build"
                        onClick={() => handleBuildRepport()}

                    >
                        {loading ? <CustomLoaderLine height="10" width="100" /> : language.build_report?.toUpperCase()}
                    </button>
                </div>

            </div>
        </div >

    )
}

export default BuildBusinessFinancialStatement