import React from 'react'
import { useLanguage } from '../Providers/LanguageContext'
import CustomDateBtn from './CustomDateBtn'
import { useNavigate } from 'react-router-dom';
import { useUserData } from '../Providers/UserWrapper';

function Managementtop({ title, getVendorName, getCreationDate, searchBoxRef, isCleared, setIsCleared, filters, time }) {
    const { language } = useLanguage();
    const navigate = useNavigate();
    const { isBusiness } = useUserData();

    return (


        <>

            <section id='mng-top'>
                <div className='container'>
                    <div className='row'>

                        <div className='col'>
                            <div className='viewInsighDiv'>
                                {/* <h2>{isBusiness ? language.client_vendor : language.income_vendor}</h2> */}
                                <h2>{title}</h2>

                                <div className='viewInsignInpBtnDiv'>
                                    <button
                                        className='viewInsighBtn'
                                        onClick={() => navigate("/news", { state: { time: time } })}
                                    >
                                        {language.view_insight}
                                    </button>
                                    <div className='viewInsighInpDiv'>
                                        <input
                                            type='text'
                                            onChange={getVendorName}
                                            ref={searchBoxRef}
                                        />
                                        <i class="fa-solid fa-magnifying-glass"></i>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* <div className='col-xl-6 col-lg-6 col-md-12 col-12'>
                            <div className='left-heading'>
                                <h2>{title ? title : language.expense_management}</h2>
                            </div>
                        </div>
                        <div className='col-xl-6 col-lg-6 col-md-12 col-12'>
                            <div className='right-box'>
                                <form>
                                    <div className='top-search'>
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id=""
                                                aria-describedby=""
                                                placeholder=""
                                                onChange={getVendorName}
                                                ref={searchBoxRef}
                                            />
                                            <i class="fa-solid fa-magnifying-glass"></i>
                                        </div>
                                    </div>

                                </form>
                            </div>
                        </div> */}
                    </div>
                </div>
            </section>

            <div id='creation'>
                <div className='container'>
                    <CustomDateBtn
                        text={language.creation_date}
                        getValue={getCreationDate}
                        setIsCleared={setIsCleared}
                        isCleared={isCleared}
                        filters={{ date: filters?.transDate }}
                    />
                </div>
            </div>

        </>


    )
}

export default Managementtop