import React from 'react';
import success from '../../images/success.gif';
import { useNavigate } from 'react-router-dom';


const PaymentSuccessBook = () => {
    const navigate = useNavigate()
    return (
        <div>
            <div className="payment-success">
                <h1>Payment Successful!</h1>
                <p>Thank you for your payment.</p>
                <div className="gif-container">
                    <img src={success} alt="Success GIF" />
                </div>
                <button
                    style={{ height: '30px', width: '100px', backgroundColor: '#33aaa4', margin: '10px' }}
                    onClick={() => navigate('/manager')}
                >
                    Go to home
                </button>
            </div>
        </div>
    )
}

export default PaymentSuccessBook
