import React, { useContext, useState, useEffect } from 'react'
import { Navigate } from 'react-router-dom';
import { UserContext } from '../Providers/UserWrapper'

function IsSubscribed({ children }) {
    const { profileData, pricePathMemo, regCodeData, isSubscribeMemo, getUserNumber } = useContext(UserContext);

    // const getUserNumber = async () => {
    //     const sendData = {
    //         accountRegCode: Number(profileData && profileData.accountRegCode)
    //     }
    //     // console.log('sendData', sendData)
    //     const res = await HttpClient.requestData("total-user", "POST", sendData);
    //     console.log('resPaySub', res)
    //     if (res && res.status) {
    //         setRegData(res.data[0])
    //     }
    // }

    console.log("regCodeDatadd")


    useEffect(() => {
        getUserNumber();
    }, [profileData])

    // console.log('regCodeDatapp', Object.keys(regCodeData).length, profileData)
    if (Object.keys(regCodeData)?.length !== 0) {
        if (regCodeData?.isSubscribed) {
            return <>
                {children}
            </>
        } else {
            // return <Navigate to="/payment" />
            // return <Navigate to={`/${pricePathMemo}`} />
            return <Navigate to="/price" />
        }
    }
}

export default IsSubscribed