import React from "react";

export default function MVPrank({ goToRegistration }) {
  return (
    <div className="inner">
      <div className=" frth_slide">
        <div className="bottom_cnt mt-3">
          <h4>
            1000s Of Users Are Using Fisibility To Get Organized & Recognized,
            When Will You?
          </h4>
          <span>Unlock Fisibility In Just 4 Min &:</span>
          <ul>
            <li>Achieve Your Peak Efficiency</li>
            <li>Eliminate Stress</li>
            <li>Self-Service Setup</li>
            <li>Access to On-Demand Product Specialist</li>
            <li>Experience The Immediate Value With Free Access</li>
          </ul>
          <p>Join the Ranks of MVP’s!</p>
          <p>
            Don’t miss out on the opportunity to become the recognized Most
            Valuable Person in your workplace and personal life.
          </p>
          <p>
            Sign Up Now and experience the trans-formative power of Fisibility.
          </p>

          <a onClick={() => goToRegistration()} className="signupBtn">
            SIGN ME UP!
          </a>
        </div>
      </div>
    </div>

  );
}
