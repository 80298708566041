import React from 'react'
import Blogbanner from './Blogbanner'
import Blogslider from './Blogslider'
import Blogimage from './Blogimage'
import Gototop from '../../Component/Gototop'
import BlogBox from './BlogBox'
function index() {
  return (
    <>
      {/* <Helmet>
        <link rel="canonical" href="https://fisibility.com/Blog" />
      </Helmet> */}
      <Blogbanner />
      <BlogBox/>
      {/* <Blogslider /> */}
      <Blogimage />
      {/* <Gototop /> */}
    </>
  )
}

export default index