import React from 'react';
import { useParams } from 'react-router-dom';
import { useLanguage } from '../../Providers/LanguageContext';
import { useUserData } from '../../Providers/UserWrapper';
import Inviteimage from "../../images/inviteImage.png"

const SharedPage = () => {
    const { language } = useLanguage();
    const { reff } = useParams();
    const { profileData, setShow } = useUserData();

    console.log("reffer", reff)

    return (
        <div>
            <section id="invite">
                {/* <button onClick={sendEmail}>click</button> */}
                <div className='container'>

                    <div className='row'>
                        <div className='col-xl-6 col-lg-6 col-md-12 col-12'>
                            <div className='invite-left'>
                                {/* <div className='heading1'>
                                    <h1>{language.hey}!</h1>
                                </div> */}
                                <div className='heading2'>
                                    {/* <h2>{language.invite_friend}</h2> */}
                                    {/* <h2>The Invitation Code Is : 6541321</h2> */}
                                </div>
                                <div className='description'>
                                    {/* <p>{language.invite_friend_text}</p>
                                    <p>{language.your_reference_code} {profileData && profileData.reffCode}</p> */}
                                    <p>{language.invite_code_is} : {reff}</p>
                                    <p>{language.use_this_code}</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-xl-6 col-lg-6 col-md-12 col-12'>
                            <div className='invite-image'>
                                <img className="img-fluid" src={Inviteimage} alt="img" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div >
    )
}

export default SharedPage
