import React, { useContext, useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useLanguage } from '../Providers/LanguageContext';
import user_home_en2 from "../extras/x_manage_users_audio_b.mp3";
import user_home_es2 from "../extras/x_manage_users_audio_b_es.mp3";
import { SpeechContext } from '../Providers/SpeechWrapper';
import HttpClient from '../utils/HttpClient';
import { toast } from 'react-hot-toast';
import Swal from 'sweetalert2';
import moment from 'moment';
import { useUserData } from '../Providers/UserWrapper';

function Userform() {
    const selectRef = useRef()
    const location = useLocation();
    // console.log('locationUser', location.state)
    const navigate = useNavigate()
    const { language, currentLanguage } = useLanguage()
    const { currAudio, setCurrAudio, playing } = useContext(SpeechContext);
    const { getUserNumber, isBusiness, isMainUserMemo, setIsLoading } = useUserData()
    const [values, setValues] = useState({
        isDeleted: "",
        id: "",
        name: "",
        email: "",
        surname: "",
        accountType: "",
        createdOn: "",
        isDisabled: ""
    })
    console.log('values', values)

    useEffect(() => {
        if (location.state) {
            setValues({
                id: location.state._id ? location.state._id : "",
                name: location.state.name ? location.state.name : "",
                email: location.state.email ? location.state.email : "",
                surname: location.state.surName ? location.state.surName : "",
                accountType: location.state.accountType ? location.state.accountType : "",
                isDeleted: location.state.isDeleted,
                createdOn: location.state.createdOn,
                isDisabled: location.state.isDisable
            })
        }
    }, [location.state])
    console.log(location.state, "locationstate")

    useEffect(() => {
        if (playing) {
            if (currAudio) {
                if (!currAudio.paused) {
                    currAudio.pause()
                }
            }
            if (currentLanguage === "eng") {
                setCurrAudio(new Audio(user_home_en2))
            } else {
                setCurrAudio(new Audio(user_home_es2))
            }
        } else {
            if (currAudio) {
                if (!currAudio.paused) {
                    currAudio.pause()
                    setCurrAudio(null)
                }
            }
        }

        return () => {
            if (currAudio && !currAudio.paused) {
                currAudio.pause()
            }
        }
    }, [playing, currentLanguage]);


    // useEffect(() => {
    //     console.log("currAudio", currAudio)
    //     if (currAudio) {
    //         if (!currAudio.paused) {
    //             currAudio.pause()
    //         }
    //         currAudio.play()
    //     }
    //     return () => {
    //         if (currAudio && !currAudio.paused) {
    //             currAudio.pause()
    //         }
    //     }
    // }, [currAudio])

    const handleEdit = async () => {
        const sendData = {};
        if (values.name) {
            sendData.name = values.name
        }
        if (values.email) {
            sendData.email = values.email
        }
        if (values.surname) {
            sendData.surname = values.surname
        }
        if (values.accountType) {
            sendData.accountType = values.accountType
        }
        if (values.id) {
            const res = await HttpClient.requestData("/user-management-edit/" + values.id, "PUT", sendData);
            console.log('userResMan', res)
            if (res && res.status) {
                toast.success(language.account_update_success)
                setValues({ ...res.data })
            } else {
                toast.error(language.account_update_failed)
            }
            console.log(res, "responsedata")
        }
    }

    const handleDeleteAccount = () => {
        Swal.fire({
            title: language.delete_account3,
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: language.delete,
            denyButtonText: `Don't save`,
        }).then(async (result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                if (values.id) {
                    setIsLoading(true);
                    const res = await HttpClient.requestData("user-management-delete/" + values.id, "DELETE", {});
                    if (res && res.status) {
                        Swal.fire(language.acc_deleted, " ", "success");
                        navigate("/users");
                        getUserNumber();
                        setIsLoading(false);
                    } else {
                        Swal.fire(language.acc_delete_failed, '', 'info')
                    }
                }
            }
        })
    }

    const handleDisable = async () => {
        const res = await HttpClient.requestData("/user-management-disabled/" + values.id, "PUT");
        console.log("resDisable", res)
        if (res && res.status) {
            // toast.success(language.user_disabled)
            console.log('disableRes', res)
            toast.success(language.user_updated_success)
            navigate("/users")
        } else {
            toast.error(language.user_update_failed)
        }
    }
    return (

        <>

            <div id="user-form">
                <div className='container'>
                    <div className='inner-container'>
                        {
                            values.name && (
                                <div className='user-name'>
                                    <h4>{language.user} - {(values.name && values.surname) ? values.name + " " + values.surname : ""}</h4>
                                </div>
                            )
                        }

                        <div className='user-pro'>
                            {
                                // values.surname && (
                                <div className='user-left'>
                                    <span className={values.isDisabled ? 'red-btn' : 'green-btn'}></span>
                                    <h5>{(values.name && values.surname) ? values.name + " " + values.surname : values.email}</h5>
                                </div>
                                // )
                            }
                            <div className='user-right'>
                                {
                                    values.isDisabled ? (
                                        <a
                                            className='disable-btn'
                                            onClick={handleDisable}
                                            style={{ cursor: "pointer" }}
                                        >
                                            {language.enable_account}
                                        </a>
                                    )
                                        : (
                                            <a
                                                className='disable-btn'
                                                style={{ cursor: "pointer" }}
                                                onClick={handleDisable}
                                            >
                                                {language.disable_account}
                                            </a>
                                        )
                                }
                            </div>
                        </div>
                        <div className='user-form'>
                            <form>
                                <div className="form-group">
                                    <input
                                        type="email"
                                        className="form-control"
                                        id=""
                                        aria-describedby=""
                                        placeholder="Jael@hotmail.com "
                                        value={values.email}
                                        onChange={(e) => setValues(prev => ({ ...prev, email: e.target.value }))}
                                    />
                                </div>
                                <div className="form-group">
                                    <select className="form-control" id="exampleFormControlSelect1" ref={selectRef}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            if (value !== "Profile Type") {
                                                setValues(prev => ({ ...prev, accountType: e.target.value }))
                                            }
                                        }}>
                                        <option>{language.profile_type}</option>
                                        {
                                            isBusiness ?
                                                <>
                                                    <option selected={values.accountType === "Owner"} value={"Owner"}>{language.owner}</option>
                                                    <option selected={values.accountType === "Manager"} value={"Manager"}>{language.manager}</option>
                                                    <option selected={values.accountType === "Staff" || values.accountType === "Stuff"} value={"Staff"}>{language.staff}</option>
                                                    <option selected={values.accountType === "Bookkeeper"} value={"Bookkeeper"}>{language.bookkeeper}</option>
                                                </>
                                                :
                                                <>
                                                    <option selected={values.accountType === "Manager"} value={"Manager"}>{language.manager}</option>
                                                    <option selected={values.accountType === "Member"} value={"Member"}>{language.member}</option>
                                                    <option selected={values.accountType === "Staff" || values.accountType === "Stuff"} value={"Staff"}>{language.staff}</option>
                                                    <option selected={values.accountType === "Bookkeeper"} value={"Bookkeeper"}>{language.bookkeeper}</option>
                                                </>
                                        }
                                    </select>
                                </div>
                            </form>
                        </div>
                        <div className='form-btm'>
                            <p>{language.registered_on} {moment(values.createdOn).format("DD/MM/YYYY")}</p>
                            <div className='form-right'>
                                <i class="fa-regular fa-trash-can"></i>
                                <a
                                    style={{ cursor: 'pointer' }}
                                    className='delete-btn'
                                    onClick={handleDeleteAccount}
                                >
                                    {language.delete_account2}
                                </a>
                            </div>
                        </div>
                        <div className='user-btn'>
                            <button type="submit" className='save-btn' onClick={handleEdit}>{language.save}</button>
                        </div>
                    </div>
                </div >
            </div >

        </>

    )
}

export default Userform