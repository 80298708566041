import React, { useContext, useEffect, useState } from 'react'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import { IoIosArrowBack } from "react-icons/io"
import CustomDateComp from '../../Component/CustomDateComp';
import moment from 'moment';
import HttpClient from '../../utils/HttpClient';
import { MdArrowBackIosNew, MdArrowForwardIos } from "react-icons/md";
import CustomInputComp from '../../Component/CustomInputComp';
import { useLanguage } from '../../Providers/LanguageContext';
import { SpeechContext } from '../../Providers/SpeechWrapper';
import transdetails_en from "../../Audio_files/II. Revenue Manager Audio/II b. View & Edit Sales Revenue User Selected Option/ii_view_and_edit_sales_revenue_audio_b.mp3";
import transdetails_es from "../../spanish_audio_files/II. Revenue Manager Audio/II b. View & Edit Sales Revenue User Selected Option/ii_view_and_edit_sales_revenue_audio_b_es.mp3";
import CustomPriceComp from './CustomPriceComp';
import CustomSupplierComp from './CustomSupplierComp';
import CustomTypeComp from './CustomTypeComp';
import CustomDateCompIncome from './CustomDateCompIncome';

function TransactionList() {
    const location = useLocation();
    // console.log('locationList', location.state)
    const navigate = useNavigate();

    const { language, currentLanguage } = useLanguage()
    const {
        currAudio, setCurrAudio, playing, setIsPlaying
    } = useContext(SpeechContext)

    const [salesData, setSelesData] = useState();
    const [expenseData, setExpenseData] = useState();
    const [allTranData, setAllTranData] = useState([]);
    const [singleData, setSingleData] = useState({})

    const [data, setData] = useState(() => {
        if (location.state) {
            return location.state
        } else {
            return {}
        }
    })


    const [transactions, setTransactions] = useState({});
    const [currPage, setCurrPage] = useState(0)
    const [date, setDate] = useState("")

    // useEffect(() => {
    //     if (data) {
    //         console.log(data, "dataaaa")
    //         getTransactionData(data.transactionFilter, data.email)

    //     }
    // }, [data, currPage])

    useEffect(() => {
        if (allTranData.length) {
            // console.log('pppppppppppppp')
            setSingleData(allTranData[currPage])
            setDate(allTranData[currPage].transactionDate)
        }
    }, [allTranData, currPage])

    useEffect(() => {
        if (data) {
            fetchTranData()
        }
    }, [data, currPage])


    useEffect(() => {
        if (playing) {
            if (currAudio) {
                if (!currAudio.paused) {
                    currAudio.pause()
                }
            }
            if (currentLanguage === "eng") {
                setCurrAudio(new Audio(transdetails_en))
            } else {
                setCurrAudio(new Audio(transdetails_es))
            }
        } else {
            if (currAudio) {
                if (!currAudio.paused) {
                    currAudio.pause()
                    setCurrAudio(null)
                }
            }
        }
        return () => {
            if (currAudio && !currAudio.paused) {
                currAudio.pause()
            }
        }
    }, [playing, currentLanguage])

    // useEffect(() => {
    //     if (currAudio) {
    //         if (!currAudio.paused) {
    //             currAudio.pause()
    //         }
    //         currAudio.play()
    //     }
    //     return () => {
    //         if (currAudio && !currAudio.paused) {
    //             currAudio.pause()
    //         }
    //     }
    // }, [currAudio])


    if (!location.state) {
        return <Navigate to="/" replace />
    }


    const getTransactionData = async (filters, email) => {
        // const res = await HttpClient.requestData("get-transaction", "POST", filters);
        // if (res && res.status) {
        //     const found = res.data.find(t => t.email === email);
        //     if (found) {
        //         setTransactions(found)
        //         console.log("founddd", found)
        //         setDate(found.expenseData[currPage].transactionDate)
        //     }
        // }
        setTransactions([])
    }

    // console.log('locationList', location.state.transactionFilter.projectName)
    const fetchTranData = async () => {
        const sendData = {
            id: location.state.id,
            projectName: location.state.transactionFilter.projectName,
            startDate: location.state.transactionFilter.startDate ? location.state.transactionFilter.startDate : "",
            endDate: location.state.transactionFilter.endDate ? location.state.transactionFilter.endDate : ""
        }
        console.log('sendDataae', sendData)
        const res = await HttpClient.requestData("sales-transaction-details", "POST", sendData);
        console.log('tdsalse', res)
        // if (res && res.status) {
        //     setSelesData(res.data)
        // }
        // console.log('arr1',arr1)

        const res2 = await HttpClient.requestData("expense-transaction-details", "POST", sendData);
        console.log('tdexpense', res2)
        // if (res2 && res2.status) {
        //     setExpenseData(res2.data);
        // }

        if (res && res.status && res2 && res2.status) {
            setAllTranData([...res2.data, ...res.data])
        }
    }



    if (Object.keys(transactions).length > 0 && transactions.expenseData.length === 0) {
        return (
            <div className='d-flex justify-content-center align-items-center mb-3' style={{ fontSize: "20px" }}>
                <span>No data found...</span>
            </div>
        )
    }



    return (
        <>
            <div className='d-flex justify-content-center'>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "50px" }}>
                    <IoIosArrowBack size={30} onClick={() => navigate(-1)} />
                    <h4 className='mt-2'>{language.transaction_details}</h4>
                </div>
            </div>

            {/* <div>
                {
                    Object.keys(transactions).length > 0 && (
                        <div>
                            <CustomDateComp
                                defaultval={date}
                                id={transactions?.expenseData[currPage]?._id}
                                setDate={setDate}
                                setData={setData}
                                getTransactionData={getTransactionData}
                                data={data}
                                expenseData={transactions.expenseData}
                                setCurrPage={setCurrPage}
                                currPage={currPage}
                            />
                            <CustomInputComp
                                id={transactions?.expenseData[currPage]?._id}
                                getTransactionData={getTransactionData}
                                data={data}
                                currPage={currPage}
                                expenseData={transactions.expenseData}
                                type="number"
                                field="price"
                                successmsg={language.expense_updated}
                                errormsg={language.expense_updated_error}
                            />
                            <CustomInputComp
                                id={transactions?.expenseData[currPage]?._id}
                                getTransactionData={getTransactionData}
                                data={data}
                                currPage={currPage}
                                expenseData={transactions.expenseData}
                                type="text"
                                field="vendorName"
                                successmsg={language.supplier_updated}
                                errormsg={language.supplier_updated_error}
                            />

                            <CustomInputComp
                                id={transactions?.expenseData[currPage]?._id}
                                getTransactionData={getTransactionData}
                                data={data}
                                currPage={currPage}
                                expenseData={transactions.expenseData}
                                type="select"
                                field="expenseTypeID"
                                successmsg={language.expensetype_updated}
                                errormsg={language.expensetype_updated_error}
                            />

                        </div>
                    )
                }

                {
                    Object.keys(transactions).length > 0 && (
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", marginTop: "15px", marginBottom: "15px" }}>
                            {
                                currPage !== 0 && (
                                    <span style={{ marginRight: "30px" }}>
                                        <MdArrowBackIosNew
                                            size={20} color="black"
                                            onClick={() => setCurrPage(prev => --prev)}
                                        />
                                    </span>
                                )
                            }
                            <span>Showing {currPage + 1} of {transactions.expenseData.length}</span>
                            {
                                currPage + 1 !== transactions.expenseData.length && (
                                    <span style={{ marginLeft: "30px" }}>
                                        <MdArrowForwardIos
                                            size={20}
                                            color="black"
                                            onClick={() => setCurrPage(prev => ++prev)}
                                        />
                                    </span>
                                )
                            }
                        </div>
                    )
                }

            </div> */}

            <div>
                {
                    Object.keys(singleData).length > 0 ? (
                        <div>
                            <CustomDateCompIncome
                                defaultval={date}
                                // id={transactions?.expenseData[currPage]?._id}
                                setDate={setDate}
                                setData={setData}
                                getTransactionData={getTransactionData}
                                data={data}
                                setCurrPage={setCurrPage}
                                currPage={currPage}
                                singleData={singleData}
                                fetchTranData={fetchTranData}
                            />

                            <CustomPriceComp
                                // id={transactions?.expenseData[currPage]?._id}
                                getTransactionData={getTransactionData}
                                data={data}
                                currPage={currPage}
                                expenseData={singleData.price}
                                type="number"
                                field={singleData.customerName ? language.sales : language.expense}
                                successmsg={language.expense_updated}
                                errormsg={language.expense_updated_error}
                                singleData={singleData}
                                fetchTranData={fetchTranData}
                            />
                            <CustomSupplierComp
                                // id={transactions?.expenseData[currPage]?._id}
                                getTransactionData={getTransactionData}
                                data={data}
                                currPage={currPage}
                                expenseData={singleData.customerName ? singleData.customerName : singleData.vendorName}
                                type="text"
                                field={singleData.customerName ? language.customer_name : language.supplier}
                                successmsg={language.supplier_updated}
                                errormsg={language.supplier_updated_error}
                                singleData={singleData}
                                fetchTranData={fetchTranData}
                            />
                            <CustomTypeComp
                                // id={transactions?.expenseData[currPage]?._id}
                                getTransactionData={getTransactionData}
                                data={data}
                                currPage={currPage}
                                expenseData={singleData.type}
                                type="text"
                                field={singleData.customerName ? language.sales_type : language.expanse_type}
                                successmsg={language.expensetype_updated}
                                errormsg={language.expensetype_updated_error}
                                singleData={singleData}
                                fetchTranData={fetchTranData}
                            />
                        </div>
                    )
                        :
                        <div className='d-flex justify-content-center m-5'>
                            {language.no_data}
                        </div>
                }

                {
                    Object.keys(singleData).length > 0 && (
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", marginTop: "15px", marginBottom: "15px" }}>
                            {
                                currPage !== 0 && (
                                    <span style={{ marginRight: "30px" }}>
                                        <MdArrowBackIosNew
                                            size={20} color="black"
                                            onClick={() => setCurrPage(prev => --prev)}
                                        />
                                    </span>
                                )
                            }
                            <span>Showing {currPage + 1} of {allTranData.length}</span>
                            {
                                currPage + 1 !== allTranData.length && (
                                    <span style={{ marginLeft: "30px" }}>
                                        <MdArrowForwardIos
                                            size={20}
                                            color="black"
                                            onClick={() => setCurrPage(prev => ++prev)}
                                        />
                                    </span>
                                )
                            }
                        </div>
                    )
                }

            </div>

        </>
    )
}

export default TransactionList