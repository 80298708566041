import React, { useEffect, useState } from 'react'
import { useLanguage } from '../../Providers/LanguageContext';
import HttpClient from '../../utils/HttpClient';

const ViewBalanceSheet = ({ setBuildRepData }) => {
    const { language, currentLanguage } = useLanguage();

    const initAsset = {
        title: "",
        ammount: ""
    }
    const [assetData, setAssetData] = useState([]);
    useEffect(() => {
        setAssetData([
            {
                title: language.cash_on_hand,
                ammount: ""
            },
            {
                title: language.cash_in_bank,
                ammount: ""
            },
            {
                title: language.total_cash_hand,
                ammount: ""
            },
            {
                title: language.total_current_asset,
                ammount: ""
            },
            {
                title: language.client_ar_1,
                ammount: ""
            },
            {
                title: language.client_ar_2,
                ammount: ""
            },
            {
                title: language.total_acc_recievable,
                ammount: ""
            },
            {
                title: language.total_fixet_asset,
                ammount: ""
            },
            {
                title: language.total_other_asset,
                ammount: ""
            },

        ])
    }, [currentLanguage]);

    const [liabilityData, setLiabilityData] = useState([]);
    useEffect(() => {
        setLiabilityData([
            {
                title: language.total_current_liability,
                ammount: ""
            },
            {
                title: language.supplier_ap_1,
                ammount: ""
            },
            {
                title: language.supplier_ap_2,
                ammount: ""
            },
            {
                title: language.total_acc_payable,
                ammount: ""
            },
            {
                title: language.total_long_term_lia,
                ammount: ""
            },
            {
                title: language.total_shareholder_equitiy,
                ammount: ""
            },
            {
                title: language.total_liability_Share_equity,
                ammount: ""
            },
            {
                title: language.total_profit_loss_year,
                ammount: ""
            }
        ])
    }, [currentLanguage])

    // console.log("assetDataaa", assetData)

    const [totalData, setTotalData] = useState({ asset: "", liability: "" })



    const getInvoiceStatement = async () => {
        const res = await HttpClient.requestData("reportbuilder-view-balancesheet", "GET")
        // console.log("ressProffbb", res)
        if (res && res?.status) {
            setBuildRepData(res?.data)
            const asst = res?.data?.assets?.map((item) => ({
                title: item?.title,
                ammount: item?.price
            }))
            setAssetData(asst);

            const liaData = res?.data?.liabilities?.map((item) => ({
                title: item?.title,
                ammount: item?.price
            }))
            setLiabilityData(liaData);

            const totalDD = {
                asset: res?.data?.Total_assets,
                liability: res?.data?.Total_liabilities
            }
            setTotalData(totalDD)
        }
    }

    useEffect(() => {
        getInvoiceStatement();
    }, [])



    return (
        <div>
            <div className="container mt-4 mb-4">
                <div className="row">

                    {/* Assets */}
                    <div className="col-lg-6">
                        <div className="buldBalTabInnerDiv">
                            <table className="buildBalTable">
                                <thead>
                                    <tr>
                                        <th colSpan="2" className="buildBalLeftThInp">


                                            <span className="buildBalanceHead">{language.assets}</span>


                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="mt-2">
                                    {
                                        assetData?.map((item, i, arr) => {
                                            return (
                                                <tr key={i}>
                                                    <td className="buildBalLeftTdInp">

                                                        <span className="buildBalLeftTdSpan">{item.title?.toUpperCase()}</span>

                                                    </td>
                                                    <td className="buildBalRightTdInp">
                                                        <input
                                                            type="number"
                                                            name="ammount"
                                                            value={item.ammount}
                                                        />
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td className="buildBalLeftTdFoot">
                                            <span>{language.total_assets}</span>
                                        </td>
                                        <td className="buildBalRightTdFoot">
                                            <input
                                                type="number"
                                                value={totalData.asset}
                                                readOnly
                                            />
                                        </td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>

                    {/* Liabilities */}
                    <div className="col-lg-6 mt-4 mt-lg-0">
                        <div className="buldBalTabInnerDiv">
                            <table className="buildBalTable">
                                <thead>
                                    <tr>
                                        <th colSpan="2" className="buildBalLeftThInp">
                                            <span className="buildBalanceHead">{language.liabilities}</span>
                                        </th>
                                    </tr>
                                </thead>

                                <tbody className="mt-2">
                                    {
                                        liabilityData?.map((item, i, arr) => {
                                            return (
                                                <tr key={i}>
                                                    <td className="buildBalLeftTdInp">

                                                        <span className="buildBalLeftTdSpan">{item.title?.toUpperCase()}</span>

                                                    </td>
                                                    <td className="buildBalRightTdInp">
                                                        <input
                                                            type="number"
                                                            name="ammount"
                                                            value={item.ammount}
                                                        />
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }

                                </tbody>

                                {/* total liabilities */}
                                <tfoot>
                                    <tr>
                                        <td className="buildBalLeftTdFoot">
                                            <span>{language.total_liabilities}</span>
                                        </td>
                                        <td className="buildBalRightTdFoot">
                                            <input
                                                type="number"
                                                value={totalData.liability}
                                                readOnly
                                            />

                                        </td>
                                    </tr>
                                </tfoot>

                            </table>
                        </div>
                    </div>

                    {/* button group */}
                    {/* <div className="d-flex justify-content-center mt-3">
                        <button
                            className="btn-report-save"
                            onClick={handleSave}
                        >
                            {isSave ?
                                saveLoading ? <CustomLoaderLine height="10" width="40" /> : language.save?.toUpperCase()
                                :
                                language.edit?.toUpperCase()
                            }
                        </button>
                        <button
                            className="btn-report-build"
                            onClick={() => handleBuildRepport()}
                        >
                            {loading ? <CustomLoaderLine height="10" width="100" /> : language.build_report?.toUpperCase()}          </button>
                    </div> */}

                </div>
            </div >

        </div>
    )
}

export default ViewBalanceSheet
