import React, { useContext, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { useLanguage } from '../../Providers/LanguageContext'
import { SpeechContext } from '../../Providers/SpeechWrapper';
import resource_es from '../../Audio_files/Resource Manager Audio Files/ES Audio Files/a. View Additional Resources Audio/view_additional_resources_audio_a_es.mp3'
import resource_en from '../../Audio_files/Resource Manager Audio Files/EN Audio Files/a. View Additional Resources Audio/view_additional_resources_audio_a.mp3';
import fisiYoutube from '../../images/Resource_manager/FISIBILITY-YOUTUBE.png'
import niv from '../../images/Resource_manager/NIV.png'
import nuevi from '../../images/Resource_manager/NUEVI TESTAMENTO 2020.png'

const ResourceManager = () => {
    const { language, currentLanguage } = useLanguage();
    const { currAudio, setCurrAudio, playing, setShowGif, setGifToShow, setGifPoints } = useContext(SpeechContext);

    const text = [
        {
            title: language.new_international_version,
            link: 'https://www.thenivbible.com',
            image: niv
        },
        {
            title: language.new_testament,
            link: 'https://2020newtestament.godaddysites.com',
            image: nuevi
        },
        {
            title: language.how_use_fisi_woutube,
            link: 'https://youtube.com/@FisibilityLite',
            image: fisiYoutube
        }
    ]

    useEffect(() => {
        if (playing) {
            if (currAudio) {
                if (!currAudio.paused) {
                    currAudio.pause()
                }
            }
            if (currentLanguage === "eng") {
                setCurrAudio(new Audio(resource_en))
            } else {
                setCurrAudio(new Audio(resource_es))
            }
        } else {
            if (currAudio) {
                if (!currAudio.paused) {
                    currAudio.pause()
                    setCurrAudio(null)
                }
            }
        }

        return () => {
            if (currAudio && !currAudio.paused) {
                currAudio.pause()
            }
        }
    }, [playing, currentLanguage])


    // useEffect(() => {
    //     console.log("currAudio", currAudio)
    //     if (currAudio) {
    //         if (!currAudio.paused) {
    //             currAudio.pause()
    //         }
    //         currAudio.play()
    //     }
    //     return () => {
    //         if (currAudio && !currAudio.paused) {
    //             currAudio.pause()
    //         }
    //     }
    // }, [currAudio])
    return (
        <>
            {/* {
                text.map((item, i) =>
                    <div id='all-news' key={i}>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-xl-12 col-lg-12 col-md-12 col-12'>
                                    <div className='news-box'>
                                        <ul onClick={() => { }}>
                                            <li>
                                                <a href={item.link} target="_blank">
                                                    <div className='cnt-wrap'>
                                                        <h4>{item.title}</h4>
                                                        <i class="fa-solid fa-chevron-right"></i>
                                                    </div>
                                                </a>
                                            </li>
                                        </ul>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                )
            } */}

            <div className='container'>
                <div style={{ display: 'flex', justifyContent: 'center', fontFamily: 'Sans-serif' }}>
                    <h1>{language.resource_manager}</h1>
                </div>
                <div style={{ margin: '30px 0', display: 'flex', justifyContent: 'center' }}>

                    <div className='row'>
                        {
                            text.map((item, i) =>
                                <div className='col-sm-12 col-md-12 col-lg-4'>
                                    <a href={item.link} target="_blank">
                                        <img src={item.image} style={{ height: '200px', width: '300px', borderRadius: '8px', boxShadow: '0px 0px 9px 1px' }} />
                                    </a>
                                    <h5 style={{ margin: '10px 0' }}>{item.title}</h5>
                                </div>
                            )

                        }
                        {/* <div className='col-md-4'>
                                <img src={fisiYoutube} style={{ height: '200px', width: '300px', borderRadius: '8px' }} />
                            </div>
                            <div className='col-md-4'>
                                <img src={fisiYoutube} style={{ height: '200px', width: '300px', borderRadius: '8px' }} />
                            </div> */}
                    </div>

                </div>
            </div>
        </>
    )
}

export default ResourceManager