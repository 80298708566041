import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useLanguage } from '../../Providers/LanguageContext';
import { useUserData } from '../../Providers/UserWrapper';

const SignUpButton = () => {
    const navigate = useNavigate();
    const { language } = useLanguage();
    const { logout, isLogin } = useUserData();
    return (
        <div>
            {/* <div
                className='' style={{ width: "100%", height: "auto", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <button 
                className='siGnup_btn'
                onClick={()=>navigate('/login')}
                >
                    {language.homeBannerBtn1}
                </button>
            </div> */}

            <div className='registrarAnddescargar' style={{ transform: "translate(-50% , -50%)", top: "100%", }}>

                <button className='registrar_Btn' onClick={() => navigate('/price')}>
                    {language.homeBannerBtn1}
                </button>

                <a href='https://fisibilitylite.com/app_api/share_url.html' target="_blank">
                    <button className='descargar_Btn'>
                        {language.homeBannerBtn2}
                    </button>
                </a>
            </div>
        </div>
    )
}

export default SignUpButton;