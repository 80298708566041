import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import ClassificationInvoice from '../../Component/invoice/ClassificationInvoice';
import CreateDateComp from '../../Component/invoice/CreateDateComp';
import { useLanguage } from '../../Providers/LanguageContext';
import HttpClient from '../../utils/HttpClient';
import { AiOutlineArrowLeft } from 'react-icons/ai';

function ViewInvoiceSummary() {
    const location = useLocation();
    const navigate = useNavigate();
    const { language } = useLanguage();
    console.log(location.state, "location.state")
    const [isCleared, setIsCleared] = useState(false);
    const [invoiceData, setInvoiceData] = useState([])
    console.log('invoiceData', invoiceData)
    const [filters, setFilters] = useState({
        date: '',
        classification: ""
    })
    // console.log('filters223', filters)

    useEffect(() => {
        if (!location.state) {
            navigate(-1);
        } else {
            fetchInvoiceByName(location.state.name);
        }
    }, [location.state])

    // useEffect(() => {
    //     fetchInvoice()
    // }, [filters])

    const getDate = (value) => {
        console.log(value)
        // console.log('valueDate', value)
        if (value) {
            setFilters(prev => ({ ...prev, date: value }))

        }
    }

    async function fetchInvoiceByName(name) {
        const res = await HttpClient.requestData("view-invoice-nameWise/" + name, "POST", {
            "date": "",
            "classification": ""
        })
        // console.log('resInvName', res)
        // console.log('resFD', moment(res.data[0].date).format('yyyy-MM-DD') === filters.date)
        if (res && res.status) {
            const arr1 = filters.date ? res.data.filter((item) => moment(item.date).format('yyyy-MM-DD') === filters.date) : res.data
            console.log('invoiceDatarr', arr1)
            setInvoiceData(arr1)
        }
    }

    // async function fetchInvoice() {
    //     const res = await HttpClient.requestData("view-invoice-nameWise/" + location.state.name, "POST", filters)
    //     console.log('resInvName', res)
    //     console.log(res.data, "responsedata")
    //     if (res && res.status) {
    //         setInvoiceData(res.data)
    //     }
    // }

    const handleFilterClassChange = (e) => {
        const val = e.target.value;
        // if (value === language.classification) {
        //     fetchInvoiceByName(location.state.name)
        // } else {
        //     setFilters(prev => ({ ...prev, classification: value }))
        // }
        // console.log(e.target.value)

        if (val === 'Recent') {
            let arr = invoiceData;
            sortByDate(arr)
            let arr2 = arr.reverse()
            setInvoiceData([...arr2]);
        }
        else if (val === 'Oldest') {
            let arr = invoiceData;
            sortByDate(arr);
            setInvoiceData([...arr])
        }
    }

    const sortByDate = (val) => {
        // console.log('sortval')
        val.sort((a, b) => {
            let da = new Date(a.date),
                db = new Date(b.date);
            return da - db;
        });
        // console.log('sortval', val)
        return val
    }

    useEffect(() => {
        getDate(location.state.filtersDate)
        fetchInvoiceByName(location.state.name);
        // console.log('locc', location.state.filtersDate)
    }, [location])

    useEffect(() => {
        fetchInvoiceByName(location.state.name);
    }, [filters])

    return (
        <div className='container'>
            <div
                className="back-arrow-left"
                onClick={() => {
                    navigate(-1)
                    console.log("object")
                }
                }
            >
                <AiOutlineArrowLeft />
            </div>
            <CreateDateComp
                title={location?.state?.name}
                getCreationDate={getDate}
                isCleared={isCleared}
                setIsCleared={setIsCleared}
                filters={filters}
            />
            <ClassificationInvoice
                expenses={invoiceData}
                setFilters={setFilters}
                setIsCleared={setIsCleared}
                // fetchExpenses={fetchInvoice}
                handleFilterClassChange={handleFilterClassChange}
            />

            {
                invoiceData.length === 0 &&
                <div className='d-flex justify-content-center m-3'>
                    <h4>{language.no_data}</h4>
                </div>
            }

        </div>
    )
}

export default ViewInvoiceSummary