import React from 'react'
import { RotatingLines } from 'react-loader-spinner'
import { useUserData } from '../Providers/UserWrapper'

const LoaderRotate = () => {
    const { isLoading } = useUserData();
    return (
        <>
            {
                isLoading &&
                <div id="loader_fis" class="loader_fis">
                    <div className="loader-container">
                        {/* <div className='container'> */}
                        <RotatingLines
                            strokeColor="#194860"
                            strokeWidth="5"
                            animationDuration="0.75"
                            width="96"
                            visible={true}
                        />
                        {/* </div> */}
                    </div>
                </div>
            }
        </>
    )
}

export default LoaderRotate;