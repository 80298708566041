import React, { useContext, useEffect, useState } from 'react'
import Managebtn from '../../../Component/Managebtn'
import Tracker from './Tracker'
import { SpeechContext } from '../../../Providers/SpeechWrapper';
import { useNavigate } from 'react-router-dom';
import { useLanguage } from '../../../Providers/LanguageContext';

function BudgetTracker2() {
    const [time, setTime] = useState("")
    const { language } = useLanguage();
    const navigate = useNavigate()
    const { isProject, setIsproject } = useContext(SpeechContext);
    console.log('isProjectbb', isProject)


    const getTime = (val) => {
        setTime(val)
    }

    useEffect(() => {
        setIsproject(true)
        // else if(isProject==true){
        //     setIsproject(false)
        // }
        // else{

        // }

    }, [])

    return (
        <>
            <div className=''>
                <button className='w-25 mb-2 btn btn-outline-success' style={{ fontWeight: "bold", margin: "0 0 0 120px" }} onClick={() => navigate('/project-performance')}>
                    {language.view_pro_performance}
                </button>
            </div>
            <Managebtn getTime={getTime} value={time} />
            <Tracker time={time} setTime={setTime} />

        </>
    )
}

export default BudgetTracker2