import React, { useEffect, useState } from 'react'
import { useLanguage } from '../../Providers/LanguageContext';
import HttpClient from '../../utils/HttpClient';

const ViewIncomeStatement = ({ reportDate, setBuildRepData }) => {
    const { language, currentLanguage } = useLanguage();

    const initIncome = {
        title: "",
        period: "",
        periodPercent: "",
        cumulative: "",
        cumulativePercent: ""
    }

    const [incomeData, setIncomeData] = useState([]);
    const [expenseData, setExpenseData] = useState([]);
    useEffect(() => {

        setIncomeData([
            {
                title: language.sales?.toUpperCase(),
                period: "",
                periodPercent: "",
                cumulative: "",
                cumulativePercent: ""
            },
            {
                title: language.other_income,
                period: "",
                periodPercent: "",
                cumulative: "",
                cumulativePercent: ""
            },
            {
                title: language.finance_income,
                period: "",
                periodPercent: "",
                cumulative: "",
                cumulativePercent: ""
            }
        ])

        setExpenseData([
            {
                title: "DEV.",
                period: "",
                periodPercent: "",
                cumulative: "",
                cumulativePercent: ""
            },
            {
                title: language.cost_of_sales,
                period: "",
                periodPercent: "",
                cumulative: "",
                cumulativePercent: ""
            }
        ])

    }, [currentLanguage])


    const [totalIncome, setTotalIncome] = useState(initIncome);
    const [totalExpense, setTotalExpense] = useState(initIncome);
    const [totalFinal, setTotalFinal] = useState(initIncome);


    const getInvoiceStatement = async () => {
        const res = await HttpClient.requestData("reportbuilder-view-profitloss", "GET")
        console.log("ressProff", res)
        if (res && res?.status) {
            setBuildRepData(res?.data)
            const incdata = res?.data && res?.data?.Income?.map((item) => ({
                title: item?.title,
                period: item?.period,
                periodPercent: item?.periodPer,
                cumulative: item?.cumulative,
                cumulativePercent: item?.cumulativePer
            }))
            setIncomeData(incdata);

            const expData = res?.data && res?.data?.Expenses?.map((item) => ({
                title: item?.title,
                period: item?.period,
                periodPercent: item?.periodPer,
                cumulative: item?.cumulative,
                cumulativePercent: item?.cumulativePer
            }))
            setExpenseData(expData);

            const totInc = {
                // title: item?.title,
                period: res?.data?.Total_Income?.period,
                periodPercent: res?.data?.Total_Income?.periodPer,
                cumulative: res?.data?.Total_Income?.cumulative,
                cumulativePercent: res?.data?.Total_Income?.cumulativePer
            }
            setTotalIncome(totInc);

            const totExp = {
                // title: item?.title,
                period: res?.data?.Total_Expenses?.period,
                periodPercent: res?.data?.Total_Expenses?.periodPer,
                cumulative: res?.data?.Total_Expenses?.cumulative,
                cumulativePercent: res?.data?.Total_Expenses?.cumulativePer
            }
            setTotalExpense(totExp);

        }
    }

    useEffect(() => {
        getInvoiceStatement();
    }, [])

    return (
        <div>
            <div className="buildIncmStTableDiv mt-4 mb-4">
                <table className="buildIncmStTable">

                    {/* ******************Income****************** */}
                    <tr>
                        <th className="buildIncmStTh buildIncmStThFirst">

                            {/* plus button */}

                            {/* <button
                                className="buildAddBtn"
                            >
                                <i class="fa-solid fa-plus"></i>
                            </button> */}


                            <span>{language.income}</span>

                            {/* <button
                                className="delBuildRowBtn"
                            >
                                {deleteRowInc ? language.remove_delete : language.delete_row}
                            </button> */}
                        </th>
                        <th className="buildIncmStTh pr-2">
                            <span>{language.period}</span>
                        </th>
                        <th className="buildIncmStTh pr-2 buildIncmSttdPer">
                            <span>%</span>
                        </th>
                        <th className="buildIncmStTh pr-2">
                            <span>{language.cunulative}</span>
                        </th>
                        <th className="buildIncmStTh buildIncmSttdPer">
                            <span>%</span>
                        </th>
                    </tr>

                    {
                        incomeData?.map((item, i, arr) => {
                            return (
                                <tr>
                                    <td className="buildIncmStTd">
                                        <span>{item.title?.toUpperCase()}</span>
                                    </td>
                                    <td className="buildIncmStTd pr-2">
                                        <input
                                            type="number"
                                            name="period"
                                            value={item.period}
                                        />
                                    </td>
                                    <td className="buildIncmStTd buildIncmSttdPer pr-2">
                                        <input
                                            type="number"
                                            name="periodPercent"
                                        />
                                    </td>
                                    <td className="buildIncmStTd pr-2">
                                        <input
                                            type="number"
                                            name="cumulative"
                                            value={item.cumulative}
                                        />
                                    </td>
                                    <td className="buildIncmStTd buildIncmSttdPer">
                                        <input
                                            type="number"
                                            name="cumulativePercent"
                                            value={item.cumulativePercent}
                                        />
                                    </td>
                                </tr>
                            )
                        })
                    }
                    {/* ******************Income end****************** */}


                    {/*divide line */}
                    <tr>
                        <td></td>
                        <td colSpan="4">
                            <div className="buildTotDivider"></div>
                        </td>
                    </tr>

                    {/* Total Income */}
                    <tr>
                        <td className="buildIncmStTd">
                            <span className="buildTotSpan">{language?.total_income}</span>
                        </td>
                        <td className="buildIncmStTd pr-2">
                            <input
                                type="number"
                                readOnly
                                value={totalIncome.period}
                            />
                        </td>
                        <td className="buildIncmStTd buildIncmSttdPer pr-2">
                            <input
                                type="number"
                                value={totalIncome.periodPercent}
                                readOnly
                            />
                        </td>
                        <td className="buildIncmStTd pr-2">
                            <input
                                type="number"
                                readOnly
                                value={totalIncome.cumulative}
                            />
                        </td>
                        <td className="buildIncmStTd buildIncmSttdPer">
                            <input
                                type="number"
                                value={totalIncome.cumulativePercent}
                                readOnly
                            />
                        </td>
                    </tr>

                    {/* ******************Expenses************************* */}
                    <tr>
                        <th className="buildIncmStTh">
                            <span>{language.expenses}</span>
                        </th>
                        <th colSpan="4"></th>
                    </tr>

                    {
                        expenseData?.map((item, i, arr) => {
                            return (
                                <tr key={i}>
                                    <td className="buildIncmStTd">
                                        <span>{item.title?.toUpperCase()}</span>
                                    </td>
                                    <td className="buildIncmStTd pr-2">
                                        <input
                                            type="number"
                                            name="period"
                                            value={item.period}
                                        />
                                    </td>
                                    <td className="buildIncmStTd buildIncmSttdPer pr-2">
                                        <input
                                            type="number"
                                            name="periodPercent"
                                            value={item.periodPercent}
                                        />
                                    </td>
                                    <td className="buildIncmStTd pr-2">
                                        <input
                                            type="number"
                                            name="cumulative"
                                            value={item.cumulative}
                                        />
                                    </td>
                                    <td className="buildIncmStTd buildIncmSttdPer">
                                        <input
                                            type="number"
                                            name="cumulativePercent"
                                            value={item.cumulativePercent}
                                        />
                                    </td>
                                </tr>
                            )
                        })
                    }
                    {/* ******************Expenses  End******************** */}

                    {/*  */}
                    <tr>
                        <td></td>
                        <td colSpan="4">
                            <div className="buildTotDivider"></div>
                        </td>
                    </tr>

                    {/* Total Expenses */}
                    <tr>
                        <td className="buildIncmStTd">
                            <span className="buildTotSpan">{language.total_expense}</span>
                        </td>
                        <td className="buildIncmStTd pr-2">
                            <input
                                type="number"
                                value={totalExpense.period}
                                readOnly
                            />
                        </td>
                        <td className="buildIncmStTd buildIncmSttdPer pr-2">
                            <input
                                type="number"
                                value={totalExpense.periodPercent}
                                readOnly
                            />
                        </td>
                        <td className="buildIncmStTd pr-2">
                            <input
                                type="number"
                                value={totalExpense.cumulative}
                                readOnly
                            />
                        </td>
                        <td className="buildIncmStTd buildIncmSttdPer">
                            <input
                                type="number"
                                value={totalExpense.cumulativePercent}
                                readOnly
                            />
                        </td>
                    </tr>

                    {/* final total */}
                    <tr>
                        <td className="buildIncmStTd">
                            <span className="buildProSpan">{language.profit} (or {language.loss})</span>
                        </td>
                        <td className="buildIncmStTd pr-2">
                            <input
                                type="number"
                                value={Number(totalIncome?.period) - Number(totalExpense?.period)}
                                readOnly
                            />
                        </td>
                        <td className="buildIncmStTd buildIncmSttdPer pr-2">
                            <input
                                type="number"
                                value={0}
                                readOnly
                            />
                        </td>
                        <td className="buildIncmStTd pr-2">
                            <input
                                type="number"
                                value={Number(totalIncome?.cumulative) - Number(totalExpense?.cumulative)}
                                readOnly
                            />
                        </td>
                        <td className="buildIncmStTd buildIncmSttdPer">
                            <input
                                type="number"
                                value={0}
                                readOnly
                            />
                        </td>
                    </tr>

                </table>

                {/* button group */}
                {/* <div className="d-flex justify-content-center mt-3">
                    <button
                        className="btn-report-save"
                        onClick={handleSave}
                    >
                        {isSave ?
                            saveLoading ? <CustomLoaderLine height="10" width="40" /> : language.save?.toUpperCase()
                            :
                            language.edit?.toUpperCase()
                        }
                    </button>

                    <button
                        className="btn-report-build"
                        onClick={handleBuild}
                    >
                        {loading ? <CustomLoaderLine height="10" width="100" /> : language.build_report?.toUpperCase()}
                    </button>
                </div> */}
            </div >

        </div>
    )
}

export default ViewIncomeStatement
