import React from 'react';
import { useLanguage } from '../../Providers/LanguageContext'
import SignUpButton from './SignUpButton';
import iMg1 from "../../images/ilustraciones soluciones industriales_COMERCIO ELECTRONICO 1.png";
import iMg2 from "../../images/ilustraciones soluciones industriales_COMERCIO ELECTRONICO 2.png";
import iMg3 from "../../images/ilustraciones soluciones industriales_COMERCIO ELECTRONICO 3 EN.png";
import teXtImg from "../../images/ilustraciones soluciones industriales_COMERCIO ELECTRONICO 3.png";
import iMg3en from "../../images/ilustraciones soluciones industriales_COMERCIO ELECTRONICO 4.png";



const ElectronicsCommerce = () => {
  const { language, currentLanguage } = useLanguage();

  return (
    <div>
      {/* <section id='agriculture_sec'>
        <div className='container'>
          <div className='agriculture_head'>
            <div>
              <h3>{language.fisibility_key_feture}:</h3>
              <h2>{language.electronic_commerce}</h2>
            </div>
          </div>
          <div className='agriculture_list'>
            <ul>
              <li><strong>{language.multi_channel_ana}: </strong>
                {language.fisi_prov_console}
              </li>
               <li><strong>{language.inv_manage}: </strong>
                {language.fisi_ecom_field}
              </li>
              <li><strong>{language.profitability_analysis}: </strong>
                {language.fisi_ecom_analyze}
              </li>
              <li><strong>{language.financial_management}: </strong>
                {language.fisi_provide_range}
              </li>
              <li><strong>{language.sales_forcasting}: </strong>
                {language.fisi_use_advance}
              </li>
            </ul>
          </div>

          <SignUpButton />
        </div>
      </section> */}

      <section id='agriculture_sec'>
        <div className='container'>
          <div className='row mb-5'>
            <div className='col-md-6 col-12 m-auto'>
              <div className='agriculture_head'>
                <h3 style={{ color: "#33aaa4" }}>{language.electronic_commerce}</h3>
              </div>

              <div className='agriculture_headtext'>
                <h4 style={{ color: "#ffca2e" }}>{language.multi_channel_ana}</h4>
                <p>{language.fisi_prov_console}</p>
              </div>
            </div>
            <div className='col-md-6 col-12 m-auto'>
              <div className='agriculture_img'>
                <img src={iMg1} className='img-fluid' alt='' />
              </div>
            </div>
          </div>

          <div className='row row_reverse'>
            <div className='col-md-6 col-12 m-auto'>
              <div className='agriculture_img'>
                <img src={iMg2} className='img-fluid' alt='' />
              </div>
            </div>
            <div className='col-md-6 col-12 m-auto'>
              <div className='agriculture_headtext'>
                <h4 style={{ color: "#33aaa4", textAlign: "right" }}>{language.inv_manage}</h4>
                <p>{language.fisi_ecom_field}</p>
                <div className='agriculturetext_img'>
                  <img src={teXtImg} className='img-fluid' />
                </div>
              </div>
            </div>
          </div>

          <div className='row mb-5'>
            <div className='col-md-12 col-12'>
              <div className='agriculture_headtext'>
                <h4 style={{ color: "#eabf07", textAlign: "left" }}>{language.profitability_analysis}</h4>
                <p>{language.fisi_ecom_analyze}</p>
              </div>
            </div>
          </div>

          <div className='row mb-5'>
            <div className='col-md-12 col-12'>
              <div className='agriculture_headtext'>
                <h4 style={{ color: "#33aaa4", textAlign: "left" }}>{language.financial_management}</h4>
                <p>{language.fisi_provide_range}</p>
              </div>
            </div>
          </div>


          <div className='row'>
            <div className='col-md-6 col-12 m-auto'>
              <div className='agriculture_headtext'>
                <h4 style={{ color: "rgb(234, 191, 7)", textAlign: "left" }}>{language.sales_forcasting}</h4>
                <p>{language.fisi_use_advance}</p>
              </div>
            </div>

            <div className='col-md-6 col-12 m-auto'>
              <div className='agriculture_img'>
                {
                  currentLanguage === 'spanish'
                    ?
                    <img src={iMg3} className='img-fluid' alt='' />
                    :
                    <img src={iMg3en} className='img-fluid' alt='' />
                }

                <div className='registrarAnddescargar' style={{ transform: "translate(-50% , -50%)", top: "85%" }}>
                  {/* <button className='registrar_Btn'>Registrarse</button>
                  <button className='descargar_Btn'>Descargar</button> */}
                  <SignUpButton />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </div>
  )
}

export default ElectronicsCommerce;