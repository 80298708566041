import React, { useContext, useEffect, useState } from "react";
import spanish from "../language/spanish.json";
import english from "../language/eng.json";

const Language = React.createContext(null);

export const useLanguage = () => useContext(Language);

export const LanguageContext = ({ children }) => {
  const [currentLanguage, setCurrentLanguage] = useState("eng");
  const language = currentLanguage === "eng" ? english : spanish
  useEffect(() => {
    let lan = localStorage.getItem("language")
    if (lan) {
      setCurrentLanguage(lan)
    }
  }, [])

  return (
    <Language.Provider value={{ language, currentLanguage, setCurrentLanguage }}>
      {children}
    </Language.Provider>
  );
};
