import { async } from '@firebase/util'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useNavigate, useParams } from 'react-router-dom'
import { useLanguage } from '../../Providers/LanguageContext'
import HttpClient from '../../utils/HttpClient'

export default function ConfirmMessage() {
    const { language, currentLanguage } = useLanguage()
    const [invData, setInvData] = useState({})
    const navigate = useNavigate()
    console.log('invData', invData)
    const { id } = useParams();
    const [updateInvData, setUpdateInvData] = useState({
        InvestmentType: "",
        InvestmentAmt: ""
    })
    console.log('updateInvData', updateInvData)

    useEffect(() => {

        setUpdateInvData({
            InvestmentType: invData.InvestmentType ? invData.InvestmentType : "",
            InvestmentAmt: invData.InvestmentAmt ? invData.InvestmentAmt : ""
        })
    }, [invData])

    const getInvData = async () => {
        const res = await HttpClient.requestData('get-investment/' + id, 'GET', {});
        console.log('invdatares', res);
        if (res && res.status) {
            if (res.data[0]) {
                setInvData(res.data[0])
            }
            else {
                setInvData({})
            }
        } else {
            toast.error(res.message);
        }
    }

    useEffect(() => {
        getInvData();
    }, [])

    const handleConfirm = async () => {
        // console.log('confirm')
        if (!Object.keys(invData).length) {
            toast.error(language.no_data)
            return
        }
        if (!updateInvData.InvestmentType) {
            toast.error(language.inv_type_req)
            return
        }
        if (!updateInvData.InvestmentAmt) {
            toast.error(language.inv_amt_req)
            return
        }

        const res = await HttpClient.requestData('update-investment/' + id, 'PUT', updateInvData);
        console.log('updateResinv', res)
        if (res && res.status) {
            const res = await HttpClient.requestData('approved-investment/' + id, 'PUT', {});
            console.log('approveRes', res)
            if (res && res.status) {
                toast.success(language.investment_approved_success)
                navigate("/")
            }
        }
    }

    return (
        <>
            <section id="ConfirmMessage">
                <div className='container'>
                    <div className='wraper'>
                        <h3 className='mb-5'>{language.confirm_inv_details}</h3>
                        <div className='row'>
                            <div className="col-xl-12 col-lg-12 col-md-12 col-12 mb-5">
                                <div className='row mb-2' style={{ alignItems: "center" }}>
                                    <div className='col-sm-3'>
                                        <h4>{language.register_investment}</h4>
                                    </div>
                                    <div className='col-sm-9'>
                                        <h5>{invData.InvestorOrgName}</h5>
                                    </div>
                                </div>
                                <div className='row mb-2' style={{ alignItems: "center" }}>
                                    <div className='col-sm-3'>
                                        <h4>{language.individual_name}</h4>
                                    </div>
                                    <div className='col-sm-4'>
                                        <h5>{invData.IndividualName}</h5>
                                    </div>
                                </div>

                                <div className='row mb-2' style={{ alignItems: "center" }}>
                                    <div className='col-sm-3'>
                                        <h4>{language.email_placeholder}</h4>
                                    </div>
                                    <div className='col-sm-4'>
                                        <h5>{invData.Email}</h5>
                                    </div>
                                </div>

                                <div className='row mb-2' style={{ alignItems: "center" }}>
                                    <div className='col-sm-3'>
                                        <h4>{language.investment_type}</h4>
                                    </div>
                                    <div className='col-sm-4'>
                                        <input
                                            type="text"
                                            placeholder={language.debt_equity_mezz}
                                            value={updateInvData.InvestmentType}
                                            onChange={(e) => {
                                                setUpdateInvData((prev) => { return { ...prev, InvestmentType: e.target.value } })
                                            }}
                                        />
                                    </div>
                                    <div className='col-sm-2'>
                                        <h4>{language.investment_amt}</h4>
                                    </div>
                                    <div className='col-sm-3'>
                                        <input
                                            type="number"
                                            placeholder='$2,000,000,00'
                                            value={updateInvData.InvestmentAmt}
                                            onChange={(e) => {
                                                setUpdateInvData((prev) => { return { ...prev, InvestmentAmt: e.target.value } })
                                            }}
                                        />
                                    </div>
                                </div>

                                <div className='row mb-2' style={{ alignItems: "center" }}>
                                    <div className='col-sm-3'>
                                        <h4>{language.investment_date}</h4>
                                    </div>
                                    <div className='col-sm-4'>
                                        <h5>{invData.InvestmentDate}</h5>
                                    </div>
                                </div>

                                <div className='row mb-2' style={{ alignItems: "center" }}>
                                    <div className='col-sm-3'>
                                        <h4>{language.investment_url}</h4>
                                    </div>
                                    <div className='col-sm-4'>
                                        <h5>{invData.InvestmentURL}</h5>
                                    </div>
                                </div>
                            </div>



                            <div className='col-lg-12 col-md-12 col-12'>

                                <div className='row mb-2' style={{ alignItems: "center" }}>
                                    <div className='col-sm-3'>
                                        <h4>{language.business_name}</h4>
                                    </div>
                                    <div className='col-sm-9'>
                                        <h5>{invData.InvestmentOrgName}</h5>
                                    </div>
                                </div>

                                <div className='row mb-2' style={{ alignItems: "center" }}>
                                    <div className='col-sm-3'>
                                        <h4>{language.key_person_name}</h4>
                                    </div>
                                    <div className='col-sm-4'>
                                        <h5>{invData.KeypersonName}</h5>
                                    </div>
                                </div>

                                <div className='row mb-2' style={{ alignItems: "center" }}>
                                    <div className='col-sm-3'>
                                        <h4>{language.investment_email}</h4>
                                    </div>
                                    <div className='col-sm-4'>
                                        <h5>{invData.InvestmentEmail}</h5>
                                    </div>
                                </div>

                                <div className='row mb-2' style={{ alignItems: "center" }}>
                                    <div className='col-sm-3'>
                                        <h4>Tax ID #</h4>
                                    </div>
                                    <div className='col-sm-4'>
                                        <h5>{invData.TaxID}</h5>
                                    </div>
                                    <div className='col-sm-1'>
                                        <h4>RFC ID</h4>
                                    </div>
                                    <div className='col-sm-4'>
                                        <h5>{invData.RfcID}</h5>
                                    </div>
                                </div>

                                <div className='row mb-2' style={{ alignItems: "center" }}>
                                    <div className='col-sm-3'>
                                        <h4>SS# / CURP ID</h4>
                                    </div>
                                    <div className='col-sm-4'>
                                        <h5>{invData.CurpID}</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='btnPart'>
                            <button onClick={() => navigate("/")} className='btn btn-light mr-2'>{language.cancel}</button>
                            <button className='btn btn-light mr-2'>{language.review}</button>
                            <button className='btn btn-light' onClick={handleConfirm}>{language.confirm}</button>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}
