import React, { useContext, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { useLocation } from 'react-router-dom';
import CustomModal from '../../Component/CustomModal';
import { UserContext } from '../../Providers/UserWrapper';
import Sidebar1 from './Sidebar1';
import Sidebar2 from './Sidebar2';
import Sidebar3 from './Sidebar3';
import Sidebar4 from './Sidebar4';
import {AiOutlineClose} from "react-icons/ai";
import { useLanguage } from '../../Providers/LanguageContext';
import ChangePassword from '../../Component/changepass/ChangePassword';
import Sidebar5 from './Sidebar5';

export function Sidebar({ name, ...props }) {
  const {language} = useLanguage()
  const location = useLocation()
  const {show,setShow} = useContext(UserContext);
  const [modalIsOpen, setIsOpen] = useState(false);

    function openModal() {
        setIsOpen(true);

    }


    function closeModal() {
        setIsOpen(false);
    }
  
  
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  
    return (
      <>
        {/* <Button variant="primary" onClick={handleShow} className="me-2">
          {name}
        </Button> */}
        <Offcanvas show={show} onHide={handleClose} {...props}>
        <div style={{ overflowX: "scroll" }}>

          <Offcanvas.Header closeButton style={{justifyContent:"flex-end", alignItems:"flex-end"}}>
            {/* <Offcanvas.Title>Offcanvas</Offcanvas.Title> */}
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Sidebar1/>
            <Sidebar2 openModal={openModal} />
            <Sidebar3 setShow={setShow}/>
            <Sidebar4 setShow={setShow}/>
            <Sidebar5/>
          </Offcanvas.Body>
        </div>
        </Offcanvas>
        <CustomModal
                modalIsOpen={modalIsOpen}
                setIsOpen={setIsOpen}
                openModal={openModal}
                closeModal={closeModal}
            >
              <div>
                    <div className='d-flex justify-content-between text-secondary'>
                        <span>
                            {language.change_password}
                        </span>
                        <span>
                            <AiOutlineClose className='icon' onClick={() => {
                                closeModal();
                            }} size={20} color="gray" />
                        </span>
                    </div>
                    <ChangePassword
                     closeModal={closeModal}
                    />

                </div>
            </CustomModal>
      </>
    );
  }
  
  // function Example() {
  //   return (
  //     <>
  //       {['start', 'end', 'top', 'bottom'].map((placement, idx) => (
  //         <Sidebar key={idx} placement={placement} name={placement} />
  //       ))}
  //     </>
  //   );
  // }
  
  // render(<Example />);