import React, { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast';
import Help from "../images/help.png";
import { useLanguage } from '../Providers/LanguageContext';
import HttpClient from '../utils/HttpClient';
import { useUserData } from '../Providers/UserWrapper';

function Helpform() {
    const { language } = useLanguage();
    const { profileData } = useUserData()
    const [values, setValues] = useState({
        email: '',
        sub: "",
        desc: ""
    })

    useEffect(() => {
        setValues((prev) => {
            return {
                ...prev,
                email: profileData && profileData.email,
            }
        })
    }, [profileData])

    const handleContact = async () => {
        const { email, sub, desc } = values;
        if (!email || !sub || !desc) {
            toast.error(language.please_enter);
            return
        }
        const res = await HttpClient.requestData("add-contactForm", "POST", values);
        if (res && res.status) {
            toast.success(language.contact_success);
            setValues({
                email: "",
                sub: "",
                desc: ""
            })
        } else {
            toast.error(language.contact_error);
        }
    }
    return (

        <>

            <div id="help-form">
                <div className='container'>
                    <div className='row'>
                        <div className='col-xl-6 col-lg-6 col-md-6 col-12'>
                            <div className='top-heading'>
                                <h1>{language.hello}</h1>
                            </div>
                            <div className='heading-two'>
                                <h2>{language.help}</h2>
                            </div>
                            <div className='form-inner'>
                                <form>
                                    <div className="form-group">
                                        <input
                                            type="email"
                                            className="form-control"
                                            id=""
                                            aria-describedby=""
                                            placeholder={language.email_placeholder}
                                            value={values.email}
                                            onChange={(e) => setValues(prev => ({ ...prev, email: e.target.value }))}

                                        />
                                    </div>
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            className="form-control"
                                            id=""
                                            aria-describedby=""
                                            placeholder={language.subject}
                                            value={values.sub}
                                            onChange={(e) => setValues(prev => ({ ...prev, sub: e.target.value }))}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <textarea
                                            className="form-control"
                                            id="exampleFormControlTextarea1"
                                            rows={3}
                                            defaultValue={""}
                                            placeholder={language.write_msg}
                                            value={values.desc}
                                            onChange={(e) => setValues(prev => ({ ...prev, desc: e.target.value }))}
                                        />
                                    </div>

                                </form>
                                <div className='help-btn'>
                                    <button type='submit' className='send-btn' onClick={handleContact}>{language.send}</button>
                                </div>
                            </div>
                        </div>
                        <div className='col-xl-6 col-lg-6 col-md-6 col-12'>
                            <div className='help-image'>
                                <img className="img-fluid" src={Help} alt="image" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>

    )
}

export default Helpform