import React, { useContext, useState } from 'react'
import { useLanguage } from '../../Providers/LanguageContext'
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai"
import { toast } from 'react-hot-toast';
import HttpClient from '../../utils/HttpClient';
import { UserContext } from '../../Providers/UserWrapper';
import { useNavigate } from 'react-router-dom';
function ChangePassword({ closeModal }) {
    const navigate = useNavigate()
    const { language } = useLanguage();
    const { logout, setShow } = useContext(UserContext)
    const [showoldpass, setshowoldpass] = useState(false);
    const [newpass, setnewpass] = useState(false);
    const [confirmnewpass, setconfirmnewpass] = useState(false);
    const [values, setValues] = useState({
        oldPassword: "",
        password: ""
    })
    const [confirmpass, setconfirmpass] = useState("")


    const handleChangePass = async () => {
        if (!values.oldPassword) {
            toast.error(language.provide_old_pass_error);
            return;
        }
        if (!values.password) {
            toast.error(language.provide_new_pass_error);
            return;
        }
        if (values.password !== confirmpass) {
            toast.error(language.password_not_match_error);
            return;
        }

        const res = await HttpClient.requestData("password-change", "PUT", values);
        if (res && res.status) {
            closeModal()
            toast.success(language.password_change_success);
            setValues({
                oldPassword: "",
                password: ""
            })
            setconfirmpass("")
            setShow(false)
            // logout()
            // navigate("/")
        } else {
            toast.error(language.password_change_error)
        }
    }
    return (
        <div className='mt-3' style={{}}>
            <div className="td-details mb-2" style={{
                // display: "flex",
                // justifyContent: "center", alignItems: "center",
                // gap: "8px", width: "100%", background: "lightgray", padding: "10px"
            }}
            >

                <div>
                    <div>
                        <label htmlFor="inputdate" style={{ marginRight: "0", fontSize: "15px", width: "170px", textAlign: "left" }}>
                            {language.oldpasword}
                        </label>
                        <div className='custom-wrap' style={{ display: "flex", justifyContent: "start" }}>
                            <div style={{ position: "relative", width: "100%" }}>
                                <input type={showoldpass ? "text" : "password"}
                                    value={values.oldPassword} onChange={(e) => setValues(prev => ({ ...prev, oldPassword: e.target.value }))}
                                    style={{ width: "100%" }} id="inputdate" placeholder={language.oldpasword} />
                                <div style={{ position: "absolute", top: 18, right: 10 }}>
                                    {!showoldpass ? <AiFillEye size={25} onClick={() => setshowoldpass(true)} /> :
                                        <AiFillEyeInvisible size={25} onClick={() => setshowoldpass(false)} />}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <label htmlFor="inputdate" style={{ marginRight: "0", fontSize: "15px", width: "170px", textAlign: "left" }}>
                            {language.newpassword}
                        </label>
                        <div className='custom-wrap' style={{ display: "flex", justifyContent: "start" }}>
                            <div style={{ position: "relative", width: "100%" }}>
                                <input type={newpass ? "text" : "password"} style={{ width: "100%" }}
                                    value={values.password} onChange={(e) => setValues(prev => ({ ...prev, password: e.target.value }))}
                                    id="inputdate" placeholder={language.newpassword} />
                                <div style={{ position: "absolute", top: 18, right: 10 }}>
                                    {!newpass ? <AiFillEye size={25} onClick={() => setnewpass(true)} /> :
                                        <AiFillEyeInvisible size={25} onClick={() => setnewpass(false)} />}
                                </div>
                            </div>
                        </div>
                    </div>
                    <label htmlFor="inputdate" style={{ marginRight: "0", fontSize: "15px", width: "170px", textAlign: "left" }}>
                        {language.confirmnewpas}
                    </label>
                    <div className='custom-wrap' style={{ display: "flex", justifyContent: "start" }}>
                        <div style={{ position: "relative", width: "100%" }}>
                            <input type={confirmnewpass ? "text" : "password"} style={{ width: "100%" }}
                                value={confirmpass} onChange={(e) => setconfirmpass(e.target.value)}
                                id="inputdate" placeholder={language.confirmnewpas} />
                            <div style={{ position: "absolute", top: 18, right: 10 }}>
                                {!confirmnewpass ? <AiFillEye size={25} onClick={() => setconfirmnewpass(true)} /> :
                                    <AiFillEyeInvisible size={25} onClick={() => setconfirmnewpass(false)} />}
                            </div>
                        </div>
                    </div>
                </div>

                <div style={{ display: "flex", justifyContent: "center" }}>
                    <button className='customBtn5' onClick={handleChangePass}>{language.change_password}</button>
                </div>
            </div>
        </div>
    )
}

export default ChangePassword