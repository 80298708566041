import React from 'react'
import { useLanguage } from '../Providers/LanguageContext'

const NoDataFound = () => {
    const { language } = useLanguage()

    return (
        <div className='d-flex justify-content-center m-3'>
            {language.no_data}
        </div>
    )
}

export default NoDataFound