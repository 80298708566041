import React, { useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useUserData } from "../../Providers/UserWrapper";
import HttpClient from "../../utils/HttpClient";
import { useLanguage } from "../../Providers/LanguageContext";
import BackArrow from "../../Component/BackArrow/BackArrow";
import Select from "react-dropdown-select";
import { PlaidLink, usePlaidLink } from "react-plaid-link";
import { useNavigate } from "react-router-dom";

const Integration = () => {
  const { profileData, setIsLoading } = useUserData();
  const { language, currentLanguage } = useLanguage();
  const [acountData, setAccountData] = useState([]);
  const [loaderAcc, setLoaderAcc] = useState(false);
  const navigate = useNavigate();

  const [token, setToken] = useState(null)

  console.log("tokenfgg", token)

  const initOptions = [
    {
      value: 1,
      label: "Leanne Graham"
    },
    {
      value: 2,
      label: "Ervin Howell"
    }
  ];

  const [optionSelect, setOptionSelect] = useState([]);

  const initData = {
    userName: "",
    password: "",
    check: false
  }
  const [formValue, setFormValue] = useState(initData);
  // console.log("profileData", profileData)

  const handleCange = (e) => {
    const { name, value } = e.target;
    // console.log("valluue", name, e.target.checked)
    setFormValue(prev => ({ ...prev, [name]: value }));
  }

  const validate = () => {
    if (!formValue.userName) {
      toast.error(language.userNAme_required);
      return true
    }
    if (!formValue.password) {
      toast.error(language.password_validate_blank);
      return true
    }
    // if (!formValue.check) {
    //   toast.error("please click on the chck box");
    //   return true
    // }
    return false
  }

  const handleConnect = async () => {
    if (validate()) {
      return;
    }

    const data = {
      userId: profileData?._id,
      userName: formValue.userName,
      password: formValue.password
    }

    setIsLoading(true)
    const res = await HttpClient.requestData("create-plaid-token-new", "POST", data);
    // console.log("ressCC", res);
    if (res && res.status) {
      toast.success(language.account_connected_success);
      setFormValue(initData);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      toast.error(language.invalid_user_name);
    }
  }

  // get bank details
  const getAccounts = async () => {
    let sendData = JSON.stringify({
      "client_id": "641de1312b1dac0013438f60",
      "secret": "4baeb91fe3d7d81df753c167a9026c",
      "query": "delta schools",
      "country_codes": [
        "US"
      ],
      "products": [
        "auth"
      ]
    });

    setLoaderAcc(true);
    const res = await fetch("https://sandbox.plaid.com/institutions/search",
      {
        method: "POST",
        headers: {
          "content-type": 'application/json'
        },
        body: sendData
      }
    );
    const data = await res?.json();
    if (data && !data?.error_message) {
      const arr = data?.institutions.map((item) => ({ value: 1, label: item?.name }))
      setOptionSelect(arr)
      setAccountData(data?.institutions);
      setLoaderAcc(false);
    } else {
      setLoaderAcc(true)
    }
  }

  // ******************plaid get token***********************************
  const onSuccess = useCallback(async (publicToken) => {
    // console.log("publicToken", publicToken);
    const sendData = {
      publicToken: publicToken
    }
    const res = await HttpClient.requestData("update-bank-access-token", "POST", sendData)
    if (res && res?.status) {
      navigate('/dashboard-manager', { state: { slideNo: 5 } })
    } else {

    }
  }, [token]);

  const onExit = useCallback(() => {
    navigate('/manager')
  }, [])

  const config = {
    token,
    onSuccess,
    // onEvent
    onExit
  };

  const { open, ready } = usePlaidLink(config);

  const createLinkToken = React.useCallback(async () => {
    const res = await HttpClient.requestData("create-link-token", "GET");
    setIsLoading(true);
    if (res && res?.status) {
      setToken(res?.data?.link_token);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  }, [setToken]);


  useEffect(() => {
    if (token == null) {
      createLinkToken();
    }
    if (ready) {
      open();
    }
  }, [token, ready]);

  useEffect(() => {
    getAccounts();
  }, [])

  return (
    <>
      {/* <section className="integrationSection">
        <BackArrow />
        <div className="container">
          <div className="row align-baseline">

            <div className="col-lg-7 col-12">
              <div className="row mt-5">
                <p className="pleAccDet">{language.to_connect_click_below}</p>
                <div className="inteSaveBtnDiv">
                  <button
                    className="inteSaveConBtn"
                    onClick={() => open()}
                  >
                    Connect to Plaid
                  </button>
                </div>
              </div>

            </div>

            <div className="col-lg-5 col-12">
              <p className="pleAccDet">Connected Accounts</p>
              <div className="bankStatusDiv">
                <ul className="bankStatusUl">
                  <li className="bankStatusLi" >
                    <span className="bankStatusSpan">Bank of Maxico</span>
                    <span className="bankStatusSpan">Checking</span>
                    <button className="bankStatusBtn">Remove</button>
                  </li>
                  <li className="bankStatusLi">
                    <span className="bankStatusSpan">Bank of America</span>
                    <span className="bankStatusSpan">Savings</span>
                    <button className="bankStatusBtn">Remove</button>
                  </li>
                  <li className="bankStatusLi">
                    <span className="bankStatusSpan">Wells Fargo</span>
                    <span className="bankStatusSpan">Money Market</span>
                    <button className="bankStatusBtn">Remove</button>
                  </li>
                </ul>
              </div>
            </div>
          </div>

        </div>
      </section> */}

    </>
  );
};

export default Integration;
