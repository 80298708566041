import React, { useEffect, useState } from 'react'
import { useUserData } from '../../../Providers/UserWrapper'
import moment from 'moment'
import HttpClient from '../../../utils/HttpClient'
import toast from 'react-hot-toast'
import { useLanguage } from '../../../Providers/LanguageContext'

function BuildCashflow({ setCashflowCsv, setPdfCreate, setPdfLoader, setEmailSendFunc }) {

    const { language, currentLanguage } = useLanguage()
    const { profileData, setIsLoading } = useUserData()

    const initCashValues = {
        "title": "",
        "current_period": "",
        "previous_period": "",
        "increase_decrease": ""
    }
    const [cashReciepts, setCashReciepts] = useState([
        {
            "title": language.Cash_Sales,
            "current_period": "",
            "previous_period": "",
            "increase_decrease": ""
        },
        {
            "title": language.Customer_Account_Collections,
            "current_period": "",
            "previous_period": "",
            "increase_decrease": ""
        },
        {
            "title": language.Loan_Cash_Injection,
            "current_period": "",
            "previous_period": "",
            "increase_decrease": ""
        },
        {
            "title": language.Interest_income,
            "current_period": "",
            "previous_period": "",
            "increase_decrease": ""
        },
        {
            "title": language.Tax_Refund,
            "current_period": "",
            "previous_period": "",
            "increase_decrease": ""
        },
        {
            "title": language.Other_Cash_Receipts,
            "current_period": "",
            "previous_period": "",
            "increase_decrease": ""
        },
        {
            "title": language.other,
            "current_period": "",
            "previous_period": "",
            "increase_decrease": ""
        },

    ])
    const [cashPayment, setCashPayment] = useState([
        {
            "title": language.Direct_Product_Service_Costs,
            "current_period": "",
            "previous_period": "",
            "increase_decrease": ""
        },
        {
            "title": language.Payroll_Taxes_Benefits,
            "current_period": "",
            "previous_period": "",
            "increase_decrease": ""
        },
        {
            "title": language.Salaries_Direct,
            "current_period": "",
            "previous_period": "",
            "increase_decrease": ""
        },
        {
            "title": language.Supplies,
            "current_period": "",
            "previous_period": "",
            "increase_decrease": ""
        },
        {
            "title": language.other,
            "current_period": "",
            "previous_period": "",
            "increase_decrease": ""
        },

    ])
    const [operatingExp, setOperatingExp] = useState([
        {
            "title": language.Account_Fees,
            "current_period": "",
            "previous_period": "",
            "increase_decrease": ""
        },
        {
            "title": language.Advertising,
            "current_period": "",
            "previous_period": "",
            "increase_decrease": ""
        },
        {
            "title": language.Bank_Fees,
            "current_period": "",
            "previous_period": "",
            "increase_decrease": ""
        },
        {
            "title": language.Continuing_Education,
            "current_period": "",
            "previous_period": "",
            "increase_decrease": ""
        },
        {
            "title": language.Dues_Subscriptions,
            "current_period": "",
            "previous_period": "",
            "increase_decrease": ""
        },
        {
            "title": language.Insurance,
            "current_period": "",
            "previous_period": "",
            "increase_decrease": ""
        },
        {
            "title": "Internet",
            "current_period": "",
            "previous_period": "",
            "increase_decrease": ""
        },
        {
            "title": language.Licenses_Permits,
            "current_period": "",
            "previous_period": "",
            "increase_decrease": ""
        },
        {
            "title": language.Meals_Entertainment,
            "current_period": "",
            "previous_period": "",
            "increase_decrease": ""
        },
        {
            "title": language.Office_Supplies,
            "current_period": "",
            "previous_period": "",
            "increase_decrease": ""
        },
        {
            "title": language.Payroll_Processing,
            "current_period": "",
            "previous_period": "",
            "increase_decrease": ""
        },
        {
            "title": language.Payroll_Taxes_Benefits_Indirect,
            "current_period": "",
            "previous_period": "",
            "increase_decrease": ""
        },
        {
            "title": language.Postage_Shipping,
            "current_period": "",
            "previous_period": "",
            "increase_decrease": ""
        },
        {
            "title": language.Printing,
            "current_period": "",
            "previous_period": "",
            "increase_decrease": ""
        },
        {
            "title": language.Professional_Services,
            "current_period": "",
            "previous_period": "",
            "increase_decrease": ""
        },
        {
            "title": language.Occupancy,
            "current_period": "",
            "previous_period": "",
            "increase_decrease": ""
        },
        {
            "title": language.Rental_Fees,
            "current_period": "",
            "previous_period": "",
            "increase_decrease": ""
        },
        {
            "title": language.Salaries_Indirect,
            "current_period": "",
            "previous_period": "",
            "increase_decrease": ""
        },
        {
            "title": language.Subcontractors,
            "current_period": "",
            "previous_period": "",
            "increase_decrease": ""
        },
        {
            "title": "Telephone",
            "current_period": "",
            "previous_period": "",
            "increase_decrease": ""
        },
        {
            "title": language.Transportation,
            "current_period": "",
            "previous_period": "",
            "increase_decrease": ""
        },
        {
            "title": language.Travel,
            "current_period": "",
            "previous_period": "",
            "increase_decrease": ""
        },
        {
            "title": language.Utilities,
            "current_period": "",
            "previous_period": "",
            "increase_decrease": ""
        },
        {
            "title": language.Web_Development,
            "current_period": "",
            "previous_period": "",
            "increase_decrease": ""
        },
        {
            "title": language.Web_Domain_Hosting,
            "current_period": "",
            "previous_period": "",
            "increase_decrease": ""
        },
        {
            "title": language.other,
            "current_period": "",
            "previous_period": "",
            "increase_decrease": ""
        },
    ])
    const [addCashExp, setAddCashExp] = useState([
        {
            "title": language.Cash_Disbursements_Owners,
            "current_period": "",
            "previous_period": "",
            "increase_decrease": ""
        },
        {
            "title": language.Charitable_Contributions,
            "current_period": "",
            "previous_period": "",
            "increase_decrease": ""
        },
        {
            "title": language.Interest_Expense,
            "current_period": "",
            "previous_period": "",
            "increase_decrease": ""
        },
        {
            "title": language.Income_Tax_Expense,
            "current_period": "",
            "previous_period": "",
            "increase_decrease": ""
        },
        {
            "title": language.other,
            "current_period": "",
            "previous_period": "",
            "increase_decrease": ""
        },

    ])

    const initTotalData = {
        cashReciepts_cur: "",
        cashReciepts_prev: "",
        cashReciepts_inc: "",
        cashPayment_cur: "",
        cashPayment_prev: "",
        cashPayment_inc: "",
        operatingExp_cur: "",
        operatingExp_prev: "",
        operatingExp_inc: "",
        addCashExp_cur: "",
        addCashExp_prev: "",
        addCashExp_inc: ""
    }
    const [totalData, setTotalData] = useState(initTotalData)

    const initAllValues = {
        beginBalance_cur: "",
        beginBalance_prev: "",
        beginBalance_inc: "",
        totalCash_cur: "",
        totalCash_prev: "",
        totalCash_inc: "",
        netCash_cur: "",
        netCash_prev: "",
        netCash_inc: "",
        monthEndCash_cur: "",
        monthEndCash_prev: "",
        monthEndCash_inc: "",
    }
    const [allValues, setAllValues] = useState(initAllValues)


    const [isSave, setIsSave] = useState(true)


    console.log("totalData", totalData)


    // all change
    const handleAllChange = (e) => {
        const { name, value } = e.target;
        setAllValues(prev => ({ ...prev, [name]: value }))
    }

    // total change
    const handleTotalChange = (e) => {
        const { name, value } = e.target;
        setTotalData(prev => ({ ...prev, [name]: value }))
    }

    // cash reciept change
    const handleAddMore = (e, i, setData) => {
        const { name, value } = e.target
        console.log("ffgghh", name, value)
        // let newFormValues = [...cashReciepts];
        // newFormValues[i][name] = value;
        // setData(newFormValues);
        setData(prev => {
            let newFormValues = [...prev];
            newFormValues[i][name] = value;
            return newFormValues
        });
    }

    // handle build
    const handleBildReport = async () => {
        const data = {
            "report_Name": "Cash Flow",
            "company_Name": profileData?.companyName,
            "report_GenerateBy": profileData?.name + " " + profileData?.surName,
            "report_GeneratDate": moment().format("YYYY-MM-DD"),
            // "report_Period": {
            //     "startDate": moment().format("YYYY-MM-DD"),
            //     "endDate": moment().format("YYYY-MM-DD")
            // },
            "Beginning_Balance": {
                "current_period": allValues?.beginBalance_cur,
                "previous_period": allValues?.beginBalance_prev,
                "increase_decrease": allValues?.beginBalance_inc
            },
            "Cash_Receipts": cashReciepts,
            "Cash_Payments_CostOfGoods": cashPayment,
            "Operating_Expenses": operatingExp,
            "Additional_Expenses": addCashExp,
            "Total_Cash_Payments": {
                "current_period": allValues?.totalCash_cur,
                "previous_period": allValues?.totalCash_prev,
                "increase_decrease": allValues?.totalCash_inc
            },
            "Net_Cash_Change": {
                "current_period": allValues?.netCash_cur,
                "previous_period": allValues?.netCash_prev,
                "increase_decrease": allValues?.netCash_inc
            },
            "Month_Ending_Cashposition": {
                "current_period": allValues?.monthEndCash_cur,
                "previous_period": allValues?.monthEndCash_prev,
                "increase_decrease": allValues?.monthEndCash_inc
            }
        }

        setIsLoading(true)
        const res = await HttpClient.requestData("reportbuilder-add-cashflow", "POST", data)
        // console.log("ressfc", res)
        setIsLoading(false)
        if (res && res?.status) {
            getCashFlowReport();
            toast.success("Report Build SuccessFully!");
        } else {
            toast.error(res?.message || "Error")
        }

    }

    // handle save
    const handleSave = () => {
        setIsSave(false);
        setAllValues(prev => ({
            ...prev,
            totalCash_cur: totalData?.cashReciepts_cur + totalData?.cashPayment_cur + totalData?.operatingExp_cur + totalData?.addCashExp_cur,
            totalCash_prev: totalData?.cashReciepts_prev + totalData?.cashPayment_prev + totalData?.operatingExp_prev + totalData?.addCashExp_prev,
            totalCash_inc: totalData?.cashReciepts_inc + totalData?.cashPayment_inc + totalData?.operatingExp_inc + totalData?.addCashExp_inc,
            netCash_cur: totalData?.cashReciepts_cur - totalData?.cashPayment_cur,
            netCash_prev: totalData?.cashReciepts_prev - totalData?.cashPayment_prev,
            netCash_inc: totalData?.cashReciepts_inc - totalData?.cashPayment_inc,
            monthEndCash_cur: allValues?.beginBalance_cur + totalData?.cashReciepts_cur - totalData?.cashPayment_cur,
            monthEndCash_prev: allValues?.beginBalance_prev + totalData?.cashReciepts_prev - totalData?.cashPayment_prev,
            monthEndCash_inc: allValues?.beginBalance_inc + totalData?.cashReciepts_inc - totalData?.cashPayment_inc,
        }))
    }

    // send mail
    const sendCapatialMail = async (emailData, oncloseSendModal) => {
        const data = {
            "email": emailData?.email,
            "language": currentLanguage === "eng" ? "EN" : "ES",
            "report_Name": "Cash Flow",
            "company_Name": profileData?.companyName,
            "report_GenerateBy": profileData?.name + " " + profileData?.surName,
            "report_GeneratDate": moment().format("YYYY-MM-DD"),
            "report_Period": {
                "startDate": moment().format("YYYY-MM-DD"),
                "endDate": moment().format("YYYY-MM-DD")
            },
            "Beginning_Balance": {
                "current_period": allValues?.beginBalance_cur,
                "previous_period": allValues?.beginBalance_prev,
                "increase_decrease": allValues?.beginBalance_inc
            },
            "Cash_Receipts": cashReciepts,
            "Cash_Payments_CostOfGoods": cashPayment,
            "Operating_Expenses": operatingExp,
            "Additional_Expenses": addCashExp,
            "Total_Cash_Payments": {
                "current_period": allValues?.totalCash_cur,
                "previous_period": allValues?.totalCash_prev,
                "increase_decrease": allValues?.totalCash_inc
            },
            "Net_Cash_Change": {
                "current_period": allValues?.netCash_cur,
                "previous_period": allValues?.netCash_prev,
                "increase_decrease": allValues?.netCash_inc
            },
            "Month_Ending_Cashposition": {
                "current_period": allValues?.monthEndCash_cur,
                "previous_period": allValues?.monthEndCash_prev,
                "increase_decrease": allValues?.monthEndCash_inc
            }
        }

        oncloseSendModal();
        setIsLoading(true);
        const res = await HttpClient.requestData("reportbuilder/pdf/cash-flow", "POST", data)
        if (res && res?.status) {
            toast?.success(language.mail_sent_success);
            setIsLoading(false);
        } else {
            setIsLoading(false);
        }
    }


    // calculate sum
    const calculateTotal = (arr, name) => {
        const sum = arr?.reduce((acc, cur) => {
            return Number(acc) + Number(cur[name])
        }, 0)
        return sum
    }

    // get report
    const getCashFlowReport = async () => {
        setIsLoading(true)
        const res = await HttpClient.requestData("reportbuilder-view-cashflow", "GET", {})
        // console.log("resddfg", res?.data?.Cash_Receipts)
        setIsLoading(false)
        if (res && res?.status) {
            const resData = res?.data
            setAllValues({
                beginBalance_cur: resData?.Beginning_Balance?.current_period,
                beginBalance_prev: resData?.Beginning_Balance?.previous_period,
                beginBalance_inc: resData?.Beginning_Balance?.increase_decrease,
                totalCash_cur: resData?.Total_Cash_Payments?.current_period,
                totalCash_prev: resData?.Total_Cash_Payments?.previous_period,
                totalCash_inc: resData?.Total_Cash_Payments?.increase_decrease,
                netCash_cur: resData?.Net_Cash_Change?.current_period,
                netCash_prev: resData?.Net_Cash_Change?.previous_period,
                netCash_inc: resData?.Net_Cash_Change?.increase_decrease,
                monthEndCash_cur: resData?.Month_Ending_Cashposition?.current_period,
                monthEndCash_prev: resData?.Month_Ending_Cashposition?.increase_decrease,
                monthEndCash_inc: resData?.Month_Ending_Cashposition?.previous_period,
            })

            // console.log("Cash_Receiptsff", resData?.Cash_Receipts)
            if (resData?.Cash_Receipts) {
                setCashReciepts(resData?.Cash_Receipts);
            }

            if (resData?.Cash_Payments_CostOfGoods) {
                setCashPayment(resData?.Cash_Payments_CostOfGoods);
            }

            if (resData?.Operating_Expenses) {
                setOperatingExp(resData?.Operating_Expenses);
            }

            if (resData?.Additional_Expenses) {
                setAddCashExp(resData?.Additional_Expenses);
            }
        }
    }

    // dowmload pdf
    const handlePdfDownload = async () => {
        // if (isSave) {
        //     toast.error("Please Save First");
        //     return
        // }

        setPdfLoader(true);
        const res = await HttpClient.requestData("reportbuilder/download/pdf/cash-flow", "GET", {})
        setPdfLoader(false);
        if (res && res?.status) {
            window.open(res?.data, "_blank")
        } else {
            toast.error(res?.message || "Something Wrong!")
        }
    }



    useEffect(() => {
        getCashFlowReport();
        setPdfCreate(() => handlePdfDownload);

    }, [])


    useEffect(() => {

        if (!isSave) {
            const arrCashReciept = cashReciepts?.map((item) => {
                return Object.values(item).slice(0, 4)
            })

            const arrCashPayment = cashPayment?.map((item) => {
                return Object.values(item).slice(0, 4)
            })

            const arrCashOperatingExp = operatingExp?.map((item) => {
                return Object.values(item).slice(0, 4)
            })
            // console.log("arrCashOperatingExp", arrCashOperatingExp)

            const arrAddExp = addCashExp?.map((item) => {
                return Object.values(item).slice(0, 4)
            })

            const finalData = [
                [],
                ["", "Current Period", "Previous Period", "Increase (or Decrease)"],
                ["BEGINNING BALANCE | CASH ON HAND", allValues.beginBalance_cur, allValues.beginBalance_prev, allValues.beginBalance_inc],
                [],

                [language.CASH_RECEIPTS],
                ...arrCashReciept,
                ["TOTAL CASH RECEIPTS", totalData.cashReciepts_cur, totalData.cashReciepts_prev, totalData.cashReciepts_inc],
                [],

                [language.CASH_PAYMENTS + " " + language.COST_GOODS_SOLD],
                ...arrCashPayment,
                ["TOTAL COST OF GOODS SOLD", totalData.cashPayment_cur, totalData.cashPayment_prev, totalData.cashPayment_inc],
                [],

                [language.OPERATING_EXPENSES],
                ...arrCashOperatingExp,
                ["TOTAL OPERATING EXPENSES", totalData.operatingExp_cur, totalData.operatingExp_prev, totalData.operatingExp_inc],
                [],

                [language.ADDITIONAL_EXPENSES],
                ...arrAddExp,
                ["TOTAL ADDITIONAL EXPENSES", totalData.addCashExp_cur, totalData.addCashExp_prev, totalData.addCashExp_inc],
                [],

                ["TOTAL CASH PAYMENTS", allValues.totalCash_cur, allValues.totalCash_prev, allValues.totalCash_inc],
                ["NET CASH CHANGE", allValues.netCash_cur, allValues.netCash_prev, allValues.netCash_inc],
                ["MONTH ENDING CASH POSITION", allValues.monthEndCash_cur, allValues.monthEndCash_prev, allValues.monthEndCash_inc],
                []
            ];

            // console.log("finalData", finalData);
            setCashflowCsv(finalData);

            // this saves the pdf download function in a state
            // setPdfCreate(() => handleBildReport);
        }

        // send
        setEmailSendFunc(() => sendCapatialMail)
    }, [isSave])


    // calculation useeffrct
    useEffect(() => {
        setTotalData(prev => ({
            ...prev,
            cashReciepts_cur: calculateTotal(cashReciepts, "current_period"),
            cashReciepts_prev: calculateTotal(cashReciepts, "previous_period"),
            cashReciepts_inc: calculateTotal(cashReciepts, "increase_decrease"),
        }))
    }, [cashReciepts])

    useEffect(() => {
        setTotalData(prev => ({
            ...prev,
            cashPayment_cur: calculateTotal(cashPayment, "current_period"),
            cashPayment_prev: calculateTotal(cashPayment, "previous_period"),
            cashPayment_inc: calculateTotal(cashPayment, "increase_decrease"),
        }))
    }, [cashPayment])

    useEffect(() => {
        setTotalData(prev => ({
            ...prev,
            operatingExp_cur: calculateTotal(operatingExp, "current_period"),
            operatingExp_prev: calculateTotal(operatingExp, "previous_period"),
            operatingExp_inc: calculateTotal(operatingExp, "increase_decrease"),
        }))
    }, [operatingExp])

    useEffect(() => {
        setTotalData(prev => ({
            ...prev,
            addCashExp_cur: calculateTotal(addCashExp, "current_period"),
            addCashExp_prev: calculateTotal(addCashExp, "previous_period"),
            addCashExp_inc: calculateTotal(addCashExp, "increase_decrease"),
        }))
    }, [addCashExp])




    return (
        <>
            <div className='cashFlwTableSec'>

                <div className="cashFlwTblInn">
                    <button class="cashFlwAddBtn" style={{ visibility: 'hidden', }}>
                        <i class="fa-solid fa-plus"></i>
                    </button>

                    {/* BEGINNING BALANCE | CASH ON HAND */}
                    <div className='cashFlwRest'>
                        <table>
                            <thead>
                                <th></th>
                                <th>Current Period</th>
                                <th>Previous Period</th>
                                <th>Increase (or Decrease)</th>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>BEGINNING BALANCE | CASH ON HAND</td>
                                    <td>
                                        <input
                                            type="number"
                                            placeholder='$0.00'
                                            name='beginBalance_cur'
                                            value={allValues.beginBalance_cur}
                                            onChange={(e) => handleAllChange(e)}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            placeholder='$0.00'
                                            name='beginBalance_prev'
                                            value={allValues.beginBalance_prev}
                                            onChange={(e) => handleAllChange(e)}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            placeholder='$0.00'
                                            name='beginBalance_inc'
                                            value={allValues.beginBalance_inc}
                                            onChange={(e) => handleAllChange(e)}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                </div>

                {/*  CASH RECEIPTS */}
                <div className="cashFlwTblInn">
                    <button
                        class="cashFlwAddBtn"
                        onClick={() => setCashReciepts(prev => [...prev, initCashValues])}
                    >
                        <i class="fa-solid fa-plus"></i>
                    </button>
                    <div className='cashFlwRest'>
                        <div className="cashFlwTblTop">
                            <h5 className='buildCshFlwhead'>(+) {language.CASH_RECEIPTS}</h5>
                        </div>

                        <table>
                            <tbody>
                                {
                                    cashReciepts?.map((item, i, arr) =>
                                        <tr>
                                            <td>
                                                <button
                                                    class="cshFlwRemoveBtn"
                                                    onClick={() => setCashReciepts(prev => prev?.filter((ele, ind) => ind !== i))}
                                                >
                                                    <i class="fa-solid fa-minus"></i>
                                                </button>
                                                <input
                                                    type="text"
                                                    placeholder='Title'
                                                    name='title'
                                                    value={item?.title}
                                                    onChange={(e) => handleAddMore(e, i, setCashReciepts)}
                                                />
                                            </td>
                                            <td>
                                                <input
                                                    type="number"
                                                    // placeholder='$0.00'
                                                    name='current_period'
                                                    value={item?.current_period}
                                                    onChange={(e) => {
                                                        handleAddMore(e, i, setCashReciepts);
                                                        // calculateTotal(arr, i)
                                                    }
                                                    }
                                                />
                                            </td>
                                            <td>
                                                <input
                                                    type="number"
                                                    // placeholder='$0.00'
                                                    name='previous_period'
                                                    value={item?.previous_period}
                                                    onChange={(e) => handleAddMore(e, i, setCashReciepts)}
                                                />
                                            </td>
                                            <td>
                                                <input
                                                    type="number"
                                                    // placeholder='$0.00'
                                                    name='increase_decrease'
                                                    value={item?.increase_decrease}
                                                    onChange={(e) => handleAddMore(e, i, setCashReciepts)}
                                                />
                                            </td>
                                        </tr>
                                    )
                                }

                                {/* TOTAL CASH RECEIPTS */}
                                <tr>
                                    <td className='cfBigtxt'>TOTAL CASH RECEIPTS</td>
                                    <td>
                                        <input
                                            type="number"
                                            placeholder='$0.00'
                                            name='cashReciepts_cur'
                                            value={totalData?.cashReciepts_cur}
                                            onChange={(e) => handleTotalChange(e)}
                                            readOnly
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            placeholder='$0.00'
                                            name='cashReciepts_prev'
                                            value={totalData?.cashReciepts_prev}
                                            onChange={(e) => handleTotalChange(e)}
                                            readOnly
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            placeholder='$0.00'
                                            name='cashReciepts_inc'
                                            value={totalData?.cashReciepts_inc}
                                            onChange={(e) => handleTotalChange(e)}
                                            readOnly
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                {/* CASH PAYMENTS & (-) COST OF GOODS SOLD */}
                <div className="cashFlwTblInn">
                    <button
                        class="cashFlwAddBtn"
                        onClick={() => setCashPayment(prev => [...prev, initCashValues])}
                    >
                        <i class="fa-solid fa-plus"></i>
                    </button>
                    <div className='cashFlwRest'>
                        <div className="cashFlwTblTop">
                            <h5 className='buildCshFlwhead'>(-) {language.CASH_PAYMENTS} & (-) {language.COST_GOODS_SOLD}</h5>
                        </div>

                        <table>
                            <tbody>
                                {
                                    cashPayment?.map((item, i) =>
                                        <tr key={i}>
                                            <td>
                                                <button
                                                    class="cshFlwRemoveBtn"
                                                    onClick={() => setCashPayment(prev => prev?.filter((ele, ind) => ind !== i))}
                                                >
                                                    <i class="fa-solid fa-minus"></i>
                                                </button>
                                                <input
                                                    type="text"
                                                    placeholder='Title'
                                                    name='title'
                                                    value={item?.title}
                                                    onChange={(e) => handleAddMore(e, i, setCashPayment)}
                                                />
                                            </td>
                                            <td>
                                                <input
                                                    type="number"
                                                    // placeholder='$0.00'
                                                    name='current_period'
                                                    value={item?.current_period}
                                                    onChange={(e) => handleAddMore(e, i, setCashPayment)}
                                                />
                                            </td>
                                            <td>
                                                <input
                                                    type="number"
                                                    // placeholder='$0.00'
                                                    name='previous_period'
                                                    value={item?.previous_period}
                                                    onChange={(e) => handleAddMore(e, i, setCashPayment)}
                                                />
                                            </td>
                                            <td>
                                                <input
                                                    type="number"
                                                    // placeholder='$0.00'
                                                    name='increase_decrease'
                                                    value={item?.increase_decrease}
                                                    onChange={(e) => handleAddMore(e, i, setCashPayment)}
                                                />
                                            </td>
                                        </tr>
                                    )
                                }

                                <tr>
                                    <td className='cfBigtxt'>TOTAL COST OF GOODS SOLD</td>
                                    <td>
                                        <input
                                            type="number"
                                            // placeholder='$0.00'
                                            name='cashPayment_cur'
                                            value={totalData?.cashPayment_cur}
                                            onChange={(e) => handleTotalChange(e)}
                                            readOnly
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            // placeholder='$0.00'
                                            name='cashPayment_prev'
                                            value={totalData?.cashPayment_prev}
                                            onChange={(e) => handleTotalChange(e)}
                                            readOnly
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            // placeholder='$0.00'
                                            name='cashPayment_inc'
                                            value={totalData?.cashPayment_inc}
                                            onChange={(e) => handleTotalChange(e)}
                                            readOnly
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                {/*  OPERATING EXPENSES */}
                <div className="cashFlwTblInn">
                    <button
                        class="cashFlwAddBtn"
                        onClick={() => setOperatingExp(prev => [...prev, initCashValues])}
                    >
                        <i class="fa-solid fa-plus"></i>
                    </button>
                    <div className='cashFlwRest'>
                        <div className="cashFlwTblTop">
                            <h5 className='buildCshFlwhead'>(-) {language.OPERATING_EXPENSES}</h5>
                        </div>

                        <table>
                            <tbody>
                                {
                                    operatingExp?.map((item, i) =>
                                        <tr>
                                            <td>
                                                <button
                                                    class="cshFlwRemoveBtn"
                                                    onClick={() => setOperatingExp(prev => prev?.filter((ele, ind) => ind !== i))}
                                                >
                                                    <i class="fa-solid fa-minus"></i>
                                                </button>
                                                <input
                                                    type="text"
                                                    placeholder='Title'
                                                    name='title'
                                                    value={item?.title}
                                                    onChange={(e) => handleAddMore(e, i, setOperatingExp)}
                                                />
                                            </td>
                                            <td>
                                                <input
                                                    type="number"
                                                    // placeholder='$0.00'
                                                    name='current_period'
                                                    value={item?.current_period}
                                                    onChange={(e) => handleAddMore(e, i, setOperatingExp)}
                                                />
                                            </td>
                                            <td>
                                                <input
                                                    type="number"
                                                    // placeholder='$0.00'
                                                    name='previous_period'
                                                    value={item?.previous_period}
                                                    onChange={(e) => handleAddMore(e, i, setOperatingExp)}
                                                />
                                            </td>
                                            <td>
                                                <input
                                                    type="number"
                                                    // placeholder='$0.00'
                                                    name='increase_decrease'
                                                    value={item?.increase_decrease}
                                                    onChange={(e) => handleAddMore(e, i, setOperatingExp)}
                                                />
                                            </td>
                                        </tr>
                                    )
                                }

                                <tr>
                                    <td className='cfBigtxt'>TOTAL OPERATING EXPENSES</td>
                                    <td>
                                        <input
                                            type="number"
                                            // placeholder='$0.00'
                                            name='operatingExp_cur'
                                            value={totalData?.operatingExp_cur}
                                            onChange={(e) => handleTotalChange(e)}
                                            readOnly
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            // placeholder='$0.00'
                                            name='operatingExp_cur'
                                            value={totalData?.operatingExp_prev}
                                            onChange={(e) => handleTotalChange(e)}
                                            readOnly
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            // placeholder='$0.00'
                                            name='operatingExp_cur'
                                            value={totalData?.operatingExp_inc}
                                            onChange={(e) => handleTotalChange(e)}
                                            readOnly
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                {/* ADDITIONAL EXPENSES */}
                <div className="cashFlwTblInn">
                    <button
                        class="cashFlwAddBtn"
                        onClick={() => setAddCashExp(prev => [...prev, initCashValues])}

                    >
                        <i class="fa-solid fa-plus"></i>
                    </button>
                    <div className='cashFlwRest'>
                        <div className="cashFlwTblTop">
                            <h5 className='buildCshFlwhead'>(-) {language.ADDITIONAL_EXPENSES}</h5>
                        </div>

                        <table>

                            {/*  ADDITIONAL EXPENSES */}
                            <tbody>
                                {
                                    addCashExp?.map((item, i) =>
                                        <tr>
                                            <td>
                                                <button
                                                    class="cshFlwRemoveBtn"
                                                    onClick={() => setAddCashExp(prev => prev?.filter((ele, ind) => ind !== i))}
                                                >
                                                    <i class="fa-solid fa-minus"></i>
                                                </button>
                                                <input
                                                    type="text"
                                                    placeholder='Title'
                                                    name='title'
                                                    value={item?.title}
                                                    onChange={(e) => handleAddMore(e, i, setAddCashExp)}
                                                />
                                            </td>
                                            <td>
                                                <input
                                                    type="number"
                                                    // placeholder='$0.00'
                                                    name='current_period'
                                                    value={item?.current_period}
                                                    onChange={(e) => handleAddMore(e, i, setAddCashExp)}
                                                />
                                            </td>
                                            <td>
                                                <input
                                                    type="number"
                                                    // placeholder='$0.00'
                                                    name='previous_period'
                                                    value={item?.previous_period}
                                                    onChange={(e) => handleAddMore(e, i, setAddCashExp)}
                                                />
                                            </td>
                                            <td>
                                                <input
                                                    type="number"
                                                    // placeholder='$0.00'
                                                    name='increase_decrease'
                                                    value={item?.increase_decrease}
                                                    onChange={(e) => handleAddMore(e, i, setAddCashExp)}
                                                />
                                            </td>
                                        </tr>
                                    )
                                }

                                <tr>
                                    <td className='cfBigtxt'>TOTAL ADDITIONAL EXPENSES</td>
                                    <td>
                                        <input
                                            type="number"
                                            // placeholder='$0.00'
                                            name='addCashExp_cur'
                                            value={totalData?.addCashExp_cur}
                                            onChange={(e) => handleTotalChange(e)}
                                            readOnly
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            // placeholder='$0.00'
                                            name='addCashExp_prev'
                                            value={totalData?.addCashExp_prev}
                                            onChange={(e) => handleTotalChange(e)}
                                            readOnly
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            // placeholder='$0.00'
                                            name='addCashExp_inc'
                                            value={totalData?.addCashExp_inc}
                                            onChange={(e) => handleTotalChange(e)}
                                            readOnly
                                        />
                                    </td>
                                </tr>
                            </tbody>

                            {/* bottom table */}
                            <tbody>

                                {/* TOTAL CASH PAYMENTS */}
                                <tr>
                                    <td className='cfBigtxt' style={{ textAlign: 'end', color: '#e46c0a', }}>TOTAL CASH PAYMENTS</td>
                                    <td>
                                        <input
                                            type="number"
                                            style={{ color: '#e46c0a', fontWeight: '700', }}
                                            placeholder='$0.00'
                                            name='totalCash_cur'
                                            value={allValues.totalCash_cur}
                                            onChange={(e) => handleAllChange(e)}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            style={{ color: '#e46c0a', fontWeight: '700', }}
                                            placeholder='$0.00'
                                            name='totalCash_prev'
                                            value={allValues.totalCash_prev}
                                            onChange={(e) => handleAllChange(e)}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            style={{ color: '#e46c0a', fontWeight: '700', }}
                                            placeholder='$0.00'
                                            name='totalCash_inc'
                                            value={allValues.totalCash_inc}
                                            onChange={(e) => handleAllChange(e)}
                                        />
                                    </td>
                                </tr>

                                {/* NET CASH CHANGE */}
                                <tr>
                                    <td className='cfBigtxt' style={{ textAlign: 'end', color: '#00b050', }}>NET CASH CHANGE
                                        <span className='cfSmallTxt'>(cash receipts – cash payments)</span>
                                    </td>
                                    <td>
                                        <input
                                            style={{ color: '#00b050', fontWeight: '700', }}
                                            type="number"
                                            placeholder='$0.00'
                                            name='netCash_cur'
                                            value={allValues.netCash_cur}
                                            onChange={(e) => handleAllChange(e)}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            style={{ color: '#00b050', fontWeight: '700', }}
                                            type="number"
                                            placeholder='$0.00'
                                            name='netCash_prev'
                                            value={allValues.netCash_prev}
                                            onChange={(e) => handleAllChange(e)}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            style={{ color: '#00b050', fontWeight: '700', }}
                                            type="number"
                                            placeholder='$0.00'
                                            name='netCash_inc'
                                            value={allValues.netCash_inc}
                                            onChange={(e) => handleAllChange(e)}
                                        />
                                    </td>
                                </tr>

                                {/* MONTH ENDING CASH POSITION */}
                                <tr>
                                    <td className='cfBigtxt' style={{ textAlign: 'end', color: '#00b0f0', }}>MONTH ENDING CASH POSITION
                                        <span className='cfSmallTxt'>(cash on hand + cash receipts – cash payments)</span>
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            style={{ color: '#00b0f0', fontWeight: '700', }}
                                            placeholder='$0.00'
                                            name='monthEndCash_cur'
                                            value={allValues.monthEndCash_cur}
                                            onChange={(e) => handleAllChange(e)}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            style={{ color: '#00b0f0', fontWeight: '700', }}
                                            placeholder='$0.00'
                                            name='monthEndCash_prev'
                                            value={allValues.monthEndCash_prev}
                                            onChange={(e) => handleAllChange(e)}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            style={{ color: '#00b0f0', fontWeight: '700', }}
                                            placeholder='$0.00'
                                            name='monthEndCash_inc'
                                            value={allValues.monthEndCash_inc}
                                            onChange={(e) => handleAllChange(e)}
                                        />
                                    </td>
                                </tr>

                            </tbody>

                        </table>

                    </div>
                </div>


                {/* button group */}
                <div class="d-flex justify-content-center mt-5">
                    {
                        isSave
                            ?
                            <button
                                class="btn-report-save"
                                onClick={() => handleSave()}
                            >
                                {language.save.toUpperCase()}
                            </button>
                            :
                            <button
                                class="btn-report-save"
                                onClick={() => setIsSave(true)}
                            >
                                {language.edit}
                            </button>
                    }

                    <button
                        class="btn-report-build"
                        onClick={() => handleBildReport()}
                    >
                        {language?.build_report?.toUpperCase()}
                    </button>
                </div>

            </div >

        </>
    )
}

export default BuildCashflow
