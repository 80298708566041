import React, { useEffect, useRef } from 'react'

function Dropbox({ text = "", data = [], getValue, transactionFilter }) {
    const selectRef = useRef()
    // console.log('dataproject',data);
    useEffect(() => {
        if (transactionFilter) {
            if (transactionFilter.projectName === "" || transactionFilter.project === "") {
                selectRef.current.selectedIndex = "0"
            }
        }
    }, [transactionFilter])
    return (

        <>

            <div id="dropbox">
                <div className='container'>
                    <div className='inner-container'>
                        <form>
                            <div class="form-group">
                                <select class="form-control" id="exampleFormControlSelect1" ref={selectRef} onChange={getValue}>
                                    <option>{text}</option>
                                    {
                                        data.map((d, i) => (
                                            <option value={d.projectName}>{d.projectName}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Dropbox