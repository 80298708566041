import React from "react";
import a from "../Images/3-Problem-With-Calendar.png";
export default function SpreadSheet({ goToRegistration }) {
  return (
    <div className="inner">
      <div className="SpreadSheet">
        <div className="lftImg">
          <img src={a} alt="" />
        </div>
        <div className="rgtCnt">
          <div className="bottom_cnt">
            <div className="wrapCnt">
              <h4>
                Problem With Calendars & Spreadsheets Is They Don’t Talk to Each
                Other!
              </h4>
              <ul>
                <li>You can’t easily correlate Tasks to Profitability</li>
                <li>
                  Your Calendar shows your efforts, but lacks productivity Intel
                </li>
                <li>
                  This is preventing you from doing more in less time without all
                  the stress
                </li>
              </ul>
              <a onClick={() => goToRegistration()} className="read-more">
                Try FISIBILITY Instead
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}
