import Accordion from 'react-bootstrap/Accordion';

function CustomAccordion({ data,values,setValues }) {
    return (
        <Accordion flush>
            {
                data.map((d, i) => (
                    <Accordion.Item eventKey={i.toString()}>
                        <Accordion.Header>{d.title}</Accordion.Header>
                        <Accordion.Body>
                          <input className='form-control' type="text" placeholder={d.title}
                           value={values[d.field]}
                           onChange={(e)=>setValues(prev=>({...prev,[d.field]:e.target.value}))}
                            />
                        </Accordion.Body>
                    </Accordion.Item>
                ))
            }
        </Accordion>
    );
}

export default CustomAccordion;