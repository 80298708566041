import React, { useState } from 'react'
import { Offcanvas } from 'react-bootstrap'
import Sidebar1 from '../Layout/SideBar/Sidebar1'
import Sidebar2 from '../Layout/SideBar/Sidebar2'
import Sidebar3 from '../Layout/SideBar/Sidebar3'
import Sidebar4 from '../Layout/SideBar/Sidebar4'
import { useLanguage } from '../Providers/LanguageContext'
import { AiOutlineClose } from "react-icons/ai"
import CustomModal from './CustomModal'
import ChangePassword from './changepass/ChangePassword'
import Sidebar5 from '../Layout/SideBar/Sidebar5'
function OffcanvasComp({ show, handleClose }) {
  const { language } = useLanguage();

  const [modalIsOpen, setIsOpen] = useState(false);

  function openModal() {
    setIsOpen(true);

  }


  function closeModal() {
    setIsOpen(false);
  }

  // v
  return (
    <>
      <Offcanvas show={show} onHide={handleClose} placement="end">

        <div style={{ overflowX: "scroll" }}>
          <Offcanvas.Header closeButton style={{ justifyContent: "flex-end", alignItems: "flex-end" }}>
            {/* <Offcanvas.Title>Offcanvas</Offcanvas.Title> */}
          </Offcanvas.Header>

          <Offcanvas.Body>
            <Sidebar1 />
            <Sidebar2 openModal={openModal} />
            <Sidebar3 />
            <Sidebar4 />
            <Sidebar5 />
          </Offcanvas.Body>
        </div>

      </Offcanvas>


      <CustomModal
        modalIsOpen={modalIsOpen}
        setIsOpen={setIsOpen}
        openModal={openModal}
        closeModal={closeModal}
      >
        <div>
          <div className='d-flex justify-content-between text-secondary'>
            <span>
              {language.change_password}
            </span>
            <span>
              <AiOutlineClose className='icon' onClick={() => {
                closeModal();
              }} size={20} color="gray" />
            </span>
          </div>
          <ChangePassword
            closeModal={closeModal}
          />
        </div>
      </CustomModal>
    </>
  )
}

export default OffcanvasComp