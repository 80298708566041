import React from 'react'
import Banner2 from "../../images/banner-bg-2.png";

export default function Banner1() {
    return (
        <>

            {/* <section id='banner_area' style={{ backgroundImage: `url('${Banner2}')` }}>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-6 col-md-12 col-12'>
                            <div className='left_cnt'>
                                <div className='head'>
                                    <h2>Gestiona</h2>
                                    <h2>tus Finanzas</h2>
                                    <h2 style={{ color: "#3b3b3a" }}>Como un</h2>
                                    <h2 style={{ color: "#3b3b3a" }}>Profesional </h2>
                                </div> */}

                                {/* <div className='text'>
                                    <p>Disponer de una supervisión del rendimiento 24/7/365 con notificaciones de alerta en tiempo real le dá la tranquilidad de que su negocio y su capital están protegidos en todo momento.</p>
                                </div> */}

                                {/* <div className='bottom'>
                                    <div className='registrarse_btn'>
                                        <button className='btn'>REGISTRARSE</button>
                                    </div>
                                    <div className='descargar_btn'>
                                        <button className='btn'>DESCARGAR</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6 col-md-12 col-12'>
                            <div className='right_cnt'></div>
                        </div>
                    </div>
                </div>
            </section> */}

        </>
    )
}
