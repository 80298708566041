import React, { useCallback, useEffect, useMemo, useState } from 'react';
import fisiIconDrop from "../../../images/FisTaskIcon.png";
import HttpClient from '../../../utils/HttpClient';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import EventPopUp from '../EventPopUp';
import { all } from 'axios';


const EventListUser = ({ allData, setAllData }) => {


  const [teamData, setTeamData] = useState([]);
  const [allSubUser, SetAllSubUSer] = useState([]);

  const [modalb, setModalb] = useState(false);
  const [eventDetailData, setEventDetailData] = useState({});

  // console.log("teamDataevv", teamData)

  // it removes duplicate from
  const removeDuplicate = useCallback((arr) => {
    const unique = arr.filter((obj, index) => {
      return index === arr.findIndex(o => obj?.linkedUser === o?.linkedUser);
    });
    return unique;
  })

  // getting assigned users
  const getSubUSer = async () => {
    const res = await HttpClient.requestData("user-management", "GET")
    // console.log("resUser", res);
    if (res && res?.status) {
      SetAllSubUSer(res?.data);
    }
    else {

    }
  }

  useEffect(() => {
    getSubUSer();
  }, [])


  useEffect(() => {
    const unique = allData.filter((obj, index, arr) => {
      return index === arr.findIndex(o => obj?.assignToUserName === o?.assignToUserName);
    });

    const arr = allSubUser.map(item => {
      const newArr = allData?.filter(ele => ele?.assignToUser === item?._id)
      return ({ name: item?.name, subuserId: item?._id, data: newArr })
    })
    const notAssigned = allData?.filter(ele => !ele?.assignToUser)
    setTeamData([{ name: "Not-Assigned", subuserId: null, data: notAssigned }, ...arr]);
  }, [allData, allSubUser])



  // ********************drag and drop*******************
  const grid = 4;

  // when moves in a single list or column
  const reorder = (list, startIndex, endIndex) => {
    console.log('list_startIndex_endIndex', list, startIndex, endIndex)
    const result = Array.from(list);
    // console.log('resultreorder', result, list)
    const [removed] = result.splice(startIndex, 1);
    // console.log('removedreorder', removed)
    result.splice(endIndex, 0, removed);

    return result;
  };


  // Moves an item from one list to another list.
  const move = (source, destination, droppableSource, droppableDestination) => {
    // console.log('source_destination', source, destination, droppableSource, droppableDestination)
    const sourceClone = Array.from(source?.data);
    const destClone = Array.from(destination?.data);

    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    // console.log('droppableSource', droppableSource.droppableId, droppableDestination.droppableId)
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;
    // console.log('resultMove', result)
    return result;
  };


  //drag end: this function calles aftert drag end
  const onDragEnd = (result) => {
    const { source, destination } = result;
    // console.log("onDragEndresult", result)
    // dropped outside the list
    if (!destination) {
      return;
    }
    const sInd = +source.droppableId;
    const dInd = +destination.droppableId;
    // console.log('sInd_dInd', sInd, dInd);

    if (sInd === dInd) {
      // const items = reorder(allData[sInd], source.index, destination.index);
      // // console.log('itemsReorder', items)
      // const newState = [...allData];
      // newState[sInd] = items;

      // setTeamData(newState);
    } else {
      const resultArr = move(teamData[sInd], teamData[dInd], source, destination);

      // console.log("resultArrdf", resultArr)

      const newState = [...teamData];
      newState[sInd]["data"] = resultArr[sInd];
      newState[dInd]["data"] = resultArr[dInd];

      // console.log("resultmovept", teamData, newState)
      // setState(newState.filter(group => group.length));
      setTeamData(newState);

      updateStatus(result?.draggableId, teamData[dInd]);
    }
  }

  const updateStatus = async (id, destData) => {
    // console.log("destdata", destData)
    const data = {
      assignToUser: destData?.subuserId
    }
    // console.log("destDatadff", destData)
    const res = await HttpClient.requestData("update-event-eventDetails/" + id, "PUT", data)
    if (res && res?.status) {
      // setProjectAllData()
      // getTaskData();
    }
  }

  // add style to the each list or column
  const getListStyle = isDraggingOver => {
    // console.log("isDraggingOver", isDraggingOver)
    return {
      background: isDraggingOver ? "#e5dfdf" : "lightgrey",
      padding: grid,
      width: 141,
    }
  };

  // adds style to the each row
  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid,
    margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    background: isDragging ? "lightgreen" : "#fff",
    borderTop: "2px solid #c9c9c9",
    // styles we need to apply on draggables
    ...draggableStyle
  });
  // ********************End drag and drop*******************



  return (
    <div>
      {/* Single tab part --> Don't delete the below part */}
      <div className="addTaskTestDiv">
        <DragDropContext onDragEnd={onDragEnd}>
          {teamData &&
            teamData?.map((item, ind) => {
              return (
                <div className="addinnerTskDivOne addinnerTskDiv">
                  <div className="addInClrHeadDiv">
                    <span className="addInClrNumSpan">{item?.name ? item?.name : 'Not Assigned'}</span>
                    <span className="addInClrNumSpan">{item?.length}</span>
                  </div>

                  <Droppable key={ind} droppableId={`${ind}`}>
                    {(provided, snapshot) => {
                      return (
                        <div
                          ref={provided.innerRef}
                          style={getListStyle(snapshot.isDraggingOver)}
                          {...provided.droppableProps}
                        >
                          <div className="addMinTaskDiv">
                            {item &&
                              item?.data?.map((ele, index) => {
                                return (
                                  <div key={index}>
                                    <Draggable key={ele?._id} draggableId={ele?._id} index={index}>
                                      {(provided, snapshot) => (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                        >
                                          <div
                                            className="addInTextsDiv"
                                            onClick={() => {
                                              setEventDetailData(ele);
                                              setModalb(true);
                                            }}
                                          >
                                            <p className="addMinPrepText">{ele?.eventName}</p>
                                            <div className="addAssiTextDiv">
                                              {/* <div
                                                            className="addAssiCircleDiv"
                                                            style={{ backgroundColor: "rgb(46, 195, 247)" }}
                                                        ></div> */}
                                              <div> </div>
                                              <figure className="smallFisiIconFig">
                                                <img src={fisiIconDrop} alt="..." />
                                              </figure>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </Draggable>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      );
                    }}
                  </Droppable>

                  {/* <div>
                                        <button className="addInTaskBtn">+ Add New Task</button>
                                    </div> */}
                </div>
              );
            })}
        </DragDropContext>
      </div>
      {modalb && <EventPopUp closemodal={setModalb} detailData={eventDetailData} getEventData={allData} />}
    </div>
  );
}

export default EventListUser;
