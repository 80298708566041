import React, { useContext, useEffect, useRef, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useLanguage } from '../Providers/LanguageContext'
import HttpClient from '../utils/HttpClient'
import budget_en_1 from "../Audio_files/IV. Budget Manager Audio/IV a. Setup My Budget User Selected Option/iv_setup_my_budget_audio_a.mp3";
import budget_en_2 from "../Audio_files/IV. Budget Manager Audio/IV a. Setup My Budget User Selected Option/iv_setup_my_budget_audio_b.mp3";
import budget_es_1 from "../spanish_audio_files/IV. Budget Manager Audio/IV a. Setup My Budget User Selected Option/iv_setup_my_budget_audio_a_es.mp3";
import budget_es_2 from "../spanish_audio_files/IV. Budget Manager Audio/IV a. Setup My Budget User Selected Option/iv_setup_my_budget_audio_b_es.mp3";
import { SpeechContext } from '../Providers/SpeechWrapper';

function Budget({ time, setTime }) {
    const { language, currentLanguage } = useLanguage()
    const { currAudio, setCurrAudio, playing, setShowGif, setGifToShow, setGifPoints } = useContext(SpeechContext);
    const [isSubmitted, setIsSubmitted] = useState(false);

    const selectRef = useRef();
    const [values, setValues] = useState({
        budgetAmount: "",
        budgetItem: "",
        budgetPeriod: "",
        projectName: "",
        startProjectDate: "",
        endProjectDate: ""
    })

    const handleBudgetItem = (e) => {
        if (e.target.value !== language.budget_itemm) {
            setValues(prev => ({ ...prev, budgetItem: e.target.value }))
        }
    }

    useEffect(() => {
        setValues(prev => ({ ...prev, budgetPeriod: time }))
    }, [time])

    const handleBudgetPost = async () => {
        if (!values.budgetAmount || !values.budgetItem || !values.budgetPeriod) {
            toast.error(language.please_enter);
            return
        }
        const sendData = {
            budgetAmount: values.budgetAmount,
            budgetItem: values.budgetItem.toLowerCase(),
            budgetPeriod: values.budgetPeriod,
            projectName: "",
            startProjectDate: "",
            endProjectDate: ""
        }
        console.log('click', values.budgetItem)
        const res = await HttpClient.requestData("add-budget", "POST", sendData);
        console.log('trackRes', res);
        if (res && res.status) {
            toast.success(language.budget_added)
            if (values.budgetItem === 'Sales' || values.budgetItem === 'Expense') {
                setGifPoints(50)
                setTimeout(() => {
                    setGifPoints(0)
                }, 3000);
            }
            setTime("")
            setValues({
                budgetAmount: "",
                budgetItem: "",
                budgetPeriod: "",
                projectName: "",
                startProjectDate: "",
                endProjectDate: ""
            })
            selectRef.current.selectedIndex = "0"
            setIsSubmitted(true)
        } else {
            toast.error(language.budget_error)
        }
    }

    useEffect(() => {
        if (playing) {
            if (currAudio) {
                if (!currAudio.paused) {
                    currAudio.pause()
                }
            }
            if (currentLanguage === "eng") {
                setCurrAudio(new Audio(budget_en_1))
            } else {
                setCurrAudio(new Audio(budget_es_1))
            }
        } else {
            if (currAudio) {
                if (!currAudio.paused) {
                    currAudio.pause()
                    setCurrAudio(null)
                }
            }
        }

        return () => {
            if (currAudio && !currAudio.paused) {
                currAudio.pause()
            }
        }
    }, [playing, currentLanguage])


    // useEffect(() => {
    //     console.log("currAudio", currAudio)
    //     if (currAudio) {
    //         if (!currAudio.paused) {
    //             currAudio.pause()
    //         }
    //         currAudio.play()
    //     }
    //     return () => {
    //         if (currAudio && !currAudio.paused) {
    //             currAudio.pause()
    //         }
    //     }
    // }, [currAudio])

    useEffect(() => {
        console.log(isSubmitted, "")
        if (playing) {
            if (isSubmitted) {
                if (currentLanguage === "eng") {
                    setCurrAudio(new Audio(budget_en_2))
                } else {
                    setCurrAudio(new Audio(budget_es_2))
                }
            } else {
                if (currAudio && !currAudio.paused) {
                    currAudio.pause()
                }
            }
        }
        return () => {
            if (currAudio && !currAudio.paused) {
                currAudio.pause()
            }
        }
    }, [isSubmitted])


    return (

        <>

            <div id='budget'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-xl-12 col-lg-12 col-md-12 col-12'>
                            <div className='budget-left'>
                                <div className='heading'>
                                    <h2>{language.budget_tracking}</h2>
                                </div>
                                <div className='budget-cnt'>
                                    <div className='cnt-top'>
                                        <form>
                                            <div className="form-group">
                                                <label for="exampleInputEmail1">{language.budget_amount}</label>
                                                <input
                                                    value={values.budgetAmount}
                                                    type="number"
                                                    className="form-control"
                                                    id=""
                                                    aria-describedby=""
                                                    onChange={(e) => setValues(prev => ({ ...prev, budgetAmount: e.target.value }))}
                                                    placeholder={language.amount}
                                                />
                                            </div>
                                        </form>
                                    </div>
                                    <div className='cnt-mid'>

                                        <form>
                                            <div class="form-group">
                                                <label>{language.budget_itemm}</label>
                                                <select className="form-control drop" id="exampleFormControlSelect1" ref={selectRef} onChange={handleBudgetItem}>
                                                    <option>{language.budget_itemm}</option>
                                                    <option>{language.sales}</option>
                                                    <option>{language.expense}</option>
                                                    <option>{language.profit}</option>
                                                </select>
                                            </div>
                                        </form>

                                    </div>
                                    {/* <div className='btm-cnt'>
                                        <form>
                                            <div class="form-group">
                                                <label>{language.project_data}</label>
                                                <select className="form-control drop" id="exampleFormControlSelect1">
                                                    <option>{language.project_name}</option>
                                                    <option>2</option>
                                                    <option>3</option>
                                                    <option>4</option>
                                                    <option>5</option>
                                                </select>
                                            </div>
                                        </form>
                                    </div> */}
                                    <div className='cnt-btn'>
                                        <button type="send" className='post-btn' onClick={handleBudgetPost}>{language.post}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className='col-xl-5 col-lg-5 col-md-12 col-12 d-flex justify-content-center align-items-center' style={{paddingTop:"80px"}}>
                            <div className='bill-right'>
                                <label className="form_label">
                                    <input type="file" className="form-control" id="uploadPhoto" />
                                    imagen por enviar
                                </label>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>

        </>

    )
}

export default Budget