import React, { useEffect, useState } from 'react'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import HttpClient from '../../../utils/HttpClient'
import { useUserData } from '../../../Providers/UserWrapper'
import toast from 'react-hot-toast'
import DeleteModal from '../../../Modal/deleteMoal/DeleteModal'
import ViewCapitalOptComp from './ViewCapitalOptComp'
import { useLanguage } from '../../../Providers/LanguageContext'
import { useNavigate } from 'react-router-dom'

const ViewCapitalOption = () => {
    const { setIsLoading } = useUserData();
    const { language, currentLanguage } = useLanguage()
    const [debtData, setDebtData] = useState([])
    const [equityData, setEquityData] = useState([])
    const navigate = useNavigate();
    // getCapitalData
    const getCapitalData = async () => {
        setIsLoading(true)
        const res = await HttpClient.requestData("get-investment-capital-by-user", "GET", {})
        console.log("resdsd", res)
        setIsLoading(false)
        if (res && res?.status) {
            const debt = res?.data?.filter(item => item?.raiseCapitalType === "Debt Capital Raise"
                || item?.raiseCapitalType === "Debt"
            )
            const equity = res?.data?.filter(item => item?.raiseCapitalType === "Equity Capital Raise")
            setDebtData(debt)
            setEquityData(equity)
        }
    }

    // accept
    const handleAccept = async (item) => {
        const data = {
            "acceptStatus": "accepted", // ['accepted', 'cancelled']
            "language": currentLanguage === "eng" ? "EN" : "ES"
        }
        setIsLoading(true)
        const res = await HttpClient.requestData("update-accept-status-by-user/" + item?._id, "PUT", data)
        setIsLoading(false)
        if (res && res?.status) {
            toast.success("Investment Accepted SuccessFully");
            getCapitalData();
        } else {
            toast.error(res?.messeage || "Error")
        }
    }

    // reject
    const handleReject = async (item) => {

        const data = {
            "acceptStatus": "cancelled" // ['accepted', 'cancelled']
        }

        setIsLoading(true)
        const res = await HttpClient.requestData("update-accept-status-by-user/" + item?._id, "PUT", data)
        setIsLoading(false)
        if (res && res?.status) {
            toast.success("Investment Rejected SuccessFully")
            getCapitalData()
        } else {
            toast.error(res?.messeage || "Error")
        }
    }

    useEffect(() => {
        getCapitalData()
    }, [])


    return (
        <>
            <section className="bookkeperSection">
                <div className="container">
                    <div className='d-flex gap-2 mb-2'>
                        <div className=''>
                            <button className='w-100 btn btn-outline-success' style={{ fontWeight: "bold" }} onClick={() => navigate('/raise-capital')}>{language.raise_capital}</button>
                        </div>

                        <div className=''>
                            <button className='w-100 btn btn-outline-success' style={{ fontWeight: "bold" }} onClick={() => navigate('/view-investor-document')}>
                                {language.view_upload_docs}
                            </button>
                        </div>
                    </div>
                    <div className="bookkeMainHeadDiv">
                        <span className="bookkeMainHead">Investment Manager – Capital Options</span>
                    </div>
                    <Tabs className="bookeperPlanTab">
                        <TabList>
                            <Tab >
                                <div className="bookkeTabDiv">
                                    <label className="bookkPlan">Debt Capital Raise</label>
                                </div>
                            </Tab>
                            <Tab>
                                <div className="bookkeTabDiv">
                                    <label className="bookkPlan">Equity Capital Raise</label>
                                </div>
                            </Tab>
                        </TabList>


                        {/* Debt Capital Raise */}
                        <TabPanel>
                            <ViewCapitalOptComp
                                arrData={debtData}
                                handleAccept={handleAccept}
                                handleReject={handleReject}
                            />
                        </TabPanel>


                        {/* Equity Capital Raise */}
                        <TabPanel>
                            <ViewCapitalOptComp
                                arrData={equityData}
                                handleAccept={handleAccept}
                                handleReject={handleReject}
                            />

                        </TabPanel>

                    </Tabs>

                </div>
            </section>

            {/* {
                isAccepet &&
                <DeleteModal

                />
            }


            {
                isReject &&
                <DeleteModal
                    closeDeleteModal={() => setIsReject(false)}
                    handleDelete={handleReject}
                />
            } */}

        </>
    )
}

export default ViewCapitalOption
