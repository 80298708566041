import React, { useEffect, useState } from 'react'
import Nav from 'react-bootstrap/Nav';


const times = ["Day", "Week", "Month", "Year"]

function TimeFrameTabs({onChangeNav}) {
    const [activeKey,setActiveKey] = useState("Day")

    useEffect(()=>{
      onChangeNav(activeKey)
    },[activeKey])
    
    useEffect(()=>{
      setActiveKey("Day")
    },[])
    return (
        <div className='container d-flex justify-content-center mt-3 mb-3'>
            <Nav variant="pills" activeKey={activeKey} >
                {
                    times.map((t, i) => (
                        <Nav.Item key={i} style={{width:"100px",textAlign:"center",fontSize:"1.2rem"}}>
                            <Nav.Link style={{backgroundColor:activeKey===t?"black":"#fff",color:activeKey===t?"whitesmoke":"black"}} onClick={()=>{
                                setActiveKey(t)
                            }} eventKey={t}>{t}</Nav.Link>
                        </Nav.Item>
                    ))
                }
            </Nav>
        </div>
    )
}

export default TimeFrameTabs