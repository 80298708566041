import React from "react";
import iMg1 from "../../images/ilustraciones soluciones industriales_Mesa de trabajo 1 copia 20.png";
import iMg2 from "../../images/ilustraciones soluciones industriales_empresas contables 1.png";
import iMg3 from "../../images/ilustraciones soluciones industriales_empresas contables 3.png";
import iMg3en from "../../images/ilustraciones soluciones industriales_empresas contables 3 EN.png";

import { useLanguage } from "../../Providers/LanguageContext";
import { useNavigate } from "react-router-dom";
import SignUpButton2 from "./SignUpButton2";
const AccountingComp = () => {
  const { language, currentLanguage } = useLanguage();
  const navigate = useNavigate();


  return (
    <>
      <section id="agriculture_sec" style={{ paddingBottom: "0" }}>
        <div className="container">
          <div className="agriculture_head">
            <h3 style={{ color: "#0e2537" }}>
              {language.accountant_accounting_firm}
            </h3>
          </div>
          <div className="row mb-5">
            <div className="col-md-6 col-12 m-auto">
              <div className="agriculture_headtext">
                <h4 style={{ color: "#333333" }}>
                  {language.streamline_data_manage}
                </h4>
                <p>
                  {language.fisi_simplifies_process}
                </p>
              </div>
            </div>
            <div className="col-md-6 col-12 m-auto">
              <div className="agriculture_img">
                <img src={iMg2} className="img-fluid" alt="" />
              </div>
            </div>
          </div>

          <div className="row row_reverse mb-5">
            <div className="col-md-6 col-12 m-auto">
              <div className="agriculture_img" style={{ width: "75%" }}>
                <img src={iMg1} className="img-fluid" alt="" />
              </div>
            </div>
            <div className="col-md-6 col-12 m-auto">
              <div className="agriculture_headtext">
                <h4 style={{ color: "#333333", textAlign: "right" }}>
                  {language.improve_colab}
                </h4>
                <p style={{ textAlign: "justify" }}>
                  {language.fisi_enables_seamless_colab}
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-12 col-12 m-auto pb-5">
            <div className="agriculture_headtext">
              <h4 style={{ color: "#0e2537", textAlign: "left" }}>
                {language.increased_efficiency}
              </h4>
              <p style={{ textAlign: "justify" }}>
                {language.automating_many_time}
              </p>
            </div>
          </div>
          <div className="col-md-12 col-12 m-auto pt-5">
            <div className="agriculture_headtext">
              <h4 style={{ color: "#0e2537", textAlign: "right" }}>
                {language.data_analysis_and_report}
              </h4>
              <p style={{ textAlign: "justify" }}>
                {language.fisi_provide_in_depth}
              </p>
            </div>
          </div>
          <div className="row row_reverse ">
            <div className="col-md-6 col-12 m-auto mt-5">
              <div className="agriculture_headtext">
                <h4 style={{ color: "#0d2232", textAlign: "left" }}>
                  {language.green_initiative}
                </h4>
                <p style={{ textAlign: "justify" }}>
                  {language.reducing_need_paper}
                </p>
              </div>
            </div>
            <div className="col-md-6 col-12 m-auto">
              <div className="agriculture_img">
                {
                  currentLanguage === 'spanish'
                    ?
                    <img src={iMg3} className="img-fluid" alt="" />
                    :
                    <img src={iMg3en} className="img-fluid" alt="" />
                }
                {/* <div
                  className="registrarAnddescargar"
                  style={{
                    transform: "translate(-50% , -50%)",
                    top: "44%",
                    left: "21%",
                    flexDirection: "column",
                  }}
                >
                  <button
                    className="registrar_Btn"
                    style={{
                      background:
                        " linear-gradient(to right, #18465d 1%,#0f283b 100%,#2989d8 100%)",
                      border: "0",
                      marginBottom: "20px",
                    }}
                    onClick={() => navigate('/login')}
                  >
                    {language.homeBannerBtn1}
                  </button>
                  <button className="descargar_Btn">  {language.homeBannerBtn2}</button>
                </div> */}
                <SignUpButton2/>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AccountingComp;
