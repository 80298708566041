import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { toast } from "react-hot-toast";
import CustomButton from "../../Component/CustomButton";
import CustomFormButtons from "../../Component/CustomFormButtons";
import CustomInputField from "../../Component/CustomInputField";
import CustomModal from "../../Component/CustomModal";
import { useLanguage } from "../../Providers/LanguageContext";
import { AiOutlineClose } from "react-icons/ai";
import moment from "moment";
import HttpClient from "../../utils/HttpClient";
import { SpeechContext } from "../../Providers/SpeechWrapper";
import revenue_welcome_en_1 from "../../Audio_files/II. Revenue Manager Audio/II a. Post New Sales Revenue User Selected Option/ii_post_new_sales_revenue_audio_a.mp3";
import revenue_welcome_en_2 from "../../Audio_files/II. Revenue Manager Audio/II a. Post New Sales Revenue User Selected Option/ii_post_new_sales_revenue_audio_b.mp3";
import revenue_welcome_en_3 from "../../Audio_files/II. Revenue Manager Audio/II a. Post New Sales Revenue User Selected Option/ii_post_new_sales_revenue_audio_c.mp3";
import revenue_welcome_en_4 from "../../Audio_files/II. Revenue Manager Audio/II a. Post New Sales Revenue User Selected Option/ii_post_new_sales_revenue_audio_d.mp3";
import revenue_welcome_en_5 from "../../Audio_files/II. Revenue Manager Audio/II a. Post New Sales Revenue User Selected Option/ii_post_new_sales_revenue_audio_e.mp3";
import revenue_welcome_en_6 from "../../Audio_files/II. Revenue Manager Audio/II a. Post New Sales Revenue User Selected Option/ii_post_new_sales_revenue_audio_f.mp3";
import revenue_welcome_es_1 from "../../spanish_audio_files/II. Revenue Manager Audio/II a. Post New Sales Revenue User Selected Option/ii_post_new_sales_revenue_audio_a_es.mp3";
import revenue_welcome_es_2 from "../../spanish_audio_files/II. Revenue Manager Audio/II a. Post New Sales Revenue User Selected Option/ii_post_new_sales_revenue_audio_b_es.mp3";
import revenue_welcome_es_3 from "../../spanish_audio_files/II. Revenue Manager Audio/II a. Post New Sales Revenue User Selected Option/ii_post_new_sales_revenue_audio_c_es.mp3";
import revenue_welcome_es_4 from "../../spanish_audio_files/II. Revenue Manager Audio/II a. Post New Sales Revenue User Selected Option/ii_post_new_sales_revenue_audio_d_es.mp3";
import revenue_welcome_es_5 from "../../spanish_audio_files/II. Revenue Manager Audio/II a. Post New Sales Revenue User Selected Option/ii_post_new_sales_revenue_audio_e_es.mp3";
import revenue_welcome_es_6 from "../../spanish_audio_files/II. Revenue Manager Audio/II a. Post New Sales Revenue User Selected Option/ii_post_new_sales_revenue_audio_f_es.mp3";
import { useUserData } from "../../Providers/UserWrapper";
import { async } from "@firebase/util";
import CustomLoaderLine from "../../Component/Loader/CustomLoaderLine";

function IncomeForm({ time, setTime }) {
    const [isPlayingFirstAudio, setIsPlayingFirstAudio] = useState(false);
    const [isPlayingPostAudio, setIsPlayingPostAudio] = useState(false);
    const [cashCreditClicked, setCashCreditClicked] = useState(false);
    const [isCustonerName, setIsCustomerName] = useState(false);
    const [searchData, setSearchData] = useState([]);
    const [isSave, setIsSave] = useState(false);
    const timerRef = useRef();
    const { currentLanguage, language } = useLanguage();
    const { currAudio, setCurrAudio, playing, setIsPlaying } =
        useContext(SpeechContext);

    const today = new Date();
    const [isPost, setIsPost] = useState(false);
    // console.log("today", today.getMonth())

    const [values, setValues] = useState({
        dayType: "day",
        price: "",
        customerName: "",
        type: "",
        transactionDate: moment().format("YYYY-MM-DD"),
        salesCategory: "",
        customerCredit: "",
        projectName: "",
    });
    // console.log('timeRev', values)

    const [projects, setProjects] = useState([]);
    const { profileData, isBusiness } = useUserData();
    // console.log('profileDatarev', profileData)
    const [isSaveVendor, setIsSaveVendor] = useState(false);

    const [weekList, setWeekList] = useState([]);
    const getCurrWeekMemo = useMemo(() => {
        let currentDate = new Date();
        let startDate = new Date(currentDate.getFullYear(), 0, 1);
        let days = Math.floor((currentDate - startDate) / (24 * 60 * 60 * 1000));
        let weekNumber = Math.ceil(days / 7);
        return weekNumber?.toString();
    }, [time, isPost]);

    const [weekDate, setWeekDate] = useState({
        weekNumber: "",
        startDate: "",
        endDate: "",
    });
    const [yearList, setYearList] = useState([]);
    const [autoSyncLoad, setAutoSyncLoad] = useState(false);
    // console.log("isPost", isPost)

    // console.log('weekList', weekList.filter((item) => item.weekNumber == getCurrWeekMemo)[0].startDate)

    useEffect(() => {
        setWeekDate({
            weekNumber: getCurrWeekMemo,
            startDate:
                weekList.length &&
                weekList.filter((item) => item.weekNumber == getCurrWeekMemo)[0]
                    .startDate,
            endDate:
                weekList.length &&
                weekList.filter((item) => item.weekNumber == getCurrWeekMemo)[0]
                    .endDate,
        });
    }, [weekList, isPost]);

    const [months, setMonths] = useState([
        { label: "January", value: "January", number: 0, month: "01" },
        { label: "February", value: "February", number: 1, month: "02" },
        { label: "March", value: "March", number: 2, month: "03" },
        { label: "April", value: "April", number: 3, month: "04" },
        { label: "May", value: "May", number: 4, month: "05" },
        { label: "June", value: "June", number: 5, month: "06" },
        { label: "July", value: "July", number: 6, month: "07" },
        { label: "August", value: "August", number: 7, month: "08" },
        { label: "September", value: "September", number: 8, month: "09" },
        { label: "October", value: "October", number: 9, month: "10" },
        { label: "November", value: "November", number: 10, month: "11" },
        { label: "December", value: "December", number: 11, month: "12" },
    ]);

    const [modalIsOpen, setIsOpen] = useState(false);

    function openModal() {
        // setIncomePostClicked(true);
        setIsOpen(true);
    }

    function closeModal() {
        // setIncomePostClicked(false)
        setIsOpen(false);
    }

    const handleSubmit = () => {
        const { dayType, price, type, customerName } = values;
        if (!dayType) {
            toast.error(language.timeframe_error);
            return;
        }
        if (!price) {
            toast.error(language.price_error);
            return;
        }
        if (!type) {
            toast.error(language.type_error);
            return;
        }
        if (!customerName) {
            toast.error(language.customer_name_error);
            return;
        }

        console.log(values);
        fetchProjects();
        openModal();
    };

    const getValue = (val, name) => {
        if (val < 0) {
            return
        }

        setValues((prev) => ({ ...prev, [name]: val }));
    };

    const onClick = (text) => {
        if (text) {
            setCashCreditClicked(text);
        }
        if (text === language.cash) {
            // "cash")
            setValues((prev) => ({ ...prev, type: "cash" }));
            if (currentLanguage === "eng") {
                toast.success("Selected Cash");
            } else {
                toast.success("Efectivo seleccionado");
            }
        } else if (text === language.digital) {
            // "credit")
            setValues((prev) => ({ ...prev, type: "credit" }));
            if (currentLanguage === "eng") {
                toast.success("Selected credit");
            } else {
                toast.success("crédito seleccionado");
            }
        }
    };

    const fetchProjects = async () => {
        const res = await HttpClient.requestData("projectname", "GET");
        if (res && res.status) {
            setProjects(res.data);
        } else {
            console.log(res);
        }
    };

    const removeDuplicate = (arr) => {
        const unique = arr.filter((obj, index) => {
            return index === arr.findIndex((o) => obj.projectName === o.projectName);
        });
        return unique;
    };

    const handleSalesPost = async () => {
        if (!values.transactionDate) {
            toast.error(language.transaction_error);
            return;
        }
        const res = await HttpClient.requestData("add-sales", "POST", values);
        if (res && res.status) {
            setIsPost(!isPost);
            toast.success(language.success_post_sales);
            closeModal();
            // setTime("t")
            setValues({
                dayType: time,
                price: "",
                customerName: "",
                type: "",
                transactionDate: moment().format("YYYY-MM-DD"),
                salesCategory: "",
                customerCredit: "",
                projectName: "",
            });

            if (time === "day") {
                setValues((prev) => ({
                    ...prev,
                    dayType: time,
                    transactionDate: moment().format("YYYY-MM-DD"),
                }));
            } else if (time === "week") {
                setValues((prev) => ({
                    ...prev,
                    dayType: time,
                    transactionDate: weekDate.startDate,
                }));
            } else if (time === "month") {
                let month = months.filter((item) => item.number == today.getMonth())[0]
                    .month;
                setValues((prev) => ({
                    ...prev,
                    dayType: time,
                    transactionDate: today.getFullYear() + "-" + month + "-01",
                }));
            } else if (time === "year") {
                setValues((prev) => ({
                    ...prev,
                    dayType: time,
                    transactionDate: today.getFullYear() + "-01-01",
                }));
            } else {
                setValues((prev) => ({
                    ...prev,
                    dayType: time,
                    transactionDate: moment().format("YYYY-MM-DD"),
                }));
            }
        } else {
            toast.error(language.error_post_sales);
        }
    };

    const removeDuplicates = (arr) => {
        return arr.filter((item, index) => arr.indexOf(item) === index);
    };

    const fetchSerchName = async (name) => {
        const res = await HttpClient.requestData("sales-search/" + name, "GET");
        // console.log('searchName', res)
        let arr = [];
        if (res && res.status) {
            res.data.forEach((element) => {
                arr.push(element.customerName);
            });
            // console.log('element',arr)
            setSearchData(removeDuplicates(arr));
        } else {
            setSearchData([]);
        }
    };

    useEffect(() => {
        if (time === "day") {
            setValues((prev) => ({
                ...prev,
                dayType: time,
                transactionDate: moment().format("YYYY-MM-DD"),
            }));
        } else if (time === "week") {
            setValues((prev) => ({
                ...prev,
                dayType: time,
                transactionDate: weekDate.startDate,
            }));
        } else if (time === "month") {
            let month = months.filter((item) => item.number == today.getMonth())[0]
                .month;
            // console.log("monthOBj", month)
            setValues((prev) => ({
                ...prev,
                dayType: time,
                transactionDate: today.getFullYear() + "-" + month + "-01",
            }));
        } else if (time === "year") {
            setValues((prev) => ({
                ...prev,
                dayType: time,
                transactionDate: today.getFullYear() + "-01-01",
            }));
        } else {
            setValues((prev) => ({
                ...prev,
                dayType: time,
                transactionDate: moment().format("YYYY-MM-DD"),
            }));
        }

        // console.log("NewgetAllYear", NewgetAllYear());
        setWeekList(getWeeksInYear(today.getFullYear()));
        setYearList(NewgetAllYear());
    }, [time]);

    const getWeeksInYear = (year) => {
        const weeks = [];
        const date = new Date(year, 0, 1); // January 1st of the year
        while (date.getFullYear() === year) {
            const weekNumber = getWeekNumber(date);
            const startDate = new Date(date);
            const endDate = new Date(date);
            endDate.setDate(date.getDate() + 6);
            weeks.push({
                weekNumber: weekNumber?.toString(),
                startDate: moment(startDate).format("YYYY-MM-DD"),
                endDate: moment(endDate).format("YYYY-MM-DD"),
            });
            date.setDate(date.getDate() + 7);
        }
        return weeks;
    };

    function getWeekNumber(date) {
        const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
        const daysSinceFirstDay = (date - firstDayOfYear) / 86400000; // 86400000 = milliseconds in a day
        return Math.ceil((daysSinceFirstDay + firstDayOfYear.getDay() + 1) / 7);
    }

    function NewgetAllYear() {
        const currentYear = new Date().getFullYear();
        const range = (start, stop, step) =>
            Array.from(
                { length: (stop - start) / step + 1 },
                (_, i) => start + i * step
            );
        return range(currentYear, currentYear - 50, -1).map((item) => {
            return {
                label: item.toString(),
                value: item.toString(),
            };
        });
    }

    // plaid api
    const handlePlaid = async () => {
        const data = {
            userId: profileData?._id,
        };
        setAutoSyncLoad(true);
        const res = await HttpClient.requestData("plaid-auto-post", "POST", data);
        console.log("resPlaidRev", res);
        if (res && res?.status) {
            toast.success(language.auto_sync_success);
            setAutoSyncLoad(false);
        } else {
            setAutoSyncLoad(false);
        }
    };

    useEffect(() => {
        if (currAudio) {
            currAudio.onended = function () {
                if (currentLanguage === "eng") {
                    setCurrAudio(new Audio(revenue_welcome_en_6));
                } else {
                    setCurrAudio(new Audio(revenue_welcome_es_6));
                }
            };
        }
        return () => {
            if (currAudio && !currAudio.paused) {
                currAudio.pause();
            }
        };
    }, [isPlayingPostAudio]);

    useEffect(() => {
        console.log(currAudio, setCurrAudio, "funcsssss");
        if (playing) {
            if (currAudio) {
                if (!currAudio.paused) {
                    currAudio.pause();
                }
            }
            if (currentLanguage === "eng") {
                setCurrAudio(new Audio(revenue_welcome_en_1));
                setIsPlayingFirstAudio(true);
            } else {
                setCurrAudio(new Audio(revenue_welcome_es_1));
                setIsPlayingFirstAudio(true);
            }
        } else {
            if (currAudio) {
                if (!currAudio.paused) {
                    currAudio.pause();
                    setCurrAudio(null);
                }
            }
        }

        return () => {
            if (currAudio && !currAudio.paused) {
                currAudio.pause();
            }
        };
    }, [playing, currentLanguage]);

    useEffect(() => {
        if (playing && isPlayingFirstAudio && currAudio) {
            currAudio.onended = function () {
                if (currentLanguage === "eng") {
                    setCurrAudio(new Audio(revenue_welcome_en_2));
                    setIsPlayingFirstAudio(false);
                } else {
                    setCurrAudio(new Audio(revenue_welcome_es_2));
                    setIsPlayingFirstAudio(false);
                }
            };
        } else {
            if (currAudio) {
                if (!currAudio.paused) {
                    currAudio.pause();
                    setCurrAudio(null);
                }
            }
        }

        return () => {
            if (currAudio && !currAudio.paused) {
                currAudio.pause();
            }
        };
    }, [isPlayingFirstAudio, playing]);

    // useEffect(() => {
    //     console.log("currAudio", currAudio)
    //     if (currAudio) {
    //         if (!currAudio.paused) {
    //             currAudio.pause()
    //         }
    //         currAudio.play()
    //     }
    //     return () => {
    //         if (currAudio && !currAudio.paused) {
    //             currAudio.pause()
    //         }
    //     }
    // }, [currAudio])

    useEffect(() => {
        if (cashCreditClicked) {
            // alert("clicked")
            if (currentLanguage === "eng") {
                setCurrAudio(new Audio(revenue_welcome_en_3));
            } else {
                setCurrAudio(new Audio(revenue_welcome_es_3));
            }
        }
        return () => {
            if (currAudio && !currAudio.paused) {
                currAudio.pause();
            }
        };
    }, [cashCreditClicked]);

    useEffect(() => {
        if (values.customerName) {
            if (currentLanguage === "eng") {
                if (timerRef.current) {
                    clearTimeout(timerRef.current);
                }
                timerRef.current = setTimeout(() => {
                    setCurrAudio(new Audio(revenue_welcome_en_4));
                }, 300);
            } else {
                if (timerRef.current) {
                    clearTimeout(timerRef.current);
                }
                timerRef.current = setTimeout(() => {
                    setCurrAudio(new Audio(revenue_welcome_es_4));
                }, 300);
            }
        }
        return () => {
            if (currAudio && !currAudio.paused) {
                currAudio.pause();
            }
        };
    }, [isSaveVendor]);

    useEffect(() => {
        if (modalIsOpen) {
            if (currentLanguage === "eng") {
                setCurrAudio(new Audio(revenue_welcome_en_5));
                setIsPlayingPostAudio(true);
            } else {
                setCurrAudio(new Audio(revenue_welcome_es_5));
                setIsPlayingPostAudio(true);
            }
        } else {
            if (currAudio && !currAudio.paused) {
                currAudio.pause();
            }
        }
        return () => {
            if (currAudio && !currAudio.paused) {
                currAudio.pause();
            }
        };
    }, [modalIsOpen]);

    return (
        <>
            <div
                className="card"
                style={{
                    width: "100%",
                    background: "whitesmoke",
                    marginTop: "40px",
                    padding: "10px",
                }}
            >
                <h3 className="card-title">
                    {profileData && profileData.userType === "Business"
                        ? language.revenew
                        : language.income}
                </h3>
                <div className="card-body">
                    <CustomInputField
                        type="number"
                        value={values.price}
                        placeholder={language.amount}
                        name="price"
                        getValue={getValue}
                    />
                    <div className="d-flex flex-row mt-2">
                        <CustomButton
                            text={language.cash}
                            disabled={values.type === "cash"}
                            className="customBtn3"
                            onClick={onClick}
                        />
                        <CustomButton
                            text={language.digital}
                            disabled={values.type === "credit"}
                            className="customBtn3"
                            onClick={onClick}
                        />
                    </div>
                    <h5 className="card-title mt-4 mx-2">
                        {isBusiness ? language.customer_name : language.income_type}
                    </h5>
                    {/* <CustomInputField type="text" placeholder="Tipo de Ingresos"
                name="income" getValue={getValue} /> */}
                    <div>
                        {/* <CustomInputField type="text" placeholder={language.customer_name}
                            value={values.customerName}
                            name="customerName" getValue={getValue} /> */}
                        <input
                            type="text"
                            placeholder={
                                isBusiness ? language.customer_name : language.income_type
                            }
                            readOnly
                            value={
                                isSave
                                    ? values.customerName
                                    : isBusiness
                                        ? language.customer_name
                                        : language.income_type
                            }
                            onClick={() => {
                                setIsCustomerName(true);
                                setIsSave(false);
                            }}
                        />
                        <CustomModal
                            modalIsOpen={isCustonerName}
                            setIsOpen={setIsCustomerName}
                            openModal={() => setIsCustomerName(true)}
                            closeModal={() => setIsCustomerName(false)}
                        >
                            <div>
                                <div className="d-flex justify-content-between text-secondary">
                                    <span>{/* {language.confirmation} */}</span>
                                    <span>
                                        <AiOutlineClose
                                            className="icon"
                                            onClick={() => setIsCustomerName(false)}
                                            size={20}
                                            color="gray"
                                        />
                                    </span>
                                </div>
                            </div>
                            <div className="input-group mb-3">
                                <div style={{ width: "80%" }}>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder={
                                            isBusiness ? language.customer_name : language.income_type
                                        }
                                        aria-label="Recipient's username"
                                        aria-describedby="basic-addon2"
                                        value={values.customerName}
                                        onChange={(e) => {
                                            // console.log(e.target.value)
                                            if (/^[A-Za-z\s]*$/.test(e.target.value)) {
                                                setValues((prev) => {
                                                    return { ...prev, customerName: e.target.value };
                                                });
                                                fetchSerchName(e.target.value);
                                            }
                                        }}
                                    />
                                </div>
                                <button
                                    className="customer_name_button_revenew btn btn-primary"
                                    onClick={() => {
                                        setIsSave(true);
                                        setIsCustomerName(false);
                                        setIsSaveVendor(!isSaveVendor);
                                    }}
                                >
                                    {language.save}
                                </button>
                                {searchData.map((item, i) => (
                                    <div className="search_name" key={i}>
                                        <p
                                            style={{ margin: "0" }}
                                            onClick={() => {
                                                setValues((prev) => {
                                                    return { ...prev, customerName: item };
                                                });
                                                setSearchData([]);
                                            }}
                                        >
                                            {item}
                                        </p>
                                    </div>
                                ))}
                            </div>
                        </CustomModal>
                    </div>
                    <div className="d-flex justify-content-end">
                        <CustomButton
                            text={language.post}
                            className="customBtn2"
                            onClick={handleSubmit}
                        />
                    </div>
                    <CustomButton
                        text={!autoSyncLoad ? language.auto_sync : <CustomLoaderLine />}
                        className="customBtn2"
                        onClick={handlePlaid}
                    />
                </div>
            </div>
            <CustomModal
                modalIsOpen={modalIsOpen}
                setIsOpen={setIsOpen}
                openModal={openModal}
                closeModal={closeModal}
            >
                <div>
                    <div className="d-flex justify-content-between text-secondary">
                        <span>{language.confirmation}</span>
                        <span>
                            <AiOutlineClose
                                className="icon"
                                onClick={closeModal}
                                size={20}
                                color="gray"
                            />
                        </span>
                    </div>
                    <div className="d-flex flex-column income-confirm-data">
                        <span>
                            {isBusiness ? language.sales : language.income}: ${values.price}
                        </span>
                        <span>
                            {isBusiness ? language.customer_name : language.income_type}:{" "}
                            {values.customerName}
                        </span>
                        <span>
                            {language.current_date}: {moment(new Date()).format("LL")}
                        </span>
                        {time === "day" && (
                            <span>
                                <label htmlFor="dateinput">{language.choose_date}</label>
                                <input
                                    type="date"
                                    className="mx-2"
                                    name="dateinput"
                                    id="dateinput"
                                    value={values.transactionDate}
                                    onChange={(e) =>
                                        setValues((prev) => ({
                                            ...prev,
                                            transactionDate: moment(e.target.value).format(
                                                "YYYY-MM-DD"
                                            ),
                                        }))
                                    }
                                />
                            </span>
                        )}

                        {time === "week" && (
                            <>
                                <span>
                                    <label htmlFor="dateinput">{language.choose_week}</label>
                                    {/* <input type="date" className='mx-2' name="dateinput" id="dateinput"
                                        onChange={(e) => setValues(prev => ({ ...prev, transactionDate: moment(e.target.value).format("YYYY-MM-DD") }))}
                                    /> */}
                                    <select
                                        onChange={(e) => {
                                            setWeekDate(JSON.parse(e.target.value));
                                            setValues((prev) => {
                                                return {
                                                    ...prev,
                                                    transactionDate: JSON.parse(e.target.value).startDate,
                                                };
                                            });
                                        }}
                                    >
                                        {weekList.map((item, i) => (
                                            <option
                                                value={JSON.stringify(item)}
                                                selected={
                                                    item.weekNumber == getCurrWeekMemo ? true : false
                                                }
                                            >
                                                {item.weekNumber}
                                            </option>
                                        ))}
                                    </select>
                                </span>
                                <span>
                                    {"Start Date"}: {weekDate.startDate}
                                </span>
                                <span>
                                    {"End Date"}: {weekDate.endDate}
                                </span>
                            </>
                        )}

                        {time === "month" && (
                            <span>
                                <label htmlFor="dateinput">{language.choose_month}</label>
                                <select
                                    onChange={(e) => {
                                        setValues((prev) => {
                                            return {
                                                ...prev,
                                                transactionDate:
                                                    today.getFullYear() + "-" + e.target.value + "-01",
                                            };
                                        });
                                    }}
                                >
                                    {months.map((item, i) => (
                                        <option
                                            value={item.month}
                                            selected={item.number == today.getMonth() ? true : false}
                                        >
                                            {item.label}
                                        </option>
                                    ))}
                                </select>
                            </span>
                        )}

                        {time === "year" && (
                            <span>
                                <label htmlFor="dateinput">{language.choose_year}</label>
                                <select
                                    onChange={(e) => {
                                        setValues((prev) => {
                                            return {
                                                ...prev,
                                                transactionDate: e.target.value + "-01-01",
                                            };
                                        });
                                    }}
                                >
                                    {yearList.map((item, i) => (
                                        <option
                                            value={item.value}
                                            selected={
                                                item.number == today.getFullYear() ? true : false
                                            }
                                        >
                                            {item.label}
                                        </option>
                                    ))}
                                </select>
                            </span>
                        )}

                        {/* {
                            time === "day" &&
                            <span>
                                <label htmlFor="dateinput">{language.choose_date}</label>
                                <input type="date" className='mx-2' name="dateinput" id="dateinput"
                                    onChange={(e) => setValues(prev => ({ ...prev, transactionDate: moment(e.target.value).format("YYYY-MM-DD") }))}
                                />
                            </span>
                        } */}

                        <h6 className="mt-2">
                            {isBusiness ? language.sales_category : language.income_category}:
                        </h6>
                        <span>
                            <input
                                type="text"
                                name="sales_category"
                                id="sales_category"
                                placeholder={
                                    isBusiness
                                        ? language.sales_category
                                        : language.income_category
                                }
                                value={values.salesCategory}
                                onChange={(e) =>
                                    setValues((prev) => ({
                                        ...prev,
                                        salesCategory: e.target.value,
                                    }))
                                }
                            />
                        </span>
                        <h6 className="mt-2">{language.customer_credit}:</h6>
                        <span>
                            {/* <label htmlFor="customer_credit">{language.customer_credit}</label> */}
                            <input
                                type="text"
                                name="customer_credit"
                                id="customer_credit"
                                placeholder={language.customer_credit}
                                value={values.customerCredit}
                                onChange={(e) =>
                                    setValues((prev) => ({
                                        ...prev,
                                        customerCredit: e.target.value,
                                    }))
                                }
                            />
                        </span>
                        <span>
                            {language.type}:{" "}
                            {values.type === "Cash" ? language.cash : language.credit}
                        </span>
                        <span>
                            <select
                                onChange={(e) => {
                                    console.log(e.target.value);
                                    if (e.target.value !== "Select project") {
                                        setValues((prev) => ({
                                            ...prev,
                                            projectName: e.target.value,
                                        }));
                                    }
                                }}
                            >
                                <option>{language.select_project}</option>
                                {removeDuplicate(projects).map((p, i) => (
                                    <option key={p._id} value={p.projectName}>
                                        {p.projectName}
                                    </option>
                                ))}
                            </select>
                        </span>
                        <div
                            className="d-flex justify-content-center"
                            style={{ gap: "10px", marginTop: "10px" }}
                        >
                            <button className="customBtn4" onClick={handleSalesPost}>
                                {language.post}
                            </button>
                            <button className="customBtn5" onClick={closeModal}>
                                {language.cancel}
                            </button>
                        </div>
                    </div>
                </div>
            </CustomModal>
        </>
    );
}

export default IncomeForm;
