import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { useLanguage } from '../../Providers/LanguageContext';
import { useUserData } from '../../Providers/UserWrapper';
import HttpClient from '../../utils/HttpClient';
import PaginationReport from './PaginationReport';

const ProfitabilityTable = ({ reportDate }) => {
    const { language, currentLanguage } = useLanguage();
    const { isBusiness, setProfitabilityCsv, setProfitabilityExcel, profileData } = useUserData();

    const [yearData, setYearData] = useState([])
    const [monthData, setMonthData] = useState([])
    const [weekData, setWeekData] = useState([])
    const [dayData, setDayData] = useState([])

    const [pagiDayData, setPagiDayData] = useState([]);
    const [pagiWeekData, setPagiWeekData] = useState([]);
    const [pagiMonthData, setPagimonthData] = useState([]);
    const [pagiYearData, setPagiYearData] = useState([]);


    console.log('yearDatabb', yearData)


    const getYearData = async () => {
        const res = await HttpClient.requestData("yearly-profitability", "GET", {});
        // console.log('yearDatarev', res);
        if (res && res?.status) {
            setYearData(res?.data)
        }
    }

    const getMonthData = async () => {
        const res = await HttpClient.requestData("monthly-profitability", "GET", {});
        // console.log('SelfMonthRes', res);
        if (res && res?.status) {
            setMonthData(res?.data)
        }
    }

    const getWeekData = async () => {
        const res = await HttpClient.requestData("weekly-profitability", "GET", {});
        // console.log('selfWeekRes', res);
        if (res && res?.status) {
            setWeekData(res?.data);
        }
    }

    const getDayData = async () => {
        // console.log('reportDateRev', reportDate)
        if (reportDate.startData && reportDate.endDate) {
            const res = await HttpClient.requestData("profitability-date", "POST", reportDate);
            // console.log('selfDayaData', res);
            if (res && res?.status) {
                setDayData(res?.data)
            }
        } else {
            const res = await HttpClient.requestData("daily-profitability", "GET", {});
            // console.log('selfDayaDatabb', res);
            if (res && res?.status) {
                setDayData(res?.data)
            }
        }
    }

    useEffect(() => {
        getYearData();
        getMonthData();
        getWeekData();
    }, [])

    useEffect(() => {
        getDayData();
    }, [reportDate]);

    const getMonthName = (num) => {
        switch (num) {
            case 1: return language.january;
            case 2: return language.february;
            case 3: return language.march;
            case 4: return language.april;
            case 5: return language.may;
            case 6: return language.june;
            case 7: return language.july;
            case 8: return language.august;
            case 9: return language.september;
            case 10: return language.october;
            case 11: return language.november;
            case 12: return language.december;
        }
    }

    useEffect(() => {
        const maxLength = Math.max(yearData.length, monthData.length, weekData.length, dayData.length)

        // header
        let revenueHeader = [
            { label: "", key: "titles" },
        ];
        for (let i = 0; i < maxLength; i++) {
            revenueHeader = [
                ...revenueHeader,
                { label: "", key: String(i) },
            ]
        }

        // data
        // day
        let obj1 = { titles: "Day" };
        let obj2 = { titles: "Daily Profit – Budget $ Amount" };
        // let obj3 = { titles: "Daily Revenue – Budget $ Amount" };
        // let obj4 = { titles: "Daily Revenue Budget Performance +/-" };

        let revenueData = []
        if (dayData.length) {
            dayData.forEach((item, i) => {
                obj1 = { ...obj1, [i]: moment(item._id).format("YYYY-MM-DD") };
                obj2 = { ...obj2, [i]: item.actualProfit };
                // obj3 = { ...obj3, [i]: item.budgetAmount };
                // obj4 = { ...obj4, [i]: item.budgetPerformance };
            })
            revenueData = [obj1, obj2]
        }

        // week
        let objWeek = { titles: "" };
        let obj5 = { titles: "Week" };
        let obj6 = { titles: "Daily Profit – Budget $ Amount" };
        // let obj7 = { titles: "Daily Revenue – Budget $ Amount" };
        // let obj8 = { titles: "Daily Revenue Budget Performance +/-" };

        if (weekData.length) {
            weekData.forEach((item, i) => {
                obj5 = { ...obj5, [i]: "week " + item._id };
                obj6 = { ...obj6, [i]: item.actualProfit };
                // obj7 = { ...obj7, [i]: item.budgetAmount };
                // obj8 = { ...obj8, [i]: item.budgetPerformance };
            })
            revenueData = [...revenueData, objWeek, obj5, obj6]
        }

        // month
        let objMonth = { titles: "" };
        let obj9 = { titles: "Month" };
        let obj10 = { titles: "Monthly Profit – Actual $ Amount	" };
        // let obj11 = { titles: "Monthly Revenue – Budget $ Amount	" };
        // let obj12 = { titles: "Monthly Revenue Budget Performance +/-" };

        if (monthData.length) {
            monthData.forEach((item, i) => {
                obj9 = { ...obj9, [i]: getMonthName(item._id) };
                obj10 = { ...obj10, [i]: item.actualProfit };
                // obj11 = { ...obj11, [i]: item.budgetExpense };
                // obj12 = { ...obj12, [i]: item.budgetPerformance };
            })
            revenueData = [...revenueData, objMonth, obj9, obj10]
        }

        // Year
        let objYear = { titles: "" };
        let obj13 = { titles: "Year" };
        let obj14 = { titles: "Yearly Profit – Actual $ Amount	" };
        // let obj15 = { titles: "Yearly Revenue – Budget $ Amount	" };
        // let obj16 = { titles: "Yearly Revenue Budget Performance +/-" };

        if (yearData.length) {
            yearData.forEach((item, i) => {
                obj13 = { ...obj13, [i]: item.year };
                obj14 = { ...obj14, [i]: item.actualprofit };
                // obj15 = { ...obj15, [i]: item.actualBudget };
                // obj16 = { ...obj16, [i]: item.budgetpersormance };
            })
            revenueData = [...revenueData, objYear, obj13, obj14];
        }

        setProfitabilityCsv({
            headers: revenueHeader,
            data: revenueData
        })

        getExcelData();
    }, [yearData, monthData, weekData, dayData,]);

    const getExcelData = () => {

        let myArray = [
            [language.report_generated_by, profileData?.name + " " + profileData?.surName],
            [language.report_generated_on, moment().format("YYYY-MM-DD")],
            [],
            [language.profitability]
        ];

        // day
        let arr1 = [language.daily];
        let arr2 = [isBusiness ? language.daily_profit_amt : language.daily_margin_amt];
        // let arr3 = [language.daily_expense_budget_amt];
        // let arr4 = [language.daily_expense_budget_perf];
        dayData.forEach((item) => {
            arr1.push(moment(item._id).format("YYYY-MM-DD"));
            arr2.push(item.actualProfit);
            // arr3.push(item.budgetExpense);
            // arr4.push(item.budgetPerformance);
        })

        // week
        let arr5 = [language.weekly];
        let arr6 = [isBusiness ? language.weekly_profit_amt : language.weekly_margin_amt];
        // let arr7 = [language.weekly_expense_budget_amt];
        // let arr8 = [language.weekly_expense_budget_perf];
        weekData.forEach((item) => {
            arr5.push(language.week + " " + item._id);
            arr6.push(item.actualProfit);
            // arr7.push(item.budgetExpense);
            // arr8.push(item.budgetPerformance);
        })

        // month
        let arr9 = [language.monthly];
        let arr10 = [isBusiness ? language.monthly_profit_amt : language.monthly_margin_amt];
        // let arr11 = [language.monthly_expense_budget_amt];
        // let arr12 = [language.monthly_expense_budget_perf];
        monthData.forEach((item) => {
            arr9.push(getMonthName(item._id));
            arr10.push(item.actualProfit);
            // arr11.push(item.budgetExpense);
            // arr12.push(item.budgetPerformance);
        })

        // year
        let arr13 = [language.yearly];
        let arr14 = [isBusiness ? language.yearly_profit_amt : language.yearly_margin_amt];
        // let arr15 = [language.yearly_expense_budget_amt];
        // let arr16 = [language.yearly_expense_budget_perf];
        yearData.forEach((item) => {
            arr13.push(item.year);
            arr14.push(item.actualprofit);
            // arr15.push(item.actualBudget);
            // arr16.push(item.budgetpersormance);
        })

        myArray = [...myArray, arr1, arr2, [], arr5, arr6, [], arr9, arr10, [], arr13, arr14];

        // console.log('myArray', myArray)

        setProfitabilityExcel(myArray);
    }

    return (
        <div>
            {/* Daily */}
            <h4>{language.daily}</h4>
            <div className='table-responsive'>
                <table className="table table-striped gen-fin-table">
                    <thead>
                        <tr>
                            <th scope="col"></th>
                            {
                                pagiDayData.length
                                    ?
                                    pagiDayData.map((item, i) =>
                                        <th scope="col" key={i}>{moment(item._id).format("YYYY-MM-DD")}</th>
                                    )
                                    :
                                    // language.no_data
                                    ""
                            }
                        </tr>
                    </thead>
                    <tbody className="">
                        <tr>
                            <td className='report-first-column'>
                                {isBusiness ? language.daily_profit_amt : language.daily_margin_amt}
                            </td>
                            {
                                pagiDayData.length
                                    ?
                                    pagiDayData.map((item, i) =>
                                        <td key={i}>{item.actualProfit?.toFixed(2)}</td>
                                    )
                                    :
                                    "-"
                            }
                        </tr>
                    </tbody>
                </table>
            </div>
            <PaginationReport
                originalData={dayData}
                setPaginateData={setPagiDayData}
            />

            {/* Weekly */}
            <h4 className="mt-4">{language.weekly}</h4>
            <div className='table-responsive'>
                <table className="table table-striped gen-fin-table">
                    <thead>
                        <tr>
                            <th scope="col"></th>
                            {
                                pagiWeekData.length && pagiWeekData.map((item, i) =>
                                    <th scope="col" key={i}>{language.week} {item._id}</th>
                                )
                            }
                        </tr>
                    </thead>
                    <tbody className="">
                        <tr>
                            <td className='report-first-column'>
                                {isBusiness ? language.weekly_profit_amt : language.weekly_margin_amt}
                            </td>
                            {
                                pagiWeekData.length && pagiWeekData.map((item, i) =>
                                    <td key={i}>{item.actualProfit?.toFixed(2)}</td>
                                )
                            }
                        </tr>
                    </tbody>
                </table>
            </div>
            <PaginationReport
                originalData={weekData}
                setPaginateData={setPagiWeekData}
            />

            {/* Monthly */}
            <h4 className="mt-4">{language.monthly}</h4>
            <div className='table-responsive'>
                <table className="table table-striped gen-fin-table">
                    <thead>
                        <tr>
                            <th scope="col"></th>
                            {
                                pagiMonthData.map((item, i) =>
                                    <th scope="col" key={i}>{getMonthName(item._id)}</th>
                                )
                            }
                        </tr>
                    </thead>
                    <tbody className="">
                        <tr>
                            <td className='report-first-column'>
                                {isBusiness ? language.monthly_profit_amt : language.monthly_margin_amt}
                            </td>
                            {
                                pagiMonthData.map((item, i) =>
                                    <td key={i}>{item.actualProfit?.toFixed(2)}</td>
                                )
                            }
                        </tr>
                    </tbody>
                </table>
            </div >
            <PaginationReport
                originalData={monthData}
                setPaginateData={setPagimonthData}
            />

            {/* Yearly */}
            <h4 className="mt-4">{language.yearly}</h4>
            <div className='table-responsive'>
                <table className="table table-striped gen-fin-table">
                    <thead>
                        <tr>
                            <th scope="col"></th>
                            {
                                pagiYearData.map((item, i) =>
                                    <th scope="col" key={i}>{item.year}</th>
                                )
                            }
                        </tr>
                    </thead>
                    <tbody className="">
                        <tr>
                            <td className='report-first-column'>
                                {isBusiness ? language.yearly_profit_amt : language.yearly_margin_amt}
                            </td>
                            {
                                pagiYearData.map((item, i) =>
                                    <td>{item.actualprofit?.toFixed(2)}</td>
                                )
                            }
                        </tr>
                    </tbody>
                </table>
            </div>
            <PaginationReport
                originalData={yearData}
                setPaginateData={setPagiYearData}
            />
        </div>
    )
}

export default ProfitabilityTable