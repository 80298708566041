import React, { useEffect, useState } from 'react'
import "react-datetime/css/react-datetime.css";
import Datetime from "react-datetime";
import moment from 'moment';
import { toast } from 'react-hot-toast';
import Swal from 'sweetalert2';
import HttpClient from '../../utils/HttpClient';
import { useLanguage } from '../../Providers/LanguageContext';

function ExpenseEditInput({ type = "text", field, id, data, successmsg, errormsg, width }) {

    console.log('dataaa', data.vendorName);
    const { language } = useLanguage();

    const [editEnabled, setEditEnabled] = useState(false)

    const [value, setValue] = useState(data.price);



    const [isSaved, setIsSaved] = useState(false)
    const handleEdit = () => {
        setEditEnabled(prev => !prev);
    }

    const handleVal = (value) => {
        setValue(value.target.value)
    }

    const handleSave = () => {
        setIsSaved(true)
    }

    const handleEditField = async () => {
        if (!value) {
            return
        }
        if (data.vendorName) {
            const res = await HttpClient.requestData("update-expense/" + data._id, "PUT", { price: value });
            if (res && res.status) {
                toast.success(successmsg);
                // getTransactionData(data.transactionFilter, data.email)
                console.log(res)
                fetchExpenseSingle()
            } else {
                toast.error(errormsg)
            }
        } else {
            const res = await HttpClient.requestData("update-sales/" + data._id, "PUT", { price: value });
            if (res && res.status) {
                toast.success(language.sales_updated);
                // getTransactionData(data.transactionFilter, data.email)
                console.log(res)
                fetchExpenseSingle()
            } else {
                toast.error(errormsg)
            }
        }
    }

    useEffect(() => {
        if (data._id) {
            fetchExpenseSingle()
        }
    }, [data._id])

    const fetchExpenseSingle = async () => {
        if (data.vendorName) {
            const res = await HttpClient.requestData("view-expense-details_single/" + data._id, "GET", {});
            console.log(res, "singleexpense")
            if (res && res.status) {
                setValue(res.data[0].price)
            }
        }
        else {
            const res2 = await HttpClient.requestData("get-sales/" + data._id, "GET", {});
            console.log('res22', res2)
            if (res2 && res2.status) {
                setValue(res2.data.price)
            }
        }
    }

    // const fetchSalesSingle = async () => {
    //     console.log('fetchSalesSinglepp')
    //     const res2 = await HttpClient.requestData("get-sales/" + data._id, "GET", {});
    //     console.log('res22', res2)
    //     if (res2 && res2.status) {
    //         setValue(res2.data.price)
    //     }
    // }

    return (
        <div className='mt-3' style={{}}>
            <div className="td-details mb-2" style={{
                // display: "flex",
                // justifyContent: "center", alignItems: "center",
                // gap: "8px", width: "100%", background: "lightgray", padding: "10px"
            }}
            >

                <div style={{}}>
                    <label htmlFor="inputdate" style={{ marginRight: "0", fontSize: "25px", width: "170px", textAlign: "left" }}>
                        {/* {
                            field === "expense" ? language.expense : field === "vendorName" ? language.supplier : field === "expenseTypeID" ? language.expensetype : ""
                        } */}
                        {
                            data.vendorName ? language.expense : language.sales
                        }
                    </label>
                    <div className='custom-wrap' style={{ display: "flex", justifyContent: "start" }}>
                        {type !== "select" && (
                            <input type={type} style={{ width: "100%" }} value={value} id="inputdate" disabled={!editEnabled} onChange={handleVal} />
                        )}
                    </div>

                    <div style={{ display: "flex", justifyContent: "start" }}>
                        <button className='customBtn10 me-2' onClick={handleEdit}>{editEnabled ? language.disable_edit : language.edit}</button>
                        <button className='customBtn10 me-2' onClick={handleSave}>{language.save}</button>
                        <button className='customBtn10' onClick={handleEditField} disabled={!isSaved}>{language.post}</button>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default ExpenseEditInput