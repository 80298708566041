
import React, { useEffect, useState, useRef } from "react";
import "./DocumentManager.css";
import ImportDocument from './ImportDocument';
import Slider from "react-slick";
import DocumentVault from "./DocumentVault";
import { useLocation, useNavigate } from "react-router-dom";
import HttpClient from "../../utils/HttpClient";
import { useLanguage } from "../../Providers/LanguageContext";

function Index() {
    const location = useLocation();
    const { language } = useLanguage();
    const navigate = useNavigate();

    // Create a reference for the Slider component
    const sliderRef = useRef(null);

    // State to track the current slide index
    const [currentSlide, setCurrentSlide] = useState(location.state?.slideNo || 0);

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: location.state?.slideNo || 0,
        afterChange: (current) => setCurrentSlide(current) // Update current slide index
    };

    const [docData, setDocData] = useState([]);

    const getAllDocument = async () => {
        const res = await HttpClient.requestData("view/count/selfservice-document", "GET", {});
        if (res && res?.status) {
            setDocData(res?.data);
        }
    };

    useEffect(() => {
        getAllDocument();
    }, []);

    // Handle slide change
    const goToSlide = (slideIndex) => {
        if (sliderRef.current) {
            sliderRef.current.slickGoTo(slideIndex);
        }
    };

    return (
        <>
            <div className="DocumentManager_mainwrap">
                <div className='w-75' style={{marginLeft:"7.5rem"}}>
                    <button
                        className={`w-25 mb-2 btn ${currentSlide === 0 ? 'btn-success' : 'btn-outline-success'}`}
                        style={{ fontWeight: "bold" }}
                        onClick={() => goToSlide(0)}
                        disabled={currentSlide === 0} // Disable button if already on slide 0
                    >
                        {language.imp_docs}
                    </button>
                    <button
                        className={`w-25 mb-2 btn mx-1 ${currentSlide === 1 ? 'btn-success' : 'btn-outline-success'}`}
                        style={{ fontWeight: "bold" }}
                        onClick={() => goToSlide(1)}
                        disabled={currentSlide === 1} // Disable button if already on slide 1
                    >
                        {language.view_docs_vault}
                    </button>
                </div>

                <Slider ref={sliderRef} {...settings}>
                    <div>
                        <ImportDocument getAllDocument={getAllDocument} />
                    </div>
                    <div>
                        <DocumentVault docData={docData} />
                    </div>
                </Slider>
            </div>
        </>
    );
}

export default Index;

