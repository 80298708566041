import React from 'react'
import iMg1 from "../../images/ilustraciones soluciones industriales_Mesa de trabajo 1 copia 34.png";
import iMg2 from "../../images/ilustraciones soluciones industriales_HOSPITALIDAD - HOTELERIA 2.png";
import iMg3 from "../../images/ilustraciones soluciones industriales_HOSPITALIDAD - HOTELERIA 3.png";
import iMg3en from "../../images/ilustraciones soluciones industriales_HOSPITALIDAD - HOTELERIA 3 EN.png";

import { useLanguage } from '../../Providers/LanguageContext';
import SignUpButton from './SignUpButton';
const Hospitality = () => {
  const { language, currentLanguage } = useLanguage();

  return (
    <>
      <section id='agriculture_sec'>
        <div className='container'>
          <div className='row mb-5'>
            <div className='col-md-6 col-12 m-auto'>
              <div className='agriculture_head'>
                <h3 style={{ color: "#5db8d1" }}>{language.hospitality}</h3>
              </div>

              <div className='agriculture_headtext'>
                <h4 style={{ color: "#e5c028" }}>{language.streamline_expanse_tracking}</h4>
                <p>{language.fisi_sopitality_business}
                </p>
              </div>
            </div>
            <div className='col-md-6 col-12 m-auto'>
              <div className='agriculture_img'>
                <img src={iMg1} className='img-fluid' alt='' />
              </div>
            </div>
          </div>

          <div className='row row_reverse mb-5'>
            <div className='col-md-6 col-12 m-auto'>
              <div className='agriculture_img'>
                <img src={iMg2} className='img-fluid' alt='' />
              </div>
            </div>
            <div className='col-md-6 col-12 m-auto'>
              <div className='agriculture_headtext'>
                <h4 style={{ color: "#5db8d1", textAlign: "left" }}>{language.automated_reporting}</h4>
                <p>{language.fisi_auto_generate}</p>

              </div>
              <div className='agriculture_headtext pt-5'>
                <h4 style={{ color: "#e8c845", textAlign: "left" }}>{language.inventory_management}</h4>
                <p>{language.fisi_includes_inventory}</p>

              </div>
            </div>
          </div>

          <div className='col-md-12 col-12 m-auto py-5'>
            <div className='agriculture_headtext'>
              <h4 style={{ color: "#5db8d1", textAlign: "left" }}>{language.simplified_app_complience}</h4>
              <p>{language.app_simplifies_complience}
              </p>
            </div>
          </div>
          <div className='row mb-5 mt-5'>

            <div className='col-md-6 col-12'>
              <div className='agriculture_headtext'>
                <h4 style={{ color: "#e5c028", textAlign: "left" }}>{language.enhance_financial_insight}</h4>
                <p>{language.providing_detailed_financial_insight}
                </p>
              </div>
            </div>
            <div className='col-md-6 col-12 m-auto'>
              <div className='agriculture_img'>
                {
                  currentLanguage === 'spanish'
                    ?
                    <img src={iMg3} className='img-fluid' alt='' />
                    :
                    <img src={iMg3en} className='img-fluid' alt='' />

                }
                {/* <div className='registrarAnddescargar' style={{ transform: "translate(-50% , -50%)", top: "100%", marginTop: "30px" }}>
                  <button className='registrar_Btn' style={{ background: "#4aa7bc", border: "0" }}>Registrarse</button>
                  <button className='descargar_Btn' style={{ borderColor: "#f8d846", color: "#f8d846" }}>Descargar</button>
                </div> */}
                <SignUpButton />
              </div>
            </div>
          </div>


        </div>
      </section>


    </>
  )
}

export default Hospitality