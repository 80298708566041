import React from "react";
import ManImg from "../../images/girlImg.png";
import Personsbg from "../../images/personsbg.png";
import phoneSet from "../../images/Archivoenlazado.png";
import GooglePlay from "../../images/google-play.png";
import AppStore from "../../images/app-store.png";
import { useLanguage } from "../../Providers/LanguageContext";
import phonesetEn from '../../images/TELEFONOS INGLES.png'

function Persons() {
  const { language, currentLanguage } = useLanguage();
  const points = [
    language.homeBannerPoint1,
    language.homeBannerPoint2,
    language.homeBannerPoint3,
    language.homeBannerPoint4,
    language.homeBannerPoint5,
    language.homeBannerPoint6,
  ];
  return (
    <>
      <section
        id="persons_area"
        style={{ backgroundImage: `url('${Personsbg}')` }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-6">
              <div className="left_area">
                <img src={ManImg} className="img-fluid" alt="image" />
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-6">
              <div className="right_area">
                <div className="head">
                  <h2>{language.homePointsHeading}</h2>
                </div>

                <div className="head2">
                  <h2 style={{ fontWeight: "bold" }}>
                    {language.homePointsHeading}
                  </h2>
                </div>

                <div className="all_list">
                  <ul>
                    {points.map((item, i) => {
                      return <li key={i}>{item}</li>;
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="persons_bottom">
        <div className="container">
          <div className="top">
            <div className="all_list">
              <ul>
                {points.map((item, i) => {
                  return <li key={i}>{item}</li>;
                })}
              </ul>
            </div>

            <div className="img_area">
              {
                currentLanguage === 'eng'
                  ?
                  <img src={phoneSet} className="img-fluid" alt="image" />
                  :
                  <img src={phonesetEn} className="img-fluid" alt="image" />
              }
            </div>
          </div>
          <div className="description">
            <div className="head">
              <h2>{language.homeControlHead.toUpperCase()}</h2>
            </div>
            <div className="text">
              <p>{language.homeControlPara}</p>
            </div>

            <div className="googleapp_area">
              <div className="google_img">
                <a href='https://play.google.com/store/apps/details?id=com.fisibilitylite' target={"_blank"}>
                  <img src={GooglePlay} className="img-fluid" alt="image" />
                </a>
              </div>
              <div className="app_store">
                <a href="https://apps.Apple.com/mx/app/Fisibility-Lite/id1633678357" target={"_blank"}>
                  <img src={AppStore} className="img-fluid" alt="image" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Persons;
