import React from "react";
import BookCustModal from "../../Modal/BookCustModal";
import BookCustDocMissingModal from "../../Modal/BookCustDocMissingModal";

function BookkeperLink() {
  function bookLinkTabOff() {
    document.querySelector(".bookkLinkTable").classList.add("disTabDiv");
    document.querySelector(".bookkManaGrandMain").classList.remove("disTabDiv");
    document.querySelector(".bookLinkNextBtn").style.cssText +=
      "display: none;";
    document.querySelector(".bookLinkPrevBtn").style.cssText +=
      "display: block;";
    document.querySelector(".bokkLinkHead").innerHTML =
      "Manage Accounts Dashboard";
  }

  function bookLinkTabOn() {
    document.querySelector(".bookkLinkTable").classList.remove("disTabDiv");
    document.querySelector(".bookkManaGrandMain").classList.add("disTabDiv");
    document.querySelector(".bookLinkNextBtn").style.cssText +=
      "display: block;";
    document.querySelector(".bookLinkPrevBtn").style.cssText +=
      "display: none;";
    document.querySelector(".bokkLinkHead").innerHTML = "Link Accounts";
  }

  const bookCusts = document.querySelectorAll(".bookkCustName");

  for (let bookCust of bookCusts) {
    bookCust.addEventListener("click", function () {
      document
        .querySelector(".bookCustModalSection")
        .classList.remove("bookCustDisplayModalDiv");
    });
  }

  const bookMisss = document.querySelectorAll(".bookkCustNumber");
  for (let bookMiss of bookMisss) {
    bookMiss.addEventListener("click", function () {
      document
        .querySelector(".bookkMissModalSection")
        .classList.remove("bookCustDisplayModalDiv");
    });
  }

  return (
    <>
      <section className="bookkeLinkSection">
        <div className="container">
          <div className="bookkeMainHeadDiv">
            <span className="bookkeMainHead">
              Bookkeeper Manager - Link & Manage Accounts
            </span>
          </div>
          <div className="bookDocHeadDiv">
            <span className="bokkHead bokkLinkHead">Link Accounts</span>
          </div>
          <div className="bookkLinkTableDiv">
            <div className="bookkManaGrandDiv1">
              <table className="bookkLinkTable">
                <thead>
                  <tr>
                    <th className="bookkLinkTableHead">
                      Enter Account Code
                      <button className="bookkLinkPlusBtn"><i class="fa-solid fa-plus"></i></button>
                      {/* <button className="bookkLinkMinusBtn"><i class="fa-solid fa-minus"></i></button> */}
                    </th>
                    <th className="bookkLinkTableHead">Link Account</th>
                    <th className="bookkLinkTableHead">Client Name</th>
                    <th className="bookkLinkTableHead">Client Type</th>
                    <th className="bookkLinkTableHead">Subscription Type</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="bookkLinkTableData">
                      <input
                        type="number"
                        className="bkkLinInp"
                        placeholder="710552470"
                      />
                    </td>
                    <td className="bookkLinkTableData">
                      <button className="bookLiBtn">LINK</button>
                      <button className="bookRmBtn">Remove</button>
                    </td>
                    <td className="bookkLinkTableData">
                      <span className="bookkLiCmnyName">ABC Enterprises</span>
                    </td>
                    <td className="bookkLinkTableData">
                      <span className="bookkLiCmnyName">Business</span>
                    </td>
                    <td className="bookkLinkTableData">
                      <span className="bookkLiCmnyName">Basic Plan</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="bookkLinkTableData">
                      <input
                        type="number"
                        className="bkkLinInp"
                        placeholder="710552470"
                      />
                    </td>
                    <td className="bookkLinkTableData">
                      <button className="bookLiBtn">LINK</button>
                      <button className="bookRmBtn">Remove</button>
                    </td>
                    <td className="bookkLinkTableData">
                      <span className="bookkLiCmnyName">Jane Doe</span>
                    </td>
                    <td className="bookkLinkTableData">
                      <span className="bookkLiCmnyName">Personal</span>
                    </td>
                    <td className="bookkLinkTableData">
                      <span className="bookkLiCmnyName">Custom Plan</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="bookkLinkTableData">
                      <input
                        type="number"
                        className="bkkLinInp"
                        placeholder="710552470"
                      />
                    </td>
                    <td className="bookkLinkTableData">
                      <button className="bookLiBtn">LINK</button>
                      <button className="bookRmBtn">Remove</button>
                    </td>
                    <td className="bookkLinkTableData">
                      <span className="bookkLiCmnyName">John Doe</span>
                    </td>
                    <td className="bookkLinkTableData">
                      <span className="bookkLiCmnyName">Personal</span>
                    </td>
                    <td className="bookkLinkTableData">
                      <span className="bookkLiCmnyName">N/A</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="bookkLinkTableData">
                      <input
                        type="number"
                        className="bkkLinInp"
                        placeholder="710552470"
                      />
                    </td>
                    <td className="bookkLinkTableData">
                      <button className="bookLiBtn">LINK</button>
                      <button className="bookRmBtn">Remove</button>
                    </td>
                    <td className="bookkLinkTableData">
                      <span className="bookkLiCmnyName">DEF Business</span>
                    </td>
                    <td className="bookkLinkTableData">
                      <span className="bookkLiCmnyName">Business</span>
                    </td>
                    <td className="bookkLinkTableData">
                      <span className="bookkLiCmnyName">Custom Plan</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="bookkLinkTableData">
                      <input
                        type="number"
                        className="bkkLinInp"
                        placeholder="710552470"
                      />
                    </td>
                    <td className="bookkLinkTableData">
                      <button className="bookLiBtn">LINK</button>
                      <button className="bookRmBtn">Remove</button>
                    </td>
                    <td className="bookkLinkTableData">
                      <span className="bookkLiCmnyName">ABC Enterprises</span>
                    </td>
                    <td className="bookkLinkTableData">
                      <span className="bookkLiCmnyName">Business</span>
                    </td>
                    <td className="bookkLinkTableData">
                      <span className="bookkLiCmnyName">Basic Plan</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="bookkLinkTableData">
                      <input
                        type="number"
                        className="bkkLinInp"
                        placeholder="710552470"
                      />
                    </td>
                    <td className="bookkLinkTableData">
                      <button className="bookLiBtn">LINK</button>
                      <button className="bookRmBtn">Remove</button>
                    </td>
                    <td className="bookkLinkTableData">
                      <span className="bookkLiCmnyName">XYZ Corporation</span>
                    </td>
                    <td className="bookkLinkTableData">
                      <span className="bookkLiCmnyName">Business</span>
                    </td>
                    <td className="bookkLinkTableData">
                      <span className="bookkLiCmnyName">Basic Plan</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <button className="bookLinkNextBtn" onClick={bookLinkTabOff}>
              <i class="fa-solid fa-angle-right"></i>
            </button>
            <button className="bookLinkPrevBtn" onClick={bookLinkTabOn}>
              <i class="fa-solid fa-angle-left"></i>
            </button>
            <div className="bookkManaGrandMain disTabDiv">
              <div className="bookkManaMain">
                <div className="bookkManaLeft">
                  <div className="bookkMainInpLabel">
                    <label className="bookkManaLabel">Client Name:</label>
                    <input
                      type="search"
                      placeholder="Type-to-Search"
                      className="bookkManaInp"
                    />
                  </div>
                  <div className="bookkMainInpLabel">
                    <label className="bookkManaLabel">Date Rage:</label>
                    <div className="bookkManaInpDiv">
                      <input type="date" />
                      <input type="date" />
                    </div>
                  </div>
                  <div className="bookkMainInpLabel">
                    <label className="bookkManaLabel">Month & Year:</label>
                    <div className="bookkManaInpDiv">
                      <input
                        type="number"
                        placeholder="Month"
                        min="1"
                        max="12"
                      />
                      <input type="number" placeholder="Year" />
                    </div>
                  </div>
                </div>
                <div className="bookkManaRight">
                  <div>
                    <label className="bookkManaLabel">
                      Total Active Clients:
                    </label>
                    <span className="bookkManaSpan">5</span>
                  </div>
                  <div>
                    <label className="bookkManaLabel">
                      Total Monthly Fees:
                    </label>
                    <span className="bookkManaSpan">$12,754.00</span>
                  </div>
                  <div>
                    <label className="bookkManaLabel">
                      Average Monthly Fees:
                    </label>
                    <span className="bookkManaSpan">$2,550.80</span>
                  </div>
                </div>
              </div>
              <div className="bookkManaBtnDiv">
                <button className="bookkManaBtn">Clear All</button>
              </div>
              <div className="bookkLinkTableDiv2">
                <table className="bookkLinkTable2">
                  <thead>
                    <tr>
                      <th className="bookkLinkTableHead2">Client Name</th>
                      <th className="bookkLinkTableHead2">Missing Docs</th>
                      <th className="bookkLinkTableHead2">Reminder</th>
                      <th className="bookkLinkTableHead2">Account Code</th>
                      <th className="bookkLinkTableHead2">Internal Code</th>
                      <th className="bookkLinkTableHead2">Monthly Fee $</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="bookkLinkTableData2">
                        <button className="bookkCustName">
                          ABC Enterprises
                        </button>
                      </td>
                      <td className="bookkLinkTableData2">
                        <button className="bookkCustNumber">2</button>
                      </td>
                      <td className="bookkLinkTableData2">
                        <button className="bookkCustSendBtn">SEND</button>
                      </td>
                      <td className="bookkLinkTableData2">
                        <input
                          type="number"
                          placeholder="710552470"
                          className="bookkTableCustInp"
                        />
                      </td>
                      <td className="bookkLinkTableData2">
                        <input
                          type="text"
                          placeholder="E&Y ID: 1234"
                          className="bookkTableCustInp"
                        />
                      </td>
                      <td className="bookkLinkTableData2">
                        <span className="bookkLinkCustAmnt">$1.877.00</span>
                      </td>
                    </tr>
                    <tr>
                      <td className="bookkLinkTableData2">
                        <button className="bookkCustName">Jane Doe</button>
                      </td>
                      <td className="bookkLinkTableData2">
                        <button className="bookkCustNumber">2</button>
                      </td>
                      <td className="bookkLinkTableData2">
                        <button className="bookkCustSendBtn">SEND</button>
                      </td>
                      <td className="bookkLinkTableData2">
                        <input
                          type="number"
                          placeholder="710552470"
                          className="bookkTableCustInp"
                        />
                      </td>
                      <td className="bookkLinkTableData2">
                        <input
                          type="text"
                          placeholder="E&Y ID: 1234"
                          className="bookkTableCustInp"
                        />
                      </td>
                      <td className="bookkLinkTableData2">
                        <span className="bookkLinkCustAmnt">$4,500.00</span>
                      </td>
                    </tr>
                    <tr>
                      <td className="bookkLinkTableData2">
                        <button className="bookkCustName">
                          ABC Enterprises
                        </button>
                      </td>
                      <td className="bookkLinkTableData2">
                        <button className="bookkCustNumber">2</button>
                      </td>
                      <td className="bookkLinkTableData2">
                        <button className="bookkCustSendBtn">SEND</button>
                      </td>
                      <td className="bookkLinkTableData2">
                        <input
                          type="number"
                          placeholder="710552470"
                          className="bookkTableCustInp"
                        />
                      </td>
                      <td className="bookkLinkTableData2">
                        <input
                          type="text"
                          placeholder="E&Y ID: 1234"
                          className="bookkTableCustInp"
                        />
                      </td>
                      <td className="bookkLinkTableData2">
                        <span className="bookkLinkCustAmnt">N/A</span>
                      </td>
                    </tr>
                    <tr>
                      <td className="bookkLinkTableData2">
                        <button className="bookkCustName">DEF Business</button>
                      </td>
                      <td className="bookkLinkTableData2">
                        <button className="bookkCustNumber">2</button>
                      </td>
                      <td className="bookkLinkTableData2">
                        <button className="bookkCustSendBtn">SEND</button>
                      </td>
                      <td className="bookkLinkTableData2">
                        <input
                          type="number"
                          placeholder="710552470"
                          className="bookkTableCustInp"
                        />
                      </td>
                      <td className="bookkLinkTableData2">
                        <input
                          type="text"
                          placeholder="E&Y ID: 1234"
                          className="bookkTableCustInp"
                        />
                      </td>
                      <td className="bookkLinkTableData2">
                        <span className="bookkLinkCustAmnt">$4,500.00</span>
                      </td>
                    </tr>
                    <tr>
                      <td className="bookkLinkTableData2">
                        <button className="bookkCustName">
                          XYZ Corporation
                        </button>
                      </td>
                      <td className="bookkLinkTableData2">
                        <button className="bookkCustNumber">2</button>
                      </td>
                      <td className="bookkLinkTableData2">
                        <button className="bookkCustSendBtn">SEND</button>
                      </td>
                      <td className="bookkLinkTableData2">
                        <input
                          type="number"
                          placeholder="710552470"
                          className="bookkTableCustInp"
                        />
                      </td>
                      <td className="bookkLinkTableData2">
                        <input
                          type="text"
                          placeholder="E&Y ID: 1234"
                          className="bookkTableCustInp"
                        />
                      </td>
                      <td className="bookkLinkTableData2">
                        <span className="bookkLinkCustAmnt">$1.877.00</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
      <BookCustModal />
      <BookCustDocMissingModal />
    </>
  );
}

export default BookkeperLink;
