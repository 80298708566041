import { async } from '@firebase/util';
import React, { useContext, useMemo } from 'react'
import { AiOutlineArrowLeft } from 'react-icons/ai';
import { Link, useNavigate } from 'react-router-dom';
import { EmailShareButton, FacebookShareButton, TwitterShareButton, WhatsappShareButton } from 'react-share';
import Inviteimage from "../../images/inviteImage.png";
import { useLanguage } from '../../Providers/LanguageContext';
import { SpeechContext } from '../../Providers/SpeechWrapper';
import { useUserData } from '../../Providers/UserWrapper';
import HttpClient from '../../utils/HttpClient';

function Index() {
    const { language } = useLanguage();
    const { profileData, setShow } = useUserData();
    const { currAudio, setCurrAudio, playing, setShowGif, setGifToShow, setGifPoints } = useContext(SpeechContext);


    const navigate = useNavigate();
    // console.log('profileDatafriend',profileData.reffCode)
    const sendText = language.friend_share_text;
    const sendText2 = language.enter_invite_code;
    const sendText3 = language.kind_regards;
    // const sendLink = `${sendText} \nhttp://35.154.235.57:4008/login \n${sendText2} ${profileData && profileData.reffCode}. ${sendText3}`
    const sendLink = `${sendText} \nhttps://fisibility.com/login \n${sendText2} ${profileData && profileData.reffCode}. ${sendText3}`
    console.log(sendLink, 'sendLink')

    const reffCodeMemo = useMemo(() => {
        if (profileData) {
            return profileData.reffCode
        } else {
            return ""
        }
    }, [profileData])
    console.log("reffCodeMemo", reffCodeMemo)

    const handleShare = async () => {
        const res = await HttpClient.requestData('add-invitation', 'POST', {});
        console.log('resShare', res);
        // setGifPoints(25);
        // setTimeout(() => {
        //     setGifPoints(0);
        // }, 3000);

    }

    const handleGif = async () => {
        const res = await HttpClient.requestData('add-invitation', 'POST', {});
        console.log('resShare', res);
        setGifPoints(25);
        setTimeout(() => {
            setGifPoints(0);
        }, 3000);
    }

    // const sendEmail = () => {
    //     const email = 'example@example.com';
    //     const subject = 'Check out this website!';
    //     const body = `Hey,

    // I found this amazing website that you might be interested in. Here is the link: ${window.location.href}

    // Check it out!

    // Best regards,
    // Your Name`;

    //     const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    //     window.location.href = mailtoLink;
    // };

    // const handleFbShare = () => {
    //     const url = `https://fisibility.com/login/${reffCodeMemo}`
    //     window.open(url, "_blank")
    // }

    // const shareUrl = 'https://fisibility.com';
    // const shareQuote = 'Check out this amazing website!';
    // const handleClick = () => {
    //     window.FB.ui({
    //         method: 'share',
    //         href: shareUrl,
    //         quote: shareQuote
    //     }, function (response) { });
    // };

    return (

        <>
            <section id="invite">
                {/* <button onClick={sendEmail}>click</button> */}
                <div className='container'>
                    <div
                        style={{ fontSize: '53px' }}
                        onClick={() => {
                            navigate('/manager');
                            // setShow(true);
                        }}
                    >
                        <AiOutlineArrowLeft />
                    </div>
                    <div className='row'>
                        <div className='col-xl-6 col-lg-6 col-md-12 col-12'>
                            <div className='invite-left'>

                                <div className='heading1'>
                                    <h1>{language.hey}!</h1>
                                </div>
                                <div className='heading2'>
                                    <h2>{language.invite_friend}</h2>
                                </div>
                                <div className='description'>
                                    <p>{language.invite_friend_text}</p>
                                    <p>{language.your_reference_code} {profileData && profileData.reffCode}</p>
                                </div>
                                <div className='invite-social'>
                                    <ul>
                                        <FacebookShareButton
                                            // url={sendLink}
                                            url={`https://fisibility.com/refferenceCode/${reffCodeMemo}`}
                                            // hashtag={"#investment"}
                                            quote="fisibility brings money"
                                            // onClick={handleShare}
                                            onShareWindowClose={handleGif}
                                        >
                                            <li><a href="#" className='invite-icon'><i class="fa-brands fa-facebook-f"></i></a></li>
                                        </FacebookShareButton>

                                        {/* <li onClick={handleFbShare}>
                                            <a href="#" className='invite-icon'><i class="fa-brands fa-facebook-f"></i></a>
                                        </li> */}

                                        <EmailShareButton
                                            url={sendLink}
                                            // url="https://fisibility.com"
                                            body={sendLink}
                                            // separator="https://fisibility.com"
                                            subject={'Fisibility Refferece Code'}
                                            onShareWindowClose={handleGif}
                                        // onClick={handleShare}
                                        >
                                            <li onClick={handleShare}><a href="#" className='invite-icon'><i class="fa-regular fa-envelope"></i></a></li>
                                        </EmailShareButton>

                                        <WhatsappShareButton
                                            url={sendLink}
                                            // onClick={handleShare}
                                            onShareWindowClose={handleGif}

                                        >
                                            <li><a href="#" className='invite-icon'><i class="fa-brands fa-whatsapp"></i></a></li>
                                        </WhatsappShareButton>

                                        <TwitterShareButton
                                            url={sendLink}
                                            // onClick={handleShare}
                                            onShareWindowClose={handleGif}
                                        >
                                            <li><a href="#" className='invite-icon'><i class="fa-brands fa-twitter"></i></a></li>
                                        </TwitterShareButton>
                                    </ul>
                                </div>
                                <div className='social-info'>
                                    <Link to='/information' className='info-btn'>{language.information}</Link>
                                </div>
                            </div>
                        </div>
                        <div className='col-xl-6 col-lg-6 col-md-12 col-12'>
                            <div className='invite-image'>
                                <img className="img-fluid" src={Inviteimage} alt="img" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>

    )
}

export default Index;