// import React from 'react'
// import Modal from 'react-modal';

// const closeStyle = {
//     color: "#aaaaaa",
//     float: "right",
//     fontSize: "28px",
//     fontWeight: "bold",
//     cursor: "pointer"
// }

// const customStyles = {
//     content: {
//         top: '50%',
//         left: '50%',
//         right: 'auto',
//         bottom: 'auto',
//         marginRight: '-50%',
//         transform: 'translate(-50%, -50%)',
//         width: "50%",

//     },
// };


// const TotalDOcumentModal = ({ modalIsOpen, closeModal, singleTotalDoc }) => {


//     console.log("singleTotalDoccv", singleTotalDoc?.data)
//     return (
//         <div>
//             <div>
//                 <Modal
//                     isOpen={modalIsOpen}
//                     // onAfterOpen={afterOpenModal}
//                     onRequestClose={closeModal}
//                     style={customStyles}
//                     contentLabel="Example Modal"
//                 >
//                     <span onClick={() => closeModal()} style={closeStyle}>&times;</span>

//                     <h3 className='d-flex justify-content-center'>Customer Invoice</h3>

//                     <div className='d-flex justify-content-center mt-4'>
//                         <div className='invoicedocModalTotal'>
//                             {
//                                 singleTotalDoc?.data?.map((item, i) =>
//                                     <div>
//                                         <a
//                                             key={i}
//                                             href={item?.file}
//                                             target='_blank'
//                                         >
//                                             {item?.documentName}
//                                         </a>
//                                     </div>
//                                 )
//                             }
//                         </div>
//                     </div>
//                 </Modal>
//             </div>
//         </div>
//     )
// }

// export default TotalDOcumentModal


import React from 'react';
import Modal from 'react-modal';

const closeStyle = {
    color: "#aaaaaa",
    float: "right",
    fontSize: "28px",
    fontWeight: "bold",
    cursor: "pointer"
};

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: "50%",
    },
};

const TotalDOcumentModal = ({ modalIsOpen, closeModal, singleTotalDoc }) => {
    console.log("singleTotalDoc", singleTotalDoc)
    return (
        <div>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Document Modal"
            >
                <span onClick={closeModal} style={closeStyle}>&times;</span>

                <h3 className='d-flex justify-content-center'>
                    {singleTotalDoc?.typeNameEnglish || 'Documents'}
                </h3>

                <div className='d-flex justify-content-center mt-4'>
                    <div className='invoicedocModalTotal'>
                        {/* {singleTotalDoc && singleTotalDoc?.length !== 0 &&
                            singleTotalDoc?.data?.map((item, i) => {
                                return
                                (
                                    <div key={i}>
                                        <a
                                            href={item?.file}
                                            target='_blank'
                                            rel='noopener noreferrer'
                                        >
                                            {item?.documentName}
                                        </a>
                                    </div>
                                )
                            })} */}
                        {
                            singleTotalDoc && singleTotalDoc?.length !== 0 &&
                            singleTotalDoc?.map((item, i) => {
                                return (
                                    <div key={i}>
                                        <ul>
                                            <li>
                                                <a
                                                    href={item?.file}
                                                    target='_blank'
                                                    rel='noopener noreferrer'
                                                >
                                                    {item?.documentName}
                                                </a>

                                            </li>
                                        </ul>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default TotalDOcumentModal;
