import React, { useContext } from 'react'
import { Link } from 'react-router-dom';
import { reactLocalStorage } from 'reactjs-localstorage';
import Logo2 from '../../src/images/footer-logo 1.png';
import { useLanguage } from '../Providers/LanguageContext';
import { UserContext } from '../Providers/UserWrapper';
import privace_En from '../images/blog_pdfs/Fisibility - Privacy Policy_En.pdf';
import privace_Es from '../images/blog_pdfs/privacy_police_Es.pdf'


function Footer() {
  const { language, currentLanguage } = useLanguage()
  const { profileData, isLogin } = useContext(UserContext);
  const userData = reactLocalStorage.getObject('userData')
  // console.log('userDataclip', userData)
  const handleOpenRoute = () => {
    const routeUrl = '/Privacy'; // Replace with your desired route URL
    window.open(routeUrl, '_blank');
  };


  return (
    <footer id='footer-section'>
      <div className='container'>
        <div className='footer-content'>
          <div className='footer-top'>
            <div className='footer-top-content'>
              {/* <Link to={Object.keys(userData).length ? isBookKeeper ? "/bookkeeper-manager" : '/manager' : '/'}> */}
              <Link to={isLogin ? '/manager' : '/'}>
                <div className='footer-logo'>
                  <img className="img-fluid" src={Logo2} />
                </div>
              </Link>
              <p style={{ position: 'relative', bottom: '28px', right: '11px' }}>Powered by CIP</p>
            </div>
            <div className='social-links'>
              {/* <a href='#'><i className="fa-solid fa-t"></i></a> */}
              <a href='https://facebook.com/Fisibility' target="_blank"><i className="fa-brands fa-facebook-f"></i></a>
              <a href='https://twitter.com/FisibilityAI' target="_blank"><i className="fa-brands fa-twitter"></i></a>
              <a href='https://youtube.com/@FisibilityLite' target="_blank"><i className="fa-brands fa-youtube"></i></a>
              <a href='https://instagram.com/FisibilityAI' target="_blank"><i className="fa-brands fa-instagram"></i></a>
              {/* <a href='#'><i className="fa-brands fa-linkedin-in"></i></a> */}
            </div>
          </div>
          <div className='footer-mid'>
            <div className='footer-link'>
              <ul>
                <li><Link to="/" >{language.headerMenu1}</Link></li>
                <li><Link to="/product-tour" >{language.headerMenu4}</Link></li>
                <li><Link to="/Blog" >{language.headerMenu2}</Link></li>
                <li><Link to="/Nosotros">{language.headerMenu3}</Link></li>
                <li><Link to="/deleteaccount">Delete Account</Link></li>
              </ul>
            </div>
            <div className='footer-mail'>
              <a href='mailto:support@fisibility.com'>support@fisibility.com</a>
            </div>
          </div>
          <div className='footer-login'>
            {
              !profileData && (
                <Link to="/login">{language.loginButton}</Link>
              )
            }
            {/* <h5 className='text-light'>hello world</h5> */}
          </div>
          <div className='footer-bottom'>
            <div>
              <a style={{ cursor: "pointer" }} onClick={handleOpenRoute} >{language.privacy_policy}</a>
              <p>Copyright© 2023 Fisibility</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer