import React from "react";
import iMg1 from "../../images/ilustraciones soluciones industriales_TRANSPORTE 2.png";
import iMg2 from "../../images/ilustraciones soluciones industriales_TRANSPORTE 1.png";
import iMg3 from "../../images/ilustraciones soluciones industriales_TRANSPORTE 3.png";
import iMg3en from "../../images/ilustraciones soluciones industriales_TRANSPORTE 3 EN.png";

import { useLanguage } from "../../Providers/LanguageContext";
import { useNavigate } from "react-router-dom";
import SignUpButton2 from "./SignUpButton2";
export default function Transportation() {
  const { language, currentLanguage } = useLanguage();
  const navigate = useNavigate();

  return (
    <>
      <section id="agriculture_sec">
        <div className="container">
          <div className="row mb-5">
            <div className="col-md-6 col-12 m-auto">
              <div className="agriculture_head">
                <h3 style={{ color: "#0e2537" }}>{language.transportation}</h3>
              </div>

              <div className="agriculture_headtext">
                <h4 style={{ color: "#333333" }}>{language.expense_tracking}</h4>
                <p>
                  {language.our_app_trsansport}
                </p>
              </div>
            </div>
            <div className="col-md-6 col-12 m-auto">
              <div className="agriculture_img">
                <img src={iMg2} className="img-fluid" alt="" />
              </div>
            </div>
          </div>

          <div className="col-md-12 col-12 m-auto">
            <div className="agriculture_headtext">
              <h4 style={{ color: "#0e2537", textAlign: "left" }}>
                {language.real_time_reporting}
              </h4>
              <p style={{ textAlign: "justify" }}>
                {language.app_provide_realTime}
              </p>
            </div>
          </div>
          <div className="row row_reverse mb-5">
            <div className="col-md-6 col-12 m-auto">
              <div className="agriculture_img" style={{ width: "75%" }}>
                <img src={iMg1} className="img-fluid" alt="" />
              </div>
            </div>
            <div className="col-md-6 col-12 m-auto">
              <div className="agriculture_headtext">
                <h4 style={{ color: "#333333", textAlign: "right" }}>
                  {language.paperless_management}
                </h4>
                <p style={{ textAlign: "justify" }}>
                  {language.allow_transport_manage}
                </p>
              </div>
            </div>
          </div>

          <div className="row row_reverse ">
            <div className="col-md-6 col-12 m-auto mt-5">
              <div className="agriculture_headtext">
                <h4 style={{ color: "#0d2232", textAlign: "left" }}>
                  {language.auditing_supplier}
                </h4>
                <p style={{ textAlign: "justify" }}>
                  {language.fisi_help_transport_comp}
                </p>
              </div>
            </div>
            <div className="col-md-6 col-12 m-auto">
              <div className="agriculture_img">
                {
                  currentLanguage === 'spanish'
                    ?
                    <img src={iMg3} className="img-fluid" alt="" />
                    :
                    <img src={iMg3en} className="img-fluid" alt="" />
                }
                <div
                  className="registrarAnddescargar"
                  style={{
                    transform: "translate(-50% , -50%)",
                    top: "75%",
                    left: "95%",
                    flexDirection: "column",
                  }}
                >
                  {/* <button
                    className="registrar_Btn"
                    style={{
                      background:
                        " linear-gradient(to right, #18465d 1%,#0f283b 100%,#2989d8 100%)",
                      border: "0",
                      marginBottom: "20px",
                    }}
                    onClick={() => navigate('/login')}
                  >
                    {language.homeBannerBtn1}
                  </button>
                  <button className="descargar_Btn">{language.homeBannerBtn2}</button> */}
                  <SignUpButton2 />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
