import React, { useMemo, useState } from 'react'
import ProjectPopUp from '../ProjectPopUp';
import FisProjectsIcon from "../../../images/FisProjectsIcon.png";
import HttpClient from '../../../utils/HttpClient';
import toast from 'react-hot-toast';
import { useLanguage } from '../../../Providers/LanguageContext';
import Select from 'react-select';
import { useUserData } from '../../../Providers/UserWrapper';


const ProjectSidebar = ({
    getProjecData,
    setModalc,
    preojectAllData,
    setTaskDetailData,
    selectAssignUserMemo,
    selectCustVendorMemo
}) => {
    const { language, currentLanguage } = useLanguage();
    const { isMainUserMemo, profileData } = useUserData();

    const initSelectData = { value: "", label: "" }
    const initData = {
        projectName: "",
        projectNotes: "",
        projectStatus: "",
        assignToUser: initSelectData,
        linkUserCustVendor: initSelectData,
        linkTask: "",
        startDate: "",
        endDate: ""

    }
    const [formData, setFormData] = useState(initData);
    const [linkedTask, setLinkedTask] = useState([]);
    const [searchedData, setSearchedData] = useState(initSelectData);


    // console.log("preojectAllData", preojectAllData)


    const searchSelectOptionMemo = useMemo(() => {
        return preojectAllData?.map((item) => ({
            value: item,
            label: item?.projectName
        }))
    }, [preojectAllData])


    //on change 
    const handleProjectChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    }

    // validate
    const validate = () => {
        if (!formData.projectName) {
            toast.error("Project Name is Required")
            return true
        }
        if (!formData.projectNotes) {
            toast.error("Project Notes is Required")
            return true
        }
        if (!formData.startDate) {
            toast.error("Start Date is Required")
            return true
        }
        if (!formData.projectNotes) {
            toast.error("End Date is Required")
            return true
        }
        return false
    }

    // submit create
    const handleCreateProject = async () => {
        if (validate()) {
            return
        }

        const data = {
            "projectName": formData.projectName,
            "projectNotes": formData.projectNotes,
            "projectStatus": formData.projectStatus ? formData.projectStatus : "open",
            "assignToUser": isMainUserMemo ? formData.assignToUser?.value : profileData?._id,
            "linkedUser": formData.linkUserCustVendor?.value,
            "linkedTask": formData.linkTask,
            "startDate": formData.startDate,
            "endDate": formData.endDate
        }
        // setFormData(initData);

        // return
        const res = await HttpClient.requestData("add-project-eventdetails", "POST", data);
        console.log("resData", res)
        if (res && res?.status) {
            toast.success("Project Added Successfully");
            getProjecData();
            setFormData(initData);
        } else {

        }
    }



    return (
        <div>
            <form action="" className="taskEvForm">
                <div>
                    <div className="taskMainInpDiv">
                        <div className="taskTabInps" style={{ width: "100%" }}>
                            <Select
                                className="taskUpperInpSearch"
                                placeholder={language.search}
                                isClearable={true}
                                isSearchable={true}
                                // name="linkedUser"
                                value={searchedData?.value === "" ? null : searchedData}
                                options={searchSelectOptionMemo}
                                onChange={e => {
                                    // console.log('euser', e);
                                    if (e) {
                                        // setSearchedData(e);
                                        setSearchedData(initSelectData);
                                        setModalc(true);
                                        setTaskDetailData(e.value)
                                    } else {
                                        setSearchedData(initSelectData);
                                    }
                                }}
                            />

                            {/* <input
                                type="text"
                                placeholder={language.search}
                                className="taskSearInp"
                            /> */}
                            <i class="fa-solid fa-magnifying-glass taskSearGlass" style={{ left: "282px" }}></i>
                        </div>
                    </div>

                    {/* project name */}
                    <div className="taskMainInpDiv">
                        <input
                            type="text"
                            placeholder={language.Project_Name}
                            className="taskUpperInp"
                            name='projectName'
                            value={formData.projectName}
                            onChange={(e) => handleProjectChange(e)}
                        />
                    </div>

                    {/* project notes */}
                    <div className="taskMainInpDiv">
                        <input
                            type="text"
                            placeholder={language.Project_Notes}
                            className="taskUpperInp"
                            name='projectNotes'
                            value={formData.projectNotes}
                            onChange={(e) => handleProjectChange(e)}
                        />
                    </div>

                    {/*  status */}
                    <div className="taskMainInpDiv">
                        <select
                            id=""
                            className="taskUpperInp"
                            name="projectStatus"
                            value={formData.projectStatus}
                            onChange={(e) => handleProjectChange(e)}
                        >
                            <option value="" disabled selected>
                                {language.Event_Status_optional}
                            </option>
                            <option value="open">{language.open}</option>
                            <option value="doing">{language.doing}</option>
                            <option value="done">{language.done}</option>
                            <option value="ideas">{language.ideas}</option>
                            <option value="onhold">{language.on_hold}</option>
                        </select>
                    </div>
                    {/* <div className="taskMainInpDiv">
                        <select
                            id=""
                            className="taskUpperInp"
                            name='projectStatus'
                            value={formData.projectStatus}
                            onChange={(e) => handleProjectChange(e)}
                        >
                            <option value="" disabled selected>
                                Project Status (select) optional
                            </option>
                            <option value="">Option 1</option>
                            <option value="">Option 2</option>
                        </select>
                    </div> */}

                    {
                        isMainUserMemo &&
                        <div className="taskMainInpDiv">
                            <Select
                                className="taskUpperInpSearch"
                                placeholder="Assign to User(Select)"
                                isClearable={true}
                                isSearchable={true}
                                name="assignedUser"
                                value={formData?.assignToUser?.value === "" ? null : formData?.assignToUser}
                                options={selectAssignUserMemo}
                                onChange={(e) => {
                                    console.log("euser", e)
                                    if (e) {
                                        setFormData(prev => ({ ...prev, assignToUser: e }));
                                    } else {
                                        setFormData(prev => ({ ...prev, assignToUser: initSelectData }));
                                    }
                                }}
                            />
                        </div>
                    }

                    {/* Link Users, Customers. Vendors */}
                    <div className="taskMainInpDiv">
                        <Select
                            className="taskUpperInpSearch"
                            placeholder={language.Link_Users_Customers_Vendors}
                            isClearable={true}
                            isSearchable={true}
                            name="linkUserCustVendor"
                            value={formData?.linkUserCustVendor?.value === "" ? null : formData?.linkUserCustVendor}
                            options={selectCustVendorMemo}
                            onChange={e => {
                                if (e) {
                                    setFormData(prev => ({ ...prev, linkUserCustVendor: e }));
                                } else {
                                    setFormData(prev => ({ ...prev, linkUserCustVendor: initSelectData }));
                                }
                            }}
                        />
                    </div>
                    {/* <div className="taskMainInpDiv">
                        <input
                            type="text"
                            className="taskUpperInp"
                            placeholder={language.Link_Users_Customers_Vendors}
                            name='linkUserCustVendor'
                            value={formData.linkUserCustVendor}
                            onChange={(e) => handleProjectChange(e)}
                        />
                    </div> */}

                    {/* Link Tasks */}
                    <div className="taskMainInpDiv">
                        <input
                            type="text"
                            className="taskUpperInp"
                            placeholder={language.Link_Tasks}
                            name='linkTask'
                            value={formData.linkTask}
                            onChange={(e) => handleProjectChange(e)}
                        />
                    </div>


                    <div className="taskMainInpDiv">

                        {/* start Date */}
                        <div className="taskTabInps">
                            <input
                                type="date"
                                className="taskUpperInp"
                                placeholder={language.first}
                                name='startDate'
                                value={formData.startDate}
                                onChange={(e) => handleProjectChange(e)}
                            />
                        </div>

                        {/* end Date */}
                        <div className="taskTabInps">
                            <input
                                type="date"
                                className="taskUpperInp"
                                placeholder={language.last}
                                name='endDate'
                                value={formData.endDate}
                                onChange={(e) => handleProjectChange(e)}
                            />
                        </div>
                    </div>
                </div>
                {/* <div className="addNewTaskBtnDiv">
                    <button type='button'>+ {language.add_list}</button>
                </div> */}
                <div className="postTaskBtnDiv">
                    <button
                        onClick={() => handleCreateProject()}
                        type='button'
                    >
                        {language.CREATE}
                    </button>
                </div>
            </form>

            <div className="orDrFigDiv">
                <p className="orDrText">{language.Or_drop_to_calendar}</p>
                <figure className="fisiDrIconFig">
                    <img src={FisProjectsIcon} alt="..." />
                </figure>
            </div>


            {/* quick view */}
            {
                preojectAllData?.length !== 0 &&
                <div className="quickMainViewDiv">
                    <p className="ouickViewHead">{language.Quick_View}</p>
                    <div className="quickViewUlDiv">
                        <ul style={{ maxHeight: "300px" }} className="quickViewUl">
                            {preojectAllData?.map((item, i) =>
                                <li
                                    key={i}
                                    className="quickViewLi"
                                    onClick={() => {
                                        setModalc(true);
                                        setTaskDetailData(item);
                                    }}
                                >
                                    <div>
                                        <p className="quickAnsText">{item?.projectName}</p>
                                        <div className="quickDoneTextDiv">
                                            <p className="quickDoneText quickSkyDoneDot">
                                                {item?.projectStatus}
                                            </p>
                                        </div>
                                    </div>
                                    <figure className="quickFirstIconFig">
                                        <img src={FisProjectsIcon} alt="..." />
                                    </figure>
                                </li>

                            )
                            }
                        </ul>
                    </div>
                </div>
            }

        </div>
    )
}

export default ProjectSidebar
