import React, { useEffect, useState } from 'react'
import { useUserData } from '../../Providers/UserWrapper';
import { useLanguage } from '../../Providers/LanguageContext';
import HttpClient from '../../utils/HttpClient';

const View13WeekCFS = ({ setBuildRepData }) => {


    const initTotals = {
        week1price: 0,
        week2price: 0,
        week3price: 0,
        week4price: 0,
        week5price: 0,
        week6price: 0,
        week7price: 0,
        week8price: 0,
        week9price: 0,
        week10price: 0,
        week11price: 0,
        week12price: 0,
        week13price: 0,
    };

    // >>>>Cash On Hand Data<<<<
    const initCashOnHand = [
        {
            Beginning: "",
            week1price: "",
            week2price: "",
            week3price: "",
            week4price: "",
            week5price: "",
            week6price: "",
            week7price: "",
            week8price: "",
            week9price: "",
            week10price: "",
            week11price: "",
            week12price: "",
            week13price: "",
        }
    ]

    //   >>>>Cash Receipts Default Value<<<< 
    const defaultCashReceipts = [
        {
            title: "Cash Sales",
            week1price: "",
            week2price: "",
            week3price: "",
            week4price: "",
            week5price: "",
            week6price: "",
            week7price: "",
            week8price: "",
            week9price: "",
            week10price: "",
            week11price: "",
            week12price: "",
            week13price: "",
        },
        {
            title: "Customer Account Collections",
            week1price: "",
            week2price: "",
            week3price: "",
            week4price: "",
            week5price: "",
            week6price: "",
            week7price: "",
            week8price: "",
            week9price: "",
            week10price: "",
            week11price: "",
            week12price: "",
            week13price: "",
        },
        {
            title: "Loan / Other Cash Injection",
            week1price: "",
            week2price: "",
            week3price: "",
            week4price: "",
            week5price: "",
            week6price: "",
            week7price: "",
            week8price: "",
            week9price: "",
            week10price: "",
            week11price: "",
            week12price: "",
            week13price: "",
        },
        {
            title: "Interest Income",
            week1price: "",
            week2price: "",
            week3price: "",
            week4price: "",
            week5price: "",
            week6price: "",
            week7price: "",
            week8price: "",
            week9price: "",
            week10price: "",
            week11price: "",
            week12price: "",
            week13price: "",
        },
        {
            title: "Tax Refund",
            week1price: "",
            week2price: "",
            week3price: "",
            week4price: "",
            week5price: "",
            week6price: "",
            week7price: "",
            week8price: "",
            week9price: "",
            week10price: "",
            week11price: "",
            week12price: "",
            week13price: "",
        },
        {
            title: "Other Cash Receipts",
            week1price: "",
            week2price: "",
            week3price: "",
            week4price: "",
            week5price: "",
            week6price: "",
            week7price: "",
            week8price: "",
            week9price: "",
            week10price: "",
            week11price: "",
            week12price: "",
            week13price: "",
        },
        {
            title: "Other",
            week1price: "",
            week2price: "",
            week3price: "",
            week4price: "",
            week5price: "",
            week6price: "",
            week7price: "",
            week8price: "",
            week9price: "",
            week10price: "",
            week11price: "",
            week12price: "",
            week13price: "",
        }
    ];

    //   >>>>Cash Paid Out Default Value<<<< 
    const defaultCashPaidOut = [
        {
            title: "Purchases (merchandise)",
            week1price: "",
            week2price: "",
            week3price: "",
            week4price: "",
            week5price: "",
            week6price: "",
            week7price: "",
            week8price: "",
            week9price: "",
            week10price: "",
            week11price: "",
            week12price: "",
            week13price: "",
        },
        {
            title: "Purchases (specify)",
            week1price: "",
            week2price: "",
            week3price: "",
            week4price: "",
            week5price: "",
            week6price: "",
            week7price: "",
            week8price: "",
            week9price: "",
            week10price: "",
            week11price: "",
            week12price: "",
            week13price: "",
        },
        {
            title: "Gross Wages (exact withdrawal)",
            week1price: "",
            week2price: "",
            week3price: "",
            week4price: "",
            week5price: "",
            week6price: "",
            week7price: "",
            week8price: "",
            week9price: "",
            week10price: "",
            week11price: "",
            week12price: "",
            week13price: "",
        },
        {
            title: "Payroll Expenses (taxes, etc)",
            week1price: "",
            week2price: "",
            week3price: "",
            week4price: "",
            week5price: "",
            week6price: "",
            week7price: "",
            week8price: "",
            week9price: "",
            week10price: "",
            week11price: "",
            week12price: "",
            week13price: "",
        },
        {
            title: "Outside Services",
            week1price: "",
            week2price: "",
            week3price: "",
            week4price: "",
            week5price: "",
            week6price: "",
            week7price: "",
            week8price: "",
            week9price: "",
            week10price: "",
            week11price: "",
            week12price: "",
            week13price: "",
        },
        {
            title: "Supplies (office & operating)",
            week1price: "",
            week2price: "",
            week3price: "",
            week4price: "",
            week5price: "",
            week6price: "",
            week7price: "",
            week8price: "",
            week9price: "",
            week10price: "",
            week11price: "",
            week12price: "",
            week13price: "",
        },
        {
            title: "Repairs & Maintenance",
            week1price: "",
            week2price: "",
            week3price: "",
            week4price: "",
            week5price: "",
            week6price: "",
            week7price: "",
            week8price: "",
            week9price: "",
            week10price: "",
            week11price: "",
            week12price: "",
            week13price: "",
        },
        {
            title: "Advertising",
            week1price: "",
            week2price: "",
            week3price: "",
            week4price: "",
            week5price: "",
            week6price: "",
            week7price: "",
            week8price: "",
            week9price: "",
            week10price: "",
            week11price: "",
            week12price: "",
            week13price: "",
        },
        {
            title: "Car, Delivery & Travel",
            week1price: "",
            week2price: "",
            week3price: "",
            week4price: "",
            week5price: "",
            week6price: "",
            week7price: "",
            week8price: "",
            week9price: "",
            week10price: "",
            week11price: "",
            week12price: "",
            week13price: "",
        },
        {
            title: "Accounting & Lega",
            week1price: "",
            week2price: "",
            week3price: "",
            week4price: "",
            week5price: "",
            week6price: "",
            week7price: "",
            week8price: "",
            week9price: "",
            week10price: "",
            week11price: "",
            week12price: "",
            week13price: "",
        },
        {
            title: "Rent",
            week1price: "",
            week2price: "",
            week3price: "",
            week4price: "",
            week5price: "",
            week6price: "",
            week7price: "",
            week8price: "",
            week9price: "",
            week10price: "",
            week11price: "",
            week12price: "",
            week13price: "",
        },
        {
            title: "Telephone",
            week1price: "",
            week2price: "",
            week3price: "",
            week4price: "",
            week5price: "",
            week6price: "",
            week7price: "",
            week8price: "",
            week9price: "",
            week10price: "",
            week11price: "",
            week12price: "",
            week13price: "",
        },
        {
            title: "Utilities",
            week1price: "",
            week2price: "",
            week3price: "",
            week4price: "",
            week5price: "",
            week6price: "",
            week7price: "",
            week8price: "",
            week9price: "",
            week10price: "",
            week11price: "",
            week12price: "",
            week13price: "",
        },
        {
            title: "Insurance",
            week1price: "",
            week2price: "",
            week3price: "",
            week4price: "",
            week5price: "",
            week6price: "",
            week7price: "",
            week8price: "",
            week9price: "",
            week10price: "",
            week11price: "",
            week12price: "",
            week13price: "",
        },
        {
            title: "Taxes (real estate, etc.)",
            week1price: "",
            week2price: "",
            week3price: "",
            week4price: "",
            week5price: "",
            week6price: "",
            week7price: "",
            week8price: "",
            week9price: "",
            week10price: "",
            week11price: "",
            week12price: "",
            week13price: "",
        },
        {
            title: "Interest",
            week1price: "",
            week2price: "",
            week3price: "",
            week4price: "",
            week5price: "",
            week6price: "",
            week7price: "",
            week8price: "",
            week9price: "",
            week10price: "",
            week11price: "",
            week12price: "",
            week13price: "",
        },
        {
            title: "Other Expenses (specify)",
            week1price: "",
            week2price: "",
            week3price: "",
            week4price: "",
            week5price: "",
            week6price: "",
            week7price: "",
            week8price: "",
            week9price: "",
            week10price: "",
            week11price: "",
            week12price: "",
            week13price: "",
        },
        {
            title: "Other (specify)",
            week1price: "",
            week2price: "",
            week3price: "",
            week4price: "",
            week5price: "",
            week6price: "",
            week7price: "",
            week8price: "",
            week9price: "",
            week10price: "",
            week11price: "",
            week12price: "",
            week13price: "",
        },
        {
            title: "Miscellaneous",
            week1price: "",
            week2price: "",
            week3price: "",
            week4price: "",
            week5price: "",
            week6price: "",
            week7price: "",
            week8price: "",
            week9price: "",
            week10price: "",
            week11price: "",
            week12price: "",
            week13price: "",
        },
        {
            title: "Loan Principal",
            week1price: "",
            week2price: "",
            week3price: "",
            week4price: "",
            week5price: "",
            week6price: "",
            week7price: "",
            week8price: "",
            week9price: "",
            week10price: "",
            week11price: "",
            week12price: "",
            week13price: "",
        },
        {
            title: "Capital Purchase (specify)",
            week1price: "",
            week2price: "",
            week3price: "",
            week4price: "",
            week5price: "",
            week6price: "",
            week7price: "",
            week8price: "",
            week9price: "",
            week10price: "",
            week11price: "",
            week12price: "",
            week13price: "",
        },
        {
            title: "Other Startup Costs",
            week1price: "",
            week2price: "",
            week3price: "",
            week4price: "",
            week5price: "",
            week6price: "",
            week7price: "",
            week8price: "",
            week9price: "",
            week10price: "",
            week11price: "",
            week12price: "",
            week13price: "",
        },
        {
            title: "Reserve and/or Escrow",
            week1price: "",
            week2price: "",
            week3price: "",
            week4price: "",
            week5price: "",
            week6price: "",
            week7price: "",
            week8price: "",
            week9price: "",
            week10price: "",
            week11price: "",
            week12price: "",
            week13price: "",
        },
        {
            title: "Owner’s Withdrawal",
            week1price: "",
            week2price: "",
            week3price: "",
            week4price: "",
            week5price: "",
            week6price: "",
            week7price: "",
            week8price: "",
            week9price: "",
            week10price: "",
            week11price: "",
            week12price: "",
            week13price: "",
        }
    ]

    //   >>>>Operating Data Default Value<<<< 
    const defaultOperatingData = [
        {
            title: "Sales Volume",
            week1price: "",
            week2price: "",
            week3price: "",
            week4price: "",
            week5price: "",
            week6price: "",
            week7price: "",
            week8price: "",
            week9price: "",
            week10price: "",
            week11price: "",
            week12price: "",
            week13price: "",
        },
        {
            title: "Accounts Receivable",
            week1price: "",
            week2price: "",
            week3price: "",
            week4price: "",
            week5price: "",
            week6price: "",
            week7price: "",
            week8price: "",
            week9price: "",
            week10price: "",
            week11price: "",
            week12price: "",
            week13price: "",
        },
        {
            title: "Bad Debt (end of month)",
            week1price: "",
            week2price: "",
            week3price: "",
            week4price: "",
            week5price: "",
            week6price: "",
            week7price: "",
            week8price: "",
            week9price: "",
            week10price: "",
            week11price: "",
            week12price: "",
            week13price: "",
        },
        {
            title: "Inventory on Hand",
            week1price: "",
            week2price: "",
            week3price: "",
            week4price: "",
            week5price: "",
            week6price: "",
            week7price: "",
            week8price: "",
            week9price: "",
            week10price: "",
            week11price: "",
            week12price: "",
            week13price: "",
        },
        {
            title: "Accounts Payable",
            week1price: "",
            week2price: "",
            week3price: "",
            week4price: "",
            week5price: "",
            week6price: "",
            week7price: "",
            week8price: "",
            week9price: "",
            week10price: "",
            week11price: "",
            week12price: "",
            week13price: "",
        },
        {
            title: "Depreciation",
            week1price: "",
            week2price: "",
            week3price: "",
            week4price: "",
            week5price: "",
            week6price: "",
            week7price: "",
            week8price: "",
            week9price: "",
            week10price: "",
            week11price: "",
            week12price: "",
            week13price: "",
        }
    ]


    const { language, currentLanguage } = useLanguage();
    const { profileData, setIsLoading } = useUserData();
    const [isSave, setIsSave] = useState(true);
    const [loading, setLoading] = useState(false);
    const [saveLoading, setSaveLoading] = useState(false);

    const [cashOnHand, setCashOnHand] = useState(initCashOnHand)
    const [cashReceipts, setCashReceipts] = useState(defaultCashReceipts);
    const [cashPaidOut, setCashPaidOut] = useState(defaultCashPaidOut);
    const [operatingData, setOperatingData] = useState(defaultOperatingData);

    const [totals, setTotals] = useState({
        week1price: 0,
        week2price: 0,
        week3price: 0,
        week4price: 0,
        week5price: 0,
        week6price: 0,
        week7price: 0,
        week8price: 0,
        week9price: 0,
        week10price: 0,
        week11price: 0,
        week12price: 0,
    });

    const [summedValues, setSummedValues] = useState(initTotals);
    const [totalCashPaidOut, setTotalCashPaidOut] = useState(new Array(13).fill(0));
    const [subtractedValues, setSubtractedValues] = useState(new Array(13).fill(0));


    // >>>>GET 13 Weeks Cash Flow Statemwnt Data<<<<
    const get13WeeksCashFlowStatementData = async () => {
        try {
            const res = await HttpClient.requestData("reportbuilder-view-13weekstatement", "GET");
            if (res && res.status) {
                const data = res.data[0] || {};

                setCashOnHand(data.cashonhands.length > 0 ? data.cashonhands[0] : initCashOnHand);
                setCashReceipts(data.cashRecipts.length > 0 ? data.cashRecipts : defaultCashReceipts)
                setCashPaidOut(data.CashPaidOut.length > 0 ? data.CashPaidOut : defaultCashPaidOut)
                setOperatingData(data.operatingData.length > 0 ? data.operatingData : defaultOperatingData)
                // console.log("APIData", data, data.cashonhands);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    // >>>>Setting TOTAL CASH RECEIPTS Value<<<<
    useEffect(() => {
        const calculateTotals = () => {
            const totals = {
                week1price: 0,
                week2price: 0,
                week3price: 0,
                week4price: 0,
                week5price: 0,
                week6price: 0,
                week7price: 0,
                week8price: 0,
                week9price: 0,
                week10price: 0,
                week11price: 0,
                week12price: 0,
                week13price: 0,
            };

            // Calculate totals for cashReceipts
            cashReceipts.forEach(item => {
                Object.keys(totals).forEach(week => {
                    totals[week] += parseFloat(item[week] || 0);
                });
            });

            return totals;
        };

        setTotals(calculateTotals());
    }, [cashReceipts]);

    // >>>>Setting Total Cash Available (before cash out)<<<<
    useEffect(() => {
        const calculateSums = () => {
            let summed = {};
            for (let week = 1; week <= 13; week++) {
                const cashValue = parseFloat(cashOnHand[`week${week}price`] || 0);
                const totalValue = totals[`week${week}price`];
                summed[`week${week}sum`] = cashValue + totalValue;
            }
            setSummedValues(summed);
        };

        calculateSums();
    }, [cashOnHand, totals]);

    // >>>>Setting TOTAL CASH PAID OUT<<<<
    useEffect(() => {
        const calculateTotalCashPaidOut = () => {
            const weeks = 13; // Number of weeks
            const totals = new Array(weeks).fill(0);

            cashPaidOut.forEach(item => {
                for (let i = 1; i <= weeks; i++) {
                    const weekPrice = parseFloat(item[`week${i}price`]) || 0;
                    totals[i - 1] += weekPrice;
                }
            });

            return totals;
        };
        setTotalCashPaidOut(calculateTotalCashPaidOut());
    }, [cashPaidOut]);

    // >>>>Setting Cash Position (end of the month)<<<<
    useEffect(() => {
        const calculateSubtraction = () => {
            let subtracted = {};
            for (let week = 1; week <= 13; week++) {
                const summedValue = summedValues[`week${week}sum`] || 0;
                const subtractedValue = summedValue - totalCashPaidOut[week - 1];
                subtracted[`week${week}subtract`] = subtractedValue;
            }
            setSubtractedValues(subtracted);
        };

        calculateSubtraction();
    }, [summedValues, totalCashPaidOut]);

    useEffect(() => {
        get13WeeksCashFlowStatementData()
    }, [])

    return (
        <div className="container">
            <div className="buildIncmStTableDiv">

                <table className="buildIncmStTable">
                    <tbody>
                        {/* Cash On Hand */}
                        <tr>
                            <th className="buildIncmStTh12 buildIncmStTh12First">
                            </th>
                            <th className="buildIncmStTh12 pr-2">
                                <span>{language.beginning}</span>
                            </th>
                            <th className="buildIncmStTh12 pr-2">
                                <span>Week 1</span>
                            </th>
                            <th className="buildIncmStTh12 pr-2">
                                <span>Week 2</span>
                            </th>
                            <th className="buildIncmStTh12 pr-2">
                                <span>Week 3</span>
                            </th>
                            <th className="buildIncmStTh12 pr-2">
                                <span>Week 4</span>
                            </th>
                            <th className="buildIncmStTh12 pr-2">
                                <span>Week 5</span>
                            </th>
                            <th className="buildIncmStTh12 pr-2">
                                <span>Week 6</span>
                            </th>
                            <th className="buildIncmStTh12 pr-2">
                                <span>Week 7</span>
                            </th>
                            <th className="buildIncmStTh12 pr-2">
                                <span>Week 8</span>
                            </th>
                            <th className="buildIncmStTh12 pr-2">
                                <span>Week 9</span>
                            </th>
                            <th className="buildIncmStTh12 pr-2">
                                <span>Week 10</span>
                            </th>
                            <th className="buildIncmStTh12 pr-2">
                                <span>Week 11</span>
                            </th>
                            <th className="buildIncmStTh12 pr-2">
                                <span>Week 12</span>
                            </th>
                            <th className="buildIncmStTh12">
                                <span>Week 13</span>
                            </th>
                        </tr>

                        <tr>
                            <td className="buildIncmStTd" style={{ fontWeight: "bold" }}>

                            {language.coh}
                            </td>
                            <td className="buildIncmStTd pr-2">
                                <input
                                    type="number"
                                    name="Beginning"
                                    value={cashOnHand?.Beginning}
                                    placeholder="$0.00"
                                />
                            </td>
                            <td className="buildIncmStTd pr-2">
                                <input
                                    type="number"
                                    name="week1price"
                                    value={cashOnHand?.week1price}
                                    placeholder="$0.00"
                                />
                            </td>
                            <td className="buildIncmStTd pr-2">
                                <input
                                    type="number"
                                    name="week2price"
                                    value={cashOnHand?.week2price}
                                    placeholder="$0.00"
                                />
                            </td>
                            <td className="buildIncmStTd pr-2">
                                <input
                                    type="number"
                                    name="week3price"
                                    value={cashOnHand?.week3price}
                                    placeholder="$0.00"
                                />
                            </td>
                            <td className="buildIncmStTd pr-2">
                                <input
                                    type="number"
                                    name="week4price"
                                    value={cashOnHand?.week4price}
                                    placeholder="$0.00"
                                />
                            </td>
                            <td className="buildIncmStTd pr-2">
                                <input
                                    type="number"
                                    name="week5price"
                                    value={cashOnHand?.week5price}
                                    placeholder="$0.00"
                                />
                            </td>
                            <td className="buildIncmStTd pr-2">
                                <input
                                    type="number"
                                    name="week6price"
                                    value={cashOnHand?.week6price}
                                    placeholder="$0.00"
                                />
                            </td>
                            <td className="buildIncmStTd pr-2">
                                <input
                                    type="number"
                                    name="week7price"
                                    value={cashOnHand?.week7price}
                                    placeholder="$0.00"
                                />
                            </td>
                            <td className="buildIncmStTd pr-2">
                                <input
                                    type="number"
                                    name="week8price"
                                    value={cashOnHand?.week8price}
                                    placeholder="$0.00"
                                />
                            </td>
                            <td className="buildIncmStTd pr-2">
                                <input
                                    type="number"
                                    name="week9price"
                                    value={cashOnHand?.week9price}
                                    placeholder="$0.00"
                                />
                            </td>
                            <td className="buildIncmStTd pr-2">
                                <input
                                    type="number"
                                    name="week10price"
                                    value={cashOnHand?.week10price}
                                    placeholder="$0.00"
                                />
                            </td>
                            <td className="buildIncmStTd pr-2">
                                <input
                                    type="number"
                                    name="week11price"
                                    value={cashOnHand?.week11price}
                                    placeholder="$0.00"
                                />
                            </td>
                            <td className="buildIncmStTd pr-2">
                                <input
                                    type="number"
                                    name="week12price"
                                    value={cashOnHand?.week12price}
                                    placeholder="$0.00"
                                />
                            </td>
                            <td className="buildIncmStTd ">
                                <input
                                    type="number"
                                    name="week13price"
                                    value={cashOnHand?.week13price}
                                    placeholder="$0.00"
                                />
                            </td>
                        </tr>

                        {/* CASH RECEIPTS */}
                        <tr>
                            <td className="buildIncmStTh12 buildIncmStTh12First">
                            <span>{language.cash_receipt}</span>
                            </td>
                        </tr>

                        {
                            cashReceipts?.map((item, i, arr) => {
                                return (

                                    <tr>
                                        <td className="buildIncmStTd">
                                            <input
                                                type="text"
                                                className="userBuildInp"
                                                name="title"
                                                value={item.title}
                                            />
                                        </td>
                                        <td className="buildIncmStTd pr-2">

                                        </td>
                                        <td className="buildIncmStTd pr-2">
                                            <input
                                                type="number"
                                                name="week1price"
                                                value={item.week1price}
                                                placeholder="$0.00"
                                            />
                                        </td>
                                        <td className="buildIncmStTd pr-2">
                                            <input
                                                type="number"
                                                name="week2price"
                                                value={item.week2price}
                                                placeholder="$0.00"
                                            />
                                        </td>
                                        <td className="buildIncmStTd pr-2">
                                            <input
                                                type="number"
                                                name="week3price"
                                                value={item.week3price}
                                                placeholder="$0.00"
                                            />
                                        </td>
                                        <td className="buildIncmStTd pr-2">
                                            <input
                                                type="number"
                                                name="week4price"
                                                value={item.week4price}
                                                placeholder="$0.00"
                                            />
                                        </td>
                                        <td className="buildIncmStTd pr-2">
                                            <input
                                                type="number"
                                                name="week5price"
                                                value={item.week5price}
                                                placeholder="$0.00"
                                            />
                                        </td>
                                        <td className="buildIncmStTd pr-2">
                                            <input
                                                type="number"
                                                name="week6price"
                                                value={item.week6price}
                                                placeholder="$0.00"
                                            />
                                        </td>
                                        <td className="buildIncmStTd pr-2">
                                            <input
                                                type="number"
                                                name="week7price"
                                                value={item.week7price}
                                                placeholder="$0.00"
                                            />
                                        </td>
                                        <td className="buildIncmStTd pr-2">
                                            <input
                                                type="number"
                                                name="week8price"
                                                value={item.week8price}
                                                placeholder="$0.00"
                                            />
                                        </td>
                                        <td className="buildIncmStTd pr-2">
                                            <input
                                                type="number"
                                                name="week9price"
                                                value={item.week9price}
                                                placeholder="$0.00"
                                            />
                                        </td>
                                        <td className="buildIncmStTd pr-2">
                                            <input
                                                type="number"
                                                name="week10price"
                                                value={item.week10price}
                                                placeholder="$0.00"
                                            />
                                        </td>
                                        <td className="buildIncmStTd pr-2">
                                            <input
                                                type="number"
                                                name="week11price"
                                                value={item.week11price}
                                                placeholder="$0.00"
                                            />
                                        </td>
                                        <td className="buildIncmStTd pr-2">
                                            <input
                                                type="number"
                                                name="week12price"
                                                value={item.week12price}
                                                placeholder="$0.00"
                                            />
                                        </td>
                                        <td className="buildIncmStTd">
                                            <input
                                                type="number"
                                                name="week13price"
                                                value={item.week13price}
                                                placeholder="$0.00"
                                            />
                                        </td>
                                    </tr>
                                )
                            })
                        }

                        <tr>
                            <td colSpan={15}>
                                <div className="buildTotDivider" />
                            </td>
                        </tr>

                        {/*TOTAL CASH RECEIPTS  */}
                        <tr>
                            <td className="buildIncmStTd">
                                <span className="buildTotSpan">{language.total_cash_receipt}</span>
                            </td>
                            <td className="buildIncmStTd pr-2">

                            </td>
                            {Array.from({ length: 13 }).map((_, index) => (
                                <td key={index} className="buildIncmStTd pr-2">
                                    <input
                                        type="number"
                                        placeholder="$0.00"
                                        value={totals[`week${index + 1}price`]}
                                        readOnly
                                    />
                                </td>
                            ))}

                        </tr>

                    </tbody>
                </table>

                <table className="buildIncmStTable mt-3">
                    <tbody>
                        <tr>
                            <th className="buildIncmStTh12 buildIncmStTh12First">
                            </th>
                            <th className="buildIncmStTh12 pr-2">
                            <span>{language.beginning}</span>
                            </th>
                            <th className="buildIncmStTh12 pr-2">
                                <span>Week 1</span>
                            </th>
                            <th className="buildIncmStTh12 pr-2">
                                <span>Week 2</span>
                            </th>
                            <th className="buildIncmStTh12 pr-2">
                                <span>Week 3</span>
                            </th>
                            <th className="buildIncmStTh12 pr-2">
                                <span>Week 4</span>
                            </th>
                            <th className="buildIncmStTh12 pr-2">
                                <span>Week 5</span>
                            </th>
                            <th className="buildIncmStTh12 pr-2">
                                <span>Week 6</span>
                            </th>
                            <th className="buildIncmStTh12 pr-2">
                                <span>Week 7</span>
                            </th>
                            <th className="buildIncmStTh12 pr-2">
                                <span>Week 8</span>
                            </th>
                            <th className="buildIncmStTh12 pr-2">
                                <span>Week 9</span>
                            </th>
                            <th className="buildIncmStTh12 pr-2">
                                <span>Week 10</span>
                            </th>
                            <th className="buildIncmStTh12 pr-2">
                                <span>Week 11</span>
                            </th>
                            <th className="buildIncmStTh12 pr-2">
                                <span>Week 12</span>
                            </th>
                            <th className="buildIncmStTh12">
                                <span>Week 13</span>
                            </th>
                        </tr>

                        <tr>
                            <td className="buildIncmStTd" style={{ fontWeight: "bold" }}>
                            {language.cash_available}
                            </td>

                            <td className="buildIncmStTd pr-2">
                                <input
                                    disabled
                                    type="number"
                                    name="pastDue"
                                    placeholder="$0.00"
                                    defaultValue=""
                                />
                            </td>
                            {Array.from({ length: 13 }).map((_, index) => (
                                <td key={index} className="buildIncmStTd pr-2">
                                    <input
                                        type="number"
                                        placeholder="$0.00"
                                        value={summedValues[`week${index + 1}sum`] || 0}
                                        readOnly
                                    />
                                </td>
                            ))}
                        </tr>

                        <tr>
                            <td className="buildIncmStTh12 buildIncmStTh12First">
                            <span>{language.cash_paid_out}</span>
                            </td>
                        </tr>

                        {
                            cashPaidOut?.map((item, i, arr) => {
                                return (
                                    <tr>
                                        <td className="buildIncmStTd">
                                            <input
                                                type="text"
                                                className="userBuildInp"
                                                name="title"
                                                value={item.title}
                                            />
                                        </td>
                                        <td className="buildIncmStTd pr-2">

                                        </td>
                                        <td className="buildIncmStTd pr-2">
                                            <input
                                                type="number"
                                                name="week1price"
                                                value={item.week1price}
                                                placeholder="$0.00"
                                            />
                                        </td>
                                        <td className="buildIncmStTd pr-2">
                                            <input
                                                type="number"
                                                name="week2price"
                                                value={item.week2price}
                                                placeholder="$0.00"
                                            />
                                        </td>
                                        <td className="buildIncmStTd pr-2">
                                            <input
                                                type="number"
                                                name="week3price"
                                                value={item.week3price}
                                                placeholder="$0.00"
                                            />
                                        </td>
                                        <td className="buildIncmStTd pr-2">
                                            <input
                                                type="number"
                                                name="week4price"
                                                value={item.week4price}
                                                placeholder="$0.00"
                                            />
                                        </td>
                                        <td className="buildIncmStTd pr-2">
                                            <input
                                                type="number"
                                                name="week5price"
                                                value={item.week5price}
                                                placeholder="$0.00"
                                            />
                                        </td>
                                        <td className="buildIncmStTd pr-2">
                                            <input
                                                type="number"
                                                name="week6price"
                                                value={item.week6price}
                                                placeholder="$0.00"
                                            />
                                        </td>
                                        <td className="buildIncmStTd pr-2">
                                            <input
                                                type="number"
                                                name="week7price"
                                                value={item.week7price}
                                                placeholder="$0.00"
                                            />
                                        </td>
                                        <td className="buildIncmStTd pr-2">
                                            <input
                                                type="number"
                                                name="week8price"
                                                value={item.week8price}
                                                placeholder="$0.00"
                                            />
                                        </td>
                                        <td className="buildIncmStTd pr-2">
                                            <input
                                                type="number"
                                                name="week9price"
                                                value={item.week9price}
                                                placeholder="$0.00"
                                            />
                                        </td>
                                        <td className="buildIncmStTd pr-2">
                                            <input
                                                type="number"
                                                name="week10price"
                                                value={item.week10price}
                                                placeholder="$0.00"
                                            />
                                        </td>
                                        <td className="buildIncmStTd pr-2">
                                            <input
                                                type="number"
                                                name="week11price"
                                                value={item.week11price}
                                                placeholder="$0.00"
                                            />
                                        </td>
                                        <td className="buildIncmStTd pr-2">
                                            <input
                                                type="number"
                                                name="week12price"
                                                value={item.week12price}
                                                placeholder="$0.00"
                                            />
                                        </td>
                                        <td className="buildIncmStTd">
                                            <input
                                                type="number"
                                                name="week13price"
                                                value={item.week13price}
                                                placeholder="$0.00"
                                            />
                                        </td>
                                    </tr>
                                )
                            })

                        }

                        <tr>
                            <td />
                            <td colSpan={15}>
                                <div className="buildTotDivider" />
                            </td>
                        </tr>
                        <tr>
                            <td className="buildIncmStTd">
                                <span className="buildTotSpan">{language.total_cash_paid_out}</span>
                            </td>
                            <td className="buildIncmStTd pr-2">

                            </td>
                            {totalCashPaidOut.map((total, index) => (
                                <td key={index} className="buildIncmStTd pr-2">
                                    <input type="number" value={total} readOnly />
                                </td>
                            ))}

                        </tr>
                    </tbody>
                </table>

                <table className="buildIncmStTable mt-3">
                    <tbody>
                        <tr>
                            <th className="buildIncmStTh12 buildIncmStTh12First">

                            </th>
                            <th className="buildIncmStTh12 pr-2">
                            <span>{language.beginning}</span>
                            </th>
                            <th className="buildIncmStTh12 pr-2">
                                <span>Week 1</span>
                            </th>
                            <th className="buildIncmStTh12 pr-2">
                                <span>Week 2</span>
                            </th>
                            <th className="buildIncmStTh12 pr-2">
                                <span>Week 3</span>
                            </th>
                            <th className="buildIncmStTh12 pr-2">
                                <span>Week 4</span>
                            </th>
                            <th className="buildIncmStTh12 pr-2">
                                <span>Week 5</span>
                            </th>
                            <th className="buildIncmStTh12 pr-2">
                                <span>Week 6</span>
                            </th>
                            <th className="buildIncmStTh12 pr-2">
                                <span>Week 7</span>
                            </th>
                            <th className="buildIncmStTh12 pr-2">
                                <span>Week 8</span>
                            </th>
                            <th className="buildIncmStTh12 pr-2">
                                <span>Week 9</span>
                            </th>
                            <th className="buildIncmStTh12 pr-2">
                                <span>Week 10</span>
                            </th>
                            <th className="buildIncmStTh12 pr-2">
                                <span>Week 11</span>
                            </th>
                            <th className="buildIncmStTh12 pr-2">
                                <span>Week 12</span>
                            </th>
                            <th className="buildIncmStTh12">
                                <span>Week 13</span>
                            </th>
                        </tr>

                        <tr>
                            <td className="buildIncmStTd" style={{ fontWeight: "bold" }}>

                            {language.cash_position}
                            </td>
                            <td className="buildIncmStTd pr-2">
                                <input
                                    disabled
                                    type="number"
                                    name="pastDue"
                                    placeholder="$0.00"
                                    defaultValue=""
                                />
                            </td>

                            {Array.from({ length: 13 }).map((_, index) => (
                                <td key={index} className="buildIncmStTd pr-2">
                                    <input
                                        type="number"
                                        placeholder="$0.00"
                                        value={subtractedValues[`week${index + 1}subtract`] || 0}
                                        readOnly
                                    />
                                </td>
                            ))}
                        </tr>

                        {/* OPERATING DATA */}
                        <tr>
                            <td className="buildIncmStTh12 buildIncmStTh12First" colSpan={5}>
                                <span>{language.essential_operating_data}</span>
                            </td>
                        </tr>

                        {
                            operatingData?.map((item, i, arr) => {
                                return (
                                    <tr>
                                        <td className="buildIncmStTd">
                                            <input
                                                type="text"
                                                className="userBuildInp"
                                                name="title"
                                                value={item.title}
                                            />
                                        </td>
                                        <td className="buildIncmStTd pr-2">

                                        </td>
                                        <td className="buildIncmStTd pr-2">
                                            <input
                                                type="number"
                                                name="week1price"
                                                value={item.week1price}
                                                placeholder="$0.00"
                                            />
                                        </td>
                                        <td className="buildIncmStTd pr-2">
                                            <input
                                                type="number"
                                                name="week2price"
                                                value={item.week2price}
                                                placeholder="$0.00"
                                            />
                                        </td>
                                        <td className="buildIncmStTd pr-2">
                                            <input
                                                type="number"
                                                name="week3price"
                                                value={item.week3price}
                                                placeholder="$0.00"
                                            />
                                        </td>
                                        <td className="buildIncmStTd pr-2">
                                            <input
                                                type="number"
                                                name="week4price"
                                                value={item.week4price}
                                                placeholder="$0.00"
                                            />
                                        </td>
                                        <td className="buildIncmStTd pr-2">
                                            <input
                                                type="number"
                                                name="week5price"
                                                value={item.week5price}
                                                placeholder="$0.00"
                                            />
                                        </td>
                                        <td className="buildIncmStTd pr-2">
                                            <input
                                                type="number"
                                                name="week6price"
                                                value={item.week6price}
                                                placeholder="$0.00"
                                            />
                                        </td>
                                        <td className="buildIncmStTd pr-2">
                                            <input
                                                type="number"
                                                name="week7price"
                                                value={item.week7price}
                                                placeholder="$0.00"
                                            />
                                        </td>
                                        <td className="buildIncmStTd pr-2">
                                            <input
                                                type="number"
                                                name="week8price"
                                                value={item.week8price}
                                                placeholder="$0.00"
                                            />
                                        </td>
                                        <td className="buildIncmStTd pr-2">
                                            <input
                                                type="number"
                                                name="week9price"
                                                value={item.week9price}
                                                placeholder="$0.00"
                                            />
                                        </td>
                                        <td className="buildIncmStTd pr-2">
                                            <input
                                                type="number"
                                                name="week10price"
                                                value={item.week10price}
                                                placeholder="$0.00"
                                            />
                                        </td>
                                        <td className="buildIncmStTd pr-2">
                                            <input
                                                type="number"
                                                name="week11price"
                                                value={item.week11price}
                                                placeholder="$0.00"
                                            />
                                        </td>
                                        <td className="buildIncmStTd pr-2">
                                            <input
                                                type="number"
                                                name="week12price"
                                                value={item.week12price}
                                                placeholder="$0.00"
                                            />
                                        </td>
                                        <td className="buildIncmStTd">
                                            <input
                                                type="number"
                                                name="week13price"
                                                value={item.week13price}
                                                placeholder="$0.00"
                                            />
                                        </td>
                                    </tr>
                                )
                            })
                        }

                    </tbody>
                </table>

            </div>
        </div>
    )
}

export default View13WeekCFS