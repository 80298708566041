import React from 'react'
import { useLanguage } from '../../Providers/LanguageContext'
import SignUpButton from './SignUpButton';
import { IoMdAdd } from 'react-icons/io';
import iMg1 from "../../images/ilustraciones soluciones industriales_AGRICULTURA 1 .png";
import iMg2 from "../../images/ilustraciones soluciones industriales_AGRICULTURA 2 EN.png";
import iMg3 from "../../images/ilustraciones soluciones industriales_AGRICULTURA 3 .png";
import iMg3en from "../../images/ilustraciones soluciones industriales_AGRICULTURA 3 EN.png";
import { useNavigate } from 'react-router-dom';

function Agriculture() {
    const { language, currentLanguage } = useLanguage();
    const navigate = useNavigate();
    // console.log('language', language)
    return (
        <>
            {/* <section id='agriculture_sec'>
                <div className='container'>
                    <div className='agriculture_head'>
                        <div>
                            <h3>{language.fisibility_key_feture}:</h3>
                            <h2>{language.agriculture}</h2>
                        </div>
                    </div>
                    <div className='agriculture_list'>
                        <ul>
                            <li><strong>{language.cop_liverstock}: </strong>
                                {language.fisibility_allows_farmars}
                            </li>
                            <li><strong>{language.invest_management}: </strong>
                                {language.with_fisi_farmers}
                            </li>
                            <li><strong>{language.financial_planning}: </strong>
                                {language.fisi_help_farmers}
                            </li>
                            <li><strong>{language.compliance_management}: </strong>
                                {language.fisi_farmer_compliant}
                            </li>
                            <li><strong>{language.supply_chain_management}: </strong>
                                {language.fisi_farmers_manage}
                            </li>
                        </ul>
                    </div>

                    <SignUpButton />
                </div>
            </section> */}

            <section id='agriculture_sec'>
                <div className='container'>
                    <div className='row mb-5'>
                        <div className='col-md-6 col-12 m-auto'>
                            <div className='agriculture_head'>
                                <h3 style={{ color: "#2c7300" }}>{language.agriculture}</h3>
                            </div>

                            <div className='agriculture_headtext'>
                                <h4 style={{ color: "#864e07" }}>{language.cop_liverstock}</h4>
                                <p>{language.fisibility_allows_farmars}</p>
                                <ul>
                                    <li>{language.crops}</li>
                                    <li>{language.expenses}</li>
                                    <li>{language.cattle_health}</li>
                                </ul>
                                <p>{language.giving_them_real_time}</p>
                            </div>
                        </div>
                        <div className='col-md-6 col-12 m-auto'>
                            <div className='agriculture_img'>
                                <img src={iMg1} className='img-fluid' alt='' />
                            </div>
                        </div>
                    </div>

                    <div className='row mb-5 row_reverse'>
                        <div className='col-md-6 col-12 m-auto'>
                            <div className='agriculture_img'>
                                <img src={iMg2} className='img-fluid' alt='' />
                            </div>
                        </div>

                        <div className='col-md-6 col-12 m-auto'>
                            <div className='agriculture_headtext'>
                                <h4 style={{ color: "#2c7300", textAlign: "right" }}>{language.invest_management}</h4>
                                <p>{language.with_fisi_farmers}</p>
                            </div>

                            <div className='agriculture_headtext'>
                                <h4 style={{ color: "#864e07", textAlign: "right" }}>{language.financial_planning}</h4>
                                <p> {language.fisi_help_farmers}</p>
                            </div>
                        </div>
                    </div>

                    <div className='row mb-5'>
                        <div className='col-md-12 col-12'>
                            <div className='agriculture_headtext'>
                                <h4 style={{ color: "#2c7300", textAlign: "left" }}>{language.compliance_management}</h4>
                                <p>{language.fisi_farmer_compliant}</p>
                            </div>
                        </div>
                    </div>


                    <div className='row'>
                        <div className='col-md-6 col-12 m-auto'>
                            <div className='agriculture_headtext'>
                                <h4 style={{ color: "#864e07", textAlign: "left" }}>{language.supply_chain_management}</h4>
                                <p>{language.fisi_farmers_manage}</p>
                            </div>
                        </div>

                        <div className='col-md-6 col-12 m-auto'>
                            <div className='agriculture_img'>
                                {
                                    currentLanguage === 'spanish'
                                        ?
                                        <img src={iMg3} className='img-fluid' alt='' />
                                        :
                                        <img src={iMg3en} className='img-fluid' alt='' />
                                }

                                <div className='registrarAnddescargar'>
                                    {/* <button className='registrar_Btn' onClick={() => navigate('/login')}>Registrarse</button>
                                    <button className='descargar_Btn'>Descargar</button> */}
                                    <SignUpButton />
                                </div>
                            </div>
                        </div>
                    </div>



                </div>
            </section>
        </>
    )
}

export default Agriculture