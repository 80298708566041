import React from "react";
import bbb from "../images/modalImage1.png";
import ccc from "../images/modalImage2.png";
import { useLanguage } from "../Providers/LanguageContext";
export default function InvestmentRegistry({
  closeModal,
  registerClick,
  searchClick,
}) {
  const { language, currentLanguage } = useLanguage();

  return (
    <>
      <div className="RegisterInvest">
        <div className="modal-content w-fc">
          <span
            className="close pl-05"
            style={{ cursor: "pointer" }}
            onClick={() => closeModal()}
          >
            ×
          </span>
          <div className="p-1 g-2-1fr">
            <span
              className="registerButton"
              onClick={() => {
                registerClick();
                closeModal();
              }}
            >
              <h2>{language.reg_inv}</h2>
              <div style={{ width: 225 }} className="modalImageContainer">
                <img
                  style={{ width: "100%" }}
                  src={bbb}
                  alt="register investment"
                  className=""
                />
                <div className="position-absolute top-0 left-0 p-3">
                  <p className="w-50 blueText">
                    <span className="bigText">{currentLanguage === 'eng' ? "REGISTER" : "REGISTRO"} </span>
                    {currentLanguage === 'eng' ? "Investment" : "Inversión"}
                  </p>

                </div>
              </div>
            </span>
            <span
              className="registerButton"
              onClick={() => {
                searchClick();
                closeModal();
              }}
            >
              <h2>{language.search_investment}</h2>
              <div style={{ width: 225 }} className="modalImageContainer">
                <img
                  style={{ width: "100%" }}
                  src={ccc}
                  alt="search investment"
                />
                <div className="position-absolute top-0 left-0 p-3">
                  <p className="w-50 blueText">
                    <span className="bigText">{currentLanguage === 'eng' ? "SEARCH" : "BUSCAR"}</span> {currentLanguage === 'eng' ? "Investment" : "Inversión"}
                  </p>
                </div>
              </div>
            </span>
          </div>
        </div>
      </div>
    </>
  );
}
