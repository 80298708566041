import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { toast } from 'react-hot-toast'
import { AiOutlineClose } from "react-icons/ai";
import { useNavigate } from 'react-router-dom';
import { useLanguage } from '../../Providers/LanguageContext';

function ClassificationInvoice({ expenses, handleFilterClassChange, setFilters, fetchExpenses, setIsCleared }) {
    const { language } = useLanguage()
    const navigate = useNavigate()




    const selectRef = useRef()

    const clearFilters = () => {
        setFilters({
            "date": "",
            "classification": ""
        })
        selectRef.current.selectedIndex = "0"
        setIsCleared(true)
        fetchExpenses()
        toast.success(language.clear_filter)
    }

    const gotoFunc = (ex)=>{
        console.log(ex)
        navigate("/invoice-details-summary",{state:ex._id})
    }




    return (

        <>

            <div id='clafication'>
                <div className='container'>
                    <div className='table-top'>
                        <div className='top-left'>
                            <form>
                                <div className='top-select'>
                                    <div class="form-group">
                                        <select class="form-control" id="exampleFormControlSelect1" ref={selectRef} onChange={handleFilterClassChange}>
                                            <option>{language.classification}</option>
                                            <option value="Recent">{language.most_recent}</option>
                                            <option value="Oldest">{language.oldest}</option>
                                            {/* <option value="Largest">{language.largest}</option> */}
                                            {/* <option value="Lessar">{language.lessar}</option> */}
                                            {/* <option value="A-Z">Name A-Z</option> */}
                                            {/* <option value="Z-A">Name Z-A</option> */}
                                        </select>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className='top-right'>
                            {/* <button className='customBtn3' onClick={clearFilters}>{language.clear_filters}</button> */}
                            <span onClick={clearFilters} className='todo-btn' id="filterbtn">{language.all_results}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div id='todo-table'>
                <div className='container'>
                    <div className='table-responsive'>
                        <table className="table">
                            {/* <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">First</th>
                                <th scope="col">Last</th>
                                <th scope="col">Handle</th>
                            </tr>
                        </thead> */}
                            <tbody>
                                {
                                    expenses && expenses.map((ex, i) => (
                                        <tr style={{ backgroundColor: "#ebeff3" }}>
                                            {/* <th scope="row">1</th> */}
                                            <td style={{ color: "#0e2537", fontWeight: "600", fontSize: "18px", paddingLeft: "20px", borderTop: "0" }}>
                                                {moment(ex.date).utc().format("LL")}
                                            </td>
                                            <td style={{ display: "flex", justifyContent: "flex-end", alignItems: "flex-end", paddingRight: "30px" }}>
                                                <span className='mx-3'>
                                                    #{ex.invoiceNumber}
                                                </span>
                                                <button style={{
                                                    width: "150px", height: "30px", borderRadius: "10px",
                                                    background: "linear-gradient(to right, #18465e 1%, #14384d 51%, #0f273a 100%)",
                                                    border: "none", color: "#fff", fontSize: "16px"
                                                }}
                                                    onClick={() => {
                                                        gotoFunc(ex)
                                                    }}
                                                >
                                                    {language.see}
                                                </button>
                                            </td>
                                            {/* <td>@mdo</td> */}
                                        </tr>
                                    ))
                                }

                            </tbody>
                        </table>
                    </div>

                </div>
            </div>
            {/* custom modal */}

        </>

    )
}

export default ClassificationInvoice