import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import ModalComp2 from '../Authentication/ModalComp2'
import { useLanguage } from '../Providers/LanguageContext'
import HttpClient from '../utils/HttpClient'
import CustomModal from './CustomModal'

function ListComp({ transactions, transactionFilter }) {
    const navigate = useNavigate()
    const { language } = useLanguage()

   
    const handleFetchTrans = async (email,id) => {
        // const res = await HttpClient.requestData("get-transaction", "POST", transactionFilter);
        // if (res && res.status) {
        //      const transactionfind = res.data.find(t=>t.email === email);
        //     navigate("/transaction-details",{state:{...transactionfind,transactionFilter}})
        // }

        navigate("/transaction-details",{state:{transactionFilter,email,id}})

    }
    return (
        <>
            <div id='todo-table'>
                <div className='container'>
                    <div className='table-responsive'>
                        <table className="table">
                            <tbody>
                                {
                                    transactions && transactions.map((t, i) => (
                                        <tr style={{ backgroundColor: "#ebeff3" }} key={i}>
                                            <td style={{
                                                color: "#0e2537",
                                                fontWeight: "600",
                                                fontSize: "18px",
                                                paddingLeft: "20px",
                                                borderTop: "0"
                                            }}>
                                                {t.name} {t.surName}
                                            </td>
                                            <td style={{
                                                display: "flex",
                                                justifyContent: "flex-end",
                                                alignItems: "flex-end",
                                                paddingRight: "30px"
                                            }}>
                                                <button
                                                    style={{
                                                        maxWidth: "110px",
                                                        width: "100%",
                                                        height: "30px",
                                                        borderRadius: "10px",
                                                        background: "linear-gradient(to right, #18465e 1%, #14384d 51%, #0f273a 100%)",
                                                        border: "none", color: "#fff", fontSize: "16px"
                                                    }}
                                                    onClick={()=>handleFetchTrans(t.email,t._id)}
                                                >
                                                    {language.see}
                                                </button>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ListComp