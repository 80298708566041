import React, { useContext } from 'react';
import { FaUserAlt } from 'react-icons/fa';
import { GiAchievement } from 'react-icons/gi';
import { FaStarOfLife } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { useLanguage } from '../../Providers/LanguageContext';
import { UserContext } from '../../Providers/UserWrapper';
import achivement from '../../images/fisibility_icons/rewards_manager.png'


const Sidebar2 = (props) => {
    const { show, setShow } = useContext(UserContext)
    const { language } = useLanguage();
    // const data = [
    //     {
    //         text: 'Tu perfil',
    //         icon: 'ii'
    //     },
    //     {
    //         text: 'Tu perfil',
    //         icon: 'ii'
    //     },
    //     {
    //         text: 'Tu perfil',
    //         icon: 'ii'
    //     },
    // ]
    return (
        <div className='container mt-4'>

            <div className="row">
                <div className="col-md-1 sidebar-icon">
                    {/* <img src="" alt="" /> */}
                    <span><FaUserAlt /></span>
                </div>
                <div className="col-md-11">
                    <Link to="/configuration"><span>{language.your_profile}</span></Link>
                    <br />
                    <small>{language.update_profile_information}</small>
                </div>
                <hr />
            </div>


            <div className="row">
                <div className="col-md-1 sidebar-icon">
                    {/* <img src="" alt="" /> */}
                    <span><FaStarOfLife /></span>

                </div>
                <div
                    style={{ cursor: 'pointer' }}
                    className="col-md-11" onClick={() => {
                        setShow(false)
                        props.openModal();

                    }}>
                    <span>{language.change_password}</span>
                </div>
                <hr />
            </div>


            {/* Logros Financieros */}

            <div className="row">
                <div className="col-md-1 sidebar-icon" >
                    <img cl src={achivement} alt="" width={20} height={21} className="dark_blue" style={{ height: '14px' }} />
                    {/* <span style={{fontSize:'18px'}}><GiAchievement/></span>  */}
                </div>
                <div className="col-md-11">
                    <Link to="/achievements">
                        <span>{language.financial_achievements}</span>
                    </Link>
                </div>
                <hr />
            </div>
        </div>
    )
}

export default Sidebar2