import moment, { lang } from 'moment';
import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react'
import { toast } from 'react-hot-toast';
import HttpClient from '../utils/HttpClient';
import { useLanguage } from './LanguageContext';
import { useContext } from 'react';

export const UserContext = createContext();

export const useUserData = () => useContext(UserContext)

function UserWrapper({ children }) {
    const { language } = useLanguage()
    const [userData, setUserData] = useState(localStorage.getItem("userData"));
    const [profileData, setProfileData] = useState()
    const [show, setShow] = useState(false);
    const [isPaid, setIsPaid] = useState(false);
    const [isCipAi, setIsCipAi] = useState(true);
    const [regCodeData, setRegCodeData] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [getNowUser, setGetNowUser] = useState("");

    // console.log("profileDataUser", profileData)

    const initCsv = {
        headers: [],
        data: []
    }
    const [repSelectCon, setRepSelectCon] = useState("");

    const [repCsv, setRepCsv] = useState(initCsv);
    const [financeCsv, setFinanceCsv] = useState(initCsv);
    const [revenueCsv, setRevenueCsv] = useState(initCsv);
    const [expenseCsv, setExpenseCsv] = useState(initCsv);
    const [profitCsv, setProfitCsv] = useState(initCsv);
    const [profitablityCsv, setProfitabilityCsv] = useState(initCsv);

    const [repExcel, setRepExcel] = useState([]);
    const [financeExcel, setFinanceExcel] = useState([]);
    const [revenueExcel, setRevenueExcel] = useState([]);
    const [expenseExcel, setExpenseExcel] = useState([]);
    const [profitExcel, setProfitExcel] = useState([]);
    const [profitablityExcel, setProfitabilityExcel] = useState([]);
    const [TranDetaiExcel, setTranDetailExcel] = useState([]);
    const [ExpenseDetExcel, SetExpenseDetExcel] = useState([]);
    const [googleEmail, setGoogleEmail] = useState("");

    const isLogin = userData ? true : false
    const isBusiness = profileData ? profileData.userType === "Business" ? true : false : false;
    const isPersonal = profileData ? profileData.userType === "Personal" ? true : false : false;
    const isBookKeeper = profileData ? profileData.userType === "Bookkeeper" ? true : false : false;
    const isInvestor = profileData ? profileData.userType === "Investor" ? true : false : false;

    const isSubscribeMemo = useMemo(() => {
        if (Object.keys(regCodeData).length) {
            if (regCodeData.expiryDate >= moment().format()) {
                return true;
            } else {
                return false;
            }
        } else {
            return false
        }
    }, [regCodeData, profileData]);


    // report Manager
    useEffect(() => {
        switch (repSelectCon) {
            case "Financial": setRepCsv(financeCsv); setRepExcel(financeExcel); break;
            case "Revenue": setRepCsv(revenueCsv); setRepExcel(revenueExcel); break;
            case "Expense": setRepCsv(expenseCsv); setRepExcel(expenseExcel); break;
            case "Profit": setRepCsv(profitCsv); setRepExcel(profitExcel); break;
            case "Profitability": setRepCsv(profitablityCsv); setRepExcel(profitablityExcel); break;
            case "Transaction": setRepCsv(TranDetaiExcel); setRepExcel(TranDetaiExcel); break;
            case "ExpenseDetail": setRepCsv(ExpenseDetExcel); setRepExcel(ExpenseDetExcel); break;
            default: setRepCsv({
                headers: [],
                data: []
            }); break;
        }
    }, [repSelectCon, financeCsv, revenueCsv, expenseCsv, profitCsv, profitablityCsv, financeExcel, revenueExcel, expenseExcel, profitExcel, profitablityExcel, TranDetaiExcel, ExpenseDetExcel])

    const getProfile = async () => {
        if (userData) {
            setIsLoading(true);
            const result = await HttpClient.requestData("get-profile", "GET");
            // console.log('getProfile', result);
            if (result && result.success) {
                setProfileData(result.data[0]);
                setIsLoading(false);
            } else {
                // console.log(result, "errorcontext");
                setIsLoading(false);
            }
        }
    }

    const logout = () => {
        localStorage.clear();
        setUserData(null)
        setProfileData(null)
        setRegCodeData({})
        toast.success(language.logout_success)
    }

    const pricePathMemo = useMemo(() => {
        if (isLogin) {
            if (isBusiness) {
                return "price"
            }
            else {
                return "price"
            }
        }
        else {
            return "price"
        }
    }, [profileData, isBusiness, isLogin])

    const getUserNumber = async () => {
        const sendData = {
            accountRegCode: Number(profileData && profileData.accountRegCode)
        }
        // console.log('sendData', sendData)
        const res = await HttpClient.requestData("total-user", "POST", sendData);
        console.log('resPaySub', res, sendData);
        if (res && res.status) {
            setRegCodeData(res.data[0]);
        }
    }

    // returns true for main users
    const isMainUserMemo = useMemo(() => {
        if (profileData) {
            if (profileData.userType === "Personal") {
                if (profileData.accountType === "Manager") {
                    return true
                } else {
                    return false
                }
            } else if (profileData.userType === "Business") {
                if (profileData.accountType === "Owner") {
                    return true;
                } else {
                    return false
                }
            } else if (profileData.userType === "Bookkeeper") {
                if (profileData.accountType === "Owner") {
                    return true;
                } else {
                    return false
                }
            }
            else {
                return false
            }
        } else {
            return false
        }

    }, [profileData])

    useEffect(() => {
        getUserNumber();
    }, [profileData])


    useEffect(() => {
        getProfile()
    }, [userData])

    // console.log("profileData", profileData)

    const values = {
        profileData,
        setProfileData,
        logout,
        setUserData,
        getProfile,
        show,
        setShow,
        isPaid,
        setIsPaid,
        isLogin,
        isCipAi,
        setIsCipAi,
        isBusiness, isPersonal, isBookKeeper, isInvestor,
        pricePathMemo,
        setRepSelectCon,
        // report csv 
        setFinanceCsv,
        setRevenueCsv,
        setExpenseCsv,
        setProfitCsv,
        setProfitabilityCsv,
        repCsv,
        // report excel
        repExcel,
        setFinanceExcel,
        setRevenueExcel,
        setExpenseExcel,
        setProfitExcel,
        setProfitabilityExcel,
        setTranDetailExcel,
        SetExpenseDetExcel,
        regCodeData,
        setRegCodeData,
        isSubscribeMemo,
        isLoading, setIsLoading,
        getUserNumber,
        isMainUserMemo,
        getNowUser, setGetNowUser,
        googleEmail, setGoogleEmail
    }

    return (
        <UserContext.Provider value={values}>
            {children}
        </UserContext.Provider>
    )
}

export default UserWrapper;


