import React, { useEffect, useState } from "react";
import "./BuildReports.css";
import { useLanguage } from "../../../Providers/LanguageContext";
import HttpClient from "../../../utils/HttpClient";
import toast from "react-hot-toast";
import CustomLoaderLine from "../../../Component/Loader/CustomLoaderLine";
import moment from "moment";
import { useUserData } from "../../../Providers/UserWrapper";

function BuildTrial({ setTrialBalanceCsv, setPdfCreate, setPdfLoader, setEmailSendFunc }) {
  const { language, currentLanguage } = useLanguage();
  const { profileData, setIsLoading } = useUserData()

  const initAcc = {
    accName: "",
    debit: "",
    credit: ""
  }
  const [accNameData, setAccNameData] = useState([initAcc]);

  useEffect(() => {
    setAccNameData([
      {
        accName: "Petty Cash",
        debit: "",
        credit: ""
      },
      {
        accName: language.cash_in_checking,
        debit: "",
        credit: ""
      },
      {
        accName: language.cash_in_savings,
        debit: "",
        credit: ""
      },
      {
        accName: language.account_reciever,
        debit: "",
        credit: ""
      },
      {
        accName: language.reverse_bad_debts,
        debit: "",
        credit: ""
      },
      {
        accName: language.reverse_bad_debts,
        debit: "",
        credit: ""
      },
      {
        accName: language.prepaid_insurance,
        debit: "",
        credit: ""
      },
      {
        accName: language.prepsaid_other_exp,
        debit: "",
        credit: ""
      },
      {
        accName: language.Office_Supplies,
        debit: "",
        credit: ""
      },
      {
        accName: language.Utility_Deposits,
        debit: "",
        credit: ""
      },
      {
        accName: language.Notes_Receivable,
        debit: "",
        credit: ""
      },
      {
        accName: language.Investments,
        debit: "",
        credit: ""
      },
      {
        accName: language.Organization_Expenses,
        debit: "",
        credit: ""
      },

    ])
  }, [currentLanguage]);

  const [totalData, setTotalData] = useState({ debit: "", credit: "", difference: "" });
  const [isSave, setIsSave] = useState(true);
  const [loading, setLoading] = useState(false);
  const [isDelAccName, setIsDelAccName] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);



  // console.log("accNameData", accNameData);

  // onChange
  const handleChangeAcc = (e, ind) => {
    const { name, value } = e.target;
    const data = accNameData.map((item, i) => {
      if (i === ind) {
        return { ...item, [name]: value }
      } else {
        return item
      }
    })
    setAccNameData(data);
    clculateAllDataIncome(data)
  }

  // sum calculation function for asset
  const clculateAllDataIncome = (changedArr) => {
    const sumDebit = sumFromArrayOfObject(changedArr, "debit");
    const sumCredit = sumFromArrayOfObject(changedArr, "credit");
    const diff = DiffFromTwoValues(sumCredit, sumDebit);
    setTotalData(prev => ({ ...prev, debit: sumDebit, credit: sumCredit, difference: diff }));
  }

  // returns the sum of a key from array of object
  const sumFromArrayOfObject = (arr, key) => {
    const sum = arr.reduce((acc, cur) => acc + Number(cur[key]), 0);
    return sum ? sum : "";
  }

  // difference from to values
  const DiffFromTwoValues = (num1, num2) => {
    return +num1 - +num2
  }


  // click on build report
  const handleBuildRepport = async () => {
    // if (!validateAsset() || !validateLiability()) {
    //   toast.error(language.please_enter_all);
    //   return
    // }

    if (isSave) {
      toast.error("Please Save First");
      return
    }

    // console.log("accNameData", accNameData)

    const sendData = {
      Account: accNameData?.map(item => ({
        "accountName": item?.accName,
        "debit": +item?.debit,
        "credit": +item?.credit
      })),
      "totalDebit": totalData?.debit,
      "totalCredit": totalData?.credit,
      "diffrence": totalData?.difference
    }

    // return

    setLoading(true);
    setPdfLoader(true);
    // const res = await HttpClient.requestData("report-builder/trial-balance", "POST", sendData);
    const res = await HttpClient.requestData("reportbuilder-add-trialbalance", "POST", sendData);
    // console.log("resbbb", res)
    if (res && res?.status) {
      // window.open(res?.path, "_blank");
      setLoading(false);
      setPdfLoader(false);
      toast.success(language.report_build_succ);
    }
    else {
      setLoading(false);
      setPdfLoader(false);
    }
  }

  // click on save
  const handleSave = async () => {

    const assetArr = accNameData.map(item => item?.accName);
    if (isSave) {
      const sendData = {
        "type": "Trial_Balance",
        "Account_Name": assetArr
      }
      // console.log("assetarrlia", assetData, liabilityData, sendData);
      setSaveLoading(true)
      const res = await HttpClient.requestData("add-record-reportbuilder", "POST", sendData);
      // console.log("resiccc", res)
      if (res && res?.status) {
        toast.success("Report Saved Successfully");
        setIsSave(!isSave);
        setSaveLoading(false);
        // getInvoiceData();
      } else {
        setSaveLoading(false);
        toast.error(res?.message);
        return;
      }
    } else {
      setIsSave(!isSave);
    }
  }


  // getting saved invoice data
  const getInvoiceData = async () => {
    const sendData = {
      "type": "Trial_Balance"
    }
    const res = await HttpClient.requestData("view-record-reportbuilder", "POST", sendData);
    console.log("resInvvgettrial", res);
    if (res && res?.status) {
      const accArr = res?.data?.Account_Name?.map(item => ({ ...initAcc, accName: item }))
      if (accArr) {
        setAccNameData([...accArr]);
      }
    }
  }

  // send mail
  const sendTrialMail = async (emailData, oncloseSendModal) => {
    const data = {
      "email": emailData?.email,
      "language": currentLanguage === "eng" ? "EN" : "ES",
      "Account": accNameData?.map(item => ({
        "accountName": item?.accName,
        "debit": +item?.debit,
        "credit": +item?.credit
      })),
      "totalDebit": totalData?.debit,
      "totalCredit": totalData?.credit,
      "diffrence": totalData?.difference
    }

    oncloseSendModal();
    setIsLoading(true);
    const res = await HttpClient.requestData("reportbuilder/pdf/trialbalance", "POST", data)
    if (res && res?.status) {
      toast?.success(language.mail_sent_success);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  }

  // dowmload pdf
  const handlePdfDownload = async () => {
    // if (isSave) {
    //   toast.error("Please Save First");
    //   return
    // }

    setPdfLoader(true);
    const res = await HttpClient.requestData("reportbuilder/download/pdf/trialbalance", "GET", {})
    setPdfLoader(false);
    if (res && res?.status) {
      window.open(res?.data, "_blank")
    } else {
      toast.error(res?.message || "Something Wrong!")
    }
  }



  useEffect(() => {
    getInvoiceData();
    setPdfCreate(() => handlePdfDownload);

  }, []);


  //  csv,pdf,excel 
  useEffect(() => {
    if (!isSave) {
      const arrAccName = accNameData?.map((item) => {
        return Object.values(item)
      })

      const finalData = [
        [],
        [language.trial_balance, language.period_ending, language.report_date],
        [profileData?.companyName, "", moment().format("DD-MM-YYYY")],
        [language.report_generated_by + ":", profileData?.name + " " + profileData?.surName],
        [],
        [],
        ["Account Name", "Debit", "Credit"],
        [],
        ...arrAccName,
        ["Total", totalData?.debit, totalData.credit],
        ["Difference", "", totalData.difference]
      ];
      // console.log("finalData", finalData)
      setTrialBalanceCsv(finalData);

      // this saves the pdf download function in a state
      // setPdfCreate(() => handleBuildRepport);

    }


    // send
    setEmailSendFunc(() => sendTrialMail);

  }, [isSave])

  const getInvoiceStatement = async () => {
    const res = await HttpClient.requestData("reportbuilder-view-trialbalance", "GET");
    // console.log("ressPttrr", res);
    if (res && res?.status) {
      // setBuildRepData(res?.data)
      const incdata = res?.data?.Account?.map((item) => ({
        accName: item?.accountName,
        debit: item?.credit,
        credit: item?.debit
      }))
      setAccNameData(incdata);

      setTotalData({
        debit: res?.data?.totalCredit,
        credit: res?.data?.totalDebit,
        difference: res?.data?.diffrence
      })
    }
  }

  useEffect(() => {
    getInvoiceStatement();
  }, [])

  return (
    <div className="buildTrialTableDiv mt-4">
      <table className="buildTrialTable">

        <thead>
          <th className="bulidTriLeftTh">

            {/* add row */}
            {isSave &&
              <button
                className="buildAddBtn"
                onClick={() => setAccNameData(prev => [initAcc, ...prev])}
              >
                <i class="fa-solid fa-plus"></i>
              </button>
            }

            <span className="buildBalanceHead">{language.account_name}</span>

            {/* delete row button*/}
            {isSave &&
              <button
                style={{ fontSize: "12px" }}
                className="delBuildRowBtn"
                onClick={() => setIsDelAccName(prev => !prev)}
              >
                {isDelAccName ? language.remove_delete : language.delete_row}
              </button>
            }

          </th>
          <th className="bulidTriRightTh">
            <span className="buildBalanceHead">Debit</span>
          </th>
          <th className="bulidTriRightTh">
            <span className="buildBalanceHead">Credit</span>
          </th>
        </thead>

        <tbody>
          {accNameData && accNameData?.map((item, i) => {
            return (
              <tr key={i}>
                <td className="buildTrialLeftTd">

                  {/* delete minus icon */}
                  {(isDelAccName && isSave) &&
                    <button
                      className="buildTriRemoveBtn"
                      onClick={() => {
                        const flltData = accNameData?.filter((ele, ind) => i !== ind);
                        clculateAllDataIncome(flltData);
                        setAccNameData(prev => prev.filter((ele, ind) => i !== ind));
                        // setAccNameData(prev => prev.filter((ele, ind) => ind !== i))
                      }}
                    >
                      <i class="fa-solid fa-minus"></i>
                    </button>
                  }

                  {
                    isSave
                      ?
                      <input
                        type="text"
                        name="accName"
                        value={item.accName}
                        onChange={(e) => handleChangeAcc(e, i)}
                      />
                      :
                      <span className="buildBalLeftTdSpan">{item.accName?.toUpperCase()}</span>
                  }

                  {/* <span className="buildTriLeftTdSpan">{item.accName}</span> */}
                </td>
                <td className="buildTrialRightTd">
                  <input
                    type="number"
                    // placeholder="$0.00"
                    name="debit"
                    value={item.debit}
                    onChange={(e) => handleChangeAcc(e, i)}
                    readOnly={!isSave}
                  />
                </td>
                <td className="buildTrialRightTd">
                  <input
                    type="number"
                    // placeholder="$0.00"
                    name="credit"
                    value={item.credit}
                    onChange={(e) => handleChangeAcc(e, i)}
                    readOnly={!isSave}
                  />
                </td>
              </tr>
            )
          })}

          {/* <tr>
            <td className="buildTrialLeftTd">
              <button className="buildTriRemoveBtn">
                <i class="fa-solid fa-minus"></i>
              </button>
              <span className="buildTriLeftTdSpan">Cash in Checking</span>
            </td>
            <td className="buildTrialRightTd">
              <input type="number" placeholder="$0.00" />
            </td>
            <td className="buildTrialRightTd">
              <input type="number" placeholder="$0.00" />
            </td>
          </tr> */}
          {/* <tr>
            <td className="buildTrialLeftTd">
              <button className="buildTriRemoveBtn">
                <i class="fa-solid fa-minus"></i>
              </button>
              <span className="buildTriLeftTdSpan">Cash in Savings</span>
            </td>
            <td className="buildTrialRightTd">
              <input type="number" placeholder="$0.00" />
            </td>
            <td className="buildTrialRightTd">
              <input type="number" placeholder="$0.00" />
            </td>
          </tr> */}
          {/* <tr>
            <td className="buildTrialLeftTd">
              <button className="buildTriRemoveBtn">
                <i class="fa-solid fa-minus"></i>
              </button>
              <span className="buildTriLeftTdSpan">Accounts Receivable</span>
            </td>
            <td className="buildTrialRightTd">
              <input type="number" placeholder="$0.00" />
            </td>
            <td className="buildTrialRightTd">
              <input type="number" placeholder="$0.00" />
            </td>
          </tr>
          <tr>
            <td className="buildTrialLeftTd">
              <button className="buildTriRemoveBtn">
                <i class="fa-solid fa-minus"></i>
              </button>
              <span className="buildTriLeftTdSpan">Reserve for Bad Debts</span>
            </td>
            <td className="buildTrialRightTd">
              <input type="number" placeholder="$0.00" />
            </td>
            <td className="buildTrialRightTd">
              <input type="number" placeholder="$0.00" />
            </td>
          </tr>
          <tr>
            <td className="buildTrialLeftTd">
              <button className="buildTriRemoveBtn">
                <i class="fa-solid fa-minus"></i>
              </button>
              <input type="text" placeholder="Enter New" />
            </td>
            <td className="buildTrialRightTd">
              <input type="number" placeholder="$0.00" />
            </td>
            <td className="buildTrialRightTd">
              <input type="number" placeholder="$0.00" />
            </td>
          </tr> */}
        </tbody>

        {/* total */}
        <tfoot>
          <tr>
            <td className="buildTrialLeftFootTd">
              {/* <span>{language.totals}:</span> */}
              <span>Totals:</span>
            </td>
            <td className="buildTrialRightTd">
              <input
                type="number"
                // placeholder="$0.00"
                value={totalData.debit}
                readOnly
              />
            </td>
            <td className="buildTrialRightTd">
              <input
                type="number"
                // placeholder="$0.00"
                value={totalData.credit}
                readOnly
              />

            </td>
          </tr>
          <tr>
            <td className="buildTrialLeftFootTd">
              <span>{language.difference}:</span>
            </td>
            <td className="buildTrialRightTd">

            </td>
            <td className="buildTrialRightTd">
              <input
                type="number"
                placeholder="$0.00"
                value={totalData.difference}
                readOnly
              />
            </td>
          </tr>
        </tfoot>
      </table>

      {/* button group */}
      <div className="d-flex justify-content-center mt-3">
        <button
          className="btn-report-save"
          onClick={() => handleSave()}
        >
          {isSave ?
            saveLoading ? <CustomLoaderLine height="10" width="40" /> : language.save?.toUpperCase()
            :
            language.edit?.toUpperCase()
          }
        </button>
        <button
          className="btn-report-build"
          onClick={() => handleBuildRepport()}
        >
          {loading ? <CustomLoaderLine height="10" width="100" /> : language.build_report?.toUpperCase()}
          {/* {language.build_report?.toUpperCase()} */}
        </button>
      </div>

    </div>
  );
}

export default BuildTrial;
