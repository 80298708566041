import React from 'react';
import { AiFillSetting } from 'react-icons/ai';
import { useLanguage } from '../../Providers/LanguageContext';
import privacyIcon from "../../images/New Home Screen Icons/h. CIPBITS Manager - BU & PU/cipbits_manager.png";
import { Link, useNavigate } from 'react-router-dom';
import { useUserData } from '../../Providers/UserWrapper';
import payIcon from '../../images/fisibility_icons/expense_manager.png';
import privacy from '../../images/blog_pdfs/Fisibility - Privacy Policy_En.pdf';
import privacy_Es from '../../images/blog_pdfs/privacy_police_Es.pdf'

// AiFillSetting

const Sidebar5 = () => {
    const { language, currentLanguage } = useLanguage()
    const { logout, pricePathMemo } = useUserData();
    const navigate = useNavigate();

    const handleOpenRoute = () => {
        const routeUrl = 'https://fisibility.com/Privacy'; // Replace with your desired route URL
        window.open(routeUrl, '_blank');
    };
    // const data = [
    //     {
    //         text: language.contact_support,
    //         icon: <AiFillSetting />,
    //         link: "/contact"
    //     },
    //     {
    //         text: language.privacy_policy2,
    //         icon: <img src={privacyIcon} width={20} height={20} />,
    //         link: "/term_contitions"
    //     }
    // ]
    return (
        <>
            <div className='container mt-4'>
                <Link to={`/${pricePathMemo}`}>
                    <div className="row">
                        <div className="col-md-1 sidebar-icon">
                            <img src={payIcon} alt="" style={{ width: "20px", height: "20px" }} />
                            {/* <span><AiFillSetting /></span> */}
                        </div>
                        <div className="col-md-11">
                            <span> {language.pay}</span>
                        </div>
                        <hr />
                    </div>
                </Link>
            </div>

            <div className='container mt-4'>
                <Link to='/contact'>
                    <div className="row">
                        <div className="col-md-1 sidebar-icon">
                            {/* <img src="" alt="" /> */}
                            <span><AiFillSetting /></span>
                        </div>
                        <div className="col-md-11">
                            <span> {language.contact_support}</span>
                        </div>
                        <hr />
                    </div>
                </Link>

                {/* <a href={currentLanguage === 'eng' ? privacy : privacy_Es} target='_blank'> */}
                <a style={{ cursor: "pointer" }} onClick={handleOpenRoute}>
                    <div className="row">
                        <div className="col-md-1 sidebar-icon">
                            {/* <img src="" alt="" /> */}
                            <span><img src={privacyIcon} width={20} height={20} /></span>
                        </div>
                        <div className="col-md-11">
                            <span> {language.privacy_policy2}</span>
                        </div>
                        <hr />
                    </div>
                </a>

                <div className='d-flex justify-content-center'>
                    <button
                        onClick={() => {
                            logout();
                            navigate('/')
                        }}
                        className='btn btn-secondary'
                    >
                        {language.logoutButton}
                    </button>
                </div>
            </div>
        </>
    )
}

export default Sidebar5;