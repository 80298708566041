import React, { useEffect, useState } from 'react'
import HttpClient from '../utils/HttpClient';

export const useRequestData = (uri,method,formdata)=> {
    const [loading,setLoading] = useState(false);
    const [error,setError] = useState(null)
    const [data,setData] = useState([])
   
    useEffect(()=>{
      const fetchFunc = async()=>{
        setLoading(true);
      const res = await HttpClient.requestData(uri,method,formdata);
      setLoading(false)
      if(res && res.status){
        setData(res.data)
      }else{
        setError(res)
      }
      }
      fetchFunc()
    },[])

    return {loading,data,error}
}
