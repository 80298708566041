import React, { useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useUserData } from '../Providers/UserWrapper';

const PrivateRouter = () => {
    // console.log("private router");

    const { isLogin } = useUserData()

    // if (localStorage.getItem("userData")) {
    if (isLogin) {
        return <Outlet />
    } else {
        return <Navigate to="/login" />
    }

}

export default PrivateRouter;