import React from 'react'
import iMg1 from "../../images/ilustraciones soluciones industriales_MINORISTAS 2.png";
import iMg2 from "../../images/ilustraciones soluciones industriales_MINORISTAS 1.png";
import iMg3 from "../../images/ilustraciones soluciones industriales_MINORISTAS 3 .png";
import iMg3en from "../../images/ilustraciones soluciones industriales_MINORISTAS 3 EN.png";

import { useLanguage } from '../../Providers/LanguageContext';
import SignUpButton from './SignUpButton';
const Retailer = () => {
  const { language, currentLanguage } = useLanguage();

  return (
    <>

      <section id='agriculture_sec'>
        <div className='container'>
          <div className='row mb-5'>
            <div className='col-md-6 col-12 m-auto'>
              <div className='agriculture_head'>
                <h3 style={{ color: "#000000" }}>{language.retailers}</h3>
              </div>

              <div className='agriculture_headtext'>
                <h4 style={{ color: "#7da3db" }}>{language.inventory_management}</h4>
                <p>{language.fisi_enable_retailer}
                </p>
              </div>
            </div>
            <div className='col-md-6 col-12 m-auto'>
              <div className='agriculture_img'>
                <img src={iMg2} className='img-fluid' alt='' />
              </div>
            </div>
          </div>

          <div className='row row_reverse mb-5'>
            <div className='col-md-6 col-12 m-auto'>
              <div className='agriculture_img'>
                <img src={iMg1} className='img-fluid' alt='' />

              </div>
            </div>
            <div className='col-md-6 col-12 m-auto'>
              <div className='agriculture_headtext'>
                <h4 style={{ color: "#d3671e", textAlign: "right" }}>{language.expence_tracking}</h4>
                <p style={{ textAlign: "justify" }}>{language.fisi_retail_track_expanse}</p>

              </div>
              <div className='agriculture_headtext mt-5'>
                <h4 style={{ color: "#7da3db", textAlign: "right" }}>{language.sales_tracking}</h4>
                <p style={{ textAlign: "justify" }}>{language.app_allow_retailer_track}</p>

              </div>
            </div>

          </div>



          <div className='row wrapBg_Img mt-5'>


            <div className='col-md-12 col-12 m-auto'>
              <div className='agriculture_headtext'>
                <h4 style={{ color: "#d3671e", textAlign: "left" }}>{language.multi_location_support}</h4>
                <p style={{ textAlign: "justify" }}>{language.fisi_app_support_multi}
                </p>

              </div>
            </div>


            <div className='col-md-6 col-12 m-auto pt-5'>
              <div className='agriculture_headtext'>
                <h4 style={{ color: "#86a9dd", textAlign: "left" }}>{language.finaciqal_insight}</h4>
                <p>{language.fisi_provide_retail_detail}
                </p>
              </div>
            </div>

            <div className='col-md-6 col-12 m-auto mb-5'>
              <div className='agriculture_img' style={{ maxWidth: "60%", height: "400px" }}>
                {
                  currentLanguage === 'spanish'
                    ?
                    <img src={iMg3} className='img-fluid' alt='' style={{ marginBottom: "30px" }} />
                    :
                    <img src={iMg3en} className='img-fluid' alt='' style={{ marginBottom: "30px" }} />

                }
                {/* <div className='registrarAnddescargar' style={{ transform: "translate(-50% , -50%)", top: "100%", }}>
                  <button className='registrar_Btn' style={{ background:"#d3671e",border:"0" }}>Registrarse</button>
                  <button className='descargar_Btn' style={{borderColor:"#d4e2fd", color:"#d4e2fd"}}>Descargar</button>
                </div> */}
                <SignUpButton />
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  )
}

export default Retailer