import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { useLanguage } from '../../Providers/LanguageContext';
import { useUserData } from '../../Providers/UserWrapper';
import HttpClient from '../../utils/HttpClient';
import PaginationReport from './PaginationReport';

const BudgetRevenueTable = ({ reportDate }) => {
    const { language, currentLanguage } = useLanguage();
    const { setRevenueCsv, setRevenueExcel, profileData } = useUserData();

    const { isBusiness } = useUserData();
    const [yearData, setYearData] = useState([])
    const [monthData, setMonthData] = useState([])
    const [weekData, setWeekData] = useState([])
    const [dayData, setDayData] = useState([])

    const [pagiDayData, setPagiDayData] = useState([]);
    const [pagiWeekData, setPagiWeekData] = useState([]);
    const [pagiMonthData, setPagimonthData] = useState([]);
    const [pagiYearData, setPagiYearData] = useState([]);


    console.log('revenueData', yearData)


    const getYearData = async () => {
        const res = await HttpClient.requestData("self-service-revenue-yearly", "GET", {});
        // console.log('yearDatarev', res);
        if (res && res?.status) {
            setYearData(res?.data)
        }
    }

    const getMonthData = async () => {
        const res = await HttpClient.requestData("self-service-revenue-monthly", "GET", {});
        // console.log('SelfMonthRes', res);
        if (res && res?.status) {
            setMonthData(res?.data)
        }
    }

    const getWeekData = async () => {
        const res = await HttpClient.requestData("self-service-revenue-weekly", "GET", {});
        // console.log('selfWeekRes', res);
        if (res && res?.status) {
            setWeekData(res?.data);
        }
    }

    const getDayData = async () => {
        if (reportDate.startData && reportDate.endDate) {
            const res = await HttpClient.requestData("self-service-revenue-date", "POST", reportDate);
            // console.log('selfDayaData', res);
            if (res && res?.status) {
                setDayData(res?.data)
            }
        } else {
            const res = await HttpClient.requestData("self-service-revenue-daily", "GET", {});
            // console.log('selfDayaData', res);
            if (res && res?.status) {
                setDayData(res?.data)
            }
        }
    }

    useEffect(() => {
        getYearData();
        getMonthData();
        getWeekData();
    }, [])

    useEffect(() => {
        getDayData();
    }, [reportDate]);

    const getMonthName = (num) => {
        // console.log('monthDataNumRev', num)
        switch (num) {
            case 1: return language.january;
            case 2: return language.february;
            case 3: return language.march;
            case 4: return language.april;
            case 5: return language.may;
            case 6: return language.june;
            case 7: return language.july;
            case 8: return language.august;
            case 9: return language.september;
            case 10: return language.october;
            case 11: return language.november;
            case 12: return language.december;
        }
    }



    useEffect(() => {

        const maxLength = Math.max(yearData.length, monthData.length, weekData.length, dayData.length)
        // console.log('maxLength', maxLength)

        // header
        let revenueHeader = [
            { label: "", key: "titles" },
        ];
        for (let i = 0; i < maxLength; i++) {
            revenueHeader = [
                ...revenueHeader,
                { label: "", key: String(i) },
            ]
        }
        // console.log('revenueHeader', revenueHeader)

        // data
        // day
        let obj1 = { titles: "Day" };
        let obj2 = { titles: "Daily Revenue – Budget $ Amount" };
        let obj3 = { titles: "Daily Revenue – Budget $ Amount" };
        let obj4 = { titles: "Daily Revenue Budget Performance +/-" };

        let revenueData = []
        if (dayData.length) {
            dayData.forEach((item, i) => {
                obj1 = { ...obj1, [i]: moment(item._id).format("YYYY-MM-DD") };
                obj2 = { ...obj2, [i]: item.actualAmount };
                obj3 = { ...obj3, [i]: item.budgetAmount };
                obj4 = { ...obj4, [i]: item.budgetPerformance };
            })
            revenueData = [obj1, obj2, obj3, obj4]
        }

        // week
        let objWeek = { titles: "" };
        let obj5 = { titles: "Week" };
        let obj6 = { titles: "Daily Revenue – Budget $ Amount" };
        let obj7 = { titles: "Daily Revenue – Budget $ Amount" };
        let obj8 = { titles: "Daily Revenue Budget Performance +/-" };

        if (weekData.length) {
            weekData.forEach((item, i) => {
                obj5 = { ...obj5, [i]: "week " + item._id };
                obj6 = { ...obj6, [i]: item.actualAmount };
                obj7 = { ...obj7, [i]: item.budgetAmount };
                obj8 = { ...obj8, [i]: item.budgetPerformance };
            })
            revenueData = [...revenueData, objWeek, obj5, obj6, obj7, obj8]
        }

        // month
        let objMonth = { titles: "" };
        let obj9 = { titles: "Month" };
        let obj10 = { titles: "Monthly Revenue – Actual $ Amount	" };
        let obj11 = { titles: "Monthly Revenue – Budget $ Amount	" };
        let obj12 = { titles: "Monthly Revenue Budget Performance +/-" };

        if (monthData.length) {
            monthData.forEach((item, i) => {
                obj9 = { ...obj9, [i]: getMonthName(item._id) };
                obj10 = { ...obj10, [i]: item.actualExpense };
                obj11 = { ...obj11, [i]: item.budgetExpense };
                obj12 = { ...obj12, [i]: item.budgetPerformance };
            })
            revenueData = [...revenueData, objMonth, obj9, obj10, obj11, obj12]
        }

        // Year
        let objYear = { titles: "" };
        let obj13 = { titles: "Year" };
        let obj14 = { titles: "Yearly Revenue – Actual $ Amount	" };
        let obj15 = { titles: "Yearly Revenue – Budget $ Amount	" };
        let obj16 = { titles: "Yearly Revenue Budget Performance +/-" };

        if (yearData.length) {
            yearData.forEach((item, i) => {
                obj13 = { ...obj13, [i]: item.year };
                obj14 = { ...obj14, [i]: item.actualSales };
                obj15 = { ...obj15, [i]: item.actualBudget };
                obj16 = { ...obj16, [i]: item.budgetpersormance };
            })
            revenueData = [...revenueData, objYear, obj13, obj14, obj15, obj16];
        }

        // console.log('revenueData', revenueData)

        setRevenueCsv({
            headers: revenueHeader,
            data: revenueData
        });

        getExcelData();
    }, [yearData, monthData, weekData, dayData, currentLanguage]);

    const getExcelData = () => {

        let myArray = [
            [language.report_generated_by, profileData?.name + " " + profileData?.surName],
            [language.report_generated_on, moment().format("YYYY-MM-DD")],
            [],
            [language.act_budget_rev]
        ];

        // day
        let arr1 = [language.daily];
        let arr2 = [isBusiness ? language.daily_rev_act_amt : language.daily_income_act_amt];
        let arr3 = [isBusiness ? language.daily_rev_budget_amt : language.daily_income_budget_amt];
        let arr4 = [isBusiness ? language.daily_rev_budget_perf : language.daily_income_budget_perf];
        dayData.forEach((item) => {
            arr1.push(moment(item._id).format("YYYY-MM-DD"));
            arr2.push(item.actualAmount);
            arr3.push(item.budgetAmount);
            arr4.push(item.budgetPerformance);
        })

        // week
        let arr5 = [language.weekly];
        let arr6 = [isBusiness ? language.weekly_rev_act_amt : language.weekly_income_act_amt];
        let arr7 = [isBusiness ? language.weekly_rev_budget_amt : language.weekly_income_budget_amt];
        let arr8 = [isBusiness ? language.weekly_rev_budget_perf : language.weekly_income_budget_perf];
        weekData.forEach((item) => {
            arr5.push(language.week + " " + item._id);
            arr6.push(item.actualAmount);
            arr7.push(item.budgetAmount);
            arr8.push(item.budgetPerformance);
        })

        // month
        let arr9 = [language.monthly];
        let arr10 = [isBusiness ? language.monthly_rev_act_amt : language.monthly_income_act_amt];
        let arr11 = [isBusiness ? language.monthly_rev_budget_amt : language.monthly_income_budget_amt];
        let arr12 = [isBusiness ? language.monthly_rev_budget_perf : language.monthly_income_budget_perf];
        monthData.forEach((item) => {
            arr9.push(getMonthName(item._id));
            arr10.push(item.actualExpense);
            arr11.push(item.budgetExpense);
            arr12.push(item.budgetPerformance);
        })

        // year
        let arr13 = [language.yearly];
        let arr14 = [isBusiness ? language.yearly_rev_act_amt : language.yearly_income_act_amt];
        let arr15 = [isBusiness ? language.yearly_rev_budget_amt : language.yearly_income_budget_amt];
        let arr16 = [isBusiness ? language.yearly_rev_budget_perf : language.yearly_income_budget_perf];
        yearData.forEach((item) => {
            arr13.push(item.year);
            arr14.push(item.actualSales);
            arr15.push(item.actualBudget);
            arr16.push(item.budgetpersormance);
        })

        myArray = [...myArray, arr1, arr2, arr3, arr4, [], arr5, arr6, arr7, arr8, [], arr9, arr10, arr11, arr12, [], arr13, arr14, arr15, arr16];

        setRevenueExcel(myArray);
    }

    return (
        <div>
            {/* Daily */}
            <h4 className="mt-4">{language.daily}</h4>
            <div className='table-responsive'>
                <table className="table table-striped gen-fin-table">
                    <thead>
                        <tr>
                            <th scope="col"></th>
                            {
                                pagiDayData.length
                                    ?
                                    pagiDayData.map((item, i) =>
                                        <th scope="col" key={i}>{moment(item._id).format("YYYY-MM-DD")}</th>
                                    )
                                    :
                                    // language.no_data
                                    ""
                            }
                        </tr>
                    </thead>
                    <tbody className="">
                        <tr>
                            <td className='report-first-column'>
                                {isBusiness ? language.daily_rev_act_amt : language.daily_income_act_amt}
                            </td>
                            {
                                pagiDayData.length
                                    ?
                                    pagiDayData.map((item, i) =>
                                        <td key={i}>{item.actualAmount?.toFixed(2)}</td>
                                    )
                                    :
                                    "-"
                            }
                        </tr>
                        <tr>
                            <td className='report-first-column'>
                                {isBusiness ? language.daily_rev_budget_amt : language.daily_income_budget_amt}
                            </td>
                            {
                                pagiDayData.length
                                    ?
                                    pagiDayData.map((item, i) =>
                                        <td key={i}>{item.budgetAmount?.toFixed(2)}</td>
                                    )
                                    :
                                    "-"
                            }
                        </tr>
                        <tr>
                            <td className='report-first-column'>
                                {isBusiness ? language.daily_rev_budget_perf : language.daily_income_budget_perf}
                            </td>
                            {
                                pagiDayData.length
                                    ?
                                    pagiDayData.map((item, i) =>
                                        <td key={i}>{item.budgetPerformance?.toFixed(2)}</td>
                                    )
                                    :
                                    "-"
                            }
                        </tr>
                    </tbody>
                </table>
            </div>
            <PaginationReport
                originalData={dayData}
                setPaginateData={setPagiDayData}
            />

            {/* Weekly */}
            <h4 className="mt-4">{language.weekly}</h4>
            <div className='table-responsive'>
                <table className="table table-striped gen-fin-table">
                    <thead>
                        <tr>
                            <th scope="col"></th>
                            {
                                pagiWeekData.length && pagiWeekData?.map((item, i) =>
                                    <th scope="col" key={i}>{language.week} {item._id}</th>
                                )
                            }
                        </tr>
                    </thead>
                    <tbody className="">
                        <tr>
                            <td className='report-first-column'>
                                {isBusiness ? language.weekly_rev_act_amt : language.weekly_income_act_amt}
                            </td>
                            {
                                pagiWeekData.length && pagiWeekData.map((item, i) =>
                                    <td key={i}>{item.actualAmount?.toFixed(2)}</td>
                                )
                            }
                        </tr>
                        <tr>
                            <td className='report-first-column'>
                                {isBusiness ? language.weekly_rev_budget_amt : language.weekly_income_budget_amt}
                            </td>
                            {
                                pagiWeekData.length && pagiWeekData.map((item, i) =>
                                    <td key={i}>{item.budgetAmount?.toFixed(2)}</td>
                                )
                            }
                        </tr>
                        <tr>
                            <td className='report-first-column'>
                                {isBusiness ? language.weekly_rev_budget_perf : language.weekly_income_budget_perf}
                            </td>
                            {
                                pagiWeekData.length && pagiWeekData.map((item, i) =>
                                    <td key={i}>{item.budgetPerformance?.toFixed(2)}</td>
                                )
                            }
                        </tr>
                    </tbody>
                </table>
            </div>
            <PaginationReport
                originalData={weekData}
                setPaginateData={setPagiWeekData}
            />

            {/* Monthly */}
            <h4 className="mt-4">{language.monthly}</h4>
            <div className='table-responsive'>
                <table className="table table-striped gen-fin-table">
                    <thead>
                        <tr>
                            <th scope="col"></th>
                            {
                                pagiMonthData?.map((item, i) =>
                                    <th scope="col" key={i}>{getMonthName(item._id)}</th>
                                )
                            }
                        </tr>
                    </thead>
                    <tbody className="">
                        <tr>
                            <td className='report-first-column'>
                                {isBusiness ? language.monthly_rev_act_amt : language.monthly_income_act_amt}
                            </td>
                            {
                                pagiMonthData?.map((item, i) =>
                                    <td key={i}>{item.actualExpense?.toFixed(2)}</td>
                                )
                            }
                        </tr>
                        <tr>
                            <td className='report-first-column'>
                                {isBusiness ? language.monthly_rev_budget_amt : language.monthly_income_budget_amt}
                            </td>
                            {
                                pagiMonthData?.map((item, i) =>
                                    <td key={i}>{item.budgetExpense?.toFixed(2)}</td>
                                )
                            }
                        </tr>
                        <tr>
                            <td className='report-first-column'>
                                {isBusiness ? language.monthly_rev_budget_perf : language.monthly_income_budget_perf}
                            </td>
                            {
                                pagiMonthData?.map((item, i) =>
                                    <td key={i}>{item.budgetPerformance?.toFixed(2)}</td>
                                )
                            }
                        </tr>
                    </tbody>
                </table>
            </div>
            <PaginationReport
                originalData={monthData}
                setPaginateData={setPagimonthData}
            />

            {/* Yearly */}
            <h4 className="mt-4">{language.yearly}</h4>
            <div className='table-responsive'>
                <table className="table table-striped gen-fin-table">
                    <thead>
                        <tr>
                            <th scope="col"></th>
                            {
                                pagiYearData.map((item, i) =>
                                    <th scope="col" key={i}>{item.year}</th>
                                )
                            }
                        </tr>
                    </thead>
                    <tbody className="">
                        <tr>
                            <td className='report-first-column'>
                                {isBusiness ? language.yearly_rev_act_amt : language.yearly_income_act_amt}
                            </td>
                            {
                                pagiYearData.map((item, i) =>
                                    <td>{item.actualSales?.toFixed(2)}</td>
                                )
                            }
                        </tr>
                        <tr>
                            <td className='report-first-column'>
                                {isBusiness ? language.yearly_rev_budget_amt : language.yearly_income_budget_amt}
                            </td>
                            {
                                pagiYearData.map((item, i) =>
                                    <td>{item.actualBudget?.toFixed(2)}</td>
                                )
                            }
                        </tr>
                        <tr>
                            <td className='report-first-column'>
                                {isBusiness ? language.yearly_rev_budget_perf : language.yearly_income_budget_perf}
                            </td>
                            {
                                pagiYearData.map((item, i) =>
                                    <td>{item.budgetpersormance?.toFixed(2)}</td>
                                )
                            }
                        </tr>
                    </tbody>
                </table>
            </div>
            <PaginationReport
                originalData={yearData}
                setPaginateData={setPagiYearData}
            />
        </div>
    )
}

export default BudgetRevenueTable;