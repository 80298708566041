import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import GoldHeader from "../../images/GoldHeader_600x300.png";
import GoldHeader2 from "../../images/GoldHeader2.png";
import dimondBg from '../../images/levelImgs/leyer1.png'
import platinamBg from '../../images/levelImgs/leyer2.png'
import Badgeimage from "../../images/GOLD-INSIGNIAS-BADGE_LEVEL1 copy.png";
import Badgefocus from "../../images/oro-icon.png";
import Fadebadge from "../../../src/images/platino-icon.png";
import Fadebadge2 from "../../../src/images/platino-icon2.png";
import { useLanguage } from '../../Providers/LanguageContext';
import HttpClient from '../../utils/HttpClient';
import { ProgressBar } from 'react-bootstrap';
// import outstanding from "../../images/levelImgs/AllBatch/GOLD-INSIGNIAS-BADGE_LEVEL1.png"
// import superior from "../../images/levelImgs/AllBatch/GOLD-INSIGNIAS-BADGE_LEVEL2.png"
// import phenomenal from "../../images/levelImgs/AllBatch/GOLD-INSIGNIAS-BADGE_LEVEL 3.png"
import { useRequestData } from '../../hooks/RequestDataHook';
import CustomModal from '../../Component/CustomModal';
import achievement_home_en1 from "../../Audio_files/VIII. Rewards Manager Audio/VIII. a. View My Achievements/viii_view_my_achievements_audio_a.mp3";
import achievement_home_en2 from "../../Audio_files/VIII. Rewards Manager Audio/VIII. a. View My Achievements/viii_view_my_achievements_audio_b.mp3";
import achievement_home_es1 from "../../spanish_audio_files/VIII. Rewards Manager Audio/VIII. a. View My Achievements/viii_view_my_achievements_audio_a_es.mp3";
import achievement_home_es2 from "../../spanish_audio_files/VIII. Rewards Manager Audio/VIII. a. View My Achievements/viii_view_my_achievements_audio_b_es.mp3"
import { SpeechContext } from '../../Providers/SpeechWrapper';
import oroIcon from '../../images/oro-icon.png';
import platinoIcon from '../../images/platino-icon.png';
import diamanteIcon from '../../images/diamante-icon.png';
import engGold from '../../images/AllBatch/gold.png';
import engdiamond from '../../images/AllBatch/diamond.png';
import engplatinam from '../../images/AllBatch/platinum.png';

import outstanding from "../../images/levelImgs/POINTSLEVELS/ENGLISH/GOLD-INSIGNIAS-BADGE_LEVEL1.png";
import superior from "../../images/levelImgs/POINTSLEVELS/ENGLISH/GOLD-INSIGNIAS-BADGE_LEVEL2.png";
import phenomenal from "../../images/levelImgs/POINTSLEVELS/ENGLISH/GOLD-INSIGNIAS-BADGE_LEVEL3.png";
import outstanding_es from "../../images/levelImgs/POINTSLEVELS/SPANISH/GOLD-INSIGNIAS-BADGE_LEVEL1.png";
import superior_es from "../../images/levelImgs/POINTSLEVELS/SPANISH/GOLD-INSIGNIAS-BADGE_LEVEL2.png";
import phenomenal_es from "../../images/levelImgs/POINTSLEVELS/SPANISH/GOLD-INSIGNIAS-BADGE_LEVEL3.png";

import brilliance from "../../images/levelImgs/POINTSLEVELS/ENGLISH/PLATINUM-INSIGNIAS-BADGE_LEVEL4.png";
import genius from "../../images/levelImgs/POINTSLEVELS/ENGLISH/PLATINUM-INSIGNIAS-BADGE_LEVEL5.png";
import brilliance_es from "../../images/levelImgs/POINTSLEVELS/SPANISH/PLATINUM-INSIGNIAS-BADGE_LEVEL4.png";
import genius_es from "../../images/levelImgs/POINTSLEVELS/SPANISH/PLATINUM-INSIGNIAS-BADGE_LEVEL5.png";

import worldClass from "../../images/levelImgs/POINTSLEVELS/ENGLISH/DIAMOND-INSIGNIAS-BADGE_LEVEL6.png";
import top1 from "../../images/levelImgs/POINTSLEVELS/ENGLISH/DIAMOND-INSIGNIAS-BADGE_LEVEL7.png";
import worldClass_es from "../../images/levelImgs/POINTSLEVELS/SPANISH/DIAMOND-INSIGNIAS-BADGE_LEVEL6.png";
import top1_es from "../../images/levelImgs/POINTSLEVELS/SPANISH/DIAMOND-INSIGNIAS-BADGE_LEVEL7.png";


function Index() {

    const { language, currentLanguage } = useLanguage()
    // console.log('currentLanguage', currentLanguage)
    const { currAudio, setCurrAudio, playing } = useContext(SpeechContext);
    const [isSubmitted, setIsSubmitted] = useState(false);

    const [totalRewards, setTotalRewards] = useState("");
    const [level, setLevel] = useState("");
    const [sublevel, setSublevel] = useState("");
    const [maxProgress, setMaxProgress] = useState(100);
    const [minProgress, setMinProgress] = useState(0);
    const [lebelImg, setLebelImg] = useState("");
    const [leftClass, setLeftClass] = useState("");
    console.log('totalRewardsRR', totalRewards)

    const [modalIsOpen, setIsOpen] = useState(false);

    const [modalOpen, setModalOpen] = useState(false);

    function openModal() {
        setIsOpen(true);
        setIsSubmitted(true)
    }


    function closeModal() {
        setIsOpen(false);
        setIsSubmitted(false)
    }
    const { data, loading, error } = useRequestData("get-reward-history", "GET", {})

    const fetchTotalRewards = async () => {
        const res = await HttpClient.requestData("reward-manager", "GET", {});
        console.log(res, "Response reward")
        if (res && res.status) {
            setTotalRewards(res.data);
            const num = Number(res.data)
            if (num >= 0 && num <= 399) {
                setLevel(language.gold);
                setLebelImg("gold");
                setLeftClass("retos-left");
                if (num >= 0 && num <= 199) {
                    setSublevel(language.outstanding)
                    setMinProgress(0)
                    setMaxProgress(199)
                } else if (num >= 200 && num <= 299) {
                    setMinProgress(200)
                    setMaxProgress(299)
                    setSublevel("SUPERIOR")
                } else if (num >= 300 && num <= 399) {
                    setMinProgress(300)
                    setMaxProgress(399)
                    setSublevel(language.phenomenal)
                }
            } else if (num >= 400 && num <= 599) {
                setLevel(language.platinum);
                setLebelImg("platinum");
                setLeftClass("retos-left-platinam")
                if (num >= 400 && num <= 499) {
                    setMinProgress(400);
                    setMaxProgress(499);
                    setSublevel(language.brilliance)
                } else if (num >= 500 && num <= 599) {
                    setMinProgress(500)
                    setMaxProgress(599)
                    setSublevel(language.genius)
                }
            } else if (num >= 600) {
                setLevel(language.diamond);
                setLebelImg("diamond");
                setLeftClass("retos-left-diamond");
                if (num >= 600 && num <= 699) {
                    setMinProgress(600);
                    setMaxProgress(699)
                    setSublevel(language.world_class)
                } else if (num >= 700) {
                    setMinProgress(700);
                    setMaxProgress(5000)
                    setSublevel(language.top1)
                }
            }
        }
    }

    useEffect(() => {
        fetchTotalRewards()
    }, [currentLanguage])


    const colorMemo = useMemo(() => {
        if (lebelImg === "gold") {
            return "#8c5711"
        } else if (lebelImg === "diamond") {
            return "#0D2537"
        } else
            return "#868686"
    }, [lebelImg])


    const renderMananger = (val, point) => {
        let text;
        switch (val.trim()) {
            case "CIPBITS Views":
                text = <div className='d-flex justify-content-between'>
                    <span>{language.cipbit_views}</span>
                    <span>+{point}</span>
                </div>;
                break;
            case "Dashboard Views":
                text = <div className='d-flex justify-content-between'>
                    <span>{language.dashboard_views}</span>
                    <span>+{point}</span>
                </div>;
                break;
            case "1x Per Week App Usage":
                text = <div className='d-flex justify-content-between'>
                    <span>{language.perweek_1x_app_usage}</span>
                    <span>+{point}</span>
                </div>;
                break;
            case "Income Budget Creation":
                text = <div className='d-flex justify-content-between'>
                    <span>{language.income_budget_creation}</span>
                    <span>+{point}</span>
                </div>;
                break;
            case "10x for weekly App View Streak":
                text = <div className='d-flex justify-content-between'>
                    <span>{language.weekly_app_streak_10x}</span>
                    <span>+{point}</span>
                </div>;
                break;
            case "Expense Budget Creation":
                text = <div className='d-flex justify-content-between'>
                    <span>{language.expense_budget_creation}</span>
                    <span>+{point}</span>
                </div>;
                break;
            case "Profit Budget Creation":
                text = <div className='d-flex justify-content-between'>
                    <span>{language.profit_budget_creation}</span>
                    <span>+{point}</span>
                </div>;
                break;
            case "Income Budget Creation":
                text = <div className='d-flex justify-content-between'>
                    <span>{language.income_budget_creation}</span>
                    <span>+{point}</span>
                </div>;
                break;
            case "5x Per Week App Usage":
                text = <div className='d-flex justify-content-between'>
                    <span>{language.app_usage_5x}</span>
                    <span>+{point}</span>
                </div>;
                break;
            case "3x Per Week App Usage":
                text = <div className='d-flex justify-content-between'>
                    <span>{language.app_usage_3x}</span>
                    <span>+{point}</span>
                </div>;
                break;
            default:
                text = "";
        }

        return text;
    }

    useEffect(() => {
        if (playing) {
            if (currAudio) {
                if (!currAudio.paused) {
                    currAudio.pause()
                }
            }
            if (currentLanguage === "eng") {
                setCurrAudio(new Audio(achievement_home_en1))
            } else {
                setCurrAudio(new Audio(achievement_home_es1))
            }
        } else {
            if (currAudio) {
                if (!currAudio.paused) {
                    currAudio.pause()
                    setCurrAudio(null)
                }
            }
        }

        return () => {
            if (currAudio && !currAudio.paused) {
                currAudio.pause()
            }
        }
    }, [playing, currentLanguage])


    // useEffect(() => {
    //     console.log("currAudio", currAudio)
    //     if (currAudio) {
    //         if (!currAudio.paused) {
    //             currAudio.pause()
    //         }
    //         currAudio.play()
    //     }
    //     return () => {
    //         if (currAudio && !currAudio.paused) {
    //             currAudio.pause()
    //         }
    //     }
    // }, [currAudio])

    useEffect(() => {
        console.log(isSubmitted, "")
        if (playing) {
            if (isSubmitted) {
                if (currentLanguage === "eng") {
                    setCurrAudio(new Audio(achievement_home_en2))
                } else {
                    setCurrAudio(new Audio(achievement_home_es2))
                }
            } else {
                if (currAudio && !currAudio.paused) {
                    currAudio.pause()
                }
            }
        }
        return () => {
            if (currAudio && !currAudio.paused) {
                currAudio.pause()
            }
        }
    }, [isSubmitted])



    return (
        <>
            <section id="retos">
                <div className='retos-container'>
                    <div className='row'>
                        <div className='col-xl-4 col-lg-4 col-md-12 col-12' style={{ padding: "0" }}>
                            <div className={leftClass}>
                                <div className='heading'>
                                    <h2>{language.retos}</h2>
                                </div>
                                <div className='evenbg' style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "0 40px" }}>
                                    <p>{language.use_app_once_week}</p>
                                    <h6>+1</h6>
                                </div>
                                <div className='oddbg' style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "0 40px" }}>
                                    <p>{language.use_app_three_times_week}</p>
                                    <h6>+5</h6>
                                </div>
                                <div className='evenbg' style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "0 40px" }}>
                                    <p>{language.use_app_five_times_week}</p>
                                    <h6>+10</h6>
                                </div>
                                <div className='oddbg' style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "0 40px" }}>
                                    <p>{language.weekly_strak_every_day}</p>
                                    <h6>+10</h6>
                                </div>
                                <div className='evenbg' style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "0 40px" }}>
                                    <p>{language.create_income_budget}</p>
                                    <h6>+50</h6>
                                </div>
                                <div className='oddbg' style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "0 40px" }}>
                                    <p>{language.create_expense_budget}</p>
                                    <h6>+50</h6>
                                </div>
                                <div className='evenbg' style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "0 40px" }}>
                                    <p>{language.dashboard_view}</p>
                                    <h6>+1</h6>
                                </div>
                                <div className='oddbg' style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "0 40px" }}>
                                    <p>{language.cipbits_view}</p>
                                    <h6>+2</h6>
                                </div>
                                <div className='evenbg' style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "0 40px" }}>
                                    <p>{language.post_expense_ai}</p>
                                    <h6>+5</h6>
                                </div>
                                <div className='oddbg' style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "0 40px" }}>
                                    <p>{language.invite}</p>
                                    <h6>+25</h6>
                                </div>
                                <div className='evenbg' style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "0 40px" }}>
                                    <p>{language.invite_sub}</p>
                                    <h6>+10</h6>
                                </div>

                                <div className='oddbg' style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "0 40px" }}>
                                    <p>{language.config_recurr}</p>
                                    <h6>+20</h6>
                                </div>
                            </div>
                        </div>
                        <div className='col-xl-8 col-lg-8 col-md-12 col-12' style={{ padding: "0" }}>
                            <div className='retos-right'>
                                <div className={leftClass}>
                                    <div className='retos-bg'>
                                        {
                                            lebelImg === "gold" &&
                                            <img className="img-fluid" src={GoldHeader} alt="img" />
                                        }
                                        {
                                            lebelImg === "platinum" &&
                                            <img className="img-fluid" src={platinamBg} alt="img" style={{ height: "" }} />
                                        }
                                        {
                                            lebelImg === "diamond" &&
                                            <img className="img-fluid" src={dimondBg} alt="img" />
                                        }
                                    </div>
                                    <div className='result'>
                                        <div className='heading'>
                                            <h4>{language.financial_achievements}</h4>
                                        </div>
                                        <div className='result-cnt'>
                                            <div>
                                                <h3>{level}</h3>
                                                <h2>{totalRewards || 0}</h2>
                                                <div className='result-bar'>
                                                    <div className='d-flex'>
                                                        <div className="progress">
                                                            {/* <div
                                                            className="progress-bar"
                                                            role="progressbar"
                                                            style={{ width: "25%" }}
                                                            aria-valuenow={"55"}
                                                            aria-valuemin={"0"}
                                                            aria-valuemax={"100"}
                                                        /> */}
                                                            <ProgressBar variant="success" now={totalRewards} min={minProgress} max={maxProgress} />
                                                        </div>
                                                        <span className='rating-number'>{totalRewards} /{maxProgress}</span>
                                                    </div>
                                                </div>
                                                <h4 className='outstanding'>{sublevel}</h4>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        currentLanguage === 'spanish'
                                            ?
                                            <>
                                                {
                                                    lebelImg === "gold" &&
                                                    <div className='retos-btm'>
                                                        <div className='review-images'>
                                                            <a href='#' className='badge-image'>
                                                                <img className="img-fluid" src={outstanding_es} alt="image" />
                                                            </a>
                                                            <a href='#' className='badge-image2' style={{ opacity: totalRewards <= 199 ? "0.5" : "" }}>
                                                                <img className="img-fluid" src={superior_es} alt="image" />
                                                            </a>
                                                            <a href='#' className='badge-image3' style={{ opacity: totalRewards <= 299 ? "0.5" : "" }}>
                                                                <img className="img-fluid" src={phenomenal_es} alt="image" />
                                                            </a>
                                                            {/* <a href='#' className='badge-focus'><img className="img-fluid" src={Badgefocus} alt="image"/></a> */}
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    lebelImg === "platinum" &&
                                                    <div className='retos-btm'>
                                                        <div className='review-images'>
                                                            <a href='#' className='badge-image' style={{ opacity: totalRewards <= 399 ? "0.5" : "" }}>
                                                                <img className="img-fluid" src={brilliance_es} alt="image" />
                                                            </a>
                                                            <a href='#' className='badge-image2' style={{ opacity: totalRewards <= 499 ? "0.5" : "" }}>
                                                                <img className="img-fluid" src={genius_es} alt="image" />
                                                            </a>
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    lebelImg === "diamond" &&
                                                    <div className='retos-btm'>
                                                        <div className='review-images'>
                                                            <a href='#' className='badge-image' style={{ opacity: totalRewards <= 599 ? "0.5" : "" }}>
                                                                <img className="img-fluid" src={worldClass_es} alt="image" />
                                                            </a>
                                                            <a href='#' className='badge-image2' style={{ opacity: totalRewards <= 699 ? "0.5" : "" }}>
                                                                <img className="img-fluid" src={top1_es} alt="image" />
                                                            </a>
                                                        </div>
                                                    </div>
                                                }
                                            </>
                                            :
                                            <>
                                                {
                                                    lebelImg === "gold" &&
                                                    <div className='retos-btm'>
                                                        <div className='review-images'>
                                                            <a href='#' className='badge-image4' >
                                                                <img className="img-fluid" src={outstanding} alt="image" />
                                                            </a>
                                                            <a href='#' className='badge-image4' style={{ opacity: totalRewards <= 199 ? "0.5" : "" }}>
                                                                <img className="img-fluid" src={superior} alt="image" />
                                                            </a>
                                                            <a href='#' className='badge-image4' style={{ opacity: totalRewards <= 299 ? "0.5" : "" }}>
                                                                <img className="img-fluid" src={phenomenal} alt="image" />
                                                            </a>
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    lebelImg === "platinum" &&
                                                    <div className='retos-btm'>
                                                        <div className='review-images'>
                                                            <a href='#' className='badge-image4'>
                                                                <img className="img-fluid" src={brilliance} alt="image" style={{ opacity: totalRewards <= 399 ? "0.5" : "" }} />
                                                            </a>
                                                            <a href='#' className='badge-image4'>
                                                                <img className="img-fluid" src={genius} alt="image" style={{ opacity: totalRewards <= 499 ? "0.5" : "" }} />
                                                            </a>
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    lebelImg === "diamond" &&
                                                    <div className='retos-btm'>
                                                        <div className='review-images'>
                                                            <a href='#' className='badge-image4'>
                                                                <img className="img-fluid" src={worldClass} alt="image" style={{ opacity: totalRewards <= 599 ? "0.5" : "" }} />
                                                            </a>
                                                            <a href='#' className='badge-image4'>
                                                                <img className="img-fluid" src={top1} alt="image" style={{ opacity: totalRewards <= 699 ? "0.5" : "" }} />
                                                            </a>
                                                        </div>
                                                    </div>
                                                }
                                            </>
                                    }
                                </div>
                            </div>
                            <div className='historial'>
                                <div className={leftClass}>
                                    <div className='heading'>
                                        <h2>{language.history}</h2>
                                        {
                                            data && data.slice(0, 3).map((manager, i) => (
                                                <div className={i % 2 === 0 ? "non-bg-text" : "bg-text"} style={i % 2 === 0 ? {} : { backgroundColor: "#f2f2f2", width: "100%" }}>
                                                    <p>{renderMananger(manager.achievementReason, manager.rewardsPoint)}</p>
                                                </div>
                                            ))
                                        }
                                        {/* <div className='bg-text' style={{ backgroundColor: "#f2f2f2", width: "100%" }}>
                                        <p>Uso de la app una vez por semana</p>
                                    </div>
                                    <div className='non-bg-text'>
                                        <p>Postear un gasto con la Inteligencia Artificial</p>
                                    </div>
                                    <div className='bg-text' style={{ backgroundColor: "#f2f2f2", width: "100%" }}>
                                        <p>Vista del panel de control</p>
                                    </div> */}

                                        <div className='historical-btm'>
                                            <button
                                                style={{ backgroundColor: colorMemo }}
                                                type="submit"
                                                className='reset-btn'
                                                onClick={openModal}
                                            >
                                                {language.see_more}
                                            </button>
                                            <p>{language.point_reset}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <CustomModal
                modalIsOpen={modalIsOpen}
                setIsOpen={setIsOpen}
                openModal={openModal}
                closeModal={closeModal}
            >
                <div className='historial'>
                    <div className='heading'>
                        <h2>{language.historical}</h2>
                        {
                            data && data.map((manager, i) => (
                                <div className={i % 2 === 0 ? "non-bg-text" : "bg-text"} style={i % 2 === 0 ? {} : { backgroundColor: "#f2f2f2", width: "100%" }}>
                                    <p>{renderMananger(manager.achievementReason, manager.rewardsPoint)}</p>
                                </div>
                            ))
                        }
                        {/* <div className='bg-text' style={{ backgroundColor: "#f2f2f2", width: "100%" }}>
                                        <p>Uso de la app una vez por semana</p>
                                    </div>
                                    <div className='non-bg-text'>
                                        <p>Postear un gasto con la Inteligencia Artificial</p>
                                    </div>
                                    <div className='bg-text' style={{ backgroundColor: "#f2f2f2", width: "100%" }}>
                                        <p>Vista del panel de control</p>
                                    </div> */}

                    </div>
                </div>
            </CustomModal>

        </>


    )
}


export default Index