
import React, { useState, useEffect } from 'react';
import HttpClient from '../../utils/HttpClient';
import toast from 'react-hot-toast';
import { useUserData } from '../../Providers/UserWrapper';

const ManageOffers = ({ closeModal, singleUserData, getLinkedAccData }) => {
    const { setIsLoading } = useUserData();
    const [selectedRows, setSelectedRows] = useState([]);
    const [activeTab, setActiveTab] = useState('add');

    const initValue = {
        investmentType: singleUserData?.capitalType === "Debt Capital Raise" ? "Debt" : "Equity",
        investmentAmmount: "",
        interestPercentage: "",
        equity: "",
        requstedAmmount: singleUserData?.reqAndDebtAmmount,
        raiseCapitalId: singleUserData?._id,
        raiseCapitalType: singleUserData?.capitalType,
        userRegCode: singleUserData?.userBusniessRegcode
    };

    const initData = {
        investmentType: singleUserData?.capitalType === "Debt Capital Raise" ? "Debt" : "Equity",
        investmentAmmount: "",
        interestPercentage: "",
        equity: "",
        requstedAmmount: singleUserData?.reqAndDebtAmmount,
        raiseCapitalId: singleUserData?._id,
        raiseCapitalType: singleUserData?.capitalType,
        userRegCode: singleUserData?.userBusniessRegcode
    }

    const [formValue, setFormValue] = useState([initValue]);
    const [addInput, setAddInput] = useState([initData])

    const handleChange = (e, ind) => {
        const { name, value } = e.target;
        let newArr = [...addInput];
        newArr[ind][name] = value;
        setAddInput(newArr);
    };

    const handleChangeViewInput = (e, ind) => {
        const { name, value } = e.target;
        let newArr = [...formValue];
        newArr[ind][name] = value
        setFormValue(newArr)
    }

    const handleSendOffer = async () => {
        const data = { data: addInput };
        setIsLoading(true);
        closeModal();
        const res = await HttpClient.requestData("investor/add-investor-investment-capital", "POST", data);
        setIsLoading(false);
        if (res && res?.status) {
            toast.success("Offer Sent Successfully!");
            getLinkedAccData();
        } else {
            toast.error(res?.message || "Error");
        }
    };

    const handleUpdateOffer = async () => {
        const selectedData = formValue.filter((_, index) => selectedRows.includes(index));
        if (selectedData?.length === 0) {
            return toast.error("Please select a data...!");
        } else {
            const newData = selectedData.map(item => ({
                offerId: item.id,
                investmentAmount: item.investmentAmmount,
                interestPercentage: item?.interestPercentage,
                equity: item?.equity
            }));

            const data = { updates: newData };

            const res = await HttpClient.requestData("investor/update-offer-value", "POST", data);
            if (res && res?.status) {
                toast.success(res?.message || "Investment amounts updated successfully...!");
                setSelectedRows([]);
            } else {
                return toast.error(res?.message || "Something went wrong...!");
            }
        }
    };

    const handlePauseStatus = async () => {
        const selectedData = formValue.filter((_, index) => selectedRows.includes(index));
        if (selectedData?.length === 0) {
            return toast.error("Please select a data...!");
        } else {
            const newData = selectedData.map(item => ({ offerId: item.id }));

            const data = { updates: newData };
            const res = await HttpClient.requestData("investor/update-offer-pause-status", "POST", data);
            if (res && res?.status) {
                toast.success(res?.message || "Investment status updated successfully...!");
                setSelectedRows([]);
            } else {
                return toast.error(res?.message || "Something went wrong...!");
            }
        }
    };

    const handleRejectStatus = async () => {
        const selectedData = formValue.filter((_, index) => selectedRows.includes(index));
        if (selectedData?.length === 0) {
            return toast.error("Please select a data...!");
        } else {
            const newData = selectedData.map(item => ({ offerId: item.id }));

            const data = { updates: newData };
            const res = await HttpClient.requestData("investor/update-offer-reject-status", "POST", data);
            if (res && res?.status) {
                toast.success(res?.message || "Investment status updated successfully...!");
                setSelectedRows([]);
            } else {
                return toast.error(res?.message || "Something went wrong...!");
            }
        }
    };

    useEffect(() => {
        if (singleUserData?.userBusinessDetails?.length > 0) {
            let data = singleUserData?.userBusinessDetails.map(element => ({
                id: element?._id,
                investmentType: singleUserData?.capitalType === "Debt Capital Raise" ? "Debt" : "Equity",
                investmentAmmount: element.investmentAmmount,
                interestPercentage: element.interestPercentage,
                equity: element.equity,
                requstedAmmount: singleUserData?.reqAndDebtAmmount,
                raiseCapitalId: singleUserData?._id,
                raiseCapitalType: singleUserData?.capitalType,
                userRegCode: singleUserData?.userBusniessRegcode,
                acceptStatus: element.acceptStatus
            }));
            setFormValue(data);
        }
    }, [singleUserData]);

    return (
        <div>
            {/* Tabs */}
            <div className="tabs d-flex">
                <button
                    className={activeTab === 'add' ? 'active btn' : 'btn'}
                    onClick={() => setActiveTab('add')}
                    style={{ fontWeight: "bold" }}
                >
                    Add Offer
                </button>
                <div style={{ marginLeft: "15px", width: "2px", height: "50px", backgroundColor: "#0E2537" }}></div>
                <button
                    className={activeTab === 'view' ? 'active btn mx-3' : 'btn mx-2'}
                    onClick={() => setActiveTab('view')}
                    style={{ fontWeight: "bold" }}
                >
                    View Offers
                </button>
            </div>
            <hr />
            {/* Add Offer Tab */}
            {activeTab === 'add' && (
                <div>
                    <h4 className='bookSecModalHead'>Setup & Manage Documents</h4>
                    <button
                        className='bookmodalAddBtn'
                        onClick={() => setAddInput(prev => [initData, ...prev])}
                    >
                        <i className="fa-solid fa-plus"></i>ADD
                    </button>

                    <div style={{ height: "300px", overflow: "auto" }}>
                        <table className="buildCapitalTable mt-3 mb-5">
                            <thead>
                                <tr>
                                    <th className="column-padding">Investment Type</th>
                                    <th className="column-padding">Investment Amount</th>
                                    <th className="column-padding">Interest %</th>
                                    <th className="column-padding">Equity %</th>
                                    <th className="column-padding">Requested Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                {addInput?.map((item, i) => (
                                    <tr key={i}>
                                        <td className="column-padding">
                                            <div className='d-flex'>
                                                <span
                                                    className='mt-2 setDocManageTrushBtn'
                                                    onClick={() => {
                                                        setAddInput(prev => prev.filter((ele, ind) => i !== ind))
                                                    }}
                                                >
                                                    <i class="fa-solid fa-trash"></i>
                                                </span>
                                                <input
                                                    className='bookLinkTableInput'
                                                    type="text"
                                                    name="investmentType"
                                                    value={item.investmentType}
                                                    onChange={(e) => handleChange(e, i)}
                                                />
                                            </div>
                                        </td>
                                        <td className="column-padding">
                                            <input
                                                className='bookLinkTableInput'
                                                type="number"
                                                name="investmentAmmount"
                                                value={item.investmentAmmount}
                                                onChange={(e) => handleChange(e, i)}
                                            />
                                        </td>
                                        <td className="column-padding">
                                            <input
                                                className='bookLinkTableInput'
                                                type="number"
                                                name="interestPercentage"
                                                value={item.interestPercentage}
                                                onChange={(e) => handleChange(e, i)}
                                            />
                                        </td>
                                        <td className="column-padding">
                                            <input
                                                className='bookLinkTableInput'
                                                type="number"
                                                name="equity"
                                                value={item.equity}
                                                onChange={(e) => handleChange(e, i)}
                                            />
                                        </td>
                                        <td className="column-padding">
                                            <input
                                                className='bookLinkTableInput'
                                                type="number"
                                                name="requstedAmmount"
                                                value={item.requstedAmmount}
                                                onChange={(e) => handleChange(e, i)}
                                                readOnly
                                            />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div style={{ textAlign: "center" }}>
                        <button className='bookmodalSaveBtn btn btn-outline-success'
                            style={{ fontWeight: "bold" }}
                            onClick={handleSendOffer}
                        >
                            Add Offer
                        </button>
                    </div>
                </div>
            )}

            {/* View Offers Tab */}
            {activeTab === 'view' && (
                <div>
                    <h4 className='bookSecModalHead'>Setup & Manage Documents</h4>

                    <div style={{ height: "300px", overflow: "auto" }}>
                        <table className="buildCapitalTable mt-3 mb-5">
                            <thead>
                                <tr>
                                    <th className="column-padding">
                                        <input
                                            type="checkbox"
                                            checked={selectedRows.length === formValue.length}
                                            onChange={(e) => setSelectedRows(e.target.checked ? formValue.map((_, index) => index) : [])}
                                        />
                                    </th>
                                    <th className="column-padding">Investment Type</th>
                                    <th className="column-padding">Investment Amount</th>
                                    <th className="column-padding">Interest %</th>
                                    <th className="column-padding">Equity %</th>
                                    <th className="column-padding">Requested Amount</th>
                                    <th className="column-padding">Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {formValue?.map((item, i) => (
                                    <tr key={i}>
                                        <td className="column-padding">
                                            <input
                                                type="checkbox"
                                                checked={selectedRows.includes(i)}
                                                onChange={(e) => {
                                                    const isChecked = e.target.checked;
                                                    if (isChecked) {
                                                        setSelectedRows((prev) => [...prev, i]);
                                                    } else {
                                                        setSelectedRows((prev) => prev.filter((rowIndex) => rowIndex !== i));
                                                    }
                                                }}
                                            />
                                        </td>
                                        <td className="column-padding">
                                            <input
                                                className='bookLinkTableInput'
                                                type="text"
                                                name="investmentType"
                                                value={item.investmentType}
                                                onChange={(e) => handleChangeViewInput(e, i)}
                                                readOnly
                                            />
                                        </td>
                                        <td className="column-padding">
                                            <input
                                                className='bookLinkTableInput'
                                                type="number"
                                                name="investmentAmmount"
                                                value={item.investmentAmmount}
                                                onChange={(e) => handleChangeViewInput(e, i)}
                                            />
                                        </td>
                                        <td className="column-padding">
                                            <input
                                                className='bookLinkTableInput'
                                                type="number"
                                                name="interestPercentage"
                                                value={item.interestPercentage}
                                                onChange={(e) => handleChangeViewInput(e, i)}
                                            />
                                        </td>
                                        <td className="column-padding">
                                            <input
                                                className='bookLinkTableInput'
                                                type="number"
                                                name="equity"
                                                value={item.equity}
                                                onChange={(e) => handleChangeViewInput(e, i)}
                                            />
                                        </td>
                                        <td className="column-padding">
                                            <input
                                                className='bookLinkTableInput'
                                                type="number"
                                                name="requstedAmmount"
                                                value={item.requstedAmmount}
                                                onChange={(e) => handleChangeViewInput(e, i)}
                                                readOnly
                                            />
                                        </td>
                                        <td className="column-padding">
                                            <input
                                                className='bookLinkTableInput'
                                                type="text"
                                                value={item.acceptStatus === "accepted" ? "Accepted" : item.acceptStatus === "pending" ? "Pending" : item.acceptStatus === "Paused" ? "Paused" : item.acceptStatus === "Rejected" ? "Rejected" : ""}
                                                disabled
                                                style={{
                                                    color: item.acceptStatus === "accepted" ? "green" :
                                                        item.acceptStatus === "pending" ? "#9E950D" :
                                                            item.acceptStatus === "Paused" ? "#9E950D" :
                                                                item.acceptStatus === "Rejected" ? "red" : "",
                                                    fontWeight: "bold"
                                                }}
                                            />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                    <div style={{ textAlign: "center" }}>
                        <button className='bookmodalSaveBtn btn btn-outline-primary' style={{ fontWeight: "bold" }} onClick={handleUpdateOffer}>
                            Update Offer
                        </button>
                        <button className='bookmodalPauseBtn btn btn-outline-warning mx-3' style={{ fontWeight: "bold" }} onClick={handlePauseStatus}>
                            Pause Offer
                        </button>
                        <button className='bookmodalRejectBtn btn btn-outline-danger' style={{ fontWeight: "bold" }} onClick={handleRejectStatus}>
                            Reject Offer
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ManageOffers;
