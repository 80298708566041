import React from 'react'
import { ThreeDots } from 'react-loader-spinner'

const CustomLoaderLine = (props) => {
    const {
        height = "50",
        width = "50",
        margin = "",
        color = "#194860"
    } = props;

    return (
        <div style={{ display: "flex", justifyContent: "center", margin: { margin } }}>
            <ThreeDots
                height={height}
                width={width}
                radius="9"
                color={color}
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
            />
        </div>
    )
}

export default CustomLoaderLine;
