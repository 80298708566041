import React, { useEffect, useState } from 'react'
import ReactApexChart from 'react-apexcharts'
import { useLanguage } from '../../../Providers/LanguageContext'
import { useNavigate } from 'react-router-dom'
import HttpClient from '../../../utils/HttpClient'
import { useUserData } from '../../../Providers/UserWrapper'

function DashboardChartOne({ data, time, userType, userCode }) {
    const { language } = useLanguage();
    const { isBusiness, isInvestor, isBookKeeper } = useUserData();
    // console.log('tataTime', data, time)

    const navigate = useNavigate();

    // console.log(data[0], data[1], data[2], "Dataaa")

    const [hoverData, setHoverData] = useState({
        revenue: {},
        expense: {},
        profit: {}
    })

    const [barIndex, setBarIndex] = useState("");

    const [text, setText] = useState(() => {
        if (time === "day") {
            return language.current_day_total
        } else if (time === "week") {
            return language.current_week_total
        } else if (time === "month") {
            return language.current_month_total
        } else if (time === "year") {
            return language.current_year_total
        }
    })

    const [series, setSeries] = useState([{
        name: text,
        data: [data[0], data[1], data[2]]
    }])

    const [options, setOptions] = useState({
        chart: {
            type: 'bar',
            height: "100%",
            events: {
                dataPointSelection: (event, chartContext, { dataPointIndex }) => {
                    // Handle bar click event here
                    console.log(`Bar ${dataPointIndex + 1} clicked`);
                },
            },
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '30%',
                endingShape: 'rounded',
                distributed: true,
                dataLabels: {
                    position: 'top', // top, center, bottom
                },
            },

        },
        title: {
            text: text,
            align: 'left',
            margin: 10,
            offsetX: 0,
            offsetY: 0,
            floating: false,
            style: {
                fontSize: '14px',
                fontWeight: 'bold',
                fontFamily: undefined,
                color: '#263238'
            },
        },
        dataLabels: {
            enabled: true,
            formatter: function (val) {
                return "$ " + Number(val).toLocaleString();
            },
            offsetY: -20,
            style: {
                fontSize: "12px",
                colors: ["#304758"]
            }
        },
        stroke: {
            show: true,
            width: -2,
            colors: ['transparent']
        },
        xaxis: {
            categories: userType && userType === 'Business'
                ?
                [language.revenue, language.expense, language.profit]
                :
                [language.income, language.expense, language.expense_budget],
            position: "bottom",
            labels: {
                offsetX: 0
            },
        },

        fill: {
            opacity: 1,
            // gradient: {
            //     shade: "light",
            //     type: "horizontal",
            //     shadeIntensity: 0.25,
            //     gradientToColors: undefined,
            //     inverseColors: true,
            //     opacityFrom: 1,
            //     opacityTo: 1,
            //     stops: [50, 0, 100, 100]
            // }
        },
        colors: ["#3b3425", "#735a24", "#918b7e"],
        legend: {
            show: true,
            showForSingleSeries: false,
            showForNullSeries: true,
            showForZeroSeries: true,
            position: 'bottom',
            horizontalAlign: 'center',
            floating: false,
            fontSize: '14px',
            fontFamily: 'Helvetica, Arial',
            fontWeight: 400,
            formatter: undefined,
            inverseOrder: false,
            width: undefined,
            height: undefined,
            tooltipHoverFormatter: undefined,
            customLegendItems: [],
            offsetX: 0,
            offsetY: 0,
            labels: {
                colors: undefined,
                useSeriesColors: false
            },
            markers: {
                width: 12,
                height: 12,
                strokeWidth: 0,
                strokeColor: '#fff',
                fillColors: undefined,
                radius: 12,
                customHTML: undefined,
                onClick: undefined,
                offsetX: 0,
                offsetY: 0
            },
            itemMargin: {
                horizontal: 5,
                vertical: 0
            },
            onItemClick: {
                toggleDataSeries: true
            },
            onItemHover: {
                highlightDataSeries: true
            },
        }
    }
    )

    // getting the label text
    const getLabelTextData = () => {
        if (time === "day") {
            setText(language.current_day_total)
        } else if (time === "week") {
            setText(language.current_week_total)
        } else if (time === "month") {
            setText(language.current_month_total)
        } else if (time === "year") {
            setText(language.current_year_total)
        }
    }

    // gettinh hover data
    const getHoverData = async () => {
        const data = {
            "dayType": time
        }

        // revenue hover
        const res = await HttpClient.requestData(isInvestor || isBookKeeper ? `investor/dashboard-one-hover-revenue/${userCode}` : isBusiness ? "dashboard-one-hover-revenue" : "dashboard-one-hover-revenue-pu", "POST", data);
        if (res && res?.status) {
            setHoverData(prev => ({ ...prev, revenue: res?.data }));
        } else {

        }

        // expense hover
        const res1 = await HttpClient.requestData(isInvestor || isBookKeeper ? `investor/dashboard-one-hover-expense/${userCode}` : "dashboard-one-hover-expense", "POST", data);
        if (res1 && res1?.status) {
            setHoverData(prev => ({ ...prev, expense: res1?.data }))
        } else {

        }

        // profit hover
        const res2 = await HttpClient.requestData(isInvestor || isBookKeeper ? `investor/dashboard-one-hover-profit/${userCode}` : isBusiness ? "dashboard-one-hover-profit" : "dashboard-one-hover-profit-pu", "POST", data);
        if (res2 && res2?.status) {
            setHoverData(prev => ({ ...prev, profit: res2?.data }))
        } else {

        }
    }

    // update the state of option
    useEffect(() => {
        if (data.length > 0) {
            setSeries([{
                name: text,
                // data: [-300, 200, -500]
                data: [data[0], data[1], data[2]]
            }])

            setOptions({
                chart: {
                    type: 'bar',
                    height: "100%",
                    events: {
                        dataPointSelection: (event, chartContext, { dataPointIndex }) => {
                            // Handle bar click event here
                            console.log(`Bar_clicked`, dataPointIndex, event);
                            if (dataPointIndex == 0) {
                                // navigate("/editexistingrevenue", {
                                navigate("/view_reports", {
                                    state: {
                                        reportType: "out",
                                        reportName: "Transaction",
                                        time: time
                                    }
                                });
                            } else if (dataPointIndex == 1) {
                                // navigate("/expense-datewise", {
                                navigate("/view_reports", {
                                    state: {
                                        reportType: "out",
                                        reportName: "ExpenseDetail",
                                        time: time
                                    }
                                });
                            } else {
                                // navigate("/customer-vendor-datewise", {
                                navigate("/view_reports", {
                                    state: {
                                        reportType: "self",
                                        reportName: "Profitability",
                                        time: time
                                    }
                                });
                            }
                        },
                    },
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: '30%',
                        endingShape: 'rounded',
                        distributed: true,
                        dataLabels: {
                            position: 'top', // top, center, bottom
                        },
                    },
                },
                title: {
                    text: text,
                    align: 'left',
                    margin: 10,
                    offsetX: 0,
                    offsetY: 0,
                    floating: false,
                    style: {
                        fontSize: '14px',
                        fontWeight: 'bold',
                        fontFamily: undefined,
                        color: '#263238'
                    },
                },
                dataLabels: {
                    enabled: true,
                    formatter: function (val) {
                        return "$ " + Number(val).toLocaleString();
                    },
                    offsetY: -20,
                    style: {
                        fontSize: "12px",
                        colors: ["#304758"]
                    }
                },
                stroke: {
                    show: true,
                    width: -2,
                    colors: ['transparent']
                },
                xaxis: {
                    categories: userType && userType === 'Business'
                        ?
                        [language.revenue, language.expense, language.profit]
                        :
                        [language.income, language.expense, language.expense_budget],
                    position: "bottom",
                    labels: {
                        offsetX: 0
                    },
                },
                fill: {
                    opacity: 1,
                },
                tooltip: {
                    enabled: true,
                    offsetY: -35,
                    custom: ({ series, seriesIndex, dataPointIndex, w }) => {
                        setBarIndex(dataPointIndex);
                        return null
                    }
                },
                colors: ["#3b3425", "#735a24", "#918b7e"],
                legend: {
                    show: true,
                    showForSingleSeries: false,
                    showForNullSeries: true,
                    showForZeroSeries: true,
                    position: 'bottom',
                    horizontalAlign: 'center',
                    floating: false,
                    fontSize: '14px',
                    fontFamily: 'Helvetica, Arial',
                    fontWeight: 400,
                    formatter: undefined,
                    inverseOrder: false,
                    width: undefined,
                    height: undefined,
                    tooltipHoverFormatter: undefined,
                    customLegendItems: [],
                    offsetX: 0,
                    offsetY: 0,
                    labels: {
                        colors: undefined,
                        useSeriesColors: false
                    },
                    markers: {
                        width: 12,
                        height: 12,
                        strokeWidth: 0,
                        strokeColor: '#fff',
                        fillColors: undefined,
                        radius: 12,
                        customHTML: undefined,
                        onClick: undefined,
                        offsetX: 0,
                        offsetY: 0
                    },
                    itemMargin: {
                        horizontal: 5,
                        vertical: 0
                    },
                    onItemClick: {
                        toggleDataSeries: false
                    },
                    onItemHover: {
                        highlightDataSeries: true
                    },
                }
            })
        }
    }, [data, text])

    // getting the day or week or month or year
    useEffect(() => {
        getLabelTextData();
        getHoverData();
    }, [time, userCode])

    const [hoveredArea, setHoveredArea] = useState(null);
    function handleDataPointHover(event, chartContext, config) {
        // Check if the area being hovered over meets your criteria (e.g., white area)
        if (config.seriesIndex) {
            // Update the state to indicate that the specific area is being hovered
            console.log("sdfgh", config.seriesIndex)
            setHoveredArea(config.seriesIndex); // You can use any unique identifier here
        } else {
            // Reset the state to indicate that no specific area is being hovered
            setHoveredArea(null);
        }
    }

    return (
        <div>
            <div onMouseLeave={() => setBarIndex("")}>
                <ReactApexChart
                    options={options}
                    series={series}
                    type="bar"
                    height={350}
                />
            </div>


            {/* hover modal */}
            <div className='hover-modal-parent'>

                {/* revenue */}
                {
                    barIndex === 0 && (
                        isBusiness || isInvestor || isBookKeeper
                            ?
                            // business revenue
                            <div className="custom-toolip bar-index-0">
                                <div className="custom-toolip-header">
                                    <div className="header-text">
                                        <span>{language.revenue}</span>
                                        {/* <span>ddd</span> */}
                                    </div>
                                </div>
                                <div className="custom-toolip-body">
                                    <div className="body-text">
                                        <p className='hover-data'>
                                            <span>{language.customer_total}:</span> <span>{hoverData?.revenue?.customerTotal}</span>
                                        </p>
                                        <p className='hover-data'>
                                            <span>{language.transaction_total}:</span> <span>{hoverData?.revenue?.transcationTotal}</span>
                                        </p>
                                        <p className='hover-data'>
                                            <span>{language?.revenue_growth}:</span> <span>{hoverData?.revenue?.revenueGrowth?.toFixed(2)}</span>
                                        </p>
                                        <p className='hover-data'>
                                            <span>{language.customer_growth}:</span> <span>{hoverData?.revenue?.customerGrowth?.toFixed(2)}</span>
                                        </p>
                                        <p className='hover-data'>
                                            <span>{language.transaction_growth}:</span> <span>{hoverData?.revenue?.transactionGrowth?.toFixed(2)}</span>
                                        </p>
                                    </div>
                                    <div className='hover-more-details'>
                                        <p>{language.click_graph_more_details}</p>
                                    </div>
                                </div >
                            </div >
                            :
                            // ppersonal revenue/income
                            <div className="custom-toolip bar-index-0">
                                <div className="custom-toolip-header">
                                    <div className="header-text">
                                        <span>{language.income}</span>
                                        {/* <span>ddd</span> */}
                                    </div>
                                </div>
                                <div className="custom-toolip-body">
                                    <div className="body-text">
                                        {/* <p className='hover-data'>
                                            <span>{language.customer_total}:</span> <span>{hoverData?.revenue?.customerTotal}</span>
                                        </p> */}
                                        <p className='hover-data'>
                                            <span>{language.transaction_total}:</span> <span>{hoverData?.revenue?.transcationTotal}</span>
                                        </p>
                                        <p className='hover-data'>
                                            <span>{language?.total_income_type}:</span> <span>{hoverData?.revenue?.total}</span>
                                        </p>
                                        <p className='hover-data'>
                                            <span>{language.income_growth}:</span> <span>{hoverData?.revenue?.incomeGrowth?.toFixed(2)}</span>
                                        </p>
                                        <p className='hover-data'>
                                            <span>{language.transaction_growth}:</span> <span>{hoverData?.revenue?.transactionGrowth?.toFixed(2)}</span>
                                        </p>
                                    </div >
                                    <div className='hover-more-details'>
                                        <p>{language.click_graph_more_details}</p>
                                    </div>
                                </div >
                            </div >
                    )
                }

                {/* expense */}
                {
                    barIndex === 1 && (
                        <div className="custom-toolip bar-index-1">
                            <div className="custom-toolip-header">
                                <div className="header-text">
                                    <span>{language.expense}</span>
                                    {/* <span>ddd</span> */}
                                </div>
                            </div>
                            <div className="custom-toolip-body">
                                <div className="body-text">
                                    <p className='hover-data'>
                                        <span>{language.vendor_total}:</span> <span>{hoverData?.expense?.vendorTotal}</span>
                                    </p>
                                    <p className='hover-data'>
                                        <span>{language.transaction_total}:</span> <span>{hoverData?.expense?.transcationTotal}</span>
                                    </p>
                                    <p className='hover-data'>
                                        <span>{language?.expense_growth}:</span> <span>{hoverData?.expense?.expenseGrowth?.toFixed(2)}</span>
                                    </p>
                                    <p className='hover-data'>
                                        <span>{language.vendor_growth}:</span> <span>{hoverData?.expense?.customerGrowth?.toFixed(2)}</span>
                                    </p>
                                    <p className='hover-data'>
                                        <span>{language.transaction_growth}:</span> <span>{hoverData?.expense?.transactionGrowth?.toFixed(2)}</span>
                                    </p>
                                </div >
                                <div className='hover-more-details'>
                                    <p>{language.click_graph_more_details}</p>
                                </div>
                            </div >
                        </div >
                    )
                }

                {/* profit */}
                {
                    barIndex === 2 &&
                    (
                        isBusiness || isInvestor || isBookKeeper
                            ?
                            // business profit
                            <div className="custom-toolip bar-index-2">
                                <div className="custom-toolip-header">
                                    <div className="header-text">
                                        <span>{language.profit}</span>
                                        {/* <span>ddd</span> */}
                                    </div>
                                </div>
                                <div className="custom-toolip-body">
                                    <div className="body-text">
                                        <p className='hover-data'>
                                            <span>{language.profit_growth}:</span> <span>{hoverData?.profit?.profitGrowth?.toFixed(2)}</span>
                                        </p>
                                        <p className='hover-data'>
                                            <span>{language.customer_total}:</span> <span>{hoverData?.profit?.customerTotal}</span>
                                        </p>
                                        <p className='hover-data'>
                                            <span>{language?.vendor_total}:</span> <span>{hoverData?.profit?.vendorTotal}</span>
                                        </p>
                                        <p className='hover-data'>
                                            <span>{language.transaction_total}:</span> <span>{hoverData?.profit?.transactionTotal}</span>
                                        </p>
                                    </div>
                                    <div className='hover-more-details'>
                                        <p>{language.click_graph_more_details}</p>
                                    </div>
                                </div >
                            </div >
                            :
                            // personal proffit/mmargin
                            <div className="custom-toolip bar-index-2">
                                <div className="custom-toolip-header">
                                    <div className="header-text">
                                        <span>{language.margin}</span>
                                        {/* <span>ddd</span> */}
                                    </div>
                                </div>
                                <div className="custom-toolip-body">
                                    <div className="body-text">
                                        <p className='hover-data'>
                                            <span>{language.margin_growth}:</span> <span>{hoverData?.profit?.marginGrowth?.toFixed(2)}</span>
                                        </p>
                                        <p className='hover-data'>
                                            <span>{language.income_tepe_total}:</span> <span>{hoverData?.profit?.incomeTypeTotal}</span>
                                        </p>
                                        <p className='hover-data'>
                                            <span>{language?.vendor_total}:</span> <span>{hoverData?.profit?.vendorTotal}</span>
                                        </p>
                                        <p className='hover-data'>
                                            <span>{language.transaction_total}:</span> <span>{hoverData?.profit?.transactionTotal}</span>
                                        </p>
                                    </div>
                                    <div className='hover-more-details'>
                                        <p>{language.click_graph_more_details}</p>
                                    </div>
                                </div >
                            </div >
                    )
                }
            </div>

        </div>
    )
}

export default DashboardChartOne;






