import React, { useState, useEffect, useContext, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
// import Swal from 'sweetalert2';
// import Lockicon from '../../src/images/lock-icon.png';
import { useLanguage } from '../Providers/LanguageContext';
import HttpClient, { BASE_URL } from '../utils/HttpClient';
import toast from 'react-hot-toast';
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai"
import { UserContext, useUserData } from '../Providers/UserWrapper';
// import term from '../images/blog_pdfs/Fisibility - Terms of Service_En.pdf';
// import term_Es from '../images/blog_pdfs/term_condition_Es.pdf';

export default function Creataccount() {
    const { language, currentLanguage } = useLanguage();
    const { setIsLoading, getNowUser } = useUserData();
    const navigate = useNavigate();
    const location = useLocation();
    const [visible, setVisible] = useState(false);
    const [confirmvisible, setConfirmVisible] = useState(false);
    const { setUserData } = useContext(UserContext);
    const [checkTotalUser, setCheckTotalUser] = useState(false);
    const [payUser, setPayUser] = useState({
        total: "",
        payType: ""
    });

    const initialValue = {
        email: '',
        pass: '',
        conPass: '',
        discCode: location?.state?.discCode ? location?.state?.discCode : "",
        accCode: '',
        userType: location?.state?.userType,
        acType: '',
        term: false
    }

    const [formValue, setFormValue] = useState(initialValue);
    const [formError, setFormError] = useState({});

    console.log('formValuereg', formValue);

    const handleChange = (e) => {
        let { name, value } = e.target
        setFormValue((prev) => {
            return (
                {
                    ...prev,
                    [name]: value
                }
            )
        })
    }

    const handleTerms = (e) => {
        setFormValue((prev) => {
            return (
                {
                    ...prev,
                    term: e.target.checked
                }
            )
        })
    }

    // const handleUserType = (e) => {
    //     setFormValue((prev) => {
    //         return (
    //             {
    //                 ...prev,
    //                 userType: e.target.value
    //             }
    //         )
    //     })
    // }

    const handleAccountType = (e) => {
        setFormValue((prev) => {
            return (
                {
                    ...prev,
                    acType: e.target.value
                }
            )
        })
    }

    const validate = (value) => {
        let error = {};
        if (!value.email) {
            error.email = language.email_validate_blank;
        }
        if (!value.pass) {
            error.pass = language.password_validate_blank;
        }
        else if (value.pass.length < 3) {
            error.pass = language.password_validate_min;
        }

        if (!value.conPass) {
            error.conPass = language.confirm_password_validate_blank;
        }
        else if (value.conPass.length < 3) {
            error.conPass = language.password_validate_min;
        }
        else if (value.conPass !== value.pass) {
            error.conPass = language.confirm_password_validate_match;
        }

        if (
            !(
                formValue.userType === "Business" &&
                formValue.acType === "Owner"
            )
        ) {
            if (
                !(
                    formValue.userType === language.personal &&
                    formValue.acType === "Manager"
                )
            ) {
                if (!value.accCode) {
                    error.accCode = language.account_reg_code;
                }
            }
        }

        if (!value.acType) {
            error.acType = language.accType_validate_blank;
        }

        if (!value.term) {
            error.term = language.term_validate;
        }
        return error;
    }

    // const checkRegCode = async () => {
    //     const sendData = {
    //         userType: formValue.userType,
    //         accountRegCode: formValue.accCode
    //     }
    //     const result = await HttpClient.requestData('check-reg-code', 'GET', sendData);
    //     if (result && result.status) {
    //         setIsRegCode(result.data)
    //     }
    // }

    const handleOpenRoute = () => {
        const routeUrl = 'https://fisibility.com/TermsService'; // Replace with your desired route URL
        window.open(routeUrl, '_blank');
    };

    // register
    const handleRegister = async (e) => {
        e.preventDefault();
        setFormError(validate(formValue));

        let registerData = {
            email: formValue.email,
            password: formValue.pass,
            userType: formValue.userType,
            accountType: formValue.acType,
            accountRegCode: formValue.accCode,
            fromWhichReffCode: formValue.discCode,
            language: currentLanguage == "eng" ? "EN" : "ES",
            userFlag: 0
        }
        console.log("fd4g65", registerData)
        // return;

        if (!Object.keys(validate(formValue)).length) {
            if (checkTotalUser) {
                // toast.error(`In ${payUser.payType} Maximum User Resistered`);
                toast.error(language.no_more_user);
                return;
            }

            // for regcode
            if (showRegCodeMemo) {
                const sendData = {
                    userType: formValue.userType,
                    accountRegCode: Number(formValue.accCode)
                }
                const result = await HttpClient.requestData('check-reg-code', 'POST', sendData);
                // console.log("resultregcode", result)
                if (result) {
                    if (!result.data) {
                        toast.error(language.acc_reg_code_exist);
                        return;
                    }
                }
            }
        }

        setIsLoading(true)
        const result = await HttpClient.requestData('register', 'POST', registerData)
        if (result && result.status) {
            if (location?.state?.priceId) {
                createCheckout(location?.state?.priceId, result?.data);
            } else {
                // if (formValue.userType === "Bookkeeper") {
                //     navigate('/bookkeeper-manager', { state: formValue.userType });
                // } else {
                navigate('/manager', { state: formValue.userType });
                // }
            }
            setIsLoading(false)
            localStorage.setItem("userData", JSON.stringify({ token: result.data }));
            toast.success(language.loggedin_success);
            setUserData({ token: result.data })
        }
        else {
            // Swal.fire({
            //     icon: 'error',
            //     // title: 'Oops...',
            //     text: `${language.email_exist}`,
            // })
            setIsLoading(false)
            if (result.message === "Email already exsists") {
                toast.error(language.email_exist)
            } else if (result.message === "This code does not exsists") {
                toast.error(language.discount_code_not_exist)
            } else if (result.message === "This code does not add users") {
                toast.error(language.pay_add_more_user)
            }
            else {
                toast.error(result.message)
            }
        }
    }

    const passsVisibility = () => {
        if (visible) {
            setVisible(false)
        }
        else {
            setVisible(true)
        }
    }

    const passsVisibility2 = () => {
        if (confirmvisible) {
            setConfirmVisible(false)
        }
        else {
            setConfirmVisible(true)
        }
    }

    const getUserNumber = async () => {
        if (formValue.userType === "Business") {
            if (formValue.acType === "Manager" || formValue.acType === "Staff") {
                const sendData = {
                    accountRegCode: Number(formValue.accCode)
                }
                const res = await HttpClient.requestData("total-user", "POST", sendData);
                if (res && res.status) {
                    const total = res?.data[0]?.total;
                    const payType = res?.data[0]?.SubscriptionData[0]?.priceData[0]?.payType;
                    setPayUser({
                        total: total,
                        payType: payType
                    })
                    if (total && payType) {
                        if (payType === "starter" && total < 5) {
                            setCheckTotalUser(false);
                        } else if (payType === "small business plan" && total < 10) {
                            setCheckTotalUser(false);
                        } else if (payType === "mid size plan" && total < 20) {
                            setCheckTotalUser(false);
                        } else {
                            setCheckTotalUser(true);
                        }
                    }
                } else {
                    setCheckTotalUser(false)
                }
            }
        }
    }

    // show regcode 
    const showRegCodeMemo = useMemo(() => {
        // console.log("typeee", formValue.userType, formValue.acType)
        if (formValue.acType) {
            if (formValue.userType === "Business") {
                if (formValue.acType === "Owner") return false
                else return true
            } else if (formValue.userType === "Personal") {
                if (formValue.acType === "Manager") return false
                else return true
            } else if (formValue.userType === "Bookkeeper") {
                if (formValue.acType === "Owner") return false
                else return true
            } else if (formValue.userType === "Investor") {
                if (formValue.acType === "Owner") return false
                else return true
            } else {
                return false
            }
        } else {
            return false
        }
    }, [formValue.userType, formValue.acType])

    // console.log("showRegCodeMemo", showRegCodeMemo)

    useEffect(() => {
        getUserNumber()
    }, [formValue.acType, formValue.accCode])

    useEffect(() => {
        window.scroll(0, 0);
        setFormValue((prev) => {
            return (
                {
                    ...prev,
                    email: '',
                    pass: '',
                    conPass: '',
                    // discCode: '',
                    accCode: '',
                    // userType: '',
                    acType: '',
                    term: false
                }
            )
        })

        // if (formValue.userType === 'Business' || formValue.userType === 'Negocios') {
        //     if (language.business === 'Business') {
        //         setFormValue((prev) => { return ({ ...prev, userType: "Business" }) })
        //     }
        //     else if (language.business === 'Negocios') {
        //         setFormValue((prev) => { return ({ ...prev, userType: "Negocios" }) })
        //     }
        // }

        if (document.getElementById('ac1')) {
            document.getElementById('ac1').checked = false;
        }
        if (document.getElementById('ac2')) {
            document.getElementById('ac2').checked = false;
        }
        if (document.getElementById('ac3')) {
            document.getElementById('ac3').checked = false;
        }

    }, [language])


    useEffect(() => {
        if (!location?.state) {
            if (getNowUser) {
                setFormValue((prev) => { return ({ ...prev, userType: getNowUser }) })
            } else {
                navigate('/login')
            }
        }
        else {
        }
    }, [location?.state, getNowUser])


    // stripe payment api
    const createCheckout = async (priceID, token) => {
        const data = {
            "line_items": [
                {
                    "price": priceID,
                    "quantity": 1
                }
            ]
        }

        console.log("datappdd", data)

        var myHeaders = new Headers();
        if (token != "") {
            myHeaders.append("authorization", token);
        }
        myHeaders.append("userType", "User");
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Access-Control-Allow-Origin", "*");

        var raw = JSON.stringify(data);
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        let apiUrl = BASE_URL + "create-checkout";

        setIsLoading(true);
        const res = await fetch(apiUrl, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                // console.log(result);
                return result;
            })
            .catch((error) => console.log("error", error));
        if (res && res.status) {
            window.location.href = res.url;
            setIsLoading(false);
        } else {
            setIsLoading(false);
        }

        // setIsLoading(true);
        // const res = await HttpClient.requestData("create-checkout", "POST", data);
        // // console.log('resbaler', res)
        // if (res.status) {
        //     window.location.href = res.url;
        //     setIsLoading(false);
        // } else {
        //     setIsLoading(false);
        // }
    }


    return (

        <>
            <section id='create-account'>
                <div className='container'>
                    <div className='heading'>
                        <h4>{language.create_account} </h4>
                    </div>
                    <div className='inner-container'>
                        <form onSubmit={handleRegister}>
                            <div className="form-group text-field">
                                <input
                                    type="email"
                                    className="form-control"
                                    id=""
                                    aria-describedby=""
                                    placeholder={language.email_placeholder}
                                    name="email"
                                    value={formValue.email}
                                    onChange={handleChange}
                                />
                                <span className='text-danger'>{formError.email}</span>
                            </div>

                            <div className='lock-field'>

                                <div className="form-group">
                                    <input
                                        type={visible ? 'text' : 'password'}
                                        className="form-control"
                                        // id=""
                                        aria-describedby=""
                                        placeholder={language.password_placeholder}
                                        name="pass"
                                        value={formValue.pass}
                                        onChange={handleChange}
                                    />
                                    <div
                                        onClick={passsVisibility}
                                        className='lock-icon'
                                    >
                                        {/* <img className="img-fluid" src={Lockicon} /> */}
                                        {
                                            visible ? (<AiFillEyeInvisible size={25} />) :
                                                (<AiFillEye size={25} />)
                                        }
                                    </div>
                                </div>
                            </div>
                            <span className='text-danger'>{formError.pass}</span>


                            <div className='lock-field'>

                                <div className="form-group">
                                    <input
                                        type={confirmvisible ? 'text' : 'password'}
                                        className="form-control"
                                        // id=""
                                        aria-describedby=""
                                        placeholder={language.confirm_password_placeholder}
                                        name="conPass"
                                        value={formValue.conPass}
                                        onChange={handleChange}
                                    />
                                    <div onClick={passsVisibility2} className='lock-icon'>
                                        {/* <img className="img-fluid" src={Lockicon} /> */}
                                        {
                                            confirmvisible ? (<AiFillEyeInvisible size={25} />) :
                                                (<AiFillEye size={25} />)
                                        }
                                    </div>
                                </div>
                            </div>
                            <span className='text-danger'>{formError.conPass}</span>


                            {/* ...........Account Type.......... */}
                            {/* Business Acc */}
                            <section>                            {
                                formValue.userType === "Business" &&
                                <div className='form-checkbox'>
                                    <label className="check-field">
                                        {language.owner}

                                        <input type="radio"
                                            id="ac1"
                                            name="acType"
                                            // checked={ }
                                            value="Owner"
                                            onChange={handleAccountType}
                                        />
                                        <span className="checkmark" />
                                    </label>

                                    {/* member/manager */}
                                    {
                                        formValue.discCode !== "ADMIN" &&
                                        <>
                                            <label className="check-field">
                                                {language.manager}

                                                <input type="radio"
                                                    id="ac2"
                                                    name="acType"
                                                    value="Manager"
                                                    onChange={handleAccountType}
                                                />
                                                <span className="checkmark" />
                                            </label>

                                            {/* staff */}
                                            <label className="check-field">
                                                {language.staff}
                                                <input type="radio"
                                                    id="ac3"
                                                    name="acType"
                                                    value="Staff"
                                                    onChange={handleAccountType}
                                                />
                                                <span className="checkmark" />
                                            </label>

                                            {/* bookkeper */}
                                            <label className="check-field">
                                                {language.bookkeeper}
                                                <input type="radio"
                                                    id="ac3"
                                                    name="acType"
                                                    value="Bookkeeper"
                                                    onChange={handleAccountType}
                                                />
                                                <span className="checkmark" />
                                            </label>
                                        </>
                                    }
                                </div>
                            }
                            </section>

                            {/* Personal Acc */}
                            <section>
                                {
                                    // Account owner
                                    formValue.userType === "Personal" &&
                                    <div className='form-checkbox'>
                                        <label className="check-field">
                                            {language.account_owner}

                                            <input type="radio"
                                                id="ac1"
                                                name="acType"
                                                // checked={ }
                                                value="Manager"
                                                onChange={handleAccountType}
                                            />
                                            <span className="checkmark" />
                                        </label>

                                        {/* member/manager */}
                                        {
                                            formValue.discCode !== "ADMIN" &&
                                            <>
                                                <label className="check-field">
                                                    {language.member}

                                                    <input type="radio"
                                                        id="ac2"
                                                        name="acType"
                                                        value="Member"
                                                        onChange={handleAccountType}
                                                    />
                                                    <span className="checkmark" />
                                                </label>

                                                {/* staff */}
                                                <label className="check-field">
                                                    {language.staff}
                                                    <input type="radio"
                                                        id="ac3"
                                                        name="acType"
                                                        value="Staff"
                                                        onChange={handleAccountType}
                                                    />
                                                    <span className="checkmark" />
                                                </label>

                                                {/* bookkeper */}
                                                <label className="check-field">
                                                    {language.bookkeeper}
                                                    <input type="radio"
                                                        id="ac3"
                                                        name="acType"
                                                        value="Bookkeeper"
                                                        onChange={handleAccountType}
                                                    />
                                                    <span className="checkmark" />
                                                </label>
                                            </>
                                        }
                                    </div>
                                }
                            </section>

                            {/* Bookkeeper Acc */}
                            <section>
                                {
                                    (formValue.userType === "Bookkeeper" || formValue.userType === "Investor")
                                    &&
                                    <div className='form-checkbox'>

                                        {/* owner */}
                                        <label className="check-field">
                                            {language.owner}
                                            <input type="radio"
                                                id="ac1"
                                                name="acType"
                                                // checked={ }
                                                value="Owner"
                                                onChange={handleAccountType}
                                            />
                                            <span className="checkmark" />
                                        </label>

                                        {/* sub User */}
                                        {
                                            formValue.discCode !== "ADMIN" &&
                                            <label className="check-field">
                                                {"Sub User"}
                                                <input type="radio"
                                                    id="ac3"
                                                    name="acType"
                                                    value="Subuser"
                                                    onChange={handleAccountType}
                                                />
                                                <span className="checkmark" />
                                            </label>
                                        }
                                    </div>
                                }
                            </section>
                            <span className='text-danger'>{formError.acType}</span>


                            {/* account generating code */}
                            {
                                showRegCodeMemo
                                &&
                                <div className="form-group text-field">
                                    <input
                                        type="text"
                                        className="form-control"
                                        // id=""
                                        aria-describedby=""
                                        placeholder={language.account_reg_code}
                                        name="accCode"
                                        value={formValue.accCode}
                                        onChange={handleChange}
                                    />
                                    <span className='text-danger'>{formError.accCode}</span>
                                </div>
                            }

                            <div className="form-group text-field">
                                <input
                                    type="text"
                                    className="form-control"
                                    // id=""
                                    aria-describedby=""
                                    placeholder={language.discount_code_placeholder}
                                    name="discCode"
                                    value={formValue.discCode}
                                    onChange={handleChange}
                                />
                                {/* <span className='text-danger'>{formError.discCode}</span> */}
                            </div>

                            <div className='terms-condition'>
                                <label className="check-field" style={{ fontSize: '16px', textDecoration: 'underline' }}>
                                    {/* <a href={currentLanguage === 'eng' ? term : term_Es} target="blank"> */}
                                    <a
                                        style={{ cursor: "pointer" }}
                                        onClick={handleOpenRoute}
                                    >
                                        {language.accept_register}
                                    </a>
                                    <input type="checkbox"
                                        name="term"
                                        onChange={handleTerms}
                                    />
                                    <span className="checkmark" />
                                </label>

                                <span className='text-danger'>{formError.term}</span>

                            </div>

                            <div className='account-btn'>
                                <button type='submit' className='register-btn'>
                                    {language.register.toUpperCase()}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </section >
        </>
    )
}
