import React from 'react'
import Profilegirl from "../images/profile-girl.png";

export default function Createprofile2() {
    return (

        <>
            <section id='profile2'>
                <div className='container'>

                    <div className='row'>
                        <div className='col-xl-8 col-lg-8 col-md-8 col-12'>
                            <div className='profile-top'>
                                <h2>Hola!</h2>
                                <h4>Crea tu perfil</h4>
                                <p>Regístrate para continuar</p>
                            </div>
                            <div className='profile-form'>
                                <form>


                                    <div className="form-group">
                                        <select className="form-control" id="exampleFormControlSelect1">
                                            <option>País</option>
                                            <option>2</option>
                                            <option>3</option>
                                            <option>4</option>
                                            <option>5</option>
                                        </select>
                                    </div>


                                    <div className="form-group">
                                        <select className="form-control" id="exampleFormControlSelect1">
                                            <option>Seleccionar Moneda</option>
                                            <option>2</option>
                                            <option>3</option>
                                            <option>4</option>
                                            <option>5</option>
                                        </select>
                                    </div>

                                    <div className="form-group">
                                        <input
                                            type="text"
                                            className="form-control"
                                            id=""
                                            aria-describedby=""
                                            placeholder="Nombre"
                                        />
                                    </div>

                                    <div className="form-group">
                                        <input
                                            type="text"
                                            className="form-control"
                                            id=""
                                            aria-describedby=""
                                            placeholder="Apellidos"
                                        />
                                    </div>


                                </form>
                            </div>
                        </div>
                        <div className='col-xl-4 col-lg-4 col-md-4 col-12 mb-3'>
                            <div className='profile-image'>
                                <img className="img-fluid" src={Profilegirl} />
                            </div>
                        </div>
                    </div>

                    <div className='profile-bottom'>
                        <div className='row'>
                            <div className='col-xl-6 col-lg-6 col-md-6 col-12'>
                                <div className="form-group">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id=""
                                        aria-describedby=""
                                        placeholder="C.P"
                                    />
                                </div>
                            </div>
                            <div className='col-xl-6 col-lg-6 col-md-6 col-12'>
                                <div className="form-group">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id=""
                                        aria-describedby=""
                                        placeholder="Ciudad"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-xl-6 col-lg-6 col-md-6 col-12'>
                                <div className="form-group">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id=""
                                        aria-describedby=""
                                        placeholder="Estado"
                                    />
                                </div>
                            </div>
                            <div className='col-xl-6 col-lg-6 col-md-6 col-12'>
                                <div className="form-group">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id=""
                                        aria-describedby=""
                                        placeholder="Número de Teléfono"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='profile-btn'>
                        <button className='continue-btn'>CONTINUAR</button>
                    </div>


                </div>
            </section>

        </>


    )
}
