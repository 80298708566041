import React, { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { AiOutlineArrowLeft, AiOutlineArrowRight } from 'react-icons/ai';
import { useLanguage } from '../../Providers/LanguageContext';

const PaginationReport = ({ originalData, setPaginateData }) => {
    console.log('originalData', originalData)

    const { language, currentLanguage } = useLanguage();

    const noDataInPage = 8;
    const totalNoOfPage = Math.ceil(originalData.length / noDataInPage);

    const [currPage, setCurrPage] = useState(1)
    const [initialIndex, setInitialIndex] = useState(0);
    const [lastIndex, setLastIndex] = useState(initialIndex + noDataInPage);

    const [goData, setGoData] = useState(currPage)

    console.log('currPage', currPage)

    useEffect(() => {
        let arr = originalData.slice(initialIndex, lastIndex);
        setPaginateData(arr);
        setGoData(currPage);
    }, [currPage, originalData])

    // console.log('currPage', currPage)

    return (
        <div>
            {
                totalNoOfPage > 1 &&
                <div className='report-pagination'>
                    <div className='page-no-report'>
                        <strong>{currPage}</strong> of <strong>{totalNoOfPage}</strong>
                    </div>
                    <div>
                        <button
                            disabled={currPage === 1}
                            className='btn btn-sm'
                            onClick={() => {
                                if (currPage > 1) {
                                    setInitialIndex(initialIndex - noDataInPage);
                                    setLastIndex(lastIndex - noDataInPage);
                                    setCurrPage(currPage - 1)
                                }
                            }}
                        >
                            <AiOutlineArrowLeft />Prev
                        </button>

                        <input
                            value={goData}
                            onChange={(e) => {
                                if (/^0*[1-9]\d*$/.test(e.target.value)) {
                                    setGoData(Number(e.target.value))
                                } else {
                                    setGoData("")
                                }
                            }}
                        />
                        <button
                            className='btn btn-sm'
                            onClick={() => {
                                if (goData && goData <= totalNoOfPage) {
                                    setInitialIndex(noDataInPage * (goData - 1));
                                    setLastIndex((noDataInPage * goData));
                                    setCurrPage(goData);
                                } else {
                                    toast.error(`${language.please_enter_number_up} ${totalNoOfPage}`)
                                }
                            }}
                        >
                            Go
                        </button>
                        <button
                            disabled={currPage >= totalNoOfPage}
                            className='btn btn-sm'
                            onClick={() => {
                                if (currPage < totalNoOfPage) {
                                    setLastIndex(lastIndex + noDataInPage);
                                    setInitialIndex(initialIndex + noDataInPage);
                                    setCurrPage(currPage + 1)
                                }
                            }}
                        >
                            Next<AiOutlineArrowRight />
                        </button>
                    </div>
                </div>
            }
        </div>
    )
}

export default PaginationReport