import jsPDF from 'jspdf';
import moment from 'moment';
import React, { useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useLanguage } from '../../Providers/LanguageContext';
import { useUserData } from '../../Providers/UserWrapper';
import { AiOutlineArrowLeft } from 'react-icons/ai';


function InvoicePrint() {

    const { language } = useLanguage();
    const navigate = useNavigate()
    const location = useLocation();
    const componentRef = useRef();
    const { profileData } = useUserData()
    // console.log('profileData', profileData)
    const reportTemplateRef = useRef(null);
    // console.log(location.state, "locationstateprint")

    if (!location.state) {
        navigate(-1)
    }
    const handleDownload = () => {
        const doc = new jsPDF({
            format: 'a2',
            unit: 'px',
        });

        // Adding the fonts.
        doc.setFont('Inter-Regular', 'normal');

        doc.html(reportTemplateRef.current, {
            async callback(doc) {
                await doc.save('document');
            },
        });
    }


    // const handlePrint = useReactToPrint({

    //     content: () => componentRef.current,
    //   });
    return (

        <>
            <section style={{ width: "100%", height: "100%", backgroundColor: "black" }} ref={componentRef}>
                <div className="container" style={{ width: "100%", maxWidth: "1400px", height: "auto", margin: "0 auto" }}>
                    <div
                        className="back-arrow-left"
                        style={{ color: "#fff", position: "fixed" }}
                        onClick={() => navigate(-1)}
                    >
                        <AiOutlineArrowLeft />
                    </div>
                    <div ref={reportTemplateRef} className="main_wrap"
                        style={{ width: "100%", maxWidth: "850px", height: "auto", margin: "0 auto", backgroundColor: "#fff", padding: "40px 40px" }}
                    >
                        <div className='d-flex justify-content-end'>
                            <button className='btn btn-sm' style={{ backgroundColor: "#0E2537", color: "#fff" }} onClick={handleDownload}>{language.print}</button>
                        </div>
                        <div className="head" style={{ marginBottom: "30px" }}>
                            <table style={{ width: "100%" }}>
                                <tr style={{ width: "100%" }}>
                                    <th>
                                        <div className="logo" style={{ width: "50px", height: "40px", overflow: "hidden" }}>
                                            <img src="https://png.pngitem.com/pimgs/s/160-1604128_angle-text-brand-menu-icon-png-vector-transparent.png"
                                                className="img-fluid" alt="logo" style={{ width: "100%", height: "100%" }} />
                                        </div>
                                    </th>
                                    <th>
                                        <h5 className=""
                                            style={{
                                                fontSize: "48px", lineHeight: "normal", fontWeight: "500", color: "#000000",
                                                textAlign: "right", padding: " 0", marginBottom: "15px", marginTop: "0"
                                            }}>
                                            {language.item_invoice}
                                        </h5>
                                    </th>
                                </tr>
                            </table>
                        </div>

                        <div className="add" style={{ width: "100%", height: "auto", marginBottom: "30px" }}>
                            <table style={{ width: "100%" }}>
                                <thead style={{ marginBottom: "20px" }}>
                                    <tr style={{ width: "100%" }}>
                                        <th>{language.bill_from}</th>
                                        <th>{language.bill_To}</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <table>
                                                <tr>
                                                    <td
                                                        style={{ padding: "5px 5px", display: "flex", alignItems: "start", fontSize: "14px", fontWeight: "400", color: "#000000" }}>
                                                        <b>{language.name_only}</b> :
                                                    </td>
                                                    <td
                                                        style={{ padding: "5px 5px", fontSize: "14px", fontWeight: "400", color: "#000000", borderBottom: "1px solid #ccc" }}>
                                                        {profileData && profileData.name} {profileData && profileData.surName}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        style={{ padding: "5px 5px", display: "flex", alignItems: "start", fontSize: "14px", fontWeight: "400", color: "#000000" }}>
                                                        <b>{language.company_name}</b> :
                                                    </td>
                                                    <td
                                                        style={{ padding: "5px 5px", fontSize: "14px", fontWeight: "400", color: "#000000", borderBottom: "1px solid #ccc" }}>
                                                        {profileData && profileData.companyName}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        style={{ padding: "5px 5px", display: "flex", alignItems: "start", fontSize: "14px", fontWeight: "400", color: "#000000" }}>
                                                        <b>{language.country}</b> :
                                                    </td>
                                                    <td
                                                        style={{ padding: "5px 5px", fontSize: "14px", fontWeight: "400", color: "#000000", borderBottom: "1px solid #ccc" }}>
                                                        {profileData && profileData.country}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        style={{ padding: "5px 5px", display: "flex", alignItems: "start", fontSize: "14px", fontWeight: "400", color: "#000000" }}>
                                                        <b>City,ST ZIP Codde</b> :
                                                    </td>
                                                    <td
                                                        style={{ padding: "5px 5px", fontSize: "14px", fontWeight: "400", color: "#000000", borderBottom: "1px solid #ccc" }}>
                                                        {profileData && profileData.city}, {profileData && profileData.state}, {profileData && profileData.cp}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        style={{ padding: "5px 5px", display: "flex", alignItems: "start", fontSize: "14px", fontWeight: "400", color: "#000000" }}>
                                                        <b>{language.email}</b> :
                                                    </td>
                                                    <td
                                                        style={{ padding: "5px 5px", fontSize: "14px", fontWeight: "400", color: "#000000", borderBottom: "1px solid #ccc" }}>
                                                        {profileData && profileData.email}
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                        <td>
                                            <table>
                                                <tr>
                                                    <td
                                                        style={{ padding: "5px 5px", display: "flex", alignItems: "start", fontSize: "14px", fontWeight: "400", color: "#000000" }}>
                                                        <b>{language.name_only}</b> :
                                                    </td>
                                                    <td
                                                        style={{ padding: "5px 5px", fontSize: "14px", fontWeight: "400", color: "#000000", borderBottom: "1px solid #ccc" }}>
                                                        {location.state?.customerName}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        style={{ padding: "5px 5px", display: "flex", alignItems: "start", fontSize: "14px", fontWeight: "400", color: "#000000" }}>
                                                        <b>{language.customerRFCID}</b> :
                                                    </td>
                                                    <td
                                                        style={{ padding: "5px 5px", fontSize: "14px", fontWeight: "400", color: "#000000", borderBottom: "1px solid #ccc" }}>
                                                        {location.state?.customerRFCID}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        style={{ padding: "5px 5px", display: "flex", alignItems: "start", fontSize: "14px", fontWeight: "400", color: "#000000" }}>
                                                        <b>{language.id_no}</b> :
                                                    </td>
                                                    <td
                                                        style={{ padding: "5px 5px", fontSize: "14px", fontWeight: "400", color: "#000000", borderBottom: "1px solid #ccc" }}>
                                                        {location.state?.identificationNumber}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        style={{ padding: "5px 5px", display: "flex", alignItems: "start", fontSize: "14px", fontWeight: "400", color: "#000000" }}>
                                                        <b>City,ST ZIP Codde</b> :
                                                    </td>
                                                    <td
                                                        style={{ padding: "5px 5px", fontSize: "14px", fontWeight: "400", color: "#000000", borderBottom: "1px solid #ccc" }}>
                                                        xxxxxx
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        style={{ padding: "5px 5px", display: "flex", alignItems: "start", fontSize: "14px", fontWeight: "400", color: "#000000" }}>
                                                        <b>Email</b> :
                                                    </td>
                                                    <td
                                                        style={{ padding: "5px 5px", fontSize: "14px", fontWeight: "400", color: "#000000", borderBottom: "1px solid #ccc" }}>
                                                        {location.state?.customerEmail}
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                        <td>
                                            <table>
                                                <tr>
                                                    <td
                                                        style={{ padding: "5px 5px", display: "flex", alignItems: "start", fontSize: "14px", fontWeight: "400", color: "#000000" }}>
                                                        <b>{language.invoice_number}:</b> :
                                                    </td>
                                                    <td
                                                        style={{ padding: "5px 5px", fontSize: "14px", fontWeight: "400", color: "#000000", borderBottom: "1px solid #ccc" }}>
                                                        {location.state?.invoiceNumber}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        style={{ padding: "5px 5px", display: "flex", alignItems: "start", fontSize: "14px", fontWeight: "400", color: "#000000" }}>
                                                        <b>{language.invoice_date}:</b> :
                                                    </td>
                                                    <td
                                                        style={{ padding: "5px 5px", fontSize: "14px", fontWeight: "400", color: "#000000", borderBottom: "1px solid #ccc" }}>
                                                        {location.state && moment(location.state.date).utc().format("MM/DD/YYYY")}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        style={{ padding: "5px 5px", display: "flex", alignItems: "start", fontSize: "14px", fontWeight: "400", color: "#000000" }}>
                                                        <b>{language.order_no}</b> :
                                                    </td>
                                                    <td
                                                        style={{ padding: "5px 5px", fontSize: "14px", fontWeight: "400", color: "#000000", borderBottom: "1px solid #ccc" }}>
                                                        {location.state?.orderNumber}
                                                    </td>
                                                </tr>

                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>


                        <div className="description" style={{ width: " 100%", height: "auto", marginBottom: "30px" }}>
                            <table style={{ width: "100%", height: "auto" }}>
                                <thead style={{ width: "100%" }}>
                                    <tr style={{ width: "100%" }}>
                                        <th
                                            style={{ textAlign: "center", border: "1px solid #000000", "backgroundColor": "lightslategray", padding: "10px 5px", margin: "0", color: "#fff" }}>
                                            {language.description}</th>

                                        <th
                                            style={{ textAlign: "center", border: "1px solid #000000", "backgroundColor": "lightslategray", padding: "10px 5px", margin: "0", color: "#fff" }}>
                                            {language.quantity}</th>

                                        <th
                                            style={{ textAlign: "center", border: "1px solid #000000", "backgroundColor": "lightslategray", padding: "10px 5px", margin: "0", color: "#fff" }}>
                                            {language.unit}</th>
                                        <th
                                            style={{ textAlign: "center", border: "1px solid #000000", "backgroundColor": "lightslategray", padding: "10px 5px", margin: "0", color: "#fff" }}>
                                            {language.price}($)</th>
                                        <th
                                            style={{ textAlign: "center", border: "1px solid #000000", "backgroundColor": "lightslategray", padding: "10px 5px", margin: "0", color: "#fff" }}>
                                            {language.total}($)</th>
                                    </tr>

                                </thead>

                                <tbody>
                                    <tr>
                                        <td
                                            style={{ textAlign: "center", border: "1px solid #000000", backgroundColor: "transparent", padding: "10px 5px", margin: "0", color: "#000" }}>
                                            {location.state?.description}
                                        </td>
                                        <td
                                            style={{ textAlign: "center", border: "1px solid #000000", backgroundColor: "transparent", padding: "10px 5px", margin: "0", color: "#000" }}>
                                            {location.state?.quantity}
                                        </td>
                                        <td
                                            style={{ textAlign: "center", border: "1px solid #000000", backgroundColor: "transparent", padding: "10px 5px", margin: "0", color: "#000" }}>
                                            {location.state?.unit}

                                        </td>
                                        <td
                                            style={{ textAlign: "center", border: "1px solid #000000", backgroundColor: "transparent", padding: "10px 5px", margin: "0", color: "#000" }}></td>
                                        <td
                                            style={{ textAlign: "center", border: "1px solid #000000", backgroundColor: "transparent", padding: "10px 5px", margin: "0", color: "#000" }}>
                                            {location.state?.total}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td
                                            style={{ textAlign: "right", backgroundColor: "transparent", padding: "10px 5px", margin: "0", color: "#000" }}>{language.subTotal}</td>
                                        <td
                                            style={{ textAlign: "center", border: "1px solid #000000", backgroundColor: "transparent", padding: "10px 5px", margin: "0", color: "#000" }}>
                                            {location.state?.subTotal}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td
                                            style={{ textAlign: "right", backgroundColor: "transparent", padding: "10px 5px", margin: "0", color: "#000" }}>{language.sale_tax}</td>
                                        <td
                                            style={{ textAlign: "center", border: "1px solid #000000", backgroundColor: "transparent", padding: "10px 5px", margin: "0", color: "#000" }}>
                                            {location.state?.tax}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td
                                            style={{ textAlign: "right", backgroundColor: "transparent", padding: "10px 5px", margin: "0", color: "#000" }}>Other</td>
                                        <td
                                            style={{ textAlign: "center", border: "1px solid #000000", backgroundColor: "transparent", padding: "10px 5px", margin: "0", color: "#000" }}>0</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td
                                            style={{ textAlign: "right", backgroundColor: "transparent", padding: "10px 5px", margin: "0", color: "#000" }}>{language.total}</td>
                                        <td
                                            style={{ textAlign: "center", border: "1px solid #000000", backgroundColor: "transparent", padding: "10px 5px", margin: "0", color: "#000" }}>{location.state?.total}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div className="bottom">
                            <p style={{ textAlign: "center", fontSize: "18px", lineHeight: "26px", fontWeight: "400", color: "#000000", padding: "0", marginBottom: "10px" }}>{language.thank_you_business}</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default InvoicePrint