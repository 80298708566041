import React, { useEffect, useState } from 'react'
// import { useUserData } from '../Providers/UserWrapper';
// import { useLanguage } from '../Providers/LanguageContext';
import { Link } from 'react-router-dom';
// import HttpClient from '../utils/HttpClient';
import toast from 'react-hot-toast';
import moment from 'moment';
import { useUserData } from '../../../Providers/UserWrapper';
import { useLanguage } from '../../../Providers/LanguageContext';
import HttpClient from '../../../utils/HttpClient';

const SetUpManageDocsSubUser = () => {

    const { language, currentLanguage } = useLanguage()
    const { setIsLoading, profileData } = useUserData();

    const [setupDocData, setSetupDocData] = useState([])

    console.log("profileDatadd", setupDocData)

    const InitBlankDocData = {
        docType: "",
        dueDate: "",
        status: "",
        fileUrl: "",
        fileName: ""
    }
    const initDocData = [
        {
            docType: language.twelve_month_cash_flow,
            dueDate: "",
            fileUrl: "",
            fileName: "",
        },
        {
            docType: language.Week_Cash_Flow,
            dueDate: "",
            fileUrl: "",
            fileName: "",
        },
        {
            docType: language.Cash_Flow,
            dueDate: "",
            fileUrl: "",
            fileName: "",
        },
        {
            docType: language.Balance_Sheet,
            dueDate: "",
            fileUrl: "",
            fileName: "",
        },
        {
            docType: language.Income_Statement,
            dueDate: "",
            fileUrl: "",
            fileName: "",
        },
        {
            docType: language.Profit_Loss_Statement,
            dueDate: "",
            fileUrl: "",
            fileName: "",
        },
        {
            docType: language.Monthly_Bank_Statement,
            dueDate: "",
            fileUrl: "",
            fileName: "",
        },
        {
            docType: language.Accounts_Receivable_Report,
            dueDate: "",
            fileUrl: "",
            fileName: "",
        },
        {
            docType: language.Accounts_Payable_Report,
            dueDate: "",
            fileUrl: "",
            fileName: "",
        },
        {
            docType: language.Capitalization_Table_Report,
            dueDate: "",
            fileUrl: "",
            fileName: "",
        },
        {
            docType: language.Tax_Return,
            dueDate: "",
            fileUrl: "",
            fileName: "",
        },
        {
            docType: language.Sales_Report,
            dueDate: "",
            fileUrl: "",
            fileName: "",
        },
        {
            docType: language.Business_Financial_Statement,
            dueDate: "",
            fileUrl: "",
            fileName: "",
        },
        {
            docType: language.Personal_Financial_Statement,
            dueDate: "",
            fileUrl: "",
            fileName: "",
        },
        {
            docType: language.Month_End_Financial_Report,
            dueDate: "",
            fileUrl: "",
            fileName: "",
        },
        {
            docType: language.Customer_Agreement,
            dueDate: "",
            fileUrl: "",
            fileName: "",
        },
        {
            docType: language.Supplier_Agreement,
            dueDate: "",
            fileUrl: "",
            fileName: "",
        },
        {
            docType: language.Purchase_Order_Agreement,
            dueDate: "",
            fileUrl: "",
            fileName: "",
        },
        {
            docType: language.Operating_Agreement,
            dueDate: "",
            fileUrl: "",
            fileName: "",
        },
        {
            docType: language.Supplier_Invoice,
            dueDate: "",
            fileUrl: "",
            fileName: "",
        },
        {
            docType: language.Customer_Invoice,
            dueDate: "",
            fileUrl: "",
            fileName: "",
        }

    ]
    const [docData, setDocData] = useState(initDocData)

    // console.log("docData_ddd", docData)

    // return attach, missing, pastDue
    const statusOfDoc = (date, file) => {
        const checkDate = moment(date).isAfter(moment().format("YYYY-MM-DD")) || moment(date).isSame(moment().format("YYYY-MM-DD"))
        // console.log("momentt", date, moment(date).isSame(moment().format("YYYY-MM-DD")))
        if (date) {
            if (checkDate) {
                if (file) {
                    return "Attached"
                } else {
                    return "Missing"
                }
            } else {
                return "Past Due"
            }
        } else {
            return "-"
        }
    }

    // handle change
    const handleChange = (e, i) => {
        const { name, value } = e.target;
        setDocData(prev => prev.map((item, ind) => {
            if (i === ind) {
                return { ...item, [name]: value }
            } else {
                return item
            }
        }))
    }

    // handle change pdf
    const handlePdfUpload = async (e, i) => {
        const file = e.target.files[0]
        console.log("filesdd", file?.name)
        if (file) {
            const data = new FormData();
            data.append("pdfFile", file)
            // console.log('imgData',data)
            setIsLoading(true)
            const res = await HttpClient.fileUplode("bookkeeper/upload-pdf", "POST", data);
            // console.log('imgres', res)
            setIsLoading(false)
            if (res && res.status) {
                handleChange({ target: { name: "fileUrl", value: res?.url } }, i)
                handleChange({ target: { name: "fileName", value: file?.name } }, i)
                // setTaxDetData(prev => ({ ...prev, pdf: res?.url }))
                toast.success("Document Uploaded Successfully")
            } else {

            }
        }
    }

    // handle oublish
    const handlePublish = async () => {
        const data = {
            "Data": docData?.map(item => ({
                "documentType": item.docType,
                "dueDate": moment(item?.dueDate).format("YYYY-MM-DD"),
                // "status": statusOfDoc(item?.dueDate),
                "pdf": item?.fileUrl,
                "pdfName": item?.fileName
            }))
        }
        // console.log("datadff", data)
        setIsLoading(true)
        const res = await HttpClient.requestData("add-expense-manage-document/" + setupDocData?.bookkeeperRegCode, "PUT", data)
        setIsLoading(false)
        if (res && res?.status) {
            toast.success("Document Published Successfully!")
            getSetupManageDocs()
            // setCallGetAcc(prev => !prev)
            getSetupManageDocs();
        } else {
            toast.error(res?.message)
        }
    }

    // get data
    const getSetupManageDocs = async () => {
        setIsLoading(true)
        // console.log("ddddddddddddrgrs")
        const res = await HttpClient.requestData("get-user-expense-manage-document", "GET")
        console.log("resffxcc", res?.data?.[0])
        setIsLoading(false)
        if (res && res?.status) {
            setSetupDocData(res?.data?.[0])
            if (res?.data) {
                const newData = res?.data?.[0]?.Data?.length !== 0
                    ?
                    res?.data?.[0]?.Data?.map(item => ({
                        docType: item?.documentType,
                        dueDate: moment(item?.dueDate).format("YYYY-MM-DD"),
                        // status: item,
                        fileUrl: item?.pdf,
                        fileName: item?.pdfName,
                    }))
                    :
                    initDocData
                setDocData(newData)
            }
        }
    }

    // send renuinder
    const handleSendReminder = async (item) => {
        const data = {
            "documentType": item?.docType,
            "dueDate": item?.dueDate,
            "status": statusOfDoc(item?.dueDate, item?.fileUrl),
            "language": currentLanguage === "eng" ? "EN" : "ES",

        }
        console.log("itemdff", item)
        setIsLoading(true)
        const res = await HttpClient.requestData("bookkeeper/send-missing-mail-user/" + setupDocData?._id, "POST", data)
        setIsLoading(false)
        if (res && res.status) {
            toast?.success("A Reminder Mail has been Sent to The User")
        } else {
            toast.error(res?.message)
        }
    }


    useEffect(() => {
        getSetupManageDocs();
    }, [profileData])


    return (
        <div>
            <div>
                <h4 className='bookSecModalHead'> Se123tup & Manage Documents</h4>
                <button
                    className='bookmodalAddBtn'
                    onClick={() => {
                        setDocData(prev => [InitBlankDocData, ...prev])
                    }}
                >
                    <i className="fa-solid fa-plus"></i>ADD
                </button>
                <div className='manageSliderInn'>
                    <div className='missingDocTbl'>
                        <table style={{ width: "100%" }}>
                            <thead>
                                <tr>
                                    <th>Document Type</th>
                                    <th>Due Date</th>
                                    <th>Status</th>
                                    {/* <th>Reminder</th> */}
                                    <th>Upload</th>
                                    <th>View File</th>
                                </tr>
                            </thead>
                            <tbody>
                                {docData?.map((item, i) => {
                                    const checkDate = moment(item?.dueDate).isAfter(moment().format())
                                    // console.log("checkDatevv", checkDate)
                                    return (
                                        <tr key={i}>
                                            <td>
                                                {/* <button className='bookModalBtn'>{item?.docType}</button> */}
                                                {/* <input type='text' className='bookModalBtn' /> */}
                                                <span
                                                    className='setDocManageTrushBtn'
                                                    onClick={() => {
                                                        setDocData(prev => prev.filter((ele, ind) => i !== ind))
                                                    }}
                                                >
                                                    <i class="fa-solid fa-trash"></i>
                                                </span>
                                                <input
                                                    type="text"
                                                    className="setupDocInp"
                                                    id="exampleFormControlInput1"
                                                    placeholder="Document Type"
                                                    name='docType'
                                                    value={item?.docType}
                                                    onChange={(e) => handleChange(e, i)}
                                                />
                                            </td>
                                            <td>
                                                {/* <button className='bookModalBtn'>Select</button> */}
                                                <input
                                                    type='date'
                                                    className='setupDocInp'
                                                    name='dueDate'
                                                    value={item?.dueDate}
                                                    onChange={(e) => handleChange(e, i)}
                                                />
                                            </td>
                                            <td>
                                                {
                                                    statusOfDoc(item?.dueDate, item?.fileUrl) === "Attached"
                                                    && <div className='text-success'>Attached</div>
                                                }
                                                {
                                                    statusOfDoc(item?.dueDate, item?.fileUrl) === "Missing"
                                                    && <div className='text-primary'>Missing</div>
                                                }
                                                {
                                                    statusOfDoc(item?.dueDate, item?.fileUrl) === "Past Due"
                                                    && <div className='text-danger'>Past Due</div>
                                                }
                                                {
                                                    statusOfDoc(item?.dueDate, item?.fileUrl) === "-"
                                                    && <div className='text-dark'>-</div>
                                                }
                                                {
                                                    // checkDate
                                                    //     ?
                                                    //     item?.fileUrl
                                                    //         ?
                                                    //         <div className='text-success'>Attached</div>
                                                    //         :
                                                    //         <div className='text-primary'>Missing</div>
                                                    //     :
                                                    //     <div className='text-danger'>Past Due</div>
                                                }
                                            </td>
                                            {/* <td>
                                                <button
                                                    className='buildCapTblBtn'
                                                    onClick={() => handleSendReminder(item)}
                                                >
                                                    Send
                                                </button>
                                            </td> */}
                                            <td >
                                                <div style={{ position: "relative" }}>
                                                    <button className='buildCapTblBtn'>Browse</button>
                                                    <input
                                                        className='setupDocFileInp'
                                                        type="file"
                                                        onChange={(e) => handlePdfUpload(e, i)}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                {
                                                    item?.fileUrl &&
                                                    <div className='d-flex'>
                                                        <a href={item?.fileUrl} target='_blank' className='buildCapMidlink'>{item?.fileName}</a>
                                                        (<span
                                                            className='text-danger'
                                                            style={{ cursor: "pointer" }}
                                                            onClick={() => {
                                                                handleChange({ target: { name: "fileUrl", value: "" } }, i)
                                                                handleChange({ target: { name: "fileName", value: "" } }, i)
                                                            }}
                                                        >
                                                            remove
                                                        </span>)
                                                    </div>
                                                }
                                            </td>
                                        </tr>
                                    )
                                })}

                            </tbody>
                        </table>
                    </div>
                </div>
                <button
                    className='bookSecPublish'
                    onClick={() => handlePublish()}
                >
                    Publish
                </button>
            </div>

        </div>
    )
}

export default SetUpManageDocsSubUser
