import React from 'react';
import './BookkeeperPrice.css';
import HttpClient from '../../../utils/HttpClient';
import { useNavigate } from 'react-router-dom';
import { useUserData } from '../../../Providers/UserWrapper';
import toast from 'react-hot-toast';

const BookKeeperPrice = () => {
    const navigate = useNavigate()
    const { getProfile, setProfileData, setIsLoading } = useUserData()
    const priceData = [
        {
            price: 14,
            user: 5
        },
        {
            price: 50,
            user: 10
        },
        {
            price: 100,
            user: 20
        }
    ]

    // pay
    const handlePay = async () => {
        setIsLoading(true);
        const res = await HttpClient.requestData("bookkeeper/subscription", "PUT");
        console.log("ressdd", res)
        if (res && res?.status) {
            getProfileBook();
        } else {
            setIsLoading(false);
        }
    }

    // get profile
    const getProfileBook = async () => {
        const result = await HttpClient.requestData("get-profile", "GET");
        // console.log('getProfile', result);
        if (result && result.success) {
            setProfileData(result.data[0]);
            setIsLoading(false);
            // navigate("/bookkeeper-manager");
            navigate("/manager");
            toast.success("Payment Successfull")
        } else {
            setIsLoading(false);
            toast?.error(result?.message || "error")
        }
    }

    return (
        <div>
            <div className="wrapper-card">

                {/* one */}

                {
                    priceData?.map((item, i) =>
                        <div className="card">
                            <div className="card-title">
                                <h3>Basic</h3>
                                {/* <h4>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</h4>    */}
                            </div>
                            <div className="card-price">
                                <h1>
                                    <sup>$</sup>
                                    {item?.price}
                                    <small>month</small>
                                </h1>
                            </div>
                            <div className="card-description">
                                <ul>
                                    <li>For {item?.user} User</li>
                                    {/* <li>Pellentesque hendrerit</li>
                            <li>Aliquam at orci aliquam</li>
                            <li>Praesent non sapien laoreet</li> */}
                                </ul>
                            </div>
                            <div className="card-action">
                                <button
                                    type="button"
                                    onClick={() => handlePay()}
                                >
                                    Pay
                                </button>
                            </div>
                        </div>
                    )
                }

            </div>
        </div>
    )
}

export default BookKeeperPrice;
