import React, { useState } from 'react'
import { useEffect } from 'react';
import { toast } from 'react-hot-toast';
import { BsArrowLeft, BsArrowRight } from 'react-icons/bs';
import { useLanguage } from '../../Providers/LanguageContext';
import HttpClient from '../../utils/HttpClient';

export default function SearchResultModal({ setIsSearchResult, searchResultData, handleRegultNext, handleRegultPrev, searchCount, allSearchData }) {
    const { language, currentLanguage } = useLanguage()

    console.log('searchResultData', searchResultData);

    // useEffect(() => {
    //     if (currentLanguage === 'eng') {
    //         setRegData((prev) => { return { ...prev, ln: "EN" } })
    //     } else {
    //         setRegData((prev) => { return { ...prev, ln: "ES" } })
    //     }
    // }, [currentLanguage])


    return (
        <>
            <div className='RegisterInvest'>
                <section className="Regiter InvestREgi" style={{ backgroundColor: "transparent" }}>

                    {/* <div className="heading">
                        <h1 style={{ fontWeight: "400" }}>{language.reg_inv}</h1>
                    </div> */}
                    <div onClick={() => setIsSearchResult(false)}>
                        <a href="#" className='closeIcon' style={{ right: "37px", top: "34px", color: "#fff" }}><i class="fa fa-times" aria-hidden="true"></i></a>
                    </div>
                    <section id="ConfirmMessage" style={{ padding: "0px" }}>
                        <div className='wraper wraper2'>
                            <h3>{language.confirm_inv_details}</h3>

                            <div className='search-result-arrow'>
                                <div onClick={handleRegultPrev}><BsArrowLeft /></div>
                                <small style={{ fontSize: "20px", margin: "10px" }}>{searchCount} of {allSearchData.length}</small>
                                <div onClick={handleRegultNext}><BsArrowRight /></div>
                            </div>

                            <div className='row'>
                                <div className='col-xl-12 col-lg-12 col-md-12 col-12 mb-5'>

                                    <div className='row mb-2' style={{ alignItems: "center" }}>
                                        <div className='col-sm-3'>
                                            <h4>{language.register_investment}</h4>
                                        </div>
                                        <div className='col-sm-9'>
                                            <h5>{searchResultData.InvestorOrgName}</h5>
                                        </div>
                                    </div>

                                    <div className='row mb-2' style={{ alignItems: "center" }}>
                                        <div className='col-sm-3'>
                                            <h4>{language.individual_name}</h4>
                                        </div>
                                        <div className='col-sm-4'>
                                            <h5>{searchResultData.IndividualName}</h5>
                                        </div>
                                    </div>

                                    <div className='row mb-2' style={{ alignItems: "center" }}>
                                        <div className='col-sm-3'>
                                            <h4>{language.email_placeholder}</h4>
                                        </div>
                                        <div className='col-sm-4'>
                                            <h5>{searchResultData.Email}</h5>
                                        </div>
                                    </div>

                                    <div className='row mb-2' style={{ alignItems: "center" }}>
                                        <div className='col-sm-3'>
                                            <h4>{language.investment_type}</h4>
                                        </div>
                                        <div className='col-sm-4'>
                                            <h5>{searchResultData.InvestmentType}</h5>
                                        </div>
                                        <div className='col-sm-3'>
                                            <h4>{language.investment_amt}</h4>
                                        </div>
                                        <div className='col-sm-2'>
                                            <h5>{searchResultData.InvestmentAmt}</h5>
                                        </div>
                                    </div>

                                    <div className='row mb-2' style={{ alignItems: "center" }}>
                                        <div className='col-sm-3'>
                                            <h4>{language.investment_date}</h4>
                                        </div>
                                        <div className='col-sm-4'>
                                            <h5>{searchResultData.InvestmentDate}</h5>
                                        </div>
                                    </div>

                                    <div className='row mb-2' style={{ alignItems: "center" }}>
                                        <div className='col-sm-3'>
                                            <h4>{language.investment_url}</h4>
                                        </div>
                                        <div className='col-sm-4'>
                                            <h5>{searchResultData.InvestmentURL}</h5>
                                        </div>
                                    </div>
                                </div>


                                <div className='col-xl-12 col-lg-12 col-md-12 col-12'>
                                    <div className='row mb-2' style={{ alignItems: "center" }}>
                                        <div className='col-sm-3'>
                                            <h4>{language.register_investment}</h4>
                                        </div>
                                        <div className='col-sm-9'>
                                            <h5>{searchResultData.InvestorOrgName}</h5>
                                        </div>
                                    </div>

                                    <div className='row mb-2' style={{ alignItems: "center" }}>
                                        <div className='col-sm-3'>
                                            <h4>{language.business_name}</h4>
                                        </div>
                                        <div className='col-sm-4'>
                                            <h5>{searchResultData.InvestmentOrgName}</h5>
                                        </div>
                                    </div>

                                    <div className='row mb-2' style={{ alignItems: "center" }}>
                                        <div className='col-sm-3'>
                                            <h4>{language.key_person_name}</h4>
                                        </div>
                                        <div className='col-sm-4'>
                                            <h5>{searchResultData.KeypersonName}</h5>
                                        </div>
                                    </div>

                                    <div className='row mb-2' style={{ alignItems: "center" }}>
                                        <div className='col-sm-3'>
                                            <h4>{language.investment_email}</h4>
                                        </div>
                                        <div className='col-sm-4'>
                                            <h5>{searchResultData.InvestmentEmail}</h5>
                                        </div>
                                    </div>

                                    <div className='row mb-2' style={{ alignItems: "center" }}>
                                        <div className='col-sm-3'>
                                            <h4>Tax ID #</h4>
                                        </div>
                                        <div className='col-sm-4'>
                                            <h5>{searchResultData.TaxID}</h5>
                                        </div>
                                        <div className='col-sm-1'>
                                            <h4>RFC ID</h4>
                                        </div>
                                        <div className='col-sm-4'>
                                            <h5>{searchResultData.RfcID}</h5>
                                        </div>
                                    </div>

                                    <div className='row mb-2' style={{ alignItems: "center" }}>
                                        <div className='col-sm-3'>
                                            <h4>SS# / CURP ID</h4>
                                        </div>
                                        <div className='col-sm-4'>
                                            <h5>{searchResultData.CurpID}</h5>
                                        </div>
                                    </div>

                                </div>

                            </div>

                        </div>
                        {/* <div className='btnPart'>
                                <button onClick={() => navigate("/")} className='btn btn-light mr-2'>{language.cancel}</button>
                                <button className='btn btn-light mr-2'>{language.review}</button>
                                <button className='btn btn-light' onClick={handleConfirm}>{language.confirm}</button>
                            </div> */}
                    </section>
                </section>
            </div >
        </>
    )
}
