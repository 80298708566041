import React, { useEffect, useState } from 'react'
import { useLanguage } from '../Providers/LanguageContext'

function TimeFrameComp({getTime,value}) {
  const {language} = useLanguage()
    const [activeTime,setActiveTime] = useState("")

    useEffect(()=>{
      if(activeTime){
        getTime(activeTime)
      }
    },[activeTime])
    return (
        <div className='top-btn'>
            <button className={`calender-btn ${value==="day"?"timeframe-btn-active":""}`}
             onClick={()=>setActiveTime("day")}>{language.day}</button>
            <button className={`calender-btn ${value==="week"?"timeframe-btn-active":""}`}
             onClick={()=>setActiveTime("week")}>{language.week}</button>
            <button className={`calender-btn ${value==="month"?"timeframe-btn-active":""}`}
             onClick={()=>setActiveTime("month")}>{language.month}</button>
            <button className={`calender-btn ${value==="year"?"timeframe-btn-active":""}`}
             onClick={()=>setActiveTime("year")}>{language.year}</button>
        </div>
    )
}

export default TimeFrameComp