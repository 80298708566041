import React, { useEffect, useState } from 'react'
import HttpClient from '../../../utils/HttpClient'
import toast from 'react-hot-toast'
import { useLanguage } from '../../../Providers/LanguageContext';
import CustomLoaderLine from '../../../Component/Loader/CustomLoaderLine';
import { useUserData } from '../../../Providers/UserWrapper';

const BuildBudgetForecast = ({ setCapitalCsv, setPdfCreate, setPdfLoader, setEmailSendFunc }) => {
    const { language, currentLanguage } = useLanguage();
    const { profileData, setIsLoading } = useUserData();

    const employeeCost = {
        empCost: "",
        jan: "",
        feb: "",
        mar: "",
        apr: "",
        may: "",
        jun: "",
        jul: "",
        aug: "",
        sep: "",
        oct: "",
        nov: "",
        dec: "",
        year: ""
    }

    const officeCost = {
        offCost: "",
        jan: "",
        feb: "",
        mar: "",
        apr: "",
        may: "",
        jun: "",
        jul: "",
        aug: "",
        sep: "",
        oct: "",
        nov: "",
        dec: "",
        year: ""
    }

    const marketingCost = {
        markCost: "",
        jan: "",
        feb: "",
        mar: "",
        apr: "",
        may: "",
        jun: "",
        jul: "",
        aug: "",
        sep: "",
        oct: "",
        nov: "",
        dec: "",
        year: ""
    }

    const trainingCost = {
        trainCost: "",
        jan: "",
        feb: "",
        mar: "",
        apr: "",
        may: "",
        jun: "",
        jul: "",
        aug: "",
        sep: "",
        oct: "",
        nov: "",
        dec: "",
        year: ""
    }


    // >>>>Set Employee Cost Default Data<<<<
    const defaultOwnerData = [
        {
            empCost: "Wages",
            jan: "",
            feb: "",
            mar: "",
            apr: "",
            may: "",
            jun: "",
            jul: "",
            aug: "",
            sep: "",
            oct: "",
            nov: "",
            dec: "",
            year: ""
        },
        {
            empCost: "Benefits",
            jan: "",
            feb: "",
            mar: "",
            apr: "",
            may: "",
            jun: "",
            jul: "",
            aug: "",
            sep: "",
            oct: "",
            nov: "",
            dec: "",
            year: ""
        }
    ]

    // >>>>Set Office Cost Default Data<<<<
    const defaultOffData = [
        {
            offCost: "Office Lease",
            jan: "",
            feb: "",
            mar: "",
            apr: "",
            may: "",
            jun: "",
            jul: "",
            aug: "",
            sep: "",
            oct: "",
            nov: "",
            dec: "",
            year: ""
        },
        {
            offCost: "Gas",
            jan: "",
            feb: "",
            mar: "",
            apr: "",
            may: "",
            jun: "",
            jul: "",
            aug: "",
            sep: "",
            oct: "",
            nov: "",
            dec: "",
            year: ""
        },
        {
            offCost: "Electric",
            jan: "",
            feb: "",
            mar: "",
            apr: "",
            may: "",
            jun: "",
            jul: "",
            aug: "",
            sep: "",
            oct: "",
            nov: "",
            dec: "",
            year: ""
        },
        {
            offCost: "Water",
            jan: "",
            feb: "",
            mar: "",
            apr: "",
            may: "",
            jun: "",
            jul: "",
            aug: "",
            sep: "",
            oct: "",
            nov: "",
            dec: "",
            year: ""
        },
        {
            offCost: "Telephone",
            jan: "",
            feb: "",
            mar: "",
            apr: "",
            may: "",
            jun: "",
            jul: "",
            aug: "",
            sep: "",
            oct: "",
            nov: "",
            dec: "",
            year: ""
        },
        {
            offCost: "Internet Access",
            jan: "",
            feb: "",
            mar: "",
            apr: "",
            may: "",
            jun: "",
            jul: "",
            aug: "",
            sep: "",
            oct: "",
            nov: "",
            dec: "",
            year: ""
        },
        {
            offCost: "Office Supplies",
            jan: "",
            feb: "",
            mar: "",
            apr: "",
            may: "",
            jun: "",
            jul: "",
            aug: "",
            sep: "",
            oct: "",
            nov: "",
            dec: "",
            year: ""
        }, {
            offCost: "Security",
            jan: "",
            feb: "",
            mar: "",
            apr: "",
            may: "",
            jun: "",
            jul: "",
            aug: "",
            sep: "",
            oct: "",
            nov: "",
            dec: "",
            year: ""
        }
    ]

    // >>>>Set Marketing Cost Default Data<<<<
    const defaultMarketData = [
        {
            markCost: "Website hosting",
            jan: "",
            feb: "",
            mar: "",
            apr: "",
            may: "",
            jun: "",
            jul: "",
            aug: "",
            sep: "",
            oct: "",
            nov: "",
            dec: "",
            year: ""
        },
        {
            markCost: "Website updates",
            jan: "",
            feb: "",
            mar: "",
            apr: "",
            may: "",
            jun: "",
            jul: "",
            aug: "",
            sep: "",
            oct: "",
            nov: "",
            dec: "",
            year: ""
        },
        {
            markCost: "Collateral preparation",
            jan: "",
            feb: "",
            mar: "",
            apr: "",
            may: "",
            jun: "",
            jul: "",
            aug: "",
            sep: "",
            oct: "",
            nov: "",
            dec: "",
            year: ""
        },
        {
            markCost: "Collateral printing",
            jan: "",
            feb: "",
            mar: "",
            apr: "",
            may: "",
            jun: "",
            jul: "",
            aug: "",
            sep: "",
            oct: "",
            nov: "",
            dec: "",
            year: ""
        },
        {
            markCost: "Marketing events",
            jan: "",
            feb: "",
            mar: "",
            apr: "",
            may: "",
            jun: "",
            jul: "",
            aug: "",
            sep: "",
            oct: "",
            nov: "",
            dec: "",
            year: ""
        },
        {
            markCost: "Internet Access",
            jan: "",
            feb: "",
            mar: "",
            apr: "",
            may: "",
            jun: "",
            jul: "",
            aug: "",
            sep: "",
            oct: "",
            nov: "",
            dec: "",
            year: ""
        },
        {
            markCost: "Miscellaneous expenses",
            jan: "",
            feb: "",
            mar: "",
            apr: "",
            may: "",
            jun: "",
            jul: "",
            aug: "",
            sep: "",
            oct: "",
            nov: "",
            dec: "",
            year: ""
        }
    ]

    // >>>>Set Training Cost Default Data<<<<
    const defaultTrainingData = [
        {
            trainCost: "Training Classes",
            jan: "",
            feb: "",
            mar: "",
            apr: "",
            may: "",
            jun: "",
            jul: "",
            aug: "",
            sep: "",
            oct: "",
            nov: "",
            dec: "",
            year: ""
        },
        {
            trainCost: "Training-related travel costs",
            jan: "",
            feb: "",
            mar: "",
            apr: "",
            may: "",
            jun: "",
            jul: "",
            aug: "",
            sep: "",
            oct: "",
            nov: "",
            dec: "",
            year: ""
        },
        {
            trainCost: "Collateral preparation",
            jan: "",
            feb: "",
            mar: "",
            apr: "",
            may: "",
            jun: "",
            jul: "",
            aug: "",
            sep: "",
            oct: "",
            nov: "",
            dec: "",
            year: ""
        }
    ]

    const [ownerData, setOwnerData] = useState(defaultOwnerData);
    const [offCostData, setOffCostData] = useState(defaultOffData);
    const [markCostData, setMarketingCostData] = useState(defaultMarketData)
    const [trainingCostData, setTrainingCostData] = useState(defaultTrainingData)

    const [isDelOwner, setIsDelOwner] = useState(false);
    const [isDelOfficeCost, setIsDelOfficeCost] = useState(false);
    const [isDelMarketingCost, setIsDelMarketingCost] = useState(false);
    const [isDelTrainingCost, setIsDelTrainingCost] = useState(false);

    const [subTotals, setSubTotals] = useState({});
    const [officeSubTotal, setOfficeSubTotal] = useState({})
    const [marketingSubTotal, setMarketingSubTotal] = useState({})
    const [trainingSubTotal, setTrainingSubTotal] = useState({})

    const [totalSubTotals, setTotalSubTotals] = useState({});
    const [cumulativeSubTotals, setCumulativeSubTotals] = useState({});

    const [isSave, setIsSave] = useState(true);
    const [loading, setLoading] = useState(false);
    const [saveLoading, setSaveLoading] = useState(false);

    console.log("fgsf5d4", cumulativeSubTotals)
    // >>>>Showing Months Value To Each Section Row-wisw<<<<
    const months = [
        "Jan-24", "Feb-24", "Mar-24", "Apr-24", "May-24", "Jun-24",
        "Jul-24", "Aug-24", "Sep-24", "Oct-24", "Nov-24", "Dec-24"
    ];

    // >>>>onChange ownwr / admore<<<<
    const handleChangeOwner = (e, ind) => {
        const { name, value } = e.target;
        const data = ownerData?.map((item, i) => {

            if (i === ind) {
                return { ...item, [name]: value }
            } else {
                return item
            }
        })
        setOwnerData(data);
    }

    // >>>>onChange Office Cost / admore<<<<
    const handleChangeOffice = (e, ind) => {
        const { name, value } = e.target;
        const data = offCostData?.map((item, i) => {

            if (i === ind) {
                return { ...item, [name]: value }
            } else {
                return item
            }
        })
        setOffCostData(data);
    }

    // >>>>onChange Marketing Cost / admore<<<<
    const handleChangeMarketing = (e, ind) => {
        const { name, value } = e.target;
        const data = markCostData?.map((item, i) => {

            if (i === ind) {
                return { ...item, [name]: value }
            } else {
                return item
            }
        })
        setMarketingCostData(data);
    }

    // >>>>onChange Training Cost / admore<<<<
    const handleChangeTraining = (e, ind) => {
        const { name, value } = e.target;
        const data = trainingCostData?.map((item, i) => {

            if (i === ind) {
                return { ...item, [name]: value }
            } else {
                return item
            }
        })
        setTrainingCostData(data);
    }

    // >>>>Get Budget Forcast Report<<<<
    const getBudgetForcastReport = async () => {
        const res = await HttpClient.requestData('reportbuilder-view-budget-statement', 'GET');
        if (res && res?.status && res?.data !== null) {

            const employeeCostData = (res.data.EmployeeCost || []).map((cost) => ({
                empCost: cost.title,
                jan: cost.prices.find(price => price.month === 'Jan')?.amount || "",
                feb: cost.prices.find(price => price.month === 'Feb')?.amount || "",
                mar: cost.prices.find(price => price.month === 'Mar')?.amount || "",
                apr: cost.prices.find(price => price.month === 'Apr')?.amount || "",
                may: cost.prices.find(price => price.month === 'May')?.amount || "",
                jun: cost.prices.find(price => price.month === 'Jun')?.amount || "",
                jul: cost.prices.find(price => price.month === 'Jul')?.amount || "",
                aug: cost.prices.find(price => price.month === 'Aug')?.amount || "",
                sep: cost.prices.find(price => price.month === 'Sep')?.amount || "",
                oct: cost.prices.find(price => price.month === 'Oct')?.amount || "",
                nov: cost.prices.find(price => price.month === 'Nov')?.amount || "",
                dec: cost.prices.find(price => price.month === 'Dec')?.amount || "",
                year: ""
            }));

            const officeCostData = (res.data.OfficeCost || []).map((cost) => ({
                offCost: cost.title,
                jan: cost.prices.find(price => price.month === 'Jan')?.amount || "",
                feb: cost.prices.find(price => price.month === 'Feb')?.amount || "",
                mar: cost.prices.find(price => price.month === 'Mar')?.amount || "",
                apr: cost.prices.find(price => price.month === 'Apr')?.amount || "",
                may: cost.prices.find(price => price.month === 'May')?.amount || "",
                jun: cost.prices.find(price => price.month === 'Jun')?.amount || "",
                jul: cost.prices.find(price => price.month === 'Jul')?.amount || "",
                aug: cost.prices.find(price => price.month === 'Aug')?.amount || "",
                sep: cost.prices.find(price => price.month === 'Sep')?.amount || "",
                oct: cost.prices.find(price => price.month === 'Oct')?.amount || "",
                nov: cost.prices.find(price => price.month === 'Nov')?.amount || "",
                dec: cost.prices.find(price => price.month === 'Dec')?.amount || "",
                year: ""
            }));

            const marketingCostData = (res.data.MarketingCost || []).map((cost) => ({
                markCost: cost.title,
                jan: cost.prices.find(price => price.month === 'Jan')?.amount || "",
                feb: cost.prices.find(price => price.month === 'Feb')?.amount || "",
                mar: cost.prices.find(price => price.month === 'Mar')?.amount || "",
                apr: cost.prices.find(price => price.month === 'Apr')?.amount || "",
                may: cost.prices.find(price => price.month === 'May')?.amount || "",
                jun: cost.prices.find(price => price.month === 'Jun')?.amount || "",
                jul: cost.prices.find(price => price.month === 'Jul')?.amount || "",
                aug: cost.prices.find(price => price.month === 'Aug')?.amount || "",
                sep: cost.prices.find(price => price.month === 'Sep')?.amount || "",
                oct: cost.prices.find(price => price.month === 'Oct')?.amount || "",
                nov: cost.prices.find(price => price.month === 'Nov')?.amount || "",
                dec: cost.prices.find(price => price.month === 'Dec')?.amount || "",
                year: ""
            }));

            const trainingCostData = (res.data.TrainingCost || []).map((cost) => ({
                trainCost: cost.title,
                jan: cost.prices.find(price => price.month === 'Jan')?.amount || "",
                feb: cost.prices.find(price => price.month === 'Feb')?.amount || "",
                mar: cost.prices.find(price => price.month === 'Mar')?.amount || "",
                apr: cost.prices.find(price => price.month === 'Apr')?.amount || "",
                may: cost.prices.find(price => price.month === 'May')?.amount || "",
                jun: cost.prices.find(price => price.month === 'Jun')?.amount || "",
                jul: cost.prices.find(price => price.month === 'Jul')?.amount || "",
                aug: cost.prices.find(price => price.month === 'Aug')?.amount || "",
                sep: cost.prices.find(price => price.month === 'Sep')?.amount || "",
                oct: cost.prices.find(price => price.month === 'Oct')?.amount || "",
                nov: cost.prices.find(price => price.month === 'Nov')?.amount || "",
                dec: cost.prices.find(price => price.month === 'Dec')?.amount || "",
                year: ""
            }));

            setOwnerData(employeeCostData.length > 0 ? employeeCostData : defaultOwnerData);
            setOffCostData(officeCostData.length > 0 ? officeCostData : defaultOffData);
            setMarketingCostData(marketingCostData.length > 0 ? marketingCostData : defaultMarketData);
            setTrainingCostData(trainingCostData.length > 0 ? trainingCostData : defaultTrainingData);
        } else {
            setOwnerData(defaultOwnerData);
            setOffCostData(defaultOffData);
            setMarketingCostData(defaultMarketData);
            setTrainingCostData(defaultTrainingData);
        }
    }

    // >>>>Handle Save<<<<
    const handleSave = async () => {

        // >>>>Validation Logic<<<<
        const validateData = (data, key) => data.every(item => item[key] && item[key].trim() !== '');

        const isEmpCostValid = validateData(ownerData, 'empCost');
        const isOffCostValid = validateData(offCostData, 'offCost');
        const isMarkCostValid = validateData(markCostData, 'markCost');
        const isTrainCostValid = validateData(trainingCostData, 'trainCost');

        if (!isEmpCostValid) {
            toast.error("Employee Cost input must have a value...!");
            return;
        }

        if (!isOffCostValid) {
            toast.error("Office Cost input must have a value...!");
            return;
        }

        if (!isMarkCostValid) {
            toast.error("Marketing Cost input must have a value...!");
            return;
        }

        if (!isTrainCostValid) {
            toast.error("Training Cost input must have a value...!");
            return;
        }

        // >>>>Transforming Data For Employee Cost<<<<
        const transformedEmpCostData = ownerData.map(item => ({
            title: item.empCost,
            prices: [
                { month: 'Jan', amount: parseFloat(item.jan) || 0 },
                { month: 'Feb', amount: parseFloat(item.feb) || 0 },
                { month: 'Mar', amount: parseFloat(item.mar) || 0 },
                { month: 'Apr', amount: parseFloat(item.apr) || 0 },
                { month: 'May', amount: parseFloat(item.may) || 0 },
                { month: 'Jun', amount: parseFloat(item.jun) || 0 },
                { month: 'Jul', amount: parseFloat(item.jul) || 0 },
                { month: 'Aug', amount: parseFloat(item.aug) || 0 },
                { month: 'Sep', amount: parseFloat(item.sep) || 0 },
                { month: 'Oct', amount: parseFloat(item.oct) || 0 },
                { month: 'Nov', amount: parseFloat(item.nov) || 0 },
                { month: 'Dec', amount: parseFloat(item.dec) || 0 }
            ].filter(price => price.amount !== 0)
        }));

        // >>>>Transforming Data For Office Cost<<<<
        const transformedOffCostData = offCostData.map(item => ({
            title: item.offCost,
            prices: [
                { month: 'Jan', amount: parseFloat(item.jan) || 0 },
                { month: 'Feb', amount: parseFloat(item.feb) || 0 },
                { month: 'Mar', amount: parseFloat(item.mar) || 0 },
                { month: 'Apr', amount: parseFloat(item.apr) || 0 },
                { month: 'May', amount: parseFloat(item.may) || 0 },
                { month: 'Jun', amount: parseFloat(item.jun) || 0 },
                { month: 'Jul', amount: parseFloat(item.jul) || 0 },
                { month: 'Aug', amount: parseFloat(item.aug) || 0 },
                { month: 'Sep', amount: parseFloat(item.sep) || 0 },
                { month: 'Oct', amount: parseFloat(item.oct) || 0 },
                { month: 'Nov', amount: parseFloat(item.nov) || 0 },
                { month: 'Dec', amount: parseFloat(item.dec) || 0 }
            ].filter(price => price.amount !== 0)
        }));

        // >>>>Transforming Data For Marketing Cost<<<<
        const transformedMarkCostData = markCostData.map(item => ({
            title: item.markCost,
            prices: [
                { month: 'Jan', amount: parseFloat(item.jan) || 0 },
                { month: 'Feb', amount: parseFloat(item.feb) || 0 },
                { month: 'Mar', amount: parseFloat(item.mar) || 0 },
                { month: 'Apr', amount: parseFloat(item.apr) || 0 },
                { month: 'May', amount: parseFloat(item.may) || 0 },
                { month: 'Jun', amount: parseFloat(item.jun) || 0 },
                { month: 'Jul', amount: parseFloat(item.jul) || 0 },
                { month: 'Aug', amount: parseFloat(item.aug) || 0 },
                { month: 'Sep', amount: parseFloat(item.sep) || 0 },
                { month: 'Oct', amount: parseFloat(item.oct) || 0 },
                { month: 'Nov', amount: parseFloat(item.nov) || 0 },
                { month: 'Dec', amount: parseFloat(item.dec) || 0 }
            ].filter(price => price.amount !== 0)
        }));

        // >>>>Transforming Data For Trainig Cost<<<<
        const transformedTrainingCostData = trainingCostData.map(item => ({
            title: item.trainCost,
            prices: [
                { month: 'Jan', amount: parseFloat(item.jan) || 0 },
                { month: 'Feb', amount: parseFloat(item.feb) || 0 },
                { month: 'Mar', amount: parseFloat(item.mar) || 0 },
                { month: 'Apr', amount: parseFloat(item.apr) || 0 },
                { month: 'May', amount: parseFloat(item.may) || 0 },
                { month: 'Jun', amount: parseFloat(item.jun) || 0 },
                { month: 'Jul', amount: parseFloat(item.jul) || 0 },
                { month: 'Aug', amount: parseFloat(item.aug) || 0 },
                { month: 'Sep', amount: parseFloat(item.sep) || 0 },
                { month: 'Oct', amount: parseFloat(item.oct) || 0 },
                { month: 'Nov', amount: parseFloat(item.nov) || 0 },
                { month: 'Dec', amount: parseFloat(item.dec) || 0 }
            ].filter(price => price.amount !== 0)
        }));

        if (isSave) {
            const data = {
                EmployeeCost: transformedEmpCostData,
                OfficeCost: transformedOffCostData,
                MarketingCost: transformedMarkCostData,
                TrainingCost: transformedTrainingCostData
            }
            // console.log("df654gh65df", data,)
            // return
            setSaveLoading(true);
            const res = await HttpClient.requestData('reportbuilder-add-budget-statement', 'POST', data);
            if (res && res?.status) {
                toast.success(language.budget_forecast)
                setSaveLoading(false);
                setIsSave(!isSave);
                getBudgetForcastReport()
            } else {
                toast.error(res?.message || "Something went wrong...!")
                setSaveLoading(false);
            }
        } else {
            setIsSave(!isSave);

        }

    }

    // >>>>Handle Build Report<<<<
    const handleBuildRepport = async () => {
        // >>>>Transforming Data For Employee Cost<<<<
        const transformedEmpCostData = ownerData.map(item => ({
            title: item.empCost,
            prices: [
                { month: 'Jan', amount: parseFloat(item.jan) || 0 },
                { month: 'Feb', amount: parseFloat(item.feb) || 0 },
                { month: 'Mar', amount: parseFloat(item.mar) || 0 },
                { month: 'Apr', amount: parseFloat(item.apr) || 0 },
                { month: 'May', amount: parseFloat(item.may) || 0 },
                { month: 'Jun', amount: parseFloat(item.jun) || 0 },
                { month: 'Jul', amount: parseFloat(item.jul) || 0 },
                { month: 'Aug', amount: parseFloat(item.aug) || 0 },
                { month: 'Sep', amount: parseFloat(item.sep) || 0 },
                { month: 'Oct', amount: parseFloat(item.oct) || 0 },
                { month: 'Nov', amount: parseFloat(item.nov) || 0 },
                { month: 'Dec', amount: parseFloat(item.dec) || 0 }
            ].filter(price => price.amount !== 0)
        }));

        // >>>>Transforming Data For Office Cost<<<<
        const transformedOffCostData = offCostData.map(item => ({
            title: item.offCost,
            prices: [
                { month: 'Jan', amount: parseFloat(item.jan) || 0 },
                { month: 'Feb', amount: parseFloat(item.feb) || 0 },
                { month: 'Mar', amount: parseFloat(item.mar) || 0 },
                { month: 'Apr', amount: parseFloat(item.apr) || 0 },
                { month: 'May', amount: parseFloat(item.may) || 0 },
                { month: 'Jun', amount: parseFloat(item.jun) || 0 },
                { month: 'Jul', amount: parseFloat(item.jul) || 0 },
                { month: 'Aug', amount: parseFloat(item.aug) || 0 },
                { month: 'Sep', amount: parseFloat(item.sep) || 0 },
                { month: 'Oct', amount: parseFloat(item.oct) || 0 },
                { month: 'Nov', amount: parseFloat(item.nov) || 0 },
                { month: 'Dec', amount: parseFloat(item.dec) || 0 }
            ].filter(price => price.amount !== 0)
        }));

        // >>>>Transforming Data For Marketing Cost<<<<
        const transformedMarkCostData = markCostData.map(item => ({
            title: item.markCost,
            prices: [
                { month: 'Jan', amount: parseFloat(item.jan) || 0 },
                { month: 'Feb', amount: parseFloat(item.feb) || 0 },
                { month: 'Mar', amount: parseFloat(item.mar) || 0 },
                { month: 'Apr', amount: parseFloat(item.apr) || 0 },
                { month: 'May', amount: parseFloat(item.may) || 0 },
                { month: 'Jun', amount: parseFloat(item.jun) || 0 },
                { month: 'Jul', amount: parseFloat(item.jul) || 0 },
                { month: 'Aug', amount: parseFloat(item.aug) || 0 },
                { month: 'Sep', amount: parseFloat(item.sep) || 0 },
                { month: 'Oct', amount: parseFloat(item.oct) || 0 },
                { month: 'Nov', amount: parseFloat(item.nov) || 0 },
                { month: 'Dec', amount: parseFloat(item.dec) || 0 }
            ].filter(price => price.amount !== 0)
        }));

        // >>>>Transforming Data For Trainig Cost<<<<
        const transformedTrainingCostData = trainingCostData.map(item => ({
            title: item.trainCost,
            prices: [
                { month: 'Jan', amount: parseFloat(item.jan) || 0 },
                { month: 'Feb', amount: parseFloat(item.feb) || 0 },
                { month: 'Mar', amount: parseFloat(item.mar) || 0 },
                { month: 'Apr', amount: parseFloat(item.apr) || 0 },
                { month: 'May', amount: parseFloat(item.may) || 0 },
                { month: 'Jun', amount: parseFloat(item.jun) || 0 },
                { month: 'Jul', amount: parseFloat(item.jul) || 0 },
                { month: 'Aug', amount: parseFloat(item.aug) || 0 },
                { month: 'Sep', amount: parseFloat(item.sep) || 0 },
                { month: 'Oct', amount: parseFloat(item.oct) || 0 },
                { month: 'Nov', amount: parseFloat(item.nov) || 0 },
                { month: 'Dec', amount: parseFloat(item.dec) || 0 }
            ].filter(price => price.amount !== 0)
        }));


        if (isSave) {
            toast.error("Please Save First");
            return
        }

        const data = {
            EmployeeCost: transformedEmpCostData,
            OfficeCost: transformedOffCostData,
            MarketingCost: transformedMarkCostData,
            TrainingCost: transformedTrainingCostData
        }
        // console.log("df654gh65df", data,)
        // return
        setLoading(true);
        const res = await HttpClient.requestData('reportbuilder-add-budget-statement', 'POST', data);
        if (res && res?.status) {
            setLoading(false);
            toast.success(language.report_build_succ);
            getBudgetForcastReport()
        } else {
            toast.error(res?.message || "Something went wrong...!")
            setLoading(false);
        }

    }

    // >>>>Download PDF<<<<
    const handlePdfDownload = async () => {
        // if (isSave) {
        //     toast.error("Please Save First");
        //     return
        // }

        setPdfLoader(true);
        setIsLoading(true);
        const res = await HttpClient.requestData("reportbuilder/download/pdf/budgetforecast-Statement", "GET", {})
        setPdfLoader(false);
        setIsLoading(false);
        if (res && res?.status) {
            window.open(res?.data, "_blank")
        } else {
            toast.error(res?.message || "Something Wrong!")
        }
    }

    // >>>>Sending mail<<<<
    const sendCapatialMail = async (emailData, oncloseSendModal) => {
        if (isSave) {
            return toast.error("Please Save First");
        } else {

            // >>>>Transforming Data For Employee Cost<<<<
            const transformedEmpCostData = ownerData.map(item => ({
                title: item.empCost,
                prices: [
                    { month: 'Jan', amount: parseFloat(item.jan) || 0 },
                    { month: 'Feb', amount: parseFloat(item.feb) || 0 },
                    { month: 'Mar', amount: parseFloat(item.mar) || 0 },
                    { month: 'Apr', amount: parseFloat(item.apr) || 0 },
                    { month: 'May', amount: parseFloat(item.may) || 0 },
                    { month: 'Jun', amount: parseFloat(item.jun) || 0 },
                    { month: 'Jul', amount: parseFloat(item.jul) || 0 },
                    { month: 'Aug', amount: parseFloat(item.aug) || 0 },
                    { month: 'Sep', amount: parseFloat(item.sep) || 0 },
                    { month: 'Oct', amount: parseFloat(item.oct) || 0 },
                    { month: 'Nov', amount: parseFloat(item.nov) || 0 },
                    { month: 'Dec', amount: parseFloat(item.dec) || 0 }
                ].filter(price => price.amount !== 0)
            }));

            // >>>>Transforming Data For Office Cost<<<<
            const transformedOffCostData = offCostData.map(item => ({
                title: item.offCost,
                prices: [
                    { month: 'Jan', amount: parseFloat(item.jan) || 0 },
                    { month: 'Feb', amount: parseFloat(item.feb) || 0 },
                    { month: 'Mar', amount: parseFloat(item.mar) || 0 },
                    { month: 'Apr', amount: parseFloat(item.apr) || 0 },
                    { month: 'May', amount: parseFloat(item.may) || 0 },
                    { month: 'Jun', amount: parseFloat(item.jun) || 0 },
                    { month: 'Jul', amount: parseFloat(item.jul) || 0 },
                    { month: 'Aug', amount: parseFloat(item.aug) || 0 },
                    { month: 'Sep', amount: parseFloat(item.sep) || 0 },
                    { month: 'Oct', amount: parseFloat(item.oct) || 0 },
                    { month: 'Nov', amount: parseFloat(item.nov) || 0 },
                    { month: 'Dec', amount: parseFloat(item.dec) || 0 }
                ].filter(price => price.amount !== 0)
            }));

            // >>>>Transforming Data For Marketing Cost<<<<
            const transformedMarkCostData = markCostData.map(item => ({
                title: item.markCost,
                prices: [
                    { month: 'Jan', amount: parseFloat(item.jan) || 0 },
                    { month: 'Feb', amount: parseFloat(item.feb) || 0 },
                    { month: 'Mar', amount: parseFloat(item.mar) || 0 },
                    { month: 'Apr', amount: parseFloat(item.apr) || 0 },
                    { month: 'May', amount: parseFloat(item.may) || 0 },
                    { month: 'Jun', amount: parseFloat(item.jun) || 0 },
                    { month: 'Jul', amount: parseFloat(item.jul) || 0 },
                    { month: 'Aug', amount: parseFloat(item.aug) || 0 },
                    { month: 'Sep', amount: parseFloat(item.sep) || 0 },
                    { month: 'Oct', amount: parseFloat(item.oct) || 0 },
                    { month: 'Nov', amount: parseFloat(item.nov) || 0 },
                    { month: 'Dec', amount: parseFloat(item.dec) || 0 }
                ].filter(price => price.amount !== 0)
            }));

            // >>>>Transforming Data For Trainig Cost<<<<
            const transformedTrainingCostData = trainingCostData.map(item => ({
                title: item.trainCost,
                prices: [
                    { month: 'Jan', amount: parseFloat(item.jan) || 0 },
                    { month: 'Feb', amount: parseFloat(item.feb) || 0 },
                    { month: 'Mar', amount: parseFloat(item.mar) || 0 },
                    { month: 'Apr', amount: parseFloat(item.apr) || 0 },
                    { month: 'May', amount: parseFloat(item.may) || 0 },
                    { month: 'Jun', amount: parseFloat(item.jun) || 0 },
                    { month: 'Jul', amount: parseFloat(item.jul) || 0 },
                    { month: 'Aug', amount: parseFloat(item.aug) || 0 },
                    { month: 'Sep', amount: parseFloat(item.sep) || 0 },
                    { month: 'Oct', amount: parseFloat(item.oct) || 0 },
                    { month: 'Nov', amount: parseFloat(item.nov) || 0 },
                    { month: 'Dec', amount: parseFloat(item.dec) || 0 }
                ].filter(price => price.amount !== 0)
            }));

            const data = {
                email: emailData?.email,
                language: currentLanguage === "eng" ? "EN" : "ES",
                EmployeeCost: transformedEmpCostData,
                OfficeCost: transformedOffCostData,
                MarketingCost: transformedMarkCostData,
                TrainingCost: transformedTrainingCostData
            }
            // console.log("4d5fgh51", data)
            // return
            oncloseSendModal();
            setIsLoading(true);
            const res = await HttpClient.requestData("reportbuilder/pdf/budgetStatementPDF", "POST", data)
            if (res && res?.status) {
                toast?.success(language.mail_sent_success);
                setIsLoading(false);
            } else {
                setIsLoading(false);
            }
        }
    }

    // >>>>Setting Sub-Totals For Employee Cost Data<<<<
    useEffect(() => {
        const calculateSubTotals = () => {
            const subTotals = ownerData.reduce((acc, item) => {
                Object.keys(employeeCost).forEach(month => {
                    if (month !== 'empCost') {
                        acc[month] = (acc[month] || 0) + (parseFloat(item[month]) || 0);
                    }
                });
                return acc;
            }, {});
            setSubTotals(subTotals);
        };
        calculateSubTotals();
    }, [ownerData]);

    // >>>>Setting Sub-Totals For Office Cost Data<<<<
    useEffect(() => {
        const calculateSubTotals = () => {
            const subTotals = offCostData.reduce((acc, item) => {
                Object.keys(officeCost).forEach(month => {
                    if (month !== 'offCost') {
                        acc[month] = (acc[month] || 0) + (parseFloat(item[month]) || 0);
                    }
                });
                return acc;
            }, {});
            setOfficeSubTotal(subTotals);
        };
        calculateSubTotals();
    }, [offCostData]);

    // >>>>Setting Sub-Totals For Marketing Cost Data<<<<
    useEffect(() => {
        const calculateSubTotals = () => {
            const subTotals = markCostData.reduce((acc, item) => {
                Object.keys(marketingCost).forEach(month => {
                    if (month !== 'markCost') {
                        acc[month] = (acc[month] || 0) + (parseFloat(item[month]) || 0);
                    }
                });
                return acc;
            }, {});
            setMarketingSubTotal(subTotals);
        };
        calculateSubTotals();
    }, [markCostData]);

    // >>>>Setting Sub-Totals For Training Cost Data<<<<
    useEffect(() => {
        const calculateSubTotals = () => {
            const subTotals = trainingCostData.reduce((acc, item) => {
                Object.keys(trainingCost).forEach(month => {
                    if (month !== 'trainCost') {
                        acc[month] = (acc[month] || 0) + (parseFloat(item[month]) || 0);
                    }
                });
                return acc;
            }, {});
            setTrainingSubTotal(subTotals);
        };
        calculateSubTotals();
    }, [trainingCostData]);

    // >>>>Calculating Total of Sub-Totals<<<<
    useEffect(() => {
        const calculateTotalSubTotals = () => {
            const total = {};
            const months = Object.keys(employeeCost).filter(key => key !== 'empCost' && key !== 'offCost' && key !== 'markCost' && key !== 'trainCost');

            months.forEach(month => {
                total[month] = (subTotals[month] || 0) +
                    (officeSubTotal[month] || 0) +
                    (marketingSubTotal[month] || 0) +
                    (trainingSubTotal[month] || 0);
            });

            setTotalSubTotals(total);
        };

        calculateTotalSubTotals();
    }, [subTotals, officeSubTotal, marketingSubTotal, trainingSubTotal]);

    // >>>>Calculating Total Monthly Expenses<<<<
    useEffect(() => {
        const calculateCumulativeSubTotals = () => {
            const total = {};
            const cumulative = {};
            const months = Object.keys(employeeCost).filter(key => key !== 'empCost' && key !== 'offCost' && key !== 'markCost' && key !== 'trainCost');
            let runningTotal = 0;
            let hasValue = false;

            months.forEach(month => {
                total[month] = (subTotals[month] || 0) +
                    (officeSubTotal[month] || 0) +
                    (marketingSubTotal[month] || 0) +
                    (trainingSubTotal[month] || 0);

                if (total[month] !== 0) {
                    hasValue = true;
                    runningTotal += total[month];
                    cumulative[month] = runningTotal;
                } else {
                    hasValue = false
                    cumulative[month] = hasValue ? runningTotal : 0;
                }
            });

            cumulative['year'] = hasValue ? runningTotal : 0;
            setCumulativeSubTotals(cumulative);
        };

        calculateCumulativeSubTotals();
    }, [subTotals, officeSubTotal, marketingSubTotal, trainingSubTotal]);

    // >>>>Sending Male<<<< 
    useEffect(() => {
        setEmailSendFunc(() => sendCapatialMail)
    }, [isSave])

    useEffect(() => {
        getBudgetForcastReport()
        setPdfCreate(() => handlePdfDownload);
    }, [])
    return (
        <div className='container'>
            <div className="buildIncmStTableDiv">

                <h5 className='mb-2 font-weight-bold'>{language.planned_exp}</h5>

                <table className="buildIncmStTable">
                    <tbody>

                        {/****table Head Employee Cost***/}
                        <tr>
                            <th className="buildIncmStTh12 buildIncmStTh12First">

                                {isSave && <button
                                    className="buildAddBtn"
                                    onClick={() => {
                                        setOwnerData(prev => [...prev, employeeCost]);
                                    }}
                                >
                                    <i className="fa-solid fa-plus" />
                                </button>}

                                <span>{language.employee_cost}</span>

                                {isSave && <button className="delBuildRowBtn"
                                    onClick={() => setIsDelOwner(prev => !prev)}
                                >
                                    {isDelOwner ? language.remove_delete : language.delete_row}
                                </button>}

                            </th>

                            {months.map((month, index) => (
                                <th key={index} className="buildIncmStTh12 pr-2">
                                    <span>{month}</span>
                                </th>
                            ))}

                            <th className="buildIncmStTh12">
                                <span>YEAR</span>
                            </th>

                        </tr>

                        {/****Data**/}
                        {ownerData?.map((item, i) => {
                            return (
                                <tr key={i}>
                                    <td className=" buildIncmStTd">
                                        {(isDelOwner && isSave) && <button
                                            className="buildCapiRemoveBtn"
                                            onClick={() => {
                                                setOwnerData(prev => prev.filter((ele, ind) => ind !== i))
                                            }}
                                        >
                                            <i class="fa-solid fa-minus"></i>
                                        </button>}
                                        <input type="text"
                                            className="userBuildInp"
                                            name="empCost"
                                            value={item?.empCost}
                                            onChange={(e) => handleChangeOwner(e, i)}
                                        // placeholder='Wages'
                                        />
                                    </td>

                                    {['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec', 'year'].map(month => (
                                        <td key={month} className="buildIncmStTd pr-2">
                                            <input
                                                type="number"
                                                name={month}
                                                value={item[month]}
                                                onChange={(e) => handleChangeOwner(e, i)}
                                                placeholder="$0.00"
                                            />
                                        </td>
                                    ))}
                                </tr>
                            )
                        })
                        }

                        {/*****border**/}
                        <tr>
                            <td />
                            <td colSpan={13}>
                                <div className="buildTotDivider" />
                            </td>
                        </tr>

                        {/***Sub Total**/}
                        <tr>
                            <td className="buildIncmStTd">
                                <span className="buildTotSpan">{language.sub_total}</span>
                            </td>
                            {['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec', 'year'].map(month => (
                                <td key={month} className="buildIncmStTd pr-2">
                                    <input
                                        type="number"
                                        readOnly
                                        value={subTotals[month] || 0}
                                        placeholder="$0.00"
                                    />
                                </td>
                            ))}
                        </tr>

                        {/****table Head Office Cost***/}
                        <tr>
                            <th className="buildIncmStTh12 buildIncmStTh12First">

                                {isSave && <button
                                    className="buildAddBtn"
                                    onClick={() => {
                                        setOffCostData(prev => [...prev, officeCost]);
                                    }}
                                >
                                    <i className="fa-solid fa-plus" />
                                </button>}

                                <span>{language.office_cost}</span>

                                {isSave && <button className="delBuildRowBtn"
                                    onClick={() => setIsDelOfficeCost(prev => !prev)}
                                >{isDelOfficeCost ? language.remove_delete : language.delete_row}</button>}

                            </th>

                            {months.map((month, index) => (
                                <th key={index} className="buildIncmStTh12 pr-2">
                                    <span>{month}</span>
                                </th>
                            ))}

                            <th className="buildIncmStTh12">
                                <span>YEAR</span>
                            </th>

                        </tr>

                        {/****Data**/}
                        {offCostData?.map((item, i) => {
                            return (
                                <tr key={i}>
                                    <td className="buildIncmStTd">
                                        {isDelOfficeCost && <button
                                            className="buildCapiRemoveBtn"
                                            onClick={() => {
                                                setOffCostData(prev => prev.filter((ele, ind) => ind !== i))
                                            }}
                                        >
                                            <i class="fa-solid fa-minus"></i>
                                        </button>}
                                        <input type="text"
                                            className="userBuildInp"
                                            name="offCost"
                                            value={item?.offCost}
                                            onChange={(e) => handleChangeOffice(e, i)}
                                            defaultValue="Wages"
                                        />
                                    </td>

                                    {['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec', 'year'].map(month => (
                                        <td key={month} className="buildIncmStTd pr-2">
                                            <input
                                                type="number"
                                                name={month}
                                                value={item[month]}
                                                onChange={(e) => handleChangeOffice(e, i)}
                                                placeholder="$0.00"
                                            />
                                        </td>
                                    ))}
                                </tr>
                            )
                        })
                        }

                        {/*****border**/}
                        <tr>
                            <td />
                            <td colSpan={13}>
                                <div className="buildTotDivider" />
                            </td>
                        </tr>

                        {/***Sub Total**/}
                        <tr>
                            <td className="buildIncmStTd">
                                <span className="buildTotSpan">{language.sub_total}</span>
                            </td>
                            {['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec', 'year'].map(month => (
                                <td key={month} className="buildIncmStTd pr-2">
                                    <input
                                        type="number"
                                        readOnly
                                        value={officeSubTotal[month] || 0}
                                        placeholder="$0.00"
                                    />
                                </td>
                            ))}
                        </tr>

                        {/****table Head Marketing Cost***/}
                        <tr>
                            <th className="buildIncmStTh12 buildIncmStTh12First">

                                {isSave && <button
                                    className="buildAddBtn"
                                    onClick={() => {
                                        setMarketingCostData(prev => [...prev, marketingCost]);
                                    }}
                                >
                                    <i className="fa-solid fa-plus" />
                                </button>}

                                <span>{language.marketing_cost}</span>

                                {isSave && <button className="delBuildRowBtn"
                                    onClick={() => setIsDelMarketingCost(prev => !prev)}
                                >{isDelMarketingCost ? language.remove_delete : language.delete_row}</button>}

                            </th>

                            {months.map((month, index) => (
                                <th key={index} className="buildIncmStTh12 pr-2">
                                    <span>{month}</span>
                                </th>
                            ))}

                            <th className="buildIncmStTh12">
                                <span>YEAR</span>
                            </th>

                        </tr>

                        {/****Data**/}
                        {markCostData?.map((item, i) => {
                            return (
                                <tr key={i}>
                                    <td className="buildIncmStTd">
                                        {isDelMarketingCost && <button
                                            className="buildCapiRemoveBtn"
                                            onClick={() => {
                                                setMarketingCostData(prev => prev.filter((ele, ind) => ind !== i))
                                            }}
                                        >
                                            <i class="fa-solid fa-minus"></i>
                                        </button>}
                                        <input
                                            type="text"
                                            className="userBuildInp"
                                            name="markCost"
                                            value={item?.markCost}
                                            onChange={(e) => handleChangeMarketing(e, i)}
                                            defaultValue="Website hosting"
                                        />
                                    </td>

                                    {['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec', 'year'].map(month => (
                                        <td key={month} className="buildIncmStTd pr-2">
                                            <input
                                                type="number"
                                                name={month}
                                                value={item[month]}
                                                onChange={(e) => handleChangeMarketing(e, i)}
                                                placeholder="$0.00"
                                            />
                                        </td>
                                    ))}
                                </tr>
                            )
                        })
                        }

                        {/*****border**/}
                        <tr>
                            <td />
                            <td colSpan={13}>
                                <div className="buildTotDivider" />
                            </td>
                        </tr>

                        {/***Sub Total**/}
                        <tr>
                            <td className="buildIncmStTd">
                                <span className="buildTotSpan">{language.sub_total}</span>
                            </td>
                            {['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec', 'year'].map(month => (
                                <td key={month} className="buildIncmStTd pr-2">
                                    <input
                                        type="number"
                                        readOnly
                                        value={marketingSubTotal[month] || 0}
                                        placeholder="$0.00"
                                    />
                                </td>
                            ))}
                        </tr>

                        {/****table Head Training Cost***/}
                        <tr>
                            <th className="buildIncmStTh12 buildIncmStTh12First">

                                {isSave && <button
                                    className="buildAddBtn"
                                    onClick={() => {
                                        setTrainingCostData(prev => [...prev, trainingCost]);
                                    }}
                                >
                                    <i className="fa-solid fa-plus" />
                                </button>}

                                <span>{language.training_cost}</span>

                                {isSave && <button className="delBuildRowBtn"
                                    onClick={() => setIsDelTrainingCost(prev => !prev)}
                                >{isDelTrainingCost ? language.remove_delete : language.delete_row}</button>}
                            </th>

                            {months.map((month, index) => (
                                <th key={index} className="buildIncmStTh12 pr-2">
                                    <span>{month}</span>
                                </th>
                            ))}

                            <th className="buildIncmStTh12">
                                <span>YEAR</span>
                            </th>

                        </tr>

                        {/****Data**/}
                        {trainingCostData?.map((item, i) => {
                            return (
                                <tr key={i}>
                                    <td className="buildIncmStTd">
                                        {isDelTrainingCost && <button
                                            className="buildCapiRemoveBtn"
                                            onClick={() => {
                                                setTrainingCostData(prev => prev.filter((ele, ind) => ind !== i))
                                            }}
                                        >
                                            <i class="fa-solid fa-minus"></i>
                                        </button>}
                                        <input
                                            type="text"
                                            className="userBuildInp"
                                            name="trainCost"
                                            value={item?.trainCost}
                                            onChange={(e) => handleChangeTraining(e, i)}
                                            defaultValue="Training Classes"
                                        />
                                    </td>

                                    {['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec', 'year'].map(month => (
                                        <td key={month} className="buildIncmStTd pr-2">
                                            <input
                                                type="number"
                                                name={month}
                                                value={item[month]}
                                                onChange={(e) => handleChangeTraining(e, i)}
                                                placeholder="$0.00"
                                            />
                                        </td>
                                    ))}
                                </tr>
                            )
                        })
                        }

                        {/*****border**/}
                        <tr>
                            <td />
                            <td colSpan={13}>
                                <div className="buildTotDivider" />
                            </td>
                        </tr>

                        {/***Sub Total**/}
                        <tr>
                            <td className="buildIncmStTd">
                                <span className="buildTotSpan">{language.sub_total}</span>
                            </td>
                            {['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec', 'year'].map(month => (
                                <td key={month} className="buildIncmStTd pr-2">
                                    <input
                                        type="number"
                                        readOnly
                                        value={trainingSubTotal[month] || 0}
                                        placeholder="$0.00"
                                    />
                                </td>
                            ))}
                        </tr>


                        {/****MONTHLY Planned Expenses***/}
                        <tr>
                            <td className="buildIncmStTd">
                                <span className="buildTotSpan">{language.monthly_planned_exp}</span>
                            </td>

                            <td className="buildIncmStTd pr-2">
                                <input type="number"
                                    readOnly=""
                                    value={totalSubTotals?.jan}
                                    placeholder='$0.00' />
                            </td>

                            {/* Feb-24 */}
                            <td className="buildIncmStTd pr-2">
                                <input type="number"
                                    readOnly=""
                                    value={totalSubTotals?.feb}
                                    placeholder='$0.00'
                                />
                            </td>

                            {/* Mar-24 */}
                            <td className="buildIncmStTd pr-2">
                                <input type="number"
                                    readOnly=""
                                    value={totalSubTotals?.mar}
                                    placeholder='$0.00'
                                />
                            </td>

                            {/* Apr-24 */}
                            <td className="buildIncmStTd pr-2">
                                <input type="number"
                                    readOnly=""
                                    value={totalSubTotals?.apr}
                                    placeholder='$0.00'
                                />
                            </td>

                            {/* May-24 */}
                            <td className="buildIncmStTd pr-2">
                                <input type="number"
                                    readOnly=""
                                    value={totalSubTotals?.may}
                                    placeholder='$0.00'
                                />
                            </td>

                            {/* Jun-24 */}
                            <td className="buildIncmStTd pr-2">
                                <input type="number"
                                    readOnly=""
                                    value={totalSubTotals?.jun}
                                    placeholder='$0.00'
                                />
                            </td>

                            {/* Jul-24 */}
                            <td className="buildIncmStTd pr-2">
                                <input type="number"
                                    readOnly=""
                                    value={totalSubTotals?.jul}
                                    placeholder='$0.00'
                                />
                            </td>

                            {/* Aug-24 */}
                            <td className="buildIncmStTd pr-2">
                                <input type="number"
                                    readOnly=""
                                    value={totalSubTotals?.aug}
                                    placeholder='$0.00'
                                />
                            </td>

                            {/* Sep-24 */}
                            <td className="buildIncmStTd pr-2">
                                <input type="number"
                                    readOnly=""
                                    value={totalSubTotals?.sep}
                                    placeholder='$0.00'
                                />
                            </td>

                            {/* Oct-24 */}
                            <td className="buildIncmStTd pr-2">
                                <input type="number"
                                    readOnly=""
                                    value={totalSubTotals?.oct}
                                    placeholder='$0.00'
                                />
                            </td>

                            {/* Nov-24 */}
                            <td className="buildIncmStTd pr-2">
                                <input type="number"
                                    readOnly=""
                                    value={totalSubTotals?.nov}
                                    placeholder='$0.00'
                                />
                            </td>

                            {/* Dec-24 */}
                            <td className="buildIncmStTd pr-2">
                                <input type="number"
                                    readOnly=""
                                    value={totalSubTotals?.dec}
                                    placeholder='$0.00'
                                />
                            </td>

                            {/* Year */}
                            <td className="buildIncmStTd">
                                <input type="number"
                                    readOnly=""
                                    value={totalSubTotals?.year}
                                    placeholder="$0.00"
                                />
                            </td>

                        </tr>

                        {/****TOTAL Planned Expenses****/}
                        <tr>

                            <td className="buildIncmStTd">
                                <span className="buildProSpan">{language.total_planned_exp}</span>
                            </td>

                            {/* Jan-24 */}
                            <td className="buildIncmStTd pr-2">
                                <input type="number"
                                    readOnly=""
                                    value={cumulativeSubTotals.jan}
                                    placeholder='$0.00'
                                />
                            </td>

                            {/* Feb-24 */}
                            <td className="buildIncmStTd pr-2">
                                <input type="number"
                                    readOnly=""
                                    value={cumulativeSubTotals.feb}
                                    placeholder='$0.00'
                                />
                            </td>

                            {/* Mar-24 */}
                            <td className="buildIncmStTd pr-2">
                                <input type="number"
                                    readOnly=""
                                    value={cumulativeSubTotals.mar}
                                    placeholder='$0.00'
                                />
                            </td>

                            {/* Apr-24 */}
                            <td className="buildIncmStTd pr-2">
                                <input type="number"
                                    readOnly=""
                                    value={cumulativeSubTotals.apr}
                                    placeholder='$0.00'
                                />
                            </td>

                            {/* May-24 */}
                            <td className="buildIncmStTd pr-2">
                                <input type="number"
                                    readOnly=""
                                    value={cumulativeSubTotals.may}
                                    placeholder='$0.00'
                                />
                            </td>

                            {/* Jun-24 */}
                            <td className="buildIncmStTd pr-2">
                                <input type="number"
                                    readOnly=""
                                    value={cumulativeSubTotals.jun}
                                    placeholder='$0.00'
                                />
                            </td>

                            {/* Jul-24 */}
                            <td className="buildIncmStTd pr-2">
                                <input type="number"
                                    readOnly=""
                                    value={cumulativeSubTotals.jul}
                                    placeholder='$0.00'
                                />
                            </td>

                            {/* Aug-24 */}
                            <td className="buildIncmStTd pr-2">
                                <input type="number"
                                    readOnly=""
                                    value={cumulativeSubTotals.aug}
                                    placeholder='$0.00'
                                />
                            </td>

                            {/* Sep-24 */}
                            <td className="buildIncmStTd pr-2">
                                <input type="number"
                                    readOnly=""
                                    value={cumulativeSubTotals.sep}
                                    placeholder='$0.00'
                                />
                            </td>

                            {/* Oct-24 */}
                            <td className="buildIncmStTd pr-2">
                                <input type="number"
                                    readOnly=""
                                    value={cumulativeSubTotals.oct}
                                    placeholder='$0.00'
                                />
                            </td>

                            {/* Nov-24 */}
                            <td className="buildIncmStTd pr-2">
                                <input type="number"
                                    readOnly=""
                                    value={cumulativeSubTotals.nov}
                                    placeholder='$0.00'
                                />
                            </td>

                            {/* Dec-24 */}
                            <td className="buildIncmStTd pr-2">
                                <input type="number"
                                    readOnly=""
                                    value={cumulativeSubTotals.dec}
                                    placeholder='$0.00'
                                />
                            </td>

                            {/* Year */}
                            <td className="buildIncmStTd">
                                <input type="number"
                                    readOnly=""
                                    value={cumulativeSubTotals.year}
                                    placeholder='$0.00'
                                />
                            </td>

                        </tr>

                    </tbody>
                </table>

                {/* Buttons */}
                <div className="d-flex justify-content-center mt-3">

                    <button className="btn-report-save"
                        onClick={handleSave}
                    >{
                            isSave ?
                                saveLoading ? <CustomLoaderLine height="10" width="40" /> : language.save?.toUpperCase()
                                :
                                language.edit?.toUpperCase()
                        }</button>

                    <button className="btn-report-build"
                        onClick={() => handleBuildRepport()}

                    >
                        {loading ? <CustomLoaderLine height="10" width="100" /> : language.build_report?.toUpperCase()}
                    </button>

                </div>

            </div>
        </div>
    )
}

export default BuildBudgetForecast