import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import CheckoutForm from "./CheckoutForm";
// import "./stripe.css";
import { reactLocalStorage } from "reactjs-localstorage";
import HttpClient from "../../utils/HttpClient";
import { data } from "jquery";
import { useLocation } from "react-router-dom";
import { useLanguage } from "../../Providers/LanguageContext";
import { useUserData } from "../../Providers/UserWrapper";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.
const stripePromise = loadStripe("pk_test_51LzMApC7pf0mBoesrVajthFhCktKa5kuXbSxwRjkbVbhfcJ4IzE200zW1WfKJ1G5PZtIULioDuSyUKRIEO8doxEJ00QbZeCNsv");

export default function Stripegetway() {
  const { language, currentLanguage } = useLanguage();
  const [clientSecret, setClientSecret] = useState("");
  const { isLoading, setIsLoading } = useUserData();
  const location = useLocation();
  const [priceId, setPriceId] = useState(location.sid)
  const [price, setPrice] = useState(location.state)
  
  // console.log('priceId',location)

  const fetchSubsStripe = async () => {
    let data = {
      "amount": Number(price) * 100,
      "currency": currentLanguage === 'eng' ? "usd" : "mxn",
    }

    console.log("datahjjk", data);

    setIsLoading(true);
    const res = await HttpClient.requestData("create-subscription-stripe", "POST", data)
    console.log("resddggsd", res)
    if (res && res?.status) {
      setClientSecret(res?.clientSecret);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    // let token = "";
    // let user = reactLocalStorage.getObject("userData");
    // if (user && user != null && Object.keys(user).length > 0) {
    //   token = user.token;
    // }
    // // Create PaymentIntent as soon as the page loads
    // console.log('token', token)
    // let data = {
    //   price:priceId
    // }
    // fetch("http://35.154.235.57:4040/api/v1/user/create-checkout", {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //     "Authorization": token,
    //     "userType": "User",
    //     "Access-Control-Allow-Origin": "*"
    //   },
    //   body: JSON.stringify(data),
    // })
    //   .then((res) => res.json())
    //   .then((data) => setClientSecret(data.clientSecret));

    fetchSubsStripe();
  }, [currentLanguage]);

  const appearance = {
    theme: 'stripe',
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <div className="Appstripe">
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm 
            clientSecretId={clientSecret} 
          />
        </Elements>
      )}
    </div>
  );
}