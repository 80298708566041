import React from 'react'
import './BuildReports.css'
import BuildRepoStart from "../../../Component/BuildRepoStart";
import moment from 'moment';
import { useUserData } from '../../../Providers/UserWrapper';
import { useLanguage } from '../../../Providers/LanguageContext';
import { useNavigate } from 'react-router-dom';

function BuildReport() {
  const { profileData } = useUserData()
  const navigate = useNavigate();
  const { language, currentLanguage } = useLanguage()

  console.log("profileDataxc", profileData?.name)
  return (
    <>
      <section>
        <div className="container">
          <div className="buildReprtTop">
            <ul>
              <li><span className='buildtopSpn' style={{color:"#2a6099"}}>{language?.company_name} : </span> {profileData && profileData?.companyName}</li>
              <li><span className='buildtopSpn' style={{color:"#2a6099"}}>{language?.report_generated_by} : </span> {profileData?.name + " " + profileData?.surName}</li>
              <li><span className='buildtopSpn' style={{color:"#2a6099"}}>{language?.report_generated_on} : </span> {moment().format("LL")}</li>
              {/* <li><span className='buildtopSpn'>Report Period:</span> 01.02.23 - 27.02.24</li> */}
            </ul>
          </div>
          <div className='' style={{ marginLeft: "-7.5rem" }}>
            <button className='w-25 btn btn-outline-success mb-2' style={{ fontWeight: "bold", margin: "0 0 0 120px" }} onClick={() => navigate('/view_reports')}>
              {language.view_reports}
            </button>
          </div>
          <div className='buildRepoSection'>
            <BuildRepoStart />
          </div>
        </div>
      </section>
    </>
  )
}

export default BuildReport